import * as React from 'react';
import { apiAction } from '../../api/api';
import Loader from '../custom/loader/Loader';
import { useNavigate } from 'react-router-dom';
import * as Actions from '../../state/Actions';
import { get_history_of_object } from '../../api/urls';
import { getBusinessInfo } from '../../config/cookiesInfo';
import CustomTypography from '../custom/typography/CustomTypography';

import {
    setLoader,
    getDateFormat,
} from '../../utils/Utils';

import {
    Box,
    Step,
    Slide,
    Drawer,
    Button,
    Avatar,
    Stepper,
    Divider,
    StepLabel,
    IconButton,
    StepContent,
} from '@mui/material';
import moment from 'moment';
import { Close } from '@mui/icons-material';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});
const AuditTrailHistory = (props) => {
    const { data,AnalyticsEvent } = props;
    const navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);

    const [open, setOpen] = React.useState(false);
    const [results, setResults] = React.useState([]);
    const toggleDrawer = () => setOpen(!open);

    let business_id = getBusinessInfo().id;
    const [message, setMessage] = React.useState()

    React.useEffect(() => {
        const getData = async (data) => {
            setMessage();
            setLoader(dispatch, Actions, false);
            let res = await apiAction({
                dispatch: dispatch,
                navigate: navigate,
                url: get_history_of_object(business_id, data.object_type, data.object_id),
            })
            if (res?.success) {
                setResults(res?.result);
                setMessage('No history found');
                setLoader(dispatch, Actions, false);
            } else {
                setLoader(dispatch, Actions, false);
            }
        }
        if (open && data) {
            getData(data)
        }
    }, [open]);

    return (
        <React.Fragment>
            <Button
                id={'audit_trail_history_btn'}
                dataTestId={'audit_trail_history_btn'}
                size='small'
                variant='contained'
                onClick={() => {
                    toggleDrawer();
                    if (AnalyticsEvent) {
                        AnalyticsEvent()
                    }
                }}
                sx={{
                    ml: 1,
                    height: '38px',
                    fontSize: '12px',
                    fontWeight: '700',
                    fontStyle: 'normal',
                    textTransform: 'none',
                    fontFamily: 'Noto Sans',
                }}
            >
                History
            </Button>
            <Drawer
                open={open}
                elevation={5}
                anchor={'right'}
                hideBackdrop={true}
                onClose={() => toggleDrawer()}
                TransitionComponent={Transition}
                id={'common_audit_trail_Dialog'}
                variant={open?"persistent":'temporary'}
                dataTestId={'common_audit_trail_Dialog'}
                PaperProps={{
                    sx: {
                        zIndex:999999,
                        backgroundColor: '#FFFFFF',
                        width: {
                            xs: '100vw',
                            sm: '35vw'
                        }
                    }
                }}
            >
                <Box sx={{
                    zIndex: 1,
                    minHeight: '100vh',
                    backgroundColor: '#FFFFFF',
                    boxShadow: '1px 0px 4px rgba(0, 0, 0, 0.04)',
                }}>
                    <Box sx={{ height: '24px', p: 2, pt: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <CustomTypography
                            text={'History'}
                            sx={{
                                color: '#000000',
                                fontSize: '14px',
                                fontWeight: '700',
                                lineHeight: '18px',
                                fontStyle: 'normal',
                                fontFamily: "Noto Sans",
                            }}
                        />
                        <IconButton size='small' id={'close_history_btn'} dataTestId={'close_history_btn'} onClick={() => toggleDrawer()} sx={{ ":hover": { bgcolor: '#e0e0e0' } }}>
                            <Close fontSize='small' />
                        </IconButton>

                    </Box>
                    <Divider />
                    <Box
                        sx={{
                            height: '90vh',
                            overflow: 'scroll',

                            backgroundColor: '#FFFFFF',
                        }}
                    >
                        {
                            results.length ?
                                <CustomSteps steps={results} />
                                :
                                <CustomTypography
                                    text={message ? message : <Loader />}
                                    sx={{
                                        mt: '40vh',
                                        color: '#000000',
                                        fontSize: '14px',
                                        fontWeight: '700',
                                        textAlign: 'center',
                                        lineHeight: '18px',
                                        fontStyle: 'normal',
                                        fontFamily: "Noto Sans",
                                    }}
                                />
                        }

                    </Box>
                </Box>
            </Drawer>
        </React.Fragment>
    )
};

export default AuditTrailHistory;

const CustomSteps = (props) => {
    const { steps } = props;

    const customStepIcon = () => <Avatar
        sx={{
            m: -1,
            background: '#f6f6f6',
        }}><SpeakerNotesIcon fontSize='small' sx={{ color: '#2098ff', opacity: 0.9 }} /></Avatar>

    return (
        <Stepper
            orientation="vertical"
            sx={{
                p: 2,

            }}
        >
            {steps.map((item, index) => (
                <Step
                    key={index}
                    active={true}
                >
                    <StepLabel StepIconComponent={customStepIcon}>
                        <Box sx={{
                            p: 1,
                        }}>
                            <CustomTypography
                                text={moment(item.history_date).format(getDateFormat() + ' hh:mm A')}
                                sx={{
                                    color: '#7A7A7A',
                                    fontSize: '14px',
                                    fontWeight: '500',
                                    lineHeight: '18px',
                                    fontStyle: 'normal',
                                    fontFamily: "Noto Sans",
                                }}
                            />
                        </Box>
                    </StepLabel>
                    <StepContent>
                        <Box sx={{
                            p: 1,
                            background: '#f6f6f6',
                        }}>
                            <CustomTypography
                                text={<span>{item.history_change_reason} by <span style={{ fontWeight: '700', }}>{item.history_user_name}</span></span>}
                                sx={{
                                    color: '#000000',
                                    fontSize: '14px',
                                    fontWeight: '500',
                                    lineHeight: '20px',
                                    fontStyle: 'normal',
                                    fontFamily: "Noto Sans",
                                }}
                            />
                        </Box>
                    </StepContent>
                </Step>
            ))}
        </Stepper>
    )
}
