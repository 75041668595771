import React from 'react';
import moment from 'moment';
import { apiAction } from '../../../../../api/api';
import * as Actions from '../../../../../state/Actions';
import { getGeneralLedger } from '../../../../../api/urls';
import { useNavigate, useLocation } from 'react-router-dom';
import { routesName } from '../../../../../config/routesName';
import { CustomLink } from '../../../../custom/link/CustomLink';
import { getBusinessInfo } from '../../../../../config/cookiesInfo';
import CurrencyFormatter from '../../../../common/CurrencyFormatter';
import ReportsSettingButtons from '../../../reports/ReportsSettingButtons';
import CommonCustomDateRange from '../../../../common/CommonCustomDateRange';
import { date_range, setLoader, getDateFormat } from '../../../../../utils/Utils';
import { ReactComponent as CalendarIcon } from '../../../../../assets/svg/calendar.svg';

import {
  Box,
  Grid,
} from '@mui/material';

import {
  CustomTable,
  CustomTableRow,
  CustomTableBody,
  CustomTableHead,
  CustomTableHeadCell,
  CustomTableBodyCell,
  CustomTableContainer,
} from '../../../../custom/table/CustomTable';
import { CustomContainer } from '../../../../custom/container/CustomContainer';
import CustomTypography from '../../../../custom/typography/CustomTypography';


const ListGeneralLedger = () => {

  let navigate = useNavigate();
  let location = useLocation();
  const dispatch = Actions.getDispatch(React.useContext);
  const stateAction = Actions.getState(React.useContext);

  const { selectedDateRange } = stateAction;
  let filter_date_range = date_range().find((item) => item.name === selectedDateRange)
  const default_date_range = filter_date_range ? filter_date_range : date_range()[2]

  const [dateRange, setDateRange] = React.useState(default_date_range)
  const [fromDate, setFromDate] = React.useState(moment())
  const [toDate, setToDate] = React.useState(moment())
  const [results, setResults] = React.useState([])
  const [date, setDate] = React.useState()

  const getApiResults = async (from_date, to_date, range_date) => {
    setLoader(dispatch, Actions, true)
    setDate(range_date)
    let res = await apiAction({
      method: 'post',
      navigate: navigate,
      dispatch: dispatch,
      url: getGeneralLedger(),
      data: {
        business_id: getBusinessInfo().id,
        from_date: from_date,
        to_date: to_date
      },
    })
    // console.log('res', res)
    if (res?.success) {
      setLoader(dispatch, Actions, false)
      setResults(res?.result)
    } else {
    }
  }

  React.useEffect(() => {
    if (dateRange) {
      if (dateRange.name !== "Custom") {
        getApiResults(dateRange.dates.from_date, dateRange.dates.to_date, dateRange.dates.date_range)
      } else {
        getApiResults(fromDate.format('YYYY-MM-DD'), toDate.format('YYYY-MM-DD'), `${fromDate.format(getDateFormat())} - ${toDate.format(getDateFormat())}`)
      }
    }
    // eslint-disable-next-line
  }, [dateRange, toDate, fromDate])


  ///////////////////////////////////////////////Api Call for the dropdown of accounts////////////////////////////////////////////////////////////////
  const [account_value, setAccount_Value] = React.useState(null)

  const onAccountChangeHandler = (value) => {
    setAccount_Value(value)
    if (value) {
      navigate(routesName.generalLedgerTransaction.path.replace(':id', value.id),
        {
          state: { dateRange: dateRange, toDate: toDate.format('YYYY-MM-DD'), fromDate: fromDate.format('YYYY-MM-DD') }
        }
      )
    }
  }

  ///////////////////////////////////////////////Console////////////////////////////////////////////////////////////////

  let isFromReport = location.pathname.includes('reports');

  return (
    <div>
      {isFromReport && <ReportsSettingButtons />}

      <CustomContainer maxWidth={"400"} sx={{ height: isFromReport ? 'calc(100vh - 126px)' : 'calc(100vh - 70px)' }}>
        <div style={{ paddingLeft: '16px', paddingRight: '16px' }}>
          <Grid container spacing={0} sx={{ pb: 2 }} style={{
            alignItems: 'center',
            justifyItems: 'center',
            justifyContent: 'space-evenly',
            alignContent: 'space-evenly',
          }}>
            <Grid item xs={12} sm={4}>
              <GeneralTitle date={date} />
            </Grid>
            <Grid container spacing={1} item xs={12} sm={8} sx={{ justifyContent: 'end', }}>
              <CommonCustomDateRange
                setPage={() => { }}
                date_range={date_range()}

                toDate={toDate}
                setToDate={setToDate}

                fromDate={fromDate}
                setFromDate={setFromDate}

                dateRange={dateRange}
                setDateRange={setDateRange}

                isAccountDropdown={true}
                account_value={account_value}
                onAccountChangeHandler={onAccountChangeHandler}
              />
            </Grid>
          </Grid>

        </div>
        <CustomTableContainer>
          <CustomTable>
            <GeneralHeader />
            <GeneralBody
              data={results}
              dateRange={dateRange}
              toDate={toDate.format('YYYY-MM-DD')}
              fromDate={fromDate.format('YYYY-MM-DD')}
            />
            <caption style={{ fontFamily: 'Noto Sans', fontWeight: '500', fontSize: '10px', fontStyle: 'normal', lineHeight: '14px' }}>**Amount is displayed in your base currency <span style={{ backgroundColor: '#6ff795', paddingLeft: '5px', paddingRight: '5px', paddingBottom: '2px', paddingTop: '1px' }}>{getBusinessInfo().currency.currency_code}</span></caption>
          </CustomTable>
        </CustomTableContainer>
      </CustomContainer>
    </div >
  )
}


const GeneralTitle = (props) => {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
    }}>
      <CustomTypography
        text={getBusinessInfo().legal_name}
        sx={{
          mb: 1,
          color: '#A3A3A3',
          fontSize: '12px',
          fontWeight: '800',
          lineHeight: '16px',
          fontStyle: 'normal',
          letterSpacing: '0.5px',
          fontFamily: "Noto Sans",
          textTransform: 'uppercase',
        }}
      />
      <CustomTypography
        text={'General Ledger'}
        sx={{
          mb: 1,
          color: '#141414',
          fontSize: '20px',
          fontWeight: '700',
          lineHeight: '27px',
          fontStyle: 'normal',
          textTransform: 'none',
          fontFamily: "Noto Sans",
        }}
      />

      <CustomTypography
        text={<span><CalendarIcon />{props.date}</span>}
        sx={{
          mb: 1,
          color: '#A3A3A3',
          fontSize: '12px',
          fontWeight: '500',
          lineHeight: '16px',
          fontStyle: 'normal',
          textTransform: 'none',
          fontFamily: "Noto Sans",
        }}
      />
    </Box>
  )
}

const GeneralHeader = () => {
  return (
    <CustomTableHead>
      <CustomTableRow >
        <CustomTableHeadCell style={{ width: 50 }}><span>Sr. No.</span></CustomTableHeadCell>
        <CustomTableHeadCell><span>Account</span></CustomTableHeadCell>
        <CustomTableHeadCell align='right'><span>Account Code</span></CustomTableHeadCell>
        <CustomTableHeadCell align='right'><span>Net Movement</span></CustomTableHeadCell>
      </CustomTableRow>
    </CustomTableHead>
  )
}

const GeneralBody = (props) => {
  const { data, toDate, fromDate, dateRange, } = props

  return (
    <CustomTableBody>
      {data.map((item, index) => {
        return (
          <CustomTableRow key={index} >
            <CustomTableBodyCell sx={{}} ><span>{(index + 1)}</span></CustomTableBodyCell>
            <CustomTableBodyCell >
              <span>
                <CustomLink
                  id={'business_account_name_link' + index}
                  dataTestId={'business_account_name_link' + index}

                  to={routesName.generalLedgerTransaction.path.replace(':id', item.business_account_id)}
                  state={{ dateRange: dateRange, toDate: toDate, fromDate: fromDate }}
                >{item.business_account_name}</CustomLink>
              </span><br />
              <span style={{ color: '#A3A3A3', fontFamily: 'Noto Sans', fontWeight: '500', fontSize: '10px', fontStyle: 'normal', lineHeight: '14px' }}>{item.parent_account_name}</span>
            </CustomTableBodyCell>
            <CustomTableBodyCell align='right'>{item.account_number}</CustomTableBodyCell>
            <CustomTableBodyCell align='right'>
              <CurrencyFormatter amount={item.net_movement} currency={item.currency_code} />
              {item.net_movement_type === "DEBIT" ? ' ( Dr. )' : ' ( Cr. )'}
            </CustomTableBodyCell>
          </CustomTableRow>
        )
      })
      }
    </CustomTableBody>
  )
}


export default ListGeneralLedger