import React, { useContext } from "react";
import moment from "moment/moment";
import "react-multi-email/dist/style.css";
import Emails from "../../common/Emails";
import { TaxList } from "../../common/GST";
import Input from "../../../../common/Input";
import * as Common from "../../common/Common";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import DateRange from "../../../../common/DateRange";
import DatePicker from "../../../../common/DatePicker";
import * as Actions from "../../../../../state/Actions";
import CustomTitle from "../../../../common/CustomTitle";
import CommonAlertBox from "../../common/CommonAlertBox";
import PrintAndPreview from "../../common/PrintAndPreview";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import AsyncDropdown from "../../../../common/AsyncDropdown";
import { routesName } from "../../../../../config/routesName";
import CommonDropdown from "../../../../common/CommonDropdown";
import CommonCalculation from "../../common/CommonCalculation";
import { input_css_style } from "../../../../../utils/Constant";
import { apiAction, apiFormData } from "../../../../../api/api";
import CustomDialog from "../../../../custom/dialog/CustomDialog";
import CustomButton from "../../../../custom/button/CustomButton";
import { getBusinessInfo } from "../../../../../config/cookiesInfo";
import { TermsAndConditions } from "../../common/TermsAndConditions";
import CurrencyFormatter from "../../../../common/CurrencyFormatter";
import { LabelWithAsteriskMark } from "../../../../common/CommonLabel";
import CommonPreviewShare from "../../../../common/CommonPreviewShare";
import CommonAsyncDropdown from "../../../../common/CommonAsyncDropdown";
import CustomGroupButton from "../../../../custom/button/CustomGroupButton";
import CommonCurrencySelection from "../../common/CommonCurrencySelection";
import CustomTypography from "../../../../custom/typography/CustomTypography";
import { eventsNames,AnalyticsEvent } from "../../../../../firebase/firebaseAnalytics";

import {
  getNotesPreferences,
  getDiscountPreferences,
  getAmountArePreferences,
  getTermsAndConditionPreferences,
} from "../../../setting/settings/Preferences/common/getPreferences";

import {
  debitNoteReasonChoice,
  GSTRegistrationTypeChoice,
} from "../../../../../utils/Constant";

import {
  CustomContainer,
  CustomTitleContainer,
  CustomButtonContainer,
} from "../../../../custom/container/CustomContainer";

import {
  setLoader,
  filterTax,
  isFormValid,
  filterArray,
  getQueryParams,
  findDefaultTax,
  isVendorRegister,
  stateChangeManager,
  isApplicableForTax,
  setIsVendorRegister,
  isBusinessRegistered,
  breadCrumbsStateChangeManager,
} from "../../../../../utils/Utils";

import {
  basePriceTotal,
  checkItemDiscount,
  totalDiscountOnBasePrice,
  isFinalAmountRangeCrossed,
  convertExclusiveToInclusive,
  convertInclusiveToExclusive,
  applyTaxOnMultipleGSTComponents,
} from "../../sales/invoices/InvoiceManager";

import {
  get_item,
  get_party,
  list_party,
  list_items,
  retrieve_bill,
  get_list_of_tax,
  get_list_of_bill,
  get_list_of_state,
  export_debit_note,
  create_debit_note,
  update_debit_note,
  retrieve_debit_note,
  send_debit_note_mail,
  get_create_debit_note_meta_data,
  get_chart_of_account_nested_nodes,
} from "../../../../../api/urls";

import {
  Box,
  Grid,
  Alert,
  Checkbox,
  AlertTitle,
  IconButton,
  FormControlLabel,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  CustomTable,
  CustomTableRow,
  CustomTableBody,
  CustomTableHead,
  CustomTableHeadCell,
  CustomTableBodyCell,
  CustomTableContainer,
} from "../../../../custom/table/CustomTable";
import CustomDefaultDropdown from "../../../../custom/dropdown/CustomDefaultDropdown";

const Dropdown = DateRange;

// eslint-disable-next-line
const isNumeric = (num) =>
  (typeof num === "number" || (typeof num === "string" && num.trim() !== "")) &&
  !isNaN(num);

const CreateDebitNote = (props) => {
  const { onClose } = props;

  const navigate = useNavigate();
  let location = useLocation();
  const params = getQueryParams(location.search);
  const dispatch = Actions.getDispatch(React.useContext);
  const [isFormSubmitted, setFormSubmitted] = React.useState(false);
  const [state, setState] = React.useState({
    id: "",
    title: "",
    open: false,
    condition: "",
    maxWidth: "lg",
    compulsory: "",
    fullWidth: true,
  });

  const [zero, setZero] = React.useState(false);
  const [saveAndSend, setSaveAndSend] = React.useState(false);

  const [itemIndex, setItemIndex] = React.useState(null);

  const addButtonHandler = (title, condition, maxWidth, compulsory, index) => {
    setState({
      open: true,
      title: title,
      fullWidth: true,
      maxWidth: maxWidth,
      condition: condition,
      compulsory: compulsory,
    });
    setItemIndex(index);
    if (index !== null || index !== undefined) {
      setNotesData({ ...notesData });
    }
  };

  const initial_debit_notes_data = {
    business_id: getBusinessInfo().id,

    vendor_id: null,
    selected_vendor: null,
    debit_note_number: null,

    vendor_emails: {
      cc: [],
      to: [],
      bcc: [],
      toEmails: "",
      ccEmails: "",
      bccEmails: "",
    },
    selected_bill: null,
    against_bill_id: null,
    against_bill_type: null,
    selected_against_bill_type: null,
    debit_note_date: moment().format("YYYY-MM-DD"),

    reason: null,
    has_rcm: false,
    selected_reason: null,

    note: getNotesPreferences('debit_note'),
    terms_and_conditions_id: getTermsAndConditionPreferences('debit_note'),
    selected_terms_and_condition: null,

    // sales_person_id: null,
    source_of_supply_id: null,
    selected_source_of_supply: null,

    mailing_address: null,

    is_entity_discount: true,
    tax_applied: getAmountArePreferences(),
    is_amount_tax_inclusive: getAmountArePreferences() === 'inclusive',

    debit_note_items: [
      {
        item: null,
        tax: null,
        rate: null,
        quantity: 1,
        tax_id: null,
        amount: null,
        item_id: null,
        unit_id: null,
        item_order: null,
        expense_category: null,
        item_level_discount: 0,
        expense_category_id: null,
        is_item_discount_in_percentage: true,
      },
    ],

    tax: null,
    discount: 0,
    subtotal: 0,
    bill_amount: 0,
    total_amount: 0,
    debit_note_upper_limit: 0,

    currency_code: null,

    is_draft: false,
  };

  const [notesData, setNotesData] = React.useState({
    ...initial_debit_notes_data,
  });

  React.useEffect(() => {
    const apiResults = async () => {
      let res = await apiAction({
        method: "post",
        url: get_create_debit_note_meta_data(),
        data: { business_id: getBusinessInfo().id },
      });
      if (res?.success) {
        notesData.debit_note_number = res?.result.debit_note_number;
        setNotesData({
          ...notesData,
          debit_note_number: res?.result.debit_note_number,
        });
      } else {
      }
    };
    if (Object.keys(params).length === 0) {
      apiResults();
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    const getPaidBill = async (id) => {
      setLoader(dispatch, Actions, true);
      let res = await apiAction({
        method: "post",
        url: retrieve_bill(id),
        data: { business_id: getBusinessInfo().id },
      });
      const partyRes = await apiAction({
        method: "post",
        url: get_party(notesData.vendor_id),
        data: { business_id: getBusinessInfo().id },
      });
      if (partyRes?.success) {
        if (Object.keys(params).length === 0) {
          let party_contact_data = partyRes?.result.party_contact_data.find(
            (data) => data.contact_type === "primary"
          );
          dataMapping({
            ...res?.result,
            gst_registration_type: partyRes?.result.gst_registration_type,
            party_contact_data: party_contact_data,
          });
        } else {
          setNotesData({
            ...notesData,
            bill_amount: res?.result.total_amount,
            debit_note_upper_limit:
              res?.result.debit_note_upper_limit + notesData.total_amount,
          });
        }
        setLoader(dispatch, Actions, false);
      }
    };
    if (notesData.against_bill_id) {
      getPaidBill(notesData.against_bill_id);
    }
    // eslint-disable-next-line
  }, [notesData.against_bill_id]);

  React.useEffect(() => {
    if (params.id) {
      setLoader(dispatch, Actions, true);
      breadCrumbsStateChangeManager(dispatch, 'Edit');
      const retrieveDebitNote = async () => {
        let res = await apiAction({
          method: "post",
          url: retrieve_debit_note(params.id),
          data: { business_id: getBusinessInfo().id },
        });
        if (res?.success) {
          updateDataMapping(res?.result);
          setLoader(dispatch, Actions, false);
        }
      };
      retrieveDebitNote();
    }
    // eslint-disable-next-line
  }, []);

  const dataMapping = (data) => {
    data.bill_items.map((item) => {
      if (item.tax) {
        item["tax_id"] = item.tax.id;
        item["item"] = {
          name: item.item,
          id: item.item_id,
          gst_rate: item.tax.rate ? item.tax.rate : null,
        };
        item["expense_category_id"] = item.expense_category;
        item["expense_category"] = {
          name: item.expense_category_name,
          id: item.expense_category,
          account_name: item.expense_category_name,
        };
      } else {
        item["tax_id"] = null;
        item["item"] = { name: item.item, id: item.item_id, gst_rate: null };
        item["expense_category_id"] = item.expense_category;
        item["expense_category"] = {
          name: item.expense_category_name,
          id: item.expense_category,
          account_name: item.expense_category_name,
        };
      }
      // item['disabled'] = true
    });
    setIsVendorRegister(data.gst_registration_type);
    setNotesData({
      ...notesData,
      ...data,
      bill_amount: data.total_amount,
      debit_note_upper_limit: data.debit_note_upper_limit,
      vendor_emails: {
        ...notesData.vendor_emails,
        to: data.party_contact_data.email ? [data.party_contact_data.email] : [],
      },
      against_bill_type: data.gst_registration_type,
      tax_applied: data.tax_applied,
      is_amount_tax_inclusive: data.tax_applied === "inclusive" ? true : false,

      selected_against_bill_type: GSTRegistrationTypeChoice.find(
        (item) => item.value === data.gst_registration_type
      ),

      source_of_supply_id: data.source_of_supply.id,
      selected_source_of_supply: data.source_of_supply.id
        ? { name: data.source_of_supply.state }
        : null,

      mailing_address: data.mailing_address,

      debit_note_items: data.bill_items,

      note: null,
      attachment: null,
      terms_and_condition: null,
      terms_and_conditions_id: null,
    });
  };

  const updateDataMapping = (data) => {
    data.debit_note_items.map((item) => {
      if (item.tax) {
        item["tax_id"] = item.tax.id;
        item["item"] = {
          name: item.item,
          id: item.item_id,
          gst_rate: item.tax.rate ? item.tax.rate : null,
        };
        item["expense_category_id"] = item.expense_category;
        item["expense_category"] = {
          name: item.expense_category_name,
          id: item.expense_category,
          account_name: item.expense_category_name,
        };
      } else {
        item["tax_id"] = null;
        item["item"] = { name: item.item, id: item.item_id, gst_rate: null };
        item["expense_category_id"] = item.expense_category;
        item["expense_category"] = {
          name: item.expense_category_name,
          id: item.expense_category,
          account_name: item.expense_category_name,
        };
      }
      // item['disabled'] = true
    });
    setIsVendorRegister(data.against_bill_type);
    setNotesData({
      ...notesData,
      ...data,
      // bill_amount: data.total_amount,
      source_of_supply_id: data.source_of_supply.id,
      selected_source_of_supply: data.source_of_supply.id
        ? { name: data.source_of_supply.state }
        : null,

      tax_applied: data.tax_applied,
      is_amount_tax_inclusive: data.tax_applied === "inclusive" ? true : false,

      terms_and_conditions_id: data.terms_and_conditions,
      selected_vendor: { name: data.vendor_display_name },
      selected_bill: { name: data.against_bill_number },
      debit_note_date: moment(data.debit_note_date).format("YYYY-MM-DD"),
      debit_note_upper_limit:
        data.total_amount + notesData.debit_note_upper_limit,
      selected_reason: debitNoteReasonChoice.find(
        (item) => item.value === data.reason
      ),
      terms_and_condition: data.terms_and_conditions
        ? { name: data.terms_and_conditions_name }
        : null,
      selected_against_bill_type: GSTRegistrationTypeChoice.find(
        (item) => item.value === data.against_bill_type
      ),
    });
  };

  const saveEvents = (is_draft) => {
    if (params.id) {
      AnalyticsEvent(eventsNames.categories.DEBIT_NOTES, { action: eventsNames.actions.EDIT })
    } else if (is_draft) {
      AnalyticsEvent(eventsNames.categories.DEBIT_NOTES, { action: eventsNames.actions.CREATE, state: eventsNames.actions.debitNote.state.DRAFT })
    } else {
      AnalyticsEvent(eventsNames.categories.DEBIT_NOTES, { action: eventsNames.actions.CREATE, state: eventsNames.actions.debitNote.state.SAVE })
    }
  };

  //////////////////////////////////////////////////////////////////////////////////////////
  const gst_registration_type = notesData.against_bill_type && notesData.against_bill_type;
  setIsVendorRegister(gst_registration_type);

  let is_Location = gst_registration_type === "overseas";
  let is_gst_with_zero = gst_registration_type === "overseas" || gst_registration_type === "sez";

  //////////////////////////////////////////////////////////////////////////////////////////
  const onSave = async ({ is_draft = false, is_save_send = false }) => {
    console.log("Debit Note On save====>", notesData);

    setFormSubmitted(true);
    const { tax_applied, debit_note_items, vendor_emails, has_rcm, is_amount_tax_inclusive } = notesData;
    const { to } = vendor_emails;

    const is_rate = debit_note_items.find((item) => item.rate === null);
    const is_quantity = debit_note_items.find((item) => !item.quantity);
    const is_tax = debit_note_items.find(
      (item) => tax_applied !== "no_tax" && !item.tax_id
    );
    const is_credit_note_item_not_selected = debit_note_items.find(
      (item) => !item.item_id
    );
    const is_expense_category_not_selected = debit_note_items.find(
      (item) => !item.expense_category_id
    );

    let validation_data = [
      {
        key: "",
        validation: notesData.debit_note_upper_limit < notesData.total_amount,
        message: `Something's not quite right!`,
      },
      {
        key: "",
        validation:
          notesData.applied_as_payment_amount + notesData.refunded_amount >
          notesData.total_amount,
        message: `Something's not quite right!`,
      },
      { key: "vendor_id", message: "Please Select Vendor" },
      { key: "against_bill_id", message: "Please Select Bill Number" },
      { key: "debit_note_number", message: "Please Enter Debit Note Number" },
      // {
      //   key: "emails",
      //   validation: to.length === 0,
      //   message: "Please Enter Valid Vendor Email",
      // },
      { key: "against_bill_type", message: "Please Select Bill Type" },
      isVendorRegister && !is_Location && {
        key: "source_of_supply_id",
        message: "Please Select Source of Supply",
      },
      { key: "debit_note_date", message: "Please Enter Valid Debit Note Date" },
      { key: "reason", message: "Please Select Reason" },
      {
        key: "",
        validation: is_credit_note_item_not_selected,
        message: "Please Select Item",
      },
      {
        key: "",
        validation: is_expense_category_not_selected,
        message: "Please Select Account",
      },
      {
        key: "",
        validation: is_quantity,
        message: "Please Enter the Quantity",
      },
      { key: "", validation: is_rate, message: "Please Enter the Rate" },
      { key: "", validation: is_tax, message: "Please Select the Tax" },
      { key: "", validation: has_rcm && is_amount_tax_inclusive, message: "Cannot create a debit note with Reverse Charge Mechanism (RCM) in a tax-inclusive case" },
    ];

    const { isValid, message } = isFormValid(notesData, validation_data);
    if (isValid) {
      notesData.vendor_emails.to = filterArray(notesData.vendor_emails.to)

      if (is_draft) {
        notesData.is_draft = true;
      } else {
        notesData.is_draft = false;
      }
      if (isApplicableForTax(notesData.tax_applied,isVendorRegister)) {
        setZero('isApplicableForTax');
        setSaveAndSend(is_save_send);
      }else if (notesData.total_amount === 0) {
        setZero('withZero');
        setSaveAndSend(is_save_send);
      } else {
        onSaveAction(is_save_send);
      }
    } else {
      stateChangeManager(dispatch, Actions, true, "error", message);
    }
  };

  const onSaveAction = async (is_save_send) => {
    setLoader(dispatch, Actions, true);
    let res = await apiFormData({
      method: "post",
      data: notesData,
      url: params.id ? update_debit_note(params.id) : create_debit_note(),
    });
    if (res?.success) {
      setLoader(dispatch, Actions, false);
      stateChangeManager(dispatch, Actions, true, "success", res?.status);
      if (is_save_send) {
        sendDebitNotes(res?.result.id);
      } else {
        redirect(res?.result.id);
        setLoader(dispatch, Actions, false);
      }
    } else {
      setLoader(dispatch, Actions, false);
      stateChangeManager(dispatch, Actions, true, "error", res?.status);
    }
  };

  const sendDebitNotes = async (debit_note_id) => {
    setSendMail(true);
    setSendMailId(debit_note_id);
  };

  const onAddItem = (value) => {
    // Construct the new item data
    const newItemData = {
      item: value,
      quantity: 1,
      item_order: null,
      item_id: value.id,
      unit_id: value?.unit?.id,
      amount: value.cost_price,
      description: value.description,
      hsn_sac_code: value.hsn_sac_code,
      rate: parseFloat(value.cost_price),
      item_level_discount: value.item_level_discount || 0,
      is_item_discount_in_percentage: checkItemDiscount(notesData?.debit_note_items),

      expense_category: value.purchase_account,
      expense_category_id: value.purchase_account?.id,

      gst_rate: value?.tax?.rate,
      tax: notesData.tax_applied === "no_tax" ? null: value?.tax,
      tax_id: notesData.tax_applied === "no_tax" ? null : value?.tax?.id,
    };

    // Update the sales order items
    notesData.debit_note_items[itemIndex] = newItemData;

    // Update the sales order state
    setNotesData(prevOrder => ({
      ...prevOrder,
      newItem: value,
      sales_items: [...prevOrder.debit_note_items],
    }));

  };

  const redirect = (id) => {
    if (onClose) {
      onClose(id);
    } else {
      navigate(routesName.invoicingDebitNotesView.path + "?id=" + id);
    }
  };

  const onClear = () => {
    setFormSubmitted(false);
    setNotesData({
      ...initial_debit_notes_data,
      debit_note_number: notesData.debit_note_number,
    });
  };

  // Common api call for the Accounts
  const [NewAccountsResult, setNewAccountsResult] = React.useState();

  const onAddAccount = (value) => {
    notesData.debit_note_items[itemIndex] = {
      ...notesData.debit_note_items[itemIndex],
      expense_category: value,
      expense_category_id: value.id,
    };
    setNewAccountsResult(value);
    setNotesData({
      ...notesData,
      newItem: value,
      sales_items: [...notesData.debit_note_items],
    });
  };

  const [sendMail, setSendMail] = React.useState(false);
  const [sendMailId, setSendMailId] = React.useState(false);

  // console.log('=====>before',notesData.debit_note_upper_limit < notesData.total_amount,notesData.debit_note_upper_limit,notesData.total_amount,notesData);

  return (
    <div>
      <CustomDialog
        state={state}
        setState={setState}
        itemIndex={notesData.itemIndex}
        onAddItem={(item) => onAddItem(item)}
        onAddAccount={(account) => onAddAccount(account)}
      />
      <CommonPreviewShare
        open={sendMail}
        id={sendMailId}
        type={"debit_note"}
        file_key={"debit_note_number"}
        export_Url={export_debit_note}
        retrieve_Url={retrieve_debit_note}
        setOpen={(value) => {
          setSendMail(value);
          redirect(sendMailId);
        }}
      />
      <CommonAlertBox
        open={zero}
        setOpen={setZero}
        onSaveAction={() => {
          if (zero==='withZero') {
            onSaveAction(saveAndSend);
          }else if (notesData.total_amount === 0) {
            setTimeout(()=>{
              setZero('withZero');
            },100)
          } else{
            onSaveAction(saveAndSend);
          }
        }}
        title={`Debit Note No. ${notesData.debit_note_number ? notesData.debit_note_number : ""
          }`}
        message={
          zero==='withZero'?
          `You are about to create the debit note with zero amount. Are you sure you want to proceed?`
          :
          `Please consult the relevant provisions of the laws to determine the application of taxes for GST unregistered entities.`
        }
      />
      <CustomTitleContainer>
        <Grid
          container
          spacing={0}
          style={{
            alignItems: "center",
            justifyItems: "center",
            alignContent: "space-evenly",
            justifyContent: "space-evenly",
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: "-webkit-flex", justifyContent: "start" }}
          >
            <CustomTitle
              title={`Debit Note No. ${notesData.debit_note_number ? notesData.debit_note_number : ""
                }`}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: "-webkit-flex", justifyContent: "end" }}
          >
            <CustomTypography
              text={
                <span>
                  Remaining Credit :{" "}
                  <span style={{ fontWeight: "700", color: "#000000" }}>
                    <CurrencyFormatter
                      amount={notesData.total_amount}
                      currency={notesData.currency_code}
                    />
                  </span>
                </span>
              }
              sx={{
                fontSize: "16px",
                color: "#7A7A7A",
                fontWeight: "600",
                textAlign: "right",
                lineHeight: "22px",
                fontStyle: "normal",
                fontFamily: "Noto Sans",
              }}
            />
          </Grid>
        </Grid>
      </CustomTitleContainer>
      {notesData.against_bill_id &&
        <>
          {notesData.debit_note_upper_limit < notesData.total_amount ? (
            notesData.debit_note_upper_limit === 0 ? (
              <Box sx={{ mt: 2 }}>
                <Alert severity="error">
                  <AlertTitle>
                    <strong>Something's not quite right</strong>
                  </AlertTitle>
                  It is not possible to create a new debit note for this bill{" "}
                  {notesData.bill_number} because the total amount of debit notes
                  already created against this bill {notesData.bill_number} has
                  reached the limit.
                </Alert>
              </Box>
            ) : notesData.debit_note_upper_limit === notesData.bill_amount ? (
              <Box sx={{ mt: 2 }}>
                <Alert severity="error">
                  <AlertTitle>
                    <strong>Something's not quite right</strong>
                  </AlertTitle>
                  The remaining credit amount{" "}
                  {
                    <CurrencyFormatter
                      amount={notesData.total_amount}
                      currency={notesData.currency_code}
                    />
                  }{" "}
                  can not be greater than selected bill amount{" "}
                  {
                    <CurrencyFormatter
                      amount={notesData.bill_amount}
                      currency={notesData.currency_code}
                    />
                  }
                  . Please change the amount, unit price, or quantity you want to
                  credit.
                </Alert>
              </Box>
            ) : (
              <Box sx={{ mt: 2 }}>
                <Alert severity="error">
                  <AlertTitle>
                    <strong>Something's not quite right</strong>
                  </AlertTitle>
                  Please ensure that the total amount of debit notes against the
                  bill {notesData.bill_number} does not exceed the remaining balance{" "}
                  {
                    <CurrencyFormatter
                      amount={notesData.debit_note_upper_limit}
                      currency={notesData.currency_code}
                    />
                  }
                  .
                </Alert>
              </Box>
            )
          ) : null}
          {notesData.applied_as_payment_amount + notesData.refunded_amount >
            notesData.total_amount ? (
            <Box sx={{ mt: 2 }}>
              <Alert severity="error">
                <AlertTitle>
                  <strong>Something's not quite right</strong>
                </AlertTitle>
                Please make sure that the debit note's amount is not lesser than{" "}
                {
                  <CurrencyFormatter
                    amount={
                      notesData.applied_as_payment_amount +
                      notesData.refunded_amount
                    }
                    currency={notesData.currency_code}
                  />
                }{" "}
                because as many credits have been applied to bills or refunded.
              </Alert>
            </Box>
          ) : null}
        </>
      }
      <CustomContainer maxWidth={"400"} sx={{}}>
        <form noValidate>
          <div style={{ borderBottom: "4px solid #F5F5F5" }}>
            <VendorDetails
              open={state.open}
              notesData={notesData}
              is_Location={is_Location}
              setNotesData={setNotesData}
              isFormSubmitted={isFormSubmitted}
              addButtonHandler={addButtonHandler}
              is_gst_with_zero={is_gst_with_zero}
              editDisabled={params.id ? true : false}
              initial_debit_notes_data={initial_debit_notes_data}
            />
          </div>

          <ProductAndServicesDetails
            open={state.open}
            itemIndex={itemIndex}
            notesData={notesData}
            is_Location={is_Location}
            setNotesData={setNotesData}
            isFormSubmitted={isFormSubmitted}
            is_gst_with_zero={is_gst_with_zero}
            addButtonHandler={addButtonHandler}
            NewAccountsResult={NewAccountsResult}
          />
        </form>
      </CustomContainer>
      <CustomButtonContainer>
        <Buttons
          onSave={onSave}
          onClear={onClear}
          notesData={notesData}
          is_Location={is_Location}
          onCancel={() => navigate(-1)}
          is_gst_with_zero={is_gst_with_zero}
          setFormSubmitted={setFormSubmitted}
        />
      </CustomButtonContainer>
    </div>
  );
};

export default CreateDebitNote;

const VendorDetails = (props) => {
  const {
    is_gst_with_zero,
    is_Location,
    editDisabled,
    notesData,
    setNotesData,
    isFormSubmitted,
    initial_debit_notes_data,
  } = props;
  const { vendor_emails } = notesData;

  const onChange = (key_name, value) => {
    setNotesData({ ...notesData, [key_name]: value });
  };

  const setEmails = (emails) => {
    setNotesData({
      ...notesData,
      vendor_emails: { ...vendor_emails, cc: emails.cc, bcc: emails.bcc },
    });
  };

  const [clickedOutside, setClickedOutside] = React.useState(true);
  const myRef = React.useRef();

  const handleClickOutside = (e) => {
    if (!myRef.current.contains(e.target)) {
      setClickedOutside(true);
    }
  };

  const handleClickInside = () => setClickedOutside(false);

  React.useEffect(() => {
    document?.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  return (
    <Box
      style={{
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingBottom: "32px",
      }}
    >
      <Grid container spacing={3}>
        {/*//////////////////////////////// Customer ////////////////////////////////*/}
        <Grid item xs={12} sm={4.5}>
          <CommonAsyncDropdown
            id={"vendor_dropdown"}
            dataTestId={"vendor_dropdown"}
            autoFocus={true}
            autoSelect={false}
            disableClearable={false}
            optionLabel="display_name"
            placeholder="Search Vendor"
            noOptionsText={"No result found"}
            validation={isFormSubmitted && !notesData.vendor_id}
            item={{
              method: "post",
              url: list_party(1),
              disabled: editDisabled,
              value: notesData.selected_vendor,
              label: <LabelWithAsteriskMark label={"Vendor"} />,
              sx: editDisabled ? { background: "#F1F3F4" } : {},
              body: {
                is_inactive: false,
                business_id: getBusinessInfo().id,
                role: "vendor",
              },
              onChange: (event, value) => {
                if (value) {
                  setNotesData({
                    ...notesData,
                    ...initial_debit_notes_data,
                    vendor_id: value.id,
                    selected_vendor: value,
                    debit_note_number: notesData.debit_note_number,
                  });
                } else {
                  setNotesData({
                    ...notesData,
                    ...initial_debit_notes_data,
                    vendor_id: null,
                    selected_bill: null,
                    against_bill_id: null,
                    selected_vendor: null,
                    debit_note_number: notesData.debit_note_number,
                  });
                }
              },
            }}
          />
        </Grid>
        {/*//////////////////////////////// Bill Number ////////////////////////////////*/}
        <Grid item xs={12} sm={4.5}>
          <CommonAsyncDropdown
            id={"bill_dropdown"}
            dataTestId={"bill_dropdown"}
            autoSelect={false}
            disableClearable={false}
            optionLabel="bill_number"
            noOptionsText={"No result found"}
            placeholder="Search Bill Number"
            validation={isFormSubmitted && !notesData.against_bill_id}
            item={{
              method: "post",
              disabled: !notesData.vendor_id || editDisabled,
              value: notesData.selected_bill,
              label: <LabelWithAsteriskMark label={"Bill Number"} />,
              url: notesData.vendor_id ? get_list_of_bill(1) : null,
              sx:
                !notesData.vendor_id || editDisabled
                  ? { background: "#F1F3F4" }
                  : {},
              body: notesData.vendor_id
                ? {
                  business_id: getBusinessInfo().id,
                  role: "vendor",
                  status: "paid",
                  vendor_id: notesData.vendor_id,
                }
                : null,
              onChange: (event, value) => {
                if (value) {
                  setNotesData({
                    ...notesData,
                    selected_bill: value,
                    against_bill_id: value.id,
                  });
                } else {
                  setNotesData({
                    ...notesData,
                    ...initial_debit_notes_data,
                    selected_bill: null,
                    against_bill_id: null,
                    vendor_id: notesData.vendor_id,
                    selected_vendor: notesData.selected_vendor,
                    debit_note_number: notesData.debit_note_number,
                  });
                }
              },
            }}
          />
        </Grid>

        {/*//////////////////////////////// Debit Note Number ////////////////////////////////*/}
        <Grid item xs={12} sm={3}>
          <Input
            id={"input_debit_note_number"}
            dataTestId={"input_debit_note_number"}
            item={{
              type: "text",
              required: true,
              fullWidth: true,
              texttransform: "none",
              inputProps: { maxLength: 16 },
              placeholder: "Enter Debit Note Number",
              title: <LabelWithAsteriskMark label={"Debit Note Number"} />,
              validation: isFormSubmitted && !notesData.debit_note_number,
              onChange: (e) => onChange("debit_note_number", e.target.value),
              value: notesData.debit_note_number
                ? notesData.debit_note_number
                : "",
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} sx={{ pt: 4 }}>
        {/*//////////////////////////////// Customer Email ////////////////////////////////*/}
        <Grid item xs={12} sm={4.5}>
          <Grid item xs={12} container>
            <Grid item xs={12} sm={6}>
              <CustomTypography
                text={"Vendor Email"}
                // text={<LabelWithAsteriskMark label={"Vendor Email"} />}
                sx={{
                  mb: 1,
                  fontSize: "14px",
                  color: "#000000",
                  fontWeight: "700",
                  lineHeight: "18px",
                  fontStyle: "normal",
                  textTransform: "none",
                  fontFamily: "Noto Sans",
                  textTransform: "capitalize",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ textAlign: "right" }}>
              <Emails
                value={notesData}
                setValue={setNotesData}
                customer_emails={vendor_emails}
                setEmail={setEmails}
              />
            </Grid>
          </Grid>
          <div onClick={handleClickInside} ref={myRef}>
            <ReactMultiEmail
              id={"input_to_email"}
              dataTestId={"input_to_email"}
              emails={filterArray(notesData.vendor_emails.to)}
              // placeholder={<span style={{}}><span style={{ margin: 0, height: "100%", overflow: 'hidden', color: '#2F2F2E', fontWeight: '400', lineHeight: '0px', fontSize: '12px', fontStyle: 'normal', fontFamily: 'Noto Sans', opacity: 0.50, }}>Enter Email Address</span></span>}
              placeholder={
                <p
                  style={{
                    margin: "0px",
                    marginTop: "-8px",
                    position: "sticky",
                  }}
                >
                  <span
                    style={{
                      margin: 0,
                      height: "100%",
                      overflow: "hidden",
                      color: "#2F2F2E",
                      fontWeight: "400",
                      lineHeight: "0px",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontFamily: "Noto Sans",
                      opacity: 0.5,
                    }}
                  >
                    Enter Email Address
                  </span>
                </p>
              }
              onChange={(emails) => {
                setNotesData({
                  ...notesData,
                  vendor_emails: { ...vendor_emails, to: emails },
                });
              }}
              style={{
                marginTop: "0px",
                textAlign: "center",
                borderRadius: "4px",
                overflowY: "scroll",
                maxHeight: 20,
                borderColor: clickedOutside
                  ?
                  // isFormSubmitted && 
                  // notesData.vendor_emails.to.length === 0
                  //   ? "#d32f2f": 
                  '#c7c7c7'
                  : "#2464EB",
                borderWidth: clickedOutside ? 1.5 : 2,
              }}
              getLabel={(email, index, removeEmail) => {
                return (
                  <div data-tag key={index} style={{ ...input_css_style }}>
                    {email}
                    <span data-tag-handle onClick={() => removeEmail(index)}>
                      ×
                    </span>
                  </div>
                );
              }}
              validateEmail={(email) => {
                if (email) {
                  return isEmail(email)
                } else {
                  return false
                }
                // return email?isEmail(email):false; // return boolean
              }}
            />
          </div>
        </Grid>

        {/*//////////////////////////////// Bill Type ////////////////////////////////*/}
        <Grid item xs={12} sm={4.5}>
          <CustomTypography
            text={<LabelWithAsteriskMark label={'Bill Type'} />}
            sx={{
              mb: 1,
              color: '#000000',
              fontSize: '14px',
              fontWeight: '700',
              lineHeight: '18px',
              fontStyle: 'normal',
              textTransform: "none",
              fontFamily: "Noto Sans",
              textTransform: 'capitalize',
            }}
          />
          <CustomDefaultDropdown
            labelKey='name'
            fullWidth={true}
            valueLabelKey={'name'}
            id={'bill_type_dropdown'}
            placeholder='Select Bill Type'
            results={GSTRegistrationTypeChoice}
            dataTestId={'bill_type_dropdown'}
            validation={isFormSubmitted && !notesData.against_bill_type}
            value={notesData.against_bill_type ? GSTRegistrationTypeChoice.find((item) => item.value === notesData.against_bill_type) : null}
            setValue={(value) => {
              setIsVendorRegister(value?.value)
              let taxType = isVendorRegister ? 'exclusive' : 'no_tax'

              if (value) {
                notesData.against_bill_type = value.value;
                notesData.selected_against_bill_type = value;

                if (value.value === "overseas" || value.value === "sez") {
                  notesData.source_of_supply_id = null;
                  notesData.selected_source_of_supply = null;
                }
              } else {
                notesData.against_bill_type = null;
                notesData.selected_against_bill_type = null;
              }

              notesData.tax_applied = taxType;
              notesData.is_amount_tax_inclusive = taxType === 'inclusive'
              setNotesData({ ...notesData });
            }}
          />

        </Grid>

        {/*//////////////////////////////// Debit Note Date ///////////////////////////////////////*/}
        <Grid item xs={12} sm={3}>
          <DatePicker
            id={"debit_note_date_select"}
            dataTestId={"debit_note_date_select"}
            placeholder=""
            title={<LabelWithAsteriskMark label={"Debit Note Date"} />}
            validation={isFormSubmitted && !notesData.debit_note_date}
            date={
              notesData.debit_note_date
                ? moment(notesData.debit_note_date)
                : null
            }
            setDate={(date) => {
              onChange(
                "debit_note_date",
                date ? date.format("YYYY-MM-DD") : null
              );
            }}
          />
        </Grid>

        {/*//////////////////////////////// Source of supply ////////////////////////////////*/}

        <Grid item xs={12} sm={4.5}>
          <CommonDropdown
            stateComponent={true}
            id={"source_of_supply_dropdown"}
            dataTestId={"source_of_supply_dropdown"}
            disableClearable={false}
            placeholder="Select a Location"
            validation={
              isVendorRegister &&
              !is_Location &&
              isFormSubmitted &&
              !notesData.source_of_supply_id
            }
            item={{
              method: "post",
              textTransform: "none",
              disabled: is_Location,
              url: get_list_of_state(),
              value: notesData.selected_source_of_supply,
              body: { business_id: getBusinessInfo().id },
              label: <LabelWithAsteriskMark label={"Source of Supply"} isAsteriskMark={isVendorRegister} />,
              onChange: (event, value) => {
                if (value) {
                  setNotesData({
                    ...notesData,
                    selected_source_of_supply: value,
                    source_of_supply_id: value.id,
                  });
                } else {
                  setNotesData({
                    ...notesData,
                    selected_source_of_supply: null,
                    source_of_supply_id: null,
                  });
                }
              },
            }}
          />
        </Grid>



        {/*//////////////////////////////// Reason ////////////////////////////////*/}
        <Grid item xs={12} sm={4.5}>
          <Dropdown
            id={"reason_dropdown"}
            dataTestId={"reason_dropdown"}
            disableClearable={false}
            placeholder="Select Reason"
            results={debitNoteReasonChoice}
            value={notesData.selected_reason}
            label={<LabelWithAsteriskMark label={"Reason"} />}
            validation={isFormSubmitted && !notesData.reason}
            setValue={(event, value) => {
              setNotesData({
                ...notesData,
                reason: value ? value.value : null,
                selected_reason: value ? value : null,
              });
            }}
          />
        </Grid>

        {/*//////////////////////////////// Mailing address ////////////////////////////////*/}
        <Grid item xs={12} sm={4.5}>
          <Input
            id={"input_mailing_address"}
            dataTestId={"input_mailing_address"}
            item={{
              rows: 4,
              type: "text",
              required: true,
              fullWidth: true,
              multiline: true,
              title: "Mailing Address",
              placeholder: "Enter Mailing Address",
              onChange: (e) => onChange("mailing_address", e.target.value),
              value: notesData.mailing_address ? notesData.mailing_address : "",
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const ProductAndServicesDetails = (props) => {
  const {
    NewAccountsResult,
    is_gst_with_zero,
    open,
    notesData,
    setNotesData,
    addButtonHandler,
    isClear,
    newItem,
    itemIndex,
    isFormSubmitted,
  } = props;
  const [data, setData] = React.useState(notesData.debit_note_items);

  const onAddLine = () => {
    notesData.debit_note_items.push({
      item: null,
      tax: null,
      rate: null,
      quantity: 1,
      tax_id: null,
      amount: null,
      item_id: null,
      unit_id: null,
      item_order: null,
      hsn_sac_code: null,
      expense_category: null,
      item_level_discount: 0,
      expense_category_id: null,
      is_item_discount_in_percentage: checkItemDiscount(notesData?.debit_note_items),
    });

    setData([...notesData.debit_note_items]);
  };

  const onDeleteLine = (index) => {
    if (notesData.debit_note_items.length > 1) {
      notesData.debit_note_items.splice(index, 1);
      setData([...notesData.debit_note_items]);
    } else {
      setData([
        {
          item: null,
          tax: null,
          rate: null,
          quantity: 1,
          tax_id: null,
          amount: null,
          item_id: null,
          unit_id: null,
          item_order: null,
          hsn_sac_code: null,
          item_level_discount: 0,
          is_item_discount_in_percentage: checkItemDiscount(notesData?.debit_note_items),
        },
      ]);
    }
  };

  const onClearLines = () => {
    setData([
      {
        item: null,
        tax: null,
        rate: null,
        quantity: 1,
        tax_id: null,
        amount: null,
        item_id: null,
        unit_id: null,
        item_order: null,
        hsn_sac_code: null,
        item_level_discount: 0,
        is_item_discount_in_percentage: checkItemDiscount(notesData?.debit_note_items),
      },
    ]);
  };

  const accounts = applyTaxOnMultipleGSTComponents(
    notesData,
    "debit_note_items",
    "input_"
  );

  const totalAmount = basePriceTotal(
    notesData,
    notesData.debit_note_items
  ).toFloat();

  const totalTax = Object.values(accounts)
    .reduce((acc, tax) => acc + tax, 0)
    .toFloat();

  const totalDiscount = totalDiscountOnBasePrice(
    notesData,
    notesData.debit_note_items
  ).toFloat();

  const subTotal = notesData.is_amount_tax_inclusive
    ? notesData.debit_note_items
      .filter((item) => isNumeric(item.amount))
      .reduce((a, b) => a + parseFloat(b.amount), 0)
      .toFloat()
    : totalAmount.toFloat();

  const finalPayment =
    totalAmount.toFloat() -
    totalDiscount.toFloat() +
    (notesData.has_rcm ? 0 : totalTax).toFloat();

  notesData.tax = {
    ...accounts,
    total: parseFloat(parseFloat(totalTax).toFixed(2)),
  };
  notesData.total_amount = (
    totalAmount -
    totalDiscount +
    (notesData.has_rcm ? 0 : totalTax)
  ).toFloat();
  notesData.discount = totalDiscount.toFloat();
  notesData.subtotal = totalAmount.toFloat();

  React.useEffect(() => {
    setNotesData({
      ...notesData,
      debit_note_items: data,
      total_amount: (totalAmount + (notesData.has_rcm ? 0 : totalTax) - totalDiscount).toFloat(),
      discount: totalDiscount,
      subtotal: totalAmount,
    });
    // eslint-disable-next-line
  }, [data]);

  const hasRCM = (has_rcm) => {
    notesData.has_rcm = has_rcm;
    notesData.total_amount = (
      totalAmount -
      totalDiscount +
      (notesData.has_rcm ? 0 : totalTax)
    ).toFloat();
    setNotesData({ ...notesData });

    onAmountsAreInChange({ name: "Exclusive", value: "exclusive" })
  };

  if (!("input_IGST" in notesData.tax)) {
    notesData.tax["input_IGST"] = 0;
  }
  if (!("input_SGST" in notesData.tax)) {
    notesData.tax["input_SGST"] = 0;
  }
  if (!("input_CGST" in notesData.tax)) {
    notesData.tax["input_CGST"] = 0;
  }

  const filterList = [
    { name: "Exclusive", value: "exclusive" },
    { name: "Inclusive", value: "inclusive" },
    { name: "No Tax", value: "no_tax" },
  ];

  const onAmountsAreInChange = (selected_type) => {
    let previous = notesData.tax_applied;
    if (previous !== selected_type.value) {
      if (selected_type.value === "inclusive") {
        notesData.is_amount_tax_inclusive = true;
        convertExclusiveToInclusive(notesData.debit_note_items);
        notesData.total_amount = totalAmount - totalDiscount;
      } else if (selected_type.value === "no_tax") {
        if (previous === filterList[1].value) {
          convertInclusiveToExclusive(notesData.debit_note_items);
        }
        // eslint-disable-next-line
        notesData.debit_note_items.map((bill_item) => {
          bill_item.tax = null;
          bill_item.tax_id = null;
        });
        notesData.is_amount_tax_inclusive = false;
        notesData.total_amount = totalAmount - totalDiscount;
      } else {
        notesData.is_amount_tax_inclusive = false;
        convertInclusiveToExclusive(notesData.debit_note_items);
      }
      setNotesData({ ...notesData, tax_applied: selected_type.value });
      setData([...notesData.debit_note_items]);
    }
  };

  const [isDiscountFiled, setIsDiscountFiled] = React.useState(getDiscountPreferences());
  React.useEffect(() => {
    if (notesData.discount) {
      setIsDiscountFiled(getDiscountPreferences() || (notesData.discount ? true : false))
    }
  }, [notesData.discount]);

  return (
    <div>
      <Box
        style={{ paddingRight: "20px" }}
        sx={{ pb: 2, pt: 2, display: "flex", justifyContent: "flex-end" }}
      >
        <Grid
          container
          spacing={0}
          style={{
            alignItems: "center",
            justifyItems: "center",
            alignContent: "space-evenly",
            justifyContent: "space-evenly",
          }}
        >
          <Grid item sm={6} xs={12} sx={{ display: { xs: '', sm: '-webkit-flex' }, justifyContent: "start" }}>
            {
              isBusinessRegistered() && (
                <FormControlLabel
                  id={"has_rcm_checkbox"}
                  dataTestId={"has_rcm_checkbox"}
                  labelPlacement="end"
                  control={
                    <Checkbox
                      sx={{ ml: 2 }}
                      checked={notesData.has_rcm}
                      onChange={(event) => {
                        hasRCM(event.target.checked);
                      }}
                    />
                  }
                  label={
                    <span
                      style={{
                        marginTop: "10px",
                        fontSize: "14px",
                        fontWeight: "700",
                        lineHeight: "19px",
                        letterSpacing: "0px",
                        fontFamily: "Noto Sans",
                      }}
                    >
                      This transaction is applicable for reverse charge
                    </span>
                  }
                />
              )
            }


          </Grid>
          <Grid item sm={6} xs={12} sx={{ display: { xs: '', sm: '-webkit-flex' }, justifyContent: "end" }}>
            <CommonCurrencySelection
              isCurrency={false}
              isAmountAre={true}
              data={notesData}
              setData={setNotesData}
              dropdown_key='vendor_id'
              isFormSubmitted={isFormSubmitted}
              onAmountsAreInChange={onAmountsAreInChange}
            />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ mb: 2 }}>
        <CustomTableContainer sx={{ overflowX: 'hidden' }} className="custom_table_css">
          <CustomTable>
            <ProductAndServicesDetailsHeaders isDiscountFiled={isDiscountFiled} data={notesData} setData={setNotesData} />
            <ProductAndServicesDetailsBody
              isDiscountFiled={isDiscountFiled}
              open={open}
              setData={setData}
              newItem={newItem}
              notesData={notesData}
              itemIndex={itemIndex}
              onDeleteLine={onDeleteLine}
              onClearLines={onClearLines}
              setNotesData={setNotesData}
              finalPayment={finalPayment}
              data={notesData.debit_note_items}
              isFormSubmitted={isFormSubmitted}
              addButtonHandler={addButtonHandler}
              is_gst_with_zero={is_gst_with_zero}
              NewAccountsResult={NewAccountsResult}
            />
          </CustomTable>
        </CustomTableContainer>
      </Box>

      <Grid
        container
        style={{
          paddingBottom: "24px",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
      >
        <Grid item xs={12} sm={6}>
          <Grid item xs={12}>
            <CustomTypography
              id={"add_action"}
              dataTestId={"add_action"}
              text={"+ Add Line"}
              onClick={onAddLine}
              sx={{
                fontSize: "12px",
                color: "#2464EB",
                cursor: "pointer",
                display: "inline",
                fontWeight: "700",
                lineHeight: "16px",
                fontStyle: "normal",
                textTransform: "none",
                fontFamily: "Noto Sans",
                ":hover": { textDecoration: "underline" },
              }}
            />
            <CustomTypography
              text={"|"}
              sx={{
                mr: 1,
                ml: 1,
                fontSize: "12px",
                color: "#B8B8B8",
                fontWeight: "700",
                lineHeight: "16px",
                fontStyle: "normal",
                display: "inline",
                textTransform: "none",
                fontFamily: "Noto Sans",
              }}
            />
            <CustomTypography
              id={"clear_action"}
              dataTestId={"clear_action"}
              text={"Clear All Lines"}
              onClick={onClearLines}
              sx={{
                fontSize: "12px",
                color: "#2464EB",
                cursor: "pointer",
                display: "inline",
                fontWeight: "700",
                lineHeight: "16px",
                fontStyle: "normal",
                textTransform: "none",
                fontFamily: "Noto Sans",
                ":hover": { textDecoration: "underline" },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={9} sx={{ mt: 3 }}>
            <Input
              id={"input_note"}
              dataTestId={"input_note"}
              item={{
                rows: 4,
                type: "text",
                fullWidth: true,
                required: true,
                multiline: true,
                title: "Notes",
                texttransform: "none",
                placeholder: "Enter Notes",
                value: notesData.note ? notesData.note : "",
                onChange: (event) => {
                  setNotesData({ ...notesData, note: event.target.value });
                },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={9} sx={{ mt: 4 }}>
            <TermsAndConditions
              title="Terms and Conditions"
              value={notesData.terms_and_conditions_id}
              setValue={(value) =>
                setNotesData({
                  ...notesData,
                  terms_and_conditions_id: value ? value.id : null,
                })
              }
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <CommonCalculation
            data={notesData}
            setData={setNotesData}
            isFormSubmitted={isFormSubmitted}

            accounts={accounts}
            subTotal={subTotal}
            totalTax={totalTax}
            totalAmount={totalAmount}
            finalPayment={finalPayment}
            totalDiscount={totalDiscount}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const ProductAndServicesDetailsHeaders = (props) => {
  const { isDiscountFiled, data, setData } = props;

  return (
    <CustomTableHead>
      <CustomTableRow>
        <CustomTableHeadCell style={{ width: 5 }} align="left">  <span>#</span></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 310 }} align="left">  <span>Goods/Service</span></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 275 }} align="left">  <span>Account</span></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 185 }} align="left"> <Common.QuantityLabelComponent data={data} setData={setData} items='debit_note_items' /></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 175 }} align="left"> <Common.RateLabelComponent data={data} setData={setData} items='debit_note_items' /></CustomTableHeadCell>

        {isDiscountFiled &&
          <CustomTableHeadCell style={{ width: 100 }} align="left">  <Common.DiscountLabelComponent data={data} setData={setData} items='debit_note_items' /></CustomTableHeadCell>
        }

        <CustomTableHeadCell style={{ width: 200 }} align="left">
          <span>
            Tax{" "}
            {data.has_rcm ? (
              <span style={{ fontSize: "10px", opacity: 0.6 }}>
                ( REVERSE CHARGE )
              </span>
            ) : (
              ""
            )}
          </span>
        </CustomTableHeadCell>

        <CustomTableHeadCell style={{ width: 145 }} align="left">  <span>Tax Amount</span></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 145 }} align="left">  <span>Amount</span></CustomTableHeadCell>

        <CustomTableHeadCell style={{ width: 5 }} align="right" />
      </CustomTableRow>
    </CustomTableHead>
  );
};

const ProductAndServicesDetailsBody = (props) => {
  const {
    isDiscountFiled,
    is_gst_with_zero,
    open,
    newItem,
    itemIndex,
    data,
    onDeleteLine,
    setData,
    addButtonHandler,
    notesData,
    NewAccountsResult,
    setNotesData,
    isFormSubmitted,
    finalPayment,
  } = props;

  let location = useLocation();
  const params = getQueryParams(location.search);
  const dispatch = Actions.getDispatch(useContext);
  const [taxResult, setTaxResult] = React.useState([]);
  let taxApplied = notesData.tax_applied;
  const source_of_supply_id = notesData.source_of_supply_id;
  const taxList = filterTax(taxResult, source_of_supply_id, is_gst_with_zero);

  React.useEffect(() => {
    const apiResults = async () => {
      let res = await apiAction({
        method: "post",
        url: get_list_of_tax(),
        data: {
          business_id: getBusinessInfo().id,
        },
      });
      if (res?.success) {
        setTaxResult(res?.result);
      } else {
      }
    };
    apiResults();
    // eslint-disable-next-line
  }, []);

  const apiItemResults = async (id, index) => {
    let res = await apiAction({
      method: "post",
      url: get_item(id),
      data: { business_id: getBusinessInfo().id },
    });
    if (res?.success) {
      onItemSelect(res?.result, index);
    } else {
    }
  };

  const onItemSelect = (value, index) => {
    const getDefaultTax = () => {
      return findDefaultTax(taxList, false, value)
    };

    const getItemData = () => ({
      item: value,
      item_order: null,
      unit_id: value.unit?.id,
      quantity: value.quantity || 1,
      description: value.description,
      hsn_sac_code: value.hsn_sac_code,
      item_id: value.item_id || value.id,
      amount: value.amount || value.cost_price,
      rate: parseFloat(value.rate || value.cost_price),
      item_level_discount: value.item_level_discount || 0,
      is_item_discount_in_percentage: checkItemDiscount(notesData?.debit_note_items),

      gst_rate:getDefaultTax()?.rate,
      tax: taxApplied === "no_tax" ? null : getDefaultTax(),
      tax_id: taxApplied === "no_tax" ? null : getDefaultTax()?.id,

      expense_category: value.purchase_account || value.expense_category,
      expense_category_id: value.purchase_account?.id || value.expense_category_id,
    });

    const updateData = () => {
      data[index] = value ? getItemData() : {
        tax: null,
        item: null,
        rate: null,
        quantity: 1,
        amount: null,
        tax_id: null,
        item_id: null,
        unit_id: null,
        item_order: null,
        expense_category: null,
        item_level_discount: 0,
        expense_category_id: null,
        is_item_discount_in_percentage: checkItemDiscount(notesData?.debit_note_items),
      };
    };

    const isAmountRangeExceeded = () => isFinalAmountRangeCrossed({
      quantity: data[index]?.quantity,
      rate: data[index]?.rate,
      item_level_discount: data[index].item_level_discount,
      item: data[index],
      invoice: notesData,
      finalPayment: data.length <= 1
        ? 0
        : finalPayment - (data[index]?.amount || 0) - (data[index]?.tax_amount || 0),
    });
    if (isAmountRangeExceeded()) {
      stateChangeManager(
        dispatch,
        Actions,
        true,
        "error",
        "Amount range max limit reached"
      );
    } else {
      updateData();
      setData([...data]);
      setNotesData({ ...notesData, debit_note_items: [...data] });
    }
  };

  React.useEffect(() => {

    const getDefaultTax = (value) => {
      return findDefaultTax(taxList, false, value);
    };

    const updatedData = data.map((item) => {
      const defaultTax = getDefaultTax(item);
      const isDefaultSelectTax = item.tax_id;

      // Calculate values based on conditions
      const gstRate = isDefaultSelectTax ? defaultTax?.rate ?? null : null;
      const tax = isDefaultSelectTax ? (taxApplied === "no_tax" ? null : defaultTax) : null;
      const taxId = isDefaultSelectTax ? (taxApplied === "no_tax" ? null : defaultTax?.id ?? null) : null;

      return {
        ...item,
        tax: tax,
        tax_id: taxId,
        gst_rate: gstRate,
      }
    });

    setData(updatedData);
    setNotesData((prev) => ({
      ...prev,
      debit_note_items: updatedData
    }));

    // eslint-disable-next-line
  }, [is_gst_with_zero, source_of_supply_id, taxApplied]);

  return (
    <CustomTableBody>
      {notesData.debit_note_items.map((item, index) => {
        item['item_order'] = index + 1;

        return (
          <CustomTableRow key={index}>

            <CustomTableBodyCell sx={{}} align="left">
              <span>{index + 1}</span>
            </CustomTableBodyCell>

            <CustomTableBodyCell sx={{}} align="left">
              <CommonAsyncDropdown
                id={"item_dropdown_" + index}
                dataTestId={"item_dropdown_" + index}
                optionLabel="name"
                newItem={newItem}
                autoSelect={false}
                itemIndex={itemIndex}
                disableClearable={false}
                noOptionsText={"No result found"}
                placeholder="Select Goods/Service"
                validation={isFormSubmitted && !data[index].item_id}
                item={{
                  open: open,
                  method: "post",
                  url: list_items(1),
                  value: data[index].item,
                  onChange: (event, value) => {
                    console.log("value", value);
                    if (value) {
                      data[index].item = value.name;
                      setData([...data]);
                      apiItemResults(value.id, index);
                    } else {
                      onItemSelect(value, index);
                    }
                  },
                  body: {
                    role: "vendor",
                    business_id: getBusinessInfo().id,
                    for_purchase: true,
                  },
                }}
                addButton={{
                  title: "+ Add an item",
                  onClick: () =>
                    addButtonHandler(
                      "Add item",
                      "new_pas",
                      "lg",
                      "product",
                      index
                    ),
                }}
              />
            </CustomTableBodyCell>

            <CustomTableBodyCell sx={{}} align="left">
              <div>
                <CustomTypography
                  text={''}
                  sx={{
                    mb: 1,
                    fontSize: '14px',
                    color: '#000000',
                    fontWeight: '700',
                    lineHeight: '18px',
                    fontStyle: 'normal',
                    textTransform: "none",
                    fontFamily: "Noto Sans",
                  }}
                />
                <AsyncDropdown
                  sx={{}}
                  fullWidth={true}
                  autoFocus={false}
                  disabledCloseIcon={false}
                  isGroupHeaderSticky={true}
                  newResults={NewAccountsResult}
                  id={'expense_category_dropdown_' + index}
                  dataTestId={'expense_category_dropdown_' + index}
                  validation={isFormSubmitted && !data[index]?.expense_category_id}

                  selectedValue={data[index]?.expense_category}
                  setSelectedValue={(value) => {
                    if (value) {
                      data[index].expense_category = value;
                      data[index].expense_category_id = value.id;
                    } else {
                      data[index].expense_category = null;
                      data[index].expense_category_id = null;
                    }
                    setData([...data]);
                  }}

                  valueLabelKey='id'
                  uniqueOptionKey='id'
                  searchKey='account_name'
                  optionLabelKey='account_name'
                  placeholder='Select the Account'
                  playLoad={{ account_type: ['EXPENSE'] }}
                  optionGroupLabelKey='account_type'
                  URL={get_chart_of_account_nested_nodes(1)}

                  addButton={{
                    title: '+ Add accounts',
                    onClick: () => addButtonHandler('Add accounts', 'create_sub_account', 'sm', "EXPENSE", index),
                  }}
                />
              </div>
            </CustomTableBodyCell>

            <CustomTableBodyCell sx={{}} align="left">
              <Common.QuantityInputComponent
                index={index}
                data={data}
                setData={setData}
                quantityData={notesData}
                finalPayment={finalPayment}
                isFormSubmitted={isFormSubmitted}
              />
            </CustomTableBodyCell>

            <CustomTableBodyCell sx={{}} align="left">
              <Common.RateInputComponent
                index={index}
                data={data}
                setData={setData}
                rateData={notesData}
                finalPayment={finalPayment}
                isFormSubmitted={isFormSubmitted}
              />
            </CustomTableBodyCell>

            {isDiscountFiled &&
              <CustomTableBodyCell sx={{}} align="left">
                <Common.DiscountInputComponent
                  index={index}
                  data={data}
                  setData={setData}
                  discountData={notesData}
                  finalPayment={finalPayment}
                />

              </CustomTableBodyCell>
            }

            <CustomTableBodyCell sx={{}} align="left">
              <TaxList
                id={"tax_dropdown_" + index}
                dataTestId={"tax_dropdown_" + index}
                result={taxList}
                value={data[index]?.tax}
                disableClearable={false}
                inputDisabled={ notesData.tax_applied === "no_tax"}
                validation={
                  isFormSubmitted &&
                  notesData.tax_applied !== "no_tax" &&
                  !data[index]?.tax_id
                }
                setValue={(tax) => {
                  if (
                    isFinalAmountRangeCrossed({
                      quantity: data[index]?.quantity,
                      rate: data[index]?.rate,
                      item_level_discount: data[index].item_level_discount,
                      item: { ...data[index], tax: tax },
                      invoice: notesData,
                      finalPayment:
                        data.length <= 1
                          ? 0
                          : finalPayment -
                          (data[index]?.amount ? data[index]?.amount : 0) -
                          (data[index]?.tax_amount
                            ? data[index]?.tax_amount
                            : 0),
                    })
                  ) {
                    stateChangeManager(
                      dispatch,
                      Actions,
                      true,
                      "error",
                      "Amount range max limit reached"
                    );
                  } else {
                    if (tax) {
                      data[index].tax = tax;
                      data[index].tax_id = tax.id;
                      data[index].gst_rate = tax.rate;
                    } else {
                      data[index].tax = null;
                      data[index].tax_id = null;
                      data[index].tax_amount = null;
                    }
                    setData([...data]);
                  }
                }}
              />
            </CustomTableBodyCell>


            <CustomTableBodyCell sx={{}} align="left">
              <Input
                id={"input_tax_amount_" + index}
                dataTestId={"input_tax_amount_" + index}
                editable={false}
                is_currency_symbol={true}
                isShowCommaSeparatedValue={true}
                currency_code={notesData.currency_code}
                item={{
                  type: "number",
                  disabled: true,
                  fullWidth: true,
                  required: true,
                  placeholder: "",
                  value:
                    data[index]?.tax_amount && notesData.tax_applied !== "no_tax"
                      ? data[index]?.tax_amount
                      : "",
                }}
              />
            </CustomTableBodyCell>

            <CustomTableBodyCell sx={{}} align="left">
              <Input
                id={"input_amount_" + index}
                dataTestId={"input_amount_" + index}
                editable={false}
                is_currency_symbol={true}
                isShowCommaSeparatedValue={true}
                currency_code={notesData.currency_code}
                item={{
                  disabled: true,
                  type: "number",
                  fullWidth: true,
                  required: true,
                  placeholder: "",
                  value: data[index]?.amount ? data[index]?.amount : "",
                }}
              />
            </CustomTableBodyCell>

            <CustomTableBodyCell sx={{}} align="right">
              <IconButton
                onClick={() => onDeleteLine(index)}
                id={"delete_icon_" + index}
                dataTestId={"delete_icon_" + index}
              >
                <DeleteIcon />
              </IconButton>
            </CustomTableBodyCell>

          </CustomTableRow>
        );
      })}
    </CustomTableBody>
  );
};

const Buttons = (props) => {
  const {
    is_Location,
    onSave,
    onCancel,
    onClear,
    notesData,
    setFormSubmitted,
  } = props;
  let location = useLocation();
  const params = getQueryParams(location.search);

  const { tax_applied, debit_note_items, vendor_emails } = notesData;
  const { to } = vendor_emails;

  const is_rate = debit_note_items.find((item) => item.rate === null);
  const is_quantity = debit_note_items.find((item) => !item.quantity);
  const is_tax = debit_note_items.find(
    (item) => tax_applied !== "no_tax" && !item.tax_id
  );
  const is_credit_note_item_not_selected = debit_note_items.find(
    (item) => !item.item_id
  );
  const is_expense_category_not_selected = debit_note_items.find(
    (item) => !item.expense_category_id
  );

  let validation_data = [
    {
      key: "",
      validation: notesData.debit_note_upper_limit < notesData.total_amount,
      message: `Something's not quite right!`,
    },
    {
      key: "",
      validation:
        notesData.applied_as_payment_amount + notesData.refunded_amount >
        notesData.total_amount,
      message: `Something's not quite right!`,
    },
    { key: "vendor_id", message: "Please Select Vendor" },
    { key: "against_bill_id", message: "Please Select Bill Number" },
    { key: "debit_note_number", message: "Please Enter Debit Note Number" },
    // {
    //   key: "emails",
    //   validation: to.length === 0,
    //   message: "Please Enter Valid Vendor Email",
    // },
    {
      key: "against_bill_type",
      message: "Please Select GST Registration Type",
    },
    !is_Location && {
      key: "source_of_supply_id",
      message: "Please Select Source of Supply",
    },
    { key: "debit_note_date", message: "Please Enter Valid Debit Note Date" },
    { key: "reason", message: "Please Select Reason" },
    {
      key: "",
      validation: is_credit_note_item_not_selected,
      message: "Please Select Item",
    },
    {
      key: "",
      validation: is_expense_category_not_selected,
      message: "Please Select Account",
    },
    { key: "", validation: is_quantity, message: "Please Enter the Quantity" },
    { key: "", validation: is_rate, message: "Please Enter the Rate" },
    { key: "", validation: is_tax, message: "Please Select the Tax" },
  ];

  const printPreviewEvent = () => {
    AnalyticsEvent(eventsNames.categories.CREDIT_NOTES, { action: eventsNames.actions.PREVIEW })
  };

  const clearActionDisabled =
    Object.keys(params).length === 0 || params.clone_id;

  return (
    <Box component="form" noValidate>
      <Grid container>
        <Grid item xs={12} sm={4} sx={{ display: "-webkit-flex", justifyContent: "start" }}>
          {clearActionDisabled && (
            <CustomButton
              id={"clear_btn"}
              dataTestId={"clear_btn"}
              variant="outlined"
              sx={{
                textTransform: "none",
                "&:hover": { backgroundColor: "#e8f3ff" },
              }}
              btnLabel="Clear"
              onClick={() => {
                onClear();
              }}
            />
          )}
          <CustomButton
            id={"cancel_btn"}
            dataTestId={"cancel_btn"}
            variant="contained"
            sx={{ ml: 1, textTransform: "none" }}
            btnLabel="Cancel"
            onClick={() => {
              onCancel();
            }}
          />
        </Grid>
        <Grid item xs={12} sm={8} sx={{ display: "-webkit-flex", justifyContent: "end" }}>
          <PrintAndPreview
            apiUrl={export_debit_note()}
            data={{ ...notesData, export_type: "pdf" }}
            validation_data={validation_data}
            setFormSubmitted={() => {
              setFormSubmitted(true);
              printPreviewEvent();
            }}
          />
          <CustomButton
            id={"save_as_draft_btn"}
            dataTestId={"save_as_draft_btn"}
            variant="outlined"
            sx={{
              mr: 1,
              textTransform: "none",
              "&:hover": { backgroundColor: "#e8f3ff" },
            }}
            btnLabel={params.id ? "Update as Draft" : "Save as Draft"}
            onClick={() => {
              onSave({ is_draft: true, is_save_send: false });
            }}
          />
          <CustomGroupButton
            options={[
              {
                id: "save_send_btn",
                dataTestId: "save_send_btn",
                label: params.id ? "Update and Send" : "Save and Send",
                condition: () => {
                  onSave({ is_draft: false, is_save_send: true });
                },
              },
              {
                id: "save_send_later_btn",
                dataTestId: "save_send_later_btn",
                label: params.id
                  ? "Update and Send Later"
                  : "Save and Send Later",
                condition: () => {
                  onSave({ is_draft: false, is_save_send: false });
                },
              },
            ]}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
