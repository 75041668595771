import * as React from 'react';
import { apiAction } from '../../../api/api';
import { useNavigate } from 'react-router-dom';
import * as Actions from '../../../state/Actions';
import CustomTypography from '../typography/CustomTypography';
import { getUniqueObjects, findIndexAndCompare } from '../../pages/home/insight/common/Helper';

import {
    Box,
    Chip,
    Stack,
    styled,
    TextField,
    IconButton,
    Autocomplete,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getBusinessInfo } from '../../../config/cookiesInfo';


export default function AllowDuplicateMultipleValueDropdown(props) {
    const {
        url,
        method = 'get',
        apiPayloadData = null,
        setValue,
        addButton,
        value = [],
        isChildren,
        isLastGroup,
        validation,
        defaultValue,
        results = [],
        listboxStyle = {},
        matchValueKey = '',
        optionLabel = '',
        groupByLabel = '',
        minInputBoxHeight = '',
        placeholder='Select Data Points',
        id = 'dropdown_with_multiple_value',
        dataTestId = 'dropdown_with_multiple_value',
    } = props;

    let navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);
    const [options, setOptions] = React.useState(Array.isArray(results) ? results : [])

    const [nextUrl, setNextUrl] = React.useState(url);
    const [hasMore, setHasMore] = React.useState(false);
    const [searchText, setSearchText] = React.useState();

    const fetchOptions = async (previousOptions, url) => {
        if (url) {
            let res = await apiAction({ url: url, method: method, data: apiPayloadData, navigate: navigate, dispatch: dispatch, })
            if (res?.success) {
                if (res?.result.next) {
                    if (searchText) {
                        setNextUrl(`${res?.result.next}&business_id=${getBusinessInfo().id}&name=${searchText}&sort_by=data_point_type`);
                    } else {
                        setNextUrl(`${res?.result.next}&business_id=${getBusinessInfo().id}&sort_by=data_point_type`);
                    }
                } else {
                    setNextUrl(null);
                }
                setHasMore(!!res?.result.next);
                setOptions(getUniqueObjects([...previousOptions, ...res?.result.result]));
            }
        }
    }
    const onChangeCaptureHandler = (text) => {
        setSearchText(text);
    }
    React.useEffect(() => {
        if (url && searchText) {
            fetchOptions(options, `${url}&name=${searchText}`);
        }
    }, [searchText])


    React.useEffect(() => {
        if (results) { setOptions(results); }
        setTimeout(() => {
            if (url && results) { fetchOptions(results, url) };
        }, 0);
    }, [url, results])

    const onChangeHandler = (event, values) => {
        setValue(values);
        setSearchText(null);
    }

    const getSelectValues = () => {
        const valueSet = new Set(value.map(o => o[matchValueKey]));
        return options.filter(o => valueSet.has(o[matchValueKey]));
    }


    const GroupHeader = styled('div')(({ theme }) => ({
        top: '-8px',
        fontWeight: 700,
        color: '#141414',
        marginTop: '-8px',
        fontSize: '12px',
        lineHeight: '16px',
        position: 'sticky',
        fontStyle: 'normal',
        padding: '4px 10px',
        background: '#FFFFFF',
        fontFamily: 'Noto Sans',
    }));

    const GroupItems = styled('ul')({
        padding: 0,
        fontWeight: 400,
        fontSize: '12px',
        color: '#141414',
        lineHeight: '16px',
        fontStyle: 'normal',
        background: '#FFFFFF',
        fontFamily: 'Noto Sans',
    });

    const NoOptionsComponentCustom = () => {
        return (
            <CustomTypography
                text={`No results found`}
                sx={{
                    mb: 2,
                    color: '#141414',
                    fontWeight: 500,
                    fontSize: '12px',
                    lineHeight: '16px',
                    fontStyle: 'normal',
                    fontFamily: 'Noto Sans',
                }}
            />
        )
    }

    return (
        <Stack spacing={3} sx={{ width: '100%' }}>
            <Autocomplete
                multiple
                limitTags={10}
                includeInputInList

                id="tags-outlined"
                options={getUniqueObjects([...options])}
                onHighlightChange={(event, value) => { if (nextUrl && findIndexAndCompare(options, value)) { fetchOptions(options, nextUrl) } }}

                disableClearable={true}
                onChange={onChangeHandler}
                popupIcon={<ExpandMoreIcon />}
                noOptionsText={<NoOptionsComponentCustom />}
                getOptionLabel={(option) => option[optionLabel]}

                value={defaultValue ? [...defaultValue] : [...getSelectValues()]}
                defaultValue={defaultValue ? [...defaultValue] : [...getSelectValues()]}


                inputValue={searchText ? searchText : ''}
                renderInput={params => {
                    const { InputProps, ...restParams } = params;
                    const { startAdornment, ...restInputProps } = InputProps;
                    return (
                        <TextField
                            {...restParams}
                            size='small'
                            validation={validation}
                            placeholder={placeholder}
                            onChange={(event) => { onChangeCaptureHandler(event.target.value) }}
                            sx={{ "& input::placeholder": { color: '#2F2F2E', fontWeight: '400', lineHeight: '16px', fontSize: '12px', fontStyle: 'normal', fontFamily: 'Noto Sans', opacity: 0.50, } }}
                            InputProps={{
                                ...restInputProps,
                                style: { color: '#000000', fontWeight: '700', lineHeight: '16px', fontSize: '12px', fontStyle: 'normal', fontFamily: 'Noto Sans', },
                                startAdornment: (
                                    <div style={{
                                        maxHeight: 60,
                                        overflowY: 'auto',
                                        cursor: 'auto'
                                    }}
                                        className='display_scroll_bar'
                                    >
                                        {startAdornment}
                                    </div>
                                ),
                            }}
                        />
                    );
                }}

                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip
                            size="small"
                            variant="outlined"
                            sx={{
                                borderRadius: 1,
                                cursor: 'pointer',
                                bgcolor: '#f5f5f5',
                                ":hover": { bgcolor: '#fafafa' }
                            }}
                            deleteIcon={<IconButton size='small' sx={{ "&:hover": { color: '#ef5350' } }}><CloseIcon sx={{}} fontSize='inherit' /></IconButton>}
                            label={<span style={{ fontSize: '12px' }}>{option[optionLabel]}</span>}
                            {...getTagProps({ index })}
                        />
                    ))
                }

                groupBy={(option) => option[groupByLabel]}
                renderGroup={(params) => {
                    return (
                        <li key={params.key} style={{
                            margin: '0px',
                            fontWeight: 400,
                            fontSize: '12px',
                            color: '#141414',
                            lineHeight: '16px',
                            fontStyle: 'normal',
                            background: '#FFFFFF',
                            fontFamily: 'Noto Sans',
                        }}>
                            <GroupHeader>{params.group}</GroupHeader>
                            <GroupItems>{params.children}</GroupItems>
                        </li>
                    )
                }}

                ListboxProps={{
                    style: { ...listboxStyle },
                    onScroll: (event) => {
                        const listboxNode = event.currentTarget;
                        if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight && hasMore) {
                            fetchOptions(options, nextUrl);
                        } else if (listboxNode.scrollTop === 0 && hasMore) {
                            fetchOptions(options, nextUrl);

                        }
                    }
                }}
            />

        </Stack>
    );
}
