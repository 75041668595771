import React from 'react';
import Status from '../../../../common/Status';
import HTMLViewer from '../../../../common/HTMLViewer';
import * as Actions from '../../../../../state/Actions';
import CustomTitle from '../../../../common/CustomTitle';
import CustomTabs from '../../../../custom/tabs/CustomTabs';
import { useLocation, useNavigate } from 'react-router-dom';
import { routesName } from '../../../../../config/routesName';
import { CommonWebSocket } from '../../../../../api/webSocket';
import { expense_number } from '../../../../../utils/Constant';
import CustomDialog from '../../../../custom/dialog/CustomDialog';
import { getBusinessInfo } from '../../../../../config/cookiesInfo';
import CurrencyFormatter from '../../../../common/CurrencyFormatter';
import AuditTrailHistory from '../../../../common/AuditTrailHistory';
import ViewPageActionButtons from '../../../../common/ViewPageActionButtons';
import CustomTypography from '../../../../custom/typography/CustomTypography';
import { eventsNames,AnalyticsEvent } from '../../../../../firebase/firebaseAnalytics';
import CommonJournalEntryTransactionView from '../../common/CommonJournalEntryTransactionView';

import {
  CustomContainer,
  CustomTitleContainer,
} from '../../../../custom/container/CustomContainer';

import {
  setLoader,
  getQueryParams,
  stateChangeManager,
  breadCrumbsStateChangeManager,
} from '../../../../../utils/Utils';

import {
  apiAction,
  apiBlobResponse,
  apiHandleDownload,
} from '../../../../../api/api';

import {
  delete_expense,
  export_expense,
  retrieve_expense,
} from '../../../../../api/urls';

import {
  CustomTable,
  CustomTableRow,
  CustomTableBody,
  CustomTableHead,
  CustomTableHeadCell,
  CustomTableBodyCell,
  CustomTableContainer,
} from '../../../../custom/table/CustomTable';

import {
  Box,
  Grid,
  Collapse,
} from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';





const ExpenseViewpage = () => {
  let navigate = useNavigate();
  let location = useLocation();
  const [tab, setTab] = React.useState(0);
  const params = getQueryParams(location.search);
  const dispatch = Actions.getDispatch(React.useContext);

  const [expenseResults, setExpenseResults] = React.useState();

  const [state, setState] = React.useState({
    id: '',
    title: '',
    open: false,
    condition: '',
    maxWidth: 'lg',
    fullWidth: true,
  })

  const viewJournalEntryHandler = (id) => {
    setState({
      id: id,
      open: true,
      maxWidth: 'sm',
      fullWidth: true,
      condition: 'view',
      title: 'View Journal Entry',
    })
  }
  const stateChangeHandler = (title, condition, maxWidth, url, deleteMessage) => {
    setState({
      url: url,
      open: true,
      title: title,
      fullWidth: true,
      maxWidth: maxWidth,
      condition: condition,
      deleteMessage: deleteMessage,
      navigate: () => navigate(routesName.invoicingExpense.path),
    })
  }

  const webSocketResponse = CommonWebSocket();
  React.useEffect(() => {
    if (webSocketResponse) {
      let webSocketData = JSON.parse(webSocketResponse.data)
      if (webSocketData.document_type === "expense") {

        if (webSocketData.event === "delete_document" && expenseResults.id === webSocketData.object_id) {
          if (webSocketData.event_success) {
            stateChangeManager(dispatch, Actions, true, "success", `Expense ${expense_number}-${webSocketData.object_id} deleted successfully`);
            navigate(routesName.invoicingExpense.path);
          } else {
            stateChangeManager(dispatch, Actions, true, "error", `Failed to delete Expense ${expense_number}-${webSocketData.object_id}`);
          }
        }

        if (webSocketData.event === "update_document" && expenseResults.id === webSocketData.object_id) {
          if (webSocketData.event_success) {
            stateChangeManager(dispatch, Actions, true, "success", `Expense ${expense_number}-${webSocketData.object_id} updated successfully`);
            getExpense();
          } else {
            stateChangeManager(dispatch, Actions, true, "error", `Failed to update Expense ${expense_number}-${webSocketData.object_id}`);
          }
        }
      }
    }
  }, [webSocketResponse])

  React.useEffect(() => {
    if (params.id) {
      getExpense()
    }
  }, []);

  const getExpense = async () => {
    setLoader(dispatch, Actions, true)
    let res = await apiAction({
      method: 'post',
      url: retrieve_expense(params.id),
      data: { business_id: getBusinessInfo().id },
    })
    if (res?.success) {
      setExpenseResults(res?.result);
      setLoader(dispatch, Actions, false);
      breadCrumbsStateChangeManager(dispatch, `${expense_number}-${res?.result.id}`)

    } else {
      setLoader(dispatch, Actions, false)
    }
  }

  const print = async (data) => {
    setLoader(dispatch, Actions, true);
    if (data) {
      apiBlobResponse({
        url: export_expense(), data: { ...data, expense_id: data.id, source_of_supply_id: data.source_of_supply && data.source_of_supply.id }, onSuccess: () => {
          setLoader(dispatch, Actions, false);
        }
      })
    }
  }
  const download = async (data) => {
    setLoader(dispatch, Actions, true);
    if (data) {
      apiHandleDownload({
        url: export_expense(), data: { ...data, expense_id: data.id, source_of_supply_id: data.source_of_supply && data.source_of_supply.id }, filename: `${expense_number}-${data.id}`, onSuccess: () => {
          setLoader(dispatch, Actions, false)
        }
      })
    }
  }

  const onSelectAction = (action, item) => {
    setLoader(dispatch, Actions, true)
    if (action === "create") {
      setLoader(dispatch, Actions, false)
      navigate(routesName.invoicingExpenseAdd.path)
      AnalyticsEvent(eventsNames.categories.EXPENSES,{action:eventsNames.actions.NEW})
    }
    if (action === "clone") {
      navigate(routesName.invoicingExpenseAdd.path + "?clone_id=" + item.id)
      AnalyticsEvent(eventsNames.categories.EXPENSES,{action:eventsNames.actions.CLONE})
    }
    if (action === "edit") {
      navigate(routesName.invoicingExpenseAdd.path + "?id=" + item.id)
    }
    if (action === "print") {
      print(item)
      AnalyticsEvent(eventsNames.categories.EXPENSES,{action:eventsNames.actions.PRINT})
    }
    if (action === "download") {
      download(item)
      AnalyticsEvent(eventsNames.categories.EXPENSES,{action:eventsNames.actions.DOWNLOAD})
    }
    if (action === "convert") {
      navigate(routesName.invoicingSalesInvoiceAdd.path + "?expense_id=" + item.id)
      AnalyticsEvent(eventsNames.categories.EXPENSES,{action:eventsNames.actions.expense.action.CONVERT_INVOICE})
    }

  }

  const toActionText = (status) => {
    let text
    if (status === 'unbilled') {
      text = 'Convert to Invoice'
    } else if (status !== 'unbilled') {
      text = 'Record New Expense'
    }
    return text
  }

  const onClickActionText = (status, item) => {
    if (status === 'unbilled') {
      onSelectAction("convert", item);
    } else if (status !== 'unbilled') {
      onSelectAction("create", item);
    }
  }
  const tabs = [
    { name: 'View', condition: 'preview' },
  ]
  return (
    <div>
      {
        expenseResults &&
        <div>
          <CustomDialog
            state={state}
            setState={setState}
          />
          <CustomTitleContainer>
            <Grid container spacing={0} style={{
              alignItems: 'center',
              justifyItems: 'center',
              alignContent: 'space-evenly',
              justifyContent: 'space-evenly',
            }}>
              <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'start', alignItems: 'center' }}>
                <CustomTitle title={'Expense'} />
                <Status status={expenseResults.status} sx={{ ml: 1, }} />
              </Grid>
              <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'end', }}>
                <ViewPageActionButtons
                  isEditable={expenseResults.status !== 'invoiced'}
                  edit={() => onSelectAction("edit", expenseResults)}
                  print={() => onSelectAction("print", expenseResults)}
                  download={() => onSelectAction("download", expenseResults)}

                  actionsList={
                    [
                      { name: 'Clone', onClick: () => onSelectAction('clone', expenseResults) },
                      { name: 'Make Recurring', onClick: () => { navigate(routesName.invoicingRecurringExpenseAdd.path + '?mark_id=' + expenseResults.id);AnalyticsEvent(eventsNames.categories.EXPENSES,{action:eventsNames.actions.MAKE_RECURRING}) } },
                      { name: 'Delete', showDivider: true, onClick: () => { stateChangeHandler('Delete Expense', 'delete', 'sm', delete_expense(expenseResults.id), `The expense record will be deleted and can not be retrieved later.\n Are you sure about deleting it?`);AnalyticsEvent(eventsNames.categories.EXPENSES,{action:eventsNames.actions.DELETE}) } }
                    ]
                  }
                />
                <AuditTrailHistory
                  data={{ object_id: expenseResults.id, object_type: 'expense' }}
                  AnalyticsEvent={()=>{ AnalyticsEvent(eventsNames.categories.EXPENSES,{action:eventsNames.actions.HISTORY})}}

                />
              </Grid>
            </Grid>
          </CustomTitleContainer>


          <CustomContainer maxWidth={"400"} sx={{ height: 'calc(100vh - 134px)', }}>
            <Box sx={{
              p: 2,
              border: '1px solid #FFF0C0', backgroundColor: "#FFF7DC"
            }}>
              <Grid container sx={{ mb: 1 }}>
                <Grid item xs={12} sm={6} sx={{}}>
                  <CustomTypography
                    text={<span>Action:</span>}
                    sx={{
                      mb: 2,
                      color: '#7A7A7A',
                      fontSize: '14px',
                      fontWeight: '600',
                      lineHeight: '19px',
                      fontStyle: 'normal',
                      textTransform: 'none',
                      fontFamily: "Noto Sans",
                    }}
                  />
                  <Box sx={{ display: 'flex' }}>
                    <CustomTypography
                      text={toActionText(expenseResults.status)}
                      sx={{
                        color: '#2464EB',
                        fontSize: '14px',
                        cursor: 'pointer',
                        lineHeight: '0px',
                        display: 'inline',
                        fontWeight: '700',
                        fontStyle: 'normal',
                        textAlign: 'center',
                        fontFamily: "Noto Sans",
                        ":hover": { textDecoration: 'underline' }
                      }}
                      onClick={() => onClickActionText(expenseResults.status, expenseResults)}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Box sx={{}}>
              <CustomTabs
                tabs={tabs}
                value={tab}
                onChange={(event, tab) => { setTab(tab) }}
              />

              {tabs[tab].condition === 'preview' && <Preview basicInformation={expenseResults} dispatch={dispatch} />}

            </Box>

          </CustomContainer>
        </div>
      }
    </div>
  )
}

export default ExpenseViewpage;

const Preview = (props) => {

  const { basicInformation, dispatch } = props;
  const [previewData, setPreviewData] = React.useState();

  React.useEffect(() => {
    const getCustomerInvoice = async (data) => {
      setLoader(dispatch, Actions, true)
      let res = await apiAction({
        method: 'post',
        url: export_expense(data.id),
        data: { ...data, expense_id: data.id, source_of_supply_id: data.source_of_supply && data.source_of_supply.id, export_type: 'html' },
      })
      if (res?.success) {
        setPreviewData(res?.result);
        setLoader(dispatch, Actions, false);
      } else {
        setLoader(dispatch, Actions, false);
      }
    }
    if (basicInformation) {
      getCustomerInvoice(basicInformation)
    }
  }, [basicInformation]);

  return (
    previewData &&
    <React.Fragment>
      {
        basicInformation.has_rcm &&
        <Box sx={{ pl: 6, pr: 6, pt: 2, }}>
          <ReverseChargeSummaryScreen
            data={[
              { name: 'Input CGST', amount: basicInformation.tax.input_CGST },
              { name: 'Input SGST', amount: basicInformation.tax.input_SGST },
              { name: 'Input IGST', amount: basicInformation.tax.input_IGST },
            ]}
            total={basicInformation.tax.total}
            currency_code={basicInformation.currency_code}
          />
        </Box>

      }
      <Box sx={{ pt: 2 }}>
        <HTMLViewer html_string={previewData.html_string} />
      </Box>
      <CommonJournalEntryTransactionView id={props.basicInformation.journal_entry} />

    </React.Fragment>
  )
}

const ReverseChargeSummaryScreen = (props) => {
  const { data, currency_code, total } = props;
  const [open, setOpen] = React.useState(false);
  const handleChange = () => {
    setOpen((prev) => !prev);
  };
  return (
    <React.Fragment>
      <CustomTableContainer sx={{ mt: 2 }}>
        <Box sx={{ display: 'flex', }}>
          <CustomTypography
            text={<span onClick={() => handleChange()}>Reverse Charge Summary</span>}
            sx={{
              color: '#000000',
              fontSize: '14px',
              cursor: 'pointer',
              fontWeight: '700',
              lineHeight: '18px',
              fontStyle: 'normal',
              fontFamily: "Noto Sans",
            }}
          />
          {
            open ?
              <ArrowDropUpIcon sx={{ cursor: 'pointer' }} onClick={() => handleChange()} />
              :
              <ArrowDropDownIcon sx={{ cursor: 'pointer' }} onClick={() => handleChange()} />
          }
        </Box>

        <Collapse in={open}>
          <CustomTable>
            <CustomTableHead>
              <CustomTableRow>
                <CustomTableHeadCell ><span >Tax Account</span></CustomTableHeadCell>
                <CustomTableHeadCell align='right' ><span >Tax Amount</span></CustomTableHeadCell>
              </CustomTableRow>
            </CustomTableHead>
            <CustomTableBody>
              {
                data.map((item, index) => {
                  return (
                    item.amount ?
                      <CustomTableRow key={index}>
                        <CustomTableBodyCell sx={{}}  >{item.name}</CustomTableBodyCell>
                        <CustomTableBodyCell sx={{}} align='right'><span>{<CurrencyFormatter amount={item.amount} currency={currency_code} />}</span></CustomTableBodyCell>
                      </CustomTableRow>
                      : null
                  )
                })
              }
              <CustomTableRow >
                <CustomTableBodyCell sx={{}}  >Total</CustomTableBodyCell>
                <CustomTableBodyCell sx={{}} align='right'><span>{<CurrencyFormatter amount={total} currency={currency_code} />}</span></CustomTableBodyCell>

              </CustomTableRow>
            </CustomTableBody>
          </CustomTable>
        </Collapse>
      </CustomTableContainer>
    </React.Fragment>
  )
}