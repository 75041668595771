import React, { useContext, useEffect } from "react";
import { routesName } from "../../../../config/routesName";
import FileUpload from "../../bulkStatementUpload/mappings/FileUpload";
import * as Actions from '../../../../state/Actions' 
import initialState from '../../../../state/initialState.json'
import { get_field_for_opening_balance } from "../../../../api/urls";
import { opening_balance } from "../../../../utils/Constant";
const ImportOpeningBalanceFile = (props) => {
    const {nextButton}=props;
    let dispatch = Actions.getDispatch(useContext)
    return(
        <FileUpload 
        isDateFormat={false}
        nextButton={nextButton}
        mapping_for={ opening_balance }
        model_fields_url={get_field_for_opening_balance()} 
        nextPagePath={routesName.openingBalanceMapping.path} 
        />
        
    )
}

export default ImportOpeningBalanceFile