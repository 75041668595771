import React from 'react';
import Highcharts from 'highcharts';
import { useNavigate } from 'react-router-dom';
import HighchartsReact from 'highcharts-react-official';
import { routesName } from '../../../config/routesName';
import { amountFormatter,numberWithSuffix } from '../../../utils/Utils';
import { eventsNames,AnalyticsEvent } from '../../../firebase/firebaseAnalytics';

const CustomHighChart = ({ data, condition, color }) => {
  let navigate = useNavigate();
  let categories = data.map((cashflow_item, index) => {
    cashflow_item.y = cashflow_item.closing_balance
    // return condition ? index ? moment(cashflow_item.end_date).format("MMM YY") : ' ' : moment(cashflow_item.end_date).format("MMM YY")
    return cashflow_item.period
  })
  const minClosingBalance = Math.min(...data.map(item => item.closing_balance)); // find the minimum closing_balance value in the data array
  const maxClosingBalance = Math.max(...data.map(item => item.closing_balance)); // find the minimum closing_balance value in the data array

  // console.log('====>minClosingBalance', minClosingBalance)
  // console.log('====>maxClosingBalance', maxClosingBalance)
  const options = {
    chart: {
      type: 'scatter'
    },
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    yAxis: {
      title: {
        text: null
      },
      max: maxClosingBalance,
      min: minClosingBalance,
      labels: {
        formatter: function () {
          return (maxClosingBalance === minClosingBalance) ? amountFormatter(this.value) : numberWithSuffix(this.value);
        }
      },
    },
    xAxis: {
      maxPadding: 0,
      tickInterval: 1,
      endOnTick: false,
      startOnTick: false,
      min: condition ? 0.5 : 0,
      categories: categories,
    },

    plotOptions: {
      series: {
        cursor: 'pointer',
        stickyTracking: false,
        connectNulls: false,
        point: {
          events: {
            click: function () {
              AnalyticsEvent(eventsNames.categories.DASHBOARD,{action:eventsNames.actions.CLICK,graph_name:eventsNames.actions.dashboard.graph_name.CASH_FLOW})
              navigate(routesName.cashFlow.path,
                {
                  state: {
                    dates: 0,
                    name: 'Custom',
                    to_date: this.options.end_date,
                    from_date: this.options.start_date,
                  }

                })
            }
          }
        }
      },
    },

    tooltip: {
      snap: 0,
      formatter: function () {
        var tooltip = `<br><span style="font-family: 'Noto Sans';"><span style="color:${'#049735'}">${'Incoming'} : </span><b>${amountFormatter(this.point.incoming)}</b></span>`;
        tooltip += `<br><span style="font-family: 'Noto Sans';"><span style="color:${'#ED0F1C'}">${'Outgoing'} : </span><b>${amountFormatter(this.point.outgoing)}</b></span>`;
        tooltip += `<br><span style="font-family: 'Noto Sans';"><span style="color:${'#2464EB'}">${'Opening Balance'} : </span><b>${amountFormatter(this.point.opening_balance)}</b></span>`;
        tooltip += `<br><span style="font-family: 'Noto Sans';"><span style="color:${'#7A7A7A'}">${'Closing Balance'} : </span><b>${amountFormatter(this.point.closing_balance)}</b></span>`;

        return tooltip;
      }
    },

    series: [{
      data: data,
      lineWidth: 2,
      color: color,
      name: 'Cash Flow',
      showInLegend: false,
    }]
  };
  return (
    <div> <HighchartsReact highcharts={Highcharts} options={options} containerProps={{ style: { height: "350px" } }} /></div>
  )
}

export default CustomHighChart