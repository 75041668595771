import React from "react"
import { Badge } from "@mui/material"

export const BadgeWithTitle = (props) => {
    const {title,count}=props;
    return (
        <span style={{ }}>
            {title}
            <Badge sx={{ pl: 2 }} color="secondary" badgeContent={<span style={{fontSize:'10px'}}>{count ? count : '0'}</span>} max={999} />
        </span>
    )
}