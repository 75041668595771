export const validateIfscCode = (ifscCode) => {
    const ifscRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
    return ifscRegex.test(ifscCode);
};

export const validateUpiId = (upiId) => {
    const upiPattern = /^[a-zA-Z0-9.\-_]{2,256}@[a-zA-Z]{2,64}$/;
    return upiPattern.test(upiId);
};

export const validateAccountNumber = (accountNumber) => {
    const accountNumberRegex = /^[0-9]{6,18}$/;
    return accountNumberRegex.test(accountNumber);
};