import React from 'react';
import { getBusinessInfo } from '../../../../config/cookiesInfo';
import CurrencyFormatter from '../../../common/CurrencyFormatter';
import { amountFormatter,isNumeric } from '../../../../utils/Utils';

const CurrencyFormatterWithExchangeRate = (props) => {
  const { currency } = getBusinessInfo();
  const { currency_code, amount, exchange_rate = 1 } = props;
  const base_currency_code = currency ? currency.currency_code : null;
  return (
    currency_code !== base_currency_code &&
    <span style={{ fontSize: '10px', opacity: 0.6, }}>{
      amountFormatter(isNumeric(amount)?Number(amount * exchange_rate):0, base_currency_code )
      // <CurrencyFormatter amount={amount * exchange_rate} currency={base_currency_code} />
    }</span>
  )
}

export default CurrencyFormatterWithExchangeRate;