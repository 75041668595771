import { ReactMultiEmail, isEmail } from 'react-multi-email';
import { input_css_style } from '../../../../utils/Constant';
import React, { useContext, useEffect, useState } from 'react';
import CustomButton from '../../../custom/button/CustomButton';
import CustomTypography from '../../../custom/typography/CustomTypography';

import {
    Box,
    Grid,
    Popover,
    Container,
    CssBaseline,
} from '@mui/material';
import * as Actions from "../../../../state/Actions"
import { stateChangeManager } from '../../../../utils/Utils';

export const Emails = (props) => {

    const { customer_emails, setEmail } = props
    const [anchorEl, setAnchorEl] = React.useState(null);
    // console.log('customer_emails',customer_emails)
    const dispatch = Actions.getDispatch(useContext)
    const [emails, setEmails] = React.useState(customer_emails)
    const [ccInputValue, setCCInputValue] = useState("")
    const [bccInputValue, setBCCInputValue] = useState("")

    // console.log('====>emails',emails)
    // console.log('====>customer_emails',customer_emails)
    useEffect(() => {
        setEmails({ ...emails, cc: customer_emails.cc ? customer_emails.cc : [], bcc: customer_emails.bcc ? customer_emails.bcc : [] })
    }, [customer_emails,anchorEl])

    const handleSubmit = () => {
        if ((ccInputValue !== '' ? !isEmail(ccInputValue) : false) || (bccInputValue !== '' ? !isEmail(bccInputValue) : false)) {
            stateChangeManager(dispatch, Actions, true, 'error', "Please enter valid email.")
        } else {
            handleClose()
            setEmail(emails);
        }

    }

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setCCInputValue('');
        setBCCInputValue('');
    }

    const myRef = React.useRef();
    const [clickedOutsideCC, setClickedOutsideCC] = React.useState(true);
    const [clickedOutsideBCC, setClickedOutsideBCC] = React.useState(true);

    const handleClickInside_CC = () => { setClickedOutsideCC(false); setClickedOutsideBCC(true) };
    const handleClickInside_BCC = () => { setClickedOutsideBCC(false); setClickedOutsideCC(true) };

    const handleClickOutside_CC = e => {
        if (!myRef.current.contains(e.target)) {
            setClickedOutsideCC(true);
        }
    };
    const handleClickOutside_BCC = e => {
        if (!myRef.current.contains(e.target)) {
            setClickedOutsideBCC(true);
        }
    };

    React.useEffect(() => {
        document?.addEventListener('mousedown', handleClickOutside_CC);
        return () => document.removeEventListener('mousedown', handleClickOutside_CC);
    });
    React.useEffect(() => {
        document?.addEventListener('mousedown', handleClickOutside_BCC);
        return () => document.removeEventListener('mousedown', handleClickOutside_BCC);
    });


    return (
        <React.Fragment>
            <CustomTypography
                id={'action_cc_bcc'}
                dataTestId={'action_cc_bcc'}
                text={'Cc/Bcc'}
                onClick={handleMenu}
                sx={{
                    mb: 1,
                    fontSize: '14px',
                    color: '#2464EB',
                    display:'inline',
                    cursor: 'pointer',
                    fontWeight: '500',
                    lineHeight: '18px',
                    fontStyle: 'normal',
                    textTransform: "none",
                    fontFamily: "Noto Sans",
                    textTransform: 'capitalize',
                    ":hover": { textDecoration: 'underline' },
                }}
            />
            <Popover
                sx={{ mt: '70px' }}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                PaperProps={{
                    style: {
                        width: 564,
                    },
                }}
                open={Boolean(anchorEl)}
            >
                <Box sx={{ p: 2 }}>
                    <form>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <CustomTypography
                                    text={'Cc'}
                                    sx={{
                                        mb: 1,
                                        fontSize: '14px',
                                        color: '#000000',
                                        fontWeight: '700',
                                        lineHeight: '18px',
                                        fontStyle: 'normal',
                                        textTransform: "none",
                                        fontFamily: "Noto Sans",
                                        textTransform: 'capitalize',
                                    }}
                                />
                                <div onClick={handleClickInside_CC} ref={myRef}>
                                    <ReactMultiEmail
                                        autoFocus={true}
                                        id='input_cc_email'
                                        dataTestId='input_cc_email'
                                        emails={emails.cc ? emails.cc : []}
                                        onChangeInput={(email) => {
                                            setCCInputValue(email)
                                        }}

                                        onChange={(valueEmails) => {

                                            setEmails({ ...emails, cc: valueEmails })
                                        }}
                                        style={{ marginTop: '0px', maxHeight: 40, textAlign: 'center', borderRadius: "4px", overflowY: 'scroll', borderColor: (clickedOutsideCC) ? (ccInputValue && !isEmail(ccInputValue)) ? '#d32f2f' : '' : "#2464EB", borderWidth: clickedOutsideCC ? 1.5 : 2 }}
                                        placeholder={<span style={{}}><span style={{ margin: 0, height: "100%", overflow: 'hidden', color: '#2F2F2E', fontWeight: '400', lineHeight: '0px', fontSize: '12px', fontStyle: 'normal', fontFamily: 'Noto Sans', opacity: 0.50, }}>Add Cc recipients</span></span>}

                                        getLabel={(
                                            email,
                                            index,
                                            removeEmail
                                        ) => {

                                            return (
                                                <div data-tag key={index} style={{...input_css_style}}>{email}<span data-tag-handle onClick={() => removeEmail(index)}>×</span>
                                                </div>
                                            );
                                        }}
                                        validateEmail={email => {
                                            return email?isEmail(email):false; // return boolean
                                        }}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sx={{ mt: 2 }}>
                                <CustomTypography
                                    text={'Bcc'}
                                    sx={{
                                        mb: 1,
                                        fontSize: '14px',
                                        color: '#000000',
                                        fontWeight: '700',
                                        lineHeight: '18px',
                                        fontStyle: 'normal',
                                        textTransform: "none",
                                        fontFamily: "Noto Sans",
                                        textTransform: 'capitalize',
                                    }}
                                />
                                <div onClick={handleClickInside_BCC} ref={myRef}>
                                    <ReactMultiEmail
                                        id='input_bcc_email'
                                        dataTestId='input_bcc_email'
                                        emails={emails.bcc ? emails.bcc : []}
                                        onChange={(valueEmails) => { setEmails({ ...emails, bcc: valueEmails }) }}
                                        placeholder={<span style={{}}><span style={{ margin: 0, height: "100%", overflow: 'hidden', color: '#2F2F2E', fontWeight: '400', lineHeight: '0px', fontSize: '12px', fontStyle: 'normal', fontFamily: 'Noto Sans', opacity: 0.50, }}>Add Bcc recipients</span></span>}
                                        style={{ marginTop: '0px', maxHeight: 40, textAlign: 'center', borderRadius: "4px", overflowY: 'scroll', borderColor: (clickedOutsideBCC) ? (bccInputValue && !isEmail(bccInputValue)) ? '#d32f2f' : '' : "#2464EB", borderWidth: clickedOutsideBCC ? 1.5 : 2 }}
                                        onChangeInput={(email) => {
                                            setBCCInputValue(email)
                                        }}
                                        getLabel={(
                                            email,
                                            index,
                                            removeEmail
                                        ) => {
                                            return (
                                                <div data-tag key={index} style={{...input_css_style}}>{email}<span data-tag-handle onClick={() => removeEmail(index)}>×</span>
                                                </div>
                                            );
                                        }}
                                        validateEmail={email => {
                                            return email?isEmail(email):false; // return boolean
                                        }}
                                    />
                                </div>
                            </Grid>

                            <Grid item xs={12} container spacing={10}>
                                <Grid item xs={6} sx={{ mt: 3, mb: 3 }}>
                                    <CustomButton
                                        fullWidth
                                        btnLabel='Cancel'
                                        variant="contained"
                                        id={'cc_bcc_cancel_btn'}
                                        dataTestId={'cc_bcc_cancel_btn'}
                                        onClick={() => handleClose()}
                                        sx={{ textTransform: 'capitalize' }}
                                    />
                                </Grid>
                                <Grid item xs={6} sx={{ mt: 3, mb: 3 }}>
                                    <CustomButton
                                        fullWidth
                                        btnLabel='Save'
                                        variant="contained"
                                        id={'cc_bcc_save_btn'}
                                        dataTestId={'cc_bcc_save_btn'}
                                        onClick={() => handleSubmit()}
                                        sx={{ textTransform: 'capitalize' }}
                                    />
                                </Grid>
                            </Grid>

                        </Grid>
                    </form>
                </Box>
            </Popover>
        </React.Fragment>
    )
}

export default Emails