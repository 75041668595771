import React from 'react';
import { useNavigate } from 'react-router-dom';
import { routesName } from '../../../../config/routesName';
import CustomButton from '../../../custom/button/CustomButton';
import CustomTypography from '../../../custom/typography/CustomTypography';
import { ReactComponent as BannerIcon } from '../../../../assets/svg/Banner.svg';

import {
  Box,
  Grid
} from '@mui/material';

const Banner = () => (
  <Box sx={{ height: { sm: '80vh', xs: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' } }}>
    <Content />
  </Box>
);

const Content = () => (
  <Grid container>
    <Text />
    <Graphic />
  </Grid>
);

const Text = () => (
  <Grid
    item
    sm={6}
    xs={12}
    sx={{
      display: 'flex',
      alignItems: 'center',
      flexDirection:'column',
      justifyContent:'center',
    }}
    order={{ xs: 2, sm: 1 }}
  >
    <CustomTypography
      text="Empowering your Business with Streamlined Accounting & Actionable Insights"
      sx={{
        color: '#141414',
        fontWeight: 700,
        lineHeight: '56px',
        letterSpacing: '0px',
        fontSize: { xs: '36px', sm: '40px' },
      }}
    />
    <CustomTypography
      text="Finycs is the comprehensive cloud accounting platform that effortlessly manages your business finances."
      sx={{
        mt: 2,
        mb: 2,
        fontSize: '20px',
        fontWeight: 400,
        color: '#666666',
        lineHeight: '32px',
        fontStyle: 'normal',
        letterSpacing: '0.2px',
      }}
    />
    <Buttons />
  </Grid>
);

const Graphic = () => (
  <Grid
    item
    sm={6}
    xs={12}
    order={{ xs: 1, sm: 2 }}
    sx={{
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      mb: { xs: 5, sm: 0 },
      mt: { xs: 10, sm: 0 },
      justifyContent: 'center',
    }}
  >
    <Box
      component="div"
      sx={{
        width:'100%',
        height: 'auto',
        maxWidth: '600px',
        maxHeight: '364px',
      }}
    >
      <BannerIcon style={{ width: '100%', height: 'auto'}} />
    </Box>
  </Grid>
);

const Buttons = () => {
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <Box sx={{display: 'flex',width:'100%',  flexDirection: { xs: 'column', sm: 'row' }, mb: 2, mt: 5 }}>
      <CustomButton
        variant="contained"
        btnLabel="Create Free Account"
        id="landing_create_free_account"
        dataTestId="landing_create_free_account"
        onClick={() => handleNavigate(`/auth${routesName.signUp.path}`)}
        sx={{
          mb: { xs: 2, sm: 0 },
          whiteSpace: 'nowrap',
          fontSize: '14px !important',
          width: { xs: 'auto', sm: '200px' },
          textTransform: 'uppercase !important',
        }}
      />
      <CustomButton
        variant="outlined"
        btnLabel="Book a Free Demo"
        id="landing_book_free-demo"
        dataTestId="landing_book_free-demo"
        onClick={() => handleNavigate(`/landing${routesName.demoPage.path}`)}
        sx={{
          whiteSpace: 'nowrap',
          ml: { xs: '', sm: 1 },
          fontSize: '14px !important',
          width: { xs: 'auto', sm: '200px' },
          textTransform: 'uppercase !important',
        }}
      />
    </Box>
  );
};

export default Banner;
