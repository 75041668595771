import React from 'react';
import CustomDropdown from '../../../custom/dropdown/CustomDropdown';
import {
    Box,
} from '@mui/material';
import { AddTermsAndConditions } from './TermsAndConditions';

const UpdateTermsAndConditions = (props) => {
    const { results = [], onClose, onAddItem } = props;
    const [selectedData, setSelectedData] = React.useState();

    return (
        <div>
            <Box minHeight={350}>
                {!selectedData &&
                    <CustomDropdown
                        fullWidth={true}
                        results={results}
                        disableClearable={false}
                        placeholder='Select Terms and Condition'
                        value={selectedData ? selectedData : null}
                        id={'update_terms_and_conditions_dropdown'}
                        dataTestId={'update_terms_and_conditions_dropdown'}
                        onChange={(event, newInputValue) => { setSelectedData(newInputValue) }}
                    />
                }
                {selectedData &&
                    <AddTermsAndConditions onAddItem={onAddItem} updateData={selectedData} onClose={() => { onClose(); setSelectedData() }} />
                }
            </Box>
        </div>
    )
}

export default UpdateTermsAndConditions