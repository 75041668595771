import React from 'react';
import NoTransactionComponent from '../../../common/NoTransactionComponent';

import {
  getColumns,
} from './Helper';

import {
  CustomTable,
  CustomTableRow,
  CustomTableBody,
  CustomTableHead,
  CustomTableHeadCell,
  CustomTableBodyCell,
  CustomTableContainer,
} from '../../../custom/table/CustomTable';


const ListBarcode = ({ data, onBarcodeRemove, onBarcodeEdit }) => {
  const columns = getColumns(onBarcodeEdit, onBarcodeRemove);

  return (
    <CustomTableContainer >
      <CustomTable className="barcode-custom-card">
        <ListBarcodeHeaders columns={columns} />
        <ListBarcodeBody rows={data} columns={columns} />
      </CustomTable>
    </CustomTableContainer>
  );
};
export default ListBarcode;

const ListBarcodeHeaders = ({ columns }) => (
  <CustomTableHead>
    <CustomTableRow>
      {columns.map((col, index) => (
        <CustomTableHeadCell sx={{whiteSpace:'nowrap'}} align={col.align} key={index}>{col.label}</CustomTableHeadCell>
      ))}
    </CustomTableRow>
  </CustomTableHead>
);

const ListBarcodeBody = ({ rows = [], columns = [] }) => (
  <CustomTableBody>
    {rows.map((row, rIndex) => (
      <CustomTableRow key={rIndex}>
        {columns.map((col, cIndex) => (
          <CustomTableBodyCell sx={{whiteSpace:'nowrap'}} align={col.align} key={cIndex}>
            {col.customField ? col.customField(row, rIndex) : row[col.field]}
          </CustomTableBodyCell>
        ))}
      </CustomTableRow>
    ))}
    {Boolean(!rows.length) && (
      <CustomTableRow>
        <CustomTableBodyCell colSpan={8}>
          <NoTransactionComponent data={rows} />
        </CustomTableBodyCell>
      </CustomTableRow>
    )}
  </CustomTableBody>
);
