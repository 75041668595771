import React from 'react';
import AddBankDetails from './AddBankDetails';
import BankDetailsList from './BankDetailsList';
import CustomTitle from '../../../../../../common/CustomTitle';
import CustomButton from '../../../../../../custom/button/CustomButton';
import { CustomHeading, CustomCheckbox } from '../../common/CustomElement';
import CustomTypography from '../../../../../../custom/typography/CustomTypography';
import { CustomContainer, CustomTitleContainer } from '../../../../../../custom/container/CustomContainer';
import { BootstrapDialog, BootstrapDialogTitle } from '../../../../../bulkStatementUpload/mappings/Transactions';
import { Box, Grid, DialogContent } from '@mui/material';

const styles = {
    title: {
        color: '#000000',
        fontSize: '16px',
        fontWeight: '700',
        lineHeight: '18px',
        fontStyle: 'normal',
        fontFamily: "Noto Sans",
    },
};

const BankDetails = ({ initial_data, onSaveHandler }) => {
    const [open, setOpen] = React.useState(false);
    const [editAccount, setEditAccount] = React.useState();
    const [data, setData] = React.useState({ ...initial_data });
    const [defaultAccount, setDefaultAccount] = React.useState();

    React.useEffect(() => {
        if (!open && data) {
            setDefaultAccount({ ...data.invoice.default_bank_account });
        }
    }, [data, open]);

    const onHandleChange = React.useCallback((key_name, value) => {
        setData(previous => ({
            ...previous,
            invoice: { ...previous.invoice, [key_name]: value }
        }));
    }, []);

    const onAddBankAccount = (value, isDefault) => {
        setData(previous => {
            const existingAccountIndex = previous.invoice.bank_accounts.findIndex(
                account => account.account_number === value.account_number
            );

            let updatedBankAccounts;
            if (existingAccountIndex > -1) {
                // Update the existing account
                updatedBankAccounts = [...previous.invoice.bank_accounts];
                updatedBankAccounts[existingAccountIndex] = value;
            } else {
                // Add the new account
                updatedBankAccounts = [...previous.invoice.bank_accounts, value];
            }

            const updatedInvoice = {
                ...previous.invoice,
                bank_accounts: updatedBankAccounts
            };

            if (isDefault) {
                updatedInvoice.default_bank_account = value;
            }

            return {
                ...previous,
                invoice: updatedInvoice
            };
        });

        if (isDefault) {
            setDefaultAccount(value);
            onHandleChange('default_bank_account', value);
        }
    };

    return (
        data && (
            <Box>
                <AddBankAccountDialog
                    open={open}
                    setOpen={setOpen}
                    data={editAccount}
                    onAddHandler={onAddBankAccount}
                    isDefaultAccountChecked={defaultAccount?.account_number === editAccount?.account_number ? true : data.invoice.bank_accounts?.length === 0}
                />
                <CustomTitleContainer>
                    <Grid container spacing={0} alignItems="center" justifyContent="space-evenly">
                        <Grid item xs={6}>
                            <CustomTitle title='Bank Accounts' />
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: "end" }}>
                            <CustomButton
                                btnLabel="Save"
                                variant="contained"
                                id="save_bank_account"
                                dataTestId="save_bank_account"
                                onClick={() => onSaveHandler(data)}
                            />
                        </Grid>
                    </Grid>
                </CustomTitleContainer>
                <CustomContainer maxWidth="400" sx={{ height: 'calc(100vh - 134px)' }}>
                    <Box sx={{ p: 2, pt: 1 }}>
                        <WantBankDetails data={data} onHandleChange={onHandleChange} />
                    </Box>
                    <Box sx={{ height: 'calc(100vh - 318px)' }}>
                        <Box sx={{ p: 2, pt: 0, textAlign: 'end' }}>
                            <CustomButton
                                variant="contained"
                                id="add_bank_account"
                                btnLabel="Add Bank Account"
                                dataTestId="add_bank_account"
                                onClick={() => { setOpen(true); setEditAccount() }}
                            />
                        </Box>
                        <Box sx={{ overflow: 'auto' }}>
                            <BankDetailsList
                                list={data.invoice.bank_accounts}
                                defaultAccount={data.invoice.default_bank_account}
                                onClick={(item) => { setEditAccount(item); setOpen(true); }}
                            />
                        </Box>
                    </Box>
                </CustomContainer>
            </Box>
        )
    );
};

export default React.memo(BankDetails);

const WantBankDetails = React.memo(({ data, onHandleChange }) => (
    <Box sx={{ pb: 2, mb: 2, borderBottom: '1px solid #e0e0e0' }}>
        <CustomHeading label='Do you want to?' />
        <CustomCheckbox
            id='show_bank_details_on_invoice'
            label='Show bank details on invoice'
            dataTestId='show_bank_details_on_invoice'
            checked={data.invoice.show_bank_details_on_invoice}
            disabled={!data?.invoice?.bank_accounts?.length}
            onClickedHandler={() => onHandleChange('show_bank_details_on_invoice', !data.invoice.show_bank_details_on_invoice)}
        /><br />
        <CustomCheckbox
            id='show_qr_code_on_invoice'
            dataTestId='show_qr_code_on_invoice'
            label='Show QR code (UPI) on invoice'
            checked={data.invoice.show_qr_code_on_invoice}
            onClickedHandler={() => onHandleChange('show_qr_code_on_invoice', !data.invoice.show_qr_code_on_invoice)}
            disabled={!data?.invoice?.bank_accounts?.length || !data?.invoice?.default_bank_account?.upi_id}
        /><br />
    </Box>
));

const AddBankAccountDialog = React.memo(({ open, setOpen, data, isDefaultAccountChecked, onAddHandler }) => (
    <BootstrapDialog fullWidth open={open} maxWidth='md'>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={() => setOpen(false)}>
            <CustomTypography text={data ? "Edit Bank Details" : 'Add Bank Details'} sx={styles.title} />
        </BootstrapDialogTitle>
        <DialogContent dividers>
            <AddBankDetails
                defaultAccountData={data}
                onAddHandler={onAddHandler}
                onClose={() => setOpen(false)}
                isDefaultAccountChecked={isDefaultAccountChecked}
            />
        </DialogContent>
    </BootstrapDialog>
));
