import React from "react";
import { landingRoutes } from "../../config/routes";
import { routesName } from "../../config/routesName";
import { isAuthenticated } from "../../config/cookiesInfo";
import Headers from "../../components/pages/landing/pages/Headers";
import Footer from "../../components/pages/landing/pages/Footer";

import {
  getQueryParams,
} from "../../utils/Utils";

import {
  Routes,
  Route,
  Navigate,
  useLocation
} from "react-router-dom";
import MainLayout from "../main/MainLayout";

export default function LandingLayout() {
  // console.log('LandingLayout=>')


  const location = useLocation();
  const params = getQueryParams(location.search);

  const ProtectedRoute = ({ children }) => {
    if (isAuthenticated()) {
      return <Navigate to={routesName.dashboard.path} replace />;
    } else {
      return children;
    }
  };

  return (
    <React.Fragment>
      {!isAuthenticated() &&
        <Headers />
      }
      <Routes>
        {
          landingRoutes.map((route, index) => {
            return (
              <Route key={index}
                path={route.path}
                element={
                  <ProtectedRoute>
                    {route.component}
                  </ProtectedRoute>
                }
              />
            )
          }
          )
        }
      </Routes>
      {!isAuthenticated() &&
        <Footer />
      }
    </React.Fragment>
  );
}


