import React from 'react';
import moment from 'moment';
import Input from '../../../common/Input';
import { useNavigate } from 'react-router-dom';
import { apiAction } from '../../../../api/api';
import DatePicker from '../../../common/DatePicker';
import * as Actions from '../../../../state/Actions';
import { stateChangeManager } from '../../../../utils/Utils';
import { LabelWithAsteriskMark } from '../../../common/CommonLabel';
import { AsyncParentAccountDropdown } from './AsyncParentAccountDropdown';
import CustomTypography from '../../../custom/typography/CustomTypography';
import { subAccountValidation, openingBalanceDateValidation } from './Helper';
import { getBusinessInfo, setBusinessInfo } from '../../../../config/cookiesInfo';
import { eventsNames,AnalyticsEvent } from '../../../../firebase/firebaseAnalytics';

import {
    create_custom_account,
    validate_migration_date,
    get_list_of_parent_account_new,
    get_chart_of_account_nested_nodes,
} from '../../../../api/urls';


import {
    Box,
    Grid,
    Radio,
    Button,
    Container,
    RadioGroup,
    createTheme,
    FormControlLabel,
} from '@mui/material';
import AsyncDropdown from '../../../common/AsyncDropdown';

const theme = createTheme();

const CreateSubAccount = (props) => {
    const { filterValue, account_type } = props;

    let navigate = useNavigate();
    const { onClose, filter, onAddAccount } = props
    const [type, setType] = React.useState('credit');
    const [manual, setManual] = React.useState(false);
    const [subAccount, setSubAccount] = React.useState();
    const dispatch = Actions.getDispatch(React.useContext);
    const [migrationDate, setMigrationDate] = React.useState();
    const [openingBalance, setOpeningBalance] = React.useState(0);
    const [parentAccount, setParentAccount] = React.useState(null);
    const [isFormSubmitted, setFormSubmitted] = React.useState(false)
    const [openingBalanceDate, setOpeningBalanceDate] = React.useState();
    const [defaultSubaccount, setDefaultSubaccount] = React.useState(null);
    const [disabledParentAccount, setDisabledParentAccount] = React.useState(false);

    const handleSubmit = async (event, is_multiple) => {
        setFormSubmitted(true);
        event.preventDefault();
        if (subAccountValidation(subAccount.name, dispatch, Actions)) {
            if (Boolean(openingBalance) ? openingBalanceDateValidation(openingBalanceDate, dispatch, Actions) : true) {
                AnalyticsEvent(eventsNames.categories.CHART_OF_ACCOUNTS,{action:eventsNames.actions.CREATE,type:eventsNames.actions.chartOfAccount.type.ACCOUNT})
                let res = await apiAction({
                    method: 'post',
                    navigate: navigate,
                    dispatch: dispatch,
                    url: create_custom_account(),
                    data: {
                        name: subAccount.name,
                        transaction_type: type,
                        parent_id: parentAccount.id,
                        opening_balance: openingBalance,
                        business_id: getBusinessInfo().id,
                        opening_balance_as_on_date: openingBalanceDate,
                    },
                })
                if (res?.success) {
                    setManual(false)
                    setParentAccount(null);
                    setFormSubmitted(false);
                    setDefaultSubaccount(null);
                    setSubAccount({ name: null });
                    if (!is_multiple) { onClose() };
                    if (onAddAccount) { onAddAccount(res?.result) }
                    stateChangeManager(dispatch, Actions, true, 'success', res?.status)
                    if (openingBalanceDate !== getBusinessInfo().migration_date) {
                        setBusinessInfo({ ...getBusinessInfo(), migration_date: openingBalanceDate })
                    }

                } else {
                    stateChangeManager(dispatch, Actions, true, 'error', res?.status)
                }
            }
        }
    };

    const onSubAccountHandler = (newInputValue) => {
        setDefaultSubaccount(newInputValue);
        setSubAccount(newInputValue ? newInputValue : { name: null });
    }
    const onParentAccountHandler = (newInputValue) => {
        setManual(false);
        setType('credit');
        setOpeningBalance(0);
        setDefaultSubaccount(null);
        setSubAccount({ name: null });
        setParentAccount(newInputValue);
    }

    const onSearchHandler = (searchValue) => {
        setSubAccount({ name: searchValue });
    }

    const fetchParentAccount = async (filterValue, account_type) => {
        console.log('===>filterValue, account_type',filterValue, account_type)
        const res = await apiAction({ url: `${get_list_of_parent_account_new(1)}&account_type=${account_type}&name=${filterValue}` })
        if (res?.success) {
            if (res?.result?.count === 1) {
                setDisabledParentAccount(true);
                onParentAccountHandler(res?.result?.result[0])
            }
        } else {
            setDisabledParentAccount(false);
        }
    }

    React.useEffect(() => {
        if (filterValue && account_type) {
            fetchParentAccount(filterValue, account_type);
        }
    }, [filterValue, account_type]);

    React.useEffect(() => {
        const apiResults = async () => {
            let res = await apiAction({
                method: 'post',
                navigate: navigate,
                dispatch: dispatch,
                url: validate_migration_date(),
                data: { business_id: getBusinessInfo().id, },
            })
            if (res?.success) {
                setMigrationDate(res?.result);
                setOpeningBalanceDate(getBusinessInfo() ? getBusinessInfo().migration_date : res?.result.previous_migration_date)
            } else {

            }
        }
        apiResults()
        // eslint-disable-next-line
    }, [])

    let additionParentUrlData = filter ? `account_type=${filter}` : '';

    return (
        <Container>
            <Box
                sx={{
                    marginTop: 0,
                    display: 'flex',
                    minHeight: '55vh',
                    justifyContent: 'center',
                }}
            >

                <Box component="form" noValidate sx={{ mt: 1 }}>
                    <AsyncParentAccountDropdown
                        isGroupHeaderSticky={false}
                        selectedValue={parentAccount}
                        disabled={disabledParentAccount}
                        id={'parent_account_type_dropdown'}
                        sx={{ maxWidth: 400, width: '100vw' }}
                        placeholder='Select the Parent Account'
                        dataTestId={'parent_account_type_dropdown'}
                        title={<LabelWithAsteriskMark label={'Parent Account'} />}
                        URL={`${get_list_of_parent_account_new(1)}&${additionParentUrlData}`}
                        nextUrlAdditionData={`&business_id=${getBusinessInfo()?.id}&${additionParentUrlData}`}

                        setSelectedValue={(value) => { onParentAccountHandler(value) }}
                    />
                    <br />
                    {parentAccount ?
                        <React.Fragment>
                            {
                                manual ?
                                    <Input
                                        id={'input_account_name'}
                                        dataTestId={'input_account_name'}
                                        item={{
                                            type: 'text',
                                            required: true,
                                            fullWidth: true,
                                            placeholder: 'Enter Account Name',
                                            value: subAccount.name ? subAccount.name : '',
                                            validation: isFormSubmitted && !subAccount.name,
                                            title: <LabelWithAsteriskMark label={'Account Name'} />,
                                            onChange: (e) => setSubAccount({ name: e.target.value !== '' ? e.target.value : null }),
                                        }}
                                    />
                                    :
                                    <AsyncDropdown
                                    sx={{}}
                                    fullWidth={true}
                                    autoFocus={false}
                                    disabledCloseIcon={false}
                                    isGroupHeaderSticky={false}
                                    id={'account_name_dropdown'}
                                    dataTestId={'account_name_dropdown'}
                
                                    valueLabelKey='id'
                                    uniqueOptionKey='id'
                                    searchKey='account_name'
                                    optionLabelKey='account_name'
                                    placeholder='select Account'
                                    selectedValue={defaultSubaccount}
                                    optionGroupLabelKey='parent_account_name'
                                    validation={isFormSubmitted && !subAccount.name}
                                    title={<LabelWithAsteriskMark label={'Account Name'} />}

                                    METHOD = {'post'}
                                    URL={get_chart_of_account_nested_nodes(1)}
                                    playLoad={{parent_account_id:[parentAccount.id]}}
                                    
                                    getInputValue={(value)=>{onSearchHandler(value)}}
                                    setSelectedValue={(value) => {onSubAccountHandler(value)}}
                                    addButton={{
                                                title: '+ Add new subaccount',
                                                onClick: () => setManual(true),
                                            }}
                                />
                            }

                            <Box sx={{ mt: 3 }}>
                                <Grid container spacing={0} style={{
                                    alignItems: 'center',
                                    justifyItems: 'center',
                                    justifyContent: 'space-evenly',
                                    alignContent: 'space-evenly',
                                }}>
                                    <Grid item xs={6}>
                                        <CustomTypography
                                            text={'Opening Balance'}
                                            sx={{
                                                color: '#000000',
                                                display: 'inline',
                                                fontSize: '14px',
                                                textAlign: 'start',
                                                fontWeight: '700',
                                                lineHeight: '18px',
                                                fontStyle: 'normal',
                                                fontFamily: "Noto Sans",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                        <RadioGroup row sx={{ marginBottom: -1 }}>
                                            <FormControlLabel value="credit"
                                                id={'credit_radio'}
                                                dataTestId={'credit_radio'}
                                                label={<span style={{
                                                    color: '#000000',
                                                    fontSize: '14px',
                                                    lineHeight: '18px',
                                                    display: 'inline',
                                                    fontStyle: 'normal',
                                                    fontFamily: "Noto Sans",
                                                    textTransform: 'capitalize',
                                                    fontWeight: type === 'credit' ? '600' : '400',
                                                }}>Credit</span>} control={<Radio sx={{ color: '#8F8F8F' }} checked={type === 'credit'} onChange={(e) => setType(e.target.value)} />} />
                                            <FormControlLabel value="debit"
                                                id={'debit_radio'}
                                                dataTestId={'debit_radio'}
                                                label={<span style={{
                                                    color: '#000000',
                                                    fontSize: '14px',
                                                    display: 'inline',
                                                    lineHeight: '18px',
                                                    fontStyle: 'normal',
                                                    fontFamily: "Noto Sans",
                                                    textTransform: 'capitalize',
                                                    fontWeight: type === 'debit' ? '600' : '400',
                                                }}>Debit</span>} control={<Radio sx={{ color: '#8F8F8F' }} checked={type === 'debit'} onChange={(e) => setType(e.target.value)} />} />
                                        </RadioGroup>
                                    </Grid>
                                </Grid>
                                <Input
                                    id={'input_opening_balance'}
                                    dataTestId={'input_opening_balance'}
                                    item={{
                                        type: 'text',
                                        required: true,
                                        fullWidth: true,
                                        title: '',
                                        placeholder: 'Enter Opening Balance',
                                        value: openingBalance ? openingBalance : '',
                                        onChange: (e) => setOpeningBalance(e.target.value === '' ? 0 : e.target.value)
                                    }}
                                    validation={false}
                                />
                            </Box>

                            <Box sx={{ mt: 3 }}>
                                {migrationDate && migrationDate.maximum_migration_date ?
                                    <DatePicker
                                        no_minDate={true}
                                        disabledCloseIcon={true}
                                        date={moment(openingBalanceDate)}
                                        id={'opening_balance_date_select'}
                                        dataTestId={'opening_balance_date_select'}
                                        disabledDatePicker={!migrationDate.is_editable}
                                        title={<LabelWithAsteriskMark label={'As of'} />}
                                        maxDate={moment(migrationDate.maximum_migration_date)}
                                        sx={{ background: !migrationDate.is_editable && '#F1F3F4' }}
                                        setDate={(date) => { setOpeningBalanceDate(date ? date.format('YYYY-MM-DD') : null) }}
                                    />
                                    :
                                    <DatePicker
                                        id={'opening_balance_date_select'}
                                        dataTestId={'opening_balance_date_select'}
                                        disabledCloseIcon={true}
                                        date={moment(openingBalanceDate)}
                                        title={<LabelWithAsteriskMark label={'As of'} />}
                                        setDate={(date) => { setOpeningBalanceDate(date ? date.format('YYYY-MM-DD') : null) }}
                                    />
                                }
                            </Box>

                            <Box sx={{ mt: 3, textAlign: 'end', bottom: 0, }}>
                                <Button
                                    id={'save_and_add_btn'}
                                    dataTestId={'save_and_add_btn'}
                                    variant="contained"
                                    onClick={(event) => { handleSubmit(event, true) }}
                                    sx={{ mt: 2, mr: 1, textTransform: 'none' }}
                                >Save and add another</Button>
                                <Button
                                    id={'save_btn'}
                                    dataTestId={'save_btn'}
                                    variant="contained"
                                    onClick={(event) => { handleSubmit(event, false) }}
                                    sx={{ mt: 2, textTransform: 'none' }}
                                >Save</Button>
                            </Box>
                        </React.Fragment>
                        : null
                    }
                </Box>
            </Box>
        </Container>
    )
}

export default CreateSubAccount