import React from 'react';
import { AppLogo } from '../../../common/AppLogo';
import { routesName } from '../../../../config/routesName';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomButton from '../../../custom/button/CustomButton';
import CustomTypography from '../../../custom/typography/CustomTypography';

import { Dropdown } from '../common/CustomComponent';
import DrawerComponent from '../common/DrawerComponent';

import {
    Box,
    AppBar,
    Toolbar,
    Container,
    IconButton,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';


const Headers = () => {


    const [openDrawer, setOpenDrawer] = React.useState(false);
    return (
        <div>
            <AppBar sx={{ bgcolor: "#fafafa", boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px' }} elevation={0}>
                <Container maxWidth={false} disableGutters sx={{ pl: { xs: 0, sm: 14 }, pr: { xs: 0, sm: 14 } }}>
                    <Toolbar disableGutters sx={{ display: 'flex', alignItems: 'center',}}>

                        <Box sx={{flexGrow:1, display: 'flex' }}>
                            <CustomLogo />
                        </Box>

                        <Box sx={{ alignItems: 'center', display: { xs: 'none', md: 'flex' } }}>
                            <NavMenuItem
                                onCloseHandler={() => setOpenDrawer(false)}
                            />
                        </Box>

                        <Box sx={{ alignItems: 'center', pl: 5, display: { xs: 'none', md: 'flex' } }}>
                            <Buttons />
                        </Box>

                        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={() => setOpenDrawer(true)}
                            >
                                <MenuIcon sx={{ color: '#2464EB', fontSize: 40 }} />
                            </IconButton>
                            <DrawerComponent
                                openDrawer={openDrawer}
                                setOpenDrawer={setOpenDrawer}
                            />
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <Toolbar id="back-to-top-anchor" />
        </div>
    )
}

export default Headers;

const NavMenuItem = (props) => {
    const { onCloseHandler } = props;
    const navigate = useNavigate();
    const { state } = useLocation();

    const handleFeatureClick = (id) => {
        navigate('/landing' + routesName.landingPage.path, { state: { id: id } });
        const anchor = document.querySelector(id);
        if (anchor) {
            anchor.scrollIntoView({
                block: "center",
            });
        }
    };

    const handleFeatureClickTab = (tab) => {
        const anchor = document.querySelector('#feature_section');
        if (anchor) {
            document.querySelector(tab)?.click();
            anchor.scrollIntoView({
                block: 'start',
            });
            navigate('/landing' + routesName.landingPage.path, { state: {tab:tab, id: '#feature_section' } });
        }
    };

    const features = [
        { id: '#tabs_0', name: 'Invoicing', onClick: () => handleFeatureClickTab('#tabs_1') },
        { id: '#tabs_1', name: 'Dashboard', onClick: () => handleFeatureClickTab('#tabs_0') },
        { id: '#tabs_2', name: 'Expenses', onClick: () => handleFeatureClickTab('#tabs_2') },
        { id: '#tabs_3', name: 'Accounting', onClick: () => handleFeatureClickTab('#tabs_3') },
        { id: '#tabs_4', name: 'Reports', onClick: () => handleFeatureClickTab('#tabs_4') },
        { id: '#tabs_5', name: 'GST', onClick: () => handleFeatureClickTab('#tabs_5') },
        { id: '#tabs_6', name: 'Inventory', onClick: () => handleFeatureClickTab('#tabs_6') },
        { id: '#tabs_7', name: 'Tally Integration', onClick: () => handleFeatureClickTab('#tabs_7') },

    ]

    const getTextColor = (id) => { return state?.id === id ? '#2464EB' : '#141414' }

    return (
        <React.Fragment>
            <CustomTypography
                text={'Home'}
                onClick={() => handleFeatureClick('#banner_section')}
                sx={{
                    mr: 3,
                    fontWeight: 700,
                    cursor: 'pointer',
                    lineHeight: '26px',
                    letterSpacing: '0px',
                    fontSize: '16px !important',
                    color: getTextColor('#banner_section'),
                    '&:hover': {
                        color: '#2464EB',
                    },
                }}
            />
            <CustomTypography
                text={'Analyze'}
                onClick={() => handleFeatureClick('#analysis_section')}
                sx={{
                    mr: 3,
                    fontWeight: 700,
                    cursor: 'pointer',
                    lineHeight: '26px',
                    letterSpacing: '0px',
                    fontSize: '16px !important',
                    color: getTextColor('#analysis_section'),
                    '&:hover': {
                        color: '#2464EB',
                    },
                }}
            />
            <Dropdown
                isGrid={true}
                title={'Features'}
                options={features}
                onCloseHandler={onCloseHandler}
                getTextColor={getTextColor('#feature_section')}
                onNavigate={() => navigate('/landing' + routesName.landingPage.path)}
            />
        </React.Fragment>
    )
}

const CustomLogo = () => {
    const navigate = useNavigate();

    return (
        <Box style={{ padding: 4, cursor: 'pointer' }} onClick={() => navigate('/landing' + routesName.landingPage.path)}>
            <AppLogo />
        </Box>
    )
}

const Buttons = () => {
    const navigate = useNavigate();

    return (
        <Box>
            <CustomButton
                btnLabel='Login'
                variant="outlined"
                id={'landing_page_sign_in'}
                dataTestId={'landing_page_sign_in'}
                onClick={() => { navigate("/auth" + routesName.signIn.path) }}
                sx={{
                    whiteSpace: 'nowrap',
                    fontSize: '14px !important',
                    width: { xs: 'auto', sm: '120px' },
                    textTransform: 'uppercase !important',
                }}
            />
            <CustomButton
                btnLabel='Sign Up'
                variant="contained"
                id={'landing_page_sign_up'}
                dataTestId={'landing_page_sign_up'}
                onClick={() => { navigate("/auth" + routesName.signUp.path) }}
                sx={{
                    ml: 1,
                    whiteSpace: 'nowrap',
                    fontSize: '14px !important',
                    width: { xs: 'auto', sm: '120px' },
                    textTransform: 'uppercase !important',
                }}
            />
        </Box>
    )
}


const resources = [
    { name: 'Guid', onClick: () => { } },
    { name: 'Blog', onClick: () => { } },
    { name: 'Help', onClick: () => { } },

]
