import React from 'react';
import Input from '../../../common/Input';
import { apiAction } from '../../../../api/api';
import * as Actions from '../../../../state/Actions';
import CustomInput from '../../../custom/input/CustomInput';
import CustomButton from '../../../custom/button/CustomButton';
import CustomDialog from '../../../custom/dialog/CustomDialog';
import { getBusinessInfo } from '../../../../config/cookiesInfo';
import { LabelWithAsteriskMark } from '../../../common/CommonLabel';
import CustomDropdown from '../../../custom/dropdown/CustomDropdown';
import CustomTypography from '../../../custom/typography/CustomTypography';
import {
  create_terms_and_conditions,
  update_terms_and_conditions,
  get_list_of_terms_and_conditions,
} from '../../../../api/urls';

import {
  isFormValid,
  stateChangeManager,
} from '../../../../utils/Utils';

import {
  Box,
  Grid,
  Container,
} from '@mui/material';

export const AddTermsAndConditions = (props) => {
  const { onClose, updateData, onAddItem } = props
  const dispatch = Actions.getDispatch(React.useContext);

  const [isFormSubmitted, setFormSubmitted] = React.useState(false);
  const [value, setValue] = React.useState({
    name: updateData ? updateData.name : null,
    content: updateData ? updateData.content : null
  })

  const handleSubmit = async () => {
    setFormSubmitted(true);

    let validation_data = [
      { key: "name", message: `Name field can not be empty` },
      { key: "content", message: 'Content field can not be empty' },
    ]
    const { isValid, message } = isFormValid(value, validation_data);
    if (isValid) {
      onClose()
      //Api call
      let res = await apiAction({
        method: 'post',
        url: updateData ? update_terms_and_conditions(updateData.id) : create_terms_and_conditions(),
        data: {
          ...value,
          business_id: getBusinessInfo().id,
        }
      })
      if (res?.success) {
        if (onAddItem) {
          onAddItem(res?.result)
        }
        // checking the response and according to save the data in to 'js-cookie' for the future use
        stateChangeManager(dispatch, Actions, true, 'success', res?.status);

      } else {
        // stateChangeManager is a alert function which show the error message
        stateChangeManager(dispatch, Actions, true, 'error', res?.status)
      }
    } else {
      stateChangeManager(dispatch, Actions, true, 'error', message)
    }
  };
  return (
    <Container component="main" maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item xs={12} container spacing={1} sx={{ mt: 1 }}>
          <Grid item xs={12}>
            <CustomTypography
              text={<LabelWithAsteriskMark label={'Name'} />}
              sx={{
                mb: 1,
                fontSize: '14px',
                color: '#000000',
                fontWeight: '700',
                lineHeight: '18px',
                fontStyle: 'normal',
                textTransform: "none",
                fontFamily: "Noto Sans",
                textTransform: 'capitalize',
              }}
            />
            <CustomInput
              autoFocus={true}
              required
              fullWidth
              type="text"
              value={value.name}
              id="terms_condition_name"
              dataTestId="terms_condition_name"
              validation={isFormSubmitted && !value.name}
              onChange={(e) => setValue({ ...value, name: e.target.value })}
            /></Grid>
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <CustomTypography
            text={<LabelWithAsteriskMark label={'Content'} />}
            sx={{
              mb: 1,
              fontSize: '14px',
              color: '#000000',
              fontWeight: '700',
              lineHeight: '18px',
              fontStyle: 'normal',
              textTransform: "none",
              fontFamily: "Noto Sans",
              textTransform: 'capitalize',
            }}
          />
          <CustomInput
            rows={6}
            required
            fullWidth
            type="text"
            multiline={true}
            value={value.content}
            id="terms_condition_content"
            dataTestId="terms_condition_content"
            validation={isFormSubmitted && !value.content}
            onChange={(e) => setValue({ ...value, content: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} container spacing={10}>
          <Grid item xs={6}>
            <CustomButton
              fullWidth
              btnLabel='Cancel'
              variant="contained"
              sx={{ mt: 3, mb: 2, }}
              onClick={() => onClose()}
              id="terms_condition_cancel_btn"
              dataTestId="terms_condition_cancel_btn"
            />
          </Grid>
          <Grid item xs={6}>
            <CustomButton
              fullWidth
              btnLabel='Save'
              variant="contained"
              onClick={handleSubmit}
              sx={{ mt: 3, mb: 2, }}
              id="terms_condition_save_btn"
              dataTestId="terms_condition_save_btn"
            />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

export const TermsAndConditions = (props) => {
  const { title, value, setValue } = props

  const dispatch = Actions.getDispatch(React.useContext);

  const [state, setState] = React.useState({
    id: '',
    title: '',
    open: false,
    condition: '',
    maxWidth: 'lg',
    fullWidth: true,
  })

  const addHandler = () => {
    setState({
      open: true,
      maxWidth: 'sm',
      fullWidth: true,
      title: 'Add Terms and Conditions',
      condition: 'create_terms_and_conditions',
    })
  }

  const [edit, setEdit] = React.useState(false);
  const [selectedData, setSelectedData] = React.useState();

  const [results, setResults] = React.useState([])
  React.useEffect(() => {
    const apiResults = async () => {
      let res = await apiAction({
        method: 'post',
        url: get_list_of_terms_and_conditions(),
        data: { business_id: getBusinessInfo().id },
      })
      if (res?.success) {
        setResults(res?.result)
      } else {

      }
    }
    apiResults()
    // eslint-disable-next-line
  }, [state.open])

  const onTermAndConditionAdd = (item) => {
    setValue(item);
    results.push(item);
    setSelectedData(item);
    setResults([...results]);
  }

  let selectedTerms = results.find((item) => item.id === value);
  React.useEffect(() => {
    setEdit(false);
    if (selectedTerms) {
      setSelectedData(selectedTerms);
    } else {
      setSelectedData(null);
    }
    // eslint-disable-next-line
  }, [value, selectedTerms])

  const handleSubmit = async () => {
    let validation_data = [
      { key: "name", message: `Name field can not be empty` },
      { key: "content", message: 'Content field can not be empty' },
    ]
    const { isValid, message } = isFormValid(selectedData, validation_data);
    if (isValid) {
      let res = await apiAction({
        method: 'post',
        url: update_terms_and_conditions(selectedData.id),
        data: {
          ...selectedData,
          business_id: getBusinessInfo().id,
        }
      })
      if (res?.success) {
        setEdit(!edit)
        stateChangeManager(dispatch, Actions, true, 'success', res?.status);
      } else {
        stateChangeManager(dispatch, Actions, true, 'error', res?.status)
      }
    } else {
      stateChangeManager(dispatch, Actions, true, 'error', message)
    }
  };


  return (
    <React.Fragment>
      <CustomDialog
        state={state}
        setState={setState}
        onAddItem={onTermAndConditionAdd}

      />
      <Grid container>
        <Grid item xs={6}>
          <CustomTypography
            text={title ? title : ''}
            sx={{
              mb: 1,
              color: '#000000',
              fontSize: '14px',
              fontWeight: '700',
              lineHeight: '18px',
              fontStyle: 'normal',
              textTransform: 'none',
              fontFamily: "Noto Sans",
            }}
          />
        </Grid>
        <Grid item xs={6} sx={{ textAlign: 'right' }}>
          {
            selectedData ?
              <CustomTypography
                text={edit ? 'Save' : 'Edit'}
                onClick={() => edit ? handleSubmit() : setEdit(!edit)}
                sx={{
                  mb: 1,
                  fontSize: '12px',
                  display: 'inline',
                  color: '#2464EB',
                  cursor: 'pointer',
                  display: 'inline',
                  fontWeight: '700',
                  lineHeight: '16px',
                  fontStyle: 'normal',
                  textTransform: "none",
                  fontFamily: "Noto Sans",
                  ":hover": { textDecoration: 'underline' },
                }}
              />
              : null
          }
        </Grid>
      </Grid>
      <CustomDropdown
        results={results}
        disableClearable={false}
        id={'terms_and_conditions_dropdown'}
        placeholder='Select Terms and Condition'
        value={selectedData ? selectedData : null}
        dataTestId={'terms_and_conditions_dropdown'}
        onChange={(event, newInputValue) => setValue(newInputValue)}
        addButton={{
          title: '+ Add Terms and Condition',
          onClick: () => addHandler(),
        }}
      />
      {
        selectedData ?
          <Box sx={{}}>
            <Input
              id={'terms_and_conditions_input'}
              dataTestId={'terms_and_conditions_input'}
              item={{
                rows: 8,
                title: "",
                type: "text",
                required: true,
                disabled: !edit,
                multiline: true,
                fullWidth: true,
                placeholder: "",
                value: selectedData && selectedData.content ? selectedData.content : '',
                onChange: (e) => { setSelectedData((previous) => ({ ...previous, content: e.target.value })) }
              }}
            />
          </Box>
          : null
      }
    </React.Fragment>
  )
}