import React from 'react';
import DateRange from './DateRange';
import debounce from 'lodash/debounce';
import { apiAction } from '../../api/api';
import * as Actions from '../../state/Actions';
import { useNavigate } from 'react-router-dom';
import { getBusinessInfo } from '../../config/cookiesInfo';
import {
    get_list_of_tds_tcs_details,
} from '../../api/urls';

import {
    Grid,
    Radio,
    RadioGroup,
    FormControlLabel,
} from '@mui/material';

const Dropdown = DateRange;
const CommonTDSandTCS = (props) => {
    let navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);

    const { tax_type, set_tax_type, value, setValue, validation } = props;

    let business_id = getBusinessInfo().id;
    const [apiCall,setApiCall]=React.useState(true);
    const [results, setResults] = React.useState([]);
    const [searchValue, setSearchValue] = React.useState();

    const handleChange = (event) => {
        setResults([]);
        setApiCall(true);
        setSearchValue(null);
        event.preventDefault();
        set_tax_type(event.target.value);
    }
    const onHandleChange = debounce(handleChange, 0);

    React.useEffect(() => {
        const getData = async (selected_tax_type, search_term) => {
            let res = await apiAction({
                navigate: navigate,
                dispatch: dispatch,
                url: `${get_list_of_tds_tcs_details(business_id, selected_tax_type)}${search_term ? '&name=' + search_term : ''}`,
            })
            if (res?.success) {
                let result_list = res?.result.result;
                if (result_list.length) {
                    let temp_result_list = result_list.map((item) => {
                        item['display_name'] = item.name + ' [' + item.rate + '%]'
                        return item;
                    })
                    setResults(temp_result_list);
                    setValue(temp_result_list.find((item) => item.id === value));
                }
                if (res?.result.result.length === 0) {
                    setSearchValue(null)
                }
                setApiCall(false);
            } else {
            }
        }
        if (apiCall&&tax_type && tax_type !== 'no_tax') {
            getData(tax_type, searchValue)
        }
    }, [tax_type, searchValue]);

    return (

        <Grid item sx={{}}>
            <RadioGroup row sx={{ marginBottom: 0 }}>
                <FormControlLabel
                    id={'tds'}
                    dataTestId={'tds'}
                    value={'tds'}
                    label={<span style={{
                        color: '#000000',
                        fontSize: '12px',
                        display: 'inline',
                        lineHeight: '18px',
                        fontStyle: 'normal',
                        fontFamily: "Noto Sans",
                        fontWeight: tax_type === 'tds' ? '600' : '400',
                    }}>TDS</span>} control={<Radio size='small' sx={{ color: '#8F8F8F' }}
                        checked={tax_type === 'tds'}
                        onChange={(e) => { onHandleChange(e) }}

                    />} />
                <FormControlLabel
                    id={'tcs'}
                    dataTestId={'tcs'}

                    value={'tcs'}
                    label={<span style={{
                        color: '#000000',
                        fontSize: '12px',
                        lineHeight: '18px',
                        display: 'inline',
                        fontStyle: 'normal',
                        fontFamily: "Noto Sans",
                        fontWeight: tax_type === 'tcs' ? '600' : '400',
                    }}>TCS</span>} control={<Radio size='small' sx={{ color: '#8F8F8F' }}
                        checked={tax_type === 'tcs'}
                        onChange={(e) => { onHandleChange(e) }}
                    />}

                />
                <FormControlLabel
                    id={'no_tax'}
                    dataTestId={'no_tax'}

                    value={'no_tax'}
                    label={<span style={{
                        color: '#000000',
                        fontSize: '12px',
                        lineHeight: '18px',
                        display: 'inline',
                        fontStyle: 'normal',
                        fontFamily: "Noto Sans",
                        fontWeight: tax_type === 'no_tax' ? '600' : '400',
                    }}>No Tax</span>} control={<Radio size='small' sx={{ color: '#8F8F8F' }}
                        checked={tax_type === 'no_tax'}
                        onChange={(e) => { onHandleChange(e) }}

                    />}

                />
            </RadioGroup>
            {tax_type && tax_type !== 'no_tax' &&
                <Dropdown
                    id={'tds_tcs_dropdown'}
                    dataTestId={'tds_tcs_dropdown'}
                    label=''
                    results={results}
                    forcePopupIcon={true}
                    validation={validation}
                    disableClearable={false}
                    placeholder='Select Tax'
                    setValue={(event, value) => { setValue(value) }}
                    onSearchHandler={(value) => { setSearchValue(value) }}
                    value={value ? results.find((item) => item.id === value) : null}
                />
            }
        </Grid>
    )
}

export default CommonTDSandTCS;
