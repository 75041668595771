import React from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { apiAction } from "../../../../api/api";
import ReportsTitle from '../common/ReportsTitle';
import * as Actions from '../../../../state/Actions';
import { routesName } from '../../../../config/routesName';
import ReportsSettingButtons from '../ReportsSettingButtons';
import { getBusinessInfo } from '../../../../config/cookiesInfo';
import { NoDataComponent } from '../../../common/NoDataComponent';
import CurrencyFormatter from '../../../common/CurrencyFormatter';
import CommonCustomDateRange from '../../../common/CommonCustomDateRange';
import CustomPagination from '../../../custom/pagination/CustomPagination';
import CustomTypography from '../../../custom/typography/CustomTypography';
import { CustomContainer } from '../../../custom/container/CustomContainer';

import {
    get_summary_for_tds
} from '../../../../api/urls';

import {
    setLoader,
    date_range,
    getDateFormat,
} from '../../../../utils/Utils';

import {
    Box,
    Grid,
} from '@mui/material';

import {
    CustomTable,
    CustomTableRow,
    CustomTableBody,
    CustomTableHead,
    CustomTableHeadCell,
    CustomTableBodyCell,
    CustomTableContainer,
} from '../../../custom/table/CustomTable';

const TDSSummaryReports = () => {
    let navigate = useNavigate();

    const stateAction = Actions.getState(React.useContext);
    const dispatch = Actions.getDispatch(React.useContext);

    const { selectedDateRange } = stateAction;
    let filter_date_range = date_range().find((item) => item.name === selectedDateRange)
    const default_date_range = filter_date_range ? filter_date_range : date_range()[2]

    const [dateRange, setDateRange] = React.useState(default_date_range)
    const [fromDate, setFromDate] = React.useState(moment())
    const [toDate, setToDate] = React.useState(moment())
    const [results, setResults] = React.useState([])
    const [date, setDate] = React.useState()

    const [page, setPage] = React.useState(1);
    const [pagination, setPagination] = React.useState({
        next: undefined,
        count: undefined,
        previous: undefined,
        number_of_pages: undefined,
    });


    React.useEffect(() => {
        if (dateRange) {
            if (dateRange.name !== "Custom") {
                getApiResults(dateRange.dates.from_date, dateRange.dates.to_date, dateRange.dates.date_range)
            } else {
                getApiResults(fromDate.format('YYYY-MM-DD'), toDate.format('YYYY-MM-DD'), `${fromDate.format(getDateFormat())} - ${toDate.format(getDateFormat())}`)
            }
        }
        // eslint-disable-next-line
    }, [dateRange, toDate, fromDate, page]);

    const getApiResults = async (from_date, to_date, range_date) => {
        setDate(range_date);
        setLoader(dispatch, Actions, true);
        let res = await apiAction({
            method: 'get',
            url: get_summary_for_tds(getBusinessInfo().id, from_date, to_date, page)
        })

        if (res?.success) {
            let result = res?.result;
            setPagination({
                ...pagination,
                next: result.next,
                count: result.count,
                previous: result.previous,
                number_of_pages: result.number_of_pages,
            });
            setResults(res?.result.result);
            setLoader(dispatch, Actions, false);
        } else {
            setLoader(dispatch, Actions, false);
        }
    }

    let to_date = dateRange.dates ? dateRange.dates.to_date : toDate.format('YYYY-MM-DD');
    let from_date = dateRange.dates ? dateRange.dates.from_date : fromDate.format('YYYY-MM-DD');
    return (
        results &&
        <div>
            <ReportsSettingButtons
               scheduleData={{
                report_name: 'form_27eq_details',
                report_title: 'tds_summary',
            }}
                exportData={{method:'get', url: get_summary_for_tds(getBusinessInfo().id, from_date, to_date, page)+'&export_type=pdf', data: dateRange.dates ? dateRange.dates : { to_date: toDate.format('YYYY-MM-DD'), from_date: fromDate.format('YYYY-MM-DD') } }}
            />
            <CustomContainer maxWidth={"400"} sx={{}}>
                <div style={{ paddingLeft: '16px', paddingRight: '16px' }}>
                    <Grid container spacing={0} sx={{ pb: 2 }} style={{
                        alignItems: 'center',
                        justifyItems: 'center',
                        alignContent: 'space-evenly',
                        justifyContent: 'space-evenly',
                    }}>
                        <Grid item xs={12} sm={4}>
                            <ReportsTitle
                                date={date}
                                title={'TDS Summary'}
                            />
                        </Grid>
                        <Grid container spacing={1} item xs={12} sm={8} sx={{ justifyContent: 'end', }}>
                            <CommonCustomDateRange
                                setPage={setPage}
                                date_range={date_range()}

                                toDate={toDate}
                                setToDate={setToDate}

                                fromDate={fromDate}
                                setFromDate={setFromDate}

                                dateRange={dateRange}
                                setDateRange={setDateRange}
                            />
                        </Grid>
                    </Grid>
                </div>
                <CustomTableContainer>
                    <CustomTable>
                        <Header />
                        {
                            results.length ?
                                <Body
                                    data={results}
                                    navigate={(item) => {
                                        navigate(
                                            routesName.tdsDetailsSectionReports.path +
                                            "?section_id=" + item.section_id,
                                            {
                                                state: {
                                                    ...item,
                                                    date: dateRange.dates ? dateRange :
                                                        { name: 'Custom', dates: 0, to_date: toDate.format('YYYY-MM-DD'), from_date: fromDate.format('YYYY-MM-DD') }
                                                }
                                            }
                                        )
                                    }}
                                />
                                : null
                        }
                    </CustomTable>
                    <Box sx={{ p: !results.length ? 2 : 0 }} >
                        <NoDataComponent left='0%' top='0%' position={'relative'} data={results} />
                    </Box>
                </CustomTableContainer>
            </CustomContainer>

            {
                results.length ?
                    <CustomPagination
                        page={page}
                        count={pagination.number_of_pages}
                        onChange={(event, newValue) => setPage(newValue)}
                    />
                    : null
            }
        </div >
    )
}

export default TDSSummaryReports;

const Header = () => {
    return (
        <CustomTableHead>
            <CustomTableRow >
                <CustomTableHeadCell><span>TDS Section</span></CustomTableHeadCell>
                <CustomTableHeadCell align='right'><span>Total</span></CustomTableHeadCell>
                <CustomTableHeadCell align='right'><span>Total After TDS Deduction</span></CustomTableHeadCell>
                <CustomTableHeadCell align='right'><span>Tax Deduction at Source</span></CustomTableHeadCell>
            </CustomTableRow>
        </CustomTableHead>
    )
}

const Body = (props) => {
    const { data, navigate } = props;

    return (
        <CustomTableBody>
            {data.map((item, index) => {
                return (
                    <CustomTableRow key={index}>
                        <CustomTableBodyCell>
                            <CustomTypography
                                text={<span>Section {item.section_name}</span>}
                                sx={{
                                    color: '#2464EB',
                                    fontSize: '12px',
                                    display: 'inline',
                                    cursor: 'pointer',
                                    fontWeight: '700',
                                    lineHeight: '18px',
                                    fontStyle: 'normal',
                                    fontFamily: "Noto Sans",
                                    ":hover": { textDecoration: 'underline' }
                                }}
                                onClick={() => { navigate(item) }}
                            />
                            <p style={{ fontSize: '10px', opacity: 0.6, marginBottom: '0px', marginTop: '0px' }}>{item.tax_name}</p>
                        </CustomTableBodyCell>
                        <CustomTableBodyCell align='right'><CurrencyFormatter amount={item.total} currency={null} /></CustomTableBodyCell>
                        <CustomTableBodyCell align='right'><CurrencyFormatter amount={item.total_after_tds} currency={null} /></CustomTableBodyCell>
                        <CustomTableBodyCell align='right'><CurrencyFormatter amount={item.tax_deducted} currency={null} /></CustomTableBodyCell>
                    </CustomTableRow>
                )
            })}
        </CustomTableBody>
    )
}