import moment from 'moment';
import React, { useState, useEffect } from 'react';


const TimeAgo = ({ created_at }) => {
  const [timeAgo, setTimeAgo] = useState(getTimeAgo(created_at));

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeAgo(getTimeAgo(created_at));
    }, 1000); // Update every second

    return () => {
      clearInterval(interval); // Clean up the interval on component unmount
    };
  }, [created_at]);

  return <span>{timeAgo}</span>;
};

export default TimeAgo;

const getTimeAgo = (created_at) => {
    var now = moment();
    var createdAt = moment(created_at);
    var diff = moment.duration(now.diff(createdAt));
  
    var years = diff.years();
    var months = diff.months();
    var days = diff.days();
    var hours = diff.hours();
    var minutes = diff.minutes();
    var seconds = diff.seconds();
    if (years > 0) {
      return years + (years === 1 ? " year" : " years") + " ago";
    } else if (months > 0) {
      return months + (months === 1 ? " month" : " months") + " ago";
    } else if (days > 0) {
      return days + (days === 1 ? " day" : " days") + " ago";
    } else if (hours > 0) {
      return hours + (hours === 1 ? " hour" : " hours") + " ago";
    } else if (minutes > 0) {
      return minutes + (minutes === 1 ? " minute" : " minutes") + " ago";
    } else if (seconds > 0) {
      // return seconds + (seconds === 1 ? " second" : " seconds") + " ago";
      return "Just now";
    } else {
      return "Just now";
    }
  }
