import moment from "moment/moment";
import React, { useContext } from "react";
import "react-multi-email/dist/style.css";
import { TaxList } from "../../common/GST";
import Input from "../../../../common/Input";
import * as Common from "../../common/Common";
import { useLocation } from "react-router-dom";
import { ListTerms } from "../../common/Terms";
import { useNavigate } from "react-router-dom";
import DateRange from "../../../../common/DateRange";
import DatePicker from "../../../../common/DatePicker";
import * as Actions from "../../../../../state/Actions";
import CustomTitle from "../../../../common/CustomTitle";
import { InputFile } from "../../../../common/InputFile";
import CommonAlertBox from "../../common/CommonAlertBox";
import PrintAndPreview from "../../common/PrintAndPreview";
import AsyncDropdown from "../../../../common/AsyncDropdown";
import { routesName } from "../../../../../config/routesName";
import CommonCalculation from "../../common/CommonCalculation";
import CommonDropdown from "../../../../common/CommonDropdown";
import CustomDialog from "../../../../custom/dialog/CustomDialog";
import CustomButton from "../../../../custom/button/CustomButton";
import { getBusinessInfo } from "../../../../../config/cookiesInfo";
import CurrencyFormatter from "../../../../common/CurrencyFormatter";
import { LabelWithAsteriskMark } from "../../../../common/CommonLabel";
import CommonAsyncDropdown from "../../../../common/CommonAsyncDropdown";
import CommonCurrencySelection from "../../common/CommonCurrencySelection";
import CustomTypography from "../../../../custom/typography/CustomTypography";
import { eventsNames,AnalyticsEvent } from "../../../../../firebase/firebaseAnalytics";

import {
  getNotesPreferences,
  getDiscountPreferences,
  getAmountArePreferences,
} from "../../../setting/settings/Preferences/common/getPreferences";

import {
  CustomContainer,
  CustomTitleContainer,
  CustomButtonContainer,
} from "../../../../custom/container/CustomContainer";

import {
  basePriceTotal,
  totalTdsTcsAmount,
  checkItemDiscount,
  totalDiscountOnBasePrice,
  isFinalAmountRangeCrossed,
  convertInclusiveToExclusive,
  convertExclusiveToInclusive,
  applyTaxOnMultipleGSTComponents,
} from "../../sales/invoices/InvoiceManager";

import {
  setLoader,
  filterTax,
  getAddress,
  isFormValid,
  getQueryParams,
  findDefaultTax,
  isVendorRegister,
  stateChangeManager,
  isApplicableForTax,
  setIsVendorRegister,
  isBusinessRegistered,
  breadCrumbsStateChangeManager,
} from "../../../../../utils/Utils";

import { vendorMappings } from "./Helper";

import { apiAction, apiFormData } from "../../../../../api/api";

import {
  get_item,
  list_items,
  get_vendor,
  create_bill,
  list_vendor,
  update_bill,
  export_bill,
  retrieve_bill,
  get_list_of_tax,
  get_list_of_state,
  list_payment_term,
  retrieve_purchase_order,
  get_create_bill_meta_data,
  get_chart_of_account_nested_nodes,
} from "../../../../../api/urls";

import {
  Box,
  Grid,
  Checkbox,
  IconButton,
  FormControlLabel,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  CustomTable,
  CustomTableRow,
  CustomTableBody,
  CustomTableHead,
  CustomTableHeadCell,
  CustomTableBodyCell,
  CustomTableContainer,
} from "../../../../custom/table/CustomTable";

const Dropdown = DateRange;

// eslint-disable-next-line
const isNumeric = (num) =>
  (typeof num === "number" || (typeof num === "string" && num.trim() !== "")) &&
  !isNaN(num);

const CreateNewBill = (props) => {
  const { onClose, vendor } = props;
  // console.log('vendor======>', vendor)

  let location = useLocation();
  const navigate = useNavigate();
  const params = getQueryParams(location.search);
  const [isClear, setClear] = React.useState(false);
  const dispatch = Actions.getDispatch(React.useContext);
  const [isFormSubmitted, setFormSubmitted] = React.useState(false);
  const [state, setState] = React.useState({
    id: "",
    title: "",
    open: false,
    condition: "",
    maxWidth: "lg",
    compulsory: "",
    fullWidth: true,
  });

  const [zero, setZero] = React.useState(false);

  const [newItem, setNewItem] = React.useState(null);
  const [itemIndex, setItemIndex] = React.useState(null);

  const addButtonHandler = (title, condition, maxWidth, compulsory, index) => {
    setState({
      open: true,
      title: title,
      fullWidth: true,
      maxWidth: maxWidth,
      condition: condition,
      compulsory: compulsory,
    });
    setItemIndex(index);
    if (index !== null || index !== undefined) {
      setBill({ ...bill });
    }
  };

  const bill_initial_data = {
    tax: null,
    attachments: null,
    total_amount: 0,
    discount: 0,
    vendor: null,
    vendor_id: null,
    order_number: null,
    payment_term: null,
    payment_method: "cash",
    sales_person_id: null,
    payment_status: null,
    payment_term_id: null,
    bill_number: null,
    mailing_address: null,
    entity_level_discount: null,
    terms_and_conditions_id: null,
    business_id: getBusinessInfo().id,
    due_date: moment().format("YYYY-MM-DD"),
    bill_date: moment().format("YYYY-MM-DD"),
    terms_and_condition: null,
    bill_items: [
      {
        item: null,
        tax: null,
        rate: null,
        quantity: 1,
        tax_id: null,
        amount: null,
        item_id: null,
        unit_id: null,
        item_order: null,
        expense_category: null,
        item_level_discount: 0,
        expense_category_id: null,
        is_item_discount_in_percentage: true,
      },
    ],

    tds_tcs_id: null,
    tds_tcs_amount: 0,
    selected_tds_tcs: null,
    tds_tcs_choice: "no_tax",

    has_rcm: false,

    is_draft: true,
    bill_title: "bill Test",
    bill_sub_heading: "bill Test",
    note: getNotesPreferences('bill'),

    is_entity_discount: true,
    tax_applied: getAmountArePreferences(),
    is_amount_tax_inclusive: getAmountArePreferences() === 'inclusive',
    currency_symbol: null,
    currency_code: null,
    exchange_rate: 1,
    newVendor: null,
    itemIndex: null,
    newItem: null,

    source_of_supply_id: null,
    selected_source_of_supply: null,
  };

  const [bill, setBill] = React.useState(bill_initial_data);

  React.useEffect(() => {
    const getBill = async () => {
      breadCrumbsStateChangeManager(dispatch, 'Edit');
      let res = await apiAction({
        method: "post",
        url: retrieve_bill(params.id),
        data: { business_id: getBusinessInfo().id },
      });
      const paymentRes = await apiAction({
        method: "post",
        url: list_payment_term(),
        data: {
          business_id: getBusinessInfo().id,
          sort_by: "name",
          sort_order: "A",
        },
      });
      if (res?.success && paymentRes.success) {
        const partyRes = await apiAction({
          method: "post",
          url: get_vendor(res?.result.vendor_id),
          data: { business_id: getBusinessInfo().id },
        });

        res?.result.bill_items.map((item) => {
          if (item.tax) {
            item["tax_id"] = item.tax.id;
            item["item"] = {
              name: item.item,
              id: item.item_id,
              gst_rate: item.tax.rate ? item.tax.rate : null,
            };
            item["expense_category_id"] = item.expense_category;
            item["expense_category"] = {
              name: item.expense_category_name,
              id: item.expense_category,
              account_name: item.expense_category_name,
            };
          } else {
            item["tax_id"] = null;
            item["item"] = {
              name: item.item,
              id: item.item_id,
              gst_rate: null,
            };
            item["expense_category_id"] = item.expense_category;
            item["expense_category"] = {
              name: item.expense_category_name,
              id: item.expense_category,
              account_name: item.expense_category_name,
            };
          }
        });

        let payment_item = paymentRes.result.find(
          (data) => data.id === res?.result.payment_term
        );
        setLoader(dispatch, Actions, false);
        setBill({
          ...bill,
          ...res?.result,
          vendor: partyRes?.result,
          payment_term: payment_item,
          payment_term_id: payment_item.id,
          tds_tcs_amount: res?.result.tds_tcs_amount,

          is_amount_tax_inclusive: res?.result.tax_applied === "inclusive" ? true : false,

          source_of_supply_id: res?.result.source_of_supply.id,
          selected_source_of_supply: res?.result.source_of_supply.id ? { ...res?.result.source_of_supply, name: res?.result.source_of_supply.state, } : null,
        });
      }
    };

    if (params.id) {
      setTimeout(() => {
        getBill();
      }, 1500);
    }
    // eslint-disable-next-line
  }, []);

  //Clone
  React.useEffect(() => {
    const getBill = async () => {
      let res = await apiAction({
        method: "post",
        url: retrieve_bill(params.clone_id),
        data: { business_id: getBusinessInfo().id },
      });
      const paymentRes = await apiAction({
        method: "post",
        url: list_payment_term(),
        data: {
          business_id: getBusinessInfo().id,
          sort_by: "name",
          sort_order: "A",
        },
      });
      if (res?.success && paymentRes.success) {
        const partyRes = await apiAction({
          method: "post",
          url: get_vendor(res?.result.vendor_id),
          data: { business_id: getBusinessInfo().id },
        });
        let payment_item = paymentRes.result.find(
          (data) => data.id === res?.result.payment_term
        );

        setLoader(dispatch, Actions, false);

        // eslint-disable-next-line
        res?.result.bill_items.map((item) => {
          if (item.tax) {
            item["tax_id"] = item.tax.id;
            item["item"] = {
              name: item.item,
              id: item.item_id,
              gst_rate: item.tax.rate ? item.tax.rate : null,
            };
            item["expense_category_id"] = item.expense_category;
            item["expense_category"] = {
              name: item.expense_category_name,
              id: item.expense_category,
              account_name: item.expense_category_name,
            };
          } else {
            item["tax_id"] = null;
            item["item"] = {
              name: item.item,
              id: item.item_id,
              gst_rate: null,
            };
            item["expense_category_id"] = item.expense_category;
            item["expense_category"] = {
              name: item.expense_category_name,
              id: item.expense_category,
              account_name: item.expense_category_name,
            };
          }
        });
        setBill({
          ...bill,
          ...res?.result,
          vendor: partyRes?.result,
          tds_tcs_amount: res?.result.tds_tcs_amount,
          payment_term_id: res?.result.payment_term,
          payment_term: payment_item,

          order_number: null,
          payment_status: null,
          bill_number: bill.bill_number,
          bill_date: moment().format("YYYY-MM-DD"),
          due_date: payment_item
            ? moment()
              .add(payment_item.number_of_days, "days")
              .format("YYYY-MM-DD")
            : bill.due_date,

          is_amount_tax_inclusive:
            res?.result.tax_applied === "inclusive" ? true : false,

          source_of_supply_id: res?.result.source_of_supply.id,
          selected_source_of_supply: res?.result.source_of_supply.id
            ? {
              ...res?.result.source_of_supply,
              name: res?.result.source_of_supply.state,
            }
            : null,
        });
      }
    };

    if (params.clone_id) {
      setTimeout(() => {
        getBill();
      }, 1500);
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (params.purchase_id) {
      const getPurchaseOrder = async () => {
        // console.log(partyRes)
        let res = await apiAction({
          method: "post",
          url: retrieve_purchase_order(params.purchase_id),
          data: { business_id: getBusinessInfo().id },
        });
        if (res?.success) {
          let item = res?.result;
          item.purchase_order_items.map((item) => {
            if (item.tax) {
              item["tax_id"] = item.tax.id;
              item["item"] = {
                name: item.item,
                id: item.item_id,
                gst_rate: item.tax.rate ? item.tax.rate : null,
              };
              item["expense_category_id"] = item.expense_category;
              item["expense_category"] = {
                name: item.expense_category_name,
                id: item.expense_category,
                account_name: item.expense_category_name,
              };
            } else {
              item["tax_id"] = null;
              item["item"] = {
                name: item.item,
                id: item.item_id,
                gst_rate: null,
              };
              item["expense_category_id"] = item.expense_category;
              item["expense_category"] = {
                name: item.expense_category_name,
                id: item.expense_category,
                account_name: item.expense_category_name,
              };
            }
            item["disabled"] = true;
          });

          const partyRes = await apiAction({
            method: "post",
            url: get_vendor(res?.result.vendor_id),
            data: { business_id: getBusinessInfo().id },
          });
          setLoader(dispatch, Actions, false);
          setBill({
            ...bill,
            ...item,
            vendor: partyRes?.result,
            payment_term: {
              name: item.payment_term_name,
              payment_term_id: item.payment_term,
              id: item.payment_term,
              number_of_days: item.payment_term_number_of_days
            },
            payment_term_id: item.payment_term,
            bill_items: item.purchase_order_items,
            due_date: item.payment_term_number_of_days
              ? moment()
                .add(item.payment_term_number_of_days, "days")
                .format("YYYY-MM-DD")
              : moment().format("YYYY-MM-DD"),

            order_number: item.purchase_order_number,
            sales_person_id: res?.result.sales_person,

            note: null,
            attachment: null,
            terms_and_condition: null,
            terms_and_conditions_id: null,

            source_of_supply_id: item.source_of_supply.id,
            selected_source_of_supply: item.source_of_supply.id
              ? { name: item.source_of_supply.state }
              : null,

            discount: item.discount,
            is_amount_tax_inclusive:
              item.tax_applied === "inclusive" ? true : false,

            payment_method: item.payment_method ? item.payment_method : "cash",
            purchase_order_id: item.id,
          });
        }
      };
      setTimeout(() => {
        getPurchaseOrder();
      }, 1500);

      // eslint-disable-next-line
    }
  }, []);

  // api call to set the default bill_number
  React.useEffect(() => {
    const apiResults = async () => {
      let res = await apiAction({
        method: "post",
        url: get_create_bill_meta_data(),
        data: { business_id: getBusinessInfo().id },
      });
      if (res?.success) {
        bill.bill_number = res?.result.bill_number;
        setBill({ ...bill, bill_number: res?.result.bill_number });
      } else {
      }
    };
    if (!params.id) {
      apiResults();
    }

    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    const fetchVendorData = async (id) => {
      setLoader(dispatch, Actions, true);

      let res = await apiAction({
        method: "post",
        url: get_vendor(id),
        data: { business_id: getBusinessInfo().id },
      });
      if (res?.success) {
        let vendor = res?.result;
        setBill({
          ...bill,
          vendor: vendor,
          vendor_id: vendor.id,
          payment_term: vendor.payment_term ? vendor.payment_term : null,
          payment_term_id: vendor.payment_term ? vendor.payment_term.id : null,
          mailing_address: vendor.billing_address
            ? getAddress(vendor.billing_address)
            : null,
          due_date: vendor.payment_term
            ? moment()
              .add(vendor.payment_term.number_of_days, "days")
              .format("YYYY-MM-DD")
            : moment().format("YYYY-MM-DD"),
          currency_symbol: vendor.currency ? vendor.currency.symbol : null,
          currency_code: vendor.currency ? vendor.currency.currency_code : null,
          payment_method: vendor.preferred_payment_method
            ? vendor.preferred_payment_method
            : "cash",
        });

        setLoader(dispatch, Actions, false);
      } else {
        setLoader(dispatch, Actions, false);
      }
    };

    if (params.vendor_id) {
      fetchVendorData(params.vendor_id);
    }
    // eslint-disable-next-line
  }, []);
  const onClear = () => {
    setClear(true);
    setFormSubmitted(false);
    setBill({ ...bill_initial_data, bill_number: bill.bill_number });
  };

  //////////////////////////////////////////////////////////////////////////////////////////
  const gst_registration_type = bill.vendor && bill.vendor.gst_registration_type;
  setIsVendorRegister(gst_registration_type);

  let is_Location = gst_registration_type === "overseas";
  let is_gst_with_zero = gst_registration_type === "overseas" || gst_registration_type === "sez";
  //////////////////////////////////////////////////////////////////////////////////////////
  const onSave = async ({ is_draft = false }) => {
    console.log("bill On save====>", bill);

    setFormSubmitted(true);
    const { tax_applied, bill_items, bill_date, due_date, tds_tcs_choice, has_rcm, is_amount_tax_inclusive } =
      bill;

    const is_rate = bill_items.find((item) => item.rate === null);
    const is_quantity = bill_items.find((item) => !item.quantity);
    const is_bill_item_not_selected = bill_items.find((item) => !item.item_id);
    const is_tax = bill_items.find(
      (item) => tax_applied !== "no_tax" && !item.tax_id
    );
    const is_expense_category_not_selected = bill_items.find(
      (item) => !item.expense_category_id
    );
    let validation_data = [
      { key: "vendor_id", message: "Please Select Vendor" },
      { key: "payment_term_id", message: "Please Select Terms" },
      { key: "bill_number", message: "Please Enter Bill Number" },
      isVendorRegister && !is_Location && {
        key: "source_of_supply_id",
        message: "Please Select Source of Supply",
      },
      { key: "bill_date", message: "Please Enter Valid Bill Date" },
      { key: "due_date", message: "Please Enter Valid Due Date" },
      {
        key: "",
        validation:
          bill_date &&
          due_date &&
          moment(bill_date).format("YYYY-MM-DD") >
          moment(due_date).format("YYYY-MM-DD"),
        due_date,
        message: "The Payment Due date can not be Before the Bill Date.",
      },
      // { key: "mailing_address", message: 'Please Enter Mailing Address' },
      {
        key: "",
        validation: is_bill_item_not_selected,
        message: "Please Select Item",
      },
      {
        key: "",
        validation: is_expense_category_not_selected,
        message: "Please Select Account",
      },
      {
        key: "",
        validation: is_quantity,
        message: "Please Enter the Quantity",
      },
      { key: "", validation: is_rate, message: "Please Enter the Rate" },
      { key: "", validation: is_tax, message: "Please Select the Tax" },
      tds_tcs_choice !== "no_tax" && {
        key: "tds_tcs_id",
        message: `Please Select the ${tds_tcs_choice.toLocaleUpperCase()} Tax`,
      },
      { key: "", validation: has_rcm && is_amount_tax_inclusive, message: "Cannot create a bill with Reverse Charge Mechanism (RCM) in a tax-inclusive case" },


      // { key: "", validation: is_bill_item_hsn_sac_empty, message: 'HASN/SAC code in all items required.' },
    ];

    const { isValid, message } = isFormValid(bill, validation_data);
    if (isValid) {
      if (is_draft) {
        bill.is_draft = true;
      } else {
        bill.is_draft = false;
      }
      if (isApplicableForTax(bill.tax_applied,isVendorRegister)) {
        setZero('isApplicableForTax');
      } else if (bill.total_amount === 0) {
        setZero('withZero');
      } else {
        onSaveAction();
      }
    } else {
      stateChangeManager(dispatch, Actions, true, "error", message);
    }
  };

  const saveEvents = (is_draft) => {
    if (params.id) {
      AnalyticsEvent(eventsNames.categories.BILLS, { action: eventsNames.actions.EDIT })
    } else if (is_draft) {
      AnalyticsEvent(eventsNames.categories.BILLS, { action: eventsNames.actions.CREATE, state: eventsNames.actions.bill.state.DRAFT })
    } else {
      AnalyticsEvent(eventsNames.categories.BILLS, { action: eventsNames.actions.CREATE, state: eventsNames.actions.bill.state.SAVE })
    }
  };

  const onSaveAction = async () => {
    saveEvents(bill.is_draft)
    setLoader(dispatch, Actions, true);
    let res = await apiFormData({
      method: "post",
      data: bill,
      url: params.id ? update_bill(params.id) : create_bill(),
    });
    if (res?.success) {
      redirect(res?.result.id);
      setLoader(dispatch, Actions, false);
      stateChangeManager(dispatch, Actions, true, "success", res?.status);
    } else {
      setLoader(dispatch, Actions, false);
      stateChangeManager(dispatch, Actions, true, "error", res?.status);
    }
  };
  const redirect = (id) => {
    if (onClose) {
      onClose(id);
    } else {
      navigate(routesName.invoicingPurchaseBillView.path + "?id=" + id);
    }
  };

  const onAddVendor = (vendor) => {
    setBill({ ...bill, newVendor: vendor });
  };

  const onAddItem = (value) => {
    // Construct the new item data
    const newItemData = {
      item: value,
      quantity: 1,
      item_order: null,
      item_id: value.id,
      unit_id: value?.unit?.id,
      amount: value.cost_price,
      description: value.description,
      hsn_sac_code: value.hsn_sac_code,
      rate: parseFloat(value.cost_price),
      item_level_discount: value.item_level_discount || 0,
      is_item_discount_in_percentage: checkItemDiscount(bill?.bill_items),

      expense_category: value.purchase_account,
      expense_category_id: value.purchase_account?.id,

      gst_rate: value?.tax?.rate,
      tax: bill.tax_applied === "no_tax" ? null : value?.tax,
      tax_id: bill.tax_applied === "no_tax" ? null : value?.tax?.id,
    };

    // Update the sales order items
    bill.bill_items[itemIndex] = newItemData;

    setNewItem(newItemData);

    // Update the sales order state
    setBill(prevOrder => ({
      ...prevOrder,
      newItem: value,
      bill_items: [...prevOrder.bill_items],
    }));
  };

  // Common api call for the Accounts
  const [NewAccountsResult, setNewAccountsResult] = React.useState();

  const onAddAccount = (value) => {
    bill.bill_items[itemIndex] = {
      ...bill.bill_items[itemIndex],
      expense_category: value,
      expense_category_id: value.id,
    };
    setNewAccountsResult(value);
    setBill({ ...bill, bill_items: [...bill.bill_items] });
  };

  const accounts = applyTaxOnMultipleGSTComponents(
    bill,
    "bill_items",
    "input_"
  );

  const totalAmount = basePriceTotal(bill, bill.bill_items);

  const totalTax = Object.values(accounts)
    .reduce((acc, tax) => acc + tax, 0)
    .toFloat();

  // totalTax = (bill.has_rcm ? 0 : totalTax)

  const totalDiscount = totalDiscountOnBasePrice(
    bill,
    bill.bill_items
  ).toFloat();

  const subTotal = bill.is_amount_tax_inclusive
    ? bill.bill_items
      .filter((item) => isNumeric(item.amount))
      .reduce((a, b) => a + parseFloat(b.amount), 0)
      .toFloat()
    : totalAmount.toFloat();

  let tds_tcs_amount = totalTdsTcsAmount(
    bill.selected_tds_tcs,
    bill.tds_tcs_choice,
    bill.is_amount_tax_inclusive ? totalAmount : subTotal,
    totalDiscount,
    totalAmount - totalDiscount + (bill.has_rcm ? 0 : totalTax)
  );

  bill.tds_tcs_amount = tds_tcs_amount;

  let tempFinalPayment = (
    totalAmount -
    totalDiscount +
    (bill.has_rcm ? 0 : totalTax)
  ).toFloat();

  const finalPayment =
    bill.tds_tcs_choice === "tds"
      ? (tempFinalPayment - tds_tcs_amount).toFloat()
      : bill.tds_tcs_choice === "tcs"
        ? (tempFinalPayment + tds_tcs_amount).toFloat()
        : tempFinalPayment;

  bill.tax = {
    ...accounts,
    total: parseFloat(parseFloat(totalTax).toFixed(2)),
  };

  bill.discount = totalDiscount.toFloat();

  bill.subtotal = totalAmount.toFloat();

  bill.total_amount = finalPayment;

  return (
    <div>
      <CustomDialog
        state={state}
        setState={setState}
        itemIndex={bill.itemIndex}
        onAddItem={(item) => onAddItem(item)}
        onAddVendor={(vendor) => onAddVendor(vendor)}
        onAddAccount={(account) => onAddAccount(account)}
      />
      <CommonAlertBox
        open={zero}
        setOpen={setZero}
        onSaveAction={() => {
          if (zero==='withZero') {
            onSaveAction();
          }else if (bill.total_amount === 0) {
            setTimeout(()=>{
              setZero('withZero');
            },100)
          } else{
            onSaveAction();
          }
        }}
        title={`Bill No. ${bill.bill_number ? bill.bill_number : ""}`}
        message={
          zero==='withZero'?
          `You are about to create the bill with zero amount. Are you sure you want to proceed?`
          :
          `Please consult the relevant provisions of the laws to determine the application of taxes for GST unregistered entities.`
        }
      />
      <CustomTitleContainer>
        <Grid
          container
          spacing={0}
          style={{
            alignItems: "center",
            justifyItems: "center",
            alignContent: "space-evenly",
            justifyContent: "space-evenly",
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: "-webkit-flex", justifyContent: "start" }}
          >
            <CustomTitle
              title={`Bill No. ${bill.bill_number ? bill.bill_number : ""}`}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: "-webkit-flex", justifyContent: "end" }}
          >
            <CustomTypography
              text={
                <span>
                  Balance Due:{" "}
                  <span style={{ fontWeight: "700", color: "#000000" }}>
                    <CurrencyFormatter
                      amount={bill.total_amount}
                      currency={bill.currency_code}
                    />
                  </span>
                </span>
              }
              sx={{
                fontSize: "16px",
                color: "#7A7A7A",
                fontWeight: "600",
                textAlign: "right",
                lineHeight: "22px",
                fontStyle: "normal",
                fontFamily: "Noto Sans",
              }}
            />
          </Grid>
        </Grid>
      </CustomTitleContainer>

      <CustomContainer maxWidth={"400"} sx={{}}>
        <form noValidate>
          <div style={{ borderBottom: "4px solid #F5F5F5" }}>
            <VendorDetails
              bill={bill}
              open={state.open}
              setBill={setBill}
              is_Location={is_Location}
              isFormSubmitted={isFormSubmitted}
              is_gst_with_zero={is_gst_with_zero}
              addButtonHandler={addButtonHandler}
              vendor={props.vendor ? props.vendor : null}
            />
          </div>

          <ProductAndServicesDetails
            bill={bill}
            setBill={setBill}
            data={bill.bill_items}
            is_Location={is_Location}
            isFormSubmitted={isFormSubmitted}
            is_gst_with_zero={is_gst_with_zero}
            addButtonHandler={addButtonHandler}
            NewAccountsResult={NewAccountsResult}
            accounts={accounts}
            totalAmount={totalAmount}
            totalTax={totalTax}
            totalDiscount={totalDiscount}
            subTotal={subTotal}
            tds_tcs_amount={tds_tcs_amount}
            finalPayment={finalPayment}
          />
        </form>
      </CustomContainer>

      <CustomButtonContainer>
        <Buttons
          bill={bill}
          onSave={onSave}
          vendor={vendor}
          onClear={onClear}
          is_Location={is_Location}
          onCancel={() => navigate(-1)}
          setFormSubmitted={setFormSubmitted}
          is_gst_with_zero={is_gst_with_zero}
        />
      </CustomButtonContainer>
    </div>
  );
};

const VendorDetails = (props) => {
  const {
    is_gst_with_zero,
    is_Location,
    open,
    bill,
    setBill,
    addButtonHandler,
    isFormSubmitted,
    isClear,
    isFromCustomer,
    vendor,
  } = props;

  let location = useLocation();
  const params = getQueryParams(location.search);

  const onChange = (key_name, value) => {
    setBill({ ...bill, [key_name]: value });
  };

  const [defaultTerm, setDefaultTerm] = React.useState();
  const onFetchTerms = (term) => {
    if (term && Object.keys(params).length === 0 && (!isFromCustomer || !vendor.payment_term)
    ) {
      setTimeout(() => {
        setBill((previous) => ({
          ...previous,
          payment_term: term,
          payment_term_id: term.id,
          due_date: moment()
            .add(term.number_of_days, "days")
            .format("YYYY-MM-DD"),
        }));
      }, 1000);
    }
  };

  React.useEffect(() => {
    if (bill.newVendor) {
      vendorMappings(bill.newVendor, bill, setBill);
    }
    // eslint-disable-next-line
  }, [bill.newVendor]);

  return (
    <Box
      style={{
        paddingLeft: "24px",
        paddingRight: "24px",
        paddingBottom: "32px",
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4.5}>
          <CommonAsyncDropdown
            autoFocus={true}
            autoSelect={false}
            id={"vendor_dropdown"}
            dataTestId={"vendor_dropdown"}
            disableClearable={false}
            optionLabel="display_name"
            placeholder="Select Vendor"
            noOptionsText={"No result found"}
            validation={isFormSubmitted && !bill.vendor_id}
            value={vendor}
            item={{
              open: open,
              method: "post",
              value: bill.vendor,
              url: list_vendor(1),
              label: <LabelWithAsteriskMark label={"Vendor"} />,
              sx: !bill.is_draft ? { background: "#F1F3F4" } : {},
              body: {
                is_inactive: false,
                business_id: getBusinessInfo().id,
                role: "vendor",
              },
              disabled: (!params.clone_id && !bill.is_draft && Object.keys(params).length !== 0),

              onChange: async (event, newValue) => {
                if (newValue) {
                  if (newValue.id !== bill.vendor_id) {
                    const vendorRes = await apiAction({
                      method: "post",
                      url: get_vendor(newValue.id),
                      data: { business_id: getBusinessInfo().id },
                    });
                    if (vendorRes.success) {
                      vendorMappings(vendorRes.result, bill, setBill);
                    }
                  }
                } else {
                  vendorMappings(newValue, bill, setBill);
                }
              },
            }}
            addButton={{
              title: "+ Add new vendor",
              onClick: () => addButtonHandler("New Vendor", "new_vendor", "lg"),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4.5}>
          <ListTerms
            id={"payment_term_dropdown"}
            dataTestId={"payment_term_dropdown"}
            isClear={isClear}
            setTerms={(key_name, value, item) => {
              if (value) {
                setBill({
                  ...bill,
                  [key_name]: value,
                  payment_term: item,
                  due_date:
                    item.number_of_days !== null
                      ? moment(bill.bill_date)
                        .add(item.number_of_days, "days")
                        .format("YYYY-MM-DD")
                      : null,
                });
              } else {
                setBill({
                  ...bill,
                  [key_name]: value,
                  payment_term: item,
                  due_date: bill.bill_date,
                });
              }
            }}
            value={bill.payment_term}
            onFetchTerms={onFetchTerms}
            validation={isFormSubmitted}
            defaultTerm={bill.payment_term}
            onDueDateChange={setDefaultTerm}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Input
            id={"input_bill_number"}
            dataTestId={"input_bill_number"}
            item={{
              type: "text",
              required: true,
              fullWidth: true,
              texttransform: "none",
              inputProps: { maxLength: 16 },
              placeholder: "Enter Bill Number",
              value: bill.bill_number ? bill.bill_number : "",
              title: <LabelWithAsteriskMark label={"Bill Number"} />,
              validation: isFormSubmitted && !bill.bill_number,
              onChange: (e) => onChange("bill_number", e.target.value),
            }}
          />
        </Grid>
        {/*//////////////////////////////// Source of supply ////////////////////////////////*/}
        <Grid item xs={12} sm={4.5}>
          <CommonDropdown
            stateComponent={true}
            disableClearable={false}
            placeholder="Select a Location"
            id={"source_of_supply_dropdown"}
            dataTestId={"source_of_supply_dropdown"}
            validation={
              isVendorRegister &&
              !is_Location &&
              isFormSubmitted &&
              !bill.source_of_supply_id
            }
            item={{
              method: "post",
              textTransform: "none",
              url: get_list_of_state(),
              disabled: is_Location,
              value: bill.selected_source_of_supply,
              body: { business_id: getBusinessInfo().id },
              label: <LabelWithAsteriskMark label={"Source of Supply"} isAsteriskMark={isVendorRegister} />,
              onChange: (event, value) => {
                if (value) {
                  setBill({
                    ...bill,
                    selected_source_of_supply: value,
                    source_of_supply_id: value.id,
                  });
                } else {
                  setBill({
                    ...bill,
                    selected_source_of_supply: null,
                    source_of_supply_id: null,
                  });
                }
              },
            }}
          />
        </Grid>

        <Grid item xs={12} sm={2.25}>
          <DatePicker
            id={"bill_date_select"}
            dataTestId={"bill_date_select"}
            placeholder=""
            validation={isFormSubmitted && !bill.bill_date}
            title={<LabelWithAsteriskMark label={"Bill Date"} />}
            date={bill.bill_date ? moment(bill.bill_date) : null}
            setDate={(date) => {
              if (date) {
                setBill({
                  ...bill,
                  bill_date: date.format("YYYY-MM-DD"),
                  due_date: bill.payment_term && bill.payment_term.number_of_days ?
                    moment(date.format("YYYY-MM-DD")).add(bill.payment_term.number_of_days, "days").format("YYYY-MM-DD")
                    : date.format("YYYY-MM-DD") > moment(bill.due_date).format("YYYY-MM-DD") ? date.format("YYYY-MM-DD") : bill.due_date,
                });
              } else {
                setBill({ ...bill, bill_date: null });
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={2.25}>
          {bill.bill_date ? (
            <DatePicker
              id={"due_date_select"}
              dataTestId={"due_date_select"}
              placeholder=""
              minDate={moment(bill.bill_date)}
              validation={isFormSubmitted && !bill.due_date}
              title={<LabelWithAsteriskMark label={"Due date"} />}
              date={bill.due_date ? moment(bill.due_date) : null}
              setDate={(date) => {
                setBill({
                  ...bill,
                  due_date: date ? date.format("YYYY-MM-DD") : null,
                  payment_term_id: defaultTerm ? defaultTerm.id : null,
                  payment_term: defaultTerm,
                });
              }}
            />
          ) : (
            <DatePicker
              id={"due_date_select"}
              dataTestId={"due_date_select"}
              placeholder=""
              validation={isFormSubmitted && !bill.due_date}
              title={<LabelWithAsteriskMark label={"Due date"} />}
              date={bill.due_date ? moment(bill.due_date) : null}
              setDate={(date) => {
                setBill({
                  ...bill,
                  due_date: date ? date.format("YYYY-MM-DD") : null,
                  payment_term_id: defaultTerm ? defaultTerm.id : null,
                  payment_term: defaultTerm,
                });
              }}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={3}>
          <Input
            id={"input_order_number"}
            dataTestId={"input_order_number"}
            item={{
              type: "text",
              required: true,
              fullWidth: true,
              texttransform: "none",
              title: "Purchase Order Number",
              placeholder: "Enter Purchase Order Number",
              value: bill.order_number ? bill.order_number : "",
              onChange: (e) => onChange("order_number", e.target.value),
            }}
          />
        </Grid>

        <Grid item xs={12} sm={4.5}>
          <Input
            id={"input_mailing_address"}
            dataTestId={"input_mailing_address"}
            item={{
              rows: 4,
              type: "text",
              required: true,
              multiline: true,
              fullWidth: true,
              title: "Mailing Address",
              placeholder: "Enter Mailing Address",
              // validation: isFormSubmitted && !bill.mailing_address,
              value: bill.mailing_address ? bill.mailing_address : "",
              onChange: (e) => onChange("mailing_address", e.target.value),
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const ProductAndServicesDetails = (props) => {
  const {
    NewAccountsResult,
    is_gst_with_zero,
    open,
    bill,
    setBill,
    addButtonHandler,
    newItem,
    itemIndex,
    isFormSubmitted,
    accounts,
    totalAmount,
    totalTax,
    totalDiscount,
    subTotal,
    finalPayment,
  } = props;

  const [data, setData] = React.useState(bill.bill_items);

  const onAddLine = () => {
    bill.bill_items.push({
      item: null,
      tax: null,
      rate: null,
      quantity: 1,
      tax_id: null,
      amount: null,
      item_id: null,
      unit_id: null,
      editable: false,
      item_order: null,
      hsn_sac_code: null,
      expense_category: null,
      item_level_discount: 0,
      expense_category_id: null,
      is_item_discount_in_percentage: checkItemDiscount(bill?.bill_items),
    });
    setData([...bill.bill_items]);
    setBill({ ...bill });
  };

  const onDeleteLine = (index) => {
    if (bill.bill_items.length > 1) {
      bill.bill_items.splice(index, 1);
      setBill({ ...bill, bill_items: bill.bill_items });
      setData([...bill.bill_items]);
    } else {
      setBill({
        ...bill,
        bill_items: [
          {
            item: null,
            tax: null,
            rate: null,
            quantity: 1,
            tax_id: null,
            amount: null,
            item_id: null,
            unit_id: null,
            editable: false,
            item_order: null,
            hsn_sac_code: null,
            item_level_discount: 0,
            is_item_discount_in_percentage: checkItemDiscount(bill?.bill_items),
          },
        ],
      });
      setData([
        {
          item: null,
          tax: null,
          rate: null,
          quantity: 1,
          tax_id: null,
          amount: null,
          item_id: null,
          unit_id: null,
          editable: false,
          item_order: null,
          hsn_sac_code: null,
          item_level_discount: 0,
          is_item_discount_in_percentage: checkItemDiscount(bill?.bill_items),
        },
      ]);
    }
  };

  const onClearLines = () => {
    setData([
      {
        item: null,
        tax: null,
        rate: null,
        quantity: 1,
        tax_id: null,
        amount: null,
        item_id: null,
        unit_id: null,
        editable: false,
        item_order: null,
        hsn_sac_code: null,
        item_level_discount: 0,
        is_item_discount_in_percentage: checkItemDiscount(bill?.bill_items),
      },
    ]);
  };

  React.useEffect(() => {
    bill.bill_items = data;
    bill.total_amount = finalPayment;
    bill.subtotal = totalAmount.toFloat();
    bill.discount = totalDiscount.toFloat();
    // bill.total_amount = bill.tds_tcs_choice === 'tds' ? (((subTotal - totalDiscount) - bill.tds_tcs_amount) + (bill.has_rcm ? 0 : totalTax)).toFloat() : bill.tds_tcs_choice === 'tcs' ? ((totalAmount + (bill.has_rcm ? 0 : totalTax) - totalDiscount) + bill.tds_tcs_amount).toFloat() : (totalAmount + (bill.has_rcm ? 0 : totalTax) - totalDiscount).toFloat();
    setBill({ ...bill });
    // eslint-disable-next-line
  }, [data]);

  React.useEffect(() => {
    // bill.total_amount = finalPayment
    // bill.total_amount = bill.tds_tcs_choice === 'tds' ? (((subTotal - totalDiscount) - bill.tds_tcs_amount) + (bill.has_rcm ? 0 : totalTax)).toFloat() : bill.tds_tcs_choice === 'tcs' ? ((totalAmount + (bill.has_rcm ? 0 : totalTax) - totalDiscount) + bill.tds_tcs_amount).toFloat() : (totalAmount + (bill.has_rcm ? 0 : totalTax) - totalDiscount).toFloat();
    setBill({ ...bill });
    // eslint-disable-next-line
  }, [bill.has_rcm]);

  const hasRCM = (has_rcm) => {
    bill.has_rcm = has_rcm;
    setBill({ ...bill });
    onAmountsAreInChange({ name: "Exclusive", value: "exclusive" })
    // bill.total_amount = finalPayment;
    // bill.total_amount = bill.tds_tcs_choice === 'tds' ? (((subTotal - totalDiscount) - bill.tds_tcs_amount) + (has_rcm ? 0 : (bill.has_rcm ? 0 : totalTax))).toFloat() : bill.tds_tcs_choice === 'tcs' ? ((totalAmount + (has_rcm ? 0 : (bill.has_rcm ? 0 : totalTax)) - totalDiscount) + bill.tds_tcs_amount).toFloat() : (totalAmount + (has_rcm ? 0 : (bill.has_rcm ? 0 : totalTax)) - totalDiscount).toFloat();
  };

  if (!("input_IGST" in bill.tax)) {
    bill.tax["input_IGST"] = 0;
  }
  if (!("input_SGST" in bill.tax)) {
    bill.tax["input_SGST"] = 0;
  }
  if (!("input_CGST" in bill.tax)) {
    bill.tax["input_CGST"] = 0;
  }

  // console.log("totalAmount ", totalAmount, " totalDiscount ", totalDiscount, " totalTax ", totalTax, totalAmount + totalTax - totalDiscount, bill)

  const filterList = [
    { name: "Exclusive", value: "exclusive" },
    { name: "Inclusive", value: "inclusive" },
    { name: "No Tax", value: "no_tax" },
  ];

  const onAmountsAreInChange = (selected_type) => {
    let previous = bill.tax_applied;
    if (previous !== selected_type.value) {
      if (selected_type.value === "inclusive") {
        bill.is_amount_tax_inclusive = true;
        convertExclusiveToInclusive(bill.bill_items);
        bill.total_amount = totalAmount - totalDiscount;
      } else if (selected_type.value === "no_tax") {
        if (previous === filterList[1].value) {
          convertInclusiveToExclusive(bill.bill_items);
        }
        // eslint-disable-next-line
        bill.bill_items.map((bill_item) => {
          bill_item.tax = null;
          bill_item.tax_id = null;
        });
        bill.is_amount_tax_inclusive = false;
        bill.total_amount = totalAmount - totalDiscount;
      } else {
        bill.is_amount_tax_inclusive = false;
        convertInclusiveToExclusive(bill.bill_items);
      }
      setBill({ ...bill, tax_applied: selected_type.value });
      setData([...bill.bill_items]);
    }
  };

  const [isDiscountFiled, setIsDiscountFiled] = React.useState(getDiscountPreferences());
  React.useEffect(() => {
    if (bill.discount) {
      setIsDiscountFiled(getDiscountPreferences() || (bill.discount ? true : false))
    }
  }, [bill.discount]);

  return (
    <div>
      <Box style={{ paddingRight: "20px" }}
        sx={{ pb: 2, pt: 2 }}>
        <Grid
          container
          spacing={0}
          style={{
            alignItems: "center",
            justifyItems: "center",
            alignContent: "space-evenly",
            justifyContent: "space-evenly",
          }}
        >
          <Grid item xs={12} sm={6} sx={{ display: { xs: '', sm: '-webkit-flex' }, justifyContent: "start" }}
          >
            {isBusinessRegistered() && (

              <Box sx={{ display: 'flex', alignItems: 'center', mb: -3 }}>
                <Checkbox
                  id={"has_rcm_checkbox"}
                  dataTestId={"has_rcm_checkbox"}
                  sx={{}}
                  checked={bill.has_rcm}
                  onChange={(event) => {
                    hasRCM(event.target.checked);
                  }}
                />
                <CustomTypography
                  text={'This transaction is applicable for reverse charge'}
                  sx={{
                    fontSize: "14px",
                    fontWeight: "700",
                    lineHeight: "19px",
                    letterSpacing: "0px",
                    fontFamily: "Noto Sans",
                  }}
                />
              </Box>
            )}

          </Grid>

          <Grid item xs={12} sm={6} sx={{ display: { xs: '', sm: '-webkit-flex' }, justifyContent: "end" }}
          >
            <CommonCurrencySelection
              data={bill}
              isCurrency={true}
              isAmountAre={true}
              setData={setBill}
              dropdown_key='vendor_id'
              isFormSubmitted={isFormSubmitted}
              onAmountsAreInChange={onAmountsAreInChange}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mb: 2 }}>
        <CustomTableContainer sx={{ overflowX: 'hidden' }} className="custom_table_css">
          <CustomTable>
            <ProductAndServicesDetailsHeaders isDiscountFiled={isDiscountFiled} data={bill} setData={setBill} />
            <ProductAndServicesDetailsBody
              isDiscountFiled={isDiscountFiled}
              open={open}
              bill={bill}
              setData={setData}
              setBill={setBill}
              newItem={newItem}
              itemIndex={itemIndex}
              data={bill.bill_items}
              finalPayment={finalPayment}
              onClearLines={onClearLines}
              onDeleteLine={onDeleteLine}
              isFormSubmitted={isFormSubmitted}
              addButtonHandler={addButtonHandler}
              is_gst_with_zero={is_gst_with_zero}
              NewAccountsResult={NewAccountsResult}
            />
          </CustomTable>
        </CustomTableContainer>
      </Box>

      <Grid
        container
        style={{
          paddingBottom: "24px",
          paddingLeft: "24px",
          paddingRight: "24px",
        }}
      >
        <Grid item Container xs={12} sm={6}>
          <Grid item xs={12}>
            <CustomTypography
              id={"add_action"}
              dataTestId={"add_action"}
              text={"+ Add Line"}
              onClick={onAddLine}
              sx={{
                fontSize: "12px",
                color: "#2464EB",
                cursor: "pointer",
                display: "inline",
                fontWeight: "700",
                lineHeight: "16px",
                fontStyle: "normal",
                textTransform: "none",
                fontFamily: "Noto Sans",
                ":hover": { textDecoration: "underline" },
              }}
            />
            <CustomTypography
              text={"|"}
              sx={{
                mr: 1,
                ml: 1,
                fontSize: "12px",
                color: "#B8B8B8",
                fontWeight: "700",
                lineHeight: "16px",
                fontStyle: "normal",
                display: "inline",
                textTransform: "none",
                fontFamily: "Noto Sans",
              }}
            />
            <CustomTypography
              id={"clear_action"}
              dataTestId={"clear_action"}
              text={"Clear All Lines"}
              onClick={onClearLines}
              sx={{
                fontSize: "12px",
                color: "#2464EB",
                cursor: "pointer",
                display: "inline",
                fontWeight: "700",
                lineHeight: "16px",
                fontStyle: "normal",
                textTransform: "none",
                fontFamily: "Noto Sans",
                ":hover": { textDecoration: "underline" },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={9} sx={{ mt: 3 }}>
            <Input
              id={"input_note"}
              dataTestId={"input_note"}
              item={{
                rows: 4,
                type: "text",
                fullWidth: true,
                required: true,
                multiline: true,
                title: "Notes",
                texttransform: "none",
                placeholder: "Enter Notes",
                value: bill.note ? bill.note : "",
                onChange: (event) => {
                  setBill({ ...bill, note: event.target.value });
                },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={9} sx={{ mt: 3 }}>
            <InputFile
              id={"attachment_action"}
              dataTestId={"attachment_action"}
              onFileUpload={(file) => setBill({ ...bill, attachment: file })}
              attachment={bill.attachment}
              onFileRemove={() => {
                setBill({ ...bill, attachment: null });
              }}
            />
          </Grid>
        </Grid>
        <Grid item Container xs={12} sm={6}>
          <CommonCalculation
            data={bill}
            isTcsTds={true}
            setData={setBill}
            isCurrency={true}
            dropdown_key='vendor_id'
            isFormSubmitted={isFormSubmitted}

            accounts={accounts}
            subTotal={subTotal}
            totalTax={totalTax}
            totalAmount={totalAmount}
            finalPayment={finalPayment}
            totalDiscount={totalDiscount}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const ProductAndServicesDetailsHeaders = (props) => {
  const { data, setData, isDiscountFiled } = props;
  return (
    <CustomTableHead>
      <CustomTableRow>
        <CustomTableHeadCell style={{ width: 5 }} align="left">  <span>#</span></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 310 }} align="left">   <span>Goods/Service</span> </CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 275 }} align="left">   <span>Account</span> </CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 185 }} align="left">  <Common.QuantityLabelComponent data={data} setData={setData} items='bill_items' /></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 175 }} align="left">  <Common.RateLabelComponent data={data} setData={setData} items='bill_items' /></CustomTableHeadCell>

        {isDiscountFiled &&
          <CustomTableHeadCell style={{ width: 100 }} align="left">  <Common.DiscountLabelComponent data={data} setData={setData} items='bill_items' /></CustomTableHeadCell>
        }

        <CustomTableHeadCell style={{ width: 200 }} align="left">
          <span>
            Tax{" "}
            {data?.has_rcm ? (
              <span style={{ fontSize: "10px", opacity: 0.6 }}>
                ( REVERSE CHARGE )
              </span>
            ) : (
              ""
            )}
          </span>
        </CustomTableHeadCell>

        <CustomTableHeadCell style={{ width: 145 }} align="left">  <span>Tax Amount</span></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 145 }} align="left">  <span>Amount</span></CustomTableHeadCell>

        <CustomTableHeadCell style={{ width: 5 }} align="right" />
      </CustomTableRow>
    </CustomTableHead>
  );
};

const ProductAndServicesDetailsBody = (props) => {
  const {
    isDiscountFiled,
    is_gst_with_zero,
    open,
    newItem,
    itemIndex,
    data,
    onDeleteLine,
    isFormSubmitted,
    setData,
    addButtonHandler,
    bill,
    NewAccountsResult,
    setBill,
    finalPayment,
  } = props;
  React.useEffect(() => {
    setData(data);
  }, []);

  let location = useLocation();
  const params = getQueryParams(location.search);
  const dispatch = Actions.getDispatch(useContext);
  const [taxResult, setTaxResult] = React.useState([]);
  let taxApplied = bill.tax_applied;
  const source_of_supply_id = bill.source_of_supply_id;
  const taxList = filterTax(taxResult, source_of_supply_id, is_gst_with_zero);

  React.useEffect(() => {
    const apiResults = async () => {
      let res = await apiAction({
        method: "post",
        url: get_list_of_tax(),
        data: {
          business_id: getBusinessInfo().id,
        },
      });
      if (res?.success) {
        setTaxResult(res?.result);
      } else {
      }
    };
    apiResults();
    // eslint-disable-next-line
  }, []);

  const apiItemResults = async (id, index) => {
    let res = await apiAction({
      method: "post",
      url: get_item(id),
      data: { business_id: getBusinessInfo().id },
    });
    if (res?.success) {
      onItemSelect(res?.result, index);
    } else {
    }
  };

  const onItemSelect = (value, index) => {
    const getDefaultTax = () => {
      return findDefaultTax(taxList, false, value)
    };

    const getItemData = () => ({
      item: value,
      item_order: null,
      unit_id: value.unit?.id,
      quantity: value.quantity || 1,
      description: value.description,
      hsn_sac_code: value.hsn_sac_code,
      item_id: value.item_id || value.id,
      amount: value.amount || value.cost_price,
      rate: parseFloat(value.rate || value.cost_price),
      item_level_discount: value.item_level_discount || 0,
      is_item_discount_in_percentage: checkItemDiscount(bill?.bill_items),

      gst_rate: getDefaultTax()?.rate,
      tax: taxApplied === "no_tax" ? null : getDefaultTax(),
      tax_id: taxApplied === "no_tax" ? null : getDefaultTax()?.id,

      expense_category: value.purchase_account || value.expense_category,
      expense_category_id: value.purchase_account?.id || value.expense_category_id,
    });

    const updateData = () => {
      data[index] = value ? getItemData() : {
        tax: null,
        item: null,
        rate: null,
        quantity: 1,
        amount: null,
        tax_id: null,
        item_id: null,
        unit_id: null,
        item_order: null,
        expense_category: null,
        item_level_discount: 0,
        expense_category_id: null,
        is_item_discount_in_percentage: checkItemDiscount(bill?.bill_items),
      };
    };

    const isAmountRangeExceeded = () => isFinalAmountRangeCrossed({
      quantity: data[index]?.quantity,
      rate: data[index]?.rate,
      item_level_discount: data[index].item_level_discount,
      item: data[index],
      invoice: bill,
      finalPayment: data.length <= 1
        ? 0
        : finalPayment - (data[index]?.amount || 0) - (data[index]?.tax_amount || 0),
    });
    if (isAmountRangeExceeded()) {
      stateChangeManager(
        dispatch,
        Actions,
        true,
        "error",
        "Amount range max limit reached"
      );
    } else {
      updateData();
      setData([...data]);
      setBill({ ...bill, bill_items: [...data] });
    }
  };

  React.useEffect(() => {

    const getDefaultTax = (value) => {
      return findDefaultTax(taxList, false, value);
    };

    const updatedData = data.map((item) => {
      const defaultTax = getDefaultTax(item);
      const isDefaultSelectTax = item.tax_id;

      // Calculate values based on conditions
      const gstRate = isDefaultSelectTax ? defaultTax?.rate ?? null : null;
      const tax = isDefaultSelectTax ? (taxApplied === "no_tax" ? null : defaultTax) : null;
      const taxId = isDefaultSelectTax ? (taxApplied === "no_tax" ? null : defaultTax?.id ?? null) : null;

      return {
        ...item,
        tax: tax,
        tax_id: taxId,
        gst_rate: gstRate,
      }
    });

    setData(updatedData);
    setBill((prev) => ({
      ...prev,
      bill_items: updatedData
    }));

    // eslint-disable-next-line
  }, [is_gst_with_zero, source_of_supply_id, taxApplied]);

  return (
    <CustomTableBody>
      {bill.bill_items.map((item, index) => {
        item['item_order'] = index + 1;

        return (
          <CustomTableRow key={index}>
            <CustomTableBodyCell sx={{}} align="left">
              <span>{index + 1}</span>
            </CustomTableBodyCell>

            <CustomTableBodyCell sx={{}} align="left">
              <CommonAsyncDropdown
                id={"item_dropdown_" + index}
                dataTestId={"item_dropdown_" + index}
                newItem={newItem}
                optionLabel="name"
                autoSelect={false}
                itemIndex={itemIndex}
                disableClearable={false}
                noOptionsText={"No result found"}
                placeholder="Select Goods/Service"
                validation={isFormSubmitted && !data[index].item_id}
                item={{
                  open: open,
                  method: "post",
                  url: list_items(1),
                  disabled: item.disabled,
                  value: data[index].item,
                  onChange: (event, value) => {
                    if (value) {
                      data[index].item = value.name;
                      setData([...data]);
                      apiItemResults(value.id, index);
                    } else {
                      onItemSelect(value, index);
                    }
                  },
                  body: {
                    role: "vendor",
                    business_id: getBusinessInfo().id,
                    for_purchase: true,
                  },
                }}
                addButton={{
                  title: "+ Add an item",
                  onClick: () =>
                    addButtonHandler(
                      "New Item",
                      "new_pas",
                      "lg",
                      "product",
                      index
                    ),
                }}
              />
            </CustomTableBodyCell>

            <CustomTableBodyCell sx={{}} align="left">
              <div>
                <CustomTypography
                  text={''}
                  sx={{
                    mb: 1,
                    fontSize: '14px',
                    color: '#000000',
                    fontWeight: '700',
                    lineHeight: '18px',
                    fontStyle: 'normal',
                    textTransform: "none",
                    fontFamily: "Noto Sans",
                  }}
                />
                <AsyncDropdown
                  sx={{}}
                  fullWidth={true}
                  autoFocus={false}
                  disabledCloseIcon={false}
                  isGroupHeaderSticky={true}
                  newResults={NewAccountsResult}
                  id={'expense_category_dropdown_' + index}
                  dataTestId={'expense_category_dropdown_' + index}
                  validation={isFormSubmitted && !data[index]?.expense_category_id}

                  selectedValue={data[index]?.expense_category}
                  setSelectedValue={(value) => {
                    if (value) {
                      data[index].expense_category = value;
                      data[index].expense_category_id = value.id;
                    } else {
                      data[index].expense_category = null;
                      data[index].expense_category_id = null;
                    }
                    setData([...data]);
                  }}

                  valueLabelKey='id'
                  uniqueOptionKey='id'
                  searchKey='account_name'
                  optionLabelKey='account_name'
                  placeholder='Select the Account'
                  playLoad={{ account_type: ['EXPENSE'] }}
                  optionGroupLabelKey='account_type'
                  URL={get_chart_of_account_nested_nodes(1)}

                  addButton={{
                    title: '+ Add accounts',
                    onClick: () => addButtonHandler('Add accounts', 'create_sub_account', 'sm', "EXPENSE", index),
                  }}
                />
              </div>
            </CustomTableBodyCell>

            <CustomTableBodyCell sx={{}} align="left">
              <Common.QuantityInputComponent
                index={index}
                data={data}
                setData={setData}
                quantityData={bill}
                finalPayment={finalPayment}
                isFormSubmitted={isFormSubmitted}
              />
            </CustomTableBodyCell>

            <CustomTableBodyCell sx={{}} align="left">

              <Common.RateInputComponent
                index={index}
                data={data}
                setData={setData}
                rateData={bill}
                finalPayment={finalPayment}
                isFormSubmitted={isFormSubmitted}
              />
            </CustomTableBodyCell>

            {isDiscountFiled &&
              <CustomTableBodyCell sx={{}} align="left">
                <Common.DiscountInputComponent
                  index={index}
                  data={data}
                  setData={setData}
                  discountData={bill}
                  finalPayment={finalPayment}
                />

              </CustomTableBodyCell>
            }

            <CustomTableBodyCell sx={{}} align="left">
              <TaxList
                id={"tax_dropdown_" + index}
                dataTestId={"tax_dropdown_" + index}
                result={taxList}
                value={data[index]?.tax}
                disableClearable={false}
                inputDisabled={ bill.tax_applied === "no_tax"}
                validation={
                  isFormSubmitted &&
                  bill.tax_applied !== "no_tax" &&
                  !data[index]?.tax_id
                }
                setValue={(tax) => {
                  if (
                    isFinalAmountRangeCrossed({
                      quantity: data[index]?.quantity,
                      rate: data[index]?.rate,
                      item_level_discount: data[index].item_level_discount,
                      item: { ...data[index], tax: tax },
                      invoice: bill,
                      finalPayment:
                        data.length <= 1
                          ? 0
                          : finalPayment -
                          (data[index]?.amount ? data[index]?.amount : 0) -
                          (data[index]?.tax_amount
                            ? data[index]?.tax_amount
                            : 0),
                    })
                  ) {
                    stateChangeManager(
                      dispatch,
                      Actions,
                      true,
                      "error",
                      "Amount range max limit reached"
                    );
                  } else {
                    if (tax) {
                      data[index].tax = tax;
                      data[index].tax_id = tax.id;
                      data[index].gst_rate = tax.rate;
                    } else {
                      data[index].tax = null;
                      data[index].tax_id = null;
                      data[index].tax_amount = null;
                    }
                    setData([...data]);
                  }
                }}
              />
            </CustomTableBodyCell>


            <CustomTableBodyCell sx={{}} align="left">
              <Input
                id={"input_tax_amount_" + index}
                dataTestId={"input_tax_amount_" + index}
                editable={false}
                is_currency_symbol={true}
                isShowCommaSeparatedValue={true}
                currency_code={bill.currency_code}
                item={{
                  type: "number",
                  disabled: true,
                  fullWidth: true,
                  required: true,
                  placeholder: "",
                  value:
                    data[index]?.tax_amount && bill.tax_applied !== "no_tax"
                      ? data[index]?.tax_amount
                      : "",
                }}
              />
            </CustomTableBodyCell>

            <CustomTableBodyCell sx={{}} align="left">
              <Input
                id={"input_amount_" + index}
                dataTestId={"input_amount_" + index}
                editable={false}
                is_currency_symbol={true}
                isShowCommaSeparatedValue={true}
                currency_code={bill.currency_code}
                item={{
                  disabled: true,
                  type: "number",
                  fullWidth: true,
                  required: true,
                  placeholder: "",
                  value: data[index]?.amount ? data[index]?.amount : "",
                }}
              />
            </CustomTableBodyCell>

            <CustomTableBodyCell sx={{}} align="right">
              <IconButton
                onClick={() => onDeleteLine(index)}
                disabled={item.disabled}
                id={"delete_icon_" + index}
                dataTestId={"delete_icon_" + index}
              >
                <DeleteIcon />
              </IconButton>
            </CustomTableBodyCell>

          </CustomTableRow>
        );
      })}
    </CustomTableBody>
  );
};

const Buttons = (props) => {
  const {
    is_Location,
    onSave,
    onCancel,
    onClear,
    bill,
    setFormSubmitted,
    vendor,
  } = props;
  let location = useLocation();
  const params = getQueryParams(location.search);

  const { tax_applied, bill_items, bill_date, due_date } = bill;

  const is_rate = bill_items.find((item) => item.rate === null);
  const is_quantity = bill_items.find((item) => !item.quantity);
  const is_bill_item_not_selected = bill_items.find((item) => !item.item_id);
  const is_tax = bill_items.find(
    (item) => tax_applied !== "no_tax" && !item.tax_id
  );
  const is_expense_category_not_selected = bill_items.find(
    (item) => !item.expense_category_id
  );

  let validation_data = [
    { key: "vendor_id", message: "Please Select Vendor" },
    { key: "payment_term_id", message: "Please Select Terms" },
    { key: "bill_number", message: "Please Enter Bill Number" },
    isVendorRegister && !is_Location && {
      key: "source_of_supply_id",
      message: "Please Select Source of Supply",
    },
    { key: "bill_date", message: "Please Enter Valid Bill Date" },
    { key: "due_date", message: "Please Enter Valid Due Date" },
    {
      key: "",
      validation:
        bill_date &&
        due_date &&
        moment(bill_date).format("YYYY-MM-DD") >
        moment(due_date).format("YYYY-MM-DD"),
      due_date,
      message: "The Payment Due date can not be Before the Bill Date.",
    },
    // { key: "mailing_address", message: 'Please Enter Mailing Address' },
    {
      key: "",
      validation: is_bill_item_not_selected,
      message: "Please Select Item",
    },
    {
      key: "",
      validation: is_expense_category_not_selected,
      message: "Please Select Account",
    },
    { key: "", validation: is_quantity, message: "Please Enter the Quantity" },
    { key: "", validation: is_rate, message: "Please Enter the Rate" },
    { key: "", validation: is_tax, message: "Please Select the Tax" },
  ];

  const printPreviewEvent = () => {
    AnalyticsEvent(eventsNames.categories.BILLS,{action:eventsNames.actions.PREVIEW});
  };

  const clearActionDisabled =
    Object.keys(params).length === 0 || params.clone_id || params.vendor_id;

  return (
    <Box component="form" noValidate>
      <Grid container>
        <Grid item xs={12} sm={4} sx={{ display: "-webkit-flex", justifyContent: "start" }}>
          {clearActionDisabled && (
            <CustomButton
              id={"clear_btn"}
              dataTestId={"clear_btn"}
              variant="outlined"
              sx={{
                textTransform: "none",
                "&:hover": { backgroundColor: "#e8f3ff" },
              }}
              btnLabel="Clear"
              onClick={() => {
                onClear();
              }}
            />
          )}
          <CustomButton
            id={"cancel_btn"}
            dataTestId={"cancel_btn"}
            variant="contained"
            sx={{ ml: 1, textTransform: "none" }}
            btnLabel="Cancel"
            onClick={() => {
              onCancel();
            }}
          />
        </Grid>
        <Grid item xs={12} sm={8} sx={{ display: "-webkit-flex", justifyContent: "end" }}>
          <PrintAndPreview
            apiUrl={export_bill()}
            data={bill}
            validation_data={validation_data}
            setFormSubmitted={() => {
              setFormSubmitted(true);
              printPreviewEvent();
            }}
          />
          <CustomButton
            id={"save_as_draft_btn"}
            dataTestId={"save_as_draft_btn"}
            variant="outlined"
            sx={{
              mr: 1,
              textTransform: "none",
              "&:hover": { backgroundColor: "#e8f3ff" },
            }}
            btnLabel={params.id ? "Update as Draft" : "Save as Draft"}
            onClick={() => {
              onSave({ is_draft: true });
            }}
          />
          <CustomButton
            id={"save_btn"}
            dataTestId={"save_btn"}
            variant="contained"
            sx={{ textTransform: "none" }}
            btnLabel={params.id ? "Update" : "Save"}
            onClick={() => {
              onSave({ is_draft: false });
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default CreateNewBill;
