import React from "react";
import {vendor } from "../../../../../../utils/Constant";
import { routesName } from "../../../../../../config/routesName";
import Mappings from "../../../../bulkStatementUpload/mappings/Mappings";


const VendorsMapping = (props) => {
    return(

        <Mappings 
        isDateFormat={false}
        mapping_for={ vendor }
        onFileErrorPath={routesName.invoicingPurchaseVendorImportFile.path}  
        nextPagePath={routesName.invoicingPurchaseVendorImportPreview.path} 
        />
        
    )
}

export default VendorsMapping