import React from 'react';
import Query from '../queryBuilder/Query';
// import Query from '../queryBuilder/Query1';
import Input from '../../../../common/Input';
import { useNavigate } from 'react-router-dom';
import { apiAction } from '../../../../../api/api';
import DateRange from '../../../../common/DateRange';
import * as Actions from '../../../../../state/Actions';
import CustomButton from '../../../../custom/button/CustomButton';
import { getBusinessInfo } from '../../../../../config/cookiesInfo';
import { LabelWithAsteriskMark } from '../../../../common/CommonLabel';
import CustomTypography from '../../../../custom/typography/CustomTypography';
import { CustomContainer } from '../../../../custom/container/CustomContainer';
import { CustomButtonContainer } from '../../../../custom/container/CustomContainer';

import {
  get_data_point,
  create_data_point,
  update_data_point,
} from '../../../../../api/urls';

import {
  getNextCopy,
} from '../common/Helper';

import {
  isFormValid,
  stateChangeManager,
} from '../../../../../utils/Utils';

import {
  Box,
  Grid,
} from '@mui/material';
import { ConstructionOutlined } from '@mui/icons-material';

const Dropdown = DateRange;
const CreateDataPoint = (props) => {
  const { is_clone, data, onClose, assignDataPoint, boxHeight = 'calc(100vh - 203.5px)' } = props;

  const navigate = useNavigate();
  const dispatch = Actions.getDispatch(React.useContext);
  const [equationError, setEquationError] = React.useState([]);
  const [retrieveData,setRetrieveData]=React.useState();

  const unitList = [
    { name: `Base Currency - ${getBusinessInfo().currency.currency_code} : (${getBusinessInfo().currency.symbol})`, value: 'base_currency', symbol: getBusinessInfo().currency.symbol },
    { name: 'Percentage - (%)', value: 'percentage', symbol: '%' },
    { name: 'None', value: 'none', symbol: '' }
  ]


  const initial_data = {
    business_id: getBusinessInfo().id,
    calculation_type: 'equation',

    unit: null,
    name: null,
    description: null,
    category_id: null,
    calculation_formula: null,
    data_point_dependencies_id: []
  }

  const [isFormSubmitted, setFormSubmitted] = React.useState(false);
  const [formData, setFormData] = React.useState({ ...initial_data });

  const onSave = async () => {
    setFormSubmitted(true);
    let validation_data = [
      { key: 'name', message: 'Data point name can not be empty' },
      { key: 'calculation_formula', message: 'Calculation formula can not be empty' },
      { key: 'unit', message: 'Unit can not be empty' },
      { key: "", validation: equationError.length !== 0, message: "Something went wrong", },

    ]
    // console.log('====>formData', formData)

    const { isValid, message } = isFormValid(formData, validation_data)
    if (isValid) {
      let res = await apiAction({
        method: 'post',
        navigate: navigate,
        dispatch: dispatch,
        url: data && !is_clone ? update_data_point() : create_data_point(),
        data: data && !is_clone ? { ...formData, data_point_id: data.id, } : { ...formData, },

      });
      if (res?.success) {
        if (assignDataPoint) { assignDataPoint(res?.result) }
        // if (onClose) { onClose(); }
        setFormSubmitted(false);
        stateChangeManager(dispatch, Actions, true, 'success', res?.status);
      } else {
        stateChangeManager(dispatch, Actions, true, 'error', res?.status);
      }
    } else {
      stateChangeManager(dispatch, Actions, true, 'error', message);
    }
  }


  const boxBorderColor = isFormSubmitted && equationError.length !== 0 ? '#d32f2f' : '#c4c4c4';
  const isFormulaValid = isFormSubmitted ? equationError.length !== 0 || !formData.calculation_formula : false;

  const getDataPoint = async (data_point_id) => {
    const res = await apiAction({
      url: get_data_point(data_point_id)
    })
      .then((value) => {
        if (value.success) {
          let data = value.result;
          setRetrieveData(value.result);
          setFormData({
            ...initial_data,
            unit: data.unit,
            category_id: null,
            calculation_type: 'equation',
            description: data.description,
            business_id: getBusinessInfo().id,
            calculation_formula: data.calculation_formula,
            name: is_clone ? getNextCopy(data.name) : data.name,
            data_point_dependencies_id: data.data_point_dependencies.map(item => item.id)
          })
        }
      })
      .catch((error) => {
        console.log('==>error', error)
      })

  }

  React.useEffect(() => {
    if (data && data.id) {
      getDataPoint(data.id)
    }
    // console.log('====>data Create Data Point', data)
  }, [data])
  // console.log('====>retrieveData Create Data Point', retrieveData)


  return (
    <Box sx={{ mt: 0, overflow: 'hidden', maxHeight: 'calc(100vh - 140px)' }}>
      <CustomContainer maxWidth={"400"} sx={{ height: boxHeight, }}>
        <Box sx={{ p: 2, pt: 0 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} container spacing={2}>
              <Grid item xs={12}>
                <Input
                  autoFocus={true}
                  id={'input_data_point_name'}
                  dataTestId={'input_data_point_name'}
                  item={{
                    type: 'text',
                    fullWidth: true,
                    placeholder: 'Enter Name',
                    value: formData.name ? formData.name : '',
                    validation: isFormSubmitted && !formData.name,
                    title: <LabelWithAsteriskMark label={'Name'} />,
                    onChange: (e) => { setFormData((previous) => ({ ...previous, ...formData, name: e.target.value !== '' ? e.target.value : null })) },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  id={"input_data_point_description"}
                  dataTestId={"input_data_point_description"}
                  item={{
                    rows: 4,
                    type: "text",
                    required: true,
                    multiline: true,
                    fullWidth: true,
                    title: "Description",
                    placeholder: "Enter Description",
                    value: formData.description ? formData.description : '',
                    onChange: (e) => { setFormData((previous) => ({ ...previous, ...formData, description: e.target.value !== '' ? e.target.value : null })) },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Query
                  data={retrieveData}
                  isFormulaValid={isFormulaValid}
                  getData={(value) => {

                    if (value) {
                      // console.log('====>value',value)
                      setEquationError(value.unknownAccount);
                      setFormData((previous) => ({
                        ...previous, ...formData,
                        data_point_dependencies_id: value.getDataPointDependenciesId,
                        calculation_formula: value.getCalculationFormula !== '' ? value.getCalculationFormula : null,
                      }))
                    }
                  }
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Dropdown
                  results={unitList}
                  id={'data_point_unit_dropdown'}
                  placeholder='Select data point unit'
                  dataTestId={'data_point_unit_dropdown'}
                  label={<LabelWithAsteriskMark label={'Unit'} />}
                  validation={isFormSubmitted && !formData.unit}
                  value={formData.unit ? unitList.find((item) => item.value === formData.unit) : null}
                  setValue={(event, value) => {
                    if (value) {
                      formData.unit = value.value;
                    } else {
                      formData.unit = null;
                    }
                    setFormData({ ...formData })
                  }}
                />
              </Grid>

            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{ p: 1, mt: 3.2, minHeight: 'calc(100% - 48.5px)', borderRadius: '4px', border: `1px solid ${boxBorderColor}` }}>
                <CustomTypography
                  text={''}
                  sx={{
                    mb: 1,
                    color: '#000000',
                    fontSize: '14px',
                    fontWeight: '700',
                    lineHeight: '18px',
                    fontStyle: 'normal',
                    textTransform: 'none',
                    fontFamily: "Noto Sans",
                  }}
                />

                {
                  equationError.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <CustomTypography
                          text={'Unknown word: ' + item}
                          sx={{
                            mb: 1,
                            color: '#d32f2f',
                            fontSize: '14px',
                            fontWeight: '700',
                            lineHeight: '18px',
                            fontStyle: 'normal',
                            textTransform: 'none',
                            fontFamily: "Noto Sans",
                          }}
                        />
                      </React.Fragment>
                    )
                  })
                }
              </Box>
            </Grid>
          </Grid>
        </Box>
      </CustomContainer>

      <CustomButtonContainer sx={{ mr: 0, ml: 0 }}>
        <Buttons
          onSave={() => { onSave() }}
          onCancel={() => { onClose() }}
        />
      </CustomButtonContainer>
    </Box>
  )
}

export default CreateDataPoint

const Buttons = (props) => {
  const { onSave, onCancel } = props

  return (
    <Box component="form" noValidate>
      <Grid container>
        <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'start' }}>
          <CustomButton id={'cancel_btn'} dataTestId={'cancel_btn'} variant='outlined' sx={{ textTransform: 'none', '&:hover': { backgroundColor: '#e8f3ff' } }} btnLabel='Cancel' onClick={onCancel} />
        </Grid>
        <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'end', }}>
          <CustomButton id={'save_btn'} dataTestId={'save_btn'} variant='contained' sx={{ ml: 1, textTransform: 'none' }} btnLabel={'Save'} onClick={onSave} />
        </Grid>
      </Grid>
    </Box>
  )
}
