import React from 'react';
import PropTypes from "prop-types";
import { images } from '../../../../utils/Constant';
import CustomTypography from '../../../custom/typography/CustomTypography';

import {
    Box,
    Grid,
    Popover,
    MenuItem,
    ListItem,
    ListItemText,
    TextareaAutosize,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
// import { KeyboardArrowDownIcon, KeyboardArrowUpIcon } from '@mui/icons-material';

// Custom theme with updated styles
const theme = createTheme({
    components: {
        MuiTextareaAutosize: {
            styleOverrides: {
                root: {
                    '&:focus': {
                        borderColor: 'blue', // Change to the desired border color
                        boxShadow: '0 0 0 2px rgba(0, 0, 255, 0.3)', // Optional: add a box shadow
                    },
                },
            },
        },
    },
});

export const Textarea = (props) => {
    return (
        <ThemeProvider theme={theme}>
            <TextareaAutosize {...props} />
        </ThemeProvider>
    );
};
export const CustomImage = (props) => {
    const { image, style } = props;

    return (
        <Box
            component="img"
            sx={{ ...style }}
            alt={images[image] ? images[image].alt : ''}
            src={images[image] ? images[image].src : ''}
        />
    )
}

export const YoutubeEmbed = ({ embedId, style, iframeStyle }) => (
    // <div style={{ ...style,...iframeStyle }}>
    <iframe
        frameBorder="0"
        allowFullScreen
        title="Embedded youtube"
        style={{ ...style, ...iframeStyle, }}
        src={`https://www.youtube.com/embed/${embedId}`}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    />
    // </div>
);

YoutubeEmbed.propTypes = {
    embedId: PropTypes.string.isRequired
};

export const Dropdown = (props) => {
    const { getTextColor, title, options, onCloseHandler} = props;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        if (onCloseHandler) { onCloseHandler() }
    };
    const action_style = {
        fontWeight: 700,
        color: '#666666',
        cursor: 'pointer',
        lineHeight: '26px',
        letterSpacing: '0px',
        fontFamily: "Noto Sans",
        fontSize: '16px !important',
        ":hover": { backgroundColor: "#f5f5f5", }
    }
    return (
        <React.Fragment>

            <ListItem
                sx={{
                    padding: '0px',
                    cursor: 'pointer',
                    color: getTextColor,
                    fontFamily: "Noto Sans",
                    fontSize: '16px !important',
                    '&:hover': {
                        color: '#2464EB',
                    },
                }}
                onClick={(event) => { handleClick(event); }}
            >
                <ListItemText sx={{}}
                    primary={
                        <CustomTypography
                            text={title}
                            sx={{
                                fontWeight: 700,
                                lineHeight: '26px',
                                color: getTextColor,
                                letterSpacing: '0px',
                                fontSize: '16px !important',
                                '&:hover': {
                                    color: '#2464EB',
                                }
                            }}
                        />
                    }
                />
                {open ? <KeyboardArrowUpIcon sx={{}} /> : <KeyboardArrowDownIcon sx={{}} />}
            </ListItem>

            <Popover
                sx={{ mt: '20px' }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                PaperProps={{
                    style: {
                        minWidth: 140,
                    }
                }}
                onClose={handleClose}
                anchorEl={anchorEl}
                open={open}
                id={`list_action_popover`}
                dataTestId={`list_action_popover`}
            >
                {
                    options && options.map((option, index) => {
                        return (
                            <React.Fragment key={index}>
                                <MenuItem
                                    sx={{ ...action_style, }}
                                    onClick={() => { handleClose(); if (option.onClick) { option.onClick() } }}>
                                    {option.name}
                                </MenuItem>
                            </React.Fragment>
                        )
                    })
                }
            </Popover>
        </React.Fragment>
    )
}