import React from 'react';
import moment from 'moment';
import Highcharts from 'highcharts';
import { apiAction } from "../../../../../api/api";
import ReportsTitle from '../../common/ReportsTitle';
import DateRange from '../../../../common/DateRange';
import DatePicker from '../../../../common/DatePicker';
import * as Actions from '../../../../../state/Actions';
import HighchartsReact from 'highcharts-react-official';
import ReportsSettingButtons from '../../ReportsSettingButtons';
import { getBusinessInfo } from '../../../../../config/cookiesInfo';
import CurrencyFormatter from '../../../../common/CurrencyFormatter';
import CommonCustomDateRange from '../../../../common/CommonCustomDateRange';
import CustomTypography from '../../../../custom/typography/CustomTypography';
import { CustomContainer } from '../../../../custom/container/CustomContainer';



import {
  get_ratio_summary,
  get_ratio_details
} from '../../../../../api/urls';

import {
  setLoader,
  amountFormatter,
} from '../../../../../utils/Utils';

import {
  Box,
  Grid,
} from '@mui/material';

import {
  CustomTable,
  CustomTableRow,
  CustomTableBody,
  CustomTableHead,
  CustomTableHeadCell,
  CustomTableBodyCell,
  CustomTableContainer,
} from '../../../../custom/table/CustomTable';

const BusinessPerformanceRatios = () => {

  const dispatch = Actions.getDispatch(React.useContext);

  const [results, setResults] = React.useState([]);
  const [toDate, setToDate] = React.useState(moment());
  const [fromDate, setFromDate] = React.useState(moment());
  const [selectRatio, setSelectRatio] = React.useState('current_ratio');
  const [selectedDateRange, setSelectedDateRange] = React.useState('last_12_months');

  const ratioType = [
    { name: 'Current Ratio', value: 'current_ratio' },
    { name: 'Gross Profit Ratio', value: 'gross_profit_ratio' },
    { name: 'Debt Ratio', value: 'debt_ratio' },
    { name: 'Net Profit Ratio', value: 'net_profit_ratio' },
    { name: 'Acid Test Ratio', value: 'acid_test_ratio' },
    { name: 'Receivable Turnover Ratio', value: 'receivable_turnover_ratio' },
    { name: 'Debt To Equity Ratio', value: 'debt_to_equity_ratio' },
    { name: 'Operating Cost Ratio', value: 'operating_cost_ratio' },
    { name: 'Asset Turnover Ratio', value: 'asset_turnover_ratio' },
  ]

  const dateRange = [
    { name: 'Previous Year', value: 'previous_year' },
    { name: 'Last 12 Months', value: 'last_12_months' },
    { name: 'Last 6 Months', value: 'last_6_months' },
    { name: 'Previous Quarter', value: 'previous_quarter' },
    { name: 'Custom', value: 'custom' },
  ]
  const title = selectRatio ? ratioType.find((item) => item.value === selectRatio) : null;

  React.useEffect(() => {
    if (selectedDateRange !== 'custom') {
      let get_ratio_details_url = get_ratio_details(selectRatio, selectedDateRange)
      getApiResults(get_ratio_details_url)
    } else {
      let get_ratio_details_url = get_ratio_details(selectRatio, selectedDateRange) + `&from_month=${moment(fromDate).format('yyyy-MM')}&to_month=${moment(toDate).format('yyyy-MM')}`
      getApiResults(get_ratio_details_url)
    }
    // eslint-disable-next-line
  }, [selectRatio, selectedDateRange, toDate, fromDate,]);


  const getApiResults = async (url) => {
    setLoader(dispatch, Actions, true)
    let res = await apiAction({
      url: url,
      method: 'get',
    })

    if (res?.succes) {
      setResults(res?.result)
      setLoader(dispatch, Actions, false);
    } else {
      setLoader(dispatch, Actions, false);
    }
  }

  return (
    <div>
      <ReportsSettingButtons
        ratioData={{
          ratioType: ratioType,
          setValue: (value) => { setSelectRatio(value ? value.value : null) },
          value: selectRatio ? ratioType.find((item) => item.value === selectRatio) : null,
        }}
      />
      <CustomContainer maxWidth={"400"} sx={{ height: 'calc(100vh - 134px)' }}>
        <div style={{ paddingLeft: '16px', paddingRight: '16px' }}>
          <Grid container spacing={0} sx={{ pb: 2 }} style={{
            alignItems: 'center',
            justifyItems: 'center',
            alignContent: 'space-evenly',
            justifyContent: 'space-evenly',
          }}>
            <Grid item xs={12} sm={4}>
              <ReportsTitle
                title={title ? title.name : ''}
              // date={date}
              />
            </Grid>
            <Grid container spacing={1} item xs={12} sm={8} sx={{ justifyContent: 'end', }}>
              <Grid item xs={12} sm={4}>
                <DateRange
                  sx={{ mb: 2 }}
                  label='Date Range'
                  results={dateRange}
                  disableClearable={true}
                  id={'date_range_dropdown'}
                  placeholder='select date range'
                  dataTestId={'date_range_dropdown'}
                  value={selectedDateRange ? dateRange.find((item) => item.value === selectedDateRange) : null}
                  setValue={(event, selectedValue) => { setSelectedDateRange(selectedValue ? selectedValue.value : null) }
                  }
                />
              </Grid>
              {selectedDateRange === 'custom' && (
                <React.Fragment>
                  <Grid item xs={6} sm={3}>
                    <DatePicker
                      title='From Date'
                      inputFormat={'MMM-yyyy'}
                      id={'from_date_select'}
                      views={["month", "year"]}
                      dataTestId={'from_date_select'}

                      date={fromDate}
                      setDate={(date) => {
                        setFromDate(date)
                        if (date > toDate) { setToDate(date) }
                      }
                      }

                      disabledCloseIcon={true}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <DatePicker
                      title='To Date'
                      id={'to_date_select'}
                      inputFormat={'MMM-yyyy'}
                      views={["month", "year"]}
                      dataTestId={'to_date_select'}

                      date={toDate}
                      minDate={fromDate}
                      disabledCloseIcon={true}
                      setDate={(date) => { setToDate(date) }}
                    />
                  </Grid>
                </React.Fragment>
              )
              }
            </Grid>
          </Grid>
        </div>
        <SummaryComponent
          toDate={toDate}
          fromDate={fromDate}
          selectRatio={selectRatio}
          financial_ratios={financial_ratios}
          selectedDateRange={selectedDateRange}
        />

        <Graph
          data={results}
          selectRatio={selectRatio}
          financial_ratios={financial_ratios}
        />

        <DetailsComponent
          results={results}
          selectRatio={selectRatio}
          financial_ratios={financial_ratios}
        />
      </CustomContainer>
    </div>
  )
}

export default BusinessPerformanceRatios;

const SummaryComponent = (props) => {
  const { selectRatio, selectedDateRange, toDate, fromDate } = props;
  const dispatch = Actions.getDispatch(React.useContext);

  const [Data, setData] = React.useState(
    [
      { title: 'Previous Year', value: 0 },
      { title: 'Previous Quarter', value: 0, borderLeft: '2px solid #F1D881', borderRight: '2px solid #F1D881', },
      { title: 'Last 12 Months ', value: 0, borderRight: '2px solid #F1D881', },
      { title: 'Last 6 Months', value: 0 }
    ]
  );
  React.useEffect(() => {
    if (selectedDateRange !== 'custom') {
      let get_ratio_summary_url = get_ratio_summary(selectRatio, selectedDateRange)
      getApiResults(get_ratio_summary_url)
    } else {
      let get_ratio_summary_url = get_ratio_summary(selectRatio, selectedDateRange) + `&from_month=${moment(fromDate).format('yyyy-MM')}&to_month=${moment(toDate).format('yyyy-MM')}`
      getApiResults(get_ratio_summary_url)
    }
    // eslint-disable-next-line
  }, [selectRatio, selectedDateRange, toDate, fromDate,]);

  const getApiResults = async (url) => {
    setLoader(dispatch, Actions, false)
    let res = await apiAction({
      url: url,
      method: 'get',
    })

    if (res?.success) {
      let dataResults = [
        { title: 'Previous Year', value: res?.result.previous_year },
        { title: 'Previous Quarter', value: res?.result.previous_quarter, borderLeft: '2px solid #F1D881', borderRight: '2px solid #F1D881', },
        { title: 'Last 12 Months ', value: res?.result.last_12_months, borderRight: '2px solid #F1D881', },
        { title: 'Last 6 Months', value: res?.result.last_6_months }
      ]
      setData(dataResults)
    } else {
      setLoader(dispatch, Actions, false);
    }
  }

  return (
    <Box sx={{ p: 2, border: '1px solid #FFF0C0', backgroundColor: "#FFF7DC" }}>
      <Grid container>
        {
          Data.map((item, index) => {
            return (
              <Grid item xs={12} sm={3} key={index} sx={{
                p: 1.5,
                borderLeft: item.borderLeft,
                borderRight: item.borderRight,
              }}
              >
                <Box>
                  <CustomTypography
                    text={item.title}
                    sx={{
                      mb: 1,
                      color: '#7A7A7A',
                      fontSize: '14px',
                      fontWeight: '600',
                      lineHeight: '19px',
                      fontStyle: 'normal',
                      textTransform: 'none',
                      fontFamily: "Noto Sans",
                    }} />
                  <CustomTypography
                    text={item.value}
                    sx={{
                      mb: 1,
                      fontSize: '24px',
                      color: '#000000',
                      display: 'inline',
                      fontWeight: '700',
                      lineHeight: '28px',
                      fontStyle: 'normal',
                      textTransform: 'none',
                      fontFamily: "Noto Sans",
                    }}
                  />
                </Box>
              </Grid>
            )
          })
        }
      </Grid>
    </Box>
  )
}

const DetailsComponent = (props) => {
  const { selectRatio, results, financial_ratios } = props;
  return (
    <CustomTableContainer>
      <CustomTable>
        <Header cols={financial_ratios[selectRatio]} />
        <Body results={results} />
      </CustomTable>
    </CustomTableContainer>
  )
}

const Header = (props) => {
  const { cols = [] } = props
  return (
    <CustomTableHead>
      <CustomTableRow >
        {cols.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <CustomTableHeadCell align={item.align}><span>{item.field_name}</span></CustomTableHeadCell>
            </React.Fragment>
          )
        })
        }
      </CustomTableRow>
    </CustomTableHead>
  )
}

const Body = (props) => {
  const { results = [] } = props;
  return (
    <CustomTableBody>
      {results.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <CustomTableRow>
              <CustomTableBodyCell align='left'>{item.month_and_year}</CustomTableBodyCell>
              <CustomTableBodyCell align='right'>{<CurrencyFormatter amount={item.numerator} currency={null} />}</CustomTableBodyCell>
              <CustomTableBodyCell align='right'>{<CurrencyFormatter amount={item.denominator} currency={null} />}</CustomTableBodyCell>
              <CustomTableBodyCell align='right'>{item.result}</CustomTableBodyCell>
            </CustomTableRow>
          </React.Fragment>
        )
      })}

    </CustomTableBody>
  )
}

const Graph = (props) => {
  const { data, financial_ratios, selectRatio, color = "#2464EB" } = props

  let categories = data.map((item) => {
    item.y = item.result
    return moment(item.month_and_year).format("MMM YY")
  })
  const minClosingBalance = Math.min(...data.map(item => item.result)); // find the minimum closing_balance value in the data array
  const maxClosingBalance = Math.max(...data.map(item => item.result)); // find the minimum closing_balance value in the data array
  const options = {
    chart: {
      type: 'scatter'
    },
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    yAxis: {
      title: {
        text: null
      },
      max: maxClosingBalance,
      min: minClosingBalance,
    },
    xAxis: {
      min: 0,
      maxPadding: 0,
      tickInterval: 1,
      endOnTick: false,
      startOnTick: false,
      categories: categories,
    },

    plotOptions: {
      series: {
        cursor: 'pointer',
        stickyTracking: false,
        connectNulls: false,
      },
    },

    tooltip: {
      snap: 0,
      formatter: function () {
        var tooltip = ''
        financial_ratios[selectRatio].map((item) => {
          return tooltip += `<br><span style="font-family: 'Noto Sans';"><span style="color:${'#2464EB'}">${item.field_name} : </span><b>${item.number_formatter?amountFormatter(this.point[item.field_type]):this.point[item.field_type]}</b></span>`;
        })
        return tooltip;
      }
    },

    series: [{
      data: data,
      lineWidth: 2,
      color: color,
      // name: 'Cash Flow',
      showInLegend: false,
    }]
  };
  return (
    <Box sx={{ pt: 5, pb: 5 }}>
      <HighchartsReact highcharts={Highcharts} options={options} containerProps={{ style: { height: "350px" } }} />
    </Box>
  )
}

const financial_ratios = {
  current_ratio: [
    { align: 'left', field_type: 'month_and_year', field_name: 'Month' },
    { align: 'right', field_type: 'numerator', number_formatter: true, field_name: 'Current Assets' },
    { align: 'right', field_type: 'denominator', number_formatter: true, field_name: 'Current Liabilities' },
    { align: 'right', field_type: 'result', field_name: 'Current Ratio' },
  ],
  gross_profit_ratio: [
    { align: 'left', field_type: 'month_and_year', field_name: 'Month' },
    { align: 'right', field_type: 'numerator', number_formatter: true, field_name: 'Gross Profit' },
    { align: 'right', field_type: 'denominator', number_formatter: true, field_name: 'Net Sales' },
    { align: 'right', field_type: 'result', field_name: 'Gross Profit Ratio' },
  ],
  debt_ratio: [
    { align: 'left', field_type: 'month_and_year', field_name: 'Month' },
    { align: 'right', field_type: 'numerator', number_formatter: true, field_name: 'Total Liabilities' },
    { align: 'right', field_type: 'denominator', number_formatter: true, field_name: 'Total Assets' },
    { align: 'right', field_type: 'result', field_name: 'Debit Ratio' },
  ],
  net_profit_ratio: [
    { align: 'left', field_type: 'month_and_year', field_name: 'Month' },
    { align: 'right', field_type: 'numerator', number_formatter: true, field_name: 'Net Profit After Tax' },
    { align: 'right', field_type: 'denominator', number_formatter: true, field_name: 'Net Sales' },
    { align: 'right', field_type: 'result', field_name: 'Net Profit Ratio' },
  ],
  acid_test_ratio: [
    { align: 'left', field_type: 'month_and_year', field_name: 'Month' },
    { align: 'right', field_type: 'numerator', number_formatter: true, field_name: 'Current And Its Equivalents + Accounts Receivable' },
    { align: 'right', field_type: 'denominator', number_formatter: true, field_name: 'Current Liabilities' },
    { align: 'right', field_type: 'result', field_name: 'Acid Test Ratio' },
  ],
  receivable_turnover_ratio: [
    { align: 'left', field_type: 'month_and_year', field_name: 'Month' },
    { align: 'right', field_type: 'numerator', number_formatter: true, field_name: 'Net Sales' },
    { align: 'right', field_type: 'denominator', number_formatter: true, field_name: 'Avg Accounts Receivable' },
    { align: 'right', field_type: 'result', field_name: 'Accounts Receivable Turnover Ratio' },
  ],
  debt_to_equity_ratio: [
    { align: 'left', field_type: 'month_and_year', field_name: 'Month' },
    { align: 'right', field_type: 'numerator', number_formatter: true, field_name: 'Total Liabilities' },
    { align: 'right', field_type: 'denominator', number_formatter: true, field_name: 'Total Equities' },
    { align: 'right', field_type: 'result', field_name: 'Debit To Equities Ratio' },
  ],
  operating_cost_ratio: [
    { align: 'left', field_type: 'month_and_year', field_name: 'Month' },
    { align: 'right', field_type: 'numerator', number_formatter: true, field_name: 'Operating Expense' },
    { align: 'right', field_type: 'denominator', number_formatter: true, field_name: 'Net Sales' },
    { align: 'right', field_type: 'result', field_name: 'Operating Cost Ratio' },
  ],
  asset_turnover_ratio: [
    { align: 'left', field_type: 'month_and_year', field_name: 'Month' },
    { align: 'right', field_type: 'numerator', number_formatter: true, field_name: 'Net Sales' },
    { align: 'right', field_type: 'denominator', number_formatter: true, field_name: 'Avg Total Assets' },
    { align: 'right', field_type: 'result', field_name: 'Asset Turnover Ratio' },
  ],
}