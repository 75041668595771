import React from 'react';
import { isEqual } from '../common/Helper';
import ManageSection from './ManageSection';
import { useNavigate } from 'react-router-dom';
import { apiAction } from '../../../../../api/api';
import * as Actions from '../../../../../state/Actions';
import { DeleteComponent } from '../common/CommonComponents';
import { stateChangeManager } from '../../../../../utils/Utils';
import { update_insight_section } from '../../../../../api/urls';
import { DataPointComponent } from '../dataPoint/DataPointComponents';
import { HtmlTooltip } from '../../../../custom/tooltip/CustomTooltip';
import { CustomCard, CustomSkeleton } from '../common/CustomComponents';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import CustomTypography from '../../../../custom/typography/CustomTypography';

import {
    CommonHeader,
} from '../common/CommonComponents';

import {
    Box,
    Grid,
    IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

const Sections = (props) => {
    const { onMoveComponent, is_drag, sectionData, onDeleteAction, from_date, to_date, refreshDataPoint, } = props;
    const navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);

    const [data, setData] = React.useState();
    const [open, setOpen] = React.useState(false);
    const [action, setAction] = React.useState('');
    const [openDelete, setOpenDelete] = React.useState(false);


    React.useEffect(() => {
        setData(sectionData);
    }, [sectionData])

    const handleDataPointDrop = (result) => {
        if (!result.destination) {
            return; // Dragged outside the droppable area
        }

        // Handle data point drop logic here
        const updatedDataPoints = Array.from(data.data_point_ids);
        const [draggedItem] = updatedDataPoints.splice(result.source.index, 1);
        updatedDataPoints.splice(result.destination.index, 0, draggedItem);


        const hasChanged = !isEqual(data.data_point_ids, updatedDataPoints);
        console.log('Data point ids have changed:', hasChanged);
        if (hasChanged) {
            // Update the data_point_ids array in the sectionData accordingly
            // setData({ ...data, data_point_ids: updatedDataPoints });
            onUpdateSection(updatedDataPoints);

        }

    };

    const onUpdateSection = async (data_point_ids) => {
        let res = await apiAction({
            method: 'post',
            navigate: navigate,
            dispatch: dispatch,
            url: update_insight_section(),
            data: { ...data, section_id: data.id, data_point_ids: data_point_ids },

        });
        if (res?.success) {
            setData({ ...res?.result, id: data.id });
            stateChangeManager(dispatch, Actions, true, 'success', res?.status);
        } else {
            stateChangeManager(dispatch, Actions, true, 'error', res?.status);
        }
    }

    return (
        <>
            <DeleteComponent
                action={action}

                openDelete={openDelete}
                setOpenDelete={(value) => { setOpenDelete(value); setAction('') }}

                id={data ? data.id : null}
                onDeleteAction={(id) => { onDeleteAction(id); setOpenDelete(false); setAction('') }}
            />
            <ManageSection
                open={open}
                data={data}
                action={action}
                setData={setData}
                setOpen={setOpen}
            />

                {/* {data ?
                    <CommonHeader isBtn={true} title={data.name} onMoveComponent={onMoveComponent} actionComponent={
                        <Box sx={{ ml: 1 }}>
                            <HtmlTooltip title={'Manage Section'} placement="top">
                                <IconButton aria-label="edit" sx={{ ":hover": { color: '#2464eb' } }} onClick={(event) => { setOpen(true); setAction('manage') }}>
                                    <ModeEditIcon fontSize='small' sx={{ fontSize: '18px' }} />
                                </IconButton>
                            </HtmlTooltip>
                            <HtmlTooltip title={'Delete Section'} placement="top">
                                <IconButton aria-label="delete" sx={{ ":hover": { color: '#2464eb' } }} onClick={(event) => { setOpenDelete(true); setAction('delete_section') }}>
                                    <DeleteIcon fontSize='small' sx={{ fontSize: '18px' }} />
                                </IconButton>
                            </HtmlTooltip>
                        </Box>
                    } />
                    :
                    <Box sx={{ pl: 2, pr: 2, height: 55, display: 'flex', justifyContent: 'space-between', alignItems: 'center', bgcolor: '#FFFFFF' }}>
                        <Box>
                            <CustomSkeleton variant="circular" width={35} height={35} />
                        </Box>
                        <Box sx={{ ml: 2, width: '100', flexGrow: 1 }}>
                            <CustomSkeleton variant="text" height={50} />
                        </Box>
                    </Box>
                } */}
                {is_drag ? ''
                    :
                    <React.Fragment>
                        <DragDropContext onDragEnd={handleDataPointDrop}>
                            <Droppable droppableId="dataPoints">
                                {(provided, snapshot) => (
                                    <Grid
                                        container
                                        spacing={2}
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        sx={{
                                            pb: 2, pt: 0,
                                            mt: snapshot.isDraggingOver ? 0.1 : 0,
                                            backgroundColor: snapshot.isDraggingOver ? '#ffebe6' : 'transparent',
                                            transition: 'background-color 0.2s ease', // Optional: add a smooth transition
                                        }}
                                    >
                                        {data &&
                                            data.data_point_ids &&
                                            data.data_point_ids.map((item, index) => (
                                                <Draggable
                                                    key={item}
                                                    index={index}
                                                    draggableId={item.toString()}
                                                >
                                                    {(provided, snapshot) => (
                                                        <Grid
                                                            item
                                                            sm={6}
                                                            md={6}
                                                            lg={4}
                                                            xs={12}
                                                            sx={{}}
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                        >
                                                            <div

                                                                {...provided.dragHandleProps}
                                                                style={{
                                                                    cursor: 'grab', // Customize cursor style
                                                                    padding: snapshot.isDragging ? '8px' : '', // Add padding for better visibility
                                                                }}
                                                            >
                                                                {/* <DataPointComponent
                                                                    to_date={to_date}
                                                                    sectionData={data}
                                                                    from_date={from_date}
                                                                    data_point_id={item}
                                                                    refreshDataPoint={refreshDataPoint}
                                                                    setSectionData={(item) => { setData(item); }}
                                                                    border={snapshot.isDragging ? '2px solid #858e9a' : ''}
                                                                    backgroundColor={snapshot.isDragging ? '#c8e6c9' : '#ffffff'}
                                                                /> */}
                                                            </div>
                                                        </Grid>
                                                    )}
                                                </Draggable>
                                            ))}
                                        {provided.placeholder}
                                    </Grid>
                                )}
                            </Droppable>
                        </DragDropContext>
                        {/* <Grid container sx={{ pb: 2, pt: 2, }} spacing={2}>
                            <Grid item xs={12} sm={6} md={6} lg={4} sx={{}}>
                                <Box
                                    sx={{
                                        p: 2,
                                        pr: 0,
                                        height: 55,
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        bgcolor: '#FFFFFF',
                                        overflow: 'hidden',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderRadius: '6.58599px',
                                        boxShadow: `rgba(17, 17, 26, 0.1) 0px 0px 16px`,
                                    }}
                                >
                                    <Box sx={{
                                        p: 1.3,
                                        minWidth: 120,
                                        cursor: 'pointer',
                                        borderRadius: 0.5,
                                        background: '#e8f3ff',
                                        border: '1px dashed #2464eb',
                                        '&:hover': { backgroundColor: '#cbe2fa' }
                                    }}
                                        onClick={() => { setOpen(true); setAction('add_data_point') }}
                                    >
                                        <CustomTypography
                                            text={'Add New Data Point'}
                                            sx={{
                                                color: '#00000',
                                                fontSize: '12px',
                                                fontWeight: '700',
                                                lineHeight: '16px',
                                                fontStyle: 'normal',
                                                fontFamily: 'Noto Sans',
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid> */}
                    </React.Fragment>
                }
        </>
    )
}

export default Sections;
