import React from "react";
import * as Actions from '../../../../../../state/Actions'
import { customer } from "../../../../../../utils/Constant";
import { routesName } from "../../../../../../config/routesName";
import FileUpload from "../../../../bulkStatementUpload/mappings/FileUpload";
import { get_fields_for_mapping,import_party_sample } from "../../../../../../api/urls";
const CustomersImportFile = (props) => {
    let dispatch = Actions.getDispatch(React.useContext)
    return (

        <FileUpload
        isDateFormat={false}
        mapping_for={customer}
        sample_file_download_url={import_party_sample}
        model_fields_url={get_fields_for_mapping(customer)}
        nextPagePath={routesName.invoicingSalesCustomersImportMapping.path}
        />

    )
}

export default CustomersImportFile