import React from 'react'
import CustomInput from '../custom/input/CustomInput'
import CustomTypography from '../custom/typography/CustomTypography'

const Input = (props) => {
    const {input_search, id,inputRef,isKeyPressEnter, dataTestId, autoFocus, item, InputProps, onKeyPress, onFocus, onBlur, inputProps, editable = false, currency_code = null,is_currency_symbol=false, isSearchableComponent } = props
    const { validation, titleVisibility = "visible",isTitleVisibility=true,size='small' } = item || {}

    return (
        <div >
            {isTitleVisibility&&(
                 <CustomTypography
                text={item?.title ? item?.title : item?.label}

                sx={{
                    mb: 1,
                    color: '#000000',
                    fontSize: '14px',
                    fontWeight: '700',
                    lineHeight: '18px',
                    fontStyle: 'normal',
                    textTransform: 'none',
                    fontFamily: "Noto Sans",
                    visibility: titleVisibility,
                    width: '100%'

                }}
            />)}
            <CustomInput
                id={id}
                size={size}
                rows={item?.rows}
                type={item?.type}
                onFocus={onFocus}
                value={item?.value}
                inputRef={inputRef}
                editable={editable}
                autoFocus={autoFocus}
                dataTestId={dataTestId}
                validation={validation}
                onKeyPress={onKeyPress}
                disabled={item?.disabled}
                required={item?.required}
                onChange={item?.onChange}
                input_search={input_search}
                multiline={item?.multiline}
                fullWidth={item?.fullWidth}
                isFormSubmitted={validation}
                placeholder={item?.placeholder}
                isKeyPressEnter={isKeyPressEnter}
                customValidation={item?.customValidation}
                onBlur={item?.onBlur ? item?.onBlur : onBlur}
                isSearchableComponent={isSearchableComponent}
                helperText={item?.helperText ? item?.helperText : ""}
                sx={{ background: item?.disabled ? '#F1F3F4' : null, ...item?.sx, }}
                inputProps={item?.inputProps ? item?.inputProps : inputProps}
                InputProps={item?.inputProps ? item?.inputProps : InputProps}
                currency_code={currency_code}
                is_currency_symbol={is_currency_symbol}
                {...props}
            // {...item}
            />
        </div>
    )
}

export default Input