import React from 'react';
import moment from 'moment/moment';
import * as Actions from '../../../../../state/Actions';
import CommonAlertBox from '../../common/CommonAlertBox';
import CreateNewExpense from '../expense/CreateNewExpense';
import { useNavigate, useLocation } from 'react-router-dom';
import { routesName } from '../../../../../config/routesName';
import { apiAction, apiFormData } from '../../../../../api/api';
import CustomButton from '../../../../custom/button/CustomButton';
import { getBusinessInfo } from '../../../../../config/cookiesInfo';
import { CustomButtonContainer } from '../../../../custom/container/CustomContainer';
import { eventsNames,AnalyticsEvent } from '../../../../../firebase/firebaseAnalytics';

import {
    setLoader,
    isFormValid,
    getQueryParams,
    isVendorRegister,
    stateChangeManager,
    disabled_gst_number,
    breadCrumbsStateChangeManager,
} from '../../../../../utils/Utils';


import {
    get_list_of_tax,
    retrieve_expense,
    update_recurring_expense,
    create_recurring_expense,
    retrieve_recurring_expense,
} from '../../../../../api/urls';

import {
    Box,
    Grid,
} from '@mui/material';

const CreateRecurringExpense = (props) => {
    const { onClose } = props;

    let location = useLocation();
    const navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);

    const [data, setData] = React.useState();
    const [isFormSubmitted, setFormSubmitted] = React.useState(false)

    const params = getQueryParams(location.search);



    const redirect = (id) => {
        if (onClose) {
            onClose(id)
        } else {
            navigate(routesName.invoicingRecurringExpenseView.path + "?id=" + id)
        }
    }
    const recurring_expense_initial_data = {
        ends_on: null,
        is_active: true,
        repeat_every: 1,
        expense_data: null,
        profile_name: null,
        never_expires: true,
        selected_ends_on: null,
        repeat_frequency: "week",
        preference: "create_and_send",
        business_id: getBusinessInfo().id,
        selected_custom_every_list: { name: 'Week(s)' },
        selected_repeat_every_list: repeat_every_list[0],
        starts_on: moment().utc().format('YYYY-MM-DD HH:mm:ssZ'),
        selected_starts_on: moment().format('YYYY-MM-DD HH:mm:ssZ'),
        // selected_preference: { name: 'Create Expense as Drafts' },
    }
    const [recurringExpenseData, setRecurringExpenseData] = React.useState({ ...recurring_expense_initial_data })

    React.useEffect(() => {
        if (data) {
            let selected_repeat_every_list= repeat_every_list.find((item) => item.repeat_frequency === data.repeat_frequency & item.repeat_every === data.repeat_every);

            setRecurringExpenseData({
                ...recurring_expense_initial_data,
                ...data,
                never_expires: params.recurring_clone_id ? true : data.never_expires,
                profile_name: params.recurring_clone_id ? null : data.profile_name,
                selected_custom_every_list: repeat_list.find((item) => item.repeat_frequency === data.repeat_frequency),
                ends_on: !params.recurring_clone_id && data.ends_on ? moment(data.ends_on).format('YYYY-MM-DD HH:mm:ssZ') : null,
                selected_ends_on: !params.recurring_clone_id && data.ends_on ? moment(data.ends_on).format('YYYY-MM-DD HH:mm:ssZ') : null,
                starts_on: !params.recurring_clone_id && data.starts_on ? moment(data.starts_on).format('YYYY-MM-DD HH:mm:ssZ') : moment().format('YYYY-MM-DD HH:mm:ssZ'),
                selected_starts_on: !params.recurring_clone_id && data.starts_on ? moment(data.starts_on).format('YYYY-MM-DD HH:mm:ssZ') : moment().format('YYYY-MM-DD HH:mm:ssZ'),
                selected_repeat_every_list:selected_repeat_every_list?selected_repeat_every_list : { name: 'Custom', repeat_frequency: data.repeat_frequency, repeat_every: data.repeat_every },
            })
        }
    }, [data]);

    React.useEffect(() => {
        if (params.recurring_id) {
            getApiResults(params.recurring_id);
            breadCrumbsStateChangeManager(dispatch, 'Edit');
        }
        if (params.recurring_clone_id) {
            getApiResults(params.recurring_clone_id);
        }

        if (params.mark_id) {
            getMarkExpenseData(params.mark_id);
        }
        // eslint-disable-next-line
    }, []);

    const getApiResults = async (id) => {
        setLoader(dispatch, Actions, true)
        let res = await apiAction({
            method: 'get',
            url: retrieve_recurring_expense(id),
        })
        if (res?.success) {
            res.result.expense_data = { ...res.result.expense_data, vendor: res?.result.vendor, vendor_display_name: res?.result.vendor_display_name };
            setData(res?.result);
            // setLoader(dispatch, Actions, false)
        } else {
            setLoader(dispatch, Actions, false)
        }
    }

    const getMarkExpenseData = async (id) => {
        setLoader(dispatch, Actions, true)
        let res = await apiAction({
            method: "post",
            url: retrieve_expense(id),
            data: { business_id: getBusinessInfo().id },
        });
        let taxRes = await apiAction({
            method: "post",
            url: get_list_of_tax(),
            data: { business_id: getBusinessInfo().id },
        });
        if (res?.success && taxRes.success) {
            res.result = {
                ...res.result,
                attachment:null,
                reference_invoice_number: null,
                vendor_display_name: res?.result.vendor_name,

                vendor: { id: res?.result.vendor_id, name: res?.result.vendor_name, display_name: res?.result.vendor_name, },
                paid_through_account: { id: res?.result.paid_through_account_id, account_name: res?.result.paid_through_account_name },
                customer: res?.result.customer_id && res?.result.customer_name ? { id: res?.result.customer_id, name: res?.result.customer_name, display_name: res?.result.customer_name, } : null,

                expense_accounts_list: [
                    ...res.result.expense_accounts_list.map((item) => {
                        let default_tax = item.tax_id ? taxRes.result.find((tax) => tax.id === item.tax_id) : null;
                        return {
                            ...item,
                            tax: default_tax,
                            account: { id: item.account_id, account_name: item.account_name },
                            gst_rate: default_tax ? default_tax.rate !== 0 ? default_tax.rate : null : null,
                        };
                    }),
                ],

                source_of_supply_id: res?.result.source_of_supply.id,
                source_of_supply: res?.result.source_of_supply.id ? { ...res?.result.source_of_supply, name: res?.result.source_of_supply.state, } : null,

                destination_of_supply_id: res?.result.destination_of_supply.id,
                destination_of_supply: res?.result.destination_of_supply.id ? { ...res?.result.destination_of_supply, name: res?.result.destination_of_supply.state, } : null,
            };

            setData({ ...recurring_expense_initial_data, expense_data: { ...res?.result } });

            // setLoader(dispatch, Actions, false)
        } else {
            setLoader(dispatch, Actions, false)
        }
    }
    //////////////////////////////////////////////////////////////////////////////////////////
    const gst_registration_type = recurringExpenseData.expense_data && recurringExpenseData.expense_data.gst_registration_type;

    let is_Location = gst_registration_type === "overseas";
    const disable_gstin = disabled_gst_number(gst_registration_type) ? true : false;


    const onSave = () => {
        setFormSubmitted(true)
        console.log('=====>recurringExpenseData', recurringExpenseData)

        const { never_expires, ends_on, starts_on, selected_custom_every_list, expense_data } = recurringExpenseData;
        const { expense_accounts_list } = expense_data;

        const is_tax = expense_accounts_list.find((item) => isVendorRegister && !item.tax_id);
        const is_account_not_selected = expense_accounts_list.find((item) => !item.account_id);
        const is_amount_not_selected = expense_accounts_list.find((item) => !item.amount);


        let validation_data = [
            { key: "", validation: expense_data.vendor_id === null, message: "Please Select Vendor" },
            { key: "", validation: expense_data.gst_registration_type === null, message: "Please Select GST Registration Type", },
            !disable_gstin && { key: "", validation: expense_data.gstin_number === null, message: "Please Enter GSTIN Number", },

            { key: "profile_name", message: 'Please Enter Profile Name' },
            { key: "starts_on", message: 'Please Enter Valid Start Date' },
            { key: '', validation: never_expires ? false : ends_on === null, message: 'Please Enter Valid End Date' },
            { key: "", validation: starts_on && ends_on && moment(starts_on).format('YYYY-MM-DD') > moment(ends_on).format('YYYY-MM-DD'), message: 'Start Date Can Not be Greater than End Date.' },
            { key: "repeat_every", message: `Please Enter the Number of ${selected_custom_every_list.name} .` },

            { key: "", validation: expense_data.paid_through_account_id === null, message: "Please Select Paid Through Account ", },
            isVendorRegister && !is_Location && { key: "", validation: expense_data.source_of_supply_id === null, message: "Please Select Source of Supply", },

            { key: "", validation: is_account_not_selected, message: "Please Select Account", },
            { key: "", validation: is_tax, message: "Please Select the Tax" },
            { key: "", validation: is_amount_not_selected, message: "Please Enter Amount.", },
            // !disable_gstin && { key: "", validation: expense_data.reference_invoice_number === null, message: "Please Enter Reference Invoice Number", },
        ];
        const { isValid, message } = isFormValid(recurringExpenseData, validation_data)
        expense_data['currency_symbol'] = expense_data.currency_code;
        if (isValid) {
            onSaveAction()
        } else {
            stateChangeManager(dispatch, Actions, true, 'error', message);
        }

    }

    const onSaveAction = async () => {
        if (params.recurring_id) {
            AnalyticsEvent(eventsNames.categories.RECURRING_EXPENSES,{action:eventsNames.actions.EDIT})
        } else {
            AnalyticsEvent(eventsNames.categories.RECURRING_EXPENSES,{action:eventsNames.actions.CREATE})
        }

        setLoader(dispatch, Actions, true)
        let res = await apiFormData({
            method: 'post',
            data: { ...recurringExpenseData, },
            url: params.recurring_id ? update_recurring_expense(params.recurring_id) : create_recurring_expense(),
        })

        if (res?.success) {
            setLoader(dispatch, Actions, false);
            redirect(res?.result.recurring_expense_id)
            stateChangeManager(dispatch, Actions, true, 'success', res?.status);
        } else {
            setLoader(dispatch, Actions, false);
            stateChangeManager(dispatch, Actions, true, 'error', res?.status);
        }

    }
    return (
        <React.Fragment>

            <CreateNewExpense
                isRecurringExpense={true}
                repeat_list={repeat_list}
                onClickSave={isFormSubmitted}
                repeat_every_list={repeat_every_list}
                recurringExpenseData={recurringExpenseData}
                expense_data={data ? data.expense_data : null}
                setRecurringExpenseData={setRecurringExpenseData}
                vendorEditable={!params.mark_id && !params.recurring_clone_id && Object.keys(params).length !== 0}
                title={params.recurring_id ? 'Update Recurring Expense' : 'New Recurring Expense'}
            />
            <CustomButtonContainer>
                <Buttons onSave={onSave} onCancel={() => navigate(-1)} params={params} />
            </CustomButtonContainer>
        </React.Fragment>
    )
}

const Buttons = (props) => {
    const { onSave, onCancel, params } = props

    return (
        <Box component="form" noValidate>
            <Grid container >
                <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'start', }}>
                    <CustomButton id={'cancel_btn'} dataTestId={'cancel_btn'} variant='outlined' sx={{ textTransform: 'none', '&:hover': { backgroundColor: '#e8f3ff' } }} btnLabel='Cancel' onClick={() => { onCancel();}} />
                </Grid>

                <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'end', }}>
                    <CustomButton id={'save_recurring_btn'} dataTestId={'save_recurring_btn'} variant='contained' sx={{ textTransform: 'none', }} btnLabel={params.recurring_id ? 'update' : 'Save'} onClick={() => { onSave() }} />
                </Grid>
            </Grid>
        </Box>
    )
}

export default CreateRecurringExpense;
const repeat_every_list = [
    { name: 'Week', repeat_frequency: 'week', repeat_every: 1 },
    { name: '2 Week', repeat_frequency: 'week', repeat_every: 2 },
    { name: 'Month', repeat_frequency: 'month', repeat_every: 1 },
    { name: '2 Month', repeat_frequency: 'month', repeat_every: 2 },
    { name: '3 Month', repeat_frequency: 'month', repeat_every: 3 },
    { name: '6 Month', repeat_frequency: 'month', repeat_every: 6 },
    { name: 'Year', repeat_frequency: 'year', repeat_every: 1 },
    { name: '2 Year', repeat_frequency: 'year', repeat_every: 2 },
    { name: '3 Year', repeat_frequency: 'year', repeat_every: 3 },
    { name: 'Custom', repeat_frequency: 'week', repeat_every: 1 },
]

const repeat_list = [
    { name: 'Day(s)', repeat_frequency: 'day' },
    { name: 'Week(s)', repeat_frequency: 'week' },
    { name: 'Month(s)', repeat_frequency: 'month' },
    { name: 'Year(s)', repeat_frequency: 'year' }
]