import React from "react";
import { images, Img } from '../../../../../utils/Constant'
import CustomButton from "../../../../custom/button/CustomButton";
import CustomTypography from '../../../../custom/typography/CustomTypography';
import { BootstrapDialog } from '../../../bulkStatementUpload/mappings/Transactions';
import { CustomRadio } from "../../../setting/settings/Preferences/common/CustomElement";
import { BootstrapDialogTitle } from '../../../bulkStatementUpload/mappings/Transactions';

import {
    CustomContainer,
    CustomButtonContainer,
} from "../../../../custom/container/CustomContainer";

import {
    Box,
    Grid,
    DialogActions,
    DialogContent,
} from "@mui/material"

export const Templates = (props) => {
    const {defaultSelected='list_view', select='list_view',setSelect} = props;
    const [next, setNext] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    const onClose=()=>{
        setOpen(false);
        setSelect(defaultSelected)
    }

    const list_templates = [
        {
            type: 'list_view',
            title: 'List View',
            src: images.list_view.src,
            alt: images.list_view.alt,
            imageStyle: { height: '300px' },
            onClick: () => { },
        },
        {
            title: 'Pie Chart',
            type: 'pie_chart',
            src: images.pie_chart.src,
            alt: images.pie_chart.alt,
            imageStyle: {height: '300px' },
            onClick: () => { },

            // style: {
            //     background: `#0E2946`
            // },
        },
        {
            title: 'Line Chart',
            type: 'line_chart',
            src: images.line_chart.src,
            alt: images.line_chart.alt,
            imageStyle: { height: '300px',objectFit: 'fill' },
            onClick: () => { },

            // style: {
            //     background: `#182488`
            // },
        },
        {
            title: 'Bar Graph',
            type: 'bar_graph',
            src: images.bar_graph.src,
            alt: images.bar_graph.alt,
            imageStyle: { height: '300px' },

            // style: {
            //     background: `linear-gradient(117deg, #57026A 0.63%, #3A1362 70.66%)`
            // },
            onClick: () => { },
        },

        {
            title: 'Scatter Chart',
            type: 'scatter_chart',
            src: images.scatter_chart.src,
            alt: images.scatter_chart.alt,
            imageStyle: { height: '300px' },

            // style: {
            //     background: `#1A2842`
            // },
            onClick: () => { },
        },
        // {
        //     title: 'Deviation Chart',
        //     type: 'deviation_chart',
        //     src: images.deviation_chart.src,
        //     alt: images.deviation_chart.alt,
        //     imageStyle: { height: '300px' },

        //     style: {
        //         background: `#FFE077`
        //     },
        //     onClick: () => { },
        // },

    ]

    return (
        <React.Fragment>
            {!open &&
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <CustomTypography
                        text={`Template : ${list_templates.find((item)=>item.type===select).title}`}
                        sx={{
                            color: '#000000',
                            fontSize: '14px',
                            fontWeight: '700',
                            lineHeight: '18px',
                            fontStyle: 'normal',
                            textTransform: 'none',
                            fontFamily: "Noto Sans",

                        }}
                    />
                    <CustomTypography
                        text={'Change'}
                        onClick={() => setOpen(true)}
                        sx={{
                            ml: 1,
                            color: '#2464eb',
                            fontSize: '14px',
                            cursor: 'pointer',
                            fontWeight: '700',
                            lineHeight: '18px',
                            fontStyle: 'normal',
                            textTransform: 'none',
                            fontFamily: "Noto Sans",
                            '&:hover': { textDecoration: 'underline' }
                        }}
                    />

                </Box>
            }
            <ManageTemplates open={open} onClose={onClose}>

                <Box sx={{ mt: 0, overflow: 'hidden', maxHeight: 'calc(100vh - 140px)' }}>

                    <CustomContainer maxWidth={"400"} sx={{mt:0,pt:'0px !important', height: 'calc(100vh - 230px)', }}>
                        {next ?
                            ''
                            :
                            <Grid container spacing={0}>
                                {list_templates.map((item, index) => {
                                    return (
                                        <Grid xs={12} sm={6} md={4} index={index} sx={{ p: 1.5,":hover":{borderRadius:1,background:'#dae4fb4a'} }}>
                                            <CustomRadio
                                                checked={select === item.type}
                                                id={`select_template_${index}`}
                                                dataTestId={`select_template_${index}`}
                                                onClickedHandler={(value) => { setSelect(!value ? item.type : null) }}

                                                label={<span style={{
                                                    color: '#141414',
                                                    fontSize: '13px',
                                                    fontWeight: '700',
                                                    fontStyle: 'normal',
                                                    lineHeight: 'normal',
                                                    fontFamily: "Noto Sans",
                                                }}>{item.title}</span>}
                                            />
                                            <Box sx={{ ...item.style, cursor: 'pointer' }} onClick={() => { setSelect(item.type !== select ? item.type : null) }}>
                                                <Img
                                                    src={item.src}
                                                    alt={item.alt}
                                                    sx={{
                                                        pt: 2, pb: 2,
                                                        flexShrink: 0,
                                                        width: '100%',
                                                        height: '100%',
                                                        objectFit:'contain',
                                                        background:'#FFFFFF',
                                                        minWidth: { xs: '100%', sm: '210px' },
                                                        minHeight: { xs: '100%', sm: '210px' },
                                                        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                                                        ...item.imageStyle
                                                    }}
                                                />
                                            </Box>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        }
                    </CustomContainer>

                    <CustomButtonContainer sx={{ mr: 0, ml: 0 }}>
                        <Buttons
                            next={next}
                            disabled={!select}
                            setNext={()=>{setOpen(false)}}

                            onSave={() => { setOpen(false);}}
                            onCancel={() => {onClose() }}
                        />
                    </CustomButtonContainer>
                </Box>
            </ManageTemplates>
        </React.Fragment>

    )
}

const Buttons = (props) => {
    const { onSave, onCancel, next, setNext, disabled } = props

    return (
        <Box component="form" noValidate>
            <Grid container>
                <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'start' }}>
                    <CustomButton id={'cancel_btn'} dataTestId={'cancel_btn'} variant='outlined' sx={{ textTransform: 'none', '&:hover': { backgroundColor: '#e8f3ff' } }} btnLabel='Cancel' onClick={onCancel} />
                    {next &&
                        <CustomButton id={'previous_btn'} dataTestId={'previous_btn'} variant='contained' sx={{ ml: 1, }} btnLabel='Previous' onClick={() => { setNext(false) }} />
                    }
                </Grid>
                <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'end', }}>
                    {next ?
                        <CustomButton id={'save_btn'} dataTestId={'save_btn'} variant='contained' sx={{ ml: 1, textTransform: 'none' }} btnLabel={'Save'} onClick={onSave} />
                        :
                        <CustomButton disabled={disabled} id={'next_btn'} dataTestId={'next_btn'} variant='contained' sx={{ ml: 1, textTransform: 'none' }} btnLabel={'Next'} onClick={() => { setNext(true) }} />
                    }
                </Grid>
            </Grid>
        </Box>
    )
}

const ManageTemplates = (props) => {
    const { children, open, onClose } = props;
    return (
        <BootstrapDialog
            fullWidth
            open={open}
            maxWidth={'lg'}
        >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={() => onClose()}>
                <CustomTypography
                    text={<span>Choose from Template <span style={{fontSize:'10px',fontWeight: '500',}}>(These images are only for sample purposes.)</span></span>}
                    sx={{
                        color: '#000000',
                        fontSize: '16px',
                        fontWeight: '700',
                        lineHeight: '18px',
                        fontStyle: 'normal',
                        fontFamily: "Noto Sans",
                    }}
                />
            </BootstrapDialogTitle>
            <DialogContent dividers sx={{overflow:'hidden'}}>
                    {children}
                <DialogActions>
                </DialogActions>
            </DialogContent>
        </BootstrapDialog>
    )
}