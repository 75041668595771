import React from 'react';
import ListBarcode from './ListBarcode';
import PreviewBarcode from './PreviewBarcode';
import BarcodeGenerator from './BarcodeGenerator';
import DateRange from '../../../common/DateRange';
import '../../../../assets/css/BarcodeGenerator.css';
import CustomTitle from '../../../common/CustomTitle';
import CustomButton from '../../../custom/button/CustomButton';
import { eventsNames,AnalyticsEvent } from '../../../../firebase/firebaseAnalytics';


import {
    sizeType,
    printerType,
    initial_data,
    defaultSizeType,
    getBarcodeConfig,
    getSizeTypePages,
    defaultPrinterType,
    onBarcodeEditHandler,
    initial_preview_data,
    onBarcodeRemoveHandler,
    onBarcodeAddHandlerLabel,
    onBarcodeAddHandlerRegular,
} from './Helper';

import {
    CustomContainer,
    CustomTitleContainer,
    CustomButtonContainer,
} from '../../../custom/container/CustomContainer';

import {
    Grid,
} from '@mui/material';

const Dropdown = DateRange

const Barcode = () => {

    const [data, setData] = React.useState([]);
    const [selectedData, setSelectedData] = React.useState({ ...initial_data });
    const [selectedSize, setSelectedSize] = React.useState({...defaultSizeType});
    const [previewData, setPreviewData] = React.useState({ ...initial_preview_data });
    const [selectedPrinter, setSelectedPrinter] = React.useState({...defaultPrinterType});

    const onBarcodeRemove = (value) => onBarcodeRemoveHandler(value, setData);
    const onBarcodeEdit = (value) => onBarcodeEditHandler(value, setSelectedData);
    const onBarcodeAdd = (value) => previewData?.printer_type === 'label_printer'? onBarcodeAddHandlerLabel(value, setData):onBarcodeAddHandlerRegular(value, setData);

    React.useEffect(() => {
        setPreviewData((prev) => ({
            ...prev,
            size: selectedSize?.name,
            printer_type: selectedPrinter?.value,
            barcode_config: getBarcodeConfig(data)
        }))
    }, [data, selectedSize, selectedPrinter]);


    return (
        <div>
            <CustomTitleContainer>
                <Headers
                    size={selectedSize}
                    type={selectedPrinter}
                    setSize={(value)=>{setSelectedSize(value)}}
                    setType={(value)=>{setSelectedPrinter(value);setData([])}}
                />
            </CustomTitleContainer>
            <CustomContainer maxWidth="400px" sx={{ background: '#FAFAFA', boxShadow: 'none', height: 'calc(100vh - 224px)' }}>
                <BarcodeGenerator
                    previewData={previewData}
                    onBarcodeAdd={onBarcodeAdd}
                    initial_data={initial_data}
                    selectedData={selectedData}
                    setSelectedData={setSelectedData}
                />
                <div style={{ marginTop: '24px' }}>
                    <ListBarcode
                        data={data}
                        onBarcodeEdit={onBarcodeEdit}
                        onBarcodeRemove={onBarcodeRemove}
                    />
                </div>
            </CustomContainer>
            <CustomButtonContainer>
                <Buttons
                    size={selectedSize}
                    previewData={previewData}
                    isMessageContainer={Boolean(data?.length)}
                    copies={data.reduce((sum, item) => sum + parseInt(item.no_of_copies ? item.no_of_copies : 1), 0)}
                />
            </CustomButtonContainer>
        </div>
    );
};

export default Barcode;


const Headers = ({ type, size, setType, setSize }) => {

    return (
        <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
            <Grid item xs={12} sm={6}>
                <CustomTitle title="Barcode Generator" />
            </Grid>
            <Grid item xs={12} sm={6} container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Dropdown
                        label='Printer'
                        value={type}
                        results={printerType}
                        disableClearable={true}
                        placeholder='Select Printer'
                        id={'printing_type_dropdown'}
                        dataTestId={'printing_type_dropdown'}
                        setValue={(event, value) => { setType(value) }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Dropdown
                        label='Size'
                        value={size}
                        disableClearable={true}
                        placeholder='Select size'
                        id={'size_type_dropdown'}
                        dataTestId={'size_type_dropdown'}
                        results={type ? sizeType[type?.value] : []}
                        setValue={(event, value) => { setSize(value) }}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

const Buttons = ({ previewData, size, copies, isMessageContainer }) => {

    const [open, setOpen] = React.useState(false);

    return (
        isMessageContainer &&
        <div className='message-main-container'>
            <PreviewBarcode
                open={open}
                maxWidth='md'
                title={open}
                previewData={previewData}
                onClose={() => { setOpen(false) }}
            />
            <div class="message-container">
                <div class="message">{`You will need ${getSizeTypePages(size, copies)} pages for printing`}</div>
                <div class="paper-size">Paper Size: A4</div>
            </div>
            <div class="message-container">
                <CustomButton
                    sx={{}}
                    variant="outlined"
                    btnLabel='Preview'
                    id={'preview_barcode_btn'}
                    dataTestId={'preview_barcode_btn'}
                    onClick={() => { 
                        setOpen('preview');
                        AnalyticsEvent(eventsNames.categories.BARCODE_GENERATOR,{action:eventsNames.actions.PREVIEW});
                     }}
                />
                <CustomButton
                    sx={{}}
                    variant="contained"
                    btnLabel='Generate'
                    id={'generate_barcode_btn'}
                    dataTestId={'generate_barcode_btn'}
                    onClick={() => { 
                        setOpen('Generate');
                        AnalyticsEvent(eventsNames.categories.BARCODE_GENERATOR,{action:eventsNames.actions.GENERATE});
                     }}
                />
            </div>
        </div>
    )
}
