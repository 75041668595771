import moment from 'moment';
import {
     getAddress,
     filterArray,
     isAddressSame
     } from '../../../../../utils/Utils';

export const getPartyContactData = (customer) => {
    return customer.party_contact_data.find(
        (data) => data.contact_type === "primary"
    ) || {};
};

export const getBillingAddress = (customer) => {
    return customer.billing_address ? getAddress(customer.billing_address) : "";
};

export const getShippingAddress = (customer) => {
    return customer.shipping_address ? getAddress(customer.shipping_address) : "";
};

export const getPaymentTerm = (customer) => {
    return customer.payment_term || {};
};

export const getCurrency = (customer) => {
    return customer.currency || {};
};

export const getDueDate = (paymentTerm, invoice) => {
    return paymentTerm.number_of_days
        ? moment(invoice.invoice_date).add(paymentTerm.number_of_days, "days").format("YYYY-MM-DD")
        : invoice.due_date;
};


export const customerMappings = (customer, invoice, setInvoice) => {
    if (customer) {
        const partyContactData = getPartyContactData(customer);
        const billingAddress = getBillingAddress(customer);
        const shippingAddress = getShippingAddress(customer);
        const paymentTerm = getPaymentTerm(customer);
        const currency = getCurrency(customer);
        const dueDate = getDueDate(paymentTerm, invoice);

        setInvoice(prevInvoice => ({
            ...prevInvoice,
            customer: customer,
            customer_emails: {
                ...prevInvoice.customer_emails,
                to:  partyContactData.email?filterArray([partyContactData.email]):[],
            },
            customer_id: customer.id,
            billing_address: billingAddress,
            shipping_address: shippingAddress,
            payment_term: paymentTerm,
            payment_term_id: paymentTerm.id,
            due_date: dueDate,
            currency_id: currency.id,
            currency_symbol: currency.symbol,
            currency_code: currency.currency_code,
            payment_method: customer.preferred_payment_method || "cash",
            same_as_billing_address: isAddressSame(billingAddress, shippingAddress),
            place_of_supply: null,
            place_of_supply_id: null,
        }));
    } else {
        setInvoice(prevInvoice => ({
            ...prevInvoice,
            customer: null,
            customer_id: null,
            payment_term: null,
            currency_code: null,
            billing_address: null,
            currency_symbol: null,
            payment_term_id: null,
            payment_method: "cash",
            shipping_address: null,
            due_date: prevInvoice.due_date,
            same_as_billing_address: false,
            customer_emails: { cc: [], to: [], bcc: [] },
        }));
    }
};
