// eslint-disable-next-line
Array.prototype.addLabelValue=function(labelKey,valueKey){
    this.map(item=>{
        item['label']=item[labelKey]
        item['value']=item[valueKey] 
        return item
    })
    return this
};
// eslint-disable-next-line
Array.prototype.insert = function ( index, item ) {
    this.splice( index, 0, item );
};
// eslint-disable-next-line
Array.prototype.remove = function(elem) {
    var indexElement = this.findIndex(el => el === elem);
    if (indexElement !== -1)
      this.splice(indexElement, 1);
    return this;
  };

  


