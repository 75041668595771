import React from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { apiAction } from '../../../../../api/api';
import Loader from '../../../../custom/loader/Loader';
import * as Actions from '../../../../../state/Actions';
import { getDateFormat } from '../../../../../utils/Utils';
import { routesName } from '../../../../../config/routesName';
import { CustomLink } from '../../../../custom/link/CustomLink';
import { retrieve_journal_entry } from '../../../../../api/urls';
import { getBusinessInfo } from '../../../../../config/cookiesInfo';
import CurrencyFormatter from '../../../../common/CurrencyFormatter';
import CustomTypography from '../../../../custom/typography/CustomTypography';

import {
    CustomTable,
    CustomTableRow,
    CustomTableBody,
    CustomTableHead,
    CustomTableHeadCell,
    CustomTableBodyCell,
    CustomTableContainer,
} from '../../../../custom/table/CustomTable';


import {
    Grid,
} from '@mui/material';
import { CustomContainer } from '../../../../custom/container/CustomContainer';

const ViewJournalEntry = (props) => {
    const { id } = props

    let navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);

    React.useEffect(() => {
        if (id) {
            getApiResults(id);
        }
    }, [id])

    const [results, setResults] = React.useState()
    const [currency, setCurrency] = React.useState()
    const getApiResults = async (id) => {
        let res = await apiAction({
            method: 'post',
            navigate: navigate,
            dispatch: dispatch,
            url: retrieve_journal_entry(id),
            data: { business_id: getBusinessInfo().id }
        })
        if (res?.success) {
            setResults(res?.result)
            setCurrency(res?.result.transactions[0].currency)
        } else {

        }
    }

    return (
        <CustomContainer maxWidth={"400"} sx={{}}>
            <TransactionInfo results={results} currency={currency} />
            <CustomTableContainer sx={{ mt: 0 }}>
                <CustomTable>
                    <TransactionHeaders />
                    {
                        results?.transactions &&
                        <TransactionBody results={results.transactions} currency={currency} />
                    }
                </CustomTable>
                {!results?.transactions &&
                    <CustomTypography
                        text={<Loader />}
                        sx={{
                            mt: '15vh',
                            color: '#000000',
                            fontSize: '14px',
                            fontWeight: '700',
                            textAlign: 'center',
                            lineHeight: '18px',
                            fontStyle: 'normal',
                            fontFamily: "Noto Sans",
                        }}
                    />
                }
            </CustomTableContainer>
        </CustomContainer>
    )
}

const TransactionHeaders = () => {
    return (
        <CustomTableHead>
            <CustomTableRow >
                <CustomTableHeadCell style={{ width: 150 }}><span>Account</span></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 150 }} align='right'><span>Debit</span></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 150 }} align='right'><span>Credit</span></CustomTableHeadCell>
            </CustomTableRow>
        </CustomTableHead>
    )
}

const TransactionBody = (props) => {
    const { results, currency } = props
    return (
        <CustomTableBody>
            {

                results?.map((item, index) => {
                    return (
                        <CustomTableRow key={index}>
                            <CustomTableBodyCell sx={{}}>
                                <CustomLink style={{ color: '#2464EB' }} to={routesName.journalEntryTransaction.path.replace(':id', item.business_account_id)}><span>{item.business_account}</span></CustomLink>

                                {/* <span>{item.business_account}</span> */}
                            </CustomTableBodyCell>
                            <CustomTableBodyCell sx={{}} align='right'><span>{item.transaction_type === "DEBIT" ? <CurrencyFormatter amount={item.amount} currency={currency} /> : '-'}</span></CustomTableBodyCell>
                            <CustomTableBodyCell sx={{}} align='right'><span>{item.transaction_type === "CREDIT" ? <CurrencyFormatter amount={item.amount} currency={currency} /> : '-'}</span></CustomTableBodyCell>
                        </CustomTableRow>
                    )
                })
            }
        </CustomTableBody>
    )
}

const TransactionInfo = (props) => {
    const { results, currency } = props

    const toReturnColor = (status) => {
        let color = {
            color: '',
            background: '',
            borderColor: '',
        }
        if (status === 'is_contra') {
            color.color = '#00000';
            color.background = '#ede3c175';
            color.borderColor = '#635e5edb';

        } else if (status) {
            color.color = '#00000';
            color.background = '#cdebc7';
            color.borderColor = '#a2d998';
        } else {
            color.color = '#00000';
            color.background = '#e8f3ff';
            color.borderColor = '#a8a8a8';
        }
        return color
    }

    console.log('===>results', results)
    return (
        results && (
            <Grid container item xs={12} sx={{ alignItems: 'center' }}>
                <Grid item xs={4}>
                    <CustomTypography
                        text={"Date"}
                        sx={{
                            mb: 1,
                            color: '#000000',
                            fontSize: '14px',
                            fontWeight: '700',
                            lineHeight: '18px',
                            fontStyle: 'normal',
                            textTransform: 'none',
                            fontFamily: "Noto Sans",
                        }}
                    />
                    <CustomTypography
                        text={moment(results.transaction_date).format(getDateFormat())}
                        sx={{
                            color: '#000000',
                            fontWeight: 700,
                            fontSize: '12px',
                            lineHeight: '16px',
                            fontStyle: 'normal',
                            fontFamily: 'Noto Sans',
                        }}
                    />
                </Grid>
                <Grid item xs={4} sx={{}}>
                    <CustomTypography
                        text={"Amount"}
                        sx={{
                            mb: 1,
                            color: '#000000',
                            fontSize: '14px',
                            fontWeight: '700',
                            lineHeight: '18px',
                            fontStyle: 'normal',
                            textTransform: 'none',
                            fontFamily: "Noto Sans",
                        }}
                    />
                    <CustomTypography
                        text={<CurrencyFormatter amount={results.transaction_amount} currency={currency} />}
                        sx={{
                            color: '#000000',
                            fontWeight: 700,
                            fontSize: '12px',
                            lineHeight: '16px',
                            fontStyle: 'normal',
                            fontFamily: 'Noto Sans',
                        }}
                    />

                </Grid>
                <Grid item xs={4} sx={{ textAlign: 'end' }}>
                    <span
                        style={{
                            border: '1px',
                            width: '50px',
                            paddingTop: 5,
                            paddingLeft: 15,
                            paddingBottom: 5,
                            fontSize: '14px',
                            paddingRight: 15,
                            textAlign: 'center',
                            borderRadius: '5px',
                            display: 'inline-block',
                            fontFamily: "Noto Sans",
                            color: toReturnColor(results.is_contra ? 'is_contra' : results.is_posted).color,
                            background: toReturnColor(results.is_contra ? 'is_contra' : results.is_posted).background,
                        }}
                    >{results.is_contra ? 'Contra' : results.is_posted ? 'Posted' : 'Draft'}</span>
                </Grid>

                <Grid item xs={12} sx={{ mt: 4, mb: 4 }}>
                    <CustomTypography
                        text={"Description"}
                        sx={{
                            mb: 1,
                            color: '#000000',
                            fontSize: '14px',
                            fontWeight: '700',
                            lineHeight: '18px',
                            fontStyle: 'normal',
                            textTransform: 'none',
                            fontFamily: "Noto Sans",
                        }}
                    />
                    <CustomTypography
                        text={results.description}
                        sx={{
                            color: '#000000',
                            fontWeight: 700,
                            fontSize: '12px',
                            lineHeight: '16px',
                            fontStyle: 'normal',
                            fontFamily: 'Noto Sans',
                        }}
                    />

                </Grid>
                <Grid item xs={12} sx={{ mb: 4 }}>
                    <CustomTypography
                        text={"Note"}
                        sx={{
                            mb: 1,
                            color: '#000000',
                            fontSize: '14px',
                            fontWeight: '700',
                            lineHeight: '18px',
                            fontStyle: 'normal',
                            textTransform: 'none',
                            fontFamily: "Noto Sans",
                        }}
                    />
                    <CustomTypography
                        text={results.note ? results.note : '--'}
                        sx={{
                            color: '#000000',
                            fontWeight: 700,
                            fontSize: '12px',
                            lineHeight: '16px',
                            fontStyle: 'normal',
                            fontFamily: 'Noto Sans',
                        }}
                    />
                </Grid>
            </Grid>

        )
    )
}
export default ViewJournalEntry;