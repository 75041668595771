import React from 'react';

import { Grid, Box } from '@mui/material';
import Input from '../../../common/Input';
import PreviewBarcode from './PreviewBarcode';
import { useNavigate } from 'react-router-dom';
import { apiAction } from '../../../../api/api';
import * as Actions from '../../../../state/Actions';
import CustomButton from '../../../custom/button/CustomButton';
import { LabelWithAsteriskMark } from '../../../common/CommonLabel';
import CommonAsyncDropdown from '../../../common/CommonAsyncDropdown';
import CustomTypography from '../../../custom/typography/CustomTypography';
import { getToken, getBusinessInfo } from '../../../../config/cookiesInfo';
import { eventsNames,AnalyticsEvent } from '../../../../firebase/firebaseAnalytics';
import { list_items, get_barcode, get_item_dropdown_fields } from '../../../../api/urls';
import AllowDuplicateMultipleValueDropdown from '../../../custom/dropdown/AllowDuplicateMultipleValueDropdown';

import {
  initial_data as initialData,
  html_string as initialHtmlString,

} from './Helper';

const BarcodeGenerator = ({ previewData, selectedData, setSelectedData, onBarcodeAdd }) => (
  <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
    <Box gridColumn={{ xs: 'span 12', sm: 'span 12', md: 'span 12', lg: 'span 10' }} className="barcode-custom-card">
      <BarcodeGeneratorContainer
        selectedData={selectedData}
        setSelectedData={setSelectedData}
      />
    </Box>
    <Box gridColumn={{ xs: 'span 12', sm: 'span 12', md: 'span 12', lg: 'span 2' }} className="barcode-custom-card">
      <BarcodeAdd
        previewData={previewData}
        onBarcodeAdd={onBarcodeAdd}
        selectedData={selectedData}
        setSelectedData={setSelectedData}
        initialHtmlString={initialHtmlString}
      />
    </Box>
  </Box>
);

export default BarcodeGenerator;

const BarcodeGeneratorContainer = ({ selectedData, setSelectedData }) => {
  const navigate = useNavigate();
  const dispatch = Actions.getDispatch(React.useContext);
  const [dropdownFields, setDropdownFields] = React.useState([]);

  const getApiResults = async (id) => {
    try {
      const res = await apiAction({
        method: 'get',
        dispatch,
        navigate,
        url: get_item_dropdown_fields(id),
      });

      if (res?.success) {
        const result = res.result.map((item) => ({ name: item, id: item }));
        setDropdownFields(result);
      }
    } catch (error) {
      console.error('Error fetching API results:', error);
    }
  };

  React.useEffect(() => {
    if (selectedData.item_id) {
      getApiResults(selectedData.item_id);
    }
  }, [selectedData.item_id]);

  const handleFieldChange = (selectedField, field, maxLength) => (value) => {
    if (value.length <= maxLength) {
      setSelectedData((prev) => ({
        ...prev,
        [selectedField]: value,
        [field]: value.map((field) => field.id).join(',') || '',
      }));
    }
  };

  const handleItemChange = (event, value) => {
    setDropdownFields([]);
    setSelectedData((prev) => ({
      ...prev,
      selected_item: value,
      item_id: value?.id || null,
    }));
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <CommonAsyncDropdown
            optionLabel="name"
            autoSelect={false}
            validation={false}
            id="item_dropdown"
            disableClearable={false}
            className="item_dropdown"
            dataTestId="item_dropdown"
            placeholder="Select item name"
            noOptionsText="No result found"
            item={{
              method: 'post',
              url: list_items(1),
              onChange: handleItemChange,
              value: selectedData.selected_item,
              label: <LabelWithAsteriskMark label="Item Name" />,
              body: {
                sort_order: 'D',
                for_sales: true,
                sort_by: 'created_at',
                business_id: getBusinessInfo().id,
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            id="input_number_copies"
            dataTestId="input_number_copies"
            item={{
              type: 'number',
              required: true,
              fullWidth: true,
              validation: false,
              inputProps: { maxLength: 16 },
              placeholder: 'Enter No of copies greater than zero',
              value: selectedData.no_of_copies?selectedData.no_of_copies:'',
              title: <LabelWithAsteriskMark label='No of copies' />,
              onChange: (e) => setSelectedData((prev) => ({
                ...prev,
                no_of_copies: e.target.value,
              })),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FieldGroup
            label="Title"
            fields={[
              { id: 'title_line_1', sm: 6, maxLength: 1, value: selectedData.selected_title_line_1 },
              { id: 'title_line_2', sm: 6, maxLength: 1, value: selectedData.selected_title_line_2 }
            ]}
            dropdownFields={dropdownFields}
            handleFieldChange={handleFieldChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FieldGroup
            label="Note"
            fields={[
              { id: 'note_line_1', sm: 4, maxLength: 1, value: selectedData.selected_note_line_1 },
              { id: 'note_line_2', sm: 4, maxLength: 2, value: selectedData.selected_note_line_2 },
              { id: 'note_line_3', sm: 4, maxLength: 2, value: selectedData.selected_note_line_3 }
            ]}
            dropdownFields={dropdownFields}
            handleFieldChange={handleFieldChange}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const FieldGroup = ({ label, fields, handleFieldChange, dropdownFields }) => (
  <Grid container spacing={1}>
    <Grid item xs={12}><CustomLabel label={label} sx={{
      mb: 0,
      fontSize: '18px',
      color: '#0000007d',
      textDecorationThickness: '2px',
      textDecorationColor: '#0000002e',
      textDecorationLine: 'underline',
    }} /></Grid>
    {fields.map((field) => (
      <Grid item xs={12} sm={field.sm} key={field.id}>
        <CustomMultipleValueDropdown
          id={field.id}
          value={field.value}
          dataTestId={field.id}
          results={dropdownFields}
          label={`Line ${field.id.split('_').pop()}`}
          setValue={handleFieldChange(`selected_${field.id}`, field.id, field.maxLength)}
          placeholder={`Only ${field.maxLength} item${field.maxLength > 1 ? 's' : ''} can select`}
        />
      </Grid>
    ))}
  </Grid>
);

const CustomLabel = ({ label, sx = {} }) => (
  <CustomTypography
    text={label}
    sx={{
      mb: 1,
      color: '#000000',
      fontSize: '14px',
      fontWeight: '700',
      lineHeight: '18px',
      fontStyle: 'normal',
      textTransform: 'none',
      fontFamily: 'Noto Sans',
      ...sx,
    }}
  />
);

const CustomMultipleValueDropdown = ({ id, dataTestId, label, value, setValue, results = [], placeholder }) => (
  <div>
    <CustomLabel label={label} />
    <AllowDuplicateMultipleValueDropdown
      id={id}
      value={value}
      groupByLabel=""
      results={results}
      matchValueKey="id"
      optionLabel="name"
      setValue={setValue}
      defaultValue={value}
      allowDuplicate={false}
      dataTestId={dataTestId}
      placeholder={placeholder}
      isOptionEqualToValue={true}
      listboxStyle={{ maxHeight: 200 }}
    />
  </div>
);

const BarcodeAdd = ({ previewData, selectedData, setSelectedData, initialHtmlString, onBarcodeAdd }) => {
  const { item_id, title_line_1, title_line_2 ,note_line_1,note_line_2,note_line_3} = selectedData;
  const [openAlert, setOpenAlert] = React.useState(false);
  const [htmlString, setHtmlString] = React.useState(initialHtmlString);

  const onBarcodeAddHandler = () => {
    AnalyticsEvent(eventsNames.categories.BARCODE_GENERATOR,{action:eventsNames.actions.ADD});
    if (previewData?.printer_type === 'label_printer' && previewData.barcode_config.length !== 0) {
      setOpenAlert(true)
    } else {
      onAdd()
    }
  };

  const onAdd = () => {
    if (selectedData.item_id) {
      onBarcodeAdd(selectedData);
      setHtmlString(initialHtmlString);
      setSelectedData({ ...initialData });
    }
  }
  const getApiResults = async (data) => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${getToken()}`,
      },
    };

    try {
      const response = await fetch(get_barcode(data), requestOptions);
      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }
      const result = await response.text();
      setHtmlString(result);
    } catch (error) {
      console.error('Error fetching barcode:', error);
    }
  };

  React.useEffect(() => {
    if (selectedData.item_id) {
      getApiResults(selectedData);
    }
  }, [item_id, title_line_1, title_line_2 ,note_line_1,note_line_2,note_line_3]);

  const onClose = () => {
    setOpenAlert(false);
  }
  const onSave = () => {
    onAdd();
    setOpenAlert(false);
  }

  const isAddButtonDisabled = !selectedData.item_id || !selectedData.no_of_copies || Number(selectedData.no_of_copies) === 0;
  return (
    <div className="barcode-section">
      <PreviewBarcode
        maxWidth='sm'
        isAlert={true}
        onSave={onSave}
        open={openAlert}
        title={'Add New'}
        onClose={onClose}
      />
      <div className="barcode" dangerouslySetInnerHTML={{ __html: htmlString }} />
      <CustomButton
        variant="contained"
        id="barcode_add_btn"
        className="barcode-button"
        btnLabel="+ Add for Barcode"
        dataTestId="barcode_add_btn"
        onClick={onBarcodeAddHandler}
        disabled={isAddButtonDisabled}
        sx={{ width: '100%', maxWidth: '400px' }}
      />
    </div>
  );
};

