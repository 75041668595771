import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";



// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional


// NUmbers Web App
// const firebaseConfig = {
//   apiKey: "AIzaSyCr7Ueo9mQmTaxgn9FcG1M8ib2L_hzlO8Y",
//   authDomain: "number-web-app.firebaseapp.com",
//   projectId: "number-web-app",
//   storageBucket: "number-web-app.appspot.com",
//   messagingSenderId: "916407487801",
//   appId: "1:916407487801:web:ff490e31f7350a371a6ce8",
//   measurementId: "G-9CHWJ63T75"
// };

// Finycs Web App
const firebaseConfig = {
  apiKey: "AIzaSyAijDqBWygAEqbfVU374JgZAUuVqY2NVtc",
  authDomain: "finycs-49924.firebaseapp.com",
  projectId: "finycs-49924",
  storageBucket: "finycs-49924.appspot.com",
  messagingSenderId: "116279361657",
  appId: "1:116279361657:web:5a492a119a8fd6e10314a8",
  measurementId: "G-FV3NLEX8N2"
};

const firebaseApp = initializeApp(firebaseConfig);
export const analytics = getAnalytics(firebaseApp);

