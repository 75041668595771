import React from 'react';
import DateRange from '../../common/DateRange';
import ReportSearch from './common/ReportSearch';
import * as Actions from '../../../state/Actions';
import ReportSchedules from './common/ReportSchedules';
import { MetaData } from '../../custom/tooltip/CustomTooltip';
import { getBusinessInfo } from '../../../config/cookiesInfo';
import ReportAccountMapping from './common/ReportAccountMapping';
import CustomTypography from '../../custom/typography/CustomTypography';
import { CustomTitleContainer } from '../../custom/container/CustomContainer';
import { CustomCheckbox } from '../setting/settings/Preferences/common/CustomElement';

import {
    setLoader,
    stateChangeManager,
} from '../../../utils/Utils';

import {
    apiAction,
} from '../../../api/api';

import {
    Box,
    Button,
    Popover,
    Divider,
    MenuItem,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const Dropdown = DateRange
const ReportsSettingButtons = (props) => {
    const { ratioData, exportData, labelData, accountMappingData, zeroData, scheduleData } = props;

    const dispatch = Actions.getDispatch(React.useContext);


    const exportAsPdf = async (exportData) => {
        const { url, data, method = 'post' } = exportData;
        let res = await apiAction({
            url: url,
            method: method,
            data: { ...data, export_type: "pdf", business_id: getBusinessInfo().id },
        })
        if (res?.success) {
            setLoader(dispatch, Actions, false);
            stateChangeManager(dispatch, Actions, true, 'success', res?.status);
        } else {
            setLoader(dispatch, Actions, false);
            stateChangeManager(dispatch, Actions, true, 'error', res?.status);
        }
    }

    const buttonStyle = {
        height: '33px',
        fontSize: '12px',
        fontWeight: '700',
        lineHeight: '16px',
        fontStyle: 'normal',
        textTransform: 'none',
        fontFamily: 'Noto Sans',
    }

    return (
        <CustomTitleContainer>
            <Box sx={{ display: '-webkit-flex', justifyContent: 'end', alignItems: 'center' }}>


                {
                    ratioData && (
                        <Dropdown
                            sx={{ minWidth: 200, }}
                            value={ratioData.value}
                            disableClearable={true}
                            id={'ratio_type_dropdown'}
                            results={ratioData.ratioType}
                            dataTestId={'ratio_type_dropdown'}
                            placeholder='select date Ratio type'
                            setValue={(event, selectValue) => { ratioData.setValue(selectValue) }
                            }
                        />
                    )
                }

                {zeroData && (
                    <CustomCheckbox id={'Zero_check_box'} dataTestId={'Zero_check_box'} checked={zeroData.value} label={<span style={{}}>{'Show non-zero Accounts'} </span>} onClickedHandler={(value) => zeroData.setValue(!value)} />
                )
                }

                {labelData && (

                    <CustomCheckbox id={'check_box'} dataTestId={'check_box'} checked={labelData.value} label={<span style={{}}>{labelData.title} ({spanTag(labelData.labelName)}) </span>} onClickedHandler={(value) => labelData.setValue(!value)} />
                )
                }
                {scheduleData && (
                    <ReportSchedules buttonStyle={buttonStyle} {...scheduleData} />
                )}

                {accountMappingData && (
                    <AccountMapping buttonStyle={buttonStyle} data={accountMappingData} />
                )}

                {exportData &&
                    (

                        <CustomExportButton
                            buttonStyle={buttonStyle}
                            actions={[
                                { name: 'PDF', onClick: () => { exportAsPdf(exportData) } }
                            ]}
                        />
                    )}
                {
                    <ReportSearch
                        buttonStyle={buttonStyle}
                    />
                }

            </Box>
        </CustomTitleContainer>
    )
}

export default ReportsSettingButtons;


const AccountMapping = (props) => {
    const { data, buttonStyle } = props;
    const [open, setOpen] = React.useState(false);

    return (
        data &&
        <React.Fragment>
            <ReportAccountMapping
                open={open}
                setOpen={setOpen}
                refreshPage={data.refreshPage}
                mapping_type={data.mapping_type}
            />
            <Button
                size='small'
                variant="outlined"
                aria-label="EditIcon"
                sx={{ ...buttonStyle, mr: 1 }}
                id={'edit_account_mapping_btn'}
                onClick={() => { setOpen(true) }}
                dataTestId={'edit_account_mapping_btn'}
                startIcon={<EditIcon fontSize="inherit" />}
            >
                Edit Accounts Mapping
            </Button>
        </React.Fragment>

    )
}


const CustomExportButton = (props) => {
    const { actions, buttonStyle } = props

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const action_style = {
        p: 1,
        color: '#141414',
        fontWeight: 500,
        display: 'block',
        fontSize: '14px',
        cursor: 'pointer',
        lineHeight: '12px',
        textDecoration: 'none',
        ":hover": { backgroundColor: "#f5f5f5", }
    }
    return (
        <Box>
            <Button
                size='small'
                variant="outlined"
                onClick={handleMenu}
                id={'handle_menu_btn'}
                sx={{ ...buttonStyle }}
                dataTestId={'handle_menu_btn'}
                endIcon={<ArrowDropDownIcon fontSize="inherit" />}>
                Export as
            </Button>
            <Popover
                sx={{ mt: '35px' }}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}

                PaperProps={{
                    style: {
                        minWidth: 140,
                        // minHeight: 60,

                    },
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                id={'popover_open'}
                dataTestId={'popover_open'}
            >
                {
                    actions.map((action, index) => {
                        return action ? <React.Fragment key={index}>{action.showDivider ? <><Divider /><MenuItem id={'action_item_' + index} dataTestId={'action_item_' + index} disabled={action.disabled} sx={{ ...action_style, mt: actions[0] ? -1 : 0, }} onClick={() => { action.onClick(); handleClose() }}>{action.name}</MenuItem></> : <MenuItem sx={{ ...action_style }} id={'action_item_' + index} disabled={action.disabled} onClick={() => { action.onClick(); handleClose() }}>{action.name}</MenuItem>}</React.Fragment> : null
                    })
                }
            </Popover>
        </Box>
    )
}

export const spanTag = (value, size = '12px') => <span style={{
    fontSize: size,
    color: '#141414',
    fontWeight: '500',
    lineHeight: '20px',
    fontStyle: 'normal',
    textTransform: 'none',
    fontFamily: "Noto Sans",
}}>{value}</span>