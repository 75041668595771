import React from 'react';
import OverviewSummary from './OverviewSummary';

const GSTR1 = () => {
  return (
    <div>
      <OverviewSummary />
    </div>
  )
}

export default GSTR1