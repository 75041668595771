import React from 'react'
import Input from '../../../../common/Input';
import { useNavigate } from 'react-router-dom';
import { Templates } from '../common/Templates';
import { apiAction } from '../../../../../api/api';
import * as Actions from '../../../../../state/Actions';
import { getBusinessInfo } from '../../../../../config/cookiesInfo';
import { LabelWithAsteriskMark } from '../../../../common/CommonLabel';
import CustomTypography from '../../../../custom/typography/CustomTypography';
import { BootstrapDialog } from '../../../bulkStatementUpload/mappings/Transactions';
import { eventsNames, AnalyticsEvent } from '../../../../../firebase/firebaseAnalytics';
import { BootstrapDialogTitle } from '../../../bulkStatementUpload/mappings/Transactions';

import {
    create_insight_tab,
    update_insight_tab,
    create_insight_section,
} from '../../../../../api/urls';

import {
    isFormValid,
    stateChangeManager,
} from '../../../../../utils/Utils';



import {
    Box,
    Grid,
    Button,
    Container,
    DialogContent,
    DialogActions,
} from '@mui/material';

const TabManager = (props) => {
    const { tab_id, open, setOpen, tabList, setTabList, selectedTab, setSelectedTab, action, setSelectedInsight } = props

    const getActionData = (action) => {
        let data = {}
        if (action === 'edit_tab') {
            data.key = 'name';
            data.title = 'Edit Tab';
            data.label = 'Tab Name';
            data.url = update_insight_tab();
            data.placeholder = 'Enter Tab Name';
            data.formData = { name: selectedTab.name, tab_id: tab_id };
            data.validation_data = [
                { key: 'name', message: 'Tab name can not be empty' },
            ];
        }
        if (action === 'add_new_tab') {
            data.key = 'name';
            data.label = 'Tab Name';
            data.title = 'Add New Tab';
            data.url = create_insight_tab();
            data.placeholder = 'Enter Tab Name';
            data.formData = { name: null };
            data.validation_data = [
                { key: 'name', message: 'Tab name can not be empty' },
            ];
        }
        if (action === 'add_new_section') {
            data.key = 'name';
            data.label = 'Section Name';
            data.title = 'Add New Section';
            data.url = create_insight_section();
            data.placeholder = 'Enter Section Name';
            data.formData = { name: null, tab_id: tab_id, section_type: 'list_view' };
            data.validation_data = [
                { key: 'name', message: 'Section name can not be empty' },
            ];
        }
        return data
    }

    const navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);
    const [isFormSubmitted, setFormSubmitted] = React.useState(false);
    const [formData, setFormData] = React.useState({ ...getActionData(action).formData });

    React.useEffect(() => {
        if (open) {
            setFormSubmitted(false);
            setFormData({ ...getActionData(action).formData })
        }
    }, [open])

    const handleSubmit = async (event) => {
        event.preventDefault();
        setFormSubmitted(true);


        const { isValid, message } = isFormValid(formData, getActionData(action).validation_data)
        if (isValid) {
            if (action === 'add_new_section') {
                AnalyticsEvent(eventsNames.categories.INSIGHT, { action: eventsNames.actions.ADD, chart_type: formData.section_type })
            }
            let res = await apiAction({
                method: 'post',
                navigate: navigate,
                dispatch: dispatch,
                url: getActionData(action).url,
                data: { ...formData, business_id: getBusinessInfo().id },

            });
            if (res?.success) {

                if (action === 'edit_tab') {
                    let index = tabList.findIndex((item) => item.id === res?.result.id);
                    tabList[index] = res?.result;

                    setTabList(tabList);
                    setSelectedTab(res?.result);
                }

                if (action === 'add_new_section') {
                    selectedTab.sections.push(res?.result);
                    setSelectedTab(selectedTab);
                }

                if (action === 'add_new_tab') {
                    tabList.push(res?.result);
                    setTabList(tabList);
                    setSelectedInsight();

                }

                setOpen(false);
                setFormSubmitted(false);
                setFormData({ ...getActionData(action).formData })
                stateChangeManager(dispatch, Actions, true, 'success', res?.status);
            } else {
                stateChangeManager(dispatch, Actions, true, 'error', res?.status);
            }
        } else {
            stateChangeManager(dispatch, Actions, true, 'error', message);
        }
    }

    return (
        <div>
            <BootstrapDialog
                fullWidth
                open={open}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={() => setOpen(false)}>
                    <CustomTypography
                        text={getActionData(action).title}
                        sx={{
                            color: '#000000',
                            fontSize: '16px',
                            fontWeight: '700',
                            lineHeight: '18px',
                            fontStyle: 'normal',
                            fontFamily: "Noto Sans",
                        }}
                    />
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <CustomForm
                        action={action}
                        inputKey={getActionData(action).key}
                        label={getActionData(action).label}
                        placeholder={getActionData(action).placeholder}

                        formData={formData}
                        handleSubmit={handleSubmit}
                        isFormSubmitted={isFormSubmitted}
                        setFormData={(value, inputKey) => { setFormData({ ...formData, [inputKey]: value !== '' ? value : null }) }}
                    />
                    <DialogActions>
                    </DialogActions>
                </DialogContent>
            </BootstrapDialog>
        </div>
    )
}

export default TabManager;

const CustomForm = (props) => {
    const { action, formData, setFormData, inputKey, label, placeholder, handleSubmit, isFormSubmitted } = props;

    return (
        <Container component="main" maxWidth="xs">
            <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{ mt: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ mt: 1, mb: 1 }}>
                        <Input
                            autoFocus={true}
                            id={'input_text_box'}
                            dataTestId={'input_text_box'}
                            item={{
                                type: 'text',
                                fullWidth: true,
                                placeholder: placeholder,
                                title: <LabelWithAsteriskMark label={label} />,
                                validation: isFormSubmitted && !formData[inputKey],
                                value: formData[inputKey] ? formData[inputKey] : '',
                                onChange: (e) => { setFormData(e.target.value, inputKey); },
                            }}
                        />
                    </Grid>
                    {action === 'add_new_section' &&
                        <Grid item xs={12} sx={{ mt: 1, mb: 1 }}>
                            <Templates
                                defaultSelected='list_view'
                                select={formData['section_type']}
                                setSelect={(value) => { setFormData(value, 'section_type'); }}
                            />
                        </Grid>
                    }
                </Grid>
                <Button
                    fullWidth
                    type="submit"
                    id={'save_btn'}
                    variant="contained"
                    dataTestId={'save_btn'}
                    sx={{ mt: 3, mb: 2, textTransform: 'capitalize' }}>Save
                </Button>
            </Box>
        </Container>
    )
}
