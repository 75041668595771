import React from 'react';
import { Box } from '@mui/material';
import { getBusinessInfo } from '../../../../config/cookiesInfo';
import CustomTypography from '../../../custom/typography/CustomTypography';
import { ReactComponent as CalendarIcon } from '../../../../assets/svg/calendar.svg';

const ReportsTitle = (props) => {

    const tax_name = props.state ? props.state.tax_name : null;
    const section_name = props.state ? props.state.section_name : '';

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
        }}>
            <CustomTypography
                text={getBusinessInfo().legal_name}
                sx={{
                    mb: 1,
                    color: '#A3A3A3',
                    fontSize: '12px',
                    fontWeight: '800',
                    lineHeight: '16px',
                    fontStyle: 'normal',
                    letterSpacing: '0.5px',
                    fontFamily: "Noto Sans",
                    textTransform: 'uppercase',
                }}
            />
            <CustomTypography
                text={props.title + " " + section_name}
                sx={{
                    mb: 1,
                    color: '#141414',
                    fontSize: '20px',
                    fontWeight: '700',
                    lineHeight: '27px',
                    fontStyle: 'normal',
                    textTransform: 'none',
                    fontFamily: "Noto Sans",
                }}
            />
            {tax_name ?
                <CustomTypography
                    text={<span>{tax_name}</span>}
                    sx={{
                        mb: 1,
                        color: '#A3A3A3',
                        fontSize: '12px',
                        fontWeight: '500',
                        lineHeight: '16px',
                        fontStyle: 'normal',
                        textTransform: 'none',
                        fontFamily: "Noto Sans",
                    }}
                />
                : null
            }
            <CustomTypography
                text={props.date ? <span>{props.dateText}<CalendarIcon />{props.date}</span> : ''}
                sx={{
                    mb: 1,
                    color: '#A3A3A3',
                    fontSize: '12px',
                    fontWeight: '500',
                    lineHeight: '16px',
                    fontStyle: 'normal',
                    textTransform: 'none',
                    fontFamily: "Noto Sans",
                }}
            />
        </Box>
    )
}

export default ReportsTitle