import React from 'react';
import { Box, Paper } from '@mui/material';
import ReactHtmlParser from 'react-html-parser';

const HTMLViewer = (props) => {
    const { html_string } = props
    const ref = React.useRef();
    const [height, setHeight] = React.useState("0px");
    const onLoad = () => {
        setHeight(ref.current.contentWindow.document.body.scrollHeight + "px");
    };
    return (
        <Box sx={{ p: 6, pt: 4 }}>
            <iframe
                ref={ref}
                width="100%"
                height={height}
                onLoad={onLoad}
                scrolling={"no"}
                frameBorder={'0'}
                srcDoc={html_string}
                style={{
                    width: "100%",
                    overflow: "auto",
                }}
            >
            </iframe>
            {/* {ReactHtmlParser(html_string)} */}
        </Box>
    )
}

export default HTMLViewer;