import React from 'react';
import Input from '../../../common/Input';
import { apiAction } from '../../../../api/api';
import DateRange from '../../../common/DateRange';
import * as Actions from '../../../../state/Actions';
import CustomTitle from '../../../common/CustomTitle';
import { routesName } from '../../../../config/routesName';
import CommonDropdown from '../../../common/CommonDropdown';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomButton from '../../../custom/button/CustomButton';
import { accountTypeChoice } from '../../../../utils/Constant';
import { getBusinessInfo } from '../../../../config/cookiesInfo';
import { LabelWithAsteriskMark } from '../../../common/CommonLabel';
import { AsyncParentAccountDropdown } from './AsyncParentAccountDropdown';
import CustomTypography from '../../../custom/typography/CustomTypography';
import { eventsNames,AnalyticsEvent } from '../../../../firebase/firebaseAnalytics';

import {
    CustomContainer,
    CustomTitleContainer,
    CustomButtonContainer,
} from '../../../custom/container/CustomContainer';

import {
    setLoader,
    isFormValid,
    getQueryParams,
    stateChangeManager,
} from '../../../../utils/Utils';

import {
    get_list_of_parent_account,
    retrieve_parent_account,
    update_custom_parent_account,
    create_custom_parent_account,
    get_next_parent_account_number,
    get_list_of_parent_account_new,
} from '../../../../api/urls';

import {
    Box,
    Grid,
    Radio,
    RadioGroup,
    FormControlLabel,
} from '@mui/material';


const Dropdown = DateRange;

const CreateParentAccount = (props) => {
    const { onClose, filter, onAddAccount } = props

    const navigate = useNavigate();
    let location = useLocation();
    const params = getQueryParams(location.search);
    const dispatch = Actions.getDispatch(React.useContext);

    const [type, setType] = React.useState('no');
    const [isFormSubmited, setFormSubmited] = React.useState(false)
    const [parentAccount, setParentAccount] = React.useState(null);

    const initial_parent_account_data = {
        name: null,
        is_primary: false,
        account_type: null,
        account_number: null,
        parent_account_id: null,
        business_id: getBusinessInfo().id,
    }

    const redirect = () => {
        if (onClose) {
            onClose()
        } else {
            navigate(-1)
        }
    }
    const [data, setData] = React.useState({ ...initial_parent_account_data })

    const onSave = async () => {
        console.log('OnSave ====>', data)
        setFormSubmited(true);
        let validation_data = [
            { key: "name", message: 'Please Enter the Name' },
            { key: "", validation: data.is_primary && data.account_type === null, message: 'Please Select the Account Type ' },
            { key: "", validation: !data.is_primary && data.parent_account_id === null, message: 'Please Select the Parent Account ' },
            { key: "account_number", message: 'Please Enter the Account Code' },
        ]
        const { isValid, message } = isFormValid(data, validation_data)
        if (isValid) {
            AnalyticsEvent(eventsNames.categories.CHART_OF_ACCOUNTS,{action:eventsNames.actions.CREATE,type:eventsNames.actions.chartOfAccount.type.PARENT_ACCOUNT})
            let res = await apiAction({
                method: 'post',
                navigate: navigate,
                dispatch: dispatch,
                data: { ...data },
                url: params.id ? update_custom_parent_account(params.id) : create_custom_parent_account(),
            })
            if (res?.success) {
                redirect();
                if (onAddAccount) {
                    onAddAccount(res?.result);
                }
                stateChangeManager(dispatch, Actions, true, 'success', res?.status);
            }
            else {
                stateChangeManager(dispatch, Actions, true, 'error', res?.status);
            }
        } else {
            stateChangeManager(dispatch, Actions, true, 'error', message);

        }
    }


    React.useEffect(() => {
        const apiResults = async () => {
            setLoader(dispatch, Actions, true);
            let res = await apiAction({
                method: 'post',
                navigate: navigate,
                dispatch: dispatch,
                url: retrieve_parent_account(params.id),
                data: { business_id: getBusinessInfo().id },
            })

            if (res?.success) {
                let item = res?.result;
                let is_primary_account = item.paren_account__id ? false : true;
                setData({
                    ...data,
                    name: item.name,
                    is_primary: is_primary_account,
                    account_type: item.account_type,
                    account_number: item.account_number,
                    parent_account_id: item.paren_account__id,
                })
                setLoader(dispatch, Actions, false);
                setType(is_primary_account ? 'yes' : 'no');
                setParentAccount({ id: item.paren_account__id, name: item.parent_account__name })
            } else {

            }
        }
        if (params.id) {
            apiResults(params.id)
        }
        // eslint-disable-next-line
    }, [])


    const getNextParentAccountNumber = async (account_type) => {
        let res = await apiAction({
            method: 'post',
            navigate: navigate,
            dispatch: dispatch,
            url: get_next_parent_account_number(),
            data: { business_id: getBusinessInfo().id, account_type: account_type },
        })

        if (res?.success) {
            let account_number = res?.result;
            setData({
                ...data,
                account_number: account_number.toString(),
            })
            setLoader(dispatch, Actions, false);
        } else {
            setLoader(dispatch, Actions, false);
        }
    }


    let additionParentUrlData = filter ? `account_type=${filter}` : '';
    let filterPresentStyle = filter ? { maxHeight: 'calc(100vh - 220px)', overflow: 'hidden' } : {};
    return (
        <div>
            {!filter && (
                <CustomTitleContainer>
                    <CustomTitle title={params.id ? 'Update Parent Account' : 'New Parent Account'} />
                </CustomTitleContainer>
            )}
            <CustomContainer maxWidth={"400"} sx={{ ...filterPresentStyle }}>
                <div style={{ paddingLeft: '20px', paddingRight: '20px', paddingBottom: '48px' }}>

                    <Box noValidate sx={{ mt: 1, }}>
                        <Input
                            id={'input_parent_account_name'}
                            dataTestId={'input_parent_account_name'}
                            autoFocus={true}
                            item={{
                                type: 'text',
                                required: true,
                                fullWidth: true,
                                sx: { mb: 3, width: 400 },
                                placeholder: 'Enter Name',
                                value: data.name ? data.name : '',
                                validation: isFormSubmited && !data.name,
                                title: <LabelWithAsteriskMark label={'Name'} />,
                                onChange: (e) => { setData({ ...data, name: e.target.value === '' ? null : e.target.value }) }
                            }}
                        />

                        <Box >
                            <Grid container spacing={0}
                                style={{
                                    alignItems: 'center',
                                    justifyItems: 'center',
                                    alignContent: 'space-evenly',
                                }}
                            >
                                <Grid item sx={{ pr: 2, textAlign: 'left' }}>
                                    <CustomTypography
                                        text={'Is it a primary account ?'}
                                        sx={{
                                            color: '#000000',
                                            display: 'inline',
                                            fontSize: '14px',
                                            textAlign: 'start',
                                            fontWeight: '700',
                                            lineHeight: '18px',
                                            fontStyle: 'normal',
                                            fontFamily: "Noto Sans",
                                        }}
                                    />
                                </Grid>
                                <Grid item sx={{ pl: 2, textAlign: 'left' }}>
                                    <RadioGroup row sx={{ marginBottom: 0 }}>
                                        <FormControlLabel value={'yes'}
                                            id={'yes_parent_account_radio'}
                                            dataTestId={'yes_parent_account_radio'}
                                            label={<span style={{
                                                color: '#000000',
                                                fontSize: '14px',
                                                lineHeight: '18px',
                                                display: 'inline',
                                                fontStyle: 'normal',
                                                fontFamily: "Noto Sans",
                                                fontWeight: type === 'yes' ? '600' : '400',
                                            }}>YES</span>} control={<Radio sx={{ color: '#8F8F8F' }} checked={type === 'yes'} onChange={(e) => { setType(e.target.value); setData({ ...data, is_primary: e.target.value === 'yes', account_type: null, parent_account_id: null }); setParentAccount(null); }} />} />
                                        <FormControlLabel value={'no'}
                                            id={'no_parent_account_radio'}
                                            dataTestId={'no_parent_account_radio'}
                                            label={<span style={{
                                                color: '#000000',
                                                fontSize: '14px',
                                                display: 'inline',
                                                lineHeight: '18px',
                                                fontStyle: 'normal',
                                                fontFamily: "Noto Sans",
                                                fontWeight: type === 'no' ? '600' : '400',
                                            }}>NO</span>} control={<Radio sx={{ color: '#8F8F8F' }} checked={type === 'no'} onChange={(e) => { setType(e.target.value); setData({ ...data, is_primary: e.target.value === 'yes', account_type: null, parent_account_id: null }); setParentAccount(null); }} />} />
                                    </RadioGroup>
                                </Grid>

                            </Grid>

                            <Box sx={{ mt: 3 }}>

                                {data.is_primary ?
                                    <Dropdown
                                        id={'account_type_dropdown'}
                                        dataTestId={'account_type_dropdown'}
                                        sx={{ width: 400 }}
                                        disableClearable={false}
                                        results={accountTypeChoice}
                                        placeholder='Select the Account Type'
                                        validation={isFormSubmited && !data.account_type}
                                        label={<LabelWithAsteriskMark label={'Account Type'} />}
                                        value={data.account_type ? accountTypeChoice.find((item) => item.value === data.account_type) : null}
                                        setValue={(event, value) => {
                                            if (value) {
                                                data.account_number = null;
                                                data.account_type = value.value;
                                                getNextParentAccountNumber(value.value);
                                            } else {
                                                data.account_type = null;
                                                data.account_number = null;
                                            }
                                            setData({ ...data })
                                        }}
                                    />
                                    :
                                    <AsyncParentAccountDropdown
                                        sx={{ maxWidth: 400 }}
                                        selectedValue={parentAccount}
                                        id={'parent_account_type_dropdown'}
                                        placeholder='Select the Parent Account'
                                        dataTestId={'parent_account_type_dropdown'}
                                        validation={isFormSubmited && !data.parent_account_id}
                                        title={<LabelWithAsteriskMark label={'Parent Account'} />}
                                        URL={`${get_list_of_parent_account_new(1)}${additionParentUrlData}`}
                                        nextUrlAdditionData={`&business_id=${getBusinessInfo()?.id}${additionParentUrlData}`}

                                        setSelectedValue={(value) => {
                                            if (value) {
                                                setParentAccount(value);
                                                data.account_number = null;
                                                data.parent_account_id = value.id;
                                                getNextParentAccountNumber(value.account_type);
                                            } else {
                                                setParentAccount(null);
                                                data.account_number = null;
                                                data.parent_account_id = null;
                                            }
                                            setData({ ...data })
                                        }}
                                    />

                                    // <CommonDropdown
                                    //     id={'parent_account_type_dropdown'}
                                    //     dataTestId={'parent_account_type_dropdown'}
                                    //     sx={{ width: 400 }}
                                    //     placeholder='Select the Parent Account'
                                    //     validation={isFormSubmited && !data.parent_account_id}
                                    //     item={{
                                    //         filter: filter,
                                    //         value: parentAccount,
                                    //         url: get_list_of_parent_account(getBusinessInfo().id),
                                    //         label: <LabelWithAsteriskMark label={'Parent Account'} />,
                                    //         onChange: (event, value) => {
                                    //             if (value) {
                                    //                 setParentAccount(value);
                                    //                 data.account_number = null;
                                    //                 data.parent_account_id = value.id;
                                    //                 getNextParentAccountNumber(value.account_type);
                                    //             } else {
                                    //                 setParentAccount(null);
                                    //                 data.account_number = null;
                                    //                 data.parent_account_id = null;
                                    //             }
                                    //             setData({ ...data })
                                    //         },
                                    //     }}
                                    // />
                                }
                            </Box>
                        </Box>
                        <Box sx={{ mt: 3 }}>
                            <Input
                                id={'input_account_number'}
                                dataTestId={'input_account_number'}
                                item={{
                                    type: 'text',
                                    required: true,
                                    fullWidth: true,
                                    sx: { mb: 3, width: 400 },
                                    inputProps: { maxLength: 5 },
                                    placeholder: 'Enter Account Code',
                                    validation: isFormSubmited && !data.account_number,
                                    title: <LabelWithAsteriskMark label={'Account Code'} />,
                                    value: data.account_number ? data.account_number.toUpperCase() : '',
                                    onChange: (e) => { setData({ ...data, account_number: e.target.value === '' ? null : e.target.value.replace(/[`~$&+,:;=?_[\]@#|{}'<>.^*()%!-/]/, "").toUpperCase() }) }
                                }}
                            />
                        </Box>
                    </Box>

                </div>
            </CustomContainer>
            <CustomButtonContainer>
                <Buttons onSave={onSave} onCancel={() => redirect()} />
            </CustomButtonContainer>

        </div>
    )
}

export default CreateParentAccount;

const Buttons = (props) => {
    const { onSave, onCancel } = props
    let location = useLocation();
    const params = getQueryParams(location.search)
    return (
        <Grid container>
            <Grid item xs={12} sx={{ display: '-webkit-flex', justifyContent: 'end', }}>
                <CustomButton id={'parent_account_cancel_btn'} dataTestId={'parent_account_cancel_btn'} variant='outlined' sx={{ mr: 1, textTransform: 'none', '&:hover': { backgroundColor: '#e8f3ff' } }} btnLabel='Cancel' onClick={onCancel} />
                <CustomButton id={'parent_account_save_btn'} dataTestId={'parent_account_save_btn'} variant='contained' sx={{ textTransform: 'none', }} btnLabel={params.item_id ? 'Update' : 'Save'} onClick={onSave} />
            </Grid>
        </Grid>
    )
}