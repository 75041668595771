import React from 'react';
import InviteNewUser from './InviteNewUser';
import { apiAction } from '../../../../../api/api';
import * as Actions from '../../../../../state/Actions';
import CustomTitle from '../../../../common/CustomTitle';
import CustomButton from '../../../../custom/button/CustomButton';
import CustomTypography from '../../../../custom/typography/CustomTypography';
import { getBusinessInfo, getUserDetails } from '../../../../../config/cookiesInfo';

import {
    CustomContainer,
    CustomTitleContainer,
} from '../../../../custom/container/CustomContainer';

import {
    setLoader,
} from "../../../../../utils/Utils";

import {
    list_users_in_business,
} from '../../../../../api/urls';

import {
    Box,
    Grid,
    Avatar,
} from '@mui/material'


const UserList = (props) => {
    const {initial_state,onManageProfile}=props;
    React.useEffect(()=>{setAction(false)},[initial_state]);
    
    const { legal_name } = getBusinessInfo();
    const dispatch = Actions.getDispatch(React.useContext);

    // console.log('====>getUserDetails',getUserDetails())
    // console.log('====>getBusinessInfo',getBusinessInfo())


    const [userId, setUserId] = React.useState();
    const [action, setAction] = React.useState(false);
    const [result, setResult] = React.useState([]);


    React.useEffect(() => {
        const getBusiness = async () => {
            setLoader(dispatch, Actions, true);
            let res = await apiAction({
                method: "get",
                url: list_users_in_business(),
            });
            if (res?.success) {
                const targetId = getUserDetails().id ? getUserDetails().id : getUserDetails().pk;
                let data = res?.result
                const itemToMove = data.find(item => item.id === targetId);
                const filteredData = data.filter(item => item.id !== targetId);

                const newData = [itemToMove, ...filteredData];
                setResult(newData);
                setLoader(dispatch, Actions, false);
            } else {
                setLoader(dispatch, Actions, false);
            }
        };
        getBusiness();
        // eslint-disable-next-line
    }, []);

    return (
        action ?
            <InviteNewUser id={userId} onCancel={() => setAction(false)} />
            :
            <div>
                <CustomTitleContainer>
                    <Grid container spacing={0} style={{
                        alignItems: "center",
                        justifyItems: "center",
                        alignContent: "space-evenly",
                        justifyContent: "space-evenly",
                    }}>
                        <Grid item xs={6}>
                            <CustomTitle title={legal_name + ' Users'} />
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: "end", alignSelf: "center" }}>
                            <CustomButton
                                sx={{}}
                                variant="contained"
                                btnLabel="Invite User"
                                id={"invite_user_btn"}
                                dataTestId={"invite_user_btn"}
                                onClick={() => { setAction(true) }}
                            />
                        </Grid>
                    </Grid>
                </CustomTitleContainer>

                <CustomContainer maxWidth={"400"} sx={{ height: 'calc(100vh - 134px)', }}>
                    <CustomTypography
                        text={<span>You can invite a new user to access your <strong>Finycs Account</strong>.Only give access to people you trust, since users can see your transactions and other business information.</span>}
                        sx={{
                            p: 2,
                            pt: 1,
                            color: "#7A7A7A",
                            fontSize: "12px",
                            fontWeight: "500",
                            lineHeight: "18px",
                            fontStyle: "normal",
                            fontFamily: "Noto Sans",
                        }}
                    />
                    <Box sx={{ mt: 3, borderTop: '2px solid #F5F5F5' }}>
                        {result.map((item, index) => {
                            return (
                                <Box key={index}>
                                    <Users item={item} onManageProfile={onManageProfile} />
                                </Box>
                            );
                        })}
                    </Box>
                </CustomContainer>
            </div>
    )
}

export default UserList;

const Users = (props) => {
    const { item, onManageProfile } = props;
    let login_user_id = getUserDetails().id ? getUserDetails().id : getUserDetails().pk
    return (
        <Box
            className={login_user_id === item.id ? "border-left-success" : ""}
            sx={{
                // cursor: "pointer",
                borderBottom: "2px solid #F5F5F5",
                ":hover": { backgroundColor: "#f5f5f5" },
            }}
        >
            <Grid container>
                <Grid item xs={8}>
                    <Box sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'row',
                    }}>
                        <Avatar />
                        <Box sx={{ alignSelf: 'center', ml: 2.5 }}>

                            <CustomTypography
                                text={
                                    <span>
                                        <span>{item.fullname}</span>
                                        {/* {item.role !== "admin" &&
                                            <span
                                                style={{
                                                    marginLeft: 10,
                                                    border: '1px',
                                                    width: '50px',
                                                    paddingTop: 5,
                                                    paddingLeft: 5,
                                                    paddingRight: 10,
                                                    color: '#0232b7a6',
                                                    paddingBottom: 5,
                                                    textAlign: 'center',
                                                    borderRadius: '5px',
                                                    background: '#d7e0f963',
                                                    display: 'inline-block',
                                                }}>{item.role}</span>
                                        } */}
                                    </span>
                                }

                                sx={{
                                    mb: 1,
                                    color: '#000000',
                                    fontSize: '14px',
                                    fontWeight: '700',
                                    lineHeight: '18px',
                                    fontStyle: 'normal',
                                    fontFamily: "Noto Sans",
                                }}
                            />
                            <CustomTypography
                                text={item.email}
                                sx={{
                                    mb: 0.5,
                                    color: '#7A7A7A',
                                    fontSize: '12px',
                                    fontWeight: '500',
                                    lineHeight: '18px',
                                    fontStyle: 'normal',
                                    fontFamily: "Noto Sans",
                                }}
                            />
                        </Box>
                    </Box>
                </Grid>
                {/* <Grid item xs={4} sx={{ textAlign: "end", alignSelf: "center" }}>
                    {
                        item.role === "admin" ?
                            <CustomButton
                                onClick={() => { onManageProfile() }}
                                variant="contained"
                                id={"manage_profile_btn"}
                                btnLabel="Manage Your Profile"
                                dataTestId={"invite_user_btn"}
                                sx={{ textTransform: "none", borderRadius: "4px", m: 1 }}
                            />
                            :
                            <CustomButton
                                onClick={() => { }}
                                variant="contained"
                                btnLabel="Edit"
                                id={"edit_user_btn"}
                                dataTestId={"invite_user_btn"}
                                sx={{ textTransform: "none", borderRadius: "4px", m: 1 }}
                            />
                    }
                </Grid> */}
            </Grid>
        </Box>
    );
}