export const mentionsInputWrapperStyle = (isFormulaValid) => {
  return {
    control: {
      backgroundColor: '#ffffff',
      
    },
    input: {

    },
    "&multiLine": {
      control: {
        fontSize: '9.5pt',
        fontWeight: '700',
        fontStyle: 'normal',
        fontFamily: 'Noto Sans',
      },
      highlighter: {
        padding: '10px',
        minHeight: `120px`,
        fontStyle: 'normal',
        border: '1px inset transparent',
      },
      input: {
        outLine: 0,
        padding: '10px',
        fontWeight: '700',
        borderRadius: `4px`,
        border: `1px solid ${isFormulaValid ? '#d32f2f' : '#c4c4c4'}`,
      },
    },
    suggestions: {
      list: {
        opacity: 10,
        zIndex: 9999,
        fontSize: `12px`,
        color: `#000000`,
        fontWeight: `700`,
        overflowY: 'auto',
        maxHeight: '250px',
        borderRadius: '4px',
        boxSizing: 'border-box',
        border: '1px solid var(--border-color)',
        boxShadow: '0 8px 24px rgba(149, 157, 165, 0.2)',
      },
      item: {
        padding: "4px 20px",
        borderBottom: "1px solid #F5F5F5",
        "&focused": {
          backgroundColor: "rgba(0, 0, 0, 0.04)",
        },
      },
    },
  }
};

export const mentionStyle = (isFormulaValid) => {
  return {
    zIndex: 1,
    color: '#2464EB',
    position: 'relative',
    pointerEvents: 'none',
    textDecoration: 'underline',
    textUnderlineOffset: '1.5px',
    textShadow: '1px 1px 1px #FFFFFF, 1px -1px 1px #FFFFFF, -1px 1px 1px #FFFFFF,-1px -1px 1px #FFFFFF'
  };
};