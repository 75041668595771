import React from 'react';
import moment from 'moment';
import Status from '../../../common/Status';
import { useNavigate } from 'react-router-dom';
import { apiAction } from "../../../../api/api";
import ReportsTitle from '../common/ReportsTitle';
import DatePicker from '../../../common/DatePicker';
import * as Actions from '../../../../state/Actions';
import { sourceTypeList } from '../../../../utils/Constant';
import ReportsSettingButtons from '../ReportsSettingButtons';
import { getBusinessInfo } from '../../../../config/cookiesInfo';
import CurrencyFormatter from '../../../common/CurrencyFormatter';
import { NoDataComponent } from '../../../common/NoDataComponent';
import { VendorViewLink } from '../../invoicing/common/CommonLinks';
import CustomTypography from '../../../custom/typography/CustomTypography';
import { CustomContainer } from '../../../custom/container/CustomContainer';

import {
    get_source_of_journal_entry,
    account_payable_detailed_report,
    account_payable_detail_report_file,
} from '../../../../api/urls';

import {
    setLoader,
    getDateFormat,
    sumOfArrayByKey
} from '../../../../utils/Utils';

import {
    Box,
    Grid,
} from '@mui/material';

import {
    CustomTable,
    CustomTableRow,
    CustomTableBody,
    CustomTableHead,
    CustomTableHeadCell,
    CustomTableBodyCell,
    CustomTableContainer,
} from '../../../custom/table/CustomTable';

const ApDetailedReports = () => {
    const dispatch = Actions.getDispatch(React.useContext);

    const [results, setResults] = React.useState();
    const [date, setDate] = React.useState(moment());

    React.useEffect(() => {
        if (date) {
            getApiResults(date)
        }
        // eslint-disable-next-line
    }, [date]);

    const getApiResults = async (date) => {
        setLoader(dispatch, Actions, true)
        let res = await apiAction({
            method: 'post',
            url: account_payable_detailed_report(),
            data: {
                business_id: getBusinessInfo().id,
                as_on_date: moment(date).format('YYYY-MM-DD'),
            },
        })

        if (res?.success) {
            setResults(res?.result);
            setLoader(dispatch, Actions, false);
        } else {
            setLoader(dispatch, Actions, false);
        }
    }

    let condition = results && (!results.above_45.length & !results.days_31_to_45.length & !results.days_16_to_30.length & !results.days_1_to_15.length & !results.current.length)
    return (
        results &&
        <div>
            <ReportsSettingButtons
                scheduleData={{
                    report_name: 'account_payable_aging_detail',
                    report_title: 'AP Aging Details By Bill Due Date',
                }}
                exportData={{ url: account_payable_detail_report_file(), data: { to_date: moment(date).format('YYYY-MM-DD') } }}
            />
            <CustomContainer maxWidth={"400"} sx={{ height: 'calc(100vh - 134px)' }}>
                <div style={{ paddingLeft: '16px', paddingRight: '16px' }}>
                    <Grid container spacing={0} sx={{ pb: 2 }} style={{
                        alignItems: 'center',
                        justifyItems: 'center',
                        alignContent: 'space-evenly',
                        justifyContent: 'space-evenly',
                    }}>
                        <Grid item xs={12} sm={4}>
                            <ReportsTitle
                                dateText={'As on '}
                                date={moment(date).format(getDateFormat())}
                                title={'AP Aging Details By Bill Due Date'}
                            />
                        </Grid>
                        <Grid container spacing={2} item xs={12} sm={8} sx={{ justifyContent: 'end', }}>
                            <Grid item xs={12} sm={4}>
                                <DatePicker
                                    id={'as_on_date_select'}
                                    dataTestId={'as_on_date_select'}

                                    date={date}
                                    setDate={setDate}
                                    title='As on Date'
                                    disabledCloseIcon={true}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <CustomTableContainer>
                    <CustomTable>
                        <Header />
                        <Body data={results} />
                    </CustomTable>
                    <Box sx={{ p: condition ? 2 : 0 }} >
                        <NoDataComponent left='0%' top='0%' position={'relative'} data={condition ? [] : results} />
                    </Box>
                </CustomTableContainer>
            </CustomContainer>
        </div>
    )
}

export default ApDetailedReports;

const Header = () => {
    return (
        <CustomTableHead>
            <CustomTableRow >
                <CustomTableHeadCell><span>Date</span></CustomTableHeadCell>
                <CustomTableHeadCell><span>Status</span></CustomTableHeadCell>
                <CustomTableHeadCell><span>Transaction</span></CustomTableHeadCell>
                <CustomTableHeadCell><span>Vendor Name</span></CustomTableHeadCell>
                <CustomTableHeadCell><span>Age</span></CustomTableHeadCell>
                <CustomTableHeadCell align='right'><span>Amount</span></CustomTableHeadCell>
                <CustomTableHeadCell align='right'><span>Balance Due</span></CustomTableHeadCell>
            </CustomTableRow>
        </CustomTableHead>
    )
}

const Body = (props) => {
    const { data } = props;
    let navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);

    const data_keys = [
        { name: '> 45 Days', value: 'above_45' },
        { name: '31-45 Days', value: 'days_31_to_45' },
        { name: '16-30 Days', value: 'days_16_to_30' },
        { name: '1-15 Days', value: 'days_1_to_15' },
        { name: 'Current', value: 'current' },
    ]

    const onClickDescriptionHandler = async (journal_entry_id, source_type) => {
        let res = await apiAction({
            method: 'get',
            navigate: navigate,
            dispatch: dispatch,
            url: get_source_of_journal_entry(journal_entry_id, source_type),
        })
        if (res?.success) {
            const { source_id, source_type } = res?.result;

            sourceTypeList.map((item) => {
                if (item.source_type === source_type) {
                    navigate(item.source_path + source_id)
                }
            })
        } else {
            if (source_type === 'opening_balance') {
                sourceTypeList.map((item) => {
                    if (item.source_type === source_type) {
                        navigate(item.source_path, { state: { ...item.state } })
                    }
                })
            }
        }
    }
    return (
        <CustomTableBody>
            {data_keys.map((item, index) => {
                return (
                    data[item.value].length ?
                        <React.Fragment key={index}>
                            <CustomTableRow>
                                <CustomTableBodyCell colSpan={12}>
                                    <CustomTypography
                                        text={item.name}
                                        sx={{
                                            color: '#939393',
                                            fontSize: '16px',
                                            fontWeight: '700',
                                            lineHeight: '27px',
                                            fontStyle: 'normal',
                                            textTransform: 'none',
                                            fontFamily: "Noto Sans",
                                        }}
                                    />
                                </CustomTableBodyCell>

                            </CustomTableRow>
                            <TransactionsBody data={data[item.value]} onClickDescriptionHandler={onClickDescriptionHandler} />
                            <TransactionsTotal total_amount={sumOfArrayByKey(data[item.value], 'total_amount')} total_Balance={sumOfArrayByKey(data[item.value], 'balance_due')} />
                        </React.Fragment>
                        : null
                )
            })}

        </CustomTableBody>
    )
}

const TransactionsTotal = (props) => {
    const { total_amount, total_Balance, currency_code } = props;

    return (
        <CustomTableRow sx={{
            pb: 1,
            pt: 2,
            background: '#FFF7DC',
            borderTop: '1px solid #FFF0C0',
            borderBottom: '1px solid #FFF0C0'
        }}>
            <CustomTableBodyCell colSpan={5}><span>Total</span></CustomTableBodyCell>
            <CustomTableBodyCell align='right'><CurrencyFormatter amount={total_amount} currency={null} /></CustomTableBodyCell>
            <CustomTableBodyCell align='right'><CurrencyFormatter amount={total_Balance} currency={null} /></CustomTableBodyCell>
        </CustomTableRow>
    )
}

const TransactionsBody = (props) => {
    const { data, onClickDescriptionHandler } = props;

    return (
        data.map((item, index) => {
            return (
                <CustomTableRow key={index}>
                    <CustomTableBodyCell><span>{moment(item.due_date).format(getDateFormat())}</span></CustomTableBodyCell>
                    <CustomTableBodyCell><span>{item.status ? <Status status={item.status} /> : ''}</span></CustomTableBodyCell>
                    <CustomTableBodyCell>
                        <CustomTypography
                            text={item.description}
                            sx={{
                                color: '#2464EB',
                                fontSize: '12px',
                                cursor: 'pointer',
                                fontWeight: '500',
                                lineHeight: '0px',
                                display: 'inline',
                                fontStyle: 'normal',
                                textAlign: 'center',
                                fontFamily: "Noto Sans",
                                ":hover": { textDecoration: 'underline' }
                            }}
                            onClick={() => { onClickDescriptionHandler(item.journal_entry_id, item.source) }}
                        />
                    </CustomTableBodyCell>
                    <CustomTableBodyCell>
                        {
                            item.vendor_id ? <VendorViewLink title={item.vendor_name} id={item.vendor_id} /> :
                                item.vendor_name
                        }
                    </CustomTableBodyCell>
                    <CustomTableBodyCell><span>{item.age ? item.age.toString().replace('-', '') + ' Days' : ''}</span></CustomTableBodyCell>
                    <CustomTableBodyCell align='right'><CurrencyFormatter amount={item.total_amount} currency={null} /></CustomTableBodyCell>
                    <CustomTableBodyCell align='right'><CurrencyFormatter amount={item.balance_due} currency={null} /></CustomTableBodyCell>
                </CustomTableRow>
            )
        })
    )
}
