import React from 'react'
import moment from 'moment'
import { useNavigate } from 'react-router-dom';
import { apiAction } from '../../../../../api/api';
import DateRange from '../../../../common/DateRange';
import * as Actions from '../../../../../state/Actions';
import CustomTitle from '../../../../common/CustomTitle';
import CommonSearch from '../../../../common/CommonSearch';
import CustomTabs from '../../../../custom/tabs/CustomTabs';
import AsyncDropdown from '../../../../common/AsyncDropdown';
import { routesName } from '../../../../../config/routesName';
import { sourceTypeList } from '../../../../../utils/Constant';
import { CustomLink } from '../../../../custom/link/CustomLink';
import ListItemActions from '../../../../common/ListItemActions';
import CustomDialog from '../../../../custom/dialog/CustomDialog';
import CustomButton from '../../../../custom/button/CustomButton';
import { getBusinessInfo } from '../../../../../config/cookiesInfo';
import { NoDataComponent } from '../../../../common/NoDataComponent';
import CurrencyFormatter from '../../../../common/CurrencyFormatter';
import { HeadingWithSortable } from '../../../../common/SortableHeading';
import { ActionTextLinkBtn } from '../../../invoicing/common/CommonLinks';
import CustomPagination from '../../../../custom/pagination/CustomPagination';
import CustomTypography from '../../../../custom/typography/CustomTypography';
import CustomDateRangePicker from '../../../../custom/Datepicker/CustomDateRangePicker';
import { eventsNames, AnalyticsEvent } from '../../../../../firebase/firebaseAnalytics';

import {
    setLoader,
    getDateFormat,
    isFiltersApplied,
    setFilterMessage,
    stateChangeManager,
    convertSlugToString,
} from '../../../../../utils/Utils';

import {
    update_journal_entry,
    get_source_of_journal_entry,
    get_list_of_journal_entries,
    get_chart_of_account_nested_nodes,
} from '../../../../../api/urls';

import {
    CustomContainer,
    CustomTitleContainer,
} from '../../../../custom/container/CustomContainer';

import {
    Box,
    Grid,
} from '@mui/material'

import {
    CustomTable,
    CustomTableRow,
    CustomTableBody,
    CustomTableHead,
    CustomTableHeadCell,
    CustomTableBodyCell,
    CustomTableContainer,
} from '../../../../custom/table/CustomTable';


const tabs = [
    { name: 'All', is_posted: null, AnalyticsEvent: () => { AnalyticsEvent(eventsNames.categories.JOURNAL_ENTRY, { action: eventsNames.actions.CLICK, filter_type: eventsNames.actions.journalEntry.filter_type.ALL }) } },
    { name: 'Posted', is_posted: true, AnalyticsEvent: () => { AnalyticsEvent(eventsNames.categories.JOURNAL_ENTRY, { action: eventsNames.actions.CLICK, filter_type: eventsNames.actions.journalEntry.filter_type.POSTED }) } },
    { name: 'Draft', is_posted: false, AnalyticsEvent: () => { AnalyticsEvent(eventsNames.categories.JOURNAL_ENTRY, { action: eventsNames.actions.CLICK, filter_type: eventsNames.actions.journalEntry.filter_type.DRAFT }) } }
]
const Dropdown = DateRange;
const GetListJournalEntry = () => {

    let navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);

    const [results, setResults] = React.useState([])

    const [pagination, setPagination] = React.useState({
        next: undefined,
        count: undefined,
        previous: undefined,
        number_of_pages: undefined,
    })

    const [state, setState] = React.useState({
        id: '',
        title: '',
        open: false,
        condition: '',
        maxWidth: 'lg',
        fullWidth: true,
    })


    const [page, setPage] = React.useState(1);
    const [value, setValue] = React.useState(0);

    const [filters, setFilters] = React.useState({
        sort_by: 'transaction_date',
        sort_order: 'D'
    })

    React.useEffect(() => {
        if (!state.open) {
            getApiResults();
        }
        // eslint-disable-next-line
    }, [value, filters, page, state.open])

    React.useEffect(() => {
        if (tabs[value].AnalyticsEvent) {
            tabs[value].AnalyticsEvent()
        }
    }, [value])


    ///////////////////////////////////////////////Api call///////////////////////////////////////////////////////////////////////////////////////////////

    const getApiResults = async () => {
        setLoader(dispatch, Actions, true);
        setFilterMessage(dispatch, Actions, null);
        let res = await apiAction({
            method: 'post',
            navigate: navigate,
            dispatch: dispatch,
            url: get_list_of_journal_entries(page),
            data: { is_posted: tabs[value].is_posted, business_id: getBusinessInfo().id, ...filters, },
        })
        if (res?.success) {
            setPagination({
                ...pagination,
                next: res?.result.next,
                count: res?.result.count,
                previous: res?.result.previous,
                number_of_pages: res?.result.number_of_pages,
            });
            setResults(res?.result.result);
            setLoader(dispatch, Actions, false);
            if (isFiltersApplied(filters)) { setFilterMessage(dispatch, Actions, `No journal entries found for your current filters. Verify your filters and try again.`) }
        } else {
            setLoader(dispatch, Actions, false);
            stateChangeManager(dispatch, Actions, true, 'error', res?.status);
        }
    }
    const onClickDescriptionHandler = async (journal_entry_id, source_type) => {
        AnalyticsEvent(eventsNames.categories.JOURNAL_ENTRY, { action: eventsNames.actions.CLICK, type: eventsNames.actions.journalEntry.type.DESCRIPTION })
        let res = await apiAction({
            method: 'get',
            navigate: navigate,
            dispatch: dispatch,
            url: get_source_of_journal_entry(journal_entry_id, source_type),
        })
        if (res?.success) {
            const { source_id, source_type } = res?.result;

            sourceTypeList.map((item) => {
                if (item.source_type === source_type) {
                    navigate(item.source_path + source_id)
                }
            })
        } else {
            if (source_type === 'opening_balance') {
                sourceTypeList.map((item) => {
                    if (item.source_type === source_type) {
                        navigate(item.source_path, { state: { ...item.state } })
                    }
                })
            }
        }
    }
    ///////////////////////////////////////////////edit and delete///////////////////////////////////////////////////////////////////////////////////////////////

    const viewHandler = (id) => {
        AnalyticsEvent(eventsNames.categories.JOURNAL_ENTRY, { action: eventsNames.actions.VIEW })

        setState({
            id: id,
            open: true,
            maxWidth: 'sm',
            fullWidth: true,
            condition: 'view',
            title: 'View Journal Entry',
        })
    }

    const editHandler = (id) => {
        setState({
            id: id,
            open: true,
            maxWidth: 'lg',
            fullWidth: true,
            condition: 'update',
            title: 'Update Journal Entry',
        })
    }
    const deleteHandler = (id) => {
        AnalyticsEvent(eventsNames.categories.JOURNAL_ENTRY, { action: eventsNames.actions.DELETE })
        setState({
            id: id,
            open: true,
            maxWidth: 'sm',
            fullWidth: false,
            condition: 'delete',
            title: 'Delete Journal Entry',
            onDeleteAction: () => { getApiResults(); },
            deleteMessage: `You will loose the data on deleting this journal entry.\n Are you sure you want to proceed?`,
        })
    }

    const approveHandler = async (id) => {
        AnalyticsEvent(eventsNames.categories.JOURNAL_ENTRY, { action: eventsNames.actions.APPROVE })
        setLoader(dispatch, Actions, true);
        const approveHandlerRes = await apiAction({ url: update_journal_entry(id), method: 'POST', navigate: navigate, dispatch: dispatch, data: { is_posted: true, business_id: getBusinessInfo().id } })
        if (approveHandlerRes.success) {
            getApiResults();
            stateChangeManager(dispatch, Actions, true, 'success', approveHandlerRes.status);
        } else {
            setLoader(dispatch, Actions, false)
            stateChangeManager(dispatch, Actions, true, 'error', approveHandlerRes.status);

        }
    }
    ///////////////////////////////////////////////Console/////////////////////////////////////////////////////////////////////////////////


    return (
        <div>
            <CustomDialog
                state={state}
                setState={setState}
            />

            <CustomTitleContainer>
                <Grid container spacing={0} style={{
                    alignItems: 'center',
                    justifyItems: 'center',
                    alignContent: 'space-evenly',
                    justifyContent: 'space-evenly',
                }}>
                    <Grid item xs={6} sm={6} sx={{ display: '-webkit-flex', justifyContent: 'start', }}>
                        <CustomTitle title={'List of Journal Entries'} />
                    </Grid>
                    <Grid item xs={6} sm={6} sx={{ display: '-webkit-flex', justifyContent: 'end', }}>
                        <CustomButton
                            id={'create_journal_entry_btn'}
                            dataTestId={'create_journal_entry_btn'}
                            variant="contained"
                            sx={{ textTransform: 'none' }}
                            btnLabel='Create New Journal Entry'
                            onClick={() => {
                                navigate(routesName.journalEntryCreate.path)
                                AnalyticsEvent(eventsNames.categories.JOURNAL_ENTRY, { action: eventsNames.actions.NEW })
                            }}
                        />
                    </Grid>
                </Grid>
            </CustomTitleContainer>

            <CustomContainer maxWidth={"400"} sx={{}}>
                <div style={{ paddingLeft: '16px', paddingRight: '16px', paddingBottom: '24px', display: '-webkit-flex', }}>
                    <Filters filters={filters} setFilters={setFilters} setPage={setPage} />
                </div>
                <CustomTabs
                    id={'tabs_'}
                    dataTestId={'tabs_'}
                    tabs={tabs}
                    value={value}
                    onChange={(event, newValue) => { setValue(newValue); setPage(1) }}
                />
                <CustomTableContainer>
                    <CustomTable sx={{}}>
                        <JournalEntryHeader filters={filters} setFilters={setFilters} setPage={setPage} />
                        <JournalEntryBody
                            page={page}
                            setPage={setPage}
                            results={results}
                            filters={filters}
                            pagination={pagination}
                            viewHandler={viewHandler}
                            editHandler={editHandler}
                            deleteHandler={deleteHandler}
                            approveHandler={approveHandler}
                            onClickDescriptionHandler={onClickDescriptionHandler}
                        />
                    </CustomTable>
                    <Box sx={{ pt: !results.length ? 10 : 0 }} >
                        <NoDataComponent left='0%' top='0%' position={'relative'} data={results} />
                    </Box>
                </CustomTableContainer>
            </CustomContainer>

            <CustomPagination
                page={page}
                count={pagination.number_of_pages}
                onChange={(event, newValue) => setPage(newValue)}
            />
        </div>
    )
}

const JournalEntryHeader = (props) => {
    const { filters, setFilters, setPage } = props

    return (
        <CustomTableHead>
            <CustomTableRow>
                {/* <CustomTableHeadCell style={{ width: 50 }}  ><span>Sr. No.</span></CustomTableHeadCell> */}
                <CustomTableHeadCell style={{ width: 100 }} align='center'><span>Status </span></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 180 }}><HeadingWithSortable heading={'Date'} sortableKey={'transaction_date'} filters={filters} setFilters={setFilters} /></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 200 }} ><span>Description</span></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 300 }} ><span>Account</span></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 180 }} align='right'><span>Debit</span></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 180 }} align='right'><span>Credit</span></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 200 }} align='right'><span>Actions</span></CustomTableHeadCell>
            </CustomTableRow>
        </CustomTableHead>
    )
}


const JournalEntryBody = (props) => {
    const { onClickDescriptionHandler, filters, results, viewHandler, editHandler, deleteHandler, approveHandler } = props
    const toReturnColor = (status) => {
        let color = {
            color: '',
            background: '',
            borderColor: '',
        }
        if (status === 'is_contra') {
            color.color = '#00000';
            color.background = '#ede3c175';
            color.borderColor = '#635e5edb';

        } else if (status) {
            color.color = '#00000';
            color.background = '#cdebc7';
            color.borderColor = '#a2d998';
        } else {
            color.color = '#00000';
            color.background = '#e8f3ff';
            color.borderColor = '#a8a8a8';
        }
        return color
    }


    const onClickAccount = () => {
        AnalyticsEvent(eventsNames.categories.JOURNAL_ENTRY, { action: eventsNames.actions.CLICK, type: eventsNames.actions.journalEntry.type.ACCOUNT })
    }
    return (
        <CustomTableBody>
            {
                results.map((result, index) => {
                    return (
                        <CustomTableRow key={index}>
                            {/* <CustomTableBodyCell sx={{}} ><span>{(index + 1)}</span></CustomTableBodyCell> */}
                            <CustomTableBodyCell sx={{}} align='center'>
                                <span
                                    style={{
                                        border: '1px',
                                        width: '50px',
                                        paddingTop: 5,
                                        paddingLeft: 5,
                                        paddingBottom: 5,
                                        paddingRight: 10,
                                        textAlign: 'center',
                                        borderRadius: '5px',
                                        display: 'inline-block',
                                        color: toReturnColor(result.is_contra ? 'is_contra' : result.is_posted).color,
                                        background: toReturnColor(result.is_contra ? 'is_contra' : result.is_posted).background,
                                    }}
                                >{result.is_contra ? 'Contra' : result.is_posted ? 'Posted' : 'Draft'}</span>
                            </CustomTableBodyCell>
                            {/* <CustomTableBodyCell sx={{}}><span>{moment(result.transaction_date).format(getDateFormat())}</span></CustomTableBodyCell> */}
                            <CustomTableBodyCell sx={{}}><span>{moment(result.transaction_date).utc().format(getDateFormat() + ' HH:mm:ss')}</span></CustomTableBodyCell>

                            <CustomTableBodyCell sx={{ fontWeight: 500, }} id={'description_link_' + index} dataTestId={'description_link_' + index}>
                                {result.source === 'manual' || result.source === 'statement' ?
                                    <span>{convertSlugToString(result.description, '_')} </span>
                                    :
                                    <CustomTypography
                                        text={<span>{convertSlugToString(result.description, '_')} </span>}
                                        sx={{
                                            color: '#2464EB',
                                            fontSize: '12px',
                                            cursor: 'pointer',
                                            fontWeight: '500',
                                            lineHeight: '0px',
                                            display: 'inline',
                                            fontStyle: 'normal',
                                            textAlign: 'center',
                                            fontFamily: "Noto Sans",
                                            ":hover": { textDecoration: 'underline' }
                                        }}
                                        onClick={() => { onClickDescriptionHandler(result.id, result.source) }}
                                    />
                                }

                            </CustomTableBodyCell>
                            <CustomTableBodyCell sx={{ fontWeight: 600, }} id={'account_link_' + index} dataTestId={'account_link_' + index} >
                                {
                                    result.transactions.map((item, index) => {
                                        return (
                                            item.transaction_type === 'DEBIT' ?
                                                <p key={index} style={{ margin: '0px' }}>
                                                    <CustomLink style={{ color: '#2464EB' }}
                                                        onClick={onClickAccount}
                                                        to={routesName.journalEntryTransaction.path.replace(':id', item.business_account_id)}
                                                        state={filters.filter_start_date ? { dateRange: { name: 'Custom', }, toDate: filters.filter_end_date, fromDate: filters.filter_start_date } : null}
                                                    ><span>{item.business_account}</span></CustomLink>
                                                    <span > Dr.</span>
                                                </p>
                                                :
                                                <p key={index} style={{ margin: '0px' }}>
                                                    <span >To </span>
                                                    <CustomLink style={{ color: '#2464EB' }}
                                                        onClick={onClickAccount}
                                                        to={routesName.journalEntryTransaction.path.replace(':id', item.business_account_id)}
                                                        state={filters.filter_start_date ? { dateRange: { name: 'Custom', }, toDate: filters.filter_end_date, fromDate: filters.filter_start_date } : null}
                                                    >{item.business_account}</CustomLink>
                                                </p>
                                        )
                                    })
                                }
                            </CustomTableBodyCell>
                            <CustomTableBodyCell sx={{}} align='right'>
                                {
                                    result.transactions.map((item, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                {
                                                    item.transaction_type === 'DEBIT' ?
                                                        <p key={index} style={{ margin: '0px' }}>{<CurrencyFormatter amount={item.amount} currency={item.currency} />}</p>
                                                        :
                                                        <React.Fragment>
                                                            <span style={{ textAlign: 'center' }}>-</span><br />
                                                        </React.Fragment>
                                                }
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </CustomTableBodyCell>
                            <CustomTableBodyCell sx={{}} align='right'>
                                {
                                    result.transactions.map((item, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                {

                                                    item.transaction_type === 'CREDIT' ?
                                                        <p key={index} style={{ margin: '0px' }}>{<CurrencyFormatter amount={item.amount} currency={item.currency} />}</p>
                                                        :
                                                        <React.Fragment>
                                                            <span style={{ textAlign: 'center' }}>-</span><br />
                                                        </React.Fragment>

                                                }
                                            </React.Fragment>

                                        )
                                    })
                                }
                            </CustomTableBodyCell>
                            <CustomTableBodyCell align='right'>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>

                                    <ActionTextLinkBtn
                                        index={index}
                                        toActionText={result.source !== "manual" ? 'View' : result.is_posted || result.is_contra ? 'View' : 'Approve'}

                                        onClickActionText={() => { result.source !== "manual" ? viewHandler(result.id) : result.is_posted || result.is_contra ? viewHandler(result.id) : approveHandler(result.id) }} />
                                    <ListItemActions
                                        index={index}
                                        actions={
                                            [
                                                { name: 'View', onClick: () => viewHandler(result.id) },
                                                { name: 'Edit', disabled: result.source !== "manual", onClick: () => editHandler(result.id) },
                                                { name: 'Delete', disabled: result.source !== "manual", showDivider: true, onClick: () => deleteHandler(result.id) }
                                            ]
                                        }
                                    />
                                </Box>
                            </CustomTableBodyCell>
                        </CustomTableRow>
                    )
                })
            }
        </CustomTableBody>
    )
}

export default GetListJournalEntry;

const Filters = (props) => {
    const { setFilters, filters, setPage } = props
    const [selectedAccount, setSelectedAccount] = React.useState();
    ///////////////////////////////////////////////Api Call for the dropdown of accounts////////////////////////////////////////////////////////////////

    const onDateRangeChange = (dates) => {
        setPage(1);
        if (dates) {
            setFilters({ ...filters, filter_start_date: dates[0].format("YYYY-MM-DD"), filter_end_date: dates[1].format("YYYY-MM-DD") })
        } else {
            delete filters.filter_start_date
            delete filters.filter_end_date
            setFilters({ ...filters })
        }

    }
    return (
        <Grid item xs={12} container spacing={1}>
            <Grid item xs={12} sm={3}>
                <CommonSearch id={'input_search_description'} dataTestId={'input_search_description'} title={'Description'} placeholder={'Search Description'} titleVisibility={'visible'} fullWidth={true} onSearchValue={filters.description ? filters.description : ''} onSearch={(search_text) => { setFilters({ ...filters, description: search_text }); setPage(1) }} />
            </Grid>

            <Grid item xs={12} sm={3}>

                <AsyncDropdown
                    sx={{}}
                    fullWidth={true}
                    autoFocus={false}
                    validation={false}
                    disabledCloseIcon={false}
                    isGroupHeaderSticky={false}
                    id={'account_dropdown'}
                    dataTestId={'account_dropdown'}

                    title='Account'
                    valueLabelKey='id'
                    uniqueOptionKey='id'
                    searchKey='account_name'
                    optionLabelKey='account_name'
                    placeholder='Select the Account'
                    optionGroupLabelKey='parent_account_name'
                    URL={get_chart_of_account_nested_nodes(1)}

                    selectedValue={selectedAccount}
                    setSelectedValue={(value) => {
                        setPage(1);
                        setSelectedAccount(value);
                        if (value) {
                            setFilters({ ...filters, transaction_account_id: value.id })
                        } else {
                            delete filters.transaction_account_id
                            setFilters({ ...filters })
                        }
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={6} container>
                <CustomDateRangePicker onDateRangeChange={onDateRangeChange} />
            </Grid>
        </Grid>
    )
}


