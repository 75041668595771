import React from 'react';
import { useNavigate } from 'react-router-dom';
import { apiAction } from '../../../../api/api';
import DateRange from '../../../common/DateRange';
import * as Actions from '../../../../state/Actions';
import CustomTitle from '../../../common/CustomTitle';
import CommonSearch from '../../../common/CommonSearch';
import { routesName } from '../../../../config/routesName';
import ListItemActions from '../../../common/ListItemActions';
import CustomDialog from '../../../custom/dialog/CustomDialog';
import CustomPagination from '../../../custom/pagination/CustomPagination';

import {
    CustomContainer,
    CustomTitleContainer,
} from '../../../custom/container/CustomContainer';

import {
    delete_parent_account,
    get_list_of_parent_account_new,
} from '../../../../api/urls';

import {
    setLoader,
} from '../../../../utils/Utils';


import {
    CustomTable,
    CustomTableRow,
    CustomTableBody,
    CustomTableHead,
    CustomTableHeadCell,
    CustomTableBodyCell,
    CustomTableContainer,
} from '../../../custom/table/CustomTable';

import {
    Grid
} from '@mui/material';

const Dropdown = DateRange;

const ViewParentAccount = () => {

    let navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);


    const [state, setState] = React.useState({
        id: '',
        title: '',
        open: false,
        condition: '',
        maxWidth: 'lg',
        fullWidth: true,
    })
    const stateChangeHandler = (title, condition, maxWidth, id, item, url) => {
        setState({
            id: id,
            url: url,
            open: true,
            item: item,
            title: title,
            fullWidth: true,
            maxWidth: maxWidth,
            condition: condition,
        })
    }
    const [page, setPage] = React.useState(1);
    const [filters, setFilters] = React.useState({
        name: '',
        account_type: '',
    });

    const [pagination, setPagination] = React.useState({
        next: undefined,
        count: undefined,
        previous: undefined,
        number_of_pages: undefined,
    });
    const [results, setResults] = React.useState([])
    const getApiResults = async () => {
        const { name, account_type } = filters
        let additionData = account_type ? `&account_type=${account_type}` : ''
        setLoader(dispatch, Actions, true)
        let res = await apiAction({
            navigate: navigate,
            dispatch: dispatch,
            url: `${get_list_of_parent_account_new(page)}&name=${name}${additionData}`,
        })
        if (res?.success) {
            setPagination({
                ...pagination,
                next: res?.result.next,
                count: res?.result.count,
                previous: res?.result.previous,
                number_of_pages: res?.result.number_of_pages,
            });
            setLoader(dispatch, Actions, false);
            setResults(res?.result.result);
        } else {
            setLoader(dispatch, Actions, false);
        }
    }
    React.useEffect(() => {
        if (!state.open) {
            getApiResults()
        }
        // eslint-disable-next-line
    }, [page,state.open, filters])

    return (
        <div>
            <CustomDialog
                state={state}
                setState={setState}
            />
            <CustomTitleContainer>
                <CustomTitle title={'Parent Accounts'} />
            </CustomTitleContainer>

            <CustomContainer maxWidth={"400"} sx={{}}>
                <div style={{ paddingLeft: '16px', paddingRight: '16px', paddingBottom: '24px', display: '-webkit-flex', }}>
                    <Filters filters={filters} setFilters={setFilters} setPage={setPage} />
                </div>
                <CustomTableContainer sx={{}}>
                    <CustomTable>
                        <ParentAccountHeader />
                        <ParentAccountBody results={results} stateChangeHandler={stateChangeHandler} navigate={navigate} />
                    </CustomTable>
                </CustomTableContainer>
            </CustomContainer>
            <CustomPagination
                page={page}
                count={pagination.number_of_pages}
                onChange={(event, newValue) => setPage(newValue)}
            />
        </div>
    )
}

export default ViewParentAccount;
const ParentAccountHeader = () => {
    return (
        <CustomTableHead>
            <CustomTableRow>
                <CustomTableHeadCell style={{ width: 200 }} align='left' ><span>Name</span></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 140 }} align='left'><span>Primary</span></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 140 }} align='left'><span>Under Parent Account</span></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 140 }} align='right'><span>Account Type</span></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 140 }} align='right'><span>Account Code</span></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 140 }} align='right'><span>Actions</span></CustomTableHeadCell>
            </CustomTableRow>
        </CustomTableHead>
    )
}

const ParentAccountBody = (props) => {
    const { results, stateChangeHandler, navigate } = props;
    return (
        <CustomTableBody>
            {
                results?.map((item, index) => {
                    return (
                        <CustomTableRow key={index}>
                            <CustomTableBodyCell size='small' align='left'>{item.name}</CustomTableBodyCell>
                            <CustomTableBodyCell size='small' align='left'>{item.parent_account__name ? 'N' : "Y"}</CustomTableBodyCell>
                            <CustomTableBodyCell size='small' align='left'>{item.parent_account__name ? item.parent_account__name : '-'}</CustomTableBodyCell>
                            <CustomTableBodyCell size='small' align='right' >{item.account_type}</CustomTableBodyCell>
                            <CustomTableBodyCell size='small' align='right' >{item.account_number}</CustomTableBodyCell>
                            <CustomTableBodyCell size='small' align='right'>
                                <ListItemActions
                                    index={index}
                                    actions={
                                        [
                                            { name: 'Edit', disabled: !item.is_custom, onClick: () => { navigate(routesName.parentAccountAdd.path + "?id=" + item.id, { replace: false}) } },
                                            { name: 'Delete', disabled: !item.is_custom, showDivider: true, onClick: () => { stateChangeHandler('Delete Custom Parent Account', 'delete', 'sm', item.id, item, delete_parent_account(item.id)) } }
                                        ]
                                    }
                                />
                            </CustomTableBodyCell>
                        </CustomTableRow>
                    )
                })
            }

        </CustomTableBody>
    )
}

const Filters = ({ filters, setFilters, setPage }) => {

    let accountList = [{ name: 'ASSET' }, { name: 'LIABILITY' }, { name: 'EQUITY' }, { name: 'EXPENSE' }, { name: 'INCOME' }]
    return (
        <Grid item xs={12} container spacing={1}>
            <Grid item xs={12} sm={2.5}>
                <Dropdown
                    // label="Account Type"
                    results={accountList}
                    titleVisibility='hidden'
                    value={filters.account_type}
                    id={'account_type_dropdown'}
                    placeholder='Select account type'
                    dataTestId={'account_type_dropdown'}
                    setValue={(event, value) => { setFilters((previous) => ({ ...previous, account_type: value?.name })); setPage(1) }}
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                <CommonSearch
                    // title={'Name'}
                    fullWidth={true}
                    isTitleVisibility={false}
                    titleVisibility={'hidden'}
                    placeholder={'Search by name'}
                    id={'input_search_parent_name'}
                    dataTestId={'input_search_parent_name'}
                    onSearchValue={filters.name ? filters.name : ''}
                    onSearch={(search_text) => { setFilters((previous) => ({ ...previous, name: search_text })); setPage(1) }} />
            </Grid>
        </Grid>
    )
}