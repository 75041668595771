import React from 'react';
import { privacy_policy, terms_and_conditions } from '../../../api/urls';

export const PrivacyPolicy = () => {
  const [privacyPolicyContent, setPrivacyPolicyContent] = React.useState(null);

  React.useEffect(() => {
    fetch(privacy_policy())
      .then(response => response.text())
      .then(data => setPrivacyPolicyContent(data))
      .catch(error => console.error('Error fetching privacy policy:', error));
  }, []);

  return (
    <div style={{ padding: '10px' }}>
      {privacyPolicyContent && <div dangerouslySetInnerHTML={{ __html: privacyPolicyContent }} />}
    </div>
  );
};

export const TermsAndServices = () => {
  const [termsAndServicesContent, setTermsAndServicesContent] = React.useState(null);

  React.useEffect(() => {
    fetch(terms_and_conditions())
      .then(response => response.text())
      .then(data => setTermsAndServicesContent(data))
      .catch(error => console.error('Error fetching terms and services:', error));
  }, []);

  return (
    <div style={{ padding: '10px' }}>
      {termsAndServicesContent && <div dangerouslySetInnerHTML={{ __html: termsAndServicesContent }} />}
    </div>
  );
};


// export const PrivacyPolicy = () => {
//   return (
//     <div>
//       <iframe src={privacy_policy()} width='100%' height='100%' title="Privacy Policy"></iframe>
//     </div>
//   );
// };

// export const TermsAndServices = () => {
//   return (
//     <div>
//       <iframe src={terms_and_conditions()} width='100%' height='100%' title="Terms and Conditions"></iframe>
//     </div>
//   );
// };
