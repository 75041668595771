import React from "react";
import { items, party } from "../../../../../utils/Constant";
import * as Actions from '../../../../../state/Actions'
import { routesName } from "../../../../../config/routesName";
import FileUpload from "../../../bulkStatementUpload/mappings/FileUpload";
import { get_fields_for_mapping,import_item_sample } from "../../../../../api/urls";

const ItemsImportFile = (props) => {
    let dispatch = Actions.getDispatch(React.useContext)
    return (

        <FileUpload
        mapping_for={items}
        isDateFormat={true}
        sample_file_download_url={import_item_sample}
        model_fields_url={get_fields_for_mapping(items)}
        nextPagePath={routesName.invoicingItemsMapping.path}
        />

    )
}

export default ItemsImportFile