import React from 'react';
import ViewProfile from './userProfile/ViewProfile';
import ListBusiness from './businessProfile/ListBusiness';
import CustomVerticalTabs from '../../custom/tabs/CustomVerticalTabs';

import {
    Box,
} from '@mui/material';

const MyAccount = () => {
    const tabs = [
        { name: 'Profile', condition: 'personal_information' },
        { name: 'Organizations', condition: 'organizations_information' },
    ]
    const [tab, setTab] = React.useState(0);

    return (
            <Box sx={{display: 'flex', height: '100vh',width:'100%'}}>
                <Box sx={{borderRight: '1px solid #e8e8e8',background: '#FFFFFF',overflowY: 'scroll' }}>
                <CustomVerticalTabs
                id={'tabs_'}
                dataTestId={'tabs_'}
                    tabs={tabs}
                    value={tab}
                    onChange={(event, value) => setTab(value)}
                    />
                    </Box>

                <Box sx={{pl:2,width:'100%'}}>
                    {tabs[tab].condition === 'personal_information' && <ViewProfile />}
                    {tabs[tab].condition === 'organizations_information' && <ListBusiness />}
                </Box>
            </Box>
    )
}

export default MyAccount