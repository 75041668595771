import React from 'react';
import Highcharts from "highcharts/highstock";
import PieChart from "highcharts-react-official";
import { apiAction } from '../../../../../../api/api';
import { useIsElementVisible } from '../../common/Helper';
import CurrencyFormatter from "../../../../../common/CurrencyFormatter";
import { HtmlTooltip } from '../../../../../custom/tooltip/CustomTooltip';
import CustomTypography from "../../../../../custom/typography/CustomTypography";
import { BootstrapDialog, BootstrapDialogTitle } from '../../../../bulkStatementUpload/mappings/Transactions';


import {
  get_data_point,
  evaluate_data_point,
  evaluate_dependant_data_point,
} from '../../../../../../api/urls';

import {
  isNumber,
  isNumeric,
  amountFormatter,
  getUniqueObjectsByKey,
  numberFormatterWithoutSymbol,
} from '../../../../../../utils/Utils';

import {
  Box,
  IconButton,
  DialogContent,
} from '@mui/material';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';


const PieChartView = (props) => {
  const { data, to_date, from_date, refreshDataPoint, openFull, setOpenFull, isFromListView, onClose } = props;

  const elementRef = React.useRef(null);
  const [dataList, setDataList] = React.useState([]);
  const [dataPointData, setDataPointData] = React.useState();
  const [dependencies, setDependencies] = React.useState([]);
  const [Id, setId] = React.useState(data?.data_point_ids[0]);
  const isElementVisible = useIsElementVisible(elementRef.current);

  const fetchDataPoint = async (data_point_id) => {
    try {
      const value = await apiAction({
        url: get_data_point(data_point_id),
      });
      if (!value?.success) {
        console.log('Error', value);
        return;
      }
      const result = value.result;

      const item = await evaluateDataPoint(data_point_id);
      if (item?.success) {
        result['amount'] = item.result;
        result['tooltip_message'] = item.tooltip_message;
      }
      setId(data_point_id);
      processDependenciesDependantDataPoint(data_point_id);
      setDataPointData(result);

      setDataList((prevDataList) => [...getUniqueObjectsByKey([...prevDataList, result])]);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const evaluateDataPoint = async (data_point_id, isRefresh = false) => {
    try {
      const value = await apiAction({
        url: isRefresh ? `${evaluate_data_point(data_point_id, from_date, to_date)}&is_refresh=${true}` : evaluate_data_point(data_point_id, from_date, to_date),
      });

      return value?.success ? value : null;
    } catch (error) {
      console.error('Error:', error);
      return null;
    }
  };

  const evaluateDependantDataPoint = async (data_point_id, isRefresh = false) => {
    try {
      const value = await apiAction({
        url: isRefresh ? `${evaluate_dependant_data_point(data_point_id, from_date, to_date)}&is_refresh=${true}` : evaluate_dependant_data_point(data_point_id, from_date, to_date),
      });

      return value?.success ? value : null;
    } catch (error) {
      console.error('Error:', error);
      return null;
    }
  };

  const getHasDependencies = (type) => {
    if (type === 'Account' || type === 'Function') {
      return false;
    }
    return true;
  };

  const processDependenciesDependantDataPoint = async (data_point_id, isRefresh = false) => {
    try {
      const data_point_dependencies = await evaluateDependantDataPoint(data_point_id, isRefresh);

      if (data_point_dependencies?.success && data_point_dependencies?.result) {
        const updatedDependencies = data_point_dependencies?.result?.map((item) => ({
          id: item?.data_point?.id,
          name: item?.data_point?.name,
          tooltip_message: item?.tooltip,
          amount: isNumber(item.result) ? Number(item.result) : 0,
          has_dependencies: getHasDependencies(item?.data_point?.data_point_type),
        }));

        setDependencies(updatedDependencies);
      } else {
        setDependencies([]);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  React.useEffect(() => {
    const fetchData = async () => {
      if (isElementVisible && !dataPointData) {
        await fetchDataPoint(data?.data_point_ids[0]);
      } else if (isElementVisible && data && dataPointData && dataList[0]?.id !== data?.data_point_ids[0]) {
        setDataList([]);
        await fetchDataPoint(data?.data_point_ids[0]);
      }
    };

    fetchData();
  }, [isElementVisible, data]);

  React.useEffect(() => {
    const refreshDependencies = async () => {
      if (dependencies.length && isElementVisible) {
        processDependenciesDependantDataPoint(Id, false);
      }
    };
    refreshDependencies();
  }, [from_date, to_date]);

  React.useEffect(() => {
    const refreshDependencies = async () => {
      if (isElementVisible) {
        processDependenciesDependantDataPoint(Id, true);
      }
    };
    refreshDependencies();
  }, [refreshDataPoint]);

  const handleDrilldown = (item) => {
    fetchDataPoint(item?.options?.id);
    setDataList((prevDataList) => [...getUniqueObjectsByKey([...prevDataList, item?.options])]);
  };

  React.useEffect(() => {
    if (isFromListView) {
      setOpenFull(true)
    }

  }, [isFromListView])
  return (
    <div ref={elementRef} style={{ position: 'relative', height: '100%', overflow: 'hidden' }}>

      <FullViewModePieChart
        Id={Id}
        open={openFull}
        setOpen={() => {
          setOpenFull(false)
          if (onClose) {
            onClose()
          }
        }}
        title={data?.name}
        dataList={dataList}
        setDataList={setDataList}
        fetchDataPoint={fetchDataPoint}
        handleDrilldown={handleDrilldown}
        pieChartData={dependencies?.filter((item) => item.amount > 0)}
      />
      <DataPointList Id={Id} setDataList={setDataList} dataList={dataList} fetchDataPoint={fetchDataPoint} />

      {Boolean(dependencies?.filter((item) => item.amount > 0)?.length) ? (
        <Box sx={{ height: 'calc(100vh - 450px)' }}>
          <CustomPieChart
            handleDrilldown={handleDrilldown}
            pieChartData={dependencies?.filter((item) => item.amount > 0)}
            containerProps={{ style: { width: '100%', minHeight: '100%', } }}
          />
        </Box>
      ) : (
        <NoDataMessage />
      )}
    </div>
  );
};

export default PieChartView;

const getTooltipContent = (dataPoint) => {
  if (!dataPoint) return '';
  const value = dataPoint.amount;
  if (dataPoint.unit === 'base_currency') {
    return <CurrencyFormatter amount={value} />;
  } else if (dataPoint.unit === 'percentage') {
    return `${numberFormatterWithoutSymbol(null, isNumeric(value) ? Number(value) : 0)} %`;
  } else {
    return numberFormatterWithoutSymbol(null, isNumeric(value) ? Number(value) : 0);
  }
};

const DataPointItem = ({ Id, index, item, onClick }) => (
  <React.Fragment key={index}>
    <CustomTypography
      text={
        <HtmlTooltip title={<span style={{ fontWeight: '700' }}>{getTooltipContent(item)}</span>} placement="top">
          {!index ? item?.name + ' ' : '›' + ' ' + item?.name}
        </HtmlTooltip>
      }
      sx={{
        fontSize: '12px',
        marginLeft: '4px',
        fontWeight: '700',
        lineHeight: '18px',
        marginRight: '4px',
        fontStyle: 'normal',
        whiteSpace: 'nowrap',
        textTransform: 'none',
        fontFamily: 'Noto Sans',
        cursor: Id !== item.id ? 'pointer' : 'default',
        color: Id !== item.id ? '#2464EB' : 'GrayText',
      }}
      onClick={() => { onClick() }}
    />
  </React.Fragment>
);

const DataPointList = ({ Id, dataList, setDataList, fetchDataPoint }) => (
  <Box sx={{ p: 1.5, mb: 2, pl: 1, display: 'flex', overflowX: 'auto', borderBottom: '1px solid #c4c4c4' }}>
    {getUniqueObjectsByKey([...dataList]).map((value, index) => (
      <DataPointItem
        Id={Id}
        index={index}
        item={value}
        onClick={() => {
          if (Id !== value.id) {
            fetchDataPoint(value.id);
            const newData = dataList.slice(0, index + 1);
            setDataList([...getUniqueObjectsByKey([...newData])]);
          }
        }} />
    ))}
  </Box>
);

const NoDataMessage = () => (
  <Box display="flex" alignItems="center" justifyContent="center" height="calc(100vh - 410px)">
    <CustomTypography
      text={'No data recorded'}
      sx={{
        opacity: 0.6,
        color: '#000000',
        fontSize: '14px',
        fontWeight: '700',
        textAlign: 'center',
        fontStyle: 'normal',
        lineHeight: 'normal',
        fontFamily: 'Noto Sans',
      }}
    />
  </Box>
);

const CustomPieChart = ({ pieChartData, handleDrilldown, containerProps }) => {
  pieChartData.map((item) => {
    item['y'] = item.amount;
    item['is_parent'] = item.has_dependencies;
  })

  const options = {
    chart: {
      type: "pie"
    },
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    tooltip: {
      formatter: function () {
        return this.point.name + `: <b style="font-family: 'Noto Sans';">` + amountFormatter(this.y) + '</b>';
      }
    },

    plotOptions: {
      pie: {
        cursor: 'pointer',
        showInLegend: true,
        allowPointSelect: false,

        dataLabels: {
          enabled: true,
          formatter: function () {
            if (this.point.is_parent) {
              return '<span class="hover-underline-class" style="color: #2464EB; cursor: pointer;"><b style="font-family: \'Noto Sans\';">' + this.point.name + '</b>: ' + this.point.percentage.toFixed(2) + ' %</span>';
            } else {
              return this.point.name + ': ' + this.point.percentage.toFixed(2) + ' %';
            }
          },
          style: {
            color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
          }
        },
        point: {
          events: {
            click: function () {
              if (this.is_parent) {
                handleDrilldown(this);
              }
            }
          },
          states: {
            select: {
              enabled: function () {
                return this.is_parent;
              },
              cursor: 'pointer'
            }
          }
        }
      }
    },

    series: [
      {
        data: pieChartData,
        showInLegend: false,
      }
    ]
  };
  return (
    <PieChart highcharts={Highcharts} options={options} containerProps={{ ...containerProps }} />
  )
}


export const FullViewModePieChart = ({ Id, title, open, setOpen, dataList, setDataList, fetchDataPoint, handleDrilldown, pieChartData }) => {
  return (
    <BootstrapDialog
      fullWidth
      open={open}
      maxWidth={'lg'}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={() => setOpen(false)}>
        <CustomTypography
          text={title}
          sx={{
            color: '#000000',
            fontSize: '16px',
            fontWeight: '700',
            lineHeight: '18px',
            fontStyle: 'normal',
            fontFamily: "Noto Sans",
          }}
        />
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <Box sx={{ mt: '-14px' }}>
          <DataPointList Id={Id} setDataList={setDataList} dataList={dataList} fetchDataPoint={fetchDataPoint} />
        </Box>
        {Boolean(pieChartData?.length) ? (
          <CustomPieChart
            pieChartData={pieChartData}
            handleDrilldown={handleDrilldown}
            containerProps={{ style: { width: '100%', minHeight: '100%', } }}
          />
        ) : (
          <NoDataMessage />
        )}
      </DialogContent>
    </BootstrapDialog>
  )
}
