import React from 'react';
import PropTypes from 'prop-types';
import { export_item } from '../../api/urls';
import { apiHandleDownload } from '../../api/api';
import CustomButton from '../custom/button/CustomButton';
import { getBusinessInfo } from '../../config/cookiesInfo';
import CustomTypography from '../custom/typography/CustomTypography';

import {
    Radio,
    Dialog,
    RadioGroup,
    DialogTitle,
    FormControl,
    DialogContent,
    DialogActions,
    FormControlLabel,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const CommonExport = (props) => {
    const { open, title, setOpen, fileName, url, role } = props
    const [type, setType] = React.useState('csv');

    const returnFormate = (export_type) => {
        if (export_type === 'csv') {
            return '.csv'
        }
        if (export_type === 'excel') {
            return '.xlsx'
        }
    }

    const onClose = () => {
        setType('csv');
        setOpen(false);
    }
    const onSaveAction = async (export_to) => {
        apiHandleDownload({
            filename: fileName,
            url: url(),
            formate: returnFormate(export_to),
            data: { business_id: getBusinessInfo().id, export_to: export_to, role: role },
            onSuccess: () => {
                onClose();
            }
        })
    }
    return (
        <div>
            <BootstrapDialog
                open={open}
                maxWidth={'sm'}
                fullWidth={true}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={() => { onClose() }}>
                    <CustomTypography
                        text={'Export'}
                        sx={{
                            color: '#000000',
                            fontSize: '16px',
                            fontWeight: '700',
                            lineHeight: '18px',
                            fontStyle: 'normal',
                            fontFamily: "Noto Sans",
                        }}
                    />
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <CustomTypography
                        text={title}
                        sx={{
                            mb: 4,
                            fontSize: '16px',
                            color: '#000000',
                            fontWeight: '500',
                            lineHeight: '18px',
                            fontStyle: 'normal',
                            textTransform: "none",
                            fontFamily: "Noto Sans",
                        }}
                    />
                    <FormControl >
                        <CustomTypography
                            text={'Export As'}
                            sx={{
                                mt: 2,
                                mb: 2,
                                opacity: 0.8,
                                fontSize: '18px',
                                color: '#000000',
                                fontWeight: '700',
                                lineHeight: '18px',
                                fontStyle: 'normal',
                                textTransform: "none",
                                fontFamily: "Noto Sans",
                            }}
                        />
                        <RadioGroup >
                            <FormControlLabel
                                id={'csv_radio_btn'}
                                dataTestId={'csv_radio_btn'}
                                value="csv" control={<Radio size="small" checked={type === 'csv'} onChange={(e) => { setType(e.target.value) }} />}
                                label={
                                    <span style={{
                                        color: '#000000',
                                        fontSize: '14px',
                                        display: 'inline',
                                        lineHeight: '18px',
                                        fontStyle: 'normal',
                                        fontFamily: "Noto Sans",
                                        fontWeight: type === 'csv' ? '600' : '400',
                                    }}>CSV (Comma Separated Value)</span>
                                }
                            />
                            <FormControlLabel
                                id={'excel_radio_btn'}
                                dataTestId={'excel_radio_btn'}
                                value="excel" control={<Radio size="small" checked={type === 'excel'} onChange={(e) => { setType(e.target.value) }} />}
                                label={
                                    <span style={{
                                        color: '#000000',
                                        fontSize: '14px',
                                        display: 'inline',
                                        lineHeight: '18px',
                                        fontStyle: 'normal',
                                        fontFamily: "Noto Sans",
                                        fontWeight: type === 'excel' ? '600' : '400',
                                    }}>XLSX (Microsoft Excel)</span>
                                }
                            />
                        </RadioGroup >
                    </FormControl>

                    <div style={{ textAlign: 'center', marginTop: '24px' }}>
                        <CustomButton
                            btnLabel='Cancel'
                            variant='outlined'
                            id={'export_cancel_btn'}
                            onClick={() => onClose()}
                            dataTestId={'export_cancel_btn'}
                            sx={{ textTransform: 'none', height: '35px', mr: 2, pl: 2, pr: 2, '&:hover': { backgroundColor: '#e8f3ff' } }}
                        />
                        <CustomButton
                            btnLabel='Export'
                            variant='contained'
                            id={'export_export_btn'}
                            dataTestId={'export_export_btn'}
                            onClick={() => onSaveAction(type)}
                            sx={{ textTransform: 'none', height: '35px', mr: 2, pl: 2, pr: 2, }}
                        />
                    </div>
                    <DialogActions>

                    </DialogActions>
                </DialogContent>
            </BootstrapDialog>
        </div>
    )
}

export default CommonExport;


const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));