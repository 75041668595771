import React from 'react';
import validator from 'validator';
import { Resend } from './VerifyOtp';
import SocialLogin from '../SocialLogin';
import Input from '../../../common/Input';
import { useNavigate, useLocation } from 'react-router-dom';
import { send_otp, userSignIn } from '../../../../api/urls';
import CustomButton from '../../../custom/button/CustomButton';
import { LabelWithAsteriskMark } from '../../../common/CommonLabel';
import CustomTypography from '../../../custom/typography/CustomTypography';

import { apiAction } from '../../../../api/api';
import * as Actions from '../../../../state/Actions';
import { routesName } from '../../../../config/routesName';
import { eventsNames, AnalyticsEvent } from '../../../../firebase/firebaseAnalytics';

import {
    isFormValid,
    getQueryParams,
    stateChangeManager,
    isValidPhoneNumber,
} from '../../../../utils/Utils';

import {
    getUserPreferenceData,
    getBusinessPreferenceData
} from '../../setting/settings/Preferences/common/getPreferences';

import {
    setToken,
    setLoginStatus,
    setUserDetails,
    setBusinessInfo
} from '../../../../config/cookiesInfo';

import {
    Box,
    Link,
    Grid,
    Divider,
    Checkbox,
    IconButton,
    InputAdornment,
} from '@mui/material';
import { Edit } from '@mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ModalUpdateUserDetails from './ModalUpdateUserDetails';

const SigninForm = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = getQueryParams(location.search);
    const dispatch = Actions.getDispatch(React.useContext);

    const [agree, setAgree] = React.useState(true);
    const [isResend, setIsResend] = React.useState(false);
    const [nextStep, setNextStep] = React.useState(false);
    const [businessData, setBusinessData] = React.useState(false);
    const [isMissingData, setIsMissingData] = React.useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);
    const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);
    const [data, setData] = React.useState({ loginInputValue: '', loginPasswordValue: '' });

    const isEmailInput = validator.isEmail(data.loginInputValue);

    const handleInputChange = (field) => (event) => {
        setData((prev) => ({ ...prev, [field]: event.target.value }));
    };

    const togglePasswordVisibility = () => {
        setIsPasswordVisible((prev) => !prev);
    };

    const handleSubmitNext = async (event) => {
        event.preventDefault();
        setIsFormSubmitted(true);

        let validation_data = [
            { key: 'loginInputValue', message: 'Email/Mobile Number field cannot be empty' },
            {
                key: 'loginInputValue',
                message: 'Please enter a valid Email/Mobile Number',
                validation: data.loginInputValue && !(isValidPhoneNumber(data.loginInputValue) || validator.isEmail(data.loginInputValue)),
            },

        ];

        const { isValid, message } = isFormValid(data, validation_data);

        if (isValid) {
            setIsFormSubmitted(false);
            if (validator.isEmail(data.loginInputValue)) {
                setNextStep(true);
            }
            if (isValidPhoneNumber(data.loginInputValue)) {
                await getOTP(data.loginInputValue);
            }
        } else {
            stateChangeManager(dispatch, Actions, true, 'error', message);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsFormSubmitted(true);

        let validation_data = [
            { key: 'loginPasswordValue', message: `${isEmailInput ? 'Password' : 'OTP'} field cannot be empty` },
            { key: '', validation: !agree, message: 'Please accept the terms and conditions.' }
        ];

        const { isValid, message } = isFormValid(data, validation_data);

        if (isValid) {
            signInHandler();
        } else {
            stateChangeManager(dispatch, Actions, true, 'error', message);
        }
    };

    const signInHandler = async () => {

        let additionData;
        if (validator.isEmail(data.loginInputValue)) {
            additionData = { is_otp_based_auth: false, email: data.loginInputValue, password: data.loginPasswordValue };
            AnalyticsEvent(eventsNames.categories.USER_AUTHENTICATION, { action: 'SIGN_IN', method: 'EMAIL' });
        } else {
            additionData = { is_otp_based_auth: true, mobile_number: data.loginInputValue, otp: data.loginPasswordValue, otp_through: 'mobile_number' };
            AnalyticsEvent(eventsNames.categories.USER_AUTHENTICATION, { action: 'SIGN_IN', method: 'MOBILE' });
        }
        const res = await apiAction({
            method: 'post',
            url: userSignIn(),
            data: { ...data, ...additionData, is_privacy_policy_and_terms_accepted: agree }
        });

        if (res?.success) {
            setBusinessData(res.business);
            setUserDetails(res.data.user);
            setToken(res.data.access_token);
            if (res.is_mobile_number_empty) {
                setIsMissingData('is_mobile_number_empty');
            } else if (res.is_email_empty) {
                setIsMissingData('is_email_empty');
            } else {
                handleBusinessRedirect(res.business);
            }
        } else {
            stateChangeManager(dispatch, Actions, true, 'error', res?.status);
        }
    };

    const handleBusinessRedirect = (businessData) => {
        if (businessData) {
            setLoginStatus('true');
            getUserPreferenceData(navigate, dispatch);
            const defaultBusiness = businessData.find((item) => item.is_default) || businessData[0];
            setBusinessInfo(defaultBusiness);
            getBusinessPreferenceData(defaultBusiness.id, navigate, dispatch);
            navigate(routesName.dashboard.path);
        } else {
            navigate("/auth" + routesName.businessCreate.path);
        }
    };

    const getOTP = async (mobileNumber) => {
        const res = await apiAction({
            method: 'post',
            url: send_otp(),
            data: { otp_for: 'login', mobile_number: mobileNumber, is_privacy_policy_and_terms_accepted: true },
        });
        if (res?.success) {
            setNextStep(true);
            setIsResend(!isResend);
            setIsFormSubmitted(false);
            setData((prev) => ({ ...prev, loginPasswordValue: '', session_id: res?.session_id }));
            stateChangeManager(dispatch, Actions, true, 'success', res?.status);
        } else {
            stateChangeManager(dispatch, Actions, true, 'error', res?.status);
        }
    };

    if (params.code) {
        return <SocialLogin AnalyticsEvent={() => AnalyticsEvent(eventsNames.categories.USER_AUTHENTICATION, { action: 'SIGN_IN', method: 'GOOGLE' })} />;
    }

    if (isMissingData) {
        return <ModalUpdateUserDetails open={isMissingData} setOpen={() => { setIsMissingData(false); handleBusinessRedirect(businessData); }} />
    }
    return (
        <Box sx={{ display: 'flex' }}>
            <Box className="m_login_left_box" sx={{ backgroundColor: '#F7F7F7', minHeight: '100%', width: '28%', display: 'block' }} >
                <Box sx={{ pt: 8, minHeight: "100vh", minWidth: '100vh', alignItems: 'center', justifyContent: 'center' }}>
                    <Box sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ height: '100%', width: '100%' }}>
                <Box className='m_login_container' sx={{ pt: 8, alignItems: "center", justifyContent: "center", }}>
                    <Box sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: "center", flexDirection: 'column', }}>
                        <Box component="form" onSubmit={nextStep ? handleSubmit : handleSubmitNext} noValidate sx={{ minWidth: { xs: '100%', sm: 500 } }}>
                            <CustomTypography text="Account Login" sx={{ mb: 4, fontSize: '30px', fontWeight: 700, color: '#000', textAlign: 'start' }} />

                            {/* Login Input */}
                            <Box sx={{ mt: 2, }}>
                                <Input
                                    autoFocus
                                    id="login_input"
                                    dataTestId="login_input"
                                    item={{
                                        type: 'text',
                                        required: true,
                                        fullWidth: true,
                                        disabled: nextStep,
                                        value: data.loginInputValue,
                                        onChange: handleInputChange('loginInputValue'),
                                        placeholder: 'Enter Your Email Address or Mobile number',
                                        title: <LabelWithAsteriskMark label={'Email/Mobile Number'} />,
                                        validation: isFormSubmitted && data.loginInputValue && !(isValidPhoneNumber(data.loginInputValue) || validator.isEmail(data.loginInputValue)),
                                    }}
                                    InputProps={{
                                        endAdornment: nextStep && (
                                            <InputAdornment position="end">
                                                <Divider sx={{ height: 20, m: 0.5 }} orientation="vertical" />
                                                <IconButton
                                                    edge="end"
                                                    aria-label="Edit mode"
                                                    onClick={() => { setNextStep(false); setData({ loginInputValue: '' }) }}
                                                >
                                                    {<Edit fontSize='small' sx={{ color: '#2464EB' }} />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Box>
                            {nextStep && (

                                <Box sx={{ mt: 2, }}>
                                    <PasswordInput
                                        isFormSubmitted={isFormSubmitted}
                                        password={data.loginPasswordValue}
                                        isPasswordVisible={isPasswordVisible}
                                        label={isEmailInput ? 'Password' : 'OTP'}
                                        togglePasswordVisibility={togglePasswordVisibility}
                                        handleInputChange={handleInputChange('loginPasswordValue')}
                                    />
                                    {!isEmailInput && (
                                        <Resend handleResend={() => getOTP(data.loginInputValue)} />
                                    )}
                                    <AgreeTerms agree={agree} setAgree={setAgree} />
                                </Box>
                            )}

                            <CustomButton
                                fullWidth
                                type="submit"
                                id="sign_in_btn"
                                variant="contained"
                                sx={{ mt: 4, mb: 3 }}
                                timeoutValue={nextStep ? 1000 : 0}
                                btnLabel={nextStep ? "Sign In" : 'Next'}
                                onClick={nextStep ? handleSubmit : handleSubmitNext}
                            />

                            <Divider>
                                <CustomTypography text="OR" sx={{ fontSize: '14px', fontWeight: 700 }} />
                            </Divider>

                            <SocialLogin btnText="Sign in with Google" AnalyticsEvent={() => AnalyticsEvent(eventsNames.categories.USER_AUTHENTICATION, { action: 'SIGN_IN', method: 'GOOGLE' })} />

                            <Grid container spacing={2} sx={{ pt: 3, fontSize: { xs: '12px', sm: '16px' } }}>
                                <Grid item xs>
                                    <Link id='forgot_password_link' dataTestId='forgot_password_link' href={"/auth" + routesName.forgotPassword.path}>Forgot password?</Link>
                                </Grid>
                                <Grid item>
                                    <Link id='sign_up_link' dataTestId='sign_up_link' href={"/auth" + routesName.signUp.path}>Don't have an account? sign up</Link>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
export default SigninForm;

const PasswordInput = ({ label, password, isPasswordVisible, togglePasswordVisibility, handleInputChange, isFormSubmitted }) => (
    <Input
        autoFocus
        id="password"
        dataTestId="password"
        isKeyPressEnter={false}
        item={{
            required: true,
            fullWidth: true,
            value: password,
            onChange: handleInputChange,
            placeholder: 'Enter Your ' + label,
            validation: isFormSubmitted && (!password),
            type: isPasswordVisible ? 'text' : 'password',
            title: <LabelWithAsteriskMark label={label} />,
        }}
        InputProps={{
            endAdornment: (
                <InputAdornment position="end">
                    <Divider sx={{ height: 20, m: 0.5 }} orientation="vertical" />
                    <IconButton
                        edge="end"
                        onClick={togglePasswordVisibility}
                        aria-label="toggle password visibility"
                    >
                        {isPasswordVisible ? <VisibilityIcon fontSize='small' /> : <VisibilityOffIcon fontSize='small' />}
                    </IconButton>
                </InputAdornment>
            ),
        }}
    />
);


export const AgreeTerms = ({ agree, setAgree }) => (
    <Box sx={{ mt: 2 }}>
        <CustomTypography
            text={
                <>
                    <Checkbox size="small" checked={agree} onChange={() => setAgree(!agree)} />
                    By signing in, I agree to the finycs{' '}
                    <Link href={routesName.privacyPolicy.path} target="_blank" rel="noreferrer">Privacy Policy</Link> and{' '}
                    <Link href={routesName.termsAndServices.path} target="_blank" rel="noreferrer">Terms and Services</Link>.
                </>
            }
            sx={{ fontSize: '14px', color: '#000', textAlign: 'start', }}
        />
    </Box>
);
