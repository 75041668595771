// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scrollbarHidden::-webkit-scrollbar {
    display: none;
}

@media only screen and (max-width: 600px) {
    .fontSize {
        font-size: 10px !important;
        font: optional !important;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .fontSize {
        font-size: 10px !important;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .fontSize {
        font-size: 18px !important;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .fontSize {
        font-size: 18px !important;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .fontSize {
        font-size: 10px !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/assets/css/mediaQuery.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI;QACI,0BAA0B;QAC1B,yBAAyB;IAC7B;AACJ;;AAEA,oEAAoE;AACpE;IACI;QACI,0BAA0B;IAC9B;AACJ;;AAEA,qDAAqD;AACrD;IACI;QACI,0BAA0B;IAC9B;AACJ;;AAEA,mDAAmD;AACnD;IACI;QACI,0BAA0B;IAC9B;AACJ;;AAEA,oEAAoE;AACpE;IACI;QACI,0BAA0B;IAC9B;AACJ","sourcesContent":[".scrollbarHidden::-webkit-scrollbar {\r\n    display: none;\r\n}\r\n\r\n@media only screen and (max-width: 600px) {\r\n    .fontSize {\r\n        font-size: 10px !important;\r\n        font: optional !important;\r\n    }\r\n}\r\n\r\n/* Small devices (portrait tablets and large phones, 600px and up) */\r\n@media only screen and (min-width: 600px) {\r\n    .fontSize {\r\n        font-size: 10px !important;\r\n    }\r\n}\r\n\r\n/* Medium devices (landscape tablets, 768px and up) */\r\n@media only screen and (min-width: 768px) {\r\n    .fontSize {\r\n        font-size: 18px !important;\r\n    }\r\n}\r\n\r\n/* Large devices (laptops/desktops, 992px and up) */\r\n@media only screen and (min-width: 992px) {\r\n    .fontSize {\r\n        font-size: 18px !important;\r\n    }\r\n}\r\n\r\n/* Extra large devices (large laptops and desktops, 1200px and up) */\r\n@media only screen and (min-width: 1200px) {\r\n    .fontSize {\r\n        font-size: 10px !important;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
