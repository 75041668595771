import React from 'react';
import CustomTypography from '../custom/typography/CustomTypography';

const CustomTitle = (props) => {
  return (
    <CustomTypography
      text={props.title}
      sx={{
        color: '#141414',
        fontSize: '20px',
        fontWeight: '700',
        lineHeight: '27px',
        fontStyle: 'normal',
        textTransform:'none',
        fontFamily: "Noto Sans",

        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      }}
    />
  )
}

export default CustomTitle