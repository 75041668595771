import * as React from 'react';
import validator from 'validator';
import Input from '../../common/Input';
import * as Actions from '../../../state/Actions';
import { routesName } from '../../../config/routesName';
import { useNavigate, useLocation } from 'react-router-dom';
import { apiAction, apiAction_social } from '../../../api/api';
import CustomTypography from '../../custom/typography/CustomTypography';
import { getBusinessPreferenceData,getUserPreferenceData } from '../setting/settings/Preferences/common/getPreferences';

import {
  user_register_through_invite,
} from '../../../api/urls';

import {
  parseJwt,
  setLoader,
  isFormValid,
  verifyPassword,
  getQueryParams,
  stateChangeManager,
} from '../../../utils/Utils';

import {
  setToken,
  setLoginStatus,
  setUserDetails,
  setBusinessInfo,
} from '../../../config/cookiesInfo';

import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const CreateInvitedUser = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const params = getQueryParams(location.search);
  const dispatch = Actions.getDispatch(React.useContext);
  const [parseJwtData, setParseJwtData] = React.useState(false);

  React.useEffect(() => {
    if (params.token) {
      setParseJwtData(parseJwt(params.token))
    }
    // eslint-disable-next-line
  }, []);

  const sign_up_initialData = {
    email: null,
    token: null,
    new_password: null,
    confirm_password: null,
  }

  const [isFormSubmitted, setFormSubmitted] = React.useState(false);
  const [data, setData] = React.useState({ ...sign_up_initialData });

  React.useEffect(() => {
    if (parseJwtData) {
      setData({
        ...data,
        ...parseJwtData,
        token: params.token,
        email: parseJwtData.email,
      })
    }
    // eslint-disable-next-line
  }, [parseJwtData]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormSubmitted(true);

    let validation_data = [
      { key: "new_password", message: 'New password field can not be empty' },
      { key: "confirm_password", message: 'Confirm password field can not be empty' },
      { key: '', validation: (!data.email || !validator.isEmail(data.email)), message: 'Please enter valid email.' },
      { key: "", validation: data.new_password !== data.confirm_password, message: 'New password and confirm password does not match' },
      { key: "", validation: !verifyPassword(data.new_password), message: 'Use 8 or more characters with a mix of letters, finycs & symbols.' },

    ]
    
    const { isValid, message } = isFormValid(data, validation_data)
    if (isValid) {
      setLoader(dispatch, Actions, true)
      let res = await apiAction({
        method: 'post',
        url: user_register_through_invite(),
        data: { ...data, password: data.new_password,name: data.first_name + ' ' + data.last_name },
      })
      if (res?.success) {
        let business = res?.business.find((item) => item.id === data.business_id);
        if (business) {
          setLoginStatus('true');
          setBusinessInfo(business);
          setUserDetails(res?.data.user);
          setToken(res?.data?.access_token);
          navigate(routesName.dashboard.path);
          getUserPreferenceData(navigate, dispatch);
          getBusinessPreferenceData(business.id,navigate, dispatch);
        }
        setLoader(dispatch, Actions, false);
        stateChangeManager(dispatch, Actions, true, 'success', res?.status);

      } else {
        setLoader(dispatch, Actions, false);
        stateChangeManager(dispatch, Actions, true, 'error', res?.status);
      }
    } else {
      setLoader(dispatch, Actions, false);
      stateChangeManager(dispatch, Actions, true, 'error', message);
    }
  };

  const onAccept = async () => {
    let res = await apiAction_social({
      method: 'post',
      url: user_register_through_invite(),
      data: { ...data, password: data.new_password },
    })
    if (res?.success) {
      setLoader(dispatch, Actions, false);
      navigate("/auth" + routesName.signIn.path)
      stateChangeManager(dispatch, Actions, true, 'success', res?.status);
    } else {
      setLoader(dispatch, Actions, false);
      stateChangeManager(dispatch, Actions, true, 'error', res?.status);
    }
  }

  const [isShow, setIsShow] = React.useState({
    is_password: false,
    is_confirmed_password: false
  })
  const onPasswordVisibility = (data) => {
    if (data === 'is_password') {
      isShow.is_password = !isShow.is_password;
    } else {
      isShow.is_confirmed_password = !isShow.is_confirmed_password;
    }
    setIsShow({ ...isShow })
  }


  return (
    parseJwtData ? parseJwtData.is_user_exists ?
      <AlreadyHaveAccount data={data} onAccept={onAccept} />
      :
      <Box sx={{ display: "flex" }}>
        <Box className='m_login_left_box' sx={{ backgroundColor: '#F7F7F7', minHeight: '100%', width: '28%' }}>
          <Box
            sx={{
              pt: 8,
              minHeight: "100vh",
              minWidth: '100vh',
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                p: 2,
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: "center",
              }}
            >
              {/* <Img sx={{ pt: 1.5, ml: 30 }} alt={images.signup.alt} src={images.signup.src} /> */}
            </Box>
          </Box>

        </Box>
        <Box sx={{ height: '100%', width: '100%' }}>
          <Box
            sx={{
              pt: 8,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                p: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: "center",
                flexDirection: 'column',
              }}>
              <Box component="form" onSubmit={handleSubmit} noValidate>
                {
                  params.token &&
                  <Box>
                    <CustomTypography
                      text={<span>You've been invited by {data.business_name} <br />to use the finycs.</span>}
                      sx={{
                        color: '#000000',
                        fontSize: '20px',
                        textAlign: 'start',
                        fontWeight: '700',
                        fontStyle: 'normal',
                        lineHeight: '40.86px',
                        fontFamily: "Noto Sans",
                      }}
                    />
                    <Box sx={{ mt: 3, }}>
                      <Input
                        id='input_email'
                        dataTestId='input_email'
                        item={{
                          type: 'text',
                          disabled: true,
                          title: 'Email',
                          required: true,
                          fullWidth: true,
                          placeholder: 'Enter Email',
                          value: data.email ? data.email : '',
                          validation: isFormSubmitted && !data.email,
                          onChange: (e) => setData({ ...data, email: e.target.value })
                        }}
                      />
                    </Box>

                    <Box sx={{ mt: 3, }}>
                      <Input
                        id='input_new_password'
                        dataTestId='input_new_password'
                        item={{
                          required: true,
                          fullWidth: true,
                          title: 'Password',
                          placeholder: 'Enter Password',
                          type: isShow.is_password ? 'text' : 'password',
                          value: data.new_password ? data.new_password : '',
                          validation: isFormSubmitted && !verifyPassword(data.new_password),
                          onChange: (e) => setData({ ...data, new_password: e.target.value })
                        }}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">
                            <Divider sx={{ height: 20, m: 0.5 }} orientation="vertical" />
                            <IconButton edge="end" size='small' sx={{ ml: .5, color: '#2464EB', cursor: 'pointer' }}
                              onClick={() => { onPasswordVisibility('is_password') }} id='show_password_icon' dataTestId='show_password_icon'>
                              {
                                isShow.is_password ?
                                  <VisibilityIcon fontSize='small' />
                                  :
                                  <VisibilityOffIcon fontSize='small' />
                              }
                            </IconButton>
                          </InputAdornment>

                        }}
                      />
                    </Box>
                    <Box sx={{ mt: 2, }}>
                      <Input
                        id='input_confirm_password'
                        dataTestId='input_confirm_password'
                        item={{
                          required: true,
                          fullWidth: true,
                          title: 'Confirm Password',
                          placeholder: 'Enter Confirm Password',
                          type: isShow.is_confirmed_password ? 'text' : 'password',
                          value: data.confirm_password ? data.confirm_password : '',
                          validation: isFormSubmitted && !verifyPassword(data.confirm_password),
                          onChange: (e) => setData({ ...data, confirm_password: e.target.value }),
                        }}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">
                            <Divider sx={{ height: 20, m: 0.5 }} orientation="vertical" />
                            <IconButton edge="end" size='small' sx={{ ml: .5, color: '#2464EB', cursor: 'pointer' }}
                              onClick={() => { onPasswordVisibility('is_confirmed_password') }} id='show_confirmed_password_icon' dataTestId='show_confirmed_password_icon'>
                              {
                                isShow.is_confirmed_password ?
                                  <VisibilityIcon fontSize='small' />
                                  :
                                  <VisibilityOffIcon fontSize='small' />
                              }
                            </IconButton>
                          </InputAdornment>

                        }}
                      />
                    </Box>
                  </Box>
                }


                <Button
                  fullWidth
                  type="submit"
                  id='clicked_btn'
                  variant="contained"
                  dataTestId='clicked_btn'
                  sx={{ mt: 4, mb: 2, textTransform: 'none' }}>{`Continue`}</Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      : null
  )
}

export default CreateInvitedUser;

const AlreadyHaveAccount = (props) => {
  const { data, onAccept } = props;
  const navigate = useNavigate();

  return (
    <Box sx={{ display: "flex", justifyContent: "center", pt: 8, }}>
      <Box >
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
          <Box
            style={{
              display: 'flex',
              borderRadius: '6px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {/* <IconButton disableTouchRipple sx={{ cursor: 'default', height: 60, width: 60, backgroundColor: '#000000', }}>
              <CustomTypography
                sx={{
                  fontWeight: 700,
                  fontSize: '40px',
                  color: '#ffffff',
                  boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
                }}
                text={''}
              />
            </IconButton> */}
            {/* <Box style={{ width: '100%' }}>
              <CustomTypography
                sx={{ paddingTop: 0.4, fontSize: '40px', paddingLeft: 1, fontWeight: 600, color: '#000000' }}
                text={'Finycs'}
              />
              <CustomTypography
                text={'Accounting Software'}
                sx={{ paddingLeft: 1, fontWeight: 400, color: '#000000', paddingTop: 1.5, fontSize: '20px', opacity: 0.6, lineHeight: '12px' }}
              />


            </Box> */}
          </Box>
        </Box>
        <Box sx={{ mt: 4, p: 4 }}>
          <CustomTypography
            text={'Finycs Invitation'}
            sx={{
              mb: 3,
              color: '#000000',
              fontSize: '30px',
              fontWeight: '700',
              textAlign: 'center',
              fontStyle: 'normal',
              lineHeight: '40.86px',
              fontFamily: "Noto Sans",
            }}
          />
          <CustomTypography
            text={<span>
              You have been invited to join {data.business_name} as a collaborator.
              Accept this invitation to access our business on your finycs account and collaborate.
            </span>}
            sx={{
              opacity: 0.6,
              color: '#000000',
              fontSize: '20px',
              textAlign: 'start',
              fontWeight: '700',
              fontStyle: 'normal',
              lineHeight: '40.86px',
              fontFamily: "Noto Sans",
            }}
          />

          <Box sx={{ display: 'flex', justifyContent: "center", textAlign: 'center' }}>
            <Button

              id='clicked_btn'
              variant="contained"
              dataTestId='clicked_btn'
              onClick={() => onAccept()}
              sx={{ mt: 4, mb: 2, textTransform: 'none' }}>{`Accept`}</Button>
            <Button
              variant="outlined"
              id='clicked_btn_cancel'
              dataTestId='clicked_btn_cancel'
              onClick={() => { window.close() }}
              sx={{ ml: 2, mt: 4, mb: 2, textTransform: 'none' }}>{`Cancel`}</Button>
          </Box>
          {/* <h1>If you choose to accept, you will be redirected to the login page.</h1> */}
        </Box>
      </Box>
    </Box>
  )
}