import React from 'react';
import AsyncDropdown from '../../../common/AsyncDropdown';
import { getBusinessInfo } from '../../../../config/cookiesInfo';
import { get_list_of_parent_account_new } from '../../../../api/urls';

export const AsyncParentAccountDropdown = ({
    sx,
    title = '',
    addButton,
    selectedValue,
    setSelectedValue,

    disabled = false,
    fullWidth = true,
    autoFocus = false,
    validation = false,

    disabledCloseIcon = false,

    isGroupHeaderSticky = true,

    id = 'parent_account_',
    dataTestId = 'parent_account_',

    searchKey = 'name',
    valueLabelKey = 'id',
    uniqueOptionKey = 'id',
    optionLabelKey = 'name',
    optionGroupLabelKey = 'account_type',
    placeholder = 'Select parent account',

    URL = get_list_of_parent_account_new(1),
    nextUrlAdditionData = `&business_id=${getBusinessInfo()?.id}`,
}) => {
    return (
        <AsyncDropdown
            sx={sx}
            id={id}
            URL={URL}
            METHOD='get'
            title={title}
            addButton={addButton}
            searchKey={searchKey}
            fullWidth={fullWidth}
            isRefreshOption={true}
            autoFocus={autoFocus}
            validation={validation}
            dataTestId={dataTestId}
            inputDisabled={disabled}
            placeholder={placeholder}
            valueLabelKey={valueLabelKey}
            selectedValue={selectedValue}
            optionLabelKey={optionLabelKey}
            uniqueOptionKey={uniqueOptionKey}
            setSelectedValue={setSelectedValue}
            disabledCloseIcon={disabledCloseIcon}
            nextUrlAdditionData={nextUrlAdditionData}
            isGroupHeaderSticky={isGroupHeaderSticky}
            optionGroupLabelKey={optionGroupLabelKey}
        />
    );
};