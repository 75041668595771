import React from 'react';
import { useNavigate } from 'react-router-dom';
import { apiAction } from '../../../../../../api/api';
import * as Actions from '../../../../../../state/Actions';

import {
    setLoader,
    stateChangeManager,
} from '../../../../../../utils/Utils';

import {
    get_user_preference,
    update_user_preference,
    get_business_preference,
    update_business_preference,
} from '../../../../../../api/urls';
import {
    getBusinessInfo,
    setUserPreferences,
    getUserPreferences,
    getBusinessPreferences,
    setBusinessPreferences,
} from '../../../../../../config/cookiesInfo';

import GeneralPreferences from '../GeneralPreferences';
import TermsAndConditionsPreferences from '../TermsAndConditionsPreferences';

import NotesPreferences from '../NotesPreferences';
import BankDetails from '../Sales/bank/BankDetails';
import ItemPreferences from '../Item/ItemPreferences';
import VendorPreferences from '../Purchase/VendorPreferences';
import CustomerPreferences from '../Sales/CustomerPreferences';


const PreferencesWrapper = (props) => {
    const { selectedTab } = props;

    const [results, setResults] = React.useState()
    let navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);

    React.useEffect(() => {
        getApiResults();
    }, [])

    const getApiResults = async () => {
        let res = await apiAction({
            method: 'get',
            navigate: navigate,
            dispatch: dispatch,
            url: get_business_preference(getBusinessInfo().id),
        })
        if (res?.success) {
            setResults(res?.result)
            setBusinessPreferences(res?.result);
        } else {

        }
    }

    const onSaveHandler = async (data) => {
        setLoader(dispatch, Actions, true);
        let res = await apiAction({
            method: 'post',
            navigate: navigate,
            dispatch: dispatch,
            url: update_business_preference(),
            data: { ...data, business_id: getBusinessInfo().id, },
        })
        if (res?.success) {
            setResults(res?.result)
            setBusinessPreferences(res?.result);
            setLoader(dispatch, Actions, false);
            stateChangeManager(dispatch, Actions, true, 'success', res?.status);
        } else {
            setLoader(dispatch, Actions, false)
            stateChangeManager(dispatch, Actions, true, 'error', res?.status)
        }
    }

    return (
        results&&
        <div>
            {selectedTab === 'general_preference' && <GeneralPreferences initial_data={results} onSaveHandler={onSaveHandler} />}

            {selectedTab === 'items_preference' && <ItemPreferences initial_data={results} onSaveHandler={onSaveHandler} />}

            {selectedTab === 'customers_preference' && <CustomerPreferences initial_data={results} onSaveHandler={onSaveHandler} />}

            {selectedTab === 'bank_accounts_preference' && <BankDetails initial_data={results} onSaveHandler={onSaveHandler} />}

            {selectedTab === 'purchase_notes_preference' && <NotesPreferences initial_data={results} onSaveHandler={onSaveHandler} type={'purchase'} />}
            {selectedTab === 'purchase_terms_condition_preference' && <TermsAndConditionsPreferences initial_data={results} onSaveHandler={onSaveHandler} type={'purchase'} />}

            {selectedTab === 'vendors_preference' && <VendorPreferences initial_data={results} onSaveHandler={onSaveHandler} />}

            {selectedTab === 'sales_notes_preference' && <NotesPreferences initial_data={results} onSaveHandler={onSaveHandler} type={'sales'} />}
            {selectedTab === 'sales_terms_condition_preference' && <TermsAndConditionsPreferences initial_data={results} onSaveHandler={onSaveHandler} type={'sales'} />}

        </div>
    )
}

export default PreferencesWrapper;