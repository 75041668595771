import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as Actions from '../../../../state/Actions';
import { generate_bulk_barcode } from '../../../../api/urls';
import CustomButton from '../../../custom/button/CustomButton';
import { getBusinessInfo } from '../../../../config/cookiesInfo';
import CustomTypography from '../../../custom/typography/CustomTypography';
import { eventsNames, AnalyticsEvent } from '../../../../firebase/firebaseAnalytics';

import {
  apiAction,
  apiBlobResponse,
  apiHandleDownload,

} from '../../../../api/api';

import {
  setLoader,
  stateChangeManager,
} from '../../../../utils/Utils';

import {
  BootstrapDialog,
  BootstrapDialogTitle,
} from '../../bulkStatementUpload/mappings/Transactions';

import {
  DialogContent
} from '@mui/material';
import moment from 'moment';


const PreviewBarcode = ({ maxWidth = 'lg', title = 'title', open, onClose, onSave, previewData, isAlert = false, }) => {
  return (
    <BootstrapDialog
      fullWidth
      open={open}
      maxWidth={maxWidth}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={() => onClose()}>
        <CustomTypography
          text={title}
          sx={{
            color: '#000000',
            fontSize: '16px',
            fontWeight: '700',
            lineHeight: '18px',
            fontStyle: 'normal',
            fontFamily: "Noto Sans",
          }}
        />
      </BootstrapDialogTitle>
      <DialogContent dividers>
        {previewData && <PreviewComponent open={open} previewData={previewData} onClose={onClose} isGenerate={title === 'Generate'} />}
        {isAlert && <AlertMessage onClose={onClose} onSave={onSave} />}
      </DialogContent>
    </BootstrapDialog>
  )
}

export default PreviewBarcode

const AlertMessage = ({ onClose, onSave }) => {
  return (
    <div>
      <CustomTypography
        text={'you can add only one item while using the Label Printing. Do you want to replace the item added already ?'}
        sx={{
          fontSize: '14px',
          color: '#000000',
          fontWeight: '700',
          lineHeight: '22px',
          fontStyle: 'normal',
          textTransform: "none",
          fontFamily: "Noto Sans",
        }}
      />
      <div style={{ textAlign: 'end', marginTop: '24px' }}>
        <CustomButton
          btnLabel='Cancel'
          variant='outlined'
          onClick={() => { onClose() }}
        />
        <CustomButton
          btnLabel='Yes'
          variant='contained'
          onClick={() => { onSave() }}
          sx={{ ml: 2, textTransform: 'none', }}
        />
      </div>
    </div>
  )
}

const PreviewComponent = ({ previewData, onClose, open, isGenerate }) => {
  const navigate = useNavigate();
  const [htmlString, setHtmlString] = React.useState('');
  const dispatch = Actions.getDispatch(React.useContext);


  const getApiResults = async () => {
    setLoader(dispatch, Actions, true);
    try {
      const res = await apiAction({
        dispatch,
        navigate,
        method: 'POST',
        url: generate_bulk_barcode(),
        data: { ...previewData, business_id: getBusinessInfo().id },
      });

      if (res?.success) {
        setLoader(dispatch, Actions, false);
        setHtmlString(res.result.html_string);
        if (isGenerate) {
          stateChangeManager(dispatch, Actions, true, 'success', 'Barcode generated successfully');
        }
      } else {
        setLoader(dispatch, Actions, false);
        stateChangeManager(dispatch, Actions, true, 'error', res?.status);
      }
    } catch (error) {
      setLoader(dispatch, Actions, false);
      console.error('Error fetching API results:', error);
    }
  };

  const print = async () => {
    setLoader(dispatch, Actions, true);
    if (previewData) {
      apiBlobResponse({
        url: generate_bulk_barcode(), data: { ...previewData, business_id: getBusinessInfo().id, export_type: "pdf" }, onSuccess: () => {
          setLoader(dispatch, Actions, false);
        }
      })
    }
  }

  const download = async () => {
    setLoader(dispatch, Actions, true);
    if (previewData) {
      apiHandleDownload({
        url: generate_bulk_barcode(), data: { ...previewData, business_id: getBusinessInfo().id, export_type: "pdf" }, filename: `${previewData.size}-[${moment().format('DD-MM-YYYY,h:mm:ss a')}]`, onSuccess: () => {
          onClose();
          setLoader(dispatch, Actions, false);
          // stateChangeManager(dispatch, Actions, true, 'success', 'Barcode downloaded successfully');
        }
      })
    }
  }

  React.useEffect(() => {
    if (previewData && open) {
      getApiResults()
    }
  }, [open])

  return (
    <div >
      <div id="pdf-iframe">
        <iframe
          srcDoc={htmlString}
          style={{ width: '794px', height: 'calc(100vh - 240px)', border: 'none', overflow: 'hidden' }}
        />
      </div>
      {isGenerate &&
        <div style={{ textAlign: 'end', marginTop: '24px' }}>
          <CustomButton
            variant='outlined'
            btnLabel='Download'
            id={'generate_barcode_download_btn'}
            dataTestId={'generate_barcode_download_btn'}

            onClick={() => {
              download();
              AnalyticsEvent(eventsNames.categories.BARCODE_GENERATOR, { action: eventsNames.actions.DOWNLOAD });
            }}

          />
          
          <CustomButton
            sx={{ ml: 2 }}
            btnLabel='Print'
            variant='contained'
            id={'generate_barcode_print_btn'}
            dataTestId={'generate_barcode_print_btn'}

            onClick={() => {
              print();
              AnalyticsEvent(eventsNames.categories.BARCODE_GENERATOR, { action: eventsNames.actions.DOWNLOAD });
            }}

          />
        </div>
      }
    </div>
  )
}