import * as React from 'react';
import CustomInput from "../input/CustomInput";
import CustomButton from '../button/CustomButton';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, Autocomplete, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomTypography from '../typography/CustomTypography';

const CustomDropdown = (props) => {
    const {
        id,
        sx,
        item,
        value,
        results,
        onFocus,
        onChange,
        setValue,
        inputRef,
        className,
        dataTestId,
        addButton,
        autoFocus,
        isLastGroup,
        autoSelect,
        InputProps,
        validation,
        isChildren,
        firstletter,
        placeholder,
        renderInput,
        optionLabel,
        size = "small",
        inputDisabled,
        forcePopupIcon,
        disableClearable,
        isShowEndAdornment = false,
        isfilterOptions = 'startsWith',
    } = props

    const options = Array.isArray(results) ? results.map((option) => {
        const firstletterOptions = option.parent_account_name ? option.parent_account_name
            : option.account_type ? option.account_type
                : option.parent_account ? option.parent_account : ''
        return {
            firstletter: firstletter ? firstletter : firstletterOptions,
            ...option,
        };
    }) : [];


    const onChangeInputHandler = (newValue) => {
        if (setValue) {
            setValue(newValue)
        }
    }

    const optionsLastElementIndex = options.length - 1
    const PaperComponentCustom = options => {
        const { key, group, containerProps, children } = options;
        let isLastGroupElement = optionsLastElementIndex === key;

        // console.log('===>options',options)
        // console.log('===>isChildren',isChildren)
        // console.log('===>isLastGroupElement',isLastGroupElement)
        return (
            <React.Fragment key={isChildren ? children : key}>

                {isLastGroup && isLastGroupElement && addButton ? (
                    <>
                        <CustomButton
                            fullWidth
                            variant='text'
                            id={id + '_add_new_btn'}
                            btnLabel={<b>+ Add new</b>}
                            onClick={addButton.onClick}
                            dataTestId={dataTestId + '_add_new_btn'}
                            onMouseDown={(event) => { event.preventDefault(); }}
                            sx={{ mt: -1, textTransform: 'none', '&:hover': { backgroundColor: '#e8f3ff' } }}
                        />
                        <Divider />
                    </>
                ) : null
                }
                <Box {...containerProps} sx={{
                    mb: -1,
                    margin: '0px',
                    color: '#141414',
                    fontWeight: 400,
                    fontSize: '12px',
                    background: '#FFFF',
                    lineHeight: '16px',
                    fontStyle: 'normal',
                    fontFamily: 'Noto Sans',
                }}>
                    {!isLastGroup && addButton ? (
                        <>
                            <CustomButton
                                fullWidth
                                variant='text'
                                id={id + '_add_new_btn'}
                                btnLabel={<b>+ Add new </b>}
                                onClick={addButton.onClick}
                                dataTestId={dataTestId + '_add_new_btn'}
                                onMouseDown={(event) => { event.preventDefault(); }}
                                sx={{ mt: -1, textTransform: 'none', '&:hover': { backgroundColor: '#e8f3ff' } }}
                            />
                            <Divider sx={{}} />
                        </>
                    ) : null
                    }
                    {group &&
                        <CustomTypography
                            text={group}
                            sx={{
                                pb: 1,
                                pt: 1,
                                pl: 1,
                                color: '#141414',
                                fontWeight: 700,
                                fontSize: '12px',
                                lineHeight: '16px',
                                fontStyle: 'normal',
                                fontFamily: 'Noto Sans',
                            }}
                        />
                    }
                    {children}
                </Box>
            </React.Fragment>
        );
    };

    const NoOptionsComponentCustom = () => {
        return (
            <>
                <CustomTypography
                    text={'No results found'}
                    sx={{
                        mb: 2,
                        color: '#141414',
                        fontWeight: 500,
                        fontSize: '12px',
                        lineHeight: '16px',
                        fontStyle: 'normal',
                        fontFamily: 'Noto Sans',
                    }}
                />
                {addButton ? (
                    <div style={{ marginLeft: '-18px', marginRight: '-18px' }}>
                        <Divider />
                        <CustomButton
                            fullWidth
                            variant='text'
                            id={id + '_add_new_btn'}
                            btnLabel={<b>+ Add new</b>}
                            onClick={addButton.onClick}
                            dataTestId={dataTestId + '_add_new_btn'}
                            onMouseDown={(event) => { event.preventDefault(); }}
                            sx={{ mb: -1.7, textTransform: 'none', '&:hover': { backgroundColor: '#e8f3ff' } }}
                        />
                    </div>
                ) : null
                }
            </>
        )
    }

    const filterOptions = (options, { inputValue }) => {
        let filter;
        if (isfilterOptions === 'startsWith') {
            // console.log('===>startsWith',isfilterOptions)
            return filter = options.filter(option => getOptionLabel(option)?.toLowerCase().startsWith(inputValue?.toLowerCase()));
        }
        if (isfilterOptions === 'includes') {
            // console.log('===>includes',isfilterOptions)
            return filter = options.filter(option => getOptionLabel(option)?.toLowerCase().includes(inputValue?.toLowerCase()));
        }
        return filter
    }

    const getOptionLabel = (option) => {
        return (typeof (option) === "string" ? option : option[optionLabel] ? option[optionLabel] : option.display_name ? option.display_name : option.account_name ? option.account_name : option.currency_code ? option.currency_code : option.name ? option.name : value ? value : option)
    }

    return (
        <Autocomplete
            size={size}
            value={value}
            disablePortal
            sx={{ ...sx, }}
            onFocus={onFocus}
            onChange={onChange}
            autoHighlight={true}
            className={className}
            dataTestId={dataTestId}
            disabled={inputDisabled}
            placeholder={placeholder}
            filterOptions={filterOptions}
            popupIcon={<ExpandMoreIcon />}
            forcePopupIcon={forcePopupIcon}
            disableClearable={disableClearable}
            groupBy={(option) => option.firstletter}
            clearIcon={<ClearIcon fontSize='small' />}
            autoSelect={!autoSelect ? autoSelect : true}
            noOptionsText={<NoOptionsComponentCustom />}
            getOptionLabel={(option) => getOptionLabel(option)}
            renderGroup={(option) => <PaperComponentCustom {...option} />}
            getOptionDisabled={option => option.disabled ? option.disabled : null}
            id={id ? id : placeholder ? placeholder?.toLowerCase()?.replace(/ /g, "_") : "-"}
            isOptionEqualToValue={(option, value) => getOptionLabel(option) === getOptionLabel(value)}
            options={props.options ? props.options : options.sort((a, b) => -b.firstletter.localeCompare(a.firstletter))}
            renderInput={renderInput ? renderInput : (params) => <CustomInput inputRef={inputRef} sx={sx} isShowEndAdornment={isShowEndAdornment} validation={validation} InputProps={InputProps} fullWidth {...params} {...item} autoFocus={autoFocus} placeholder={placeholder} value={value ? value.name : ''} onChange={(e) => { onChangeInputHandler(e.target.value) }} />}
        />
    );
}


export default CustomDropdown