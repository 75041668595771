import React from 'react';
import moment from 'moment';
import ReportsSettingButtons from '../ReportsSettingButtons';
import { apiAction } from "../../../../api/api";
import ReportsTitle from '../common/ReportsTitle';
import * as Actions from '../../../../state/Actions';
import CustomDialog from '../../../custom/dialog/CustomDialog';
import { getBusinessInfo } from '../../../../config/cookiesInfo';
import CurrencyFormatter from '../../../common/CurrencyFormatter';
import { get_account_transactions_report } from '../../../../api/urls';
import CommonCustomDateRange from '../../../common/CommonCustomDateRange';
import CustomPagination from '../../../custom/pagination/CustomPagination';
import CustomTypography from '../../../custom/typography/CustomTypography';
import { CustomContainer } from '../../../custom/container/CustomContainer';

import {
    setLoader,
    date_range,
    getDateFormat,
    convertSlugToString,
} from '../../../../utils/Utils';

import {
    Box,
    Grid,
} from '@mui/material';

import {
    CustomTable,
    CustomTableRow,
    CustomTableBody,
    CustomTableHead,
    CustomTableHeadCell,
    CustomTableBodyCell,
    CustomTableContainer,
} from '../../../custom/table/CustomTable';

const AccountTransactions = () => {
    const stateAction = Actions.getState(React.useContext);
    const dispatch = Actions.getDispatch(React.useContext);

    const { selectedDateRange } = stateAction;
    let filter_date_range = date_range().find((item) => item.name === selectedDateRange)
    const default_date_range = filter_date_range ? filter_date_range : date_range()[2]

    const [dateRange, setDateRange] = React.useState(default_date_range)
    const [fromDate, setFromDate] = React.useState(moment())
    const [toDate, setToDate] = React.useState(moment())
    const [results, setResults] = React.useState()
    const [date, setDate] = React.useState()

    const [page, setPage] = React.useState(1);
    const [pagination, setPagination] = React.useState({
        next: undefined,
        count: undefined,
        previous: undefined,
        number_of_pages: undefined,
    });

    const [state, setState] = React.useState({
        id: '',
        title: '',
        open: false,
        condition: '',
        maxWidth: 'lg',
        fullWidth: true,
    });

    React.useEffect(() => {
        if (dateRange) {
            if (dateRange.name !== "Custom") {
                getApiResults(dateRange.dates.from_date, dateRange.dates.to_date, dateRange.dates.date_range)
            } else {
                getApiResults(fromDate.format('YYYY-MM-DD'), toDate.format('YYYY-MM-DD'), `${fromDate.format(getDateFormat())} - ${toDate.format(getDateFormat())}`)
            }
        }
        // eslint-disable-next-line
    }, [dateRange, toDate, fromDate, page]);

    const getApiResults = async (from_date, to_date, range_date) => {
        setLoader(dispatch, Actions, true)
        setDate(range_date)
        let res = await apiAction({
            method: 'post',
            url: get_account_transactions_report(page),
            data: {
                business_id: getBusinessInfo().id,
                from_date: from_date,
                to_date: to_date
            },
        })

        if (res?.success) {
            let result = res?.result.result.pagination_entries
            setPagination({
                ...pagination,
                next: result.next,
                count: result.count,
                previous: result.previous,
                number_of_pages: result.number_of_pages,
            })

            setResults(result.result)
            setLoader(dispatch, Actions, false);
        } else {
            setLoader(dispatch, Actions, false);
        }
    }

    const viewHandler = (id) => {
        setState({
            id: id,
            open: true,
            maxWidth: 'sm',
            fullWidth: true,
            condition: 'view',
            title: 'View Journal Entry',
        })
    }
    return (
        results &&
        <div>
            <CustomDialog
                state={state}
                setState={setState}
            />
            <ReportsSettingButtons

                scheduleData={{
                    report_name: 'account_transactions',
                    report_title: 'Account Transactions',

                }}
                exportData={{ url: get_account_transactions_report(), data: dateRange.dates ? dateRange.dates : { to_date: toDate.format('YYYY-MM-DD'), from_date: fromDate.format('YYYY-MM-DD') } }}
            />
            <CustomContainer maxWidth={"400"} sx={{}}>
                <div style={{ paddingLeft: '16px', paddingRight: '16px' }}>
                    <Grid container spacing={0} sx={{ pb: 2 }} style={{
                        alignItems: 'center',
                        justifyItems: 'center',
                        alignContent: 'space-evenly',
                        justifyContent: 'space-evenly',
                    }}>
                        <Grid item xs={12} sm={4}>
                            <ReportsTitle
                                date={date}
                                title={'Account Transactions'}
                            />
                        </Grid>
                        <Grid container spacing={1} item xs={12} sm={8} sx={{ justifyContent: 'end', }}>
                            <CommonCustomDateRange
                                setPage={setPage}
                                date_range={date_range()}

                                toDate={toDate}
                                setToDate={setToDate}

                                fromDate={fromDate}
                                setFromDate={setFromDate}

                                dateRange={dateRange}
                                setDateRange={setDateRange}
                            />
                        </Grid>
                    </Grid>
                </div>
                <CustomTableContainer>
                    <CustomTable>
                        <Header />
                        <Body data={results} viewHandler={viewHandler} />
                    </CustomTable>
                </CustomTableContainer>
            </CustomContainer>
            <CustomPagination
                page={page}
                count={pagination.number_of_pages}
                onChange={(event, newValue) => setPage(newValue)}
            />
        </div>
    )
}

export default AccountTransactions;

const Header = () => {
    return (
        <CustomTableHead>
            <CustomTableRow >
                <CustomTableHeadCell><span>Date</span></CustomTableHeadCell>
                <CustomTableHeadCell><span>Account</span></CustomTableHeadCell>
                <CustomTableHeadCell><span>Transaction Details</span></CustomTableHeadCell>
                <CustomTableHeadCell><span>Transaction Type</span></CustomTableHeadCell>
                <CustomTableHeadCell align='right'><span>Debit</span></CustomTableHeadCell>
                <CustomTableHeadCell align='right'><span>Credit</span></CustomTableHeadCell>
            </CustomTableRow>
        </CustomTableHead>
    )
}

const Body = (props) => {
    const { data, viewHandler } = props;
    return (
        <CustomTableBody>
            {data.map((item, index) => {
                return (
                    <CustomTableRow key={index}>
                        <CustomTableBodyCell><span>{moment(item.transaction_date).format(getDateFormat())}</span></CustomTableBodyCell>
                        <CustomTableBodyCell><span>{item.account_name}</span></CustomTableBodyCell>
                        <CustomTableBodyCell>
                            <CustomTypography
                                id={'viewHandler_' + index}
                                dataTestId={'viewHandler_' + index}

                                text={item.description}
                                onClick={() => viewHandler(item.journal_entry_id)}
                                sx={{
                                    fontSize: '12px',
                                    fontWeight: 500,
                                    display: 'inline',
                                    color: '#2464EB',
                                    cursor: 'pointer',
                                    lineHeight: '16px',
                                    fontStyle: 'normal',
                                    textDecoration: 'none',
                                    fontFamily: 'Noto Sans',
                                    ":hover": { textDecoration: 'underline' },
                                }}
                            /></CustomTableBodyCell>
                        <CustomTableBodyCell><span>{convertSlugToString(item.transaction_source, '_').capitalize()}</span></CustomTableBodyCell>
                        <CustomTableBodyCell align='right'>
                            <CustomTypography
                                text={item.transaction_type === "DEBIT" ? <CurrencyFormatter amount={item.amount} currency={null} /> : '-'}
                                sx={{
                                    color: '#000000',
                                    fontSize: '12px',
                                    fontWeight: '700',
                                    lineHeight: '14px',
                                    fontStyle: 'normal',
                                    fontFamily: 'Noto Sans',
                                }}
                            />
                        </CustomTableBodyCell>
                        <CustomTableBodyCell align='right'>
                            <CustomTypography
                                text={item.transaction_type === "CREDIT" ? <CurrencyFormatter amount={item.amount} currency={null} /> : '-'}
                                sx={{
                                    color: '#000000',
                                    fontSize: '12px',
                                    fontWeight: '700',
                                    lineHeight: '14px',
                                    fontStyle: 'normal',
                                    fontFamily: 'Noto Sans',
                                }}
                            />
                        </CustomTableBodyCell>
                    </CustomTableRow>
                )
            })}

            {/* <CustomTableRow sx={{
                pb: 1,
                pt: 2,
                background: '#FFF7DC',
                borderTop: '1px solid #FFF0C0',
                borderBottom: '1px solid #FFF0C0'
            }}>
                <CustomTableBodyCell><span>Total</span></CustomTableBodyCell>
                <CustomTableBodyCell align='right'><span><CurrencyFormatter amount={total.all_income} currency={null} /></span></CustomTableBodyCell>
                <CustomTableBodyCell align='right'><span><CurrencyFormatter amount={total.paid_income} currency={null} /></span></CustomTableBodyCell>
            </CustomTableRow> */}
        </CustomTableBody>
    )
}
