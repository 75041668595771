import * as React from 'react';
import validator from 'validator';
import SocialLogin from './SocialLogin';
import { apiAction } from '../../../api/api';
import { userSignIn } from '../../../api/urls';
import * as Actions from '../../../state/Actions';
import { routesName } from '../../../config/routesName';
import CustomInput from '../../custom/input/CustomInput';
import { useNavigate, useLocation } from 'react-router-dom';
import CustomTypography from '../../custom/typography/CustomTypography';
import { eventsNames, AnalyticsEvent } from '../../../firebase/firebaseAnalytics';

import {
  isFormValid,
  getQueryParams,
  stateChangeManager,
} from '../../../utils/Utils';

import {
  getUserPreferenceData,
  getBusinessPreferenceData,
} from '../setting/settings/Preferences/common/getPreferences';

import {
  setToken,
  setLoginStatus,
  setUserDetails,
  setBusinessInfo,
} from '../../../config/cookiesInfo';

import {
  Box,
  Link,
  Grid,
  Button,
  styled,
  Divider,
  Checkbox,
  Typography,
  IconButton,
  InputAdornment,
} from '@mui/material';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link id='copy_right_link' dataTestId='copy_right_link' color="inherit" href="https://numbers.artdexandcognoscis.com/">
        www.numbers.artdexandcognoscis.com
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '98%',
  maxHeight: '98%',
  alignItems: 'center',

});

export default function Login() {
  const navigate = useNavigate();

  const location = useLocation();
  const [agree, setAgree] = React.useState(true);
  const params = getQueryParams(location.search);
  const dispatch = Actions.getDispatch(React.useContext);



  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    let formData = {
      email: data.get("email"),
      password: data.get("password")
    }
    let validation_data = [
      { key: "email", message: 'Email field can not be empty' },
      { key: "password", message: 'Password field can not be empty' },
      { key: '', validation: (!formData.email || !validator.isEmail(formData.email)), message: 'Please enter valid email.' },
      { key: "", validation: !agree, message: "Please accept the terms and conditions." },
    ]
    const { isValid, message } = isFormValid(formData, validation_data)

    // validate the data
    if (isValid) {
      AnalyticsEvent(eventsNames.categories.USER_AUTHENTICATION, { action: eventsNames.actions.SIGN_IN, method: eventsNames.actions.userAuthentication.method.EMAIL });
      //Api call
      let res = await apiAction({
        method: 'post',
        url: userSignIn(),
        data: { ...formData, is_privacy_policy_and_terms_accepted: agree }
      })
      if (res?.success) {
        setUserDetails(res?.data.user);
        setToken(res?.data?.access_token);
        stateChangeManager(dispatch, Actions, true, 'success', res?.status);
        if (res?.business) {
          setLoginStatus('true');
          getUserPreferenceData(navigate, dispatch);
          let businessData = res?.business.find((item) => item.is_default);
          setBusinessInfo(businessData ? businessData : res?.business[0]);
          getBusinessPreferenceData(businessData ? businessData.id : res?.business[0].id, navigate, dispatch)

          navigate(routesName.dashboard.path);
        } else {
          navigate("/auth" + routesName.businessCreate.path)
        }

      } else {

        // stateChangeManager is a alert function which show the error message
        stateChangeManager(dispatch, Actions, true, 'error', res?.status)
      }
    }
    else {
      stateChangeManager(dispatch, Actions, true, 'error', message);
    }
  };
  const [isShow, setIsShow] = React.useState({
    is_password: false,
    is_confirmed_password: false
  })
  const onPasswordVisibility = (data) => {
    if (data === 'is_password') {
      isShow.is_password = !isShow.is_password;
    } else {
      isShow.is_confirmed_password = !isShow.is_confirmed_password;
    }
    setIsShow({ ...isShow })
  }

  return (
    params.code ?
      <SocialLogin
        AnalyticsEvent={() => { AnalyticsEvent(eventsNames.categories.USER_AUTHENTICATION, { action: eventsNames.actions.SIGN_IN, method: eventsNames.actions.userAuthentication.method.GOOGLE }); }}
      />
      :
      <div>
        {/* <SelectBusiness
        open={open}
        results={results}
        setOpen={setOpen}
      /> */}
        <Box sx={{ display: "flex" }}>
          <Box className='m_login_left_box' sx={{ backgroundColor: '#F7F7F7', minHeight: '100%', width: '28%', display: 'block' }}>
            <Box
              sx={{
                pt: 8,
                minHeight: "100vh",
                minWidth: '100vh',
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  p: 2,
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  justifyContent: "center",
                }}
              >
                {/* <Img sx={{pt:1.5, ml: 20 }} alt={images.login.alt} src={images.login.src} /> */}
              </Box>
            </Box>

          </Box>
          <Box sx={{ height: '100%', width: '100%' }}>
            <Box
              className='m_login_container'
              sx={{
                pt: 8,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  p: 2,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: "center",
                  flexDirection: 'column',
                }}>
                <Box component="form" onSubmit={handleSubmit} noValidate>
                  <CustomTypography
                    text={'Account Login'}
                    sx={{
                      mb: 4,
                      color: '#000000',
                      fontSize: '30px',
                      textAlign: 'start',
                      fontWeight: '700',
                      fontStyle: 'normal',
                      lineHeight: '40.86px',
                      fontFamily: "Noto Sans",
                    }}
                  />
                  <CustomTypography
                    text={'Email Address'}
                    sx={{
                      mb: 1,
                      color: '#000000',
                      fontSize: '14px',
                      fontWeight: '700',
                      lineHeight: '18px',
                      fontStyle: 'normal',
                      fontFamily: "Noto Sans",
                      textTransform: 'capitalize',
                    }}
                  />
                  <CustomInput
                    required
                    fullWidth
                    id="email"
                    name="email"
                    autoFocus={true}
                    dataTestId="email"
                    autoComplete="email"
                  />
                  <CustomTypography
                    text={'Password'}
                    sx={{
                      mt: 2,
                      mb: 1,
                      color: '#000000',
                      fontSize: '14px',
                      fontWeight: '700',
                      lineHeight: '18px',
                      fontStyle: 'normal',
                      fontFamily: "Noto Sans",
                      textTransform: 'capitalize',
                    }}
                  />
                  <CustomInput
                    required
                    fullWidth
                    id="password"
                    name="password"
                    dataTestId="password"
                    autoComplete="current-password"
                    type={isShow.is_password ? 'text' : "password"}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">
                        <Divider sx={{ height: 20, m: 0.5 }} orientation="vertical" />
                        <IconButton edge="end" size='small' sx={{ ml: .5, color: '#2464EB', cursor: 'pointer' }}
                          onClick={() => { onPasswordVisibility('is_password') }} id={'show_password_icon'} dataTestId={'show_password_icon'}>
                          {
                            isShow.is_password ?
                              <VisibilityIcon fontSize='small' />
                              :
                              <VisibilityOffIcon fontSize='small' />
                          }
                        </IconButton>
                      </InputAdornment>

                    }}
                  />

                  <CustomTypography
                    text={<span><Checkbox size="small" sx={{ ml: -1.5, mt: -.01 }} checked={agree} onClick={() => { setAgree(!agree) }} /><span>By sign in, I agree to the finycs <Link href={routesName.privacyPolicy.path} variant="body2" target="_blank" rel="noreferrer">Privacy Policy</Link> and <Link href={routesName.termsAndServices.path} variant="body2" target="_blank" rel="noreferrer">Terms and Services</Link>.</span></span>}
                    sx={{
                      mb: 2,
                      mt: 1,
                      color: '#000000',
                      fontSize: '14px',
                      textAlign: 'start',
                      fontWeight: '500',
                      fontStyle: 'normal',
                      lineHeight: '40.86px',
                      fontFamily: "Noto Sans",
                    }}
                  />
                  <Button
                    fullWidth
                    type="submit"
                    id='sign_in_btn'
                    variant="contained"
                    dataTestId='sign_in_btn'
                    sx={{ mt: 3, mb: 3, textTransform: 'capitalize' }}
                  >Sign In</Button>

                  <Divider>
                    <CustomTypography
                      text={'OR'}
                      sx={{
                        color: '#000000',
                        fontSize: '14px',
                        fontWeight: '700',
                        lineHeight: '18px',
                        fontStyle: 'normal',
                        fontFamily: "Noto Sans",
                        textTransform: 'capitalize',
                      }}
                    />
                  </Divider>

                  <SocialLogin
                    AnalyticsEvent={() => { AnalyticsEvent(eventsNames.categories.USER_AUTHENTICATION, { action: eventsNames.actions.SIGN_IN, method: eventsNames.actions.userAuthentication.method.GOOGLE }); }}
                  />


                  <Grid container spacing={2} sx={{ pt: 3 }}>
                    <Grid item xs>
                      <Link id='forgot_password_link' dataTestId='forgot_password_link' href={"/auth" + routesName.restPassword.path} variant="body2">
                        Forgot password?
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link id='sign_up_link' dataTestId='sign_up_link' href={"/auth" + routesName.signUp.path} variant="body2">
                        {"Don't have an account? Sign up"}
                      </Link>
                    </Grid>
                  </Grid>
                  {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
                </Box>

              </Box>
            </Box>
          </Box>
        </Box>
      </div>

  );
}

