import React from 'react';
import moment from 'moment/moment';
import Refund from '../../refund/Refund';
import * as Actions from '../../../../../state/Actions';
import { useNavigate, useLocation } from 'react-router-dom';
import { getQueryParams } from '../../../../../utils/Utils';
import { routesName } from '../../../../../config/routesName';
import { getBusinessInfo } from '../../../../../config/cookiesInfo';
import { eventsNames,AnalyticsEvent } from '../../../../../firebase/firebaseAnalytics';

import {
  apiAction,
} from '../../../../../api/api';

import {
  setLoader,
} from '../../../../../utils/Utils';

import {
  retrieve_payment,
} from '../../../../../api/urls';

const RefundPaymentReceived = () => {
    let location = useLocation();
    const navigate = useNavigate();
    const params = getQueryParams(location.search)
    const dispatch = Actions.getDispatch(React.useContext);
  
  
    const [basicInformation, setBasicInformation] = React.useState();
  
    React.useEffect(() => {
        const retrievePayment = async (payment_id) => {
          setLoader(dispatch, Actions, true);
          let res = await apiAction({
            method: 'post',
            url: retrieve_payment(payment_id),
            data: { business_id: getBusinessInfo().id },
          })
          if (res?.success) {
            setBasicInformation(res?.result);
            setLoader(dispatch, Actions, false);
          }
        }
      if (params.payment_id) {
        retrievePayment(params.payment_id)
      }
      // eslint-disable-next-line
    }, [])
  return (
    basicInformation &&
    <div>
           <Refund
          initial_refund_data={{
            note: null,
            account_id: null,
            payment_type: "pay",
            debit_note_id: null,
            credit_note_id: null,
            selected_account: null,
            payment_method: "cash",
            payment_id:basicInformation.id,
            business_id: getBusinessInfo().id,
            party_id: basicInformation.party_id,
            refund_date: moment().format('YYYY-MM-DD'),

            currency_id:basicInformation.currency_id,
            exchange_rate:basicInformation.exchange_rate,
            currency_code: basicInformation.currency_code,
            
            credit_remaining: basicInformation.balance_amount,
            total_refund_amount: basicInformation.balance_amount,
          }}

          title={`Payment Received #`+basicInformation.id}
          redirect={() => navigate(location.state ? location.state : routesName.invoicingPaymentsReceivedView.path + "?payment_id=" + basicInformation.id)}
          AnalyticsEvent={(params)=>{AnalyticsEvent(eventsNames.categories.PAYMENTS_RECEIVED,params)}}

        />
    </div>
  )
}

export default RefundPaymentReceived