import React from 'react';
import CustomTypography from '../typography/CustomTypography';

import {
    Box,
    Grid,
    Divider,
    Collapse,
} from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';



const VerticalTabs = (props) => {
    const { id, dataTestId, tabs, value, onChange, selectedTab, setSelectedTab } = props

    const [collapse, setCollapse] = React.useState(false)
    return (
        <Box>
            {
                tabs.map((item, index) => {
                    return (
                        <React.Fragment key={index}>
                            {item.child && item.child.length ?
                                <React.Fragment>
                                    <CollapseContentData
                                        id={id}
                                        data={item}
                                        index={index}
                                        value={value}
                                        dataTestId={dataTestId}
                                        selectedTab={selectedTab}
                                        collapse={index === collapse}
                                        onChange={(value) => onChange(value)}
                                        setCollapse={(value) => setCollapse(value)}
                                        setSelectedTab={(value) => setSelectedTab(index,value)}
                                    />
                                    <Divider
                                        sx={{
                                            height: '1px',
                                            // marginLeft: 2,
                                            // marginRight: 2,
                                            border: "none",
                                            bgcolor: '#F5F5F5',
                                        }}
                                    />
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <Box sx={{ display: 'flex', paddingTop: 2, paddingBottom: 2 }}>
                                        <Box sx={{ backgroundColor: '#2464EB', width: 5, height: 18, marginLeft: 0.2, borderRadius: 6, visibility: value === index ? "visible" : "hidden" }}></Box>
                                        <CustomTypography
                                            id={id + index}
                                            dataTestId={dataTestId + index}
                                            text={item.name ? item.name : ''}
                                            onClick={() => { onChange(index); setSelectedTab(index,item.condition);setCollapse(false); }}
                                            sx={{
                                                paddingLeft: 1,
                                                paddingTop: 0.3,
                                                fontSize: '14px',
                                                cursor: 'pointer',
                                                lineHeight: '12px',
                                                whiteSpace: "nowrap",
                                                fontWeight: value === index ? 700 : 500,
                                                color: value === index ? '#2464EB' : '#141414',
                                                '&:hover': {
                                                    opacity: 1,
                                                    color: '#2464EB',
                                                    fontSize: '14px',
                                                },
                                            }}
                                        />
                                    </Box>
                                    <Divider
                                        sx={{
                                            height: '1px',
                                            border: "none",
                                            bgcolor: '#F5F5F5',
                                        }}
                                    />
                                </React.Fragment>
                            }

                        </React.Fragment>
                    )
                })
            }
        </Box>

    )
}

export default VerticalTabs;

const CollapseContentData = (props) => {
    const { id, data, index, value, dataTestId, selectedTab, collapse, setSelectedTab, setCollapse, onChange } = props;
    const onCollapseHandler = () => {
        setCollapse(collapse ? false : index)
    }

    const onClickedCollapseHandler = (condition) => {
        onChange(index);
        setSelectedTab(condition);
    }

    const active_collapse = data.child && data.child.find((item) => item.condition === selectedTab)
    React.useEffect(() => {
        if (active_collapse) {
            setCollapse(index);
        }
    }, [active_collapse])
    return (
        <React.Fragment key={index} >
            <Box sx={{ display: 'flex', paddingLeft: 0.5, paddingTop: 2, paddingBottom: 1.5 }}>
                {/* <Box sx={{ backgroundColor: '#2464EB', width: 5, height: 20, marginLeft: 0.2, borderRadius: 6, visibility: value === index ? "visible" : "hidden" }}></Box> */}
                <React.Fragment >
                    <Grid container sx={{ paddingLeft: 1, }} onClick={() => { onCollapseHandler(); }} >
                        <Grid item xs={6} sx={{ textAlign: 'start', alignItems: 'center' }}>
                            <CustomTypography
                                id={id + index}
                                dataTestId={dataTestId + index}
                                text={data.name ? data.name : ''}
                                onClick={() => { onCollapseHandler(); }}
                                sx={{
                                    paddingTop: 0.3,
                                    fontSize: '14px',
                                    cursor: 'pointer',
                                    lineHeight: '12px',
                                    whiteSpace: "nowrap",
                                    fontWeight: value === index ? 700 : 500,
                                    color: value === index ? '#2464EB' : '#141414',
                                    '&:hover': {
                                        opacity: 1,
                                        color: '#2464EB',
                                        fontSize: '14px',
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} sx={{ paddingRight: 2, textAlign: 'end', alignItems: 'center' }}>
                            <CustomTypography
                                id={id + index}
                                dataTestId={dataTestId + index}
                                text={
                                    collapse ?
                                        <ArrowDropUpIcon fontSize='small' sx={{ cursor: 'pointer' }} onClick={() => onCollapseHandler()} />
                                        :
                                        <ArrowDropDownIcon fontSize='small' sx={{ cursor: 'pointer' }} onClick={() => onCollapseHandler()} />
                                }
                                onClick={() => { onCollapseHandler(); }}
                                sx={{
                                    fontSize: '12px',
                                    cursor: 'pointer',
                                    lineHeight: '12px',
                                    letterSpacing: '0.5px',
                                    fontWeight: value === index ? 700 : 800,
                                    color: value === index ? '#2464EB' : '#A3A3A3',
                                }}
                            />
                        </Grid>
                    </Grid>
                </React.Fragment>
            </Box>
            <Collapse in={collapse} sx={{ pb: collapse ? 2 : 0.2 }}>
                {
                    data.child && data.child.map((childRoute, index) => {
                        return (
                            <React.Fragment key={index}>
                                {childRoute.disabled ?
                                    <Box sx={{ mt: 1, }}>
                                        <Divider
                                            sx={{
                                                height: '1px',
                                                border: "none",
                                                bgcolor: '#F5F5F5',
                                            }}
                                        />
                                        <Box sx={{ display: 'flex', paddingLeft: 0.5, paddingTop: 1, paddingBottom: 0.5, }}>
                                            <CustomTypography
                                                text={childRoute.name}
                                                sx={{
                                                    color: '#939393',
                                                    paddingTop: 0.4,
                                                    paddingLeft: 1,
                                                    fontSize: '12px',
                                                    lineHeight: '12px',
                                                    paddingBottom: 0.3,
                                                    whiteSpace: "nowrap",
                                                    letterSpacing: '0.5px',
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                    :
                                    <Box sx={{ display: 'flex', paddingLeft: 0.5, paddingTop: 1, paddingBottom: 0.5, }}>
                                        <Box sx={{ backgroundColor: '#2464EB', width: 5, height: 18, marginLeft: 0.2, borderRadius: 6, visibility: selectedTab === childRoute.condition ? "visible" : "hidden" }}> </Box>
                                        <CustomTypography
                                            id={id + index}
                                            dataTestId={dataTestId + index}
                                            text={childRoute.name}
                                            onClick={() => { onClickedCollapseHandler(childRoute.condition) }}
                                            sx={{
                                                paddingLeft: 2,
                                                paddingTop: 0.3,
                                                fontSize: '14px',
                                                cursor: 'pointer',
                                                lineHeight: '12px',
                                                whiteSpace: "nowrap",
                                                fontWeight: selectedTab === childRoute.condition ? 700 : 500,
                                                color: selectedTab === childRoute.condition ? '#2464EB' : '#141414',
                                                '&:hover': {
                                                    opacity: 1,
                                                    color: '#2464EB',
                                                    fontSize: '14px',
                                                },
                                            }}
                                        >

                                        </CustomTypography>
                                    </Box>
                                }
                            </React.Fragment>

                        )
                    })
                }
            </Collapse>
        </React.Fragment>
    )
}

