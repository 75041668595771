
import * as React from 'react';
import { apiAction } from '../../api/api';
import * as Actions from '../../state/Actions';
import { useNavigate } from 'react-router-dom';
import CustomInput from '../custom/input/CustomInput';
import CustomButton from '../custom/button/CustomButton';
import { getUniqueObjectsByKey } from '../../utils/Utils';
import { getBusinessInfo } from '../../config/cookiesInfo';
import { get_chart_of_account_nested_nodes } from '../../api/urls';
import CustomTypography from '../custom/typography/CustomTypography';
import { useIsElementVisible } from '../pages/home/insight/common/Helper';

import {
    styled,
    Divider,
    Autocomplete
} from '@mui/material';

import ClearIcon from "@mui/icons-material/Clear";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const AsyncDropdown = (props) => {
    const {
        sx,
        id,
        title,
        className,
        autoFocus,
        addButton,
        fullWidth,
        dataTestId,
        validation,
        newResults,
        InputProps,
        selectedValue,
        maxHeight = 300,
        getInputValue,
        forcePopupIcon,
        setSelectedValue,
        isShowEndAdornment,
        fetchTermLimit = 1,
        isRefreshOption=false,
        inputDisabled = false,
        isTitleVisibility = true,
        disabledCloseIcon = false,
        isGroupHeaderSticky = true,

        size = 'small',
        getOptionDisabled,
        searchKey = 'name',
        uniqueOptionKey = 'id',
        valueLabelKey = 'name',
        optionLabelKey = 'name',
        titleVisibility = 'visible',
        optionGroupLabelKey = 'name',
        placeholder = 'Select Account',

        playLoad,
        METHOD = 'post',
        nextUrlAdditionData,
        URL = get_chart_of_account_nested_nodes(1),
    } = props

    const navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);

    const [results, setResults] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [loadMore, setLoadMore] = React.useState(false);

    const [nextUrl, setNextUrl] = React.useState();
    const [hasMore, setHasMore] = React.useState(false);

    const fetchOptions = async (url = URL, apiBody, previousResult = results) => {
        setLoading(true);
        let res = await apiAction({
            url: url,
            method: METHOD,
            navigate: navigate,
            dispatch: dispatch,
            data: { ...apiBody, ...playLoad, business_id: getBusinessInfo().id },

        })
        if (res?.success) {
            setLoading(false);
            setLoadMore(false);
            if (METHOD === 'get' && res?.result.next) {
                setNextUrl(res?.result.next + nextUrlAdditionData);
            } else {
                setNextUrl(res?.result.next);
            }
            setHasMore(!!res?.result.next);
            const uniqueOptions = [
                ...previousResult,
                ...res?.result.result.filter((item) =>
                    !previousResult.some((option) => option[uniqueOptionKey] === item[uniqueOptionKey])
                )
            ];
            setResults([...getUniqueObjectsByKey([...uniqueOptions])])
        } else {
            setLoading(false);
            console.log('===>Failed API Call !!')
        }
    }

    const onChangeInputHandler = (text = '') => {
        if (text.length % fetchTermLimit === 0) {
            let apiBody = text && text !== '' ? { [searchKey]: text } : {}
            if (METHOD === 'get') {
                fetchOptions(URL + `&${searchKey}=${text}`, apiBody, []);
            } else {
                fetchOptions(URL, apiBody, []);
            }
        }

        if (getInputValue) {
            getInputValue(text)
        }

    }

    React.useEffect(() => {
        if (newResults) {
            const uniqueOptions = [
                ...results,
                ...[newResults].filter((item) =>
                    !results.some((option) => option[uniqueOptionKey] === item[uniqueOptionKey])
                )
            ];
            setResults([...getUniqueObjectsByKey([...uniqueOptions])])
        }
    }, [newResults]);

    const lastNodeRef = React.useRef();
    const isElementVisible = useIsElementVisible(lastNodeRef.current);

    React.useEffect(() => {
        if (isElementVisible && loadMore && hasMore) {
            fetchOptions(nextUrl);
        }
    }, [loadMore, isElementVisible])

    const getOptionEqualToValue = (option, value) => {
        return option[valueLabelKey] === value[valueLabelKey] || option[optionLabelKey] === value[optionLabelKey] || option[optionLabelKey] === value[valueLabelKey]
    }

    const onOpenHandler = () => {
        if (isRefreshOption || results.length < 10) {
            fetchOptions(URL, {}, []);
        }
    };
    return (
        <React.Fragment>
            {isTitleVisibility &&
                <CustomTypography
                    text={title}

                    sx={{
                        mb: 1,
                        width: '100%',
                        color: '#000000',
                        fontSize: '14px',
                        fontWeight: '700',
                        lineHeight: '18px',
                        fontStyle: 'normal',
                        textTransform: 'none',
                        fontFamily: "Noto Sans",
                        visibility: titleVisibility,

                    }}
                />}
            <Autocomplete
                id={id}
                size={size}
                loading={loading}
                options={results}
                autoHighlight={true}
                className={className}
                fullWidth={fullWidth}
                dataTestId={dataTestId}
                disabled={inputDisabled}
                placeholder={placeholder}

                popupIcon={<ExpandMoreIcon />}
                forcePopupIcon={forcePopupIcon}
                disableClearable={disabledCloseIcon}
                onOpen={() => { onOpenHandler()}}
                clearIcon={<ClearIcon fontSize='small' />}
                noOptionsText={<NoOptionsComponentCustom {...props} />}

                value={selectedValue ? selectedValue : null}
                groupBy={(option) => option[optionGroupLabelKey]}
                getOptionValue={(option) => option[valueLabelKey]}
                getOptionLabel={(option) => option[optionLabelKey]}
                isOptionEqualToValue={(option, value) => getOptionEqualToValue(option, value)}
                getOptionDisabled={(option) => (getOptionDisabled ? getOptionDisabled(option) : null)}

                sx={{ ...sx, background: inputDisabled ? '#F1F3F4' : '' }}

                onChange={(event, selected) => { setSelectedValue(selected); }}

                renderGroup={(params) => {
                    return (
                        <React.Fragment key={params.key}>
                            {params.key === 0 && addButton ? (
                                <CustomButton
                                    fullWidth
                                    variant='text'
                                    id={id + '_add_new_btn'}
                                    onClick={addButton.onClick}
                                    dataTestId={dataTestId + '_add_new_btn'}
                                    btnLabel={<b>{addButton.title?addButton.title:'+ Add new'}</b>}
                                    sx={{
                                        top: '-8px',
                                        zIndex: 1000,
                                        position: 'sticky',
                                        marginTop: '-8px',
                                        marginBottom: '6px',
                                        background: '#FFFFFF',
                                        borderBottomLeftRadius: '0px',
                                        borderBottomRightRadius: '0px',
                                        '&:hover': { backgroundColor: '#e8f3ff' },
                                        boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset',
                                    }}
                                    onMouseDown={(event) => { event.preventDefault(); }}
                                />
                            ) : null
                            }
                            <li key={params.key} style={{
                                fontWeight: 400,
                                fontSize: '12px',
                                marginTop: '0px',
                                color: '#141414',
                                lineHeight: '16px',
                                fontStyle: 'normal',
                                background: '#FFFFFF',
                                fontFamily: 'Noto Sans',
                            }}>
                                <GroupHeader
                                    sx={{
                                        top: addButton ? '30px' : '-8px',
                                        position: isGroupHeaderSticky ? 'sticky' : '',
                                    }}
                                >{params.group}</GroupHeader>
                                <GroupItems >{params.children}</GroupItems>
                            </li>
                        </React.Fragment>
                    )
                }}

                ListboxProps={{
                    style: { overflowY: 'auto', overflowX: 'hidden', maxHeight: maxHeight },
                    onScroll: (event) => {
                        const listboxNode = event.currentTarget;
                        const options = listboxNode.querySelectorAll('[role="option"]');
                        lastNodeRef.current = options[options.length - 3];
                        const lastOptionText = options[options.length - 3]?.textContent;

                        let lastItem = results.find((item) => item[optionLabelKey] === lastOptionText)
                        if (!loadMore && lastItem && hasMore) {
                            setLoadMore(true);
                        }
                    },
                }}

                renderInput={(params) => (<CustomInput sx={{ ...sx, background: inputDisabled ? '#F1F3F4' : '' }} isSearchableComponent={true} isShowEndAdornment={isShowEndAdornment} validation={validation} InputProps={InputProps} fullWidth   {...params} autoFocus={autoFocus} placeholder={placeholder} value={selectedValue ? selectedValue : ''} onChange={(e) => { onChangeInputHandler(e.target.value) }} />)}
            />
        </React.Fragment>
    )
}

export default AsyncDropdown;

const NoOptionsComponentCustom = (props) => {
    const { id, dataTestId, noOptionsText = 'No results found', addButton } = props;
    return (
        <React.Fragment>
            <CustomTypography
                text={noOptionsText}
                sx={{
                    mb: 2,
                    color: '#141414',
                    fontWeight: 500,
                    fontSize: '12px',
                    lineHeight: '16px',
                    fontStyle: 'normal',
                    fontFamily: 'Noto Sans',
                }}
            />
            {addButton ? (
                <div style={{
                    margin: '-16px',
                    marginTop: '0px',
                }}>
                    <Divider />
                    <CustomButton
                        fullWidth
                        variant='text'
                        id={id + '_add_new_btn'}
                        onClick={addButton.onClick}
                        dataTestId={dataTestId + '_add_new_btn'}
                        onMouseDown={(event) => { event.preventDefault(); }}
                        btnLabel={<b>{addButton.title?addButton.title:'+ Add new'}</b>}
                        sx={{
                            '&:hover': { backgroundColor: '#e8f3ff' },
                        }}
                    />
                </div>
            ) : null
            }
        </React.Fragment>
    )
}

const GroupHeader = styled('div')(({ theme }) => ({
    fontWeight: 700,
    color: '#141414',
    fontSize: '12px',
    lineHeight: '16px',
    fontStyle: 'normal',
    padding: '4px 10px',
    background: '#FFFFFF',
    fontFamily: 'Noto Sans',
}));

const GroupItems = styled('ul')({
    padding: 0,
    paddingTop: 1,
    fontWeight: 400,
    fontSize: '12px',
    color: '#141414',
    paddingBottom: 1,
    lineHeight: '16px',
    fontStyle: 'normal',
    background: '#FFFFFF',
    fontFamily: 'Noto Sans',
});