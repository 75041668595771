import React from 'react';
import { apiAction } from '../../../../api/api';
import DateRange from './../../../common/DateRange';
import { getBusinessInfo } from '../../../../config/cookiesInfo';
import CustomTypography from '../../../custom/typography/CustomTypography';

import {
    get_chart_of_account,
} from '../../../../api/urls';

import {
    Box,
    Grid,
    Radio,
    RadioGroup,
    FormControlLabel,
} from '@mui/material';
const Dropdown = DateRange;

const PaymentTDSAndTCS = (props) => {
    const { is_tds_applicable, set_is_tds_applicable, set_default_tds_account_id, tds_account_id, set_tds_account_id } = props;

    const [results, setResults] = React.useState([]);

    React.useEffect(() => {
        const getData = async () => {
            let res = await apiAction({
                method: 'post',
                url: get_chart_of_account(),
                data: {
                    // account_type: "ASSET",
                    business_id: getBusinessInfo().id,
                    parent_account_name: 'Other Current Assets'
                },

            })
            if (res?.success) {
                if (!tds_account_id) {
                    set_default_tds_account_id(res?.result.find((item) => item.account_name === "TDS Receivable"))
                }
                setResults(res?.result);
            } else {
            }
        }
        if (is_tds_applicable) {
            getData();
        }
    }, [is_tds_applicable]);

    return (
        <Box sx={{ mt: 2.5, minHeight: 100, alignItems: 'center', display: 'flex' }}>
            <Grid container spacing={5} sx={{ alignItems: 'center' }}>
                <Grid item sx={{ mb: -3, display: 'flex', alignItems: 'center' }} xs={12} sm={6}>
                    <CustomTypography
                        text={'Tax deducted?'}
                        sx={{
                            mr: 2,
                            color: '#000000',
                            display: 'inline',
                            fontSize: '14px',
                            textAlign: 'start',
                            fontWeight: '700',
                            lineHeight: '18px',
                            fontStyle: 'normal',
                            fontFamily: "Noto Sans",
                        }}
                    />
                    <RadioGroup row sx={{ marginBottom: 0 }}>
                        <FormControlLabel
                            id={'no_radio_btn'}
                            dataTestId={'no_radio_btn'}
                            value={'no'}
                            label={<span style={{
                                color: '#000000',
                                fontSize: '14px',
                                display: 'inline',
                                lineHeight: '18px',
                                fontStyle: 'normal',
                                fontFamily: "Noto Sans",
                                fontWeight: !is_tds_applicable ? '600' : '400',
                            }}>No Tax deducted</span>} control={<Radio size='small' sx={{ color: '#8F8F8F' }}
                                checked={!is_tds_applicable}
                                onChange={(e) => { set_is_tds_applicable(e.target.value === 'yes') }}

                            />} />
                        <FormControlLabel
                            id={'yes_radio_btn'}
                            dataTestId={'yes_radio_btn'}

                            value={'yes'}
                            label={<span style={{
                                color: '#000000',
                                fontSize: '14px',
                                lineHeight: '18px',
                                display: 'inline',
                                fontStyle: 'normal',
                                fontFamily: "Noto Sans",
                                fontWeight: is_tds_applicable ? '600' : '400',
                            }}>Yes, TDS (Income Tax)</span>} control={<Radio size='small' sx={{ color: '#8F8F8F' }}
                                checked={is_tds_applicable}
                                onChange={(e) => { set_is_tds_applicable(e.target.value === 'yes'); }}
                            />}

                        />
                    </RadioGroup>
                </Grid>
                {is_tds_applicable &&
                    <Grid item xs={12} sm={6} sx={{}}>
                        <Dropdown
                            results={results}
                            forcePopupIcon={true}
                            disableClearable={false}
                            label={'TDS Tax Account'}
                            id={'tds_account_dropdown'}
                            placeholder='Select Tax'
                            dataTestId={'tds_account_dropdown'}
                            setValue={(event, value) => { set_tds_account_id(value) }}
                            value={tds_account_id ? results.find((item) => item.id === tds_account_id) : null}
                        />
                    </Grid>
                }
            </Grid>
        </Box>
    )
}

export default PaymentTDSAndTCS