import React from 'react';
import PropTypes from 'prop-types';
import CustomButton from '../../../../custom/button/CustomButton';
import CustomTypography from '../../../../custom/typography/CustomTypography';

import {
    Radio,
    Dialog,
    RadioGroup,
    DialogTitle,
    FormControl,
    DialogContent,
    DialogActions,
    FormControlLabel,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const DeliveryChallanAlertBox = (props) => {
    const { open, setOpen, onSaveAction } = props
    const [type, setType] = React.useState('original');
    const handleClicked = () => {
        setOpen(false);
        onSaveAction(type);
    };

    return (
        <div>
            <BootstrapDialog
                open={open}
                maxWidth={'sm'}
                fullWidth={true}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={() => setOpen(false)}>
                    <CustomTypography
                        text={'Number of Copies'}
                        sx={{
                            color: '#000000',
                            fontSize: '16px',
                            fontWeight: '700',
                            lineHeight: '18px',
                            fontStyle: 'normal',
                            fontFamily: "Noto Sans",
                        }}
                    />
                </BootstrapDialogTitle>
                <DialogContent dividers>

                    <FormControl >
                        <RadioGroup >
                            <FormControlLabel value="original" control={<Radio size="small" checked={type === 'original'} onChange={(e) => { setType(e.target.value) }} />}
                                label={
                                    <span style={{
                                        color: '#000000',
                                        fontSize: '14px',
                                        display: 'inline',
                                        lineHeight: '18px',
                                        fontStyle: 'normal',
                                        fontFamily: "Noto Sans",
                                        fontWeight: type === 'original' ? '600' : '400',
                                    }}>One Copy</span>
                                }
                            />
                            <CustomTypography
                                text={'An original copy will be printed.'}
                                sx={{
                                    pb: 2,
                                    pl: 3.5,
                                    opacity:0.6,
                                    color: '#000000',
                                    fontSize: '12px',
                                    fontWeight: '700',
                                    lineHeight: '18px',
                                    fontStyle: 'normal',
                                    fontFamily: "Noto Sans",
                                }} />
                            <FormControlLabel value="duplicate" control={<Radio size="small" checked={type === 'duplicate'} onChange={(e) => { setType(e.target.value) }} />}
                                label={
                                    <span style={{
                                        color: '#000000',
                                        fontSize: '14px',
                                        display: 'inline',
                                        lineHeight: '18px',
                                        fontStyle: 'normal',
                                        fontFamily: "Noto Sans",
                                        fontWeight: type === 'duplicate' ? '600' : '400',
                                    }}>Two Copies</span>
                                }
                            />
                            <CustomTypography
                                text={'A supplier copy and a recipient copy will be printed.'}
                                sx={{
                                    pb: 2,
                                    pl: 3.5,
                                    opacity:0.6,
                                    color: '#000000',
                                    fontSize: '12px',
                                    fontWeight: '700',
                                    lineHeight: '18px',
                                    fontStyle: 'normal',
                                    fontFamily: "Noto Sans",
                                }} />
                            <FormControlLabel value="triplicate" control={<Radio size="small" checked={type === 'triplicate'} onChange={(e) => { setType(e.target.value) }} />}
                                label={
                                    <span style={{
                                        color: '#000000',
                                        fontSize: '14px',
                                        display: 'inline',
                                        lineHeight: '18px',
                                        fontStyle: 'normal',
                                        fontFamily: "Noto Sans",
                                        fontWeight: type === 'triplicate' ? '600' : '400',
                                    }}>Three Copies</span>
                                }
                            />
                            <CustomTypography
                                text={'A supplier copy, a transporter copy, and a recipient copy will be printed.'}
                                sx={{
                                    pb: 2,
                                    pl: 3.5,
                                    opacity:0.6,
                                    color: '#000000',
                                    fontSize: '12px',
                                    fontWeight: '700',
                                    lineHeight: '18px',
                                    fontStyle: 'normal',
                                    fontFamily: "Noto Sans",
                                }} />
                        </RadioGroup>
                    </FormControl>

                    <div style={{ textAlign: 'center', marginTop: '24px' }}>
                        <CustomButton
                            btnLabel='Cancel'
                            variant='outlined'
                            onClick={() => setOpen(false)}
                            sx={{ textTransform: 'none', height: '35px', mr: 2, pl: 2, pr: 2, '&:hover': { backgroundColor: '#e8f3ff' } }}
                        />
                        <CustomButton
                            variant='contained'
                            btnLabel='Print'
                            onClick={() => handleClicked()}
                            sx={{ textTransform: 'none', height: '35px', mr: 2, pl: 2, pr: 2, }}
                        />
                    </div>
                    <DialogActions>

                    </DialogActions>
                </DialogContent>
            </BootstrapDialog>
        </div>
    )
}

export default DeliveryChallanAlertBox;


const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));