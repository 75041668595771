import React from "react";
import { customer } from "../../../../../../utils/Constant";
import { routesName } from "../../../../../../config/routesName";
import Mappings from "../../../../bulkStatementUpload/mappings/Mappings";


const CustomersMapping = (props) => {
    return(

        <Mappings 
        isDateFormat={false}
        mapping_for={ customer } 
        nextPagePath={routesName.invoicingSalesCustomersImportPreview.path} 
        onFileErrorPath={routesName.invoicingSalesCustomersImportFile.path}  
        />
        
    )
}

export default CustomersMapping