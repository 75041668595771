import React from 'react';

import {
  Box,
  TableSortLabel,
} from '@mui/material';

import ArrowDropUpSharpIcon from '@mui/icons-material/ArrowDropUpSharp';

export const HeadingWithSortable = (props) => {
  const { filters, setFilters, sortableKey, heading, remove_default_Sort_by } = props;

  const { sort_by = '', sort_order = '' } = filters;

  const onSort = (sort_by) => {
    if (remove_default_Sort_by) { remove_default_Sort_by() }
    if (filters.sort_by === sort_by) {
      filters.sort_by = sort_by;
      filters.sort_order = filters.sort_order === "D" ? "A" : "D";
    } else {
      filters.sort_by = sort_by;
    }
    setFilters({ ...filters });
  };

  let isActive = sort_by === sortableKey;
  const [isHovered, setIsHovered] = React.useState(false);
  return (
    // <Box
    //   onClick={() => onSort(sortableKey)}
    //   onMouseEnter={() => setIsHovered(true)}
    //   onMouseLeave={() => setIsHovered(false)}
    //   sx={{
    //     cursor: 'pointer',
    //     color: isActive ? '#292828' : '',
    //   }}>
    //   {heading}{' '}
    //   {isActive ?
    //     <span style={{ fontSize: '9px', }}>
    //       {sort_order === "A" ? '▲' : '▼'}
    //     </span>
    //     // : isHovered & !isActive ?
    //     //   <span style={{ fontSize: '9px',}}>
    //     //     {'▼'}
    //     //   </span>
    //     :
    //     null
    //   }
    // </Box>
    <TableSortLabel
      // hideSortIcon={true}
      active={sort_by === sortableKey}
      onClick={() => onSort(sortableKey)}
      // IconComponent={ArrowDropUpSharpIcon}
      direction={sort_order === "A" ? 'asc' : 'desc'}
      sx={{
        // flexDirection: sort_by === sortableKey ? 'row' : '',
        // '& .MuiTableSortLabel-icon': {
        //   margin:0,
        //   fontSize: 22,
        //   marginRight: -0.8,
        // },
      }}
    >
      <span style={{}}>{heading}</span>
    </TableSortLabel>
  );
};





