import moment from 'moment';
import {
    getAddress,
    isVendorRegister,
    setIsVendorRegister
} from '../../../../../utils/Utils';


export const getMailingAddress = (vendor) => {
    return vendor.billing_address ? getAddress(vendor.billing_address) : "";
};

export const getPaymentTerm = (vendor) => {
    return vendor.payment_term || {};
};

export const getCurrency = (vendor) => {
    return vendor.currency || {};
};

export const getDueDate = (paymentTerm, bill) => {
    return paymentTerm.number_of_days
        ? moment(bill.bill_date).add(paymentTerm.number_of_days, "days").format("YYYY-MM-DD")
        : bill.due_date;
};


export const vendorMappings = (vendor, bill, setBill) => {

    setIsVendorRegister(vendor?.gst_registration_type);
    let taxType = isVendorRegister ? 'exclusive' : 'no_tax'

    if (vendor) {
        const mailingAddress = getMailingAddress(vendor);
        const paymentTerm = getPaymentTerm(vendor);
        const currency = getCurrency(vendor);
        const dueDate = getDueDate(paymentTerm, bill);

        setBill(prev => ({
            ...prev,
            vendor: vendor,
            vendor_id: vendor.id,
            mailing_address: mailingAddress,
            payment_term: paymentTerm,
            payment_term_id: paymentTerm.id,
            due_date: dueDate,
            currency_id: currency.id,
            currency_symbol: currency.symbol,
            currency_code: currency.currency_code,
            payment_method: vendor.preferred_payment_method || "cash",

            source_of_supply_id: null,
            selected_source_of_supply: null,

            tax_applied: taxType,
            is_amount_tax_inclusive: taxType === 'inclusive',
        }));
    } else {
        setBill(prev => ({
            ...prev,
            vendor: null,
            vendor_id: null,
            payment_term: null,
            currency_code: null,
            currency_symbol: null,
            mailing_address: null,
            payment_term_id: null,
            payment_method: "cash",
            due_date: prev.due_date,

            tax_applied: taxType,
            is_amount_tax_inclusive: taxType === 'inclusive',
        }));
    }
};
