import React from 'react';
import CustomTypography from '../../../custom/typography/CustomTypography';
import { ReactComponent as CheckIcon } from '../../../../assets/svg/outline_check_icon.svg';


import {
    Box,
    Tab,
    Grid,
    styled,
} from '@mui/material';


const Features = () => {
    const [tab, setTab] = React.useState(0);

    return (

        <Box sx={{ pt: {xs:0,sm:3}, pb: 3 }}>
            <Grid container sx={{}}>
                <Grid item xs={12} sx={{ pb: {xs:4,sm:6}, pt: {xs:2,sm:6}, textAlign: "center" }} >
                    <CustomTypography
                        text={'Key features of Finycs'}
                        sx={{
                            color: '#141414',
                            fontWeight: 700,
                            lineHeight: '56px',
                            letterSpacing: '0px',
                            fontSize: { xs: '36px', sm: '40px' },
                        }}
                    />
                </Grid>

                <Grid container item xs={12} spacing={3}>
                    <Grid item xs={12} sm={4}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                width: '70%',
                                padding: '30px',
                                borderRadius: '8px',
                                backgroundColor: '#FFFFFF',
                                boxShadow: 'rgba(0, 0, 0, 0.08) 0px 4px 12px'

                            }}
                        >
                            <Box>
                                {
                                    tabs.map((item, index) => {
                                        return (
                                            <Box
                                                id={`tabs_${index}`}
                                                key={index}
                                                onClick={() => { setTab(index) }}
                                                sx={{
                                                    m: 1,
                                                    padding: '10px',
                                                    maxWidth: '160px',
                                                    maxHeight: '51px',
                                                    cursor: 'pointer',
                                                    whiteSpace: 'nowrap',
                                                    borderRadius: '30px',
                                                    color: tab === index ? '#FFFFFF' : '#141414',
                                                    backgroundColor: tab === index ? '#2464EB' : '#FFFFFF'
                                                }}
                                            >
                                                {item.name}
                                            </Box>
                                        )
                                    })
                                }
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={8}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}>
                        {tabs.map((item, index) => {
                            return (
                                tab === index && (
                                    <Box
                                        key={index}
                                        sx={{
                                            maxHeight: '662px',
                                            maxWeight: '364px',
                                        }}
                                    >
                                        <CommonFeatures {...tabsItems[item.condition]} />
                                    </Box>
                                )
                            )
                        })}
                    </Grid>
                </Grid>
            </Grid>

        </Box>
    )
}

export default Features;

const CommonFeatures = (props) => {
    const { title, headLine, description } = props
    return (

        <Box>
            <CustomTypography
                text={title}
                sx={{
                    fontWeight: 700,
                    color: '#141414',
                    fontSize: '32px',
                    lineHeight: '32px',
                    letterSpacing: '0px',
                }}
            />
            <CustomTypography
                text={headLine}
                sx={{
                    fontSize: '20px',
                    fontWeight: 400,
                    color: '#666666',
                    lineHeight: '32px',
                    fontStyle: 'normal',
                    letterSpacing: '0.2px',
                }}
            />
            <CustomTypography
                text={description}
                sx={{
                    fontSize: '20px',
                    fontWeight: 400,
                    color: '#666666',
                    lineHeight: '32px',
                    fontStyle: 'normal',
                    letterSpacing: '0.2px',
                }}
            />
        </Box>
    )
}

const style = {
    padding: '5px',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: 'auto',
    letterSpacing: '0px',
}
const tabs = [
    { name: <span style={{ ...style }}>Dashboard</span>, condition: `dashboard` },
    { name: <span style={{ ...style }}>Invoicing</span>, condition: `invoicing` },
    { name: <span style={{ ...style }}>Expenses</span>, condition: `expenses` },
    { name: <span style={{ ...style }}>Accounting</span>, condition: `accounting` },
    { name: <span style={{ ...style }}>Reports</span>, condition: `reports` },
    { name: <span style={{ ...style }}>GST</span>, condition: `gst` },
    { name: <span style={{ ...style }}>Inventory</span>, condition: `inventory` },
    { name: <span style={{ ...style }}>Tally Integration</span>, condition: `tallyIntegration` },
]

const tabsItems = {
    dashboard: {
        image: `landing_feature_dashboard`,
        title: `Dashboard & Simple UI`,
        description: <div>
            <p>Customize your dashboard to suit your unique needs and preferences with Finycs, a powerful tool that provides real-time data for a clear view of your business's health.</p>
            <ul className='custom-bullets'>
                <li><CheckIcon />Comprehensive Data Overview</li>
                <li><CheckIcon />Monitor Key Performance Indicators</li>
                <li><CheckIcon />User-Friendly Interface</li>
                <li><CheckIcon />Informed Decision-Making</li>
            </ul>
        </div>

    },
    invoicing: {
        image: `landing_feature_invoice`,
        title: `Invoicing`,
        description: <div>
            <p>Simplifies and streamlines your invoicing process for efficient and effective management.</p>
            <ul className='custom-bullets'>
                <li><CheckIcon />Professional and Customizable Invoices</li>
                <li><CheckIcon />Transaction Validation and Approval</li>
                <li><CheckIcon />Secure Invoice Sharing and Payment reminders</li>
                <li><CheckIcon />Recurring Invoices , Credit Notes</li>
                <li><CheckIcon />Multi-Currency Support</li>
            </ul>
        </div>

    },
    expenses: {
        image: `landing_feature_expense`,
        title: `Expenses `,
        description: <div>
            <p>Create and track expenses, categorize them efficiently, and bill them to your customers when necessary.</p>
            <ul className='custom-bullets'>
                <li><CheckIcon />Hassle-Free Expense Recording</li>
                <li><CheckIcon />Efficient Expense Tracking and Categorization</li>
                <li><CheckIcon />Seamless Billing to Billable Customers</li>
                <li><CheckIcon />Automated Expense Generation for Recurring Profiles</li>
            </ul>
        </div>
    },
    accounting: {
        image: `landing_feature_account`,
        title: `Accounting`,
        description: <div>
            <p>Streamlines accounting processes, simplifying income and expense tracking while ensuring compliance.</p>
            <ul className='custom-bullets'>
                <li><CheckIcon />Intuitive Double Entry System</li>
                <li><CheckIcon />Automated General Entries</li>
                <li><CheckIcon />Chart of Accounts Preparation</li>
                <li><CheckIcon />Simplified Income and Expense Tracking</li>
                <li><CheckIcon />Seamless Tax Compliance</li>
            </ul>
        </div>
    },
    reports: {
        image: `landing_feature_reports`,
        title: `Reports`,
        description: <div>
            <p>Make smart business decisions by offering a diverse range of reports to keep you informed about crucial aspects of your business.</p>
            <ul className='custom-bullets'>
                <li><CheckIcon />Wide Range of Insightful Reports</li>
                <li><CheckIcon />Strategic Data Analysis</li>
                <li><CheckIcon />Sharing Report</li>
                <li><CheckIcon />Data-Driven Decision-Making</li>
            </ul>
        </div>
    },
    analysis: {
        image: `landing_feature_reports`,
        title: `Forecasting & Analysis`,
        description: <div>
            <p>Empowers your business with robust forecasting and analysis tools for uncovering growth opportunities and informed decision-making.</p>
            <ul className='custom-bullets'>
                <li><CheckIcon />Comprehensive Business Understanding</li>
                <li><CheckIcon />Informed Decision-Making</li>
                <li><CheckIcon />Proactive Growth Strategies</li>
                <li><CheckIcon />Stay Ahead in the Market</li>
                <li><CheckIcon />Business Potential</li>
            </ul>
        </div>
    },
    gst: {
        image: `landing_feature_gst`,
        title: `GST `,
        description: <div>
            <p>Take control of your GST management with Finycs, ensuring you stay on top of your business's financial obligations. The platform's features and reporting tools streamline GST compliance,</p>
            <ul className='custom-bullets'>
                <li><CheckIcon />GST Compliant Invoicing</li>
                <li><CheckIcon />Automated Tax Calculation</li>
                <li><CheckIcon />Organized Customer and Vendor Records with GST information</li>
                <li><CheckIcon />HSN or SAC codes for proper taxation.</li>
                <li><CheckIcon />Simplified GST Reporting - generate GSTR-1 and GSTR-3B reports</li>
            </ul>
        </div>
    },
    inventory: {
        image: `landing_feature_inventory`,
        title: `Inventory`,
        description: <div>
            <p>Finycs offers a comprehensive set of features to effortlessly manage inventory, providing real-time visibility, valuable insights, and streamlined processes.</p>
            <ul className='custom-bullets'>
                <li><CheckIcon />Organized Inventory Tracking</li>
                <li><CheckIcon />real-time visibility of your stock levels.</li>
                <li><CheckIcon />Low Stock Reminders and Alerts</li>
                <li><CheckIcon />Efficient Stock Adjustments</li>
                <li><CheckIcon />Comprehensive Inventory Reports</li>
            </ul>
        </div>
    },
    tallyIntegration: {
        image: `landing_feature`,
        title: `Tally Integration `,
        description: <div>
            <p>Seamless Tally Integration with Finycs for Real-Time Tally Data Accessibility</p>
            <ul className='custom-bullets'>
                <li><CheckIcon />Data Synchronization</li>
                <li><CheckIcon />Real-Time Information Availability</li>
                <li><CheckIcon />Convenient Accessibility</li>
                <li><CheckIcon />Secure Syncing and Data Protection</li>
            </ul>
        </div>
    },





    contact: {
        image: `landing_feature_contact`,
        title: `Contact`,
        headLine: `Track and manage your contacts in one glance`,
        description: `Keeping track of information about customers and business done with them can get      overwhelming. Finycs contacts solves this problem once and for all by giving a single screen view of all your contacts and your business history with them.`

    },
    multiBusiness: {
        image: `landing_feature_business`,
        title: `Multi - Business`,
        headLine: `Bookkeeping Software Built for Your Multiple Businesses`,
        description: `Running multiple businesses is complicated. It gets even more challenging when you have to balance the books for each company. Finycs is an all-in-one accounting solution that manages all your bookkeeping needs across multiple businesses, from creating professional invoices to tracking your time and recording all your business expenses. Finycs  gives important financial insights to help you take each of your businesses to the next level. Create multiple business profiles in one account to oversee all your finances from one convenient central hub.`
    },
    multiCurrency: {
        image: `landing_feature_currency`,
        title: `Multi - Currency`,
        headLine: `Profits should know no borders`,
        description: `Multi currency accounting software makes it easy to manage your accounting in more than 100 currencies for your fast & growing business. Finycs makes managing multi-currency payments, invoicing and accounts easy.`
    },
    multiUser: {
        image: `landing_feature_role`,
        title: `Multi - User`,
        headLine: `Work better together by sharing business insights`,
        description: `Multi user feature in Finycs enables business owners & accountants to collaborate and make the best of their Finycs. With real time sharing of financial data, you can derive meaningful insight and run your business with efficiency.`
    },
    importData: {
        image: `landing_feature_upload`,
        title: `Import data`,
        headLine: `Switch from old accounting software or excel to Finycs.`,
        description: `You might have started your accounting with Excel, but it’s time you to bring your Finycs to a Finycs  accounting software which minimizes the clutter of spreadsheets & confusion of mismanaged accounting books.Finycs is a powerful alternative to your old accounting software, offline or online. Just export all the data of your company from your previous accounting software in CSV(comma-separated values) or excel format, and jump on the Finycs accounting cloud. `
    },
    shareData: {
        image: `landing_feature_share`,
        title: `Share Data`,
        headLine: `Smart collaboration across all departments`,
        description: `Multi user accounting software enables business owners & accountants to collaborate and make the best of their Finycs. With real time sharing of financial data, you can derive meaningful insight and run your business with efficiency.`
    },
    support: {
        image: `landing_feature_supports`,
        title: `Support`,
        headLine: ``,
        description: `With a diligent support team, your query is one call or email away from resolution.`
    },
    estimates: {
        image: `landing_feature`,
        title: `Estimates `,
        headLine: `Create online quotes in minutes.`,
        description: `Create professional online quotes using Finycs software. Send quotes instantly from your phone or desktop.`
    },

    bills: {
        image: `landing_feature`,
        title: `Bills `,
        headLine: `Create bills from purchase orders.`,
        description: `Once a purchase order is finalized, you can instantly create a bill at the click of a button. Finycs  makes billing simple.`
    },
    salesOrder: {
        image: `landing_feature`,
        title: `Sales Order`,
        headLine: `Convert Sales Order into Invoice.`,
        description: `Convert an approved estimate into a sales order, and then into an invoice! Finycs makes it that simple.`
    },
    purchaseOrder: {
        image: `landing_feature`,
        title: `Purchase Order`,
        headLine: `Convert purchase orders into bills.`,
        description: `When you're dealing with a vendor, you can instantly convert the purchase orders into bills.`
    },
    auditTrail: {
        image: `landing_feature`,
        title: `Audit Trail`,
        headLine: `Track & maintain log of every change made in your books.`,
        description: `The audit trail maintains a record of every action performed within Finycs, along with a timestamp and the details of the user who performed that action. This makes it easy to monitor who created, modified, or deleted transactions in your books.`
    },
    mobileApps: {
        image: `landing_feature`,
        title: `Mobile Apps`,
        headLine: `Do your accounting on the go using the mobile apps for android and iOS`,
        description: `Create and send personalized invoices that reflect your brand. Know how well your business is performing through a KPI dashboard. Generate key financial reports like balance sheets, cash flow statements, and income statements to know where your business stands.`
    },
    recurringInvoice: {
        image: `landing_feature`,
        title: `Recurring Invoice`,
        headLine: `Speed up Payment and reduce time spent chasing payment.`,
        description: `Recurring invoices are automatically generated and billed to your customers according to their billing cycle. Recurring invoices save your time and eliminate other hidden costs.`
    },
    customerStatements: {
        image: `landing_feature_export`,
        title: `Customer Statements`,
        headLine: `Generate and send customer statements`,
        description: `When customers have outstanding balances or invoices that will soon be overdue, you can send them a customer statement. These are gentle, detailed reminders about upcoming deadlines.`
    },
    bankingStatements: {
        image: `landing_feature_upload`,
        title: `Banking - Upload statements`,
        headLine: `Upload your bank statement, categorize transactions, and reconcile your accounts with ease.`,
        description: `It's easy to miss one transaction when you've got hundreds coming in. Use banks mapping rules to filter and categorize transactions automatically so nothing slips through the cracks.Finycs makes it easier to select, categorize, and delete thousands of transactions with bulk actions.`
    },
    guide: {
        image: `landing_feature_dashboard`,
        title: `Guide`,
        headLine: ``,
        description: `Find guides, articles, and infographics to help you do better business, and understand the often weird world of accounting.`
    },
}