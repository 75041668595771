import React from 'react';
import moment from 'moment';
import * as Actions from '../../../../state/Actions';
import CustomTitle from '../../../common/CustomTitle';
import { useNavigate, useLocation } from 'react-router-dom';
import CommonCustomDateRange from '../../../common/CommonCustomDateRange';

import CommonTable from './CommonTable';

import {
    date_range
} from '../../../../utils/Utils';

import {
    CustomContainer,
    CustomTitleContainer
} from '../../../custom/container/CustomContainer';

import { Grid } from '@mui/material';

const GSTR3B = () => {
    let location = useLocation();
    let navigate = useNavigate();
    let locationState = location.state;

    const stateAction = Actions.getState(React.useContext);
    const dispatch = Actions.getDispatch(React.useContext);

    const { selectedDateRange } = stateAction;
    let filter_date_range = date_range().find((item) => item.name === selectedDateRange)
    const default_date_range = filter_date_range ? filter_date_range : date_range()[2]
    const [dateRange, setDateRange] = React.useState(default_date_range)
    const [fromDate, setFromDate] = React.useState(moment())
    const [toDate, setToDate] = React.useState(moment())

    return (
        <div>
            <CustomTitleContainer>
                <Grid container spacing={0} style={{
                    alignItems: 'center',
                    justifyItems: 'center',
                    alignContent: 'space-evenly',
                    justifyContent: 'space-evenly',
                }}>
                    <Grid item xs={12} sm={4}>
                        <CustomTitle title={'GSTR 3B Summary'} />
                    </Grid>
                    <Grid container spacing={1} item xs={12} sm={8} sx={{ justifyContent: 'end', }}>
                        <CommonCustomDateRange
                            setPage={() => { }}
                            date_range={date_range()}

                            toDate={toDate}
                            setToDate={setToDate}

                            fromDate={fromDate}
                            setFromDate={setFromDate}

                            dateRange={dateRange}
                            setDateRange={setDateRange}
                        />
                        {/* <Grid item xs={12} sm={3}>
                            <DateRange
                                id={'date_range_dropdown'}
                                dataTestId={'date_range_dropdown'}

                                sx={{ mb: 2 }}
                                value={dateRange}
                                label='Date Range'
                                results={date_range}
                                disableClearable={true}
                                placeholder='select date range'
                                setValue={(event, newDateRange) => {
                                    setToDate(moment());
                                    setFromDate(moment());
                                    setDateRange(newDateRange);
                                }
                                }
                            />
                        </Grid>
                        {dateRange && dateRange.name === 'Custom' ?
                            <React.Fragment>
                                <Grid item xs={6} sm={2.5}>
                                    <DatePicker
                                        id={'from_date_select'}
                                        dataTestId={'from_date_select'}

                                        title='From Date'
                                        date={fromDate}
                                        setDate={(date) => {
                                            setFromDate(date)
                                            if (date > fromDate) { setToDate(date) }
                                        }
                                        }

                                        disabledCloseIcon={true}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={2.5}>
                                    <DatePicker
                                        id={'to_date_select'}
                                        dataTestId={'to_date_select'}
                                        
                                        date={toDate}
                                        title='To Date'
                                        minDate={fromDate}
                                        setDate={setToDate}
                                        disabledCloseIcon={true}
                                    />
                                </Grid>

                            </React.Fragment>
                            : null
                        } */}
                    </Grid>
                </Grid>
            </CustomTitleContainer>
            <CustomContainer maxWidth={"400"} sx={{}}>
                <div style={{ paddingLeft: '20px', paddingRight: '20px', paddingBottom: '48px' }}>
                    <CommonTable
                        toDate={toDate}
                        fromDate={fromDate}
                        dateRange={dateRange}
                        table_key={`table_3_1`}
                        bodyBackgroundColor='#a7c2f4'
                        headerBackgroundColor='#e3edff'
                        title={`3.1 Details of Outward Supplies and inward supplies liable to reverse charge`}
                    />
                </div>
                <div style={{ paddingLeft: '20px', paddingRight: '20px', paddingBottom: '48px' }}>
                    <CommonTable
                        toDate={toDate}
                        fromDate={fromDate}
                        dateRange={dateRange}
                        table_key={`table_3_1_1`}
                        bodyBackgroundColor='#a7c2f4'
                        headerBackgroundColor='#e3edff'
                        title={`3.1.1 Details of supplies notified under sub-section (5) of section 9 of the Central Goods and Services Tax Act`}
                    />
                </div>
                <div style={{ paddingLeft: '20px', paddingRight: '20px', paddingBottom: '48px' }}>
                    <CommonTable
                        toDate={toDate}
                        fromDate={fromDate}
                        dateRange={dateRange}
                        table_key={`table_3_2`}
                        bodyBackgroundColor='#a7c2f4'
                        headerBackgroundColor='#e3edff'
                        title={`3.2 Of the supplies shown in 3.1 (a) above, details of inter-State supplies made to unregistered persons, composition taxable persons and UIN holders`}
                    />
                </div>

                <div style={{ paddingLeft: '20px', paddingRight: '20px', paddingBottom: '48px' }}>
                    <CommonTable
                        toDate={toDate}
                        fromDate={fromDate}
                        dateRange={dateRange}
                        table_key={`table_4`}
                        bodyBackgroundColor='#fbccac'
                        headerBackgroundColor='#ffeee3'
                        title={`4. Eligible ITC`}
                    />
                </div>

                <div style={{ paddingLeft: '20px', paddingRight: '20px', paddingBottom: '48px' }}>
                    <CommonTable
                        toDate={toDate}
                        fromDate={fromDate}
                        dateRange={dateRange}
                        table_key={`table_5`}
                        bodyBackgroundColor='#fbccac'
                        headerBackgroundColor='#ffeee3'
                        title={`5. Values of exempt, nil-rated and non-GST inward supplies`}
                    />
                </div>
            </CustomContainer>
        </div>
    )
}

export default GSTR3B