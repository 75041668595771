import React from 'react'
import EditSection from './EditSection';
import { useNavigate } from 'react-router-dom';
import { Templates } from '../common/Templates';
import { apiAction } from '../../../../../api/api';
import * as Actions from '../../../../../state/Actions';
import CreateDataPoint from '../dataPoint/CreateDataPoint';
import CustomTabs from '../../../../custom/tabs/CustomTabs';
import { update_insight_section } from '../../../../../api/urls';
import { getBusinessInfo } from '../../../../../config/cookiesInfo';
import CustomTypography from '../../../../custom/typography/CustomTypography';
import { BootstrapDialog } from '../../../bulkStatementUpload/mappings/Transactions';
import { BootstrapDialogTitle } from '../../../bulkStatementUpload/mappings/Transactions';

import {
    isFormValid,
    stateChangeManager,
} from '../../../../../utils/Utils';


import {
    Box,
    DialogActions,
    DialogContent,
} from '@mui/material';

const ManageSection = (props) => {
    const { isDataPointManage = false, open, setOpen, dataPointData, data, setData, action, } = props;
    const navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext)
    
    const [tab, setTab] = React.useState(0);
    const [tabs, setTabs] = React.useState([]);
    const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);

    React.useEffect(() => {
        if (action === 'manage') { setTab(0); setTabs([{ name: 'Edit', condition: 'edit' },]) }
        if (action === 'edit_data_point') { setTab(0); setTabs([{ name: 'Edit Data Point', condition: 'edit_data_point' }]) }
        if (action === 'add_new_data_point') { setTab(0); setTabs([{ name: 'New Data Point', condition: 'add_data_point' }]) }
        if (action === 'clone_data_point') { setTab(0); setTabs([{ name: 'Clone Data Point', condition: 'clone_data_point' }]) }
        if (action === 'add_data_point') { setTab(0); setTabs([{ name: 'Existing Data Point', condition: 'existing_data_point' }, { name: 'New Data Point', condition: 'add_data_point' }]) }
    }, [action]);

    const handleClicked = () => {
        setTab(0);
        setOpen(false);
        setIsFormSubmitted(false);
    };

    const onUpdateSection = async (formData) => {
        setIsFormSubmitted(true);
        let validation_data = [
            { key: 'name', message: 'section name can not be empty' },
        ]
        const { isValid, message } = isFormValid(formData, validation_data)
        if (isValid) {
            let res = await apiAction({
                method: 'post',
                navigate: navigate,
                dispatch: dispatch,
                data: { ...formData, },
                url: update_insight_section(),

            });
            if (res?.success) {
                handleClicked();
                setData({ ...res?.result, id: formData.section_id });
                stateChangeManager(dispatch, Actions, true, 'success', res?.status);
            } else {
                stateChangeManager(dispatch, Actions, true, 'error', res?.status);
            }
        } else {
            stateChangeManager(dispatch, Actions, true, 'error', message);
        }

    }

    const assignDataPoint = (id) => {
        if (id) {
            const formData = {
                business_id: getBusinessInfo().id,

                name: data.name,
                tab_id: data.tab_id,
                section_id: data.id,
                section_type: data.section_type,
                data_point_ids: data.data_point_ids?data.data_point_ids.concat(id):[id]
            }
            onUpdateSection(formData)
        }

    }

    const updateDataPoint = (value) => {
        if (value) {
            setData(value);
            handleClicked();
        }
    }

    const getTitle = (action) => {
        if (isDataPointManage) {
            if (action === 'edit_data_point') {
                return 'Edit Data point'
            }
            if (action === 'clone_data_point') {
                return 'Clone Data Point'
            }
            if (action === 'add_new_data_point') {
                return 'New Data Point'
            }
        } else {
            return `Section - (${data.name})`
        }

    }

    return (
        data && open &&
        <div>
            <BootstrapDialog
                fullWidth
                open={open}
                maxWidth={'lg'}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={() => setOpen(false)}>
                    <CustomTypography
                        text={getTitle(action)}
                        sx={{
                            color: '#000000',
                            fontSize: '16px',
                            fontWeight: '700',
                            lineHeight: '18px',
                            fontStyle: 'normal',
                            fontFamily: "Noto Sans",
                        }}
                    />
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Box sx={{ height: 'calc(100vh - 151px)' }}>
                        <CustomTabs
                            tabs={tabs}
                            sx={{ mt: -1.5 }}
                            value={tab}
                            onChange={(event, tab) => {
                                setTab(tab)
                            }}
                        />


                        {tabs.length ?
                            <>
                                {tabs.length && tabs[tab].condition === 'edit_data_point' && <CreateDataPoint data={dataPointData} assignDataPoint={(value) => { isDataPointManage ? setData(value) : updateDataPoint(value) }} boxHeight={'calc(100vh - 250px)'} onClose={() => { handleClicked() }} />}
                                {tabs.length && tabs[tab].condition === 'clone_data_point' && <CreateDataPoint is_clone={true} data={dataPointData} assignDataPoint={(value) => { isDataPointManage ? setData(value) : assignDataPoint(value.id) }} boxHeight={'calc(100vh - 250px)'} onClose={() => { handleClicked() }} />}
                                {tabs.length && tabs[tab].condition === 'add_data_point' && <CreateDataPoint assignDataPoint={(value) => { isDataPointManage ? setData(value) : assignDataPoint(value.id) }} boxHeight={'calc(100vh - 250px)'} onClose={() => { handleClicked() }} />}
                                {tabs.length && tabs[tab].condition === 'existing_data_point' && <EditSection isExisting={true} isFormSubmitted={isFormSubmitted} onManageSection={(formData) => onUpdateSection(formData)} boxHeight={'calc(100vh - 250px)'} data={data} onClose={() => { handleClicked() }} />}
                                {tabs.length && tabs[tab].condition === 'edit' && <EditSection isFormSubmitted={isFormSubmitted} onManageSection={(formData) => onUpdateSection(formData)} boxHeight={'calc(100vh - 250px)'} data={data} onClose={() => { handleClicked() }} />}
                            </>
                            :
                            ''
                        }
                    </Box>
                    <DialogActions>
                    </DialogActions>
                </DialogContent>
            </BootstrapDialog>
        </div>
    )
}

export default ManageSection
