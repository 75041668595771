import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import CustomButton from './CustomButton';

// const options = ['Create a merge commit', 'Squash and merge', 'Rebase and merge'];

export default function CustomGroupButton(props) {
    const { options, style,minWidth='80px !important' } = props

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const handleMenuItemClick = (event, index) => {
        setOpen(false);
        setSelectedIndex(index);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    return (
        <React.Fragment>
            <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button" >
                <CustomButton  variant='contained' sx={{ textTransform: 'none',minWidth: minWidth,}} id={options[0].id} dataTestId={options[0].dataTestId} btnLabel={options[0].label} onClick={options[0].condition} />
                {/* <Button onClick={handleClick}>{'options[selectedIndex]'}</Button> */}
                <Button
                    id='handle_toggle_btn'
                    dataTestId='handle_toggle_btn'
                    size="small"
                    onClick={handleToggle}
                >
                    <ArrowDropDownIcon />
                </Button>
                {/* <CustomButton variant='contained' btnLabel={<ArrowDropDownIcon />}  onClick={handleToggle} /> */}

            </ButtonGroup>
            <Popper
                // sx={{
                //     zIndex: 1,
                // }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper sx={{ ...style, mb: 0.5 }}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" dataTestId="split-button-menu" autoFocusItem>
                                    {options.map((option, index) => (
                                        index ?
                                            <MenuItem
                                                key={index}
                                                id={option.id}
                                                dataTestId={option.dataTestId}
                                                onClick={(event) => { handleMenuItemClick(event, index); option.condition() }}
                                                sx={{ fontFamily: 'Noto Sans', fontStyle: 'normal', fontWeight: '700', fontSize: '12px', lineHeight: '16px', }}
                                            >
                                                {option.label}
                                            </MenuItem>
                                            : null
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </React.Fragment>
    );
}
