import React from 'react';
import { styled } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip arrow {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 220,
        color: '#141414',
        fontWeight: '500',
        fontStyle: 'normal',
        textTransform: 'none',
        fontFamily: 'Noto Sans',
        backgroundColor: '#f5f5f9',
        border: '1px solid #dadde9',
        fontSize: theme.typography.pxToRem(12),
    },
    [`& .${tooltipClasses.arrow}`]: {
        "&::before": {
            backgroundColor: "#f5f5f9",
            border: "1px solid #dadde9"
        }
    },
}));


export const MetaData = (props) => {
    const { placement = 'bottom', meta_data, sx, } = props;

    return (
        <HtmlTooltip placement={placement} arrow title={meta_data} sx={{ ...sx }}>
            <InfoOutlinedIcon fontSize='small' sx={{ cursor: 'pointer', pl: 0.5, fontSize: '16px', color: '#939393', ":hover": { color: '#2098ff' } }} />
        </HtmlTooltip>
    )
}