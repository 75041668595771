import { createTheme } from "@mui/material/styles";

const primary = "#2464EB";
const lightblue = "#e8f3ff";

const theme = createTheme({
  palette: {
    common: {
      pink: primary,
      lightblue: lightblue,
    },
    primary: {
      main: primary,
    },
    secondary: {
      main: lightblue,
    },
  },

  components: {
    // Name of the component
    MuiTextareaAutosize: {
      styleOverrides: {
        root: {
          '&:focus': {
            borderColor: 'blue', // Change to the desired border color
            boxShadow: '0 0 0 2px rgba(0, 0, 255, 0.3)', // Optional: add a box shadow
          },
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        paddingLeft: '0px !important',
        paddingRight: '0px !important'
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        // Name of the slot
        noOptions: {
          // Some CSS
          textAlign: 'center'
        },
      },
    },
  },

  typography: {
    tab: {
      fontFamily: "Noto Sans",
      textTransform: "none",
      // fontSize: "2.5rem",
      // color: black,
    },
    h1: {
      fontFamily: "Noto Sans",
      textTransform: "none",
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '14px',
      lineHeight: '18px',
      color: '#000000'
    },
    h2: {
      fontFamily: "Noto Sans",
      textTransform: "none"
      // fontSize: "2.5em"
    },
    subtitle1: {
      fontFamily: "Noto Sans",
      textTransform: "none"
    },
    subtitle2: {
      fontFamily: "Noto Sans",
      textTransform: "none"
      // fontSize: "1.5rem"
    },
    buttons: {
      fontFamily: "Noto Sans",
      textTransform: "none"
    },
  },
});

export default theme