import React from 'react';
import moment from 'moment';
import ReportsSettingButtons from '../../ReportsSettingButtons';
import { useLocation } from 'react-router-dom';
import { apiAction } from "../../../../../api/api";
import ReportsTitle from '../../common/ReportsTitle';
import * as Actions from '../../../../../state/Actions';
import { getBusinessInfo } from '../../../../../config/cookiesInfo';
import CurrencyFormatter from '../../../../common/CurrencyFormatter';
import CommonCustomDateRange from '../../../../common/CommonCustomDateRange';
import { CustomContainer } from '../../../../custom/container/CustomContainer';

import {
  get_cash_flow,
  export_cash_flow
} from '../../../../../api/urls';

import {
  setLoader,
  date_range,
  getDateFormat,
  special_character_check,
} from '../../../../../utils/Utils';


import {
  Grid,
} from '@mui/material';

import {
  CustomTable,
  CustomTableRow,
  CustomTableBody,
  CustomTableHead,
  CustomTableHeadCell,
  CustomTableBodyCell,
  CustomTableContainer,
} from '../../../../custom/table/CustomTable';


const CashFlow = () => {
  let location = useLocation();

  const stateAction = Actions.getState(React.useContext);
  const dispatch = Actions.getDispatch(React.useContext);

  const { selectedDateRange } = stateAction;
  let filter_date_range = date_range().find((item) => item.name === selectedDateRange)
  const default_date_range = filter_date_range ? filter_date_range : date_range()[2]

  const [date, setDate] = React.useState();
  const [results, setResults] = React.useState([]);
  const [isZero, setIsZero] = React.useState(false);
  const [dateRange, setDateRange] = React.useState();
  const [toDate, setToDate] = React.useState(moment());
  const [fromDate, setFromDate] = React.useState(moment());
  const [isDetailed, setIsDetailed] = React.useState(false);

  React.useEffect(() => {
    if (dateRange) {
      if (dateRange.name !== "Custom") {
        getApiResults(dateRange.dates.from_date, dateRange.dates.to_date, dateRange.dates.date_range)
      } else {
        getApiResults(fromDate.format('YYYY-MM-DD'), toDate.format('YYYY-MM-DD'), `${fromDate.format(getDateFormat())} - ${toDate.format(getDateFormat())}`)
      }
    }
    // eslint-disable-next-line
  }, [dateRange, toDate, fromDate]);

  const getApiResults = async (from_date, to_date, range_date) => {
    setLoader(dispatch, Actions, true)
    setDate(range_date)
    let res = await apiAction({
      method: 'post',
      url: get_cash_flow(),
      data: {
        business_id: getBusinessInfo().id,
        from_date: from_date,
        to_date: to_date
      },
    })
    // console.log('res',res)
    if (res?.success) {
      setResults(res?.result.components)
      setLoader(dispatch, Actions, false)
    } else {
    }
  }

  React.useEffect(() => {
    if (location.state) {
      let data = location.state;
      setToDate(moment(data.to_date));
      setFromDate(moment(data.from_date));
      setDateRange({ name: data.name, dates: data.dates });
    } else {
      setDateRange(default_date_range);
    }
    // eslint-disable-next-line
  }, []);


  return (
    <div>
      <ReportsSettingButtons

        scheduleData={{
          report_name: 'cash_flow',
          report_title: 'Cash Flow',

        }}

        zeroData={{
          value: isZero,
          setValue: (value) => setIsZero(value),
        }}

        labelData={{
          value: isDetailed,
          labelName: 'Detailed',
          title: 'Export Preference',
          setValue: (value) => setIsDetailed(value),
        }}

        exportData={{ url: export_cash_flow(), data: dateRange && dateRange.dates ? { ...dateRange.dates, is_detailed: isDetailed, display_zero_balance_account: !isZero } : { to_date: toDate.format('YYYY-MM-DD'), from_date: fromDate.format('YYYY-MM-DD'), is_detailed: isDetailed, display_zero_balance_account: !isZero } }}
      />
      <CustomContainer maxWidth={"400"} sx={{ height: 'calc(100vh - 134px)' }}>
        <div style={{ paddingLeft: '16px', paddingRight: '16px' }}>
          <Grid container spacing={0} sx={{ pb: 2 }} style={{
            alignItems: 'center',
            justifyItems: 'center',
            justifyContent: 'space-evenly',
            alignContent: 'space-evenly',
          }}>
            <Grid item xs={12} sm={4}>
              <ReportsTitle
                date={date}
                title={'Cash Flow'}
              />
            </Grid>
            <Grid container spacing={1} item xs={12} sm={8} sx={{ justifyContent: 'end', }}>
              <CommonCustomDateRange
                setPage={() => { }}
                date_range={date_range()}

                toDate={toDate}
                setToDate={setToDate}

                fromDate={fromDate}
                setFromDate={setFromDate}

                dateRange={dateRange}
                setDateRange={setDateRange}
              />
            </Grid>
          </Grid>
        </div>
        <CustomTableContainer>
          <CustomTable>
            <CashFlowHeader />
            <CashFlowBody data={results} isZero={isZero} />
            <caption style={{ fontFamily: 'Noto Sans', fontWeight: '500', fontSize: '10px', fontStyle: 'normal', lineHeight: '14px' }}>**Amount is displayed in your base currency <span style={{ backgroundColor: '#6ff795', paddingLeft: '5px', paddingRight: '5px', paddingBottom: '2px', paddingTop: '1px' }}>{getBusinessInfo().currency.currency_code}</span></caption>
          </CustomTable>
        </CustomTableContainer>
      </CustomContainer>
    </div>
  )
}

const CashFlowHeader = () => {
  return (
    <CustomTableHead>
      <CustomTableRow >
        <CustomTableHeadCell><span>Account</span></CustomTableHeadCell>
        <CustomTableHeadCell align='right'><span>Total</span></CustomTableHeadCell>
      </CustomTableRow>
    </CustomTableHead>
  )
}

const CashFlowBody = (props) => {
  const { isZero, data } = props
  let custom_border = {
    paddingBottom: '10px',
    paddingTop: '10px',
    borderTop: '1px solid #F5F5F5',
    borderBottom: '1px solid #F5F5F5'
  }
  return (
    <CustomTableBody>
      {data.map((item, index) => {
        return (
          <CustomTableRow key={index} sx={item.component_name === 'Net Change in cash = A + B + C' ? { background: '#FFF7DC', border: '1px solid #FFF0C0' } : {}}>
            <CustomTableBodyCell style={special_character_check(item.component_name) ? custom_border : {}}>
              <strong style={{ color: '#000000', fontSize: '12px', lineHeight: '16px', fontWeight: '700' }}>{item.component_name.replaceAll('*', ' ')}</strong>
              {
                item.component_details ? item.component_details.map((value, index) => {
                  return (
                    <React.Fragment key={index}>
                      <p style={{ paddingLeft: '18px', color: '#000000', fontSize: '12px', lineHeight: '16px', fontWeight: '500' }}>
                        {isZero ?
                          value.component_balance !== 0 &&
                          <span>{value.component_name.replaceAll('*', ' ')}</span>
                          :
                          <span>{value.component_name.replaceAll('*', ' ')}</span>
                        }
                      </p>
                    </React.Fragment>
                  )
                })
                  : null
              }
            </CustomTableBodyCell>
            <CustomTableBodyCell align='right' style={special_character_check(item.component_name) ? custom_border : {}}>
              <strong style={{ color: '#000000', fontSize: '12px', lineHeight: '16px', fontWeight: '700', }}>
                <CurrencyFormatter amount={item.component_balance} />
              </strong>
              {
                item.component_details ? item.component_details.map((value, index) => {
                  return (
                    <React.Fragment key={index}>
                      <p style={{ display: 'block', color: '#000000', fontSize: '12px', lineHeight: '16px', fontWeight: '500' }}>
                        {isZero ?
                          value.component_balance !== 0 &&
                          <CurrencyFormatter amount={value.component_balance} />
                          :
                          <CurrencyFormatter amount={value.component_balance} />
                        }
                      </p>
                    </React.Fragment>
                  )
                })
                  : null
              }
            </CustomTableBodyCell>
          </CustomTableRow>
        )
      })
      }
    </CustomTableBody>
  )
}

export default CashFlow;