import React, { useEffect } from 'react';
import Input from './Input';

import {
  Divider,
  IconButton,
  InputAdornment,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';


const CommonSearch = (props) => {

  const { sx, size = 'small', title, autoFocus = false, inputRef, placeholder, titleVisibility, isTitleVisibility = true, inputProps, fullWidth, onSearchValue, onSearch, disabled = false } = props;
  const [searchTerm, setSearchTerm] = React.useState('');

  React.useEffect(() => {
    setSearchTerm(onSearchValue)
  }, [onSearchValue])

  return (
    <div>
      <Input
        size={size}
        input_search={true}
        inputRef={inputRef}
        autoFocus={autoFocus}
        inputProps={inputProps}
        item={{
          sx: sx,
          type: 'text',
          title: title,
          value: searchTerm,
          disabled: disabled,
          fullWidth: fullWidth,
          placeholder: placeholder,
          titleVisibility: titleVisibility,
          isTitleVisibility:isTitleVisibility,
          onChange: (e) => {
            setSearchTerm(e.target.value);
            if (e.target.value === '') {
              onSearch('')
            }
          },
        }}
        onKeyPress={(event) => {
          var key = event.keyCode || event.which;

          if (key === 13) {
            if (searchTerm !== '') {
              onSearch(searchTerm);
              setSearchTerm(event.target.value)
            }
            else {
              setSearchTerm(searchTerm)
            }
          }
        }}
        InputProps={{
          endAdornment: <InputAdornment position="end">
            <Divider sx={{ height: 20, m: 0.5 }} orientation="vertical" />
            <IconButton edge="end" size='small' disabled={disabled ? disabled : searchTerm === "" ? true : false} sx={{ ml: .5, color: '#2464EB', cursor: 'pointer' }}
              onClick={() => {
                if (onSearchValue !== "") {
                  onSearch('');
                  setSearchTerm('');
                } else {
                  onSearch(searchTerm);
                  setSearchTerm(searchTerm);
                }
              }}>
              {
                onSearchValue !== "" ?
                  <ClearIcon /> : <SearchIcon />
              }
            </IconButton>
          </InputAdornment>

        }}
      />
    </div>
  )
}

export default CommonSearch;