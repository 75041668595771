import React from 'react';
import moment from 'moment';
import { apiAction } from '../../../../api/api';
import Loader from '../../../custom/loader/Loader';
import { getDateFormat } from '../../../../utils/Utils';
import { routesName } from '../../../../config/routesName';
import { CustomLink } from '../../../custom/link/CustomLink';
import { retrieve_journal_entry } from '../../../../api/urls';
import { getBusinessInfo } from '../../../../config/cookiesInfo';
import CurrencyFormatter from '../../../common/CurrencyFormatter';
import CustomTypography from '../../../custom/typography/CustomTypography';
import { CustomContainer } from '../../../custom/container/CustomContainer';

import {
    CustomTable,
    CustomTableRow,
    CustomTableBody,
    CustomTableHead,
    CustomTableHeadCell,
    CustomTableBodyCell,
    CustomTableContainer,
} from '../../../custom/table/CustomTable';

import {
    Box,
    Grid,
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const CommonJournalEntryTransactionView = (props) => {
    const { id } = props

    React.useEffect(() => {
        if (id) {
            getApiResults(id);
        }
    }, [id])

    const [results, setResults] = React.useState([])
    const [currency, setCurrency] = React.useState()
    const getApiResults = async (id) => {
        let res = await apiAction({
            method: 'post',
            url: retrieve_journal_entry(id),
            data: { business_id: getBusinessInfo().id }
        })
        if (res?.success) {
            setResults(res?.result)
            setCurrency(res?.result?.transactions[0]?.currency)
        } else {

        }
    }
    const toReturnColor = (status) => {
        let color = {
            color: '',
            background: '',
            borderColor: '',
        }
        if (status) {
            color.color = '#00000';
            color.background = '#cdebc7';
            color.borderColor = '#a2d998';
        } else {
            color.color = '#00000';
            color.background = '#e8f3ff';
            color.borderColor = '#a8a8a8';
        }
        return color
    }
    return (
        <Box style={{ borderTop: '5px solid #F5F5F5' }} sx={{ mb: '1vh' }}>
            <Accordion
                square
                elevation={0}
                disableGutters
                defaultExpanded={true}
                sx={{
                    '&:before': { display: 'none' }
                }}
                id={'common_journal_entry_transaction'}
                dataTestId={'common_journal_entry_transaction'}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <CustomTypography
                        text={<span>{'Display Journal'}<span
                            style={{
                                border: '1px',
                                width: '50px',
                                paddingTop: 5,
                                paddingLeft: 5,
                                marginLeft: 10,
                                paddingBottom: 5,
                                paddingRight: 10,
                                textAlign: 'center',
                                borderRadius: '5px',
                                display: 'inline-block',
                                color: toReturnColor(results.is_posted).color,
                                background: toReturnColor(results.is_posted).background,
                            }}
                        >{results.is_posted ? 'Posted' : 'Draft'}</span></span>}
                        sx={{
                            color: '#000000',
                            fontSize: '14px',
                            fontWeight: '700',
                            lineHeight: '18px',
                            fontStyle: 'normal',
                            fontFamily: "Noto Sans",
                        }}
                    />
                </AccordionSummary>
                <AccordionDetails sx={{ borderTop: '2px solid #F5F5F5' }}>
                    <JournalEntryTable results={results} currency={currency} />
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}

export default CommonJournalEntryTransactionView;

const JournalEntryTable = (props) => {
    const { results, currency } = props;
    return (
        // <CustomContainer maxWidth={"400"} sx={{}}>
        <>
            <TransactionInfo results={results} currency={currency} />
            <CustomTableContainer sx={{}}>
                <CustomTable>
                    <TransactionHeaders />
                    {
                        results.transactions &&
                        <TransactionBody results={results.transactions} currency={currency} />
                    }
                </CustomTable>
                {!results.transactions &&
                    <CustomTypography
                        text={<Loader />}
                        sx={{
                            mt: '15vh',
                            color: '#000000',
                            fontSize: '14px',
                            fontWeight: '700',
                            textAlign: 'center',
                            lineHeight: '18px',
                            fontStyle: 'normal',
                            fontFamily: "Noto Sans",
                        }}
                    />
                }
            </CustomTableContainer>
        </>
        // </CustomContainer>
    )
}

const TransactionHeaders = () => {
    return (
        <CustomTableHead sx={{}}>
            <CustomTableRow >
                <CustomTableHeadCell style={{ borderTop: '1px solid #e2e2e2', width: 150 }}><span>Account</span></CustomTableHeadCell>
                <CustomTableHeadCell style={{ borderTop: '1px solid #e2e2e2', width: 150 }} align='right'><span>Debit</span></CustomTableHeadCell>
                <CustomTableHeadCell style={{ borderTop: '1px solid #e2e2e2', width: 150 }} align='right'><span>Credit</span></CustomTableHeadCell>
            </CustomTableRow>
        </CustomTableHead>
    )
}

const TransactionBody = (props) => {
    const { results, currency } = props
    return (
        <CustomTableBody>
            {

                results && results.map((item, index) => {
                    return (
                        <CustomTableRow key={index}>
                            <CustomTableBodyCell sx={{}} id={'link_account_btn'} dataTestId={'link_account_btn'}><CustomLink style={{ color: '#2464EB' }} to={routesName.journalEntryTransaction.path.replace(':id', item.business_account_id)}><span>{item.business_account}</span></CustomLink></CustomTableBodyCell>
                            <CustomTableBodyCell sx={{}} align='right'><span>{item.transaction_type === "DEBIT" ? <CurrencyFormatter amount={item.amount} currency={currency} /> : '-'}</span></CustomTableBodyCell>
                            <CustomTableBodyCell sx={{}} align='right'><span>{item.transaction_type === "CREDIT" ? <CurrencyFormatter amount={item.amount} currency={currency} /> : '-'}</span></CustomTableBodyCell>
                        </CustomTableRow>
                    )
                })
            }
            <CustomTableRow sx={{ background: '#FFF7DC', }}>
                <CustomTableBodyCell sx={{ borderTop: '1px solid #FFF0C0', borderBottom: '1px solid #FFF0C0' }}><strong>Total balance</strong></CustomTableBodyCell>
                <CustomTableBodyCell align='right' sx={{ borderTop: '1px solid #FFF0C0', borderBottom: '1px solid #FFF0C0' }}><CurrencyFormatter amount={results && results.reduce((acc, item) => acc + (item.transaction_type === "DEBIT" ? item.amount : 0), 0)} currency={currency} /></CustomTableBodyCell>
                <CustomTableBodyCell align='right' sx={{ borderTop: '1px solid #FFF0C0', borderBottom: '1px solid #FFF0C0' }}><CurrencyFormatter amount={results && results.reduce((acc, item) => acc + (item.transaction_type === "CREDIT" ? item.amount : 0), 0)} currency={currency} /></CustomTableBodyCell>
            </CustomTableRow>
        </CustomTableBody>
    )
}

const TransactionInfo = (props) => {
    const { results, currency } = props

    return (
        <Grid container spacing={2} sx={{ p: 2, pt: 1 }}>
            <Grid item xs={2}>
                <CustomTypography
                    text={"Date"}
                    sx={{
                        mb: 1,
                        color: '#000000',
                        fontSize: '14px',
                        fontWeight: '700',
                        lineHeight: '18px',
                        fontStyle: 'normal',
                        textTransform: 'none',
                        fontFamily: "Noto Sans",
                    }}
                />
                <CustomTypography
                    text={moment(results.transaction_date).format(getDateFormat())}
                    sx={{
                        color: '#000000',
                        fontWeight: 700,
                        fontSize: '12px',
                        lineHeight: '16px',
                        fontStyle: 'normal',
                        fontFamily: 'Noto Sans',
                    }}
                />
            </Grid>
            <Grid item xs={3}>
                <CustomTypography
                    text={"Amount"}
                    sx={{
                        mb: 1,
                        color: '#000000',
                        fontSize: '14px',
                        fontWeight: '700',
                        lineHeight: '18px',
                        fontStyle: 'normal',
                        textTransform: 'none',
                        fontFamily: "Noto Sans",
                    }}
                />
                <CustomTypography
                    text={<CurrencyFormatter amount={results.transaction_amount} currency={currency} />}
                    sx={{
                        color: '#000000',
                        fontWeight: 700,
                        fontSize: '12px',
                        lineHeight: '16px',
                        fontStyle: 'normal',
                        fontFamily: 'Noto Sans',
                    }}
                />

            </Grid>
            <Grid item xs={3.5}>
                <CustomTypography
                    text={"Description"}
                    sx={{
                        mb: 1,
                        color: '#000000',
                        fontSize: '14px',
                        fontWeight: '700',
                        lineHeight: '18px',
                        fontStyle: 'normal',
                        textTransform: 'none',
                        fontFamily: "Noto Sans",
                    }}
                />
                <CustomTypography
                    text={results.description}
                    sx={{
                        color: '#000000',
                        fontWeight: 700,
                        fontSize: '12px',
                        lineHeight: '16px',
                        fontStyle: 'normal',
                        fontFamily: 'Noto Sans',
                    }}
                />

            </Grid>
            <Grid item xs={3.5}>
                <CustomTypography
                    text={"Note"}
                    sx={{
                        mb: 1,
                        color: '#000000',
                        fontSize: '14px',
                        fontWeight: '700',
                        lineHeight: '18px',
                        fontStyle: 'normal',
                        textTransform: 'none',
                        fontFamily: "Noto Sans",
                    }}
                />
                <CustomTypography
                    text={results.note ? results.note : '--'}
                    sx={{
                        color: '#000000',
                        fontWeight: 700,
                        fontSize: '12px',
                        lineHeight: '16px',
                        fontStyle: 'normal',
                        fontFamily: 'Noto Sans',
                    }}
                />
            </Grid>
        </Grid>
    )
}