import React from 'react';
import { useNavigate } from 'react-router-dom';
import { apiAction } from '../../../../api/api';
import * as Actions from '../../../../state/Actions';
import CustomTitle from '../../../common/CustomTitle';
import CustomButton from '../../../custom/button/CustomButton';
import { getBusinessInfo } from '../../../../config/cookiesInfo';
import CustomTypography from '../../../custom/typography/CustomTypography';
import AsyncDropdownWithMultipleValue from '../../../custom/dropdown/AsyncDropdownWithMultipleValue';

import {
    get_report_map,
    update_report_map,
    get_chart_of_account_nested_nodes,
} from '../../../../api/urls';


import {
    CustomHeading,
} from '../../setting/settings/Preferences/common/CustomElement';


import {
    setLoader,
    stateChangeManager,
} from '../../../../utils/Utils';

import {
    Box,
    Grid,
    Slide,
    Drawer,
    Dialog,
    IconButton,
} from '@mui/material';
import { Close } from '@mui/icons-material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const ReportAccountMapping = (props) => {
    const { open, setOpen, mapping_type, refreshPage } = props;

    let navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);

    const handleClose = () => {
        setOpen(false);
    };

    const [data, setData] = React.useState();

    React.useEffect(() => {
        if (open) {
            getMappingData(mapping_type);
        }
    }, [mapping_type, open])

    const getMappingData = async (type) => {
        let res = await apiAction({
            method: 'get',
            navigate: navigate,
            dispatch: dispatch,
            url: get_report_map(type),
        })
        if (res?.success) {
            setData(res?.result.mapping)
        } else {
            stateChangeManager(dispatch, Actions, true, 'error', res?.status);
        }
    }

    const onSaveHandler = async () => {
        setLoader(dispatch, Actions, true);
        let res = await apiAction({
            method: 'post',
            navigate: navigate,
            dispatch: dispatch,
            url: update_report_map(),
            data: {
                mapping: data,
                report_type: mapping_type,
                business_id: getBusinessInfo().id,
            },
        })
        if (res?.success) {
            handleClose();
            refreshPage();
            setLoader(dispatch, Actions, false);
            stateChangeManager(dispatch, Actions, true, 'success', res?.status);
        } else {
            setLoader(dispatch, Actions, false)
            stateChangeManager(dispatch, Actions, true, 'error', res?.status)
        }
    }

    return (
        data &&
        <Dialog
            fullScreen
            open={open}
            TransitionComponent={Transition}
            id={'common_report_account_mapping_Dialog'}
            dataTestId={'common_report_account_mapping_Dialog'}

        // anchor={'right'}
        // PaperProps={{
        //     sx: {
        //         width: {
        //             xs: '100vw',
        //             sm: '40vw'
        //         }
        //     }
        // }}
        >
            <Box sx={{
                zIndex: 1,
                minHeight: '100vh',
                backgroundColor: '#FFFFFF',
                boxShadow: '1px 0px 4px rgba(0, 0, 0, 0.04)',
            }}>
                <Box sx={{ p: 1, pr: 2, pl: 2, backgroundColor: '#F2F2F2', borderBottom: '2px solid #e0e0e0', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <CustomTitle title={`Edit Accounts Mapping`} />
                    <IconButton size='small' onClick={() => handleClose()}>
                        <Close fontSize='small' />
                    </IconButton>
                </Box>
                <Box sx={{ maxHeight: 'calc(100vh - 134px)', }}>
                    <Box sx={{
                        p: 2,
                        overflow: 'scroll',
                        height: 'calc(100vh - 134px)',
                    }}>
                        {Object.keys(data).map((key, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <DataComponent
                                        index={index}
                                        data={data[key]}
                                        playLoad={
                                            mapping_type === 'balance_sheet' ?
                                                ["ASSET", "LIABILITY", "EQUITY"]
                                                : mapping_type === 'profit_and_loss' ?
                                                    ["EXPENSE", "INCOME"]
                                                    : {}
                                        }
                                        onChangeHandler={(value) => {
                                            data[key]['account_group'] = value
                                            setData({ ...data })
                                        }}
                                    />

                                </React.Fragment>
                            )
                        })}
                    </Box>
                </Box>
                <Box sx={{ p: 1, pr: 2, pl: 2, backgroundColor: '#F2F2F2', position: 'absolute', borderTop: '2px solid #e0e0e0', bottom: 0, left: 0, right: 0, }}>
                    <Buttons onSave={() => { onSaveHandler() }} onCancel={() => { handleClose() }} />
                </Box>
            </Box>
        </Dialog>
    )
}


export default ReportAccountMapping;
const Buttons = (props) => {
    const { onSave, onCancel } = props
    return (
        <Box component="form" noValidate sx={{}}>
            <Grid container>
                <Grid item xs={12} sx={{ display: '-webkit-flex', justifyContent: 'end', }}>
                    <CustomButton id={'common__report_account_mapping_cancel_btn'} dataTestId={'common__report_account_mapping_cancel_btn'} variant='outlined' sx={{ textTransform: 'none', '&:hover': { backgroundColor: '#e8f3ff' } }} btnLabel='Cancel' onClick={onCancel} />
                    <CustomButton id={'common__report_account_mapping_save_btn'} dataTestId={'common__report_account_mapping_save_btn'} variant='contained' sx={{ ml: 1, textTransform: 'none', }} btnLabel={'Save & Run'} onClick={onSave} />
                </Grid>
            </Grid>
        </Box>
    )
}

const DataComponent = (props) => {
    const { index, data, playLoad, onChangeHandler } = props;

    return (
        <Box sx={{ p: 4, pl: 1, borderTop: `${index ? '2px solid #f5f5f5' : ''}` }}>
            <Grid container sx={{ alignItems: 'center' }} spacing={2}>
                <Grid item xs={2}>
                    <CustomHeading label={<span style={{ fontSize: '14px', }}>{data.name}</span>} />
                </Grid>
                <Grid item xs={10} sx={{}}>
                    <AsyncDropdownWithMultipleValue
                        sx={{}}
                        fullWidth={true}
                        autoFocus={false}
                        newResults={null}
                        inputDisabled={false}
                        disabledCloseIcon={false}
                        isGroupHeaderSticky={true}

                        id={'mapping_account_type_' + index}
                        dataTestId={'mapping_account_type_' + index}


                        selectedValue={data.account_group}
                        setSelectedValue={(value) => {
                            onChangeHandler(value)
                        }}

                        title={null}
                        valueLabelKey='id'
                        uniqueOptionKey='id'
                        searchKey='account_name'
                        optionLabelKey='account_name'
                        placeholder={'select Account'}
                        optionGroupLabelKey='account_type'
                        playLoad={{ account_type: playLoad }}
                        URL={get_chart_of_account_nested_nodes(1)}

                    />
                </Grid>
            </Grid>
        </Box>
    )
}