import React from 'react';
import moment from 'moment/moment';
import Input from '../../../common/Input';
import DateRange from '../../../common/DateRange';
import DatePicker from '../../../common/DatePicker';
import * as Actions from '../../../../state/Actions';
import { InputFile } from '../../../common/InputFile';
import AsyncDropdown from '../../../common/AsyncDropdown';
import { useLocation, useNavigate } from 'react-router-dom';
import { apiAction, apiFormData } from '../../../../api/api';
import { listPaymentMethod } from '../../../../utils/Constant';
import { actualPrice } from '../sales/invoices/InvoiceManager';
import CustomButton from '../../../custom/button/CustomButton';
import { getBusinessInfo } from '../../../../config/cookiesInfo';
import CurrencyFormatter from '../../../common/CurrencyFormatter';
import CommonPreviewShare from '../../../common/CommonPreviewShare';
import { LabelWithAsteriskMark } from '../../../common/CommonLabel';
import { eventsNames } from '../../../../firebase/firebaseAnalytics';
import CommonAsyncDropdown from '../../../common/CommonAsyncDropdown';
import CustomGroupButton from '../../../custom/button/CustomGroupButton';
import CustomTypography from '../../../custom/typography/CustomTypography';
import CustomDefaultDropdown from '../../../custom/dropdown/CustomDefaultDropdown';

import {
  payment_made_number,
  payment_received_number,
} from '../../../../utils/Constant';

import {
  CustomContainer,
  CustomButtonContainer,
} from '../../../custom/container/CustomContainer';

import {
  isNumber,
  setLoader,
  isFormValid,
  getPaymentType,
  getQueryParams,
  isVendorRegister,
  stateChangeManager,
  limitDecimalPlaces,
  setIsVendorRegister,
  isBusinessRegistered,
  breadCrumbsStateChangeManager,
  filterTax,
  findDefaultTax,
} from '../../../../utils/Utils';

import {
  get_party,
  list_party,
  create_payment,
  update_payment,
  get_list_of_tax,
  retrieve_payment,
  get_list_of_state,
  get_list_of_currency,
  export_payment_receipt,
  get_chart_of_account_nested_nodes
} from '../../../../api/urls';

import {
  Box,
  Grid,
  Popover,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';

const Dropdown = DateRange;
const AdvancePaymentCreate = (props) => {
  let navigate = useNavigate();

  const {AnalyticsEvent}=props;
  let location = useLocation();
  const params = getQueryParams(location.search);
  const business_currency = getBusinessInfo().currency;
  const dispatch = Actions.getDispatch(React.useContext);

  let payment_initial_data = {
    business_id: getBusinessInfo().id,
    payment_date: moment().format('YYYY-MM-DD'),

    party_id: null,
    party_selected: null,

    exchange_rate: 1,
    currency_id: business_currency.id,
    currency_code: business_currency.currency_code,

    has_rcm: false,
    account_id: null,
    account_selected: null,

    total_amount: 0,
    balance_amount: 0,
    total_payment_amount: 0,

    note: null,
    reference: null,
    place_of_supply_id: null,

    payment_method: "cash",
    payment_type: props.payment_type,
    is_amount_tax_inclusive: props.is_amount_tax_inclusive,

    is_advance: true,

    tax_id: null,
    selected_tax: [],
    tax_summary: {
      total: 0,
      CGST: 0,
      SGST: 0,
      IGST: 0
    }
  }

  const [isFormSubmitted, setFormSubmitted] = React.useState(false);
  const [paymentData, setPaymentData] = React.useState({ ...payment_initial_data });

  React.useEffect(() => {
    const apiResults = async (id) => {
      // let initial_payment_for_data = []

      setLoader(dispatch, Actions, true);
      breadCrumbsStateChangeManager(dispatch, 'Edit');

      let res = await apiAction({
        method: 'post',
        url: retrieve_payment(id),
        data: { business_id: getBusinessInfo().id },
      })

      if (res?.success) {
        const partyRes = await apiAction({
          method: "post",
          url: get_party(res?.result.party_id),
          data: { business_id: getBusinessInfo().id },
        });
        if (partyRes?.success) {

          setTimeout(() => {
            setLoader(dispatch, Actions, false)
            setPaymentData({
              ...payment_initial_data, ...res?.result,
              payment_id: res?.result.id,
              total_amount: res?.result.total_amount,
              refunded_amount: res?.result.refunded_amount,
              place_of_supply_id: res?.result.place_of_supply.id,
              total_payment_amount: res?.result.total_payment_amount,

              party_selected: { ...partyRes?.result },
              account_selected: { account_name: res?.result.account_name },

            })
          }, 0)
        }
      } else {
        setLoader(dispatch, Actions, false)
      }
    }
    if (params.advance_payment_id) {
      apiResults(params.advance_payment_id)
    }
    // eslint-disable-next-line
  }, [params.advance_payment_id])

  const analyticEvents = () => {
    if (params.advance_payment_id) {
        AnalyticsEvent({action:eventsNames.actions.EDIT,type:eventsNames.actions.ADVANCE})
    } else {
        AnalyticsEvent({action:eventsNames.actions.CREATE,type:eventsNames.actions.ADVANCE})
    }
}
  //////////////////////////////////////////////////////////////////////////////////////////
  const gst_registration_type = paymentData.party_selected && paymentData.party_selected.gst_registration_type;
  setIsVendorRegister(gst_registration_type);
  const isAsteriskMark = props.role === 'vendor' ? isVendorRegister : isBusinessRegistered()
  let is_Location = gst_registration_type === "overseas";
  let is_gst_with_zero = gst_registration_type === "overseas" || gst_registration_type === "sez";
  //////////////////////////////////////////////////////////////////////////////////////////
  const onSave = async ({ is_save_send = false }) => {
    console.log('===>Advance Payment',paymentData)

    setFormSubmitted(true)
    let validation_data = [...props.validation_data,
      isAsteriskMark && !is_Location && { key: "place_of_supply_id", message: `Please select ${params.role === 'vendor' ? 'source of supply' : 'place of supply'}` },
    ]
    const { isValid, message } = isFormValid(paymentData, validation_data)
    if (isValid) {
      analyticEvents()
      setLoader(dispatch, Actions, true)
      let res = await apiFormData({
        method: 'post',
        data: paymentData,
        url: params.advance_payment_id ? update_payment() : create_payment(),
      })
      if (res?.success) {
        setLoader(dispatch, Actions, false);
        stateChangeManager(dispatch, Actions, true, 'success', res?.status);
        if (is_save_send) {
          sendPaymentReceipt(res?.result.id);
        } else {
          props.onClose(res?.result.id);
          setLoader(dispatch, Actions, false);
        }

      } else {
        setLoader(dispatch, Actions, false);
        stateChangeManager(dispatch, Actions, true, 'error', res?.status);
      }
    } else {
      stateChangeManager(dispatch, Actions, true, 'error', message);
    }

  }

  const sendPaymentReceipt = async (payment_id) => {
    setSendMail(true);
    setSendMailId(payment_id);
  };

  const [sendMail, setSendMail] = React.useState(false);
  const [sendMailId, setSendMailId] = React.useState(false);
  return (
    <div>
      <CommonPreviewShare
        open={sendMail}
        id={sendMailId}
        type={'payment'}
        retrieve_Url={retrieve_payment}
        export_Url={export_payment_receipt}
        setOpen={(value) => { setSendMail(value); setSendMailId(null); props.onClose(sendMailId); }}
        file_key={props.role === 'vendor' ? `${payment_made_number}-${sendMailId}` : `${payment_received_number}-${sendMailId}`}
      />
      <CustomContainer maxWidth={"400"} sx={{ height: 'calc(100vh - 240px)' }}>
        <div style={{ paddingLeft: '20px', paddingRight: '20px', paddingBottom: '48px' }}>
          <BasicInformation
            role={props.role}
            taxText={props.taxText}
            dateText={props.dateText}
            nameText={props.nameText}
            placeText={props.placeText}
            amountText={props.amountText}
            isAsteriskMark={isAsteriskMark}
            accountText={props.accountText}
            isFormSubmitted={isFormSubmitted}
            payment_initial_data={payment_initial_data}
            disabled={params.advance_payment_id ? true : false}
            disabledPayment={paymentData.balance_amount !== paymentData.total_amount}

            is_Location={is_Location}
            is_gst_with_zero={is_gst_with_zero}

            paymentData={paymentData}
            setPaymentData={setPaymentData}
          />

        </div>
      </CustomContainer>
      {
        paymentData.party_id ?
          <CustomButtonContainer>
            <Buttons onSave={onSave} onCancel={() => navigate(-1)} />
          </CustomButtonContainer>
          : null
      }
    </div>
  )
}

export default AdvancePaymentCreate;

const BasicInformation = (props) => {
  const { is_Location, isAsteriskMark, is_gst_with_zero, role, dateText, nameText, amountText, accountText, placeText, taxText, paymentData, setPaymentData, disabled, isFormSubmitted, payment_initial_data, disabledPayment } = props


  let location = useLocation();
  const params = getQueryParams(location.search);
  const business_currency = getBusinessInfo().currency;

  const [currencyResult, setCurrencyResult] = React.useState([])
  React.useEffect(() => {
    const apiCurrencyResults = async () => {
      let res = await apiAction({
        // navigate: navigate,
        // dispatch: dispatch,
        url: get_list_of_currency(),
      })
      if (res?.success) {
        let results = res?.result.map((item) => {
          item['helperText'] = `${item.name} (${item.symbol})`;
          return item;
        }
        )
        setCurrencyResult(results);
      } else {

      }
    }
    apiCurrencyResults()
    // eslint-disable-next-line
  }, [])

  const [states, setStates] = React.useState([]);
  React.useEffect(() => {
    const getStates = async () => {
      let res = await apiAction({
        method: 'post',
        url: get_list_of_state(),
        data: { business_id: getBusinessInfo().id, }
      })
      if (res?.success) {
        setStates(res?.result.sort((a, b) => -b.name.localeCompare(a.name)))
      } else {

      }
    }
    getStates()
    // eslint-disable-next-line
  }, []);

  const [taxResult, setTaxResult] = React.useState([]);
  const place_of_supply_id = paymentData.place_of_supply_id;

  const taxList = filterTax(taxResult, place_of_supply_id, is_gst_with_zero)

  React.useEffect(() => {
    const apiResults = async () => {
      let res = await apiAction({
        method: 'post',
        url: get_list_of_tax(),
        data: {
          business_id: getBusinessInfo().id,
         },
      })
      // console.log('res', res)
      if (res?.success) {
        setTaxResult(res?.result)
      } else {

      }
    }
    apiResults()
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    const isVendor = role === 'vendor';
  
    const updateTaxId = () => {
      const defaultTax = findDefaultTax(taxList, is_gst_with_zero);
      if (defaultTax && isAsteriskMark) {
        setPaymentData((previous) => ({ ...previous, tax_id: defaultTax.id }));
      }
    };
  
    if (is_gst_with_zero && (isVendor && paymentData.has_rcm || !isVendor)) {
      updateTaxId();
    }
  }, [is_gst_with_zero, place_of_supply_id, paymentData.has_rcm]);


  const applyTax = (amount, tax_rate, is_amount_tax_inclusive) => {
    if (is_amount_tax_inclusive) {
      return parseFloat((parseFloat(amount ? amount : 0) - parseFloat((amount ? amount : 0) / (1 + tax_rate * 0.01)))).toFixed(2)
    } else {
      return parseFloat(parseFloat((amount ? amount : 0) * (tax_rate * 0.01)))
    }

  }

  const onTaxChange = (tax_details = [], total_amount = 0, is_amount_tax_inclusive = paymentData.is_amount_tax_inclusive) => {
    let tax_total_rate = tax_details.reduce((acc, tax_info) => acc + tax_info.rate, 0);
    let actual_amount = is_amount_tax_inclusive ? actualPrice(total_amount, tax_total_rate) : total_amount

    let total_tax = applyTax(actual_amount, tax_total_rate, false).toFloat();
    let distributed_tax_amount = tax_details.reduce((acc, tax_item) => acc + applyTax(actual_amount, tax_item.rate, false).toFloat(), 0)


    const accounts = {
      total: 0,
      CGST: 0,
      SGST: 0,
      IGST: 0
    };

    let isTruncated = false;
    tax_details.map((item) => {
      if (is_amount_tax_inclusive && total_tax !== distributed_tax_amount && !isTruncated) {
        accounts[item.tax_account] = applyTax(actual_amount, item.rate, false).toFloat() + (total_tax - distributed_tax_amount).toFloat();
        isTruncated = true
      } else {
        accounts[item.tax_account] = applyTax(actual_amount, item.rate, false).toFloat();
      }
    })
    return { ...paymentData.tax_summary, ...accounts, total: total_tax }
  }

  const onPartyMapping = (value) => {
    if (value) {
      paymentData.tax_id = null;
      paymentData.total_amount = 0;
      paymentData.exchange_rate = 1;
      paymentData.balance_amount = 0;
      paymentData.party_id = value.id;
      paymentData.party_selected = value;
      paymentData.total_payment_amount = 0;
      paymentData.place_of_supply_id = null;
      paymentData.currency_id = value.currency && value.currency.id;
      paymentData.currency_code = value.currency && value.currency.currency_code;
      paymentData.payment_method = value.preferred_payment_method ? value.preferred_payment_method : 'cash';

    } else {
      paymentData.tax_id = null;
      paymentData.party_id = null;
      paymentData.total_amount = 0;
      paymentData.exchange_rate = 1;
      paymentData.balance_amount = 0;
      paymentData.party_selected = null;
      paymentData.payment_method = 'cash';
      paymentData.total_payment_amount = 0;
      paymentData.place_of_supply_id = null;
      paymentData.currency_id = business_currency.id;
      paymentData.currency_code = business_currency.currency_code;

    }
    setPaymentData({ ...paymentData, })
  }

  const [editable, setEditable] = React.useState(false);

  return (
    <Grid container spacing={5}>
      <Grid container item xs={12} spacing={5}>

        {/* /////////////////////////////////////////////////// Party details ///////////////////////////////// */}
        <Grid item xs={12} sm={6}>
          <CommonAsyncDropdown
            id={'payment_party_dropdown'}
            dataTestId={'payment_party_dropdown'}
            autoSelect={false}
            autoFocus={true}
            disableClearable={false}
            optionLabel="display_name"
            noOptionsText={"No result found"}
            placeholder={'Select ' + role.capitalize()}
            validation={isFormSubmitted && !paymentData.party_id}

            item={{
              method: 'post',
              url: list_party(1),
              value: paymentData.party_selected,
              disabled: disabled ? { disabled } : false,
              sx: disabled ? { background: '#F1F3F4' } : {},
              label: <LabelWithAsteriskMark label={nameText} />,
              body: { is_inactive: false, business_id: getBusinessInfo().id, role: role },
              onChange: async (event, value) => {
                if (value) {
                  if (value.id !== paymentData.party_id) {
                    const partyRes = await apiAction({
                      method: "post",
                      url: get_party(value.id),
                      data: { business_id: getBusinessInfo().id },
                    });
                    if (partyRes?.success) {
                      onPartyMapping(partyRes?.result);
                    }
                  }
                } else {
                  onPartyMapping(value);
                }
              }
            }}
          />
        </Grid>

        {/* /////////////////////////////////////////////////// States ///////////////////////////////// */}
        <Grid item xs={12} sm={6}>
          <Dropdown
            id={'place_of_supply_dropdown'}
            dataTestId={'place_of_supply_dropdown'}

            results={states}
            disableClearable={false}
            placeholder={'Select ' + placeText}
            item={{ disabled: (disabledPayment || is_Location) }}
            label={<LabelWithAsteriskMark label={placeText} isAsteriskMark={isAsteriskMark} />}
            validation={isAsteriskMark && !is_Location && isFormSubmitted && !paymentData.place_of_supply_id}
            value={paymentData.place_of_supply_id ? states.find((item) => item.id === paymentData.place_of_supply_id) : null}
            setValue={(event, selected) => { setPaymentData({ ...paymentData, place_of_supply_id: selected ? selected.id : null, tax_id: null, selected_tax: [], tax_summary: payment_initial_data.tax_summary }) }}
          />
        </Grid>

        {/* /////////////////////////////////////////////////// Amount details ///////////////////////////////// */}
        <Grid item xs={12} sm={6} container>
          <Grid container item xs={12} sx={{}}>
            <Grid item xs={12} sm={4}>
              <CustomTypography
                text={<LabelWithAsteriskMark label={amountText} />}
                sx={{
                  fontSize: '14px',
                  color: '#000000',
                  fontWeight: '700',
                  lineHeight: '18px',
                  fontStyle: 'normal',
                  textTransform: "none",
                  fontFamily: "Noto Sans",
                  textTransform: 'capitalize',
                }}
              />
            </Grid>
            <Grid item xs={12} sm={8} sx={{ mb: -1.2, textAlign: { xs: 'start', sm: 'right' } }}>
              <ExchangeRateComponent data={paymentData} setData={setPaymentData} />
            </Grid>
          </Grid>

          <Grid container item xs={12} sx={{}}>
            <Grid item xs={9} sx={{ alignItems: 'center' }}>
              <Input
                editable={editable}
                id={'input_total_amount'}
                isSearchableComponent={true}
                isShowCommaSeparatedValue={true}
                dataTestId={'input_total_amount'}
                onFocus={() => { setEditable(true) }}
                currency_code={paymentData.currency_code}

                item={{
                  title: '',
                  type: 'number',
                  required: true,
                  fullWidth: true,
                  disabled: disabledPayment,
                  placeholder: 'Enter ' + amountText,
                  onBlur: (e) => { setEditable(false) },
                  validation: isFormSubmitted && !paymentData.total_amount,
                  value: paymentData.total_amount ? paymentData.total_amount : '',

                  onChange: (e) => {
                    if (e.target.value) {
                      paymentData.total_amount = limitDecimalPlaces(e.target.value);
                      paymentData.balance_amount = limitDecimalPlaces(e.target.value);
                      paymentData.tax_summary = onTaxChange(paymentData.selected_tax, paymentData.total_amount);
                    } else {
                      paymentData.total_amount = 0;
                      paymentData.balance_amount = 0;
                      paymentData.tax_summary = payment_initial_data.tax_summary;
                    }
                    setPaymentData({ ...paymentData, })
                  },
                }}


                onKeyPress={(event) => {
                  if (event?.key === '-' || event?.key === '+') {
                    event.preventDefault();
                  }
                }}
              />
            </Grid>
            <Grid item xs={3} sx={{ pl: 1, mt: 1, alignItems: 'center' }}>
              <CustomDefaultDropdown
                maxHeight={250}
                fullWidth={true}
                inputDisabled={true}
                results={currencyResult}
                labelKey='currency_code'
                disabledCloseIcon={true}
                id={'currency_dropdown'}
                placeholder='Select Currency'
                valueLabelKey={'currency_code'}
                dataTestId={'currency_dropdown'}
                // inputDisabled={!paymentData.party_id}
                // sx={{ background: !paymentData.party_id ? '#F1F3F4' : '' }}
                value={paymentData.currency_id ? currencyResult.find((item) => item.id === paymentData.currency_id) : null}
                setValue={(selectedCurrency) => {
                  if (selectedCurrency) {
                    setPaymentData({ ...paymentData, currency_code: selectedCurrency.currency_code, currency_id: selectedCurrency.id, })
                  }
                }}
              />
            </Grid>
          </Grid>
          <div>
            {disabledPayment &&
              <>
                <span style={{ color: 'red', fontSize: '10px', opacity: 0.6, }}>Unused Amount: {<CurrencyFormatter currency={paymentData.currency_code} amount={paymentData.balance_amount} />}*</span><br /></>
            }

            {
              (role === 'vendor' && isBusinessRegistered()) &&
              <FormControlLabel
                id={'has_rcm_checkbox'}
                dataTestId={'has_rcm_checkbox'}
                labelPlacement='end'
                disabled={disabledPayment}
                control={
                  <Checkbox sx={{}} checked={paymentData.has_rcm}
                    onChange={(event) => {
                      setPaymentData({
                        ...paymentData,
                        tax_id: null,
                        selected_tax: [],
                        has_rcm: event.target.checked,
                        tax_summary: payment_initial_data.tax_summary,
                      })
                    }} />
                }
                label={<span style={{
                  marginTop: '10px',
                  fontSize: '14px',
                  fontWeight: '700',
                  lineHeight: '19px',
                  letterSpacing: '0px',
                  fontFamily: 'Noto Sans',
                }}>This transaction is applicable for reverse charge</span>} />
            }
          </div>
        </Grid>
        {/* /////////////////////////////////////////////////// Tax ///////////////////////////////// */}
        <Grid item xs={12} sm={6}>
          {
            role === 'vendor' ?
              <Dropdown
                id={'tax_dropdown'}
                dataTestId={'tax_dropdown'}
                label={taxText}
                results={taxList}
                isfilterOptions='includes'
                disableClearable={false}
                placeholder={'Select ' + taxText}
                value={paymentData.tax_id ? taxList.find((item) => item.id === paymentData.tax_id) : null}
                item={{ disabled: is_gst_with_zero ? is_gst_with_zero : disabledPayment || !paymentData.has_rcm }}
                setValue={(event, selected) => {
                  if (selected) {
                    paymentData.tax_id = selected.id;
                    paymentData.selected_tax = selected.tax_details;
                    paymentData.tax_summary = onTaxChange(selected.tax_details, paymentData.total_amount);
                  } else {
                    paymentData.tax_id = null;
                    paymentData.selected_tax = [];
                    paymentData.tax_summary = payment_initial_data.tax_summary;

                  }
                  setPaymentData({ ...paymentData, })
                }}
              />
              :
              <Dropdown
                id={'tax_dropdown'}
                dataTestId={'tax_dropdown'}
                isfilterOptions='includes'
                label={taxText}
                results={taxList}
                disableClearable={false}
                placeholder={'Select ' + taxText}
                item={{ disabled: is_gst_with_zero ? is_gst_with_zero : disabledPayment }}
                value={paymentData.tax_id ? taxList.find((item) => item.id === paymentData.tax_id) : null}
                setValue={(event, selected) => {
                  if (selected) {
                    paymentData.tax_id = selected.id;
                    paymentData.selected_tax = selected.tax_details;
                    paymentData.tax_summary = onTaxChange(selected.tax_details, paymentData.total_amount);
                  } else {
                    paymentData.tax_id = null;
                    paymentData.selected_tax = [];
                    paymentData.tax_summary = payment_initial_data.tax_summary;

                  }
                  setPaymentData({ ...paymentData, })
                }}
              />
          }

        </Grid>
        {/* /////////////////////////////////////////////////// Date ///////////////////////////////// */}
        <Grid item xs={12} sm={6}>
          <DatePicker
            id={'payment_date_select'}
            dataTestId={'payment_date_select'}
            disabledCloseIcon={false}
            title={<LabelWithAsteriskMark label={dateText} />}
            validation={isFormSubmitted && !paymentData.payment_date}
            date={paymentData.payment_date ? moment(paymentData.payment_date) : null}
            setDate={(date) => { setPaymentData({ ...paymentData, payment_date: date ? date.format('YYYY-MM-DD') : null }) }}
          />
        </Grid>

        {/* /////////////////////////////////////////////////// Payment Mode ///////////////////////////////// */}
        <Grid item xs={12} sm={6}>
          <Dropdown
            id={'payment_method_dropdown'}
            dataTestId={'payment_method_dropdown'}
            disableClearable={false}
            results={listPaymentMethod}
            placeholder={'Select Payment mode'}
            label={<LabelWithAsteriskMark label={'Payment Mode'} />}
            validation={isFormSubmitted && !paymentData.payment_method}
            value={paymentData.payment_method ? getPaymentType(paymentData.payment_method) : null}
            setValue={(event, selected) => { setPaymentData({ ...paymentData, payment_method: selected ? selected.value : null }) }}
          />
        </Grid>

        {/* /////////////////////////////////////////////////// Account ///////////////////////////////// */}
        <Grid item xs={12} sm={6}>

          <AsyncDropdown
            sx={{}}
            fullWidth={true}
            autoFocus={false}
            newResults={null}
            disabledCloseIcon={false}
            isGroupHeaderSticky={true}
            id={'account_selected_dropdown'}
            dataTestId={'account_selected_dropdown'}
            title={<LabelWithAsteriskMark label={accountText} />}
            validation={isFormSubmitted && !paymentData.account_id}

            selectedValue={paymentData?.account_selected}
            setSelectedValue={(value) => {
              setPaymentData({
                ...paymentData,
                account_id: value?.id,
                account_selected: value,
              })
            }}

            valueLabelKey='id'
            uniqueOptionKey='id'
            searchKey='account_name'
            optionLabelKey='account_name'
            placeholder='Select the Account'
            optionGroupLabelKey='account_type'
            playLoad={{ account_type: ['ASSET'] }}
            URL={get_chart_of_account_nested_nodes(1)}
          />
        </Grid>

        {/* /////////////////////////////////////////////////// Reference ///////////////////////////////// */}
        <Grid item xs={12} sm={6}>
          <Input
            id={'input_reference'}
            dataTestId={'input_reference'}
            item={{
              type: 'text',
              fullWidth: true,
              required: true,
              title: 'Reference#',
              placeholder: 'enter Reference',
              value: paymentData.reference ? paymentData.reference : '',
              onChange: (e) => { setPaymentData({ ...paymentData, reference: e.target.value }) },
            }}
          />
        </Grid>

        {/* /////////////////////////////////////////////////// Notes ///////////////////////////////// */}
        <Grid item xs={12} sm={6}>
          <Input
            id={'input_note'}
            dataTestId={'input_note'}
            item={{
              rows: 4,
              type: 'text',
              title: 'Note',
              required: true,
              multiline: true,
              fullWidth: true,
              placeholder: 'Enter Notes',
              value: paymentData.note ? paymentData.note : '',
              onChange: (e) => setPaymentData({ ...paymentData, note: e.target.value }),
            }}
          />
        </Grid>

        {/* /////////////////////////////////////////////////// attachment ///////////////////////////////// */}
        <Grid item xs={12} sm={6}>
          <InputFile
            onFileUpload={(file) => {
              setPaymentData({ ...paymentData, attachment: file })
            }}
            attachment={paymentData.attachment}
            onFileRemove={() => {
              setPaymentData({ ...paymentData, attachment: null })
            }}
          />
        </Grid>

      </Grid>
    </Grid>
  )
}

const Buttons = (props) => {
  const { onSave, onCancel } = props
  let location = useLocation();
  const params = getQueryParams(location.search)
  return (
    <Grid container >
      <Grid item xs={6} id={'cancel_btn'} dataTestId={'cancel_btn'} sx={{ display: '-webkit-flex', justifyContent: 'start', }}><CustomButton variant='outlined' sx={{ textTransform: 'none', '&:hover': { backgroundColor: '#e8f3ff' } }} btnLabel='Cancel' onClick={onCancel} /></Grid>
      {/* <Grid item xs={6} id={'save_btn'} dataTestId={'save_btn'} sx={{ display: '-webkit-flex', justifyContent: 'right', }}><CustomButton variant='contained' sx={{ textTransform: 'none', }} btnLabel={params.advance_payment_id ? 'Update' : 'Save'} onClick={onSave} /></Grid> */}
      <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'right', }}>
        <CustomGroupButton
          options={[
            {
              id: "save_btn",
              dataTestId: "save_btn",
              label: params.advance_payment_id ? 'Update' : 'Save',
              condition: () => { onSave({ is_save_send: false }); },
            },
            {
              id: "save_send_btn",
              dataTestId: "save_send_btn",
              label: params.advance_payment_id ? "Update and Send" : "Save and Send",
              condition: () => { onSave({ is_save_send: true }); },
            },
          ]}
        />
      </Grid>
    </Grid>
  )
}

const ExchangeRateComponent = (props) => {
  const { data, setData } = props;
  const { currency } = getBusinessInfo();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = Actions.getDispatch(React.useContext);
  const [validation, setValidation] = React.useState(false)


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    if (data.exchange_rate && data.exchange_rate !== 0) {
      setAnchorEl(null);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'scroll-popover' : undefined;

  let current_currency_id = data.currency_id;
  let business_currency_id = currency && currency.id;
  return (
    current_currency_id && business_currency_id && (current_currency_id !== business_currency_id) &&
    <React.Fragment>
      <CustomTypography
        id={"total_with_exchange_rate"}
        dataTestId={"total_with_exchange_rate"}
        text={<span style={{ display: 'inline-flex' }}>Currency conversion: <CurrencyFormatter amount={data.total_amount * data.exchange_rate} currency={currency.currency_code} /> ({'INR'}) at {data.exchange_rate} <span className='hover-underline-class' style={{ color: '#2464EB', cursor: 'pointer' }}><ModeEditOutlineIcon onClick={handleClick} sx={{ ml: 0.5, fontSize: '18px' }} /></span> </span>}
        sx={{
          mt: 0.5,
          color: "#000000",
          fontSize: "12px",
          fontWeight: "700",
          lineHeight: "14px",
          fontStyle: "normal",
          fontFamily: "Noto Sans",
        }}
      />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          style: {
            // maxHeight: 200,
            // width: 200,
          },
        }}
      >
        <Box sx={{ p: 2, pt: 0 }}>
          <Input
            id={"input_exchange_rate"}
            isSearchableComponent={true}
            dataTestId={"input_exchange_rate"}
            item={{
              type: "number",
              required: true,
              fullWidth: true,
              placeholder: "",
              title: "Order Number",
              titleVisibility: "hidden",
              validation: validation && !data.exchange_rate,
              value: data.exchange_rate !== null ? data.exchange_rate : '',
              onChange: (e) => {
                setValidation(false);
                if (isNumber(e.target.value)) {
                  data.exchange_rate = limitDecimalPlaces(e.target.value, 6);
                } else {
                  data.exchange_rate = null;
                }
                setData({ ...data })
              },
              onBlur: (e) => {
                if (isNumber(e.target.value)) {
                  if (Number(e.target.value) === 0) {
                    setValidation(true);
                    stateChangeManager(dispatch, Actions, true, "error", 'Exchange rate can not be zero');
                  }
                  data.exchange_rate = limitDecimalPlaces(e.target.value, 6);
                } else {
                  setValidation(true);
                  data.exchange_rate = null;
                  stateChangeManager(dispatch, Actions, true, "error", 'Exchange rate can not be empty');
                }
                setData({ ...data })
              },
            }}
            onKeyPress={(event) => {
              if (
                event?.key === "-" ||
                event?.key === "+" ||
                event?.key === "e" ||
                event?.key === "E"
              ) {
                event.preventDefault();
              }
            }}
          />
        </Box>
      </Popover>
    </React.Fragment>
  )
}