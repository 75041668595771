import React from "react";
import { routesName } from "../../../../../../config/routesName";
import Transactions from "../../../../bulkStatementUpload/mappings/Transactions";
import {import_party, validate_party_import_file } from "../../../../../../api/urls";


const CustomersPreview = (props) => {

    return (
        <Transactions 
            title={"Customers"}
            isPerformValidation={true} 
            role={"customer"} url={import_party()} 
            validationUrl={validate_party_import_file()} 
            nextPagePath={routesName.invoicingSalesCustomer.path} 
            onFileErrorPath={routesName.invoicingSalesCustomersImportFile.path} 
            onCancelPath={routesName.invoicingSalesCustomersImportMapping.path}  
        />
    )
}

export default CustomersPreview