import React from "react";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { AnalyticsEvent } from "../../firebase/firebaseAnalytics";
import {Box, Divider, IconButton, MenuItem, Popover } from "@mui/material";

const ListItemActions = (props) => {
    const { actions, index } = props
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const action_style={
        p: 1,
        color:'#141414',
        fontWeight: 500,
        display: 'block',
        fontSize: '14px',
        cursor: 'pointer',
        lineHeight: '12px',
        textDecoration: 'none',
        ":hover": { backgroundColor: "#f5f5f5",}
}
    return (
        <Box>
            <IconButton
                size="small"
                onClick={handleMenu}
                id={`action_button_${index}`}
                dataTestId={`action_button_${index}`}
                className={`action_button_${index}`}
                sx={{border: '1px solid #2464EB', '&:hover': { backgroundColor: '#e8f3ff' } }}
            >
                <ArrowDropDownIcon sx={{ color: '#2464EB' }} />
            </IconButton>
            <Popover
                sx={{ mt: '35px' }}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                // keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                PaperProps={{
                    style: {
                        minWidth: 140,
                        // minHeight: 60,

                    },
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                id={`list_action_popover`}
                dataTestId={`list_action_popover`}
            >
                {
                    actions.map((action, index) => {
                        return action ? <React.Fragment key={index}>
                            {
                                action.showDivider ? <>
                                    <Divider /><MenuItem id={'list_action_item_' + index} dataTestId={'list_action_item_' + index} disabled={action.disabled} sx={{...action_style, mt:actions[0]?-1:0, color: 'red' }} onClick={() => { action.onClick(); handleClose() }}>{action.name}</MenuItem>
                                </>
                                    :
                                    <MenuItem
                                    sx={{...action_style,}}
                                        id={'list_action_item_' + index}
                                        dataTestId={'list_action_item_' + index}
                                        disabled={action.disabled}
                                        onClick={() => {
                                            action.onClick();
                                            handleClose()
                                            // AnalyticsEvent("list_item_action", {action:action.name})
                                        }}
                                    >
                                        {action.name}
                                    </MenuItem>
                            }</React.Fragment> : null
                    })
                }
            </Popover>
        </Box>
    )
}

export default ListItemActions