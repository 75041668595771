export const fixed_table_key = {
    table_3_1: [
        {
            label: `Nature of Supply`,
            field: `nature_of_supply`,

            date: false,
            align: 'left',
            numberWithCommas: false,
        }, {
            label: `Taxable Value`,
            field: `taxable_value`,

            date: false,
            align: 'right',
            numberWithCommas: true,
        }, {
            label: `Integrated Tax`,
            field: `integrated_tax`,

            date: false,
            align: 'right',
            numberWithCommas: true,
        }, {
            label: `Central Tax`,
            field: `central_tax`,

            date: false,
            align: 'right',
            numberWithCommas: true,
        }, {
            label: `State/UT Tax`,
            field: `state_ut_tax`,

            date: false,
            align: 'right',
            numberWithCommas: true,
        }, {
            label: `Cess`,
            field: `cess`,

            date: false,
            align: 'right',
            numberWithCommas: true,
        },
    ],

    table_3_1_1: [
        {
            label: `Description`,
            field: `nature_of_supply`,

            date: false,
            align: 'left',
            numberWithCommas: false,
        }, {
            label: `Taxable Value`,
            field: `taxable_value`,

            date: false,
            align: 'right',
            numberWithCommas: true,
        }, {
            label: `Integrated Tax`,
            field: `integrated_tax`,

            date: false,
            align: 'right',
            numberWithCommas: true,
        }, {
            label: `Central Tax`,
            field: `central_tax`,

            date: false,
            align: 'right',
            numberWithCommas: true,
        }, {
            label: `State/UT Tax`,
            field: `state_ut_tax`,

            date: false,
            align: 'right',
            numberWithCommas: true,
        }, {
            label: `Cess`,
            field: `cess`,

            date: false,
            align: 'right',
            numberWithCommas: true,
        },
    ],

    table_3_2: [
        {
            label: ``,
            field: `section_name`,

            date: false,
            align: 'left',
            numberWithCommas: false,
        }, {
            label: `Place of supply`,
            field: `nature_of_supply`,

            date: false,
            align: 'left',
            numberWithCommas: false,
        }, {
            label: `Taxable Value`,
            field: `central_tax`,

            date: false,
            align: 'right',
            numberWithCommas: true,
        }, {
            label: `Integrated Tax`,
            field: `state_ut_tax`,

            date: false,
            align: 'right',
            numberWithCommas: true,
        }
    ],

    table_4: [
        {
            label: `Details`,
            field: `details`,

            date: false,
            align: 'left',
            numberWithCommas: false,
        }, {
            label: `Taxable Value`,
            field: `taxable_value`,

            date: false,
            align: 'right',
            numberWithCommas: true,
        }, {
            label: `Integrated Tax`,
            field: `integrated_tax`,

            date: false,
            align: 'right',
            numberWithCommas: true,
        }, {
            label: `Central Tax`,
            field: `central_tax`,

            date: false,
            align: 'right',
            numberWithCommas: true,
        }, {
            label: `State/UT Tax`,
            field: `state_ut_tax`,

            date: false,
            align: 'right',
            numberWithCommas: true,
        }, {
            label: `Cess`,
            field: `cess`,

            date: false,
            align: 'right',
            numberWithCommas: true,
        },
    ],

    table_5:[
        {
            label: `Nature of Supply`,
            field: `nature_of_supply`,

            date: false,
            align: 'left',
            numberWithCommas: false,
        }, {
            label: `Inter-State Supplies`,
            field: `inter-state_supply`,

            date: false,
            align: 'right',
            numberWithCommas: true,
        }, {
            label: `Intra-State Supplies`,
            field: `intra-state_supply`,

            date: false,
            align: 'right',
            numberWithCommas: true,
        }
    ]
}