import React from 'react';
import moment from 'moment';
import { apiAction } from "../../../../../api/api";
import ReportsTitle from '../../common/ReportsTitle';
import * as Actions from '../../../../../state/Actions';
import ReportsSettingButtons from '../../ReportsSettingButtons';
import { getBusinessInfo } from '../../../../../config/cookiesInfo';
import CurrencyFormatter from '../../../../common/CurrencyFormatter';
import CommonCustomDateRange from '../../../../common/CommonCustomDateRange';
import CustomTypography from '../../../../custom/typography/CustomTypography';
import { CustomContainer } from '../../../../custom/container/CustomContainer';

import {
    export_balance_sheet,
    get_balance_sheet_statement
} from "../../../../../api/urls";

import {
    setLoader,
    date_range,
    getDateFormat,
} from '../../../../../utils/Utils';

import {
    Box,
    Grid,
    Collapse,
} from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


const HorizontalBalanceSheet = () => {
    const stateAction = Actions.getState(React.useContext);
    const dispatch = Actions.getDispatch(React.useContext);

    const { selectedDateRange } = stateAction;
    let filter_date_range = date_range().find((item) => item.name === selectedDateRange)
    const default_date_range = filter_date_range ? filter_date_range : date_range()[2]

    const [results, setResults] = React.useState();
    const [isZero, setIsZero] = React.useState(false);
    const [toDate, setToDate] = React.useState(moment());
    const [isDetailed, setIsDetailed] = React.useState(false);
    const [dateRange, setDateRange] = React.useState(default_date_range);



    React.useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [dateRange, toDate,]);

    const fetchData = () => {
        if (dateRange) {
            if (dateRange.name !== "Custom") {
                getApiResults(dateRange.dates.from_date, dateRange.dates.to_date)
            } else {
                getApiResults(null, toDate.format('YYYY-MM-DD'))
            }
        }
    }

    const getApiResults = async (from_date, to_date) => {
        setLoader(dispatch, Actions, true)
        let res = await apiAction({
            method: 'post',
            url: get_balance_sheet_statement(),
            data: {
                to_date: to_date,
                from_date: from_date,
                business_id: getBusinessInfo().id,
            },
        })

        if (res?.success) {
            setResults(res?.result.components);
            setLoader(dispatch, Actions, false);
        } else {
            setLoader(dispatch, Actions, false);
        }
    }


    return (
        results &&
        <div>
            <ReportsSettingButtons
                accountMappingData={{
                    refreshPage: () => fetchData(),
                    mapping_type: 'balance_sheet',
                }}


                zeroData={{
                    value: isZero,
                    setValue: (value) => setIsZero(value),
                }}

                labelData={{
                    value: isDetailed,
                    labelName: 'Detailed',
                    title: 'Export Preference',
                    setValue: (value) => setIsDetailed(value),
                }}
                exportData={{ url: export_balance_sheet(), data: dateRange.dates ? { ...dateRange.dates, is_detailed: isDetailed, display_zero_balance_account: !isZero, is_horizontal: true } : { to_date: toDate.format('YYYY-MM-DD'), from_date: null, is_detailed: isDetailed, display_zero_balance_account: !isZero, is_horizontal: true } }}
            />

            <CustomContainer maxWidth={"400"} sx={{ height: 'calc(100vh - 134px)' }}>
                <div style={{ paddingLeft: '16px', paddingRight: '16px' }}>
                    <Grid container spacing={0} sx={{ pb: 2 }} style={{
                        alignItems: 'center',
                        justifyItems: 'center',
                        alignContent: 'space-evenly',
                        justifyContent: 'space-evenly',
                    }}>
                        <Grid item xs={12} sm={4}>
                            <ReportsTitle
                                dateText={'As of '}
                                title={'Horizontal Balance Sheet'}
                                date={dateRange.dates ? moment(dateRange.dates.to_date).format(getDateFormat()) : toDate.format(getDateFormat())}
                            />
                        </Grid>
                        <Grid container spacing={1} item xs={12} sm={8} sx={{ justifyContent: 'end', }}>
                            <CommonCustomDateRange
                                setPage={() => { }}
                                date_range={date_range()}

                                toDate={toDate}
                                setToDate={setToDate}

                                fromDate={null}
                                setFromDate={() => { }}

                                dateRange={dateRange}
                                setDateRange={setDateRange}

                                isAsOff={true}
                            />
                        </Grid>
                    </Grid>
                </div>
                <Body results={results} isZero={isZero} />
            </CustomContainer>

        </div>
    )
}

export default HorizontalBalanceSheet;

const Body = (props) => {
    const { isZero, results } = props;

    return (

        <Box sx={{ p: 2 }}>
            <Grid container sx={{ border: '2px solid #F5F5F5' }}>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={5.9} sx={{ pl: 1, pr: 1, }}>
                            <Header title={`Liabilities & Equities`} />
                        </Grid>
                        <Grid item xs={6.1} sx={{ pl: 1, pr: 1, borderLeft: '2px solid #F5F5F5' }}>
                            <Header title={`Assets`} />
                        </Grid>
                    </Grid>
                </Grid>


                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={5.9} sx={{ pl: 1, pr: 1, }}>
                            <Content data={[{ name: 'LIABILITIES', value: results.liabilities }, { name: 'EQUITIES', value: results.equities }]} isZero={isZero} />
                        </Grid>
                        <Grid item xs={6.1} sx={{ pl: 1, pr: 1, borderLeft: '2px solid #F5F5F5' }}>
                            <Content data={[{ name: 'ASSETS', value: results.assets }]} isZero={isZero} />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={5.9} sx={{ pl: 1, pr: 1, }}>
                            <Footer total={{ name: 'Total Liabilities & Equities', value: results.total_liabilities_and_equities }} />
                        </Grid>
                        <Grid item xs={6.1} sx={{ pl: 1, pr: 1, borderLeft: '2px solid #F5F5F5' }}>
                            <Footer total={{ name: 'Total Assets', value: results.total_assets }} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

const Header = (props) => {
    const { title } = props

    return (
        <Box sx={{ borderBottom: '2px solid #F5F5F5' }}>
            <Grid container sx={{ p: 2 }}>
                <Grid item xs={12} sx={{ textAlign: 'left' }}>
                    <CustomTypography
                        text={title}
                        sx={{
                            color: '#141414',
                            fontSize: '16px',
                            fontWeight: '700',
                            lineHeight: '27px',
                            fontStyle: 'normal',
                            textTransform: 'none',
                            fontFamily: "Noto Sans",
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

const Footer = (props) => {
    const { total } = props

    return (
        <Box sx={{ borderTop: '2px solid #F5F5F5' }}>
            <Grid container sx={{ p: 2 }}>
                <Grid item xs={6} sx={{ textAlign: 'left' }}>
                    <CustomTypography
                        text={total.name}
                        sx={{
                            color: '#141414',
                            fontSize: '16px',
                            fontWeight: '700',
                            lineHeight: '27px',
                            fontStyle: 'normal',
                            textTransform: 'none',
                            fontFamily: "Noto Sans",
                        }}
                    />
                </Grid>
                <Grid item xs={6} sx={{ textAlign: 'right' }}>
                    <CustomTypography
                        text={<CurrencyFormatter amount={total.value} />}
                        sx={{
                            color: '#141414',
                            fontSize: '16px',
                            fontWeight: '700',
                            lineHeight: '27px',
                            fontStyle: 'normal',
                            textTransform: 'none',
                            fontFamily: "Noto Sans",
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

const Content = (props) => {
    const { isZero, data } = props;
    return (
        data.map((item, index) => {
            return (
                <Box sx={{ p: 1, }} key={index}>
                    <Box sx={{ borderBottom: '2px solid #F5F5F5', borderTop: index ? '2px solid #F5F5F5' : '', pt: index ? 1 : 0, }}>
                        <Grid container sx={{ p: 1, pt: 0, pb: 0 }}>
                            <Grid item xs={12} sx={{ textAlign: 'left' }}>
                                <CustomTypography
                                    text={item.name}
                                    sx={{
                                        pl: 1,
                                        pb: 1,
                                        color: '#141414',
                                        fontSize: '14px',
                                        fontWeight: '700',
                                        lineHeight: '27px',
                                        fontStyle: 'normal',
                                        textTransform: 'none',
                                        fontFamily: "Noto Sans",
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    {
                        item.value.map((value, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <CollapseContentData {...value} index={index} isZero={isZero} />
                                </React.Fragment>
                            )
                        })
                    }
                </Box>
            )
        })
    )
}

const CollapseContentData = (props) => {
    const { isZero, index, component_details = [], component_name, has_account, marginLeft = 1, component_balance } = props;
    const [open, setOpen] = React.useState(false);
    const handleChange = () => {
        setOpen((prev) => !prev);
    };

    React.useEffect(() => {
        if (isZero) {
            setOpen(false)
        }
    }, [isZero])

    let hide_icons = has_account ? component_balance !== 0 : true;
    let is_has_account = component_name && component_details.length !== 0;

    return (
        <React.Fragment>
            <Box sx={{ ml: marginLeft, borderTop: index ? '1px solid #F5F5F5' : '' }}>
                <Grid container sx={{ p: 2, }}>
                    <Grid item xs={6} sx={{ display: 'flex', textAlign: 'left' }} >
                        <CustomTypography
                            text={
                                <span onClick={() => {
                                    isZero ?
                                        (hide_icons && component_details.length !== 0 && is_has_account) && handleChange()
                                        :
                                        is_has_account && handleChange()
                                }}>
                                    {component_name}
                                    {isZero ?
                                        (hide_icons && component_details.length !== 0 && is_has_account) && <Icon open={open} />
                                        :
                                        is_has_account && <Icon open={open} />
                                    }
                                </span>
                            }
                            sx={{
                                color: '#141414',
                                fontSize: '14px',
                                fontWeight: '700',
                                lineHeight: '27px',
                                whiteSpace: 'nowrap',
                                fontStyle: 'normal',
                                textTransform: 'none',
                                fontFamily: "Noto Sans",
                                cursor: isZero ? (hide_icons && component_details.length !== 0 && is_has_account ? 'pointer' : '') : (is_has_account ? 'pointer' : ''),
                            }}
                        />
                    </Grid>
                    {
                        !open ?
                            <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                <CustomTypography
                                    text={<CurrencyFormatter amount={component_balance} />}
                                    sx={{
                                        color: '#141414',
                                        fontSize: '14px',
                                        fontWeight: '700',
                                        lineHeight: '27px',
                                        fontStyle: 'normal',
                                        textTransform: 'none',
                                        fontFamily: "Noto Sans",
                                        visibility: 'visible',

                                    }}
                                />
                            </Grid>
                            : null
                    }
                </Grid>
            </Box>

            {/* use the recursion and show the end accounts */}
            {
                is_has_account ?
                    <React.Fragment>
                        {
                            component_details.map((componentItem, componentIndex) => {
                                return (
                                    <React.Fragment key={componentItem.component_name}>
                                        <Collapse in={open} sx={{}}>
                                            {
                                                has_account ?
                                                    <Box sx={{ ml: marginLeft - 3 }}>
                                                        {
                                                            isZero ?
                                                                componentItem.account_balance !== 0 &&
                                                                <Accounts componentItem={componentItem} />
                                                                :
                                                                <Accounts componentItem={componentItem} />
                                                        }
                                                    </Box>
                                                    :
                                                    <CollapseContentData {...componentItem} index={componentIndex} marginLeft={marginLeft + 1.5} isZero={isZero} />
                                            }
                                        </Collapse>
                                    </React.Fragment>
                                )
                            })
                        }
                        {is_has_account && open &&
                            < Box sx={{ mt: 2, borderTop: '2px solid #F5F5F5', borderBottom: '1px solid #F5F5F5' }}>
                                <Grid container sx={{ p: 2, pt: 0, pb: 0 }}>
                                    <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                        <CustomTypography
                                            text={'Total ' + component_name}
                                            sx={{
                                                pt: 1,
                                                pb: .5,
                                                color: '#141414',
                                                fontSize: '12px',
                                                fontWeight: '700',
                                                lineHeight: '27px',
                                                fontStyle: 'normal',
                                                textTransform: 'none',
                                                fontFamily: "Noto Sans",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                        <CustomTypography
                                            text={<CurrencyFormatter amount={component_balance} />}
                                            sx={{
                                                pt: 1,
                                                pb: .5,
                                                color: '#141414',
                                                fontSize: '12px',
                                                fontWeight: '700',
                                                lineHeight: '27px',
                                                fontStyle: 'normal',
                                                textTransform: 'none',
                                                fontFamily: "Noto Sans",
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        }
                    </React.Fragment>
                    : null

            }


        </React.Fragment >
    )
}


const Accounts = (props) => {
    const { componentItem } = props;
    return (
        <Grid container sx={{ p: 2, pt: 0, pb: 0, }}>
            <Grid item xs={6} sx={{ pl: 1, textAlign: 'left' }}>
                <CustomTypography
                    text={'• ' + componentItem.business_account}
                    sx={{
                        pt: .5,
                        pl: 3,
                        color: '#141414',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '27px',
                        fontStyle: 'normal',
                        textTransform: 'none',
                        fontFamily: "Noto Sans",

                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                    }}
                />
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'right' }}>
                <CustomTypography
                    text={<CurrencyFormatter amount={componentItem.account_balance} />}
                    sx={{
                        pt: .5,
                        color: '#141414',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '27px',
                        fontStyle: 'normal',
                        textTransform: 'none',
                        fontFamily: "Noto Sans",
                    }}
                />
            </Grid>
        </Grid>
    )
}


const Icon = (props) => {
    const { open } = props;
    return (
        open ?
            <ArrowDropUpIcon fontSize='small' sx={{ mb: -0.8, }} />
            :
            <ArrowDropDownIcon fontSize='small' sx={{ mb: -0.8, }} />
    )
}