import React from 'react';
import moment from 'moment/moment';
import Input from '../../../common/Input';
import { useNavigate } from 'react-router-dom';
import { apiAction } from '../../../../api/api';
import DateRange from '../../../common/DateRange';
import DatePicker from '../../../common/DatePicker';
import * as Actions from '../../../../state/Actions';
import CustomTitle from '../../../common/CustomTitle';
import AsyncDropdown from '../../../common/AsyncDropdown';
import { listPaymentMethod } from '../../../../utils/Constant';
import CustomButton from '../../../custom/button/CustomButton';
import { getBusinessInfo } from '../../../../config/cookiesInfo';
import CurrencyFormatter from '../../../common/CurrencyFormatter';
import { LabelWithAsteriskMark } from '../../../common/CommonLabel';
import { eventsNames } from '../../../../firebase/firebaseAnalytics';
import CustomTypography from '../../../custom/typography/CustomTypography';

import {
    CustomContainer,
    CustomTitleContainer,
    CustomButtonContainer,
} from '../../../custom/container/CustomContainer';

import {
    isNumber,
    isFormValid,
    getPaymentType,
    limitDecimalPlaces,
    stateChangeManager,
} from '../../../../utils/Utils';

import {
    get_chart_of_account,
    create_refund_payment,
    get_chart_of_account_nested_nodes,
} from '../../../../api/urls';

import {
    Box,
    Grid,
    Alert,
    Popover,
    AlertTitle,
} from '@mui/material';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';

const Dropdown = DateRange;

const Refund = (props) => {
    let navigate = useNavigate();

    const { initial_refund_data, AnalyticsEvent } = props
    const dispatch = Actions.getDispatch(React.useContext);

    const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);
    const [refundData, setRefundData] = React.useState({ ...initial_refund_data });

    const onSave = async () => {
        setIsFormSubmitted(true);
        let validation_data = [
            { key: "", validation: refundData.total_refund_amount === 0, message: 'Amount field can not be empty.' },
            { key: "", validation: refundData.total_refund_amount > refundData.credit_remaining, message: `Something's not quite right!` },
            { key: "account_id", message: refundData.payment_type === 'pay' ? 'From Account is missing' : 'Deposit To missing' },
        ]
        const { isValid, message } = isFormValid(refundData, validation_data);
        if (isValid) {
            if (AnalyticsEvent) {
                AnalyticsEvent({ action: eventsNames.actions.REFUND })
            }
            let res = await apiAction({
                method: 'post',
                data: { ...refundData },
                url: create_refund_payment(),

            })
            if (res?.success) {
                props.redirect()
                setIsFormSubmitted(false)
                stateChangeManager(dispatch, Actions, true, 'success', res?.status);
            } else {
                setIsFormSubmitted(false)
                stateChangeManager(dispatch, Actions, true, 'error', res?.status);
            }
        } else {
            stateChangeManager(dispatch, Actions, true, 'error', message);
        }
    }

    return (
        <div>
            <CustomTitleContainer>
                <Grid container spacing={0} style={{
                    alignItems: 'center',
                    justifyItems: 'center',
                    alignContent: 'space-evenly',
                    justifyContent: 'space-evenly',
                }}>
                    <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'start', }}>
                        <CustomTitle title={`Refund (${props.title})`} />
                    </Grid>
                    <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'end', }}>

                    </Grid>
                </Grid>
            </CustomTitleContainer>
            {
                refundData.total_refund_amount > refundData.credit_remaining ?
                    <Box sx={{ mt: 2 }}>
                        <Alert severity="error">
                            <AlertTitle><strong>Something's not quite right</strong></AlertTitle>
                            The enter amount {<CurrencyFormatter amount={refundData.total_refund_amount} currency={refundData.currency_code} />} can not be greater than balance amount {<CurrencyFormatter amount={refundData.credit_remaining} currency={refundData.currency_code} />}. Please change the amount.
                        </Alert>
                    </Box>
                    : null
            }
            <CustomContainer maxWidth={"400"} sx={{}}>
                <div style={{ paddingLeft: '20px', paddingRight: '20px', paddingBottom: '48px' }}>
                    <CreateRefund
                        refundData={refundData}
                        setRefundData={setRefundData}
                        isFormSubmitted={isFormSubmitted}
                        initial_refund_data={initial_refund_data}
                    />
                </div>
            </CustomContainer>
            <CustomButtonContainer>
                <Buttons onSave={onSave} onCancel={() => { navigate(-1); }}
                />
            </CustomButtonContainer>

        </div >
    )
}

export default Refund;

const CreateRefund = (props) => {
    const { isFormSubmitted, refundData, setRefundData } = props;


    const [editable, setEditable] = React.useState(false);

    return (
        <Grid container >
            <Grid container item xs={12} spacing={3} >
                <Grid item xs={4.5}>
                    <DatePicker
                        id={'refund_date_select'}
                        dataTestId={'refund_date_select'}
                        placeholder=''
                        validation={false}
                        disabledCloseIcon={true}
                        title={<LabelWithAsteriskMark label={'Refunded on'} />}
                        date={refundData.refund_date ? moment(refundData.refund_date) : null}
                        setDate={(date) => { setRefundData({ ...refundData, refund_date: date.format('YYYY-MM-DD') }) }}
                    />
                </Grid>
                <Grid item xs={4.5}>
                    <Dropdown
                        id={'payment_method_dropdown'}
                        dataTestId={'payment_method_dropdown'}
                        disableClearable={true}
                        results={listPaymentMethod}
                        label='Preferred Payment Method'
                        placeholder='Select Preferred Payment Method'
                        setValue={(event, selected) => { setRefundData({ ...refundData, payment_method: selected.value }) }}
                        value={refundData.payment_method ? getPaymentType(refundData.payment_method) : null}
                    />
                </Grid>
                <Grid item xs={4.5}>
                    <AsyncDropdown
                        sx={{}}
                        fullWidth={true}
                        autoFocus={false}
                        newResults={null}
                        disabledCloseIcon={false}
                        isGroupHeaderSticky={true}
                        id={'account_dropdown'}
                        dataTestId={'account_dropdown'}
                        validation={isFormSubmitted && !refundData?.account_id}
                        title={<LabelWithAsteriskMark label={refundData.payment_type === 'pay' ? 'From Account' : 'Deposit To'} />}

                        selectedValue={refundData?.selected_account}
                        setSelectedValue={(value) => {
                            setRefundData({
                                ...refundData,
                                selected_account: value,
                                account_id: value ? value.id : null,
                            })
                        }}

                        valueLabelKey='id'
                        uniqueOptionKey='id'
                        searchKey='account_name'
                        optionLabelKey='account_name'
                        placeholder='Select the Account'
                        playLoad={{ account_type: ['ASSET'] }}
                        optionGroupLabelKey='account_type'
                        URL={get_chart_of_account_nested_nodes(1)}

                    />
                </Grid>
                <Grid item xs={4.5}>
                    <Input
                        id={'input_total_refund_amount'}
                        dataTestId={'input_total_refund_amount'}

                        editable={editable}
                        isSearchableComponent={true}
                        isShowCommaSeparatedValue={true}
                        onFocus={() => { setEditable(true) }}
                        currency_code={refundData.currency_code}

                        item={{
                            type: 'number',
                            placeholder: '',
                            fullWidth: true,
                            required: true,
                            texttransform: 'none',
                            title: <LabelWithAsteriskMark label={'Amount'} />,
                            value: refundData.total_refund_amount ? refundData.total_refund_amount : '',
                            validation: refundData.total_refund_amount > refundData.credit_remaining || refundData.total_refund_amount === 0,
                            onChange: (e) => {
                                if (e.target.value) {
                                    refundData.total_refund_amount = limitDecimalPlaces(e.target.value)
                                } else {
                                    refundData.total_refund_amount = 0
                                }
                                setRefundData({ ...refundData })
                            },
                            onBlur: () => { setEditable(false) }
                        }}
                        onKeyPress={(event) => {
                            if (event?.key === '-' || event?.key === '+') {
                                event.preventDefault();
                            }
                        }}
                    />
                    <ExchangeRateComponent data={refundData} setData={setRefundData} />

                </Grid>
                <Grid item xs={3}>
                    <CustomTypography
                        id={'total_refund_amount_balance'}
                        dataTestId={'total_refund_amount_balance'}
                        text={<span><span style={{ paddingRight: '5px', color: '#848282' }}>Balance :</span><CurrencyFormatter amount={refundData.credit_remaining} currency={refundData.currency_code} /></span>}
                        sx={{
                            mt: 4,
                            color: '#000000',
                            fontSize: '14px',
                            fontWeight: '700',
                            lineHeight: '14px',
                            fontStyle: 'normal',
                            fontFamily: 'Noto Sans',
                        }}
                    />
                </Grid>
                <Grid item xs={9} >
                    <Input
                        id={'input_note'}
                        dataTestId={'input_note'}
                        item={{
                            rows: 4,
                            type: 'text',
                            title: 'Notes',
                            required: true,
                            multiline: true,
                            fullWidth: true,
                            placeholder: 'Notes',
                            texttransform: 'none',
                            value: refundData.note ? refundData.note : '',
                            onChange: (event) => {
                                setRefundData({ ...refundData, note: event.target.value })
                            }
                        }}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

const Buttons = (props) => {
    const { onSave, onCancel } = props
    return (
        <Box component="form" noValidate  >
            <Grid container>
                <Grid item xs={12} sx={{ display: '-webkit-flex', justifyContent: 'end', }}>
                    <CustomButton id={'cancel_btn'} dataTestId={'cancel_btn'} variant='outlined' sx={{ textTransform: 'none', '&:hover': { backgroundColor: '#e8f3ff' } }} btnLabel='Cancel' onClick={onCancel} />
                    <CustomButton id={'save_btn'} dataTestId={'save_btn'} variant='contained' sx={{ ml: 1, textTransform: 'none' }} btnLabel={'Save'} onClick={onSave} />
                </Grid>
            </Grid>
        </Box>
    )
}

const ExchangeRateComponent = (props) => {
    const { data, setData } = props;
    const { currency } = getBusinessInfo();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const dispatch = Actions.getDispatch(React.useContext);
    const [validation, setValidation] = React.useState(false)


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        if (data.exchange_rate && data.exchange_rate !== 0) {
            setAnchorEl(null);
        }
    };

    const open = Boolean(anchorEl);
    const id = open ? 'scroll-popover' : undefined;

    let current_currency_id = data.currency_id;
    let business_currency_id = currency && currency.id;

    return (
        current_currency_id && business_currency_id && (current_currency_id !== business_currency_id) &&
        <React.Fragment>
            <CustomTypography
                id={"total_with_exchange_rate"}
                dataTestId={"total_with_exchange_rate"}
                text={<span style={{ display: 'inline-flex' }}>Currency conversion: <CurrencyFormatter amount={data.total_refund_amount * data.exchange_rate} currency={currency.currency_code} /> ({'INR'}) at {data.exchange_rate} <span className='hover-underline-class' style={{ color: '#2464EB', cursor: 'pointer' }}><ModeEditOutlineIcon onClick={handleClick} sx={{ ml: 0.5, fontSize: '18px' }} /></span> </span>}
                sx={{
                    mt: 0.5,
                    color: "#000000",
                    fontSize: "12px",
                    fontWeight: "700",
                    lineHeight: "14px",
                    fontStyle: "normal",
                    fontFamily: "Noto Sans",
                }}
            />

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                PaperProps={{
                    style: {
                        // maxHeight: 200,
                        // width: 200,
                    },
                }}
            >
                <Box sx={{ p: 2, pt: 0 }}>
                    <Input
                        id={"input_exchange_rate"}
                        isSearchableComponent={true}
                        dataTestId={"input_exchange_rate"}
                        item={{
                            type: "number",
                            required: true,
                            fullWidth: true,
                            placeholder: "",
                            title: "Order Number",
                            titleVisibility: "hidden",
                            validation: validation && !data.exchange_rate,
                            value: data.exchange_rate !== null ? data.exchange_rate : '',
                            onChange: (e) => {
                                setValidation(false);
                                if (isNumber(e.target.value)) {
                                    data.exchange_rate = limitDecimalPlaces(e.target.value, 6);
                                } else {
                                    data.exchange_rate = null;
                                }
                                setData({ ...data })
                            },
                            onBlur: (e) => {
                                if (isNumber(e.target.value)) {
                                    if (Number(e.target.value) === 0) {
                                        setValidation(true);
                                        stateChangeManager(dispatch, Actions, true, "error", 'Exchange rate can not be zero');
                                    }
                                    data.exchange_rate = limitDecimalPlaces(e.target.value, 6);
                                } else {
                                    setValidation(true);
                                    data.exchange_rate = null;
                                    stateChangeManager(dispatch, Actions, true, "error", 'Exchange rate can not be empty');
                                }
                                setData({ ...data })
                            },
                        }}
                        onKeyPress={(event) => {
                            if (
                                event?.key === "-" ||
                                event?.key === "+" ||
                                event?.key === "e" ||
                                event?.key === "E"
                            ) {
                                event.preventDefault();
                            }
                        }}
                    />
                </Box>
            </Popover>
        </React.Fragment>
    )
}