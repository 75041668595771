import React from "react";
import { useNavigate } from 'react-router-dom';
import { apiAction } from "../../../../../api/api";
import * as Actions from '../../../../../state/Actions';
import CustomButton from "../../../../custom/button/CustomButton";
import { getBusinessInfo } from "../../../../../config/cookiesInfo";
import CurrencyFormatter from "../../../../common/CurrencyFormatter";
import { HtmlTooltip } from '../../../../custom/tooltip/CustomTooltip';
import CustomTypography from "../../../../custom/typography/CustomTypography";
import { BootstrapDialog } from '../../../bulkStatementUpload/mappings/Transactions';
import { BootstrapDialogTitle } from '../../../bulkStatementUpload/mappings/Transactions';

import {
    isNumeric,
    setLoader,
    areObjectsEqual,
    stateChangeManager,
    getGranularityRange,
    getUniqueObjectsByKey,
    getSelectedGranularityData,
    numberFormatterWithoutSymbol,
} from "../../../../../utils/Utils";

import {
    get_data_point,
    delete_data_point,
    delete_insight_tab,
    evaluate_data_point,
    delete_insight_section,
    evaluate_dependant_data_point
} from "../../../../../api/urls";

import {
    Box,
    Avatar,
    Button,
    IconButton,
    ButtonGroup,
    DialogActions,
    DialogContent,
} from "@mui/material";

import DeleteIcon from '@mui/icons-material/Delete';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';

export const CommonHeader = (props) => {
    const { title = 'Title', isBtn = true, is_sorting = false, is_add = true, is_edit = true, is_delete = true, is_full = true, onFullHandler, onEditHandler, onDeleteHandler, onAddHandler } = props;
    return (
        <Box sx={{ height: 35, p: 1, pl: 1.5, display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>
            <CustomTypography
                text={title}
                sx={{
                    color: '#000',
                    fontSize: '16px',
                    fontWeight: '700',
                    fontStyle: 'normal',
                    lineHeight: 'normal',
                    fontFamily: "Noto Sans",

                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                }}
            />
            {isBtn &&
                <Box sx={{ display: 'flex' }}>

                    {is_sorting &&
                        <HtmlTooltip title={'Sorting'} placement="top">
                            <IconButton aria-label="sorting" sx={{ ":hover": { color: '#2464eb' } }} onClick={() => { }}>
                                <SwapVertIcon sx={{ fontSize: '18px' }} />
                            </IconButton>
                        </HtmlTooltip>
                    }

                    {is_edit &&
                        <HtmlTooltip title={'Manage Section'} placement="top">
                            <IconButton aria-label="edit" sx={{ ":hover": { color: '#2464eb' } }} onClick={() => { onEditHandler() }}>
                                <ModeEditIcon sx={{ fontSize: '18px' }} />
                            </IconButton>
                        </HtmlTooltip>
                    }
                    {is_delete &&
                        <HtmlTooltip title={'Delete Section'} placement="top">
                            <IconButton aria-label="delete" sx={{ ":hover": { color: '#2464eb' } }} onClick={() => { onDeleteHandler() }}>
                                <DeleteIcon sx={{ fontSize: '18px' }} />
                            </IconButton>
                        </HtmlTooltip>
                    }

                    {is_add &&
                        <HtmlTooltip title={'Add Data Point'} placement="top">
                            <IconButton aria-label="add" sx={{ ":hover": { color: '#2464eb' } }} onClick={() => { onAddHandler() }}>
                                <Avatar sx={{ bgcolor: '#2464eb', height: '16px', width: '16px', fontSize: '12px', fontWeight: 500 }}>+</Avatar>
                            </IconButton>
                        </HtmlTooltip>
                    }

                    {is_full && <HtmlTooltip title={'Full Screen'} placement="top">
                        <IconButton aria-label="full_screen" sx={{ ":hover": { color: '#2464eb' } }} onClick={() => { onFullHandler(true) }}>
                            <OpenInFullIcon sx={{ fontSize: '18px' }} />
                        </IconButton>
                    </HtmlTooltip>
                    }
                </Box>
            }
        </Box>
    )
}


export const DeleteComponent = (props) => {
    const { id, action, openDelete, setOpenDelete, onDeleteAction, } = props

    let navigation = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);
    const getActionData = (action) => {
        let data = {}
        if (action === 'delete_tab') {
            data.title = 'Delete Tab';
            data.url = delete_insight_tab();
            data.formData = { tab_id: id };
            data.deleteMessage = 'Are you sure you want to delete?'
        }
        if (action === 'delete_section') {
            data.title = 'Delete Section';
            data.url = delete_insight_section();
            data.formData = { section_id: id };
            data.deleteMessage = 'Are you sure you want to delete?'
        }
        if (action === 'delete_data_point') {
            data.title = 'Delete Data point';
            data.url = delete_data_point();
            data.formData = { data_point_id: id };
            data.deleteMessage = 'Are you sure you want to delete?'
        }
        return data
    }

    const onClickAgree = async () => {
        let res = await apiAction({
            method: 'delete',
            dispatch: dispatch,
            navigate: navigation,
            url: getActionData(action).url,
            data: { business_id: getBusinessInfo().id, ...getActionData(action).formData }
        })
        if (res?.success) {
            if (onDeleteAction) { onDeleteAction(id) };
            stateChangeManager(dispatch, Actions, true, 'success', res?.status)
        } else {
            stateChangeManager(dispatch, Actions, true, 'error', res?.status)
        }
    }
    return (
        <BootstrapDialog
            fullWidth
            maxWidth={'sm'}
            open={openDelete}
        >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={() => setOpenDelete(false)}>
                <CustomTypography
                    text={getActionData(action).title}
                    sx={{
                        color: '#000000',
                        fontSize: '16px',
                        fontWeight: '700',
                        lineHeight: '18px',
                        fontStyle: 'normal',
                        fontFamily: "Noto Sans",
                    }}
                />
            </BootstrapDialogTitle>
            <DialogContent dividers>
                <CustomTypography
                    text={getActionData(action).deleteMessage}
                    sx={{
                        fontSize: '14px',
                        color: '#000000',
                        fontWeight: '700',
                        lineHeight: '18px',
                        fontStyle: 'normal',
                        textTransform: "none",
                        fontFamily: "Noto Sans",
                    }}
                />
                <div style={{ textAlign: 'center', marginTop: '24px' }}>
                    <CustomButton
                        btnLabel='Keep It'
                        variant='outlined'
                        onClick={() => { setOpenDelete(false) }}
                        sx={{ textTransform: 'none', '&:hover': { backgroundColor: '#e8f3ff' } }}
                    />
                    <CustomButton
                        variant='contained'
                        btnLabel='Yes, Delete'
                        onClick={() => onClickAgree()}
                        sx={{ ml: 2, textTransform: 'none', }}
                    />
                </div>
                <DialogActions>
                </DialogActions>
            </DialogContent>
        </BootstrapDialog>
    )
}

export const ViewDataPoint = (props) => {
    const { item, refreshView, open, setOpen, onEdit, setDataPointData, from_date, to_date, isDetails = true } = props;
    const [data, setData] = React.useState();
    const [dataList, setDataList] = React.useState([]);
    const [currentId, setCurrentId] = React.useState(item?.id);

    const combineData = (data1 = [], data2 = []) => {
        const dataMap = new Map(data1?.map(item => [item.data_point.id, item]));

        return data2?.map(item => {
            const matchingData = dataMap?.get(item.id);
            if (matchingData) {
                return {
                    ...item,
                    result: matchingData.result,
                    tooltip: matchingData.tooltip,
                    unit: matchingData.data_point?.unit
                };
            } else {
                return item;
            }
        });
    };

    const fetchDataPoint = async (data_point_id) => {
        try {
            const evaluateDataPointResult = await evaluateDataPoint(data_point_id, refreshView);
            const evaluateDependantDataPointResult = await evaluateDependantDataPoint(data_point_id, refreshView);
            const value = await apiAction({
                url: get_data_point(data_point_id)
            });
            if (value.success) {
                setCurrentId(data_point_id);
                const result = value.result;
                if (isDetails) {
                    result['result'] = evaluateDataPointResult.result;
                    result['tooltip'] = evaluateDataPointResult.tooltip;
                    result['data_point_dependencies'] = combineData(evaluateDependantDataPointResult?.result, value.result?.data_point_dependencies);
                }
                setData(result);
                setDataPointData(result);
                let findListData = dataList.find(i => i.id === data_point_id);

                if (findListData === undefined) {
                    setDataList([...getUniqueObjectsByKey([...dataList, result])]);
                } else if (!areObjectsEqual(result, findListData)) {
                    setDataList([...getUniqueObjectsByKey([...dataList.filter(item => item.id !== data_point_id), result])]);
                }

            }
        } catch (error) {
            console.error('Error fetching data point:', error);
        }
    };

    React.useEffect(() => {
        if (open && item) {
            setCurrentId(item?.id);
            setDataPointData(item);
            fetchDataPoint(item?.id);
        } else {
            setData(null);
            setDataList([]);
            setDataPointData(item);
        }
    }, [open, refreshView]);

    const evaluateDependantDataPoint = async (data_point_id, isRefresh = false) => {
        if (isDetails) {
            try {
                const url = isRefresh ? `${evaluate_dependant_data_point(data_point_id, from_date, to_date)}&is_refresh=true` : evaluate_dependant_data_point(data_point_id, from_date, to_date);
                const value = await apiAction({ url });
                return value?.success ? value : null;
            } catch (error) {
                console.error('Error evaluating dependent data point:', error);
                return null;
            }
        }
    };

    const evaluateDataPoint = async (data_point_id, isRefresh = false) => {
        if (isDetails) {
            try {
                const url = isRefresh ? `${evaluate_data_point(data_point_id, from_date, to_date)}&is_refresh=true` : evaluate_data_point(data_point_id, from_date, to_date);
                const value = await apiAction({ url });
                return value?.success ? value : null;
            } catch (error) {
                console.error('Error evaluating data point:', error);
                return null;
            }
        }

    };

    const handleDataPointClick = (dataPointId, newDataList) => {
        if (currentId !== dataPointId) {
            fetchDataPoint(dataPointId);
            setDataList([...getUniqueObjectsByKey([...newDataList])]);
        }
    };

    const renderDataPoint = (value, index) => {
        return (
            <CustomTypography
                key={index}
                text={
                    isDetails ?
                        <HtmlTooltip title={<span style={{ fontWeight: '700' }}>{getTooltipContent(value)}</span>} placement="top">
                            {!index ? value?.name + ' ' : '›' + ' ' + value?.name}
                        </HtmlTooltip>
                        :
                        !index ? value?.name + ' ' : '›' + ' ' + value?.name
                }
                sx={{
                    fontSize: '12px',
                    marginLeft: '4px',
                    fontWeight: '700',
                    lineHeight: '18px',
                    marginRight: '4px',
                    fontStyle: 'normal',
                    whiteSpace: 'nowrap',
                    textTransform: "none",
                    fontFamily: "Noto Sans",
                    cursor: currentId !== value.id ? 'pointer' : 'default',
                    color: currentId !== value.id ? '#2464EB' : 'GrayText',
                }}
                onClick={() => handleDataPointClick(value.id, dataList.slice(0, index + 1))}
            />
        );
    };

    const getTooltipContent = (dataPoint) => {
        if (!dataPoint) return '';
        const value = dataPoint.result;
        if (dataPoint.unit === 'base_currency') {
            return <CurrencyFormatter amount={value} />;
        } else if (dataPoint.unit === 'percentage') {
            return `${numberFormatterWithoutSymbol(null, isNumeric(value) ? Number(value) : 0)} %`;
        } else {
            return numberFormatterWithoutSymbol(null, isNumeric(value) ? Number(value) : 0);
        }
    };

    return (
        data &&
        <BootstrapDialog
            fullWidth
            open={open}
            maxWidth={'sm'}
        >
            <BootstrapDialogTitle id="customized-dialog-view-title" onClose={() => setOpen(false)}>
                <CustomTypography
                    text={
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                            {data?.name}
                            {data?.is_custom &&
                                <HtmlTooltip title={'Edit Data Point'} placement="top">
                                    <IconButton size="small" aria-label="edit" sx={{ ml: 1, ":hover": { color: '#2464eb' } }} onClick={() => { onEdit('edit_data_point') }}>
                                        <ModeEditIcon sx={{ fontSize: '18px' }} />
                                    </IconButton>
                                </HtmlTooltip>
                            }
                            <HtmlTooltip title={'Clone Data Point'} placement="top">
                                <IconButton size="small" aria-label="edit" sx={{ ml: 1, ":hover": { color: '#2464eb' } }} onClick={() => { onEdit('clone_data_point') }}>
                                    <FileCopyIcon sx={{ fontSize: '18px' }} />
                                </IconButton>
                            </HtmlTooltip>
                        </span>
                    }
                    sx={{
                        color: '#000000',
                        fontSize: '16px',
                        fontWeight: '700',
                        lineHeight: '18px',
                        fontStyle: 'normal',
                        fontFamily: "Noto Sans",
                    }}
                />
            </BootstrapDialogTitle>
            <DialogContent dividers>
                <Box sx={{ mb: 2, pb: 1, display: 'flex', flexWrap: 'wrap', borderBottom: '1px solid #c4c4c4' }}>
                    {getUniqueObjectsByKey([...dataList]).map(renderDataPoint)}
                </Box>
                <Box sx={{ padding: '10px', minHeight: '100px', borderRadius: '4px', border: '1px solid #c4c4c4' }}>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {data?.data_point_type === "Report" ?
                            <CustomTypography
                                text={`This data point relies on a report mapping. Navigate to 'Report Mappings' to view or update.`}
                                sx={{
                                    fontSize: '14px',
                                    color: '#000000',
                                    fontWeight: '700',
                                    marginLeft: '4px',
                                    lineHeight: '18px',
                                    marginRight: '4px',
                                    fontStyle: 'normal',
                                    textTransform: "none",
                                    fontFamily: "Noto Sans",
                                }}
                            /> : data?.data_point_type === "Parent Account" || data?.data_point_type === "Function" || data?.data_point_type === "Account" ?
                                <CustomTypography
                                    text={`This is a system-defined data point based on ${data?.data_point_type}.`}
                                    sx={{
                                        fontSize: '14px',
                                        color: '#000000',
                                        fontWeight: '700',
                                        marginLeft: '4px',
                                        lineHeight: '18px',
                                        marginRight: '4px',
                                        fontStyle: 'normal',
                                        textTransform: "none",
                                        fontFamily: "Noto Sans",
                                    }}
                                /> : data?.data_point_type === "Equation" && data?.calculation_formula ?
                                    data?.calculation_formula.split(/([a-zA-Z_]+|\(|\)|\/|\*|\+|\-)/g).map((item, index) => {
                                        const dataPoint = data?.data_point_dependencies?.find(i => i.slug_name === item);
                                        return dataPoint ?
                                            <CustomTypography
                                                key={index}
                                                text={
                                                    isDetails ?
                                                        <HtmlTooltip title={<>{dataPoint.name}{': '}<span style={{ fontWeight: '700' }}>{getTooltipContent(dataPoint)}</span></>} placement="top">
                                                            {dataPoint.name}
                                                        </HtmlTooltip>
                                                        : dataPoint.name
                                                }
                                                sx={{
                                                    mb: 1,
                                                    fontSize: '14px',
                                                    color: '#2464EB',
                                                    cursor: 'pointer',
                                                    fontWeight: '700',
                                                    lineHeight: '18px',
                                                    fontStyle: 'normal',
                                                    whiteSpace: 'nowrap',
                                                    textTransform: "none",
                                                    fontFamily: "Noto Sans",
                                                }}
                                                onClick={() => {
                                                    fetchDataPoint(dataPoint.id);
                                                    setDataList([...getUniqueObjectsByKey([...dataList, dataPoint])]);
                                                }}
                                            /> :
                                            <CustomTypography
                                                key={index}
                                                text={item}
                                                sx={{
                                                    fontSize: '14px',
                                                    color: '#000000',
                                                    fontWeight: '700',
                                                    marginLeft: '1px',
                                                    lineHeight: '18px',
                                                    marginRight: '1px',
                                                    fontStyle: 'normal',
                                                    whiteSpace: 'nowrap',
                                                    textTransform: "none",
                                                    fontFamily: "Noto Sans",
                                                }}
                                            />;
                                    }) : ''
                        }
                    </div>
                </Box>
            </DialogContent>
        </BootstrapDialog>
    );
};

export const GranularityComponent = (props) => {
    const dispatch = Actions.getDispatch(React.useContext);

    const { from_date, to_date, setGranularity, granularity } = props;
    const { d, w, m, y } = getGranularityRange(from_date, to_date);
    const btnStyle = {
        color: '#757575',
        fontWeight: 700,
        borderColor: '#ccd6eb',
        fontFamily: 'Noto Sans',
        ":hover": { color: '#2464eb', zIndex: 99999 }
    }

    const onClickHandler = (key) => {
        setGranularity(key);
        setLoader(dispatch, Actions, true);
    }

    const getSelectedStyles = (key) => {
        const { selected, active = {} } = getSelectedGranularityData(granularity, from_date, to_date)
        if (selected === key) {
            return { active: active }
        }
    }
    const buttons = [
        <HtmlTooltip title={'Day'} placement="top"><Button onClick={() => { onClickHandler('d') }} disabled={d} key="day" sx={{ ...btnStyle, ...getSelectedStyles('d')?.active }}><span style={{}}>D</span></Button></HtmlTooltip>,
        <HtmlTooltip title={'Week'} placement="top"><Button onClick={() => { onClickHandler('w') }} disabled={w} key="week" sx={{ ...btnStyle, ...getSelectedStyles('w')?.active }}><span style={{}}>W</span></Button></HtmlTooltip>,
        <HtmlTooltip title={'Month'} placement="top"><Button onClick={() => { onClickHandler('m') }} disabled={m} key="month" sx={{ ...btnStyle, ...getSelectedStyles('m')?.active }}><span style={{}}>M</span></Button></HtmlTooltip>,
        <HtmlTooltip title={'Year'} placement="top"><Button onClick={() => { onClickHandler('y') }} disabled={y} key="year" sx={{ ...btnStyle, ...getSelectedStyles('y')?.active }}><span style={{}}>Y</span></Button></HtmlTooltip>,
    ];
    return (
        <ButtonGroup size="small" aria-label="Small button group" >
            {buttons}
        </ButtonGroup>
    )
}