import React from "react";
import { routesName } from "../../../../../config/routesName";
import { items } from "../../../../../utils/Constant";
import Mappings from "../../../bulkStatementUpload/mappings/Mappings";


const ItemsMapping = (props) => {
    return(

        <Mappings onFileErrorPath={routesName.invoicingItemsFileUpload.path}  nextPagePath={routesName.invoicingItemsPreview.path} mapping_for={ items } isDateFormat={true}/>
        
    )
}

export default ItemsMapping