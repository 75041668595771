import React from 'react';
import moment from 'moment';
import { apiAction } from '../../../../api/api';
import UpdateUserProfile from './UpdateUserProfile';
import * as Actions from '../../../../state/Actions';
import CustomTitle from '../../../common/CustomTitle';
import { retrieve_user } from '../../../../api/urls';
import { setUserDetails } from '../../../../config/cookiesInfo';
import CustomButton from '../../../custom/button/CustomButton';
import CustomTypography from '../../../custom/typography/CustomTypography';

import {
  setLoader,
  getDateFormat,
  get_party_contact_name,
} from '../../../../utils/Utils';

import {
  CustomContainer,
  CustomTitleContainer,
} from '../../../custom/container/CustomContainer';

import {
  Box,
  Grid,
  Avatar,
} from '@mui/material';


const ViewProfile = (props) => {
  const {initial_state}=props;
  React.useEffect(()=>{setEdit(false)},[initial_state]);
  
  const dispatch = Actions.getDispatch(React.useContext);

  const [edit, setEdit] = React.useState(false);

  const [result, setResult] = React.useState();
  React.useEffect(() => {
    const getCustomer = async () => {
      setLoader(dispatch, Actions, true)
      let res = await apiAction({
        method: 'get',
        url: retrieve_user(),
      })
      if (res?.success) {
        setResult(res?.result);
        setUserDetails(res?.result);
        setLoader(dispatch, Actions, false);
      } else {
        setLoader(dispatch, Actions, false);
      }
    }
    getCustomer()
    // eslint-disable-next-line
  }, [edit])

  return (
    edit ?
      <UpdateUserProfile result={result} onCancel={() => setEdit(false)} />
      :
      <div>
        <CustomTitleContainer>
          <Grid container spacing={0} sx={{}} style={{
            alignItems: 'center',
            justifyItems: 'center',
            alignContent: 'space-evenly',
            justifyContent: 'space-evenly',
          }}>
            <Grid item xs={6}>
              <CustomTitle title={'My Profile'} />
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'end' }}>
              <CustomButton
                sx={{}}
                btnLabel='Edit'
                id={'edit_btn'}
                variant="contained"
                dataTestId={'edit_btn'}
                onClick={() => { setEdit(true) }}
              />
            </Grid>
          </Grid>
        </CustomTitleContainer>
        <CustomContainer maxWidth={"400"} sx={{ height: 'calc(100vh - 134px)' }}>
          {
            result &&
            <React.Fragment>
              <BasicInfo
                result={result}
              />
              <div style={{ paddingLeft: '24px', paddingRight: '24px', paddingTop: '16px', paddingBottom: '16px', }}>
                <DetailsInfo
                  result={result}
                />
              </div>
            </React.Fragment>
          }
        </CustomContainer>
      </div>
  )
}

export default ViewProfile;

const BasicInfo = (props) => {
  const { result } = props
  return (
    <Box sx={{ borderBottom: '2px solid #F5F5F5' }}>
      <Box sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'row',
      }}>
        <Avatar />
        <Box sx={{ alignSelf: 'center', ml: 2.5 }}>

          <CustomTypography
            text={get_party_contact_name(result) ? get_party_contact_name(result) : result.name}
            sx={{
              mb: 1,
              color: '#000000',
              fontSize: '14px',
              fontWeight: '700',
              lineHeight: '18px',
              fontStyle: 'normal',
              fontFamily: "Noto Sans",
            }}
          />
          <CustomTypography
            text={result.email}
            sx={{
              mb: 0.5,
              color: '#7A7A7A',
              fontSize: '12px',
              fontWeight: '500',
              lineHeight: '18px',
              fontStyle: 'normal',
              fontFamily: "Noto Sans",
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}

const DetailsInfo = (props) => {
  const { result } = props

  const list_details = [
    { title: 'First Name', value: result.first_name ? result.first_name : null },
    { title: 'Last Name', value: result.last_name ? result.last_name : null },
    { title: 'Gender', value: result.gender ? result.gender.capitalize() : null },
    { title: 'Date of Birth', value: result.date_of_birth ? moment(result.date_of_birth).format(getDateFormat()) : null },
    { title: 'Email', value: result.email ? result.email : null },
    { title: 'Mobile Number', value: result.mobile_number ? result.mobile_number : null },
    { title: 'Country', value: result.country ? result.country.name : null },
    { title: 'State', value: result.state ? result.state.name : null },
    { title: 'City/Town', value: result.city ? result.city : null },
    { title: 'Pin Code', value: result.zip_code ? result.zip_code : null },
  ]
  return (
    <Box sx={{ pt: 1 }}>
      <Grid container item xs={8} spacing={3}>
        {
          list_details.map((item, index) => {
            return (
              <Grid item xs={6} key={index}>
                <TitleData item={item} />
              </Grid>
            )
          })
        }

      </Grid>
    </Box>
  )
}

const TitleData = (props) => {
  const { item } = props
  return (
    <>
      <Grid item xs={6}>
        <CustomTypography
          text={item.title ? item.title : '-'}
          sx={{
            mb: 2,
            color: '#000000',
            fontSize: '14px',
            fontWeight: '700',
            lineHeight: '18px',
            fontStyle: 'normal',
            fontFamily: "Noto Sans",
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <CustomTypography
          text={item.value ? item.value : '-'}
          sx={{
            mb: 2,
            color: '#7A7A7A',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '18px',
            fontStyle: 'normal',
            fontFamily: "Noto Sans",
          }}
        />
      </Grid>
    </>
  )
}