import React from 'react';
import Input from '../../../common/Input';
import { useNavigate } from 'react-router-dom';
import { apiAction } from '../../../../api/api';
import DateRange from '../../../common/DateRange';
import * as Actions from '../../../../state/Actions';
import { get_list_of_currency } from '../../../../api/urls';
import CommonTDSandTCS from '../../../common/CommonTDSandTCS';
import { getBusinessInfo } from '../../../../config/cookiesInfo';
import CurrencyFormatter from '../../../common/CurrencyFormatter';
import { totalTdsTcsAmount } from '../sales/invoices/InvoiceManager';
import CustomTypography from '../../../custom/typography/CustomTypography';
import CustomDefaultDropdown from '../../../custom/dropdown/CustomDefaultDropdown';

import {
    isNumber,
    limitDecimalPlaces,
    stateChangeManager,
} from '../../../../utils/Utils';

import {
    Box,
    Grid,
    FormControlLabel,
} from '@mui/material';

const Dropdown = DateRange;

const CommonCurrencySelection = (props) => {
    let navigate = useNavigate();
    const { currency } = getBusinessInfo();
    const dispatch = Actions.getDispatch(React.useContext);

    const { isFormSubmitted, data, setData, isCurrency = false, isAmountAre = false, dropdown_key = 'customer_id', onAmountsAreInChange } = props;


    const [results, setResult] = React.useState([]);

    React.useEffect(() => {
        const apiResults = async (props) => {
            let res = await apiAction({
                navigate: navigate,
                dispatch: dispatch,
                url: get_list_of_currency(),
            })
            if (res?.success) {
                let results = res?.result.map((item) => {
                    item['helperText'] = `${item.name} (${item.symbol})`;
                    return item;
                }
                )
                setResult(results);
            } else {

            }
        }
        if (isCurrency) {
            apiResults()
        }
    }, [])


    React.useEffect(() => {
        if (!data[dropdown_key] && currency) {
            data.exchange_rate = 1;
            data.currency_id = currency.id;
            data.currency_symbol = currency.symbol;
            data.currency_code = currency.currency_code;
            setData({ ...data })
        }
    }, [data[dropdown_key]])


    React.useEffect(() => {
        if (currency.id === data.currency_id) {
            data.exchange_rate = 1;
            setData({ ...data })
        }
    }, [data.currency_id])

    const [validation, setValidation] = React.useState(isFormSubmitted);

    const filterList = [
        { name: "Exclusive", value: "exclusive" },
        { name: "Inclusive", value: "inclusive" },
        { name: "No Tax", value: "no_tax" },
    ];

    const filterListValue = filterList.find((option) => option.value === data.tax_applied)
    return (
        <Box sx={{ display: { sx: '', sm: 'flex' }, alignItems: 'center' }}>
            {isAmountAre &&
                <Box sx={{ mt: { xs: 2, sm: 0 }, ml: 2, width: { sx: '100%', sm: 200 }, }}>
                    <CustomTypography
                        text={<span>{"Amounts are"}<span style={{ fontSize: '10px', paddingLeft: '5px' }}>( {filterListValue?.name} )</span></span>}
                        sx={{
                            mb: 1,
                            fontSize: "14px",
                            fontWeight: "700",
                            lineHeight: "19px",
                            letterSpacing: "0px",
                            fontFamily: "Noto Sans",
                        }}
                    />
                    <Dropdown
                        results={filterList}
                        value={filterListValue}
                        disableClearable={true}
                        placeholder="All statuses"
                        id={"tax_applied_dropdown"}
                        dataTestId={"tax_applied_dropdown"}
                        setValue={(event, value) => onAmountsAreInChange(value)}
                    />
                </Box>
            }
            
            {isCurrency &&
                <>
                    <Box sx={{ mt: { xs: 2, sm: 0 }, ml: 2, width: { sx: '100%', sm: 200 }, }}>
                        <CustomTypography
                            text={"Currency"}
                            sx={{
                                mb: 1,
                                fontSize: "14px",
                                fontWeight: "700",
                                lineHeight: "19px",
                                letterSpacing: "0px",
                                fontFamily: "Noto Sans",
                            }}
                        />
                        <CustomDefaultDropdown
                            maxHeight={250}
                            fullWidth={true}
                            results={results}
                            inputDisabled={true}
                            labelKey='currency_code'
                            disabledCloseIcon={true}
                            id={'currency_dropdown'}
                            placeholder='Select Currency'
                            valueLabelKey={'currency_code'}
                            dataTestId={'currency_dropdown'}
                            // inputDisabled={!data[dropdown_key]}
                            // sx={{ background: !data[dropdown_key] ? '#F1F3F4' : '' }}
                            value={data.currency_id ? results.find((item) => item.id === data.currency_id) : null}
                            setValue={(selectedCurrency) => {
                                if (selectedCurrency) {
                                    data.currency_id = selectedCurrency.id;
                                    data.currency_symbol = selectedCurrency.symbol;
                                    data.currency_code = selectedCurrency.currency_code;
                                    setData({ ...data })
                                }
                            }}
                        />
                    </Box>

                    {data.currency_code && (currency && currency.currency_code !== data.currency_code) &&
                        <Box sx={{ mt: { xs: 2, sm: 0 }, ml: 2, width: { sx: '100%', sm: 200 }, }}>
                            <Input
                                id={"input_exchange_rate"}
                                isSearchableComponent={true}
                                dataTestId={"input_exchange_rate"}
                                item={{
                                    type: 'number',
                                    required: true,
                                    fullWidth: true,
                                    placeholder: "",
                                    title: "Exchange Rate",
                                    // titleVisibility: "hidden",
                                    validation: validation && !data.exchange_rate,
                                    value: data.exchange_rate !== null ? data.exchange_rate : '',
                                    onChange: (e) => {
                                        setValidation(false);
                                        if (isNumber(e.target.value)) {
                                            data.exchange_rate = limitDecimalPlaces(e.target.value, 6);
                                        } else {
                                            data.exchange_rate = null;
                                        }
                                        setData({ ...data })
                                    },
                                    onBlur: (e) => {
                                        if (isNumber(e.target.value)) {
                                            if (Number(e.target.value) === 0) {
                                                setValidation(true);
                                                stateChangeManager(dispatch, Actions, true, "error", 'Exchange rate can not be zero');
                                            }
                                            data.exchange_rate = String(limitDecimalPlaces(e.target.value, 6));
                                        } else {
                                            setValidation(true);
                                            data.exchange_rate = null;
                                            stateChangeManager(dispatch, Actions, true, "error", 'Exchange rate can not be empty');
                                        }
                                        setData({ ...data })
                                    },
                                }}
                                InputProps={{
                                    inputProps: { min: 0 },
                                }}
                                onKeyPress={(event) => {
                                    if (
                                        event?.key === "-" ||
                                        event?.key === "+" ||
                                        event?.key === "e" ||
                                        event?.key === "E"
                                    ) {
                                        event.preventDefault();
                                    }
                                }}
                            />
                        </Box>
                    }

                </>
            }

        </Box>
    )
}

export default CommonCurrencySelection
