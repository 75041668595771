import React from 'react';
import { useNavigate } from 'react-router-dom';
import { routesName } from '../../../../config/routesName';
import { CustomLink } from '../../../custom/link/CustomLink';
import CustomTypography from '../../../custom/typography/CustomTypography';
import { eventsNames,AnalyticsEvent } from '../../../../firebase/firebaseAnalytics';

import {
    expense_number,
    payment_made_number,
    payment_received_number,
} from '../../../../utils/Constant';


export const ItemViewLink = (props) => {
    const { id, title, style } = props;
    return (
        <CustomLink
            style={{ ...style }}
            to={routesName.invoicingItemsView.path + "?item_id=" + id}
            onClick={()=>{AnalyticsEvent(eventsNames.categories.ITEMS,{action:eventsNames.actions.VIEW});}}
        >
            {title}
        </CustomLink>
    )
}

export const SalesPersonViewLink = (props) => {
    const { id, title, style, state } = props;
    return (
        <CustomLink
            state={{ ...state }}
            style={{ ...style }}
            to={routesName.salesBySalesPersonView.path + "?id=" + id}
        >
            {title}
        </CustomLink>
    )
}
////////////////////////////////////////SALES/////////////////////////////////////////////////////
export const CustomerViewLink = (props) => {
    const { id, title, style } = props;
    return (
        <CustomLink
            style={{ ...style }}
            to={routesName.invoicingSalesCustomerView.path + "?id=" + id}
            onClick={()=>{AnalyticsEvent(eventsNames.categories.CUSTOMERS,{action:eventsNames.actions.VIEW});}}
        >
            {title}
        </CustomLink>
    )
}

export const EstimateViewLink = (props) => {
    const { id, title, style } = props;
    return (
        <CustomLink
            style={{ ...style }}
            to={routesName.invoicingSalesEstimateView.path + "?id=" + id}
            onClick={()=>{AnalyticsEvent(eventsNames.categories.ESTIMATES,{action:eventsNames.actions.VIEW});}}
        >
            {title}
        </CustomLink>
    )
}

export const SalesOrderViewLink = (props) => {
    const { id, title, style } = props;
    return (
        <CustomLink
            style={{ ...style }}
            to={routesName.invoicingSalesOrderView.path + "?id=" + id}
            onClick={()=>{AnalyticsEvent(eventsNames.categories.SALES_ORDERS,{action:eventsNames.actions.VIEW});}}
        >
            {title}
        </CustomLink>
    )
}

export const DeliveryChallanViewLink = (props) => {
    const { id, title, style } = props;
    return (
        <CustomLink
            style={{ ...style }}
            to={routesName.invoicingDeliveryChallanView.path + "?id=" + id}
            onClick={()=>{AnalyticsEvent(eventsNames.categories.DELIVERY_CHALLANS,{action:eventsNames.actions.VIEW});}}
        >
            {title}
        </CustomLink>
    )
}

export const InvoiceViewLink = (props) => {
    const { id, title, style } = props;
    return (
        <CustomLink
            style={{ ...style }}
            to={routesName.invoicingSalesInvoiceView.path + "?id=" + id}
            onClick={()=>{AnalyticsEvent(eventsNames.categories.INVOICES,{action:eventsNames.actions.VIEW});}}
        >
            {title}
        </CustomLink>
    )
}

export const RecurringInvoiceViewLink = (props) => {
    const { id, title, style } = props;
    return (
        <CustomLink
            style={{ ...style }}
            to={routesName.invoicingSalesRecurringInvoiceView.path + "?id=" + id}
            onClick={()=>{AnalyticsEvent(eventsNames.categories.RECURRING_INVOICES,{action:eventsNames.actions.VIEW});}}
        >
            {title}
        </CustomLink>
    )
}

export const PaymentReceivedViewLink = (props) => {
    const { id, title, style } = props;
    return (
        <CustomLink
            style={{ ...style }}
            to={routesName.invoicingPaymentsReceivedView.path + "?payment_id=" + id}
            onClick={()=>{AnalyticsEvent(eventsNames.categories.PAYMENTS_RECEIVED,{action:eventsNames.actions.VIEW});}}

        >
            {`${payment_received_number}-${title}`}
        </CustomLink>
    )
}

export const CreditNoteViewLink = (props) => {
    const { id, title, style } = props;
    return (
        <CustomLink
            style={{ ...style }}
            to={routesName.invoicingCreditNotesView.path + "?id=" + id}
            onClick={()=>{AnalyticsEvent(eventsNames.categories.CREDIT_NOTES,{action:eventsNames.actions.VIEW});}}
        >
            {title}
        </CustomLink>
    )
}

////////////////////////////////////////PURCHASE/////////////////////////////////////////////////////
export const VendorViewLink = (props) => {
    const { id, title, style } = props;
    return (
        <CustomLink
            style={{ ...style }}
            to={routesName.invoicingPurchaseVendorView.path + "?id=" + id}
            onClick={()=>{AnalyticsEvent(eventsNames.categories.VENDORS,{action:eventsNames.actions.VIEW});}}
        >
            {title}
        </CustomLink>
    )
}

export const ExpenseViewLink = (props) => {
    const { id, title, style } = props;
    return (
        <CustomLink
            style={{ ...style }}
            to={routesName.invoicingExpenseView.path + "?id=" + id}
            onClick={()=>{AnalyticsEvent(eventsNames.categories.EXPENSES,{action:eventsNames.actions.VIEW});}}
        >
            {`${expense_number}-${title}`}
        </CustomLink>
    )
}

export const RecurringExpenseViewLink = (props) => {
    const { id, title, style } = props;
    return (
        <CustomLink
            style={{ ...style }}
            to={routesName.invoicingRecurringExpenseView.path + "?id=" + id}
            onClick={()=>{AnalyticsEvent(eventsNames.categories.RECURRING_EXPENSES,{action:eventsNames.actions.VIEW});}}
        >
            {title}
        </CustomLink>
    )
}

export const PurchaseOrderViewLink = (props) => {
    const { id, title, style } = props;
    return (
        <CustomLink
            style={{ ...style }}
            to={routesName.invoicingPurchaseOrderView.path + "?id=" + id}
            onClick={()=>{AnalyticsEvent(eventsNames.categories.PURCHASE_ORDERS,{action:eventsNames.actions.VIEW});}}
        >
            {title}
        </CustomLink>
    )
}

export const BillViewLink = (props) => {
    const { id, title, style } = props;
    return (
        <CustomLink
            style={{ ...style }}
            to={routesName.invoicingPurchaseBillView.path + "?id=" + id}
            onClick={()=>{AnalyticsEvent(eventsNames.categories.BILLS,{action:eventsNames.actions.VIEW});}}
        >
            {title}
        </CustomLink>
    )
}

export const PaymentMadeViewLink = (props) => {
    const { id, title, style } = props;
    return (
        <CustomLink
            style={{ ...style }}
            to={routesName.invoicingPaymentsMadeView.path + "?payment_id=" + id}
            onClick={()=>{AnalyticsEvent(eventsNames.categories.PAYMENTS_MADE,{action:eventsNames.actions.VIEW});}}

        >
            {`${payment_made_number}-${title}`}
        </CustomLink>
    )
}

export const DebitNoteViewLink = (props) => {
    const { id, title, style } = props;
    return (
        <CustomLink
            style={{ ...style }}
            to={routesName.invoicingDebitNotesView.path + "?id=" + id}
            onClick={()=>{AnalyticsEvent(eventsNames.categories.DEBIT_NOTES,{action:eventsNames.actions.VIEW});}}
        >
            {title}
        </CustomLink>
    )
}

////////////////////////////////////////Action Button/////////////////////////////////////////////////////

export const ActionTextLinkBtn = (props) => {
    const { sx, index = null, toActionText, onClickActionText, id = 'action_status_btn', dataTestId = 'action_status_btn' } = props;

    return (
        <CustomTypography
            text={toActionText}
            id={index !== null ? id + index : id}
            onClick={() => onClickActionText()}
            dataTestId={index !== null ? dataTestId + index : dataTestId}
            sx={{
                mr: 2,
                fontWeight: 700,
                color: '#2464EB',
                fontSize: '12px',
                cursor: 'pointer',
                whiteSpace: 'nowrap',
                textTransform: 'none',
                ":hover": { textDecoration: 'underline' },
                ...sx,
            }}
        />
    )
}

export const ActionInvoiceListLink = (props) => {
    const navigate = useNavigate();
    const { sx, title, navigationState, id = 'invoice_link', dataTestId = 'invoice_link' } = props;
    return (
        <CustomTypography
            id={id}
            text={title}
            dataTestId={dataTestId}
            onClick={() => navigate(routesName.invoicingSalesInvoice.path, { state: { ...navigationState } })}
            sx={{
                fontWeight: 700,
                color: '#2464EB',
                fontSize: '12px',
                cursor: 'pointer',
                whiteSpace: 'nowrap',
                textTransform: 'none',
                ":hover": { textDecoration: 'underline' },
                ...sx,
            }}
        />
    )
}

export const ActionExpenseListLink = (props) => {
    const navigate = useNavigate();
    const { sx, title, navigationState, id = 'expense_link', dataTestId = 'expense_link' } = props;
    return (
        <CustomTypography
            id={id}
            text={title}
            dataTestId={dataTestId}
            onClick={() => navigate(routesName.invoicingExpense.path, { state: { ...navigationState } })}
            sx={{
                fontWeight: 700,
                color: '#2464EB',
                fontSize: '12px',
                cursor: 'pointer',
                whiteSpace: 'nowrap',
                textTransform: 'none',
                ":hover": { textDecoration: 'underline' },
                ...sx,
            }}
        />
    )
}

export const ActionDebitNoteListLink = (props) => {
    const navigate = useNavigate();
    const { sx, title, navigationState, id = 'debit_note_link', dataTestId = 'debit_note_link' } = props;
    return (
        <CustomTypography
            id={id}
            text={title}
            dataTestId={dataTestId}
            onClick={() => navigate(routesName.invoicingDebitNotesList.path, { state: { ...navigationState } })}
            sx={{
                fontWeight: 700,
                color: '#2464EB',
                fontSize: '12px',
                cursor: 'pointer',
                whiteSpace: 'nowrap',
                textTransform: 'none',
                ":hover": { textDecoration: 'underline' },
                ...sx,
            }}
        />
    )
}

export const ActionCreditNoteListLink = (props) => {
    const navigate = useNavigate();
    const { sx, title, navigationState, id = 'credit_note_link', dataTestId = 'credit_note_link' } = props;
    return (
        <CustomTypography
            id={id}
            text={title}
            dataTestId={dataTestId}
            onClick={() => navigate(routesName.invoicingCreditNotesList.path, { state: { ...navigationState } })}
            sx={{
                fontWeight: 700,
                color: '#2464EB',
                fontSize: '12px',
                cursor: 'pointer',
                whiteSpace: 'nowrap',
                textTransform: 'none',
                ":hover": { textDecoration: 'underline' },
                ...sx,
            }}
        />
    )
}

export const ActionBillListLink = (props) => {
    const navigate = useNavigate();
    const { sx, title, navigationState, id = 'bill_link', dataTestId = 'bill_link' } = props;
    return (
        <CustomTypography
            id={id}
            text={title}
            dataTestId={dataTestId}
            onClick={() => navigate(routesName.invoicingPurchaseBill.path, { state: { ...navigationState } })}
            sx={{
                fontWeight: 700,
                color: '#2464EB',
                fontSize: '12px',
                cursor: 'pointer',
                whiteSpace: 'nowrap',
                textTransform: 'none',
                ":hover": { textDecoration: 'underline' },
                ...sx,
            }}
        />
    )
}


////////////////////////////////////////Action Button/////////////////////////////////////////////////////
export const ActionTypeLink = (props) => {
    const { id, title, action_type, style } = props;
    return (
        <CustomLink
            style={{ ...style }}
            to={
                action_type === 'item' || action_type === 'item_stock_adjustment' ?
                    routesName.invoicingItemsView.path + "?item_id=" + id :
                    action_type === 'customer' ?
                        routesName.invoicingSalesCustomerView.path + "?id=" + id :
                        action_type === 'estimate' ?
                            routesName.invoicingSalesEstimateView.path + "?id=" + id :
                            action_type === 'sales_order' ?
                                routesName.invoicingSalesOrderView.path + "?id=" + id :
                                action_type === 'delivery_challan' ?
                                    routesName.invoicingDeliveryChallanView.path + "?id=" + id :
                                    action_type === 'invoice' ?
                                        routesName.invoicingSalesInvoiceView.path + "?id=" + id :
                                        action_type === 'recurring_invoice' ?
                                            routesName.invoicingSalesRecurringInvoiceView.path + "?id=" + id :
                                            action_type === 'credit_note' ?
                                                routesName.invoicingCreditNotesView.path + "?id=" + id :
                                                action_type === 'vendor' ?
                                                    routesName.invoicingPurchaseVendorView.path + "?id=" + id :
                                                    action_type === 'expense' ?
                                                        routesName.invoicingExpenseView.path + "?id=" + id :
                                                        action_type === 'recurring_expense' ?
                                                            routesName.invoicingRecurringExpenseView.path + "?id=" + id :
                                                            action_type === 'purchase_order' ?
                                                                routesName.invoicingPurchaseOrderView.path + "?id=" + id :
                                                                action_type === 'bill' ?
                                                                    routesName.invoicingPurchaseBillView.path + "?id=" + id :
                                                                    action_type === 'debit_note' ?
                                                                        routesName.invoicingDebitNotesView.path + "?id=" + id :
                                                                        ''



            }
        >
            {title}
        </CustomLink>
    )
}