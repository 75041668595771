import './index.css';
import App from './App';
import React from 'react';
import "./assets/css/loader.css";
import './proptypes/ArrayProptype';
import './assets/css/Dashboard.css';
import './proptypes/StringProptype';
import './assets/css/mediaQuery.css';
import ReactDOM from 'react-dom/client';
import * as Sentry from "@sentry/react";
import { StateProvider } from './state/Store';
import reportWebVitals from './reportWebVitals';
import { SENTRY_SDK, ENVIRONMENT } from './config/config';
import { StyledEngineProvider } from '@mui/material/styles';
import 'react-virtualized/styles.css';

Sentry.init({
  dsn: SENTRY_SDK,
  environment: ENVIRONMENT,

  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],

  // dsn: "https://d6b350e0765d1400c46396c4fed31746@o4507167914328064.ingest.de.sentry.io/4507168566149200",

// Performance Monitoring
tracesSampleRate: 0.01, //  Capture 100% of the transactions

// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
// tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

// Session Replay
replaysSessionSampleRate: 0.01, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
replaysOnErrorSampleRate: 0.01, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <StyledEngineProvider injectFirst>
  <StateProvider>
    <App />
  </StateProvider>
  // </StyledEngineProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
