import React from 'react';
import DateRange from '../../../common/DateRange';
import * as Constant from '../../../../utils/Constant';

const Dropdown = DateRange;

const FilterStatus = ({ dataKey ='', filters, setFilters, setPage }) => {
    const { status } = filters;
    const results = [...Constant[dataKey]];
    const selectedValue = results.find((item) => item.value === status);

    const handleSetValue = React.useCallback((event, value) => {
        setPage(1);
        const updatedFilters = value ? { ...filters, status: value.value } : { ...filters };
        if (!value) {
            delete updatedFilters.status;
        }
        setFilters(updatedFilters);
    }, [filters, setPage, setFilters]);

    return (
        <Dropdown
            label="Status"
            results={results}
            id="status_dropdown"
            value={selectedValue}
            disableClearable={false}
            setValue={handleSetValue}
            placeholder="Select Status"
            dataTestId="status_dropdown"
        />
    );
};

export default FilterStatus;
