// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-container {
  box-shadow: none !important;
  background: #FAFAFA !important;
  height: calc(100vh - 70px) !important;
}

.box {
  padding: 16px !important;
  background: #FFFFFF !important;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.04) !important;
}

.box-mt-4{
  margin-top: 32px !important;
}

.box-first {
  margin-top: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/Dashboard.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,8BAA8B;EAC9B,qCAAqC;AACvC;;AAEA;EACE,wBAAwB;EACxB,8BAA8B;EAC9B,sDAAsD;AACxD;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,wBAAwB;AAC1B","sourcesContent":[".custom-container {\r\n  box-shadow: none !important;\r\n  background: #FAFAFA !important;\r\n  height: calc(100vh - 70px) !important;\r\n}\r\n\r\n.box {\r\n  padding: 16px !important;\r\n  background: #FFFFFF !important;\r\n  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.04) !important;\r\n}\r\n\r\n.box-mt-4{\r\n  margin-top: 32px !important;\r\n}\r\n\r\n.box-first {\r\n  margin-top: 0 !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
