import React from 'react';
import moment from 'moment/moment';
import Status from '../../../../common/Status';
import * as Actions from '../../../../../state/Actions';
import CustomTitle from '../../../../common/CustomTitle';
import { ExpenseViewLink } from '../../common/CommonLinks';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomTabs from '../../../../custom/tabs/CustomTabs';
import { routesName } from '../../../../../config/routesName';
import { CommonWebSocket } from '../../../../../api/webSocket';
import CustomDialog from '../../../../custom/dialog/CustomDialog';
import { getBusinessInfo } from '../../../../../config/cookiesInfo';
import CurrencyFormatter from '../../../../common/CurrencyFormatter';
import AuditTrailHistory from '../../../../common/AuditTrailHistory';
import { HeadingWithSortable } from '../../../../common/SortableHeading';
import ViewPageActionButtons from '../../../../common/ViewPageActionButtons';
import CustomPagination from '../../../../custom/pagination/CustomPagination';
import CustomTypography from '../../../../custom/typography/CustomTypography';
import { eventsNames,AnalyticsEvent } from '../../../../../firebase/firebaseAnalytics';

import {
    expense_number,
    recurring_expense_number,
} from '../../../../../utils/Constant';

import {
    CustomContainer,
    CustomTitleContainer,
} from '../../../../custom/container/CustomContainer';

import {
    apiAction,
    apiBlobResponse,
    apiHandleDownload
} from '../../../../../api/api';

import {
    list_expense,
    export_expense,
    delete_expense,
    retrieve_expense,
    delete_recurring_expense,
    update_recurring_expense,
    retrieve_recurring_expense,
} from '../../../../../api/urls';

import {
    setLoader,
    getDateFormat,
    getQueryParams,
    stateChangeManager,
    to_check_repeat_every,
    breadCrumbsStateChangeManager,
} from '../../../../../utils/Utils';

import {
    CustomTable,
    CustomTableRow,
    CustomTableBody,
    CustomTableHead,
    CustomTableHeadCell,
    CustomTableBodyCell,
    CustomTableContainer,
} from '../../../../custom/table/CustomTable';

import {
    Box,
    Grid,
    Avatar,
} from '@mui/material';
import { InfoRounded } from '@mui/icons-material';

const ViewRecurringExpense = () => {
    let location = useLocation();
    let navigate = useNavigate();
    const params = getQueryParams(location.search);

    const dispatch = Actions.getDispatch(React.useContext);

    const [result, setResult] = React.useState();
    const [reLoad, setReLoad] = React.useState(false)

    const [state, setState] = React.useState({
        id: '',
        title: '',
        open: false,
        condition: '',
        maxWidth: 'lg',
        fullWidth: true,
    });

    const stateChangeHandler = (title, condition, maxWidth, url) => {
        setState({
            url: url,
            open: true,
            title: title,
            fullWidth: true,
            maxWidth: maxWidth,
            condition: condition,
            navigate: () => navigate(routesName.invoicingRecurringExpense.path),
        })
    }

    React.useEffect(() => {
        getApiResults(params.id);
        // eslint-disable-next-line
    }, [reLoad]);


    const getApiResults = async (id) => {
        setLoader(dispatch, Actions, true)
        let res = await apiAction({
            method: 'get',
            url: retrieve_recurring_expense(id),
        })

        if (res?.success) {
            res.result.expense_data = { ...res.result.expense_data, vendor: res?.result.vendor, vendor_display_name: res?.result.vendor_display_name };
            setResult(res?.result);
            setLoader(dispatch, Actions, false);
            breadCrumbsStateChangeManager(dispatch, `${recurring_expense_number}-${res?.result.periodic_task_id}`);
        } else {
            setLoader(dispatch, Actions, false)
        }
    }

    const manageStatus = async (id, is_active) => {

        let actionType=is_active?eventsNames.actions.recurringExpense.action.ACTIVATE:eventsNames.actions.recurringExpense.action.DEACTIVATE
        AnalyticsEvent(eventsNames.categories.RECURRING_EXPENSES,{action:actionType})
        
        setLoader(dispatch, Actions, true);
        let res = await apiAction({
            method: 'post',
            dispatch: dispatch,
            navigate: navigate,
            url: update_recurring_expense(id),
            data: { business_id: getBusinessInfo().id, is_active: is_active },
        })
        if (res?.success) {
            getApiResults(params.id);
            setLoader(dispatch, Actions, false);
        } else {
            setLoader(dispatch, Actions, false);
        }
    }
    return (
        result &&
        <div>
            <CustomDialog
                state={state}
                setState={setState}
            />
            <CustomTitleContainer>
                <Grid container spacing={0} style={{
                    alignItems: 'center',
                    justifyItems: 'center',
                    alignContent: 'space-evenly',
                    justifyContent: 'space-evenly',
                }}>
                    <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'start', }}>
                        <CustomTitle title={result.profile_name} />
                    </Grid>
                    <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'end', }}>
                        <ViewPageActionButtons
                            isPrint={false}
                            isEditable={true}
                            isDownload={false}
                            edit={() => { navigate(routesName.invoicingRecurringExpenseAdd.path + "?recurring_id=" + result.periodic_task_id, { replace: false }); }}

                            actionsList={[
                                result.status !== 'Expired' && { name: result.status === 'Active' ? 'Deactivate' : 'Activate', onClick: () => { manageStatus(result.periodic_task_id, result.status === 'Active' ? false : true) } },
                                { name: 'Clone', onClick: () =>{ navigate(routesName.invoicingRecurringExpenseAdd.path + "?recurring_clone_id=" + result.periodic_task_id, { replace: false });AnalyticsEvent(eventsNames.categories.RECURRING_EXPENSES,{action:eventsNames.actions.CLONE}) }},
                                { name: 'Delete', showDivider: true, onClick: () => { stateChangeHandler('Delete Recurring Expense', 'delete', 'sm', delete_recurring_expense(result.periodic_task_id)); AnalyticsEvent(eventsNames.categories.RECURRING_EXPENSES,{action:eventsNames.actions.DELETE}) } }


                            ]}
                        />
                        <AuditTrailHistory
                            data={{ object_id: result.periodic_task_id, object_type: 'recurring_expense' }}
                            AnalyticsEvent={()=>{AnalyticsEvent(eventsNames.categories.RECURRING_EXPENSES,{action:eventsNames.actions.HISTORY})}}
                        />
                    </Grid>
                </Grid>
            </CustomTitleContainer>

            <CustomContainer maxWidth={"400"} sx={{ height: 'calc(100vh - 134px)' }}>
                <Box sx={{ borderBottom: '2px solid #F5F5F5' }} >
                    <UserDetails
                        user_info={{ ...result.expense_data.vendor.party_contact_data.find((item) => item.contact_type === "primary"), vendor_display_name: result.expense_data.vendor_display_name, preference: result.preference }}
                        vendor_Details={[
                            { title: 'Profile Status:', value: <Status status={result.status} /> },
                            { title: 'Start Date:', value: result.starts_on ? moment(result.starts_on).format(getDateFormat()) : '-' },
                            { title: 'End Date:', value: result.never_expires ? 'Never Expires' : result.ends_on ? moment(result.ends_on).format(getDateFormat()) : '-' },
                        ]}
                        expense_Details={[
                            { title: 'Expense Type', value: result.expense_data.expense_type },
                            { title: 'Paid Through', value: result.expense_data.paid_through_account ? result.expense_data.paid_through_account.account_name : '-' },
                        ]}
                        expense_accounts={result.expense_data.expense_accounts_list}
                    />
                </Box>
                <Box sx={{ borderBottom: '2px solid #F5F5F5' }} >
                    <ExpenseDetails
                        recurring_expense_data={[
                            { title: 'Expense Amount', value: <CurrencyFormatter amount={result.expense_data.total_amount} currency={result.expense_data.currency_code} /> },
                            { title: 'Next Expense Date', value: result.status === 'Expired' ? '-' : result.next_expense_date ? moment(result.next_expense_date).format(getDateFormat()) : '-', borderLeft: '2px solid #F5F5F5', borderRight: '2px solid #F5F5F5', },
                            { title: 'Recurring Period', value: to_check_repeat_every(result.repeat_frequency, result.repeat_every) ? to_check_repeat_every(result.repeat_frequency, result.repeat_every).name : `Every ${result.repeat_every} ${result.repeat_frequency.capitalize()}s` }
                        ]}
                    />
                </Box>

                <CustomTableContainer>
                    <CustomTabs
                        id={'all_expenses'}
                        dataTestId={'all_expenses'}
                        tabs={[{ name: 'All Expenses' }]}
                        value={0}
                        onChange={() => { }}
                    />
                    <AllExpense />
                </CustomTableContainer>
            </CustomContainer>

        </div>
    )
}

export default ViewRecurringExpense;

const AllExpense = () => {
    let navigate = useNavigate();
    let location = useLocation();
    const dispatch = Actions.getDispatch(React.useContext);
    const params = getQueryParams(location.search);

    const [page, setPage] = React.useState(1);
    const [results, setResults] = React.useState([]);
    const [pagination, setPagination] = React.useState({
        next: undefined,
        count: undefined,
        previous: undefined,
        number_of_pages: undefined,
    });

    const [state, setState] = React.useState({
        id: '',
        title: '',
        open: false,
        condition: '',
        maxWidth: 'lg',
        fullWidth: true,
    })

    const stateChangeHandler = (title, condition, maxWidth, url, deleteMessage) => {
        setState({
            url: url,
            open: true,
            title: title,
            fullWidth: true,
            maxWidth: maxWidth,
            condition: condition,
            deleteMessage: deleteMessage,
        })
    }

    const [filters, setFilters] = React.useState({
        sort_by: 'expense_date',
        sort_order: 'D'
    })

    let body = { business_id: getBusinessInfo().id, ...filters }
    React.useEffect(() => {
        getApiResults(body, page)
        // eslint-disable-next-line
    }, [page, filters]);

    const getApiResults = async (body, page) => {
        let res = await apiAction({
            method: 'post',
            dispatch: dispatch,
            navigate: navigate,
            url: list_expense(page),
            data: { ...body, recurring_expense_id: Number(params.id), },
        })
        if (res?.success) {
            setPagination({
                ...pagination,
                next: res?.result.next,
                count: res?.result.count,
                previous: res?.result.previous,
                number_of_pages: res?.result.number_of_pages,
            });
            setResults(res?.result.result);
            setLoader(dispatch, Actions, false);
        } else {
            setLoader(dispatch, Actions, false);
        }
    }

    const webSocketResponse = CommonWebSocket();
    React.useEffect(() => {
        if (webSocketResponse) {
            let webSocketData = JSON.parse(webSocketResponse.data)
            if (webSocketData.document_type === "expense") {
                let index = results.findIndex((item) => item.id === webSocketData.object_id);

                if (webSocketData.event === "delete_document" && index !== null && index >= 0) {
                    if (webSocketData.event_success) {
                        stateChangeManager(dispatch, Actions, true, "success", `Expense ${expense_number}-${webSocketData.object_id} deleted successfully`);
                        getApiResults(body, page);
                    } else {
                        stateChangeManager(dispatch, Actions, true, "error", `Failed to delete Expense ${expense_number}-${webSocketData.object_id}`);
                    }
                }

                if (webSocketData.event === "update_document" && index !== null && index >= 0) {
                    if (webSocketData.event_success) {
                        stateChangeManager(dispatch, Actions, true, "success", `Expense ${expense_number}-${webSocketData.object_id} updated successfully`);
                        getApiResults(body, page);
                    } else {
                        stateChangeManager(dispatch, Actions, true, "error", `Failed to update Expense ${expense_number}-${webSocketData.object_id}`);
                    }
                }
            }
        }
    }, [webSocketResponse])
    return (
        <React.Fragment>
            <CustomTable sx={{}}>
                <ListHeaders filters={filters} setFilters={setFilters} />
                <ListBody data={results} stateChangeHandler={stateChangeHandler} />
            </CustomTable>
            <CustomPagination
                page={page}
                count={pagination.number_of_pages}
                onChange={(event, newValue) => setPage(newValue)}
            />
        </React.Fragment>
    )
}

const UserDetails = (props) => {
    const { user_info, vendor_Details, expense_Details, expense_accounts } = props

    const preference = [
        { name: 'Create and Send Later', preference: 'create_and_save' },
        { name: 'Create and Send Invoices', preference: 'create_and_send' },
        { name: 'Create Invoices as Drafts', preference: 'create_and_draft' },
        { name: 'Create Invoices as Drafts', preference: 'create_invoices_as_drafts' },
    ]

    return (
        <Box>
            <Grid container>
                <Grid item xs={4}>
                    <Box sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'row',
                    }}>
                        <Avatar />
                        <Box sx={{ alignSelf: 'center', ml: 2.5 }}>

                            <CustomTypography
                                text={user_info.vendor_display_name}
                                sx={{
                                    mb: 1,
                                    color: '#000000',
                                    fontSize: '14px',
                                    fontWeight: '700',
                                    lineHeight: '18px',
                                    fontStyle: 'normal',
                                    fontFamily: "Noto Sans",
                                }}
                            />
                            <CustomTypography
                                text={user_info.email}
                                sx={{
                                    mb: 0.5,
                                    color: '#7A7A7A',
                                    fontSize: '12px',
                                    fontWeight: '500',
                                    lineHeight: '18px',
                                    fontStyle: 'normal',
                                    fontFamily: "Noto Sans",
                                }}
                            />
                            <CustomTypography
                                text={user_info.mobile_number ? user_info.mobile_number : '-'}
                                sx={{
                                    mb: 0.5,
                                    color: '#7A7A7A',
                                    fontSize: '12px',
                                    fontWeight: '500',
                                    lineHeight: '18px',
                                    fontStyle: 'normal',
                                    fontFamily: "Noto Sans",
                                }}
                            />
                        </Box>
                    </Box>

                    <Box sx={{
                        m: 1,
                        p: 2,
                        borderRadius: 1,
                        background: '#f6f6f6',
                    }}>
                        <Grid container>
                            {
                                vendor_Details.map((item, index) => {
                                    return (
                                        <React.Fragment key={index} >
                                            <Grid item xs={6}>
                                                <CustomTypography
                                                    text={item.title}
                                                    sx={{
                                                        mb: 1,
                                                        color: '#000000',
                                                        fontSize: '14px',
                                                        fontWeight: '500',
                                                        lineHeight: '18px',
                                                        fontStyle: 'normal',
                                                        fontFamily: "Noto Sans",
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <CustomTypography
                                                    text={item.value}
                                                    sx={{
                                                        mb: 1,
                                                        color: '#7A7A7A',
                                                        fontSize: '14px',
                                                        fontWeight: '400',
                                                        lineHeight: '18px',
                                                        fontStyle: 'normal',
                                                        fontFamily: "Noto Sans",
                                                    }}
                                                />
                                            </Grid>
                                        </React.Fragment>
                                    )
                                })
                            }

                        </Grid>
                    </Box>

                </Grid>

                <Grid item xs={4} sx={{
                    p: 2,
                    borderLeft: '2px solid #F5F5F5',
                    borderRight: '2px solid #F5F5F5',
                }}>
                    {
                        expense_Details.map((item, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <CustomTypography
                                        text={item.title}
                                        sx={{
                                            mb: 1,
                                            mt: index ? 3 : 0,
                                            color: '#000000',
                                            fontSize: '14px',
                                            fontWeight: '700',
                                            lineHeight: '18px',
                                            fontStyle: 'normal',
                                            fontFamily: "Noto Sans",
                                        }}
                                    />
                                    <CustomTypography
                                        text={item.value ? item.value : '-'}
                                        sx={{
                                            mb: 1,
                                            color: '#7A7A7A',
                                            fontSize: '12px',
                                            fontWeight: '500',
                                            lineHeight: '18px',
                                            fontStyle: 'normal',
                                            fontFamily: "Noto Sans",
                                        }}
                                    />
                                </React.Fragment>
                            )
                        })
                    }
                </Grid>

                <Grid item xs={4}>
                    <Box sx={{
                        p: 2,
                    }}>
                        <CustomTypography
                            text={'Expense Account'}
                            sx={{
                                mb: 1,
                                color: '#000000',
                                fontSize: '14px',
                                fontWeight: '700',
                                lineHeight: '18px',
                                fontStyle: 'normal',
                                fontFamily: "Noto Sans",
                            }}
                        />
                        <Box sx={{ maxHeight: 185, overflow: 'auto' }}>
                            {
                                expense_accounts.map((item, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <CustomTypography
                                                text={item.account ? item.account.account_name : '-'}
                                                sx={{
                                                    mb: 1,
                                                    color: '#7A7A7A',
                                                    fontSize: '12px',
                                                    fontWeight: '500',
                                                    lineHeight: '18px',
                                                    fontStyle: 'normal',
                                                    fontFamily: "Noto Sans",
                                                }}
                                            />
                                        </React.Fragment>
                                    )
                                })
                            }
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

const ExpenseDetails = (props) => {
    const { recurring_expense_data } = props

    return (
        <Box>
            <Grid container>
                {
                    recurring_expense_data.map((item, index) => {
                        return (
                            <Grid item xs={4} key={index} sx={{
                                p: 2,
                                borderLeft: item.borderLeft,
                                borderRight: item.borderRight,
                            }}
                            >
                                <Box>
                                    <CustomTypography
                                        text={item.title}
                                        sx={{
                                            mb: 1,
                                            color: '#000000',
                                            fontSize: '14px',
                                            fontWeight: '700',
                                            lineHeight: '18px',
                                            fontStyle: 'normal',
                                            fontFamily: "Noto Sans",
                                        }} />
                                    <CustomTypography
                                        text={item.value}
                                        sx={{
                                            mb: 1,
                                            color: '#7A7A7A',
                                            fontSize: '14px',
                                            fontWeight: '500',
                                            lineHeight: '18px',
                                            fontStyle: 'normal',
                                            fontFamily: "Noto Sans",
                                        }}
                                    />
                                </Box>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </Box>
    )
}


const ListHeaders = (props) => {
    const { filters, setFilters } = props

    return (
        <CustomTableHead>
            <CustomTableRow >
                <CustomTableHeadCell style={{ width: 100 }} align='center'><span style={{ cursor: '' }}>Status </span></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 100 }}><HeadingWithSortable heading={'Date'} sortableKey={'expense_date'} filters={filters} setFilters={setFilters} /></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 100 }}><HeadingWithSortable heading={'Number'} sortableKey={'id'} filters={filters} setFilters={setFilters} /></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 180 }}><HeadingWithSortable heading={'Vendor Name'} sortableKey={'vendor_name'} filters={filters} setFilters={setFilters} /></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 180 }}><HeadingWithSortable heading={'Customer Name'} sortableKey={'customer_name'} filters={filters} setFilters={setFilters} /></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 200 }} align='right' ><HeadingWithSortable heading={'Amount'} sortableKey={'total_amount'} filters={filters} setFilters={setFilters} /></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 200 }} align='right' ><span>Actions</span></CustomTableHeadCell>
            </CustomTableRow>
        </CustomTableHead>
    )
}

const ListBody = (props) => {
    const { data } = props
    let navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);

    const onSelectAction = (action, item) => {
        setLoader(dispatch, Actions, true)
        if (action === "edit") {
            setLoader(dispatch, Actions, true)
            navigate(routesName.invoicingExpenseAdd.path + "?id=" + item.id)
        }
        if (action === "view") {
            setLoader(dispatch, Actions, true)
            navigate(routesName.invoicingExpenseView.path + "?id=" + item.id)
        }
        if (action === "print") {
            print(item)
        }
        if (action === "download") {
            download(item)
        }
        if (action === "convert") {
            setLoader(dispatch, Actions, true)
            navigate(routesName.invoicingSalesInvoiceAdd.path + "?expense_id=" + item.id)
        }

    }

    const print = async (expense) => {
        let data = await apiAction({
            method: 'post',
            url: retrieve_expense(expense.id),
            data: { business_id: getBusinessInfo().id },
        })
        if (data) {
            apiBlobResponse({
                url: export_expense(), data: { ...data.result }, onSuccess: () => {
                    setLoader(dispatch, Actions, false)
                }
            })
        }
    }

    const download = async (expense) => {
        let data = await apiAction({
            method: 'post',
            url: retrieve_expense(expense.id),
            data: { business_id: getBusinessInfo().id },
        })
        if (data) {
            apiHandleDownload({
                url: export_expense(), data: { ...data.result }, filename: data.result.vendor_name + ' ' + data.result.id, onSuccess: () => {
                    setLoader(dispatch, Actions, false)
                }
            })
        }
    }

    const toActionText = (status) => {
        let text = ''
        if (status === 'unbilled') {
            text = 'Convert to Invoice'
        } else if (status !== 'unbilled') {
            text = 'View'
        }
        return text
    }

    const onClickActionText = (status, item) => {
        if (status === 'unbilled') {
            onSelectAction("convert", item);
        } else if (status !== 'unbilled') {
            onSelectAction("view", item);
        }
    }

    return (
        <CustomTableBody>
            {
                data.map((item, index) => {
                    return (
                        <CustomTableRow key={index}>
                            <CustomTableBodyCell sx={{}} align='center'>
                                <Status status={item.status} />
                                {item.is_recurring ?
                                    null
                                    :
                                    <CustomTypography
                                        text={`Manually Added`}
                                        sx={{
                                            color: '#7A7A7A',
                                            fontSize: '12px',
                                            fontWeight: '500',
                                            lineHeight: '18px',
                                            fontStyle: 'normal',
                                            fontFamily: "Noto Sans",
                                        }}
                                    />
                                }
                            </CustomTableBodyCell>
                            <CustomTableBodyCell sx={{}} ><span>{moment(item.expense_date).format(getDateFormat())}</span></CustomTableBodyCell>
                            <CustomTableBodyCell sx={{}} ><span><ExpenseViewLink id={item.id} title={item.id} /></span></CustomTableBodyCell>
                            <CustomTableBodyCell sx={{}} ><span>{item.vendor_name ? item.vendor_name : "-"}</span></CustomTableBodyCell>
                            <CustomTableBodyCell sx={{}} ><span>{item.customer_name ? item.customer_name : "-"}</span></CustomTableBodyCell>
                            <CustomTableBodyCell sx={{}} align='right'><span>{<CurrencyFormatter amount={item.total_amount} currency={item.currency_code} />}</span></CustomTableBodyCell>
                            <CustomTableBodyCell sx={{}} align='right'>
                                <CustomTypography
                                    id={'action_status_btn' + index}
                                    dataTestId={'action_status_btn' + index}
                                    text={toActionText(item.status)}
                                    onClick={() => onClickActionText(item.status, item)}
                                    sx={{
                                        marginTop: '12px',
                                        fontSize: '12px',
                                        fontWeight: 700,
                                        color: '#2464EB',
                                        cursor: 'pointer',
                                        textTransform: 'none',
                                        ":hover": { textDecoration: 'underline' },
                                    }}
                                />
                            </CustomTableBodyCell>
                        </CustomTableRow>
                    )
                })
            }
        </CustomTableBody>
    )
}