import moment from "moment";
import validator from "validator";
import React, { useState } from "react";
import { TaxList } from "../../common/GST";
import Input from "../../../../common/Input";
import DateRange from "../../../../common/DateRange";
import DatePicker from "../../../../common/DatePicker";
import * as Actions from "../../../../../state/Actions";
import { InputFile } from "../../../../common/InputFile";
import CustomTitle from "../../../../common/CustomTitle";
import { useLocation, useNavigate } from "react-router-dom";
import AsyncDropdown from "../../../../common/AsyncDropdown";
import { routesName } from "../../../../../config/routesName";
import CommonCalculation from "../../common/CommonCalculation";
import CommonDropdown from "../../../../common/CommonDropdown";
import { apiAction, apiFormData } from "../../../../../api/api";
import CustomButton from "../../../../custom/button/CustomButton";
import CustomDialog from "../../../../custom/dialog/CustomDialog";
import { getBusinessInfo } from "../../../../../config/cookiesInfo";
import { LabelWithAsteriskMark } from "../../../../common/CommonLabel";
import CommonAsyncDropdown from "../../../../common/CommonAsyncDropdown";
import { GSTRegistrationTypeChoice } from "../../../../../utils/Constant";
import CommonCurrencySelection from "../../common/CommonCurrencySelection";
import CustomTypography from "../../../../custom/typography/CustomTypography";
import { applyTaxOnMultipleGSTComponents } from "../../sales/invoices/InvoiceManager";
import { eventsNames,AnalyticsEvent } from "../../../../../firebase/firebaseAnalytics";
import CustomDefaultDropdown from "../../../../custom/dropdown/CustomDefaultDropdown";


import {
  getAmountArePreferences,
  getMarkupPreferences,
} from "../../../setting/settings/Preferences/common/getPreferences";

import {
  get_vendor,
  list_party,
  list_vendor,
  update_expense,
  create_expense,
  get_list_of_tax,
  retrieve_expense,
  get_list_of_state,
  get_chart_of_account_nested_nodes
} from "../../../../../api/urls";

import {
  isNumeric,
  setLoader,
  filterTax,
  isFormValid,
  getQueryParams,
  findDefaultTax,
  isVendorRegister,
  limitDecimalPlaces,
  stateChangeManager,
  disabled_gst_number,
  setIsVendorRegister,
  isBusinessRegistered,
  breadCrumbsStateChangeManager,
} from "../../../../../utils/Utils";

import {
  CustomContainer,
  CustomTitleContainer,
  CustomButtonContainer,
} from "../../../../custom/container/CustomContainer";

import {
  CustomTable,
  CustomTableRow,
  CustomTableBody,
  CustomTableHead,
  CustomTableHeadCell,
  CustomTableBodyCell,
  CustomTableContainer,
} from "../../../../custom/table/CustomTable";

import {
  Box,
  Grid,
  Checkbox,
  IconButton,
  FormControlLabel,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const Dropdown = DateRange;

const CreateNewExpense = (props) => {
  const {
    title,
    onClose,
    onClickSave,
    repeat_list,
    expense_data,
    vendorEditable,
    repeat_every_list,
    isRecurringExpense,
    recurringExpenseData,
    setRecurringExpenseData,
  } = props;

  const expense_initial_data = {
    vendor_id: null,
    vendor: null,
    customer_id: null,
    customer: null,
    expense_type: "goods",
    source_of_supply: null,
    source_of_supply_id: null,
    paid_through_account: null,
    gst_registration_type: null,
    paid_through_account_id: null,
    reference_invoice_number: null,
    business_id: getBusinessInfo().id,
    expense_date: moment().format("YYYY-MM-DD"),
    destination_of_supply_id: getBusinessInfo().state_id,

    currency_code: null,
    currency_id: getBusinessInfo().currency
      ? getBusinessInfo().currency.id
      : null,

    subtotal: 0,
    tax: null,
    total_amount: 0,
    has_rcm: false,
    is_billable: false,
    exchange_rate: 1,
    gstin_number: null,
    
    tax_applied: 'exclusive',
    mark_up_percent: getMarkupPreferences(),
    expense_accounts_list: [
      {
        tax: null,
        note: null,
        amount: null,
        account: null,
        tax_id: null,
        hsn_sac_code: "",
        account_id: null,
      },
    ],
  };
  const [state, setState] = React.useState({
    id: "",
    title: "",
    open: false,
    condition: "",
    maxWidth: "lg",
    compulsory: "",
    fullWidth: true,
  });
  const [expense, setExpense] = useState(expense_initial_data);

  const [taxResult, setTaxResult] = React.useState([]);

  const {
    vendor,
    vendor_id,
    gst_registration_type,
    expense_date,
    gstin_number,
    paid_through_account_id,
    currency,
    source_of_supply,
    reference_invoice_number,
    mark_up_percent,
    has_rcm,
    currency_id,
    is_billable,
  } = expense;

  let location = useLocation();
  let navigate = useNavigate();
  setIsVendorRegister(gst_registration_type);
  const params = getQueryParams(location.search);
  const dispatch = Actions.getDispatch(React.useContext);

  const [itemIndex, setItemIndex] = React.useState(null);

  const addButtonHandler = (title, condition, maxWidth, compulsory, index) => {
    setState({
      open: true,
      title: title,
      fullWidth: true,
      maxWidth: maxWidth,
      condition: condition,
      compulsory: compulsory,
    });
    setItemIndex(index);
  };
  const redirect = (id) => {
    if (onClose) {
      onClose(id);
    } else {
      navigate(routesName.invoicingExpenseView.path + "?id=" + id);
    }
  };

  const fetchExpense = async (taxRes) => {
    if (params.id) { breadCrumbsStateChangeManager(dispatch, 'Edit'); }
    let res = await apiAction({
      method: "post",
      url: retrieve_expense(params.id ? params.id : params.clone_id),
      data: { business_id: getBusinessInfo().id },
    });

    if (res?.success) {
      setLoader(dispatch, Actions, false);
      let expense = {
        ...res?.result,
        reference_invoice_number: params.clone_id
          ? null
          : res?.result.reference_invoice_number,
        expense_date: params.clone_id
          ? moment().format("YYYY-MM-DD")
          : res?.result.expense_date,

        customer:
          res?.result.customer_id && res?.result.customer_name
            ? {
              id: res?.result.customer_id,
              name: res?.result.customer_name,
              display_name: res?.result.customer_name,
            }
            : null,
        vendor: {
          id: res?.result.vendor_id,
          name: res?.result.vendor_name,
          display_name: res?.result.vendor_name,
        },

        expense_accounts_list: [
          ...res?.result.expense_accounts_list.map((item) => {
            let default_tax = item.tax_id
              ? taxRes.find((tax) => tax.id === item.tax_id)
              : null;
            return {
              ...item,
              account: { id: item.account_id, account_name: item.account_name },

              tax: default_tax,
              gst_rate: default_tax
                ? default_tax.rate !== 0
                  ? default_tax.rate
                  : null
                : null,
            };
          }),
        ],

        source_of_supply_id: res?.result.source_of_supply.id,
        source_of_supply: res?.result.source_of_supply.id
          ? {
            ...res?.result.source_of_supply,
            name: res?.result.source_of_supply.state,
          }
          : null,

        destination_of_supply_id: res?.result.destination_of_supply.id,
        destination_of_supply: res?.result.destination_of_supply.id
          ? {
            ...res?.result.destination_of_supply,
            name: res?.result.destination_of_supply.state,
          }
          : null,
        paid_through_account: { id: res?.result?.paid_through_account_id, account_name: res?.result?.paid_through_account_name }
      };
      setExpense(expense);
      setLoader(dispatch, Actions, false);
    } else {
    }
  };
  React.useEffect(() => {
    if (taxResult?.length && (params.id || params.clone_id)) {
      fetchExpense(taxResult);
    }
  }, [taxResult]);

  const accounts = applyTaxOnMultipleGSTComponents(
    expense,
    "expense_accounts_list",
    "input_"
  );
  var totalTax = Object.values(accounts)
    .reduce((acc, tax) => acc + tax, 0)
    .toFloat();
  const tax = {
    ...accounts,
    total: parseFloat(parseFloat(totalTax).toFixed(2)),
  };
  expense.accounts = accounts;
  expense.tax = tax;

  if (!("input_IGST" in expense.tax)) {
    expense.tax["input_IGST"] = 0;
  }
  if (!("input_SGST" in expense.tax)) {
    expense.tax["input_SGST"] = 0;
  }
  if (!("input_CGST" in expense.tax)) {
    expense.tax["input_CGST"] = 0;
  }

  const subTotal = expense.expense_accounts_list
    .filter((item) => isNumeric(item.amount))
    .reduce((a, b) => {
      return a + parseFloat(b.amount);
    }, 0);

  const finalPayment = (
    subTotal + (expense.has_rcm ? 0 : parseFloat(parseFloat(totalTax)))
  ).toFixed(2);
  expense.total_amount = Number(finalPayment);
  expense.subtotal = subTotal;

  React.useEffect(() => {
    expense.total_amount = (
      subTotal + (expense.has_rcm ? 0 : parseFloat(parseFloat(totalTax)))
    ).toFixed(2);
    setExpense({ ...expense });
    // eslint-disable-next-line
  }, [expense.has_rcm]);

  const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);
  //////////////////////////////////////////////////////////////////////////////////////////
  let is_Location = gst_registration_type === "overseas";
  const disable_gstin = disabled_gst_number(gst_registration_type) ? true : false;
  let is_gst_with_zero = gst_registration_type === "overseas" || gst_registration_type === "sez";

  //////////////////////////////////////////////////////////////////////////////////////////
  const onSave = async () => {
    console.log("On save ===> Expense ", expense);
    setIsFormSubmitted(true);
    const { expense_accounts_list } = expense;

    const is_tax = expense_accounts_list.find((item) => isVendorRegister && !item.tax_id);
    const is_account_not_selected = expense_accounts_list.find(
      (item) => !item.account_id
    );
    const is_amount_not_selected = expense_accounts_list.find(
      (item) => !item.amount
    );

    let validation_data = [
      { key: "vendor_id", message: "Please Select Vendor" },
      { key: "gst_registration_type", message: "Please Select GST Registration Type", },
      !disable_gstin && { key: "gstin_number", message: "Please Enter GSTIN Number", },
      { key: "expense_date", message: "Please Enter Valid Expense Date" },
      { key: "paid_through_account_id", message: "Please Select Paid Through Account ", },
      isVendorRegister && !is_Location && { key: "source_of_supply_id", message: "Please Select Source of Supply", },
      { key: "", validation: is_account_not_selected, message: "Please Select Account", },
      { key: "", validation: is_tax, message: "Please Select the Tax" },
      { key: "", validation: is_amount_not_selected, message: "Please Enter Amount.", },
      !disable_gstin && { key: "reference_invoice_number", message: "Please Enter Reference Invoice Number" },
      { key: "destination_of_supply_id", message: `Your organization profile is incomplete. The 'State' field is missing. To provide accurate information, please update your Organization Profile by navigating to the Settings section` },
    ];

    const { isValid, message } = isFormValid(expense, validation_data);
    if (isValid) {
      if (params.id) {
        AnalyticsEvent(eventsNames.categories.EXPENSES,{action:eventsNames.actions.EDIT})
      } else {
        AnalyticsEvent(eventsNames.categories.EXPENSES,{action:eventsNames.actions.CREATE})
      }
  
      setLoader(dispatch, Actions, true);
      let res = await apiFormData({
        method: "post",
        data: {
          business_id: getBusinessInfo().id,
          ...expense,
          mark_up_percent: is_billable ? expense.mark_up_percent ? expense.mark_up_percent : 0 : null,
        },
        url: params.id ? update_expense(params.id) : create_expense(),
      });
      if (res?.success) {
        redirect(res?.result.id);
        setLoader(dispatch, Actions, false);
        stateChangeManager(dispatch, Actions, true, "success", res?.status);
      } else {
        setLoader(dispatch, Actions, false);
        stateChangeManager(dispatch, Actions, true, "error", res?.status);
      }
    } else {
      stateChangeManager(dispatch, Actions, true, "error", message);
    }
  };

  const vendorMappings = (vendor) => {
    setIsVendorRegister(vendor?.gst_registration_type)
    if (vendor) {
      setExpense({
        ...expense,
        vendor: vendor,
        vendor_id: vendor.id,

        currency_id: vendor.currency ? vendor.currency.id : null,
        currency_symbol: vendor.currency ? vendor.currency.symbol : null,
        currency_code: vendor.currency ? vendor.currency.currency_code : null,

        gstin_number: vendor.gstin,
        gst_registration_type: vendor.gst_registration_type,

        source_of_supply: null,
        source_of_supply_id: null,
      });
    } else {
      setExpense({
        ...expense,
        vendor: null,
        vendor_id: null,

        gstin_number: null,
        gst_registration_type: null,

        source_of_supply: null,
        source_of_supply_id: null,

        currency_code: null,
        currency_id: getBusinessInfo().currency
          ? getBusinessInfo().currency.id
          : null,
      });
    }
  };

  const source_of_supply_id = expense.source_of_supply_id;
  const taxList = filterTax(taxResult, source_of_supply_id, is_gst_with_zero);

  React.useEffect(() => {
    const apiResults = async () => {
      let res = await apiAction({
        method: "post",
        url: get_list_of_tax(),
        data: {
          business_id: getBusinessInfo().id,
        },
      });
      if (res?.success) {
        setTaxResult(res?.result);
      } else {
      }
    };
    apiResults();
    // eslint-disable-next-line
  }, []);


  // Common api call for the Accounts
  const [NewAccountsResult, setNewAccountsResult] = React.useState();
  const onAddExpenseAccount = (value) => {
    expense.expense_accounts_list[itemIndex] = {
      ...expense.expense_accounts_list[itemIndex],
      account: value,
      account_id: value.id,
    };
    setNewAccountsResult(value);
    setExpense({
      ...expense,
      expense_accounts_list: [...expense.expense_accounts_list],
    });
  };

  const onAddVendor = (vendor) => {
    vendorMappings(vendor);
  };

  React.useEffect(() => {

    if (isRecurringExpense) {
      setRecurringExpenseData({
        ...recurringExpenseData,
        expense_data: expense,
      });
    }
  }, [expense]);

  React.useEffect(() => {
    if (expense_data) {
      setTimeout(() => {
        setExpense({
          ...expense_initial_data,
          ...expense_data,
        });
        setLoader(dispatch, Actions, false);
      }, 1000);
    }
  }, [expense_data]);

  React.useEffect(() => {
    if (onClickSave) {
      setIsFormSubmitted(onClickSave);
    }
  }, [onClickSave]);

  return (
    <div>
      <CustomDialog
        state={state}
        setState={setState}
        onAddVendor={(vendor) => onAddVendor(vendor)}
        onAddAccount={(account) => onAddExpenseAccount(account)}
      />
      <CustomTitleContainer>
        <Grid
          container
          spacing={0}
          style={{
            alignItems: "center",
            justifyItems: "center",
            // alignContent: "space-evenly",
            // justifyContent: "space-evenly",
          }}
        >
          <Grid item xs={12} sm={6} sx={{ display: "-webkit-flex", justifyContent: "start" }}>
            {isRecurringExpense ?
              <Box sx={{ display: "-webkit-flex" }}>
                <CustomTitle
                  title={
                    <span>
                      {title}
                      <FormControlLabel
                        id={"is_active_checkbox"}
                        dataTestId={"is_active_checkbox"}
                        sx={{ paddingLeft: "20px", margin: '0px' }}
                        control={
                          <Checkbox
                            size="small"
                            checked={recurringExpenseData.is_active}
                            onClick={() => {
                              setRecurringExpenseData({
                                ...recurringExpenseData,
                                is_active: !recurringExpenseData.is_active,
                              });
                            }}
                            sx={{ color: "#9b9898" }}
                          />
                        }
                        label={
                          <span
                            style={{
                              color: "#000000",
                              fontFamily: "Noto Sans",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "16px",
                              fontSize: "14px",
                            }}
                          >
                            Is active
                          </span>
                        }
                      />
                    </span>
                  }
                />
              </Box>
              :
              <CustomTitle title={`Record Expense`} />
            }
          </Grid>
          {/* <Grid item xs={12} sm={6} sx={{ display: "-webkit-flex", justifyContent: "end" }}></Grid> */}
        </Grid>
      </CustomTitleContainer>

      <CustomContainer maxWidth={"400"} sx={{ height: isRecurringExpense ? 'calc(100vh - 202px)' : 'calc(100vh - 191px)', }}>
        <div style={{ borderBottom: "4px solid #F5F5F5" }}>
          <Grid container sx={{ p: 2, mb: 4 }} spacing={3}>
            <Grid item xs={12} sm={4}>
              <CommonAsyncDropdown
                id={"vendor_dropdown"}
                dataTestId={"vendor_dropdown"}
                autoSelect={false}
                autoFocus={true}
                disableClearable={false}
                optionLabel="display_name"
                placeholder="Select Vendor"
                noOptionsText={"No result found"}
                validation={isFormSubmitted && !vendor_id}
                item={{
                  value: vendor,
                  method: "post",
                  url: list_vendor(1),
                  label: <LabelWithAsteriskMark label={"Vendor"} />,
                  disabled: vendorEditable !== undefined ? vendorEditable : (!params.clone_id && Object.keys(params).length !== 0),
                  body: {
                    is_inactive: false,
                    business_id: getBusinessInfo().id,
                    role: "vendor",
                  },
                  onChange: async (event, value) => {
                    if (value) {
                      if (value.id !== vendor_id) {
                        const vendorRes = await apiAction({
                          method: "post",
                          url: get_vendor(value.id),
                          data: { business_id: getBusinessInfo().id },
                        });
                        if (vendorRes.success) {
                          vendorMappings(vendorRes.result);
                        }
                      }
                    } else {
                      vendorMappings(value);
                    }
                  },
                }}
                addButton={{
                  title: "+ Add new vendor",
                  onClick: () =>
                    addButtonHandler("New Vendor", "new_vendor", "lg"),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <CustomTypography
                text={<LabelWithAsteriskMark label={'GST Registration Type'} />}
                sx={{
                  mb: 1,
                  color: '#000000',
                  fontSize: '14px',
                  fontWeight: '700',
                  lineHeight: '18px',
                  fontStyle: 'normal',
                  textTransform: "none",
                  fontFamily: "Noto Sans",
                  textTransform: 'capitalize',
                }}
              />
              <CustomDefaultDropdown
                labelKey='name'
                fullWidth={false}
                valueLabelKey={'name'}
                results={GSTRegistrationTypeChoice}
                id={'gst_registration_type_dropdown'}
                placeholder='Select GST Registration Type'
                dataTestId={'gst_registration_type_dropdown'}
                validation={isFormSubmitted && !gst_registration_type}
                value={gst_registration_type ? GSTRegistrationTypeChoice.find((item) => item.value === gst_registration_type) : null}
                setValue={(value) => {
                  setIsVendorRegister(value?.value)
                  if (value) {
                    expense.gstin_number = null;
                    expense.gst_registration_type = value.value;
                    if (value.value === "overseas" || value.value === "sez") {
                      expense.source_of_supply = null;
                      expense.source_of_supply_id = null;
                    }
                  } else {
                    expense.gstin_number = null;
                    expense.gst_registration_type = null;
                  }

                  setExpense({ ...expense });
                }}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Input
                id={"input_gstin"}
                dataTestId={"input_gstin"}
                item={{
                  type: "text",
                  required: true,
                  fullWidth: true,
                  disabled: disable_gstin,
                  inputProps: { maxLength: 15 },
                  placeholder: "For e.g. 29APPCK7465F1Z1",
                  title: <LabelWithAsteriskMark label={"GSTIN"} />,
                  value: gstin_number ? gstin_number.toUpperCase() : "",
                  onChange: (e) => {
                    setExpense({
                      ...expense,
                      gstin_number: e.target.value
                        .replace(/[`~$&+,:;=?_[\]@#|{}'<>.^*()%!-/]/, "")
                        .toUpperCase(),
                    });
                  },
                  validation:
                    isFormSubmitted &&
                    !disable_gstin &&
                    (!gstin_number ||
                      (gstin_number &&
                        !validator.isLength(String(gstin_number), {
                          min: 15,
                          max: 15,
                        }))),
                }}
              />
            </Grid>
            {
              !isRecurringExpense &&
              <Grid item xs={12} sm={4}>
                <DatePicker
                  id={"date_select_1"}
                  dataTestId={"date_select_1"}
                  placeholder=""
                  validation={isFormSubmitted && !expense_date}
                  title={<LabelWithAsteriskMark label={"Date"} />}
                  date={expense_date ? moment(expense_date) : null}
                  setDate={(date) => {
                    console.log("Date ", date);
                    setExpense({
                      ...expense,
                      expense_date: date ? date.format("YYYY-MM-DD") : null,
                    });
                  }}
                />
              </Grid>
            }

            <Grid item xs={12} sm={4}>

              <AsyncDropdown
                sx={{}}
                fullWidth={true}
                autoFocus={false}
                newResults={null}
                disabledCloseIcon={false}
                isGroupHeaderSticky={true}
                id={"paid_through_dropdown"}
                dataTestId={"paid_through_dropdown"}
                validation={isFormSubmitted && !paid_through_account_id}
                title={<LabelWithAsteriskMark label={"Paid Through"} />}

                selectedValue={expense?.paid_through_account}
                setSelectedValue={(value) => {
                  setExpense({
                    ...expense,
                    paid_through_account_id: value ? value.id : null,
                    paid_through_account: value,
                  });
                }}

                valueLabelKey='id'
                uniqueOptionKey='id'
                searchKey='account_name'
                optionLabelKey='account_name'
                placeholder='Select the Account'
                optionGroupLabelKey='account_type'
                playLoad={{ account_type: ["ASSET"] }}
                URL={get_chart_of_account_nested_nodes(1)}

              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <CommonDropdown
                stateComponent={true}
                id={"source_of_supply_dropdown"}
                dataTestId={"source_of_supply_dropdown"}
                disableClearable={false}
                placeholder="Select a Location"
                validation={
                  isVendorRegister &&
                  !is_Location && isFormSubmitted && !source_of_supply_id
                }
                item={{
                  method: "post",
                  value: source_of_supply,
                  url: get_list_of_state(),
                  disabled: is_Location,
                  body: { business_id: getBusinessInfo().id },
                  label: <LabelWithAsteriskMark label={"Source of Supply"} isAsteriskMark={isVendorRegister} />,
                  onChange: (event, value) => {
                    setExpense({
                      ...expense,
                      source_of_supply: value,
                      source_of_supply_id: value ? value.id : null,
                    });
                  },
                }}
              />
            </Grid>

            {isRecurringExpense &&
              <React.Fragment>
                <Grid item xs={12} sm={4}>
                  <Input
                    id={"input_profile_name"}
                    dataTestId={"input_profile_name"}
                    item={{
                      type: "text",
                      required: true,
                      fullWidth: true,
                      texttransform: "none",
                      placeholder: "Enter Profile Name",
                      title: <LabelWithAsteriskMark label={"Profile Name"} />,
                      validation:
                        isFormSubmitted && !recurringExpenseData.profile_name,
                      value: recurringExpenseData.profile_name
                        ? recurringExpenseData.profile_name
                        : "",
                      onChange: (e) =>
                        setRecurringExpenseData({
                          ...recurringExpenseData,
                          profile_name: e.target.value,
                        }),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <DatePicker
                    id={"date_select_1"}
                    dataTestId={"date_select_1"}
                    isTime={true}
                    placeholder=""
                    title={<LabelWithAsteriskMark label={"Start On"} />}
                    validation={
                      isFormSubmitted && !recurringExpenseData.selected_starts_on
                    }
                    date={
                      recurringExpenseData.selected_starts_on
                        ? moment(recurringExpenseData.selected_starts_on)
                        : null
                    }
                    setDate={(date) => {
                      if (date) {
                        setRecurringExpenseData({
                          ...recurringExpenseData,
                          selected_starts_on: date.format("YYYY-MM-DD HH:mm:ssZ"),
                          starts_on: moment(date).utc().format("YYYY-MM-DD HH:mm:ssZ"),
                        });
                      } else {
                        setRecurringExpenseData({
                          ...recurringExpenseData,
                          starts_on: null,
                          selected_starts_on: null,
                        });
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Grid container item xs={12}>
                    <Grid item xs={4}>
                      <CustomTypography
                        text={<LabelWithAsteriskMark label={"End On"} />}
                        sx={{
                          fontSize: "14px",
                          color: "#000000",
                          fontWeight: "700",
                          lineHeight: "18px",
                          fontStyle: "normal",
                          textTransform: "none",
                          fontFamily: "Noto Sans",
                          textTransform: "capitalize",
                        }}
                      />
                    </Grid>
                    <Grid item xs={8} sx={{ mt: -3, textAlign: "right" }}>
                      <FormControlLabel
                        id={"never_expires_checkbox"}
                        dataTestId={"never_expires_checkbox"}
                        sx={{ mb: -5, textAlign: "right" }}
                        label={
                          <span
                            style={{
                              color: "#000000",
                              fontFamily: "Noto Sans",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "16px",
                              fontSize: "12px",
                            }}
                          >
                            Never Expires
                          </span>
                        }
                        control={
                          <Checkbox
                            size="small"
                            checked={recurringExpenseData.never_expires}
                            onClick={() =>
                              setRecurringExpenseData({
                                ...recurringExpenseData,
                                ends_on: null,
                                selected_ends_on: null,
                                never_expires: !recurringExpenseData.never_expires,
                              })
                            }
                            sx={{ color: "#9b9898" }}
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                  <DatePicker
                    id={"date_select_2"}
                    dataTestId={"date_select_2"}
                    title=""
                    isTime={true}
                    placeholder=""
                    validation={
                      isFormSubmitted &&
                      !recurringExpenseData.never_expires &&
                      !recurringExpenseData.selected_ends_on
                    }
                    date={
                      recurringExpenseData.selected_ends_on
                        ? moment(recurringExpenseData.selected_ends_on)
                        : null
                    }
                    minDate={
                      recurringExpenseData.selected_starts_on
                        ? moment(recurringExpenseData.selected_starts_on).add(1, "d")
                        : null
                    }
                    setDate={(date) => {
                      if (date) {
                        setRecurringExpenseData({
                          ...recurringExpenseData,
                          never_expires: false,
                          selected_ends_on: date.format("YYYY-MM-DD HH:mm:ssZ"),
                          ends_on: moment(date).utc().format("YYYY-MM-DD HH:mm:ssZ"),
                        });
                      } else {
                        setRecurringExpenseData({
                          ...recurringExpenseData,
                          ends_on: null,
                          never_expires: true,
                          selected_ends_on: null,
                        });
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Dropdown
                    id={"repeat_every_dropdown"}
                    dataTestId={"repeat_every_dropdown"}
                    disableClearable={true}
                    placeholder="All statuses"
                    results={repeat_every_list}
                    label={<LabelWithAsteriskMark label={"Repeat Every"} />}
                    setValue={(event, value) => {
                      setRecurringExpenseData({
                        ...recurringExpenseData,
                        repeat_every: value.repeat_every,
                        selected_repeat_every_list: value,
                        repeat_frequency: value.repeat_frequency,
                      });
                    }}
                    value={
                      recurringExpenseData.selected_repeat_every_list
                        ? recurringExpenseData.selected_repeat_every_list
                        : null
                    }
                  />
                </Grid>
                {
                  recurringExpenseData.selected_repeat_every_list.name === "Custom" && (
                    <Grid item xs={12} sm={4} container sx={{}}>
                      <Grid item xs={5} sx={{ pr: 1 }}>
                        <Input
                          id={"input_repeat_every"}
                          dataTestId={"input_repeat_every"}
                          item={{
                            type: "number",
                            required: true,
                            fullWidth: true,
                            placeholder: "",
                            title: "Order Number",
                            titleVisibility: "hidden",
                            value: recurringExpenseData.repeat_every
                              ? recurringExpenseData.repeat_every
                              : "",
                            onChange: (e) =>
                              setRecurringExpenseData({
                                ...recurringExpenseData,
                                repeat_every: Number(e.target.value),
                              }),
                          }}
                          onKeyPress={(event) => {
                            if (
                              event?.key === "-" ||
                              event?.key === "+" ||
                              event?.key === "." ||
                              event?.key === "e" ||
                              event?.key === "E"
                            ) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={7} sx={{ pl: 1 }}>
                        <Dropdown
                          id={"repeat_frequency_dropdown"}
                          dataTestId={"repeat_frequency_dropdown"}
                          label={"Order Number"}
                          disableClearable={true}
                          titleVisibility="hidden"
                          placeholder=""
                          results={repeat_list}
                          setValue={(event, value) => {
                            setRecurringExpenseData({
                              ...recurringExpenseData,
                              selected_custom_every_list: value,
                              repeat_frequency: value.repeat_frequency,
                            });
                          }}
                          value={
                            recurringExpenseData.selected_custom_every_list
                              ? recurringExpenseData.selected_custom_every_list
                              : null
                          }
                        />
                      </Grid>
                    </Grid>
                  )
                }
              </React.Fragment>
            }
          </Grid>
        </div>
        <Box style={{ paddingRight: "20px" }}
          sx={{ pb: 2, pt: 2 }}>
          <Grid
            container
            spacing={0}
            style={{
              alignItems: "center",
              justifyItems: "center",
              alignContent: "space-evenly",
              justifyContent: "space-evenly",
            }}
          >
            <Grid item xs={12} sm={6} sx={{ display: { xs: '', sm: '-webkit-flex' }, justifyContent: "start", alignItems: 'center' }}
            >
              {isBusinessRegistered() && (
                <Box sx={{ display: 'flex', alignItems: 'center', mb: -3 }}>
                  <Checkbox
                    id={"tds_checkBox"}
                    dataTestId={"tds_checkBox"}
                    sx={{}}
                    checked={has_rcm}
                    onChange={(e) => {
                      setExpense({ ...expense, has_rcm: e.target.checked });
                    }}
                  />
                  <CustomTypography
                    text={'This transaction is applicable for reverse charge'}
                    sx={{
                      fontSize: "14px",
                      fontWeight: "700",
                      lineHeight: "19px",
                      letterSpacing: "0px",
                      fontFamily: "Noto Sans",
                    }}
                  />
                </Box>
              )}

            </Grid>

            <Grid item xs={12} sm={6} sx={{ display: { xs: '', sm: '-webkit-flex' }, justifyContent: "end", alignItems: 'center' }}
            >
              <CommonCurrencySelection
                data={expense}
                isCurrency={true}
                isAmountAre={false}
                setData={setExpense}
                dropdown_key='vendor_id'
                isFormSubmitted={isFormSubmitted}
              />

            </Grid>
          </Grid>
        </Box>


        <Box sx={{ mb: 2 }}>
          <CustomTableContainer sx={{ overflowX: 'hidden' }} className="custom_table_css">
            <CustomTable>
              <RecordExpenseListHeaders expense={expense} />
              <RecordExpenseListBody
                expense={expense}
                taxResult={taxList}
                setExpense={setExpense}
                isFormSubmitted={isFormSubmitted}
                is_gst_with_zero={is_gst_with_zero}
                addButtonHandler={addButtonHandler}
                NewAccountsResult={NewAccountsResult}
              />
            </CustomTable>
          </CustomTableContainer>
        </Box>
        <Grid
          container
          style={{
            paddingBottom: "24px",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
          spacing={3}
        >
          <Grid item xs={12} sm={6}>
            <Grid item xs={12} sm={12} sx={{ mt: 3 }}>
              <CustomTypography
                id={"add_action"}
                dataTestId={"add_action"}
                text={"+ Add Line"}
                onClick={() => {
                  setExpense({
                    ...expense,
                    expense_accounts_list: [
                      ...expense.expense_accounts_list,
                      {
                        tax: null,
                        note: null,
                        amount: null,
                        tax_id: null,
                        account: null,
                        account_id: null,
                        hsn_sac_code: null,
                      },
                    ],
                  });
                }}
                sx={{
                  fontSize: "12px",
                  color: "#2464EB",
                  cursor: "pointer",
                  display: "inline",
                  fontWeight: "700",
                  lineHeight: "16px",
                  fontStyle: "normal",
                  textTransform: "none",
                  fontFamily: "Noto Sans",
                  ":hover": { textDecoration: "underline" },
                }}
              />
              <CustomTypography
                text={"|"}
                sx={{
                  mr: 1,
                  ml: 1,
                  fontSize: "12px",
                  color: "#B8B8B8",
                  fontWeight: "700",
                  lineHeight: "16px",
                  fontStyle: "normal",
                  display: "inline",
                  textTransform: "none",
                  fontFamily: "Noto Sans",
                }}
              />
              <CustomTypography
                id={"clear_action"}
                dataTestId={"clear_action"}
                text={"Clear All Lines"}
                onClick={() => {
                  setExpense({
                    ...expense,
                    expense_accounts_list: [
                      {
                        tax: null,
                        note: null,
                        amount: null,
                        tax_id: null,
                        account: null,
                        account_id: null,
                        hsn_sac_code: null,
                      },
                    ],
                  });
                }}
                sx={{
                  fontSize: "12px",
                  color: "#2464EB",
                  cursor: "pointer",
                  display: "inline",
                  fontWeight: "700",
                  lineHeight: "16px",
                  fontStyle: "normal",
                  textTransform: "none",
                  fontFamily: "Noto Sans",
                  ":hover": { textDecoration: "underline" },
                }}
              />
            </Grid>
            {!isRecurringExpense &&
              <Grid item xs={12} sm={7.5} sx={{ mt: 3 }}>
                <Input
                  id={"input_invoice"}
                  dataTestId={"input_invoice"}
                  item={{
                    type: "text",
                    required: true,
                    fullWidth: true,
                    inputProps: { maxLength: 15 },
                    placeholder: "Enter Reference Invoice Number",
                    value: reference_invoice_number
                      ? reference_invoice_number
                      : "",
                    title: !disable_gstin ? (
                      <LabelWithAsteriskMark label={"Invoice#"} />
                    ) : (
                      "Invoice#"
                    ),
                    validation:
                      isFormSubmitted &&
                      !disable_gstin &&
                      !reference_invoice_number,
                    onChange: (e) => {
                      setExpense({
                        ...expense,
                        reference_invoice_number:
                          e.target.value !== "" ? e.target.value : null,
                      });
                    },
                  }}
                />
              </Grid>
            }

            <Grid item xs={12} sm={7.5} sx={{ mt: 3 }}>
              <CommonAsyncDropdown
                id={"customer_dropdown"}
                dataTestId={"customer_dropdown"}
                autoSelect={false}
                disableClearable={false}
                optionLabel="display_name"
                placeholder="Select Customer"
                noOptionsText={"No result found"}
                item={{
                  value: expense.customer,
                  method: "post",
                  label: (
                    <p
                      style={{
                        margin: "0px",
                        marginBottom: expense.customer_id ? "-10px" : "0px",
                      }}
                    >
                      <span>Customer</span>
                      {expense.customer_id && (
                        <FormControlLabel
                          id="billable_checkbox"
                          dataTestId="billable_checkbox"
                          sx={{ ml: 1, textAlign: "right" }}
                          control={
                            <Checkbox
                              size="small"
                              checked={is_billable}
                              onClick={() =>
                                setExpense({
                                  ...expense,
                                  is_billable: !expense.is_billable,
                                })
                              }
                              sx={{ color: "#9b9898" }}
                            />
                          }
                          label={
                            <span
                              style={{
                                color: "#000000",
                                fontFamily: "Noto Sans",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "16px",
                                fontSize: "12px",
                              }}
                            >
                              Billable
                            </span>
                          }
                        />
                      )}
                    </p>
                  ),
                  url: list_party(1),
                  body: { business_id: getBusinessInfo().id, role: "customer" },
                  onChange: (event, value) => {
                    if (value) {
                      expense.customer = value;
                      expense.customer_id = value.id;
                    } else {
                      expense.customer = null;
                      expense.customer_id = null;
                      expense.is_billable = false;
                      expense.mark_up_percent = null;
                    }
                    setExpense({ ...expense });
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={7.5} sx={{ mt: 3 }}>
              {expense.is_billable && (
                <Input
                  id={"input_mark_up_percent"}
                  dataTestId={"input_mark_up_percent"}
                  item={{
                    type: "number",
                    required: true,
                    fullWidth: true,
                    inputProps: { maxLength: 15 },
                    placeholder: "Enter Mark up by percentage",
                    title: "Mark up by(%)",
                    value: mark_up_percent !== null ? mark_up_percent : "",
                    onChange: (e) => {
                      if (isNumeric(e.target.value)) {
                        if (
                          0 < parseFloat(e.target.value) &&
                          parseFloat(e.target.value) <= 100
                        ) {
                          setExpense({
                            ...expense,
                            mark_up_percent: Math.abs(limitDecimalPlaces(e.target.value)),
                          });
                        }
                      } else {
                        setExpense({ ...expense, mark_up_percent: null });
                      }
                    },
                  }}
                />
              )}
            </Grid>

            {/*//////////////////////////////// Attachments ////////////////////////////////*/}
            {isRecurringExpense ? null : (
              <Grid item xs={12} sm={7.5} sx={{ mt: 3 }}>
                <InputFile
                  id={"attachment_action"}
                  dataTestId={"attachment_action"}
                  onFileUpload={(file) => {
                    setExpense({ ...expense, attachment: file });
                  }}
                  attachment={expense.attachment}
                  onFileRemove={() => {
                    setExpense({ ...expense, attachment: null });
                  }}
                />
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} sm={6} sx={{ mt: 3 }}>
            <CommonCalculation
              data={expense}
              setData={setExpense}
              isFormSubmitted={isFormSubmitted}

              isCurrency={true}
              dropdown_key='vendor_id'

              totalAmount={0}
              totalDiscount={0}
              accounts={accounts}
              subTotal={subTotal}
              totalTax={totalTax}
              finalPayment={finalPayment}
            />
          </Grid>
        </Grid>
      </CustomContainer>
      {isRecurringExpense ? null : (
        <CustomButtonContainer>
          <Buttons
            onSave={onSave}
            onCancel={() => navigate(-1)}
            onClear={() => setExpense(expense_initial_data)}
          />
        </CustomButtonContainer>
      )}
    </div>
  );
};

export default CreateNewExpense;

const Buttons = (props) => {
  const { onSave, onCancel, onClear } = props;
  let location = useLocation();
  const params = getQueryParams(location.search);

  const clearActionDisabled =
    Object.keys(params).length === 0 || params.clone_id;
  return (
    <Box component="form" noValidate>
      <Grid container>
        <Grid item xs={12} sm={6} sx={{ display: "-webkit-flex", justifyContent: "start" }}>
          {clearActionDisabled && (
            <CustomButton
              id={"clear_btn"}
              dataTestId={"clear_btn"}
              variant="outlined"
              sx={{
                textTransform: "none",
                "&:hover": { backgroundColor: "#e8f3ff" },
              }}
              btnLabel="Clear"
              onClick={() => {
                onClear();
              }}
            />
          )}
          <CustomButton
            id={"cancel_btn"}
            dataTestId={"cancel_btn"}
            variant="contained"
            sx={{ ml: 1, textTransform: "none" }}
            btnLabel="Cancel"
            onClick={() => {
              onCancel();
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ display: "-webkit-flex", justifyContent: "end" }}>
          <CustomButton
            id={"save_btn"}
            dataTestId={"save_btn"}
            variant="contained"
            sx={{ ml: 1, textTransform: "none" }}
            btnLabel="Save"
            onClick={() => {
              onSave();
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const RecordExpenseListHeaders = (props) => {
  const { expense } = props;

  return (
    <CustomTableHead>
      <CustomTableRow>
        <CustomTableHeadCell style={{ width: 1 }} align="left"><span>#</span></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 150 }} align="left"><span style={{ cursor: "" }}>Expense Account </span></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 100 }} align="left"><span style={{ cursor: "" }}>Notes </span></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 100 }} align="left"><span style={{ cursor: "" }}>Amount </span></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 100 }} align="left"><span style={{ cursor: "" }}>Tax{" "}{expense.has_rcm ? (<span style={{ fontSize: "10px", opacity: 0.6 }}>( REVERSE CHARGE )</span>) : ("")}{" "}</span></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 100 }} align="left"><span>Tax Amount</span></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 1 }} align="right"> <span style={{ cursor: "" }}></span></CustomTableHeadCell>
      </CustomTableRow>
    </CustomTableHead>
  );
};

const RecordExpenseListBody = (props) => {
  const {
    expense,
    taxResult,
    setExpense,
    addButtonHandler,
    is_gst_with_zero,
    isFormSubmitted,
    NewAccountsResult,
  } = props;
  const { source_of_supply_id, expense_accounts_list } = expense;

  React.useEffect(() => {
    const getDefaultTax = (value) => {
      return findDefaultTax(taxResult, false, value);
    };

    const updatedData = expense_accounts_list.map((item) => {
      const defaultTax = getDefaultTax(item);
      const isDefaultSelectTax = item.tax_id;

      // Calculate values based on conditions
      const tax = isDefaultSelectTax ? defaultTax : null;
      const taxId = isDefaultSelectTax ? defaultTax?.id ?? null : null;
      const gstRate = isDefaultSelectTax ? defaultTax?.rate ?? null : null;

      return {
        ...item,
        tax: tax,
        tax_id: taxId,
        gst_rate: gstRate,
      }
    });

    setExpense((prev) => ({
      ...prev,
      expense_accounts_list: updatedData
    }));

  }, [is_gst_with_zero,source_of_supply_id]);

  return expense_accounts_list.length ? (
    <CustomTableBody>
      {expense_accounts_list.map((item, index) => {
        const { account_id, note, tax_id, amount, account, tax, tax_amount } = item;

        return (
          <CustomTableRow key={index}>
            <CustomTableBodyCell sx={{}}>
              <span>{index + 1}</span>
            </CustomTableBodyCell>

            <CustomTableBodyCell sx={{}}>
              <div>
                <CustomTypography
                  text={''}
                  sx={{
                    mb: 1,
                    fontSize: '14px',
                    color: '#000000',
                    fontWeight: '700',
                    lineHeight: '18px',
                    fontStyle: 'normal',
                    textTransform: "none",
                    fontFamily: "Noto Sans",
                  }}
                />
                <AsyncDropdown
                  sx={{}}
                  fullWidth={true}
                  autoFocus={false}
                  disabledCloseIcon={false}
                  isGroupHeaderSticky={true}
                  newResults={NewAccountsResult}
                  id={"expense_dropdown_" + index}
                  dataTestId={"expense_dropdown_" + index}
                  validation={isFormSubmitted && !account_id}

                  selectedValue={account}
                  setSelectedValue={(value) => {
                    expense_accounts_list[index].account = value;
                    expense_accounts_list[index].account_id = value
                      ? value.id
                      : null;
                    setExpense({
                      ...expense,
                      expense_accounts_list: [...expense_accounts_list],
                    });
                  }}

                  valueLabelKey='id'
                  uniqueOptionKey='id'
                  searchKey='account_name'
                  optionLabelKey='account_name'
                  placeholder='Select the Account'
                  optionGroupLabelKey='account_type'
                  playLoad={{ account_type: ['EXPENSE'] }}
                  URL={get_chart_of_account_nested_nodes(1)}

                  addButton={{
                    title: "+ Add accounts",
                    onClick: () => addButtonHandler("Add Accounts", "create_sub_account", "sm", "EXPENSE", index),
                  }}
                />
              </div>
            </CustomTableBodyCell>

            <CustomTableBodyCell sx={{}}>
              <Input
                id={"input_note_" + index}
                dataTestId={"input_note_" + index}
                onFocus={() => {
                  expense_accounts_list[index].focus = true;
                  setExpense({
                    ...expense,
                    expense_accounts_list: [...expense_accounts_list],
                  });
                }}
                onBlur={() => {
                  expense_accounts_list[index].focus = false;
                  setExpense({
                    ...expense,
                    expense_accounts_list: [...expense_accounts_list],
                  });
                }}
                item={{
                  type: "text",
                  required: true,
                  multiline: true,
                  fullWidth: true,
                  placeholder: "",
                  value: note ? note : "",
                  rows: expense_accounts_list[index].focus ? 4 : 1,
                  onChange: (event) => {
                    expense_accounts_list[index].note = event.target.value;
                    setExpense({
                      ...expense,
                      expense_accounts_list: [...expense_accounts_list],
                    });
                  },
                }}
              />

            </CustomTableBodyCell>

            <CustomTableBodyCell sx={{}}>
              <Input
                id={"input_amount_" + index}
                dataTestId={"input_amount_" + index}

                onFocus={() => {
                  expense_accounts_list[index].editable = true;
                  setExpense({
                    ...expense,
                    expense_accounts_list: [...expense_accounts_list],
                  });
                }}
                isSearchableComponent={true}
                isShowCommaSeparatedValue={true}
                currency_code={expense.currency_code}
                editable={expense_accounts_list[index].editable}

                item={{
                  type: "number",
                  fullWidth: true,
                  required: true,
                  placeholder: "",
                  value: amount !== null ? amount : "",
                  validation: isFormSubmitted && !amount,
                  onChange: (event) => {
                    if (isNumeric(event.target.value)) {
                      expense_accounts_list[index].amount = limitDecimalPlaces(
                        event.target.value
                      );
                    } else {
                      expense_accounts_list[index].amount = null;
                    }
                    setExpense({
                      ...expense,
                      expense_accounts_list: [...expense_accounts_list],
                    });
                  },
                  onBlur: (event) => {
                    expense_accounts_list[index].editable = false;
                    setExpense({
                      ...expense,
                      expense_accounts_list: [...expense_accounts_list],
                    });
                  },
                }}
                InputProps={{
                  inputProps: { min: 0 },
                }}
                onKeyPress={(event) => {
                  if (
                    event?.key === "-" ||
                    event?.key === "+" ||
                    event?.key === "e" ||
                    event?.key === "E"
                  ) {
                    event.preventDefault();
                  }
                }}
              />
            </CustomTableBodyCell>

            <CustomTableBodyCell sx={{}}>
              <TaxList
                id={"tax_dropdown_" + index}
                dataTestId={"tax_dropdown_" + index}
                value={tax}
                result={taxResult}
                inputDisabled={false}
                disableClearable={false}
                validation={
                  isVendorRegister &&
                  isFormSubmitted &&
                  !tax_id
                }
                setValue={(tax) => {
                  if (tax) {
                    expense_accounts_list[index].tax = tax;
                    expense_accounts_list[index].tax_id = tax.id;
                    expense_accounts_list[index].gst_rate = tax.rate;
                  } else {
                    expense_accounts_list[index].tax = null;
                    expense_accounts_list[index].tax_id = null;
                    expense_accounts_list[index].tax_amount = null;
                  }
                  setExpense({
                    ...expense,
                    expense_accounts_list: [...expense_accounts_list],
                  });
                }}
              />
            </CustomTableBodyCell>

            <CustomTableBodyCell sx={{}}>
              <Input
                id={"input_tax_amount_" + index}
                dataTestId={"input_tax_amount_" + index}
                editable={false}
                is_currency_symbol={true}
                isShowCommaSeparatedValue={true}
                currency_code={expense.currency_code}
                item={{
                  type: "number",
                  disabled: true,
                  fullWidth: true,
                  required: true,
                  placeholder: "",
                  value: tax ? tax_amount : "",
                }}
              />
            </CustomTableBodyCell>

            <CustomTableBodyCell sx={{ color: "#000000" }} align="right">
              <IconButton
                id={"delete_icon_" + index}
                dataTestId={"delete_icon_" + index}
                onClick={() => {
                  if (expense_accounts_list.length > 1) {
                    expense_accounts_list.splice(index, 1);
                    setExpense({
                      ...expense,
                      expense_accounts_list: [...expense_accounts_list],
                    });
                  } else {
                    setExpense({
                      ...expense,
                      expense_accounts_list: [
                        {
                          tax: null,
                          note: null,
                          amount: null,
                          account: null,
                          tax_id: null,
                          hsn_sac_code: "",
                          account_id: null,
                        },
                      ],
                    });
                  }
                }}
              >
                <DeleteIcon />
              </IconButton>
            </CustomTableBodyCell>
          </CustomTableRow>
        );
      })}
    </CustomTableBody>
  ) : (
    <caption
      style={{ textAlign: "center", padding: 50, fontFamily: "Noto Sans" }}
    >
      {" "}
      No bills found for your current filters. Verify your filters and try
      again.
    </caption>
  );
};
