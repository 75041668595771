import React from 'react';
import { apiAction } from '../../../../api/api';
import { useNavigate } from 'react-router-dom';
import * as Actions from '../../../../state/Actions';
import { stateChangeManager } from '../../../../utils/Utils';
import { getBusinessInfo } from '../../../../config/cookiesInfo';
import CurrencyFormatter from '../../../common/CurrencyFormatter';
import CustomTypography from '../../../custom/typography/CustomTypography';
import CustomMultiProgress from '../../../custom/progressbar/CustomMultiProgress';

import {
    Box,
    Grid,
    Tooltip,
} from '@mui/material';

const PayableReceivableComponent = (props) => {
    let navigation = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);

    const { id, dataTestId, type, title, url, navigate } = props;
    const [result, setResult] = React.useState();


    React.useEffect(() => {
        if (url) {
            getApiResults(url)
        }
    }, [url])


    const getApiResults = async (url) => {
        let res = await apiAction({
            url: url,
            method: 'post',
            dispatch: dispatch,
            navigate: navigation,
            data: { business_id: getBusinessInfo().id },
        })
        if (res?.success) {
            setResult(res?.result)
        } else {
            stateChangeManager(dispatch, Actions, true, "error", res?.status);
        }
    }

    const current_amount_percentage = result ? ((result.current_amount / (result.overdue_amount + result.current_amount)) * 100) : 0;
    const overdue_amount_percentage = result ? ((result.overdue_amount / (result.overdue_amount + result.current_amount)) * 100) : 0;


    const positionRef = React.useRef({
        x: 0,
        y: 0,
    });
    const popperRef = React.useRef(null);
    const areaRef = React.useRef(null);

    const handleMouseMove = (event) => {
        positionRef.current = { x: event.clientX, y: event.clientY };

        if (popperRef.current != null) {
            popperRef.current.update();
        }
    };

    let total = result ? result.current_amount + result.overdue_amount : 0
    return (
        // result?
        <div>
            <Box>
                <Box sx={{ pb: 2, borderBottom: '2px solid #F5F5F5' }}>
                    <Grid container style={{
                        alignItems: 'center',
                        justifyItems: 'center',
                        justifyContent: 'space-evenly',
                        alignContent: 'space-evenly',
                    }}>
                        <Grid item xs={6}>
                            <CustomTypography
                                text={title}
                                sx={{
                                    color: '#000000',
                                    display: 'inline',
                                    fontSize: '16px',
                                    textAlign: 'start',
                                    fontWeight: '700',
                                    lineHeight: '18px',
                                    fontStyle: 'normal',
                                    fontFamily: "Noto Sans",
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: '-webkit-right' }}></Grid>
                    </Grid>
                </Box>
                <Box sx={{ p: 2, borderBottom: '2px solid #F5F5F5' }}>
                    <div style={{ paddingBottom: '15px' }}>

                        <Grid container>
                            <Grid item xs={6}>
                                <CustomTypography
                                    text={<span>Total Unpaid {type === 'invoice' ? 'Invoices' : 'Bills'}</span>}
                                    sx={{
                                        opacity: 0.6,
                                        color: '#000000',
                                        fontSize: '12px',
                                        textAlign: 'left',
                                        fontWeight: '400',
                                        fontStyle: 'normal',
                                        textTransform: 'None',
                                        lineHeight: '13.62px',
                                        letterSpacing: '0.2px',
                                        fontFamily: "Noto Sans",
                                    }} />
                            </Grid>
                            <Grid item xs={6}>
                                <CustomTypography
                                    text={<CurrencyFormatter amount={total} />}
                                    sx={{
                                        color: '#000000',
                                        fontSize: '12px',
                                        fontWeight: '700',
                                        textAlign: 'right',
                                        fontStyle: 'normal',
                                        lineHeight: '16.34px',
                                        fontFamily: "Noto Sans",
                                    }} />
                            </Grid>
                        </Grid>
                        {/* <Tooltip title="Add"
                            placement="top"
                            arrow
                            PopperProps={{
                                popperRef,
                                anchorEl: {
                                    getBoundingClientRect: () => {
                                        return new DOMRect(
                                            positionRef.current.x,
                                            areaRef.current.getBoundingClientRect().y,
                                            0,
                                            0,
                                        );
                                    },
                                },
                            }}> */}
                        <div ref={areaRef} onMouseMove={handleMouseMove} style={{ marginTop: '10px', cursor: total ? 'pointer' : '' }} onClick={() => { if (total) { navigate() } }} id={id} dataTestId={dataTestId}>
                            <CustomMultiProgress
                                options={
                                    [
                                        {
                                            color: "#85d1d16e",
                                            // color: "#85d1d1",
                                            // color: "#2464EB",
                                            value: current_amount_percentage,
                                        },
                                        {
                                            color: "#ff98005e",
                                            // color: "#ff9800",
                                            // color: "#e8f3ff",
                                            value: overdue_amount_percentage,
                                        },
                                        {
                                            value: 100,
                                            color: "#F5F5F5",
                                        },
                                    ]
                                }
                            />
                        </div>
                        {/* </Tooltip> */}
                    </div>
                </Box>
                <Box sx={{ pt: 1, pb: 1, fontSize: '12px', }}>
                    <Grid container>
                        <Grid item xs={6} sx={{ pl: 2, pt: 1, pb: 1, borderRight: '1px solid #EBEBEB' }}>
                            <CustomTypography
                                text={'Current'}
                                sx={{
                                    color: '#85d1d1',
                                    // color: '#049735',
                                    fontSize: '12px',
                                    fontWeight: '500',
                                    textAlign: 'start',
                                    lineHeight: '16px',
                                    fontStyle: 'normal',
                                    fontFamily: "Noto Sans",
                                }} />
                            <CustomTypography
                                text={<CurrencyFormatter amount={result ? result.current_amount : 0} />}
                                sx={{
                                    mt: 1,
                                    color: '#000000',
                                    fontSize: '14px',
                                    fontWeight: '700',
                                    textAlign: 'start',
                                    fontStyle: 'normal',
                                    lineHeight: '19.07px',
                                    fontFamily: "Noto Sans",
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} sx={{ pl: 2, pt: 1, pb: 1, }}>
                            <CustomTypography
                                text={'Overdue'}
                                sx={{
                                    color: '#ff9800',
                                    // color: '#ED0F1C',
                                    fontSize: '12px',
                                    fontWeight: '500',
                                    textAlign: 'start',
                                    lineHeight: '16px',
                                    fontStyle: 'normal',
                                    fontFamily: "Noto Sans",
                                }} />

                            <CustomTypography
                                text={<CurrencyFormatter amount={result ? result.overdue_amount : 0} />}
                                sx={{
                                    mt: 1,
                                    color: '#000000',
                                    fontSize: '14px',
                                    fontWeight: '700',
                                    textAlign: 'start',
                                    fontStyle: 'normal',
                                    lineHeight: '19.07px',
                                    fontFamily: "Noto Sans",
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </div>
        // :'Loading....'
    )
}

export default PayableReceivableComponent;