import { Chip, FormLabel } from '@mui/material'
import { memo } from 'react'
import { useDrop } from 'react-dnd'
import CloseIcon from '@mui/icons-material/Close';

const style = {
  
    borderRadius: '30px',
    border: '1px solid #f4c92e',
    padding: '0.3rem 1.5rem',
    marginRight: '1.5rem',
    marginBottom: '1rem',
    float: 'left',
    width: '82%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  
}
function selectBackgroundColor(isActive, canDrop) {
    if (isActive) {
        return 'darkgreen'
    } else if (canDrop) {
        return 'darkkhaki'
    } else {
        return '#fef9e7'
    }
}

export const Dustbin = memo(function Dustbin({
  model_field,
  index,
  onDeleteMapping,
  accept,
  onDrop,
}) {
  const [{ isOver, canDrop }, drop] = useDrop({
    model_field,
    index,
    onDeleteMapping,
    accept,
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })
  const isActive = isOver && canDrop
  
  var backgroundColor = selectBackgroundColor(isActive, canDrop)
  var border = '1px solid #f4c92e'
  if (!isActive) {
        backgroundColor = "#ebebeb"
        border = 'none'
    }

  return (
    <div ref={drop} style={{ ...style,  border, backgroundColor }} data-testid="dustbin">
      {isActive
        ? <Chip label={"Release"} style={{width:'82%'}}></Chip>
        : <>{model_field.file_header ? 
            <>{<label style={{textTransform: 'capitalize'}}>{model_field.verbose_name}{model_field.is_required&&<span style={{color:'red'}}> *</span>}</label>}<CloseIcon fontSize='small' sx={{cursor:'pointer'}} onClick={()=>onDeleteMapping(index)}/></>
          :
            <FormLabel style={{textTransform: 'capitalize'}}>{model_field.verbose_name}{model_field.is_required&&<span style={{color:'red'}}> *</span>}</FormLabel>
        }</>
      }
      
    </div>
  )
})
