import React from 'react';
import moment from 'moment';
import Input from '../../../common/Input';
import DatePicker from '../../../common/DatePicker';
import CurrencyFormatter from '../../../common/CurrencyFormatter';
import CustomTypography from '../../../custom/typography/CustomTypography';


import {
    CustomTable,
    CustomTableRow,
    CustomTableBody,
    CustomTableHead,
    CustomTableHeadCell,
    CustomTableBodyCell,
    CustomTableContainer,
} from '../../../custom/table/CustomTable';


import {
    Box,
    Alert,
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { opening_balance_adjustments } from '../../../../utils/Constant';


const Setup = (props) => {
    const [parentAccounts, setParentAccounts] = React.useState([])
    const { migrationData, setMigrationData, migrationDate, validation, setValidation, accounts, setAccounts } = props


    React.useEffect(() => {
        setAccounts(parentAccounts.map((account) => account.transactions).flat(1))
    }, [parentAccounts])

    React.useEffect(() => {
        let parentAccounts = [...new Set(accounts.map((account) => account.account_type))].filter((account) => account)
        setParentAccounts(parentAccounts.map((parentAccountName) => {
            return {
                name: parentAccountName,
                transactions: accounts.filter((account) => {
                    account['debit'] = account.adjusted_transaction_type === "DEBIT" ? account.adjusted_amount ? account.adjusted_amount : '' : '';
                    account['credit'] = account.adjusted_transaction_type === "CREDIT" ? account.adjusted_amount ? account.adjusted_amount : '' : '';
                    return account.account_type === parentAccountName
                }),


            }
        }))

    }, [])


    const totalBalances = parentAccounts.map((parentAccount) => {
        let debit = 0
        let credit = 0
        parentAccount.transactions.map((transaction) => {
            if (transaction.account_name !== opening_balance_adjustments) {
                if (transaction.debit) {
                    debit += transaction.debit
                }
                if (transaction.credit) {
                    credit += transaction.credit
                }
            }

        })

        return { debit: debit, credit: credit }

    })


    let total_debit = 0
    let total_credit = 0
    totalBalances.map((amounts) => {
        total_debit += amounts.debit
        total_credit += amounts.credit

    })

    React.useEffect(() => {
        if (total_debit > total_credit) {
            setValidation({
                ...validation,
                total_amount: total_debit,
                adjusted_amount: total_debit - total_credit,
            })
        } else {
            setValidation({
                ...validation,
                total_amount: total_credit,
                adjusted_amount: total_credit - total_debit,
            })
        }

    }, [total_debit, total_credit])

    return (
        <div>
            <div style={{ paddingLeft: '24px', paddingRight: '24px', paddingBottom: '20px' }}>
                <Box sx={{
                    display: 'flex',
                }}>
                    {
                        migrationDate && migrationDate.maximum_migration_date ?
                            <DatePicker
                            no_minDate={true}
                            disabledCloseIcon={true}
                            title={'Migration Date'}
                            id={'migration_date_select'}
                            dataTestId={'migration_date_select'}
                            date={moment(migrationData.migration_date)}
                            disabledDatePicker={!migrationDate.is_editable}
                            maxDate={moment(migrationDate.maximum_migration_date)}
                            sx={{ width: 300, background: !migrationDate.is_editable && '#F1F3F4' }}
                            setDate={(date) => { setMigrationData({ ...migrationData, migration_date: moment(date).format('YYYY-MM-DD') }) }}
                            />
                            :
                            <DatePicker
                            no_minDate={true} // User can select the any date if maximum_migration_date is null
                            title={'Migration Date'}
                            disabledCloseIcon={true} 
                            id={'migration_date_select'}
                            dataTestId={'migration_date_select'}
                            date={moment(migrationData.migration_date)}
                            setDate={(date) => { setMigrationData({ ...migrationData, migration_date: moment(date).format('YYYY-MM-DD') }) }}
                            />
                    }


                    <Alert
                        sx={{
                            ml: 1,
                            mt: 2.5,
                            opacity: 0.8,
                            color: '#000000',
                            fontSize: '12px',
                            fontWeight: '700',
                            lineHeight: '18px',
                            background: '#ffff',
                            fontStyle: 'normal',
                            fontFamily: "Noto Sans",
                        }}
                        severity="info">The date on which you generated the Trial Balance report in your previous accounting software while migrating to finycs.</Alert>
                </Box>
            </div>
            {/* <Box sx={{ p: 2, border: '5px solid #F5F5F5' }}>
                <CustomTypography
                    text={'Account Receivable'}
                    sx={{
                        color: '#000000',
                        fontSize: '14px',
                        fontWeight: '700',
                        lineHeight: '18px',
                        fontStyle: 'normal',
                        fontFamily: "Noto Sans",
                    }}
                />
            </Box>
            <Box sx={{ p: 2, border: '5px solid #F5F5F5' }}>
                <CustomTypography
                    text={'Account Payable'}
                    sx={{
                        color: '#000000',
                        fontSize: '14px',
                        fontWeight: '700',
                        lineHeight: '18px',
                        fontStyle: 'normal',
                        fontFamily: "Noto Sans",
                    }}
                />
            </Box> */}

            {
                parentAccounts.map((parentAccount, pIndex) => {
                    return <React.Fragment key={pIndex}>
                        <Box style={{ border: '5px solid #F5F5F5' }}>
                            <Accordion
                                square
                                elevation={0}
                                disableGutters 
                                sx={{
                                    '&:before': { display: 'none' }
                                }}
                                id={'set_up' + pIndex}
                                dataTestId={'set_up' + pIndex}
                            >
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <CustomTypography
                                        text={parentAccount.name.capitalize()}
                                        sx={{
                                            color: '#000000',
                                            fontSize: '14px',
                                            fontWeight: '700',
                                            lineHeight: '18px',
                                            fontStyle: 'normal',
                                            fontFamily: "Noto Sans",
                                        }}
                                    />
                                </AccordionSummary>

                                <AccordionDetails>
                                    <CustomTableContainer >
                                        <CustomTable>
                                            <OpeningBalanceHeader />
                                            <OpeningBalanceBody
                                                parent_index={pIndex}
                                                filterResults={parentAccounts}
                                                data={parentAccount.transactions}
                                                setParentAccounts={setParentAccounts}
                                            />
                                        </CustomTable>
                                    </CustomTableContainer>
                                </AccordionDetails>

                            </Accordion>

                        </Box>
                    </React.Fragment>
                })
            }
            {
                parentAccounts.length ?
                    <CustomTableContainer sx={{ background: '#FFF7DC' }}>
                        <CustomTable sx={{ mb: 3 }}>
                            <CustomTableBody>
                                <CustomTableRow>
                                    <Calculation
                                        title={'Total'}
                                        color={'#000000'}
                                        borderBottom={'none'}
                                        debit={<CurrencyFormatter amount={total_debit} />}
                                        credit={<CurrencyFormatter amount={total_credit} />}
                                    />
                                </CustomTableRow>
                                <CustomTableRow >
                                    <Calculation
                                        color={'#e24b70'}
                                        borderBottom={'1px solid #FFE799'}
                                        title={'Opening Balance Adjustments'}
                                        title2={'This account will hold the difference in credit and debit.'}
                                        debit={total_debit < total_credit ? <CurrencyFormatter amount={total_credit - total_debit} /> : '-'}
                                        credit={total_credit < total_debit ? <CurrencyFormatter amount={total_debit - total_credit} /> : '-'}
                                    />
                                </CustomTableRow>
                                <CustomTableRow>
                                    <Calculation
                                        color={'#000000'}
                                        borderBottom={'none'}
                                        title={'Total Amount'}
                                        title2={'Includes Opening Balance Adjustment account.'}
                                        debit={<CurrencyFormatter amount={total_debit > total_credit ? total_debit : total_credit} />}
                                        credit={<CurrencyFormatter amount={total_debit > total_credit ? total_debit : total_credit} />}
                                    />
                                </CustomTableRow>
                            </CustomTableBody>
                        </CustomTable>
                    </CustomTableContainer>
                    : null
            }

        </div>
    )
}

export default Setup;

const OpeningBalanceHeader = () => {
    return (
        <CustomTableHead>
            <CustomTableRow>
                <CustomTableHeadCell style={{ width: 300 }}><span>Accounts</span></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 200 }} align='right'><span>Debit</span></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 200 }} align='right'><span>Credit</span></CustomTableHeadCell>
            </CustomTableRow>
        </CustomTableHead>
    )
}

const OpeningBalanceBody = (props) => {
    const { data, parent_index, filterResults, setParentAccounts } = props

    return (
        <CustomTableBody>
            {data.map((item, index) => {
                return (
                    item.account_name === opening_balance_adjustments ?
                        null
                        :
                        <CustomTableRow key={index}>
                            <CustomTableBodyCell><span>{item.account_name}</span></CustomTableBodyCell>
                            {/* <CustomTableBodyCell align='right'>
                                {item.available_balance ?
                                    <span>{item.transaction_type === 'DEBIT' ?
                                        <span><CurrencyFormatter amount={item.available_balance} currency={item.currency_code} />( Dr. )</span>
                                        :
                                        <span><CurrencyFormatter amount={item.available_balance} currency={item.currency_code} />( Cr. )</span>}</span>
                                    : <span style={{ textAlign: 'center' }}>-</span>
                                }
                            </CustomTableBodyCell> */}
                            <CustomTableBodyCell align='right'>
                                <Input
                                    id={parent_index + '_input_debit_' + index}
                                    dataTestId={parent_index + '_input_debit_' + index}
                                    item={{
                                        type: 'number',
                                        required: true,
                                        fullWidth: true,
                                        disabled: false,
                                        placeholder: '',
                                        variant: "standard",
                                        value: filterResults[parent_index].transactions[index].debit,
                                        onChange: (e) => {
                                            filterResults[parent_index].transactions[index].credit = ''
                                            filterResults[parent_index].transactions[index].adjusted_transaction_type = "DEBIT"
                                            filterResults[parent_index].transactions[index].debit = e.target.value ? Number(e.target.value) : ''
                                            filterResults[parent_index].transactions[index].adjusted_amount = e.target.value ? Number(e.target.value) : ''

                                            setParentAccounts([...filterResults])
                                        }
                                    }}
                                    InputProps={{
                                        inputProps: { min: 0 },
                                    }}
                                    onKeyPress={(event) => {
                                        if (event?.key === '-' || event?.key === '+') {
                                            event.preventDefault();
                                        }
                                    }}
                                />
                                {/* {item.available_balance ? item.transaction_type === 'DEBIT' ?
                                    <span style={{ visibility: item.debitVisibility }}>
                                        <CurrencyFormatter amount={-(item.available_balance - Number(filterResults[parent_index].transactions[index].debit))} currency={item.currency_code} />
                                    </span>
                                    :
                                    <span style={{ visibility: 'hidden' }}>
                                        <CurrencyFormatter amount={item.available_balance} currency={item.currency_code} />
                                    </span>
                                    : null
                                } */}
                            </CustomTableBodyCell>
                            <CustomTableBodyCell align='right'>
                                <Input
                                    id={parent_index + '_input_credit_' + index}
                                    dataTestId={parent_index + '_input_credit_' + index}
                                    item={{
                                        type: 'number',
                                        required: true,
                                        fullWidth: true,
                                        disabled: false,
                                        placeholder: '',
                                        variant: "standard",
                                        value: filterResults[parent_index].transactions[index].credit,
                                        onChange: (e) => {
                                            filterResults[parent_index].transactions[index].debit = ''
                                            filterResults[parent_index].transactions[index].adjusted_transaction_type = "CREDIT"
                                            filterResults[parent_index].transactions[index].credit = e.target.value ? Number(e.target.value) : '';
                                            filterResults[parent_index].transactions[index].adjusted_amount = e.target.value ? Number(e.target.value) : '';

                                            setParentAccounts([...filterResults])
                                        }
                                    }}
                                    InputProps={{
                                        inputProps: { min: 0 },
                                    }}
                                    onKeyPress={(event) => {
                                        if (event?.key === '-' || event?.key === '+') {
                                            event.preventDefault();
                                        }
                                    }}
                                />
                                {/* {item.available_balance ? item.transaction_type === 'CREDIT' ?
                                    <span style={{ visibility: item.creditVisibility }}>
                                        <CurrencyFormatter amount={-(item.available_balance - Number(filterResults[parent_index].transactions[index].credit))} currency={item.currency_code} />
                                    </span>
                                    :
                                    <span style={{ visibility: 'hidden' }}>
                                        <CurrencyFormatter amount={item.available_balance} currency={item.currency_code} />
                                    </span>
                                    : null
                                } */}
                            </CustomTableBodyCell>
                        </CustomTableRow>
                )
            })}
        </CustomTableBody>
    )
}

const Calculation = (props) => {
    const { title, title2, color, borderBottom, debit, credit } = props
    return (
        <>
            <CustomTableBodyCell style={{ borderBottom: borderBottom, width: 300 }} align='right'>
                <CustomTypography
                    text={title}
                    sx={{
                        mr: 3,
                        color: color,
                        fontSize: '14px',
                        fontWeight: '700',
                        lineHeight: '18px',
                        fontStyle: 'normal',
                        fontFamily: "Noto Sans",
                    }}
                />
                {
                    title2 ?
                        <CustomTypography
                            text={title2}
                            sx={{
                                mr: 3,
                                color: '#000000',
                                fontSize: '10px',
                                fontWeight: '500',
                                lineHeight: '18px',
                                fontStyle: 'normal',
                                fontFamily: "Noto Sans",
                            }}
                        />
                        : null
                }
            </CustomTableBodyCell>
            <CustomTableBodyCell style={{ borderBottom: borderBottom, width: 200 }} align='right'>
                <CustomTypography
                    id={'total_debit'}
                    dataTestId={'total_debit'}
                    text={debit}
                    sx={{
                        mr: 3,
                        color: color,
                        fontSize: '14px',
                        fontWeight: '700',
                        lineHeight: '18px',
                        fontStyle: 'normal',
                        fontFamily: "Noto Sans",
                    }}
                />
            </CustomTableBodyCell>
            <CustomTableBodyCell style={{ borderBottom: borderBottom, width: 200 }} align='right'>
                <CustomTypography
                    id={'total_credit'}
                    dataTestId={'total_credit'}
                    text={credit}
                    sx={{
                        mr: 3,
                        color: color,
                        fontSize: '14px',
                        fontWeight: '700',
                        lineHeight: '18px',
                        fontStyle: 'normal',
                        fontFamily: "Noto Sans",
                    }}
                />
            </CustomTableBodyCell>
        </>
    )
}