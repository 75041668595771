import React from 'react';
import { Box } from '@mui/material';
import { sumOfArrayByKey } from '../../../../utils/Utils';
import { getBusinessInfo } from '../../../../config/cookiesInfo';
import CurrencyFormatter from '../../../common/CurrencyFormatter';
import { NoDataComponent } from '../../../common/NoDataComponent';
import { opening_balance_adjustments } from '../../../../utils/Constant';
import CustomTypography from '../../../custom/typography/CustomTypography';
import {
  CustomTable,
  CustomTableRow,
  CustomTableBody,
  CustomTableHead,
  CustomTableHeadCell,
  CustomTableBodyCell,
  CustomTableContainer,
} from '../../../custom/table/CustomTable';


const CreateOpeningBalanceAdjustment = (props) => {
  const { results, migrationData, setMigrationData } = props;

  const [transactions, setTransaction] = React.useState([]);

  React.useEffect(() => {
    const transactions = [...new Set(results.map((account) => account.transactions).flat(1))]
    const opening_balance_adjustments_transaction_index = transactions.findIndex((transaction) => transaction.account_name === opening_balance_adjustments);
    let total_debit = getTotal(transactions, "DEBIT")
    let total_credit = getTotal(transactions, "CREDIT")
    if (total_credit === total_debit) {
      if (opening_balance_adjustments_transaction_index > -1) {
        transactions.splice(opening_balance_adjustments_transaction_index, 1)
      }
    } else if (total_credit > total_debit) {
      transactions[opening_balance_adjustments_transaction_index].adjusted_transaction_type = "DEBIT"
      transactions[opening_balance_adjustments_transaction_index].debit = total_credit - total_debit
      transactions[opening_balance_adjustments_transaction_index].adjusted_amount = total_credit - total_debit

    } else {
      transactions[opening_balance_adjustments_transaction_index].adjusted_transaction_type = "CREDIT"
      transactions[opening_balance_adjustments_transaction_index].credit = total_debit - total_credit
      transactions[opening_balance_adjustments_transaction_index].adjusted_amount = total_debit - total_credit
    }
    setTransaction(transactions)
    setMigrationData({ ...migrationData, transaction_amount: sumOfArrayByKey(transactions, 'credit'), transactions: createTransactionBody(transactions) })
  }, [])

  const getTotal = (transactions, type) => {
    return transactions.reduce((acc, transaction) => {
      if (transaction.account_name !== opening_balance_adjustments) {
        if (transaction.adjusted_transaction_type === type) {
          acc += transaction.adjusted_amount
        }
      }
      return acc
    }, 0)
  }

  const createTransactionBody = (data) => {
    let transactions = []
    // eslint-disable-next-line
    data.map((item) => {
      if (item.account_name) {
        let tem = {
          business_account_id: item.id,
          currency_id: getBusinessInfo().currency.id,
          transaction_type: item.debit ? 'DEBIT' : 'CREDIT',
          amount: Number(item.credit) ? Number(item.credit) : Number(item.debit),
        }
        transactions.push(tem)
      }
    })
    return transactions
  }

  return (
    <div style={{ paddingLeft: '24px', paddingRight: '24px', paddingBottom: '20px' }}>
      <CustomTableContainer>
        <CustomTable>
          <CreateOpeningBalanceAdjustmentHeader />
          <CreateOpeningBalanceAdjustmentBody data={transactions} />
        </CustomTable>
        <Box sx={{ pt: !transactions.length ? 10 : 0 }} >
          <NoDataComponent left='0%' top='0%' position={'relative'} data={transactions} />
        </Box>
      </CustomTableContainer>
    </div>
  )
}

const CreateOpeningBalanceAdjustmentHeader = () => {
  return (
    <CustomTableHead>
      <CustomTableRow>
        <CustomTableHeadCell style={{ width: 200 }}><span>Accounts</span></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 200 }} align='right'><span>Debit</span></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 200 }} align='right'><span>Credit</span></CustomTableHeadCell>
      </CustomTableRow>
    </CustomTableHead>
  )
}

const CreateOpeningBalanceAdjustmentBody = (props) => {
  const { data } = props
  return (
    <CustomTableBody>
      {
        data.map((item, index) => {
          return (
            <CustomTableRow key={index}>

              <CustomTableBodyCell ><span>{item.account_name}</span></CustomTableBodyCell>
              <CustomTableBodyCell align='right'>
                {
                  item.debit ?
                    <CurrencyFormatter amount={item.debit} currency={item.currency_code} />
                    : '-'
                }
              </CustomTableBodyCell>
              <CustomTableBodyCell align='right'>
                {
                  item.credit ?
                    <CurrencyFormatter amount={item.credit} currency={item.currency_code} />
                    : '-'
                }</CustomTableBodyCell>
            </CustomTableRow>
          )
        })
      }
      {
        data.length ?
          <CustomTableRow sx={{ background: '#FFF7DC', borderTop: '1px solid #FFF0C0', borderBottom: '1px solid #FFF0C0' }}>
            <CustomTableBodyCell align='right'>
              <CustomTypography
                text={'Total'}
                sx={{
                  color: '#000000',
                  fontSize: '14px',
                  fontWeight: '700',
                  lineHeight: '18px',
                  fontStyle: 'normal',
                  textTransform: 'none',
                  fontFamily: "Noto Sans",
                }}
              />
            </CustomTableBodyCell>
            <CustomTableBodyCell align='right'>
              <CurrencyFormatter amount={sumOfArrayByKey(data, 'debit')} />
            </CustomTableBodyCell>
            <CustomTableBodyCell align='right'>
              <CurrencyFormatter amount={sumOfArrayByKey(data, 'credit')} />
            </CustomTableBodyCell>
          </CustomTableRow>
          : null
      }

    </CustomTableBody>
  )
}

export default CreateOpeningBalanceAdjustment;