import React from 'react';
import { apiAction } from '../../../../api/api';
import * as Actions from '../../../../state/Actions';
import CustomInput from '../../../custom/input/CustomInput';
import { stateChangeManager } from '../../../../utils/Utils';
import CustomDialog from '../../../custom/dialog/CustomDialog';
import { getBusinessInfo } from '../../../../config/cookiesInfo';
import { LabelWithAsteriskMark } from '../../../common/CommonLabel';
import CustomDropdown from '../../../custom/dropdown/CustomDropdown';
import CustomTypography from '../../../custom/typography/CustomTypography';
import { create_payment_term, list_payment_term } from '../../../../api/urls';

import {
    Box,
    Grid,
    Button,
    Container,
} from '@mui/material';

export const AddTerms = (props) => {
    const { onClose, onAddTerms } = props
    const setState = Actions.getDispatch(React.useContext);

    const validation = (data, setState, Actions) => {
        var isValid = true
        if (data.get('payment_term_terms_name') === '') {
            stateChangeManager(setState, Actions, true, 'error', 'Name field can not be empty')
            isValid = false
        } else if (data.get('payment_term_number_of_days') === '') {
            stateChangeManager(setState, Actions, true, 'error', 'Number of days field can not be empty')
            isValid = false
        }
        return isValid
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        // collect all the input into data based on input id
        const data = new FormData(event.currentTarget);

        // validate the data
        if (validation(data, setState, Actions)) {
            onClose()
            //Api call
            let res = await apiAction({
                method: 'post',
                url: create_payment_term(),
                data: {
                    business_id: getBusinessInfo().id,
                    name: data.get("payment_term_terms_name"),
                    number_of_days: data.get("payment_term_number_of_days")
                }
            })
            if (res?.success) {
                onAddTerms(res?.result)
                // checking the response and according to save the data in to 'js-cookie' for the future use
                stateChangeManager(setState, Actions, true, 'success', res?.status);

            } else {
                // stateChangeManager is a alert function which show the error message
                stateChangeManager(setState, Actions, true, 'error', res?.status)
            }
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box component="form"
                onSubmit={handleSubmit}
                noValidate sx={{ mt: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <CustomTypography
                            text={<LabelWithAsteriskMark label={'Name'} />}
                            sx={{
                                mb: 1,
                                fontSize: '14px',
                                color: '#000000',
                                fontWeight: '700',
                                lineHeight: '18px',
                                fontStyle: 'normal',
                                textTransform: "none",
                                fontFamily: "Noto Sans",
                                textTransform: 'capitalize',
                            }}
                        />
                        <CustomInput
                            required
                            fullWidth
                            id="payment_term_terms_name"
                            name="payment_term_terms_name"
                            dataTestId="payment_term_terms_name"
                            autoFocus={true}
                            autoComplete="name"
                            placeholder={'Enter Term Name'}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 1 }}>
                        <CustomTypography
                            text={<LabelWithAsteriskMark label={'Number of Days'} />}
                            sx={{
                                mb: 1,
                                fontSize: '14px',
                                color: '#000000',
                                fontWeight: '700',
                                lineHeight: '18px',
                                fontStyle: 'normal',
                                textTransform: "none",
                                fontFamily: "Noto Sans",
                                textTransform: 'capitalize',
                            }}
                        />
                        <CustomInput
                            required
                            fullWidth
                            type="number"
                            id="payment_term_number_of_days"
                            name="payment_term_number_of_days"
                            autoComplete="number_of_days"
                            placeholder={'Enter Number of days'}
                        />
                    </Grid>
                </Grid>
                <Button
                    id="payment_term_save_btn"
                    name="payment_term_save_btn"
                    fullWidth
                    type="submit"
                    variant="contained"
                    sx={{ mt: 3, mb: 2, textTransform: 'capitalize' }}>Save</Button>
            </Box>
        </Container>
    )
}

export const ListTerms = (props) => {
    const { setTerms, defaultTerm, validation, title, onFetchTerms, isClear, onDueDateChange, disableClearable = false, remove_value_key } = props

    const [value, setValue] = React.useState()
    const [results, setResult] = React.useState([])
    const [state, setState] = React.useState({
        id: '',
        title: '',
        open: false,
        condition: '',
        maxWidth: 'lg',
        fullWidth: true,
    })

    const addHandler = () => {
        setState({
            open: true,
            title: 'Add New Terms',
            fullWidth: true,
            maxWidth: 'sm',
            condition: 'add_terms',
        })
    }

    React.useEffect(() => {
        
        if (defaultTerm) {
            setValue(defaultTerm)
        }
    }, [defaultTerm, results])

    React.useEffect(() => {
        if (isClear) {
            setValue(results.find((value) => value.name === '30 Days'))
        }
    }, [isClear])

    React.useEffect(() => {
        const apiResults = async () => {
            let res = await apiAction({
                method: 'post',
                url: list_payment_term(),
                data: { business_id: getBusinessInfo().id, sort_by: 'name', sort_order: 'A' },
            })
            // console.log('res',res)
            if (res?.success) {
                if (onDueDateChange) {
                    onDueDateChange(res?.result.find((value) => value.name === 'Custom'));
                }
                setResult(res?.result.filter((item) => item.name !== remove_value_key))
                if (onFetchTerms !== undefined && !defaultTerm)
                    onFetchTerms(res?.result.find((value) => value.name === '30 Days'))
                if (!defaultTerm) {
                    // console.log('Due on Receipt',res?.result.find((value)=>value.name==='Due on Receipt').id)
                    let default_value = res?.result.find((value) => value.name === '30 Days')
                    setTerms('payment_term_id', default_value ? default_value.id : null, default_value ? default_value : null)
                }

            } else {

            }
        }
        apiResults()
        // eslint-disable-next-line
    }, [state.open])

    const onChangeHandler = (newInputValue) => {
        if (newInputValue) {
            setValue(newInputValue)
            setTerms('payment_term_id', newInputValue.id, newInputValue)
        } else {
            setValue(null)
            setTerms('payment_term_id', null, null)
        }
    }

    return (
        <React.Fragment>
            <CustomDialog
                state={state}
                setState={setState}
                onAddTerms={(newInputValue) => setTerms('payment_term_id', newInputValue.id, newInputValue)}
            />
            <CustomTypography
                text={title ? title : <LabelWithAsteriskMark label={'Terms'} />}
                sx={{
                    mb: 1,
                    color: '#000000',
                    fontSize: '14px',
                    fontWeight: '700',
                    lineHeight: '18px',
                    fontStyle: 'normal',
                    textTransform: "none",
                    fontFamily: "Noto Sans",
                    textTransform: 'capitalize',
                }}
            />
            <CustomDropdown
                id={'payment_term_dropdown'}
                dataTestId={'payment_term_dropdown'}
                results={results}
                placeholder='Select the Term'
                value={value ? value : null}
                disableClearable={disableClearable}
                validation={validation && !(value ? value : null)}
                onChange={(event, newInputValue) => onChangeHandler(newInputValue)}
                addButton={{
                    title: '+ Add New Terms',
                    onClick: () => addHandler(),
                }}
            />
        </React.Fragment>

    )
}


