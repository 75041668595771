import * as React from 'react';
import { apiAction } from '../../../api/api';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../button/CustomButton';
import * as Actions from '../../../state/Actions';
import { getUniqueObjectsByKey } from '../../../utils/Utils';
import { getBusinessInfo } from '../../../config/cookiesInfo';
import { get_chart_of_account_nested_nodes } from '../../../api/urls';
import CustomTypography from '../../custom/typography/CustomTypography';
import { useIsElementVisible } from '../../pages/home/insight/common/Helper';

import {
    Chip,
    Stack,
    styled,
    Divider,
    TextField,
    IconButton,
    Autocomplete,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import ClearIcon from "@mui/icons-material/Clear";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


export default function AsyncDropdownWithMultipleValue(props) {
    const {
        sx,
        title,
        autoFocus,
        addButton,
        fullWidth,
        validation,
        newResults,
        InputProps,
        selectedValue,
        maxHeight = 300,
        forcePopupIcon,
        setSelectedValue,
        isShowEndAdornment,
        fetchTermLimit = 1,
        inputDisabled = false,
        isGroupHeaderSticky = true,
        disabledCloseIcon = false,
        id = 'dropdown_with_multiple_value',
        dataTestId = 'dropdown_with_multiple_value',

        size = 'small',
        getOptionDisabled,
        searchKey = 'name',
        uniqueOptionKey = 'id',
        valueLabelKey = 'name',
        optionLabelKey = 'name',
        titleVisibility = "visible",
        optionGroupLabelKey = 'name',
        placeholder = 'Select Account',

        playLoad,
        METHOD = 'post',
        nextUrlAdditionData,
        URL = get_chart_of_account_nested_nodes(1),

    } = props;
    const navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);

    const [results, setResults] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [loadMore, setLoadMore] = React.useState(false);

    const [nextUrl, setNextUrl] = React.useState();
    const [hasMore, setHasMore] = React.useState(false);

    const fetchOptions = async (url = URL, apiBody, previousResult = results) => {
        setLoading(true);
        let res = await apiAction({
            url: url,
            method: METHOD,
            navigate: navigate,
            dispatch: dispatch,
            data: { ...apiBody, ...playLoad, business_id: getBusinessInfo().id },

        })
        if (res?.success) {
            setLoading(false);
            setLoadMore(false);
            if (METHOD === 'get' && res?.result.next) {
                setNextUrl(res?.result.next + nextUrlAdditionData);
            } else {
                setNextUrl(res?.result.next);
            }
            setHasMore(!!res?.result.next);
            const uniqueOptions = [
                ...previousResult,
                ...res?.result.result.filter((item) =>
                    !previousResult.some((option) => option[uniqueOptionKey] === item[uniqueOptionKey])
                )
            ];
            setResults([...getUniqueObjectsByKey([...uniqueOptions])])
        } else {
            setLoading(false);
            console.log('===>Failed API Call !!')
        }
    }

    const onChangeInputHandler = (text = '') => {
        if (text.length % fetchTermLimit === 0) {
            let apiBody = text && text !== '' ? { [searchKey]: text } : {}
            if (METHOD === 'get') {
                fetchOptions(URL + `&${searchKey}=${text}`, apiBody, []);
            } else {
                fetchOptions(URL, apiBody, []);
            }
        }
    }

    React.useEffect(() => {
        if (newResults) {
            const uniqueOptions = [
                ...results,
                ...[newResults].filter((item) =>
                    !results.some((option) => option[uniqueOptionKey] === item[uniqueOptionKey])
                )
            ];
            setResults([...getUniqueObjectsByKey([...uniqueOptions])])
        }
    }, [newResults]);

    const lastNodeRef = React.useRef();
    const isElementVisible = useIsElementVisible(lastNodeRef.current);

    React.useEffect(() => {
        if (isElementVisible && loadMore && hasMore) {
            fetchOptions(nextUrl);
        }
    }, [loadMore, isElementVisible])

    const getOptionEqualToValue = (option, value) => {
        return option[valueLabelKey] === value[valueLabelKey] || option[optionLabelKey] === value[optionLabelKey] || option[optionLabelKey] === value[valueLabelKey]
    }
    return (
        <Stack spacing={3} sx={{ width: '100%' }}>
            <Autocomplete

                id={id}
                size={size}
                multiple={true}
                loading={loading}
                options={results}
                autoHighlight={true}
                fullWidth={fullWidth}
                dataTestId={dataTestId}
                disabled={inputDisabled}
                placeholder={placeholder}

                popupIcon={<ExpandMoreIcon />}
                forcePopupIcon={forcePopupIcon}
                disableClearable={disabledCloseIcon}
                clearIcon={<ClearIcon fontSize='small' />}
                noOptionsText={<NoOptionsComponentCustom {...props} />}

                value={selectedValue ? selectedValue : null}
                groupBy={(option) => option[optionGroupLabelKey]}
                getOptionValue={(option) => option[valueLabelKey]}
                getOptionLabel={(option) => option[optionLabelKey]}
                isOptionEqualToValue={(option, value) => getOptionEqualToValue(option, value)}
                getOptionDisabled={(option) => (getOptionDisabled ? getOptionDisabled(option) : null)}

                sx={{ ...sx, background: inputDisabled ? '#F1F3F4' : '' }}

                onChange={(event, selected) => { setSelectedValue(selected); }}
                onOpen={() => { if (results?.length < 10) { fetchOptions(URL, {}, [])} }}

                renderGroup={(params) => {
                    return (
                        <React.Fragment key={params.key}>
                            {params.key === 0 && addButton ? (
                                <CustomButton
                                    fullWidth
                                    variant='text'
                                    id={id + '_add_new_btn'}
                                    btnLabel={<b>+ Add new</b>}
                                    onClick={addButton.onClick}
                                    dataTestId={dataTestId + '_add_new_btn'}
                                    sx={{
                                        top: '-8px',
                                        position: 'sticky',
                                        marginTop: '-8px',
                                        marginBottom: '6px',
                                        background: '#FFFFFF',
                                        borderBottomLeftRadius: '0px',
                                        borderBottomRightRadius: '0px',
                                        '&:hover': { backgroundColor: '#e8f3ff' },
                                        boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset',
                                    }}
                                    onMouseDown={(event) => { event.preventDefault(); }}
                                />
                            ) : null
                            }
                            <li key={params.key} style={{
                                fontWeight: 400,
                                fontSize: '12px',
                                marginTop: '0px',
                                color: '#141414',
                                lineHeight: '16px',
                                fontStyle: 'normal',
                                background: '#FFFFFF',
                                fontFamily: 'Noto Sans',
                            }}>
                                <GroupHeader sx={{ position: isGroupHeaderSticky ? 'sticky' : '', }}>{params.group}</GroupHeader>
                                <GroupItems >{params.children}</GroupItems>
                            </li>
                        </React.Fragment>
                    )
                }}

                ListboxProps={{
                    style: { overflowY: 'auto', overflowX: 'hidden', maxHeight: maxHeight },
                    onScroll: (event) => {
                        const listboxNode = event.currentTarget;
                        const options = listboxNode.querySelectorAll('[role="option"]');
                        lastNodeRef.current = options[options.length - 3];
                        const lastOptionText = options[options.length - 3]?.textContent;

                        let lastItem = results.find((item) => item[optionLabelKey] === lastOptionText)
                        if (!loadMore && lastItem && hasMore) {
                            setLoadMore(true);
                        }
                    },
                }}

                renderInput={params => {
                    const { InputProps, ...restParams } = params;
                    const { startAdornment, ...restInputProps } = InputProps;
                    return (
                        <TextField
                            {...restParams}
                            size='small'
                            validation={validation}
                            placeholder={'Select Data Points'}
                            onChange={(event) => { onChangeInputHandler(event.target.value) }}
                            sx={{ "& input::placeholder": { color: '#2F2F2E', fontWeight: '400', lineHeight: '16px', fontSize: '12px', fontStyle: 'normal', fontFamily: 'Noto Sans', opacity: 0.50, } }}
                            InputProps={{
                                ...restInputProps,
                                style: { color: '#000000', fontWeight: '700', lineHeight: '16px', fontSize: '12px', fontStyle: 'normal', fontFamily: 'Noto Sans', },
                                startAdornment: (
                                    <div style={{
                                        cursor: 'auto',
                                        overflowY: 'auto',
                                        maxHeight: maxHeight,
                                    }}
                                        className='display_scroll_bar'
                                    >
                                        {startAdornment}
                                    </div>
                                ),
                            }}
                        />
                    );
                }}

                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip
                            size="small"
                            variant="outlined"
                            sx={{
                                borderRadius: 1,
                                cursor: 'pointer',
                                bgcolor: '#f5f5f5',
                                ":hover": { bgcolor: '#fafafa' }
                            }}
                            deleteIcon={<IconButton size='small' sx={{ "&:hover": { color: '#ef5350' } }}><CloseIcon sx={{}} fontSize='inherit' /></IconButton>}
                            label={<span style={{ fontSize: '12px' }}>{option[optionLabelKey]}</span>}
                            {...getTagProps({ index })}
                        />
                    ))
                }

            />
        </Stack>
    );
}

const NoOptionsComponentCustom = (props) => {
    const { id, dataTestId, noOptionsText = 'No results found', addButton } = props;
    return (
        <React.Fragment>
            <CustomTypography
                text={noOptionsText}
                sx={{
                    mb: 2,
                    color: '#141414',
                    fontWeight: 500,
                    fontSize: '12px',
                    lineHeight: '16px',
                    fontStyle: 'normal',
                    fontFamily: 'Noto Sans',
                }}
            />
            {addButton ? (
                <div style={{
                    margin: '-16px',
                    marginTop: '0px',
                }}>
                    <Divider />
                    <CustomButton
                        fullWidth
                        variant='text'
                        id={id + '_add_new_btn'}
                        btnLabel={<b>+ Add new</b>}
                        onClick={addButton.onClick}
                        dataTestId={dataTestId + '_add_new_btn'}
                        onMouseDown={(event) => { event.preventDefault(); }}
                        sx={{
                            '&:hover': { backgroundColor: '#e8f3ff' },
                        }}
                    />
                </div>
            ) : null
            }
        </React.Fragment>
    )
}

const GroupHeader = styled('div')(({ theme }) => ({
    top: '-8px',
    fontWeight: 700,
    color: '#141414',
    fontSize: '12px',
    lineHeight: '16px',
    fontStyle: 'normal',
    padding: '4px 10px',
    background: '#FFFFFF',
    fontFamily: 'Noto Sans',
}));

const GroupItems = styled('ul')({
    padding: 0,
    paddingTop: 1,
    fontWeight: 400,
    fontSize: '12px',
    color: '#141414',
    paddingBottom: 1,
    lineHeight: '16px',
    fontStyle: 'normal',
    background: '#FFFFFF',
    fontFamily: 'Noto Sans',
});