import React from 'react';
import { Box } from '@mui/material';
import { HOST } from '../../config/config';

const Logo = (props) => {
    const { image,height=80,width=80 } = props;
    return (
        <Box
            alt="Logo"
            component="img"
            src={HOST + image}
            sx={{ height: height,width:width }}
        />
    )
}

export default Logo