import React from 'react';
import PropTypes from 'prop-types';
import { routesName } from '../../../config/routesName';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Box,
    Fab,
    Fade,
    Container,
    useScrollTrigger,
} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import Banner from './pages/Banner';
import Analysis from './pages/Analysis';
import WhyChoose from './pages/WhyChoose';
import Features from './pages/Features';
import FAQ from './pages/FAQ';
import Platform from './pages/Platform';

const sections = [
    { id: 'banner_section', component: <Banner /> },
    { id: 'analysis_section', component: <Analysis />, background: '#FAFAFA' },
    { id: 'choose_section', component: <WhyChoose /> },
    { id: 'feature_section', component: <Features />, background: '#FAFAFA' },
    { id: 'faq_section', component: <FAQ /> },
    { id: 'platform_section', component: <Platform />, background: '#2464EB' },
];

const LandingPage = (props) => {
    const navigate = useNavigate();
    const { state } = useLocation();

    const scrollToSection = (id) => {
        const anchor = document.querySelector(id);
        if (anchor) {
            anchor.scrollIntoView({ block: 'start' });
        }
    };

    const scrollToTab = (tab) => {
        const anchor = document.querySelector('#feature_section');
        if (anchor) {
            document.querySelector(tab)?.click();
            anchor.scrollIntoView({ block: 'start' });
        }
    };

    React.useEffect(() => {
        if (state?.tab) {
            scrollToTab(state.tab);
        } else if (state?.id) {
            scrollToSection(state.id);
        } else {
            scrollToSection('#back-to-top-anchor');
            navigate('/landing' + routesName.landingPage.path, { state: { id: '#banner_section' } });
        }
    }, [state]);

    return (
        <div>
            <React.Fragment>
                {sections.map(({ id, component, background = '#FFFFFF' }) => (
                    <Box key={id} sx={{ pl: 2, pr: 2, background }}>
                        <Container maxWidth={false} disableGutters sx={{ pl: { xs: 0, sm: 14 }, pr: { xs: 0, sm: 14 } }}>
                            <div style={{ padding: '35px 0' }} id={id}>
                                {component}
                            </div>
                        </Container>
                    </Box>
                ))}
            </React.Fragment>

            <ScrollTop {...props}>
                <Fab size="small" aria-label="scroll back to top">
                    <KeyboardArrowUpIcon />
                </Fab>
            </ScrollTop>
        </div>
    );
};

export default LandingPage;

const ScrollTop = (props) => {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        threshold: 100,
        disableHysteresis: true,
        target: window ? window() : undefined,
    });

    const navigate = useNavigate();

    const handleClick = (event) => {
        const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');
        if (anchor) {
            anchor.scrollIntoView({ block: 'center', behavior: 'smooth' });
            navigate('/landing' + routesName.landingPage.path, { state: { id: '#banner_section' } });
        }
    };

    return (
        <Fade in={trigger} style={{ zIndex: 100001 }}>
            <Box
                onClick={handleClick}
                role="presentation"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
            >
                {children}
            </Box>
        </Fade>
    );
};

ScrollTop.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};
