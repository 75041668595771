import "../src/App.css"
import React from 'react'
import theme from './state/theme/Theme';
import { StateProvider } from './state/Store';
import { ThemeProvider } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import AuthLayout from './layouts/auth/AuthLayout';
import MainLayout from "./layouts/main/MainLayout";
import { routesName } from "./config/routesName.js";
import LandingLayout from "./layouts/landing/LandingLayout";
import CustomAlert from './components/custom/alert/CustomAlert';
import CustomLoader from './components/custom/loader/CustomLoader';
import {
  PrivacyPolicy,
  TermsAndServices,
} from "./components/pages/userManagement/FinycsPolicy.js";

import {
  Route,
  Routes,
  Navigate,
  BrowserRouter,
} from "react-router-dom";

import "../src/firebase/firebaseConfig.js"

const App = () => {

  // React.useEffect(() => {
  //   window.addEventListener('keypress', e => {
  //     var target = e.target ? e.target : e.srcElement;
  //     console.log('===>Node',target && target.nodeName.toLowerCase () === "body")
  //     console.log(e.key, "  ",e.ctrlKey , "  ",e.altKey, "  ", e.metaKey, " ",e.code )
  //   });
  // }, []);

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <StateProvider>
          <CustomLoader />
          <CustomAlert />
          <ToastContainer />
          <Routes>
            <Route path={"/auth/*"} element={<AuthLayout />} />
            <Route path={"/*"} element={<MainLayout />} />

            <Route path={"/landing/*"} element={<LandingLayout />} />
            <Route path={"/"} element={<Navigate replace to="/landing" />} />
            <Route path={"/app"} element={<Navigate replace to="/landing" />} />

            {/* need to hide */}
            {/* <Route path="/" element={<Navigate replace to="/auth/login" />} /> */}

            <Route path="/app/web/" element={<Navigate replace to={routesName.privacyPolicy.path} />} />
            <Route path={routesName.privacyPolicy.path} element={<PrivacyPolicy />} />
            <Route path={routesName.termsAndServices.path} element={<TermsAndServices />} />


          </Routes>
        </StateProvider>
      </BrowserRouter>
    </ThemeProvider>
  )
}



export default App