import React from 'react';
import moment from 'moment';
import { apiAction } from "../../../../api/api";
import ReportsTitle from '../common/ReportsTitle';
import DatePicker from '../../../common/DatePicker';
import * as Actions from '../../../../state/Actions';
import { getDateFormat } from '../../../../utils/Utils';
import ReportsSettingButtons from '../ReportsSettingButtons';
import { getBusinessInfo } from '../../../../config/cookiesInfo';
import { NoDataComponent } from '../../../common/NoDataComponent';
import { CustomerViewLink } from '../../invoicing/common/CommonLinks';
import { CustomContainer } from '../../../custom/container/CustomContainer';

import {
    time_to_get_paid_report,
} from '../../../../api/urls';

import {
    setLoader,
} from '../../../../utils/Utils';

import {
    Box,
    Grid,
} from '@mui/material';

import {
    CustomTable,
    CustomTableRow,
    CustomTableBody,
    CustomTableHead,
    CustomTableHeadCell,
    CustomTableBodyCell,
    CustomTableContainer,
} from '../../../custom/table/CustomTable';
import CustomPagination from '../../../custom/pagination/CustomPagination';

const TimeToGetPaidReports = () => {
    const dispatch = Actions.getDispatch(React.useContext);

    const [results, setResults] = React.useState([]);
    const [date, setDate] = React.useState(moment());
    const [page, setPage] = React.useState(1);
    const [pagination, setPagination] = React.useState({
        next: undefined,
        count: undefined,
        previous: undefined,
        number_of_pages: undefined,
    })

    React.useEffect(() => {
        if (date) {
            getApiResults(date)
        }
        // eslint-disable-next-line
    }, [date, page]);

    const getApiResults = async (date) => {
        setLoader(dispatch, Actions, true)
        let res = await apiAction({
            method: 'post',
            url: time_to_get_paid_report(page),
            data: {
                business_id: getBusinessInfo().id,
                to_date: moment(date).format('YYYY-MM-DD'),
            },
        })

        if (res?.success) {
            let result = res?.result.result.paginated_result;
            setPagination({
                ...pagination,
                next: result.next,
                count: result.count,
                previous: result.previous,
                number_of_pages: result.number_of_pages,
            })
            setResults(result.result);
            setLoader(dispatch, Actions, false);
        } else {
            setLoader(dispatch, Actions, false);
        }
    }
    return (
        <div>
            <ReportsSettingButtons

                scheduleData={{
                    report_name: 'time_to_get_paid',
                    report_title: 'Time to Get Paid',

                }}
                exportData={{ url: time_to_get_paid_report(), data: { to_date: moment(date).format('YYYY-MM-DD') } }}
            />
            <CustomContainer maxWidth={"400"} sx={{}}>
                <div style={{ paddingLeft: '16px', paddingRight: '16px', }}>
                    <Grid container spacing={0} sx={{ pb: 2 }} style={{
                        alignItems: 'center',
                        justifyItems: 'center',
                        alignContent: 'space-evenly',
                        justifyContent: 'space-evenly',
                    }}>
                        <Grid item xs={12} sm={4}>
                            <ReportsTitle
                                dateText={'As on '}
                                title={'Time to Get Paid'}
                                date={moment(date).format(getDateFormat())}
                            />
                        </Grid>
                        <Grid container spacing={2} item xs={12} sm={8} sx={{ justifyContent: 'end', }}>
                            <Grid item xs={12} sm={4}>
                                <DatePicker
                                    id={'as_on_date_select'}
                                    dataTestId={'as_on_date_select'}

                                    date={date}
                                    setDate={setDate}
                                    title='As on Date'
                                    disabledCloseIcon={true}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <CustomTableContainer>
                    <CustomTable>
                        <Header />
                        {results.length ?
                            <Body data={results} />
                            : null
                        }
                    </CustomTable>
                    <Box sx={{ p: !results.length ? 2 : 0 }} >
                        <NoDataComponent left='0%' top='0%' position={'relative'} data={results} />
                    </Box>
                </CustomTableContainer>
            </CustomContainer>
            {
                results.length ?
                    <CustomPagination
                        page={page}
                        count={pagination.number_of_pages}
                        onChange={(event, newValue) => setPage(newValue)}
                    />
                    : null
            }
        </div>
    )
}

export default TimeToGetPaidReports;

const Header = () => {
    return (
        <CustomTableHead>
            <CustomTableRow >
                <CustomTableHeadCell><span>Customer Name</span></CustomTableHeadCell>
                <CustomTableHeadCell align='right'><span>0-15 Days</span></CustomTableHeadCell>
                <CustomTableHeadCell align='right'><span>16-30 Days</span></CustomTableHeadCell>
                <CustomTableHeadCell align='right'><span>31-45 Days</span></CustomTableHeadCell>
                <CustomTableHeadCell align='right'><span>{'Above 45 Days'}</span></CustomTableHeadCell>
            </CustomTableRow>
        </CustomTableHead>
    )
}

const Body = (props) => {
    const { data } = props;

    return (
        <CustomTableBody>
            {data.map((item, index) => {
                return (
                    <CustomTableRow key={index}>
                        <CustomTableBodyCell>
                            {item.customer_id ?
                                <CustomerViewLink title={item.customer_name} id={item.customer_id} /> :
                                item.customer_name}
                        </CustomTableBodyCell>
                        <CustomTableBodyCell align='right'>{item.days_0_to_15}%</CustomTableBodyCell>
                        <CustomTableBodyCell align='right'>{item.days_16_to_30}%</CustomTableBodyCell>
                        <CustomTableBodyCell align='right'>{item.days_31_to_45}%</CustomTableBodyCell>
                        <CustomTableBodyCell align='right'>{item.above_45}%</CustomTableBodyCell>
                    </CustomTableRow>
                )
            })}
        </CustomTableBody>
    )
}