import moment from "moment";
import * as React from 'react';
import validator from 'validator';
import queryString from 'query-string';
import { toast } from 'react-toastify';
import * as Actions from '../state/Actions';
import momentFquarter from 'moment-fquarter';
import { getBusinessInfo } from "../config/cookiesInfo";
import { getGeneralPreferences } from "../components/pages/setting/settings/Preferences/common/getPreferences";
import { locale_dict_currency, listPaymentMethodToDisplay, listPaymentMethod, GSTRegistrationTypeChoice, currencyData } from "./Constant";

const REGISTERED_VENDOR_TYPES = ["gst_unregistered", "consumer", "overseas"];
const VENDOR_SCREEN_URL = [
  "/purchase/bills/add",
  "/purchase/expenses/add",
  "/purchase/debit-notes/add",
  "/purchase/purchase-orders/add",
  "/purchase/recurring-expenses/add",
];

export const getIsPurchaseScreen=()=>{
  return VENDOR_SCREEN_URL.includes(window.location.pathname)
}

function validateIsVendorRegistered(type) {
  return type? !REGISTERED_VENDOR_TYPES.includes(type):false;
}

let isVendorRegister = false;

const setIsVendorRegister = (value) => {
  isVendorRegister = value ? validateIsVendorRegistered(value) : false;
};
export { isVendorRegister, setIsVendorRegister };


// Get the tax type based on the place of supply and business state ID
export const getTaxType = (placeOfSupplyId, isGstZero) => {

  const businessStateId = getBusinessInfo().state_id;
  return placeOfSupplyId
    ? ((businessStateId === placeOfSupplyId && !isGstZero) ? "GST" : "IGST")
    : (isGstZero ? "IGST" : null);
};

// Filter a list based on the tax type derived from the place of supply ID
export const filterTax = (list = [], placeOfSupplyId, isGstZero) => {
  const taxType = getTaxType(placeOfSupplyId, isGstZero);
  return taxType
    ? list.filter(item => item.tax_type === taxType)
    : list;
};

// Find the default tax from a tax list based on various conditions
export const findDefaultTax = (taxList = [], isGstZero, value) => {

  return taxList.find(item => {
    if (isGstZero) {
      return item.name === "IGST-0";
    }
    if (value?.tax_id && (value?.gst_rate === null || value?.item?.gst_rate === null)) {
      return item.id === value.tax_id;
    }
    if (value?.gst_rate === 0 || value?.item?.gst_rate === 0) {
      return item.rate === 0;
    }
    return item.rate === (value?.gst_rate || value?.item?.gst_rate);
  });
};


export const isBusinessRegistered = () => {
  return getBusinessInfo()?.is_business_registered
}

export const validateDataIsBusinessRegistered = (validation) => {
  let validationData = false;
  if (getBusinessInfo()?.is_business_registered) {
    validationData = validation
  }
  return validationData
}

export const page_count = (page = 1) => {
  return (page * 10) - 10;
}

export const dateFormatter = (date, format = getDateFormat()) => {
  return moment(date).utc().format(format)
}

export const isAPISuccess = (status) => {
  return (status >= 200) && (status <= 299)
}

export const isUnAuthorized = (status) => {
  return status === 401
}

export const isUrlNotFound = (status) => {
  return status === 404
}

export const isInternalServerError = (status) => {
  return ((status >= 500) && (status <= 599))
}

export const isBadRequest = (statusCode) => {
  return statusCode === 400
}

export const isBusinessUnauthorized = (statusMessage) => {
  return statusMessage === "Unauthorized business access"
}

export function filterArray(arr) {
  return Array.isArray(arr) ? arr.filter(item => item !== null && item !== undefined && item !== '' && item !== false) : [];
}

export const isApplicableForTax=(taxType,isUnauthorizedParty)=>{
 return !isUnauthorizedParty && taxType!=='no_tax'
}

export const isFiltersApplied = (filters, check = 2) => {
  return Object.keys(filters).length > check;
}
export function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  return JSON.parse(jsonPayload)
};
export function getDefaultPaymentMethod(payment_method) {
  return listPaymentMethod.find((item) => item.value === payment_method);
}
export function getPaymentType(payment_method) {
  return listPaymentMethodToDisplay.find((item) => item.value === payment_method);
}
export const cloneData = (data) => {
  return JSON.parse(JSON.stringify(data))
}
export function getQueryParams(url) {
  return queryString.parse(url);
}

export function getQueryString(parsed) {
  return queryString.stringify(parsed);
}
export function convertSlugToString(slug, key) {
  return slug.replaceAll(key, " ");
}

export function getDateRanges(data) {
  // Sort the data by 'from_date' in descending order to ensure the latest date range is first
  data.sort((a, b) => new Date(b.from_date) - new Date(a.from_date));

  if (data.length < 2) {
    throw new Error('Insufficient data to determine previous date range');
  }

  // Extract the latest date range
  const latest = data[0];
  const previous = data[1];

  return {
    from_date: latest.from_date,
    to_date: latest.to_date,
    previous_from_date: previous.from_date,
    previous_to_date: previous.to_date
  };
}

export function HighlightedText({ text = '', search = '' }) {
  // console.log('===>text',text)
  // console.log('===>search',search)
  const parts = text.toString().split(new RegExp(`(${search})`, 'gi'));
  return (
    <React.Fragment>
      {parts.map((part, i) =>
        part.toLowerCase() === search.toLowerCase() ? (
          <mark key={i}>{part}</mark>
        ) : (
          <span key={i}>{part}</span>
        )
      )}
    </React.Fragment>
  );
}

export function validateDates(startDate, endDate) {
  // Parse the dates using moment
  var start = moment(startDate, 'YYYY-MM-DD');
  var end = moment(endDate, 'YYYY-MM-DD');

  // Check if start date is after or equal to end date
  if (start.isSameOrAfter(end)) {
    return false; // Start date is after or equal to end date, validation fails
  } else {
    return true; // Validation passes
  }
}

export const amountFormatter = (amount, currency) => {
  let currency_code = currency ? currency : getBusinessInfo()?.currency?.currency_code
  let locale_currency_code = locale_dict_currency[currency_code] ? locale_dict_currency[currency_code] : 'en_IN';

  let currencyInfo = currencyData.find((item) => item.currency_code === currency_code)
  let currency_symbol = currencyInfo ? currencyInfo.symbol : ''

  const formatterWithoutSymbol = currency_code && new Intl.NumberFormat(
    locale_currency_code.replace('_', '-'),
    {
      style: 'currency',
      currencyDisplay: "code",
      currency: currency_code,
    });
  let symbol = Number(amount) < 0 ? currency_symbol + ' ' : currency_symbol
  return symbol + formatterWithoutSymbol?.format(amount).replace(currency_code, "")
}

export function formatterWithoutSymbol(currency, amount) {
  let currency_code = currency ? currency : getBusinessInfo().currency.currency_code
  let locale_currency_code = locale_dict_currency[currency_code] ? locale_dict_currency[currency_code] : 'en_IN';

  const formatter = new Intl.NumberFormat(
    locale_currency_code.replace('_', '-'),
    {
      style: 'currency',
      currencyDisplay: "code",
      currency: currency_code,
    })
    .format(amount)
    .replace(currency_code, "")
    .trim().replace('.00', "");
  return formatter;
}

export function numberFormatterWithoutSymbol(currency, amount) {
  let currency_code = currency ? currency : getBusinessInfo().currency.currency_code
  let locale_currency_code = locale_dict_currency[currency_code] ? locale_dict_currency[currency_code] : 'en_IN';

  const formatter = new Intl.NumberFormat(
    locale_currency_code.replace('_', '-'),
    {
      style: 'currency',
      currencyDisplay: "code",
      currency: currency_code,
    })
    .format(amount)
    .replace(currency_code, "")
    .trim()
  return formatter;
}

export const numbersWithSuffix = (value, currency) => {
  const num = Number(value);
  let currency_code = currency ? currency : getBusinessInfo().currency.currency_code
  let locale_currency_code = locale_dict_currency[currency_code] ? locale_dict_currency[currency_code] : 'en_IN';
  let locale = "en-US";
  return Intl.NumberFormat(locale, {
    notation: "compact",
    // style:'currency',
    // currency: currency_code,
    maximumFractionDigits: 1
  }).format(num)
}

export const notifyErrorMessage = (message) => toast.error(message);

export const breadCrumbsStateChangeManager = (dispatch, value) => {
  dispatch(Actions.stateChange('end_breadcrumbs', value))
}

export const stateChangeManager = (dispatch, Actions, alert, severity, message) => {
  dispatch(Actions.stateChange('alert', alert))
  dispatch(Actions.stateChange('severity', severity))
  dispatch(Actions.stateChange('message', message))
}

export const lowInventoryAlert = (dispatch, Actions, item, quantity = 1) => {
  let negativeStockAlert = (item.current_stock - quantity) < 0;
  let lowStockAlert = (item.current_stock - quantity) < item.low_stock_value;

  if (lowStockAlert) {
    stateChangeManager(dispatch, Actions, true, 'warning', `Item stock is below the set low stock reminder value ${item.low_stock_value}. Please review inventory.`)
  }
  if (negativeStockAlert) {
    stateChangeManager(dispatch, Actions, true, 'warning', "Item stock has gone negative. Immediate restock required.")
  }
}

export const setLoader = (dispatch, Actions, loader) => {
  dispatch(Actions.stateChange('loader', loader))
}

export const setFilterMessage = (dispatch, Actions, message) => {
  dispatch(Actions.stateChange('filterMessage', message))
}
export function areObjectsEqual(obj1, obj2) {
  // Implement your own logic to compare objects here
  // This example assumes that the objects are equal if their stringified versions are the same.
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}
export function getUniqueObjects(list) {
  const uniqueObjects = [];

  for (const obj of list) {
    if (obj) {
      let isUnique = true;
      for (const uniqueObj of uniqueObjects) {
        if (areObjectsEqual(obj, uniqueObj)) {
          isUnique = false;
          break;
        }
      }
      if (isUnique) {
        uniqueObjects.push(obj);
      }
    }
  }

  return uniqueObjects;
}

export function getUniqueObjectsByKey(list, key = 'id') {
  const uniqueObjects = [];

  for (const obj of list) {
    if (obj) {
      let isUnique = true;
      for (const uniqueObj of uniqueObjects) {
        if (obj[key] === uniqueObj[key]) {
          isUnique = false;
          break;
        }
      }
      if (isUnique) {
        uniqueObjects.push(obj);
      }
    }
  }

  return uniqueObjects;
}

export const groupByAuto = (data, key) => {
  let unique_keys = new Set(data.map(function (element) {
    return element[key];
  }));
  let result = [];
  for (const element of unique_keys) {
    let filtered_result = data.filter(function (value) {
      return value[key] === element;
    });

    let total_balance = 0;

    filtered_result.forEach(x => {
      total_balance += x['balance_amount'];
    });

    result.push({ "parent_account": element, "items": filtered_result, "total_balance": total_balance });
  }
  return result
}

export const sumOfArray = (data) => {
  return data.reduce((pv, cv) => pv + cv, 0)
}
export const sumOfArrayByKey = (data, key) => {
  return data.reduce((acc, item) => acc + item[key], 0)
}

export const checkStartWeekDay = () => {
  let selected_week = getGeneralPreferences() ? getGeneralPreferences().start_week_day : 'Sunday'
  if (selected_week === 'Sunday') {
    return 0
  }
  if (selected_week === 'Monday') {
    return 1
  }
  if (selected_week === 'Tuesday') {
    return 2
  }
  if (selected_week === 'Wednesday') {
    return 3
  }
  if (selected_week === 'Thursday') {
    return 4
  }
  if (selected_week === 'Friday') {
    return 5
  }
  if (selected_week === 'Saturday') {
    return 6
  }
}

export const get_from_date = (key, value, date) => {
  let result;
  let startOfWeekDay = checkStartWeekDay();

  if (date) {
    result = moment(date).subtract(value, key);
  } else if (key === 'weeks') {
    const currentDay = moment().day();
    const daysUntilStartOfWeek = (currentDay - startOfWeekDay + 7) % 7;
    result = moment().subtract(value, key).subtract(daysUntilStartOfWeek, 'days');

    // result = moment().startOf(key).subtract(value, key).day(startOfWeekDay);
  } else {
    result = moment().startOf(key).subtract(value, key);
  }

  return result;
};

export const get_to_date = (key, value, date) => {
  let result;
  let startOfWeekDay = checkStartWeekDay();

  if (date) {
    result = moment(date).add(value, key);
  } else if (key === 'weeks') {
    const currentDay = moment().day();
    const daysUntilEndOfWeek = (6 - currentDay + startOfWeekDay + 7) % 7;
    result = moment().subtract(value, key).add(daysUntilEndOfWeek, 'days');

    // result = moment().startOf(key).subtract(value, key).day(startOfWeekDay).endOf(key);
  } else {
    result = moment().subtract(value, key).endOf(key);
  }

  return result;
};


export const special_character_check = (string) => {
  let format = /[*]/;
  if (string.match(format)) {
    return true;
  } else {
    return false;
  }
}
export function isEmptyDict(obj) {
  return Object.keys(obj).length === 0;
}
export const isNumber = (value) => { return !isNaN(Math.max(0, parseInt(value)).toString().slice(0, 10)) }

export const returnNumberOnly = (value) => {
  if (isNumber(value)) {
    return Number(value)
  } else {
    return 0
  }
}

export const isNumeric = (num) => ((typeof (num) === 'number') || ((typeof (num) === "string") && (num.trim() !== ''))) && !isNaN(num);

export function numberWithCommas(value) {
  let num = Number(Math.round(value + 'e' + 2) + 'e-' + 2)
  var num_parts = num.toString().split(".");
  num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return num_parts.join(".")
}

export const numberWithSuffix = (value) => {
  const num = Number(value);

  if (num === 0) return '0';

  const suffixes = ['', 'K', 'M', 'B', 'T', 'Q', 'Qu', 'Sx', 'Sp', 'Oc', 'No', 'Dc'];
  const sign = num < 0 ? '-' : '';
  const abs = Math.abs(num);
  const index = Math.floor(Math.log10(abs) / 3);
  const scaled = abs / Math.pow(10, index * 3);
  const suffix = suffixes[index];
  const formatted = scaled.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 1 });

  return sign + formatted + suffix;
}


export function getJsDateFormat(format) {
  let jsFormat = format.replace(/yyyy/g, 'YYYY')
    .replace(/month/g, 'MMMM')
    .replace(/mon/g, 'MMM')
    .replace(/yy/g, 'YY')
    .replace(/mm/g, 'MM')
    .replace(/m/g, 'M')
    .replace(/dd/g, 'DD')
    .replace(/d/g, 'D');
  return jsFormat;
}


export const verifyPassword = (pass) => {
  const passwordREGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\W_]).{8,}$/;
  return passwordREGEX.test(pass);
}


export function limitDecimalPlaces(value, decimalPlaces = 2) {
  if (value === '') return value; // Allow empty input

  // Convert the value to a string for easier manipulation
  const stringValue = value.toString();

  // Handle specific cases for small decimal values
  // const smallDecimalValues = ['0', '0.0', '0.', '.0', '.00','0.00'];
  // if (smallDecimalValues.includes(stringValue)) return stringValue;

  // Split the value into integer and decimal parts
  const [integerPart, decimalPart = ''] = stringValue.split('.');

  // Truncate the decimal part to the specified number of decimal places
  const truncatedDecimal = decimalPart.slice(0, decimalPlaces);

  // If there is no decimal part, return the integer as a number
  if (!decimalPart) return Number(integerPart);

  // Combine the integer and truncated decimal parts
  const limitedValue = `${integerPart}.${truncatedDecimal}`;

  // Return the value as a number if it has decimal places, otherwise as a string
  return Number(limitedValue);
}


export const validateCityOrPostalCode = (value) => {
  return /^[0-9A-Za-z\s-]+$/.test(value);
};

export const isValidPhoneNumber = (phoneNumber) => {
  const PHONE_REGEX = /^[\+\d\s()-]{10}$/;
  return PHONE_REGEX.test(phoneNumber);
};
// export const checkValidation = ( validation_info,data ) => {
//   let isValid = true
//   let validationKeys = validation_info.map((info)=>info.key)

// }

const useForceRerender = () => {
  const [value, setValue] = React.useState(0);
  return () => setValue(value => value + 1);
};

export const useIsElementVisible = (target, options = undefined) => {
  const [isVisible, setIsVisible] = React.useState(false);
  const forceUpdate = useForceRerender();

  React.useEffect(() => {
    forceUpdate(); // Ensure that ref.current is attached to the DOM element
  }, []);

  React.useEffect(() => {
    if (!target) return;

    const observer = new IntersectionObserver(handleVisibilityChange, options);
    observer.observe(target);

    return () => observer.unobserve(target);
  }, [target, options]);

  const handleVisibilityChange = ([entry]) => {
    if (!isVisible && entry.isIntersecting) {
      setIsVisible(true);
    }
  };

  return isVisible;
};


export const getDateFormat = (format) => {
  let date_format = format ? format : getBusinessInfo() && getBusinessInfo().date_format && getBusinessInfo().date_format.date_format
  return date_format ? getJsDateFormat(date_format) : 'DD-MM-YYYY'
}

export const isValidPan = (pan) => {
  const reg_pan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
  return reg_pan.test(pan)
}


export const isFormValid = (data, validation_data) => {
  let isFormValid = true
  let message = ""
  let key = ""
  for (var i = 0; i < validation_data.length; i++) {
    let item = validation_data[i]
    if (item) {
      if ("validation" in item) {
        if (item.validation) {
          isFormValid = !item.validation
          message = item.message
          key = item.key
          break
        }

      } else {
        if (!data[item.key]) {
          isFormValid = false
          message = item.message
          key = item.key
          break
        }
      }
    }
  }
  return { isValid: isFormValid, message: message, key: key }
}


export const isAddressSame = (address1, address2) => {
  if (address1) {
    return address1 === address2
  }
}

export const getAddress = (address) => {
  let addressLine1 = (address.address_line1 != null) ? address.address_line1 : ""
  let addressLine2 = (address.address_line2 != null) ? address.address_line2 : ""
  let city = (address.city != null) ? address.city : ""
  let state = (address.state != null) ? address.state : ""
  let zipCode = (address.zip_code != null) ? address.zip_code : ""
  let address_return = `${addressLine1} ${addressLine2} ${city} ${state} ${zipCode}`;
  return address_return !== '    ' ? address_return : null
}
export const get_party_contact_name = (data) => {
  let salutation = data && (data.salutation != null) ? data.salutation : ""
  let first_name = data && (data.first_name != null) ? data.first_name : ""
  let middle_name = data && (data.middle_name != null) ? data.middle_name : ""
  let last_name = data && (data.last_name != null) ? data.last_name : ""
  let data_return = `${salutation} ${first_name} ${middle_name} ${last_name}`;

  return data_return !== '   ' ? data_return : null
}

export const isallValidEmails = (emails) => {
  let commaSeperatedEmails = emails ? emails.split(",") : []
  let allEmailsValid = true
  for (var i = 0; i < commaSeperatedEmails.length; i++) {
    if (!validator.isEmail(commaSeperatedEmails[i])) {
      allEmailsValid = false
      break
    }
  }
  return allEmailsValid
}

export const createMappings = (model_fields = []) => {
  let mappings = {}
  model_fields.map((field) => {
    if (field.file_header) {
      mappings[field.db_field] = field.file_header
    }
    return null
  })

  return mappings
}

export const compareMappings = (mapping1, mapping2) => {
  const keys1 = Object.keys(mapping1 ? mapping1 : {});
  const keys2 = Object.keys(mapping2 ? mapping2 : {});

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (mapping1[key] !== mapping2[key]) {
      return false;
    }
  }

  return true;
};


export const to_check_repeat_every = (repeat_frequency, repeat_every) => {
  const recurring_period = [
    { name: 'Daily', repeat_frequency: 'day', repeat_every: 1 },
    { name: 'Weekly', repeat_frequency: 'week', repeat_every: 1 },
    { name: 'Monthly', repeat_frequency: 'month', repeat_every: 1 },
    { name: 'Yearly', repeat_frequency: 'year', repeat_every: 1 },
  ]
  return recurring_period.find((item) => item.repeat_frequency === repeat_frequency & item.repeat_every === repeat_every)
}

export const returnTransactionType = (type) => {
  let text = ''
  if (type === "DEBIT") {
    text = ' ( Dr. )'
  } else if (type === "CREDIT") {
    text = ' ( Cr. )'
  }
  return text
}
export const to_check_gst_registration_type = (type) => {
  const gst_registration_type = GSTRegistrationTypeChoice.find((item) => item.value === type)
  return gst_registration_type ? gst_registration_type.name : ''
}
export const disabled_gst_number = (type) => {
  return type === null || type === 'gst_unregistered' || type === 'consumer' || type === 'overseas'
}

export const getBackGroundColorFromStatus = (status) => {

  if (status === "draft" || status === "unbilled") {
    return "#e3e3e37a"
  } else if (status === "closed" || status === 'Inactive' || status === 'pro_forma') {
    return "#72f6ff26"
  } else if (status === "unsent" || status === "non_billable") {
    return "#e4d7f95c"
  } else if (status === "partially_paid" || status === "partially_utilized" || status === "low_stock") {
    return "#ede3c175"
  } else if (status === "sent" || status === 'delivered') {
    return "#d7e0f963"
  } else if (status === "overdue" || status === "no_stock" || status === "Expired" || status === 'returned') {
    return "#f9d7d74f"
  } else if (status === "unpaid" || status === 'reimbursed') {
    return "#e8f3ff8f"
  } else if (status === "paid" || status === "Active" || status === "open" || status === "in_stock" || status === "invoiced" || status === "Posted") {
    return "#bcf6de70"
  } else {
    return '#FFFFFF'
  }
}

export const getColorFromStatus = (status) => {
  if (status === "draft" || status === "unbilled") {
    return "#635e5edb"
  } else if (status === "closed" || status === 'Inactive' || status === 'pro_forma') {
    return "#00a9b5fc"
  } else if (status === "unsent" || status === "non_billable") {
    return "#4e00cdab"
  } else if (status === "partially_paid" || status === "partially_utilized" || status === "low_stock") {
    return "#be930078"
  } else if (status === "sent" || status === 'delivered') {
    return "#0232b7a6"
  } else if (status === "overdue" || status === "no_stock" || status === "Expired" || status === 'returned') {
    return "#ae0000ba"
  } else if (status === "unpaid" || status === 'reimbursed') {
    return "#005fc6c9"
  } else if (status === "paid" || status === "Active" || status === "open" || status === "in_stock" || status === "invoiced" || status === "Posted") {
    return "#00a662d1"
  } else {
    return '#000000'
  }
}


export function getLastFinancialDate() {
  const today = moment();
  const currentYear = today.year();
  const lastFinancialYearEnd = moment(`${currentYear}-03-31`);

  if (today.isBefore(moment(`${currentYear}-04-01`))) {
    // If today is before April 1, return March 31 of the previous year
    return lastFinancialYearEnd.subtract(1, 'year').format('YYYY-MM-DD');
  } else {
    // Otherwise, return March 31 of the current year
    return lastFinancialYearEnd.format('YYYY-MM-DD');
  }
}

export function getFinancialYearStartDate() {
  const today = moment();
  const currentYear = today.year();
  const financialYearStart = moment(`${currentYear}-04-01`);

  if (today.isBefore(financialYearStart)) {
    // If today is before April 1, the financial year started on April 1 of the previous year
    return financialYearStart.subtract(1, 'year').format('YYYY-MM-DD');
  } else {
    // Otherwise, the financial year started on April 1 of the current year
    return financialYearStart.format('YYYY-MM-DD');
  }
}

export const get_last_12_month_date = () => {

  let migration_date = getBusinessInfo() && getBusinessInfo().migration_date;

  let to_date = get_to_date('months', 0, moment()).format('YYYY-MM-DD')
  let start_date = get_from_date('months', 12, moment().add(1, 'day')).format('YYYY-MM-DD')
  let from_date = moment(migration_date).format("YYYY-MM-DD") <= moment(start_date).format("YYYY-MM-DD") ? start_date : migration_date

  return ({ migration_date: migration_date, from_date: from_date, to_date: to_date })
}

export const date_range = () => {
  const current_date = moment().format('YYYY-MM-DD');
  const current_financial_year = `${moment().year()}-${moment(getBusinessInfo() ? getBusinessInfo().financial_year_last_day : undefined).format('MM-DD')}`;
  const financial_year = current_date > current_financial_year ? current_financial_year : moment(current_financial_year).subtract(12, 'months').format('YYYY-MM-DD');

  return [
    {
      name: 'Today',
      dates: {
        to_date: get_to_date('day', 0).format('YYYY-MM-DD'),
        from_date: get_from_date('day', 0).format('YYYY-MM-DD'),
        date_range: `${get_from_date('day', 0).format(getDateFormat())} - ${get_to_date('day', 0).format(getDateFormat())}`
      }
    },
    {
      name: 'This Week',
      dates: {
        to_date: get_to_date('weeks', 0).format('YYYY-MM-DD'),
        from_date: get_from_date('weeks', 0).format('YYYY-MM-DD'),
        date_range: `${get_from_date('weeks', 0).format(getDateFormat())} - ${get_to_date('weeks', 0).format(getDateFormat())}`
      }
    },
    {
      name: 'This Month',
      dates: {
        to_date: get_to_date('months', 0).format('YYYY-MM-DD'),
        from_date: get_from_date('months', 0).format('YYYY-MM-DD'),
        date_range: `${get_from_date('months', 0).format(getDateFormat())} - ${get_to_date('months', 0).format(getDateFormat())}`
      },
    },
    {
      name: 'This Quarter',
      dates: {
        to_date: momentFquarter().fquarter().end,
        from_date: momentFquarter().fquarter().start,
        date_range: `${moment(momentFquarter().fquarter().start).format(getDateFormat())} - ${moment(momentFquarter().fquarter().end).format(getDateFormat())}`
      },
    },
    {
      name: 'This Fiscal Year',
      dates: {
        to_date: get_to_date('months', 12, moment(financial_year)).format('YYYY-MM-DD'),
        from_date: get_from_date('months', 0, moment(financial_year).add(1, 'day')).format('YYYY-MM-DD'),
        date_range: `${get_from_date('months', 0, moment(financial_year).add(1, 'day')).format(getDateFormat())}  - ${get_to_date('months', 12, moment(financial_year)).format(getDateFormat())}`
      },
    },
    {
      name: 'Yesterday',
      dates: {
        to_date: get_to_date('day', 1).format('YYYY-MM-DD'),
        from_date: get_from_date('day', 1).format('YYYY-MM-DD'),
        date_range: `${get_from_date('day', 1).format(getDateFormat())} - ${get_to_date('day', 1).format(getDateFormat())}`
      }
    },
    {
      name: 'Previous Week',
      dates: {
        to_date: get_to_date('weeks', 1).format('YYYY-MM-DD'),
        from_date: get_from_date('weeks', 1).format('YYYY-MM-DD'),
        date_range: `${get_from_date('weeks', 1).format(getDateFormat())} - ${get_to_date('weeks', 1).format(getDateFormat())}`
      }
    },
    {
      name: 'Previous Month',
      dates: {
        to_date: get_to_date('months', 1).format('YYYY-MM-DD'),
        from_date: get_from_date('months', 1).format('YYYY-MM-DD'),
        date_range: `${get_from_date('months', 1).format(getDateFormat())} - ${get_to_date('months', 1).format(getDateFormat())} `
      },
    },
    {
      name: 'Previous Quarter',
      dates: {
        to_date: moment(momentFquarter().fquarter().end).subtract("3", "months").format("YYYY-MM-DD"),
        from_date: moment(momentFquarter().fquarter().start).subtract("3", "months").format("YYYY-MM-DD"),
        date_range: `${moment(momentFquarter().fquarter().start).subtract("3", "months").format(getDateFormat())} - ${moment(momentFquarter().fquarter().end).subtract("3", "months").format(getDateFormat())}`
      }
    },
    {
      name: 'Previous Fiscal Year',
      dates: {
        to_date: get_to_date('months', 0, moment(financial_year)).format('YYYY-MM-DD'),
        from_date: get_from_date('months', 12, moment(financial_year).add(1, 'day')).format('YYYY-MM-DD'),
        date_range: `${get_from_date('months', 12, moment(financial_year).add(1, 'day')).format(getDateFormat())}  - ${get_to_date('months', 0, moment(financial_year)).format(getDateFormat())}`
      },

    },
    { name: 'Custom', dates: 0 },
  ]
}

export const custom_date_range = () => {
  const current_date = moment().format('YYYY-MM-DD');
  const current_financial_year = `${moment().year()}-${moment(getBusinessInfo() ? getBusinessInfo().financial_year_last_day : undefined).format('MM-DD')}`;
  const financial_year = current_date > current_financial_year ? current_financial_year : moment(current_financial_year).subtract(12, 'months').format('YYYY-MM-DD');

  return [
    {
      name: 'Today',
      dates: {
        to_date: get_to_date('day', 0).format('YYYY-MM-DD'),
        from_date: get_from_date('day', 0).format('YYYY-MM-DD'),
        date_range: `${get_to_date('day', 0).format(getDateFormat())}`
      }
    },
    {
      name: 'This Week',
      dates: {
        to_date: get_to_date('weeks', 0).format('YYYY-MM-DD'),
        from_date: get_from_date('weeks', 0).format('YYYY-MM-DD'),
        date_range: `${get_to_date('weeks', 0).format(getDateFormat())}`
      }
    },
    {
      name: 'This Month',
      dates: {
        to_date: get_to_date('months', 0).format('YYYY-MM-DD'),
        from_date: get_from_date('months', 0).format('YYYY-MM-DD'),
        date_range: `${get_to_date('months', 0).format(getDateFormat())}`
      },
    },
    {
      name: 'This Quarter',
      dates: {
        to_date: momentFquarter().fquarter().end,
        from_date: momentFquarter().fquarter().start,
        date_range: `${moment(momentFquarter().fquarter().end).format(getDateFormat())}`
      },
    },
    {
      name: 'This Fiscal Year',
      dates: {
        to_date: get_to_date('months', 12, moment(financial_year)).format('YYYY-MM-DD'),
        from_date: get_from_date('months', 0, moment(financial_year).add(1, 'day')).format('YYYY-MM-DD'),
        date_range: `${get_to_date('months', 12, moment(financial_year)).format(getDateFormat())}`
      },
    },
    {
      name: 'Yesterday',
      dates: {
        to_date: get_to_date('day', 1).format('YYYY-MM-DD'),
        from_date: get_from_date('day', 1).format('YYYY-MM-DD'),
        date_range: `${get_to_date('day', 1).format(getDateFormat())}`
      }
    },
    {
      name: 'Previous Week',
      dates: {
        to_date: get_to_date('weeks', 1).format('YYYY-MM-DD'),
        from_date: get_from_date('weeks', 1).format('YYYY-MM-DD'),
        date_range: `${get_to_date('weeks', 1).format(getDateFormat())}`
      }
    },
    {
      name: 'Previous Month',
      dates: {
        to_date: get_to_date('months', 1).format('YYYY-MM-DD'),
        from_date: get_from_date('months', 1).format('YYYY-MM-DD'),
        date_range: `${get_to_date('months', 1).format(getDateFormat())} `
      },
    },
    {
      name: 'Previous Quarter',
      dates: {
        to_date: moment(momentFquarter().fquarter().end).subtract("3", "months").format("YYYY-MM-DD"),
        from_date: moment(momentFquarter().fquarter().start).subtract("3", "months").format("YYYY-MM-DD"),
        date_range: `${moment(momentFquarter().fquarter().end).subtract("3", "months").format(getDateFormat())}`
      }
    },
    {
      name: 'Previous Fiscal Year',
      dates: {
        to_date: get_to_date('months', 0, moment(financial_year)).format('YYYY-MM-DD'),
        from_date: get_from_date('months', 12, moment(financial_year).add(1, 'day')).format('YYYY-MM-DD'),
        date_range: `${get_to_date('months', 0, moment(financial_year)).format(getDateFormat())}`
      },

    },
  ]
}

export const multiple_date_range = () => {
  const current_date = moment().format('YYYY-MM-DD');
  const current_financial_year = `${moment().year()}-${moment(getBusinessInfo() ? getBusinessInfo().financial_year_last_day : undefined).format('MM-DD')}`;
  const financial_year = current_date > current_financial_year ? current_financial_year : moment(current_financial_year).subtract(12, 'months').format('YYYY-MM-DD');
  return [
    {
      name: 'Today',
      dates: [
        {
          to_date: get_to_date('day', 0).format('YYYY-MM-DD'),
          from_date: get_from_date('day', 0).format('YYYY-MM-DD'),
          date_range: `${get_to_date('day', 0).format(getDateFormat())}`,
          date_range_dates: `${get_to_date('day', 0).format(getDateFormat())}`,
        }, {
          to_date: get_to_date('day', 1).format('YYYY-MM-DD'),
          from_date: get_from_date('day', 1).format('YYYY-MM-DD'),
          date_range: `${get_to_date('day', 1).format(getDateFormat())}`,
          date_range_dates: `${get_to_date('day', 1).format(getDateFormat())}`,
        }
      ]
    },
    {
      name: 'This Week',
      dates: [
        {
          to_date: get_to_date('weeks', 0).format('YYYY-MM-DD'),
          from_date: get_from_date('weeks', 0).format('YYYY-MM-DD'),
          date_range: `${get_to_date('weeks', 0).format(getDateFormat())}`,
          date_range_dates: `${get_from_date('weeks', 0).format(getDateFormat("MMM DD"))} - ${get_to_date('weeks', 0).format(getDateFormat("MMM DD"))}`,
        }, {
          to_date: get_to_date('weeks', 1).format('YYYY-MM-DD'),
          from_date: get_from_date('weeks', 1).format('YYYY-MM-DD'),
          date_range: `${get_to_date('weeks', 1).format(getDateFormat())}`,
          date_range_dates: `${get_from_date('weeks', 1).format(getDateFormat("MMM DD"))} - ${get_to_date('weeks', 1).format(getDateFormat("MMM DD"))}`,
        }
      ]
    },
    {
      name: 'This Month',
      dates: [
        {
          to_date: get_to_date('months', 0).format('YYYY-MM-DD'),
          from_date: get_from_date('months', 0).format('YYYY-MM-DD'),
          date_range: `${get_to_date('months', 0).format(getDateFormat())}`,
          date_range_dates: `${get_from_date('months', 0).format(getDateFormat("MMM YYYY"))}`,

        }, {
          to_date: get_to_date('months', 1).format('YYYY-MM-DD'),
          from_date: get_from_date('months', 1).format('YYYY-MM-DD'),
          date_range: `${get_to_date('months', 1).format(getDateFormat())} `,
          date_range_dates: `${get_to_date('months', 1).format(getDateFormat("MMM YYYY"))}`,

        }
      ]
    },
    {
      name: 'This Quarter',
      dates: [
        {
          to_date: momentFquarter().fquarter().end,
          from_date: momentFquarter().fquarter().start,
          date_range: `${moment(momentFquarter().fquarter().end).format(getDateFormat())}`,
          date_range_dates: `${moment(momentFquarter().fquarter().start).format(getDateFormat("MMM YYYY"))} - ${moment(momentFquarter().fquarter().end).format(getDateFormat("MMM YYYY"))}`,
        }, {
          to_date: moment(momentFquarter().fquarter().end).subtract("3", "months").format("YYYY-MM-DD"),
          from_date: moment(momentFquarter().fquarter().start).subtract("3", "months").format("YYYY-MM-DD"),
          date_range: `${moment(momentFquarter().fquarter().end).subtract("3", "months").format(getDateFormat())}`,
          date_range_dates: `${moment(momentFquarter().fquarter().start).subtract("3", "months").format(getDateFormat("MMM YYYY"))} - ${moment(momentFquarter().fquarter().end).subtract("3", "months").format(getDateFormat("MMM YYYY"))}`,
        }
      ]
    },
    {
      name: 'This Fiscal Year',
      dates: [
        {
          from_date: get_from_date('months', 0, moment(financial_year).add(1, 'day')).format('YYYY-MM-DD'),
          to_date: get_to_date('months', 12, financial_year).format('YYYY-MM-DD'),
          date_range: `${get_to_date('months', 12, financial_year).format(getDateFormat())}`,
          date_range_dates: `${get_from_date('months', 0, moment(financial_year).add(1, 'day')).format(getDateFormat("MMM YYYY"))} - ${get_to_date('months', 12, financial_year).format(getDateFormat("MMM YYYY"))}`,
        }, {
          from_date: get_from_date('months', 12, moment(financial_year).add(1, 'day')).format('YYYY-MM-DD'),
          to_date: get_to_date('months', 0, financial_year).format('YYYY-MM-DD'),
          date_range: `${get_to_date('months', 0, financial_year).format(getDateFormat())}`,
          date_range_dates: `${get_from_date('months', 12, moment(financial_year).add(1, 'day')).format(getDateFormat("MMM YYYY"))} - ${get_to_date('months', 0, financial_year).format(getDateFormat("MMM YYYY"))}`,
        }
      ]
    },
    {
      name: 'Yesterday',
      dates: [
        {
          to_date: get_to_date('day', 1).format('YYYY-MM-DD'),
          from_date: get_from_date('day', 1).format('YYYY-MM-DD'),
          date_range: `${get_to_date('day', 1).format(getDateFormat())}`,
          date_range_dates: `${get_to_date('day', 1).format(getDateFormat())}`
        }, {
          to_date: get_to_date('day', 2).format('YYYY-MM-DD'),
          from_date: get_from_date('day', 2).format('YYYY-MM-DD'),
          date_range: `${get_to_date('day', 2).format(getDateFormat())}`,
          date_range_dates: `${get_to_date('day', 2).format(getDateFormat())}`
        }
      ]
    },
    {
      name: 'Previous Week',
      dates: [
        {
          to_date: get_to_date('weeks', 1).format('YYYY-MM-DD'),
          from_date: get_from_date('weeks', 1).format('YYYY-MM-DD'),
          date_range: `${get_to_date('weeks', 1).format(getDateFormat())}`,
          date_range_dates: `${get_from_date('weeks', 1).format(getDateFormat("MMM DD"))} - ${get_to_date('weeks', 1).format(getDateFormat("MMM DD"))}`,
        }, {
          to_date: get_to_date('weeks', 2).format('YYYY-MM-DD'),
          from_date: get_from_date('weeks', 2).format('YYYY-MM-DD'),
          date_range: `${get_to_date('weeks', 2).format(getDateFormat())}`,
          date_range_dates: `${get_from_date('weeks', 2).format(getDateFormat("MMM DD"))} - ${get_to_date('weeks', 2).format(getDateFormat("MMM DD"))}`,
        }
      ]
    },
    {
      name: 'Previous Month',
      dates: [
        {
          to_date: get_to_date('months', 1).format('YYYY-MM-DD'),
          from_date: get_from_date('months', 1).format('YYYY-MM-DD'),
          date_range: `${get_to_date('months', 1).format(getDateFormat())}`,
          date_range_dates: `${get_from_date('months', 1).format(getDateFormat("MMM YYYY"))}`,
        }, {
          to_date: get_to_date('months', 2).format('YYYY-MM-DD'),
          from_date: get_from_date('months', 2).format('YYYY-MM-DD'),
          date_range: `${get_to_date('months', 2).format(getDateFormat())} `,
          date_range_dates: `${get_to_date('months', 2).format(getDateFormat("MMM YYYY"))}`,
        }
      ]
    },
    {
      name: 'Previous Quarter',
      dates: [
        {
          to_date: moment(momentFquarter().fquarter().end).subtract("3", "months").format("YYYY-MM-DD"),
          from_date: moment(momentFquarter().fquarter().start).subtract("3", "months").format("YYYY-MM-DD"),
          date_range: `${moment(momentFquarter().fquarter().end).subtract("3", "months").format(getDateFormat())}`,
          date_range_dates: `${moment(momentFquarter().fquarter().start).subtract("3", "months").format(getDateFormat("MMM YYYY"))} - ${moment(momentFquarter().fquarter().end).subtract("3", "months").format(getDateFormat("MMM YYYY"))}`,
        }, {
          to_date: moment(momentFquarter().fquarter().end).subtract("6", "months").format("YYYY-MM-DD"),
          from_date: moment(momentFquarter().fquarter().start).subtract("6", "months").format("YYYY-MM-DD"),
          date_range: `${moment(momentFquarter().fquarter().end).subtract("6", "months").format(getDateFormat())}`,
          date_range_dates: `${moment(momentFquarter().fquarter().start).subtract("6", "months").format(getDateFormat("MMM YYYY"))} - ${moment(momentFquarter().fquarter().end).subtract("6", "months").format(getDateFormat("MMM YYYY"))}`,
        }
      ]
    },
    {
      name: 'Previous Fiscal Year',
      dates: [
        {
          from_date: get_from_date('months', 12, moment(financial_year).add(1, 'day')).format('YYYY-MM-DD'),
          to_date: get_to_date('months', 0, financial_year).format('YYYY-MM-DD'),
          date_range: `${get_to_date('months', 0, financial_year).format(getDateFormat())}`,
          date_range_dates: `${get_from_date('months', 12, moment(financial_year).add(1, 'day')).format(getDateFormat("MMM YYYY"))} - ${get_to_date('months', 0, financial_year).format(getDateFormat("MMM YYYY"))}`,
        }, {
          from_date: get_from_date('months', 24, moment(financial_year).add(1, 'day')).format('YYYY-MM-DD'),
          to_date: get_to_date('months', -12, financial_year).format('YYYY-MM-DD'),
          date_range: `${get_to_date('months', -12, financial_year).format(getDateFormat())}`,
          date_range_dates: `${get_from_date('months', 24, moment(financial_year).add(1, 'day')).format(getDateFormat("MMM YYYY"))} - ${get_to_date('months', -12, financial_year).format(getDateFormat("MMM YYYY"))}`,
        }
      ]
    },
    { name: 'Custom', dates: [] },
  ]
}


export const aggregateDataByPeriod = (data, from_date, to_date) => {

  const aggregatedData = {};
  const fromDate = moment(from_date);
  const toDate = moment(to_date);

  // Determine the duration between from_date and to_date
  const duration = toDate.diff(fromDate, 'days');

  // Define the format for grouping based on the duration
  let format;
  if (duration <= 7) {
    format = { unit: "day", format: "DD MMM YYYY" }; // Group by day, display time
  } else if (duration <= 31) {
    format = { unit: "day", format: "DD MMM" }; // Group by week, display date with month and year
  } else if (duration <= 93) {
    format = { unit: "month", format: "MMM YYYY" }; // Group by month, display week number
  } else if (duration <= 365) {
    format = { unit: "month", format: "MMM YYYY" }; // Group by quarter, display quarter and year
  } else {
    format = { unit: "year", format: "YYYY" }; // Group by year, display year
  }

  data.forEach(entry => {
    const period = moment(entry.from_date).format(format.format);
    const groupKey = moment(entry.from_date).startOf(format.unit).format(format.format);

    if (aggregatedData[groupKey]) {
      aggregatedData[groupKey].result += isNumber(entry.result) ? parseFloat(entry.result) : 0;
    } else {
      aggregatedData[groupKey] = {
        result: parseFloat(entry.result),
        from_date: entry.from_date,
        to_date: entry.to_date,
        period: period
      };
    }
  });
  return Object.values(aggregatedData);
};

export const formattedDataBasedGranularity = (data, granularity) => {
  const granularityFormats = {
    d: { unit: "day", format: "DD MMM YYYY" },
    w: { unit: "week", format: "DD MMM YYYY" },
    m: { unit: "month", format: "MMM YYYY" },
    y: { unit: "year", format: "YYYY" }
  };

  const format = granularityFormats[granularity] || granularityFormats.d;
  return data.map(entry => {
    // const period = moment(entry.from_date).startOf(format.unit).format(format.format);
    const period = moment(entry.to_date).format(format.format);

    return {
      ...entry,
      to_date: entry.to_date,
      from_date: entry.from_date,
      period: period
    };
  });
};

// Function to get granularity range based on from_date and to_date
export const getGranularityRange = (from_date, to_date) => {
  const fromDate = moment(from_date);
  const toDate = moment(to_date);
  const duration = toDate.diff(fromDate, 'days');
  // console.log('==>duration',duration)

  let disable = { d: true, w: true, m: true, y: true };

  if (duration < 6) {
    disable.d = false;
  } else if (duration === 6 || duration < 30) {
    disable.d = false;
    disable.w = false;
  } else if (duration === 30 || duration < 365) {
    disable.d = false;
    disable.w = false;
    disable.m = false;
  } else {
    disable.d = false;
    disable.w = false;
    disable.m = false;
    disable.y = false;
  }

  return disable;
};

export const getSelectedGranularityData = (key, from_date, to_date) => {
  const { d, w, m, y } = getGranularityRange(from_date, to_date);
  // console.log('====>getGranularityRange',getGranularityRange(from_date, to_date))

  const activeStyle = {
    color: '#FFFFFF',
    fontWeight: 700,
    borderColor: '#2464eb',
    fontFamily: 'Noto Sans',
    backgroundColor: '#2464eb',
    ":hover": { color: '#FFFFFF', backgroundColor: '#2464eb', }
  }

  let selected
  if (!y) {
    selected = 'y'
  } else if (!m) {
    selected = 'm'
  } else if (!w) {
    selected = 'w'
  } else if (!d) {
    selected = 'd'
  }

  if (key === selected) {
    return { active: activeStyle, selected: selected }
  } else if (key) {
    return { active: activeStyle, selected: key }
  } else {
    return { selected: selected }
  }
}


const date_format = {
  "dd/mm/yyyy": '%d/%m/%Y',
  "dd/mm/yy": '%d/%m/%y',
  "mm/dd/yyyy": '%m/%d/%Y',
  "mm/dd/yy": '%m/%d/%y',
  "yyyy/mm/dd": '%Y/%m/%d',

  "dd-mm-yyyy": '%d-%m-%Y',
  "dd-mon-yyyy": '%d-%b-%Y',
  "dd-mm-yy": '%d-%m-%y',
  "dd-mon-yy": '%d-%b-%y',
  "mm-dd-yyyy": '%m-%d-%Y',
  "mon-dd-yyyy": '%b-%d-%Y',
  "yyyy-mm-dd": '%Y-%m-%d',
  "yyyy-mon-dd": '%Y-%b-%d',

  "dd.mm.yyyy": '%d.%m.%Y',
  "dd.mm.yy": '%d.%m.%y',
  "mm.dd.yyyy": '%m.%d.%Y',
  "mm.dd.yy": '%m.%d.%y',
  "yyyy.mm.dd": '%Y.%m.%d',
  "yy.mm.dd": '%y.%m.%d',

  "dd month, yyyy": '%d %B, %Y',
  "yyyy, month dd": '%Y, %B %d',

  "dd mon, yyyy": '%d %b, %Y',
  "dd mon yy": '%d %b %y',
  "mon dd, yyyy": '%b %d, %Y',
  "yyyy, mon dd": '%Y, %b %d',

  "ddmonyy": '%d%b%y',
  "monddyy": '%b%d%y',
  "yyyymmdd": '%Y%m%d',
  "yymondd": '%y%b%d',
}

