import React, { useEffect } from 'react';
import { apiAction } from '../../api/api';
import * as Actions from '../../state/Actions';
import { useNavigate } from 'react-router-dom';
import CustomDropdown from '../custom/dropdown/CustomDropdown';
import CustomTypography from '../custom/typography/CustomTypography';


const CommonDropdown = (props) => {
    let navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);

    const {stateComponent,isRefresh, id, dataTestId, filter_by_id, filterValue,filterKey, item, sx, placeholder, disableClearable, autoSelect, noOptionsText, addButton, onSearchHandler, validation, defaultValue, isShowEndAdornment = false } = props
    const [results, setResult] = React.useState([])

    React.useEffect(() => {
        const apiResults = async () => {
            let res = await apiAction({
                url: item.url,
                navigate:navigate,
                dispatch:dispatch,
                data: item.body ? item.body : '',
                method: item.method ? item.method : 'get',
            })

            if (res?.success) {
                if (filter_by_id) {
                    filterValue(res?.result.result.find((item) => item.id === filter_by_id))
                }
                if (filterValue&&filterKey) {
                    item.onChange('',res?.result.find((item) => item[filterKey] === filterValue))
                }
                if (stateComponent) {
                    setResult(res?.result.sort((a, b) => -b.name.localeCompare(a.name)))
                }else{
                    setResult(res?.result.result_list ? res?.result.result_list : res?.result.result ? res?.result.result : res?.result)
                }
            } else {

            }
        }
        if (!item.open) {
            apiResults()
        }
        // eslint-disable-next-line
    }, [item.url, item.open,isRefresh])
    return (
        <div>
            <CustomTypography
                text={item.label}
                sx={{
                    mb: 1,
                    fontSize: '14px',
                    color: '#000000',
                    fontWeight: '700',
                    lineHeight: '18px',
                    fontStyle: 'normal',
                    textTransform: "none",
                    fontFamily: "Noto Sans",
                }}
            />
            <CustomDropdown
                id={id}
                dataTestId={dataTestId}
                addButton={addButton}
                autoSelect={autoSelect}
                validation={validation}
                onChange={item.onChange}
                placeholder={placeholder}
                setValue={onSearchHandler}
                inputDisabled={item.disabled}
                noOptionsText={noOptionsText}
                disableClearable={disableClearable}
                isShowEndAdornment={isShowEndAdornment}
                value={item.value ? item.value : defaultValue ? defaultValue : null}
                results={item.filter ? results.filter((value) => value.account_type === item.filter) : results}
                sx={item.sx ? { ...item.sx, background: item.disabled ? '#F1F3F4' : null } : { ...sx, background: item.disabled ? '#F1F3F4' : null }}


            />

        </div>
    )
}

export default CommonDropdown