import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import HorizontalLinearStepper from './Stepper';
import { images } from '../../../../utils/Constant';
import React, { useContext, useEffect } from 'react';
import * as Actions from '../../../../state/Actions';
import CustomTitle from '../../../common/CustomTitle';
import { routesName } from '../../../../config/routesName';
import CustomButton from '../../../custom/button/CustomButton';
import { NoDataComponent } from '../../../common/NoDataComponent';
import { apiActionFormData, apiAction } from '../../../../api/api';
import { CustomContainer } from '../../../custom/container/CustomContainer';
import { setBusinessInfo, getBusinessInfo } from '../../../../config/cookiesInfo';
import { createMappings, stateChangeManager, getDateFormat } from '../../../../utils/Utils';
import {
  update_business_details,
  transaction_bulk_upload,
  validate_sourced_transaction,
} from '../../../../api/urls';

import {
  Box,
  Grid,
  Button,
  Dialog,
  Divider,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import {
  CustomTable,
  CustomTableRow,
  CustomTableBody,
  CustomTableHead,
  CustomTableBodyCell,
  CustomTableHeadCell,
  CustomTableContainer,
} from '../../../custom/table/CustomTable';

import CustomTypography from '../../../custom/typography/CustomTypography';


const Transactions = (props) => {
  const { title = "Transactions", is_migration_date, url = transaction_bulk_upload(), role = "", validationUrl = validate_sourced_transaction(), isPerformValidation = true, onCancelButton, nextButton, onErrorButton, nextPagePath = routesName.sourceTransactions.path, onFileErrorPath = routesName.fileUpload.path, onCancelPath = routesName.mappings.path } = props
  const dispatch = Actions.getDispatch(React.useContext)
  const navigate = useNavigate()

  const stateData = Actions.getState(useContext);
  const { mappings, model_fields, mapping, date_format, time_format, transaction_date_format, value_date_format, migration_date = getBusinessInfo().migration_date } = stateData;
  const { rows } = mappings
  const { uploadedFile } = mappings

  const [isError, setIsError] = React.useState(true)
  const [errors, setErrors] = React.useState([])

  useEffect(() => {
    if (!uploadedFile) {
      if (onErrorButton) {
        onErrorButton()
      } else {
        navigate(onFileErrorPath)
      }
    }
    // eslint-disable-next-line
  }, [])

  const mapped_items = model_fields.filter((field) => field.file_header)

  const statementUpload = async () => {
    var formdata = {};
    formdata["role"] = role
    if (is_migration_date) {
      formdata["mapping"] = JSON.stringify(createMappings(model_fields))
    } else {
      formdata["mapping_id"] = mapping.id
    }
    // formdata["mapping_id"] = mapping.id
    // formdata["time_format"] = time_format
    // formdata["date_format"] = date_format
    formdata["file"] = mappings.uploadedFile
    formdata["migration_date"] = migration_date
    // formdata["value_date_format"] = value_date_format
    // formdata["transaction_date_format"] = transaction_date_format
    formdata["business_id"] = getBusinessInfo().id

    let res = await apiActionFormData({ url: url, method: 'post', data: formdata })
    if (res?.success) {
      // stateChangeManager(dispatch, Actions, true, 'success', res?.result)
      model_fields.map((item) => {
        delete item.file_header
      })
      dispatch(Actions.stateChange("mapping", null));
      dispatch(Actions.stateChange("mappings", {
        "rows": [],
        "columns": [],
        "fileName": "",
        "file_headers": [],
        "fileExtenstion": "",
        "uploadedFile": null,
        "mapped_headers": []
      }))
      dispatch(Actions.stateChange("model_fields", [...model_fields]));
      if (nextButton) {
        nextButton()
      } else {
        navigate(nextPagePath)
      }
      stateChangeManager(dispatch, Actions, true, 'success', res?.status)
    } else {
      stateChangeManager(dispatch, Actions, true, 'error', res?.status)
    }
  }

  const updateMigrationDate = async () => {
    var formdata = {};
    formdata["migration_date"] = migration_date
    formdata["business_id"] = getBusinessInfo().id
    let res = await apiAction({ url: update_business_details(), method: 'post', navigate: navigate, dispatch: dispatch, data: formdata })
    if (res?.success) {
      setOpen(true);
      setBusinessInfo(res?.result);
    } else {

    }

  }

  const onCancel = () => {
    if (onCancelButton) {
      onCancelButton()
    } else {
      navigate(onCancelPath);
    }
    dispatch(Actions.stateChange("activeStep", 1));
    // model_fields.map((item) => {
    //   delete item.file_header
    // })
    // dispatch(Actions.stateChange("mappings", { ...mappings,
    //   "mapped_headers": []
    // }));
    // dispatch(Actions.stateChange("model_fields", [...model_fields]));

  }


  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    const getErrorData = async () => {
      var formdata = {};
      if (is_migration_date) {
        formdata["mapping"] = JSON.stringify(createMappings(model_fields))
      } else {
        formdata["mapping_id"] = mapping.id
      }
      // formdata["mapping_id"] = mapping.id
      formdata["time_format"] = time_format
      formdata["date_format"] = date_format
      formdata["file"] = mappings.uploadedFile
      formdata["value_date_format"] = value_date_format
      formdata["transaction_date_format"] = transaction_date_format
      formdata["business_id"] = getBusinessInfo().id
      formdata["role"] = role
      let res = await apiActionFormData({ url: validationUrl, method: 'post', data: formdata })
      if (res?.success) {
        setErrors(res?.result)
        setIsError(res?.result.length === 0 ? false : true)
      } else {
        stateChangeManager(dispatch, Actions, true, 'error', res?.status)
      }

    }
    if (isPerformValidation) {
      getErrorData()
    } else {
      setIsError(isPerformValidation)
    }

  }, [])



  return (
    <Box style={{ height: "100%", }}>
      <Grid sm={12} container item style={{ backgroundColor: 'white', padding: 10, marginBottom: 10 }}>
        <Grid sm={12} item>
          <HorizontalLinearStepper />
        </Grid>
      </Grid>

      {
        isError ?
          errors.length ?
            <Box sx={{ background: '#FFFFFF', }}>
              <Grid container spacing={0} sx={{ paddingTop: 2, }} style={{
                alignItems: 'center',
                justifyItems: 'center',
                justifyContent: 'space-evenly',
                alignContent: 'space-evenly',
              }}>
                <Grid item xs={6} sx={{ pl: 2 }}>
                  <CustomTitle title={'Errors'} />
                </Grid>
                <Grid item xs={6} sx={{ textAlign: 'end', pr: 2 }} >
                  <Button id={'transaction_go_back_btn'} dataTestId={'transaction_go_back_btn'} variant='contained' style={{ marginLeft: 5, textTransform: 'none', fontFamily: "Noto Sans", }} size="medium" onClick={() => {
                    Actions.resetFileImports(dispatch)
                    if (onErrorButton) {
                      onErrorButton()
                    } else {
                      navigate(onFileErrorPath)
                    }
                  }}>
                    Go Back
                  </Button>
                </Grid>
              </Grid>
              <CustomContainer maxWidth={"400"} sx={{ height: 'calc(100vh - 176px)', }}>

                <div style={{ paddingLeft: '24px', paddingRight: '24px', paddingTop: 10, borderTop: '2px solid #F5F5F5' }}>
                  {
                    errors.map((error, index) => {
                      return (
                        <React.Fragment key={index}>
                          <CustomTypography
                            text={`${index + 1}. ${error}`}
                            sx={{
                              mb: 1,
                              color: '#f06360',
                              fontSize: '14px',
                              fontWeight: '500',
                              lineHeight: '18px',
                              fontStyle: 'normal',
                              fontFamily: "Noto Sans",
                            }}
                          />
                        </React.Fragment>
                      )
                    })
                  }
                </div>
              </CustomContainer>
            </Box>
            : ''
          :
          <Box sx={{ background: '#FFFFFF', }}>
            <CustomizedDialogs
              open={open}
              title={title}
              setOpen={setOpen}
              onCancel={onCancel}
              statementUpload={statementUpload}
            />
            <Grid container spacing={0} sx={{ pb: 2, pt: 2 }} style={{
              alignItems: 'center',
              justifyItems: 'center',
              justifyContent: 'space-evenly',
              alignContent: 'space-evenly',
            }}>
              <Grid item xs={6} sx={{ pl: 2 }}>
                <CustomTitle title={title} />
              </Grid>
              <Grid item xs={6} sx={{ textAlign: 'end', pr: 2 }} >
                <Button id={'transaction_cancel_btn'} dataTestId={'transaction_cancel_btn'} variant='outlined' sx={{ marginRight: 1, textTransform: 'none', fontFamily: "Noto Sans", }} size="medium" onClick={onCancel}>
                  Cancel
                </Button>
                <Button id={'transaction_save_btn'} dataTestId={'transaction_save_btn'} variant='contained' sx={{ textTransform: 'none', fontFamily: "Noto Sans", }} size="medium" onClick={() => { is_migration_date ? updateMigrationDate() : setOpen(true) }}>
                  Save
                </Button>
              </Grid>
            </Grid>
            <Divider />
            <CustomContainer maxWidth={"400"} sx={{ height: 'calc(100vh - 194px)', }}>
              <div style={{ marginTop: 10 }}>
                {is_migration_date ?
                  <CustomTypography
                    text={`Migration Date: ${moment(migration_date).format(getDateFormat())}`}
                    sx={{
                      ml: 2,
                      mb: 2,
                      color: '#000000',
                      fontSize: '14px',
                      fontWeight: '700',
                      lineHeight: '18px',
                      fontStyle: 'normal',
                      fontFamily: "Noto Sans",
                    }}
                  />
                  : null
                }
              </div>
              <CustomTableContainer sx={{ maxWidth: 'calc(100vw - 270px)', minWidth: '100%' }}>
                <CustomTable>
                  <ItemsListHeaders columns={mapped_items} rows={rows} />
                  <ItemsListBody columns={mapped_items} rows={rows} />
                </CustomTable>
                <Box sx={{ pt: !rows.length ? 10 : 0 }} >
                  <NoDataComponent left='0%' top='0%' position={'relative'} data={rows} image={images.stateMent_upload} />
                </Box>
              </CustomTableContainer>
            </CustomContainer>
          </Box>
      }


    </Box>
  )
}

const ItemsListHeaders = ({ columns }) => {

  return (
    <CustomTableHead>
      <CustomTableRow >
        <CustomTableHeadCell style={{ whiteSpace: 'nowrap' }}><span>{'Sr No.'}</span></CustomTableHeadCell>
        {columns.map((column, index) => {
          return <CustomTableHeadCell key={index}
            sx={{ whiteSpace: 'nowrap' }}
          //  style={{ width: 150 }}
          ><span>{column.verbose_name}</span></CustomTableHeadCell>
        })}
      </CustomTableRow>
    </CustomTableHead>
  )
}

const ItemsListBody = ({ columns, rows }) => {

  return (
    <CustomTableBody>
      {
        rows.map((item, index) => {
          // console.log('item', item)
          return (
            <CustomTableRow key={index}>
              <CustomTableBodyCell sx={{}}>
                <span>{index + 1}</span>
              </CustomTableBodyCell>
              {
                columns.map((column, i) => {

                  return <CustomTableBodyCell key={i} sx={{ fontWeight: column.fontWeight }} ><span>{item[column.file_header]}</span></CustomTableBodyCell>
                })
              }
            </CustomTableRow>
          )
        })
      }
    </CustomTableBody>
  )
}


const CustomizedDialogs = (props) => {
  const { open, title, setOpen, onCancel, statementUpload } = props

  const handleClicked = () => {
    statementUpload();
    setOpen(false);
  };
  return (
    <div>
      <BootstrapDialog
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={() => setOpen(false)}>
          <CustomTypography
            text={'Confirmation'}
            sx={{
              color: '#000000',
              fontSize: '16px',
              fontWeight: '700',
              lineHeight: '18px',
              fontStyle: 'normal',
              fontFamily: "Noto Sans",
            }}
          />

        </BootstrapDialogTitle>
        <DialogContent dividers sx={{ borderBottom: 'none' }} >
          <Typography gutterBottom sx={{
            mt: 2,
            fontSize: '14px',
            color: '#000000',
            fontWeight: '700',
            lineHeight: '18px',
            fontStyle: 'normal',
            textTransform: "none",
            fontFamily: "Noto Sans",
          }}
          >
            We are about to save these {title}, please make sure that columns have correct values !
          </Typography>
        </DialogContent>
        <DialogActions sx={{ mt: 2, mb: 2, ml: 1, justifyContent: 'flex-start', }}>
          <CustomButton
            id={'want_change_mapping_btn'}
            dataTestId={'want_change_mapping_btn'}
            variant='outlined'
            btnLabel='No, I want to change the mapping.'
            onClick={() => { setOpen(false); onCancel() }}
            sx={{ textTransform: 'none', '&:hover': { backgroundColor: '#e8f3ff' } }}
          />
          <CustomButton
            id={'go_ahead_btn'}
            dataTestId={'go_ahead_btn'}
            variant='contained'
            onClick={() => handleClicked()}
            sx={{ textTransform: 'none', }}
            btnLabel={`It looks right. Go ahead, save these ${title}.`}
          />
        </DialogActions>
      </BootstrapDialog>
    </div >
  )
}
export default Transactions;


export const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, display: 'flex', alignItems: 'center' }} {...other}>
      <div style={{flexGrow:1}}>
      {children}
      </div>
      {onClose ? (
        <div style={{}}>
          <IconButton
            id={'close_icon_button'}
            dataTestId={'close_icon_button'}
            aria-label="close"
            onClick={onClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));