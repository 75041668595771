import React from "react";
import moment from "moment";
import validator from "validator";
import Input from "../../../common/Input";
import { apiAction } from "../../../../api/api";
import * as Actions from '../../../../state/Actions';
import { getBusinessInfo } from "../../../../config/cookiesInfo";
import { setLoader, isBusinessRegistered } from "../../../../utils/Utils";
import { get_item, generate_stock_item_code } from "../../../../api/urls";
import CustomTypography from "../../../custom/typography/CustomTypography";
import { getItemPreferences } from '../../setting/settings/Preferences/common/getPreferences';


const valueWithTax = (value, taxRate) => { return Number(value * (1 + taxRate * 0.01)).toFixed(2) }

const processMetaData = (itemData) => itemData?.meta_data ?? [];

const processForSales = (itemData) => itemData?.for_sales ?? true;

const processForPurchase = (itemData) => itemData?.for_purchase ?? true;

const formatAsOfDate = (date) => moment(date).format('YYYY-MM-DD');

const calculatePriceWithTax = (price, taxRate, isInclusive) => isInclusive ? valueWithTax(price ?? 0, taxRate ?? 0) : price ?? 0;

const updateProductService = (itemData, metaData, for_sales, for_purchase) => (prev) => ({
    ...prev,
    ...itemData,
    for_sales,
    for_purchase,
    meta_data: metaData,
    status: itemData.status,
    tax: itemData?.tax ?? null,
    gst_rate: itemData.gst_rate,
    unit: itemData?.unit ?? null,
    name: itemData?.name ?? null,
    tax_id: itemData?.tax?.id ?? null,
    business_id: getBusinessInfo().id,
    unit_id: itemData?.unit?.id ?? null,
    opening_stock: itemData.opening_stock,
    current_stock: itemData.current_stock,
    attachment: itemData?.attachment ?? null,
    item_type: itemData?.item_type ?? 'goods',
    low_stock_value: itemData.low_stock_value,
    stock_item_code: itemData.stock_item_code,
    description: itemData?.description ?? null,
    hsn_sac_code: itemData?.hsn_sac_code ?? null,
    isManageStock: itemData.opening_stock !== null,
    as_of_date: formatAsOfDate(itemData.as_of_date),
    is_low_stock_reminder_active: itemData.is_low_stock_reminder_active,

    selling_price: for_sales ? itemData?.selling_price ?? 0 : 0,
    sales_account: for_sales ? itemData?.sales_account ?? null : null,
    sales_account_id: for_sales ? itemData?.sales_account?.id ?? null : null,
    is_sales_inclusive: for_sales ? itemData?.is_sales_inclusive ?? false : false,
    selling_price_with_tax: for_sales ? calculatePriceWithTax(itemData.selling_price, itemData.gst_rate, itemData?.is_sales_inclusive) : 0,

    cost_price: for_purchase ? itemData?.cost_price ?? 0 : 0,
    purchase_account: for_purchase ? itemData?.purchase_account ?? null : null,
    purchase_account_id: for_purchase ? itemData?.purchase_account?.id ?? null : null,
    is_purchase_inclusive: for_purchase ? itemData?.is_purchase_inclusive ?? false : false,
    cost_price_with_tax: for_purchase ? calculatePriceWithTax(itemData.cost_price, itemData.gst_rate, itemData?.is_purchase_inclusive) : 0,
});

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const setItemData = (itemData, setProductService, setAdditionalData) => {
    const metaData = processMetaData(itemData);
    const for_sales = processForSales(itemData);
    const for_purchase = processForPurchase(itemData);

    setAdditionalData(metaData);
    setProductService(updateProductService(itemData, metaData, for_sales, for_purchase));
};

export const fetchItemData = async (itemId, dispatch) => {
    setLoader(dispatch, Actions, true);

    const response = await apiAction({
        method: 'post',
        url: get_item(itemId),
        data: { business_id: getBusinessInfo().id },
    });

    setLoader(dispatch, Actions, false);

    if (response?.success) {
        return response.result;
    } else {
        throw new Error('Failed to fetch item data');
    }
};

export const get_initial_item_data = (compulsory) => ({
    tax: null,
    unit: null,
    name: null,
    tax_id: null,
    unit_id: null,
    description: null,
    hsn_sac_code: null,
    item_type: 'goods',
    for_sales: compulsory ? compulsory === 'sales' : true,
    for_purchase: compulsory ? compulsory === 'product' : true,

    cost_price: 0,
    selling_price: 0,
    is_sales_inclusive: false,
    is_purchase_inclusive: false,
    cost_price_with_tax: 0,
    selling_price_with_tax: 0,

    sales_account_id: null,
    purchase_account_id: null,

    attachment: null,
    sales_account: null,
    purchase_account: null,
    business_id: getBusinessInfo().id,


    status: null,
    gst_rate: null,
    isManageStock: false,
    current_stock: null,
    opening_stock: null,
    low_stock_value: null,
    stock_item_code: null,
    is_low_stock_reminder_active: false,
    as_of_date: moment().format('YYYY-MM-DD'),
});

export const get_tabs = (productService) => {
    const preferences = getItemPreferences();
    const enableInventory = preferences && preferences['enable_inventory'];
    const isGoods = productService.item_type === 'goods';

    const tabs = [
        { name: 'Pricing Details', condition: 'pricing_details' },
        enableInventory && isGoods && { name: 'Stock Details', condition: 'stock_details' },
        isGoods && { name: 'Additional Fields', condition: 'additional_fields' }
    ].filter(Boolean);

    return tabs;
};

export function validateHSNCode(hsn_sac_code) {
    // Check if the HSN/SAC code is provided and has length between 4 and 8
    const isHsnSacCodeValid = !hsn_sac_code || !validator.isLength(String(hsn_sac_code), { min: 4, max: 8 });
    const isHsnSacCodeValid1 = hsn_sac_code && !validator.isLength(String(hsn_sac_code), { min: 4, max: 8 });

    return isBusinessRegistered() ? isHsnSacCodeValid : isHsnSacCodeValid1;
}

export const get_validation_data = (productService, hasAdditionalDataEmptyValue) => ([
    { key: "name", validation: productService.name === null, message: 'Please Enter Name of Product or Service' },
    { key: "unit", validation: productService.unit === null, message: 'Please Select Units' },
    {
        key: "hsn_sac_code",
        validation: validateHSNCode(productService.hsn_sac_code),
        message: 'Please Enter valid HSN/SAC Code with length between 4 and 8 characters'
    },
    {
        key: "low_stock_value",
        validation: productService.is_low_stock_reminder_active && productService.low_stock_value === null,
        message: 'Please Enter low stock reminder value'
    },
    {
        key: "as_of_date",
        validation: productService.opening_stock !== null && !(productService.as_of_date),
        message: 'Please Select as of Date'
    },
    {
        key: "selling_price_with_tax",
        validation: productService.for_sales && !(productService.selling_price_with_tax),
        message: 'Please Enter Selling Price'
    },
    {
        key: "sales_account_id",
        validation: productService.for_sales && !(productService.sales_account_id),
        message: 'Please Select Sales Account'
    },
    {
        key: "cost_price_with_tax",
        validation: productService.for_purchase && !(productService.cost_price_with_tax),
        message: 'Please Enter Cost Price'
    },
    {
        key: "purchase_account_id",
        validation: productService.for_purchase && !(productService.purchase_account_id),
        message: 'Please Select Purchase Account'
    },
    {
        key: "additional_data",
        validation: hasAdditionalDataEmptyValue,
        message: 'Please fill all fields under additional fields tab'
    }
]);


export const ItemCodeInput = ({ value, setValue }) => {
    const dispatch = Actions.getDispatch(React.useContext);
    
    const fetchItemCode = async () => {
        setLoader(dispatch, Actions, true);
        const response = await apiAction({
            url: generate_stock_item_code(),
        });
        if (response?.success) {
            setLoader(dispatch, Actions, false);
            setValue(response?.result?.item_stock_code)
        } else {
            setLoader(dispatch, Actions, false);
            throw new Error('Failed to fetch item data');
        }
    };

    const handleChange = (e) => {
        const newValue = e.target.value;
        if (newValue.length <= 12) {
            setValue(newValue);
        }
    };
  
    return (
        <React.Fragment>
            <Input
                id={'input_stock_item_code'}
                dataTestId={'input_stock_item_code'}
                item={{
                    type: 'number',
                    required: true,
                    fullWidth: true,
                    value: value || '',
                    title: 'Stock / Item Code ',
                    placeholder: 'Enter Stock / Item Code ',
                    onChange: (e) => { handleChange(e) },
                }}
            />
            <CustomTypography
                disable={true}
                text={'Generate Stock Item Code'}
                id={'generate_stock_item_code_btn'}
                dataTestId={'generate_stock_item_code_btn'}
                onClick={() => { if(!value) fetchItemCode() }}
                sx={{
                    mt:1,
                    textAlign: 'end',
                    fontSize: '12px',
                    fontWeight: '700',
                    lineHeight: '18px',
                    fontStyle: 'normal',
                    textTransform: "none",
                    fontFamily: "Noto Sans",
                    cursor:  value ? '':'pointer',
                    color:value?'#BBBCBD':'#2464EB',
                    ":hover": { textDecoration: value ? '': 'underline' }
                }}
            />
        </React.Fragment>
    )
}
