import CustomTitle from "./CustomTitle";
import { HOST } from "../../config/config";
import * as Actions from '../../state/Actions';
import { AttachFile } from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
import CustomInput from "../custom/input/CustomInput";
import { stateChangeManager } from "../../utils/Utils";
import CustomTypography from "../custom/typography/CustomTypography";
import React, { useContext, useEffect, useRef, useState } from "react";
import fileDownload from 'js-file-download';
import Loader from "../custom/loader/Loader";

import {
    Box,
    Grid,
    Modal,
    IconButton,
} from "@mui/material"
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import ZoomInOutlinedIcon from '@mui/icons-material/ZoomInOutlined';
import ZoomOutOutlinedIcon from '@mui/icons-material/ZoomOutOutlined';
import ZoomOutMapOutlinedIcon from '@mui/icons-material/ZoomOutMapOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';


export const InputFile = (props) => {
    const { onFileUpload, multiple = false, attachment, onFileRemove, fileSize = 3 } = props
    const dispatch = Actions.getDispatch(useContext)

    const [file, setFile] = useState(null);
    const [open, setOpen] = useState(false);
    const onClose = () => {
        setOpen(false)
    }
    const inputRef = useRef(null)


    const onFileChange = (e) => {
        if (e.target.files.length > 0) {
            const file = e.target.files[0]
            const extension = file.name.split(".")[file.name.split(".").length - 1]
            if (extension === "gif" || extension === "jpeg" || extension === "jpg" || extension === "jpe" || extension === "png" || extension === "bmp" || extension === "pdf" || extension === "doc" || extension === "docx" || extension === "xls" || extension === "xlsx" || extension === "csv" || extension === "xml") {
                let file_size = (file.size / 1024 / 1024)
                if (file_size > fileSize) {
                    onFileRemove()
                    setFile(null)
                    inputRef.current.value = ''
                    stateChangeManager(dispatch, Actions, true, 'error', "Maximum file size allowed is " + fileSize + "MB. Please try with different file.")
                } else {
                    if (multiple) {
                        onFileUpload(e.target.files)
                        setFile(e.target.files[0])
                    } else {
                        onFileUpload(e.target.files[0])
                        setFile(e.target.files[0])
                    }
                }

            } else {
                stateChangeManager(dispatch, Actions, true, 'error', `Incorrect file type. Please try with different file in [.gif,.jpeg,.jpg,.jpe,.png,.bmp,.pdf,.doc,.docx,.xls, .xlsx,.csv,.xml,] format.`)

            }

        } else {
            setFile(null)
            onFileUpload(null)
        }
    }

    const fileName = (file || attachment) ? file && file.name ? file.name : attachment.split("/")[attachment.split("/").length - 1] : null;
    return (
        <React.Fragment>
            <ImagePreviewModal open={open} onClose={onClose} file={file} attachment={attachment} onFileRemove={onFileRemove} fileName={fileName} />
            <Grid item xs={12} container>
                <Grid item xs={6}>
                    <CustomTypography
                        text={'Attach File'}
                        sx={{
                            mb: 1,
                            color: '#000000',
                            fontSize: '14px',
                            textAlign: 'start',
                            fontWeight: '700',
                            lineHeight: '18px',
                            fontStyle: 'normal',
                            fontFamily: "Noto Sans",
                        }}
                    />
                </Grid>
                <Grid item xs={6} sx={{ textAlign: 'right', }}>
                    {(file || attachment) && <CustomTypography
                        id={props.id ? props.id + '_view' : 'attachment_action_view'}
                        dataTestId={props.dataTestId ? props.dataTestId + '_view' : 'attachment_action_view'}
                        text={'View'}
                        onClick={() => setOpen(true)}
                        sx={{
                            mb: 1,
                            pr: 1,
                            fontSize: '12px',
                            display: 'inline',
                            color: '#2464EB',
                            cursor: 'pointer',
                            display: 'inline',
                            fontWeight: '700',
                            lineHeight: '16px',
                            fontStyle: 'normal',
                            textTransform: "none",
                            fontFamily: "Noto Sans",
                            ":hover": { textDecoration: 'underline' },
                        }}
                    />

                    }
                    {(file || (attachment && typeof attachment !== "object")) && <CustomTypography
                        id={props.id ? props.id + '_remove' : 'attachment_action_remove'}
                        dataTestId={props.dataTestId ? props.dataTestId + '_remove' : 'attachment_action_remove'}
                        text={'Remove'}
                        onClick={() => {
                            onFileRemove()
                            setFile(null)
                            // inputRef.current.value = ''
                        }}
                        sx={{
                            mb: 1,
                            pl: 1,
                            fontSize: '12px',
                            display: 'inline',
                            color: '#2464EB',
                            cursor: 'pointer',
                            display: 'inline',
                            fontWeight: '700',
                            lineHeight: '16px',
                            fontStyle: 'normal',
                            textTransform: "none",
                            fontFamily: "Noto Sans",
                            ":hover": { textDecoration: 'underline' },
                        }}
                    />


                    }

                </Grid>
            </Grid>
            {(file || (attachment && typeof attachment !== "object")) ?
                <Box sx={{ height: 50, border: '1px solid #aea4a4ab', borderRadius: 1 }}>
                    <CustomTypography
                        text={<span><AttachFile sx={{ mb: -1 }} /> <span style={{ color: '#000000' }}>{fileName}</span></span>}
                        sx={{
                            ml: 1,
                            pt: 1.2,
                            color: '#7A7A7A',
                            fontSize: '12px',
                            textAlign: 'start',
                            fontWeight: '700',
                            lineHeight: '18px',
                            fontStyle: 'normal',
                            fontFamily: "Noto Sans",
                        }}
                    />
                </Box>
                :
                <CustomInput
                    id={'file_upload'}
                    dataTestId={'file_upload'}
                    size="medium"
                    type={'file'}
                    fullWidth={true}
                    inputRef={inputRef}
                    onChange={(e) => { onFileChange(e) }}
                    inputProps={{
                        accept: `
                    .gif,
                    .jpeg,
                    .jpg,
                    .jpe,
                    .png,
                    .bmp,
                    .pdf,
                    .doc,
                    .docx,
                    .xls, 
                    .xlsx,
                    .csv,
                    .xml,`, multiple: multiple, style: { color: '#000000', fontWeight: '500 !important', lineHeight: '16px', fontSize: '12px', fontStyle: 'normal', fontFamily: 'Noto Sans', }
                    }}
                />
            }
            <CustomTypography
                text={'Maximum Size: 3 MB'}
                sx={{
                    mt: 1,
                    color: '#292929',
                    fontSize: '10px',
                    textAlign: 'start',
                    fontWeight: '400',
                    lineHeight: '14px',
                    fontStyle: 'normal',
                    fontFamily: "Noto Sans",
                }}
            />

        </React.Fragment>
    )
}


const ImagePreviewModal = (props) => {
    const { open, onClose, file, attachment, fileName, } = props

    const extension = fileName && fileName.split(".")[fileName.split(".").length - 1]
    let url = file ? window.URL.createObjectURL(file) : attachment ? HOST + attachment : null

    let docViewerRef = React.useRef(null);
    // console.log('===>url',url)
    const docs = [
        {
            uri: url,
            fileName: fileName,
            fileType: extension,
        }];

    const downloadFile = (fileUrl, fileName) => {
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleZoom = (type) => {
        
    }


    const CustomHeaderComponent = () => {
        return (
            <React.Fragment>
                <Box sx={{ p: 1, bgcolor: '#2464EB', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box>
                        <CustomTitle title={<span style={{ color: '#FFFF' }}>{fileName}</span>} />
                    </Box>
                    <Box sx={{ textAlign: 'end' }}>
                        <IconButton
                            sx={{ ":hover": { color: '#FFFF' } }}
                            size="small"
                            onClick={onClose}
                        >
                            <ClearIcon />
                        </IconButton>
                    </Box>
                </Box>
                <Box sx={{ p: 1, bgcolor: '#e8f3ff', display: 'flex', justifyContent: 'flex-end' }}>
                    <IconButton
                        size="small"
                        onClick={() => { downloadFile(url, fileName) }}
                        sx={{ mr: 0.5, bgcolor: '#2464EB', ":hover": { bgcolor: '#2464EB' } }}>
                        <CloudDownloadOutlinedIcon sx={{ color: '#000000' }} />
                    </IconButton>

                    {/* <IconButton
                        size="small"
                        onClick={() => { handleZoom('out') }}
                        sx={{ mr: 0.5, bgcolor: '#2464EB', ":hover": { bgcolor: '#2464EB' } }}>
                        <ZoomOutOutlinedIcon sx={{ color: '#000000' }} />
                    </IconButton>
                    <IconButton
                        size="small"
                        onClick={() => { handleZoom('in') }}
                        sx={{ mr: 0.5, bgcolor: '#2464EB', ":hover": { bgcolor: '#2464EB' } }}>
                        <ZoomInOutlinedIcon sx={{ color: '#000000' }} />
                    </IconButton>
                    <IconButton
                        size="small"
                        onClick={() => { handleZoom('reset') }}
                        sx={{ mr: 0.5, bgcolor: '#2464EB', ":hover": { bgcolor: '#2464EB' } }}>
                        <ZoomOutMapOutlinedIcon sx={{ color: '#000000' }} />
                    </IconButton> */}

                </Box>
            </React.Fragment>
        )
    };

    
    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <Box sx={style}>
                <DocViewer
                    ref={docViewerRef}
                    documents={docs}
                    pluginRenderers={DocViewerRenderers}
                    style={{ maxWidth: "100%", height: "calc(100vh - 130px)" }}
                    theme={{
                        primary: "#2464EB",
                        tertiary: "#e8f3ff",
                        secondary: "#ffffff",

                        textPrimary: "#ffffff",
                        textTertiary: "#00000099",
                        textSecondary: "#00000099",
                        disableThemeScrollbar: false,

                    }}
                    config={{

                        header: {
                            disableHeader: false,
                            disableFileName: false,
                            retainURLParams: false,
                            overrideComponent: CustomHeaderComponent,
                        },
                        csvDelimiter: ",",
                        pdfZoom: {
                            defaultZoom: 1.1,
                            zoomJump: 0.2,
                        },
                        pdfVerticalScrollByDefault: true,
                    }}
                />

            </Box>
        </Modal>
    )
}

const style = {
    width: "100vh",
    height: "100vh",
    maxWidth: '90vh',
    maxHeight: "90vh",
    top: '50%',
    left: '50%',
    boxShadow: 24,
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
};
