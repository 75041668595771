import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";

export const MaterialUISwitch = styled((props)=><Switch {...props}/>)(({ theme,...props }) => ({
    width: 40,
    padding: 0,
    height: 21.8,
    borderRadius: 4,
    border:'1px solid #bbbcbd',
    "& .MuiSwitch-switchBase": {
        margin: 1,
        padding:0,
        transform: "translateX(0%)",
        "&.Mui-checked": {
            color: "#fff",
            transform: "translateX(calc(100% - 4px))",
            "& .MuiSwitch-thumb:before": {
                height: "100%",
                width: "100%",
                display: "flex",
                content: "'%'",
                boxShadow:'none',
                alignItems: "center",
                justifyContent: "center",
            },
            "& + .MuiSwitch-track": {
                opacity: 1,
                backgroundColor: '#f1f3f4',
            },
        },
    },
    "& .MuiSwitch-thumb": {
        width: 20,
        height: 18,
        borderRadius: 4,
        boxShadow:'none',
        backgroundColor: '#2464eb',
        "&::before": {
            height: "100%",
            width: "100%",
            display: "flex",
            boxShadow:'none',
            alignItems: "center",
            content: `"${props?.r}"`,
            justifyContent: "center",
        },
    },
    "& .MuiSwitch-track": {
        opacity: 1,
        borderRadius: 4,
        backgroundColor: '#f1f3f4',
    },
}));