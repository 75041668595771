import React from 'react';
import moment from 'moment/moment';
import Status from '../../../../common/Status';
import HTMLViewer from '../../../../common/HTMLViewer';
import * as Actions from '../../../../../state/Actions';
import CustomTitle from '../../../../common/CustomTitle';
import { useNavigate, useLocation } from 'react-router-dom';
import CustomTabs from '../../../../custom/tabs/CustomTabs';
import { routesName } from '../../../../../config/routesName';
import { CommonWebSocket } from '../../../../../api/webSocket';
import DeliveryChallanAlertBox from './DeliveryChallanAlertBox';
import CustomDialog from '../../../../custom/dialog/CustomDialog';
import { getBusinessInfo } from '../../../../../config/cookiesInfo';
import CurrencyFormatter from '../../../../common/CurrencyFormatter';
import AuditTrailHistory from '../../../../common/AuditTrailHistory';
import ViewPageActionButtons from '../../../../common/ViewPageActionButtons';
import CustomTypography from '../../../../custom/typography/CustomTypography';
import { eventsNames,AnalyticsEvent } from '../../../../../firebase/firebaseAnalytics';

import {
    getQueryParams,
    breadCrumbsStateChangeManager,
} from '../../../../../utils/Utils';

import {
    CustomContainer,
    CustomTitleContainer,
} from '../../../../custom/container/CustomContainer';

import {
    retrieve_invoice,
    export_delivery_challan,
    update_delivery_challan,
    delete_delivery_challan,
    retrieve_delivery_challan,
} from '../../../../../api/urls';

import {
    setLoader,
    getDateFormat,
    stateChangeManager,
} from '../../../../../utils/Utils';

import {
    apiAction,
    apiBlobResponse,
    apiHandleDownload,
} from '../../../../../api/api';

import {
    CustomTable,
    CustomTableRow,
    CustomTableBody,
    CustomTableHead,
    CustomTableHeadCell,
    CustomTableBodyCell,
    CustomTableContainer,
} from '../../../../custom/table/CustomTable';

import {
    Box,
    Grid,
} from '@mui/material';


const DeliveryChallanViewpage = () => {

    let location = useLocation();
    const navigate = useNavigate();
    const params = getQueryParams(location.search);
    const dispatch = Actions.getDispatch(React.useContext);

    const [tab, setTab] = React.useState(0);
    const [open, setOpen] = React.useState(false);

    const [previewData, setPreviewData] = React.useState();
    const [basicInformation, setBasicInformation] = React.useState();

    const [state, setState] = React.useState({
        id: '',
        title: '',
        open: false,
        condition: '',
        maxWidth: 'lg',
        fullWidth: true,
    })

    const webSocketResponse = CommonWebSocket();
    React.useEffect(() => {
        if (webSocketResponse) {
            let webSocketData = JSON.parse(webSocketResponse.data)
            if (webSocketData.document_type === "delivery_challan") {

                if (webSocketData.event === "delete_document" && basicInformation.id === webSocketData.object_id) {
                    if (webSocketData.event_success) {
                        stateChangeManager(dispatch, Actions, true, "success", `Delivery Challan ${webSocketData.object_number} deleted successfully`);
                        navigate(routesName.invoicingDeliveryChallan.path);
                    } else {
                        stateChangeManager(dispatch, Actions, true, "error", `Failed to delete Delivery Challan ${webSocketData.object_number}`);
                    }
                }

                if (webSocketData.event === "update_document" && basicInformation.id === webSocketData.object_id) {
                    if (webSocketData.event_success) {
                        stateChangeManager(dispatch, Actions, true, "success", `Delivery Challan ${webSocketData.object_number} updated successfully`);
                        retrieveApiResults();
                    } else {
                        stateChangeManager(dispatch, Actions, true, "error", `Failed to update Delivery Challan ${webSocketData.object_number}`);
                    }
                }
            }
        }
    }, [webSocketResponse])

    const stateChangeHandler = (title, condition, maxWidth, url, deleteMessage) => {
        setState({
            url: url,
            open: true,
            title: title,
            fullWidth: true,
            maxWidth: maxWidth,
            condition: condition,
            deleteMessage: deleteMessage,
            navigate: () => navigate(routesName.invoicingDeliveryChallan.path),
        })
    }

    const retrieveApiResults = async () => {
        setLoader(dispatch, Actions, true);
        let res = await apiAction({
            method: 'post',
            url: retrieve_delivery_challan(params.id),
            data: { business_id: getBusinessInfo().id },
        })
        if (res?.success) {
            setBasicInformation(res?.result);
            breadCrumbsStateChangeManager(dispatch, res?.result.delivery_challan_number);
            let previewRes = await apiAction({
                method: 'post',
                url: export_delivery_challan(params.id),
                data: { ...res?.result, place_of_supply_id: res?.result.place_of_supply ? res?.result.place_of_supply.id : null, terms_and_conditions_id: res?.result.terms_and_conditions, export_type: 'html' },
            })
            if (previewRes?.success) {
                setLoader(dispatch, Actions, false);
                setPreviewData(previewRes.result)
            } else {
                setLoader(dispatch, Actions, false);
            }
        }
    }

    React.useEffect(() => {
        if (params.id) {
            retrieveApiResults()
        }
        // eslint-disable-next-line
    }, []);

    const approve = async (data, is_draft) => {
        if (data) {
            const approveDeliveryChallanRes = await apiAction({ url: update_delivery_challan(data.id), method: 'POST', data: { ...is_draft, customer_id: data.result.customer_id, place_of_supply_id: data.result.place_of_supply.id, business_id: getBusinessInfo().id } })
            if (approveDeliveryChallanRes.success) {
                retrieveApiResults();
                stateChangeManager(dispatch, Actions, true, 'success', approveDeliveryChallanRes.status);
            } else {
                retrieveApiResults();
                stateChangeManager(dispatch, Actions, true, 'error', approveDeliveryChallanRes.status);
            }
        }
    }

    const markAsDelivered = async (data, is_delivered) => {
        if (data) {
            const res = await apiAction({ url: update_delivery_challan(data.id), method: 'POST', data: { ...is_delivered, customer_id: data.customer_id, place_of_supply_id: data.place_of_supply.id, business_id: getBusinessInfo().id } })
            if (res?.success) {
                retrieveApiResults();
                stateChangeManager(dispatch, Actions, true, 'success', res?.status);
            } else {
                retrieveApiResults();
                stateChangeManager(dispatch, Actions, true, 'error', res?.status);
            }
        }
    }

    const markAsReturned = async (data, is_returned) => {
        if (data) {
            const res = await apiAction({ url: update_delivery_challan(data.id), method: 'POST', data: { ...is_returned, customer_id: data.customer_id, place_of_supply_id: data.place_of_supply.id, business_id: getBusinessInfo().id } })
            if (res?.success) {
                retrieveApiResults();
                stateChangeManager(dispatch, Actions, true, 'success', res?.status);
            } else {
                retrieveApiResults();
                stateChangeManager(dispatch, Actions, true, 'error', res?.status);
            }
        }
    }

    const download = async () => {
        setLoader(dispatch, Actions, true);
        if (basicInformation) {
            apiHandleDownload({
                url: export_delivery_challan(), data: { ...basicInformation, terms_and_conditions_id: basicInformation.terms_and_conditions, export_type: "pdf", copy_type: 'original' }, filename: basicInformation.delivery_challan_number, onSuccess: () => {
                    setLoader(dispatch, Actions, false)
                }
            })
        }
    }

    const print = async (copy_type) => {
        setLoader(dispatch, Actions, true);
        if (basicInformation) {
            apiBlobResponse({
                url: export_delivery_challan(), data: { ...basicInformation, terms_and_conditions_id: basicInformation.terms_and_conditions, export_type: "pdf", copy_type: copy_type }, onSuccess: () => {
                    setLoader(dispatch, Actions, false)
                }
            })
        }
    }


    const onSelectAction = (action, item) => {
        setLoader(dispatch, Actions, true);
        if (action === "create") {
            setLoader(dispatch, Actions, false);
            navigate(routesName.invoicingDeliveryChallanAdd.path)
            AnalyticsEvent(eventsNames.categories.DELIVERY_CHALLANS,{action:eventsNames.actions.NEW})
        }
        if (action === "edit") {
            navigate(routesName.invoicingDeliveryChallanAdd.path + "?id=" + item.id)
        }
        if (action === "approve") {
            approve(item, { is_draft: false, })
            AnalyticsEvent(eventsNames.categories.DELIVERY_CHALLANS,{action:eventsNames.actions.APPROVE})
        }
        if (action === "mark_as_returned") {
            markAsReturned(item, { is_returned: true, })
            AnalyticsEvent(eventsNames.categories.DELIVERY_CHALLANS,{action:eventsNames.actions.MARK_AS_RETURNED})
        }
        if (action === "mark_as_delivered") {
            markAsDelivered(item, { is_delivered: true, })
            AnalyticsEvent(eventsNames.categories.DELIVERY_CHALLANS,{action:eventsNames.actions.MARK_AS_DELIVERED})
        }
        if (action === "mark_as_open") {
            markAsDelivered(item, { is_delivered: false, })
            AnalyticsEvent(eventsNames.categories.DELIVERY_CHALLANS,{action:eventsNames.actions.REVERT_TO_OPEN})
        }
        if (action === "print") {
            setOpen(true);
            setLoader(dispatch, Actions, false);
            AnalyticsEvent(eventsNames.categories.DELIVERY_CHALLANS,{action:eventsNames.actions.PRINT})
        }
        if (action === "download") {
            download(item)
            AnalyticsEvent(eventsNames.categories.DELIVERY_CHALLANS,{action:eventsNames.actions.DOWNLOAD})
        }
        if (action === "convert") {
            navigate(routesName.invoicingSalesInvoiceAdd.path + "?challan_id=" + item.id)
            AnalyticsEvent(eventsNames.categories.DELIVERY_CHALLANS,{action:eventsNames.actions.deliveryChallan.action.CONVERT_INVOICE})
        }
    }

    const onClickActionText = (status, item) => {
        if (status === 'draft') {
            onSelectAction("approve", item)
        } else if (status === 'open') {
            onSelectAction("mark_as_delivered", item)
        } else if (status === 'partially_invoiced') {
            onSelectAction("mark_as_returned", item)
        } else if (status === 'delivered') {
            onSelectAction("convert", item)
        } else if (status === 'closed' || status === 'returned') {
            onSelectAction("create", item)
        }
    }

    const toActionText = (status) => {
        let text
        if (status === 'draft') {
            text = 'Approve'
        } else if (status === 'open') {
            text = 'Mark as Delivered'
        } else if (status === 'delivered') {
            text = 'Convert to Invoice'
        } else if (status === 'partially_invoiced') {
            text = 'Record Partially Returned'
        } else if (status === 'closed' || status === 'returned') {
            text = 'Create New Delivery Challan'
        }
        return text
    }


    const tabs = [
        { name: 'View', condition: 'preview' },
        basicInformation && basicInformation.is_converted_to_invoice && { name: 'Invoices', condition: 'invoice' },
    ]
    return (
        previewData && basicInformation &&
        <div>
            <CustomDialog
                state={state}
                setState={setState}
            />
            <DeliveryChallanAlertBox
                open={open}
                setOpen={() => { setOpen(false) }}
                onSaveAction={(type) => { print(type) }}
            />
            <CustomTitleContainer>
                <Grid container spacing={0} style={{
                    alignItems: 'center',
                    justifyItems: 'center',
                    alignContent: 'space-evenly',
                    justifyContent: 'space-evenly',
                }}>
                    <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'start', alignItems: 'center' }}>
                        <CustomTitle title={basicInformation.delivery_challan_number} />
                        <Status status={basicInformation.status} sx={{ ml: 1, }} />

                    </Grid>
                    <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'end', }}>
                        <ViewPageActionButtons
                            isEditable={basicInformation.status === 'open'}
                            edit={() => onSelectAction("edit", basicInformation)}
                            print={() => onSelectAction("print", basicInformation)}
                            download={() => onSelectAction("download", basicInformation)}

                            actionsList={
                                [
                                    basicInformation.status === 'open' && { name: 'Mark as Returned', onClick: () => { onSelectAction("mark_as_returned", basicInformation) } },
                                    basicInformation.status === 'delivered' && { name: 'Revert to Open', onClick: () => { onSelectAction("mark_as_open", basicInformation) } },
                                    { name: 'Delete', showDivider: true, onClick: () => { stateChangeHandler('Delete Delivery Challan', 'delete', 'sm', delete_delivery_challan(basicInformation.id), `The delivery challan will be deleted and can not be retrieved later.\n Are you sure about deleting it?`);AnalyticsEvent(eventsNames.categories.DELIVERY_CHALLANS,{action:eventsNames.actions.DELETE}) } }
                                ]
                            }
                        />
                        <AuditTrailHistory
                            AnalyticsEvent={()=>{AnalyticsEvent(eventsNames.categories.DELIVERY_CHALLANS,{action:eventsNames.actions.HISTORY})}}
                            data={{ object_id: basicInformation.id, object_type: 'delivery_challan' }}
                        />
                    </Grid>
                </Grid>
            </CustomTitleContainer>

            <CustomContainer maxWidth={"400"} sx={{ height: 'calc(100vh - 134px)' }}>
                <Box sx={{
                    p: 2,
                    border: '1px solid #FFF0C0', backgroundColor: "#FFF7DC"
                }}>
                    <Grid container sx={{ mb: 1 }}>
                        <Grid item xs={12} sm={6} sx={{}}>
                            <CustomTypography
                                text={<span>Action:</span>}
                                sx={{
                                    mb: 2,
                                    color: '#7A7A7A',
                                    fontSize: '14px',
                                    fontWeight: '600',
                                    lineHeight: '19px',
                                    fontStyle: 'normal',
                                    textTransform: 'none',
                                    fontFamily: "Noto Sans",
                                }}
                            />
                            <Box sx={{ display: 'flex' }}>
                                <CustomTypography
                                    id={'action_status_btn'}
                                    dataTestId={'action_status_btn'}
                                    text={toActionText(basicInformation.status)}
                                    sx={{
                                        color: '#2464EB',
                                        fontSize: '14px',
                                        cursor: 'pointer',
                                        lineHeight: '0px',
                                        display: 'inline',
                                        fontWeight: '700',
                                        fontStyle: 'normal',
                                        textAlign: 'center',
                                        fontFamily: "Noto Sans",
                                        ":hover": { textDecoration: 'underline' }
                                    }}
                                    onClick={() => onClickActionText(basicInformation.status, basicInformation)}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

                <Box sx={{}}>
                    <CustomTabs
                        tabs={tabs}
                        value={tab}
                        onChange={(event, tab) => { setTab(tab) }}
                    />
                    {tabs[tab].condition === 'preview' && <PreviewScreen previewData={previewData} basicInformation={basicInformation} />}
                    {tabs[tab].condition === 'invoice' && <Invoices previewData={previewData} basicInformation={basicInformation} dispatch={dispatch} />}
                </Box>

            </CustomContainer>
        </div>
    )
}

export default DeliveryChallanViewpage;

const PreviewScreen = (props) => {
    return (
        <Box sx={{ pt: 2 }}>
            <HTMLViewer html_string={props.previewData.html_string} />
        </Box>
    )
}

const Invoices = (props) => {
    const { basicInformation, dispatch } = props;
    const [results, setResults] = React.useState([]);

    const [page, setPage] = React.useState(1);
    // const [pagination, setPagination] = React.useState({
    //     next: undefined,
    //     count: undefined,
    //     previous: undefined,
    //     number_of_pages: undefined,
    // })

    React.useEffect(() => {
        const retrieveCreditNote = async () => {
            setLoader(dispatch, Actions, true);
            let res = await apiAction({
                method: 'post',
                // url: get_list_of_invoice(page),
                // data: { business_id: getBusinessInfo().id, delivery_challan_id: basicInformation.id },
                url: retrieve_invoice(basicInformation.invoice_id),
                data: { business_id: getBusinessInfo().id, },
            })
            if (res?.success) {
                // setPagination({
                //     ...pagination,
                //     next: res?.result.next,
                //     count: res?.result.count,
                //     previous: res?.result.previous,
                //     number_of_pages: res?.result.number_of_pages,
                // })
                setResults([res?.result])
                setLoader(dispatch, Actions, false);
            } else {
                setLoader(dispatch, Actions, false);
            }
        }
        if (basicInformation) {
            retrieveCreditNote()
        }
        // eslint-disable-next-line
    }, [basicInformation,]);
    return (
        <>
            <CustomTableContainer>
                <CustomTable sx={{ mt: 2, }}>
                    <CustomTableHead>
                        <CustomTableRow>
                            <CustomTableHeadCell style={{ width: 100 }} align='center' ><span >Status</span></CustomTableHeadCell>
                            <CustomTableHeadCell ><span >Date</span></CustomTableHeadCell>
                            <CustomTableHeadCell ><span >Number</span></CustomTableHeadCell>
                            <CustomTableHeadCell ><span >Customer</span></CustomTableHeadCell>
                            <CustomTableHeadCell align='right' ><span >Total</span></CustomTableHeadCell>
                            <CustomTableHeadCell align='right' ><span >Amount Due</span></CustomTableHeadCell>
                        </CustomTableRow>
                    </CustomTableHead>
                    <CustomTableBody>
                        {results.map((item, index) => {
                            return (
                                <CustomTableRow key={index}>
                                    <CustomTableBodyCell sx={{}} align='center'><Status status={item.status} /></CustomTableBodyCell>
                                    <CustomTableBodyCell sx={{}}  ><span>{moment(item.invoice_date).format(getDateFormat())}</span></CustomTableBodyCell>
                                    <CustomTableBodyCell sx={{}}  ><span>{item.invoice_number}</span></CustomTableBodyCell>
                                    <CustomTableBodyCell sx={{}}  ><span>{item.customer_display_name}</span></CustomTableBodyCell>
                                    <CustomTableBodyCell sx={{}} align='right'><span>{<CurrencyFormatter amount={item.total_amount} currency={item.currency_code} />}</span></CustomTableBodyCell>
                                    <CustomTableBodyCell sx={{}} align='right'><span>{<CurrencyFormatter amount={item.due_amount} currency={item.currency_code} />}</span></CustomTableBodyCell>
                                </CustomTableRow>
                            )
                        })}
                    </CustomTableBody>
                </CustomTable>
            </CustomTableContainer>
            {/* <CustomPagination
                page={page}
                count={pagination.number_of_pages}
                onChange={(event, newValue) => setPage(newValue)}
            /> */}
        </>
    )
}
