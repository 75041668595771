import React from 'react'
import { useNavigate } from 'react-router-dom';
import { apiAction } from '../../../../api/api';
import * as Actions from '../../../../state/Actions';
import Create from '../../accounting/journal/common/Create';
import { get_transaction_detail } from '../../../../api/urls';
import { getBusinessInfo } from '../../../../config/cookiesInfo';

const SourceTransactionJournalEntry = (props) => {
    const {mark, id, onClose,onAddTransactionAction } = props;

    let navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);
    
    React.useEffect(() => {
        if (id) {   
            getApiResults(id)
        }
    }, [id])

    const [results, setResults] = React.useState()
    const getApiResults = async (transaction_id) => {
        let res = await apiAction({
            method: 'post',
            navigate:navigate,
            dispatch:dispatch,
            url: get_transaction_detail(),
            data: { transaction_id: transaction_id, business_id: getBusinessInfo().id }
        })
        if (res?.success) {
            setResults(res?.result);
        } else {

        }
    }

    const transactions = [
        {
            account: true,
            transaction_type: "DEBIT",
            currency_id: getBusinessInfo().currency.id,
            currency: getBusinessInfo().currency.currency_code,
            amount: results&&results.debit_amount ? results.debit_amount :results&& results.credit_amount,
        },
        {
            account: true,
            transaction_type: "CREDIT",
            currency_id: getBusinessInfo().currency.id,
            currency: getBusinessInfo().currency.currency_code,
            amount: results&&results.credit_amount ? results.credit_amount :results&& results.debit_amount,
        }
    ]

    return (
        <div>
            <Create
                mark={mark}
                onClose={onClose}
                transaction_id={id}
                currencyDisabled={true}
                assignJournalEntry={true}
                transactions={transactions}
                onAddTransactionAction={onAddTransactionAction}
                updateData={{
                    source:'statement',
                    currency:getBusinessInfo().currency,
                    date: results&&results.transaction_date,
                    description: results&&results.description,
                    note: results&&results.reference_number === 'None'?'':results&&results.reference_number,
                    amount: results&&results.debit_amount ? results.debit_amount : results&&results.credit_amount,
                }}
            />
        </div>
    )
}

export default SourceTransactionJournalEntry