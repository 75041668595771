import React from 'react'
import { isEqual } from '../../pages/home/insight/common/Helper';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
    Tabs,
    Tab,
    styled,
} from '@mui/material'

const DraggableTabs = (props) => {
    const { id, dataTestId, tabs, setTabs, value, onChange, sx, variant = "scrollable" } = props

    const onDragEnd = (result) => {
        if (!result.destination) return;

        // Reorder the tabs array based on the drag and drop result
        const reorderedTabs = Array.from(tabs);
        const [movedTab] = reorderedTabs.splice(result.source.index, 1);
        reorderedTabs.splice(result.destination.index, 0, movedTab);

        // Check if the lists are the same or different
        const isListChanged = !isEqual(tabs, reorderedTabs);
        console.log('Is List Changed:', isListChanged);
        if (isListChanged) {
            setTabs(reorderedTabs);
        }

    };
    const onTabChange = (selectedTab) => {
        onChange(selectedTab)
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="horizontal_list" direction="horizontal">
                {(provided, snapshot) => (
                    <AntTabs
                        id={id}
                        value={snapshot.isDraggingOver ? false : value}
                        sx={{
                            ...sx,
                            transition: 'background-color 0.2s ease',
                            backgroundColor: snapshot.isDraggingOver ? '#ffebe6' : 'transparent',
                            '& button': {
                                minWidth: snapshot.isDraggingOver ? 10 : '',
                                border: snapshot.isDraggingOver ? '2px solid #858e9a' : '',
                            }
                        }}
                        variant={variant}
                        scrollButtons={false}
                        dataTestId={dataTestId}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                    >
                        {tabs.map((item, index) => (
                            item &&
                            <Draggable
                                key={item.id}
                                index={index}
                                draggableId={item.id.toString()}>
                                {(provided, snapshot) => (
                                    <AntTab
                                        key={index}
                                        id={id + index}
                                        isselected={value === index}
                                        dataTestId={dataTestId + index}
                                        onClick={() => onTabChange(index)}
                                        sx={{
                                            maxWidth: `${(item.name ? item.name : item).length}` + `em`,
                                        }}
                                        label={
                                            <span
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                {item.name ? item.name : item}
                                            </span>

                                        }
                                    />
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </AntTabs>
                )}
            </Droppable>
        </DragDropContext>
    )
}

export default DraggableTabs

const AntTabs = styled(Tabs)({
    fontSize: '14px',
    overflowX: 'scroll',
    borderBottom: '1px solid #e8e8e8',
    '& .MuiTabs-indicator': {
        height: '5px',
        borderRadius: '6px',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#2464EB',
    },


});

const AntTab = styled((props) => <Tab disableRipple {...props} isselected={props.isselected.toString()} />)(({ theme, isselected }) => ({
    minWidth: 0,
    display: 'inline',
    whiteSpace: 'nowrap',
    textTransform: 'none',
    [theme.breakpoints.up('sm')]: {
        minWidth: 0,
    },
    color: '#141414',
    fontSize: '14px',
    opacity: isselected ? 1 : 0.8,
    marginRight: theme.spacing(1),
    fontWeight: isselected ? 700 : 400,
    fontFamily: [
        'Noto Sans',
    ].join(','),
    '&:hover': isselected ? {} : {
        opacity: 1,
        color: '#2464EB',
        fontSize: '14px',
    },
    '&.Mui-selected': {
        opacity: 1,
        color: '#141414',
        fontWeight: 700,
        fontSize: '14px',
    },
    '&.Mui-focusVisible': {
        backgroundColor: '#d1eaff',
    },

}));







