import React from 'react';
import PropTypes from 'prop-types';
import CustomButton from '../../../custom/button/CustomButton';
import CustomTypography from '../../../custom/typography/CustomTypography';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const CommonAlertBox = (props) => {
    const { open, setOpen, message, title, onSaveAction, cancelLabelText, saveButtonLabel } = props
    const handleClicked = () => {
        onSaveAction();
        setOpen(false);
    };
    return (
        <div>
            <BootstrapDialog
                open={open}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={() => setOpen(false)}>
                    <CustomTypography
                        text={title}
                        sx={{
                            color: '#000000',
                            fontSize: '16px',
                            fontWeight: '700',
                            lineHeight: '18px',
                            fontStyle: 'normal',
                            fontFamily: "Noto Sans",
                        }}
                    />
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <CustomTypography
                        text={message}
                        sx={{
                            color: '#000000',
                            fontSize: '14px',
                            fontWeight: '700',
                            lineHeight: '18px',
                            fontStyle: 'normal',
                            fontFamily: "Noto Sans",
                        }} />


                    <div style={{ textAlign: 'center', marginTop: '24px' }}>
                        <CustomButton
                            btnLabel= {cancelLabelText ? cancelLabelText :  'Cancel' }
                            variant='outlined'
                            onClick={() => setOpen(false)}
                            sx={{ textTransform: 'none', mr: 2, pl: 2, pr: 2, '&:hover': { backgroundColor: '#e8f3ff' } }}
                        />
                        <CustomButton
                            variant='contained'
                            btnLabel= {saveButtonLabel ? saveButtonLabel : 'Save to continue' }
                            onClick={() => handleClicked()}
                            sx={{ textTransform: 'none', mr: 2, pl: 2, pr: 2, }}
                        />
                    </div>
                    <DialogActions>

                    </DialogActions>
                </DialogContent>
            </BootstrapDialog>
        </div>
    )
}

export default CommonAlertBox;


const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));