import { isNumeric } from '../../../../utils/Utils';
import { opening_balance_adjustments } from '../../../../utils/Constant';



export const groupByAutoForSetup = (data, key) => {
    let unique_keys = new Set(data.map(function (element) {
        return element[key];
    }));
    let result = [];
    let migration_transactions = []
    for (const element of unique_keys) {
        let filtered_result = data.filter((value) => {
            value['debitVisibility'] = 'visible';
            value['creditVisibility'] = 'visible';
            value['debit'] = value.adjusted_transaction_type === "DEBIT" ? value.adjusted_amount ? value.adjusted_amount : '' : '';
            value['credit'] = value.adjusted_transaction_type === "CREDIT" ? value.adjusted_amount ? value.adjusted_amount : '' : '';
            return value[key] === element;
        });
        let total_debit_amount = 0;
        let total_credit_amount = 0;
        filtered_result.forEach(x => {
            total_debit_amount += x['debit'];
            total_credit_amount += x['credit'];
        });

        filtered_result.map((item) => {
            if (item.account_name === 'Account Receivable') {
                migration_transactions.push(item)
            } if (item.account_name === 'Account Payable') {
                migration_transactions.push(item)
            } if (item.account_name === opening_balance_adjustments) {
                migration_transactions.push(item)
            }
        })
        result.push({ "account_type": element, 'total_debit_amount': isNumeric(total_debit_amount) ? Number(total_debit_amount) : 0, 'total_credit_amount': isNumeric(total_credit_amount) ? Number(total_credit_amount) : 0, "transactions": filtered_result, 'migration_transactions': migration_transactions });
    }
    return result
}

export const groupByAutoForCreate = (data, key) => {
    let opening_balance_adjustments_Account = data.find((item) => item.account_name === opening_balance_adjustments)
    let unique_keys = new Set(data.map((element) => {
        return element.adjusted_amount ? element[key] ? element[key] : element.account_name : null;
    }));

    let result = [];
    for (const element of unique_keys) {
        if (element) {
            let filtered_result = data.filter((value) => {
                value['debit'] = value.adjusted_transaction_type === "DEBIT" ? value.adjusted_amount ? value.adjusted_amount : 0 : 0;
                value['credit'] = value.adjusted_transaction_type === "CREDIT" ? value.adjusted_amount ? value.adjusted_amount : 0 : 0;
                return value.adjusted_amount ? value[key] ? value[key] === element : value.account_name === element : null;
            });
            let total_debit_amount = 0;
            let total_credit_amount = 0;
            filtered_result.forEach(x => {
                total_debit_amount += x['debit'];
                total_credit_amount += x['credit'];
            });
            result.push({ "account_type": element, "transactions": [...filtered_result, opening_balance_adjustments_Account], 'total_debit_amount': isNumeric(total_debit_amount) ? Number(total_debit_amount) : 0, 'total_credit_amount': isNumeric(total_credit_amount) ? Number(total_credit_amount) : 0, });
        }
    }
    return result
}

export const groupByAutoForView = (data, key) => {
    // console.log('data', data)
    let unique_keys = new Set(data.map((element) => {
        return element.adjusted_amount ? element[key] ? element[key] : element.account_name : null;
    }));

    let result = [];
    for (const element of unique_keys) {
        if (element) {
            let filtered_result = data.filter((value) => {
                value['debit'] = value.adjusted_transaction_type === "DEBIT" ? value.adjusted_amount ? value.adjusted_amount : 0 : 0;
                value['credit'] = value.adjusted_transaction_type === "CREDIT" ? value.adjusted_amount ? value.adjusted_amount : 0 : 0;
                return value.adjusted_amount ? value[key] ? value[key] === element : value.account_name === element : null;
            });
            let total_debit_amount = 0;
            let total_credit_amount = 0;
            filtered_result.forEach(x => {
                total_debit_amount += x['debit'];
                total_credit_amount += x['credit'];
            });
            result.push({ "account_type": element, "transactions": [...filtered_result], 'total_debit_amount': isNumeric(total_debit_amount) ? Number(total_debit_amount) : 0, 'total_credit_amount': isNumeric(total_credit_amount) ? Number(total_credit_amount) : 0, });
        }
    }
    return result
}
