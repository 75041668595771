import React from 'react';
import moment from 'moment';
import { apiAction } from "../../../../api/api";
import ReportsTitle from '../common/ReportsTitle';
import DatePicker from '../../../common/DatePicker';
import * as Actions from '../../../../state/Actions';
import { getDateFormat } from '../../../../utils/Utils';
import ReportsSettingButtons from '../ReportsSettingButtons';
import { getBusinessInfo } from '../../../../config/cookiesInfo';
import CurrencyFormatter from '../../../common/CurrencyFormatter';
import { NoDataComponent } from '../../../common/NoDataComponent';
import { customer_vendor_balances_report } from '../../../../api/urls';
import CustomPagination from '../../../custom/pagination/CustomPagination';
import { CustomContainer } from '../../../custom/container/CustomContainer';

import {
    setLoader,
} from '../../../../utils/Utils';

import {
    Box,
    Grid,
} from '@mui/material';

import {
    CustomTable,
    CustomTableRow,
    CustomTableBody,
    CustomTableHead,
    CustomTableHeadCell,
    CustomTableBodyCell,
    CustomTableContainer,
} from '../../../custom/table/CustomTable';
import { VendorViewLink } from '../../invoicing/common/CommonLinks';

const VendorBalancesReports = () => {
    const dispatch = Actions.getDispatch(React.useContext);

    const [page, setPage] = React.useState(1);
    const [results, setResults] = React.useState();
    const [date, setDate] = React.useState(moment());

    const [pagination, setPagination] = React.useState({
        next: undefined,
        count: undefined,
        previous: undefined,
        number_of_pages: undefined,
    });

    React.useEffect(() => {
        if (date) {
            getApiResults(date)
        }
        // eslint-disable-next-line
    }, [date, page]);

    const getApiResults = async (date) => {
        setLoader(dispatch, Actions, true);
        let res = await apiAction({
            method: 'post',
            url: customer_vendor_balances_report(page),
            data: {
                role: 'vendor',
                business_id: getBusinessInfo().id,
                as_on_date: moment(date).format('YYYY-MM-DD'),
            },
        })

        if (res?.success) {
            let result = res?.result.result.paginated_result
            setPagination({
                ...pagination,
                next: result.next,
                count: result.count,
                previous: result.previous,
                number_of_pages: result.number_of_pages,
            })

            setLoader(dispatch, Actions, false);
            setResults({ ...res?.result.result, balances_result: result.result });
        } else {
            setLoader(dispatch, Actions, false);
        }
    }

    return (
        results &&
        <div>
            <ReportsSettingButtons

                scheduleData={{
                    report_name: 'customer_vendor_balances',
                    report_title: 'Vendor Balances',
                    additional_data: { role: 'vendor', }
                }}
                exportData={{ url: customer_vendor_balances_report(), data: { as_on_date: moment(date).format('YYYY-MM-DD'), role: 'vendor', } }}
            />
            <CustomContainer maxWidth={"400"} sx={{}}>
                <div style={{ paddingLeft: '16px', paddingRight: '16px' }}>
                    <Grid container spacing={0} sx={{ pb: 2 }} style={{
                        alignItems: 'center',
                        justifyItems: 'center',
                        alignContent: 'space-evenly',
                        justifyContent: 'space-evenly',
                    }}>
                        <Grid item xs={12} sm={4}>
                            <ReportsTitle
                                dateText={'As on '}
                                title={'Vendor Balances'}
                                date={moment(date).format(getDateFormat())}
                            />
                        </Grid>
                        <Grid container spacing={2} item xs={12} sm={8} sx={{ justifyContent: 'end', }}>
                            <Grid item xs={12} sm={4}>
                                <DatePicker
                                    id={'as_on_date_select'}
                                    dataTestId={'as_on_date_select'}

                                    date={date}
                                    setDate={(date) => {
                                        setPage(1);
                                        setDate(date);
                                    }
                                    }
                                    title='As on Date'
                                    disabledCloseIcon={true}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <CustomTableContainer>
                    <CustomTable>
                        <Header />
                        {results.balances_result.length ?
                            <Body data={results.balances_result} total={results.total_balance} is_last_page={pagination.next === null} />
                            : null
                        }
                    </CustomTable>
                    <Box sx={{ p: !results.balances_result.length ? 2 : 0 }} >
                        <NoDataComponent left='0%' top='0%' position={'relative'} data={results.balances_result} />
                    </Box>
                </CustomTableContainer>
            </CustomContainer>
            {
                results.balances_result.length ?
                    <CustomPagination
                        page={page}
                        count={pagination.number_of_pages}
                        onChange={(event, newValue) => setPage(newValue)}
                    />
                    : null
            }
        </div>
    )
}

export default VendorBalancesReports;

const Header = () => {
    return (
        <CustomTableHead>
            <CustomTableRow >
                <CustomTableHeadCell><span>Vendor Name</span></CustomTableHeadCell>
                <CustomTableHeadCell align='right'><span>Bill Balance </span></CustomTableHeadCell>
                <CustomTableHeadCell align='right'><span>Excess Payment</span></CustomTableHeadCell>
                <CustomTableHeadCell align='right'><span>Balance</span></CustomTableHeadCell>
            </CustomTableRow>
        </CustomTableHead>
    )
}

const Body = (props) => {
    const { data, total, is_last_page } = props;

    return (
        <CustomTableBody>
            {data.map((item, index) => {
                return (
                    <CustomTableRow key={index}>
                        {/* <CustomTableBodyCell><span>{item.display_name_}</span></CustomTableBodyCell> */}
                        <CustomTableBodyCell><VendorViewLink id={item.id_} title={item.display_name_} /></CustomTableBodyCell>
                        <CustomTableBodyCell align='right'><CurrencyFormatter amount={item.invoice_or_bill_balance_fcy_} currency={null} /></CustomTableBodyCell>
                        <CustomTableBodyCell align='right'><CurrencyFormatter amount={item.available_credit_debit_fcy_} currency={null} /></CustomTableBodyCell>
                        <CustomTableBodyCell align='right'><CurrencyFormatter amount={item.balance_fcy_} currency={null} /></CustomTableBodyCell>
                    </CustomTableRow>
                )
            })}

            {
                is_last_page ?
                    <CustomTableRow sx={{
                        pb: 1,
                        pt: 2,
                        background: '#FFF7DC',
                        borderTop: '1px solid #FFF0C0',
                        borderBottom: '1px solid #FFF0C0'
                    }}>
                        <CustomTableBodyCell><span>Total</span></CustomTableBodyCell>
                        <CustomTableBodyCell align='right'><span><CurrencyFormatter amount={total.total_invoice_or_bill_balance} currency={null} /></span></CustomTableBodyCell>
                        <CustomTableBodyCell align='right'><span><CurrencyFormatter amount={total.total_available_credit_debit} currency={null} /></span></CustomTableBodyCell>
                        <CustomTableBodyCell align='right'><span><CurrencyFormatter amount={total.total_balance_} currency={null} /></span></CustomTableBodyCell>
                    </CustomTableRow>
                    : null
            }
        </CustomTableBody>
    )
}
