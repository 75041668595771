import React from 'react';
import * as Actions from '../../../state/Actions';
import { routesName } from '../../../config/routesName';
import { getBusinessPreferenceData } from '../setting/settings/Preferences/common/getPreferences';

import {
    apiAction,
    apiAction_social,
} from '../../../api/api';

import {
    google_login,
    google_connect,
    get_google_login,
    retrieve_business,
    get_list_of_business,
} from '../../../api/urls'

import {
    setLoader,
    getQueryParams,
    stateChangeManager
} from '../../../utils/Utils'

import {
    setToken,
    getToken,
    clearCookie,
    setLoginStatus,
    setUserDetails,
    setBusinessInfo,
} from '../../../config/cookiesInfo';

import {
    useNavigate,
    useLocation
} from 'react-router-dom';


import { Button } from '@mui/material'
import { Google } from '@mui/icons-material'

const SocialLogin = (props) => {
    const {
        fullWidth=true,
        AnalyticsEvent,
        btnText='Login with Google',
    }=props

    const navigate = useNavigate();
    const location = useLocation();
    const params = getQueryParams(location.search);
    const dispatch = Actions.getDispatch(React.useContext);

    React.useEffect(() => {
        if (params.code) {
            if (getToken()) {
                toConnectWithGoogle(params.code)
            } else {
                onGoogleLogin(params.code);
            }
        }
    }, [])


    const onGoogleLogin = async (code) => {
        setLoader(dispatch, Actions, true);
        let res = await apiAction_social({
            method: 'post',
            url: google_login(),
            data: {
                code: code,
                id_token: "",
                access_token: "",
            }
        })
        if (res?.access_token) {
            setToken(res?.access_token);
            setUserDetails({ ...res?.user, name: res?.user.first_name + ' ' + res?.user.last_name });
            getBusiness();
        } else {
            setLoader(dispatch, Actions, false);
            navigate("/auth" + routesName.connect.path);
            // stateChangeManager(dispatch, Actions, true, 'error', `We've found an existing account with the email address associated with your social account. To link the two accounts,please login with the email and password associated with your existing account.`)
        }

    }

    const toConnectWithGoogle = async (code) => {
        setLoader(dispatch, Actions, true);
        let res = await apiAction({
            method: 'post',
            url: google_connect(),
            data: {
                code: code,
                id_token: "",
                access_token: "",
            }
        })
        if (res?.access_token) {
            setToken(res?.access_token);
            setUserDetails({ ...res?.user, name: res?.user.first_name + ' ' + res?.user.last_name });
            getBusiness();
        } else {
            setLoader(dispatch, Actions, false);
            navigate("/auth" + routesName.connect.path);
            stateChangeManager(dispatch, Actions, true, 'error', `We've found an existing account with the email address associated with your social account. To link the two accounts,please login with the email and password associated with your existing account.`)
        }
    }

    const getBusiness = async () => {
        let res = await apiAction({
            method: 'get',
            url: get_list_of_business(),
        })
        if (res?.success) {
            
            let business = res?.result;
            let businessData = res?.result.find((item) => item.is_default);
            if (business.length) {
                let businessRes = await apiAction({
                    method: 'get',
                    url: retrieve_business(businessData?businessData.id:business[0].id),
                })
                if (businessRes?.success) {
                    setLoginStatus('true');
                    setBusinessInfo(businessRes.result);
                    navigate(routesName.dashboard.path);
                    setLoader(dispatch, Actions, false);
                    getBusinessPreferenceData(businessRes.result.id);
                } else {

                }
            } else {    
                setLoader(dispatch, Actions, false);
                navigate("/auth" + routesName.businessCreate.path)
            }
        } else {
            setLoader(dispatch, Actions, false)
        }
    }

    return (
        !params.code&&
        <Button
        id='get_google_login_btn'
        dataTestId='get_google_login_btn'
            fullWidth={fullWidth}
            variant="contained"
            href={get_google_login()}
            onClick={() => {
                clearCookie();
                setLoader(dispatch, Actions, true);
                if (AnalyticsEvent) {AnalyticsEvent()};
            }}
            sx={{
                mt: 3,
                color: 'white',
                background: '#dd4b39',
                textTransform: 'none',
                '&:hover': {
                    backgroundColor: '#dd4b39', opacity: 1
                }
            }}>
            <Google sx={{ mr: 1.5 }} />
           {btnText}
        </Button>
    )
}

export default SocialLogin