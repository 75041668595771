import React from 'react';
import Input from '../../../common/Input';
import * as Actions from "../../../../state/Actions";
import CurrencyFormatter from '../../../common/CurrencyFormatter';

import {
    isFinalAmountRangeCrossed,
} from '../sales/invoices/InvoiceManager';

import {
    isNumeric,
    stateChangeManager,
    limitDecimalPlaces,
} from '../../../../utils/Utils';


export const RateLabelComponent = () => {
    return (
        <div style={{ whiteSpace: 'nowrap' }}>
            Rate
        </div>
    );
};

export const RateInputComponent = ({ index, data, setData, rateData, finalPayment, isFormSubmitted, ...otherProps }) => {
    const dispatch = Actions.getDispatch(React.useContext);
    const [editable, setEditable] = React.useState(false);

    const handleFocus = () => {
        setEditable(true);
    };

    const handleChange = (event) => {
        const inputValue = limitDecimalPlaces(event.target.value);
        if (isFinalAmountRangeCrossed({
            item: data[index],
            invoice: rateData,
            quantity: data[index]?.quantity,
            rate: inputValue,
            item_level_discount: data[index].item_level_discount,
            finalPayment: data.length <= 1 ? 0 : finalPayment - (data[index]?.amount || 0) - (data[index]?.tax_amount || 0),
        })) {
            stateChangeManager(dispatch, Actions, true, "error", "Amount range max limit reached");
        } else {
            data[index].rate = inputValue;
            console.log('==>inputValue',inputValue)
            setData([...data]);
        }
    };

    const handleBlur = (event) => {
        const inputValue = limitDecimalPlaces(event.target.value);
        if (isNumeric(inputValue)) {
            data[index].rate = inputValue;
            if (data[index]?.quantity && isNumeric(data[index]?.quantity)) {
                data[index].amount = parseFloat((inputValue * data[index]?.quantity).toFixed(2));
            } else {
                data[index].amount = inputValue;
            }
        } else {
            data[index].amount = 0;
            data[index].rate = null;
        }
        setEditable(false);
        setData([...data]);
    };

    const handleKeyPress = (event) => {
        const restrictedKeys = ["-", "+", "e", "E"];
        if (restrictedKeys.includes(event.key)) {
            event.preventDefault();
        }
    };

    return (
        <React.Fragment>
            <Input
                editable={editable}
                onFocus={handleFocus}
                id={`input_rate_${index}`}
                isSearchableComponent={true}
                isShowCommaSeparatedValue={true}
                dataTestId={`input_rate_${index}`}
                currency_code={rateData.currency_code}
                item={{
                    type: "number",
                    required: true,
                    placeholder: "",
                    fullWidth: true,
                    onBlur: handleBlur,
                    onChange: handleChange,
                    value: data[index]?.rate !== null ? data[index]?.rate : "",
                    validation: isFormSubmitted && data[index]?.rate === null,
                }}
                InputProps={{
                    inputProps: { min: 0 },
                }}
                onKeyPress={handleKeyPress}
                {...otherProps} // Spread all other props
            />
            { data[index]?.mark_up_amount && data[index]?.rate_with_mark_up_amount === data[index]?.rate ? (
                <p
                    style={{
                        opacity: 0.6,
                        marginTop: "1px",
                        fontSize: "10px",
                        whiteSpace: 'nowrap',
                        marginBottom: "-17px",
                    }}
                >
                    Marked up by{" "}
                    <CurrencyFormatter
                        amount={data[index]?.mark_up_amount}
                        currency={rateData.currency_code}
                    />
                </p>
            ):''}
        </React.Fragment>
    );
};
