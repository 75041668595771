import React from 'react';
import { Box, Grid } from '@mui/material';
import Input from '../../../../../../common/Input';
import * as Actions from '../../../../../../../state/Actions';
import CustomButton from '../../../../../../custom/button/CustomButton';
import { LabelWithAsteriskMark } from '../../../../../../common/CommonLabel';
import { CustomCheckbox, CustomHelperText } from '../../common/CustomElement';
import { validateIfscCode, validateUpiId, validateAccountNumber } from './helper';
import { isFormValid, stateChangeManager } from '../../../../../../../utils/Utils';

const initialData = {
    upi_id: "",
    ifsc_code: "",
    bank_name: "",
    branch_name: "",
    account_number: "",
};

const formReducer = (state, action) => {
    switch (action.type) {
        case 'SET_FIELD':
            return { ...state, [action.field]: action.value };
        case 'RESET':
            return action.payload;
        default:
            return state;
    }
};

const AddBankDetails = ({ open, onClose, defaultAccountData, isDefaultAccountChecked, onAddHandler }) => {
    const globalDispatch = Actions.getDispatch(React.useContext);

    const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);
    const [defaultAccount, setDefaultAccount] = React.useState(isDefaultAccountChecked);
    const [data, dispatch] = React.useReducer(formReducer, { ...initialData, ...defaultAccountData });

    React.useEffect(() => {
        setIsFormSubmitted(false);
        setDefaultAccount(isDefaultAccountChecked);
        dispatch({ type: 'RESET', payload: { ...initialData, ...defaultAccountData } });
    }, [open, defaultAccountData, isDefaultAccountChecked]);

    const handleChange = (key, value) => {
        dispatch({ type: 'SET_FIELD', field: key, value });
    };

    const handleSave = () => {
        setIsFormSubmitted(true);
        const validationData = [
            { key: "bank_name", message: 'Please Enter the Bank Name.' },
            { key: "account_number", message: 'Please Enter the Account Number.' },
            { key: "branch_name", message: 'Please Enter the Branch Name.' },
            { key: "ifsc_code", message: 'Please Enter the IFSC Code.' },
            { key: "", validation: !validateIfscCode(data.ifsc_code), message: 'Please Enter a Valid IFSC Code.' },
            { key: "", validation: data.upi_id && !validateUpiId(data.upi_id), message: 'Please Enter a Valid UPI ID.' },
        ];
        const { isValid, message } = isFormValid(data, validationData);
        if (isValid) {
            onClose();
            onAddHandler(data, defaultAccount);
        } else {
            stateChangeManager(globalDispatch, Actions, true, 'error', message);
        }
    };

    const handleKeyPress = (event) => {
        const restrictedKeys = ["-", "+", "e", "E", "."];
        if (restrictedKeys.includes(event.key)) {
            event.preventDefault();
        }
    };

    return (
        <Box sx={{ p: 2, pt: 0 }}>
            <Grid container spacing={3}>
                <InputField
                    id="bank_name"
                    isMandatory={true}
                    value={data.bank_name}
                    onChange={handleChange}
                    placeholder="Enter Bank Name"
                    validation={isFormSubmitted && !data.bank_name}
                    title={<LabelWithAsteriskMark label="Bank Name" />}
                />
                <InputField
                    inputType='number'
                    id="account_number"
                    isMandatory={true}
                    value={data.account_number}
                    handleKeyPress={handleKeyPress}
                    placeholder="Enter Account Number"
                    title={<LabelWithAsteriskMark label="Account Number" />}
                    validation={isFormSubmitted && !validateAccountNumber(data.account_number)}
                    onChange={(key, value) => {
                        if (value?.length <= 18) {
                            handleChange(key, value);
                        } else {
                            stateChangeManager(globalDispatch, Actions, true, "error", "Account Number cannot be more than 18 digits");
                        }
                    }}
                />
                <InputField
                    id="branch_name"
                    value={data.branch_name}
                    onChange={handleChange}
                    placeholder="Enter Branch Name"
                    validation={isFormSubmitted && !data.branch_name}
                    title={<LabelWithAsteriskMark label="Branch Name" />}
                />
                <InputField
                    id="ifsc_code"
                    value={data.ifsc_code}
                    onChange={handleChange}
                    placeholder="Enter IFSC Code"
                    title={<LabelWithAsteriskMark label="IFSC Code" />}
                    validation={isFormSubmitted && !validateIfscCode(data.ifsc_code)}
                />
                <InputField
                    id="upi_id"
                    title="UPI Id"
                    value={data.upi_id}
                    onChange={handleChange}
                    placeholder="Enter UPI Id"
                    validation={!!data.upi_id && isFormSubmitted && !validateUpiId(data.upi_id)}
                />
                <Grid item xs={12} sm={6}>
                    <Box sx={{ p: 3, borderRadius: 1, background: '#f6f6f6' }}>
                        <CustomCheckbox
                            id="default_bank_account"
                            dataTestId="default_bank_account"
                            checked={defaultAccount}
                            onClickedHandler={(value) => {
                                if (isDefaultAccountChecked) {
                                    stateChangeManager(globalDispatch, Actions, true, 'error', 'This action cannot be performed');
                                } else {
                                    setDefaultAccount(!value);
                                }
                            }}
                            label="Set as Default Bank Account"
                        />
                        <CustomHelperText label={<span>This account information will be printed on the invoice</span>} />
                    </Box>
                </Grid>
            </Grid>
            <CustomButton
                variant="contained"
                onClick={handleSave}
                id="save_bank_account"
                dataTestId="save_bank_account"
                btnLabel={defaultAccountData ? "Update" : "Add"}
            />
        </Box>
    );
};

const InputField = React.memo(({ inputType = 'text', placeholder, id, value, onChange, validation, title, handleKeyPress = () => { } }) => (
    <Grid item xs={12} sm={6}>
        <Input
            id={`input_${id}`}
            dataTestId={`input_${id}`}
            item={{
                title: title,
                type: inputType,
                fullWidth: true,
                value: value || '',
                validation: validation,
                placeholder: placeholder,
                onChange: (e) => onChange(id, e.target.value),
            }}
            onKeyPress={handleKeyPress}
        />
    </Grid>
));

export default AddBankDetails;
