import React from 'react';
import SectionCard from './SectionCard';
import { isEqual } from '../common/Helper';
import { useNavigate } from 'react-router-dom';
import { apiAction } from '../../../../../api/api';
import * as Actions from '../../../../../state/Actions';
import { stateChangeManager } from '../../../../../utils/Utils';
import { getBusinessInfo } from '../../../../../config/cookiesInfo';
import { update_insight_section_order } from '../../../../../api/urls';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';


import {
    Box,
    Grid,
} from '@mui/material';

const ListSections = (props) => {
    const { tab_id, sectionList = [], onDeleteSection, from_date, to_date, refreshDataPoint } = props

    const navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);

    const [sectionListData, setSectionListData] = React.useState([]);
    React.useEffect(() => {
        setSectionListData([...sectionList])
    }, [sectionList])

    const onDragEnd = (result) => {
        if (!result.destination) {
            return; // Dragged outside the droppable area
        }

        const updatedList = Array.from(sectionListData);
        const [draggedItem] = updatedList.splice(result.source.index, 1);
        updatedList.splice(result.destination.index, 0, draggedItem);

        // Check if the lists are the same or different
        const isListChanged = !isEqual(sectionListData, updatedList);
        console.log('Is List Changed:', isListChanged);
        if (isListChanged) {
            updateInsightSectionOrder(updatedList)
        }
    };

    const updateInsightSectionOrder = async (data) => {
        const formData = {
            tab_id: tab_id,
            business_id: getBusinessInfo().id,
            section_order_list: data.map(item => item.id),
        }

        let res = await apiAction({
            method: 'post',
            navigate: navigate,
            dispatch: dispatch,
            data: { ...formData, },
            url: update_insight_section_order(),

        });
        if (res?.success) {
            setSectionListData(data);
            stateChangeManager(dispatch, Actions, true, 'success', res?.status);
        } else {
            stateChangeManager(dispatch, Actions, true, 'error', res?.status);
        }
    }


    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="sectionList" style={{}}>
                {(provided, snapshot) => (
                    <Grid
                        container
                        spacing={2}
                        ref={provided.innerRef}
                        {...provided.droppableProps}

                        sx={{
                            transition: 'background-color 0.2s ease',
                            backgroundColor: snapshot.isDraggingOver ? '#ffebe6' : 'transparent',
                        }}
                    >
                        {sectionListData.map((item, index) => {
                            return (
                                item &&
                                <Draggable
                                    index={index}
                                    key={item.id}
                                    draggableId={item.id.toString()}
                                >
                                    {(provided, snapshot) => (
                                        <Grid
                                            {...provided.draggableProps}
                                            item sx={{}} lg={4} md={6} sm={6} xs={12} ref={provided.innerRef}
                                        >
                                            <Box
                                                sx={{ height: 'calc(100vh - 280px)', overflow: 'hidden' }}
                                            >
                                                <SectionCard
                                                    item={item}
                                                    to_date={to_date}
                                                    from_date={from_date}
                                                    is_drag={snapshot.isDragging}
                                                    refreshDataPoint={refreshDataPoint}
                                                    setSectionListData={setSectionListData}
                                                    onDeleteSection={() => onDeleteSection(item.id)}

                                                    getTitle={(title) => <span  {...provided.dragHandleProps}>{title}</span>}
                                                />
                                            </Box>
                                        </Grid>
                                    )}
                                </Draggable>
                            )
                        })}
                        {provided.placeholder}
                    </Grid>
                )}
            </Droppable>
        </DragDropContext>
    )
}

export default ListSections;


