import React from 'react';
import moment from 'moment';
import { apiAction } from "../../../../api/api";
import ReportsTitle from '../common/ReportsTitle';
import DatePicker from '../../../common/DatePicker';
import * as Actions from '../../../../state/Actions';
import ReportsSettingButtons from '../ReportsSettingButtons';
import { getBusinessInfo } from '../../../../config/cookiesInfo';
import CurrencyFormatter from '../../../common/CurrencyFormatter';
import { NoDataComponent } from '../../../common/NoDataComponent';
import { VendorViewLink } from '../../invoicing/common/CommonLinks';
import { CustomContainer } from '../../../custom/container/CustomContainer';

import {
    account_payable_summary_report,
    account_payable_summary_report_file,
} from '../../../../api/urls';

import {
    setLoader,
    getDateFormat,
    sumOfArrayByKey
} from '../../../../utils/Utils';

import {
    Box,
    Grid,
} from '@mui/material';

import {
    CustomTable,
    CustomTableRow,
    CustomTableBody,
    CustomTableHead,
    CustomTableHeadCell,
    CustomTableBodyCell,
    CustomTableContainer,
} from '../../../custom/table/CustomTable';

const APAgingSummaryReports = () => {
    const dispatch = Actions.getDispatch(React.useContext);

    const [results, setResults] = React.useState([]);
    const [date, setDate] = React.useState(moment());

    React.useEffect(() => {
        if (date) {
            getApiResults(date)
        }
        // eslint-disable-next-line
    }, [date]);

    const getApiResults = async (date) => {
        setLoader(dispatch, Actions, true)
        let res = await apiAction({
            method: 'post',
            url: account_payable_summary_report(),
            data: {
                business_id: getBusinessInfo().id,
                as_on_date: moment(date).format('YYYY-MM-DD'),
            },
        })

        if (res?.success) {
            setResults(res?.result);
            setLoader(dispatch, Actions, false);
        } else {
            setLoader(dispatch, Actions, false);
        }
    }
    return (
        <div>
            <ReportsSettingButtons
                scheduleData={{
                    report_name: 'account_payable_aging_summary',
                    report_title: 'AP Aging Summary By Bill Due Date',
                }}

                exportData={{ url: account_payable_summary_report_file(), data: { to_date: moment(date).format('YYYY-MM-DD') } }}
            />
            <CustomContainer maxWidth={"400"} sx={{ height: 'calc(100vh - 134px)' }}>
                <div style={{ paddingLeft: '16px', paddingRight: '16px' }}>
                    <Grid container spacing={0} sx={{ pb: 2 }} style={{
                        alignItems: 'center',
                        justifyItems: 'center',
                        alignContent: 'space-evenly',
                        justifyContent: 'space-evenly',
                    }}>
                        <Grid item xs={12} sm={4}>
                            <ReportsTitle
                                dateText={'As on '}
                                date={moment(date).format(getDateFormat())}
                                title={'AP Aging Summary By Bill Due Date'}
                            />
                        </Grid>
                        <Grid container spacing={2} item xs={12} sm={8} sx={{ justifyContent: 'end', }}>
                            <Grid item xs={12} sm={4}>
                                <DatePicker
                                    id={'as_on_date_select'}
                                    dataTestId={'as_on_date_select'}
                                    date={date}
                                    setDate={setDate}
                                    title='As on Date'
                                    disabledCloseIcon={true}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <CustomTableContainer>
                    <CustomTable>
                        <Header />
                        {results.length ?
                            <Body data={results} />
                            : null
                        }
                    </CustomTable>
                    <Box sx={{ p: !results.length ? 2 : 0 }} >
                        <NoDataComponent left='0%' top='0%' position={'relative'} data={results} />
                    </Box>
                </CustomTableContainer>
            </CustomContainer>
        </div>
    )
}

export default APAgingSummaryReports;

const Header = () => {
    return (
        <CustomTableHead>
            <CustomTableRow >
                <CustomTableHeadCell><span>Vendor Name</span></CustomTableHeadCell>
                <CustomTableHeadCell align='right'><span>Current </span></CustomTableHeadCell>
                <CustomTableHeadCell align='right'><span>1-15 Days</span></CustomTableHeadCell>
                <CustomTableHeadCell align='right'><span>16-30 Days</span></CustomTableHeadCell>
                <CustomTableHeadCell align='right'><span>31-45 Days</span></CustomTableHeadCell>
                <CustomTableHeadCell align='right'><span>{'> 45 Days'}</span></CustomTableHeadCell>
                <CustomTableHeadCell align='right'><span>Total</span></CustomTableHeadCell>
            </CustomTableRow>
        </CustomTableHead>
    )
}

const Body = (props) => {
    const { data } = props;

    return (
        <CustomTableBody>
            {data.map((item, index) => {
                return (
                    <CustomTableRow key={index}>
                        <CustomTableBodyCell>
                            {
                                item.vendor_id ? <VendorViewLink title={item.vendor_name} id={item.vendor_id} /> :
                                    item.vendor_name
                            }
                        </CustomTableBodyCell>
                        <CustomTableBodyCell align='right'><CurrencyFormatter amount={item.current} currency={null} /></CustomTableBodyCell>
                        <CustomTableBodyCell align='right'><CurrencyFormatter amount={item.days_1_to_15} currency={null} /></CustomTableBodyCell>
                        <CustomTableBodyCell align='right'><CurrencyFormatter amount={item.days_16_to_30} currency={null} /></CustomTableBodyCell>
                        <CustomTableBodyCell align='right'><CurrencyFormatter amount={item.days_31_to_45} currency={null} /></CustomTableBodyCell>
                        <CustomTableBodyCell align='right'><CurrencyFormatter amount={item.above_45} currency={null} /></CustomTableBodyCell>
                        <CustomTableBodyCell align='right'><CurrencyFormatter amount={item.total} currency={null} /></CustomTableBodyCell>
                    </CustomTableRow>
                )
            })}

            <CustomTableRow sx={{
                pb: 1,
                pt: 2,
                background: '#FFF7DC',
                borderTop: '1px solid #FFF0C0',
                borderBottom: '1px solid #FFF0C0'
            }}>
                <CustomTableBodyCell><span>Total</span></CustomTableBodyCell>
                <CustomTableBodyCell align='right'><CurrencyFormatter amount={sumOfArrayByKey(data, 'current')} currency={null} /></CustomTableBodyCell>
                <CustomTableBodyCell align='right'><CurrencyFormatter amount={sumOfArrayByKey(data, 'days_1_to_15')} currency={null} /></CustomTableBodyCell>
                <CustomTableBodyCell align='right'><CurrencyFormatter amount={sumOfArrayByKey(data, 'days_16_to_30')} currency={null} /></CustomTableBodyCell>
                <CustomTableBodyCell align='right'><CurrencyFormatter amount={sumOfArrayByKey(data, 'days_31_to_45')} currency={null} /></CustomTableBodyCell>
                <CustomTableBodyCell align='right'><CurrencyFormatter amount={sumOfArrayByKey(data, 'above_45')} currency={null} /></CustomTableBodyCell>
                <CustomTableBodyCell align='right'><CurrencyFormatter amount={sumOfArrayByKey(data, 'total')} currency={null} /></CustomTableBodyCell>
            </CustomTableRow>
        </CustomTableBody>
    )
}