import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { StepButton } from '@mui/material';
import * as Actions from "../../../../state/Actions"
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
  step_label_root: {
    fontSize: '14px',
    fontFamily: "Noto Sans"
  }
}));

const steps = ['Upload File', 'Create/Choose Mapping', 'Save'];

export default function HorizontalLinearStepper() {
  // const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const state = Actions.getState(React.useContext)
  const dispatch = Actions.getDispatch(React.useContext)
  const {activeStep} = state
  const classes = useStyles()

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  
  return (
    <Box maxWidth={"400"} sx={{  }}>
      <Stepper  activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepButton color="inherit" >
              <StepLabel classes={{ label: classes.step_label_root }}> 
                {label}
              </StepLabel>
            </StepButton>
            </Step>
          );
        })}
      </Stepper>
      
    </Box>
  );
}