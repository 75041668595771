import React from 'react';
import { apiAction } from '../../api/api';
import * as Actions from '../../state/Actions';
import { useNavigate } from 'react-router-dom';
import { get_list_of_currency } from '../../api/urls';
import CustomDropdown from '../custom/dropdown/CustomDropdown';
import CustomTypography from '../custom/typography/CustomTypography';
import CustomDefaultDropdown from '../custom/dropdown/CustomDefaultDropdown';


const CurrencyDropdown = (props) => {
    let navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);

    const { id, dataTestId, item, sx, default_currency_id, setCurrency, disableClearable } = props
    const [results, setResult] = React.useState([])

    React.useEffect(() => {
        const apiResults = async () => {
            let res = await apiAction({
                navigate: navigate,
                dispatch: dispatch,
                url: get_list_of_currency(),
            })
            if (res?.success) {
                let results = res?.result.map((item) => {
                    item['helperText'] = `${item.name} (${item.symbol})`;
                    return item;
                }
                )
                setResult(results)
            } else {

            }
        }
        apiResults()
    }, [])
    React.useEffect(() => {

        if (default_currency_id) {
            setCurrency(results.find((item) => item.id === default_currency_id))
        }
    }, [default_currency_id])


    return (
        <div>
            <CustomTypography
                text={item.label}
                sx={{
                    mb: 1,
                    fontSize: '14px',
                    color: '#000000',
                    fontWeight: '700',
                    lineHeight: '18px',
                    fontStyle: 'normal',
                    textTransform: "none",
                    fontFamily: "Noto Sans",
                }}
            />
            <CustomDefaultDropdown
                id={id}
                maxHeight={250}
                fullWidth={true}
                results={results}
                dataTestId={dataTestId}
                labelKey='currency_code'
                placeholder='Select Currency'
                inputDisabled={item.disabled}
                valueLabelKey={'currency_code'}
                disabledCloseIcon={disableClearable}
                setValue={(selectedCurrency) => { item.onChange('', selectedCurrency) }}
                value={item.value ? results.find((value) => value.id === item.value.id) : null}
                sx={item ? { background: item.disabled ? '#F1F3F4' : null, ...item.sx, } : sx}
            />
            {/* <CustomDropdown
                id={id}
                isChildren={true}
                results={results}
                value={item.value}
                dataTestId={dataTestId}
                onChange={item.onChange}
                inputDisabledColor={item.sx}
                inputDisabled={item.disabled}
                placeholder='Select a currency'
                disableClearable={disableClearable}
                sx={item ? { background: item.disabled ? '#F1F3F4' : null, ...item.sx, } : sx}
            /> */}
        </div>
    )
}

export default CurrencyDropdown