import React from 'react';
import moment from 'moment/moment';
import Status from '../../../../common/Status';
import * as Actions from '../../../../../state/Actions';
import CustomTitle from '../../../../common/CustomTitle';
import { InvoiceViewLink } from '../../common/CommonLinks';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomTabs from '../../../../custom/tabs/CustomTabs';
import { routesName } from '../../../../../config/routesName';
import CustomDialog from '../../../../custom/dialog/CustomDialog';
import { getBusinessInfo } from '../../../../../config/cookiesInfo';
import CurrencyFormatter from '../../../../common/CurrencyFormatter';
import AuditTrailHistory from '../../../../common/AuditTrailHistory';
import CommonPreviewShare from '../../../../common/CommonPreviewShare';
import { recurring_invoice_number } from '../../../../../utils/Constant';
import ViewPageActionButtons from '../../../../common/ViewPageActionButtons';
import CustomTypography from '../../../../custom/typography/CustomTypography';
import CustomPagination from '../../../../custom/pagination/CustomPagination';
import { eventsNames,AnalyticsEvent } from '../../../../../firebase/firebaseAnalytics';

import {
    CustomContainer,
    CustomTitleContainer,
} from '../../../../custom/container/CustomContainer';

import {
    apiAction,
    apiBlobResponse,
    apiHandleDownload
} from '../../../../../api/api';

import {
    update_invoice,
    export_invoice,
    retrieve_invoice,
    send_invoice_mail,
    get_list_of_invoice,
    delete_recurring_invoice,
    update_recurring_invoice,
    retrieve_recurring_invoice,
} from '../../../../../api/urls';

import {
    setLoader,
    getDateFormat,
    getQueryParams,
    stateChangeManager,
    to_check_repeat_every,
    breadCrumbsStateChangeManager,
} from '../../../../../utils/Utils';

import {
    CustomTable,
    CustomTableRow,
    CustomTableBody,
    CustomTableHead,
    CustomTableHeadCell,
    CustomTableBodyCell,
    CustomTableContainer,
} from '../../../../custom/table/CustomTable';

import {
    Box,
    Grid,
    Avatar,
} from '@mui/material';
import { InfoRounded } from '@mui/icons-material';
import { CommonWebSocket } from '../../../../../api/webSocket';
import CurrencyFormatterWithExchangeRate from '../../common/CurrencyFormatterWithExchangeRate';



const RecurringInvoiceViewpage = () => {
    let location = useLocation();
    let navigate = useNavigate();
    const params = getQueryParams(location.search);

    const dispatch = Actions.getDispatch(React.useContext);

    const [result, setResult] = React.useState();

    const [state, setState] = React.useState({
        id: '',
        title: '',
        open: false,
        condition: '',
        maxWidth: 'lg',
        fullWidth: true,
    });

    const stateChangeHandler = (title, condition, maxWidth, url) => {
        setState({
            url: url,
            open: true,
            title: title,
            fullWidth: true,
            maxWidth: maxWidth,
            condition: condition,
            navigate: () => navigate(routesName.invoicingSalesRecurringInvoice.path),
        })
    }


    React.useEffect(() => {
        getApiResults(params.id);
        // eslint-disable-next-line
    }, []);

    const getApiResults = async (id) => {
        setLoader(dispatch, Actions, true)
        let res = await apiAction({
            method: 'post',
            url: retrieve_recurring_invoice(id),
            data: { business_id: getBusinessInfo().id },
        })

        if (res?.success) {
            res.result.invoice_data = { ...res?.result.invoice_data, customer: res?.result.customer, customer_display_name: res?.result.customer_display_name };
            setResult(res?.result);
            setLoader(dispatch, Actions, false);
            breadCrumbsStateChangeManager(dispatch, `${recurring_invoice_number}-${res?.result.periodic_task_id}`);
            // breadCrumbsStateChangeManager(dispatch,'Periodic Task #'+res?.result.periodic_task_id)
        } else {
            setLoader(dispatch, Actions, false)
        }
    }


    const manageStatus = async (id, is_active) => {

        let actionType=is_active?eventsNames.actions.recurringInvoice.action.ACTIVATE:eventsNames.actions.recurringInvoice.action.DEACTIVATE
        AnalyticsEvent(eventsNames.categories.RECURRING_INVOICES,{action:actionType})

        setLoader(dispatch, Actions, true);
        let res = await apiAction({
            method: 'post',
            dispatch: dispatch,
            navigate: navigate,
            url: update_recurring_invoice(id),
            data: { business_id: getBusinessInfo().id, is_active: is_active },
        })
        if (res?.success) {
            getApiResults(params.id);
            setLoader(dispatch, Actions, false);
        } else {
            setLoader(dispatch, Actions, false);
        }
    }

    return (
        <div>
            {result &&
                <>
                    <CustomDialog
                        state={state}
                        setState={setState}
                    />
                    <CustomTitleContainer>
                        <Grid container spacing={0} style={{
                            alignItems: 'center',
                            justifyItems: 'center',
                            alignContent: 'space-evenly',
                            justifyContent: 'space-evenly',
                        }}>
                            <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'start', }}>
                                <CustomTitle title={result.profile_name} />
                            </Grid>
                            <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'end', }}>
                                <ViewPageActionButtons
                                    isPrint={false}
                                    isEditable={true}
                                    isDownload={false}
                                    edit={() => { navigate(routesName.invoicingSalesRecurringInvoiceAdd.path + "?recurring_id=" + result.periodic_task_id, { replace: false }); }}

                                    actionsList={[
                                        result.status !== 'Expired' && { name: result.status === 'Active' ? 'Deactivate' : 'Activate', onClick: () => { manageStatus(result.periodic_task_id, result.status === 'Active' ? false : true) } },
                                        { name: 'Clone', onClick: () =>{ navigate(routesName.invoicingSalesRecurringInvoiceAdd.path + "?recurring_clone_id=" + result.periodic_task_id, { replace: false });AnalyticsEvent(eventsNames.categories.RECURRING_INVOICES,{action:eventsNames.actions.CLONE}) } },
                                        { name: 'Delete', showDivider: true, onClick: () => { stateChangeHandler('Delete Recurring Invoice', 'delete', 'sm', delete_recurring_invoice(result.periodic_task_id)); AnalyticsEvent(eventsNames.categories.RECURRING_INVOICES,{action:eventsNames.actions.DELETE}) } }


                                    ]}
                                />
                                <AuditTrailHistory
                                    data={{ object_id: result.periodic_task_id, object_type: 'recurring_invoice' }}
                                    AnalyticsEvent={()=>{AnalyticsEvent(eventsNames.categories.RECURRING_INVOICES,{action:eventsNames.actions.HISTORY})}} 
                                />
                            </Grid>
                        </Grid>
                    </CustomTitleContainer>
                    <CustomContainer maxWidth={"400"} sx={{ height: 'calc(100vh - 134px)' }}>
                        <Box sx={{ borderBottom: '2px solid #F5F5F5' }} >
                            <UserDetails
                                customer_info={{ ...result.invoice_data.customer.party_contact_data.find((item) => item.contact_type === "primary"), customer_display_name: result.invoice_data.customer_display_name, preference: result.preference }}
                                customer_Details={[
                                    { title: 'Profile Status:', value: <Status status={result.status} /> },
                                    { title: 'Start Date:', value: result.starts_on ? moment(result.starts_on).format(getDateFormat()) : '-' },
                                    { title: 'End Date:', value: result.never_expires ? 'Never Expires' : result.ends_on ? moment(result.ends_on).format(getDateFormat()) : '-' },
                                    { title: 'Order Number:', value: result.invoice_data.order_number ? result.invoice_data.order_number : '-' },
                                    { title: 'Payment Terms:', value: result.invoice_data.payment_term.name },
                                    // { title: 'Manually Created Invoices:', value: '1' },
                                ]}
                                customer_address={[
                                    { title: 'Billing Address', value: result.invoice_data.billing_address },
                                    { title: 'Shipping Address', value: result.invoice_data.shipping_address },
                                ]}
                            />
                        </Box>
                        <Box sx={{ borderBottom: '2px solid #F5F5F5' }} >
                            <InvoiceDetails
                                recurring_invoice_data={[
                                    { title: 'Invoice Amount', value: <CurrencyFormatter amount={result.invoice_data.total_amount} currency={result.invoice_data.currency_code} /> },
                                    { title: 'Next Invoice Date', value: result.status === 'Expired' ? '-' : result.next_invoice_date ? moment(result.next_invoice_date).format(getDateFormat()) : '-', borderLeft: '2px solid #F5F5F5', borderRight: '2px solid #F5F5F5', },
                                    { title: 'Recurring Period', value: to_check_repeat_every(result.repeat_frequency, result.repeat_every) ? to_check_repeat_every(result.repeat_frequency, result.repeat_every).name : `Every ${result.repeat_every} ${result.repeat_frequency.capitalize()}s` }
                                ]}
                            />
                        </Box>

                        <CustomTableContainer>
                            <CustomTabs
                                id={'all_child_invoices'}
                                dataTestId={'all_child_invoices'}
                                tabs={[{ name: 'All Child Invoices' }]}
                                value={0}
                                onChange={() => { }}
                            />
                            <ChildInvoicesList />
                        </CustomTableContainer>
                    </CustomContainer>
                </>
            }
        </div>
    )
}

export default RecurringInvoiceViewpage;

const UserDetails = (props) => {
    const { customer_info, customer_Details, customer_address } = props

    const preference = [
        { name: 'Create and Send Later', preference: 'create_and_save' },
        { name: 'Create and Send Invoices', preference: 'create_and_send' },
        { name: 'Create Invoices as Drafts', preference: 'create_and_draft' },
        { name: 'Create Invoices as Drafts', preference: 'create_invoices_as_drafts' },
    ]

    return (
        <Box>
            <Grid container>
                <Grid item xs={4}>
                    <Box sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'row',
                    }}>
                        <Avatar />
                        <Box sx={{ alignSelf: 'center', ml: 2.5 }}>

                            <CustomTypography
                                text={customer_info.customer_display_name}
                                sx={{
                                    mb: 1,
                                    color: '#000000',
                                    fontSize: '14px',
                                    fontWeight: '700',
                                    lineHeight: '18px',
                                    fontStyle: 'normal',
                                    fontFamily: "Noto Sans",
                                }}
                            />
                            <CustomTypography
                                text={customer_info.email}
                                sx={{
                                    mb: 0.5,
                                    color: '#7A7A7A',
                                    fontSize: '12px',
                                    fontWeight: '500',
                                    lineHeight: '18px',
                                    fontStyle: 'normal',
                                    fontFamily: "Noto Sans",
                                }}
                            />
                            <CustomTypography
                                text={customer_info.mobile_number ? customer_info.mobile_number : '-'}
                                sx={{
                                    mb: 0.5,
                                    color: '#7A7A7A',
                                    fontSize: '12px',
                                    fontWeight: '500',
                                    lineHeight: '18px',
                                    fontStyle: 'normal',
                                    fontFamily: "Noto Sans",
                                }}
                            />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            m: 2,
                            p: 2,
                            borderRadius: 1,
                            display: 'flex',
                            background: '#f6f6f6',
                        }}
                    >
                        <InfoRounded fontSize='small' sx={{ color: '#2098ff' }} />
                        <CustomTypography
                            text={<span>Recurring invoice preference has been set to <strong style={{ color: '#000000' }}>"{preference.find((item) => item.preference === customer_info.preference).name}"</strong></span>}
                            sx={{
                                ml: 1,
                                color: '#7A7A7A',
                                fontSize: '14px',
                                fontWeight: '500',
                                lineHeight: '25px',
                                fontStyle: 'normal',
                                fontFamily: "Noto Sans",
                            }}
                        />
                    </Box>

                </Grid>
                <Grid item xs={4} sx={{
                    p: 2,
                    borderLeft: '2px solid #F5F5F5',
                    borderRight: '2px solid #F5F5F5',
                }}>
                    <Grid container>
                        {
                            customer_Details.map((item, index) => {
                                return (
                                    <React.Fragment key={index} >
                                        <Grid item xs={6}>
                                            <CustomTypography
                                                text={item.title}
                                                sx={{
                                                    mb: 1,
                                                    color: '#000000',
                                                    fontSize: '14px',
                                                    fontWeight: '500',
                                                    lineHeight: '18px',
                                                    fontStyle: 'normal',
                                                    fontFamily: "Noto Sans",
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <CustomTypography
                                                text={item.value}
                                                sx={{
                                                    mb: 1,
                                                    color: '#7A7A7A',
                                                    fontSize: '14px',
                                                    fontWeight: '400',
                                                    lineHeight: '18px',
                                                    fontStyle: 'normal',
                                                    fontFamily: "Noto Sans",
                                                }}
                                            />
                                        </Grid>
                                    </React.Fragment>
                                )
                            })
                        }

                    </Grid>
                </Grid>
                <Grid item xs={4}>
                    <Box sx={{
                        p: 2,
                    }}>
                        {
                            customer_address.map((item, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <CustomTypography
                                            text={item.title}
                                            sx={{
                                                mb: 1,
                                                mt: index ? 3 : 0,
                                                color: '#000000',
                                                fontSize: '14px',
                                                fontWeight: '700',
                                                lineHeight: '18px',
                                                fontStyle: 'normal',
                                                fontFamily: "Noto Sans",
                                            }}
                                        />
                                        <CustomTypography
                                            text={item.value ? item.value : '-'}
                                            sx={{
                                                mb: 1,
                                                color: '#7A7A7A',
                                                fontSize: '12px',
                                                fontWeight: '500',
                                                lineHeight: '18px',
                                                fontStyle: 'normal',
                                                fontFamily: "Noto Sans",
                                            }}
                                        />
                                    </React.Fragment>
                                )
                            })
                        }
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}
const InvoiceDetails = (props) => {
    const { recurring_invoice_data } = props

    return (
        <Box>
            <Grid container>
                {
                    recurring_invoice_data.map((item, index) => {
                        return (
                            <Grid item xs={4} key={index} sx={{
                                p: 2,
                                borderLeft: item.borderLeft,
                                borderRight: item.borderRight,
                            }}
                            >
                                <Box>
                                    <CustomTypography
                                        text={item.title}
                                        sx={{
                                            mb: 1,
                                            color: '#000000',
                                            fontSize: '14px',
                                            fontWeight: '700',
                                            lineHeight: '18px',
                                            fontStyle: 'normal',
                                            fontFamily: "Noto Sans",
                                        }} />
                                    <CustomTypography
                                        text={item.value}
                                        sx={{
                                            mb: 1,
                                            color: '#7A7A7A',
                                            fontSize: '14px',
                                            fontWeight: '500',
                                            lineHeight: '18px',
                                            fontStyle: 'normal',
                                            fontFamily: "Noto Sans",
                                        }}
                                    />
                                </Box>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </Box>
    )
}

const ChildInvoicesList = () => {

    let navigate = useNavigate();
    let location = useLocation();
    const params = getQueryParams(location.search);
    const dispatch = Actions.getDispatch(React.useContext);


    const [page, setPage] = React.useState(1);
    const [results, setResults] = React.useState([]);
    const [sendMail, setSendMail] = React.useState(false);
    const [sendMailId, setSendMailId] = React.useState(null);

    const [pagination, setPagination] = React.useState({
        next: undefined,
        count: undefined,
        previous: undefined,
        number_of_pages: undefined,
    });

    const [state, setState] = React.useState({
        id: '',
        title: '',
        open: false,
        condition: '',
        maxWidth: 'lg',
        fullWidth: true,
    })

    const stateChangeHandler = (title, condition, maxWidth, url, deleteMessage) => {
        setState({
            url: url,
            open: true,
            title: title,
            fullWidth: true,
            maxWidth: maxWidth,
            condition: condition,
            deleteMessage: deleteMessage,
        })
    }

    const [filters, setFilters] = React.useState({
        sort_by: 'invoice_date',
        sort_order: 'D'
    })

    let body = { business_id: getBusinessInfo().id, ...filters }
    React.useEffect(() => {
        getApiResults(body, page);
        // eslint-disable-next-line
    }, [page, filters]);

    const getApiResults = async (body, page) => {
        let res = await apiAction({
            method: 'post',
            dispatch: dispatch,
            navigate: navigate,
            url: get_list_of_invoice(page),
            data: { ...body, recurring_invoice_id: Number(params.id), },
        })
        if (res?.success) {
            setPagination({
                ...pagination,
                next: res?.result.next,
                count: res?.result.count,
                previous: res?.result.previous,
                number_of_pages: res?.result.number_of_pages,
            });
            setResults(res?.result.result);
            setLoader(dispatch, Actions, false);
        } else {
            setLoader(dispatch, Actions, false);
        }
    }

    const webSocketResponse = CommonWebSocket();
    React.useEffect(() => {
        if (webSocketResponse) {
            let webSocketData = JSON.parse(webSocketResponse.data)
            if (webSocketData.document_type === "invoice") {
                let index = results.findIndex((item) => item.id === webSocketData.object_id);

                if (webSocketData.event === "send_document" && index !== null && index >= 0) {
                    if (webSocketData.event_success) {
                        results[index]['status'] = webSocketData.object_status
                        setResults([...results])
                        stateChangeManager(dispatch, Actions, true, "success", `Email delivered successfully for ${webSocketData.object_number}`);
                    } else {
                        stateChangeManager(dispatch, Actions, true, "error", `Email delivery failed for ${webSocketData.object_number}`);
                    }
                }

                if (webSocketData.event === "delete_document" && index !== null && index >= 0) {
                    if (webSocketData.event_success) {
                        stateChangeManager(dispatch, Actions, true, "success", `Invoice ${webSocketData.object_number} deleted successfully`);
                        getApiResults(body, page);
                    } else {
                        stateChangeManager(dispatch, Actions, true, "error", `Failed to delete Invoice ${webSocketData.object_number}`);
                    }
                }

                if (webSocketData.event === "update_document" && index !== null && index >= 0) {
                    if (webSocketData.event_success) {
                        stateChangeManager(dispatch, Actions, true, "success", `Invoice ${webSocketData.object_number} updated successfully`);
                        getApiResults(body, page);
                    } else {
                        stateChangeManager(dispatch, Actions, true, "error", `Failed to update Invoice ${webSocketData.object_number}`);
                    }
                }

            }
        }
    }, [webSocketResponse])


    const onSelectAction = (action, item) => {
        setLoader(dispatch, Actions, true)
        if (action === "edit") {
            setLoader(dispatch, Actions, false)
            navigate(routesName.invoicingSalesInvoiceAdd.path + "?id=" + item.id)
            // AnalyticsEvent("edit_recurring_invoice", { module: "Sales", sub_module: "Recurring Invoice", screen: "View Recurring Invoice", recurring_invoice_id: item.id })
        }
        if (action === "approve") {
            approveInvoice(item)
            // AnalyticsEvent("approve_child_invoice", { module: "Sales", sub_module: "Recurring Invoice", screen: "View Recurring Invoice", recurring_invoice_id: item.id })
        }
        if (action === "send") {
            sendInvoice(item.id)
            // AnalyticsEvent("send_child_invoice", { module: "Sales", sub_module: "Recurring Invoice", screen: "View Recurring Invoice", recurring_invoice_id: item.id })
        }
        if (action === "print") {
            printInvoice(item)
            // AnalyticsEvent("print_child_invoice", { module: "Sales", sub_module: "Recurring Invoice", screen: "View Recurring Invoice", recurring_invoice_id: item.id })
        }
        if (action === "view") {
            navigate(routesName.invoicingSalesInvoiceView.path + "?id=" + item.id)
            // AnalyticsEvent("view_invoice", { module: "Sales", sub_module: "Invoices", screen: "Recurring Invoice", recurring_invoice_id: item.id })
        }
        if (action === "download") {
            downloadInvoice(item)
            // AnalyticsEvent("download_child_invoice", { module: "Sales", sub_module: "Recurring Invoice", screen: "View Recurring Invoice", recurring_invoice_id: item.id })
        }
        if (action === "record_payment") {
            setLoader(dispatch, Actions, false)
            recordPayment(item)
            // AnalyticsEvent("record_payment_child_invoice", { module: "Sales", sub_module: "Recurring Invoice", screen: "View Recurring Invoice", recurring_invoice_id: item.id })
        }
    }
    const recordPayment = async (invoice) => {
        let data = await apiAction({
            method: 'post',
            url: retrieve_invoice(invoice.id),
            data: { business_id: getBusinessInfo().id },
        })
        if (data) {
            navigate(routesName.invoicingPaymentsReceived.path, { state: data.result })
        }
    }
    const sendInvoice = async (id) => {
        setSendMailId(id);
        setSendMail(true);
    }
    const approveInvoice = async (invoice) => {
        let data = await apiAction({
            method: 'post',
            url: retrieve_invoice(invoice.id),
            data: { business_id: getBusinessInfo().id },
        })
        if (data) {
            const approveInvoiceRes = await apiAction({ url: update_invoice(invoice.id), method: 'POST', data: { is_draft: false, customer_id: data.result.customer_id, business_id: getBusinessInfo().id } })
            if (approveInvoiceRes.success) {
                getApiResults(body, page);
                stateChangeManager(dispatch, Actions, true, 'success', approveInvoiceRes.status);
            }
            else {
                stateChangeManager(dispatch, Actions, true, 'error', approveInvoiceRes.status);
            }
        }
    }
    const printInvoice = async (invoice) => {
        let data = await apiAction({
            method: 'post',
            url: retrieve_invoice(invoice.id),
            data: { business_id: getBusinessInfo().id },
        })
        if (data) {
            apiBlobResponse({
                url: export_invoice(), data: { ...data.result, terms_and_conditions_id: data.result.terms_and_conditions, payment_term_id: data.result.payment_term }, onSuccess: () => {
                    setLoader(dispatch, Actions, false)
                }
            })
        }
    }
    const downloadInvoice = async (invoice) => {
        let data = await apiAction({
            method: 'post',
            url: retrieve_invoice(invoice.id),
            data: { business_id: getBusinessInfo().id },
        })
        if (data) {
            apiHandleDownload({
                url: export_invoice(), data: { ...data.result, terms_and_conditions_id: data.result.terms_and_conditions, payment_term_id: data.result.payment_term }, filename: data.result.invoice_number, onSuccess: () => {
                    setLoader(dispatch, Actions, false)
                }
            })
        }
    }
    const toActionText = (status) => {
        let text = ''
        if (status === 'draft') {
            text = 'Approve'
        } else if (status === 'unsent') {
            text = 'Send'
        } else if (status === 'paid') {
            text = 'View'
        } else if (status === 'sent' || status === 'overdue' || status === 'partially_paid') {
            text = 'Record payment'
        }
        return text
    }
    const onClickActionText = (status, item) => {
        if (status === 'draft') {
            onSelectAction("approve", item)

        } else if (status === 'unsent') {
            onSelectAction("send", item)
        } else if (status === 'paid') {
            onSelectAction("view", item)
        } else if (status === 'sent' || status === 'overdue' || status === 'partially_paid') {
            if (item.total_amount === 0) {
                stateChangeManager(dispatch, Actions, true, 'error', `Record payment is not possible for invoice with zero amount`);
            } else {
                onSelectAction("record_payment", item)
            }
        }
    }

    return (
        <React.Fragment>
            <CustomDialog
                state={state}
                setState={setState}
            />
            <CommonPreviewShare
                open={sendMail}
                type={'invoice'}
                id={sendMailId}
                file_key={'invoice_number'}
                export_Url={export_invoice}
                retrieve_Url={retrieve_invoice}
                setOpen={(value) => { setSendMail(value); getApiResults(body, page); }}
            />
            <CustomTable sx={{}}>
                <ListHeaders filters={filters} setFilters={setFilters} />
                <ListBody data={results} stateChangeHandler={stateChangeHandler} toActionText={toActionText} onClickActionText={onClickActionText} />
            </CustomTable>
            <CustomPagination
                page={page}
                count={pagination.number_of_pages}
                onChange={(event, newValue) => setPage(newValue)}
            />
        </React.Fragment>
    )
}

const ListHeaders = (props) => {
    return (
        <CustomTableHead>
            <CustomTableRow>
                <CustomTableHeadCell style={{ width: 100 }} align='center' ><span >Status</span></CustomTableHeadCell>
                <CustomTableHeadCell ><span >Date</span></CustomTableHeadCell>
                <CustomTableHeadCell ><span >Number</span></CustomTableHeadCell>
                <CustomTableHeadCell ><span >Customer</span></CustomTableHeadCell>
                <CustomTableHeadCell align='right' ><span >Total</span></CustomTableHeadCell>
                <CustomTableHeadCell align='right' ><span >Amount Due</span></CustomTableHeadCell>
                <CustomTableHeadCell align='right' ><span >Actions</span></CustomTableHeadCell>
            </CustomTableRow>
        </CustomTableHead>
    )
}

const ListBody = (props) => {
    const { data = [], toActionText, onClickActionText } = props;

    return (
        <CustomTableBody>
            {data.map((item, index) => {
                return (
                    <CustomTableRow key={index}>
                        <CustomTableBodyCell sx={{}} align='center'>
                            <Status status={item.status} />
                            {item.is_recurring ?
                                null
                                :
                                <CustomTypography
                                    text={`Manually Added`}
                                    sx={{
                                        color: '#7A7A7A',
                                        fontSize: '12px',
                                        fontWeight: '500',
                                        lineHeight: '18px',
                                        fontStyle: 'normal',
                                        fontFamily: "Noto Sans",
                                    }}
                                />
                            }
                        </CustomTableBodyCell>
                        <CustomTableBodyCell sx={{}}  ><span>{moment(item.invoice_date).format(getDateFormat())}</span></CustomTableBodyCell>
                        <CustomTableBodyCell sx={{}}  ><InvoiceViewLink id={item.id} title={item.invoice_number} /></CustomTableBodyCell>
                        <CustomTableBodyCell sx={{}}  ><span>{item.customer_display_name}</span></CustomTableBodyCell>
                        <CustomTableBodyCell sx={{}} align='right'>
                            <span>{<CurrencyFormatter amount={item.total_amount} currency={item.currency_code} />}</span><br />
                            <CurrencyFormatterWithExchangeRate currency_code={item.currency_code} amount={item.total_amount} exchange_rate={item.exchange_rate} />
                        </CustomTableBodyCell>
                        <CustomTableBodyCell sx={{}} align='right'>
                            <span>{<CurrencyFormatter amount={item.due_amount} currency={item.currency_code} />}</span><br />
                            <CurrencyFormatterWithExchangeRate currency_code={item.currency_code} amount={item.due_amount} exchange_rate={item.exchange_rate} />
                        </CustomTableBodyCell>
                        <CustomTableBodyCell sx={{}} align='right'>
                            <CustomTypography
                                id={'action_status_btn' + index}
                                dataTestId={'action_status_btn' + index}
                                text={toActionText(item.status)}
                                onClick={() => onClickActionText(item.status, item)}
                                sx={{
                                    fontSize: '12px',
                                    fontWeight: 700,
                                    color: '#2464EB',
                                    cursor: 'pointer',
                                    textTransform: 'none',
                                    ":hover": { textDecoration: 'underline' },
                                }}
                            />
                        </CustomTableBodyCell>
                    </CustomTableRow>
                )
            })}
        </CustomTableBody>
    )
}
