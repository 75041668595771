import React, { useContext} from "react";
import {vendor } from "../../../../../../utils/Constant";
import * as Actions from '../../../../../../state/Actions'
import { routesName } from "../../../../../../config/routesName";
import FileUpload from "../../../../bulkStatementUpload/mappings/FileUpload";
import { get_fields_for_mapping,import_party_sample} from "../../../../../../api/urls";
const VendorsImportFile = (props) => {
    let dispatch = Actions.getDispatch(useContext)
    return(

        <FileUpload
        isDateFormat={false}
        mapping_for={ vendor }
        sample_file_download_url={import_party_sample}
        model_fields_url={ get_fields_for_mapping(vendor) } 
        nextPagePath={routesName.invoicingPurchaseVendorImportMapping.path} 
        />
        
    )
}

export default VendorsImportFile