import React from 'react';
import Input from '../../../common/Input';
import { useNavigate } from 'react-router-dom';
import DateRange from '../../../common/DateRange';
import * as Actions from '../../../../state/Actions';
import CommonTDSandTCS from '../../../common/CommonTDSandTCS';
import { getBusinessInfo } from '../../../../config/cookiesInfo';
import CurrencyFormatter from '../../../common/CurrencyFormatter';
import { totalTdsTcsAmount } from '../sales/invoices/InvoiceManager';
import CustomTypography from '../../../custom/typography/CustomTypography';


import {
    Box,
    Grid,
} from '@mui/material';

const Dropdown = DateRange;

const CommonCalculation = (props) => {
    let navigate = useNavigate();
    const { currency } = getBusinessInfo();
    const dispatch = Actions.getDispatch(React.useContext);

    const { isFormSubmitted, isTcsTds = false, data, setData, subTotal, totalAmount, finalPayment, totalDiscount, accounts, totalTax, isCurrency = false, dropdown_key = 'customer_id' } = props;


    const handleTaxTypeChange = (value) => {
        setData({
            ...data,
            tds_tcs_id: null,
            tds_tcs_amount: 0,
            tds_tcs_choice: value,
            selected_tds_tcs: null,
            total_amount: calculateTotalAmount(totalAmount, totalTax, totalDiscount),
        });
    };

    const handleSetValue = (value) => {
        if (value) {
            const tds_tcs_amount = totalTdsTcsAmount(
                value,
                data.tds_tcs_choice,
                data.is_amount_tax_inclusive ? totalAmount : subTotal,
                totalDiscount,
                finalPayment
            );

            setData({
                ...data,
                tds_tcs_id: value.id,
                selected_tds_tcs: value,
                tds_tcs_amount: tds_tcs_amount,
                total_amount: calculateTotalAmountBasedOnChoice(tds_tcs_amount),
            });
        } else {
            setData({
                ...data,
                tds_tcs_id: null,
                tds_tcs_amount: 0,
                selected_tds_tcs: null,
                total_amount: calculateTotalAmount(totalAmount, totalTax, totalDiscount),
            });
        }
    };

    const calculateTotalAmount = (totalAmount, totalTax, totalDiscount) => {
        return (totalAmount + totalTax - totalDiscount).toFixed(2);
    };

    const calculateTotalAmountBasedOnChoice = (tds_tcs_amount) => {
        let newTotalAmount;

        if (data.tds_tcs_choice === "tds") {
            newTotalAmount = (subTotal - totalDiscount - tds_tcs_amount + (data.has_rcm ? 0 : totalTax)).toFixed(2);
        } else if (data.tds_tcs_choice === "tcs") {
            newTotalAmount = (totalAmount + (data.has_rcm ? 0 : totalTax) - totalDiscount + tds_tcs_amount).toFixed(2);
        } else {
            newTotalAmount = (totalAmount + (data.has_rcm ? 0 : totalTax) - totalDiscount).toFixed(2);
        }

        return newTotalAmount;
    };

    return (
        <Box style={{ textAlign: "-webkit-right" }}>
            <Grid item container xs={12} sm={10} sx={{ background: "#FFF7DC", border: "1px solid #FFF0C0" }}>
                <Grid container item xs={12} sm={12} sx={{ p: 2, borderBottom: "1px solid #FFF0C0" }}>
                    <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <CustomTypography
                            text={<span>{"Sub Total"} {data.tax_applied === 'inclusive' && <span style={{ fontSize: '10px' }}>{'(Tax Inclusive)'}</span>}</span>}
                            sx={{
                                color: "#000000",
                                fontSize: "12px",
                                fontWeight: "400",
                                lineHeight: "16px",
                                fontStyle: "normal",
                                fontFamily: "Noto Sans",
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: "right" }}>
                        <CustomTypography
                            id={"subtotal"}
                            dataTestId={"subtotal"}
                            // text={<span style={{ fontWeight: '700', fontFamily: 'Noto Sans', }}>{parseFloat(subTotal).toFixed(2)}</span>}
                            text={<CurrencyFormatter amount={subTotal} currency={data.currency_code} />}
                            sx={{
                                color: "#000000",
                                fontSize: "12px",
                                fontWeight: "700",
                                lineHeight: "14px",
                                fontStyle: "normal",
                                fontFamily: "Noto Sans",
                            }}
                        />
                    </Grid>
                </Grid>

                {totalDiscount > 0 && (
                    <>
                        <Grid container item xs={12} sm={12} sx={{ p: 2, borderBottom: "1px solid #FFF0C0" }}>
                            <Grid item xs={12} sm={6} sx={{ textAlign: "left" }}>
                                <CustomTypography
                                    text={<span>{"Discount"} {data.tax_applied === 'inclusive' && <span style={{ fontSize: '10px' }}>(Applied on <CurrencyFormatter amount={data.subtotal} currency={data.currency_code} />)</span>}</span>}
                                    sx={{
                                        color: "#000000",
                                        fontSize: "12px",
                                        fontWeight: "400",
                                        lineHeight: "16px",
                                        fontStyle: "normal",
                                        fontFamily: "Noto Sans",
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} sx={{ textAlign: "right" }}>
                                <CustomTypography
                                    id={"discount"}
                                    dataTestId={"discount"}
                                    // text={<span style={{ fontWeight: '700', fontFamily: 'Noto Sans', }}>{parseFloat(totalDiscount).toFixed(2)}</span>}
                                    text={<CurrencyFormatter amount={totalDiscount} currency={data.currency_code} />}
                                    sx={{
                                        color: "#000000",
                                        fontSize: "12px",
                                        fontWeight: "700",
                                        lineHeight: "14px",
                                        fontStyle: "normal",
                                        fontFamily: "Noto Sans",
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} sx={{ p: 2, borderBottom: "1px solid #FFF0C0" }}>
                            <Grid item xs={12} sm={6} sx={{ textAlign: "left" }}>
                                <CustomTypography
                                    text={<span>{'Total'} <span style={{ fontSize: '10px' }}>{'(after discount)'}</span></span>}
                                    sx={{
                                        color: "#000000",
                                        fontSize: "12px",
                                        fontWeight: "400",
                                        lineHeight: "16px",
                                        fontStyle: "normal",
                                        fontFamily: "Noto Sans",
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} sx={{ textAlign: "right" }}>
                                <CustomTypography
                                    id={"after_discount"}
                                    dataTestId={"after_discount"}
                                    // text={<span style={{ fontWeight: '700', fontFamily: 'Noto Sans', }}>{parseFloat(parseFloat(totalAmount) - parseFloat(totalDiscount)).toFixed(2)}</span>}
                                    text={<CurrencyFormatter amount={totalAmount - totalDiscount} currency={data.currency_code} />}
                                    sx={{
                                        color: "#000000",
                                        fontSize: "12px",
                                        fontWeight: "700",
                                        lineHeight: "14px",
                                        fontStyle: "normal",
                                        fontFamily: "Noto Sans",
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </>
                )}
                {!data.has_rcm && Object.keys(accounts).map((tax, index) => {
                    return (
                        <Grid key={index} container item xs={12} sx={{ p: 2, borderBottom: "1px solid #FFF0C0" }}>
                            <Grid item xs={6} sx={{ textAlign: "left" }}>
                                <CustomTypography
                                    text={tax.replace("input_", "Input ")}
                                    sx={{
                                        color: "#000000",
                                        fontSize: "12px",
                                        fontWeight: "400",
                                        lineHeight: "16px",
                                        fontStyle: "normal",
                                        fontFamily: "Noto Sans",
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} sx={{ textAlign: "right" }}>
                                <CustomTypography
                                    id={"gst_" + index}
                                    dataTestId={"gst_" + index}
                                    // text={<span style={{ fontWeight: '700', fontFamily: 'Noto Sans', }}>{parseFloat(accounts[tax]).toFixed(2)}</span>}
                                    text={<CurrencyFormatter amount={accounts[tax]} currency={data.currency_code} />}
                                    sx={{
                                        color: "#000000",
                                        fontSize: "12px",
                                        fontWeight: "700",
                                        lineHeight: "14px",
                                        fontStyle: "normal",
                                        fontFamily: "Noto Sans",
                                    }}
                                />
                            </Grid>
                        </Grid>
                    );
                })}
                {isTcsTds ? (
                    <Grid container item xs={12} sx={{ p: 2, borderBottom: "1px solid #FFF0C0" }} style={{
                        alignItems: "center",
                        justifyItems: "center",
                        alignContent: "space-evenly",
                        justifyContent: "space-evenly",
                    }}>
                        <Grid item xs={12} sm={6} sx={{ textAlign: "left" }}>

                            <CommonTDSandTCS
                                value={data.tds_tcs_id}
                                tax_type={data.tds_tcs_choice}
                                validation={isFormSubmitted && data.tds_tcs_choice !== "no_tax" && !data.tds_tcs_id}
                                set_tax_type={(value) => handleTaxTypeChange(value)}
                                setValue={(value) => handleSetValue(value)}
                            />

                            {/* <CommonTDSandTCS
                                value={data.tds_tcs_id}
                                tax_type={data.tds_tcs_choice}
                                validation={isFormSubmitted && data.tds_tcs_choice !== "no_tax" && !data.tds_tcs_id
                                }
                                set_tax_type={(value) =>
                                    setData({
                                        ...data,
                                        tds_tcs_id: null,
                                        tds_tcs_amount: 0,
                                        tds_tcs_choice: value,
                                        selected_tds_tcs: null,
                                        total_amount: (totalAmount + totalTax - totalDiscount).toFloat(),
                                    })
                                }
                                setValue={(value) => {
                                    if (value) {
                                        data.tds_tcs_id = value.id;
                                        data.selected_tds_tcs = value;
                                        let tds_tcs_amount = totalTdsTcsAmount(
                                            value,
                                            data.tds_tcs_choice,
                                            data.is_amount_tax_inclusive ? totalAmount : subTotal,
                                            totalDiscount,
                                            finalPayment
                                        );
                                        data.tds_tcs_amount = tds_tcs_amount;
                                        data.total_amount = data.tds_tcs_choice === "tds" ? (subTotal - totalDiscount - tds_tcs_amount + (data.has_rcm ? 0 : totalTax)).toFloat()
                                            : data.tds_tcs_choice === "tcs" ? (totalAmount + (data.has_rcm ? 0 : totalTax) - totalDiscount + tds_tcs_amount).toFloat()
                                                : (totalAmount + (data.has_rcm ? 0 : totalTax) - totalDiscount).toFloat();
                                    } else {
                                        data.tds_tcs_id = null;
                                        data.tds_tcs_amount = 0;
                                        data.selected_tds_tcs = null;
                                        data.total_amount = (totalAmount + totalTax - totalDiscount).toFloat();
                                    }
                                    setData({ ...data });
                                }}
                            /> */}
                        </Grid>

                        <Grid item xs={12} sm={6} sx={{ textAlign: "right" }}>
                            <CustomTypography
                                text={<CurrencyFormatter amount={data.tds_tcs_amount} currency={data.currency_code} />}
                                sx={{
                                    color: "#000000",
                                    fontSize: "12px",
                                    fontWeight: "700",
                                    lineHeight: "14px",
                                    fontStyle: "normal",
                                    fontFamily: "Noto Sans",
                                }}
                            />
                        </Grid>
                    </Grid>
                ) : null}

                <Grid container item xs={12} sx={{ p: 2, }}>
                    <Grid item xs={6} sx={{ textAlign: "left", alignItems: 'center' }}>
                        <CustomTypography
                            text={<span>{'Total'} <span style={{ fontSize: '10px' }}>({data.currency_code ? data.currency_code : currency.currency_code})</span></span>}
                            sx={{
                                color: "#000000",
                                fontSize: "12px",
                                fontWeight: "400",
                                lineHeight: "16px",
                                fontStyle: "normal",
                                fontFamily: "Noto Sans",
                            }}
                        />
                    </Grid>

                    <Grid item xs={6} sx={{ textAlign: "right", alignItems: 'center' }}>
                        <CustomTypography
                            id={"total"}
                            dataTestId={"total"}
                            text={<CurrencyFormatter amount={finalPayment} currency={data.currency_code} />}
                            sx={{
                                color: "#000000",
                                fontSize: "12px",
                                fontWeight: "700",
                                lineHeight: "14px",
                                fontStyle: "normal",
                                fontFamily: "Noto Sans",
                            }}
                        />
                    </Grid>
                </Grid>
                {
                    isCurrency && data.currency_code && (currency && currency.currency_code !== data.currency_code) ?
                        <Grid container item xs={12} sx={{ p: 2, borderTop: "1px solid #FFF0C0" }}>
                            <Grid item xs={6} sx={{ textAlign: "left", alignItems: 'center' }}>
                                <CustomTypography
                                    id={"total_with_exchange_rate"}
                                    dataTestId={"total_with_exchange_rate"}
                                    text={<span>{'Total'} <span style={{ fontSize: '10px' }}>({currency && currency.currency_code} at {data.exchange_rate !== null ? data.exchange_rate : ''})</span></span>}
                                    sx={{
                                        color: "#000000",
                                        fontSize: "12px",
                                        fontWeight: "700",
                                        lineHeight: "14px",
                                        fontStyle: "normal",
                                        fontFamily: "Noto Sans",
                                    }}
                                />
                            </Grid>

                            <Grid item xs={6} sx={{ textAlign: "right", alignItems: 'center' }}>
                                <CustomTypography
                                    id={"total"}
                                    dataTestId={"total"}
                                    text={<CurrencyFormatter amount={finalPayment * data.exchange_rate} currency={currency.currency_code} />}
                                    sx={{
                                        color: "#000000",
                                        fontSize: "12px",
                                        fontWeight: "700",
                                        lineHeight: "14px",
                                        fontStyle: "normal",
                                        fontFamily: "Noto Sans",
                                    }}
                                />
                            </Grid>
                        </Grid>
                        : null
                }
            </Grid>
        </Box>
    )
}

export default CommonCalculation;

