import React from "react";
import { Dropdown } from "./CustomComponent";
import { useNavigate } from 'react-router-dom';
import { routesName } from '../../../../config/routesName';
import CustomButton from "../../../custom/button/CustomButton";
import CustomTypography from "../../../custom/typography/CustomTypography";



import {
    List,
    Box,
    Divider,
    Drawer,
    styled,
    ListItem,
    IconButton,
} from "@mui/material";
import { Close } from "@mui/icons-material";

function DrawerComponent(props) {
    const { openDrawer, setOpenDrawer } = props
    const navigate = useNavigate();

    const onCloseHandler = () => {
        setOpenDrawer(false)
    }

    const handleFeatureClick = (id) => {
        navigate('/landing'+routesName.landingPage.path,{state:{id:id}});
        const anchor = document.querySelector(id);
        if (anchor) {
            anchor.scrollIntoView({
                block: "center",
            });
        }
    };

    const handleFeatureClickTab = (tab) => {
        navigate('/landing'+routesName.landingPage.path,{state:{tab:tab}});
        const anchor = document.querySelector('#feature_section');
        if (anchor) {
            document.querySelector(tab).click();
            anchor.scrollIntoView({
                block: "center",
            });
        }
    };
    
    const features = [
        { name: 'Dashboard', onClick: () => handleFeatureClickTab('#tabs_0') },
        { name: 'Invoicing', onClick: () => handleFeatureClickTab('#tabs_1') },
        { name: 'Expenses', onClick: () => handleFeatureClickTab('#tabs_2') },
        { name: 'Accounting', onClick: () => handleFeatureClickTab('#tabs_3') },
        { name: 'Reports', onClick: () => handleFeatureClickTab('#tabs_4') },
        { name: 'GST', onClick: () => handleFeatureClickTab('#tabs_5') },
        { name: 'Inventory', onClick: () => handleFeatureClickTab('#tabs_6') },
        { name: 'Tally Integration', onClick: () => handleFeatureClickTab('#tabs_7') },
    
        
        // { name: 'Contact', onClick: () => handleFeatureClick('#tabs_2') },
        // { name: 'Multi Business', onClick: () => handleFeatureClick('#tabs_3') },
        // { name: 'Multi Currency', onClick: () => handleFeatureClick('#tabs_4') },
        // { name: 'Multi User', onClick: () => handleFeatureClick('#tabs_5') },
        // { name: 'Import Data', onClick: () => handleFeatureClick('#tabs_6') },
        // { name: 'Share Data', onClick: () => handleFeatureClick('#tabs_7') },
        // { name: 'Analysis', onClick: () => handleFeatureClick('#tabs_10') },
        // { name: 'Support', onClick: () => handleFeatureClick('#tabs_11') },
        // { name: 'Estimates', onClick: () => handleFeatureClick('#tabs_12') },
        // { name: 'Bills', onClick: () => handleFeatureClick('#tabs_14') },
        // { name: 'Sales Order', onClick: () => handleFeatureClick('#tabs_15') },
        // { name: 'Purchase Order', onClick: () => handleFeatureClick('#tabs_16') },
        // { name: 'Audit Trail', onClick: () => handleFeatureClick('#tabs_17') },
        // { name: 'Mobile Apps', onClick: () => handleFeatureClick('#tabs_18') },
        // { name: 'Recurring Invoice', onClick: () => handleFeatureClick('#tabs_19') },
        // { name: 'Customer Statements', onClick: () => handleFeatureClick('#tabs_21') },
        // { name: 'Statements Upload', onClick: () => handleFeatureClick('#tabs_22') },
        // { name: 'Guide', onClick: () => handleFeatureClick('#tabs_23') },
    ]
    
    
    
    const resources = [
        { name: 'Guid', onClick: () => { } },
        { name: 'Blog', onClick: () => { } },
        { name: 'Help', onClick: () => { } },
    
    ]
    return (
        <Drawer
        anchor='right'
        open={openDrawer}
        onClose={onCloseHandler}
        variant={openDrawer?"persistent":'temporary'}
        >
            <DrawerHeader>
                <IconButton onClick={onCloseHandler} sx={{":hover": { bgcolor: '#e0e0e0' }}}>
                    <Close sx={{ color: '#2464EB', fontSize: 40 }} />
                </IconButton>
            </DrawerHeader>
            <DrawerHeader>
                <Buttons />
            </DrawerHeader>
            <Divider />
            <List>
                <ListItem >
                    <CustomTypography
                        text={'Analyze'}
                        onClick={() => { handleFeatureClick('#analysis_section'); onCloseHandler() }}
                        sx={{
                            mb: 1,
                            fontWeight: 700,
                            fontSize: '16px',
                            color: '#4A5381',
                            display: 'inline',
                            cursor: 'pointer',
                            '&:hover': {
                                color: '#2464EB',
                            },
                        }}
                    />
                </ListItem >
                <Divider />
                <ListItem>
                    <Dropdown
                        title={'Features'}
                        options={features}
                        onCloseHandler={onCloseHandler}
                        onNavigate={()=>navigate('/landing'+routesName.landingPage.path)}

                        sx={{
                            mb: 0.5,
                            mt: 0.5,
                            fontWeight: 700,
                            fontSize: '16px',
                            color: '#4A5381',
                            display: 'inline',
                            cursor: 'pointer',
                            '&:hover': {
                                color: '#2464EB',
                            },
                        }}
                    />
                </ListItem>
                <Divider />
                <ListItem >
                    <CustomTypography
                        text={'Pricing'}
                        onClick={() => { handleFeatureClick('#pricing_section'); onCloseHandler() }}
                        sx={{
                            mb: 1,
                            fontWeight: 700,
                            fontSize: '16px',
                            color: '#4A5381',
                            display: 'inline',
                            cursor: 'pointer',
                            '&:hover': {
                                color: '#2464EB',
                            },
                        }}
                    />
                </ListItem >
                <Divider />
                <ListItem>
                    <Dropdown
                        isGrid={false}
                        title={'Resources'}
                        options={resources}
                        onCloseHandler={onCloseHandler}
                        onNavigate={()=>navigate('/landing'+routesName.landingPage.path)}
                       />
                </ListItem>
                <Divider />

                {/*
                <ListItem >
                    <CustomTypography
                        text={'Review'}
                        onClick={() => { handleFeatureClick('#review_section'); onCloseHandler() }}
                        sx={{ mb: 1, cursor: 'pointer', color: '#000000', fontSize: '18px', fontWeight: 700, }}
                    />
                </ListItem >
                <Divider />
                <ListItem >
                    <CustomTypography
                        text={'FAQ'}
                        onClick={() => { handleFeatureClick('#faq_section'); onCloseHandler() }}
                        sx={{ mb: 1, cursor: 'pointer', color: '#000000', fontSize: '18px', fontWeight: 700, }}
                    />
                </ListItem > 
                <Divider />
                */}
            </List>
        </Drawer>
    );
}

const Buttons = () => {
    const navigate = useNavigate();

    return (
        <Box>
            <CustomButton
                sx={{}}
                btnLabel='Login'
                variant="outlined"
                id={'landing_page_sign_in'}
                dataTestId={'landing_page_sign_in'}
                onClick={() => { navigate("/auth" + routesName.signIn.path) }}
            />
            <CustomButton
                sx={{ ml: 1, }}
                btnLabel='Sign Up'
                variant="contained"
                id={'landing_page_sign_up'}
                dataTestId={'landing_page_sign_up'}
                onClick={() => { navigate("/auth" + routesName.signUp.path) }}
            />
        </Box>
    )
}

export const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-start"
}));
export default DrawerComponent;


