import React from 'react';
import Input from '../../../../common/Input';
import CustomTitle from '../../../../common/CustomTitle';
import CustomButton from '../../../../custom/button/CustomButton';
import { MetaData } from '../../../../custom/tooltip/CustomTooltip';
import CustomDefaultDropdown from '../../../../custom/dropdown/CustomDefaultDropdown';

import {
    isNumeric,
    limitDecimalPlaces,
} from '../../../../../utils/Utils';

import {
    spanTag,
    CustomRadio,
    CustomHeading,
    CustomCheckbox,
    CustomHelperText,
} from './common/CustomElement';

import {
    CustomContainer,
    CustomTitleContainer,
} from '../../../../custom/container/CustomContainer';

import {
    Box,
    Grid,
} from '@mui/material'

const GeneralPreferences = (props) => {
    const { initial_data, onSaveHandler } = props;

    const [data, setData] = React.useState({ ...initial_data });

    const onHandleChange = (key_name, value) => {
        setData((previous) => ({ ...previous, ...data, general: { ...data.general, [key_name]: value } }))
    }

    return (
        data &&
        <div>
            <CustomTitleContainer>
                <Grid container spacing={0} style={{
                    alignItems: "center",
                    justifyItems: "center",
                    alignContent: "space-evenly",
                    justifyContent: "space-evenly",
                }}>
                    <Grid item xs={6}>
                        <CustomTitle title={'General Preferences'} />
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: "end", alignSelf: "center" }}>
                        <CustomButton
                            sx={{}}
                            btnLabel="Save"
                            variant="contained"
                            id={"save_general_preferences"}
                            onClick={() => { onSaveHandler(data) }}
                            dataTestId={"save_general_preferences"}
                        />
                    </Grid>
                </Grid>
            </CustomTitleContainer>
            <CustomContainer maxWidth={"400"} sx={{ height: 'calc(100vh - 134px)', }}>
                <Box sx={{ p: 2, pt: 1 }}>
                    {/* <Modules data={data} setData={setData} onHandleChange={onHandleChange} /> */}
                    <WorkingWeek data={data} setData={setData} onHandleChange={onHandleChange} />
                    <SendWeeklyReport data={data} setData={setData} onHandleChange={onHandleChange} />
                    <Attachment data={data} setData={setData} onHandleChange={onHandleChange} />
                    <Discount data={data} setData={setData} onHandleChange={onHandleChange} />
                    <TaxInclusive data={data} setData={setData} onHandleChange={onHandleChange} />
                    <SalesPerson data={data} setData={setData} onHandleChange={onHandleChange} />
                    <BillableAndExpenses data={data} setData={setData} onHandleChange={onHandleChange} />
                </Box>
            </CustomContainer>

        </div>
    )
}

export default GeneralPreferences;

const Modules = (props) => {
    const { data, onHandleChange } = props;
    return (
        <Box sx={{ pb: 2, mb: 2, borderBottom: '1px solid #e0e0e0' }}>
            <CustomHeading label={`Select the modules you would like to enable.`} />
            <CustomCheckbox id={`check_box_bill_of_supply`} dataTestId={`check_box_bill_of_supply`} checked={false} label={`Bill Of Supply`} /><br />
            <CustomCheckbox id={`check_box_estimate`} dataTestId={`check_box_estimate`} checked={false} label={`Estimates`} /><br />
            <CustomCheckbox id={`check_box_sales_order`} dataTestId={`check_box_sales_order`} checked={false} label={`Sales Orders`} /><br />
            <CustomCheckbox id={`check_box_delivery_challan`} dataTestId={`check_box_delivery_challan`} checked={false} label={`Delivery Challans`} /><br />
            <CustomCheckbox id={`check_box_purchase_order`} dataTestId={`check_box_purchase_order`} checked={false} label={`Purchase Orders`} /><br />
            <CustomCheckbox id={`check_box_recurring_invoice`} dataTestId={`check_box_recurring_invoice`} checked={false} label={`Recurring Invoice`} /><br />
            <CustomCheckbox id={`check_box_credit_note`} dataTestId={`check_box_credit_note`} checked={false} label={`Credit Note`} /><br />
        </Box>
    )
}

const WorkingWeek = (props) => {
    const { data, onHandleChange } = props;

    const results = data.general.week_start_day_list.map((item) => { return { name: item } })
    const value = results.find((item) => item.name === data.general.start_week_day)

    return (
        <Box sx={{ pb: 2, mb: 2, borderBottom: '1px solid #e0e0e0' }}>
            <CustomHeading label={`Set the first day of your work week`} />
            <Box sx={{width:200}}>
            <CustomDefaultDropdown
                value={value}
                placeholder=''
                labelKey='name'
                fullWidth={false}
                results={results}
                validation={false}
                valueLabelKey={'name'}
                disabledCloseIcon={true}
                id={'start_week_day_info_dropdown'}
                dataTestId={'start_week_day_info_dropdown'}
                setValue={(selectedData) => { if (selectedData) { onHandleChange('start_week_day', selectedData.name) } }}
            />
            </Box>
        </Box>
    )
}

const SendWeeklyReport = (props) => {
    const { data, onHandleChange } = props;

    return (
        <Box sx={{ pb: 2, mb: 2, borderBottom: '1px solid #e0e0e0' }}>
            <CustomHeading label={`Weekly Summary report`} />
            <CustomCheckbox id={`send_weekly_summary_report`} dataTestId={`send_weekly_summary_report`} checked={data && data.general.send_weekly_summary_report} onClickedHandler={(value) => onHandleChange('send_weekly_summary_report', !value)} label={`Send Weekly Summary report`} /><br />
            <Box sx={{
                p: 3,
                borderRadius: 1,
                background: '#f6f6f6',
            }}>
                <CustomHelperText label={<span>All users with <strong>Admin</strong> access will receive a summary of all the business transactions for every week. </span>} />
            </Box>
        </Box>
    )
}

const Attachment = (props) => {
    const { data, onHandleChange } = props;
    return (
        <Box sx={{ pb: 2, mb: 2, borderBottom: '1px solid #e0e0e0' }}>
            <CustomHeading label={`PDF Attachment`} />
            <CustomCheckbox id={`check_box_attach_pdf_for_email`} dataTestId={`check_box_attach_pdf_for_email`} checked={data && data.general.pdf_attachments.attach_pdf_for_email} onClickedHandler={(value) => onHandleChange('pdf_attachments', data ? { ...data.general.pdf_attachments, attach_pdf_for_email: !value } : {})} label={`Attach PDF file while emailing.`} /><br />
            {/* <CustomCheckbox checked={data && data.general.pdf_attachments.is_encrypt_pdf} onClickedHandler={(value) => onHandleChange('pdf_attachments', data ? { ...data.general.pdf_attachments, is_encrypt_pdf: !value } : {})} label={`I would like to encrypt the PDF files that I send.`} /><br /> */}
        </Box>
    )
}

const Discount = (props) => {
    const { data, onHandleChange } = props;

    return (
        <Box sx={{ pb: 2, mb: 2, borderBottom: '1px solid #e0e0e0' }}>
            <CustomHeading label={`Do you give discounts?`} />
            <CustomRadio id={`at_line_item_level`} dataTestId={`at_line_item_level`} checked={data && data.general.discount[`at_line_item_level`]} onClickedHandler={(value) => onHandleChange('discount', data ? { ...data.general.discount, [`at_line_item_level`]: true, [`do_not_give_discount`]: false } : {})} label={`At line item level`} /><br />
            <CustomRadio id={`do_not_give_discount`} dataTestId={`do_not_give_discount`} checked={data && data.general.discount[`do_not_give_discount`]} onClickedHandler={(value) => onHandleChange('discount', data ? { ...data.general.discount, [`do_not_give_discount`]: true, [`at_line_item_level`]: false } : {})} label={`I don't give discounts`} /><br />
        </Box>
    )
}

const TaxInclusive = (props) => {
    const { data, onHandleChange } = props;

    return (
        <Box sx={{ pb: 2, mb: 2, borderBottom: '1px solid #e0e0e0' }}>
            <CustomHeading label={`Do you sell your items at rates inclusive of Tax ?`} />
            <CustomRadio id={`tax_of_item_rate_tax_inclusive`} dataTestId={`tax_of_item_rate_tax_inclusive`} checked={data && data.general.tax_of_item_rate[`tax_inclusive`]} onClickedHandler={(value) => onHandleChange('tax_of_item_rate', data ? { ...data.general.tax_of_item_rate, [`tax_inclusive`]: true, [`tax_exclusive`]: false, [`no_tax`]: false } : {})} label={`Tax Inclusive`} /><br />
            <CustomRadio id={`tax_of_item_rate_tax_exclusive`} dataTestId={`tax_of_item_rate_tax_exclusive`} checked={data && data.general.tax_of_item_rate[`tax_exclusive`]} onClickedHandler={(value) => onHandleChange('tax_of_item_rate', data ? { ...data.general.tax_of_item_rate, [`tax_inclusive`]: false, [`tax_exclusive`]: true, [`no_tax`]: false } : {})} label={`Tax Exclusive`} /><br />
            <CustomRadio id={`tax_of_item_rate_no_tax`} dataTestId={`tax_of_item_rate_no_tax`} checked={data && data.general.tax_of_item_rate[`no_tax`]} onClickedHandler={(value) => onHandleChange('tax_of_item_rate', data ? { ...data.general.tax_of_item_rate, [`tax_inclusive`]: false, [`tax_exclusive`]: false, [`no_tax`]: true } : {})} label={`No Tax`} /><br />
        </Box>
    )
}

const SalesPerson = (props) => {
    const { data, onHandleChange } = props;
    return (
        <Box sx={{ pb: 2, mb: 2, borderBottom: '1px solid #e0e0e0' }}>
            <CustomHeading label={`Sales Person`} />
            <CustomCheckbox id={`add_field_for_sales_person`} dataTestId={`add_field_for_sales_person`} checked={data && data.general.add_field_for_sales_person} onClickedHandler={(value) => onHandleChange('add_field_for_sales_person', !value)} label={`I want to add a field for salesperson`} /><br />
        </Box>
    )
}

const BillableAndExpenses = (props) => {
    const { data, onHandleChange } = props;

    return (
        <Box sx={{ pb: 2, mb: 2, borderBottom: '1px solid #e0e0e0' }}>
            <CustomHeading label={`Billable Bills and Expenses`} />
            <Box sx={{ mt: 2 }}>
                <Input
                    id={"input_mark_up_percent"}
                    dataTestId={"input_mark_up_percent"}
                    item={{
                        type: "number",
                        required: true,
                        placeholder: "",
                        fullWidth: false,
                        inputProps: { maxLength: 4 },
                        title: <span style={{ alignItems: 'center', display: 'flex' }}>{spanTag('Default Mark up by(%)')} <MetaData placement='right' meta_data={`Enter a default markup percentage to mark up the bills and expenses while invoicing.`} /></span>,
                        value: data && data.general.default_markup_percentage !== null ? data.general.default_markup_percentage : '',
                        onChange: (e) => {
                            if (isNumeric(e.target.value)) {
                                if (0 < parseFloat(e.target.value) && parseFloat(e.target.value) <= 100) {
                                    onHandleChange('default_markup_percentage', Math.abs(limitDecimalPlaces(e.target.value)));
                                }
                            } else {
                                onHandleChange('default_markup_percentage', null);

                            }
                        },
                    }}
                />
            </Box>

        </Box>
    )
}