import React from 'react';
import { apiAction } from '../../../../../api/api';
import CustomTitle from '../../../../common/CustomTitle';
import { CommonTermsAndConditions } from './common/CustomElement';
import CustomButton from '../../../../custom/button/CustomButton';
import CustomDialog from '../../../../custom/dialog/CustomDialog';
import { getBusinessInfo } from '../../../../../config/cookiesInfo';
import { get_list_of_terms_and_conditions } from '../../../../../api/urls';
import CustomTypography from '../../../../custom/typography/CustomTypography';

import {
    CustomRadio,
    CustomHeading,
    CustomCheckbox,
    CustomHelperText,
} from './common/CustomElement';

import {
    CustomContainer,
    CustomTitleContainer,
} from '../../../../custom/container/CustomContainer';

import {
    Box,
    Grid,
} from '@mui/material';

const TermsAndConditionsPreferences = (props) => {
    const { initial_data, onSaveHandler, type } = props;

    const [data, setData] = React.useState({ ...initial_data });
    const [openRef, setOpenRef] = React.useState()

    const [results, setResults] = React.useState([]);
    React.useEffect(() => {
        apiResults()
        // eslint-disable-next-line
    }, [])

    const apiResults = async () => {
        let res = await apiAction({
            method: 'post',
            url: get_list_of_terms_and_conditions(),
            data: { business_id: getBusinessInfo().id },
        })
        if (res?.success) {
            setResults(res?.result)
        } else {

        }
    }

    const [state, setState] = React.useState({
        id: '',
        title: '',
        open: false,
        condition: '',
        maxWidth: 'lg',
        fullWidth: true,
    })

    const addHandler = () => {
        setState({
            open: true,
            maxWidth: 'sm',
            fullWidth: true,
            title: 'Add Terms and Conditions',
            condition: 'create_terms_and_conditions',
        })
    }
    const updateHandler = () => {
        setState({
            open: true,
            maxWidth: 'sm',
            fullWidth: true,
            results: results,
            title: 'Update Terms and Conditions',
            condition: 'update_terms_and_conditions',
        })
    }
    const onTermAndConditionAdd = (item) => {
        results.push(item);
        setResults([...results]);
    }
    const onTermAndConditionUpdate = (item) => {
        let index = results.findIndex((value) => value.id === item.id);
        if (index >= 0) {
            results[index] = item;
            setResults([...results]);
        }
    }
    return (
        data &&
        <div>
            <CustomDialog
                state={state}
                setState={setState}
                onAddItem={onTermAndConditionAdd}
                onTermAndConditionUpdate={onTermAndConditionUpdate}
            />
            <CustomTitleContainer>
                <Grid container spacing={0} style={{
                    alignItems: "center",
                    justifyItems: "center",
                    alignContent: "space-evenly",
                    justifyContent: "space-evenly",
                }}>
                    <Grid item xs={6}>
                        <CustomTitle title={'Terms and Conditions Preferences'} />
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: "end", alignSelf: "center" }}>
                        
                        <CustomButton
                            sx={{ }}
                            variant="outlined"
                            id={"add_terms_and_condition"}
                            onClick={() => { addHandler() }}
                            btnLabel="Add New Terms and Conditions"
                            dataTestId={"add_terms_and_condition"}
                        />
                        <CustomButton
                            sx={{ ml: 2}}
                            variant="contained"
                            id={"edit_terms_and_condition"}
                            onClick={() => { updateHandler() }}
                            btnLabel="Edit Terms and Conditions"
                            dataTestId={"edit_terms_and_condition"}
                        />

                    </Grid>
                </Grid>
            </CustomTitleContainer>
            <CustomContainer maxWidth={"400"} sx={{ height: 'calc(100vh - 134px)', }}>
                <Box sx={{ p: 2, pt: 0 }}>
                    <Box sx={{ mt:2,pb: 2, mb: 2, borderBottom: '1px solid #e0e0e0', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Box>
                            <CustomHeading label={`Assign Terms and Conditions`} />
                            <CustomTypography
                                text={<CustomHelperText label={`Set the default Terms and conditions. The selected terms and conditions will be pre‑selected in the creation form.`} />}
                                sx={{
                                    mb: 2,
                                    mt: 2,
                                    color: "#7A7A7A",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    lineHeight: "18px",
                                    fontStyle: "normal",
                                    fontFamily: "Noto Sans",
                                }}
                            />
                        </Box>
                        <CustomButton
                            sx={{}}
                            btnLabel="Save"
                            variant="contained"
                            id={"save_terms_and_condition_preferences"}
                            dataTestId={"save_terms_and_condition_preferences"}
                            onClick={() => { onSaveHandler(data) }}
                        />
                    </Box>
                    {type === 'sales' &&
                        <React.Fragment>
                            <EstimatePreference setOpenRef={setOpenRef} openRef={openRef} results={results} setResults={setResults} data={data} setData={setData} />
                            <SalesOrderPreference setOpenRef={setOpenRef} openRef={openRef} results={results} setResults={setResults} data={data} setData={setData} />
                            <DeliveryChallanPreference setOpenRef={setOpenRef} openRef={openRef} results={results} setResults={setResults} data={data} setData={setData} />
                            <InvoicePreference setOpenRef={setOpenRef} openRef={openRef} results={results} setResults={setResults} data={data} setData={setData} />
                            <RecurringInvoicePreference setOpenRef={setOpenRef} openRef={openRef} results={results} setResults={setResults} data={data} setData={setData} />
                            <CreditNotePreference setOpenRef={setOpenRef} openRef={openRef} results={results} setResults={setResults} data={data} setData={setData} />
                        </React.Fragment>
                    }

                    {type === 'purchase' &&
                        <React.Fragment>
                            <DebitNotePreference setOpenRef={setOpenRef} openRef={openRef} results={results} setResults={setResults} data={data} setData={setData} />
                        </React.Fragment>
                    }
                </Box>
            </CustomContainer>

        </div>
    )
}

export default TermsAndConditionsPreferences;

const EstimatePreference = (props) => {
    const { openRef, setOpenRef, data, setData, results, setResults, } = props;

    const onHandleChange = (key_name, value) => {
        setData((previous) => ({ ...previous, ...data, estimate: { ...data.estimate, [key_name]: value } }))
    }
    return (
        <Box sx={{ pb: 2, mb: 2, pt: 2, borderBottom: '1px solid #e0e0e0' }}>
            <Grid container sx={{ alignItems: 'center' }}>
                <Grid item xs={2}>
                    <CustomHeading label={<span style={{ fontSize: '14px', }}>Estimate</span>} />
                </Grid>
                <Grid item xs={10} sx={{ mt: -1.5, }}>
                    <CommonTermsAndConditions
                        openRef={openRef}
                        results={results}
                        componentRef='estimate'
                        setResults={setResults}
                        setOpenRef={(value) => { setOpenRef(value) }}
                        value={data && data.estimate && data.estimate.terms_and_condition_id}
                        setValue={(value) => { onHandleChange('terms_and_condition_id', value); }}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

const SalesOrderPreference = (props) => {
    const { openRef, setOpenRef, data, setData, results, setResults, } = props;

    const onHandleChange = (key_name, value) => {
        setData((previous) => ({ ...previous, ...data, sales_order: { ...data.sales_order, [key_name]: value } }))
    }

    return (
        <Box sx={{ pb: 2, mb: 2, pt: 2, borderBottom: '1px solid #e0e0e0' }}>
            <Grid container sx={{ alignItems: 'center' }}>
                <Grid item xs={2}>
                    <CustomHeading label={<span style={{ fontSize: '14px', }}>Sales Order</span>} />
                </Grid>
                <Grid item xs={10} sx={{ mt: -1.5, }}>
                    <CommonTermsAndConditions
                        openRef={openRef}
                        componentRef='sales_order'
                        setOpenRef={(value) => { setOpenRef(value) }}

                        results={results}
                        setResults={setResults}
                        setValue={(value) => { onHandleChange('terms_and_condition_id', value) }}
                        value={data && data.sales_order && data.sales_order.terms_and_condition_id}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}


const DeliveryChallanPreference = (props) => {
    const { openRef, setOpenRef, data, setData, results, setResults, } = props;

    const onHandleChange = (key_name, value) => {
        setData((previous) => ({ ...previous, ...data, delivery_challan: { ...data.delivery_challan, [key_name]: value } }))
    }
    return (
        <Box sx={{ pb: 2, mb: 2, pt: 2, borderBottom: '1px solid #e0e0e0' }}>
            <Grid container sx={{ alignItems: 'center' }}>
                <Grid item xs={2}>
                    <CustomHeading label={<span style={{ fontSize: '14px', }}>Delivery Challan</span>} />
                </Grid>
                <Grid item xs={10} sx={{ mt: -1.5, }}>
                    <CommonTermsAndConditions
                        openRef={openRef}
                        componentRef='delivery_challan'
                        setOpenRef={(value) => { setOpenRef(value) }}
                        results={results}
                        setResults={setResults}
                        setValue={(value) => { onHandleChange('terms_and_condition_id', value) }}
                        value={data && data.delivery_challan && data.delivery_challan.terms_and_condition_id}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

const InvoicePreference = (props) => {
    const { openRef, setOpenRef, data, setData, results, setResults, } = props;

    const onHandleChange = (key_name, value) => {
        setData((previous) => ({ ...previous, ...data, invoice: { ...data.invoice, [key_name]: value } }))
    }
    return (
        <Box sx={{ pb: 2, mb: 2, pt: 2, borderBottom: '1px solid #e0e0e0' }}>
            <Grid container sx={{ alignItems: 'center' }}>
                <Grid item xs={2}>
                    <CustomHeading label={<span style={{ fontSize: '14px', }}>Invoice</span>} />
                </Grid>
                <Grid item xs={10} sx={{ mt: -1.5, }}>
                    <CommonTermsAndConditions
                        openRef={openRef}
                        componentRef='invoice'
                        setOpenRef={(value) => { setOpenRef(value) }}
                        results={results}
                        setResults={setResults}
                        value={data && data.invoice && data.invoice.terms_and_condition_id}
                        setValue={(value) => { onHandleChange('terms_and_condition_id', value) }}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}


const RecurringInvoicePreference = (props) => {
    const { openRef, setOpenRef, data, setData, results, setResults, } = props;

    const onHandleChange = (key_name, value) => {
        setData((previous) => ({ ...previous, ...data, recurring_invoice: { ...data.recurring_invoice, [key_name]: value } }))
    }
    return (
        <Box sx={{ pb: 2, mb: 2, pt: 2, borderBottom: '1px solid #e0e0e0' }}>
            <Grid container sx={{ alignItems: 'center' }}>
                <Grid item xs={2}>
                    <CustomHeading label={<span style={{ fontSize: '14px', }}>Recurring Invoice</span>} />
                </Grid>
                <Grid item xs={10} sx={{ mt: -1.5, }}>
                    <CommonTermsAndConditions
                        openRef={openRef}
                        componentRef='recurring_invoice'
                        setOpenRef={(value) => { setOpenRef(value) }}
                        results={results}
                        setResults={setResults}
                        setValue={(value) => { onHandleChange('terms_and_condition_id', value) }}
                        value={data && data.recurring_invoice && data.recurring_invoice.terms_and_condition_id}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}


const CreditNotePreference = (props) => {
    const { openRef, setOpenRef, data, setData, results, setResults } = props;

    const onHandleChange = (key_name, value) => {
        setData((previous) => ({ ...previous, ...data, credit_note: { ...data.credit_note, [key_name]: value } }))
    }
    return (
        <Box sx={{ pb: 2, mb: 2, pt: 2, borderBottom: '1px solid #e0e0e0' }}>
            <Grid container sx={{ alignItems: 'center' }}>
                <Grid item xs={2}>
                    <CustomHeading label={<span style={{ fontSize: '14px', }}>Credit Note</span>} />
                </Grid>
                <Grid item xs={10} sx={{ mt: -1.5, }}>
                    <CommonTermsAndConditions
                        openRef={openRef}
                        componentRef='credit_note'
                        setOpenRef={(value) => { setOpenRef(value) }}
                        results={results}
                        setResults={setResults}
                        setValue={(value) => { onHandleChange('terms_and_condition_id', value) }}
                        value={data && data.credit_note && data.credit_note.terms_and_condition_id}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

const DebitNotePreference = (props) => {
    const { openRef, setOpenRef, data, setData, results, setResults, } = props;

    const onHandleChange = (key_name, value) => {
        setData((previous) => ({ ...previous, ...data, debit_note: { ...data.debit_note, [key_name]: value } }))
    }
    return (
        <Box sx={{ pb: 2, mb: 2, pt: 2, borderBottom: '1px solid #e0e0e0' }}>
            <Grid container sx={{ alignItems: 'center' }}>
                <Grid item xs={2}>
                    <CustomHeading label={<span style={{ fontSize: '14px', }}>Debit Note</span>} />
                </Grid>
                <Grid item xs={10} sx={{ mt: -1.5, }}>
                    <CommonTermsAndConditions
                        openRef={openRef}
                        componentRef='debit_note'
                        setOpenRef={(value) => { setOpenRef(value) }}
                        results={results}
                        setResults={setResults}
                        setValue={(value) => { onHandleChange('terms_and_condition_id', value) }}
                        value={data && data.debit_note && data.debit_note.terms_and_condition_id}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

