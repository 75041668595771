import React from 'react';
import moment from 'moment/moment';
import Refund from '../../refund/Refund';
import * as Actions from '../../../../../state/Actions';
import { useNavigate, useLocation } from 'react-router-dom';
import { getQueryParams } from '../../../../../utils/Utils';
import { getBusinessInfo } from '../../../../../config/cookiesInfo';
import { eventsNames,AnalyticsEvent } from '../../../../../firebase/firebaseAnalytics';

import {
  apiAction,
} from '../../../../../api/api';

import {
  setLoader,
} from '../../../../../utils/Utils';

import {
  retrieve_credit_note,
} from '../../../../../api/urls';

const RefundCreditNote = () => {

  let location = useLocation();
  const navigate = useNavigate();
  const params = getQueryParams(location.search)
  const dispatch = Actions.getDispatch(React.useContext);


  const [basicInformation, setBasicInformation] = React.useState();

  React.useEffect(() => {
    if (params.id) {
      const retrieveCreditNote = async () => {
        setLoader(dispatch, Actions, true);
        let res = await apiAction({
          method: 'post',
          url: retrieve_credit_note(params.id),
          data: { business_id: getBusinessInfo().id },
        })
        if (res?.success) {
          setBasicInformation(res?.result);
          setLoader(dispatch, Actions, false);
        }
      }
      retrieveCreditNote()
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div>
      {
        basicInformation &&
        <Refund
          initial_refund_data={{
            note: null,
            account_id: null,
            payment_type: "pay",
            debit_note_id: null,
            selected_account: null,
            payment_method: "cash",
            credit_note_id: params.id,
            business_id: getBusinessInfo().id,
            party_id: basicInformation.customer_id,
            refund_date: moment().format('YYYY-MM-DD'),

            currency_id: basicInformation.currency_id,
            currency_code: basicInformation.currency_code,
            exchange_rate: basicInformation.exchange_rate,
            
            credit_remaining: basicInformation.credit_remaining,
            total_refund_amount: basicInformation.credit_remaining,
          }}

          redirect={() => navigate(-1)}
          title={basicInformation.credit_note_number}
          AnalyticsEvent={(params)=>{AnalyticsEvent(eventsNames.categories.CREDIT_NOTES,params)}}
        />
      }
    </div>
  )
}

export default RefundCreditNote