import React from 'react';
import moment from 'moment';
import { Box } from '@mui/material';
import CurrencyFormatter from '../../../common/CurrencyFormatter';
import { NoDataComponent } from '../../../common/NoDataComponent';
import { sumOfArrayByKey,getDateFormat } from '../../../../utils/Utils';
import CustomTypography from '../../../custom/typography/CustomTypography';

import {
    CustomTable,
    CustomTableRow,
    CustomTableBody,
    CustomTableHead,
    CustomTableHeadCell,
    CustomTableBodyCell,
    CustomTableContainer,
} from '../../../custom/table/CustomTable';

const ViewAdjustedAccount = (props) => {
    const { results, migrationDate } = props


    return (
        <div style={{ paddingLeft: '24px', paddingRight: '24px', paddingBottom: '20px' }}>
            <CustomTypography
                text={migrationDate && `Migration Date: ${moment(migrationDate.previous_migration_date).format(getDateFormat())}`}
                sx={{
                    color: '#000000',
                    fontSize: '14px',
                    fontWeight: '700',
                    lineHeight: '18px',
                    fontStyle: 'normal',
                    fontFamily: "Noto Sans",
                }}
            />
            <CustomTableContainer>
                <CustomTable sx={{ mt: 2 }}>
                    <ViewAdjustedAccountHeader />
                    <ViewAdjustedAccountBody data={results} />
                </CustomTable>
                <Box sx={{ pt: !results.length ? 10 : 0 }} >
                    <NoDataComponent left='0%' top='0%' position={'relative'} data={results} />
                </Box>
            </CustomTableContainer>
        </div>
    )
}

export default ViewAdjustedAccount;

const ViewAdjustedAccountHeader = () => {
    return (
        <CustomTableHead>
            <CustomTableRow>
                <CustomTableHeadCell style={{ width: 200 }}><span>Accounts</span></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 200 }} align='right'><span>Debit</span></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 200 }} align='right'><span>Credit</span></CustomTableHeadCell>
            </CustomTableRow>
        </CustomTableHead>
    )
}
const ViewAdjustedAccountBody = (props) => {
    const { data } = props
    return (
        <CustomTableBody>
            {
                data.map((item, index) => {
                    return (
                        item.account_type ?
                            <React.Fragment key={index}>
                                <CustomTableRow key={index}>
                                    <CustomTableBodyCell sx={{ border: 'none', pb: '0px !important', }}>
                                        <CustomTypography
                                            text={item.account_type.capitalize()}
                                            sx={{
                                                color: '#000000',
                                                fontSize: '16px',
                                                fontWeight: '700',
                                                lineHeight: '18px',
                                                fontStyle: 'normal',
                                                fontFamily: "Noto Sans",
                                            }}
                                        />
                                    </CustomTableBodyCell>
                                </CustomTableRow>
                                {item.transactions.map((item, index) => {
                                    return (
                                        <CustomTableRow key={index}>
                                            <CustomTableBodyCell sx={{ fontWeight: '500' }}><span>{item.account_name}</span></CustomTableBodyCell>
                                            <CustomTableBodyCell sx={{ fontWeight: '500' }} align='right' ><span>
                                                {
                                                    item.debit ?
                                                        <CurrencyFormatter amount={item.debit} currency={item.currency_code} />
                                                        : '-'
                                                }
                                            </span></CustomTableBodyCell>
                                            <CustomTableBodyCell sx={{ fontWeight: '500' }} align='right'><span>
                                                {
                                                    item.credit ?
                                                        <CurrencyFormatter amount={item.credit} currency={item.currency_code} />
                                                        : '-'
                                                }
                                            </span></CustomTableBodyCell>
                                        </CustomTableRow>
                                    )
                                })
                                }
                            </React.Fragment>
                            : null
                    )
                })
            }
            {data.length ?
                <CustomTableRow sx={{ background: '#FFF7DC', borderTop: '1px solid #FFF0C0', borderBottom: '1px solid #FFF0C0' }}>
                    <CustomTableBodyCell align='right'>
                        <CustomTypography
                            text={'Total'}
                            sx={{
                                color: '#000000',
                                fontSize: '14px',
                                fontWeight: '700',
                                lineHeight: '18px',
                                fontStyle: 'normal',
                                textTransform: 'none',
                                fontFamily: "Noto Sans",
                            }}
                        />
                    </CustomTableBodyCell>
                    <CustomTableBodyCell align='right'>
                        <CurrencyFormatter amount={sumOfArrayByKey(data, 'total_debit_amount')} />
                    </CustomTableBodyCell>
                    <CustomTableBodyCell align='right'>
                        <CurrencyFormatter amount={sumOfArrayByKey(data, 'total_credit_amount')} />
                    </CustomTableBodyCell>
                </CustomTableRow>
                : null
            }
        </CustomTableBody>
    )
}