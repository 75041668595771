import React from 'react';
import { HOST } from '../config/config';
import { getToken, getBusinessInfo } from '../config/cookiesInfo';

export const CommonWebSocket = () => {
  const [lastMessage, setLastMessage] = React.useState(null);
  const [connectionStatus, setConnectionStatus] = React.useState('connecting'); // Initialize with 'connecting'

  React.useEffect(() => {
    const hostName = HOST.replace('https', 'wss').replace('http', 'ws');
    const socketUrl = `${hostName}/ws/document_status/?business_id=${getBusinessInfo().id}&token=${getToken()}`;

    const socket = new WebSocket(socketUrl);

    socket.onopen = () => {
      setConnectionStatus('open'); // Update connection status on open
    };

    socket.onmessage = (event) => {
      setLastMessage(event);
    };

    socket.onclose = () => {
      setConnectionStatus('closed'); // Update connection status on close
    };

    socket.onerror = (error) => {
      console.error('WebSocket error:', error);
      setConnectionStatus('error'); // Update connection status on error
    };

    return () => {
      socket.close();
    };
  }, []); // Empty dependency array to ensure this effect runs only once
  
  console.log('connectionStatus', connectionStatus);
  return lastMessage;
};







