import React from "react";
import { routesName } from "../../../../../config/routesName";
import Transactions from "../../../bulkStatementUpload/mappings/Transactions";
import {import_item, import_party, validate_file_for_import_item, validate_party_import_file } from "../../../../../api/urls";
import { items } from "../../../../../utils/Constant";


const ItemsPreview = (props) => {

    return (
        <Transactions 
            title={"Items"}
            isPerformValidation={true} 
            role={items} 
            url={import_item()} 
            validationUrl={validate_file_for_import_item()} 
            nextPagePath={routesName.invoicingItems.path} 
            onFileErrorPath={routesName.invoicingItemsFileUpload.path} 
            onCancelPath={routesName.invoicingItemsMapping.path}  
        />
    )
}

export default ItemsPreview