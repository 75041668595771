import React, { useContext, useState } from 'react'
import Input from '../../../common/Input'
import { useNavigate } from 'react-router-dom';
import { apiAction } from '../../../../api/api';
import DateRange from '../../../common/DateRange';
import * as Actions from '../../../../state/Actions';
import CustomTabs from '../../../custom/tabs/CustomTabs'
import AsyncDropdown from '../../../common/AsyncDropdown';
import { stateChangeManager } from '../../../../utils/Utils'
import CustomButton from '../../../custom/button/CustomButton'
import CommonAlertBox from '../../invoicing/common/CommonAlertBox';
import CustomTypography from '../../../custom/typography/CustomTypography'
import { CustomButtonContainer, CustomContainer } from '../../../custom/container/CustomContainer'
import { get_chart_of_account_nested_nodes, create_sourced_transaction_preference } from '../../../../api/urls'

import {
  Grid,
  styled,
  DialogActions,
} from '@mui/material'
import CustomDialog from '../../../custom/dialog/CustomDialog';

const BootstrapDialogActions = styled(DialogActions)(({ theme }) => ({
  marginTop: '-3px',
  marginBottom: '-40px',
  borderTop: '2px solid #F2F2F2',
}));

const Dropdown = DateRange;
const PreferenceAndCategorized = (props) => {
  const { data, onClose, fetchPreferences } = props;
  const { selectionData, chartOfAccounts, setChartOfAccounts, categorizeTransactions, fetchChartOfAccounts } = data

  let navigate = useNavigate();
  const dispatch = Actions.getDispatch(useContext);

  const ButtonWrapper = data ? BootstrapDialogActions : CustomButtonContainer;


  const [body, setBody] = useState(selectionData);
  const [alert, setAlert] = React.useState(false);
  const [isRequired, setIsRequired] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [itemIndex, setItemIndex] = React.useState(null);

  const [state, setState] = React.useState({
    id: '',
    title: '',
    open: false,
    condition: '',
    maxWidth: 'lg',
    compulsory: '',
    fullWidth: true,
  });


  const onSave = (is_rule_save) => {
    console.log('==body', body)
    setIsFormSubmitted(true);
    setIsRequired(is_rule_save);
    if (is_rule_save && !body.name) {
      stateChangeManager(dispatch, Actions, true, 'error', "Please enter categorization rule name")
      return
    }
    if (!body.debit_account_id) {
      stateChangeManager(dispatch, Actions, true, 'error', "Please select bank account")
      return
    }
    if (!body.credit_account_id) {
      stateChangeManager(dispatch, Actions, true, 'error', "Please select other account")
      return
    }
    setAlert(true);

  }

  const savePreference = async () => {
    body.transaction_type = body.selected_transaction_type.name
    const preferenceRes = await apiAction({
      data: body,
      method: 'post',
      navigate: navigate,
      dispatch: dispatch,
      url: create_sourced_transaction_preference()
    })

    if (preferenceRes.success) {
      stateChangeManager(dispatch, Actions, true, "success", preferenceRes.status)
      body.preference_id = preferenceRes.result.id
      categorizeTransactions(body);
      if (fetchPreferences) { fetchPreferences() }
    } else {
      stateChangeManager(dispatch, Actions, true, "error", preferenceRes.status)
    }

  }

  const addButtonHandler = (title, condition, maxWidth, compulsory, key) => {
    setState({
      open: true,
      title: title,
      fullWidth: true,
      maxWidth: maxWidth,
      condition: condition,
      filterValue: compulsory,
      account_type: compulsory === 'Bank'? 'ASSET' : null
    })
    setItemIndex(key)
  }

  const onAddAccount = (value) => {
    chartOfAccounts.push(value);
    body[itemIndex] = value ? value.id : null
    setChartOfAccounts([...chartOfAccounts]);
    setBody({ ...body, })
  }

  return (
    <div>
      <CustomDialog
        state={state}
        setState={setState}
        onAddAccount={(account) => onAddAccount(account)}
      />
      <CommonAlertBox
        open={alert}
        setOpen={setAlert}
        title={`Confirmation`}
        cancelLabelText={"Review"}
        saveButtonLabel={"Continue"}
        onSaveAction={() => {
          if (isRequired) {
            savePreference();
          } else {
            categorizeTransactions(body);
            // setTimeout(() => { onClose() }, 1000);
          }
        }}
        message={`Please note that the reversal of these entries will be possible manually.`}
      />
      <CustomContainer maxWidth={"400"} sx={{}}>
        <Grid container spacing={3} style={{ paddingLeft: '20px', paddingRight: '20px', paddingBottom: '32px', display: 'flex' }}>
          <Grid item xs={12}>
            <Input
              autoFocus={true}
              id={'input_categorization_rule_name'}
              dataTestId={'input_categorization_rule_name'}
              validation={isRequired && isFormSubmitted && !body.name}
              item={{
                type: 'text',
                required: true,
                fullWidth: true,
                value: body.name,
                title: 'Categorization Rule Name',
                placeholder: 'Enter Categorization Rule Name',
                onChange: (e) => {
                  setBody({ ...body, name: e.target.value })
                }
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              id={'input_search_description'}
              dataTestId={'input_search_description'}
              item={{
                type: 'text',
                disabled: true,
                required: true,
                fullWidth: true,
                placeholder: '',
                value: body.description,
                title: 'Search Description',
                onChange: (e) => {
                  setBody({ ...body, description: body.description })
                }
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              id={'input_transaction_type'}
              dataTestId={'input_transaction_type'}
              item={{
                type: 'text',
                disabled: true,
                value: body.selected_transaction_type ? body.selected_transaction_type.name : "",
                required: true,
                placeholder: '',
                fullWidth: true,
                title: 'Transaction Type',
                onChange: (e) => { }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              id={'input_note'}
              dataTestId={'input_note'}
              item={{
                rows: 4,
                type: 'text',
                label: 'Note',
                fullWidth: true,
                multiline: true,
                value: body.note,
                variant: "standard",
                placeholder: 'Write your note...',
                onChange: (e) => {
                  setBody({ ...body, note: e.target.value })
                }
              }}
            />
          </Grid>
        </Grid>
        <CustomTabs
          id={'tabs_'}
          dataTestId={'tabs_'}
          value={0}
          tabs={[{ name: 'Categorization Rule' }]}
        />
        <TransactionForm
          body={body}
          setBody={setBody}
          chartOfAccounts={chartOfAccounts}
          isFormSubmitted={isFormSubmitted}
          addButtonHandler={addButtonHandler}
        />
      </CustomContainer>

      <ButtonWrapper>
        <Buttons onSave={onSave} onCancel={() => props.onClose()} />
      </ButtonWrapper>
    </div>
  )
}

export default PreferenceAndCategorized;

const Buttons = (props) => {
  const { onSave, onCancel } = props
  return (
    <Grid container >
      <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'start', }}><CustomButton id={'cancel_btn'} dataTestId={'cancel_btn'} variant='outlined' sx={{ textTransform: 'none', '&:hover': { backgroundColor: '#e8f3ff' } }} btnLabel='Cancel' onClick={onCancel} /></Grid>
      <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'right', }}>
        <CustomButton id={'categorize_btn'} dataTestId={'categorize_btn'} variant='contained' sx={{ textTransform: 'none', }} btnLabel={'Categorize'} onClick={() => onSave(false)} />
        <CustomButton id={'save_categorize_btn'} dataTestId={'save_categorize_btn'} variant='contained' sx={{ ml: 1, textTransform: 'none', }} btnLabel={'Save & Categorize'} onClick={() => onSave(true)} />
      </Grid>
    </Grid>
  )
}

const TransactionForm = ({ body, setBody, chartOfAccounts, isFormSubmitted, addButtonHandler }) => {

  const renderAsyncDropdown = (label, accountKey, isFilterApplied) => {
    const parentAccountName = isFilterApplied ? null : 'Bank';
    return (
      <Dropdown
        isLastGroup={true}
        isfilterOptions='includes'
        validation={isFormSubmitted && !body[accountKey]}
        id={`${accountKey}_dropdown`}
        dataTestId={`${accountKey}_dropdown`}
        label=""
        item={{
          sx: { width: 400 },
        }}
        placeholder='Select Account'
        value={(body[`${accountKey}_id`] && chartOfAccounts) ? chartOfAccounts.find((i) => i.id === body[`${accountKey}_id`]) : null}
        results={
          chartOfAccounts && (isFilterApplied
            ? chartOfAccounts
            : chartOfAccounts.filter((item) => item.parent_account_name === 'Bank'))
        }
        setValue={(event, value) => {
          setBody({ ...body, [accountKey]: value, [`${accountKey}_id`]: value?.id })
        }}
        addButton={{
          title: `+ Add ${label} accounts`,
          onClick: () => addButtonHandler(`Add ${label} accounts`, 'create_sub_account', 'sm', parentAccountName, `${accountKey}_id`),
        }}
      />
    )
  };

  return (
    <Grid container item xs={10} spacing={0} style={{
      paddingLeft: '24px',
      paddingRight: '24px',
      paddingTop: '24px',
      alignItems: 'center',
      justifyItems: 'center',
      alignContent: 'space-evenly',
    }}>
      {/* Debit Transaction */}
      <Grid item xs={6} sx={{ pb: 3 }}>
        {
          renderAsyncDropdown('Debit', 'debit_account', body.selected_transaction_type && body.selected_transaction_type.name === 'DEBIT')
        }
      </Grid>
      <Grid item xs={6} sx={{ pb: 3 }}>
        <CustomTypography
          text={'Debit'}
          sx={{
            fontWeight: 700,
            fontSize: '14px',
            color: '#000000',
            textTransform: 'none',
          }}
        />
      </Grid>

      {/* Credit Transaction */}
      <Grid item xs={6}>
        {
          renderAsyncDropdown('Credit', 'credit_account', body.selected_transaction_type && body.selected_transaction_type.name === 'CREDIT')
        }
      </Grid>
      <Grid item xs={6}>
        <CustomTypography
          text={'Credit'}
          sx={{
            fontWeight: 700,
            fontSize: '14px',
            color: '#000000',
            textTransform: 'none',
          }}
        />
      </Grid>
    </Grid>
  );
};
const AsyncTransactionForm = ({ body, setBody, isFormSubmitted, addButtonHandler }) => {

  const renderAsyncDropdown = (label, accountKey, parentAccountName) => (
    <Grid item xs={6} sx={{ pb: 3 }}>
      <AsyncDropdown
        sx={{ width: { xs: 'auto', sm: 400 } }}
        fullWidth={true}
        autoFocus={false}
        disabledCloseIcon={false}
        isGroupHeaderSticky={true}
        id={`${accountKey}_dropdown`}
        dataTestId={`${accountKey}_dropdown`}
        validation={isFormSubmitted && !body[accountKey]}

        selectedValue={body?.[accountKey]}
        setSelectedValue={(value) => {
          setBody({ ...body, [accountKey]: value, [`${accountKey}_id`]: value?.id })
        }}

        playLoad={parentAccountName ? { parent_account_name: parentAccountName } : {}}
        valueLabelKey='id'
        uniqueOptionKey='id'
        searchKey='account_name'
        optionLabelKey='account_name'
        placeholder={`Select ${label} Account`}
        optionGroupLabelKey='parent_account_name'
        URL={get_chart_of_account_nested_nodes(1)}

        addButton={{
          title: `+ Add ${label} accounts`,
          onClick: () => addButtonHandler(`Add ${label} accounts`, 'create_sub_account', 'sm', parentAccountName, `${accountKey}_id`),
        }}
      />
    </Grid>
  );

  return (
    <Grid container item xs={10} spacing={0} style={{
      paddingLeft: '24px',
      paddingRight: '24px',
      paddingTop: '24px',
      alignItems: 'center',
      justifyItems: 'center',
      alignContent: 'space-evenly',
    }}>
      {body.selected_transaction_type && body.selected_transaction_type.name === 'DEBIT' ? (
        renderAsyncDropdown('Debit', 'debit_account', null)
      ) : (
        renderAsyncDropdown('Debit', 'debit_account', 'Bank')
      )}

      <Grid item xs={6} sx={{ pb: 3 }}>
        <CustomTypography
          text={'Debit'}
          sx={{
            fontWeight: 700,
            fontSize: '14px',
            color: '#000000',
            textTransform: 'none',
          }}
        />
      </Grid>

      {body.selected_transaction_type && body.selected_transaction_type.name === 'CREDIT' ? (
        renderAsyncDropdown('Credit', 'credit_account', null)
      ) : (
        renderAsyncDropdown('Credit', 'credit_account', 'Bank')
      )}

      <Grid item xs={6}>
        <CustomTypography
          text={'Credit'}
          sx={{
            fontWeight: 700,
            fontSize: '14px',
            color: '#000000',
            textTransform: 'none',
          }}
        />
      </Grid>
    </Grid>
  );
};