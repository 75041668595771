import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { apiAction } from '../../../../api/api';
import * as Actions from '../../../../state/Actions';
import CustomTitle from '../../../common/CustomTitle';
import CommonSearch from '../../../common/CommonSearch';
import CustomTabs from '../../../custom/tabs/CustomTabs';
import { routesName } from '../../../../config/routesName';
import { CustomLink } from '../../../custom/link/CustomLink';
import CustomButton from '../../../custom/button/CustomButton';
import CustomDialog from '../../../custom/dialog/CustomDialog';
import { getBusinessInfo } from '../../../../config/cookiesInfo';
import CurrencyFormatter from '../../../common/CurrencyFormatter';
import { NoDataComponent } from '../../../common/NoDataComponent';
import CustomTypography from '../../../custom/typography/CustomTypography';
import { eventsNames, AnalyticsEvent } from '../../../../firebase/firebaseAnalytics';

import {
  get_chart_of_account_nested,
  get_chart_of_account_nested_new,
  get_chart_of_account_nested_nodes,
} from '../../../../api/urls';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { setFilterMessage, setLoader, getUniqueObjectsByKey } from '../../../../utils/Utils';

import {
  CustomContainer,
  CustomTitleContainer,
} from '../../../custom/container/CustomContainer';

import {
  Box,
  Grid,
} from '@mui/material'

import {
  CustomTable,
  CustomTableRow,
  CustomTableBody,
  CustomTableHead,
  CustomTableHeadCell,
  CustomTableBodyCell,
  CustomTableContainer,
} from '../../../custom/table/CustomTable';


const ChartofAccount = () => {

  const navigate = useNavigate();
  const getState = Actions.getState(React.useContext);
  const dispatch = Actions.getDispatch(React.useContext);

  const [results, setResults] = React.useState([])
  const [isParent, setIsParent] = React.useState(true);
  const [searchTerm, setSearchTerm] = React.useState(null);
  const [filterResults, setFilterResults] = React.useState([])
  const [tabs, setTabs] = React.useState(['ALL', 'ASSET', 'LIABILITY', 'EQUITY', 'INCOME', 'EXPENSE']);



  const value = getState.chartofAccountTabValue;
  const setValue = (tab_value) => dispatch(Actions.stateChange('chartofAccountTabValue', tab_value));
  // const [value, setValue] = React.useState(0);


  React.useEffect(() => {
    const mappingEventTabs = {
      ALL: eventsNames.actions.chartOfAccount.filter_type.ALL,
      ASSET: eventsNames.actions.chartOfAccount.filter_type.ASSET,
      EQUITY: eventsNames.actions.chartOfAccount.filter_type.EQUITY,
      INCOME: eventsNames.actions.chartOfAccount.filter_type.INCOME,
      EXPENSE: eventsNames.actions.chartOfAccount.filter_type.EXPENSE,
      LIABILITY: eventsNames.actions.chartOfAccount.filter_type.LIABILITY
    }

    const filter_type = tabs[value]
      ? mappingEventTabs[tabs[value]]
      : eventsNames.actions.chartOfAccount.filter_type.ALL;

    AnalyticsEvent(eventsNames.categories.CHART_OF_ACCOUNTS, { action: eventsNames.actions.CLICK, filter_type: filter_type })

  }, [value])

  /////////////////////////////////////////////////////////////////////////////////////////////////
  const [page, setPage] = React.useState(1);
  const [open, setOpen] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    next: undefined,
    count: undefined,
    previous: undefined,
    number_of_pages: undefined,
  });

  const [state, setState] = React.useState({
    title: '',
    open: false,
    condition: '',
    maxWidth: 'lg',
    fullWidth: true,
  })

  const [parentResults, setParentResults] = React.useState([])
  const getParentAccountsList = async (results = []) => {
    setLoader(dispatch, Actions, true);
    const { id } = getBusinessInfo();
    const res = await apiAction({
      method: 'post',
      navigate: navigate,
      dispatch: dispatch,
      url: get_chart_of_account_nested_new(page),
      data: { business_id: id, account_type: value ? tabs[value] : null },
    });
    if (res?.success) {
      setPagination({
        ...pagination,
        next: res?.result.next,
        count: res?.result.count,
        previous: res?.result.previous,
        number_of_pages: res?.result.number_of_pages,
      });

      setLoader(dispatch, Actions, false);

      setParentResults([...getUniqueObjectsByKey([...results, ...res?.result.result])]);

    } else {
      setLoader(dispatch, Actions, false);

    }
  }

  const getApiResults = async () => {
    setLoader(dispatch, Actions, true);

    const { id } = getBusinessInfo();
    const res = await apiAction({
      method: 'post',
      navigate: navigate,
      dispatch: dispatch,
      url: get_chart_of_account_nested(),
      data: { business_id: id },
    });

    if (res?.success) {
      let response = res?.result
      const results = response.reduce((acc, item) => {
        const { account_type } = item;
        acc[account_type] = acc[account_type] || [];
        acc[account_type].push(item);
        return acc;
      }, {});

      const tabs = ['ALL', ...Object.keys(results)];
      const dataResult = Object.values(results).flat()
      setTabs(tabs);
      setResults(dataResult);
      setLoader(dispatch, Actions, false);
      if (value) {
        const newArr = dataResult.filter((item) => {
          return item.account_type === tabs[value]
        })
        setFilterResults([...newArr])
      } else {
        setFilterResults([...dataResult])
      }
    } else {
      setLoader(dispatch, Actions, false);

    }
  };

  const getAccountsResults = async (searchTerm, results = []) => {
    setLoader(dispatch, Actions, true);
    const { id } = getBusinessInfo();
    const res = await apiAction({
      method: 'post',
      navigate: navigate,
      dispatch: dispatch,
      url: get_chart_of_account_nested_nodes(page),
      data: { business_id: id, account_name: searchTerm, account_type: value ? [tabs[value]] : null },
    });

    if (res?.success) {
      setPagination({
        ...pagination,
        next: res?.result.next,
        count: res?.result.count,
        previous: res?.result.previous,
        number_of_pages: res?.result.number_of_pages,
      });
      setLoader(dispatch, Actions, false);
      setParentResults([...getUniqueObjectsByKey([...results, ...res?.result.result])])
    } else {
      setLoader(dispatch, Actions, false);

    }
  };
  /////////////////////////////////////////////////////////////////////////////////////////////////

  React.useEffect(() => {
    if (!state.open) {
      // getApiResults()
      if (isParent) {
        getParentAccountsList(parentResults);
      } else {
        getAccountsResults(searchTerm, parentResults)
      }
    }
    // eslint-disable-next-line
  }, [state.open, page, value])

  const onClickSubAccount = () => {
    AnalyticsEvent(eventsNames.categories.CHART_OF_ACCOUNTS, { action: eventsNames.actions.NEW, type: eventsNames.actions.chartOfAccount.type.ACCOUNT })
    setState({
      open: true,
      maxWidth: 'sm',
      fullWidth: true,
      title: 'Add Account',
      condition: 'create_sub_account',
    })
  }


  /////////////////////////////////////////////////////////////////////////////////////////////////
  function searchAccountByName(data, targetName) {
    const matchingAccounts = [];

    for (const account of data) {
      const matchingChildAccounts = searchAccountByName(account.child || [], targetName);

      if (account.account_name && account.account_name.toLocaleLowerCase().includes(targetName.toLocaleLowerCase())) {
        matchingAccounts.push({
          ...account,
          child: matchingChildAccounts,
        });
      } else if (matchingChildAccounts.length > 0) {
        matchingAccounts.push({
          ...account,
          child: matchingChildAccounts,
        });
      }
    }

    return matchingAccounts;
  }

  const handleChange = (tab_value, search_text) => {
    let initial_filter_data = search_text ? searchAccountByName(results, search_text) : results;
    if (initial_filter_data.length === 0) {
      setFilterMessage(dispatch, Actions, `We can’t find anything with current search term at the moment, try searching something else.`)
    }

    if (tab_value) {
      const newArr = initial_filter_data.filter((item) => {
        return item.account_type === tabs[tab_value]
      })
      setFilterResults([...newArr])
    } else {
      setFilterResults([...initial_filter_data])
    }
    setValue(tab_value);
  };

  React.useEffect(() => {
    if (searchTerm) {
      setPage(1);
      setOpen([]);
      setIsParent(false);
      setParentResults([]);
      getAccountsResults(searchTerm)
    } else if (!isParent) {
      setPage(1);
      setIsParent(true);
      setParentResults([]);
      getParentAccountsList();
    }
    setPagination({
      ...pagination,
      next: null
    })
    handleChange(value, searchTerm)
  }, [searchTerm])


  return (
    <div>
      <CustomDialog
        state={state}
        setState={setState}
      />

      <CustomTitleContainer>
        <Grid container spacing={1} style={{
          alignItems: 'center',
          justifyItems: 'center',
          alignContent: 'space-evenly',
          justifyContent: 'space-evenly',
        }}>
          <Grid item xs={12} sm={4}>
            <CustomTitle title={'Chart of Accounts'} />
          </Grid>

          <Grid item container spacing={1} xs={12} sm={8} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', }}>
            <Grid item xs={12} sm={4} order={{xs:5,sm:1}}>
              <CommonSearch autoFocus={true} sx={{ height: '34px' }} id={'input_search_account'} dataTestId={'input_search_account'} isTitleVisibility={false} placeholder={'Search Account eg. Petty Cash'} fullWidth={true} onSearchValue={searchTerm ? searchTerm : ''} onSearch={(search_text) => {
                if (search_text) {
                  setSearchTerm(search_text)
                } else {
                  setSearchTerm(null)
                }
              }} />
            </Grid>

            <Grid item xs={6} sm={2} order={{xs:1,sm:2}}>
              <CustomButton
                fullWidth={true}
                variant="outlined"
                btnLabel='Add Account'
                id={'add_account_btn'}
                dataTestId={'add_account_btn'}
                onClick={onClickSubAccount}
              />
            </Grid>

            <Grid item xs={6} sm={2} order={{xs:2,sm:3}}>
              <CustomButton
                fullWidth={true}
                variant="outlined"
                btnLabel='Add Parent Account'
                id={'add_parent_account_btn'}
                dataTestId={'add_parent_account_btn'}
                onClick={() => {
                  navigate(routesName.parentAccountAdd.path)
                  AnalyticsEvent(eventsNames.categories.CHART_OF_ACCOUNTS, { action: eventsNames.actions.NEW, type: eventsNames.actions.chartOfAccount.type.PARENT_ACCOUNT })

                }}
              />
            </Grid>

            <Grid item xs={6} sm={2} order={{xs:3,sm:4}}>
              <CustomButton
                fullWidth={true}
                variant="outlined"
                id={'manage_account_btn'}
                dataTestId={'manage_account_btn'}
                btnLabel='Manage Chart of Accounts'
                onClick={() => {
                  navigate(routesName.chartsAccountAdd.path)
                  AnalyticsEvent(eventsNames.categories.CHART_OF_ACCOUNTS, { action: eventsNames.actions.chartOfAccount.action.MANAGE_CHART_OF_ACCOUNTS })
                }}
              />
            </Grid>

            <Grid item xs={6} sm={2} order={{xs:4,sm:5}}>
              <CustomButton
                fullWidth={true}
                variant="contained"
                btnLabel='View Parent Account'
                id={'view_parent_account_btn'}
                dataTestId={'view_parent_account_btn'}
                onClick={() => {
                  navigate(routesName.parentAccount.path)
                  AnalyticsEvent(eventsNames.categories.CHART_OF_ACCOUNTS, { action: eventsNames.actions.chartOfAccount.action.VIEW_PARENT_ACCOUNT })

                }}
              />
            </Grid>

          </Grid>

        </Grid>
      </CustomTitleContainer>

      <CustomContainer maxWidth={"400"} sx={{ height: 'calc(100vh - 142px)', maxHeight: { xs: 'calc(100vh - 310px)', sm: '100%' } }}>
        <CustomTabs
          tabs={tabs}
          id={'tabs_'}
          value={value}
          sx={{ mb: 2 }}
          dataTestId={'tabs_'}
          onChange={(event, newValue) => {
            setPage(1);
            setOpen([]);
            setParentResults([]);
            handleChange(newValue, searchTerm);
            setPagination({
              ...pagination,
              next: null
            })
          }}
        />
        <CustomTableContainer>
          <CustomTable>
            <ChartofAccountHeader />
            {isParent ?
              <ChartofAccountBodyNew results={parentResults} is_total_visible={value !== 0} open={open} setOpen={setOpen} />
              :
              <ShowChildData data={parentResults} id={'show_child_'} dataTestId={'show_child_'} />
            }
          </CustomTable>
          {
            pagination.next &&

            <CustomTypography
              text={'More'}
              sx={{
                p: 2,
                pt: 1,
                color: '#2464EB',
                fontSize: '16px',
                cursor: 'pointer',
                fontWeight: '700',
                textAlign: 'start',
                fontStyle: 'normal',
                lineHeight: 'normal',
                fontFamily: 'Noto Sans',

                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
              onClick={() => { setPage(page + 1) }}
            />
          }
          <Box sx={{ pt: !parentResults.length ? 10 : 0 }} >
            <NoDataComponent left='0%' top='0%' position={'relative'} data={parentResults} />
          </Box>
        </CustomTableContainer>
      </CustomContainer>
    </div>
  );
}

export default ChartofAccount;


const ChartofAccountHeader = () => {
  return (
    <CustomTableHead>
      <CustomTableRow>
        <CustomTableHeadCell style={{ width: 140 }} align='left' ><span>Account / Parent Account</span></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 140 }} align='right'><span>Account Code</span></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 140 }} align='right'><span>Account Type</span></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 140 }} align='right'><span>Balance Amount</span></CustomTableHeadCell>
      </CustomTableRow>
    </CustomTableHead>
  )
}

const ChartofAccountBody = (props) => {
  const { results = [], is_total_visible } = props

  return (
    results.length ?
      <CustomTableBody>
        {results.map((result, index) => {
          return (
            <React.Fragment key={index}>

              <CustomTableRow sx={{ background: '#f5f5f5' }}>
                <CustomTableBodyCell sx={{ borderTop: '1px solid #FFFFFF', borderBottom: '1px solid #FFFFFF' }} ><strong>{result.name}</strong></CustomTableBodyCell>
                <CustomTableBodyCell sx={{ borderTop: '1px solid #FFFFFF', borderBottom: '1px solid #FFFFFF' }} align='right'>{result.account_number}</CustomTableBodyCell >
                <CustomTableBodyCell sx={{ borderTop: '1px solid #FFFFFF', borderBottom: '1px solid #FFFFFF' }} align='right'>{result.account_type}</CustomTableBodyCell >
                <CustomTableBodyCell sx={{ borderTop: '1px solid #FFFFFF', borderBottom: '1px solid #FFFFFF' }} align='right'><strong>{<CurrencyFormatter amount={result.balance_amount} />}</strong></CustomTableBodyCell>
              </CustomTableRow>
              {result.child && <ShowChildData data={result.child} id={'show_child_' + index} dataTestId={'show_child_' + index} />}
            </React.Fragment>
          )
        })
        }
        {is_total_visible &&
          <CustomTableRow sx={{ background: '#FFF7DC', }}>
            <CustomTableBodyCell sx={{ borderTop: '1px solid #FFF0C0', borderBottom: '1px solid #FFF0C0' }}>
              <strong>Total balance</strong>
            </CustomTableBodyCell>
            <CustomTableBodyCell align='right' colSpan={4} sx={{ borderTop: '1px solid #FFF0C0', borderBottom: '1px solid #FFF0C0' }}>
              <CurrencyFormatter amount={results.reduce((acc, item) => acc + item.balance_amount, 0).toFixed(2)} />
            </CustomTableBodyCell>
          </CustomTableRow>
        }
      </CustomTableBody>
      : ''
  )
}

const ShowChildData = ({ data, marginLeft = 0, id, dataTestId }) => {
  return (
    data.map((item, index) => {
      return (
        <React.Fragment key={index}>
          <CustomTableRow >
            <CustomTableBodyCell sx={{ border: 'none', }} size='small'>
              <SubdirectoryArrowRightIcon sx={{ ml: marginLeft, mb: -.5, color: '#d6d1d1' }} />
              {
                item.is_parent ?
                  <span>{item.name}</span>
                  :
                  <CustomLink
                    id={id + '_' + index}
                    dataTestId={dataTestId + '_' + index}
                    style={{ color: '#2464EB', }}
                    to={routesName.chartsAccountTransaction.path + "?id=" + item.id}
                  >{item.account_name}</CustomLink>
              }
            </CustomTableBodyCell>
            <CustomTableBodyCell sx={{ border: 'none' }} size='small' align='right'>
              <SubdirectoryArrowRightIcon sx={{ mb: -.7, color: '#d6d1d1', visibility: 'hidden' }} />{item.account_number}
            </CustomTableBodyCell>
            <CustomTableBodyCell sx={{ border: 'none' }} size='small' align='right'>
              <SubdirectoryArrowRightIcon sx={{ mb: -.7, color: '#d6d1d1', visibility: 'hidden' }} />{item.account_type}
            </CustomTableBodyCell>
            <CustomTableBodyCell sx={{ border: 'none' }} size='small' align='right'>
              <SubdirectoryArrowRightIcon sx={{ mb: -.7, color: '#d6d1d1', visibility: 'hidden' }} /><span >{<CurrencyFormatter amount={item.balance_amount} />}</span>
            </CustomTableBodyCell>
          </CustomTableRow>
          {item.child && <ShowChildData data={item.child} marginLeft={2 + marginLeft} id={id + '_child' + index} dataTestId={dataTestId + '_child' + index} />}
        </React.Fragment>
      );
    })
  );
};


const ChartofAccountBodyNew = (props) => {
  const { isParent, results = [], is_total_visible, open = [], setOpen } = props
  const addOrRemove = (array, item) => {
    const exists = array.includes(item)

    if (exists) {
      return array.filter((c) => { return c !== item })
    } else {
      const result = array
      result.push(item)
      return result
    }
  }

  const onClickParentAccount = (parent_id) => {
    setOpen([...addOrRemove(open, parent_id)])
  }
  return (
    results.length ?
      <CustomTableBody>
        {results.map((result, index) => {
          return (
            <React.Fragment key={index}>

              <CustomTableRow sx={{ background: '#f5f5f5' }}>
                <CustomTableBodyCell sx={{ borderTop: '1px solid #FFFFFF', borderBottom: '1px solid #FFFFFF' }} >
                  <strong style={{ cursor: result.has_child ? 'pointer' : '', display: 'flex', alignItems: 'center' }} onClick={() => { if (result.has_child) { onClickParentAccount(result.id) } }}>
                    {result.name}
                    <Icon is_has_account={result.has_child} open={open.includes(result.id)} />
                  </strong>
                </CustomTableBodyCell>
                <CustomTableBodyCell sx={{ borderTop: '1px solid #FFFFFF', borderBottom: '1px solid #FFFFFF' }} align='right'>{result.account_number}</CustomTableBodyCell >
                <CustomTableBodyCell sx={{ borderTop: '1px solid #FFFFFF', borderBottom: '1px solid #FFFFFF' }} align='right'>{result.account_type}</CustomTableBodyCell >
                <CustomTableBodyCell sx={{ borderTop: '1px solid #FFFFFF', borderBottom: '1px solid #FFFFFF' }} align='right'><strong>{<CurrencyFormatter amount={result.balance_amount} />}</strong></CustomTableBodyCell>
              </CustomTableRow>
              {open.includes(result.id) && <ShowChildDataNew parent_id={result.id} open={open} onClickParentAccount={onClickParentAccount} id={'parent_' + result.id} dataTestId={'parent_' + result.id} />}
            </React.Fragment>
          )
        })
        }
        {is_total_visible &&
          <CustomTableRow sx={{ background: '#FFF7DC', }}>
            <CustomTableBodyCell sx={{ borderTop: '1px solid #FFF0C0', borderBottom: '1px solid #FFF0C0' }}>
              <strong>Total balance</strong>
            </CustomTableBodyCell>
            <CustomTableBodyCell align='right' colSpan={4} sx={{ borderTop: '1px solid #FFF0C0', borderBottom: '1px solid #FFF0C0' }}>
              <CurrencyFormatter amount={results.reduce((acc, item) => acc + item.balance_amount, 0).toFixed(2)} />
            </CustomTableBodyCell>
          </CustomTableRow>
        }
      </CustomTableBody>
      : ''
  )
}

const ShowChildDataNew = ({ open = [], parent_id, onClickParentAccount, marginLeft = 0, id, dataTestId }) => {
  const navigate = useNavigate();

  const [page, setPage] = React.useState(1);
  const [data, setData] = React.useState([]);
  const [openId, setOpenId] = React.useState([]);
  const [isParent, setIsParent] = React.useState(true);
  const dispatch = Actions.getDispatch(React.useContext);
  const [pagination, setPagination] = React.useState({
    next: undefined,
    count: undefined,
    previous: undefined,
    number_of_pages: undefined,
  });

  const addOrRemove = (array, item) => {
    const exists = array.includes(item)

    if (exists) {
      return array.filter((c) => { return c !== item })
    } else {
      const result = array
      result.push(item)
      return result
    }
  }
  const onClickChildAccount = (parent_id) => {
    setOpenId([...addOrRemove(openId, parent_id)])
  }

  const getApiResults = async (parent_id) => {
    setLoader(dispatch, Actions, true);

    const { id } = getBusinessInfo();
    const res = await apiAction({
      method: 'post',
      navigate: navigate,
      dispatch: dispatch,
      url: get_chart_of_account_nested_new(page),
      data: { parent_account_id: parent_id, business_id: id },
    });

    if (res?.success) {
      setPagination({
        ...pagination,
        next: res?.result.next,
        count: res?.result.count,
        previous: res?.result.previous,
        number_of_pages: res?.result.number_of_pages,
      });
      let response = res?.result
      setLoader(dispatch, Actions, false);
      setData([...getUniqueObjectsByKey([...data, ...response?.result])])

      if (!response?.result?.length < 10 || !response?.next) {
        setPage(1);
        setIsParent(false);
      }
    } else {
      setLoader(dispatch, Actions, false);

    }
  };

  const getAccountsResults = async (parent_id) => {
    setLoader(dispatch, Actions, true);
    const { id } = getBusinessInfo();
    const res = await apiAction({
      method: 'post',
      navigate: navigate,
      dispatch: dispatch,
      url: get_chart_of_account_nested_nodes(page),
      data: { parent_account_id: [parent_id], business_id: id },
    });

    if (res?.success) {
      setPagination({
        ...pagination,
        next: res?.result.next,
        count: res?.result.count,
        previous: res?.result.previous,
        number_of_pages: res?.result.number_of_pages,
      });
      let response = res?.result
      setLoader(dispatch, Actions, false);

      setData([...getUniqueObjectsByKey([...data, ...response?.result])])
    } else {
      setLoader(dispatch, Actions, false);

    }
  };

  React.useEffect(() => {
    if (parent_id) {

      // first api call for to check the parent is present or not
      if (isParent) {
        getApiResults(parent_id)
      } else {
        getAccountsResults(parent_id)
      }
    }
  }, [parent_id, page, isParent])

  return (
    open.includes(parent_id) &&
    <>
      {
        data.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <CustomTableRow >
                <CustomTableBodyCell sx={{ border: 'none' }} size='small'>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <SubdirectoryArrowRightIcon sx={{ ml: marginLeft, mt: -.7, color: '#d6d1d1' }} />
                    {
                      item.is_parent ?
                        <span style={{
                          display: 'flex',
                          overflow: 'hidden',
                          alignItems: 'center',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          cursor: item.has_child ? 'pointer' : '',
                        }} onClick={() => {
                          if (item.has_child) { onClickChildAccount(item.id) };
                        }}>
                          {item.name}
                          <Icon mb={-.1} is_has_account={item.has_child} open={openId.includes(item.id)} />
                        </span>
                        :
                        <CustomLink
                          id={id + '_' + index}
                          dataTestId={dataTestId + '_' + index}
                          style={{
                            color: '#2464EB',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                          }}
                          to={routesName.chartsAccountTransaction.path + "?id=" + item.id}
                        >{item.account_name}</CustomLink>
                    }
                  </div>
                </CustomTableBodyCell>
                <CustomTableBodyCell sx={{ border: 'none' }} size='small' align='right'>
                  {/* <SubdirectoryArrowRightIcon sx={{ mt: -.8, color: '#d6d1d1', visibility: 'hidden' }} /> */}
                  {item.account_number}
                </CustomTableBodyCell>
                <CustomTableBodyCell sx={{ border: 'none' }} size='small' align='right'>
                  {/* <SubdirectoryArrowRightIcon sx={{ mt: -.8, color: '#d6d1d1', visibility: 'hidden' }} /> */}
                  {item.account_type}
                </CustomTableBodyCell>
                <CustomTableBodyCell sx={{ border: 'none' }} size='small' align='right'>
                  {/* <SubdirectoryArrowRightIcon sx={{ mt: -.8, color: '#d6d1d1', visibility: 'hidden' }} /> */}
                  <span >{<CurrencyFormatter amount={item.balance_amount} />}</span>
                </CustomTableBodyCell>
              </CustomTableRow>

              {item.is_parent && openId.includes(item.id) && <ShowChildDataNew parent_id={item.id} open={openId} onClickParentAccount={onClickParentAccount} marginLeft={2 + marginLeft} id={id + '_child_' + item.id} dataTestId={dataTestId + '_child_' + item.id} />}
            </React.Fragment>
          );
        })
      }
      {pagination.next &&
        <CustomTableRow >
          <CustomTableBodyCell colSpan={12} size='small'>
            <CustomTypography
              text={'More'}
              sx={{
                ml: marginLeft,
                color: '#2464EB',
                fontSize: '16px',
                cursor: 'pointer',
                fontWeight: '700',
                textAlign: 'start',
                fontStyle: 'normal',
                lineHeight: 'normal',
                fontFamily: 'Noto Sans',

                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
              onClick={() => { setPage(page + 1) }}
            />
          </CustomTableBodyCell>
        </CustomTableRow>
      }
    </>
  );
};

const ShowChildDataNewCopy = ({ open = [], parent_id, onClickParentAccount, marginLeft = 0, id, dataTestId }) => {
  const navigate = useNavigate();
  const [page, setPage] = React.useState(1);
  const [childPage, setChildPage] = React.useState(1);
  const [data, setData] = React.useState([]);
  const [childData, setChildData] = React.useState([]);
  const [isParent, setIsParent] = React.useState(true);
  const dispatch = Actions.getDispatch(React.useContext);
  const [pagination, setPagination] = React.useState({
    next: undefined,
    count: undefined,
    previous: undefined,
    number_of_pages: undefined,
  });
  const [childPagination, setChildPagination] = React.useState({
    next: undefined,
    count: undefined,
    previous: undefined,
    number_of_pages: undefined,
  });
  const getApiResults = async (parent_id) => {
    setLoader(dispatch, Actions, true);

    const { id } = getBusinessInfo();
    const res = await apiAction({
      method: 'post',
      navigate: navigate,
      dispatch: dispatch,
      url: get_chart_of_account_nested_new(page),
      data: { parent_account_id: parent_id, business_id: id },
    });

    if (res?.success) {
      setPagination({
        ...pagination,
        next: res?.result.next,
        count: res?.result.count,
        previous: res?.result.previous,
        number_of_pages: res?.result.number_of_pages,
      });
      let response = res?.result
      setLoader(dispatch, Actions, false);
      setData(Array.from(new Set([...data, ...response?.result])))

      // if (!response?.result?.length && !response?.next) {
      //   setPage(1);
      //   setIsParent(false);
      // }
    } else {
      setLoader(dispatch, Actions, false);

    }
  };

  const getAccountsResults = async (parent_id) => {
    setLoader(dispatch, Actions, true);
    const { id } = getBusinessInfo();
    const res = await apiAction({
      method: 'post',
      navigate: navigate,
      dispatch: dispatch,
      url: get_chart_of_account_nested_nodes(childPage),
      data: { parent_account_id: [parent_id], business_id: id },
    });

    if (res?.success) {
      setChildPagination({
        ...childPagination,
        next: res?.result.next,
        count: res?.result.count,
        previous: res?.result.previous,
        number_of_pages: res?.result.number_of_pages,
      });
      let response = res?.result
      setLoader(dispatch, Actions, false);
      setChildData(Array.from(new Set([...childData, ...response?.result])))

    } else {
      setLoader(dispatch, Actions, false);

    }
  };

  React.useEffect(() => {
    if (parent_id) {
      getApiResults(parent_id)
      getAccountsResults(parent_id)
      // if (isParent) {
      // } else {
      // }
    }
  }, [parent_id, page,])

  const results = Array.from(new Set([...childData, ...data]))
  const childLastElement = childData[childData.length - 1]
  return (
    open.includes(parent_id) &&
    <>
      {
        results.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <CustomTableRow >
                <CustomTableBodyCell sx={{ border: 'none' }} size='small'>
                  <SubdirectoryArrowRightIcon sx={{ ml: marginLeft, mb: -.5, color: '#d6d1d1' }} />
                  {
                    item.is_parent ?
                      <span style={{ cursor: 'pointer' }} onClick={() => { onClickParentAccount(item.id) }}>{item.name}</span>
                      :
                      <CustomLink
                        id={id + '_' + index}
                        dataTestId={dataTestId + '_' + index}
                        style={{ color: '#2464EB', }}
                        to={routesName.chartsAccountTransaction.path + "?id=" + item.id}
                      >{item.account_name}</CustomLink>
                  }
                </CustomTableBodyCell>
                <CustomTableBodyCell sx={{ border: 'none' }} size='small' align='right'>
                  <SubdirectoryArrowRightIcon sx={{ mb: -.7, color: '#d6d1d1', visibility: 'hidden' }} />{item.account_number}
                </CustomTableBodyCell>
                <CustomTableBodyCell sx={{ border: 'none' }} size='small' align='right'>
                  <SubdirectoryArrowRightIcon sx={{ mb: -.7, color: '#d6d1d1', visibility: 'hidden' }} />{item.account_type}
                </CustomTableBodyCell>
                <CustomTableBodyCell sx={{ border: 'none' }} size='small' align='right'>
                  <SubdirectoryArrowRightIcon sx={{ mb: -.7, color: '#d6d1d1', visibility: 'hidden' }} /><span >{<CurrencyFormatter amount={item.balance_amount} />}</span>
                </CustomTableBodyCell>
              </CustomTableRow>
              {childLastElement?.id === item.id && childPagination.next &&
                <CustomTableRow>
                  <CustomTableBodyCell colSpan={12}>
                    <CustomTypography
                      text={'More'}
                      sx={{
                        ml: marginLeft,
                        color: '#2464EB',
                        fontSize: '16px',
                        cursor: 'pointer',
                        fontWeight: '700',
                        textAlign: 'start',
                        fontStyle: 'normal',
                        lineHeight: 'normal',
                        fontFamily: 'Noto Sans',

                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      }}
                      onClick={() => { setChildPage(childPage + 1) }}
                    />
                  </CustomTableBodyCell>
                </CustomTableRow>
              }
              {open.includes(item.id) && isParent && <ShowChildDataNew parent_id={item.id} open={open} onClickParentAccount={onClickParentAccount} marginLeft={2 + marginLeft} id={id + '_child_' + item.id} dataTestId={dataTestId + '_child_' + item.id} />}
            </React.Fragment>
          );
        })
      }
      {pagination.next &&
        <CustomTableRow>
          <CustomTableBodyCell colSpan={12}>
            <CustomTypography
              text={'More'}
              sx={{
                color: '#2464EB',
                fontSize: '16px',
                cursor: 'pointer',
                fontWeight: '700',
                textAlign: 'start',
                fontStyle: 'normal',
                lineHeight: 'normal',
                fontFamily: 'Noto Sans',

                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
              onClick={() => { setPage(page + 1) }}
            />
          </CustomTableBodyCell>
        </CustomTableRow>
      }
    </>
  );
};

const Icon = (props) => {
  const { mb = -.5, open, is_has_account } = props;
  return (
    is_has_account ? open ?
      <ArrowDropUpIcon fontSize='small' sx={{ mb: mb }} />
      :
      <ArrowDropDownIcon fontSize='small' sx={{ mb: mb }} />
      : null
  )
}

