import React from 'react';
import moment from 'moment/moment';
import Input from '../../../../common/Input';
import Status from '../../../../common/Status';
import HTMLViewer from '../../../../common/HTMLViewer';
import * as Actions from '../../../../../state/Actions';
import CustomTitle from '../../../../common/CustomTitle';
import CustomTabs from '../../../../custom/tabs/CustomTabs';
import { useLocation, useNavigate } from 'react-router-dom';
import { routesName } from '../../../../../config/routesName';
import CommonShareFile from '../../../../common/CommonShareFile';
import CustomDialog from '../../../../custom/dialog/CustomDialog';
import CustomButton from '../../../../custom/button/CustomButton';
import { getBusinessInfo } from '../../../../../config/cookiesInfo';
import { NoDataComponent } from '../../../../common/NoDataComponent';
import CurrencyFormatter from '../../../../common/CurrencyFormatter';
import AuditTrailHistory from '../../../../common/AuditTrailHistory';
import CommonPreviewShare from '../../../../common/CommonPreviewShare';
import ViewPageActionButtons from '../../../../common/ViewPageActionButtons';
import CustomPagination from '../../../../custom/pagination/CustomPagination';
import CustomTypography from '../../../../custom/typography/CustomTypography';
import { eventsNames,AnalyticsEvent } from '../../../../../firebase/firebaseAnalytics';
import CommonJournalEntryTransactionView from '../../common/CommonJournalEntryTransactionView';

import {
    CustomContainer,
    CustomTitleContainer,
    CustomButtonContainer,
} from '../../../../custom/container/CustomContainer';

import {
    setLoader,
    isFormValid,
    getDateFormat,
    getQueryParams,
    limitDecimalPlaces,
    stateChangeManager,
    breadCrumbsStateChangeManager,
} from '../../../../../utils/Utils';

import {
    apiAction,
    apiBlobResponse,
    apiHandleDownload,
} from '../../../../../api/api';

import {
    get_party,
    update_invoice,
    export_invoice,
    delete_invoice,
    retrieve_invoice,
    send_invoice_mail,
    mark_invoice_sent,
    get_list_of_payment,
    list_of_credited_invoices,
    get_credit_list_of_customer,
    apply_credit_as_invoice_payment,
    delete_credits_applied_to_invoice,
} from '../../../../../api/urls';

import {
    CustomTable,
    CustomTableRow,
    CustomTableBody,
    CustomTableHead,
    CustomTableHeadCell,
    CustomTableBodyCell,
    CustomTableContainer,
} from '../../../../custom/table/CustomTable';

import {
    Box,
    Grid,
    IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { CommonWebSocket } from '../../../../../api/webSocket';
import { CreditNoteViewLink, PaymentReceivedViewLink } from '../../common/CommonLinks';

const tabs = [
    { name: 'View', condition: 'preview' },
    { name: 'Payment History', condition: 'payment_history' },
    { name: 'Credits Applied', condition: 'applied_credited' }
]

const InvoiceViewpage = () => {
    let navigate = useNavigate();
    let location = useLocation();
    const [tab, setTab] = React.useState(0);
    const params = getQueryParams(location.search);
    const [applyNow, setApplyNow] = React.useState(false);
    const dispatch = Actions.getDispatch(React.useContext);

    const [invoiceResults, setInvoiceResults] = React.useState();
    const [customerResults, setCustomerResults] = React.useState();

    const [state, setState] = React.useState({
        id: '',
        title: '',
        open: false,
        condition: '',
        maxWidth: 'lg',
        fullWidth: true,
    });

    const webSocketResponse = CommonWebSocket();
    React.useEffect(() => {
        if (webSocketResponse) {
            let webSocketData = JSON.parse(webSocketResponse.data)
            if (webSocketData.document_type === "invoice") {

                if (webSocketData.event === "send_document" && invoiceResults.id === webSocketData.object_id) {
                    if (webSocketData.event_success) {
                        if (webSocketData.object_status === "pro_forma") {
                            invoiceResults['is_pro_forma_sent'] = true
                        }
                        setInvoiceResults({ ...invoiceResults, status: webSocketData.object_status })
                        stateChangeManager(dispatch, Actions, true, "success", `Email delivered successfully for ${webSocketData.object_number}`);
                    } else {
                        stateChangeManager(dispatch, Actions, true, "error", `Email delivery failed for ${webSocketData.object_number}`);
                    }
                }

                if (webSocketData.event === "delete_document" && invoiceResults.id === webSocketData.object_id) {
                    if (webSocketData.event_success) {
                        stateChangeManager(dispatch, Actions, true, "success", `Invoice ${webSocketData.object_number} deleted successfully`);
                        navigate(routesName.invoicingSalesInvoice.path);
                    } else {
                        stateChangeManager(dispatch, Actions, true, "error", `Failed to delete Invoice ${webSocketData.object_number}`);
                    }
                }

                if (webSocketData.event === "update_document" && invoiceResults.id === webSocketData.object_id) {
                    if (webSocketData.event_success) {
                        stateChangeManager(dispatch, Actions, true, "success", `Invoice ${webSocketData.object_number} updated successfully`);
                        getInvoice();
                    } else {
                        stateChangeManager(dispatch, Actions, true, "error", `Failed to update Invoice ${webSocketData.object_number}`);
                    }
                }
            }
        }
    }, [webSocketResponse])

    const viewJournalEntryHandler = (id) => {
        setState({
            id: id,
            open: true,
            maxWidth: 'sm',
            fullWidth: true,
            condition: 'view',
            title: 'View Journal Entry',
        })
    }
    const stateChangeHandler = (title, condition, maxWidth, id, item, url, deleteMessage) => {
        setState({
            id: id,
            url: url,
            open: true,
            item: item,
            title: title,
            fullWidth: true,
            maxWidth: maxWidth,
            condition: condition,
            deleteMessage: deleteMessage,
            navigate: () => navigate(routesName.invoicingSalesInvoice.path),
        })
    };

    const getInvoice = async () => {
        setLoader(dispatch, Actions, true)
        let res = await apiAction({
            method: 'post',
            url: retrieve_invoice(params.id),
            data: { business_id: getBusinessInfo().id },
        })
        if (res?.success) {
            setInvoiceResults(res?.result);
            breadCrumbsStateChangeManager(dispatch, res?.result.invoice_number);
            let customerRes = await apiAction({
                method: 'post',
                url: get_party(res?.result.customer_id),
                data: { role: 'customer', business_id: getBusinessInfo().id },
            })
            if (customerRes.success) {
                setLoader(dispatch, Actions, false)
                setCustomerResults(customerRes.result)
            } else {
                setLoader(dispatch, Actions, false)
            }
        } else {
            setLoader(dispatch, Actions, false)
        }
    }

    React.useEffect(() => {
        if (!state.open && !applyNow && params.id) {
            getInvoice()
        }
    }, [applyNow, state.open]);

    const print = async (item) => {
        setLoader(dispatch, Actions, true);
        if (item) {
            apiBlobResponse({
                url: export_invoice(), data: { ...item, terms_and_conditions_id: item.terms_and_conditions, payment_term_id: item.payment_term, export_type: "pdf" }, onSuccess: () => {
                    setLoader(dispatch, Actions, false);
                }
            })
        }
    }
    const download = async (item) => {
        setLoader(dispatch, Actions, true);
        if (item) {
            apiHandleDownload({
                url: export_invoice(), data: { ...item, terms_and_conditions_id: item.terms_and_conditions, payment_term_id: item.payment_term, export_type: "pdf" }, filename: item.invoice_number, onSuccess: () => {
                    setLoader(dispatch, Actions, false);
                }
            })
        }
    }
    const send = async (item) => {
        setSendMail(true)
        // const sendInvoiceRes = await apiAction({ url: send_invoice_mail(), method: 'POST', data: { business_id: getBusinessInfo().id, invoice_id: item.id } })
        // if (sendInvoiceRes.success) {
        //     getInvoice();
        //     stateChangeManager(dispatch, Actions, true, 'success', sendInvoiceRes.status);
        // }
    }
    const markSent = async (id) => {
        const sentRes = await apiAction({ url: mark_invoice_sent(id), method: 'POST', data: { business_id: getBusinessInfo().id } })
        if (sentRes.success) {
            stateChangeManager(dispatch, Actions, true, 'success', sentRes.status);
        }else {
            stateChangeManager(dispatch, Actions, true, 'error', sentRes.status);
        }
    }
    const recordPayment = async (item) => {
        if (invoiceResults) {
            navigate(routesName.invoicingPaymentsReceived.path, { state: item })
        }
    }
    const approve = async (item) => {
        if (invoiceResults) {
            const approveInvoiceRes = await apiAction({ url: update_invoice(item.id), method: 'POST', data: { is_draft: false, is_pro_forma: false, customer_id: item.customer_id, business_id: getBusinessInfo().id } })
            if (approveInvoiceRes.success) {
                getInvoice();
                stateChangeManager(dispatch, Actions, true, 'success', approveInvoiceRes.status);
            }
            else {
                getInvoice();
                stateChangeManager(dispatch, Actions, true, 'error', approveInvoiceRes.status);
            }
        }
    }

    const onSelectAction = (action, item) => {
        setLoader(dispatch, Actions, true);
        if (action === "create") {
            setLoader(dispatch, Actions, false);
            navigate(routesName.invoicingSalesInvoiceAdd.path)
            AnalyticsEvent(eventsNames.categories.INVOICES,{action:eventsNames.actions.NEW})
        }
        if (action === "clone") {
            AnalyticsEvent(eventsNames.categories.INVOICES,{action:eventsNames.actions.CLONE})
            if (item.is_converted_from_expense) {
                setLoader(dispatch, Actions, false)
                stateChangeManager(dispatch, Actions, true, "error", `Invoice created from the expense cannot be cloned`);
            } else {
                navigate(routesName.invoicingSalesInvoiceAdd.path + "?clone_id=" + item.id)
            }
        }
        if (action === "edit") {
            navigate(routesName.invoicingSalesInvoiceAdd.path + "?id=" + item.id)
        }
        if (action === "approve") {
            approve(item)
            AnalyticsEvent(eventsNames.categories.INVOICES,{action:eventsNames.actions.APPROVE})
        }
        if (action === "send") {
            send(item.id)
            AnalyticsEvent(eventsNames.categories.INVOICES,{action:eventsNames.actions.SEND})
        }
        if (action === "mark_sent") {
            markSent(item.id)
            AnalyticsEvent(eventsNames.categories.INVOICES,{action:eventsNames.actions.MARK_SENT})
        }
        if (action === "print") {
            print(item)
            AnalyticsEvent(eventsNames.categories.INVOICES,{action:eventsNames.actions.PRINT})
        }
        if (action === "download") {
            download(item)
            AnalyticsEvent(eventsNames.categories.INVOICES,{action:eventsNames.actions.DOWNLOAD})
        }
        if (action === "record_payment") {
            AnalyticsEvent(eventsNames.categories.INVOICES,{action:eventsNames.actions.RECORD_PAYMENT})
            if (item.total_amount === 0) {
                stateChangeManager(dispatch, Actions, true, 'error', `Record payment is not possible for invoice with zero amount`);
            } else {
                recordPayment(item)
                setLoader(dispatch, Actions, false);
            }

        }
    }

    const onClickActionText = (status, item) => {
        if (status === 'draft') {
            onSelectAction("approve", item)
        } else if (status === 'pro_forma') {
            onSelectAction("approve", item)
        } else if (status === 'unsent') {
            onSelectAction("send", item)
        } else if (status === 'sent' || status === 'overdue' || status === 'partially_paid') {
            onSelectAction("record_payment", item)
        }
        else if (status === 'paid') {
            onSelectAction("create", item)
        }
    }

    const toActionText = (status) => {
        let text
        if (status === 'draft') {
            text = 'Approve'
        } else if (status === 'pro_forma') {
            text = 'Convert to Invoice'
        } else if (status === 'unsent') {
            text = 'Send'
        } else if (status === 'sent' || status === 'overdue' || status === 'partially_paid') {
            text = 'Record payment'
        } else if (status === 'paid') {
            text = 'Create New Invoice'
        }
        return text
    }

    const [open, setOpen] = React.useState(false);
    const [sendMail, setSendMail] = React.useState(false);

    const getQuickActions = (item) => {
        const actions = [];

        if (['pro_forma'].includes(item.status)) {
            actions.push({
                name: item.is_pro_forma_sent ? 'Resend' : 'Send',
                onClick: () => {
                    onSelectAction("send", item);
                }
            })
        }

        if (!['draft', 'unsent', 'pro_forma'].includes(item.status)) {
            actions.push({
                name: 'Resend',
                onClick: () => {
                    onSelectAction("send", item);
                }
            });
        }

        actions.push({ name: 'Clone', onClick: () => onSelectAction('clone', item) },);

        if (['unsent'].includes(item.status)) {
            actions.push({ name: 'Mark as sent', onClick: () => { onSelectAction("mark_sent", item) } });
            actions.push({ name: 'Record payment', onClick: () => { onSelectAction("record_payment", item) } });
        }

        if (!['pro_forma'].includes(item.status)) {
            actions.push({
                name: 'Make Recurring', onClick: () => {
                    AnalyticsEvent(eventsNames.categories.INVOICES,{action:eventsNames.actions.MAKE_RECURRING})
                    if (item.is_converted_from_expense) {
                        setLoader(dispatch, Actions, false)
                        stateChangeManager(dispatch, Actions, true, "error", `Invoice created from the expense cannot be cloned`);
                    } else {
                        navigate(routesName.invoicingSalesRecurringInvoiceAdd.path + '?mark_id=' + item.id)
                    }
                }
            },)
        }

        actions.push(
            {
                name: 'Delete',
                showDivider: true,
                onClick: () => {
                    stateChangeHandler('Delete Invoice', 'delete', 'sm', '', '', delete_invoice(item.id), `The invoice will be deleted and can not be retrieved later.\n Are you sure about deleting it?`);
                    AnalyticsEvent(eventsNames.categories.INVOICES,{action:eventsNames.actions.DELETE})
                }
            }
        )

        return actions;


    }
    return (
        applyNow ?

            <div>
                {
                    invoiceResults && customerResults &&
                    <div>
                        <ApplyCredit
                            dispatch={dispatch}
                            setApplyNow={setApplyNow}
                            invoice={invoiceResults}
                            customer={customerResults}
                        />
                    </div>
                }
            </div>
            :
            <div >
                <CustomDialog
                    state={state}
                    setState={setState}
                />

                {
                    invoiceResults && customerResults &&
                    <div>
                        <CommonPreviewShare
                            open={sendMail}
                            type={'invoice'}
                            id={invoiceResults.id}
                            file_key={'invoice_number'}
                            export_Url={export_invoice}
                            retrieve_Url={retrieve_invoice}
                            setOpen={(value) => { setSendMail(value); getInvoice() }}
                        />
                        <CustomTitleContainer>
                            <Grid container spacing={0} style={{
                                alignItems: 'center',
                                justifyItems: 'center',
                                alignContent: 'space-evenly',
                                justifyContent: 'space-evenly',
                            }}>
                                <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'start', alignItems: 'center' }}>
                                    <CustomTitle title={invoiceResults.invoice_number} />
                                    <Status status={invoiceResults.status} sx={{ ml: 1, }} />
                                </Grid>
                                <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'end', }}>
                                    <ViewPageActionButtons
                                        edit={() => onSelectAction("edit", invoiceResults)}
                                        print={() => onSelectAction("print", invoiceResults)}
                                        download={() => onSelectAction("download", invoiceResults)}
                                        isEditable={['pro_forma', 'draft', 'unsent'].includes(invoiceResults.status)}
                                        actionsList={getQuickActions(invoiceResults)}
                                    />
                                    {
                                        (invoiceResults.status === 'sent' || invoiceResults.status === 'overdue' || invoiceResults.status === 'partially_paid') &&
                                        <CommonShareFile
                                            open={open}
                                            setOpen={setOpen}
                                            isReminder={true}
                                            title='Send Reminder'
                                            id={invoiceResults.id}
                                            to_email={invoiceResults.customer_emails.to}
                                            cc_email={invoiceResults.customer_emails.cc}
                                            reminderAnalyticsEvent={()=>{AnalyticsEvent(eventsNames.categories.INVOICES,{action:eventsNames.actions.invoice.action.INVOICE_REMINDER})}}
                                        />
                                    }
                                    <AuditTrailHistory
                                    AnalyticsEvent={()=>{AnalyticsEvent(eventsNames.categories.INVOICES,{action:eventsNames.actions.HISTORY})}}
                                        data={{ object_id: invoiceResults.id, object_type: 'invoice' }}
                                    />
                                </Grid>
                            </Grid>
                        </CustomTitleContainer>

                        <CustomContainer maxWidth={"400"} sx={{ height: 'calc(100vh - 134px)', }}>
                            <Box sx={{
                                p: 2,
                                border: '1px solid #FFF0C0', backgroundColor: "#FFF7DC"
                            }}>

                                <Grid container sx={{ mb: 1 }}>
                                    <Grid item xs={12} sm={6} sx={{ pl: 0, pb: 1, borderRight: '1px solid #F1D881' }}>
                                        <CustomTypography
                                            text={<span>Credits Available:</span>}
                                            sx={{
                                                mb: 2,
                                                color: '#7A7A7A',
                                                fontSize: '14px',
                                                fontWeight: '600',
                                                lineHeight: '19px',
                                                fontStyle: 'normal',
                                                textTransform: 'none',
                                                fontFamily: "Noto Sans",
                                            }}
                                        />

                                        {/*
                                        {
                                            customerResults.unused_credit > 0 && invoiceResults.due_amount > 0 && invoiceResults.status !== 'draft' &&
                                            <CustomTypography
                                                text={'Apply Now'}
                                                sx={{
                                                    pl: 1,
                                                    pr: 1,
                                                    pb: 0.6,
                                                    pt: 0.6,
                                                    height: '100px',
                                                    borderRadius: 1,
                                                    color: '#ffffff',
                                                    lineHeight: '0px',
                                                    display: 'inline',
                                                    fontSize: '12px',
                                                    cursor: 'pointer',
                                                    textAlign: 'center',
                                                    fontWeight: '700',
                                                    fontStyle: 'normal',
                                                    fontFamily: "Noto Sans",
                                                    backgroundColor: '#2464EB',
                                                }}
                                                onClick={() => setApplyNow(!applyNow)}
                                            />
                                        } */}
                                        <Box sx={{ display: 'flex' }}>
                                            <CustomTypography
                                                text={<CurrencyFormatter amount={customerResults.unused_credit} currency={customerResults.currency ? customerResults.currency.currency_code : null} />}
                                                sx={{
                                                    mr: 1,
                                                    fontSize: '24px',
                                                    color: '#000000',
                                                    fontWeight: '700',
                                                    lineHeight: '0px',
                                                    textAlign: 'center',
                                                    fontStyle: 'normal',
                                                    textTransform: 'none',
                                                    fontFamily: "Noto Sans",
                                                }}
                                            />
                                            {
                                                customerResults.unused_credit > 0 && invoiceResults.due_amount > 0 && invoiceResults.status !== 'draft' &&
                                                <CustomTypography
                                                    id={'action_apply_now_btn'}
                                                    dataTestId={'action_apply_now_btn'}
                                                    text={'Apply Now'}
                                                    sx={{
                                                        color: '#2464EB',
                                                        fontSize: '14px',
                                                        lineHeight: '0px',
                                                        display: 'inline',
                                                        cursor: 'pointer',
                                                        fontWeight: '700',
                                                        fontStyle: 'normal',
                                                        textAlign: 'center',
                                                        fontFamily: "Noto Sans",
                                                        ":hover": { textDecoration: 'underline' }
                                                    }}
                                                    onClick={() => setApplyNow(!applyNow)}
                                                />
                                                // <button style={{
                                                //     border:'none',
                                                //     padding: '5px',
                                                //     paddingLeft: '10px',
                                                //     paddingRight: '10px',
                                                //     color: '#ffffff',
                                                //     fontSize: '12px',
                                                //     fontWeight: '700',
                                                //     cursor: 'pointer',
                                                //     marginTop: '-10px',
                                                //     borderRadius: '3px',
                                                //     fontStyle: 'normal',
                                                //     fontFamily: "Noto Sans",
                                                //     backgroundColor: '#202A44',
                                                // }} onClick={() => setApplyNow(!applyNow)}>Apply Now</button>
                                            }
                                        </Box>
                                    </Grid>
                                    {
                                        <Grid item xs={12} sm={6} sx={{ pl: 1.5, borderLeft: '1px solid #F1D881' }}>
                                            <CustomTypography
                                                text={<span>Action:</span>}
                                                sx={{
                                                    mb: 2,
                                                    color: '#7A7A7A',
                                                    fontSize: '14px',
                                                    fontWeight: '600',
                                                    lineHeight: '19px',
                                                    fontStyle: 'normal',
                                                    textTransform: 'none',
                                                    fontFamily: "Noto Sans",
                                                }}
                                            />
                                            <Box sx={{ display: 'flex' }}>
                                                {/* <CustomTypography
                                             text={toActionText(invoiceResults.status)?toActionText(invoiceResults.status):'Create New Invoice'}
                                             sx={{
                                                 mr: 1,
                                                 fontSize: '24px',
                                                 color: '#000000',
                                                 fontWeight: '700',
                                                 lineHeight: '0px',
                                                 textAlign: 'center',
                                                 fontStyle: 'normal',
                                                 textTransform: 'none',
                                                 fontFamily: "Noto Sans",
                                             }}
                                         /> */}
                                                {/* <Status status={invoiceResults.status} sx={{mt:-2, mr: 1, }} /> */}

                                                <CustomTypography
                                                    id={'action_status_btn'}
                                                    dataTestId={'action_status_btn'}
                                                    text={toActionText(invoiceResults.status)}
                                                    sx={{
                                                        color: '#2464EB',
                                                        fontSize: '14px',
                                                        cursor: 'pointer',
                                                        lineHeight: '0px',
                                                        display: 'inline',
                                                        fontWeight: '700',
                                                        fontStyle: 'normal',
                                                        textAlign: 'center',
                                                        fontFamily: "Noto Sans",
                                                        ":hover": { textDecoration: 'underline' }
                                                    }}
                                                    onClick={() => onClickActionText(invoiceResults.status, invoiceResults)}
                                                />
                                                {/* <button style={{
                                             border:'none',
                                             color: '#ffffff',
                                             fontSize: '12px',
                                             fontWeight: '700',
                                             cursor: 'pointer',
                                             marginTop: '-10px',
                                             borderRadius: '3px',
                                             fontStyle: 'normal',
                                             padding: '5px',
                                             fontFamily: "Noto Sans",
                                             backgroundColor: '#202A44',
                                         }} onClick={() => { if (toActionText(invoiceResults.status)) { onClickActionText(invoiceResults.status, invoiceResults) } else { } }}>{toActionText(invoiceResults.status) ? toActionText(invoiceResults.status) : 'Create New Invoice'}</button> */}
                                            </Box>
                                        </Grid>
                                    }

                                    {/* <Grid item xs={12} sm={4} sx={{ pl: 1.5, borderLeft: '1px solid #F1D881' }}>
                                        <CustomTypography
                                            text={<span>Done with this invoice?</span>}
                                            sx={{
                                                mb: 2,
                                                color: '#7A7A7A',
                                                fontSize: '14px',
                                                fontWeight: '600',
                                                lineHeight: '19px',
                                                fontStyle: 'normal',
                                                textTransform: 'none',
                                                fontFamily: "Noto Sans",
                                            }}
                                        />
                                        <Box sx={{ display: 'flex' }}>
                                            <button style={{
                                                border:'none',
                                                padding: '5px',
                                                paddingLeft: '10px',
                                                paddingRight: '10px',
                                                color: '#ffffff',
                                                fontSize: '12px',
                                                fontWeight: '700',
                                                cursor: 'pointer',
                                                marginTop: '-10px',
                                                marginRight: '10px',
                                                borderRadius: '3px',
                                                fontStyle: 'normal',
                                                fontFamily: "Noto Sans",
                                                backgroundColor: '#202A44',
                                            }} onClick={() => { }}>{'Create New Invoice'}</button>

                                            <button style={{
                                                border:'none',
                                                paddingLeft: '10px',
                                                paddingRight: '10px',
                                                padding: '5px',
                                                color: '#ffffff',
                                                fontSize: '12px',
                                                fontWeight: '700',
                                                cursor: 'pointer',
                                                marginTop: '-10px',
                                                borderRadius: '3px',
                                                fontStyle: 'normal',
                                                fontFamily: "Noto Sans",
                                                backgroundColor: '#2464EB',
                                            }} onClick={() => { }}>{'Make a Copy'}</button>
                                        </Box>
                                    </Grid> */}
                                </Grid>
                            </Box>
                            <Box sx={{}}>
                                <CustomTabs
                                    id={'tabs_'}
                                    dataTestId={'tabs_'}
                                    tabs={tabs}
                                    value={tab}
                                    onChange={(event, tab) => { setTab(tab) }}
                                />
                                {tabs[tab].condition === 'preview' && <Preview basicInformation={invoiceResults} dispatch={dispatch} />}
                                {tabs[tab].condition === 'payment_history' && <PaymentHistoryScreen basicInformation={invoiceResults} dispatch={dispatch} state={state} setState={setState} />}
                                {tabs[tab].condition === 'applied_credited' && <CreditedAppliedScreen basicInformation={invoiceResults} dispatch={dispatch} state={state} setState={setState} />}

                            </Box>

                        </CustomContainer>
                    </div>
                }
            </div>
    )
}

export default InvoiceViewpage;

const Preview = (props) => {

    const { basicInformation, dispatch } = props;
    const [previewData, setPreviewData] = React.useState();

    React.useEffect(() => {
        const getCustomerInvoice = async (data) => {
            setLoader(dispatch, Actions, true)
            let res = await apiAction({
                method: 'post',
                url: export_invoice(),
                data: { ...data, place_of_supply_id: data.place_of_supply ? data.place_of_supply.id : null, terms_and_conditions_id: data.terms_and_conditions, payment_term_id: data.payment_term, export_type: 'html' },
            })
            if (res?.success) {
                setPreviewData(res?.result);
                setLoader(dispatch, Actions, false);
            } else {
                setLoader(dispatch, Actions, false);
            }
        }
        if (basicInformation) {
            getCustomerInvoice(basicInformation)
        }
    }, [basicInformation]);

    React.useEffect(()=>{
        AnalyticsEvent(eventsNames.categories.INVOICES,{action:eventsNames.actions.invoice.action.VIEW})
    },[])
    return (
        previewData &&
        <React.Fragment>
            <Box sx={{ pt: 2 }}>
                <HTMLViewer html_string={previewData.html_string} />
            </Box>
            <CommonJournalEntryTransactionView id={basicInformation.journal_entry} />
        </React.Fragment>
    )
}

const PaymentHistoryScreen = (props) => {
    let navigate = useNavigate();

    const { basicInformation, dispatch, state, setState } = props

    const [page, setPage] = React.useState(1);
    const [paymentData, setPaymentData] = React.useState([]);
    const [pagination, setPagination] = React.useState({
        next: undefined,
        count: '0',
        previous: undefined,
        number_of_pages: undefined,
    });

    React.useEffect(() => {
        const getCustomerPayment = async (invoice_number) => {
            setLoader(dispatch, Actions, true)
            let res = await apiAction({
                method: 'post',
                url: get_list_of_payment(page),
                data: { payment_type: "receive", business_id: getBusinessInfo().id, reference_number: invoice_number },
            })
            if (res?.success) {
                setPagination({
                    ...pagination,
                    next: res?.result.next,
                    count: res?.result.count,
                    previous: res?.result.previous,
                    number_of_pages: res?.result.number_of_pages,
                })
                setPaymentData(res?.result.result)
                setLoader(dispatch, Actions, false)
            } else {
                setLoader(dispatch, Actions, false)
            }
        }
        if (basicInformation) {
            getCustomerPayment(basicInformation.invoice_number)
        }
    }, [basicInformation, page]);

    React.useEffect(()=>{
        AnalyticsEvent(eventsNames.categories.INVOICES,{action:eventsNames.actions.invoice.action.PAYMENT_HISTORY})
    },[])
    return (
        <React.Fragment>
            <CustomDialog
                state={state}
                setState={setState}
            />
            <CustomTableContainer sx={{ pt: 2 }}>
                <CustomTable>
                    <CustomTableHead>
                        <CustomTableRow>
                            <CustomTableHeadCell ><span >Date</span></CustomTableHeadCell>
                            <CustomTableHeadCell ><span >Payment#</span></CustomTableHeadCell>
                            {/* <CustomTableHeadCell ><span >Invoice</span></CustomTableHeadCell> */}
                            <CustomTableHeadCell ><span >Mode</span></CustomTableHeadCell>
                            <CustomTableHeadCell align='right' ><span >Total Amount</span></CustomTableHeadCell>
                            <CustomTableHeadCell align='right' ><span >Unused Amount</span></CustomTableHeadCell>
                            {/* <CustomTableHeadCell align='right' /> */}
                        </CustomTableRow>
                    </CustomTableHead>
                    <CustomTableBody>
                        {
                            paymentData.map((item, index) => {
                                return (
                                    <CustomTableRow key={index}>
                                        <CustomTableBodyCell sx={{}} ><span>{moment(item.payment_date).format(getDateFormat())}</span></CustomTableBodyCell>
                                        <CustomTableBodyCell sx={{}}  ><PaymentReceivedViewLink id={item.id} title={item.id} /></CustomTableBodyCell>
                                        {/* <CustomTableBodyCell sx={{}}  >{item.payment_for_reference_number.map((value, index) => { return (<span key={index}>{index ? ', ' : ''}{value}</span>) })}</CustomTableBodyCell> */}
                                        <CustomTableBodyCell sx={{}}  ><span>{item.payment_method.replace('_', ' ').toUpperCase()}</span></CustomTableBodyCell>
                                        <CustomTableBodyCell sx={{}} align='right'><span>{<CurrencyFormatter amount={item.total_amount} currency={item.currency_code} />}</span></CustomTableBodyCell>
                                        <CustomTableBodyCell sx={{}} align='right'><span>{<CurrencyFormatter amount={item.balance_amount} currency={item.currency_code} />}</span></CustomTableBodyCell>
                                        {/* <CustomTableHeadCell align='right' >
                                            <IconButton onClick={() => {
                                                item.is_advance ?
                                                    navigate(routesName.invoicingPaymentsReceivedView.path + "?payment_id=" + item.id, { replace: false })
                                                    :
                                                    navigate(routesName.invoicingPaymentsReceived.path + "?payment_id=" + item.id, { replace: false })
                                            }}>
                                                <ModeEditIcon fontSize="small" sx={{ color: 'grey' }} />
                                            </IconButton>
                                        </CustomTableHeadCell> */}
                                    </CustomTableRow>
                                )
                            })
                        }
                    </CustomTableBody>
                </CustomTable>
                <Box sx={{ pt: !paymentData.length ? 10 : 0 }} >
                    <NoDataComponent left='0%' top='0%' position={'relative'} data={paymentData} />
                </Box>
            </CustomTableContainer>
            {paymentData.length ?
                <CustomPagination
                    page={page}
                    count={pagination.number_of_pages}
                    onChange={(event, newValue) => setPage(newValue)}
                />
                : null
            }
        </React.Fragment>
    )
}

const CreditedAppliedScreen = (props) => {
    const { basicInformation, dispatch, state, setState } = props;

    const [results, setResults] = React.useState([]);

    const stateChangeHandler = (title, condition, maxWidth, id, item, url, deleteMessage) => {
        setState({
            id: id,
            url: url,
            open: true,
            item: item,
            title: title,
            fullWidth: true,
            maxWidth: maxWidth,
            condition: condition,
            deleteMessage: deleteMessage
        })
    };

    React.useEffect(() => {
        const getCustomerInvoice = async () => {
            setLoader(dispatch, Actions, true)
            let res = await apiAction({
                method: 'post',
                url: list_of_credited_invoices(),
                data: { business_id: getBusinessInfo().id, invoice_id: basicInformation.id, },
            })
            if (res?.success) {
                setResults(res?.result);
                setLoader(dispatch, Actions, false);
            } else {
                setLoader(dispatch, Actions, false);
            }
        }
        if (!state.open && basicInformation) {
            getCustomerInvoice()
        }
    }, [state.open]);

    React.useEffect(()=>{
        AnalyticsEvent(eventsNames.categories.INVOICES,{action:eventsNames.actions.invoice.action.CREDITS_APPLIED})
    },[])
    return (
        <React.Fragment>
            <CustomDialog
                state={state}
                setState={setState}
            />
            <CustomTableContainer sx={{ pt: 2 }}>
                <CustomTable>
                    <CustomTableHead>
                        <CustomTableRow>
                            <CustomTableHeadCell ><span >Date</span></CustomTableHeadCell>
                            <CustomTableHeadCell ><span >Credit Note Number</span></CustomTableHeadCell>
                            <CustomTableHeadCell align='right' ><span >Credit Amount Applied</span></CustomTableHeadCell>
                            <CustomTableHeadCell align='right' />
                        </CustomTableRow>
                    </CustomTableHead>
                    <CustomTableBody>
                        {
                            results.map((item, index) => {
                                return (
                                    <CustomTableRow key={index}>
                                        <CustomTableBodyCell sx={{}}  ><span>{moment(item.invoice_date).format(getDateFormat())}</span></CustomTableBodyCell>
                                        <CustomTableBodyCell sx={{}}  ><CreditNoteViewLink id={item.credit_note_id} title={item.credit_note_number} /></CustomTableBodyCell>
                                        <CustomTableBodyCell sx={{}} align='right'><span>{<CurrencyFormatter amount={item.credit_amount_applied} currency={item.currency_code} />}</span></CustomTableBodyCell>
                                        <CustomTableHeadCell align='right' >
                                            <IconButton size='small' id={'delete_credit_applied_btn' + index} dataTestId={'delete_credit_applied_btn' + index} onClick={() => { stateChangeHandler('Delete Credits Applied', 'delete', 'sm', item.id, item, delete_credits_applied_to_invoice(item.id), `Are you sure you want to delete the credits applied to Invoice?`) }}>
                                                <DeleteIcon fontSize="small" sx={{ color: 'grey' }} />
                                            </IconButton>
                                        </CustomTableHeadCell>
                                    </CustomTableRow>
                                )
                            })
                        }
                    </CustomTableBody>
                </CustomTable>
                <Box sx={{ pt: !results.length ? 10 : 0 }} >
                    <NoDataComponent left='0%' top='0%' position={'relative'} data={results} />
                </Box>
            </CustomTableContainer>
        </React.Fragment>
    )
}

const ApplyCredit = (props) => {
    const { customer, invoice, setApplyNow, dispatch } = props;

    const [applyPayment, setApplyPayment] = React.useState({
        credit_note_list: [],
        invoice_id: invoice.id,
        total_credit_applied: 0,
        business_id: getBusinessInfo().id,
    })

    React.useEffect(() => {
        const getCreditNotes = async () => {
            let initial_credit_note_list = []
            let res = await apiAction({
                method: 'post',
                url: get_credit_list_of_customer(),
                data: { business_id: getBusinessInfo().id, customer_id: customer.id, },
            })
            if (res?.success) {
                res?.result.map((item) => {
                    initial_credit_note_list.push({
                        credit_note_id: item.id,
                        applied_as_payment_amount: 0,
                        credit_type: item.credit_type,
                        total_amount: item.total_amount,
                        currency_code: item.currency_code,
                        credit_remaining: item.credit_remaining,
                        credit_note_date: item.credit_note_date,
                        credit_note_number: item.credit_note_number,
                    })
                })
                setApplyPayment({ ...applyPayment, credit_note_list: initial_credit_note_list })
            } else {
            }
        }
        if (customer) {
            getCreditNotes()
        }
    }, []);

    const onSave = async () => {
        let validation_data = [
            { key: "", validation: applyPayment.total_credit_applied === 0, message: 'Please enter a valid amount for Amount to Credit.' },
        ]
        const { isValid, message } = isFormValid(applyPayment, validation_data);
        if (isValid) {

            let res = await apiAction({
                method: 'post',
                data: { ...applyPayment },
                url: apply_credit_as_invoice_payment(),

            })
            if (res?.success) {
                setApplyNow(false)
                stateChangeManager(dispatch, Actions, true, 'success', res?.status);
            } else {
                stateChangeManager(dispatch, Actions, true, 'error', res?.status);
            }
        } else {
            stateChangeManager(dispatch, Actions, true, 'error', message);
        }
    }

    let remaining_due_amount = invoice.due_amount - applyPayment.total_credit_applied;

    return (
        <div>
            <CustomTitleContainer>
                <Grid container spacing={0} style={{
                    alignItems: 'center',
                    justifyItems: 'center',
                    alignContent: 'space-evenly',
                    justifyContent: 'space-evenly',
                }}>
                    <Grid item xs={6}>
                        <CustomTitle title={`Apply credits for ${invoice.invoice_number}`} />
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: 'end' }}>
                        <CustomTypography
                            text={<span>Invoice Balance: <CurrencyFormatter amount={invoice.due_amount} currency={invoice.currency_code} /></span>}
                            sx={{
                                color: '#000000',
                                fontSize: '14px',
                                fontWeight: '700',
                                textAlign: 'right',
                                lineHeight: '18px',
                                fontStyle: 'normal',
                                fontFamily: "Noto Sans",
                            }}
                        />
                    </Grid>

                </Grid>
            </CustomTitleContainer>

            <CustomContainer maxWidth={"400"} sx={{}}>
                <CustomTableContainer>
                    <CustomTable>
                        <CustomTableHead>
                            <CustomTableRow >
                                <CustomTableHeadCell align='left'>Credit Note/Excess Payment</CustomTableHeadCell>
                                <CustomTableHeadCell align='left'>Credit Note/Excess Payment Date</CustomTableHeadCell>
                                <CustomTableHeadCell align='right'>Credit Amount</CustomTableHeadCell>
                                <CustomTableHeadCell align='right'>Credits Available</CustomTableHeadCell>
                                <CustomTableHeadCell align='right'>Amount to Credit</CustomTableHeadCell>
                            </CustomTableRow>
                        </CustomTableHead>
                        <CustomTableBody>
                            {
                                applyPayment.credit_note_list.map((item, index) => {
                                    return (
                                        <CustomTableRow key={index}>
                                            <CustomTableBodyCell sx={{}} align='left' size='small'> <p>{item.credit_note_number}</p></CustomTableBodyCell>
                                            <CustomTableBodyCell sx={{}} align='left' size='small'><p>{item.credit_note_date ? moment(item.credit_note_date).format(getDateFormat()) : '-'}</p></CustomTableBodyCell>
                                            <CustomTableBodyCell sx={{}} align='right' size='small'><p><CurrencyFormatter amount={item.total_amount} currency={item.currency_code} /></p></CustomTableBodyCell>
                                            <CustomTableBodyCell sx={{}} align='right' size='small'><p><CurrencyFormatter amount={item.credit_remaining} currency={item.currency_code} /></p></CustomTableBodyCell>
                                            <CustomTableBodyCell sx={{}} align='right' size='small'>
                                                <Input
                                                    id={'input_applied_as_payment_amount' + index}
                                                    dataTestId={'input_applied_as_payment_amount' + index}
                                                    isSearchableComponent={true}
                                                    item={{
                                                        title: '',
                                                        type: 'number',
                                                        placeholder: '',
                                                        required: true,
                                                        fullWidth: true,
                                                        value: item.applied_as_payment_amount ? item.applied_as_payment_amount : '',
                                                        onChange: (e) => {
                                                            if (e.target.value) {
                                                                applyPayment.credit_note_list[index].applied_as_payment_amount = Number(limitDecimalPlaces(Number(e.target.value)))
                                                            } else {
                                                                applyPayment.credit_note_list[index].applied_as_payment_amount = 0
                                                            }
                                                            setApplyPayment({
                                                                ...applyPayment,
                                                            })
                                                        },
                                                        onBlur: (e) => {
                                                            // console.log('===>',limitDecimalPlaces(e.target.value) <= remaining_due_amount)
                                                            // console.log('===>2',limitDecimalPlaces(e.target.value) < applyPayment.credit_note_list[index].credit_remaining)

                                                            if (limitDecimalPlaces(e.target.value) <= remaining_due_amount) {
                                                                if (limitDecimalPlaces(e.target.value) > applyPayment.credit_note_list[index].credit_remaining) {
                                                                    applyPayment.credit_note_list[index].applied_as_payment_amount = applyPayment.credit_note_list[index].credit_remaining;
                                                                } else {
                                                                    applyPayment.credit_note_list[index].applied_as_payment_amount = Number(limitDecimalPlaces(Number(e.target.value)));
                                                                }
                                                            } else {
                                                                if (limitDecimalPlaces(e.target.value) < applyPayment.credit_note_list[index].credit_remaining) {
                                                                    applyPayment.credit_note_list[index].applied_as_payment_amount = limitDecimalPlaces(remaining_due_amount);
                                                                } else {
                                                                    applyPayment.credit_note_list[index].applied_as_payment_amount = 0;
                                                                }
                                                            }
                                                            setApplyPayment({
                                                                ...applyPayment,
                                                                total_credit_applied: applyPayment.credit_note_list.reduce((acc, item) => acc + item.applied_as_payment_amount, 0),

                                                            })
                                                        }
                                                    }}
                                                    onKeyPress={(event) => {
                                                        if (event?.key === '-' || event?.key === '+' || event?.key === 'e' || event?.key === 'E') {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                />
                                            </CustomTableBodyCell>
                                        </CustomTableRow>
                                    )
                                }
                                )}

                        </CustomTableBody>
                    </CustomTable>

                    <Grid container item xs={12} sx={{ p: 2, pt: 1 }}>
                        <Grid item xs={12} sm={8} sx={{}}>
                            <p style={{ fontSize: '14px' }}>Note : If there is any tax applied to the advance payment, the tax will be reversed.</p>
                        </Grid>
                        <Grid item sm={4} sx={12} container spacing={0}>
                            <Grid item xs={6} sx={{ textAlign: 'right', mt: 1 }}>Amount to Credit :</Grid>
                            <Grid item xs={6} sx={{ pr: 1, mt: 1, textAlign: 'right' }}>{<CurrencyFormatter amount={applyPayment.total_credit_applied} currency={invoice.currency_code} />}</Grid>
                            <Grid item xs={12} container sx={{ mt: 3, pb: 1, borderRadius: 1, background: '#EEEEEE', border: '1px dashed' }}>
                                <Grid item xs={6} sx={{ pr: 1, textAlign: 'right' }}><p>Invoice Balance Due :</p></Grid>
                                <Grid item xs={6} sx={{ pr: 1, textAlign: 'right' }}>
                                    <p>{<CurrencyFormatter amount={invoice.due_amount - applyPayment.total_credit_applied} currency={invoice.currency_code} />}</p>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CustomTableContainer>
            </CustomContainer>

            <CustomButtonContainer>
                <Buttons onSave={onSave} onCancel={() => setApplyNow(false)} />
            </CustomButtonContainer>
        </div>
    )
}

const Buttons = (props) => {
    const { onSave, onCancel } = props
    return (
        <Box component="form" noValidate  >
            <Grid container>
                <Grid item xs={12} sx={{ display: '-webkit-flex', justifyContent: 'end', }}>
                    <CustomButton id={'cancel_btn'} dataTestId={'cancel_btn'} variant='outlined' sx={{ textTransform: 'none', '&:hover': { backgroundColor: '#e8f3ff' } }} btnLabel='Cancel' onClick={onCancel} />
                    <CustomButton id={'save_btn'} dataTestId={'save_btn'} variant='contained' sx={{ ml: 1, textTransform: 'none', }} btnLabel={'Save'} onClick={onSave} />
                </Grid>
            </Grid>
        </Box>
    )
}
