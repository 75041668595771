import React from 'react'
import CustomDropdown from '../custom/dropdown/CustomDropdown'
import CustomTypography from '../custom/typography/CustomTypography'

const DateRange = (props) => {
    const { id, firstletter,dataTestId,isfilterOptions, sx, item, autoFocus, results, value, setValue, disableClearable, forcePopupIcon, label, placeholder, titleVisibility = "visible", validation, addButton, optionLabel, isChildren,isLastGroup, onSearchHandler,inputRef } = props

    return (
        <div>
            {label &&
                <CustomTypography
                    text={label}
                    sx={{
                        mb: 1,
                        color: '#000000',
                        fontSize: '14px',
                        fontWeight: '700',
                        lineHeight: '18px',
                        fontStyle: 'normal',
                        textTransform: "none",
                        fontFamily: "Noto Sans",
                        textTransform: 'capitalize',
                        visibility: titleVisibility,
                    }}
                />
            }
            <CustomDropdown
                id={id}
                results={results}
                onChange={setValue}
                inputRef={inputRef}
                autoFocus={autoFocus}
                addButton={addButton}
                dataTestId={dataTestId}
                validation={validation}
                placeholder={placeholder}
                isLastGroup={isLastGroup}
                firstletter={firstletter}
                optionLabel={optionLabel}
                setValue={onSearchHandler}
                value={value ? value : null}
                forcePopupIcon={forcePopupIcon}
                isfilterOptions={isfilterOptions}
                disableClearable={disableClearable}
                inputDisabledColor={item ? item.sx : null}
                inputDisabled={item ? item.disabled : null}
                sx={item ? { background: item.disabled ? '#F1F3F4' : null, ...item.sx, } : sx}
            // noOptionsText={noOptionsText}
            />
        </div>
    )
}

export default DateRange