import React from "react";
import { mainRoutes } from "../../config/routes";
import { sidebarMenu } from "../../config/routes";
import { routesName } from "../../config/routesName";
import Sidebar from "../../components/custom/navigation/sideBar/Sidebar";
import Navbar from "../../components/custom/navigation/navBar/Navbar";

import {
  getToken,
  // getBusinessInfo,
  // getUserDetails,
  isAuthenticated,
} from "../../config/cookiesInfo";
import {
  Routes,
  Route,
  Navigate,
  useNavigate
} from "react-router-dom";

import {
  Box,
} from "@mui/material";
// import { analytics } from "../../firebase/firebaseConfig";
// import { setUserProperties } from "firebase/analytics";


function MainLayout() {
  // console.log('MainLayout=>')

  const navigate = useNavigate();

  const ProtectedRoute = ({ children }) => {
    if (!isAuthenticated()) {
      if (getToken()) {
        return <Navigate to={"/auth" + routesName.businessCreate.path} replace />;
      } else {
        return <Navigate to={"/auth" + routesName.signIn.path} replace />;
      }
    }
    return children;
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     // analytics.setUserId(getUserDetails().email)
  //     setUserProperties(analytics, { business: getBusinessInfo().id, email: getUserDetails().email });
  //   }, 1000)
  // }, [])

  return (
    <Box sx={{ display: 'flex', position: 'fixed', width: "100%", }}>
      {isAuthenticated() && <Sidebar navigate={navigate} />}
      <Box className="main-container" sx={{ display: 'flex', flexDirection: 'column', width: "100%" }}>


        <Box sx={{ height: '60px', backgroundColor: 'white', alignItems: 'center' }}>


          {isAuthenticated() && <Navbar navigate={navigate} />}

          <Box sx={{ margin: '16px', mt: 0, height: '100vh', }}>
            <Routes>
              {
                mainRoutes.map((route, index) => {
                  return (
                    <Route key={index}
                      path={route.path}
                      element={
                        <ProtectedRoute>
                          {route.component}
                        </ProtectedRoute>
                      }
                    />
                  )
                }
                )
              }
            </Routes>
          </Box>

        </Box>

      </Box>
    </Box>

  );
}

export default MainLayout;




