import React from 'react';
import { apiAction } from '../../../../api/api';
import { list_units } from '../../../../api/urls';
import { getBusinessInfo } from '../../../../config/cookiesInfo';
import CustomDropdown from '../../../custom/dropdown/CustomDropdown';
import CustomTypography from '../../../custom/typography/CustomTypography';

export const UnitsList = (props) => {
    const { title, value, setValue, validation,defaultUnitSelected=()=>{} } = props
    const [result, setResult] = React.useState([])
    React.useEffect(() => {
        const apiResults = async () => {
            let res = await apiAction({
                method: 'post',
                url: list_units(),
                data: { business_id: getBusinessInfo().id },
            })
            if (res?.success) {
                let result = res?.result.map((item) => {
                    item['unit_name'] = `${item.name} (${item.code})`;
                    return item;
                })
                defaultUnitSelected(result.find((item)=>item.code==='pcs'))
                setResult(result)
            } else {

            }
        }
        apiResults()
        // eslint-disable-next-line
    }, [])
    return (
        <React.Fragment>
            <CustomTypography
                text={title ? title : ''}
                sx={{
                    mb: 1,
                    color: '#000000',
                    fontSize: '14px',
                    fontWeight: '700',
                    lineHeight: '18px',
                    fontStyle: 'normal',
                    textTransform: "none",
                    fontFamily: "Noto Sans",
                    textTransform: 'capitalize',
                }}
            />
            <CustomDropdown
                id={'unit_dropdown'}
                dataTestId={'unit_dropdown'}
                results={result}
                validation={validation}
                disableClearable={false}
                placeholder='Select Unit'
                optionLabel={'unit_name'}
                onChange={(event, newInputValue) => setValue(newInputValue)}
                value={value ? result.find((item) => item.id === value.id) : null}
            />
        </React.Fragment>
    )
}