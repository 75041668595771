import React from "react";
import { import_opening_balance, import_party, validate_party_import_file } from "../../../../../../api/urls";
import { routesName } from "../../../../../../config/routesName";
import Transactions from "../../../../bulkStatementUpload/mappings/Transactions";


const VendorsPreview = (props) => {

    return (
        <Transactions 
            title={"Vendors"}
            isPerformValidation={true}
            validationUrl={validate_party_import_file()}  
            url={import_party()} 
            role={"vendor"}
            onFileErrorPath={routesName.invoicingPurchaseVendorImportFile.path} 
            nextPagePath={routesName.invoicingPurchaseVendor.path} 
            onCancelPath={routesName.invoicingPurchaseVendorImportMapping.path} 
        />

        
    )
}

export default VendorsPreview