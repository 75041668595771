import React from 'react';
import moment from 'moment';
import { fixed_table_key } from './helper';
import { apiAction } from "../../../../api/api";
import { getDateFormat } from '../../../../utils/Utils';
import { get_gstr3b_report } from '../../../../api/urls';
import { getBusinessInfo } from '../../../../config/cookiesInfo';
import CurrencyFormatter from '../../../common/CurrencyFormatter';
import CustomTypography from '../../../custom/typography/CustomTypography';

import {
    CustomTable,
    CustomTableRow,
    CustomTableBody,
    CustomTableHead,
    CustomTableHeadCell,
    CustomTableBodyCell,
    CustomTableContainer,
} from '../../../custom/table/CustomTable';

const CommonTable = (props) => {
    const { dateRange, toDate, fromDate, table_key, title, bodyBackgroundColor, headerBackgroundColor } = props;

    const [results, setResults] = React.useState([])

    React.useEffect(() => {
        if (dateRange) {
            if (dateRange.name !== "Custom") {
                getApiResults(dateRange.dates.from_date, dateRange.dates.to_date, dateRange.dates.date_range)
            } else {
                getApiResults(fromDate.format('YYYY-MM-DD'), toDate.format('YYYY-MM-DD'), `${fromDate.format(getDateFormat())} - ${toDate.format(getDateFormat())}`)
            }
        }
        // eslint-disable-next-line
    }, [dateRange, toDate, fromDate]);

    const getApiResults = async (from_date, to_date, range_date) => {
        let res = await apiAction({
            method: 'post',
            url: get_gstr3b_report(),
            data: {
                to_date: to_date,
                table_key: table_key,
                from_date: from_date,
                business_id: getBusinessInfo().id,
            },
        })

        if (res?.success) {
            setResults(res?.result);
            console.log(table_key, res?.result)
        } else {
        }
    }
    return (
        <div>
            <CustomTypography
                text={title}
                sx={{
                    mb: 1,
                    color: '#141414',
                    fontSize: '14px',
                    fontWeight: '700',
                    lineHeight: '27px',
                    fontStyle: 'normal',
                    textTransform: 'none',
                    fontFamily: "Noto Sans",
                }}
            />
            <CustomTableContainer sx={{}}>
                <CustomTable sx={{ border: '1px solid #c5cddd', borderBottom: 'none' }}>
                    <Header data={fixed_table_key[table_key]} headerBackgroundColor={headerBackgroundColor} />
                    <Body data={fixed_table_key[table_key]} results={results} bodyBackgroundColor={bodyBackgroundColor} table_key={table_key} />
                </CustomTable>
            </CustomTableContainer>
        </div>
    )
}

export default CommonTable;

const Header = (props) => {
    const { data, headerBackgroundColor = '#e3edff' } = props;

    return (
        <CustomTableHead >
            <CustomTableRow >
                {
                    data && data.map((item, index) => {
                        return (
                            <React.Fragment key={index}>
                                <CustomTableHeadCell align={item.align}
                                    sx={{
                                        borderBottom: '1px solid #c5cddd',
                                        backgroundColor: headerBackgroundColor,
                                        borderLeft: index ? '1px solid #c5cddd' : '',

                                    }}>
                                    {item.label}
                                </CustomTableHeadCell>
                            </React.Fragment>
                        )
                    })
                }

            </CustomTableRow>
        </CustomTableHead>
    )
}

const Body = (props) => {
    const { table_key, data, results, bodyBackgroundColor = '#a7c2f4' } = props;
    return (
        <CustomTableBody>
            <CustomTableRow sx={{ backgroundColor: bodyBackgroundColor }}>
                {
                    data.map((item, index) => {
                        return (
                            <React.Fragment key={index}>
                                <CustomTableBodyCell align='center'
                                    sx={{
                                        borderBottom: '1px solid #c5cddd',
                                        borderLeft: index ? '1px solid #c5cddd' : '',
                                    }}
                                >{index + 1}</CustomTableBodyCell>
                            </React.Fragment>
                        )
                    })
                }
            </CustomTableRow>
            {
                table_key === 'table_3_2' ?
                    <Customize
                        data={data}
                        results={results}
                    />
                    :
                    table_key === 'table_4' ?
                        <Table4
                            data={data}
                            results={results}
                        />
                        :
                        <Normal
                            data={data}
                            results={results}
                        />
            }
        </CustomTableBody>
    )
}

const Normal = (props) => {
    const { data, results } = props;
    return (
        results.map((item, index) => {
            return (
                item && data &&
                <CustomTableRow key={index}>
                    {
                        data.map((value, value_index) => {
                            return (
                                <React.Fragment key={value_index}>
                                    <CustomTableBodyCell align={value.align}
                                        sx={{
                                            borderBottom: '1px solid #c5cddd',
                                            borderLeft: value_index ? '1px solid #c5cddd' : '',
                                            backgroundColor: item[value.field] === '' ? '#F1F3F4' : ''
                                        }}>
                                        {
                                            item[value.field] !== '' ?
                                                value.numberWithCommas ? <CurrencyFormatter amount={item[value.field]} currency={null} /> :
                                                    item[value.field]
                                                : ''
                                        }
                                    </CustomTableBodyCell>

                                </React.Fragment>
                            )
                        })
                    }
                </CustomTableRow>
            )
        })
    )
}

const Customize = (props) => {
    const { data, results } = props;
    return (
        results.map((item, index) => {
            return (
                item && data &&
                <React.Fragment key={index}>
                    <CustomTableRow >
                        {
                            data.map((value, value_index) => {
                                return (
                                    <React.Fragment key={value_index}>
                                        <CustomTableBodyCell align={value.align}
                                            sx={{
                                                backgroundColor: '#F1F3F4'
                                            }}
                                        >
                                            {
                                                item[value.field]
                                            }
                                        </CustomTableBodyCell>

                                    </React.Fragment>
                                )
                            })
                        }
                    </CustomTableRow>
                    <CustomTableRow>
                        {results.length - 1 === index ?
                            <CustomTableBodyCell align={'center'} colSpan={5}
                                sx={{
                                    borderTop: '1px solid #c5cddd',
                                    borderBottom: '1px solid #c5cddd',
                                }}>
                                ---- Not Supported ----
                            </CustomTableBodyCell>
                            :
                            data.map((value, value_index) => {
                                return (
                                    <React.Fragment key={value_index}>
                                        <CustomTableBodyCell align={value.align}
                                            sx={{
                                                borderTop: '1px solid #c5cddd',
                                                borderBottom: '1px solid #c5cddd',
                                                borderLeft: value_index ? '1px solid #c5cddd' : '',
                                            }}
                                        >
                                        </CustomTableBodyCell>

                                    </React.Fragment>
                                )
                            })
                        }
                    </CustomTableRow>
                </React.Fragment>
            )
        })
    )
}

const Table4 = (props) => {
    const { data, results } = props;
    return (
        results.map((result, index) => {
            return (
                result && data &&
                <React.Fragment key={index}>
                    {
                        Array.isArray(result.section_values) ?
                            <CustomTableRow >
                                <CustomTableBodyCell align='left' sx={{ borderBottom: '1px solid #c5cddd', }} colSpan={6}>
                                    {result.section_name}
                                </CustomTableBodyCell>

                            </CustomTableRow>
                            :
                            [result.section_values].map((item, index) => {
                                return (
                                    <CustomTableRow key={index}>
                                        {
                                            data.map((value, value_index) => {
                                                return (
                                                    <React.Fragment key={value_index}>
                                                        <CustomTableBodyCell align={value.align}
                                                            sx={{
                                                                borderBottom: '1px solid #c5cddd',
                                                                borderLeft: value_index ? '1px solid #c5cddd' : '',
                                                                backgroundColor: item[value.field] === '' ? '#F1F3F4' : ''
                                                            }}>
                                                            {
                                                                item[value.field] !== '' ?
                                                                    value.numberWithCommas ? <CurrencyFormatter amount={item[value.field]} currency={null} /> :
                                                                        item[value.field] ? item[value.field] :
                                                                            result.section_name
                                                                    : ''
                                                            }
                                                        </CustomTableBodyCell>

                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </CustomTableRow>
                                )
                            })
                    }
                    {
                        Array.isArray(result.section_values) &&
                        result.section_values.map((item, index) => {
                            return (
                                <CustomTableRow key={index} >
                                    {
                                        item.value ?
                                            <React.Fragment>
                                                <CustomTableBodyCell align='left' sx={{ borderBottom: '1px solid #c5cddd', }}>
                                                    <span style={{ paddingLeft: '20px' }}>
                                                        {item.details}
                                                    </span>
                                                </CustomTableBodyCell>
                                                <CustomTableBodyCell align='center' sx={{ borderLeft: '1px solid #c5cddd', borderBottom: '1px solid #c5cddd', }} colSpan={5}>
                                                    {item.value}
                                                </CustomTableBodyCell>
                                            </React.Fragment>
                                            :
                                            data.map((value, value_index) => {
                                                return (
                                                    <React.Fragment key={value_index}>
                                                        <CustomTableBodyCell align={value.align}
                                                            sx={{
                                                                borderBottom: '1px solid #c5cddd',
                                                                borderLeft: value_index ? '1px solid #c5cddd' : '',
                                                                backgroundColor: item[value.field] === '' ? '#F1F3F4' : ''
                                                            }}>
                                                            {
                                                                item[value.field] !== '' ?
                                                                    value.numberWithCommas ? <CurrencyFormatter amount={item[value.field]} currency={null} /> :
                                                                        <span style={{ paddingLeft: '20px' }}>{item[value.field]}</span>
                                                                    : ''
                                                            }
                                                        </CustomTableBodyCell>

                                                    </React.Fragment>
                                                )
                                            })
                                    }

                                </CustomTableRow>
                            )
                        })
                    }

                </React.Fragment>
            )
        })
    )
}