import {
  Box,
  styled,
  Skeleton,
} from '@mui/material'

export const CustomCard = styled(Box)({
  background: '#FFF',
  boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, 0.04)'
});


export const CustomSkeleton=styled(Skeleton)({
})