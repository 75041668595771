import React from 'react';
import moment from 'moment/moment';
import PaymentCreate from '../../payment/PaymentCreate';
import CustomTitle from '../../../../common/CustomTitle';
import { get_list_of_bill } from '../../../../../api/urls';
import CustomTabs from '../../../../custom/tabs/CustomTabs';
import { useNavigate, useLocation } from 'react-router-dom';
import { routesName } from '../../../../../config/routesName';
import AdvancePaymentCreate from '../../payment/AdvancePaymentCreate';
import { CustomTitleContainer } from '../../../../custom/container/CustomContainer';
import { eventsNames, AnalyticsEvent } from '../../../../../firebase/firebaseAnalytics';

import {
  getQueryParams,
  getDefaultPaymentMethod,
} from '../../../../../utils/Utils';

import {
  Box,
} from '@mui/material';

const CreatePaymentsMade = (props) => {
  let location = useLocation();
  const navigate = useNavigate();
  const params = getQueryParams(location.search);

  const [tab, setTab] = React.useState(0);
  const [tabs, setTabs] = React.useState(
    [
      { name: 'Bill Payment', condition: 'invoice' },
      { name: 'Vendor Advance', condition: 'advance' },
    ]
  );

  console.log('===>params', Object.keys(params).length, location.state)
  React.useEffect(() => {
    if (params.payment_id) {
      setTab(0);
      setTabs([{ name: 'Bill Payment', condition: 'invoice' }])
    }
    if (params.advance_payment_id) {
      setTab(0);
      setTabs([{ name: 'Vendor Advance', condition: 'advance' }])
    }
    if (location.state) {
      setTab(0);
      setTabs([{ name: 'Bill Payment', condition: 'invoice' }])
    }
  }, [])


  const onClose = (id) => {
    if (location.state) {
      // navigate(-1);
      navigate(routesName.invoicingPaymentsMadeView.path + "?payment_id=" + id);
    } else {
      navigate(routesName.invoicingPaymentsMadeView.path + "?payment_id=" + id);
    }
  }

  return (
    <Box>
      <CustomTitleContainer>
        <CustomTitle title={'Payments Made'} />
      </CustomTitleContainer>
      <Box sx={{ background: '#FFFFFF', }}>
        <CustomTabs
          id={'tabs_'}
          dataTestId={'tabs_'}
          tabs={tabs}
          value={tab}
          onChange={(event, tab) => { setTab(tab) }}
        />
      </Box>
      {tabs[tab].condition === 'invoice' &&
        <PaymentCreate
          role='vendor'
          title='Payments Made'
          dateText='Payment Date '
          accountText='Paid Through '
          nameText='Vendor Name '
          amountText='Payment Made '
          checkBoxText='Pay full amount '
          label={{
            dateText: 'Date',
            paymentText: 'Payment',
            amountDueText: 'Amount Due',
            amountText: 'Bill Amount',
            numberText: 'Bill Number',
            noteText: 'Notes (Internal use. Not visible to customer)',
            blurPopoverText: 'Amount changed to reflect the outstanding balance for this bill..'
          }}
          apiData={{
            party_key: 'vendor_id',
            url: get_list_of_bill(1),
            date_key: 'bill_date',
            payment_type: 'pay',
            reference_type: 'bill',
            amountDue_key: 'due_amount',
            amount_key: 'total_amount',
            number_key: 'bill_number',
          }}
          party_disabled={location.state ? true : false}

          payment_type='pay'
          party_id={location.state ? location.state.vendor_id : null}
          total_due_amount={location.state ? location.state.due_amount : 0}
          party_selected={location.state ? { name: location.state.vendor_display_name } : null}
          payment_method={location.state ? getDefaultPaymentMethod(location.state.payment_method) ? getDefaultPaymentMethod(location.state.payment_method).value : 'cash' : 'cash'}

          currency_id={location.state ? location.state.currency_id : null}
          currency_code={location.state ? location.state.currency_code : null}

          payment_for={location.state ? [{
            due_date: location.state.due_date,
            payment_amount: 0,
            reference_type: 'bill',
            amount: location.state.total_amount,
            amount_due: location.state.due_amount,
            currency_code: location.state.currency_code,
            const_amount_due: location.state.due_amount,
            reference_number: location.state.bill_number,
            date: moment(location.state.bill_date).format('YYYY-MM-DD'),
          }] : []}
          validation_data={[
            { key: "party_id", message: 'Please select vendor' },
            { key: "total_amount", message: 'Payment made field has been left empty.' },
            { key: "payment_date", message: 'Please select the payment date' },
            { key: "payment_method", message: 'Please select the payment mode' },
            { key: "account_id", message: 'Please select the paid through' },
          ]}
          onClose={onClose}
          AnalyticsEvent={(params) => { AnalyticsEvent(eventsNames.categories.PAYMENTS_MADE, params) }}
        />
      }
      {tabs[tab].condition === 'advance' &&
        <AdvancePaymentCreate
          role='vendor'
          taxText='Tax '
          onClose={onClose}
          payment_type='pay'
          nameText='Vendor Name '
          dateText='Payment Date '
          amountText='Payment Made '
          accountText='Paid Through '
          placeText='Source Of Supply '
          is_amount_tax_inclusive={false}
          AnalyticsEvent={(params) => { AnalyticsEvent(eventsNames.categories.PAYMENTS_MADE, params) }}

          validation_data={[
            { key: "party_id", message: 'Please select vendor' },
            { key: "total_amount", message: 'Payment made field has been left empty' },
            { key: "payment_date", message: 'Please select the payment date' },
            { key: "payment_method", message: 'Please select the payment mode' },
            { key: "account_id", message: 'Please select the paid through' },
          ]}
        />
      }


    </Box>

  )
}

export default CreatePaymentsMade