import React from "react";
import moment from "moment";
import Logo from "../../../common/Logo";
import { apiAction } from "../../../../api/api";
import { images } from "../../../../utils/Constant";
import { setLoader } from "../../../../utils/Utils";
import * as Actions from "../../../../state/Actions";
import CustomTitle from "../../../common/CustomTitle";
import ViewBusinessProfile from "./ViewBusinessProfile";
import CustomButton from "../../../custom/button/CustomButton";
import CustomTypography from "../../../custom/typography/CustomTypography";

import { get_list_of_business } from "../../../../api/urls";

import { Box, Grid } from "@mui/material";
import BusinessSharpIcon from "@mui/icons-material/BusinessSharp";
import CustomDialog from "../../../custom/dialog/CustomDialog";
import { getBusinessInfo } from "../../../../config/cookiesInfo";

import {
  CustomContainer,
  CustomTitleContainer,
} from "../../../custom/container/CustomContainer";

const ListBusiness = (props) => {
  const { initial_state } = props;
  React.useEffect(() => { setView(false) }, [initial_state]);

  const dispatch = Actions.getDispatch(React.useContext);
  const [state, setState] = React.useState({
    title: "",
    open: false,
    condition: "",
    maxWidth: "lg",
    fullWidth: true,
  });
  const onClickAddBusiness = () => {
    setState({
      open: true,
      maxWidth: "sm",
      fullWidth: true,
      condition: "create_business_account",
      title: "Add New Business Account",
    });
  };

  const [id, setId] = React.useState();
  const [view, setView] = React.useState(false);
  const [result, setResult] = React.useState([]);
  React.useEffect(() => {
    const getBusiness = async () => {
      setLoader(dispatch, Actions, true);
      let res = await apiAction({
        method: "get",
        url: get_list_of_business(),
      });
      if (res?.success) {
        const targetId = getBusinessInfo().id;
        let data = res?.result
        const itemToMove = data.find(item => item.id === targetId);
        const filteredData = data.filter(item => item.id !== targetId);

        const newData = [itemToMove, ...filteredData];
        setResult(newData);
        setLoader(dispatch, Actions, false);
      } else {
        setLoader(dispatch, Actions, false);
      }
    };
    getBusiness();
    // eslint-disable-next-line
  }, [view, state.open]);

  return (
    <>
      <CustomDialog state={state} setState={setState} />
      {view ? (
        <ViewBusinessProfile id={id} onCancel={() => setView(false)} />
      ) : (
        <div>
          <CustomTitleContainer>
            <Grid container spacing={0} style={{
              alignItems: "center",
              justifyItems: "center",
              alignContent: "space-evenly",
              justifyContent: "space-evenly",
            }}
            >
              <Grid item xs={6}>
                <CustomTitle title={"My Organizations"} />
              </Grid>
              <Grid item xs={6} sx={{ textAlign: "end" }}>
                <CustomButton
                  sx={{}}
                  id={"add_btn"}
                  btnLabel="Add"
                  variant="contained"
                  dataTestId={"add_btn"}
                  onClick={() => { onClickAddBusiness(); }}
                />
              </Grid>
            </Grid>
          </CustomTitleContainer>
          <CustomContainer maxWidth={"400"} sx={{ height: 'calc(100vh - 134px)', }}>
            {result.map((item, index) => {
              return (
                <Box
                  key={index}
                  onClick={() => {
                    setView(true);
                    setId(item.id);
                  }}
                >
                  <BusinessList item={item} />
                </Box>
              );
            })}
          </CustomContainer>
        </div>
      )}
    </>
  );
};

export default ListBusiness;

const BusinessList = (props) => {
  const { item } = props;
  return (
    <Box
      className={getBusinessInfo()?.id === item?.id ? "border-left-success" : ""}
      sx={{
        borderBottom: "2px solid #F5F5F5",
        cursor: "pointer",
        ":hover": { backgroundColor: "#f5f5f5" },
      }}
    >
      <Grid container>
        <Grid item xs={8}>
          <Box
            sx={{
              p: 1,
              display: "flex",
              // flexDirection: 'row',
            }}
          >
            <Box sx={{ width: 200 }}>
              {item?.logo_url ? (
                <Logo image={item?.logo_url} />
              ) : (
                <BusinessSharpIcon fontSize="large" />
              )}
            </Box>
            <Box sx={{ alignSelf: "center" }}>
              <CustomTypography
                text={item?.legal_name}
                sx={{
                  mb: 1,
                  color: "#000000",
                  fontSize: "14px",
                  fontWeight: "700",
                  lineHeight: "18px",
                  fontStyle: "normal",
                  fontFamily: "Noto Sans",
                }}
              />
              <CustomTypography
                text={item?.industry_name}
                sx={{
                  mb: 0.5,
                  color: "#7A7A7A",
                  fontSize: "12px",
                  fontWeight: "500",
                  lineHeight: "18px",
                  fontStyle: "normal",
                  fontFamily: "Noto Sans",
                }}
              />
              <CustomTypography
                text={
                  "Organization created on : " +
                  moment(item?.created_at).format("DD-MMM-YYYY")
                }
                sx={{
                  mb: 0.5,
                  color: "#7A7A7A",
                  fontSize: "12px",
                  fontWeight: "500",
                  lineHeight: "18px",
                  fontStyle: "normal",
                  fontFamily: "Noto Sans",
                }}
              />
            </Box>
          </Box>
        </Grid>
        {/* <Grid item xs={2} sx={{ textAlign: "center", alignSelf: "center" }}>
          <CustomButton
            id={'edit_btn'}
            dataTestId={'edit_btn'}
            btnLabel='Edit'
            variant="contained"
            onClick={() => { }}
            sx={{ textTransform: 'none', borderRadius: '4px', m: 1 }}
          />
        </Grid> */}
        <Grid item xs={2} sx={{ textAlign: "end", alignSelf: "center" }}>
          {item?.integration_mode === "tally" ? (
            <Box
              component="img"
              sx={{ p: 1, height: 40 }}
              alt={images?.tallyLogo.alt}
              src={images?.tallyLogo.src}
            />
          ) : null}
        </Grid>
      </Grid>
    </Box>
  );
};
