import List from "./List";
import BarChart from "./BarChart";
import LineChart from "./LineChart";
import PieChartView from "./PieChart";
import ScatterChart from "./ScatterChart";
const PieChart=PieChartView;


export {
    List,
    BarChart,
    PieChart,
    LineChart,
    ScatterChart,
};