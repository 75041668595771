import React, { useEffect } from 'react';
import { apiAction } from '../../../../api/api';
import * as Actions from '../../../../state/Actions';
import CustomInput from '../../../custom/input/CustomInput';
import { stateChangeManager } from '../../../../utils/Utils';
import CustomDialog from '../../../custom/dialog/CustomDialog';
import { getBusinessInfo } from '../../../../config/cookiesInfo';
import { LabelWithAsteriskMark } from '../../../common/CommonLabel';
import CustomDropdown from '../../../custom/dropdown/CustomDropdown';
import CustomTypography from '../../../custom/typography/CustomTypography';


import {
    create_sales_person,
    get_list_of_sales_person
} from '../../../../api/urls';

import {
    Box,
    Grid,
    Button,
    Container,
} from '@mui/material';


export const CreateSalesPerson = (props) => {
    const { onClose, onAddItem } = props
    const setState = Actions.getDispatch(React.useContext);

    const validation = (data, setState, Actions) => {
        var isValid = true
        if (data.get('first_name') === '') {
            stateChangeManager(setState, Actions, true, 'error', 'First Name field can not be empty')
            isValid = false
        } else if (data.get('last_name') === '') {
            stateChangeManager(setState, Actions, true, 'error', 'Last Name field can not be empty')
            isValid = false
        } else if (data.get('email') === '') {
            stateChangeManager(setState, Actions, true, 'error', 'Email field can not be empty')
            isValid = false
        }
        return isValid
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        // collect all the input into data based on input id
        const data = new FormData(event.currentTarget);

        // validate the data
        if (validation(data, setState, Actions)) {
            onClose()
            //Api call
            let res = await apiAction({
                method: 'post',
                url: create_sales_person(),
                data: {
                    email: data.get("email"),
                    last_name: data.get("last_name"),
                    first_name: data.get("first_name"),
                    business_id: getBusinessInfo().id,
                }
            })
            console.log('res', res)
            if (res?.success) {
                if (onAddItem) {
                    onAddItem(res?.result)
                }
                // checking the response and according to save the data in to 'js-cookie' for the future use
                stateChangeManager(setState, Actions, true, 'success', res?.status);

            } else {
                // stateChangeManager is a alert function which show the error message
                stateChangeManager(setState, Actions, true, 'error', res?.status)
            }
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{ mt: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} container spacing={1}>
                        <Grid item xs={6}>
                            <CustomTypography
                                text={<LabelWithAsteriskMark label={'First Name'} />}
                                sx={{
                                    mb: 1,
                                    fontSize: '14px',
                                    color: '#000000',
                                    fontWeight: '700',
                                    lineHeight: '18px',
                                    fontStyle: 'normal',
                                    textTransform: "none",
                                    fontFamily: "Noto Sans",
                                    textTransform: 'capitalize',
                                }}
                            />
                            <CustomInput
                                required
                                fullWidth
                                type="text"
                                id="first_name"
                                autoFocus={true}
                                name="first_name"
                                dataTestId="first_name"
                                placeholder='Enter First Name'
                            /></Grid>
                        <Grid item xs={6}>
                            <CustomTypography
                                text={<LabelWithAsteriskMark label={'Last Name'} />}
                                sx={{
                                    mb: 1,
                                    fontSize: '14px',
                                    color: '#000000',
                                    fontWeight: '700',
                                    lineHeight: '18px',
                                    fontStyle: 'normal',
                                    textTransform: "none",
                                    fontFamily: "Noto Sans",
                                    textTransform: 'capitalize',
                                }}
                            />
                            <CustomInput
                                required
                                fullWidth
                                type="text"
                                id="last_name"
                                dataTestId="last_name"
                                name="last_name"
                                placeholder='Enter Last Name'
                            /></Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 2 }}>
                        <CustomTypography
                            text={<LabelWithAsteriskMark label={'Email'} />}
                            sx={{
                                mb: 1,
                                fontSize: '14px',
                                color: '#000000',
                                fontWeight: '700',
                                lineHeight: '18px',
                                fontStyle: 'normal',
                                textTransform: "none",
                                fontFamily: "Noto Sans",
                                textTransform: 'capitalize',
                            }}
                        />
                        <CustomInput
                            required
                            fullWidth
                            id="email"
                            dataTestId="email"
                            name="email"
                            type='email'
                            placeholder='Enter Email Id'
                        />
                    </Grid>
                </Grid>
                <Button
                    id={'sales_person_save_btn'}
                    dataTestId={'sales_person_save_btn'}
                    fullWidth
                    type="submit"
                    variant="contained"
                    sx={{ mt: 3, mb: 2, textTransform: 'capitalize' }}>Save
                </Button>
            </Box>
        </Container>
    )
}


export const SalesPersonList = (props) => {
    const { title, onChange, defaultValue, onAddItem, isClear, validation } = props

    const [state, setState] = React.useState({
        id: '',
        title: '',
        open: false,
        condition: '',
        maxWidth: 'lg',
        fullWidth: true,
    })

    const addHandler = () => {
        setState({
            open: true,
            maxWidth: 'sm',
            fullWidth: true,
            title: 'Add Sales person',
            condition: 'add_sales_person',
        })
    }

    const [value, setValue] = React.useState()
    useEffect(() => {
        if (isClear) {
            setValue(null)
        }
    }, [isClear])

    const [results, setResult] = React.useState([])
    React.useEffect(() => {
        const apiResults = async () => {
            let res = await apiAction({
                method: 'post',
                url: get_list_of_sales_person(),
                data: { business_id: getBusinessInfo().id },
            })
            if (res?.success) {
                setResult(res?.result)
            } else {

            }
        }
        apiResults()
        // eslint-disable-next-line
    }, [state.open])

    const onChangeHandler = (newInputValue) => {
        setValue(newInputValue)
        onChange(newInputValue)
    }
    // console.log("value ",value,props.value)
    const onSalesPersonAdded = (item) => {
        results.push(item)
        setResult([...results])
        onAddItem(item)
        setValue(item)
    }
    return (
        <React.Fragment>
            <CustomDialog
                state={state}
                setState={setState}
                onAddItem={onSalesPersonAdded}
            />
            <CustomTypography
                text={title ? title : ''}
                sx={{
                    mb: 1,
                    fontSize: '14px',
                    color: '#000000',
                    fontWeight: '700',
                    lineHeight: '18px',
                    fontStyle: 'normal',
                    textTransform: "none",
                    fontFamily: "Noto Sans",
                    textTransform: 'capitalize',
                }}
            />
            <CustomDropdown
                id={'sales_person_dropdown'}
                dataTestId={'sales_person_dropdown'}
                results={results}
                validation={validation}
                disableClearable={false}
                placeholder='Select or add sales person'
                value={value ? value.name : defaultValue ? defaultValue : null}
                onChange={(event, newInputValue) => onChangeHandler(newInputValue)}
                addButton={{
                    title: '+ Add Sales Person',
                    onClick: () => addHandler(),
                }}
            />
        </React.Fragment>

    )
}