import moment from 'moment';
import * as React from 'react';
import validator from 'validator';
import Input from '../../../../common/Input';
import { ListTerms } from '../../common/Terms';
import DateRange from '../../../../common/DateRange';
import DatePicker from '../../../../common/DatePicker';
import * as Actions from '../../../../../state/Actions';
import { InputFile } from '../../../../common/InputFile';
import CustomTitle from '../../../../common/CustomTitle';
import CustomTabs from '../../../../custom/tabs/CustomTabs';
import { useLocation, useNavigate } from 'react-router-dom';
import { routesName } from '../../../../../config/routesName';
import CustomInput from '../../../../custom/input/CustomInput';
import { apiAction, apiFormData } from '../../../../../api/api';
import CustomButton from '../../../../custom/button/CustomButton';
import CurrencyDropdown from '../../../../common/CurrencyDropdown';
import { getBusinessInfo } from '../../../../../config/cookiesInfo';
import { LabelWithAsteriskMark } from '../../../../common/CommonLabel';
import CustomDropdown from '../../../../custom/dropdown/CustomDropdown';
import CustomTypography from '../../../../custom/typography/CustomTypography';
import { eventsNames,AnalyticsEvent } from '../../../../../firebase/firebaseAnalytics';

import {
  getPartyPreferences,
} from '../../../setting/settings/Preferences/common/getPreferences';

import {
  CustomContainer,
  CustomTitleContainer,
  CustomButtonContainer,
} from '../../../../custom/container/CustomContainer';

import {
  listPaymentMethod,
  GSTRegistrationTypeChoice,
} from '../../../../../utils/Constant';

import {
  get_party,
  update_party,
  create_party,
  list_payment_term,
  update_party_opening_balance,
} from '../../../../../api/urls';

import {
  isNumber,
  setLoader,
  getAddress,
  isValidPan,
  isFormValid,
  isAddressSame,
  getPaymentType,
  getQueryParams,
  stateChangeManager,
  isValidPhoneNumber,
  limitDecimalPlaces,
  disabled_gst_number,
  validateCityOrPostalCode,
  breadCrumbsStateChangeManager,
} from '../../../../../utils/Utils';

import {
  Box,
  Grid,
  Radio,
  Checkbox,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
import CustomDefaultDropdown from '../../../../custom/dropdown/CustomDefaultDropdown';


const Dropdown = DateRange;

const CreateNewCustomer = (props) => {
  const { onClose, onAddCustomer } = props

  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const dispatch = Actions.getDispatch(React.useContext);

  const [isFormSubmited, setFormSubmited] = React.useState(false)

  let location = useLocation();
  const params = getQueryParams(location.search);
  const [defaultPaymentTerm, setDefaultPaymentTerm] = React.useState();
  const default_Sort_by = () => { dispatch(Actions.stateChange("sort_by", params.id ? 'updated_at' : 'created_at')) }

  function mapCustomerData(updateCustomerData) {
    const defaultData = {
      role: 'customer',
      business_id: getBusinessInfo().id,
      party_contact_primary_data: {
        name: '',
        email: null,
        last_name: '',
        salutation: '',
        first_name: '',
        middle_name: '',
        mobile_number: '',
        contact_type: 'primary',
      },
      email: null,
      party_contact_data: [],
      gstin: null,
      website: null,
      fax_number: null,
      display_name: null,
      company_name: null,
      phone_number: null,
      other_details: null,
      gst_registration_type: 'consumer',
      selected_gst_registration_type: { helperText: `A customer who is a regular consumer`, name: `Consumer`, value: 'consumer' },

      party_type: getPartyPreferences('customer'),
      payment_term: null,
      payment_term_id: null,
      currency: getBusinessInfo().currency,
      currency_id: getBusinessInfo().currency.id,
      preferred_payment_method: null,
      opening_balance: 0,
      previous_opening_balance: 0,
      opening_balance_transaction_type: 'DEBIT',
      previous_opening_balance_transaction_type: 'DEBIT',
      opening_balance_as_on_date: null,
      pan: null,
      tpin: null,
      is_tds_applicable: false,
      billing_address: {
        business_id: getBusinessInfo().id,
        id: null,
        city: null,
        state: null,
        country: null,
        zip_code: null,
        address_type: 'billing',
        address_line1: null,
      },
      shipping_address: {
        business_id: getBusinessInfo().id,
        id: null,
        city: null,
        state: null,
        country: null,
        zip_code: null,
        address_type: 'shipping',
        address_line1: null,
      },
      same_as_billing_address: false,
      note: null,
      attachments: null,
    };

    if (updateCustomerData) {
      const partyContactPrimaryData = updateCustomerData.party_contact_data.find((value) => value.contact_type === 'primary') || defaultData.party_contact_primary_data;

      return {
        ...defaultData,
        ...updateCustomerData,
        party_contact_primary_data: partyContactPrimaryData,
        party_contact_data: updateCustomerData.party_contact_data || [],
        selected_gst_registration_type: GSTRegistrationTypeChoice.find((item) => item.value === updateCustomerData.gst_registration_type) || null,
        billing_address: {
          ...defaultData.billing_address,
          ...updateCustomerData.billing_address,
        },
        shipping_address: {
          ...defaultData.shipping_address,
          ...updateCustomerData.shipping_address,
        },
        same_as_billing_address: isAddressSame(updateCustomerData.billing_address ? getAddress(updateCustomerData.billing_address) : null, updateCustomerData.shipping_address ? getAddress(updateCustomerData.shipping_address) : null) || false,
      };
    }

    return defaultData;
  };

  const [customer, setCustomer] = React.useState(mapCustomerData());


  const onSave = async () => {
    setFormSubmited(true)

    const { party_contact_primary_data, gstin, gst_registration_type, phone_number, fax_number, website, shipping_address, billing_address, pan } = customer
    const noValidate = disabled_gst_number(gst_registration_type) ? false : true;

    let validation_data = [
      { key: '', validation: party_contact_primary_data.email && !validator.isEmail(party_contact_primary_data.email), message: 'Please enter valid email.' },
      { key: 'display_name', message: 'Please Enter Display Name' },
      { key: '', validation: !gst_registration_type, message: 'Please Select the GST Registration Type' },
      { key: '', validation: noValidate && !validator.isLength(String(gstin), { min: 15, max: 15 }), message: 'Please Enter GSTIN 15 Digits' },
      { key: '', validation: phone_number && !(phone_number && isValidPhoneNumber(phone_number)), message: 'Please Enter Valid Phone Number' },
      { key: '', validation: party_contact_primary_data.mobile_number && !(party_contact_primary_data.mobile_number && isValidPhoneNumber(party_contact_primary_data.mobile_number)), message: 'Please Enter Valid Mobile Number' },
      { key: '', validation: fax_number && !(fax_number && isValidPhoneNumber(fax_number)), message: 'Please Enter Valid Fax Number' },
      { key: '', validation: website && !(website && validator.isURL(website)), message: 'Please Enter Valid website URL' },
      { key: '', validation: (pan && !isValidPan(pan)), message: 'Please Enter Valid Pan Number' },
    ]

    const { isValid, message } = isFormValid(customer, validation_data);
    if (isValid) {
      setLoader(dispatch, Actions, true);
      customer.party_contact_data = [customer.party_contact_primary_data];
      customer["email"] = customer.party_contact_primary_data?.email;
      customer["currency_id"] = customer.currency_id ? customer.currency_id : getBusinessInfo()?.currency?.id;
      customer["payment_term_id"] = customer.payment_term_id ? customer.payment_term_id : defaultPaymentTerm?.id;


      if (params.id) {
        AnalyticsEvent(eventsNames.categories.CUSTOMERS,{action:eventsNames.actions.EDIT})
      } else {
        AnalyticsEvent(eventsNames.categories.CUSTOMERS,{action:eventsNames.actions.CREATE})
      }

      onOpeningBalanceUpdate();
    }
    else {
      setLoader(dispatch, Actions, false)
      stateChangeManager(dispatch, Actions, true, 'error', message);
    }
  }

  const onOpeningBalanceUpdate = async () => {
    const condition = customer.previous_opening_balance !== customer.opening_balance || customer.previous_opening_balance_transaction_type !== customer.opening_balance_transaction_type;
    if (params.id && condition) {
      let res = await apiAction({
        method: 'post',
        url: update_party_opening_balance(),
        data: {
          business_id: getBusinessInfo().id,
          party_id: Number(params.id),
          amount: customer.opening_balance ? customer.opening_balance : 0,
          opening_balance_transaction_type: customer.opening_balance_transaction_type,
        }
      })
      if (res?.success) {
        onCustomerSave();
        stateChangeManager(dispatch, Actions, true, 'success', res?.status);
      } else {
        stateChangeManager(dispatch, Actions, true, 'error', res?.status);
      }
    } else {
      onCustomerSave();
    }
  }

  const onCustomerSave = async () => {
    let res = await apiFormData({
      method: 'post',
      url: params.id ? update_party(params.id) : create_party(),
      data: {
        ...customer,
        opening_balance: customer.opening_balance ? customer.opening_balance : 0,
      },
    })

    if (res?.success) {
      default_Sort_by()
      setLoader(dispatch, Actions, false)
      stateChangeManager(dispatch, Actions, true, 'success', res?.status);
      if (onClose) {
        onClose();
        if (onAddCustomer) {
          onAddCustomer(res?.result)
        }
      } else (navigate(routesName.invoicingSalesCustomerView.path + "?id=" + res?.result.id,))
    } else {
      setLoader(dispatch, Actions, false)
      stateChangeManager(dispatch, Actions, true, 'error', res?.status)
    }
  }

  React.useEffect(() => {
    const getCustomer = async () => {
      breadCrumbsStateChangeManager(dispatch, 'Edit');
      setLoader(dispatch, Actions, true)
      let res = await apiAction({
        method: 'post',
        url: get_party(params.id),
        data: { business_id: getBusinessInfo().id, role: 'customer', },
      })
      if (res?.success) {
        setTimeout(() => {
          setLoader(dispatch, Actions, false)
          setCustomer(mapCustomerData(res?.result))
        }, 1500)

      } else {
        setLoader(dispatch, Actions, false)
      }
    }
    if (params.id) {
      getCustomer();

    }
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    const apiResults = async () => {
      let res = await apiAction({
        method: 'post',
        url: list_payment_term(),
        data: { business_id: getBusinessInfo().id },
      })
      if (res?.success) {
        let default_value = res?.result.find((value) => value.name === '30 Days')
        setDefaultPaymentTerm(default_value);
        setCustomer({ ...customer, payment_term_id: default_value?.id, payment_term: default_value })
      } else {

      }
    }
    apiResults()
    // eslint-disable-next-line
  }, [])

  return (
    <div>
      <CustomTitleContainer>
        <CustomTitle title={'Customer Information'} />
      </CustomTitleContainer>

      <CustomContainer maxWidth={"400"} sx={{}}>
        <div style={{ paddingLeft: '20px', paddingRight: '20px', }}>
          <Box >
            <Grid container spacing={0} sx={{ mb: 2 }}
              style={{
                alignItems: 'center',
                justifyItems: 'center',
                alignContent: 'space-evenly',
              }}
            >
              <Grid item sx={{ pr: 2, textAlign: 'left' }}>
                <CustomTypography
                  text={'Customer Type'}
                  sx={{
                    color: '#000000',
                    display: 'inline',
                    fontSize: '14px',
                    textAlign: 'start',
                    fontWeight: '700',
                    lineHeight: '18px',
                    fontStyle: 'normal',
                    fontFamily: "Noto Sans",
                  }}
                />
              </Grid>
              <Grid item sx={{ pl: 2, textAlign: 'left' }}>
                <RadioGroup row sx={{ marginBottom: 0 }} id={'customer_type_checkbox'} dataTestId={'customer_type_checkbox'}>
                  <FormControlLabel value={'business'}
                    label={<span style={{
                      color: '#000000',
                      fontSize: '14px',
                      lineHeight: '18px',
                      display: 'inline',
                      fontStyle: 'normal',
                      fontFamily: "Noto Sans",
                      fontWeight: customer.party_type === 'business' ? '600' : '400',
                    }}>Business</span>} control={<Radio sx={{ color: '#8F8F8F' }} checked={customer.party_type === 'business'} onChange={(e) => { setCustomer({ ...customer, party_type: e.target.value }) }} />} />
                  <FormControlLabel value={'individual'}
                    label={<span style={{
                      color: '#000000',
                      fontSize: '14px',
                      display: 'inline',
                      lineHeight: '18px',
                      fontStyle: 'normal',
                      fontFamily: "Noto Sans",
                      fontWeight: customer.party_type === 'individual' ? '600' : '400',
                    }}>Individual</span>} control={<Radio sx={{ color: '#8F8F8F' }} checked={customer.party_type === 'individual'} onChange={(e) => { setCustomer({ ...customer, party_type: e.target.value }) }} />} />
                </RadioGroup>
              </Grid>

            </Grid>
          </Box>
          <BasicInformation updateCustomerData={params.id} customer={customer} setCustomer={setCustomer} isFormSubmited={isFormSubmited} />
        </div>

        <CustomTabs
          id={'tabs_'}
          dataTestId={'tabs_'}
          tabs={tabs}
          value={value}
          onChange={(event, newValue) => setValue(newValue)}
        />
        <div style={{ paddingLeft: '24px', paddingRight: '24px', paddingBottom: '48px' }}>
          {tabs[value].condition === 'address' && <Address updateCustomerData={customer} customer={customer} setCustomer={setCustomer} isFormSubmited={isFormSubmited} />}
          {tabs[value].condition === 'taxInfo' && <TaxInfo customer={customer} setCustomer={setCustomer} isFormSubmited={isFormSubmited} />}
          {tabs[value].condition === 'PAB' && <PaymentAndBilling customer={customer} setCustomer={setCustomer} isFormSubmited={isFormSubmited} updateCustomerData={params.id} />}
          {tabs[value].condition === 'attachment' && <Attachment customer={customer} setCustomer={setCustomer} />}
          {tabs[value].condition === 'note' && <Note customer={customer} setCustomer={setCustomer} />}
        </div>
      </CustomContainer>

      <CustomButtonContainer>
        <Buttons onSave={onSave} onCancel={() => onClose ? onClose() : navigate(-1)} updateCustomerData={params.id} />
      </CustomButtonContainer>

    </div>
  );
}


const BasicInformation = (props) => {
  const { updateCustomerData, customer, setCustomer, isFormSubmited } = props;
  const { party_contact_primary_data } = customer;
  const { name, email, salutation, first_name, last_name, middle_name, mobile_number } = party_contact_primary_data;
  const { gstin, website, fax_number, company_name, phone_number, display_name, other_details, gst_registration_type } = customer;

  const [isDisplayName, SetIsDisplayName] = React.useState(false);

  const onChange = React.useCallback((key_name, value) => {
    setCustomer((prevCustomer) => ({ ...prevCustomer, [key_name]: value }));
  }, [setCustomer]);

  const disable = React.useMemo(() => disabled_gst_number(gst_registration_type), [gst_registration_type]);

  const displayNameOptions = React.useMemo(() => {
    const options = [];
    const salutationName = salutation ? salutation + " " : "";

    if (first_name || last_name || middle_name) {
      if (first_name) options.push(`${salutationName}${first_name}`.trim());
      if (middle_name) options.push(`${salutationName}${middle_name}`.trim());
      if (last_name) options.push(`${salutationName}${last_name}`.trim());
      if (first_name && last_name) options.push(`${salutationName}${first_name} ${last_name}`.trim());
      if (last_name && first_name && middle_name) options.push(`${salutationName}${last_name} ${first_name} ${middle_name}`.trim());
      if (first_name && middle_name && last_name) options.push(`${salutationName}${first_name} ${middle_name} ${last_name}`.trim());
      if (first_name && last_name && middle_name) options.push(`${salutationName}${first_name} ${last_name} ${middle_name}`.trim());
    }

    if (name) options.push(name.trim());
    if (company_name) options.push(company_name.trim());
    if (display_name) options.push(display_name.trim());

    return [...new Set(options.filter(option => option !== ""))];
  }, [salutation, first_name, last_name, middle_name, name, company_name, display_name]);


  return (
    <Box component="form" noValidate sx={{ mb: 5 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <Input
            id="input_customer_name"
            dataTestId="input_customer_name"
            item={{
              type: 'text',
              required: true,
              fullWidth: true,
              value: name || '',
              title: 'Customer Name',
              placeholder: 'Enter Customer Name',
              onChange: (e) => setCustomer((prevCustomer) => ({
                ...prevCustomer,
                display_name: isDisplayName ? display_name : company_name ? company_name : e.target.value,
                party_contact_primary_data: {
                  ...prevCustomer.party_contact_primary_data,
                  name: e.target.value,
                },
              })),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Input
            id="input_email"
            dataTestId="input_email"
            item={{
              type: 'text',
              title: 'Email',
              required: true,
              fullWidth: true,
              value: email || '',
              placeholder: 'Enter Email Address',
              validation: isFormSubmited && email && !validator.isEmail(email),
              // validation: isFormSubmited && email && (!email || (email && !validator.isEmail(email))),
              onChange: (e) => setCustomer((prevCustomer) => ({
                ...prevCustomer,
                email:e.target.value,
                party_contact_primary_data: {
                  ...prevCustomer.party_contact_primary_data,
                  email: e.target.value,
                },
              })),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Input
            id="input_phone_number"
            dataTestId="input_phone_number"
            item={{
              type: 'text',
              title: 'Phone',
              required: true,
              fullWidth: true,
              placeholder: 'Enter Phone Number',
              value: phone_number || '',
              onChange: (e) => onChange("phone_number", e.target.value),
              validation: isFormSubmited && (phone_number && !isValidPhoneNumber(phone_number)),
            }}
            onKeyPress={(event) => {
              if (event.key.toLowerCase() === 'e') {
                event.preventDefault();
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Input
            id="input_mobile_number"
            dataTestId="input_mobile_number"
            item={{
              type: 'text',
              title: 'Mobile',
              required: true,
              fullWidth: true,
              placeholder: 'Enter Mobile Number',
              value: mobile_number || '',
              onChange: (e) => setCustomer((prevCustomer) => ({
                ...prevCustomer,
                party_contact_primary_data: {
                  ...prevCustomer.party_contact_primary_data,
                  mobile_number: e.target.value,
                },
              })),
              validation: isFormSubmited && (mobile_number && !isValidPhoneNumber(mobile_number)),
            }}
            onKeyPress={(event) => {
              if (event.key.toLowerCase() === 'e') {
                event.preventDefault();
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Input
            id="input_fax_number"
            dataTestId="input_fax_number"
            item={{
              title: 'Fax',
              type: 'text',
              required: true,
              fullWidth: true,
              placeholder: 'Enter Fax Number',
              value: fax_number || '',
              onChange: (e) => onChange("fax_number", e.target.value),
              validation: isFormSubmited && (fax_number && !isValidPhoneNumber(fax_number)),
            }}
            onKeyPress={(event) => {
              if (event.key.toLowerCase() === 'e') {
                event.preventDefault();
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Input
            id="input_company_number"
            dataTestId="input_company_number"
            item={{
              type: 'text',
              required: true,
              fullWidth: true,
              title: 'Company',
              placeholder: 'Enter Company Name',
              value: company_name || '',
              onChange: (e) => setCustomer((prevCustomer) => ({
                ...prevCustomer,
                company_name: e.target.value,
                display_name: isDisplayName ? display_name : e.target.value,
              })),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CustomTypography
            text={<LabelWithAsteriskMark label="Display Name As" />}
            sx={{
              mb: 1,
              color: '#000000',
              fontSize: '14px',
              fontWeight: '700',
              lineHeight: '18px',
              fontStyle: 'normal',
              fontFamily: "Noto Sans",
              textTransform: 'capitalize',
            }}
          />
          <CustomDropdown
            size="small"
            id="display_name_dropdown"
            dataTestId="display_name_dropdown"
            disableClearable={false}
            noOptionsText="No results found"
            value={display_name || null}
            options={displayNameOptions}
            renderInput={(params) => (
              <CustomInput
                {...params}
                size="small"
                isSearchableComponent={true}
                placeholder="Enter Display Name"
                InputLabelProps={{ shrink: false }}
                validation={isFormSubmited && !display_name}
                onBlur={(e) => {
                  if (e.target.value) {
                    SetIsDisplayName(true);
                  } else {
                    SetIsDisplayName(false);
                  }
                  onChange("display_name", e.target.value)
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Input
            id="input_website"
            dataTestId="input_website"
            item={{
              type: 'text',
              required: true,
              fullWidth: true,
              title: 'Website',
              value: website || '',
              placeholder: 'Enter Website URL',
              onChange: (e) => onChange("website", e.target.value),
              validation: isFormSubmited && (website && !validator.isURL(website)),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Input
            id="input_other"
            dataTestId="input_other"
            item={{
              type: 'text',
              title: 'Other',
              required: true,
              fullWidth: true,
              placeholder: 'Enter Other Details',
              value: other_details || '',
              onChange: (e) => onChange("other_details", e.target.value),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CustomTypography
            text={<LabelWithAsteriskMark label="GST Registration Type" />}
            sx={{
              mb: 1,
              color: '#000000',
              fontSize: '14px',
              fontWeight: '700',
              lineHeight: '18px',
              fontStyle: 'normal',
              fontFamily: "Noto Sans",
              textTransform: 'capitalize',
            }}
          />
          <CustomDefaultDropdown
            labelKey='name'
            fullWidth={true}
            valueLabelKey={'name'}
            disabledCloseIcon={true}
            results={GSTRegistrationTypeChoice}
            id={'gst_registration_type_dropdown'}
            placeholder='Select GST Registration Type'
            dataTestId={'gst_registration_type_dropdown'}
            validation={isFormSubmited && !customer.gst_registration_type}
            value={GSTRegistrationTypeChoice.find((item) => item.value === gst_registration_type)}
            setValue={(selectedData) => setCustomer({ ...customer, gst_registration_type: selectedData ? selectedData.value : null, selected_gst_registration_type: selectedData ? selectedData : null, gstin: null })}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Input
            id={'input_gstin'}
            dataTestId={'input_gstin'}
            item={{
              type: 'text',
              required: true,
              fullWidth: true,
              disabled: disable,
              inputProps: { maxLength: 15 },
              value: gstin ? gstin.toUpperCase() : '',
              placeholder: 'For e.g. 29APPCK7465F1Z1',
              title: disable ? 'GSTIN' : <LabelWithAsteriskMark label={'GSTIN'} />,
              onChange: (e) => onChange("gstin", e.target.value.replace(/[`~$&+,:;=?_[\]@#|{}'<>.^*()%!-/]/, "").toUpperCase()),
              validation: isFormSubmited && !disable && (!gstin || !validator.isLength(String(gstin), { min: 15, max: 15 })),
              // validation: isFormSubmited && !disable && (!gstin || (gstin && !validator.isLength(String(gstin), { min: 15, max: 15 }))),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} >

          <CurrencyDropdown
            id={'currency_dropdown_out_side'}
            dataTestId={'currency_dropdown_out_side'}

            disableClearable={false}
            item={{
              label: <span style={{ lineHeight: '14px' }}>{"Currency"}<span style={{ fontSize: '10px', paddingLeft: '5px' }}>( {'Can not be edited later.'} )</span></span>,
              disabled: updateCustomerData ? true : false,
              value: customer.currency ? customer.currency : null,
              sx: updateCustomerData ? { background: '#F1F3F4' } : {},
              onChange: (event, value) => {
                if (value) {
                  customer.currency = value;
                  customer.currency_id = value.id;
                } else {
                  customer.currency = null;
                }
                setCustomer({ ...customer });
              }
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const PaymentAndBilling = (props) => {
  const { customer, setCustomer, isFormSubmited, updateCustomerData } = props
  const {
    payment_term,
    opening_balance,
    preferred_payment_method,
    opening_balance_transaction_type,
  } = customer

  const onChange = (key_name, value) => {
    setCustomer({ ...customer, [key_name]: value })
  }

  return (
    <Box component="form" noValidate >

      <Grid container spacing={3} sx={{ pt: 3, }}>
        <Grid item xs={12} sm={4} sx={{}}>
          <Dropdown
            id={'preferred_payment_method_dropdown'}
            dataTestId={'preferred_payment_method_dropdown'}

            disableClearable={false}
            results={listPaymentMethod}
            label='Preferred Payment Method'
            placeholder='Select Preferred Payment Method'
            setValue={(event, value) => onChange('preferred_payment_method', value ? value.value : null)}
            value={preferred_payment_method ? getPaymentType(preferred_payment_method) : ''}
          />
        </Grid>
        <Grid item xs={12} sm={4} sx={{}}>
          <ListTerms
            title='Terms'
            disableClearable={false}
            defaultTerm={payment_term}
            remove_value_key={'Custom'}
            isFormSubmited={isFormSubmited}
            setTerms={(key_name, value, item) => {
              if (value) {
                setCustomer({ ...customer, [key_name]: value, payment_term: item })
              } else {
                setCustomer({ ...customer, payment_term: null })
              }
            }}
          />
        </Grid>
        {/* <Grid item xs={12} sm={4} sx={{}}>
          <CurrencyDropdown
            id={'currency_dropdown'}
            dataTestId={'currency_dropdown'}

            disableClearable={false}
            item={{
              label: <span style={{lineHeight: '14px'}}>{"Currency"}<span style={{ fontSize: '10px', paddingLeft: '5px' }}>( {'Can not be edited later.'} )</span></span>,
              disabled: updateCustomerData ? true : false,
              value: customer.currency ? customer.currency : null,
              sx: updateCustomerData ? { background: '#F1F3F4' } : {},
              onChange: (event, value) => {
                if (value) {
                  customer.currency = value;
                  customer.currency_id = value.id;
                } else {
                  customer.currency = null;
                }
                setCustomer({ ...customer });
              }
            }}
          />
        </Grid> */}
        <Grid item xs={12} sm={4} sx={{}}>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <CustomTypography
                text={'Opening Balance'}
                sx={{
                  color: '#000000',
                  fontSize: '14px',
                  fontWeight: '700',
                  lineHeight: '18px',
                  fontStyle: 'normal',
                  textTransform: 'none',
                  fontFamily: "Noto Sans",
                }}
              />
            </Grid>
            {/* {
              updateCustomerData ?
                <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
                  <CustomTypography
                    id={'action_click'}
                    dataTestId={'action_click'}

                    text={edit ? 'Save' : 'Edit'}
                    onClick={() => edit ? handleSubmit() : setEdit(!edit)}
                    sx={{
                      mb: 1,
                      fontSize: '12px',
                      display: 'inline',
                      color: '#2464EB',
                      cursor: 'pointer',
                      display: 'inline',
                      fontWeight: '700',
                      lineHeight: '16px',
                      fontStyle: 'normal',
                      textTransform: "none",
                      fontFamily: "Noto Sans",
                      ":hover": { textDecoration: 'underline' },
                    }}
                  />

                </Grid>
                : null
            } */}
          </Grid>
          {/* {
            updateCustomerData ?
              <Input
                id={'input_opening_balance'}
                dataTestId={'input_opening_balance'}

                isSearchableComponent={true}
                item={{
                  type: 'number',
                  required: true,
                  fullWidth: true,
                  title: '',
                  disabled: !edit ? true : false,
                  placeholder: 'Enter Opening Balance',
                  sx: !edit ? { background: '#F1F3F4' } : {},
                  value: opening_balance !== null ? opening_balance : '0.00',
                  onChange: (e) => onChange('opening_balance', limitDecimalPlaces(e.target.value)),
                  onBlur: (e) => onChange('opening_balance', limitDecimalPlaces(Number(e.target.value)))
                }}
                InputProps={{
                  inputProps: { min: 0 }
                }}
                onKeyPress={(event) => {
                  if (event?.key === '-' || event?.key === '+' || event?.key === 'e' || event?.key === 'E') {
                    event.preventDefault();
                  }
                }}
              />
              : */}
          <Input
            id={'input_opening_balance'}
            dataTestId={'input_opening_balance'}

            item={{
              type: 'number',
              required: true,
              fullWidth: true,
              title: '',
              placeholder: 'Enter Opening Balance',
              value: opening_balance !== null ? opening_balance : '',
              onChange: (e) => onChange('opening_balance', e.target.value !== '' ? limitDecimalPlaces(e.target.value) : null)
            }}

            InputProps={{
              inputProps: { min: 0 }
            }}
            onKeyPress={(event) => {
              if (event?.key === '-' || event?.key === '+' || event?.key === 'e' || event?.key === 'E') {
                event.preventDefault();
              }
            }}
          />
          {Boolean(opening_balance) && customer.opening_balance_transaction_type === 'CREDIT' &&
            <p style={{ borderRadius: '5px', marginTop: '10px', padding: '10px', background: '#fff4e5', fontSize: '14px', color: '#8f6e3e', fontFamily: "Noto Sans", }}>Customer Opening Balance is Negative!</p>
          }
          {/* } */}
        </Grid>
        {
          // (!updateCustomerData || edit) &&
          <Grid item xs={12} sm={4} sx={{ mt: 3, textAlign: 'right' }}>
            <RadioGroup row sx={{ marginBottom: 0 }} id={'opening_balance_transaction_type'} dataTestId={'opening_balance_transaction_type'}>
              <FormControlLabel value={'CREDIT'}
                label={<span style={{
                  color: '#000000',
                  fontSize: '14px',
                  lineHeight: '18px',
                  display: 'inline',
                  fontStyle: 'normal',
                  fontFamily: "Noto Sans",
                  fontWeight: customer.opening_balance_transaction_type === 'CREDIT' ? '600' : '400',
                }}>To Pay</span>} control={<Radio sx={{ color: '#8F8F8F' }} checked={customer.opening_balance_transaction_type === 'CREDIT'} onChange={(e) => { setCustomer({ ...customer, opening_balance_transaction_type: e.target.value }) }} />} />
              <FormControlLabel value={'DEBIT'}
                label={<span style={{
                  color: '#000000',
                  fontSize: '14px',
                  display: 'inline',
                  lineHeight: '18px',
                  fontStyle: 'normal',
                  fontFamily: "Noto Sans",
                  fontWeight: customer.opening_balance_transaction_type === 'DEBIT' ? '600' : '400',
                }}>To Receive</span>} control={<Radio sx={{ color: '#8F8F8F' }} checked={customer.opening_balance_transaction_type === 'DEBIT'} onChange={(e) => { setCustomer({ ...customer, opening_balance_transaction_type: e.target.value }) }} />} />
            </RadioGroup>
          </Grid>
        }


      </Grid>
    </Box>
  )
}

const TaxInfo = (props) => {
  const { customer, setCustomer, isFormSubmited } = props

  const {
    pan,
    tpin,
    is_tds_applicable,
  } = customer

  const onChange = (key_name, value) => {
    setCustomer({ ...customer, [key_name]: value })
  }

  return (
    <Box component="form" noValidate  >
      <Grid container spacing={3} sx={{ pt: 3, }}>
        <Grid item xs={12} sm={4} sx={{}}>
          <Input
            id={'input_tpin'}
            dataTestId={'input_tpin'}

            item={{
              type: 'text',
              required: true,
              fullWidth: true,
              inputProps: { maxLength: 20 },
              title: 'Tax Registration Number',
              value: tpin ? tpin.toUpperCase() : '',
              placeholder: 'Enter Tax Registration Number',
              onChange: (e) => onChange('tpin', e.target.value),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} sx={{}}>
          <Input
            id={'input_pan_number'}
            dataTestId={'input_pan_number'}

            item={{
              type: 'text',
              fullWidth: true,
              required: true,
              title: 'PAN Number',
              texttransform: 'none',
              inputProps: { maxLength: 10 },
              placeholder: 'Enter PAN Number',
              value: pan ? pan.toUpperCase() : '',
              validation: (pan && !isValidPan(pan)),
              // helperText: (pan && !isValidPan(pan)) ? "Please Enter Valid Pan" : '',
              onChange: (e) => onChange('pan', e.target.value.replace(/[`~$&+,:;=?_[\]@#|{}'<>.^*()%!-/]/, "").toUpperCase()),
            }}
          />
        </Grid>
        {/* <Grid item xs={12} sm={12} sx={{ mt: -2 }}>
          <FormControlLabel
            id='tds_applicable_checkbox'
            dataTestId='tds_applicable_checkbox'

            control={<Checkbox checked={is_tds_applicable} onClick={() => onChange('is_tds_applicable', !is_tds_applicable)} />}
            label={<span style={{ fontSize: '16px', fontFamily: "Noto Sans", color: '#000000DE' }}>Apply TDS for this customer</span>}
          />
        </Grid> */}
      </Grid>
    </Box>
  )
}

const Address = (props) => {
  const { customer, setCustomer, updateCustomerData } = props
  const { billing_address, shipping_address, same_as_billing_address } = customer
  const { address_line1, city, state, zip_code, country } = billing_address

  // console.log('customer', customer)
  React.useEffect(() => {
    if (same_as_billing_address) {
      setCustomer({ ...customer, shipping_address: { ...billing_address, address_type: 'shipping', id: updateCustomerData ? updateCustomerData.shipping_address ? updateCustomerData.shipping_address.id : null : null, } })
    } else {
      setCustomer({
        ...customer, shipping_address: {
          business_id: getBusinessInfo().id,
          id: updateCustomerData ? updateCustomerData.shipping_address ? updateCustomerData.shipping_address.id : null : null,
          city: updateCustomerData ? updateCustomerData.shipping_address ? updateCustomerData.shipping_address.city : null : null,
          state: updateCustomerData ? updateCustomerData.shipping_address ? updateCustomerData.shipping_address.state : null : null,
          country: updateCustomerData ? updateCustomerData.shipping_address ? updateCustomerData.shipping_address.country : null : null,
          zip_code: updateCustomerData ? updateCustomerData.shipping_address ? updateCustomerData.shipping_address.zip_code : null : null,
          address_line1: updateCustomerData ? updateCustomerData.shipping_address ? updateCustomerData.shipping_address.address_line1 : null : null,
          address_type: updateCustomerData ? updateCustomerData.shipping_address ? updateCustomerData.shipping_address.type : 'shipping' : 'shipping',
        }
      })
    }
    // eslint-disable-next-line
  }, [billing_address, same_as_billing_address])

  return (
    <Box component="form" noValidate>

      <Grid container spacing={3} sx={{ pt: 4, }}>
        <Grid item xs={12} sm={12} sx={{ mt: 1, mb: -1 }}>
          <CustomTypography
            text={'Billing Address'}
            sx={{
              color: '#141414',
              fontSize: '14px',
              fontWeight: '700',
              lineHeight: '19px',
              fontStyle: 'normal',
              fontFamily: "Noto Sans",
              textTransform: 'none',
            }}
          />
        </Grid>
        <Grid item xs={12} sm={8} sx={{}}>
          <Input
            id={'billing_input_address'}
            dataTestId={'billing_input_address'}

            item={{
              type: 'text',
              required: true,
              fullWidth: true,
              title: 'Address',
              placeholder: 'Enter Address ',
              value: address_line1 ? address_line1 : '',
              onChange: (e) => setCustomer({ ...customer, billing_address: { ...billing_address, address_line1: e.target.value } })
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} sx={{}}>
          <Input
            id={'billing_input_city'}
            dataTestId={'billing_input_city'}

            item={{
              title: 'City/Town',
              type: 'text',
              placeholder: 'Enter City/Town',
              fullWidth: true,
              required: true,
              value: city ? city : '',
              onChange: (e) => {
                const re = /^[a-zA-Z\u0080-\u024F]+(?:. |-| |')*([1-9a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/;
                if (e.target.value === "" || re.test(e.target.value)) {
                  setCustomer({ ...customer, billing_address: { ...billing_address, city: e.target.value !== '' ? e.target.value : '' } })
                }
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} sx={{}}>
          <Input
            id={'billing_input_state'}
            dataTestId={'billing_input_state'}

            item={{
              title: 'State',
              type: 'text',
              placeholder: 'Enter State',
              fullWidth: true,
              required: true,
              value: state ? state : '',
              onChange: (e) => setCustomer({ ...customer, billing_address: { ...billing_address, state: e.target.value } })
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} sx={{}}>
          <Input
            id={'billing_input_pincode'}
            dataTestId={'billing_input_pincode'}

            item={{
              type: 'text',
              required: true,
              fullWidth: true,
              title: 'Pin Code',
              placeholder: 'Enter Pin Code',
              // inputProps: { maxLength: 6 },
              value: zip_code ? zip_code : '',
              onChange: (e) => {
                if (e.target.value === "" || validateCityOrPostalCode(e.target.value)) {
                  setCustomer({ ...customer, billing_address: { ...billing_address, zip_code: e.target.value !== '' ? e.target.value : '' } })
                }
              }
            }}
          //               onChange: (e) => 
          //               {
          // setCustomer({ ...customer, billing_address: { ...billing_address, zip_code: isNumber(e.target.value) ? Math.max(0, parseInt(e.target.value)).toString().slice(0, 6) : null } })
          //               }
          //             }}

          // onKeyPress={(event) => {
          //   if (event?.key === '-' || event?.key === '+' || event?.key === 'e' || event?.key === 'E') {
          //     event.preventDefault();
          //   }
          // }}
          />
        </Grid>
        <Grid item xs={12} sm={4} sx={{}}>
          <Input
            id={'billing_input_country'}
            dataTestId={'billing_input_country'}

            item={{
              type: 'text',
              title: 'Country',
              placeholder: 'Enter Country',
              fullWidth: true,
              required: true,
              value: country ? country : '',
              onChange: (e) => setCustomer({ ...customer, billing_address: { ...billing_address, country: e.target.value } })
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} sx={{ pt: 4, }}>
        <Grid item xs={12} sm={12} sx={{ mt: 1, mb: -1 }}>
          <CustomTypography
            text={'Shipping Address'}
            sx={{
              color: '#141414',
              fontSize: '14px',
              fontWeight: '700',
              lineHeight: '19px',
              fontStyle: 'normal',
              fontFamily: "Noto Sans",
              textTransform: 'none',
            }}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Grid container item xs={12}>
            <Grid item xs={3} sm={6}>
              <CustomTypography
                text={'Address'}
                sx={{
                  fontSize: '14px',
                  color: '#000000',
                  fontWeight: '700',
                  lineHeight: '18px',
                  fontStyle: 'normal',
                  textTransform: "none",
                  fontFamily: "Noto Sans",
                  textTransform: 'capitalize',
                }}
              />
            </Grid>
            <Grid item xs={9} sm={6} sx={{ mt: -3, textAlign: 'right' }}>
              <FormControlLabel
                id='same_as_billing_address_checkbox'
                dataTestId='same_as_billing_address_checkbox'

                sx={{ mb: -5, textAlign: 'right' }}
                control={<Checkbox size='small' checked={same_as_billing_address} onClick={() => setCustomer({ ...customer, same_as_billing_address: !same_as_billing_address })} sx={{ color: '#9b9898' }} />}
                label={<span style={{ color: '#000000', fontFamily: 'Noto Sans', fontStyle: 'normal', fontWeight: '400', lineHeight: '16px', fontSize: '12px' }}>Same as Billing Address</span>}
              />
            </Grid>
          </Grid>
          <Input
            id={'shipping_input_address'}
            dataTestId={'shipping_input_address'}

            item={{
              type: 'text',
              required: true,
              fullWidth: true,
              title: '',
              placeholder: 'Enter Address ',
              disabled: same_as_billing_address,
              value: shipping_address.address_line1 ? shipping_address.address_line1 : '',
              sx: same_as_billing_address ? { background: '#F1F3F4', input: { color: 'black' } } : {},
              onChange: (e) => setCustomer({ ...customer, shipping_address: { ...shipping_address, address_line1: e.target.value } })
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} sx={{}}>
          <Input
            id={'shipping_input_city'}
            dataTestId={'shipping_input_city'}

            item={{
              type: 'text',
              title: 'City/Town',
              placeholder: 'Enter City/Town',
              fullWidth: true,
              required: true,
              disabled: same_as_billing_address,
              value: shipping_address.city ? shipping_address.city : '',
              sx: same_as_billing_address ? { background: '#F1F3F4', input: { color: 'black' } } : {},
              onChange: (e) => {
                const re = /^[a-zA-Z\u0080-\u024F]+(?:. |-| |')*([1-9a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/;
                if (e.target.value === "" || re.test(e.target.value)) {
                  setCustomer({ ...customer, shipping_address: { ...shipping_address, city: e.target.value !== '' ? e.target.value : '' } })
                }
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} sx={{}}>
          <Input
            id={'shipping_input_state'}
            dataTestId={'shipping_input_state'}

            item={{
              type: 'text',
              title: 'State',
              placeholder: 'Enter State',
              fullWidth: true,
              required: true,
              disabled: same_as_billing_address,
              value: shipping_address.state ? shipping_address.state : '',
              sx: same_as_billing_address ? { background: '#F1F3F4', input: { color: 'black' } } : {},
              onChange: (e) => setCustomer({ ...customer, shipping_address: { ...shipping_address, state: e.target.value } })
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} sx={{}}>
          <Input
            id={'shipping_input_pincode'}
            dataTestId={'shipping_input_pincode'}

            item={{
              type: 'text',
              title: 'Pin Code',
              fullWidth: true,
              required: true,
              placeholder: 'Enter Pin Code',
              disabled: same_as_billing_address,
              value: shipping_address.zip_code ? shipping_address.zip_code : '',
              // onChange: (e) => setCustomer({ ...customer, shipping_address: { ...shipping_address, zip_code: isNumber(e.target.value) ? Math.max(0, parseInt(e.target.value)).toString().slice(0, 6) : null } })

              onChange: (e) => {
                if (e.target.value === "" || validateCityOrPostalCode(e.target.value)) {
                  setCustomer({ ...customer, shipping_address: { ...shipping_address, zip_code: e.target.value !== '' ? e.target.value : '' } })
                }
              }
            }}

          // onKeyPress={(event) => {
          //   if (event?.key === '-' || event?.key === '+' || event?.key === 'e' || event?.key === 'E') {
          //     event.preventDefault();
          //   }
          // }}
          />
        </Grid>
        <Grid item xs={12} sm={4} sx={{}}>
          <Input
            id={'shipping_input_country'}
            dataTestId={'shipping_input_country'}

            item={{
              type: 'text',
              title: 'Country',
              placeholder: 'Enter Country',
              fullWidth: true,
              required: true,
              disabled: same_as_billing_address,
              value: shipping_address.country ? shipping_address.country : '',
              sx: same_as_billing_address ? { background: '#F1F3F4', input: { color: 'black' } } : {},
              onChange: (e) => setCustomer({ ...customer, shipping_address: { ...shipping_address, country: e.target.value } })
            }}
          />
        </Grid>
      </Grid>
    </Box>
  )
}


const Note = (props) => {
  const { customer, setCustomer } = props

  return (
    <Box component="form" noValidate  >
      <Grid container spacing={3} sx={{ pt: 3, }}>
        <Grid item xs={12}>
          <Input
            id={'input_note'}
            dataTestId={'input_note'}

            item={{
              rows: 3.5,
              type: 'text',
              title: 'Notes',
              required: true,
              multiline: true,
              fullWidth: true,
              placeholder: 'Write Notes Here...',
              value: customer.note ? customer.note : '',
              onChange: (e) => setCustomer({ ...customer, note: e.target.value })
            }}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

const Attachment = (props) => {
  const { customer, setCustomer } = props
  return (
    <Box component="form" noValidate  >
      <Grid container spacing={3} sx={{ pt: 3, textAlign: 'center' }}>
        <Grid item xs={12} sm={8} sx={{}}>
          <InputFile
            onFileUpload={(file) => {
              setCustomer({ ...customer, attachment: file })
            }}
            attachment={customer.attachments}
            onFileRemove={() => {
              setCustomer({ ...customer, attachment: null, attachments: null })
            }}
          />

        </Grid>
      </Grid>
    </Box>
  )
}


const Buttons = (props) => {
  const { onSave, onCancel, updateCustomerData } = props
  return (
    <Box component="form" noValidate >
      <Grid container>
        <Grid item xs={12} sx={{ display: '-webkit-flex', justifyContent: 'end', }}>
          <CustomButton id={'cancel_btn'} dataTestId={'cancel_btn'} variant='outlined' sx={{ textTransform: 'none', '&:hover': { backgroundColor: '#e8f3ff' } }} btnLabel='Cancel' onClick={onCancel} />
          <CustomButton id={'save_btn'} dataTestId={'save_btn'} variant='contained' sx={{ ml: 1, textTransform: 'none' }} btnLabel={updateCustomerData ? 'Update' : 'Save'} onClick={() => onSave()} />
        </Grid>
      </Grid>
    </Box>
  )
}
export default CreateNewCustomer

const titleList = [
  {
    name: 'Mr.',
  },
  {
    name: 'Mrs.',
  },
  {
    name: 'Ms.',
  },
  {
    name: 'Miss.',
  },
  {
    name: 'Dr.',
  },
]


const tabs = [
  { name: 'Address', condition: 'address' },
  { name: 'Tax info', condition: 'taxInfo' },
  { name: 'Payment & billing', condition: 'PAB' },
  { name: 'Attachments', condition: 'attachment' },
  { name: 'Notes', condition: 'note' },
]
