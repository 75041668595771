import React from 'react';
import { Box, Grid } from '@mui/material';
import Highcharts from "highcharts/highstock";
import { useNavigate } from 'react-router-dom';
import { apiAction } from '../../../../api/api';
import PieChart from "highcharts-react-official";
import DateRange from '../../../common/DateRange';
import * as Actions from '../../../../state/Actions';
import { getBusinessInfo } from '../../../../config/cookiesInfo';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { dashboard_top_expense_data_inner } from '../../../../api/urls';
import CustomTypography from '../../../custom/typography/CustomTypography';
import { CustomDataPickerPopover } from '../../../custom/Datepicker/CustomDataPickerPopover';
import { eventsNames,AnalyticsEvent } from '../../../../firebase/firebaseAnalytics';

import {
  amountFormatter,
  stateChangeManager,
} from '../../../../utils/Utils';

const YourTopExpenses = React.memo((props) => {
  const navigate = useNavigate();
  const dispatch = Actions.getDispatch(React.useContext);
  const { id, dataTestId, url, date_range, custom_date_range } = props;

  const DropdownRef = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dates, setDates] = React.useState(custom_date_range[4]?.dates);
  const [selectedDateRange, setSelectedDateRange] = React.useState(custom_date_range[4]);

  const [drillDownLevel, setDrillDownLevel] = React.useState(0);
  const [drillDownData, setDrillDownData] = React.useState([]);

  React.useEffect(() => {
    if (url) {
      getApiResults(url);
    }
  }, [url, dates]);

  const getApiResults = React.useCallback(async (url, id, level) => {
    const res = await apiAction({
      url: url,
      method: 'post',
      navigate: navigate,
      dispatch: dispatch,
      data: { business_id: getBusinessInfo().id, parent_account_id: id, ...dates },
    });

    if (res?.success) {
      setDrillDownData(res?.result.filter((item) => item.expense_amount > 0));
      setDrillDownLevel(id ? level : 0);
    } else {
      stateChangeManager(dispatch, Actions, true, "error", res?.status);
    }
  }, [dates, dispatch, navigate]);

  const handleDrilldown = React.useCallback(({ options }) => {
    const drill_url = dashboard_top_expense_data_inner();
    const id = options.account_id;
    const is_parent = options.is_parent;

    if (is_parent) {
      getApiResults(drill_url, id, drillDownLevel + 1);
    }
  }, [drillDownLevel, getApiResults]);

  const handleGoBack = React.useCallback((options) => {
    if (drillDownLevel > 1) {
      const drill_url = dashboard_top_expense_data_inner();
      const id = options.parent_parent_account_id;
      getApiResults(drill_url, id, drillDownLevel - 1);
    } else {
      getApiResults(url);
    }
  }, [drillDownLevel, getApiResults, url]);

  const formattedDrillDownData = React.useMemo(() => {
    return drillDownData.map((item) => ({
      ...item,
      y: item.expense_amount,
      name: item.account_name,
      is_parent: item.is_parent
    }));
  }, [drillDownData]);

  const options = React.useMemo(() => ({
    chart: { type: "pie" },
    title: { text: '' },
    credits: { enabled: false },
    tooltip: {
      formatter: function () {
        return `${this.point.name}: <b style="font-family: 'Noto Sans';">${amountFormatter(this.y)}</b>`;
      }
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: true,
          formatter: function () {
            if (this.point.is_parent) {
              return `<span class="hover-underline-class" style="color: #2464EB; cursor: pointer;"><b style="font-family: 'Noto Sans';">${this.point.name}</b>: ${this.point.percentage.toFixed(2)} %</span>`;
            }
            return `${this.point.name}: ${this.point.percentage.toFixed(2)} %`;
          },
          style: {
            color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
          }
        },
        point: {
          events: {
            click: function () {
              if (this.is_parent) {
                AnalyticsEvent(eventsNames.categories.DASHBOARD,{action:eventsNames.actions.CLICK,graph_name:eventsNames.actions.dashboard.graph_name.TOP_EXPENSE})
                handleDrilldown(this);
              }
            }
          },
          states: {
            select: {
              enabled: function () {
                return this.is_parent;
              },
              cursor: 'pointer'
            }
          }
        }
      }
    },
    series: [{ data: formattedDrillDownData, showInLegend: false }]
  }), [formattedDrillDownData, handleDrilldown]);

  return (
    <div>
      <CustomDataPickerPopover
        anchorEl={anchorEl}
        customDate={dates}
        setAnchorEl={setAnchorEl}
        setCustomDate={setDates}
      />
      <Box>
        <Box sx={{ pb: 2, borderBottom: '2px solid #F5F5F5' }}>
          <Grid container style={{ alignItems: 'center', justifyContent: 'space-evenly' }}>
            <Grid item xs={4} sm={6}>
              <CustomTypography
                text="Your Top Expenses"
                sx={{
                  color: '#000000',
                  display: 'inline',
                  fontSize: '16px',
                  textAlign: 'start',
                  fontWeight: '700',
                  lineHeight: '18px',
                  fontStyle: 'normal',
                  fontFamily: "Noto Sans"
                }}
              />
            </Grid>
            <Grid item xs={8} sm={6} sx={{ textAlign: '-webkit-right' }}>
              <DateRange
                label=""
                sx={{ width: 200 }}
                id={`${id}_dropdown`}
                inputRef={DropdownRef}
                disableClearable={true}
                value={selectedDateRange}
                results={custom_date_range}
                dataTestId={`${dataTestId}_dropdown`}
                setValue={(event, newDateRange) => {
                  if (newDateRange.dates) {
                    setAnchorEl(null);
                    setDates(newDateRange.dates);
                    setSelectedDateRange(newDateRange);
                  } else {
                    setAnchorEl(DropdownRef.current);
                    setSelectedDateRange(newDateRange);
                  }
                }}
              />
            </Grid>
          </Grid>
        </Box>
        {drillDownData.length ? (
          <Box sx={{ p: 1 }}>
            <Grid container sx={{ p: 1, borderRight: '1px solid #EBEBEB', alignItems: 'center' }}>
              <Grid item xs={6} sx={{ textAlign: 'start', visibility: drillDownLevel > 0 ? 'visible' : 'hidden' }}>
                <Box onClick={() => handleGoBack(drillDownData[0])}>
                  <CustomTypography
                    text={<ArrowBackIosIcon fontSize="small" sx={{ mb: -0.6 }} />}
                    sx={{
                      color: '#2464EB',
                      fontSize: '14px',
                      display: 'inline',
                      cursor: 'pointer',
                      fontWeight: '500',
                      lineHeight: '16px',
                      fontStyle: 'normal',
                      textDecoration: 'none',
                      fontFamily: 'Noto Sans',
                      ":hover": { textDecoration: 'underline' }
                    }}
                  />
                  <CustomTypography
                    text="Back"
                    id="back_btn"
                    dataTestId="back_btn"
                    sx={{
                      color: '#2464EB',
                      fontSize: '14px',
                      display: 'inline',
                      cursor: 'pointer',
                      fontWeight: '500',
                      lineHeight: '16px',
                      fontStyle: 'normal',
                      textDecoration: 'none',
                      fontFamily: 'Noto Sans',
                      ":hover": { textDecoration: 'underline' }
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={6} sx={{ textAlign: 'end', visibility: drillDownLevel > 0 ? 'visible' : 'hidden' }}>
                <Box>
                  <CustomTypography
                    text={drillDownData[0] && drillDownData[0].parent_account_name}
                    sx={{
                      color: '#000000',
                      fontSize: '16px',
                      fontWeight: '700',
                      lineHeight: '16px',
                      fontStyle: 'normal',
                      textDecoration: 'none',
                      fontFamily: 'Noto Sans'
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <PieChart highcharts={Highcharts} options={options} containerProps={{ style: { height: "100%" } }} />
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box
            sx={{
              height: "430px",
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: "center"
            }}
          >
            <CustomTypography
              text={
                <span>
                  No expenses recorded for <span style={{ textTransform: 'lowercase' }}>select date range</span>
                </span>
              }
              sx={{
                opacity: 0.6,
                color: '#000000',
                display: 'inline',
                fontSize: '14px',
                textAlign: 'start',
                fontWeight: '700',
                lineHeight: '18px',
                fontStyle: 'normal',
                fontFamily: "Noto Sans"
              }}
            />
          </Box>
        )}
      </Box>
    </div>
  );
});

export default YourTopExpenses;
