import React from 'react';
import validator from 'validator';
import Input from '../../../common/Input';
import { useNavigate } from 'react-router-dom';
import { book_demo } from '../../../../api/urls';
import { apiAction_social } from '../../../../api/api';
import * as Actions from '../../../../state/Actions';
import CustomButton from '../../../custom/button/CustomButton';
import CustomTypography from '../../../custom/typography/CustomTypography';

import {
     isNumber,
     setLoader,
     isFormValid,
     stateChangeManager,
     } from '../../../../utils/Utils';

import {
    Box,
    Grid,
    Container,
} from '@mui/material';
import { routesName } from '../../../../config/routesName';

const BookDemo = () => {
    const navigate=useNavigate()
    const dispatch = Actions.getDispatch(React.useContext);


    const initial_data={
        name:null,
        email:null,
        phone_number:null,
        help_needed:null,
    }
const [data,setData]=React.useState({...initial_data});
const [isFormSubmited,setIsFormSubmited]=React.useState(false);

const onSave= async()=>{
    setIsFormSubmited(true);
    let validation_data=[
        { key: 'name', message: 'Please Enter Your Name' },
        { key: 'email', message: 'Please Enter Your Email' },
        { key: '',validation:!(data.email &&validator.isEmail(data.email)), message: 'Please enter valid email.'  },
        { key: 'phone_number', message: 'Please Enter Your Phone Number' },
        { key: '', validation: !isNaN(data.phone_number) && data.phone_number && !(data.phone_number && validator.isLength(String(data.phone_number), { min: 10, max: 10 })), message: 'Please Enter Valid Phone Number' },
    ]
  const { isValid, message } = isFormValid(data, validation_data);
if (isValid) {
    setLoader(dispatch, Actions, true)
    let res = await apiAction_social({
      method: 'post',
      url: book_demo(),
      data: {...data,help_needed:data.help_needed?data.help_needed:''},
    })
    if (res?.success) {
    setLoader(dispatch, Actions, false);
    navigate('/landing'+routesName.landingPage.path);
    stateChangeManager(dispatch, Actions, true, 'success', res?.status); 
    } else {
      setLoader(dispatch, Actions, false);
    stateChangeManager(dispatch, Actions, true, 'error', res?.status); 
    }
} else {
    setLoader(dispatch, Actions, false)
    stateChangeManager(dispatch, Actions, true, 'error', message); 
}


}

    return (
        <Box sx={{pb:10, bgcolor: '#ffffff' }}>
            <Box sx={{ pb: 10, pt: 2, bgcolor: '#2464eb' }}>
                <Container>
                    <CustomTypography
                        text={'Book a free demo'}
                        sx={{
                            mt: 2,
                            color: '#FFFFFF',
                            fontWeight: 900,
                            lineHeight: 1.43,
                            fontSize: '40px',
                            fontStyle: 'normal',
                            fontFamily: "Noto Sans",
                        }}
                    />
                    <CustomTypography
                        text={
                            <span>
                                Already use Finycs? Sign in so we can tailor your support experience.<br />
                                If that’s not possible, we’d still like to hear from you.
                            </span>
                        }
                        sx={{
                            mt: 2,
                            mb: 2,
                            color: '#FFFFFF',
                            fontWeight: 400,
                            fontSize: '18px',
                            fontStyle: 'normal',
                            letterSpacing: '0px',
                            lineHeight: '33.04px',
                            fontFamily: "Noto Sans",
                        }}
                    />
                </Container>
            </Box>
            <Container>
                <Grid container sx={{ mt: -8, }}>
                    <Grid item xs={12} sm={6}>
                        <Box
                            sx={{
                                p: 3,
                                borderRadius: 2,
                                bgcolor: '#FFFFFF',
                                boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 50px',
                            }}>

                            <Box sx={{ mb: 3 }}>
                                <Input
                                    autoFocus={true}
                                    id={'input_your_name'}
                                    dataTestId={'input_your_name'}
                                    item={{
                                        type: 'text',
                                        fullWidth: true,
                                        title: 'Your Name',
                                        placeholder: 'John Doe',
                                        value: data.name?data.name:'',
                                        validation: isFormSubmited && !data.name,
                                        onChange: (e) => {setData({...data,name:e.target.value}) }
                                    }}
                                />
                            </Box>
                            <Box sx={{ mb: 3 }}>
                                <Input
                                    id={'input_your_email'}
                                    dataTestId={'input_your_email'}
                                    item={{
                                        type: 'email',
                                        fullWidth: true,
                                        title: 'Your Email Address',
                                        placeholder: 'john@gmail.com',
                                        value: data.email?data.email:'',
                                        onChange: (e) => {setData({...data,email:e.target.value}) },
                                        validation: isFormSubmited && !data.email || data.email  && !validator.isEmail(data.email),
                                    }}
                                />
                            </Box>
                            <Box sx={{ mb: 3 }}>
                                <Input
                                    id={'input_phone_number'}
                                    dataTestId={'input_phone_number'}
                                    item={{
                                        type: 'number',
                                        fullWidth: true,
                                        placeholder: '2249742883',
                                        title: 'Your Phone Number',
                                        inputProps: { maxLength: 10 },
                                        value: data.phone_number?data.phone_number:'',
                                        validation: isFormSubmited && !data.phone_number || data.phone_number && !(data.phone_number.match(/^\+?[0-9]{10,}$/)),
                                        onChange: (e) => setData({ ...data,phone_number: isNumber(e.target.value) ? Math.max(0, parseInt(e.target.value)).toString().slice(0, 10) : ''})
                                        // onChange:(e)=>{setData({ ...data,phone_number:e.target.value});console.log('===>',e.target.value)}
                                    }}
                                    onKeyPress={(event) => {
                                        if (event?.key === '-' || event?.key === '+' || event?.key === '.' || event?.key === 'e' || event?.key === 'E') {
                                            event.preventDefault();
                                        }
                                    }}
                                />
                            </Box>
                            <Box sx={{ mb: 3 }}>
                                <Input
                                    id={"input_message"}
                                    dataTestId={"input_message"}
                                    item={{
                                        rows: 4,
                                        type: "text",
                                        multiline: true,
                                        fullWidth: true,
                                        placeholder: "Message",
                                        title: "Or tell us what you need help with?",
                                        value: data.help_needed?data.help_needed:'',
                                        // validation: isFormSubmited && !data.help_needed,
                                        onChange: (e) => {setData({...data,help_needed:e.target.value}) }
                                    }}
                                />
                            </Box>
                            <CustomButton
                                variant="contained"
                                onClick={() => { onSave()}}
                                id={'book_demo_btn'}
                                btnLabel='Book a Demo'
                                dataTestId={'book_demo_btn'}
                                sx={{ fontSize: '16px!important', width: { xs: 'auto', sm: '200px' }, }}
                            />
                        </Box>
                    </Grid>
                    <Grid></Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default BookDemo;