import Cookies from 'js-cookie';

const COOKIE_NAMES = {
    ROLE: 'role',
    TOKEN: 'token',
    USER_INFO: 'user-info',
    LOGIN_STATUS: 'logInStatus',
    BUSINESS_INFO: 'business-info',
    USER_PREFERENCES: 'user-preferences',
    BUSINESS_PREFERENCES: 'business-preferences'
};

export const setRole = (info) => {
    Cookies.set(COOKIE_NAMES.ROLE, info, { expires: 7,  });
}
export const getRole = () => {
    return Cookies.get(COOKIE_NAMES.ROLE);
}

export const setToken = (token) => {
    Cookies.set(COOKIE_NAMES.TOKEN, token, { expires: 7,  });
}
export const getToken = () => {
    return Cookies.get(COOKIE_NAMES.TOKEN);
}

export const setUserDetails = (info) => {
    delete info['business'];
    Cookies.set(COOKIE_NAMES.USER_INFO, JSON.stringify(info), { expires: 7,  });
}
export const getUserDetails = () => {
    const cookie = Cookies.get(COOKIE_NAMES.USER_INFO);
    return cookie ? JSON.parse(cookie) : null;
}

export const setLoginStatus = (info) => {
    Cookies.set(COOKIE_NAMES.LOGIN_STATUS, info, { expires: 7,  });
}
export const isAuthenticated = () => {
    return Cookies.get(COOKIE_NAMES.LOGIN_STATUS) === "true";
}

export const setBusinessInfo = (info) => {
    Cookies.set(COOKIE_NAMES.BUSINESS_INFO, JSON.stringify(info), { expires: 7,  });
}
export const getBusinessInfo = () => {
    const cookie = Cookies.get(COOKIE_NAMES.BUSINESS_INFO);
    return cookie ? JSON.parse(cookie) : null;
}

export const setUserPreferences = (info) => {
    Cookies.set(COOKIE_NAMES.USER_PREFERENCES, JSON.stringify(info), { expires: 7,  });
}
export const getUserPreferences = () => {
    const cookie = Cookies.get(COOKIE_NAMES.USER_PREFERENCES);
    return cookie ? JSON.parse(cookie) : null;
}

export const setBusinessPreferences =  (info) => {
    localStorage.setItem(COOKIE_NAMES.BUSINESS_PREFERENCES, JSON.stringify(info))
}
export const getBusinessPreferences = () => {
    // const cookie = Cookies.get(COOKIE_NAMES.BUSINESS_PREFERENCES);
    const cookie = localStorage.getItem(COOKIE_NAMES.BUSINESS_PREFERENCES)
    return cookie ? JSON.parse(cookie) : null;
}

export const removeBusinessPreferences = () => {
    localStorage.removeItem(COOKIE_NAMES.BUSINESS_PREFERENCES);
}

export const clearCookie = () => {
    removeBusinessPreferences();
    Object.values(COOKIE_NAMES).forEach(name => Cookies.remove(name));
}
