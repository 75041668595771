import { stateChangeManager} from '../../../../utils/Utils'

export const createCompanyValidation = (data, setState, Actions) => {

    var isValid = true
    if (data.companyName === '') {
        stateChangeManager(setState, Actions, true, 'error', 'company Name field can not be empty')
        isValid = false
    } else if (data.industry === null) {
        stateChangeManager(setState, Actions, true, 'error', 'Please select the Industry')
        isValid = false
    } else if (data.country === null) {
        stateChangeManager(setState, Actions, true, 'error', 'Please select the Country')
        isValid = false
    } else if (data.currency === null) {
        stateChangeManager(setState, Actions, true, 'error', 'Please select the Currency')
        isValid = false
    }
    return isValid
}
