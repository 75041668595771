import React from 'react';
import { useNavigate } from 'react-router-dom';
import { apiAction } from '../../../../api/api';
import * as Actions from '../../../../state/Actions';
import { stateChangeManager } from '../../../../utils/Utils';
import { getBusinessInfo } from '../../../../config/cookiesInfo';
import CurrencyFormatter from '../../../common/CurrencyFormatter';
import { HtmlTooltip } from '../../../custom/tooltip/CustomTooltip';
import { setLoader, numbersWithSuffix } from '../../../../utils/Utils';
import CustomTypography from '../../../custom/typography/CustomTypography';

import {
    Box,
    Grid,
    Avatar,
} from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

const TodayStatestics = (props) => {
    const { url } = props
    const [result, setResult] = React.useState();

    let navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);

    React.useEffect(() => {
        if (url) {
            getApiResults(url)
        }
    }, [url])
    const getApiResults = async (url) => {
        setLoader(dispatch, Actions, true);
        let res = await apiAction({
            url: url,
            method: 'post',
            navigate: navigate,
            dispatch: dispatch,
            data: { business_id: getBusinessInfo().id },
        })
        if (res?.success) {
            setResult(res?.data);
            setLoader(dispatch, Actions, false);
        } else {
            setLoader(dispatch, Actions, false);
            stateChangeManager(dispatch, Actions, true, "error", res?.status);
        }
    }

    return (
        <div>
            <Box>
                <Box sx={{ pb: 2 }} >
                    <Grid container style={{
                        alignItems: 'center',
                        justifyItems: 'center',
                        alignContent: 'space-evenly',
                        justifyContent: 'space-evenly',
                    }}>
                        <Grid item xs={6} >
                            <div style={{ display: 'flex' }}>
                                <CustomTypography
                                    text={'Overview'}
                                    sx={{
                                        color: '#000000',
                                        display: 'inline',
                                        fontSize: '16px',
                                        textAlign: 'start',
                                        fontWeight: '700',
                                        lineHeight: '18px',
                                        fontStyle: 'normal',
                                        fontFamily: "Noto Sans",
                                    }}
                                />
                                <div style={{}}>
                                    {/* <DashboardFilter/> */}
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: '-webkit-right' }}></Grid>
                    </Grid>
                </Box>
                <Box>
                    <Grid container>
                        <Grid item xs={12} sm={6} md={6} lg={3} sx={{ p: 1 }}>
                            <StatesticsData
                                title={'Cash Balance'}
                                background={'#E9F9FD'}
                                value={result ? result.cash_account_balance : 0}
                                icon={<AccountBalanceWalletIcon sx={{ color: '#6FE0FC' }} />}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={3} sx={{ p: 1 }}>
                            <StatesticsData
                                title={'Bank Balance'}
                                background={'#EEEBFF'}
                                value={result ? result.bank_account_balance : 0}
                                icon={<AccountBalanceIcon sx={{ color: '#9F94DD' }} />}
                            />

                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={3} sx={{ p: 1 }}>
                            <StatesticsData
                                title={'Today Sales'}
                                background={'#ECF8F0'}
                                value={result ? result.todays_sales : 0}
                                icon={<TrendingUpIcon sx={{ color: '#41CB68' }} />}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={3} sx={{ p: 1 }}>
                            <StatesticsData
                                title={'Today Expense'}
                                background={'#FFE7E4'}
                                value={result ? result.todays_expense : 0}
                                icon={<TrendingDownIcon sx={{ color: '#FF4A33' }} />}
                            />
                        </Grid>
                    </Grid>
                </Box>

            </Box>
        </div>
    )
}

export default TodayStatestics;

const StatesticsData = (props) => {
    const { background, title, value, icon } = props;

    const boxRef = React.useRef(null);
    const [fontSize, setFontSize] = React.useState('');
    const showValue = (number) => {
        let length = Number(number)?.toFixed()?.length
        if (true) {
            // if (length <= 6) {
            return <CurrencyFormatter amount={number} isFractionDigits={false} />
        } else {
            return <span style={{ cursor: 'pointer' }}><HtmlTooltip title={<CurrencyFormatter amount={number} isFractionDigits={false} />} placement="top">{numbersWithSuffix(number)}</HtmlTooltip></span>
        }

    }

    // React.useEffect(() => {
    //     const calculateFontSize = () => {
    //         if (boxRef.current) {
    //             const boxWidth = boxRef.current.clientWidth;
    //             const newFontSize = `${Math.max(10, Math.min(24, boxWidth / 20))}px`;
    //             setFontSize(newFontSize);
    //         }
    //     };

    //     calculateFontSize();
    //     window.addEventListener('resize', calculateFontSize);

    //     return () => {
    //         window.removeEventListener('resize', calculateFontSize);
    //     };
    // }, []);

    return (
        <Box ref={boxRef} sx={{
            p: 2,
            display: 'flex',
            overflow: 'hidden',
            alignItems: 'center',
            background: background,
            borderRadius: '6.58599px',
            flexBasis: 'calc(25% - 16px)',
        }}>
            <Avatar sx={{ background: '#FFFFFF' }}>
                {icon}
            </Avatar>
            <Box sx={{ alignSelf: 'center', ml: 2.5 }}>
                <CustomTypography
                    text={showValue(Number(value).toFixed())}
                    sx={{
                        mb: 1,
                        fontSize: '20px',
                        color: '#000000',
                        fontWeight: '700',
                        fontStyle: 'normal',
                        whiteSpace: 'nowrap',
                        lineHeight: '32.69px',
                        letterSpacing: '0px',
                        fontFamily: "Noto Sans",
                    }}
                />
                <CustomTypography
                    text={title}
                    sx={{
                        color: '#666666',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '16px',
                        fontStyle: 'normal',
                        fontFamily: "Noto Sans",
                    }} />
            </Box>
        </Box>
    )
}