// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.input {
    width: 30px;
    border: none;
    margin: 0 10px;
    font-size: 28px;
    text-align: center;
    font-weight: 700;
    border-bottom: 3px solid rgba(0, 0, 0, 0.5);
}
 
.input:focus {
    outline: none;
    border-bottom: 3px solid #2c5FFE;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/otp.css"],"names":[],"mappings":";AACA;IACI,WAAW;IACX,YAAY;IACZ,cAAc;IACd,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,2CAA2C;AAC/C;;AAEA;IACI,aAAa;IACb,gCAAgC;AACpC","sourcesContent":["\r\n.input {\r\n    width: 30px;\r\n    border: none;\r\n    margin: 0 10px;\r\n    font-size: 28px;\r\n    text-align: center;\r\n    font-weight: 700;\r\n    border-bottom: 3px solid rgba(0, 0, 0, 0.5);\r\n}\r\n \r\n.input:focus {\r\n    outline: none;\r\n    border-bottom: 3px solid #2c5FFE;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
