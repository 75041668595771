import React from 'react';
import moment from 'moment';
import Status from '../../../common/Status';
import { apiAction } from '../../../../api/api';
import * as Actions from '../../../../state/Actions';
import CustomTitle from '../../../common/CustomTitle';
import CustomTabs from '../../../custom/tabs/CustomTabs';
import { useLocation, useNavigate } from 'react-router-dom';
import { getBusinessInfo } from '../../../../config/cookiesInfo';
import CurrencyFormatter from '../../../common/CurrencyFormatter';
import CustomTypography from '../../../custom/typography/CustomTypography';
import CustomPagination from '../../../custom/pagination/CustomPagination';
import NoTransactionComponent from '../../../common/NoTransactionComponent';
import CurrencyFormatterWithExchangeRate from '../../invoicing/common/CurrencyFormatterWithExchangeRate';

import {
  InvoiceViewLink,
  CreditNoteViewLink
} from '../../invoicing/common/CommonLinks';

import {
  CustomContainer,
  CustomTitleContainer,
} from '../../../custom/container/CustomContainer';

import {
  get_list_of_invoice,
  retrieve_sales_person,
  get_list_of_credit_note,
} from '../../../../api/urls';

import {
  setLoader,
  getDateFormat,
  breadCrumbsStateChangeManager,
} from '../../../../utils/Utils';

import {
  CustomTable,
  CustomTableRow,
  CustomTableBody,
  CustomTableHead,
  CustomTableHeadCell,
  CustomTableBodyCell,
  CustomTableContainer,
} from '../../../custom/table/CustomTable';

import {
  Box,
  Grid,
  Avatar,
} from '@mui/material';
import AlternateEmailRoundedIcon from '@mui/icons-material/AlternateEmailRounded';


const SalesBySalesPersonDetailReport = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = Actions.getDispatch(React.useContext);
  const [tab, setTab] = React.useState(0);
  const [data, setData] = React.useState();
  const [result, setResult] = React.useState();

  React.useEffect(() => {
    if (location.state) {
      setData(location.state);
      getApiResults(location.state.sales_person_id);
      breadCrumbsStateChangeManager(dispatch, location.state.sales_person);
    }
  }, [location]);


  const getApiResults = async (id) => {
    setLoader(dispatch, Actions, true)
    let res = await apiAction({
      method: 'post',
      url: retrieve_sales_person(id),
      data: {
        business_id: getBusinessInfo().id,
      },
    })

    if (res?.success) {

      setLoader(dispatch, Actions, false);
      setResult({ ...res?.result });
    } else {
      setLoader(dispatch, Actions, false);
    }
  }


  const tabList = [
    { name: 'Invoice', value: 'invoice' },
    { name: 'Credit Note', value: 'credit_note' },
  ]

  return (
    data && result &&
    <div>
      <CustomTitleContainer>
        <Grid container spacing={0} style={{
          alignItems: 'center',
          justifyItems: 'center',
          alignContent: 'space-evenly',
          justifyContent: 'space-evenly',
        }}>
          <Grid item xs={12} sx={{ display: '-webkit-flex', justifyContent: 'start', }}>
            <CustomTitle title={'Sales By Sales Person Details'} />
          </Grid>
        </Grid>
      </CustomTitleContainer>
      <CustomContainer maxWidth={"400"} sx={{ height: 'calc(100vh - 120px)' }}>
        <BasicInfo
          sales_person_info={result}

          count_Details={[
            { title: 'Invoice Count', value: data.invoice_count },
            { title: 'Credit Note Count', value: data.credit_note_count },
          ]}

          without_tax={[
            { title: 'Invoice Sales', value: data.invoice_sales },
            { title: 'Credit Note Sales', value: data.credit_note_sales },
            { title: 'Total Sales', value: data.total_sales },
          ]}

          with_tax={[
            { title: 'Invoice Sales with Tax', value: data.invoice_sales_with_tax },
            { title: 'Credit Note Sales with Tax', value: data.credit_note_sales_with_tax },
            { title: 'Total Sales with Tax', value: data.total_sales_with_tax },
          ]}
        />

        <CustomTableContainer>
          <CustomTabs
            id={'tabs_'}
            dataTestId={'tabs_'}

            value={tab}
            tabs={tabList}
            onChange={(event, tab) => { setTab(tab) }}
          />
          {tabList[tab].value === 'invoice' &&
            <InvoiceList
              filterData={{
                is_draft: false,
                sales_person_id: data.sales_person_id,
                invoice_end_date: moment(data.to_date).format('YYYY-MM-DD'),
                invoice_start_date: moment(data.from_date).format('YYYY-MM-DD'),
              }}
            />}
          {tabList[tab].value === 'credit_note' &&
            <CreditNoteList
              filterData={{
                is_draft: false,
                sales_person_id: data.sales_person_id,
                credit_note_end_date: moment(data.to_date).format('YYYY-MM-DD'),
                credit_note_start_date: moment(data.from_date).format('YYYY-MM-DD'),
              }}
            />}
        </CustomTableContainer>

      </CustomContainer>
    </div>
  )
}

export default SalesBySalesPersonDetailReport;

const BasicInfo = (props) => {
  const { count_Details = [], without_tax = [], with_tax = [], sales_person_info } = props;


  return (
    <Box sx={{ borderBottom: '2px solid #F5F5F5' }}>
      <Box>
        <Grid container>
          <Grid item xs={4}>
            <Box sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'row',
            }}>
              <Avatar />
              <Box sx={{ alignSelf: 'center', ml: 2.5 }}>

                <CustomTypography
                  text={<span> {sales_person_info.name}</span>}
                  sx={{
                    mb: 0.5,
                    color: '#000000',
                    fontSize: '14px',
                    fontWeight: '700',
                    lineHeight: '18px',
                    fontStyle: 'normal',
                    fontFamily: "Noto Sans",
                  }}
                />
                <CustomTypography
                  text={<span><AlternateEmailRoundedIcon fontSize='small' sx={{ mb: -.8 }} /> {sales_person_info.email}</span>}
                  sx={{
                    mb: 0.5,
                    color: '#7A7A7A',
                    fontSize: '12px',
                    display: 'inline',
                    fontWeight: '500',
                    lineHeight: '18px',
                    fontStyle: 'normal',
                    fontFamily: "Noto Sans",
                  }}
                />
              </Box>
            </Box>

            <Box sx={{
              m: 1,
              p: 2,
              borderRadius: 1,
              background: '#f6f6f6',
            }}>
              <Grid container>
                {
                  count_Details.map((item, index) => {
                    return (
                      <React.Fragment key={index} >
                        <Grid item xs={6} sx={{ mb: 1 }}>
                          <CustomTypography
                            text={item.title}
                            sx={{
                              mb: 1,
                              color: '#000000',
                              fontSize: '14px',
                              fontWeight: '500',
                              lineHeight: '18px',
                              fontStyle: 'normal',
                              fontFamily: "Noto Sans",
                            }}
                          />
                        </Grid>
                        <Grid item xs={6} sx={{ mb: 1 }}>
                          <CustomTypography
                            text={item.value}
                            sx={{
                              mb: 1,
                              color: '#7A7A7A',
                              fontSize: '14px',
                              fontWeight: '400',
                              lineHeight: '18px',
                              fontStyle: 'normal',
                              fontFamily: "Noto Sans",
                            }}
                          />
                        </Grid>
                      </React.Fragment>
                    )
                  })
                }

              </Grid>
            </Box>
          </Grid>

          <Grid item xs={4} sx={{
            p: 2,
            borderLeft: '2px solid #F5F5F5',
            borderRight: '2px solid #F5F5F5',
          }}>
            {
              without_tax.map((item, index) => {
                return (
                  <React.Fragment key={index} >
                    <DataHeading
                      index={index}
                      title={item.title}
                      value={item.value}
                    />
                  </React.Fragment>
                )
              })
            }
          </Grid>

          <Grid item xs={4} sx={{ p: 2, }}>
            {
              with_tax.map((item, index) => {
                return (
                  <React.Fragment key={index} >
                    <DataHeading
                      index={index}
                      title={item.title}
                      value={item.value}
                    />
                  </React.Fragment>
                )
              })
            }
          </Grid>

        </Grid>
      </Box>
    </Box>
  )
}


const DataHeading = (props) => {
  const { index, title, value } = props;
  return (
    <React.Fragment key={index}>
      <CustomTypography
        text={title}
        sx={{
          mb: 1,
          mt: index ? 3 : 0,
          color: '#000000',
          fontSize: '14px',
          fontWeight: '700',
          lineHeight: '18px',
          fontStyle: 'normal',
          fontFamily: "Noto Sans",
        }}
      />
      <CustomTypography
        text={<CurrencyFormatter amount={value} currency={null} />}
        sx={{
          mb: 1,
          color: '#7A7A7A',
          fontSize: '12px',
          fontWeight: '500',
          lineHeight: '18px',
          fontStyle: 'normal',
          fontFamily: "Noto Sans",
        }}
      />
    </React.Fragment>
  )
}


const InvoiceList = (props) => {
  const { filterData } = props;
  const navigate = useNavigate();
  const dispatch = Actions.getDispatch(React.useContext);

  const [page, setPage] = React.useState(1);
  const [results, setResults] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    next: undefined,
    count: undefined,
    previous: undefined,
    number_of_pages: undefined,
  });
  const getApiResults = async (id) => {
    setLoader(dispatch, Actions, true)
    let res = await apiAction({
      method: 'post',
      dispatch: dispatch,
      navigate: navigate,
      url: get_list_of_invoice(page),
      data: {
        ...filterData,
        business_id: getBusinessInfo().id,
      },
    })

    if (res?.success) {
      setPagination({
        ...pagination,
        next: res?.result.next,
        count: res?.result.count,
        previous: res?.result.previous,
        number_of_pages: res?.result.number_of_pages,
      })
      setResults(res?.result.result);
      setLoader(dispatch, Actions, false);
    } else {
      setLoader(dispatch, Actions, false);
    }
  }

  React.useEffect(() => {
    if (filterData) {
      getApiResults()
    }
  }, [page]);

  return (
    results.length ?
      <React.Fragment>
        <CustomTable>
          <CustomTableHead>
            <CustomTableRow>
              <CustomTableHeadCell style={{ width: 100 }} align='center'><span >Status</span></CustomTableHeadCell>
              <CustomTableHeadCell ><span >Date</span></CustomTableHeadCell>
              <CustomTableHeadCell ><span >Number</span></CustomTableHeadCell>
              <CustomTableHeadCell ><span >Customer</span></CustomTableHeadCell>
              <CustomTableHeadCell align='right' ><span >Total</span></CustomTableHeadCell>
              <CustomTableHeadCell align='right' ><span >Amount Due</span></CustomTableHeadCell>
            </CustomTableRow>
          </CustomTableHead>
          <CustomTableBody>
            {
              results.map((item, index) => {
                return (
                  <CustomTableRow key={index}>
                    <CustomTableBodyCell sx={{}} align='center'><Status status={item.status} /></CustomTableBodyCell>
                    <CustomTableBodyCell sx={{}}  ><span>{moment(item.invoice_date).format(getDateFormat())}</span></CustomTableBodyCell>
                    <CustomTableBodyCell sx={{}}  ><InvoiceViewLink id={item.id} title={item.invoice_number} /></CustomTableBodyCell>
                    <CustomTableBodyCell sx={{}}  ><span>{item.customer_display_name}</span></CustomTableBodyCell>
                    <CustomTableBodyCell sx={{}} align='right'>
                      <span>{<CurrencyFormatter amount={item.total_amount} currency={item.currency_code} />}</span><br />
                      <CurrencyFormatterWithExchangeRate currency_code={item.currency_code} amount={item.total_amount} exchange_rate={item.exchange_rate} />
                    </CustomTableBodyCell>
                    <CustomTableBodyCell sx={{}} align='right'>
                      <span>{<CurrencyFormatter amount={item.due_amount} currency={item.currency_code} />}</span><br />
                      <CurrencyFormatterWithExchangeRate currency_code={item.currency_code} amount={item.due_amount} exchange_rate={item.exchange_rate} />
                    </CustomTableBodyCell>
                  </CustomTableRow>
                )
              })
            }
          </CustomTableBody>
        </CustomTable>
        <CustomPagination
          page={page}
          count={pagination.number_of_pages}
          onChange={(event, newValue) => setPage(newValue)}
        />
      </React.Fragment>
      : <Box sx={{ mt: '35vh' }}>
        <NoTransactionComponent />
      </Box>
  )
}

const CreditNoteList = (props) => {
  const { filterData } = props;
  const navigate = useNavigate();
  const dispatch = Actions.getDispatch(React.useContext);

  const [page, setPage] = React.useState(1);
  const [results, setResults] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    next: undefined,
    count: undefined,
    previous: undefined,
    number_of_pages: undefined,
  });
  const getApiResults = async (id) => {
    setLoader(dispatch, Actions, true)
    let res = await apiAction({
      method: 'post',
      dispatch: dispatch,
      navigate: navigate,
      url: get_list_of_credit_note(page),
      data: {
        ...filterData,
        business_id: getBusinessInfo().id,
      },
    })

    if (res?.success) {
      setPagination({
        ...pagination,
        next: res?.result.next,
        count: res?.result.count,
        previous: res?.result.previous,
        number_of_pages: res?.result.number_of_pages,
      })
      setResults(res?.result.result);
      setLoader(dispatch, Actions, false);
    } else {
      setLoader(dispatch, Actions, false);
    }
  }

  React.useEffect(() => {
    if (filterData) {
      getApiResults()
    }
  }, [page]);

  return (
    results.length ?
      <React.Fragment>
        <CustomTable>
          <CustomTableHead>
            <CustomTableRow>
              <CustomTableHeadCell style={{ width: 100 }} align='center'><span >Status</span></CustomTableHeadCell>
              <CustomTableHeadCell ><span >Date</span></CustomTableHeadCell>
              <CustomTableHeadCell ><span >Number</span></CustomTableHeadCell>
              <CustomTableHeadCell ><span >Invoice#</span></CustomTableHeadCell>
              <CustomTableHeadCell ><span >Customer</span></CustomTableHeadCell>
              <CustomTableHeadCell align='right' ><span >Amount</span></CustomTableHeadCell>
              <CustomTableHeadCell align='right' ><span >Balance</span></CustomTableHeadCell>
            </CustomTableRow>
          </CustomTableHead>
          <CustomTableBody>
            {
              results.map((item, index) => {
                return (
                  <CustomTableRow key={index}>
                    <CustomTableBodyCell sx={{}} align='center'><Status status={item.status} /></CustomTableBodyCell>
                    <CustomTableBodyCell sx={{}}  ><span>{moment(item.credit_note_date).format(getDateFormat())}</span></CustomTableBodyCell>
                    <CustomTableBodyCell sx={{}}  ><CreditNoteViewLink id={item.id} title={item.credit_note_number} /></CustomTableBodyCell>
                    <CustomTableBodyCell sx={{}}  ><span>{item.against_invoice_number}</span></CustomTableBodyCell>
                    <CustomTableBodyCell sx={{}}  ><span>{item.customer_display_name}</span></CustomTableBodyCell>
                    <CustomTableBodyCell sx={{}} align='right'>
                      <span>{<CurrencyFormatter amount={item.total_amount} currency={item.currency_code} />}</span><br />
                      <CurrencyFormatterWithExchangeRate currency_code={item.currency_code} amount={item.total_amount} exchange_rate={item.exchange_rate} />
                    </CustomTableBodyCell>
                    <CustomTableBodyCell sx={{}} align='right'>
                      <span>{<CurrencyFormatter amount={item.credit_remaining} currency={item.currency_code} />}</span><br />
                      <CurrencyFormatterWithExchangeRate currency_code={item.currency_code} amount={item.credit_remaining} exchange_rate={item.exchange_rate} />
                    </CustomTableBodyCell>
                  </CustomTableRow>
                )
              })
            }
          </CustomTableBody>
        </CustomTable>
        <CustomPagination
          page={page}
          count={pagination.number_of_pages}
          onChange={(event, newValue) => setPage(newValue)}
        />
      </React.Fragment>
      : <Box sx={{ mt: '35vh' }}>
        <NoTransactionComponent />
      </Box>
  )
}