import React from 'react';
import CustomButton from '../custom/button/CustomButton';

import {
    Box,
    Popover,
    Divider,
    MenuItem,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import PrintIcon from '@mui/icons-material/Print';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
// import ShareRoundedIcon from '@mui/icons-material/ShareRounded';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';

const ViewPageActionButtons = (props) => {
    const { sent, isSent = false, print, isPrint = true, download, isDownload = true, edit, isEditable = false, actionsList } = props;
    return (
        <Box>
            {
                isSent &&
                <CustomButton id={'item_action_send_btn'} dataTestId={'item_action_send_btn'} timeoutValue={0} variant="outlined" sx={{ ml: 1, textTransform: 'none', }} onClick={() => { sent() }} btnLabel={'Send'} icon={<SendIcon fontSize='small' sx={{ ml: 1, transform: "rotate(-45deg)" }} />} />
            }
            {
                isEditable &&
                <CustomButton id={'item_action_edit_btn'} dataTestId={'item_action_edit_btn'} timeoutValue={0} variant="outlined" sx={{ ml: 1, textTransform: 'none', }} onClick={() => { edit() }} btnLabel={'Edit'} icon={<ModeEditIcon fontSize='small' sx={{ ml: 1, }} />} />
            }
            {
                isPrint &&
                <CustomButton id={'item_action_print_btn'} dataTestId={'item_action_print_btn'} timeoutValue={0} variant="outlined" sx={{ ml: 1, textTransform: 'none', }} onClick={() => { print() }} btnLabel={'Print'} icon={<PrintIcon fontSize='small' sx={{ ml: 1, }} />} />
            }
            {
                isDownload &&
                <CustomButton id={'item_action_download_btn'} dataTestId={'item_action_download_btn'} timeoutValue={0} variant="outlined" sx={{ ml: 1, textTransform: 'none', }} onClick={() => { download() }} btnLabel={'Download'} icon={<DownloadRoundedIcon fontSize='small' sx={{ ml: 1, }} />} />
            }
            {
                actionsList &&
                <ActionsList actions={actionsList} />
            }
        </Box>
    )
}

export default ViewPageActionButtons;

const ActionsList = (props) => {
    const { actions, } = props
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const action_style = {
        p: 1,
        color: '#141414',
        fontWeight: 500,
        display: 'block',
        fontSize: '14px',
        cursor: 'pointer',
        lineHeight: '12px',
        textDecoration: 'none',
        ":hover": { backgroundColor: "#f5f5f5", }
    }

    return (
        <React.Fragment>
            <CustomButton id={'item_action_arrow_btn'} dataTestId={'item_action_arrow_btn'} timeoutValue={0} variant="outlined" sx={{ ml: 1, textTransform: 'none', }} onClick={handleMenu} btnLabel={''} icon={<ArrowDropDownIcon />} />

            <Popover
                sx={{ mt: '35px' }}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                PaperProps={{
                    style: {
                        minWidth: 140,
                        // minHeight: 60,
                    },
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                id={'Popover_view_page_action'}
                dataTestId={'Popover_view_page_action'}
            >
                {
                    actions.map((action, index) => {
                        return action ? <React.Fragment key={index}>
                            {
                                action.showDivider ? <>
                                    <Divider /><MenuItem id={action.id?action.id:'action_item_view_page_' + index} dataTestId={action.dataTestId?action.dataTestId:'action_item_view_page_' + index} disabled={action.disabled} sx={{ ...action_style, mt:actions[0]?-1:0, color: 'red' }} onClick={() => { action.onClick(); handleClose() }}>{action.name}</MenuItem>
                                </>
                                    :
                                    <MenuItem
                                        sx={{ ...action_style }}
                                        id={action.id?action.id:'action_item_view_page_' + index}
                                        dataTestId={action.dataTestId?action.dataTestId:'action_item_view_page_' + index}
                                        disabled={action.disabled}
                                        onClick={() => {
                                            handleClose();
                                            action.onClick();
                                        }}
                                    >
                                        {action.name}
                                    </MenuItem>
                            }</React.Fragment> : null
                    })
                }
            </Popover>
        </React.Fragment>
    )
}