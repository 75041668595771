import React from 'react'
import { Typography } from '@mui/material'

const CustomTypography = ({
    id,
    sx,
    text,
    dataTestId,
    variant = 'h1',
    ...props }) => {
    return (
        <Typography
            {...props}
            variant={variant}
            dataTestId={dataTestId}
            sx={{ fontFamily: 'Noto Sans', letterSpacing: '0.03rem', ...sx }}
            id={id ? id : text && typeof (text) === "string" ? text.toLowerCase().replace(/ /g, "_") : ""}
        >
            {text}
        </Typography>
    )
}

export default CustomTypography