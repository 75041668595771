import React from 'react';
import moment from 'moment/moment';
import * as Actions from '../../../../../state/Actions';
import CommonAlertBox from '../../common/CommonAlertBox';
import { useNavigate, useLocation } from 'react-router-dom';
import CreateNewInvoice from '../invoices/CreateNewInvoice';
import { routesName } from '../../../../../config/routesName';
import { apiAction, apiFormData } from '../../../../../api/api';
import CustomButton from '../../../../custom/button/CustomButton';
import { getBusinessInfo } from '../../../../../config/cookiesInfo';
import { CustomButtonContainer } from '../../../../custom/container/CustomContainer';
import { eventsNames, AnalyticsEvent } from '../../../../../firebase/firebaseAnalytics';

import {
    setLoader,
    isFormValid,
    filterArray,
    isAddressSame,
    getQueryParams,
    stateChangeManager,
    isBusinessRegistered,
    breadCrumbsStateChangeManager,
    isApplicableForTax
} from '../../../../../utils/Utils';

import {
    get_party,
    retrieve_invoice,
    list_payment_term,
    update_recurring_invoice,
    create_recurring_invoice,
    retrieve_recurring_invoice,
} from '../../../../../api/urls';

import {
    Box,
    Grid,
} from '@mui/material';

const CreateRecurringInvoice = (props) => {
    const { onClose } = props;

    let location = useLocation();
    const navigate = useNavigate();
    const [data, setData] = React.useState();
    const [zero, setZero] = React.useState(false);

    const params = getQueryParams(location.search);
    const dispatch = Actions.getDispatch(React.useContext);
    const [isFormSubmitted, setFormSubmitted] = React.useState(false)

    const recurring_invoice_initial_data = {
        ends_on: null,
        is_active: true,
        repeat_every: 1,
        invoice_data: null,
        profile_name: null,
        never_expires: true,
        selected_ends_on: null,
        repeat_frequency: "week",
        preference: "create_and_draft",
        business_id: getBusinessInfo().id,
        selected_custom_every_list: { name: 'Week(s)' },
        selected_repeat_every_list: repeat_every_list[0],
        starts_on: moment().utc().format('YYYY-MM-DD HH:mm:ssZ'),
        selected_starts_on: moment().format('YYYY-MM-DD HH:mm:ssZ'),
        selected_preference: { name: 'Create Invoices as Drafts' },
    }
    const [recurringInvoiceData, setRecurringInvoiceData] = React.useState({ ...recurring_invoice_initial_data })

    const redirect = (id) => {
        if (onClose) {
            onClose(id)
        } else {
            navigate(routesName.invoicingSalesRecurringInvoiceView.path + "?id=" + id)
        }
    }

    //////////////////////////////////////////////////////////////////////////////////////////
    const gst_registration_type = recurringInvoiceData.invoice_data && recurringInvoiceData.invoice_data.customer && recurringInvoiceData.invoice_data.customer.gst_registration_type;

    let is_Location = gst_registration_type === 'overseas'
    //////////////////////////////////////////////////////////////////////////////////////////
    const onSave = async () => {

        console.log('recurringInvoiceData On save====>', recurringInvoiceData)

        setFormSubmitted(true)
        const { never_expires, ends_on, starts_on, selected_custom_every_list, invoice_data } = recurringInvoiceData;
        const { tax_applied, sales_items, customer_emails } = invoice_data
        const { to } = customer_emails

        const is_rate = sales_items.find((item) => item.rate === null);
        const is_quantity = sales_items.find((item) => !item.quantity);
        const is_sales_item_not_selected = sales_items.find((item) => !item.item_id && !item.expense_category_id);
        const is_tax = sales_items.find((item) => tax_applied !== "no_tax" && !item.tax_id);

        let validation_data = [
            { key: "", validation: invoice_data.customer_id === null, message: 'Please Select Customer' },
            // { key: '', validation: to.length === 0, message: 'Please enter valid email.' },
            { key: "profile_name", message: 'Please Enter Profile Name' },
            isBusinessRegistered() && !is_Location && { key: "", validation: invoice_data.place_of_supply_id === null, message: 'Please Select Place of Supply' },
            { key: "", validation: invoice_data.payment_term_id === null, message: 'Please Select Terms' },
            { key: "starts_on", message: 'Please Enter Valid Start Date' },
            { key: '', validation: never_expires ? false : ends_on === null, message: 'Please Enter Valid End Date' },
            { key: "", validation: starts_on && ends_on && moment(starts_on).format('YYYY-MM-DD') > moment(ends_on).format('YYYY-MM-DD'), message: 'Start Date Can Not be Greater than End Date.' },
            // { key: "", validation: invoice_data.billing_address ? invoice_data.billing_address === '' : true, message: 'Please Enter Billing Address' },
            // { key: "", validation: invoice_data.shipping_address ? invoice_data.shipping_address === '' : true, message: 'Please Enter Shipping Address' },
            { key: "repeat_every", message: `Please Enter the Number of ${selected_custom_every_list.name} .` },
            { key: "", validation: is_sales_item_not_selected, message: 'Please Select Item' },
            { key: "", validation: is_quantity, message: 'Please Enter the Quantity' },
            { key: "", validation: is_rate, message: 'Please Enter the Rate' },
            { key: "", validation: is_tax, message: 'Please Select the Tax' },
        ]
        const { isValid, message } = isFormValid(recurringInvoiceData, validation_data)
        if (isValid) {
            recurringInvoiceData.invoice_data.customer_emails.to = filterArray(recurringInvoiceData.invoice_data.customer_emails.to)

            recurringInvoiceData.invoice_data.payment_term_days = recurringInvoiceData.invoice_data.payment_term.number_of_days
            recurringInvoiceData.invoice_data.customer_display_name = recurringInvoiceData.invoice_data.customer.display_name
            if (isApplicableForTax(invoice_data.tax_applied, isBusinessRegistered())) {
                setZero('isApplicableForTax');
            } else if (invoice_data.total_amount === 0) {
                setZero('withZero');
            } else {
                onSaveAction();
            }
        } else {
            stateChangeManager(dispatch, Actions, true, 'error', message);
        }
    }
    const onSaveAction = async () => {
        if (params.recurring_id) {
            AnalyticsEvent(eventsNames.categories.RECURRING_INVOICES, { action: eventsNames.actions.EDIT })
        } else {
            AnalyticsEvent(eventsNames.categories.RECURRING_INVOICES, { action: eventsNames.actions.CREATE })
        }
        setLoader(dispatch, Actions, true)
        let res = await apiFormData({
            method: 'post',
            data: recurringInvoiceData,
            url: params.recurring_id ? update_recurring_invoice(params.recurring_id) : create_recurring_invoice(),
        })

        if (res?.success) {
            setLoader(dispatch, Actions, false);
            redirect(res?.result.recurring_invoice_id);
            stateChangeManager(dispatch, Actions, true, 'success', res?.status);
        } else {
            setLoader(dispatch, Actions, false);
            stateChangeManager(dispatch, Actions, true, 'error', res?.status);
        }

    }
    React.useEffect(() => {
        if (data) {
            let selected_repeat_every_list = repeat_every_list.find((item) => item.repeat_frequency === data.repeat_frequency & item.repeat_every === data.repeat_every);

            setRecurringInvoiceData({
                ...recurring_invoice_initial_data,
                ...data,
                never_expires: params.recurring_clone_id ? true : data.never_expires,
                selected_preference: preference().find((item) => item.preference === data.preference),
                profile_name: params.recurring_clone_id ? null : data.profile_name ? data.profile_name : null,
                selected_custom_every_list: repeat_list.find((item) => item.repeat_frequency === data.repeat_frequency),
                ends_on: !params.recurring_clone_id && data.ends_on ? moment(data.ends_on).format('YYYY-MM-DD HH:mm:ssZ') : null,
                selected_ends_on: !params.recurring_clone_id && data.ends_on ? moment(data.ends_on).format('YYYY-MM-DD HH:mm:ssZ') : null,
                starts_on: !params.recurring_clone_id && data.starts_on ? moment(data.starts_on).format('YYYY-MM-DD HH:mm:ssZ') : moment().format('YYYY-MM-DD HH:mm:ssZ'),
                selected_starts_on: !params.recurring_clone_id && data.starts_on ? moment(data.starts_on).format('YYYY-MM-DD HH:mm:ssZ') : moment().format('YYYY-MM-DD HH:mm:ssZ'),
                selected_repeat_every_list: selected_repeat_every_list ? selected_repeat_every_list : { name: 'Custom', repeat_frequency: data.repeat_frequency, repeat_every: data.repeat_every },
            })
        }
    }, [data]);
    React.useEffect(() => {
        if (filterArray(recurringInvoiceData?.invoice_data?.customer_emails?.to).length === 0) {
            setRecurringInvoiceData({
                ...recurringInvoiceData,
                preference: 'create_and_draft',
                selected_preference: { name: 'Create Invoices as Drafts', preference: 'create_and_draft' },
            });
        }
    }, [filterArray(recurringInvoiceData?.invoice_data?.customer_emails?.to).length === 0])

    React.useEffect(() => {
        if (params.recurring_id) {
            getApiResults(params.recurring_id);
            breadCrumbsStateChangeManager(dispatch, 'Edit');
        }
        if (params.recurring_clone_id) {
            getApiResults(params.recurring_clone_id);
        }
        if (params.mark_id) {
            getMarkInvoiceData(params.mark_id);
        }
        // eslint-disable-next-line
    }, []);

    const getApiResults = async (id) => {
        setLoader(dispatch, Actions, true)
        let res = await apiAction({
            method: 'post',
            url: retrieve_recurring_invoice(id),
            data: { business_id: getBusinessInfo().id },
        })


        if (res?.success) {
            res.result.invoice_data = {
                ...res?.result.invoice_data,
                customer: res?.result.customer,
                customer_display_name: res?.result.customer_display_name,
                is_amount_tax_inclusive: res?.result.invoice_data.tax_applied === "inclusive" ? true : false
            };
            res?.result.invoice_data.sales_items.map((item) => {
                if (item.tax) {
                    item['gst_rate'] = item.tax.rate ? item.tax.rate : null
                }

            })

            setData(res?.result)
            // setLoader(dispatch, Actions, false)
        } else {
            setLoader(dispatch, Actions, false)
        }
    }

    const getMarkInvoiceData = async (id) => {
        setLoader(dispatch, Actions, true)
        let res = await apiAction({
            method: 'post',
            url: retrieve_invoice(id),
            data: { business_id: getBusinessInfo().id },
        })

        const paymentRes = await apiAction({
            method: "post",
            url: list_payment_term(),
            data: {
                business_id: getBusinessInfo().id,
                sort_by: "name",
                sort_order: "A",
            },
        });
        if (res?.success && paymentRes.success) {
            const partyRes = await apiAction({
                method: "post",
                url: get_party(res?.result.customer_id),
                data: { business_id: getBusinessInfo().id },
            });
            if (partyRes?.success) {
                let payment_item = paymentRes.result.find((data) => data.id === res?.result.payment_term);
                res.result = {
                    ...res?.result,
                    attachment: null,
                    payment_status: null,
                    customer: partyRes?.result,
                    discount: res?.result.discount,
                    tax_applied: res?.result.tax_applied,
                    currency_code: res?.result.currency_code,
                    sales_person_id: res?.result.sales_person,
                    tds_tcs_amount: res?.result.tds_tcs_amount,
                    currency_symbol: res?.result.currency_symbol,
                    place_of_supply_id: res?.result.place_of_supply.id,
                    customer_display_name: partyRes?.result.display_name,
                    is_amount_tax_inclusive: res?.result.tax_applied === "inclusive" ? true : false,
                    same_as_billing_address: isAddressSame(res?.result.billing_address, res?.result.shipping_address),
                    place_of_supply: res?.result.place_of_supply.id ? { ...res?.result.place_of_supply, name: res?.result.place_of_supply.state, } : null,

                    payment_term: payment_item,
                    payment_term_id: payment_item.id,
                };

                // eslint-disable-next-line
                res?.result.sales_items.map((item) => {
                    if (item.tax) {
                        item["tax_id"] = item.tax.id;
                        item["item"] = {
                            name: item.item ? item.item : item.expense_category_name,
                            id: item.item_id,
                            gst_rate: item.tax.rate ? item.tax.rate : null,
                        };
                    } else {
                        item["tax_id"] = null;
                        item["item"] = {
                            name: item.item ? item.item : item.expense_category_name,
                            id: item.item_id,
                            gst_rate: null,
                        };
                    }
                    item["expense_category_id"] = item.expense_category;
                    item["expense_category_name"] = item.expense_category_name;
                    // item["disabled"] = item.item_id ? false : true;
                });
            }
            setData({ ...recurring_invoice_initial_data, invoice_data: { ...res?.result } });

            // setLoader(dispatch, Actions, false);
        } else {
            setLoader(dispatch, Actions, false)
        }
    }

    // console.log("recurringInvoiceData ", recurringInvoiceData)
    return (
        <React.Fragment>
            <CommonAlertBox
                open={zero}
                setOpen={setZero}
                title={`Recurring Invoice`}
                onSaveAction={() => {
                    if (zero === 'withZero') {
                        onSaveAction()
                    } else if (recurringInvoiceData?.invoice_data?.total_amount === 0) {
                        setTimeout(() => {
                            setZero('withZero');
                        }, 100)
                    } else {
                        onSaveAction()
                    }

                }}
                message={
                    zero === 'withZero' ?
                        `You are about to create the recurring invoice with zero amount. Are you sure you want to proceed?`
                        :
                        `Please consult the relevant provisions of the laws to determine the application of taxes for GST unregistered entities.`

                }
            />
            <CreateNewInvoice
                isRecurringInvoice={true}
                repeat_list={repeat_list}
                onClickSave={isFormSubmitted}
                repeat_every_list={repeat_every_list}
                recurringInvoiceData={recurringInvoiceData}
                invoice_data={data ? data.invoice_data : null}
                setRecurringInvoiceData={setRecurringInvoiceData}
                title={params.recurring_id ? 'Update Recurring Invoice' : 'New Recurring Invoice'}
                customerEditable={!params.mark_id && !params.recurring_clone_id && Object.keys(params).length !== 0}
                preference={preference(filterArray(recurringInvoiceData?.invoice_data?.customer_emails?.to).length === 0)}
            />
            <CustomButtonContainer>
                <Buttons onSave={onSave} onCancel={() => navigate(-1)} params={params} />
            </CustomButtonContainer>

        </React.Fragment>
    )
}

const Buttons = (props) => {
    const { onSave, onCancel, params } = props

    return (
        <Box component="form" noValidate>
            <Grid container >
                <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'start', }}>
                    <CustomButton id={'cancel_btn'} dataTestId={'cancel_btn'} variant='outlined' sx={{ textTransform: 'none', '&:hover': { backgroundColor: '#e8f3ff' } }} btnLabel='Cancel' onClick={() => { onCancel(); }} />
                </Grid>

                <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'end', }}>
                    <CustomButton id={'save_recurring_btn'} dataTestId={'save_recurring_btn'} variant='contained' sx={{ textTransform: 'none', }} btnLabel={params.recurring_id ? 'update' : 'Save'} onClick={() => { onSave() }} />
                </Grid>
            </Grid>
        </Box>
    )
}
export default CreateRecurringInvoice;

const repeat_every_list = [
    { name: 'Week', repeat_frequency: 'week', repeat_every: 1 },
    { name: '2 Week', repeat_frequency: 'week', repeat_every: 2 },
    { name: 'Month', repeat_frequency: 'month', repeat_every: 1 },
    { name: '2 Month', repeat_frequency: 'month', repeat_every: 2 },
    { name: '3 Month', repeat_frequency: 'month', repeat_every: 3 },
    { name: '6 Month', repeat_frequency: 'month', repeat_every: 6 },
    { name: 'Year', repeat_frequency: 'year', repeat_every: 1 },
    { name: '2 Year', repeat_frequency: 'year', repeat_every: 2 },
    { name: '3 Year', repeat_frequency: 'year', repeat_every: 3 },
    { name: 'Custom', repeat_frequency: 'week', repeat_every: 1 },
]

const repeat_list = [
    { name: 'Day(s)', repeat_frequency: 'day' },
    { name: 'Week(s)', repeat_frequency: 'week' },
    { name: 'Month(s)', repeat_frequency: 'month' },
    { name: 'Year(s)', repeat_frequency: 'year' }
]

const preference = (disable) => [
    { name: 'Create Invoices as Drafts', preference: 'create_and_draft' },
    { disabled: disable, name: 'Create and Send Invoices', preference: 'create_and_send' },
    { name: 'Create and Send Later', preference: 'create_and_save' },
]