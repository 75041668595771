import React from 'react';
import MultiProgress from "react-multi-progress";

const CustomMultiProgress = (props) => {
    const {options}=props;
  return (
    <MultiProgress
    elements={options}
/>
  )
}

export default CustomMultiProgress