import React from 'react';
import moment from 'moment';
import Status from '../../../../common/Status';
import TimeAgo from '../../../../common/TimeAgo';
import DateRange from '../../../../common/DateRange';
import * as Actions from '../../../../../state/Actions';
import CustomTitle from '../../../../common/CustomTitle';
import CommonSearch from '../../../../common/CommonSearch';
import { useNavigate, useLocation } from 'react-router-dom';
import CustomTabs from '../../../../custom/tabs/CustomTabs';
import { routesName } from '../../../../../config/routesName';
import ListItemActions from '../../../../common/ListItemActions';
import CustomDialog from '../../../../custom/dialog/CustomDialog';
import CustomButton from '../../../../custom/button/CustomButton';
import { getBusinessInfo } from '../../../../../config/cookiesInfo';
import CurrencyFormatter from '../../../../common/CurrencyFormatter';
import { NoDataComponent } from '../../../../common/NoDataComponent';
import { BadgeWithTitle } from '../../../../custom/badge/CustomBadge';
import CommonPreviewShare from '../../../../common/CommonPreviewShare';
import CommonAsyncDropdown from '../../../../common/CommonAsyncDropdown';
import { HeadingWithSortable } from '../../../../common/SortableHeading';
import CustomPagination from '../../../../custom/pagination/CustomPagination';
import CustomTypography from '../../../../custom/typography/CustomTypography';
import { apiAction, apiBlobResponse, apiHandleDownload } from '../../../../../api/api';
import { eventsNames,AnalyticsEvent } from '../../../../../firebase/firebaseAnalytics';
import CustomDateRangePicker from '../../../../custom/Datepicker/CustomDateRangePicker';

import {
    InvoiceViewLink,
    ActionTextLinkBtn,
} from '../../common/CommonLinks';

import {
    setLoader,
    getDateFormat,
    setFilterMessage,
    isFiltersApplied,
    stateChangeManager,
    get_last_12_month_date,
} from '../../../../../utils/Utils';

import {
    CustomContainer,
    CustomTitleContainer,
} from '../../../../custom/container/CustomContainer';

import {
    list_party,
    update_invoice,
    delete_invoice,
    export_invoice,
    retrieve_invoice,
    mark_invoice_sent,
    send_invoice_mail,
    get_list_of_invoice,
    get_list_invoice_statestics,
} from '../../../../../api/urls';

import {
    Box,
    Grid,
    Badge,
} from '@mui/material';
import LoopIcon from '@mui/icons-material/Loop';


import {
    CustomTable,
    CustomTableRow,
    CustomTableBody,
    CustomTableHead,
    CustomTableHeadCell,
    CustomTableBodyCell,
    CustomTableContainer,
} from '../../../../custom/table/CustomTable';
import { CommonWebSocket } from '../../../../../api/webSocket';
import CurrencyFormatterWithExchangeRate from '../../common/CurrencyFormatterWithExchangeRate';


const Dropdown = DateRange;
const InvoicesList = () => {
    let location = useLocation();
    let navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);

    const [count, setCount] = React.useState();
    const tabs = [
        {AnalyticsEvent:()=>{AnalyticsEvent(eventsNames.categories.INVOICES,{action:eventsNames.actions.CLICK,filter_type:eventsNames.actions.invoice.filter_type.ALL})}, name: <span>All invoices</span>, condition: {} },
        {AnalyticsEvent:()=>{AnalyticsEvent(eventsNames.categories.INVOICES,{action:eventsNames.actions.CLICK,filter_type:eventsNames.actions.invoice.filter_type.UNPAID})}, name: <BadgeWithTitle title={'Unpaid'} count={count && count.unpaid} />, condition: { payment_status: 'unpaid' } },
        {AnalyticsEvent:()=>{AnalyticsEvent(eventsNames.categories.INVOICES,{action:eventsNames.actions.CLICK,filter_type:eventsNames.actions.invoice.filter_type.DRAFT})}, name: <BadgeWithTitle title={'Draft'} count={count && count.draft} />, condition: { is_draft: true } },
        {AnalyticsEvent:()=>{AnalyticsEvent(eventsNames.categories.INVOICES,{action:eventsNames.actions.CLICK,filter_type:eventsNames.actions.invoice.filter_type.PRO_FORMA_INVOICE})}, name: <BadgeWithTitle title={'Pro Forma Invoice'} count={count && count.pro_forma} />, condition: { is_pro_forma: true } },
    ]

    const [tab, setTab] = React.useState(0)
    const [page, setPage] = React.useState(1);
    const [time, setTime] = React.useState(moment());
    const [results, setResults] = React.useState([]);
    const [overView, setOverView] = React.useState()
    const [status, setStatus] = React.useState(null)
    const [reLoad, setReLoad] = React.useState(false)

    const [pagination, setPagination] = React.useState({
        next: undefined,
        count: '0',
        previous: undefined,
        number_of_pages: undefined,
    })
    const [state, setState] = React.useState({
        id: '',
        title: '',
        open: false,
        condition: '',
        maxWidth: 'lg',
        fullWidth: true,
    })

    const stateChangeHandler = (title, condition, maxWidth, url, deleteMessage) => {
        setState({
            url: url,
            open: true,
            title: title,
            fullWidth: true,
            maxWidth: maxWidth,
            condition: condition,
            deleteMessage: deleteMessage,
            onDeleteAction: () => { getApiResults(body, page); getOverviewResults(); }
        })
    }
    const [filters, setFilters] = React.useState({
        sort_by: 'invoice_date',
        sort_order: 'D'
    })

    //This code block is use when we navigate customer page to view the Total Unpaid and Total Sales
    React.useEffect(() => {
        if (location.state) {
            if (location.state.action === 'total_unpaid') {
                setTab(1);
            }
        }
    }, [location.state]);

    const webSocketResponse = CommonWebSocket();
    React.useEffect(() => {
        if (webSocketResponse) {
            let webSocketData = JSON.parse(webSocketResponse.data);
            console.log('===>webSocketData', webSocketData);
            // console.log('===>user Info', getUserDetails())
            if (webSocketData.document_type === "invoice") {
                let index = results.findIndex((item) => item.id === webSocketData.object_id);

                if (webSocketData.event === "send_document" && index !== null && index >= 0) {
                    if (webSocketData.event_success) {
                        console.log('===>send_document', webSocketData.object_status)
                        results[index]['status'] = webSocketData.object_status
                        if (webSocketData.object_status === "pro_forma") {
                            results[index]['is_pro_forma_sent'] = true
                        }
                        getOverviewResults();
                        setResults([...results])
                        stateChangeManager(dispatch, Actions, true, "success", `Email delivered successfully for ${webSocketData.object_number}`);
                    } else {
                        stateChangeManager(dispatch, Actions, true, "error", `Email delivery failed for ${webSocketData.object_number}`);
                    }
                }

                if (webSocketData.event === "delete_document" && index !== null && index >= 0) {
                    if (webSocketData.event_success) {
                        // if (webSocketData.event_sender_id!==getUserDetails().id) {   
                        stateChangeManager(dispatch, Actions, true, "success", `Invoice ${webSocketData.object_number} deleted successfully`);
                        getOverviewResults();
                        getApiResults(body, page);
                        // }
                    } else {
                        stateChangeManager(dispatch, Actions, true, "error", `Failed to delete Invoice ${webSocketData.object_number}`);
                    }
                }

                if (webSocketData.event === "update_document" && index !== null && index >= 0) {
                    if (webSocketData.event_success) {
                        stateChangeManager(dispatch, Actions, true, "success", `Invoice ${webSocketData.object_number} updated successfully`);
                        getOverviewResults();
                        getApiResults(body, page);
                    } else {
                        stateChangeManager(dispatch, Actions, true, "error", `Failed to update Invoice ${webSocketData.object_number}`);
                    }
                }
            }
        }
    }, [webSocketResponse])

    let body = { role: "customer", business_id: getBusinessInfo().id, ...filters }
    React.useEffect(() => {
        getApiResults(body, page);
        // eslint-disable-next-line
    }, [filters, page, reLoad])

    React.useEffect(() => {
        getOverviewResults()
    }, [])

    const getOverviewResults = async () => {
        let overView_res = await apiAction({
            method: 'post',
            dispatch: dispatch,
            navigate: navigate,
            url: get_list_invoice_statestics(),
            data: { business_id: getBusinessInfo().id },
        })
        setOverView(overView_res.result)
        setTime(moment())
    }


    const getApiResults = async (body, page) => {
        setLoader(dispatch, Actions, true);
        setFilterMessage(dispatch, Actions, null);
        let res = await apiAction({
            data: body,
            method: 'post',
            dispatch: dispatch,
            navigate: navigate,
            url: get_list_of_invoice(page),
        })
        if (res?.success) {
            setPagination({
                ...pagination,
                next: res?.result.next,
                count: res?.result.count,
                previous: res?.result.previous,
                number_of_pages: res?.result.number_of_pages,
            })
            setResults(res?.result.result);
            setLoader(dispatch, Actions, false);
            setCount({
                all: res?.result.count,
                sent: res?.result.sent,
                paid: res?.result.paid,
                draft: res?.result.draft,
                unpaid: res?.result.unpaid,
                posted: res?.result.posted,
                unsent: res?.result.not_sent,
                pro_forma: res?.result.pro_forma,
                partially_paid: res?.result.partially_paid,
            })
            if (isFiltersApplied(filters)) { setFilterMessage(dispatch, Actions, `No invoices found for your current filters. Verify your filters and try again.`) };
        } else {
            setLoader(dispatch, Actions, false);
        }
    }

    const handleChange = (event, tab) => {
        setPage(1);
        setTab(tab);
        if (tab === 0) {
            delete filters.is_sent
            delete filters.is_paid
            delete filters.is_draft
            delete filters.is_pro_forma
        }
        if (tab === 1) {
            delete filters.is_draft
            delete filters.is_pro_forma
            filters['is_sent'] = true
            filters['is_paid'] = false
            if (status) {
                if (status.disabled === false) {
                    setStatus(null)
                    delete filters.status;

                }

            }
        }
        if (tab === 2) {
            delete filters.status
            delete filters.is_sent
            delete filters.is_paid
            delete filters.is_pro_forma
            filters['is_draft'] = true
            filters['is_pro_forma'] = false
            if (status) {
                setStatus(null)
            }
        }
        if (tab === 3) {
            delete filters.status
            delete filters.is_sent
            delete filters.is_paid
            delete filters.is_draft
            filters['is_pro_forma'] = true
            if (status) {
                setStatus(null)
            }
        }
        setFilters({ ...filters })
    }
    const [sendMail, setSendMail] = React.useState(false);
    const [sendMailId, setSendMailId] = React.useState(false);

React.useEffect(()=>{
if (tabs[tab].AnalyticsEvent) {
    tabs[tab].AnalyticsEvent()
}
},[tab])

    return (
        <div>
            <CustomDialog
                state={state}
                setState={setState}
            />
            <CommonPreviewShare
                open={sendMail}
                id={sendMailId}
                type={'invoice'}
                file_key={'invoice_number'}
                export_Url={export_invoice}
                retrieve_Url={retrieve_invoice}
                setOpen={(value) => { setSendMail(value); setReLoad(!reLoad); setSendMailId(null) }}
            />
            <CustomTitleContainer>
                <Grid container spacing={0} style={{
                    alignItems: 'center',
                    justifyItems: 'center',
                    alignContent: 'space-evenly',
                    justifyContent: 'space-evenly',
                }}>
                    <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'start', }}>
                        <CustomTitle title={'Invoices'} />
                    </Grid>
                    <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'end', }}>
                        <CustomButton
                            id={'create_pro_forma_invoice_btn'}
                            dataTestId={'create_pro_forma_invoice_btn'}
                            variant="outlined"
                            btnLabel='Create Pro Forma Invoice'
                            sx={{ mr: 1, }}
                            onClick={() => {
                                navigate(routesName.invoicingSalesInvoiceAdd.path, { state: { isProFormaInvoice: true } })
                            }}
                        />
                        <CustomButton
                            id={'create_invoice_btn'}
                            dataTestId={'create_invoice_btn'}
                            variant="contained"
                            btnLabel='Create an Invoice'
                            sx={{ textTransform: 'none', }}
                            onClick={() => {
                                navigate(routesName.invoicingSalesInvoiceAdd.path)
                                AnalyticsEvent(eventsNames.categories.INVOICES,{action:eventsNames.actions.NEW})
                            }}
                        />

                    </Grid>
                </Grid>
            </CustomTitleContainer>

            <CustomContainer maxWidth={"400"} sx={{maxHeight:{xs:'calc(100vh - 240px)',sm:'100%'}}}>
                <div style={{ paddingLeft: '16px', paddingRight: '16px', paddingBottom: '24px', }}>
                    <Overview overView={overView} getOverviewResults={getOverviewResults} time={time} />
                    <div style={{ display: '-webkit-flex', }}>
                        <Filters tab={tab} stateChangeHandler={stateChangeHandler} filters={filters} setFilters={setFilters} status={status} setStatus={setStatus} setPage={setPage} />
                    </div>
                </div>

                <CustomTabs
                    id={'tabs_'}
                    dataTestId={'tabs_'}
                    tabs={tabs}
                    value={tab}
                    onChange={handleChange}
                />
                <CustomTableContainer>
                    <CustomTable sx={{}}>
                        <InvoicesListHeaders filters={filters} setFilters={setFilters} />
                        <InvoicesListBody setSendMail={setSendMail} setSendMailId={setSendMailId} data={results} stateChangeHandler={stateChangeHandler} reload={() => setReLoad(!reLoad)} />
                    </CustomTable>
                    <Box sx={{ pt: !results.length ? 10 : 0 }} >
                        <NoDataComponent left='0%' top='0%' position={'relative'} data={results} />
                    </Box>
                </CustomTableContainer>
            </CustomContainer>

            <CustomPagination
                page={page}
                count={pagination.number_of_pages}
                onChange={(event, newValue) => setPage(newValue)}
            />
        </div>
    )
}


const InvoicesListHeaders = (props) => {
    const { filters, setFilters } = props

    return (
        <CustomTableHead>
            <CustomTableRow>
                <CustomTableHeadCell style={{ width: 100 }} align='center'><span style={{ cursor: '' }}>Status </span></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 100 }}><HeadingWithSortable heading={'Date'} sortableKey={'invoice_date'} filters={filters} setFilters={setFilters} /></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 120 }}><HeadingWithSortable heading={'Number'} sortableKey={'invoice_number'} filters={filters} setFilters={setFilters} /></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 210 }}><HeadingWithSortable heading={'Customer'} sortableKey={'customer_display_name'} filters={filters} setFilters={setFilters} /></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 150 }} align='right'><HeadingWithSortable heading={'Total'} sortableKey={'total_amount'} filters={filters} setFilters={setFilters} /></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 200 }} align='right'><HeadingWithSortable heading={'Amount Due'} sortableKey={'due_amount'} filters={filters} setFilters={setFilters} /></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 200 }} align='right'><span>Actions</span></CustomTableHeadCell>
            </CustomTableRow>
        </CustomTableHead>
    )
}

const InvoicesListBody = (props) => {
    const { data, stateChangeHandler, reload, setSendMailId, setSendMail } = props
    let navigate = useNavigate()
    const dispatch = Actions.getDispatch(React.useContext);

    const onSelectAction = (action, item) => {
        setLoader(dispatch, Actions, true)
        if (action === "edit") {
            navigate(routesName.invoicingSalesInvoiceAdd.path + "?id=" + item.id)
        }
        if (action === "view") {
            navigate(routesName.invoicingSalesInvoiceView.path + "?id=" + item.id)
            AnalyticsEvent(eventsNames.categories.INVOICES,{action:eventsNames.actions.VIEW})
        }
        if (action === "approve") {
            approveInvoice(item)
            AnalyticsEvent(eventsNames.categories.INVOICES,{action:eventsNames.actions.APPROVE})
        }
        if (action === "send") {
            sendInvoice(item.id)
            AnalyticsEvent(eventsNames.categories.INVOICES,{action:eventsNames.actions.SEND})
        }
        if (action === "print") {
            printInvoice(item)
            AnalyticsEvent(eventsNames.categories.INVOICES,{action:eventsNames.actions.PRINT})
        }
        if (action === "download") {
            downloadInvoice(item)
            AnalyticsEvent(eventsNames.categories.INVOICES,{action:eventsNames.actions.DOWNLOAD})
        }
        if (action === "mark_sent") {
            markSent(item.id)
            AnalyticsEvent(eventsNames.categories.INVOICES,{action:eventsNames.actions.MARK_SENT})
        }
        if (action === "record_payment") {
            AnalyticsEvent(eventsNames.categories.INVOICES,{action:eventsNames.actions.RECORD_PAYMENT})

            if (item.total_amount === 0) {
                stateChangeManager(dispatch, Actions, true, 'error', `Record payment is not possible for invoice with zero amount`);
            } else {
                recordPayment(item)
                setLoader(dispatch, Actions, false)
            }
        }
    }

    const recordPayment = async (invoice) => {
        let data = await apiAction({
            method: 'post',
            url: retrieve_invoice(invoice.id),
            data: { business_id: getBusinessInfo().id },
        })
        if (data) {
            navigate(routesName.invoicingPaymentsReceived.path, { state: data.result })
        }
    }
    const sendInvoice = async (id) => {
        setSendMail(true);
        setSendMailId(id);
    }

    const markSent = async (id) => {
        const sentRes = await apiAction({ url: mark_invoice_sent(id), method: 'POST', data: { business_id: getBusinessInfo().id } })
        if (sentRes.success) {
            reload(true)
            stateChangeManager(dispatch, Actions, true, 'success', sentRes.status);
        }else {
            stateChangeManager(dispatch, Actions, true, 'error', sentRes.status);
        }
    }

    const approveInvoice = async (invoice) => {
        let data = await apiAction({
            method: 'post',
            url: retrieve_invoice(invoice.id),
            data: { business_id: getBusinessInfo().id },
        })
        if (data) {
            const approveInvoiceRes = await apiAction({ url: update_invoice(invoice.id), method: 'POST', data: { is_draft: false, is_pro_forma: false, customer_id: data.result.customer_id, business_id: getBusinessInfo().id } })
            if (approveInvoiceRes.success) {
                reload(true)
                stateChangeManager(dispatch, Actions, true, 'success', approveInvoiceRes.status);
            }
            else {
                reload(false)
                stateChangeManager(dispatch, Actions, true, 'error', approveInvoiceRes.status);
            }
        }
    }

    const printInvoice = async (invoice) => {
        let data = await apiAction({
            method: 'post',
            url: retrieve_invoice(invoice.id),
            data: { business_id: getBusinessInfo().id },
        })
        if (data) {
            apiBlobResponse({
                url: export_invoice(), data: { ...data.result, terms_and_conditions_id: data.result.terms_and_conditions, payment_term_id: data.result.payment_term, export_type: 'pdf' }, onSuccess: () => {
                    setLoader(dispatch, Actions, false)
                }
            })
        }
    }

    const downloadInvoice = async (invoice) => {
        let data = await apiAction({
            method: 'post',
            url: retrieve_invoice(invoice.id),
            data: { business_id: getBusinessInfo().id },
        })
        if (data) {
            apiHandleDownload({
                url: export_invoice(), data: { ...data.result, terms_and_conditions_id: data.result.terms_and_conditions, payment_term_id: data.result.payment_term, export_type: 'pdf' }, filename: data.result.invoice_number, onSuccess: () => {
                    setLoader(dispatch, Actions, false)
                }
            })
        }
    }

    const toActionText = (status) => {
        let text = ''
        if (status === 'draft') {
            text = 'Approve'
        } else if (status === 'pro_forma') {
            text = 'Convert to Invoice'
        } else if (status === 'unsent') {
            text = 'Send'
        } else if (status === 'paid') {
            text = 'View'
        } else if (status === 'sent' || status === 'overdue' || status === 'partially_paid') {
            text = 'Record payment'
        }
        return text
    }


    const onClickActionText = (status, item) => {
        if (status === 'draft') {
            onSelectAction("approve", item)
        } else if (status === 'pro_forma') {
            onSelectAction("approve", item)
        } else if (status === 'unsent') {
            onSelectAction("send", item)
        } else if (status === 'paid') {
            onSelectAction("view", item)
        } else if (status === 'sent' || status === 'overdue' || status === 'partially_paid') {
            onSelectAction("record_payment", item);
        }
    }

    const getQuickActions = (item) => {
        const actions = [];

        if (item.status !== 'paid') {
            actions.push({ name: 'View', onClick: () => { onSelectAction("view", item) } });
        }

        if (['pro_forma', 'draft', 'unsent'].includes(item.status)) {
            actions.push({ name: 'Edit', onClick: () => { onSelectAction("edit", item) } });
        }
        if (['pro_forma'].includes(item.status)) {
            actions.push({
                name: item.is_pro_forma_sent ? 'Resend' : 'Send',
                onClick: () => {
                    onSelectAction("send", item);
                }
            })
        }

        if (!['draft', 'unsent', 'pro_forma'].includes(item.status)) {
            actions.push({
                name: 'Resend',
                onClick: () => {
                    onSelectAction("send", item);
                }
            });
        }
        actions.push(
            { name: 'Print', onClick: () => { onSelectAction("print", item) } }
        )
        if (['unsent'].includes(item.status)) {
            actions.push({ name: 'Mark as sent', onClick: () => { onSelectAction("mark_sent", item) } });
            actions.push({ name: 'Record payment', onClick: () => { onSelectAction("record_payment", item) } });
        }
        actions.push(
            { name: 'Export as PDF', onClick: () => { onSelectAction("download", item) } },
            {
                name: 'Delete',
                showDivider: true,
                onClick: () => {
                    stateChangeHandler('Delete Invoice','delete','sm',delete_invoice(item.id),`The invoice will be deleted and can not be retrieved later.\n Are you sure about deleting it?`);
                    AnalyticsEvent(eventsNames.categories.INVOICES,{action:eventsNames.actions.DELETE});
                }
            }
        );

        return actions;

    }
    return (
        <CustomTableBody>
            {
                data.map((item, index) => {
                    return (
                        <CustomTableRow key={index}>
                            <CustomTableBodyCell sx={{}} align='center'><Status status={item.status} /></CustomTableBodyCell>
                            <CustomTableBodyCell sx={{}}  ><span>{moment(item.invoice_date).format(getDateFormat())}</span></CustomTableBodyCell>
                            <CustomTableBodyCell sx={{}}  ><InvoiceViewLink title={item.invoice_number} id={item.id} /></CustomTableBodyCell>
                            <CustomTableBodyCell sx={{}}  ><span>{item.customer_display_name}</span></CustomTableBodyCell>
                            <CustomTableBodyCell sx={{}} align='right'>
                                <span>{<CurrencyFormatter amount={item.total_amount} currency={item.currency_code} />}</span><br />
                                <CurrencyFormatterWithExchangeRate currency_code={item.currency_code} amount={item.total_amount} exchange_rate={item.exchange_rate} />
                            </CustomTableBodyCell>
                            <CustomTableBodyCell sx={{}} align='right'>
                                <span>{<CurrencyFormatter amount={item.due_amount} currency={item.currency_code} />}</span><br />
                                <CurrencyFormatterWithExchangeRate currency_code={item.currency_code} amount={item.due_amount} exchange_rate={item.exchange_rate} />
                            </CustomTableBodyCell>
                            <CustomTableBodyCell sx={{}} align='right'>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                    <ActionTextLinkBtn
                                        index={index}
                                        toActionText={toActionText(item.status)}
                                        onClickActionText={() => onClickActionText(item.status, item)} />
                                    <ListItemActions
                                        index={index}
                                        actions={getQuickActions(item)}
                                    />
                                </Box>
                            </CustomTableBodyCell>
                        </CustomTableRow>
                    )
                })
            }
        </CustomTableBody>
    )
}

export default InvoicesList;

const Overview = (props) => {
    const { time, overView, getOverviewResults } = props
    return (
        <Grid container sx={{ pl: 2, pt: 2, pb: 2, border: '1px solid #FFF0C0', backgroundColor: "#FFF7DC" }}>

            <Grid item xs={12} sm={4} sx={{ pl: 2, pt: 2, pb: 2, borderRight: '1px solid #F1D881' }}>
                <CustomTypography
                    text={'Overdue'}
                    sx={{
                        mb: 1,
                        color: '#7A7A7A',
                        fontSize: '14px',
                        fontWeight: '600',
                        lineHeight: '19px',
                        fontStyle: 'normal',
                        textTransform: 'none',
                        fontFamily: "Noto Sans",
                    }}
                />
                <CustomTypography
                    text={overView ? <span>{<CurrencyFormatter amount={overView.overdue_amount} />} <span style={{ color: '#999999', fontSize: '12px', lineHeight: '16px', fontWeight: 400, fontFamily: "Noto Sans" }}></span></span> : ''}
                    sx={{
                        mb: 1,
                        color: '#000000',
                        fontSize: '24px',
                        fontWeight: '700',
                        lineHeight: '28px',
                        fontStyle: 'normal',
                        textTransform: 'none',
                        fontFamily: "Noto Sans",
                    }}
                />

                <CustomTypography
                    id={'refresh_btn'}
                    dataTestId={'refresh_btn'}
                    text={<span>Last update {<TimeAgo created_at={time} />}.<LoopIcon sx={{ mb: -1, color: '#2464EB', cursor: 'pointer' }} onClick={() => getOverviewResults()} /></span>}
                    sx={{
                        mb: 1,
                        color: '#999999',
                        fontSize: '12px',
                        fontWeight: '400',
                        lineHeight: '19px',
                        fontStyle: 'normal',
                        textTransform: 'none',
                        fontFamily: "Noto Sans",
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={4} sx={{ pl: 2, pt: 2, pb: 2, borderRight: '1px solid #F1D881', borderLeft: '1px solid #F1D881' }}>
                <CustomTypography
                    text={'Due within next 30 days'}
                    sx={{
                        mb: 1,
                        color: '#7A7A7A',
                        fontSize: '14px',
                        fontWeight: '600',
                        lineHeight: '19px',
                        fontStyle: 'normal',
                        textTransform: 'none',
                        fontFamily: "Noto Sans",
                    }}
                />
                <CustomTypography
                    text={overView ? <span>{<CurrencyFormatter amount={overView.due_within_next_30_days} />}<span style={{ color: '#999999', fontSize: '12px', lineHeight: '16px', fontWeight: 400, fontFamily: "Noto Sans" }}> { } </span></span> : ''}
                    sx={{
                        mb: 1,
                        color: '#000000',
                        fontSize: '24px',
                        fontWeight: '700',
                        lineHeight: '28px',
                        fontStyle: 'normal',
                        textTransform: 'none',
                        fontFamily: "Noto Sans",
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={4} sx={{ pl: 2, pt: 2, pb: 2, borderLeft: '1px solid #F1D881' }}>
                <CustomTypography
                    text={'Average time to get paid'}
                    sx={{
                        mb: 1,
                        color: '#7A7A7A',
                        fontSize: '14px',
                        fontWeight: '600',
                        lineHeight: '19px',
                        fontStyle: 'normal',
                        textTransform: 'none',
                        fontFamily: "Noto Sans",
                    }}
                />
                <CustomTypography
                    text={overView ? <span>{overView.average_time_to_get_paid} <span style={{ color: '#999999', fontSize: '12px', lineHeight: '16px', fontWeight: 400, fontStyle: 'normal', }}> {overView.average_time_to_get_paid > 1 ? 'DAYS' : 'DAY'}</span></span> : null}
                    sx={{
                        mb: 1,
                        color: '#000000',
                        fontSize: '24px',
                        fontWeight: '700',
                        lineHeight: '28px',
                        fontStyle: 'normal',
                        textTransform: 'none',
                        fontFamily: "Noto Sans",
                    }}
                />
            </Grid>
        </Grid>
    )
}

const Filters = (props) => {
    let location = useLocation();
    const { tab, setPage, stateChangeHandler, setFilters, filters, status, setStatus } = props

    let [dates, setDate] = React.useState();
    const [customer, setCustomer] = React.useState(null);

    //This code block is use when we pass the state through navigate
    const setLocationData = (customer, customer_id, from_date, to_date) => {

        let end_date = to_date ? to_date : get_last_12_month_date().to_date;
        let start_date = from_date ? from_date : get_last_12_month_date().from_date;

        setCustomer(customer);
        filters.customer_id = customer_id;
        filters.invoice_end_date = end_date;
        filters.invoice_start_date = start_date;
        setDate([moment(start_date), moment(end_date)]);
    }

    React.useEffect(() => {
        if (location.state) {
            if (location.state.action === 'total_unpaid') {
                filters.is_sent = true;
                filters.is_paid = false;
                setLocationData(location.state.customer, location.state.customer_id);
            }
            if (location.state.action === 'total_sales') {
                filters.is_draft = false;
                setLocationData(location.state.customer, location.state.customer_id);
            }
            if (location.state.action === 'sales_by_customer') {
                filters.is_draft = false;
                setLocationData(location.state.customer, location.state.customer_id, location.state.from_date, location.state.to_date);
            }

        }
        setFilters({ ...filters })
    }, [location.state]);

    const statusList = [
        { name: 'Sent', condition: { status: 'sent' } },
        { name: 'Paid', disabled: filters.is_paid === false, condition: { status: 'paid' } },
        { name: 'Unsent', disabled: filters.is_paid === false, condition: { status: 'unsent' } },
        { name: 'Overdue ', condition: { status: 'overdue' } },
        { name: 'Partially Paid', condition: { status: 'partially_paid' } },
    ]

    const onDateRangeChange = (dates) => {
        setPage(1);
        if (dates) {
            setFilters({ ...filters, invoice_start_date: dates[0].format("YYYY-MM-DD"), invoice_end_date: dates[1].format("YYYY-MM-DD") })
        } else {
            delete filters.invoice_end_date
            delete filters.invoice_start_date
            setFilters({ ...filters })
        }

    }

    return (
        <Grid item xs={12} container sx={{ mt: 2, mb: 2 }} spacing={1}>

            <Grid item xs={6} sm={2.5}>
                <Dropdown
                    id={'status_dropdown'}
                    dataTestId={'status_dropdown'}
                    label="Status"
                    item={{

                        disabled: filters.is_draft || filters.is_pro_forma,
                    }}
                    value={status}
                    placeholder='Select Status'
                    results={statusList}
                    setValue={(event, value) => {
                        setPage(1);
                        setStatus(value);
                        if (value) {
                            delete filters.status
                            setFilters({ ...filters, ...value.condition })
                        } else {
                            delete filters.status
                            if (tab === 0) {
                                delete filters.is_sent
                                delete filters.is_paid
                                delete filters.is_draft
                            }
                            if (tab === 1) {
                                delete filters.is_draft
                                delete filters.is_pro_forma
                                filters['is_sent'] = true
                                filters['is_paid'] = false
                            }
                            setFilters({ ...filters })
                        }
                    }}
                />
            </Grid>

            <Grid item xs={6} sm={2.5}>
                <CommonAsyncDropdown
                    id={'customer_dropdown'}
                    dataTestId={'customer_dropdown'}
                    autoSelect={false}
                    disableClearable={false}
                    optionLabel="display_name"
                    placeholder='Select Customer'
                    noOptionsText={"No result found"}

                    item={{
                        method: 'post',
                        value: customer,
                        label: 'Customers',
                        url: list_party(1),
                        body: { is_inactive: false, business_id: getBusinessInfo().id, role: 'customer' },
                        onChange: (event, value) => {
                            setPage(1);
                            if (value) {
                                setFilters({ ...filters, customer_id: value.id })
                            } else {
                                delete filters.customer_id
                                setFilters({ ...filters })
                            }

                            setCustomer(value)
                        },
                    }}
                    addButton={{
                        title: '+ Add new customer',
                        onClick: () => stateChangeHandler('New Customer', 'new_customer', 'lg')
                    }}
                />

            </Grid>

            <Grid item xs={12} sm={4.5} container>
                <CustomDateRangePicker dates={dates} onDateRangeChange={onDateRangeChange} />
            </Grid>

            <Grid item xs={12} sm={2.5}>
                <CommonSearch
                    id={'input_search_number'}
                    dataTestId={'input_search_number'}
                    title={'Search'}
                    fullWidth={true}
                    titleVisibility={'hidden'}
                    placeholder={'Enter invoice #'}
                    onSearchValue={filters.invoice_number ? filters.invoice_number : ''}
                    onSearch={(search_text) => {
                        setPage(1);
                        setFilters({ ...filters, invoice_number: search_text })
                    }}
                />
            </Grid>
        </Grid>
    )
}
