import moment from 'moment';
import * as React from 'react';
import validator from 'validator';
import Input from '../../../common/Input';
import { UnitsList } from '../common/Units';
import Status from '../../../common/Status';
import DateRange from '../../../common/DateRange';
import DatePicker from '../../../common/DatePicker';
import * as Actions from '../../../../state/Actions';
import CustomTitle from '../../../common/CustomTitle';
import { InputFile } from '../../../common/InputFile';
import CustomTabs from '../../../custom/tabs/CustomTabs';
import AsyncDropdown from '../../../common/AsyncDropdown';
import { routesName } from '../../../../config/routesName';
import { useNavigate, useLocation } from 'react-router-dom';
import HSNSACSearchComponent from './HSNSACSearchComponent';
import { apiAction, apiFormData } from '../../../../api/api';
import { GSTTaxRateChoice } from '../../../../utils/Constant';
import { MetaData } from '../../../custom/tooltip/CustomTooltip';
import CustomDialog from '../../../custom/dialog/CustomDialog';
import CustomButton from '../../../custom/button/CustomButton';
import { getBusinessInfo } from '../../../../config/cookiesInfo';
import { LabelWithAsteriskMark } from '../../../common/CommonLabel';
import CustomTypography from '../../../custom/typography/CustomTypography';
import { eventsNames,AnalyticsEvent } from '../../../../firebase/firebaseAnalytics';
import { BootstrapDialogTitle, BootstrapDialog } from '../../bulkStatementUpload/mappings/Transactions';

import {
  CustomContainer,
  CustomTitleContainer,
  CustomButtonContainer,
} from '../../../custom/container/CustomContainer';

import {
  isNumber,
  isFormValid,
  getQueryParams,
  amountFormatter,
  stateChangeManager,
  limitDecimalPlaces,
  isBusinessRegistered,
  formatterWithoutSymbol,
  breadCrumbsStateChangeManager,
  validateDataIsBusinessRegistered,
} from '../../../../utils/Utils';

import {
  adjust_item_stock,
  create_item, update_item,
  get_chart_of_account_nested_nodes,
} from '../../../../api/urls';

import {
  get_tabs,
  setItemData,
  ItemCodeInput,
  fetchItemData,
  validateHSNCode,
  get_validation_data,
  get_initial_item_data,
} from './Helper';

import {
  Box,
  Grid,
  Radio,
  Switch,
  Divider,
  Checkbox,
  FormLabel,
  RadioGroup,
  IconButton,
  DialogContent,
  InputAdornment,
  FormControlLabel,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import SyncAltIcon from '@mui/icons-material/SyncAlt';


const Dropdown = DateRange;
const CreateItems = (props) => {
  const { onClose, compulsory, onAddItem, itemIndex } = props

  let location = useLocation();
  const navigate = useNavigate();
  const params = getQueryParams(location.search);
  const dispatch = Actions.getDispatch(React.useContext);

  const [tab, setTab] = React.useState(0);
  const [additionalData, setAdditionalData] = React.useState([])
  const [isFormSubmitted, setFormSubmitted] = React.useState(false);
  const [productService, setProductService] = React.useState({ ...get_initial_item_data(compulsory) })

  const onSave = async () => {

    setFormSubmitted(true);

    console.log('After save Items', productService);

    if (!productService.for_sales) { ['selling_price', 'sales_account_id'].forEach(key => delete productService[key]) }

    if (!productService.for_purchase) { ['cost_price', 'purchase_account_id'].forEach(key => delete productService[key]) }

    const hasAdditionalDataEmptyValue = additionalData?.some((obj) => Object.values(obj)[0] === '' || Object.values(obj)[0] === null);

    const validation_data = get_validation_data(productService, hasAdditionalDataEmptyValue);

    const { isValid, message } = isFormValid(productService, validation_data);

    if (isValid) {
      if (params.item_id) {
        AnalyticsEvent(eventsNames.categories.ITEMS, { action: eventsNames.actions.EDIT });
      }else{
        AnalyticsEvent(eventsNames.categories.ITEMS,{action:eventsNames.actions.CREATE})
      }

      const actionType = params.item_id ? 'update' : 'save';

      const itemType = productService.item_type === 'goods' ? 'goods' : 'service';

      productService.stock_item_code = productService?.stock_item_code || '';

      productService['meta_data'] = additionalData?.length ? additionalData : null;

      const res = await apiFormData({
        method: 'post',
        data: productService,
        file_key: 'attachment',
        url: params.item_id ? update_item(params.item_id) : create_item(),
      });

      if (res?.success) {
        if (onClose) {
          onClose();
          if (onAddItem) {
            onAddItem(res?.result, itemIndex);
          }
        } else {
          navigate(`${routesName.invoicingItemsView.path}?item_id=${res?.result.id}`);
        }
        stateChangeManager(dispatch, Actions, true, 'success', res?.status);
      } else {
        stateChangeManager(dispatch, Actions, true, 'error', res?.status);
      }
    } else {
      stateChangeManager(dispatch, Actions, true, 'error', message);
    }
  };

  React.useEffect(() => {
    const fetchData = async (itemId) => {
      try {
        const itemData = await fetchItemData(itemId, dispatch);
        setItemData(itemData, setProductService, setAdditionalData);
        breadCrumbsStateChangeManager(dispatch, 'Edit');
        setTab(0);
      } catch (error) {
        console.error(error);
      }
    };
    if (params.item_id) {
      fetchData(params.item_id);
    }
  }, [dispatch, params.item_id]);


  const tabs = get_tabs(productService);
  const commonPaddingStyle = { paddingLeft: '20px', paddingRight: '20px', paddingBottom: '48px' };

  return (
    <div>
      <CustomTitleContainer>
        <CustomTitle title="Goods or Service Information" />
      </CustomTitleContainer>
      <CustomContainer maxWidth="400">
        <div style={commonPaddingStyle}>
          <BasicInformation
            setTab={setTab}
            productService={productService}
            isFormSubmitted={isFormSubmitted}
            setProductService={setProductService}
          />
        </div>
        <CustomTabs
          id="tabs_"
          tabs={tabs}
          value={tab}
          dataTestId="tabs_"
          onChange={(event, newTab) => setTab(newTab)}
        />
        <div style={commonPaddingStyle}>
          {tabs[tab].condition === 'pricing_details' && (
            <ProductInformation
              compulsory={compulsory}
              productService={productService}
              isFormSubmitted={isFormSubmitted}
              setProductService={setProductService}
              taxRate={productService.gst_rate ?? 0}
            />
          )}
          {tabs[tab].condition === 'stock_details' && (
            <StockInformation
              productService={productService}
              isFormSubmitted={isFormSubmitted}
              setProductService={setProductService}
              isManageStock={productService.isManageStock}
            />
          )}
          {tabs[tab].condition === 'additional_fields' && (
            <AdditionalInformation
              data={additionalData}
              setData={setAdditionalData}
              isFormSubmitted={isFormSubmitted}
            />
          )}
        </div>
      </CustomContainer>
      <CustomButtonContainer>
        <Buttons onSave={onSave} onCancel={() => (onClose ? onClose() : navigate(-1))} />
      </CustomButtonContainer>
    </div>
  );
}


export default CreateItems;

const Information = (props) => {
  let { id, dataTestId, compulsory, firstletter, isFormSubmitted } = props
  const { type, disabled, inputTitle, productService, setProductService, basePrice } = props
  const { for_sales, for_purchase, cost_price_with_tax, selling_price_with_tax, is_sales_inclusive, is_purchase_inclusive } = productService

  const [state, setState] = React.useState({
    id: '',
    title: '',
    open: false,
    condition: '',
    maxWidth: 'lg',
    compulsory: '',
    fullWidth: true,
  })

  const onChange = (key_name, value) => {
    setProductService({ ...productService, [key_name]: value })
  }

  const addButtonHandler = (title, condition, maxWidth, compulsory) => {
    setState({
      open: true,
      title: title,
      fullWidth: true,
      maxWidth: maxWidth,
      condition: condition,
      compulsory: compulsory,
    })
  }
  // Common api call for the Accounts
  const [NewAccountsResult, setNewAccountsResult] = React.useState();
  const onAddAccount = (value) => {
    setNewAccountsResult(value);

    if (type === "sales") {
      setProductService({ ...productService, sales_account: value, sales_account_id: value ? value.id : null })
    } else {
      setProductService({ ...productService, purchase_account: value, purchase_account_id: value ? value.id : null })
    }
  }

  return (
    <Grid container spacing={3} sx={{ mb: 4 }}>
      <CustomDialog
        state={state}
        setState={setState}
        onAddAccount={(account) => onAddAccount(account)}
      />
      {/* /////////////////////////////////////////// Sales Information OR Purchase Information /////////////////////////////////////////////////////// */}
      <Grid item xs={12} >
        <FormControlLabel
          id={id + '_checkbox'}
          dataTestId={dataTestId + '_checkbox'}
          disabled={compulsory}
          label={<span style={{ color: '#141414', textTransform: 'none', fontFamily: 'Noto Sans', fontStyle: 'normal', fontWeight: '700', lineHeight: '19px', fontSize: '14px' }}>{type === "sales" ? "Sales Information" : "Purchase Information"}</span>}
          control={<Checkbox
            style={{
              color: compulsory ? "#2464EB" : '',
            }}
            checked={type === "sales" ? for_sales : for_purchase}
            onChange={(e) => {
              if (type === "sales") {
                productService.sales_account = null;
                productService.sales_account_id = null;
                productService.is_sales_inclusive = false;
                productService.selling_price_with_tax = 0;
                productService.for_sales = e.target.checked;
              } else {
                productService.purchase_account = null;
                productService.purchase_account_id = null;
                productService.cost_price_with_tax = 0;
                productService.is_purchase_inclusive = false;
                productService.for_purchase = e.target.checked;
              }
              setProductService({ ...productService })
            }} />}
        />
      </Grid>

      {/* /////////////////////////////////////////// Selling Price OR Cost Price /////////////////////////////////////////////////////// */}
      <Grid item xs={12} sm={4} sx={{}}>
        <Grid container item xs={12}>
          <Grid item xs={12} sm={6}>
            <CustomTypography
              text={inputTitle}
              sx={{
                fontSize: '14px',
                color: '#000000',
                fontWeight: '700',
                lineHeight: '18px',
                fontStyle: 'normal',
                textTransform: "none",
                fontFamily: "Noto Sans",
                textTransform: 'capitalize',
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ mt: -3, textAlign: 'right' }}>
            <FormControlLabel
              id={id + '_inclusive_checkbox'}
              dataTestId={dataTestId + '_inclusive_checkbox'}
              labelPlacement="end"
              sx={{ mb: -5, textAlign: 'right' }}
              disabled={type === "sales" ? !for_sales : !for_purchase}
              control={<Checkbox
                size='small'
                checked={type === "sales" ? is_sales_inclusive : is_purchase_inclusive}
                onChange={(e) => {
                  if (type === "sales") {
                    productService.is_sales_inclusive = e.target.checked;
                  } else {
                    productService.is_purchase_inclusive = e.target.checked;
                  }
                  setProductService({ ...productService })
                }}
              />}
              label={<span style={{ color: '#000000', fontFamily: 'Noto Sans', fontStyle: 'normal', fontWeight: '400', lineHeight: '16px', fontSize: '12px' }}>{'Tax Inclusive'}</span>}
            />
          </Grid>
        </Grid>
        <Input
          isSearchableComponent={true}
          id={'input_' + id + '_price'}
          dataTestId={'input_' + dataTestId + '_price'}
          item={{
            title: '',
            sx: disabled,
            type: 'number',
            fullWidth: true,
            required: true,
            disabled: type === "sales" ? !for_sales : !for_purchase,
            placeholder: type === "sales" ? 'Enter Selling Price' : 'Enter Cost Price',
            value: type === "sales" ? selling_price_with_tax ? selling_price_with_tax : '' : cost_price_with_tax ? cost_price_with_tax : '',
            validation: isFormSubmitted && ((type === "sales") ? (for_sales && !selling_price_with_tax) : (for_purchase && !cost_price_with_tax)),
            onChange: (e) => {
              if (type === "sales") {
                onChange('selling_price_with_tax', e.target.value !== '' ? e.target.value : 0)
              } else {
                onChange('cost_price_with_tax', e.target.value !== '' ? e.target.value : 0)
              }
            },
            onBlur: (e) => {
              if (type === "sales") {
                onChange('selling_price_with_tax', e.target.value !== '' ? limitDecimalPlaces(e.target.value) : 0)
              } else {
                onChange('cost_price_with_tax', e.target.value !== '' ? limitDecimalPlaces(e.target.value) : 0)
              }
            },
          }}

          onKeyPress={(event) => {
            if (event?.key === '-' || event?.key === '+') {
              event.preventDefault();
            }
          }}
        />
      </Grid>

      <Grid item xs={12} sm={4} sx={{}}>
        <Input
          id={'input_' + id + '_base_price'}
          dataTestId={'input_' + dataTestId + '_base_price'}
          item={{
            type: 'text',
            disabled: true,
            fullWidth: true,
            title: 'Base Price',
            value: basePrice ? amountFormatter(basePrice) : ''
          }}
        />
      </Grid>

      <Grid item xs={12} sm={4} sx={{}}>
        <AsyncDropdown
          sx={{}}
          fullWidth={true}
          autoFocus={false}
          disabledCloseIcon={false}
          isGroupHeaderSticky={false}
          newResults={NewAccountsResult}

          id={id + '_account_dropdown'}
          dataTestId={dataTestId + '_account_dropdown'}
          inputDisabled={type === "sales" ? !for_sales : !for_purchase}

          selectedValue={type === "sales" ? productService.sales_account_id ? productService.sales_account : null : productService.purchase_account_id ? productService.purchase_account : null}
          setSelectedValue={(value) => {
            if (value) {
              if (type === "sales") {
                productService.sales_account = value;
                productService.sales_account_id = value.id;
              } else {
                productService.purchase_account = value;
                productService.purchase_account_id = value.id;
              }
            } else {
              if (type === "sales") {
                productService.sales_account = null;
                productService.sales_account_id = null;
              } else {
                productService.purchase_account = null;
                productService.purchase_account_id = null;
              }
            }
            setProductService({ ...productService })
          }}

          valueLabelKey='id'
          uniqueOptionKey='id'
          searchKey='account_name'
          optionLabelKey='account_name'
          optionGroupLabelKey='account_type'
          URL={get_chart_of_account_nested_nodes(1)}
          title={<LabelWithAsteriskMark label={'Select Account'} />}
          playLoad={{ account_type: type === "sales" ? ['INCOME'] : ['EXPENSE'] }}
          placeholder={type === "sales" ? 'Select Sales Account' : 'Select Purchase Account'}

          addButton={{
            title: '+ Add accounts',
            onClick: () => addButtonHandler('Add Accounts', 'create_sub_account', 'sm', firstletter),
          }}
        />
      </Grid>
    </Grid>

  )
}

const BasicInformation = (props) => {
  const { productService, setProductService, isFormSubmitted, setTab } = props

  const onChange = (key_name, value) => {
    setProductService({ ...productService, [key_name]: value })
  }
  const [open, setOpen] = React.useState(false);
  return (
    <React.Fragment>
      <Grid container spacing={3} >

        {/* /////////////////////////////////////////// Type /////////////////////////////////////////////////////// */}
        <Grid item xs={12} sm={12}>
          <FormLabel sx={{
            mb: 2,
            color: '#000000',
            fontSize: '14px',
            fontWeight: '700',
            lineHeight: '18px',
            fontStyle: 'normal',
            fontFamily: "Noto Sans",
            textTransform: 'capitalize',
          }}>Select Type</FormLabel>
          <RadioGroup row>
            <FormControlLabel value="goods"
              id={'goods'}
              dataTestId={'goods'}
              label={<span style={{
                color: '#000000',
                fontSize: '14px',
                lineHeight: '18px',
                fontStyle: 'normal',
                fontFamily: "Noto Sans",
                textTransform: 'capitalize',
                fontWeight: productService.item_type === 'goods' ? '600' : '400',
              }}>goods</span>} control={<Radio sx={{ color: '#8F8F8F' }} checked={productService.item_type === 'goods'} onChange={(e) => {
                setProductService((previous) => ({
                  ...previous,
                  hsn_sac_code: null,
                  item_type: e.target.value,
                  unit: previous.defaultUnit,
                  unit_id: previous.defaultUnit?.id,
                })); setTab(0)
              }} />} />
            <FormControlLabel value="service"
              id={'service'}
              dataTestId={'service'}
              disabled={productService.isManageStock}
              label={<span style={{
                fontSize: '14px',
                lineHeight: '18px',
                fontStyle: 'normal',
                fontFamily: "Noto Sans",
                textTransform: 'capitalize',
                color: productService.isManageStock ? '#8F8F8F' : '#000000',
                fontWeight: productService.item_type === 'service' ? '600' : '400',
              }}>Service</span>} control={<Radio sx={{ color: '#8F8F8F' }} checked={productService.item_type === 'service'} onChange={(e) => {
                setProductService((previous) => ({
                  ...previous,
                  unit: null,
                  unit_id: null,
                  hsn_sac_code: null,
                  item_type: e.target.value,
                })); setTab(0)
              }} />} />
          </RadioGroup>
        </Grid>

        {/* /////////////////////////////////////////// Name /////////////////////////////////////////////////////// */}
        <Grid item xs={12} sm={4} sx={{}}>
          <Input
            autoFocus={true}
            id={'input_name'}
            dataTestId={'input_name'}
            item={{
              type: 'text',
              required: true,
              fullWidth: true,
              placeholder: 'Enter Name',
              title: <LabelWithAsteriskMark label={'Name'} />,
              validation: isFormSubmitted && !productService.name,
              value: productService.name ? productService.name : '',
              onChange: (e) => onChange("name", e.target.value !== '' ? e.target.value : null)
            }}
          />
        </Grid>

        {/* /////////////////////////////////////////// Description /////////////////////////////////////////////////////// */}
        <Grid item xs={12} sm={8} sx={{}}>
          <Input
            id={'input_description'}
            dataTestId={'input_description'}
            item={{
              type: 'text',
              required: true,
              fullWidth: true,
              title: 'Description',
              placeholder: 'Enter Description',
              onChange: (e) => onChange('description', e.target.value),
              value: productService.description ? productService.description : '',
            }}
          />
        </Grid>

        {/* /////////////////////////////////////////// Unit /////////////////////////////////////////////////////// */}
        <Grid item xs={12} sm={4} sx={{}}>
          <UnitsList
            id={'unit_dropdown'}
            dataTestId={'unit_dropdown'}
            value={productService.unit}
            title={<LabelWithAsteriskMark label={'Unit'} />}
            validation={isFormSubmitted && !productService.unit}
            defaultUnitSelected={(value) => {
              if (value && productService.item_type === 'goods') {
                setProductService((prev) => ({
                  ...prev,
                  unit: value,
                  unit_id: value.id,
                  defaultUnit: value?.code === 'pcs' ? value : prev.defaultUnit,
                }))
              }
            }}

            setValue={(value) => {
              if (value) {
                productService.unit = value;
                productService.unit_id = value.id;
              } else {
                productService.unit = null;
                productService.unit_id = null;
              }
              setProductService({ ...productService })
            }}
          />
        </Grid>

        {/* /////////////////////////////////////////// HSN / SAC Code /////////////////////////////////////////////////////// */}
        <Grid item xs={12} sm={4} sx={{}}>
          <HSNSACSearchComponent
            id={'hsn_sac_code_search'}
            dataTestId={'hsn_sac_code_search'}
            open={open}
            setOpen={setOpen}
            item_type={productService.item_type}
            hsn_sac_code={productService.hsn_sac_code}
            onClick={(data) => { 
              setOpen(false);
              onChange('hsn_sac_code', data.hsn_sac_code); 
              AnalyticsEvent(eventsNames.categories.ITEMS,{action:eventsNames.actions.item.action.SELECT_HSN});
             }}
          />
          <Input
            id={'input_hsn_sac_code'}
            dataTestId={'input_hsn_sac_code'}
            item={{
              type: 'number',
              disabled: open,
              required: true,
              fullWidth: true,
              inputProps: { minLength: 4, maxLength: 8 },
              onChange: (e) => onChange('hsn_sac_code', e.target.value),
              value: productService.hsn_sac_code ? productService.hsn_sac_code : '',
              title: productService.item_type === 'goods' ? 'HSN Code' : 'SAC Code',
              placeholder: productService.item_type === 'goods' ? 'HSN Code' : 'SAC Code',
              validation: isFormSubmitted && validateHSNCode(productService.hsn_sac_code),
              title: <LabelWithAsteriskMark label={productService.item_type === 'goods' ? 'HSN Code' : 'SAC Code'} isAsteriskMark={isBusinessRegistered()} />,
            }}
            onFocus={() => { }}
            onKeyPress={(event) => {
              if (event?.key === '-' || event?.key === '+') {
                event.preventDefault();
              }
            }}

            InputProps={{
              endAdornment: <InputAdornment position="end">
                <Divider sx={{ height: 20, m: 0.5 }} orientation="vertical" />
                <IconButton edge="end" size='small' sx={{ ml: .5, color: '#2464EB', cursor: 'pointer' }} 
                onClick={() => { 
                  setOpen(true);
                  AnalyticsEvent(eventsNames.categories.ITEMS,{action:eventsNames.actions.item.action.SEARCH_HSN});
                   }}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>

            }}
          />
        </Grid>

        {/* /////////////////////////////////////////// Tax /////////////////////////////////////////////////////// */}
        <Grid item xs={12} sm={4} sx={{}}>
          {/* <TaxList
            title='Tax'
            result={result}
            value={productService.tax}
            setValue={(value) => setProductService({ ...productService, tax: value ? value : null, tax_id: value ? value.id : null })} /> */}

          <Dropdown
            id={'gst_rate_dropdown'}
            dataTestId={'gst_rate_dropdown'}
            label={'GST Rate (%) '}
            disableClearable={false}
            results={GSTTaxRateChoice}
            placeholder={'Select Tax Rate'}
            value={productService.gst_rate !== null ? GSTTaxRateChoice.find((item) => item.value === productService.gst_rate) : null}
            setValue={(event, selected) => {
              if (selected) {
                productService.gst_rate = selected.value;
              } else {
                productService.gst_rate = null;
              }
              setProductService({ ...productService })
            }}
          />
        </Grid>

        {/* /////////////////////////////////////////// Attachment /////////////////////////////////////////////////////// */}
        <Grid item xs={12} sm={4} sx={{ mt: 1 }}>
          <InputFile
            onFileUpload={(file) => {
              onChange('attachment', file)
            }}
            attachment={productService.attachment}
            onFileRemove={() => {
              onChange('attachment', null)
            }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

const ProductInformation = (props) => {
  const { productService, setProductService, taxRate, compulsory, isFormSubmitted } = props

  const { is_sales_inclusive, selling_price_with_tax, is_purchase_inclusive, cost_price_with_tax } = productService

  const inclusiveTax = (value, taxRate) => { return Number(value / (1 + taxRate * 0.01)).toFixed(2) }


  React.useEffect(() => {
    if (is_purchase_inclusive) { productService.cost_price = inclusiveTax(cost_price_with_tax, taxRate); } else { productService.cost_price = Number(cost_price_with_tax).toFixed(2); }
    if (is_sales_inclusive) { productService.selling_price = inclusiveTax(selling_price_with_tax, taxRate); } else { productService.selling_price = Number(selling_price_with_tax).toFixed(2); }
    setProductService({ ...productService });
    // eslint-disable-next-line
  }, [taxRate, is_purchase_inclusive, cost_price_with_tax, is_sales_inclusive, selling_price_with_tax])

  return (
    <Box component="form" noValidate sx={{ mt: 3 }}>
      <Information
        id={'sales'}
        type="sales"
        dataTestId={'sales'}
        firstletter='INCOME'
        productService={productService}
        isFormSubmitted={isFormSubmitted}
        compulsory={compulsory === 'sales'}
        setProductService={setProductService}
        defaultAccount={productService.sales_account}
        basePrice={Number(productService.selling_price)}
        inputTitle={<LabelWithAsteriskMark label={'Selling Price'} />}
        disabled={productService.for_sales ? {} : { background: '#F1F3F4' }}

      />
      <Information
        id={'product'}
        type="product"
        firstletter='EXPENSE'
        dataTestId={'product'}
        productService={productService}
        isFormSubmitted={isFormSubmitted}
        compulsory={compulsory === 'product'}
        setProductService={setProductService}
        basePrice={Number(productService.cost_price)}
        defaultAccount={productService.purchase_account}
        inputTitle={<LabelWithAsteriskMark label={'Cost Price'} />}
        disabled={productService.for_purchase ? {} : { background: '#F1F3F4' }}
      />
    </Box>
  )
}

const StockInformation = (props) => {
  const { isManageStock, productService, setProductService, isFormSubmitted } = props

  const [open, setOpen] = React.useState(false)
  return (
    <Box component="form" noValidate sx={{ mt: 3 }}>
      <ManageStock open={open} setOpen={setOpen} setProductService={setProductService} productService={productService} />
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={4} sx={{}}>
          {isManageStock ?
            <Input
              id={'input_current_stock'}
              dataTestId={'input_current_stock'}
              item={{
                type: 'number',
                placeholder: '',
                disabled: true,
                required: true,
                fullWidth: true,
                title: 'Current Stock',
                value: productService.current_stock,
                onChange: (e) => { }
              }}
            />
            :
            <Input
              id={'input_opening_stock'}
              dataTestId={'input_opening_stock'}
              isSearchableComponent={true}
              item={{
                type: 'number',
                required: true,
                fullWidth: true,
                title: 'Opening Stock',
                placeholder: 'Enter Opening Stock',
                value: productService.opening_stock !== null ? productService.opening_stock : '',
                onChange: (e) => {
                  if (isNumber(e.target.value)) {
                    productService.opening_stock = limitDecimalPlaces(e.target.value);
                  } else {
                    productService.opening_stock = null;
                  }
                  setProductService({ ...productService })
                },
                onBlur: (e) => {
                  if (isNumber(e.target.value)) {
                    productService.is_low_stock_reminder_active = true;
                    productService.opening_stock = limitDecimalPlaces(e.target.value);
                    productService.low_stock_value = productService.low_stock_value === null ? Math.floor(limitDecimalPlaces(productService.opening_stock) * 0.1) : productService.low_stock_value;
                  } else {
                    productService.opening_stock = null;
                    productService.low_stock_value = null;
                    productService.is_low_stock_reminder_active = false;
                  }
                  setProductService({ ...productService })
                },
              }}
              onKeyPress={(event) => {
                if (event?.key === '-' || event?.key === '+') {
                  event.preventDefault();
                }
              }}
            />
          }

          {
            isManageStock &&
            <CustomTypography
              id={'manage_action_btn'}
              dataTestId={'manage_action_btn'}
              text={<span><SyncAltIcon sx={{ pt: .5, mb: -.8, fontSize: '16px', transform: "rotate(90deg)" }} /> Manage Stock</span>}
              onClick={() => { setOpen(true) }}

              sx={{
                textAlign: 'end',
                fontSize: '12px',
                color: '#2464EB',
                fontWeight: '700',
                cursor: 'pointer',
                lineHeight: '18px',
                fontStyle: 'normal',
                textTransform: "none",
                fontFamily: "Noto Sans",
                ":hover": { textDecoration: 'underline' }
              }}
            />
          }
        </Grid>

        <Grid item xs={12} sm={4} sx={{}}>
          <DatePicker
            id={'as_of_date_select'}
            dataTestId={'as_of_date_select'}
            title={'Date'}
            maxDate={moment()}
            disabledCloseIcon={isManageStock}
            disabledDatePicker={isManageStock}
            validation={isFormSubmitted && !productService.as_of_date}
            date={productService.as_of_date ? moment(productService.as_of_date) : null}
            setDate={(date) => { setProductService({ ...productService, as_of_date: date ? date.format('YYYY-MM-DD') : null }) }}
          />
        </Grid>

        <Grid item xs={12} sm={4} sx={{}}>
          <ItemCodeInput
          value={productService.stock_item_code}
          setValue={(value)=>{
            if (value !== '') {
              productService.stock_item_code = value
            } else {
              productService.stock_item_code = null;
            }
            setProductService({ ...productService })
          }}
          />
          {/* <Input
            id={'input_stock_item_code'}
            dataTestId={'input_stock_item_code'}
            item={{
              type: 'text',
              required: true,
              fullWidth: true,
              title: 'Stock / Item Code ',
              placeholder: 'Enter Stock / Item Code ',
              value: productService.stock_item_code !== null ? productService.stock_item_code : '',
              onChange: (e) => {
                if (e.target.value !== '') {
                  productService.stock_item_code = e.target.value
                } else {
                  productService.stock_item_code = null;
                }
                setProductService({ ...productService })
              },
            }}
          /> */}
        </Grid>

        <Grid item xs={12} sm={4} sx={{}}>

          <FormControlLabel
            id={'low_stock_reminder_switch'}
            dataTestId={'low_stock_reminder_switch'}
            sx={{ mb: -1 }}
            labelPlacement="start"
            control={<Switch
              sx={{ textAlign: 'end' }}
              onChange={(event) => {
                productService.is_low_stock_reminder_active = event.target.checked
                if (event.target.checked && productService.opening_stock === null) { productService.opening_stock = 0; }
                if (event.target.checked && productService.opening_stock !== null) { productService.low_stock_value = productService.low_stock_value === null ? Math.floor(limitDecimalPlaces(productService.opening_stock) * 0.1) : productService.low_stock_value; } else if (productService.low_stock_value === null) { productService.low_stock_value = 0; }
                setProductService({ ...productService, })
              }
              }
              checked={productService.is_low_stock_reminder_active}
              inputProps={{ 'aria-label': 'controlled' }}
            />}
            label={
              <CustomTypography
                text={<span style={{ display: 'flex', alignItems: 'center' }}><span>Low Stock Reminder</span><MetaData placement='top' sx={{}} meta_data={`Get low stock alerts when stock level falls below this value`} /></span>}
                sx={{
                  ml: -2,
                  fontSize: '14px',
                  color: '#000000',
                  fontWeight: '700',
                  lineHeight: '18px',
                  fontStyle: 'normal',
                  textTransform: "none",
                  fontFamily: "Noto Sans",
                  textTransform: 'capitalize',
                }}
              />
            }
          />
          <Input
            id={'input_low_stock_reminder'}
            dataTestId={'input_low_stock_reminder'}
            item={{
              title: '',
              type: 'number',
              required: true,
              disabled: false,
              fullWidth: true,
              placeholder: 'Enter Low Stock Reminder',
              value: productService.low_stock_value !== null ? productService.low_stock_value : '',
              validation: isFormSubmitted && productService.is_low_stock_reminder_active && productService.low_stock_value === null,
              onChange: (e) => {
                if (isNumber(e.target.value)) {
                  productService.low_stock_value = limitDecimalPlaces(e.target.value)
                } else {
                  productService.low_stock_value = null;
                }
                setProductService({ ...productService })
              },
              onBlur: () => {
                if (productService.low_stock_value === null) {
                  productService.is_low_stock_reminder_active = false;
                } else if (productService.opening_stock === null) {
                  productService.opening_stock = 0;
                  productService.is_low_stock_reminder_active = true;
                }
                setProductService({ ...productService })
              }
            }}

            onKeyPress={(event) => {
              if (event?.key === '-' || event?.key === '+') {
                event.preventDefault();
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} sx={{ mt: 5 }}>
          {
            isManageStock ?
              <React.Fragment>
                {productService.current_stock !== null ?
                  (productService.current_stock > productService.low_stock_value) ?
                    <Status status={'in_stock'} />
                    :
                    productService.current_stock !== null && (productService.current_stock <= 0) ?
                      <Status status={'no_stock'} />
                      :
                      <Status status={'low_stock'} />
                  : null
                }
              </React.Fragment>
              :
              <React.Fragment>
                {productService.opening_stock !== null ? (productService.opening_stock > productService.low_stock_value) ?
                  <Status status={'in_stock'} />
                  :
                  productService.opening_stock !== null && (productService.opening_stock <= 0) ?
                    <Status status={'no_stock'} />
                    :
                    <Status status={'low_stock'} />
                  : null
                }
              </React.Fragment>
          }
        </Grid>
      </Grid>
    </Box>
  )
}

const ManageStock = (props) => {
  const { open, setOpen, productService, setProductService } = props
  let location = useLocation();
  const params = getQueryParams(location.search)

  const stock_initial = {
    reason: null,
    is_stock_in: true,
    stock_adjustment_value: null,
    business_id: getBusinessInfo().id,
  }
  const [editable, setEditable] = React.useState(false);
  const dispatch = Actions.getDispatch(React.useContext);
  const [data, setData] = React.useState({ ...stock_initial });
  const [isFormSubmitted, setFormSubmitted] = React.useState(false);

  const onSave = async () => {
    setFormSubmitted(true)

    if (data.stock_adjustment_value === null) {
      stateChangeManager(dispatch, Actions, true, 'error', `Please Enter the ${data.is_stock_in ? 'Add to Stock' : 'Remove from Stock'}`)
    } else {
      let res = await apiAction({
        data: { ...data, reason: data.reason ? data.reason : 'Adjustment' },
        method: 'post',
        url: adjust_item_stock(params.item_id),
      })
      if (res?.success) {
        setProductService({ ...productService, current_stock: res?.result });
        stateChangeManager(dispatch, Actions, true, 'success', res?.status);
        handleCancel()
      } else {
        stateChangeManager(dispatch, Actions, true, 'error', res?.status)
      }
    }
  }

  const handleCancel = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    setFormSubmitted(false);
    setData({ ...stock_initial });
  }, [open])
  return (
    <div>
      <BootstrapDialog
        open={open}
        maxWidth='sm'
        fullWidth={true}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={() => setOpen(false)}>
          <CustomTypography
            text={'Manage Stock'}
            sx={{
              color: '#000000',
              fontSize: '16px',
              fontWeight: '700',
              lineHeight: '18px',
              fontStyle: 'normal',
              fontFamily: "Noto Sans",
            }}
          />
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Box sx={{ p: 2, pt: 0, }}>
            <CustomTypography
              text={<span>Item Name: <span style={{ fontWeight: '500' }}>{productService.name}</span></span>}
              sx={{
                mb: 3,
                color: '#000000',
                fontSize: '14px',
                fontWeight: '700',
                lineHeight: '18px',
                fontStyle: 'normal',
                fontFamily: "Noto Sans",
              }}
            />
            <CustomTypography
              text={<span>Current Stock: <span style={{ fontWeight: '500' }}>{formatterWithoutSymbol(null, productService.current_stock)}</span></span>}
              sx={{
                mb: 2,
                color: '#000000',
                fontSize: '14px',
                fontWeight: '700',
                lineHeight: '18px',
                fontStyle: 'normal',
                fontFamily: "Noto Sans",
              }}
            />
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <RadioGroup row>
                  <FormControlLabel
                    id={'is_stock_in_radio'}
                    dataTestId={'is_stock_in_radio'}
                    label={<span style={{
                      color: '#000000',
                      fontSize: '14px',
                      lineHeight: '18px',
                      fontStyle: 'normal',
                      fontFamily: "Noto Sans",
                      textTransform: 'capitalize',
                      fontWeight: data.is_stock_in ? '600' : '400',
                    }}>Stock In</span>} control={<Radio sx={{ color: '#8F8F8F' }} checked={data.is_stock_in} onChange={(e) => { setData({ ...data, is_stock_in: e.target.checked }) }} />} />
                  <FormControlLabel value="service"
                    id={'is_stock_out_radio'}
                    dataTestId={'is_stock_out_radio'}
                    label={<span style={{
                      color: '#000000',
                      fontSize: '14px',
                      lineHeight: '18px',
                      fontStyle: 'normal',
                      fontFamily: "Noto Sans",
                      textTransform: 'capitalize',
                      fontWeight: !data.is_stock_in ? '600' : '400',
                    }}>Stock Out</span>} control={<Radio sx={{ color: '#8F8F8F' }} checked={!data.is_stock_in} onChange={(e) => { setData({ ...data, is_stock_in: !e.target.checked }) }} />} />
                </RadioGroup>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Input
                  id={'input_stock_adjustment_value'}
                  dataTestId={'input_stock_adjustment_value'}
                  editable={editable}
                  currency_code={null}
                  isShowCommaSeparatedValue={true}
                  onFocus={() => { setEditable(true) }}
                  item={{
                    type: 'number',
                    required: true,
                    fullWidth: true,
                    placeholder: 'Eg. 100',
                    validation: isFormSubmitted && (data.stock_adjustment_value === null),
                    value: data.stock_adjustment_value !== null ? data.stock_adjustment_value : '',
                    title: <LabelWithAsteriskMark label={data.is_stock_in ? 'Add to Stock' : 'Remove from Stock'} />,

                    onChange: (e) => {
                      if (isNumber(e.target.value)) {
                        data.stock_adjustment_value = limitDecimalPlaces(e.target.value)
                      } else {
                        data.stock_adjustment_value = null;
                      }
                      setData({ ...data })
                    },
                    onBlur: () => { setEditable(false) }
                  }}
                  onKeyPress={(event) => {
                    if (event?.key === '-' || event?.key === '+') {
                      event.preventDefault();
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <CustomTypography
                  text={<span>Final Stock: <span style={{ fontWeight: '500' }}>{data.is_stock_in ? formatterWithoutSymbol(null, (productService.current_stock + data.stock_adjustment_value)) : formatterWithoutSymbol(null, (productService.current_stock - data.stock_adjustment_value))}</span></span>}
                  sx={{
                    color: '#000000',
                    fontSize: '14px',
                    fontWeight: '700',
                    lineHeight: '18px',
                    fontStyle: 'normal',
                    fontFamily: "Noto Sans",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <Input
                  id={'input_stock_adjustment_reason'}
                  dataTestId={'input_stock_adjustment_reason'}
                  item={{
                    rows: 4,
                    type: 'text',
                    title: 'Reason',
                    required: true,
                    multiline: true,
                    fullWidth: true,
                    placeholder: 'Eg. Adjustment',
                    value: data.reason ? data.reason : '',
                    onChange: (e) => { setData({ ...data, reason: e.target.value }) },
                  }}
                />
              </Grid>
            </Grid>
            <div style={{ textAlign: 'end', marginTop: '24px' }}>
              <CustomButton
                id={'stock_adjustment_cancel_btn'}
                dataTestId={'stock_adjustment_cancel_btn'}
                btnLabel='Cancel'
                variant='outlined'
                onClick={() => { handleCancel() }}
                sx={{ textTransform: 'none', '&:hover': { backgroundColor: '#e8f3ff' } }}
              />
              <CustomButton
                id={'stock_adjustment_save_btn'}
                dataTestId={'stock_adjustment_save_btn'}
                btnLabel='Update'
                variant='contained'
                onClick={() => { onSave() }}
                sx={{ ml: 2, textTransform: 'none', }}
              />
            </div>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  )
}

const Buttons = (props) => {
  const { onSave, onCancel } = props
  let location = useLocation();
  const params = getQueryParams(location.search)
  return (
    <Box component="form" noValidate  >
      <Grid container>
        <Grid item xs={12} sx={{ display: '-webkit-flex', justifyContent: 'end', }}>
          <CustomButton id={'cancel_btn'} dataTestId={'cancel_btn'} variant='outlined' sx={{ textTransform: 'none', '&:hover': { backgroundColor: '#e8f3ff' } }} btnLabel='Cancel' onClick={onCancel} />
          <CustomButton id={'save_btn'} dataTestId={'save_btn'} variant='contained' sx={{ ml: 1, textTransform: 'none' }} btnLabel={params.item_id ? 'Update' : 'Save'} onClick={onSave} />
        </Grid>
      </Grid>
    </Box>
  )
}

const AdditionalInformation = ({ isFormSubmitted, data = [], setData }) => {

  const [isValidate, setIsValidate] = React.useState(isFormSubmitted);

  const onRemoveField = (index) => {
    let newData = [...data];
    newData.splice(index, 1);
    setData(newData);
  };

  const onChangeField = (index, newValue) => {
    let newData = [...data];
    const key = Object.keys(newData[index])[0];
    newData[index][key] = newValue;
    setData(newData);
  };

  return (
    <div>
      <AdditionalField data={data} setData={setData} setIsValidate={setIsValidate} />

      <Grid container spacing={3} sx={{ mt: 0.1 }}>
        <React.Fragment>
          {data.map((obj, index) => {
            let label = Object.keys(obj)[0];
            let value = obj[label];
            return (
              <Grid item xs={12} sm={4} key={index} sx={{}}>
                <Input
                  autoFocus={true}
                  id={'input_' + label}
                  dataTestId={'input_' + label}
                  item={{
                    type: 'text',
                    value: value,
                    required: true,
                    fullWidth: true,
                    placeholder: `Enter ${label} value`,
                    validation: value === '' && isValidate,
                    onChange: (e) => { onChangeField(index, e.target.value); },
                    title: (
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <LabelWithAsteriskMark label={label} />
                        <CustomTypography
                          id={'field_remove_btn'}
                          text={`- Remove Field`}
                          dataTestId={'field_remove_btn'}
                          onClick={() => { onRemoveField(index); }}
                          sx={{
                            textAlign: 'end',
                            fontSize: '12px',
                            color: '#2464EB',
                            fontWeight: '700',
                            cursor: 'pointer',
                            lineHeight: '18px',
                            fontStyle: 'normal',
                            textTransform: "none",
                            fontFamily: "Noto Sans",
                            ":hover": { textDecoration: 'underline' }
                          }}
                        />
                      </div>
                    ),
                  }}
                />
              </Grid>
            );
          })}
        </React.Fragment>
      </Grid>
    </div>
  );
};

const AdditionalField = ({ data, setData, setIsValidate }) => {
  const [open, setOpen] = React.useState(false);
  const [isAdd, setIsAdd] = React.useState(false);
  const dispatch = Actions.getDispatch(React.useContext);
  const [fieldInput, setFieldInput] = React.useState('');

  const onAdd = () => {
    if (fieldInput) {
      const isDuplicate = data.some(obj => Object.keys(obj)[0] === fieldInput);
      if (isDuplicate) {
        stateChangeManager(dispatch, Actions, true, 'error', 'Field name already exists');
      } else {
        onCancel();
        setData((previous) => [...previous, { [fieldInput]: '' }]);
      }
    } else {
      setIsAdd(true);
      stateChangeManager(dispatch, Actions, true, 'error', 'Please enter field name');
    }
  };

  const onCancel = () => {
    setOpen(false);
    setIsAdd(false);
    setFieldInput('');
  };

  const onOpen = () => {
    const hasEmptyValue = data.some(obj => Object.values(obj)[0] === '' || Object.values(obj)[0] === null);
    if (hasEmptyValue) {
      setIsValidate(true);
      stateChangeManager(dispatch, Actions, true, 'error', 'Please fill all fields before adding a new one');
    } else {
      setOpen(true);
      setIsValidate(false);
    }
  };

  return (
    <>
      <CustomTypography
        id={'field_add_btn'}
        text={`+ Add Field`}
        dataTestId={'field_add_btn'}
        onClick={() => { onOpen(); }}
        sx={{
          textAlign: 'end',
          fontSize: '12px',
          color: '#2464EB',
          fontWeight: '700',
          marginTop: '10px',
          cursor: 'pointer',
          lineHeight: '18px',
          fontStyle: 'normal',
          textTransform: "none",
          fontFamily: "Noto Sans",
          ":hover": { textDecoration: 'underline' }
        }}
      />
      <BootstrapDialog
        open={open}
        maxWidth='sm'
        fullWidth={true}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={() => onCancel()}>
          <CustomTypography
            text={'Add Field Name'}
            sx={{
              color: '#000000',
              fontSize: '16px',
              fontWeight: '700',
              lineHeight: '18px',
              fontStyle: 'normal',
              fontFamily: "Noto Sans",
            }}
          />
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Box sx={{ p: 2, pt: 0 }} component={'form'}>
            <Input
              autoFocus={true}
              id={'input_field_name'}
              dataTestId={'input_field_name'}
              item={{
                title: '',
                type: 'text',
                required: true,
                fullWidth: true,
                value: fieldInput,
                placeholder: `Enter field name`,
                validation: fieldInput === '' && isAdd,
                onChange: (e) => { setFieldInput(e.target.value); },
              }}
            />
            <div style={{ textAlign: 'end', marginTop: '24px' }}>
              <CustomButton
                sx={{ mr: 2 }}
                btnLabel='Cancel'
                variant='outlined'
                id={'add_field_cancel_btn'}
                onClick={() => { onCancel(); }}
                dataTestId={'add_field_cancel_btn'}
              />
              <CustomButton
                type="submit"
                btnLabel='Add'
                variant='contained'
                id={'add_field_save_btn'}
                onClick={() => { onAdd(); }}
                dataTestId={'add_field_save_btn'}
              />
            </div>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};






