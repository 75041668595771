import React from 'react';
import { apiAction } from '../../../../api/api';
import { create_tax } from '../../../../api/urls';
import * as Actions from '../../../../state/Actions';
import {stateChangeManager } from '../../../../utils/Utils';
import CustomInput from '../../../custom/input/CustomInput';
import CustomButton from '../../../custom/button/CustomButton';
import CustomDialog from '../../../custom/dialog/CustomDialog';
import { getBusinessInfo } from '../../../../config/cookiesInfo';
import CustomDropdown from '../../../custom/dropdown/CustomDropdown';
import CustomTypography from '../../../custom/typography/CustomTypography';

import {
    Box,
    Grid,
    Container,
} from '@mui/material';

export const AddTax = (props) => {
    const { onClose } = props
    const setState = Actions.getDispatch(React.useContext);

    const validation = (data, setState, Actions) => {
        var isValid = true
        if (data.get('name') === '') {
            stateChangeManager(setState, Actions, true, 'error', 'Name field can not be empty')
            isValid = false
        } else if (data.get('number_of_days') === '') {
            stateChangeManager(setState, Actions, true, 'error', 'Number of days field can not be empty')
            isValid = false
        }
        return isValid
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        // collect all the input into data based on input id
        const data = new FormData(event.currentTarget);

        // validate the data
        if (validation(data, setState, Actions)) {

            //Api call
            let res = await apiAction({
                method: 'post',
                url: create_tax(),
                data: {
                    name: data.get("name"),
                    business_id: getBusinessInfo().id,
                    number_of_days: data.get("number_of_days")
                }
            })
            console.log('res', res)
            if (res?.success) {
                onClose()
                // checking the response and according to save the data in to 'js-cookie' for the future use
                stateChangeManager(setState, Actions, true, 'success', res?.status);

            } else {
                // stateChangeManager is a alert function which show the error message
                stateChangeManager(setState, Actions, true, 'error', res?.status)
            }
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box component="form"
                onSubmit={handleSubmit}
                noValidate sx={{ mt: 1 }}>
                <Grid Container spacing={2}>
                    <Grid item xs={12}>
                        <CustomInput
                            margin="normal"
                            required
                            fullWidth
                            id="name"
                            label="Name"
                            name="name"
                            autoComplete="name"
                            autoFocus
                        />
                    </Grid>
                    <Grid item xs={12} container spacing={1}>
                        <TaxDetails />
                    </Grid>
                </Grid>
                <CustomButton
                    fullWidth
                    type="submit"
                    btnLabel='Save'
                    variant="contained"
                    sx={{ mt: 3, mb: 2, textTransform: 'capitalize' }}
                />
            </Box>
            {/* </Box> */}
        </Container>
    )
}

export const TaxList = (props) => {
    const { id, dataTestId, result, title, value, setValue, inputDisabled, disableClearable, validation } = props

    const [state, setState] = React.useState({
        id: '',
        title: '',
        open: false,
        condition: '',
        maxWidth: 'lg',
        fullWidth: true,
    })

    // const addHandler = () => {
    //     setState({
    //         open: true,
    //         title: 'Add tax',
    //         fullWidth: true,
    //         maxWidth: 'sm',
    //         condition: 'add_tax',
    //     })
    // }

    const onChangeHandler = (newInputValue) => {
        setValue(newInputValue)
    }
    return (
        <React.Fragment>
            <CustomDialog
                state={state}
                setState={setState}
            />
            <CustomTypography
                text={title ? title : ''}
                sx={{
                    mb: 1,
                    color: '#000000',
                    fontSize: '14px',
                    fontWeight: '700',
                    lineHeight: '18px',
                    fontStyle: 'normal',
                    textTransform: "none",
                    fontFamily: "Noto Sans",
                    textTransform: 'capitalize',
                }}
            />
            <CustomDropdown
                results={result}
                className='tax_dropdown'
                placeholder='Select Tax'
                isfilterOptions='includes'
                id={id ? id : 'tax_dropdown'}
                value={value ? value.name : null}
                validation={validation}
                dataTestId={dataTestId ? dataTestId : 'tax_dropdown'}
                disableClearable={!disableClearable ? disableClearable : false}
   
                inputDisabled={inputDisabled}
                sx={{ background: inputDisabled ? '#F1F3F4' : '' }}
                onChange={(event, newInputValue) => onChangeHandler(newInputValue)}
            // addButton={{
            //     title: '+ Add tax',
            //     onClick: () => addHandler()
            // }}
            />
        </React.Fragment>

    )
}

const TaxDetails = (props) => {
    return (
        <React.Fragment>
            <Grid item xs={4}>
                <CustomInput
                    margin="normal"
                    required
                    fullWidth
                    name="number_of_days"
                    label="Short name"
                    type="number"
                    id="number_of_days"
                    autoComplete="number_of_days"
                /></Grid>
            <Grid item xs={4}>
                <CustomInput
                    margin="normal"
                    required
                    fullWidth
                    name="number_of_days"
                    label="Tax account"
                    type="number"
                    id="number_of_days"
                    autoComplete="number_of_days"
                /></Grid>
            <Grid item xs={4}>
                <CustomInput
                    margin="normal"
                    required
                    fullWidth
                    name="number_of_days"
                    label="Rate"
                    type="number"
                    id="number_of_days"
                    autoComplete="number_of_days"
                /></Grid>
        </React.Fragment>
    )
}
