import React from 'react';
import moment from 'moment/moment';
import Status from '../../../../common/Status';
import HTMLViewer from '../../../../common/HTMLViewer';
import * as Actions from '../../../../../state/Actions';
import CustomTitle from '../../../../common/CustomTitle';
import { InvoiceViewLink } from '../../common/CommonLinks';
import { useNavigate, useLocation } from 'react-router-dom';
import CustomTabs from '../../../../custom/tabs/CustomTabs';
import { routesName } from '../../../../../config/routesName';
import ListItemActions from '../../../../common/ListItemActions';
import CustomDialog from '../../../../custom/dialog/CustomDialog';
import { getBusinessInfo } from '../../../../../config/cookiesInfo';
import CurrencyFormatter from '../../../../common/CurrencyFormatter';
import { NoDataComponent } from '../../../../common/NoDataComponent';
import AuditTrailHistory from '../../../../common/AuditTrailHistory';
import CommonPreviewShare from '../../../../common/CommonPreviewShare';
import ViewPageActionButtons from '../../../../common/ViewPageActionButtons';
import CustomPagination from '../../../../custom/pagination/CustomPagination';
import CustomTypography from '../../../../custom/typography/CustomTypography';
import { eventsNames,AnalyticsEvent } from '../../../../../firebase/firebaseAnalytics';
import CommonJournalEntryTransactionView from '../../common/CommonJournalEntryTransactionView';

import {
  getQueryParams,
  breadCrumbsStateChangeManager,
} from '../../../../../utils/Utils';

import {
  CustomContainer,
  CustomTitleContainer,
} from '../../../../custom/container/CustomContainer';

import {
  delete_credit_note,
  export_credit_note,
  update_credit_note,
  retrieve_credit_note,
  delete_refund_payment,
  mark_credit_note_sent,
  list_of_credited_invoices,
  get_list_of_refund_payment,
  delete_credits_applied_to_invoice,
} from '../../../../../api/urls';

import {
  setLoader,
  getDateFormat,
  setFilterMessage,
  stateChangeManager,
} from '../../../../../utils/Utils';

import {
  apiAction,
  apiBlobResponse,
  apiHandleDownload,
} from '../../../../../api/api';

import {
  CustomTable,
  CustomTableRow,
  CustomTableBody,
  CustomTableHead,
  CustomTableHeadCell,
  CustomTableBodyCell,
  CustomTableContainer,
} from '../../../../custom/table/CustomTable';

import {
  Box,
  Grid,
  IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { CommonWebSocket } from '../../../../../api/webSocket';




const CreditNoteViewpage = () => {
  let location = useLocation();
  const navigate = useNavigate();
  const params = getQueryParams(location.search)
  const dispatch = Actions.getDispatch(React.useContext);

  const [tab, setTab] = React.useState(params.tab ? 1 : 0);
  const [previewData, setPreviewData] = React.useState();
  const [basicInformation, setBasicInformation] = React.useState();

  const [state, setState] = React.useState({
    id: '',
    title: '',
    open: false,
    condition: '',
    maxWidth: 'lg',
    fullWidth: true,
  })

  const webSocketResponse = CommonWebSocket();
  React.useEffect(() => {
    if (webSocketResponse) {
      let webSocketData = JSON.parse(webSocketResponse.data)
      if (webSocketData.document_type === "credit_note") {

        if (webSocketData.event === "send_document" && basicInformation.id === webSocketData.object_id) {
          if (webSocketData.event_success) {
            setBasicInformation({ ...basicInformation, status: webSocketData.object_status })
            stateChangeManager(dispatch, Actions, true, "success", `Email delivered successfully for ${webSocketData.object_number}`);
          } else {
            stateChangeManager(dispatch, Actions, true, "error", `Email delivery failed for ${webSocketData.object_number}`);
          }
        }

        if (webSocketData.event === "delete_document" && basicInformation.id === webSocketData.object_id) {
          if (webSocketData.event_success) {
            stateChangeManager(dispatch, Actions, true, "success", `Credit Note ${webSocketData.object_number} deleted successfully`);
            navigate(routesName.invoicingCreditNotesList.path)
          } else {
            stateChangeManager(dispatch, Actions, true, "error", `Failed to delete Credit Note ${webSocketData.object_number}`);
          }
        }

        if (webSocketData.event === "update_document" && basicInformation.id === webSocketData.object_id) {
          if (webSocketData.event_success) {
            stateChangeManager(dispatch, Actions, true, "success", `Credit Note ${webSocketData.object_number} updated successfully`);
            retrieveCreditNote();
          } else {
            stateChangeManager(dispatch, Actions, true, "error", `Failed to update Credit Note ${webSocketData.object_number}`);
          }
        }
      }
    }
  }, [webSocketResponse])

  const viewJournalEntryHandler = (id) => {
    setState({
      id: id,
      open: true,
      maxWidth: 'sm',
      fullWidth: true,
      condition: 'view',
      title: 'View Journal Entry',
    })
  }
  const stateChangeHandler = (title, condition, maxWidth, url, deleteMessage) => {
    setState({
      url: url,
      open: true,
      title: title,
      fullWidth: true,
      maxWidth: maxWidth,
      condition: condition,
      deleteMessage: deleteMessage,
      navigate: () => navigate(routesName.invoicingCreditNotesList.path),
    })
  }

  const retrieveCreditNote = async () => {
    setLoader(dispatch, Actions, true);
    let res = await apiAction({
      method: 'post',
      url: retrieve_credit_note(params.id),
      data: { business_id: getBusinessInfo().id },
    })
    if (res?.success) {
      setBasicInformation(res?.result);
      breadCrumbsStateChangeManager(dispatch, res?.result.credit_note_number);
      let previewRes = await apiAction({
        method: 'post',
        url: export_credit_note(params.id),
        data: { ...res?.result, place_of_supply_id: res?.result.place_of_supply ? res?.result.place_of_supply.id : null, export_type: 'html' },
      })
      if (previewRes?.success) {
        setLoader(dispatch, Actions, false);
        setPreviewData(previewRes.result)
      } else {
        setLoader(dispatch, Actions, false);
      }
    }
  }

  React.useEffect(() => {
    if (!state.open && params.id) {
      retrieveCreditNote()
    }
    // eslint-disable-next-line
  }, [state.open]);

  React.useEffect(() => {
    if (params.id && !tab || params.tab) {
      retrieveCreditNote()
    }
    // eslint-disable-next-line
  }, [tab]);


  const approve = async (data) => {
    if (data) {
      const approveCreditNotesRes = await apiAction({ url: update_credit_note(data.id), method: 'POST', data: { is_draft: false, customer_id: data.customer_id, against_invoice_id: data.against_invoice_id, business_id: getBusinessInfo().id } })
      if (approveCreditNotesRes.success) {
        retrieveCreditNote();
        stateChangeManager(dispatch, Actions, true, 'success', approveCreditNotesRes.status);
      } else {
        retrieveCreditNote();
        stateChangeManager(dispatch, Actions, true, 'error', approveCreditNotesRes.status);
      }
    }
  }

  const send = async (data) => {
    setSendMail(true)
    // const sendCreditNotesRes = await apiAction({ url: send_credit_note_mail(), method: 'POST', data: { business_id: getBusinessInfo().id, credit_note_id: data.id } })
    // if (sendCreditNotesRes.success) {
    //   retrieveCreditNote();
    //   stateChangeManager(dispatch, Actions, true, 'success', sendCreditNotesRes.status);
    // }
  }

  const markSent = async (id) => {
    const sentRes = await apiAction({ url: mark_credit_note_sent(id), method: 'POST', data: { business_id: getBusinessInfo().id } })
    if (sentRes.success) {
        stateChangeManager(dispatch, Actions, true, 'success', sentRes.status);
    }else {
        stateChangeManager(dispatch, Actions, true, 'error', sentRes.status);
    }
}
  const print = async (data) => {
    setLoader(dispatch, Actions, true);
    if (basicInformation) {
      apiBlobResponse({
        url: export_credit_note(), data: { ...data, export_type: "pdf" }, onSuccess: () => {
          setLoader(dispatch, Actions, false);
        }
      })
    }
  }
  const download = async (data) => {
    setLoader(dispatch, Actions, true);
    if (data) {
      apiHandleDownload({
        url: export_credit_note(), data: { ...data, export_type: "pdf" }, filename: data.credit_note_number, onSuccess: () => {
          setLoader(dispatch, Actions, false)
        }
      })
    }
  }

  const onSelectAction = (action, item) => {
    setLoader(dispatch, Actions, true)
    if (action === "edit") {
      navigate(routesName.invoicingCreditNotesAdd.path + "?id=" + item.id)
    }
    if (action === "approve") {
      approve(item)
      AnalyticsEvent(eventsNames.categories.CREDIT_NOTES,{action:eventsNames.actions.APPROVE})
    }
    if (action === "send") {
      send(item)
      AnalyticsEvent(eventsNames.categories.CREDIT_NOTES,{action:eventsNames.actions.SEND})
    }
    if (action === "mark_sent") {
      markSent(item.id)
      AnalyticsEvent(eventsNames.categories.CREDIT_NOTES,{action:eventsNames.actions.MARK_SENT})
  }
    if (action === "print") {
      print(item)
      AnalyticsEvent(eventsNames.categories.CREDIT_NOTES,{action:eventsNames.actions.PRINT})
    }
    if (action === "download") {
      download(item)
      AnalyticsEvent(eventsNames.categories.CREDIT_NOTES,{action:eventsNames.actions.DOWNLOAD})
    }
    if (action === "refund") {
      navigate(routesName.invoicingCreditNotesRefund.path + "?id=" + item.id)
    }
  }

  const onClickActionText = (status, item) => {
    if (status === 'draft') {
      onSelectAction("approve", item);
    } else if (status === 'unsent') {
      onSelectAction("send", item);
    } else if (status === 'sent' || status === 'partially_utilized') {
      if (item.total_amount === 0) {
        stateChangeManager(dispatch, Actions, true, 'error', `Refund is not possible for credit note with zero amount`);
      } else {
        onSelectAction("refund", item);
      }
    }
  }

  const toActionText = (status) => {
    let text
    if (status === 'draft') {
      text = 'Approve'
    } else if (status === 'unsent') {
      text = 'Send'
    } else if (status === 'sent' || status === 'partially_utilized') {
      text = 'Refund'
    }
    return text
  }

  const tabs = [
    { name: 'View', condition: 'preview',AnalyticsEvent:()=>{AnalyticsEvent(eventsNames.categories.CREDIT_NOTES,{action:eventsNames.actions.PREVIEW})}  },
    { name: 'Refund History', condition: 'refund_history',AnalyticsEvent:()=>{AnalyticsEvent(eventsNames.categories.CREDIT_NOTES,{action:eventsNames.actions.REFUND_HISTORY})} },
    { name: 'Invoices Credited', condition: 'invoice_credited',AnalyticsEvent:()=>{AnalyticsEvent(eventsNames.categories.CREDIT_NOTES,{action:eventsNames.actions.creditNote.action.CREDITS_APPLIED})} },
  ]

  const [sendMail, setSendMail] = React.useState(false);

  React.useEffect(()=>{
    if (tabs[tab].AnalyticsEvent) {
      tabs[tab].AnalyticsEvent()
    }
  },[tab])
  return (
    previewData && basicInformation &&
    <div>
      <CustomDialog
        state={state}
        setState={setState}
      />
      <CommonPreviewShare
        open={sendMail}
        type={'credit_note'}
        id={basicInformation.id}
        file_key={'credit_note_number'}
        export_Url={export_credit_note}
        retrieve_Url={retrieve_credit_note}
        setOpen={(value) => { setSendMail(value); retrieveCreditNote() }}
      />
      <CustomTitleContainer>

        <Grid container spacing={0} style={{
          alignItems: 'center',
          justifyItems: 'center',
          alignContent: 'space-evenly',
          justifyContent: 'space-evenly',
        }} >
          <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'start', alignItems: 'center' }}>
            <CustomTitle title={basicInformation.credit_note_number} />
            <Status status={basicInformation.status} sx={{ ml: 1, }} />
          </Grid>

          <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'end', }}>
            <ViewPageActionButtons
              isEditable={basicInformation.status !== 'closed'}
              edit={() => onSelectAction("edit", basicInformation)}
              print={() => onSelectAction("print", basicInformation)}
              download={() => onSelectAction("download", basicInformation)}

              actionsList={
                [
                  basicInformation.status !== 'draft' & basicInformation.status !== 'unsent' && { name: 'Resend', onClick: () => { onSelectAction("send", basicInformation) } },
                  basicInformation.status === 'unsent' && { name: 'Mark as sent', onClick: () => { onSelectAction("mark_sent", basicInformation);} },
                  // { name: 'View Journal Entry', onClick: () => viewJournalEntryHandler(basicInformation.journal_entry) },
                  { name: 'Delete', showDivider: true, onClick: () => { stateChangeHandler('Delete Credit Note', 'delete', 'sm', delete_credit_note(basicInformation.id), `The credit note will be deleted and can not be retrieved later.\n Are you sure about deleting it?`); AnalyticsEvent(eventsNames.categories.CREDIT_NOTES,{action:eventsNames.actions.DELETE})} }
                ]
              }
            />
            <AuditTrailHistory
              data={{ object_id: basicInformation.id, object_type: 'credit_note' }}
              AnalyticsEvent={()=>{AnalyticsEvent(eventsNames.categories.CREDIT_NOTES,{action:eventsNames.actions.HISTORY})}}
            />
          </Grid>
        </Grid>
      </CustomTitleContainer>

      <CustomContainer maxWidth={"400"} sx={{ height: 'calc(100vh - 134px)' }}>
        <Box sx={{
          p: 2,
          border: '1px solid #FFF0C0', backgroundColor: "#FFF7DC"
        }}>
          <Grid container sx={{ mb: 1 }}>
            <Grid item xs={12} sm={6} sx={{ pl: 0, pb: 1, borderRight: '1px solid #F1D881' }}>
              <CustomTypography
                text={<span>Associated Invoice:</span>}
                sx={{
                  mb: 2,
                  color: '#7A7A7A',
                  fontSize: '14px',
                  fontWeight: '600',
                  lineHeight: '19px',
                  fontStyle: 'normal',
                  textTransform: 'none',
                  fontFamily: "Noto Sans",
                }}
              />
              <Box sx={{ display: 'flex' }}>
              <InvoiceViewLink style={{
                  fontSize: '14px',
                  lineHeight: '0px',
                  fontWeight: '700',
                }} id={basicInformation.against_invoice_id} title={basicInformation.against_invoice_number} />
              </Box>
            </Grid>
            {
              toActionText(basicInformation.status) &&
              <Grid item xs={12} sm={6} sx={{ pl: 1.5, borderLeft: '1px solid #F1D881' }}>
                <CustomTypography
                  text={<span>Action:</span>}
                  sx={{
                    mb: 2,
                    color: '#7A7A7A',
                    fontSize: '14px',
                    fontWeight: '600',
                    lineHeight: '19px',
                    fontStyle: 'normal',
                    textTransform: 'none',
                    fontFamily: "Noto Sans",
                  }}
                />
                <Box sx={{ display: 'flex' }}>
                  <CustomTypography
                    id={'action_status_btn'}
                    dataTestId={'action_status_btn'}
                    text={toActionText(basicInformation.status) && toActionText(basicInformation.status)}
                    sx={{
                      color: '#2464EB',
                      fontSize: '14px',
                      cursor: 'pointer',
                      lineHeight: '0px',
                      display: 'inline',
                      fontWeight: '700',
                      fontStyle: 'normal',
                      textAlign: 'center',
                      fontFamily: "Noto Sans",
                      ":hover": { textDecoration: 'underline' }
                    }}
                    onClick={() => onClickActionText(basicInformation.status, basicInformation)}
                  />
                </Box>
              </Grid>
            }

          </Grid>
        </Box>

        <Box sx={{}}>
          <CustomTabs
            id={'tabs_'}
            dataTestId={'tabs_'}
            tabs={tabs}
            value={tab}
            onChange={(event, tab) => {
              setTab(tab)
            }}
          />
          {tabs[tab].condition === 'preview' && <PreviewScreen previewData={previewData} basicInformation={basicInformation} />}
          {tabs[tab].condition === 'refund_history' && <RefundHistoryScreen basicInformation={basicInformation} dispatch={dispatch} state={state} setState={setState} />}
          {tabs[tab].condition === 'invoice_credited' && <InvoiceCreditedScreen basicInformation={basicInformation} dispatch={dispatch} state={state} setState={setState} />}
        </Box>
      </CustomContainer>
    </div>
  )
}

export default CreditNoteViewpage;

const PreviewScreen = (props) => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Box sx={{ pt: 2 }}>
        <HTMLViewer html_string={props.previewData.html_string} />
      </Box>
      <CommonJournalEntryTransactionView id={props.basicInformation.journal_entry} />
    </React.Fragment>
  )
}

const RefundHistoryScreen = (props) => {
  const { basicInformation, dispatch, state, setState } = props;

  let navigate = useNavigate();
  const [page, setPage] = React.useState(1);
  const [results, setResults] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    next: undefined,
    count: '0',
    previous: undefined,
    number_of_pages: undefined,
  });

  const stateChangeHandler = (title, condition, maxWidth, id, item, url, deleteMessage) => {
    setState({
      id: id,
      url: url,
      open: true,
      item: item,
      title: title,
      fullWidth: true,
      maxWidth: maxWidth,
      condition: condition,
      deleteMessage: deleteMessage
    })
  };


  React.useEffect(() => {
    const getCustomerInvoice = async () => {
      setLoader(dispatch, Actions, true);
      setFilterMessage(dispatch, Actions, null);
      let res = await apiAction({
        method: 'post',
        url: get_list_of_refund_payment(page),
        data: { business_id: getBusinessInfo().id, credit_note_id: basicInformation.id, },
      })
      if (res?.success) {
        setPagination({
          ...pagination,
          next: res?.result.next,
          count: res?.result.count,
          previous: res?.result.previous,
          number_of_pages: res?.result.number_of_pages,
        });
        setResults(res?.result.result);
        setLoader(dispatch, Actions, false);
        setFilterMessage(dispatch, Actions, 'No refund history recorded.\n Once you refunded the amount you will be able to view it here.');
      } else {
        setLoader(dispatch, Actions, false);
      }
    }
    if (!state.open && basicInformation) {
      getCustomerInvoice()
    }
  }, [page, state.open]);
  return (
    <React.Fragment>
      <CustomDialog
        state={state}
        setState={setState}
      />
      <CustomTableContainer sx={{ pt: 2 }}>
        <CustomTable>
          <CustomTableHead>
            <CustomTableRow>
              <CustomTableHeadCell ><span >Date</span></CustomTableHeadCell>
              <CustomTableHeadCell ><span >Payment Mode</span></CustomTableHeadCell>
              <CustomTableHeadCell align='right' ><span >Amount Refunded</span></CustomTableHeadCell>
              <CustomTableHeadCell sx={{}} align='right' />
            </CustomTableRow>
          </CustomTableHead>

          <CustomTableBody>
            {
              results.map((item, index) => {
                return (
                  <CustomTableRow key={index}>
                    <CustomTableBodyCell sx={{}}  ><span>{moment(item.refund_date).format(getDateFormat())}</span></CustomTableBodyCell>
                    <CustomTableBodyCell sx={{}}  ><span>{item.payment_method}</span></CustomTableBodyCell>
                    <CustomTableBodyCell sx={{}} align='right'><span>{<CurrencyFormatter amount={item.total_refund_amount} currency={item.currency_code} />}</span></CustomTableBodyCell>
                    <CustomTableBodyCell sx={{}} align='right'>
                      <ListItemActions
                        index={index}
                        actions={
                          [
                            // {
                            //   name: 'Edit', onClick: () => {
                            //     { }
                            //     // item.payment_type === "pay" ?
                            //     //   navigate(routesName.invoicingPaymentsMadeRefund.path + "?refund_id=" + item.id, { replace: false })
                            //     //   :
                            //     //   navigate(routesName.invoicingPaymentsReceivedRefund.path + "?refund_id=" + item.id, { replace: false })
                            //   }
                            // },
                            null,
                            { name: 'Delete', showDivider: true, onClick: () => { stateChangeHandler('Delete Refund History', 'delete', 'sm', item.id, item, delete_refund_payment(item.id), `Are you sure about deleting the selected refund history?`) } }
                          ]
                        }
                      />
                    </CustomTableBodyCell>
                  </CustomTableRow>
                )
              })
            }
          </CustomTableBody>
        </CustomTable>
        <Box sx={{ pt: !results.length ? 15 : 0 }} >
          <NoDataComponent left='0%' top='0%' position={'relative'} data={results} />
        </Box>
      </CustomTableContainer>
      {
        results.length ?
          <CustomPagination
            page={page}
            count={pagination.number_of_pages}
            onChange={(event, newValue) => setPage(newValue)}
          />
          : null
      }
    </React.Fragment>
  )
}

const InvoiceCreditedScreen = (props) => {
  const { basicInformation, dispatch, state, setState } = props;

  const [results, setResults] = React.useState([]);

  const stateChangeHandler = (title, condition, maxWidth, id, item, url, deleteMessage) => {
    setState({
      id: id,
      url: url,
      open: true,
      item: item,
      title: title,
      fullWidth: true,
      maxWidth: maxWidth,
      condition: condition,
      deleteMessage: deleteMessage
    })
  };
  React.useEffect(() => {
    const getCustomerInvoice = async () => {
      setLoader(dispatch, Actions, true);
      setFilterMessage(dispatch, Actions, null);
      let res = await apiAction({
        method: 'post',
        url: list_of_credited_invoices(),
        data: { business_id: getBusinessInfo().id, credit_note_id: basicInformation.id, },
      })
      if (res?.success) {
        setResults(res?.result);
        setLoader(dispatch, Actions, false);
        setFilterMessage(dispatch, Actions, 'No invoices credit recorded.\n Once you credited the invoice you will be able to view it here.');
      } else {
        setLoader(dispatch, Actions, false);
      }
    }
    if (!state.open && basicInformation) {
      getCustomerInvoice()
    }
  }, [state.open]);
  return (
    <React.Fragment>
      <CustomDialog
        state={state}
        setState={setState}
      />
      <CustomTableContainer sx={{ pt: 2 }}>
        <CustomTable>
          <CustomTableHead>
            <CustomTableRow>
              <CustomTableHeadCell ><span >Date</span></CustomTableHeadCell>
              <CustomTableHeadCell ><span >Invoice Number</span></CustomTableHeadCell>
              <CustomTableHeadCell align='right' ><span >Credit Amount Applied</span></CustomTableHeadCell>
              <CustomTableHeadCell align='right' />
            </CustomTableRow>
          </CustomTableHead>
          <CustomTableBody>
            {
              results.map((item, index) => {
                return (
                  <CustomTableRow key={index}>
                    <CustomTableBodyCell sx={{}}  ><span>{moment(item.invoice_date).format(getDateFormat())}</span></CustomTableBodyCell>
                    {/* <CustomTableBodyCell sx={{}}  ><span>{item.invoice_number}</span></CustomTableBodyCell> */}
                    <CustomTableBodyCell sx={{}}  ><InvoiceViewLink id={item.invoice_id} title={item.invoice_number}/></CustomTableBodyCell>
                    <CustomTableBodyCell sx={{}} align='right'><span>{<CurrencyFormatter amount={item.credit_amount_applied} currency={item.currency_code} />}</span></CustomTableBodyCell>
                    <CustomTableHeadCell align='right' >
                      <IconButton size='small' id={'delete_applied_btn' + index} dataTestId={'delete_applied_btn' + index} onClick={() => { stateChangeHandler('Delete Credits Applied', 'delete', 'sm', item.id, item, delete_credits_applied_to_invoice(item.id), `Are you sure you want to delete the credits applied to Invoice?`) }}>
                        <DeleteIcon fontSize="small" sx={{ color: 'grey' }} />
                      </IconButton>
                    </CustomTableHeadCell>
                  </CustomTableRow>
                )
              })
            }
          </CustomTableBody>
        </CustomTable>
        <Box sx={{ pt: !results.length ? 15 : 0 }} >
          <NoDataComponent left='0%' top='0%' position={'relative'} data={results} />
        </Box>
      </CustomTableContainer>
    </React.Fragment>
  )
}