import React from 'react';
import { apiAction } from '../../../../../api/api';
import { useIsElementVisible } from '../common/Helper';
import { CustomSkeleton } from '../common/CustomComponents';
import { evaluate_data_point } from '../../../../../api/urls';
import CurrencyFormatter from '../../../../common/CurrencyFormatter';
import { HtmlTooltip } from '../../../../custom/tooltip/CustomTooltip';
import CustomTypography from '../../../../custom/typography/CustomTypography';
import { isNumeric, numberFormatterWithoutSymbol } from '../../../../../utils/Utils';

const EvaluateDataPoint = (props) => {
    const { unit, data_point_id, from_date, to_date, refreshDataPointAll, refreshDataPoint } = props;

    const elementRef = React.useRef(null);
    const [result, setResult] = React.useState(0);
    const [message, setMessage] = React.useState(null);
    const [isLoader, setIsLoader] = React.useState(true);
    const [firstCall, setFirstCall] = React.useState(true);
    const [isItemVisible, setIsItemVisible] = React.useState(false);

    const evaluateDataPoint = async (url) => {
        setIsLoader(true);
        const res = await apiAction({
            url: url ? url : evaluate_data_point(data_point_id, from_date, to_date)
        })
            .then((value) => {
                setIsLoader(false);
                if (value.success) {
                    setFirstCall(false);
                    setResult(value.result);
                    setMessage(value.tooltip_message)
                } else {
                    setFirstCall(false);
                }
            })
            .catch((error) => {
                setIsLoader(false);
                setFirstCall(false);
                console.log('====>error', data_point_id, ':', error)
            })

    }
    const isElementVisible = useIsElementVisible(elementRef.current);

    React.useEffect(() => {
        if (isElementVisible) {
            setIsItemVisible(true)
        }

    }, [isElementVisible]);

    React.useEffect(() => {
        if (isItemVisible) {
            evaluateDataPoint();
        }
    }, [data_point_id, from_date, to_date, isItemVisible])

    React.useEffect(() => {
        if (!firstCall && isItemVisible) {
            evaluateDataPoint(`${evaluate_data_point(data_point_id, from_date, to_date)}&is_refresh=${true}`);
        }
    }, [refreshDataPointAll, refreshDataPoint])


    return (
        <div ref={elementRef} id={"evaluate_data_point_" + data_point_id}>
            {
                isLoader ?
                    <CustomSkeleton variant="text" sx={{ mr: 1, height: 40, width: 80 }} />
                    :
                    result === null ?
                        <HtmlTooltip title={message} placement="right">
                            <span style={{
                                color: '#000',
                                fontSize: '12px',
                                cursor: 'pointer',
                                fontWeight: '700',
                                fontStyle: 'normal',
                                lineHeight: 'normal',
                                fontFamily: 'Noto Sans',
                            }}>
                                N/A
                            </span>
                        </HtmlTooltip>
                        :
                        <CustomTypography
                            text={
                                unit === 'base_currency' ?
                                    <CurrencyFormatter amount={result} />
                                    : unit === 'percentage' ?
                                        numberFormatterWithoutSymbol(null, isNumeric(result) ? Number(result) : 0,) + ' %'
                                        :
                                        numberFormatterWithoutSymbol(null, isNumeric(result) ? Number(result) : 0,)

                            }
                            sx={{
                                color: '#000',
                                fontSize: '12px',
                                cursor: 'pointer',
                                fontWeight: '700',
                                fontStyle: 'normal',
                                whiteSpace: 'nowrap',
                                lineHeight: 'normal',
                                fontFamily: 'Noto Sans',
                            }}
                        />
            }
        </div>
    )
}

export default EvaluateDataPoint
