import React from 'react'
import { useNavigate } from 'react-router-dom';
import { apiAction } from '../../../../../api/api';
import * as Actions from '../../../../../state/Actions';
import { delete_journal_entry } from '../../../../../api/urls';
import { stateChangeManager } from '../../../../../utils/Utils';
import CustomButton from '../../../../custom/button/CustomButton';
import { getBusinessInfo } from '../../../../../config/cookiesInfo';
import CustomTypography from '../../../../custom/typography/CustomTypography';


const DeleteJournalEntry = (props) => {

    let navigation = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);

    const { id, onClose, url, navigate, onDeleteAction, deleteMessage } = props

    const onClickAgree = async (id) => {
        let res = await apiAction({
            method: 'delete',
            dispatch: dispatch,
            navigate: navigation,
            url: url ? url : delete_journal_entry(id),
            data: { business_id: getBusinessInfo().id }
        })
        if (res?.success) {
            onClose();
            if (navigate) { navigate() };
            if (onDeleteAction) { onDeleteAction() };
            stateChangeManager(dispatch, Actions, true, 'success', res?.status)
        } else {
            stateChangeManager(dispatch, Actions, true, 'error', res?.status)

        }
    }
    return (
        <div>
            <CustomTypography
                text={deleteMessage ? deleteMessage : 'Are you sure you want to delete?'}
                sx={{
                    fontSize: '14px',
                    color: '#000000',
                    fontWeight: '700',
                    lineHeight: '18px',
                    fontStyle: 'normal',
                    textTransform: "none",
                    fontFamily: "Noto Sans",
                }}
            />
            <div style={{ textAlign: 'center', marginTop: '24px' }}>
                <CustomButton
                    btnLabel='Keep It'
                    variant='outlined'
                    onClick={onClose}
                    sx={{ textTransform: 'none', '&:hover': { backgroundColor: '#e8f3ff' } }}
                />
                <CustomButton
                    btnLabel='Yes, Delete'
                    variant='contained'
                    onClick={() => onClickAgree(id)}
                    sx={{ ml: 2, textTransform: 'none', }}
                />
            </div>
        </div>
    )
}

export default DeleteJournalEntry