import React from 'react';
import moment from 'moment/moment';
import { apiAction } from '../../../../../api/api';
import * as Actions from '../../../../../state/Actions';
import CreateSalesOrder from '../order/CreateSalesOrder';
import {useNavigate, useLocation } from 'react-router-dom';
import { routesName } from '../../../../../config/routesName';
import { getBusinessInfo } from '../../../../../config/cookiesInfo';
import { isBusinessRegistered, getQueryParams } from '../../../../../utils/Utils';

import { 
  get_create_estimate_meta_data,
 } from '../../../../../api/urls';

const CreateEstimate = (props) => {
  const { onClose } = props;

  let location = useLocation();
  const navigate = useNavigate();
  const params = getQueryParams(location.search)
  const dispatch = Actions.getDispatch(React.useContext);

  const estimate_initial_data = {
    expiry_date: null,
    estimate_items: [],
    estimate_number: '',
    estimate_date: moment().format('YYYY-MM-DD'),
    customer_emails: {
      cc: [],
      to: [],
      bcc: []
  },
  }

  const [estimateData, setEstimateData] = React.useState({ ...estimate_initial_data })
  const redirect = (id) => {
    if (onClose) {
        onClose(id)
    } else {
        navigate(routesName.invoicingSalesEstimateView.path+ "?id=" + id)
    }
}


const gst_registration_type = estimateData.customer && estimateData.customer.gst_registration_type;

let is_Location = gst_registration_type === 'overseas'

const {tax_applied, estimate_items, customer_emails } = estimateData
const { to } = customer_emails

const is_estimate_item_not_selected = estimate_items.find((item) => !item.item_id)
const is_rate = estimate_items.find((item) => item.rate===null);
const is_quantity = estimate_items.find((item) => !item.quantity);
const is_tax = estimate_items.find((item) => tax_applied !== "no_tax" && !item.tax_id);

let validation_data = [
  { key: "customer_id", message: 'Please Select Customer' },
  // { key: '', validation: to.length === 0, message: 'Please enter valid email.' },
  { key: "estimate_number", message: 'Please Enter Estimate Number' },
  isBusinessRegistered()&&!is_Location&&{ key: "place_of_supply_id", message: 'Please Select Place of Supply' },
  { key: "estimate_date", message: 'Please Enter Valid Estimate Date' },
  { key: "", validation: is_estimate_item_not_selected, message: 'Please Select Item' },
  { key: "", validation: is_quantity, message: 'Please Enter the Quantity' },
  { key: "", validation: is_rate, message: 'Please Enter the Rate' },
  { key: "", validation: is_tax, message: 'Please Select the Tax' },
]

  
  React.useEffect(() => {
    const apiResults = async () => {
      let res = await apiAction({
        method: 'post',
        url: get_create_estimate_meta_data(),
        data: { business_id: getBusinessInfo().id },
      })
      if (res?.success) {
        estimateData.estimate_number = res?.result.estimate_number
        setEstimateData({ ...estimateData, estimate_number: res?.result.estimate_number })
      } else {

      }
    }
    if (!params.id) {  
      apiResults()
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div>
      <CreateSalesOrder
        isEstimate={true}
        onClose={redirect}
        estimateData={estimateData}
        setEstimateData={setEstimateData}
        estimate_validation_data={validation_data}
      />
    </div>
  )
}

export default CreateEstimate;

