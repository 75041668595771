import React from 'react';
import { useNavigate } from 'react-router-dom';
import CustomTitle from '../../common/CustomTitle';
import { routesName } from '../../../config/routesName';
import CustomButton from '../../custom/button/CustomButton';
import CustomTypography from '../../custom/typography/CustomTypography';

import {
    CustomContainer,
    CustomTitleContainer,
} from '../../custom/container/CustomContainer';

import {
    Box,
    Grid,
} from '@mui/material';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

const GSTFilingLandingpage = () => {
    const navigate = useNavigate();

    const GST_List = [
        {
            name: 'GSTR-1 ',
            logo: DescriptionOutlinedIcon,
            onClick: () => { navigate(routesName.gstr1FilingComponent.path) }
        },
        {
            name: 'GSTR-3B Summary',
            logo: DescriptionOutlinedIcon,
            onClick: () => { navigate(routesName.gstr3BFilingComponent.path) }

        }
    ]
    return (
        <div>
            <CustomTitleContainer>
                <CustomTitle title={'GSTR Summary'} />
            </CustomTitleContainer>

            <CustomContainer maxWidth={"400"} sx={{ height: 'calc(100vh - 134px)', boxShadow: 'none', background: '#FAFAFA', paddingTop: '0px !important', }}>
                {GST_List.map((item, index) => {
                    return (
                        <React.Fragment key={index}>
                            <GstFilingList data={item} id={'click_btn' + index} dataTestId={'click_btn' + index} />
                        </React.Fragment>
                    )
                })}
            </CustomContainer>
        </div>
    )
}

export default GSTFilingLandingpage;

const GstFilingList = (props) => {
    const { id, dataTestId, data } = props;
    return (
        <Box sx={{ m: 1, border: '2px solid #F5F5F5', alignItems: 'center', }}>
            <Grid container spacing={0} sx={{ p: 2 }}>
                <Grid item xs={6} sx={{ alignItems: 'center', }}>
                    <Box sx={{ display: 'flex', }}>
                        <data.logo fontSize='large' />
                        <Box sx={{ ml: 2.5, alignSelf: "center" }}>
                            <CustomTypography
                                text={data.name}
                                sx={{
                                    color: '#000000',
                                    fontSize: '16px',
                                    fontWeight: '700',
                                    lineHeight: '18px',
                                    fontStyle: 'normal',
                                    fontFamily: "Noto Sans",
                                }}
                            />
                        </Box>
                    </Box>

                </Grid>
                <Grid item xs={6} sx={{ textAlign: 'end' }}>
                    <CustomButton
                        id={id}
                        dataTestId={dataTestId}

                        variant="contained"
                        btnLabel='View Summary'
                        sx={{ textTransform: 'none', }}
                        onClick={() => { data.onClick() }}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}