import moment from 'moment';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiAction } from '../../../../api/api';
import DateRange from '../../../common/DateRange';
import { images } from '../../../../utils/Constant';
import * as Actions from '../../../../state/Actions';
import CustomTitle from '../../../common/CustomTitle';
import CustomTabs from '../../../custom/tabs/CustomTabs';
import ListItemActions from '../../../common/ListItemActions';
import CustomDialog from '../../../custom/dialog/CustomDialog';
import { getBusinessInfo } from '../../../../config/cookiesInfo';
import CurrencyFormatter from '../../../common/CurrencyFormatter';
import { NoDataComponent } from '../../../common/NoDataComponent';
import { BadgeWithTitle } from '../../../custom/badge/CustomBadge';
import { HeadingWithSortable } from '../../../common/SortableHeading';
import CustomTypography from '../../../custom/typography/CustomTypography';
import CustomPagination from '../../../custom/pagination/CustomPagination';

import {
  setLoader,
  page_count,
  dateFormatter,
  getDateFormat,
  stateChangeManager,
} from '../../../../utils/Utils';

import {
  CustomContainer,
  CustomTitleContainer,
} from '../../../custom/container/CustomContainer';

import {
  delete_journal_entry,
  update_journal_entry,
  get_chart_of_account,
  list_sourced_transactions,
  delete_sourced_transaction,
  create_sourced_transaction_preference,
  update_sourced_transaction_preference,
  list_sourced_transaction_preference,
  delete_sourced_transaction_preference,
  bulk_create_journal_entry_from_sourced_transaction,
} from '../../../../api/urls';

import {
  Box,
  Grid,
  Checkbox,
  IconButton,
  FormControlLabel,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';


import {
  CustomTable,
  CustomTableRow,
  CustomTableBody,
  CustomTableHead,
  CustomTableBodyCell,
  CustomTableHeadCell,
  CustomTableContainer,
} from '../../../custom/table/CustomTable';
import CustomButton from '../../../custom/button/CustomButton';
import SaveMapping from '../mappings/SaveMapping';
import CommonSearch from '../../../common/CommonSearch';
import CommonAlertBox from '../../invoicing/common/CommonAlertBox';


const Dropdown = DateRange;
const SourceTransactions = () => {

  let navigate = useNavigate();
  const dispatch = Actions.getDispatch(React.useContext)

  const [page, setPage] = React.useState(1);
  const [value, setValue] = React.useState(0);
  const [reLoad, setReLoad] = React.useState(false)
  const [results, setResults] = React.useState([])
  const [screenVisible, setScreenVisible] = React.useState(false)
  const [chartOfAccounts, setChartOfAccounts] = React.useState([])
  const [open, setOpen] = React.useState(false)
  const [alert, setAlert] = React.useState(false)
  const [isFilter, setFilter] = React.useState(false);
  const [preferences, setPreferences] = React.useState([])
  const [preference, setPreference] = React.useState(null)

  const [pagination, setPagination] = React.useState({
    next: undefined,
    count: undefined,
    previous: undefined,
    number_of_pages: undefined,
  })

  const tabs = [
    { name: value == 0 ? <BadgeWithTitle title={'Uncategorized'} count={pagination.count} /> : 'Uncategorized', transaction_type: 'unprocessed' },
    { name: value == 1 ? <BadgeWithTitle title={'Categorized'} count={pagination.count} /> : 'Categorized', transaction_type: 'journal_entry' },
    { name: value == 2 ? <BadgeWithTitle title={'Draft'} count={pagination.count} /> : 'Draft', transaction_type: 'draft' },
    { name: value == 3 ? <BadgeWithTitle title={'Contra'} count={pagination.count} /> : 'Contra', transaction_type: 'contra' }
  ]

  const [selectionData, setSelectionData] = React.useState({
    business_id: getBusinessInfo().id,
    sourced_transaction_id_list: [],
    debit_account_id: null,
    credit_account_id: null,
    description: "",
    is_credit: null,
    is_debit: null,
    name: "",
    note: "",
    transaction_type: tabs[value].transaction_type,
    selected_transaction_type: null,
  })
  const [filters, setFilters] = React.useState({
    sort_order: 'D',
    sort_by: 'transaction_date',

  })

  const [state, setState] = React.useState({
    id: '',
    title: '',
    open: false,
    condition: '',
    maxWidth: 'lg',
    fullWidth: true,
  })

  const getApiResults = async (body) => {
    setLoader(dispatch, Actions, true)
    let res = await apiAction({
      method: 'post',
      navigate: navigate,
      dispatch: dispatch,
      url: list_sourced_transactions(page),
      data: { ...body, ...filters },
    })

    if (res?.success) {
      // console.log('res===>', body, res)
      setPagination({
        ...pagination,
        next: res?.result.next,
        count: res?.result.count,
        previous: res?.result.previous,
        number_of_pages: res?.result.number_of_pages,
      })
      setScreenVisible(true);
      setResults(res?.result.result)
      setLoader(dispatch, Actions, false)
      // setSelectionData({...selectionData, sourced_transaction_id_list : [] })

    } else {
      setScreenVisible(true);
      setLoader(dispatch, Actions, false)
      stateChangeManager(dispatch, Actions, true, 'error', res?.status)
    }
  }
  // let body = {
  //   business_id: getBusinessInfo().id,
  //   transaction_type: tabs[value].transaction_type
  // }

  React.useEffect(() => {
    if (!state.open) {
      selectionData.transaction_type = tabs[value].transaction_type
      setFilter(true);
      getApiResults(selectionData);
    }
    // eslint-disable-next-line
  }, [page, value, reLoad])

  React.useEffect(() => {
    selectionData.transaction_type = tabs[value].transaction_type
    selectionData.sourced_transaction_id_list = []
    setSelectionData({ ...selectionData })
    if (isFilter) {
      getApiResults(selectionData)
    }

    // eslint-disable-next-line
  }, [filters, selectionData.description, selectionData.selected_transaction_type])


  ///////////////////////////////////////////////add view and delete///////////////////////////////////////////////////////////////////////////////////////////////

  const addHandler = (id) => {
    setState({
      id: id,
      open: true,
      maxWidth: 'lg',
      fullWidth: true,
      title: 'Add Journal Entry',
      condition: 'source_transaction_journalEntry',
      onAddTransactionAction: () => { getApiResults(selectionData); }
    })
  }

  const viewHandler = (id) => {
    setState({
      id: id,
      open: true,
      maxWidth: 'sm',
      fullWidth: true,
      condition: 'view',
      title: 'View Journal Entry',
    })
  }

  const updateHandler = (id) => {
    setState({
      id: id,
      open: true,
      maxWidth: 'lg',
      fullWidth: true,
      condition: 'update',
      currencyDisabled: true,
      title: 'Update Journal Entry',
    })
  }

  const categorizedHandler = () => {
    setState({
      open: true,
      maxWidth: 'lg',
      fullWidth: true,
      selectionData: selectionData,
      title: 'Add Categorization Rule',
      chartOfAccounts: chartOfAccounts,
      condition: 'preference_categorized',
      setChartOfAccounts: setChartOfAccounts,
      fetchPreferences: () => fetchPreferences(),
      fetchChartOfAccounts: () => fetchChartOfAccounts(),
      categorizeTransactions: (body) => categorizeTransactions(body),
    })
  }

  const approveHandler = async (item) => {
    setLoader(dispatch, Actions, true)
    const approveHandlerRes = await apiAction({ url: update_journal_entry(item.journal_entry), navigate: navigate, dispatch: dispatch, method: 'POST', data: { is_posted: true, business_id: getBusinessInfo().id } })
    if (approveHandlerRes.success) {
      setReLoad(!reLoad)
      setLoader(dispatch, Actions, false)
      stateChangeManager(dispatch, Actions, true, 'success', approveHandlerRes.status);
    } else {
      setLoader(dispatch, Actions, false)
      stateChangeManager(dispatch, Actions, true, 'error', approveHandlerRes.status);

    }
  }

  const deleteHandler = (id) => {
    setState({
      id: id,
      open: true,
      maxWidth: 'xs',
      fullWidth: true,
      condition: 'delete',
      onDeleteAction: () => { getApiResults(selectionData); },
      title: value ? 'Delete Journal Entry' : 'Delete Source Transaction',
      url: value ? delete_journal_entry(id) : delete_sourced_transaction(id),
    })
  }

  React.useEffect(() => {
    fetchPreferences();
    fetchChartOfAccounts();
  }, [])

  const fetchPreferences = async () => {
    const preferenceRes = await apiAction({
      method: 'get',
      navigate: navigate,
      dispatch: dispatch,
      url: list_sourced_transaction_preference(),
    })

    if (preferenceRes.success) {
      setPreferences(preferenceRes.result)
    }

  }
  const fetchChartOfAccounts = async () => {
    let res = await apiAction({
      method: 'post',
      navigate: navigate,
      dispatch: dispatch,
      data: { business_id: getBusinessInfo().id },
      url: get_chart_of_account(),

    })
    // console.log('res',res)
    if (res?.success) {
      setChartOfAccounts(res?.result)
      // setSelectionData({...selectionData, chartOfAccounts: res?.result})
    } else {

    }
  }

  const onClose = () => {
    setOpen(false)
  }

  const onSubmit = (name, description) => {
    setOpen(false)
    onPreferenceAction(name, description)
  }

  const onPreferenceAction = async (name, description) => {
    const preferenceRes = await apiAction({
      navigate: navigate,
      dispatch: dispatch,
      method: preference ? 'put' : 'post',
      data: preference ? { ...selectionData, name: name, description: description, id: preference.id } : { ...selectionData, name: name, description: description },
      url: preference ? update_sourced_transaction_preference(preference.id) : create_sourced_transaction_preference(),
    })
    if (preferenceRes.success) {
      setPreference(preferenceRes.result)
    }

  }

  const onDeletePreference = async () => {
    if (preference) {
      const preferenceRes = await apiAction({
        method: 'delete',
        navigate: navigate,
        dispatch: dispatch,
        data: preference,
        url: delete_sourced_transaction_preference(preference.id),
      })

      if (preferenceRes.success) {
        setPreference(null)
      }
    }
  }


  const categorizeTransactions = async (body) => {
    let bulkCreationRes = await apiAction({
      data: body,
      method: 'post',
      navigate: navigate,
      dispatch: dispatch,
      url: bulk_create_journal_entry_from_sourced_transaction(),

    })

    if (bulkCreationRes.success) {
      setValue(1);
      setPreference(null);
      setState({ open: false, condition: "preference_categorized" });
      stateChangeManager(dispatch, Actions, true, "success", bulkCreationRes.status)
      setSelectionData({ ...selectionData, description: "", selected_transaction_type: null, is_credit: false, is_debit: false, preference_id: null, sourced_transaction_id_list: [] })
    } else {
      stateChangeManager(dispatch, Actions, true, "error", bulkCreationRes.status)
    }
  }


  return (
    <>
      <CustomDialog
        state={state}
        setState={setState}
      // onAddPreference = {onAddPreference}

      />
      <CommonAlertBox
        open={alert}
        setOpen={setAlert}
        title={`Confirmation`}
        cancelLabelText={"Review"}
        saveButtonLabel={"Continue"}
        onSaveAction={() => { categorizeTransactions(selectionData) }}
        message={`Please note that the reversal of these entries will be possible manually.`}
      />
      {open && <SaveMapping open={open} onClose={onClose} onSubmit={onSubmit} mapping={preference} isNewMapping={preference ? false : true} />}
      <CustomTitleContainer>
        <Grid container spacing={0} style={{
          alignItems: 'center',
          justifyItems: 'center',
          alignContent: 'space-evenly',
          justifyContent: 'space-evenly',
        }}>
          <Grid item xs={6} sm={6} sx={{ display: '-webkit-flex', justifyContent: 'start', }}>
            <CustomTitle title={'List of Source Transactions'} />
          </Grid>
          <Grid item xs={6} sm={6} sx={{ display: '-webkit-flex', justifyContent: 'end', }}>
            <CustomButton
              id={''}
              dataTestId={''}
              variant="contained"
              btnLabel='Source Transactions'
              sx={{ ml: 2, visibility: 'hidden' }}
              onClick={() => { }}
            />
          </Grid>
        </Grid>
      </CustomTitleContainer>

      <CustomContainer maxWidth={"400"} sx={{}}>
        {
          value === 0 &&
          <div style={{ display: '-webkit-flex', paddingLeft: '16px', paddingRight: '16px', paddingBottom: '16px', }}>
            <Grid container sx={{}} spacing={1} style={{
              alignItems: 'center',
              justifyItems: 'center',
              alignContent: 'space-evenly',
            }}>
              <Grid container spacing={1} xs={12} sm={8} item sx={{ display: '-webkit-flex', justifyContent: 'start', }}>
                <Grid item xs={12} sm={4}>
                  <CommonSearch
                    id={'input_search_description'}
                    dataTestId={'input_search_description'}
                    fullWidth={true}
                    title={'Description'}
                    // sx={{ mr: 2, width: 400 }}
                    disabled={preference !== null}
                    placeholder={'Search by Description'}
                    onSearchValue={selectionData.description}
                    onSearch={(search_text) => {
                      let transaction_type_credit = selectionData.selected_transaction_type && selectionData.selected_transaction_type.name === 'CREDIT';

                      if (search_text) {
                        selectionData.description = search_text;
                        selectionData.is_debit = !Boolean(transaction_type_credit);
                        selectionData.is_credit = Boolean(transaction_type_credit);
                        selectionData.selected_transaction_type = selectionData.selected_transaction_type || { name: 'DEBIT' };
                      } else {
                        selectionData.is_debit = null;
                        selectionData.is_credit = null;
                        selectionData.description = search_text;
                        selectionData.selected_transaction_type = null;
                      }
                      setPage(1);
                      setSelectionData({ ...selectionData });
                    }}

                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Dropdown
                    id={'transaction_typ_dropdown'}
                    dataTestId={'transaction_typ_dropdown'}
                    label="Transaction Type"
                    item={{
                      // sx: { mr: 2, width: 300 },
                      disabled: preference !== null
                    }}

                    value={selectionData.selected_transaction_type}
                    placeholder='Select Transaction Type'
                    results={[{ name: 'DEBIT' }, { name: 'CREDIT' }]}
                    setValue={(event, value) => {
                      setPage(1);
                      if (value) {
                        if (value.name === "DEBIT") {
                          setSelectionData({ ...selectionData, selected_transaction_type: value, is_credit: false, is_debit: true })
                          // getApiResults({...selectionData, selected_transaction_type:value, is_credit:false, is_debit:true})
                        } else {
                          setSelectionData({ ...selectionData, selected_transaction_type: value, is_credit: true, is_debit: false })
                          // getApiResults({...selectionData, selected_transaction_type:value, is_credit:true, is_debit:false})
                        }
                      } else {
                        setSelectionData({ ...selectionData, selected_transaction_type: value, is_credit: null, is_debit: null })
                        // getApiResults({...selectionData, selected_transaction_type:value, is_credit:true, is_debit:true})
                      }

                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Dropdown
                    id={'categorization_dropdown'}
                    dataTestId={'categorization_dropdown'}
                    label="Categorization Rule"
                    item={{
                      // sx: { width: 300 }
                    }}
                    value={preference}
                    results={preferences}
                    placeholder='Select Categorization Rule'
                    setValue={(event, value) => {
                      setPage(1);
                      setPreference(value)
                      if (value) {
                        setSelectionData({ ...selectionData, preference_id: value.id, description: value.description, is_credit: value.transaction_type === "CREDIT" ? true : false, is_debit: value.transaction_type === "DEBIT" ? true : false, selected_transaction_type: { name: value.transaction_type }, })
                      } else {
                        setSelectionData({ ...selectionData, selected_transaction_type: null, preference_id: null, description: "", is_credit: true, is_debit: true })
                      }


                    }}
                  />
                </Grid>
              </Grid>

              <Grid container xs={12} sm={4} item sx={{ display: '-webkit-flex', justifyContent: 'end', mt: 2 }}>
                <CustomButton
                  sx={{ mr: 1, }}
                  btnLabel='Clear'
                  id={'clear_btn'}
                  variant="outlined"
                  dataTestId={'clear_btn'}
                  disabled={!selectionData.description && !selectionData.selected_transaction_type}
                  onClick={(e) => {
                    setPage(1);
                    setPreference(null);
                    setSelectionData({ ...selectionData, description: "", selected_transaction_type: null, is_credit: false, is_debit: false, preference_id: null })
                  }}
                />
                {!preference && <CustomButton
                  sx={{}}
                  id={'next_btn'}
                  btnLabel='Next'
                  variant="contained"
                  dataTestId={'next_btn'}
                  disabled={(!selectionData.description) || (!selectionData.selected_transaction_type) || (selectionData.sourced_transaction_id_list.length === 0)}
                  onClick={(e) => {
                    setPage(1);
                    categorizedHandler()
                  }}
                />}
                {preference && <CustomButton
                  sx={{}}
                  variant="contained"
                  id={'categorize_btn'}
                  btnLabel='Categorize'
                  dataTestId={'categorize_btn'}
                  disabled={(!selectionData.description) || (!selectionData.selected_transaction_type) || (selectionData.sourced_transaction_id_list.length === 0)}
                  onClick={(e) => { setAlert(true); setPage(1); }}
                />}
              </Grid>
            </Grid>

          </div>
        }
        <CustomTabs
          id={'tabs_'}
          dataTestId={'tabs_'}
          tabs={tabs}
          value={value}
          onChange={(event, newValue) => {
            // if (value.transaction_type==='unprocessed') {
            //   filters.sort_by='transaction_date'
            // }else{
            //   filters.sort_by='updated_at'
            // }
            // setFilters({...filters})

            setPage(1);
            setResults([]);
            setValue(newValue);
            setPreference(null);
            setScreenVisible(false);
            setSelectionData({ ...selectionData, selected_transaction_type: null, preference_id: null, description: "", is_credit: true, is_debit: true })
          }}
        />
        <CustomTableContainer >
          <CustomTable >
            <SourceTransactionsHeader
              results={results}
              filters={filters}
              setFilters={setFilters}
              selectionData={selectionData}
              setSelectionData={setSelectionData}
              status={tabs[value].transaction_type}
              selectedTabIndex={selectionData.description}
            />
            <SourceTransactionsBody
              page={page}
              setPage={setPage}
              results={results}
              pagination={pagination}
              addHandler={addHandler}
              viewHandler={viewHandler}
              page_count={page_count(page)}
              deleteHandler={deleteHandler}
              updateHandler={updateHandler}
              screenVisible={screenVisible}
              selectionData={selectionData}
              approveHandler={approveHandler}
              setSelectionData={setSelectionData}
              status={tabs[value].transaction_type}
              selectedTabIndex={selectionData.description}
            />

          </CustomTable>
          <Box sx={{ pt: !results.length ? 10 : 0 }} >
            <NoDataComponent left='0%' top='0%' position={'relative'} data={results} image={images.list_source_transaction} />
          </Box>
        </CustomTableContainer>
      </CustomContainer>
      <CustomPagination
        page={page}
        count={pagination.number_of_pages}
        onChange={(event, newValue) => setPage(newValue)}
      />
    </>
  )
}


const SourceTransactionsHeader = (props) => {
  const { filters, setFilters, selectedTabIndex, selectionData, setSelectionData, results } = props;
  let currentPagesIds = results.map((transaction) => transaction.id)
  let selectAllStatus = currentPagesIds.every(elem => selectionData.sourced_transaction_id_list.includes(elem))

  return (
    <CustomTableHead>
      <CustomTableRow>
        {selectedTabIndex &&
          <CustomTableHeadCell style={{ width: 10 }}  >
            <FormControlLabel
              id={'all_id_checkbox'}
              dataTestId={'all_id_checkbox'}
              disabled={false}
              label={<span></span>}
              checked={selectAllStatus && currentPagesIds.length !== 0}
              control={<Checkbox
                size='small'
                style={{
                  color: "#2464EB",
                }}

                onChange={(e) => {
                  console.log("Event ", e.target.checked)
                  if (e.target.checked) {
                    let newArray = selectionData.sourced_transaction_id_list.concat(currentPagesIds)
                    selectionData.sourced_transaction_id_list = [...newArray]
                  } else {
                    selectionData.sourced_transaction_id_list = selectionData.sourced_transaction_id_list.filter(n => !currentPagesIds.includes(n))
                  }
                  // console.log("All ",selectionData.sourced_transaction_id_list)
                  setSelectionData({ ...selectionData, sourced_transaction_id_list: [...new Set(selectionData.sourced_transaction_id_list)] })
                }} />}
            />
          </CustomTableHeadCell>
        }
        <CustomTableHeadCell style={{ width: 50 }}  ><span>Sr.No.</span></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 180 }}><HeadingWithSortable heading={'Date'} sortableKey={'transaction_date'} filters={filters} setFilters={setFilters} /></CustomTableHeadCell>

        <CustomTableHeadCell style={{ width: 250 }} ><span>Description</span></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 150 }} align='right'><span>Debit</span></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 150 }} align='right'><span>Credit</span></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 150 }} align='right'><span>Balance</span></CustomTableHeadCell>
        {
          !selectedTabIndex &&
          <CustomTableHeadCell style={{ width: 100 }} align='right' ><span>Actions</span></CustomTableHeadCell>
        }
      </CustomTableRow>
    </CustomTableHead>
  )
}

const SourceTransactionsBody = (props) => {
  const { page_count, status, results, addHandler, updateHandler, viewHandler, approveHandler, deleteHandler, screenVisible, selectedTabIndex, selectionData, setSelectionData } = props

  return (
    screenVisible ?
      <CustomTableBody>
        {
          results.map((result, index) => {

            return (
              <CustomTableRow key={index}>
                {selectedTabIndex &&
                  <CustomTableBodyCell sx={{}} >
                    <FormControlLabel
                      id={'id_checkbox_' + index}
                      dataTestId={'id_checkbox_' + index}
                      disabled={false}
                      label={<span></span>}
                      checked={selectionData.sourced_transaction_id_list.indexOf(result.id) > -1}
                      control={<Checkbox
                        size='small'
                        style={{
                          color: "#2464EB",
                        }}

                        onChange={(e) => {
                          let transaction_id = selectionData.sourced_transaction_id_list.indexOf(result.id)
                          if (transaction_id > -1) {
                            selectionData.sourced_transaction_id_list.splice(transaction_id, 1)
                          } else {
                            selectionData.sourced_transaction_id_list.push(result.id)
                          }
                          setSelectionData({ ...selectionData })
                        }} />}
                    />
                  </CustomTableBodyCell>
                }
                <CustomTableBodyCell sx={{}} >
                  <span>{(page_count) + (index + 1)}  </span>
                </CustomTableBodyCell>
                <CustomTableBodyCell sx={{}} ><span>{dateFormatter(result.transaction_date, getDateFormat() + ' HH:mm:ss')}</span></CustomTableBodyCell>
                <CustomTableBodyCell sx={{ fontWeight: 500, }} ><span>{result.description}</span></CustomTableBodyCell>
                <CustomTableBodyCell sx={{}} align='right'><span>{result.debit_amount ? <CurrencyFormatter amount={result.debit_amount} /> : '-'}</span></CustomTableBodyCell>
                <CustomTableBodyCell sx={{}} align='right'><span>{result.credit_amount ? <CurrencyFormatter amount={result.credit_amount} /> : '-'}</span></CustomTableBodyCell>
                <CustomTableBodyCell sx={{}} align='right'><span>{<CurrencyFormatter amount={result.balance} />}</span></CustomTableBodyCell>
                {
                  !selectedTabIndex &&

                  <CustomTableBodyCell align='right'>
                    <Grid container sx={{ alignItems: 'center' }}>
                      <Grid item xs={7.5} sx={{ textAlign: 'right', display: 'inline-block' }}>
                        {
                          status === 'unprocessed' ?
                            <IconButton onClick={() => addHandler(result.id)} id={'add_btn_' + index} dataTestId={'add_btn_' + index}>
                              <AddIcon fontSize="small" sx={{ color: '#1976D5' }} />
                            </IconButton>
                            :
                            status === 'journal_entry' || status === 'contra' ?
                              <CustomTypography
                                id={'view_action_btn_' + index}
                                dataTestId={'view_action_btn_' + index}
                                text={'View'}
                                onClick={() => viewHandler(result.journal_entry)}
                                sx={{
                                  // marginTop: '10px',
                                  fontSize: '14px',
                                  fontWeight: 550,
                                  color: '#2464EB',
                                  cursor: 'pointer',
                                  textTransform: 'none',
                                  ":hover": { textDecoration: 'underline' },
                                }}
                              />
                              :
                              status === 'draft' ?
                                <CustomTypography
                                  id={'approve_action_btn_' + index}
                                  dataTestId={'approve_action_btn_' + index}
                                  text={'Approve'}
                                  onClick={() => approveHandler(result)}
                                  sx={{
                                    // marginTop: '10px',
                                    fontSize: '14px',
                                    fontWeight: 550,
                                    color: '#2464EB',
                                    cursor: 'pointer',
                                    textTransform: 'none',
                                    ":hover": { textDecoration: 'underline' },
                                  }}
                                />
                                : null
                        }
                      </Grid>
                      <Grid item xs={4.5} sx={{ pl: 0.5, textAlign: 'right' }}>
                        {status === 'unprocessed' ?
                          <IconButton onClick={() => deleteHandler(status === 'unprocessed' ? result.id : result.journal_entry)} id={'delete_icon_' + index} dataTestId={'approve_action_btn' + index}>
                            <DeleteIcon fontSize="small" sx={{ color: 'grey' }} />
                          </IconButton>
                          :
                          <ListItemActions
                            index={index}
                            actions={
                              [
                                status === 'draft' && { name: 'View', onClick: () => viewHandler(result.journal_entry) },
                                { name: 'Edit', onClick: () => updateHandler(result.journal_entry) },
                                { name: 'Delete', showDivider: true, onClick: () => deleteHandler(status === 'unprocessed' ? result.id : result.journal_entry) }
                              ]
                            }
                          />
                        }
                      </Grid>

                    </Grid>


                  </CustomTableBodyCell>
                }
              </CustomTableRow>
            )
          })
        }
      </CustomTableBody>
      : null
  )
}

export default SourceTransactions;

