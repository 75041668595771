import { stateChangeManager } from "../../../../utils/Utils"

export const subAccountValidation=(data, setState, Actions)=>{
    var isValid = true
    if (!data) {
        stateChangeManager(setState, Actions, true, 'error', ' Account name field can not be empty')
        isValid = false
    }
    return isValid
}

export const openingBalanceDateValidation=(data, setState, Actions)=>{
    var isValid = true
    if (!data) {
        stateChangeManager(setState, Actions, true, 'error', 'As of date field can not be empty')
        isValid = false
    }
    return isValid
}

