import React from 'react';
import Input from './Input';
import Loader from '../custom/loader/Loader';
import * as Actions from '../../state/Actions';
import { useNavigate } from 'react-router-dom';
import { screenDetails } from '../../utils/Constant';
import { LabelWithAsteriskMark } from './CommonLabel';
import { input_css_style } from '../../utils/Constant';
import CustomButton from '../custom/button/CustomButton';
import { getBusinessInfo } from '../../config/cookiesInfo';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import CustomTypography from '../custom/typography/CustomTypography';
import { CustomCheckbox } from '../pages/setting/settings/Preferences/common/CustomElement';
import { getAttachmentPreferences } from '../pages/setting/settings/Preferences/common/getPreferences';


import {
    setLoader,
    isFormValid,
    filterArray,
    stateChangeManager,
} from '../../utils/Utils';

import {
    send_document,
    preview_document,
} from '../../api/urls';

import {
    apiAction,
    apiBlobResponse
} from '../../api/api';


import {
    Box,
    Grid,
    Slide,
    Dialog,
} from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});
const CommonPreviewShare = (props) => {
    let navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);

    const { open, setOpen, id, type, export_Url, retrieve_Url, } = props;

    const [data, setData] = React.useState();
    const [onCcBcc, setOnCcBcc] = React.useState(false);
    const [previewData, setPreviewData] = React.useState();

    const handleClose = () => {
        setOpen(false);
    };

    const invoice_mail_data = {
        to_cc: [],
        body: null,
        to_bcc: [],
        to_email: [],
        subject: null,
        business_id: getBusinessInfo().id,
        pdf_attach: getAttachmentPreferences(),
    }
    const [sendData, setSendData] = React.useState({ ...invoice_mail_data })

    const getData = async (id) => {
        let res = await apiAction({
            method: 'post',
            navigate: navigate,
            dispatch: dispatch,
            url: retrieve_Url(id),
            data: { business_id: getBusinessInfo().id },
        })
        if (res?.success) {
            setData(res?.result);
            getDocument(res?.result.id);
            getPreview({ ...res?.result, payment_id: res?.result?.id, place_of_supply_id: res?.result?.place_of_supply ? res?.result?.place_of_supply?.id : null, });
        } else {
        }
    }

    const getPreview = async (data) => {
        let res = await apiAction({
            method: 'post',
            navigate: navigate,
            dispatch: dispatch,
            url: export_Url(data.id),
            data: { ...data, terms_and_conditions_id: data?.terms_and_conditions, payment_term_id: data?.payment_term, export_type: 'html' },
        })
        if (res?.success) {
            setPreviewData(res?.result);
        } else {
            stateChangeManager(dispatch, Actions, true, 'error', res?.status);
        }
    }

    const getDocument = async (id) => {
        let res = await apiAction({
            method: 'post',
            navigate: navigate,
            dispatch: dispatch,
            url: preview_document(),
            data: { business_id: getBusinessInfo().id, document_type: type, document_id: id },
        })
        if (res?.success) {
            setSendData({
                ...sendData,
                ...res?.result,
                document_id: id,
                fileName: res?.result?.file_name + '.pdf',

                to_cc: filterArray(res?.result?.to_cc),
                to_bcc: filterArray(res?.result?.to_bcc),
                to_email: filterArray(res?.result?.to_email),
            })
            setLoader(dispatch, Actions, false);
        } else {
            setLoader(dispatch, Actions, false);
            stateChangeManager(dispatch, Actions, true, 'error', res?.status);
        }
    }

    const sendDocument = async () => {
        console.log('====>sendData',sendData)
        let validation_data = [
            { key: "", validation: sendData?.to_email?.length === 0, message: 'Please enter the to email.' },
            { key: "subject", message: 'Please enter the subject.' },
            { key: "body", message: 'Please enter the message.' },
        ]
        const { isValid, message } = isFormValid(sendData, validation_data);
        if (isValid) {

            let res = await apiAction({
                method: 'post',
                navigate: navigate,
                dispatch: dispatch,
                url: send_document(),
                data: { ...sendData, business_id: getBusinessInfo().id },
            })
            if (res?.success) {
                handleClose()
                stateChangeManager(dispatch, Actions, true, 'success', res?.status);
            } else {
                stateChangeManager(dispatch, Actions, true, 'error', res?.status);
            }
        } else {
            stateChangeManager(dispatch, Actions, true, 'error', message);
        }
    }

    React.useEffect(() => {
        if (open && id) {
            getData(id)
        } else {
            setData(null);
            setOnCcBcc(false);
            setPreviewData(null);
            setSendData({ ...invoice_mail_data });
        }
        // eslint-disable-next-line
    }, [id, open]);

    const onClickAttachment = async (data) => {
        if (data) {
            apiBlobResponse({
                url: export_Url(), data: { ...data, payment_id: data.id, payment_term_id: data.payment_term, export_type: "pdf" }, onSuccess: () => {
                }
            })
        }
    }

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            id={'common_share_Dialog'}
            dataTestId={'common_share_Dialog'}
        >
            <Box sx={{ p: .5 }}>
                {sendData.file_name ?
                    <Grid container>
                        <Grid xs={12} sm={8} item sx={{ p: 2, backgroundColor: '#F2F2F2' }}>
                            <CustomTypography
                                text={'Attached File Preview'}
                                sx={{
                                    pb: 2,
                                    fontSize: '20px',
                                    color: '#000000',
                                    fontWeight: '700',
                                    lineHeight: '18px',
                                    fontStyle: 'normal',
                                    fontFamily: "Noto Sans",
                                }}
                            />
                            <Box sx={{ overflow: "auto", borderTop: '2px solid #e0e0e0' }}>
                                <iframe
                                    title={type}
                                    frameBorder={'0'}
                                    style={{
                                        width: '100%',
                                        overflow: "auto",
                                        paddingTop: '30px',
                                        backgroundColor: '#ffffff',
                                        height: 'calc(100vh - 140px)',
                                    }}
                                    srcDoc={previewData && previewData.html_string}
                                >
                                </iframe>
                            </Box>
                        </Grid>

                        <Grid xs={12} sm={4} item sx={{ p: 2 }}>
                            <CustomTypography
                                text={'Mail Preview'}
                                sx={{
                                    pb: 2,
                                    fontSize: '16px',
                                    color: '#000000',
                                    fontWeight: '700',
                                    lineHeight: '18px',
                                    fontStyle: 'normal',
                                    fontFamily: "Noto Sans",
                                }}
                            />
                            <Box sx={{ overflow: "auto", borderTop: '2px solid #e0e0e0' }}>
                                <Information
                                    type={type}
                                    data={sendData}
                                    onCcBcc={onCcBcc}
                                    setData={setSendData}
                                    setOnCcBcc={setOnCcBcc}
                                    onClickAttachment={() => onClickAttachment(data)}
                                />
                                <Box sx={{ pb: 2, pr: 2, position: 'absolute', bottom: 0, left: 0, right: 0, }}>
                                    <Buttons onSave={() => { sendDocument() }} onCancel={() => { handleClose() }} />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    :
                    <CustomTypography
                        text={<Loader />}
                        sx={{
                            mt: '38vh',
                            color: '#000000',
                            fontSize: '14px',
                            fontWeight: '700',
                            textAlign: 'center',
                            lineHeight: '18px',
                            fontStyle: 'normal',
                            fontFamily: "Noto Sans",
                        }}
                    />

                }
            </Box>
        </Dialog>
    )
}

export default CommonPreviewShare;

const Information = (props) => {
    const { type, onCcBcc, setOnCcBcc, data, setData, onClickAttachment } = props;

    const myRef = React.useRef();
    const [clickedOutsideCC, setClickedOutsideCC] = React.useState(true);
    const [clickedOutsideTO, setClickedOutsideTO] = React.useState(true);
    const [clickedOutsideBCC, setClickedOutsideBCC] = React.useState(true);

    const handleClickInside_BCC = () => { setClickedOutsideBCC(false); setClickedOutsideTO(true); setClickedOutsideCC(true) };
    const handleClickInside_CC = () => { setClickedOutsideCC(false); setClickedOutsideTO(true); setClickedOutsideBCC(true) };
    const handleClickInside_TO = () => { setClickedOutsideTO(false); setClickedOutsideCC(true); setClickedOutsideBCC(true) };


    const handleClickOutside_CC = e => {
        if (!myRef.current.contains(e.target)) {
            setClickedOutsideCC(true);
        }
    };
    const handleClickOutside_BCC = e => {
        if (!myRef.current.contains(e.target)) {
            setClickedOutsideBCC(true);
        }
    };
    const handleClickOutside_TO = e => {
        if (!myRef.current.contains(e.target)) {
            setClickedOutsideTO(true);
        }
    };
    React.useEffect(() => {
        document?.addEventListener('mousedown', handleClickOutside_CC);
        return () => document.removeEventListener('mousedown', handleClickOutside_CC);
    });
    React.useEffect(() => {
        document?.addEventListener('mousedown', handleClickOutside_BCC);
        return () => document.removeEventListener('mousedown', handleClickOutside_BCC);
    });
    React.useEffect(() => {
        document?.addEventListener('mousedown', handleClickOutside_TO);
        return () => document.removeEventListener('mousedown', handleClickOutside_TO);
    });

    const extension = 'pdf';
    // console.log('====>data',data)
    const pdfData = screenDetails.find((item) => item.value === type)
    return (
        <Box sx={{
            paddingBottom: 2,
            overflow: 'scroll',
            height: 'calc(100vh - 140px)',
            borderBottom: '2px solid #e0e0e0'
        }}>
            <Grid container sx={{ pt: 2 }}>
                <Grid container item xs={12} spacing={3}>
                    <Grid item xs={12}>
                        <Grid item container xs={12}>
                            <Grid item xs={6}>
                                <CustomTypography
                                    text={<LabelWithAsteriskMark label={'To Email'} />}
                                    sx={{
                                        mb: 1,
                                        fontSize: '14px',
                                        color: '#000000',
                                        fontWeight: '700',
                                        lineHeight: '18px',
                                        fontStyle: 'normal',
                                        fontFamily: "Noto Sans",
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                {!onCcBcc &&
                                    <CustomTypography
                                        text={'+ Cc/Bcc'}
                                        id={'add_cc_bcc_btn'}
                                        dataTestId={'add_cc_bcc_btn'}
                                        onClick={() => setOnCcBcc(true)}
                                        sx={{
                                            mb: 1,
                                            fontSize: '14px',
                                            color: '#2464EB',
                                            cursor: 'pointer',
                                            fontWeight: '500',
                                            lineHeight: '18px',
                                            fontStyle: 'normal',
                                            fontFamily: "Noto Sans",
                                            ":hover": { textDecoration: 'underline' },
                                        }}
                                    />
                                }

                            </Grid>
                        </Grid>
                        <div onClick={handleClickInside_TO} ref={myRef}>
                            <ReactMultiEmail
                                id={'input_to_email'}
                                dataTestId={'input_to_email'}
                                emails={data.to_email ? data.to_email : []}
                                onChange={(valueEmails) => {
                                    data.to_email = valueEmails
                                    setData({ ...data })
                                }}
                                style={{
                                    marginTop: '0px',
                                    maxHeight: data.to_email.length === 0 ? 35 : '',
                                    textAlign: 'center', borderRadius: "4px", overflowY: 'scroll', borderColor: (clickedOutsideTO) ? '#c7c7c7' : "#2464EB", borderWidth: clickedOutsideTO ? 1.5 : 1.8,
                                }}

                                getLabel={(
                                    email,
                                    index,
                                    removeEmail
                                ) => {

                                    return (
                                        <div data-tag key={index} style={{ ...input_css_style }}>{email}<span data-tag-handle onClick={() => removeEmail(index)}>×</span>
                                        </div>
                                    );
                                }}
                                validateEmail={email => {
                                    return email?isEmail(email):false; // return boolean
                                }}
                            />
                        </div>
                    </Grid>
                    {
                        onCcBcc &&
                        <React.Fragment>
                            <Grid item xs={12}>
                                <CustomTypography
                                    text={'Cc'}
                                    sx={{
                                        mb: 1,
                                        fontSize: '14px',
                                        color: '#000000',
                                        fontWeight: '700',
                                        lineHeight: '18px',
                                        fontStyle: 'normal',
                                        fontFamily: "Noto Sans",
                                    }}
                                />
                                <div onClick={handleClickInside_CC} ref={myRef}>
                                    <ReactMultiEmail
                                        id={'input_to_cc'}
                                        dataTestId={'input_to_cc'}
                                        emails={data.to_cc ? data.to_cc : []}
                                        onChange={(valueEmails) => {
                                            data.to_cc = valueEmails
                                            setData({ ...data })
                                        }}
                                        style={{
                                            marginTop: '0px',
                                            maxHeight: data.to_cc.length === 0 ? 35 : '',
                                            textAlign: 'center', borderRadius: "4px", overflowY: 'scroll', borderColor: (clickedOutsideCC) ? '#c7c7c7' : "#2464EB", borderWidth: clickedOutsideCC ? 1.5 : 1.8,
                                        }}

                                        getLabel={(
                                            email,
                                            index,
                                            removeEmail
                                        ) => {

                                            return (
                                                <div data-tag key={index} style={{ ...input_css_style }}>{email}<span data-tag-handle onClick={() => removeEmail(index)}>×</span>
                                                </div>
                                            );
                                        }}
                                        validateEmail={email => {
                                            return email?isEmail(email):false; // return boolean
                                        }}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <CustomTypography
                                    text={'Bcc'}
                                    sx={{
                                        mb: 1,
                                        fontSize: '14px',
                                        color: '#000000',
                                        fontWeight: '700',
                                        lineHeight: '18px',
                                        fontStyle: 'normal',
                                        fontFamily: "Noto Sans",
                                    }}
                                />
                                <div onClick={handleClickInside_BCC} ref={myRef}>
                                    <ReactMultiEmail
                                        id={'input_to_bcc'}
                                        dataTestId={'input_to_bcc'}
                                        emails={data.to_bcc ? data.to_bcc : []}
                                        onChange={(valueEmails) => {
                                            data.to_bcc = valueEmails
                                            setData({ ...data })
                                        }}
                                        // placeholder={<span style={{ margin: 0,height: "100%", overflow: 'hidden', color: '#2F2F2E', fontWeight: '400', lineHeight: '0px', fontSize: '12px', fontStyle: 'normal', fontFamily: 'Noto Sans', opacity: 0.50, }}>Add Bcc recipients</span>}
                                        style={{
                                            marginTop: '0px',
                                            maxHeight: data.to_bcc.length === 0 ? 35 : '',
                                            textAlign: 'center', borderRadius: "4px", overflowY: 'scroll', borderColor: (clickedOutsideBCC) ? '#c7c7c7' : "#2464EB", borderWidth: clickedOutsideBCC ? 1.5 : 1.8,
                                        }}

                                        getLabel={(
                                            email,
                                            index,
                                            removeEmail
                                        ) => {
                                            return (
                                                <div data-tag key={index} style={{ ...input_css_style }}>{email}<span data-tag-handle onClick={() => removeEmail(index)}>×</span>
                                                </div>
                                            );
                                        }}
                                        validateEmail={email => {
                                            return email?isEmail(email):false; // return boolean
                                        }}
                                    />
                                </div>
                            </Grid>

                        </React.Fragment>
                    }
                    <Grid item xs={12}>
                        <Input
                            id={'input_subject'}
                            dataTestId={'input_subject'}
                            item={{
                                type: 'text',
                                fullWidth: true,
                                placeholder: 'Enter the Subject',
                                value: data.subject ? data.subject : '',
                                title: <LabelWithAsteriskMark label={'Subject'} />,
                                onChange: (event) => {
                                    setData({ ...data, subject: event.target.value })
                                },
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Input
                            id={'input_message'}
                            dataTestId={'input_message'}
                            item={{
                                rows: 14,
                                type: 'text',
                                multiline: true,
                                fullWidth: true,
                                placeholder: 'Enter the Message',
                                value: data.body ? data.body : '',
                                title: <LabelWithAsteriskMark label={'Message'} />,
                                onChange: (event) => {
                                    setData({ ...data, body: event.target.value })
                                },
                            }}
                        />
                    </Grid>
                    {
                        <Grid item xs={12} sx={{ pb: 2 }}>
                            <CustomTypography
                                text={<CustomCheckbox checked={data.pdf_attach} onClickedHandler={(value) => setData({ ...data, pdf_attach: !value })}
                                    label={<span style={{
                                        color: '#000000',
                                        fontSize: '14px',
                                        fontWeight: '700',
                                        lineHeight: '18px',
                                        fontStyle: 'normal',
                                        fontFamily: "Noto Sans",
                                    }}>Attach {pdfData ? pdfData.name : ''} PDF</span>} />}
                                sx={{
                                    pl: 2,
                                    mb: 2,
                                    color: '#000000',
                                    fontSize: '14px',
                                    fontWeight: '700',
                                    lineHeight: '18px',
                                    fontStyle: 'normal',
                                    fontFamily: "Noto Sans",
                                }}
                            />{
                                data.pdf_attach &&
                                <Box sx={{ p: 2, display: 'flex', backgroundColor: '#F2F2F2' }}>
                                    {
                                        extension === 'pdf' &&
                                        <PictureAsPdfIcon fontSize='small' sx={{ mr: .5, color: '#ee4035' }} />
                                    }
                                    <CustomTypography
                                        text={data.fileName}
                                        onClick={() => { onClickAttachment() }}
                                        id={'file_view_action'}
                                        dataTestId={'file_view_action'}
                                        sx={{
                                            fontSize: '12px',
                                            cursor: 'pointer',
                                            color: '#2464EB',
                                            fontWeight: '500',
                                            lineHeight: '16px',
                                            fontStyle: 'normal',
                                            fontFamily: 'Noto Sans',
                                            ":hover": { textDecoration: 'underline' }
                                        }}
                                    />
                                </Box>
                            }
                        </Grid>
                    }

                </Grid>
            </Grid>
        </Box>

    )
}

const Buttons = (props) => {
    const { onSave, onCancel } = props
    return (
        <Box component="form" noValidate sx={{}}>
            <Grid container>
                <Grid item xs={12} sx={{ display: '-webkit-flex', justifyContent: 'end', }}>
                    <CustomButton id={'common_share_cancel_btn'} dataTestId={'common_share_cancel_btn'} variant='outlined' sx={{ textTransform: 'none', '&:hover': { backgroundColor: '#e8f3ff' } }} btnLabel='Cancel' onClick={onCancel} />
                    <CustomButton id={'common_share_send_btn'} dataTestId={'common_share_send_btn'} variant='contained' sx={{ ml: 1, textTransform: 'none', }} btnLabel={'Send'} onClick={onSave} />
                </Grid>
            </Grid>
        </Box>
    )
}