import * as React from 'react';
import Input from '../../../common/Input';
import { apiAction } from '../../../../api/api';
import Loader from '../../../custom/loader/Loader';
import { HighlightedText } from '../../../../utils/Utils';
import InfiniteScroll from 'react-infinite-scroll-component';
import { get_list_of_hsn_sac_code } from '../../../../api/urls';
import { getBusinessInfo } from '../../../../config/cookiesInfo';
import CustomTypography from '../../../custom/typography/CustomTypography';
import { CustomRadio } from '../../setting/settings/Preferences/common/CustomElement';
import { getItemPreferences } from '../../setting/settings/Preferences/common/getPreferences';


import {
    CustomTable,
    CustomTableRow,
    CustomTableBody,
    CustomTableHead,
    CustomTableHeadCell,
    CustomTableBodyCell,
    CustomTableContainer,
} from '../../../custom/table/CustomTable';

import {
    Box,
    Slide,
    Drawer,
    Popover,
    Divider,
    Checkbox,
    RadioGroup,
    IconButton,
    InputAdornment,
    FormControlLabel,
} from '@mui/material';

import { Close } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const HSNSACSearchComponent = (props) => {
    const { item_type = 'goods', hsn_sac_code, open, setOpen, onClick } = props;

    let business_id = getBusinessInfo().id;
    let defaultCodeLength = getItemPreferences() ? getItemPreferences()[`4_digit_hsn_or_sac_code`] ? '[4]' : '[6,8]' : '[4]'

    const inputRef = React.useRef();
    const [count, setCount] = React.useState();
    const [message, setMessage] = React.useState();
    const [nextUrl, setNextUrl] = React.useState();
    const [results, setResults] = React.useState([]);
    const [type, setType] = React.useState(item_type);
    const [searchTerm, setSearchTerm] = React.useState(hsn_sac_code||null);
    const [codeLength, setCodeLength] = React.useState(defaultCodeLength);

    const toggleDrawer = () => {
        onReset();
        setOpen(!open);
        setCodeLength(defaultCodeLength);
    }

    const onReset = () => {
        setNextUrl();
        setResults([]);
        setType(item_type);
        setSearchTerm(hsn_sac_code||'');
    }
    
    React.useEffect(() => {
        onReset()
    }, [item_type]);

    React.useEffect(() => {
        if (open) {
            fetchData();
            setNextUrl();
        } else {
            onReset();
        }
    }, [open, searchTerm, type, codeLength,hsn_sac_code]);

    const fetchData = async () => {
        setMessage();
        const res = await apiAction({
            url: nextUrl ? `${nextUrl}&business_id=${business_id}&search_text=${searchTerm}${type ? '&item_type=' + type : ''}${codeLength ? '&list_of_hsn_sac_code_len=' + codeLength : ''}` : get_list_of_hsn_sac_code(business_id, searchTerm, type, codeLength)
        });
        if (res?.success) {
            setCount(res?.result.count);
            setNextUrl(res?.result.next);
            setMessage('No result found');
            setResults([...results, ...res?.result.result]);
        }
    };

    let isResults = results.length;
    return (
        <Drawer
            open={open}
            elevation={5}
            anchor={'right'}
            hideBackdrop={true}
            TransitionComponent={Transition}
            variant={open ? "persistent" : 'temporary'}

            id={'hsn_sac_code_drawer'}
            dataTestId={'hsn_sac_code_drawer'}
            PaperProps={{
                sx: {
                    zIndex: 999999,
                    backgroundColor: '#FFFFFF',
                    width: {
                        xs: '100vw',
                        sm: '35vw'
                    }
                }
            }}
        >
            <Box sx={{
                zIndex: 1,
                minHeight: '100vh',
                backgroundColor: '#FFFFFF',
                boxShadow: '1px 0px 4px rgba(0, 0, 0, 0.04)',
            }}>
                <Box sx={{ height: '24px', p: 2, pt: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <CustomTypography
                        text={type === 'goods' ? 'HSN Code' : 'SAC Code'}
                        sx={{
                            color: '#000000',
                            fontSize: '14px',
                            fontWeight: '700',
                            lineHeight: '18px',
                            fontStyle: 'normal',
                            fontFamily: "Noto Sans",
                        }}
                    />
                    <IconButton size='small' id={'close_hsn_sacs_btn'} dataTestId={'close_hsn_sacs_btn'} onClick={() => toggleDrawer()} sx={{ ":hover": { bgcolor: '#e0e0e0' } }}>
                        <Close fontSize='small' />
                    </IconButton>
                </Box>
                <Divider />
                <Box sx={{ pr: 2, pl: 2 }}>
                    <Input
                        autoFocus={true}
                        inputRef={inputRef}
                        isKeyPressEnter={false}
                        id={'hsn_sac_code_search'}
                        dataTestId={'hsn_sac_code_search'}
                        isSearchableComponent={true}
                        item={{
                            type: 'text',
                            title: '',
                            fullWidth: true,
                            titleVisibility: 'hidden',
                            value: searchTerm ? searchTerm : '',
                            placeholder: `Search ${type === 'goods' ? 'HSN Code' : 'SAC Code'}`,
                            onChange: (e) => {
                                setNextUrl();
                                setResults([]);
                                setSearchTerm(e.target.value);
                            },
                            onblur: (e) => {
                                setNextUrl();
                                setResults([]);
                                setSearchTerm(e.target.value);

                            }
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <Divider sx={{ height: 20, m: 0.5 }} orientation="vertical" />
                                <IconButton edge="end" size='small' disabled={searchTerm === "" ? true : false} sx={{ ml: .5, color: '#2464EB', cursor: 'pointer' }}
                                    onClick={() => {
                                        if (searchTerm !== "") {
                                            setNextUrl();
                                            setResults([]);
                                            setSearchTerm('');
                                        } else {
                                            setNextUrl();
                                            setResults([]);
                                            setSearchTerm(searchTerm);
                                        }
                                    }}>
                                    {
                                        searchTerm !== "" ?
                                            <ClearIcon /> : <SearchIcon />
                                    }
                                </IconButton>
                            </InputAdornment>

                        }}
                    />

                    <RadioGroup row>
                        {type === 'goods' &&
                            <FormControlLabel value="goods"
                                disabled={true}
                                label={<span style={{
                                    color: '#000000',
                                    fontSize: '14px',
                                    lineHeight: '18px',
                                    fontStyle: 'normal',
                                    fontFamily: "Noto Sans",
                                    textTransform: 'capitalize',
                                    fontWeight: type === 'goods' ? '600' : '400',
                                }}>goods</span>} control={<Checkbox style={{ color: type === 'goods' ? "#2464EB" : '', }} sx={{ color: '#8F8F8F' }} checked={type === 'goods'} onChange={(e) => { if (e.target.value === type) { setType(null) } else { setType(e.target.value) }; setResults([]); setNextUrl(); }} />} />
                        }
                        {type === 'service' &&
                            <FormControlLabel value="service"
                                disabled={true}
                                label={<span style={{
                                    color: '#000000',
                                    fontSize: '14px',
                                    lineHeight: '18px',
                                    fontStyle: 'normal',
                                    fontFamily: "Noto Sans",
                                    textTransform: 'capitalize',
                                    fontWeight: type === 'service' ? '600' : '400',
                                }}>Service</span>} control={<Checkbox style={{ color: type === 'service' ? "#2464EB" : '', }} sx={{ color: '#8F8F8F' }} checked={type === 'service'} onChange={(e) => { if (e.target.value === type) { setType(null) } else { setType(e.target.value) }; setResults([]); setNextUrl(); }} />} />
                        }

                        <CustomRadio checked={codeLength === '[4]'} onClickedHandler={(value) => { if (codeLength !== '[4]') { onReset() } setCodeLength('[4]') }} label={<span style={{ fontWeight: codeLength === '[4]' ? '700' : '' }}>4-digit</span>} />
                        <CustomRadio checked={codeLength === '[6,8]'} onClickedHandler={(value) => { if (codeLength !== '[6,8]') { onReset() } setCodeLength('[6,8]') }} label={<span style={{ fontWeight: codeLength === '[6,8]' ? '700' : '' }}>6 or 8-digit</span>} />
                    </RadioGroup>

                    <CustomTypography
                        text={`Showing ${count} results`}
                        sx={{
                            mt: 2,
                            mb: 1,
                            opacity: 0.6,
                            color: '#000000',
                            fontSize: '12px',
                            fontWeight: '700',
                            lineHeight: '18px',
                            fontStyle: 'normal',
                            fontFamily: "Noto Sans",
                        }}
                    />
                </Box>
                <Divider />

                <CustomTableContainer id="scrollableDiv" sx={{ height: 'calc(100vh - 194px)', }}>
                    <InfiniteScroll
                        hasMore={true}
                        scrollThreshold={0.9}
                        dataLength={results.length}
                        scrollableTarget="scrollableDiv"
                        next={() => { if (nextUrl) fetchData() }}
                        loader={nextUrl ?
                            <CustomTypography
                                text={''}
                                // text={'Loading...'}
                                sx={{
                                    mt: 5,
                                    color: '#000000',
                                    fontSize: '14px',
                                    fontWeight: '700',
                                    textAlign: 'center',
                                    lineHeight: '18px',
                                    fontStyle: 'normal',
                                    fontFamily: "Noto Sans",
                                }}
                            />
                            : ''}
                    >
                        {
                            isResults ?

                                <CommonTable
                                    data={results}
                                    search={searchTerm}
                                    title={type === 'goods' ? 'HSN' : 'SAC'}
                                    onClick={(data) => { onClick(data); toggleDrawer() }}
                                />

                                :
                                <CustomTypography
                                    text={message ? message : <Loader />}
                                    sx={{
                                        mt: '35vh',
                                        color: '#000000',
                                        fontSize: '14px',
                                        fontWeight: '700',
                                        textAlign: 'center',
                                        lineHeight: '18px',
                                        fontStyle: 'normal',
                                        fontFamily: "Noto Sans",
                                    }}
                                />
                        }
                    </InfiniteScroll>
                </CustomTableContainer>
            </Box>
        </Drawer>
    )
}

export default HSNSACSearchComponent;

const CommonTable = (props) => {
    const { title, data, onClick, search } = props
    return (
        <CustomTable>
            <CustomTableHead sx={{}}>
                <CustomTableRow sx={{}}>
                    <CustomTableHeadCell sx={{}}><span style={{ whiteSpace: 'nowrap' }}>{title} {'Code'}</span></CustomTableHeadCell>
                    <CustomTableHeadCell sx={{}}><span>Description</span></CustomTableHeadCell>
                </CustomTableRow>
            </CustomTableHead>
            <CustomTableBody sx={{}}>
                {
                    data.map((item, index) => {
                        return (
                            <CustomTableRow sx={{ cursor: 'pointer' }} key={index} onClick={() => { onClick(item) }}>
                                <CustomTableBodyCell sx={{ whiteSpace: 'nowrap', }}>
                                    <HighlightedText text={item.hsn_sac_code} search={search} />
                                </CustomTableBodyCell>
                                <CustomTableBodyCell sx={{}} >
                                    <HighlightedText text={item.description} search={search} />
                                </CustomTableBodyCell>

                            </CustomTableRow>
                        )
                    })
                }
            </CustomTableBody>

        </CustomTable>
    )
}