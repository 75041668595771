export const fixed_table_key = {
    b2b: [
        {
            label: `GSTIN/UIN of Recipient`,
            field: `gstin_uin`,

            date: false,
            align: 'left',
            numberWithCommas: false,
        }, {
            label: `Receiver Name`,
            field: `name_of_recipient`,

            date: false,
            align: 'left',
            numberWithCommas: false,
        }, {
            label: `Invoice Number`,
            field: `invoice_number`,

            date: false,
            align: 'left',
            numberWithCommas: false,
        }, {
            label: `Invoice date`,
            field: `invoice_date`,

            date: true,
            align: 'left',
            numberWithCommas: false,
        }, {
            label: `Invoice Value`,
            field: `invoice_value`,

            date: false,
            align: 'right',
            numberWithCommas: true,
        }, {
            label: `Place Of Supply`,
            field: `place_of_supply_r`,

            date: false,
            align: 'left',
            numberWithCommas: false,
        }, {
            label: `Reverse Charge`,
            field: `reverse_charge`,

            date: false,
            align: 'left',
            numberWithCommas: false,
        }, {
            label: `Applicable % of Tax Rate`,
            field: ``,

            date: false,
            align: 'right',
            numberWithCommas: false,
        }, {
            label: `Invoice Type`,
            field: `invoice_type`,

            date: false,
            align: 'left',
            numberWithCommas: false,
        }, {
            label: `E-Commerce GSTIN`,
            field: ``,

            date: false,
            align: 'left',
            numberWithCommas: false,
        }, {
            label: `Rate`,
            field: `tax_rate`,

            date: false,
            align: 'right',
            numberWithCommas: false,
        }, {
            label: `Taxable Value`,
            field: `taxable_value`,

            date: false,
            align: 'right',
            numberWithCommas: true,
        }, {
            label: `Cess Amount`,
            field: ``,

            date: false,
            align: 'right',
            numberWithCommas: true,
        },

    ],

    b2cl: [
        {
            label: `Invoice Number`,
            field: `invoice_number`,

            date: false,
            align: 'left',
            numberWithCommas: false,
        }, {
            label: `Invoice date`,
            field: `invoice_date`,

            date: true,
            align: 'left',
            numberWithCommas: false,
        }, {
            label: `Invoice Value`,
            field: `invoice_value`,

            date: false,
            align: 'right',
            numberWithCommas: true,
        }, {
            label: `Place Of Supply`,
            field: `place_of_supply_r`,

            date: false,
            align: 'left',
            numberWithCommas: false,
        }, {
            label: `Applicable % of Tax Rate`,
            field: ``,

            date: false,
            align: 'left',
            numberWithCommas: false,
        }, {
            label: `Rate`,
            field: `tax_rate`,

            date: false,
            align: 'left',
            numberWithCommas: false,
        }, {
            label: `Taxable Value`,
            field: `taxable_value`,

            date: false,
            align: 'right',
            numberWithCommas: true,
        }, {
            label: `Cess Amount`,
            field: ``,

            date: false,
            align: 'left',
            numberWithCommas: true,
        }, {
            label: `E-Commerce GSTIN`,
            field: ``,

            date: false,
            align: 'left',
            numberWithCommas: false,
        },
    ],

    exp: [
        {
            label: `Export Type`,
            field: `export_type`,

            date: false,
            align: 'left',
            numberWithCommas: false,
        }, {
            label: `Invoice Number`,
            field: `invoice_number`,

            date: false,
            align: 'left',
            numberWithCommas: false,
        }, {
            label: `Invoice date`,
            field: `invoice_date`,

            date: true,
            align: 'left',
            numberWithCommas: false,
        }, {
            label: `Invoice Value`,
            field: `invoice_value`,

            date: false,
            align: 'right',
            numberWithCommas: true,
        }, {
            label: `Port Code`,
            field: ``,

            date: false,
            align: 'left',
            numberWithCommas: false,
        }, {
            label: `Shipping Bill Number`,
            field: ``,

            date: false,
            align: 'left',
            numberWithCommas: false,
        }, {
            label: `Shipping Bill Date`,
            field: ``,

            date: true,
            align: 'left',
            numberWithCommas: false,
        }, {
            label: `Rate`,
            field: `tax_rate`,

            date: false,
            align: 'right',
            numberWithCommas: false,
        }, {
            label: `Taxable Value`,
            field: `taxable_value`,

            date: false,
            align: 'right',
            numberWithCommas: true,
        }, {
            label: `Cess Amount`,
            field: ``,

            date: false,
            align: 'right',
            numberWithCommas: true,
        },
    ],

    b2cs: [
        {
            label: `Type`,
            field: `type`,

            date: false,
            align: 'left',
            numberWithCommas: false,
        }, {
            label: `Place Of Supply`,
            field: `place_of_supply_r`,

            date: false,
            align: 'left',
            numberWithCommas: false,
        }, {
            label: `Applicable % of Tax Rate`,
            field: ``,

            date: false,
            align: 'left',
            numberWithCommas: false,
        }, {
            label: `Rate`,
            field: `tax_rate`,

            date: false,
            align: 'right',
            numberWithCommas: false,
        }, {
            label: `Taxable Value`,
            field: `taxable_value`,

            date: false,
            align: 'right',
            numberWithCommas: true,
        }, {
            label: `Cess Amount`,
            field: ``,

            date: false,
            align: 'right',
            numberWithCommas: true,
        }, {
            label: `E-Commerce GSTIN`,
            field: ``,

            date: false,
            align: 'left',
            numberWithCommas: false,
        },
    ],

    exempt: [
        {
            label: `Description`,
            field: `description`,

            date: false,
            align: 'left',
            numberWithCommas: false,
        }, {
            label: `Nil Rated Supplies`,
            field: `nil_rated`,

            date: false,
            align: 'right',
            numberWithCommas: true,
        }, {
            label: `Exempted(other than nil rated/non GST supply)`,
            field: `gst_exempt`,

            date: false,
            align: 'right',
            numberWithCommas: true,
        }, {
            label: `Non-GST Supplies`,
            field: `non_gst`,

            date: false,
            align: 'right',
            numberWithCommas: true,
        },
    ],

    cdnr: [
        {
            label: `GSTIN/UIN of Recipient`,
            field: `gstin_uin`,

            date: false,
            align: 'left',
            numberWithCommas: false,
        }, {
            label: `Receiver Name`,
            field: `receiver_name`,

            date: false,
            align: 'left',
            numberWithCommas: false,
        }, {
            label: `Note Number`,
            field: `note_number`,

            date: false,
            align: 'left',
            numberWithCommas: false,
        }, {
            label: `Note date`,
            field: `note_date`,

            date: true,
            align: 'left',
            numberWithCommas: false,
        }, {
            label: `Note Type`,
            field: `note_type`,

            date: false,
            align: 'left',
            numberWithCommas: false,
        }, {
            label: `Note Value`,
            field: `note_value`,

            date: false,
            align: 'right',
            numberWithCommas: true,
        }, {
            label: `Place Of Supply`,
            field: `place_of_supply_r`,

            date: false,
            align: 'left',
            numberWithCommas: false,
        }, {
            label: `Reverse Charge`,
            field: `reverse_charge`,

            date: false,
            align: 'left',
            numberWithCommas: false,
        }, {
            label: `Note Supply Type`,
            field: `note_supply_type`,

            date: false,
            align: 'left',
            numberWithCommas: false,
        }, {
            label: `Note Value`,
            field: `note_value`,

            date: false,
            align: 'right',
            numberWithCommas: true,
        }, {
            label: `Applicable % of Tax Rate`,
            field: ``,

            date: false,
            align: 'right',
            numberWithCommas: false,
        }, {
            label: `Rate`,
            field: `tax_rate`,

            date: false,
            align: 'right',
            numberWithCommas: false,
        }, {
            label: `Taxable Value`,
            field: `taxable_value`,

            date: false,
            align: 'right',
            numberWithCommas: true,
        }, {
            label: `Cess Amount`,
            field: ``,

            date: false,
            align: 'right',
            numberWithCommas: true,
        },
    ],

    cdnur: [
        {
            label: `UR Type`,
            field: `ur_type`,

            date: false,
            align: 'left',
            numberWithCommas: false,
        }, {
            label: `Note Number`,
            field: `note_number`,

            date: false,
            align: 'right',
            numberWithCommas: false,
        }, {
            label: `Note Date`,
            field: `note_date`,

            date: true,
            align: 'left',
            numberWithCommas: false,
        }, {
            label: `Note Type`,
            field: `note_type`,

            date: false,
            align: 'left',
            numberWithCommas: false,
        }, {
            label: `Place Of Supply`,
            field: `place_of_supply_r`,

            date: false,
            align: 'left',
            numberWithCommas: false,
        }, {
            label: `Note Value`,
            field: `note_value`,

            date: false,
            align: 'right',
            numberWithCommas: true,
        }, {
            label: `Applicable % of Tax Rate`,
            field: ``,

            date: false,
            align: 'right',
            numberWithCommas: false,
        }, {
            label: `Rate`,
            field: `tax_rate`,

            date: false,
            align: 'right',
            numberWithCommas: false,
        }, {
            label: `Taxable Value`,
            field: `taxable_value`,

            date: false,
            align: 'right',
            numberWithCommas: true,
        }, {
            label: `Cess Amount`,
            field: ``,

            date: false,
            align: 'right',
            numberWithCommas: true,
        }
    ],

    at: [
        {
            label: `Place Of Supply`,
            field: `place_of_supply_r`,

            date: false,
            align: 'left',
            numberWithCommas: false,
        }, {
            label: `Applicable % of Tax Rate`,
            field: ``,

            date: false,
            align: 'right',
            numberWithCommas: false,
        }, {
            label: `Rate`,
            field: `rate`,

            date: false,
            align: 'right',
            numberWithCommas: false,
        }, {
            label: `Gross Advance Received`,
            field: `gross_advance_received`,

            date: false,
            align: 'right',
            numberWithCommas: true,
        }, {
            label: `Cess Amount`,
            field: ``,

            date: false,
            align: 'right',
            numberWithCommas: true,
        },
    ],

    atadj: [
        {
            label: `Place Of Supply`,
            field: `place_of_supply_r`,

            date: false,
            align: 'left',
            numberWithCommas: false,
        }, {
            label: `Applicable % of Tax Rate`,
            field: ``,

            date: false,
            align: 'right',
            numberWithCommas: false,
        }, {
            label: `Rate`,
            field: `rate`,

            date: false,
            align: 'right',
            numberWithCommas: false,
        }, {
            label: `Gross Advance Adjusted`,
            field: `gross_advance_received`,

            date: false,
            align: 'right',
            numberWithCommas: true,
        }, {
            label: `Cess Amount`,
            field: ``,

            date: false,
            align: 'right',
            numberWithCommas: true,
        },
    ],

    hsn: [
        {
            label: `HSN`,
            field: `hsn`,

            date: false,
            align: 'left',
            numberWithCommas: false,
        }, {
            label: `Description`,
            field: `description`,

            date: false,
            align: 'left',
            numberWithCommas: false,
        }, {
            label: `UQC`,
            field: `uqc`,

            date: false,
            align: 'left',
            numberWithCommas: false,
        }, {
            label: `Total Quantity`,
            field: `total_quantity`,

            date: false,
            align: 'right',
            numberWithCommas: false,
        }, {
            label: `Total Value`,
            field: `total_value`,

            date: false,
            align: 'right',
            numberWithCommas: true,
        }, {
            label: `Rate`,
            field: `rate`,

            date: false,
            align: 'right',
            numberWithCommas: false,
        }, {
            label: `Taxable Value`,
            field: `taxable_value`,

            date: false,
            align: 'right',
            numberWithCommas: true,
        }, {
            label: `Integrated Tax Amount`,
            field: `integrated_tax_amount`,

            date: false,
            align: 'right',
            numberWithCommas: true,
        }, {
            label: `Central Tax Amount`,
            field: `center_tax_amount`,

            date: false,
            align: 'right',
            numberWithCommas: true,
        }, {
            label: `State/UT Tax Amount`,
            field: `state_tax_amount`,

            date: false,
            align: 'right',
            numberWithCommas: true,
        }, {
            label: `Cess Amount`,
            field: ``,

            date: false,
            align: 'right',
            numberWithCommas: true,
        },
    ]
}

