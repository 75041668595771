import React from 'react';
import { Fab, Box, Grid } from '@mui/material';
import ListSections from './sections/ListSections';
import DateRange from '../../../common/DateRange';
import { date_range } from '../../../../utils/Utils';
import CustomTitle from '../../../common/CustomTitle';
import ListInsightTab from './insightTabs/ListInsightTab';
import { eventsNames,AnalyticsEvent } from '../../../../firebase/firebaseAnalytics';
import { CustomDataPickerPopover } from '../../../custom/Datepicker/CustomDataPickerPopover';
import { CustomContainer, CustomTitleContainer } from '../../../custom/container/CustomContainer';

const Insight = (props) => {
    const DropdownRef = React.useRef(null);
    const [tabData, setTabData] = React.useState();
    const [refresh, setRefresh] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [sectionData, setSectionData] = React.useState([]);
    const [addNewSection, setAddNewSection] = React.useState(false);
    const [dates, setDates] = React.useState(date_range()[4]?.dates);
    const [selectedDateRange, setSelectedDateRange] = React.useState(date_range()[4]);

    const handleDeleteSection = (id) => {
        const updatedSections = tabData.sections.filter(val => val.id !== id);
        setTabData({ ...tabData, sections: updatedSections });
        setSectionData([...updatedSections]);
    };

    const handleRefreshDataPoint = () => setRefresh(!refresh);

    const handleDateRangeChange = (event, newDateRange) => {
        if (newDateRange.dates) {
            setAnchorEl(null);
            setDates(newDateRange.dates);
            setSelectedDateRange(newDateRange);
        } else {
            setAnchorEl(DropdownRef.current);
            setSelectedDateRange(newDateRange);
        }
    };

    const handleTabDataChange = (value) => {
        if (value) {
            setSectionData([...value.sections]);
        }
    };

    return (
        <div style={{ maxWidth: 'calc(100vw - 270px)', minWidth: '100%' }}>
            <CustomDataPickerPopover
                anchorEl={anchorEl}
                customDate={dates}
                setCustomDate={setDates}
                setAnchorEl={setAnchorEl}
            />
            <CustomTitleContainer>
                <Grid container spacing={0} alignItems="center" justifyContent="space-evenly">
                    <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'start' }}>
                        <CustomTitle title="Insights" />
                    </Grid>
                    <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'end' }}>
                        <DateRange
                            label=""
                            inputRef={DropdownRef}
                            results={date_range()}
                            disableClearable={true}
                            value={selectedDateRange}
                            id="common_date_dropdown"
                            dataTestId="common_date_dropdown"
                            setValue={handleDateRangeChange}
                            sx={{ width: { sm: 200, xs: '100%' }, pt: { xs: 1, sm: 0 } }}
                        />
                    </Grid>
                </Grid>
            </CustomTitleContainer>
            <ListInsightTab
                tabData={tabData}
                setTabData={setTabData}
                addNewSection={addNewSection}
                getTabData={handleTabDataChange}
                setAddNewSection={setAddNewSection}
                refreshDataPoint={handleRefreshDataPoint}
            />
            <CustomContainer maxWidth="400px" sx={{ pb: 2, height: 'calc(100vh - 248px)', background: '#FAFAFA', boxShadow: 'none' }}>
                <ListSections
                    to_date={dates.to_date}
                    from_date={dates.from_date}
                    refreshDataPoint={refresh}
                    sectionList={sectionData}
                    tab_id={tabData?.id}
                    onDeleteSection={handleDeleteSection}
                />
            </CustomContainer>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <Fab
                    size="medium"
                    color="primary"
                    variant="extended"
                    sx={{
                        mb: '-70px',
                        color: '#FFF',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: 'normal',
                        textTransform: 'none',
                        fontFamily: 'Noto Sans',
                    }}
                    onClick={() => {
                        setAddNewSection(true)
                        AnalyticsEvent(eventsNames.categories.INSIGHT,{action:eventsNames.actions.ADD,type:eventsNames.actions.insight.type.SECTION});
                    }}
                >
                    + Add New Section
                </Fab>
            </Box>
        </div>
    );
};

export default Insight;
