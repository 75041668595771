import { Box } from "./dnd/Box.js";
import SaveMapping from "./SaveMapping.js";
import { Dustbin } from "./dnd/Dustbin.js";
import { ItemTypes } from "./dnd/ItemTypes.js";
import { useNavigate } from "react-router-dom";
import HorizontalLinearStepper from "./Stepper.js";
import * as Actions from "../../../../state/Actions";
import { routesName } from "../../../../config/routesName.js";
import { getBusinessInfo } from "../../../../config/cookiesInfo";
import CustomButton from "../../../custom/button/CustomButton.js";
import { apiAction, apiActionFormData } from "../../../../api/api";
import React, { memo, useContext, useEffect, useState } from "react";
import CustomDropdown from "../../../custom/dropdown/CustomDropdown.js";
import { CustomContainer } from "../../../custom/container/CustomContainer.js";
import {
  opening_balance,
  sourced_transaction,
} from "../../../../utils/Constant.js";

import {
  createMappings,
  compareMappings,
  stateChangeManager,
} from "../../../../utils/Utils.js";

import {
  create_dynamic_mapping,
  list_dynamic_mapping,
  update_dynamic_mapping,
  transaction_bulk_upload,
} from "../../../../api/urls.js";

import {
  Card,
  Grid,
  Stack,
  Divider,
  CardHeader,
  CardContent,
} from "@mui/material";
import CustomLabel from "../../../common/CustomLabel.js";

const Mappings = memo(function Container(props) {

  const {
    nextButton,
    isDateFormat = true,
    mapping_for = sourced_transaction,
    nextPagePath = routesName.transactions.path,
    onFileErrorPath = routesName.fileUpload.path,
  } = props;

  const state = Actions.getState(useContext);
  const dispatch = Actions.getDispatch(useContext);
  const { amountFieldColumnType, dateTimeFormatList, mappings, model_fields, mapping, date_format_dict } = state;
  const { file_headers, mapped_headers, uploadedFile, } = mappings;
  const [isNewMapping, setNewMapping] = useState(false);
  const [mappingList, setMappingList] = React.useState([]);
  const viewPort = 'calc(100vh - 242px)';
  const contentViewport = 'calc(100vh - 330px)';
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [isMappingsChanged, setMappingsChanged] = useState(false);

  useEffect(() => {
    model_fields.map((item) => {
      delete item.file_header;
    });
    dispatch(
      Actions.stateChange("mappings", {
        ...mappings,
        mapped_headers: [],
      })
    );
    dispatch(Actions.stateChange("model_fields", [...model_fields]));
  }, []);

  useEffect(() => {
    if (!uploadedFile) {
      dispatch(Actions.stateChange("activeStep", 0));
      navigate(onFileErrorPath);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(Actions.stateChange("activeStep", 1));
    const getListMapping = async () => {
      let res = await apiAction({
        method: "post",
        navigate: navigate,
        dispatch: dispatch,
        url: list_dynamic_mapping(),
        data: { business_id: getBusinessInfo().id, mapping_for: mapping_for, amount_column_type: amountFieldColumnType },
      });
      if (res?.success) {
        setMappingList(res?.result);
        // dispatch(Actions.stateChange("mapping", null));
      }
    };
    if (mapping_for !== opening_balance) {
      getListMapping();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (mapping) {
      let mapped_data = mapping.mapping;
      let mapped_keys = Object.keys(mapping.mapping);

      // eslint-disable-next-line
      model_fields.map((field) => {
        if (mapped_keys.indexOf(field.db_field) > -1 && file_headers.find((item) => item === mapped_data[field.db_field])) {
          field["file_header"] = mapping.mapping[field.db_field];
        } else {
          delete field["file_header"];
        }
      });
      dispatch(
        Actions.stateChange("mappings", {
          ...mappings,
          mapped_headers: Object.values(mapping.mapping),
        })
      );
      dispatch(Actions.stateChange("model_fields", [...model_fields]));
    }
    // eslint-disable-next-line
  }, [mapping]);

  const onClose = () => {
    setOpen(false);
  };
  const onMappingChangesClose = () => {
    setMappingsChanged(false);
  };

  const statementGenerationFromMappingChanges = async (
    name,
    description,
    isSaveAndContinue
  ) => {
    setMappingsChanged(false);
    let res = await onSubmit(name, description);
    await statementUpload();
  };

  function isDropped(boxName) {
    return mapped_headers.indexOf(boxName) > -1;
  }
  const handleDrop = (index, item) => {
    // console.log('item',index,item)
    // console.log('model_fields',model_fields)
    let is_mapped = model_fields.find((value) => value.file_header === item.name)
    if (is_mapped) {
      console.log('===already mapped', is_mapped)
      stateChangeManager(dispatch, Actions, true, "error", `File Column "${is_mapped.file_header}" is already mapped with Database column "${is_mapped.db_field}"`);
    } else {
      model_fields[index]["file_header"] = item.name;
      dispatch(
        Actions.stateChange("mappings", {
          ...mappings,
          mapped_headers: [
            ...model_fields.filter((item) => item.file_header),
          ].map((i) => i.file_header),
        })
      );
      dispatch(Actions.stateChange("model_fields", [...model_fields]));
    }
  };

  const onDeleteMapping = (index) => {
    mapped_headers.splice(
      mapped_headers.indexOf(model_fields[index]["file_header"]),
      1
    );
    delete model_fields[index]["file_header"];
    dispatch(Actions.stateChange("mappings", { ...mappings, mapped_headers }));
    dispatch(Actions.stateChange("model_fields", [...model_fields]));
  };
  const mapped_items = model_fields.filter((field) => field.file_header);

  const onSubmit = async (name, description) => {
    onClose();
    let body = getMappingBody(name, description);
    let res =
      mapping && !isNewMapping
        ? await apiAction({
          method: "PATCH",
          navigate: navigate,
          dispatch: dispatch,
          url: update_dynamic_mapping(mapping.id),
          data: { ...body },
        })
        : await apiAction({
          method: "post",
          navigate: navigate,
          dispatch: dispatch,
          url: create_dynamic_mapping(),
          data: { ...body },
        });
    if (res?.success) {
      setNewMapping(false);
      dispatch(Actions.stateChange("mapping", res?.result));
      stateChangeManager(dispatch, Actions, true, "success", res?.status);
      dispatch(Actions.stateChange("activeStep", 2));
      if (nextButton) {
        nextButton()
      } else {
        navigate(nextPagePath);
      }
      return res;
    } else {
      stateChangeManager(dispatch, Actions, true, "error", res?.status);
    }
  };
  const setDateFormate = (create_new_mapping) => {
    let value_date = create_new_mapping['Value Date']
    let transaction_date = create_new_mapping['Transaction Date']
    if (mapping_for === sourced_transaction) {
      let old_mapping = mapping ? mapping.mapping : null;
      create_new_mapping = {
        ...create_new_mapping,
        amount_column_type: amountFieldColumnType,
        value_date_format: date_format_dict[value_date] ? date_format_dict[value_date] : old_mapping ? old_mapping.value_date_format : null,
        transaction_date_format: date_format_dict[transaction_date] ? date_format_dict[transaction_date] : old_mapping ? old_mapping.transaction_date_format : null
      }
    }
    return create_new_mapping
  }

  const getMappingBody = (name, description) => {
    let mappings_body = {};
    let create_new_mapping = createMappings(model_fields);

    mappings_body["name"] = name;
    mappings_body["mapping_for"] = mapping_for;
    mappings_body["description"] = description;
    mappings_body["business_id"] = getBusinessInfo().id;
    mappings_body["mapping"] = setDateFormate(create_new_mapping);
    if (mapping) {
      mappings_body["id"] = mapping.id;
    }
    return mappings_body;
  };
  const isMappingChanged = () => {
    let create_new_mapping = setDateFormate(createMappings(model_fields));
    const isMappingDifferentObj = !compareMappings(mapping && mapping.mapping, create_new_mapping);
    const isMappingDifferentStr = mapping && JSON.stringify(mapping.mapping, 0, 2) !== JSON.stringify(create_new_mapping, 0, 2);
    return (mapping_for === sourced_transaction && create_new_mapping.transaction_date_format) ? isMappingDifferentObj : isMappingDifferentStr;
    // return isMappingDifferentStr
  };


  const statementUpload = async () => {
    var formdata = {};
    formdata["mapping_id"] = mapping.id;
    formdata["file"] = mappings.uploadedFile;
    formdata["business_id"] = getBusinessInfo().id;

    let res = await apiActionFormData({
      url: transaction_bulk_upload(),
      method: "post",
      data: formdata,
    });
    if (res?.success) {
      if (nextButton) {
        nextButton()
      } else {
        navigate(nextPagePath);
      }
      stateChangeManager(dispatch, Actions, true, "success", res?.result);
    }
  };

  const isRequiredFieldsMapped = () => {
    const requiredModelFields = model_fields.filter(
      (field) => field.is_required && field.for_display
    );
    return requiredModelFields.find(
      (field) => field.file_header === undefined || field.file_header === null
    )
      ? false
      : true;
  };

  // console.log("======>",'mapped_headers=>',mapped_headers.length === 0, 'isMappingChanged',!isMappingChanged(), 'isRequiredFieldsMapped',isRequiredFieldsMapped(), 'mapped_headers',mapped_headers)

  return (
    <div style={{ height: "100%", }}>
      <SaveMapping
        open={open}
        onClose={onClose}
        onSubmit={onSubmit}
        mapping={mapping}
        isNewMapping={isNewMapping}
      />
      <Grid sm={12} container item style={{ backgroundColor: "white", padding: 10, marginBottom: 10 }}>
        <Grid sm={12} item>
          <HorizontalLinearStepper />
        </Grid>
      </Grid>


      <div style={{ background: '#FFFFFF', }}>
        <Grid container sx={{ pl: 1, pr: 1 }}>
          <Grid sm={4} item>
            <CustomLabel
              title='Mapping List'
              titleVisibility='hidden'
            />
            {mappingList.length > 0 && (
              <CustomDropdown
                id={"mapping_dropdown"}
                dataTestId={"mapping_dropdown"}
                value={mapping}
                results={mappingList}
                onChange={(event, newMapping) => {
                  console.log("newMapping ", newMapping);
                  if (newMapping) {
                    dispatch(Actions.stateChange("mapping", newMapping));
                  } else {
                    dispatch(Actions.stateChange("mapping", newMapping));
                    model_fields.map((item) => {
                      delete item.file_header;
                    });
                    dispatch(
                      Actions.stateChange("mappings", {
                        ...mappings,
                        mapped_headers: [],
                      })
                    );
                    dispatch(
                      Actions.stateChange("model_fields", [...model_fields])
                    );
                  }
                }}
                placeholder="Select the Mapping"
                addButton={mapping ? {
                  title: "+ Add New ",
                  onClick: () => {
                    if (isRequiredFieldsMapped()) {
                      setNewMapping(true);
                      setOpen(true);
                    }
                  },
                } : false}
              />
            )}
          </Grid>

          <Grid item sm={8} sx={{ mt: 2.8, justifyContent: 'end', textAlign: 'end' }}>

            {mapping ? (
              mapping_for !== opening_balance &&
              <CustomButton
                fullWidth
                variant="contained"
                id={"mapping_update_btn"}
                dataTestId={"mapping_update_btn"}
                btnLabel={"Update Mapping"}
                onClick={() => {
                  setOpen(true);
                }}
                disabled={
                  mapped_headers.length === 0 ||
                  !isMappingChanged() ||
                  !isRequiredFieldsMapped()
                }
                sx={{
                  marginLeft: 1,
                  width: "150px",
                  height: "33px",
                  textTransform: "none",
                  visibility: mapping_for !== opening_balance ? "visible" : "hidden",
                }}
              />

            ) : (
              mapping_for !== opening_balance &&
              <CustomButton
                fullWidth
                variant="contained"
                id={"mapping_save_btn"}
                btnLabel={"Save Mapping"}
                dataTestId={"mapping_save_btn"}
                onClick={() => {
                  setOpen(true);
                }}
                disabled={mapped_headers.length === 0 || !isRequiredFieldsMapped()}
                sx={{
                  marginLeft: 1,
                  width: "150px",
                  height: "33px",
                  textTransform: "none",
                  visibility: mapping_for !== opening_balance ? "visible" : "hidden",
                }}
              />
            )}

            {mapping_for !== opening_balance &&
              <CustomButton
                fullWidth
                btnLabel={"Next"}
                variant="contained"
                id={"mapping_next_btn"}
                dataTestId={"mapping_next_btn"}
                disabled={
                  mapped_headers.length === 0 ||
                  mapping === null ||
                  isMappingChanged()
                }
                sx={{
                  width: "100px",
                  height: "33px",
                  marginLeft: 1,
                  textTransform: "none",
                  visibility: mapping_for !== opening_balance ? "visible" : "hidden",
                }}
                onClick={() => {
                  dispatch(Actions.stateChange("activeStep", 2));
                  if (nextButton) {
                    nextButton()
                  } else {
                    navigate(nextPagePath);
                  }
                }}
              />
            }
            {mapping_for === opening_balance &&
              <CustomButton
                fullWidth
                variant="contained"
                btnLabel={"Next"}
                id={"mapping_next_btn_1"}
                dataTestId={"mapping_next_btn_1"}
                disabled={mapping_for === opening_balance && !isRequiredFieldsMapped()}
                sx={{
                  textTransform: "none",
                  width: "100px",
                  height: "33px",
                  marginLeft: 1,
                  visibility: mapping_for === opening_balance ? "visible" : "hidden",
                }}
                onClick={() => {
                  dispatch(Actions.stateChange("activeStep", 2));
                  if (nextButton) {
                    nextButton()
                  } else {
                    navigate(nextPagePath);
                  }
                }}
              />
            }
          </Grid>
        </Grid>
      </div>

      <CustomContainer maxWidth={"400"} sx={{ height: 'calc(100vh - 184px)', }}>
        <Grid container xs={12} item>
          <Grid xs={1} item></Grid>
          <Grid
            xs={12}
            item
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <Grid item sm={4} sx={{}}>
              <Card sx={{ margin: 1, height: viewPort }}>
                <CardHeader
                  sx={{ textAlign: "center", padding: "5px" }}
                  title="Columns in uploaded file"
                  subheader="Columns available in file"
                ></CardHeader>
                <Divider />
                <CardContent>
                  <div style={{ height: contentViewport, overflow: "scroll" }}>
                    {file_headers.map((name, index) => {
                      let selected_date = dateTimeFormatList.find((item) => item.selectDateTimeFormat === date_format_dict[name])

                      let nameFormat = selected_date ? `Date format-[${selected_date.represent_date}]` : ''
                      return (
                        <Stack direction={"column"} key={index}>
                          <Box
                            key={index}
                            name={name}
                            type={ItemTypes.Box}
                            isDropped={isDropped(name)}
                            label={<span>
                              {name}
                              {
                                isDropped(name) ?
                                  <s style={{ fontSize: '12px', paddingLeft: '10px' }}>{nameFormat}</s>
                                  : <span style={{ fontSize: '12px', paddingLeft: '10px' }}>{nameFormat}</span>
                              }
                            </span>}
                          />
                        </Stack>
                      )
                    })}
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item sm={4} sx={{}}>
              <Card sx={{ margin: 1, height: viewPort }}>
                <CardHeader
                  sx={{ textAlign: "center", padding: "5px" }}
                  title="Columns in Database"
                  subheader="Columns available in database"
                ></CardHeader>
                <Divider />
                <CardContent>
                  <div
                    style={{
                      height: contentViewport,
                      overflow: "scroll",
                      clear: "both",
                    }}
                  >
                    {model_fields.map((field, index) => {
                      return field.for_display ? (
                        <Stack direction={"column"} key={index}>
                          <Dustbin
                            model_field={field}
                            index={index}
                            onDeleteMapping={onDeleteMapping}
                            accept={ItemTypes.Box}
                            onDrop={(item) => handleDrop(index, item)}
                            key={index}
                          />
                        </Stack>
                      ) : null;
                    })}
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item sm={4} sx={{}}>
              <Card sx={{ margin: 1, height: viewPort }}>
                <CardHeader
                  sx={{ textAlign: "center", padding: "5px" }}
                  title="Mapping of Columns File - Database"
                  subheader="Corresponding columns from file and database"
                ></CardHeader>
                <Divider />
                <CardContent>
                  <div style={{
                    height: contentViewport,
                    overflow: "scroll"
                  }}>
                    {mapped_items.map((item, index) => {
                      return (
                        <Stack direction={"column"} key={index}>
                          <div
                            // key={index}
                            style={{
                              textTransform: "capitalize",
                              border: "1px gray",
                              borderRadius: "30px",
                              padding: "0.3rem 1.5rem",
                              marginRight: "1.5rem",
                              marginBottom: "1rem",
                              float: "left",
                              backgroundColor: "#fae6f3",
                              width: "80%",
                              textAlign: "center",
                            }}
                          >
                            {item.file_header} - {item.verbose_name}
                          </div>
                        </Stack>
                      );
                    })}
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid xs={1} item></Grid>
        </Grid>

      </CustomContainer>
    </div>
  );
});

export default Mappings;
