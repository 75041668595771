import * as React from 'react';
import CustomTypography from '../typography/CustomTypography';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import {
    Box,
    Grid,
    Stack,
    Pagination,
    PaginationItem,
} from '@mui/material';
import { styled } from '@mui/material/styles';


const CustomPagination = (props) => {
    const { page, count, onChange } = props

    React.useEffect(()=>{
        if (count<page) {   
            onChange('', 1)
        }
    },[count]);
    return (
        <PaginationContainer sx={{}}>
            <Grid container sx={{}}>
                <Grid item xs={8} sm={10}>
                    <Stack spacing={2}
                        sx={{
                            textAlign: 'Center',
                            alignItems: 'center',
                        }}
                    >
                        <Pagination
                            page={page}
                            size='small'
                            count={count}
                            color="primary"
                            shape="rounded"
                            onChange={onChange}
                            renderItem={(item) => (
                                <PaginationItem
                                    components={{ previous: ArrowBackIosIcon, next: ArrowForwardIosIcon }}
                                    {...item}
                                />
                            )}
                        />
                    </Stack>
                </Grid>
                <Grid item xs={4} sm={2}
                    sx={{
                        textAlign: 'right',
                        alignItems: 'center',
                    }}>
                    <CustomTypography
                        text={<span>Page : {page} of {count}</span>}
                        sx={{
                            color: '#8F8F8F',
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '19px',
                            fontStyle: 'normal',
                            textTransform: 'none',
                            fontFamily: "Noto Sans",
                        }}
                    />
                </Grid>
            </Grid>
        </PaginationContainer>
    );
}

export default CustomPagination;

export const PaginationContainer = styled((props) => <Box {...props} />)(({ theme }) => ({
    padding: '16px',
    marginTop: '8px',
    paddingTop: '10px',
    paddingBottom: '0px',
    maxHeight: '50px !important',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

