import * as React from 'react';
import validator from 'validator';
import { apiAction } from '../../../api/api';
import { images } from '../../../utils/Constant';
import * as Actions from '../../../state/Actions';
import { routesName } from '../../../config/routesName';
import CustomInput from '../../custom/input/CustomInput';
import CustomTypography from '../../custom/typography/CustomTypography';
import { eventsNames, AnalyticsEvent } from '../../../firebase/firebaseAnalytics';

import {
  setLoader,
  isFormValid,
  stateChangeManager,
} from '../../../utils/Utils';

import {
  userSignIn,
  get_google_login,
} from '../../../api/urls';

import {
  setToken,
} from '../../../config/cookiesInfo';

import {
  Box,
  Link,
  Grid,
  Alert,
  Button,
  styled,
  Checkbox,
  Typography,
} from '@mui/material';

function Copyright(props) {
      // Get the current host
      const currentHostname = window.location.host;

      // Get the origin link
      let newHref = '';
      if (window.location.origin) {
          newHref = window.location.origin;
      } else {
          newHref = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
      }
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link id='copy_right_link' dataTestId='copy_right_link' color="inherit" href={newHref} target='_blank'>
        www.{currentHostname}.com
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '98%',
  maxHeight: '98%',
  alignItems: 'center',

});

const ConnectAccountWithSocialAccount = () => {
  const dispatch = Actions.getDispatch(React.useContext);
  const [agree, setAgree] = React.useState(true);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let formData = {
      email: data.get("email"),
      password: data.get("password")
    }

    let validation_data = [
      { key: "email", message: 'Email field can not be empty' },
      { key: "password", message: 'Password field can not be empty' },
      { key: '', validation: (!formData.email || !validator.isEmail(formData.email)), message: 'Please enter valid email.' },
      { key: "", validation: !agree, message: "Please accept the terms and conditions." },
    ]
    const { isValid, message } = isFormValid(formData, validation_data)

    if (isValid) {
      AnalyticsEvent(eventsNames.categories.USER_AUTHENTICATION, { action: eventsNames.actions.SOCIAL_CONNECT});
      setLoader(dispatch, Actions, true);
      let res = await apiAction({
        method: 'post',
        url: userSignIn(),
        data: {
          email: data.get("email"),
          password: data.get("password"),
          is_privacy_policy_and_terms_accepted: true
        }
      })
      if (res?.success) {
        setToken(res?.data?.access_token);
        stateChangeManager(dispatch, Actions, true, 'success', res?.status);
        setLoader(dispatch, Actions, false);
        onClickedGoogleLogin();

      } else {
        setLoader(dispatch, Actions, false);
        stateChangeManager(dispatch, Actions, true, 'error', res?.status)
      }
    } else {
      stateChangeManager(dispatch, Actions, true, 'error', message);

    }
  };

  const onClickedGoogleLogin = () => {
    const link = document.createElement("a");
    link.href = get_google_login();
    document.body.appendChild(link);
    link.click();
  }
  return (
    <div>
      <Box sx={{ display: "flex" }}>
        <Box className='m_login_left_box' sx={{ backgroundColor: '#F7F7F7', minHeight: '100%', width: '28%', display: 'block' }}>
          <Box
            sx={{
              pt: 8,
              minHeight: "100vh",
              minWidth: '100vh',
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                p: 2,
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: "center",
              }}
            >
              <Img sx={{ pt: 1.5, ml: 20 }} alt={images.login.alt} src={images.login.src} />
            </Box>
          </Box>

        </Box>
        <Box sx={{ height: '100%', width: '100%' }}>
          <Box
            className='m_login_container'
            sx={{
              pt: 8,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                p: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: "center",
                flexDirection: 'column',
              }}>
              <Box sx={{ mb: 2 }}>
                <Alert severity="info" sx={{}}>
                  We've found an existing account with the email address associated with your social account.<br /> To link the two accounts,please login with the email and password associated with your existing account.
                </Alert>
              </Box>
              <Box component="form" onSubmit={handleSubmit} noValidate>
                <CustomTypography
                  text={'Account Login'}
                  sx={{
                    mb: 4,
                    mt: 4,
                    color: '#000000',
                    fontSize: '30px',
                    textAlign: 'start',
                    fontWeight: '700',
                    fontStyle: 'normal',
                    lineHeight: '40.86px',
                    fontFamily: "Noto Sans",
                  }}
                />
                <CustomTypography
                  text={'Email Address'}
                  sx={{
                    mb: 1,
                    color: '#000000',
                    fontSize: '14px',
                    fontWeight: '700',
                    lineHeight: '18px',
                    fontStyle: 'normal',
                    fontFamily: "Noto Sans",
                    textTransform: 'capitalize',
                  }}
                />
                <CustomInput
                  required
                  fullWidth
                  id="email"
                  name="email"
                  autoFocus={true}
                  dataTestId="email"
                  autoComplete="email"
                />
                <CustomTypography
                  text={'Password'}
                  sx={{
                    mt: 2,
                    mb: 1,
                    color: '#000000',
                    fontSize: '14px',
                    fontWeight: '700',
                    lineHeight: '18px',
                    fontStyle: 'normal',
                    fontFamily: "Noto Sans",
                    textTransform: 'capitalize',
                  }}
                />
                <CustomInput
                  required
                  fullWidth
                  id="password"
                  name="password"
                  type="password"
                  dataTestId="password"
                  autoComplete="current-password"
                />
             <CustomTypography
                    text={<span><Checkbox size="small" sx={{ ml: -1.5, mt: -.01 }} checked={agree} onClick={() => { setAgree(!agree) }} /><span>By sign in, I agree to the finycs <Link href={routesName.privacyPolicy.path} variant="body2" target="_blank" rel="noreferrer">Privacy Policy</Link> and <Link href={routesName.termsAndServices.path} variant="body2" target="_blank" rel="noreferrer">Terms and Services</Link>.</span></span>}
                    sx={{
                      mb: 2,
                      mt: 1,
                      color: '#000000',
                      fontSize: '14px',
                      textAlign: 'start',
                      fontWeight: '500',
                      fontStyle: 'normal',
                      lineHeight: '40.86px',
                      fontFamily: "Noto Sans",
                    }}
                  />
                <Button
                  id='connect_btn'
                  dataTestId='connect_btn'
                  fullWidth
                  type="submit"
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 3,
                    color: 'white',
                    // background: '#dd4b39',
                    textTransform: 'none',
                    // '&:hover': {
                    //   backgroundColor: '#dd4b39', opacity: 1
                    // }
                  }}
                >Connect</Button>

                <Grid container spacing={2} sx={{ pt: 3 }}>
                  <Grid item xs>
                    <Link id='forgot_password_link' dataTestId='forgot_password_link' href={"/auth" + routesName.restPassword.path} variant="body2">
                      Forgot password?
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link id='sign_up_link' dataTestId='sign_up_link' href={"/auth" + routesName.signUp.path} variant="body2">
                      {"Don't have an account? Sign up"}
                    </Link>
                  </Grid>
                </Grid>
                <Copyright sx={{ mt: 8, mb: 4 }} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  )
}

export default ConnectAccountWithSocialAccount;