import { stateChangeManager } from "../../../../../utils/Utils";
import { journalEntryKeys } from "../../../../../utils/Constant";


export const onChangeInputHandlerManager = (index, key, value, disableKey, data) => {
    data[index]['validation'] = false
    data[index][key] = value

    if (key === journalEntryKeys.debit || key === journalEntryKeys.credit) {
        data[index][disableKey] = true
        if (value === '') {
            data[index][disableKey] = false
        }
    }

    return data
}

export const addTransactionManager = (data, newData) => {
    data.push(newData)
    return [...data]

}

export const removeTransactionManager = (index, data) => {
    data.splice(index, 1)
    return [...data]

}

export const tallyTransaction = (data) => {
    let credit = []
    let debit = []
    // eslint-disable-next-line
    data.map((item, index) => {
        credit.push(Number(item.credit))
        debit.push(Number(item.debit))
    })
    return { debit, credit }
}

export const createTransactionBody = (data) => {
    let transactions = []
    // eslint-disable-next-line
    data.map((item) => {
        if (item.account) {
            let tem = {
                currency_id: item.currency.id,
                business_account_id: item.account.id,
                amount: item.creditDisable ? Number(item.debit) : Number(item.credit),
                transaction_type: item.creditDisable ? 'DEBIT' : 'CREDIT'
            }
            transactions.push(tem)
        }
    })
    return transactions
}

export const upDateTransactionBody = (data) => {
    let transactions = []
    // eslint-disable-next-line
    data.map((item) => {
        let temp = {
            validation: false,
            debit: item.transaction_type === 'DEBIT' ? item.amount : '',
            credit: item.transaction_type === 'CREDIT' ? item.amount : '',
            debitDisable: item.transaction_type === 'CREDIT' ? true : false,
            creditDisable: item.transaction_type === 'DEBIT' ? true : false,
            currency: { currency_code: item.currency, id: item.currency_id },
            account: item.account ? null : { account_name: item.business_account, id: item.business_account_id,parent_account_name:item.parent_account }
        }
        transactions.push(temp)
    })
    return transactions
}

export const createJournalEntryValidation = (data, setState, Actions) => {

    var isValid = true
    if (data.description === '') {
        stateChangeManager(setState, Actions, true, 'error', 'Description field can not be empty')
        isValid = false
    } else if (data.amount === '') {
        stateChangeManager(setState, Actions, true, 'error', 'Amount field can not be empty')
        isValid = false
    } else if (data.data) {
        // eslint-disable-next-line
        data.data.map((item, index) => {
            if (!item.account) {
                stateChangeManager(setState, Actions, true, 'error', 'Account field can not be empty')
                isValid = false
            } else if (!item.creditDisable & !item.debitDisable) {
                data.data[index]['validation'] = true
                stateChangeManager(setState, Actions, true, 'error', 'Debit or Credit field can not be empty')
                isValid = false
            } else if (data.data.length > index + 1) {
                if (data.data[index + 1].account) {
                    if (data.data[index]?.account.id === data.data[index + 1].account.id) {
                        stateChangeManager(setState, Actions, true, 'error', 'Accounts can not be same!')
                        isValid = false
                    }
                }
            }
        })

    }
    return isValid
}
