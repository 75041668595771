import React from 'react';
import moment from 'moment';
import { Box, Grid } from '@mui/material';
import { apiAction } from '../../../../api/api';
import { useNavigate } from 'react-router-dom';
import DateRange from '../../../common/DateRange';
import * as Actions from '../../../../state/Actions';
import { getDateFormat } from '../../../../utils/Utils';
import { stateChangeManager } from '../../../../utils/Utils';
import { getBusinessInfo } from '../../../../config/cookiesInfo';
import CurrencyFormatter from '../../../common/CurrencyFormatter';
import CustomHighChart from '../../../custom/chart/CustomHighChart';
import CustomTypography from '../../../custom/typography/CustomTypography';
import { CustomDataPickerPopover } from '../../../custom/Datepicker/CustomDataPickerPopover';

const CashflowComponents = (props) => {
    const { id, dataTestId, url, date_range, custom_date_range } = props
    const updatedCustomDateRange = custom_date_range.filter(obj => !["Today", "Yesterday"].includes(obj.name));

    let navigate = useNavigate();

    const dispatch = Actions.getDispatch(React.useContext);

    const DropdownRef = React.useRef(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [dates, setDates] = React.useState(updatedCustomDateRange[3]?.dates);
    const [selectedDateRange, setSelectedDateRange] = React.useState(updatedCustomDateRange[3]);

    const [result, setResult] = React.useState();
    const [data, setData] = React.useState([])

    React.useEffect(() => {
        if (url) {
            getApiResults(url)
        }
    }, [url, dates])


    const getApiResults = async (url) => {
        let res = await apiAction({
            url: url,
            method: 'post',
            navigate: navigate,
            dispatch: dispatch,
            data: { business_id: getBusinessInfo().id, ...dates },
        })
        if (res?.success) {
            setResult(res?.result)
            setData(aggregateDataByPeriod(res?.result.cash_flow,dates.from_date, dates.to_date))
        } else {
            stateChangeManager(dispatch, Actions, true, "error", res?.status);
        }
    }

    const aggregateDataByPeriod = (data, from_date, to_date) => {
    
        const aggregatedData = {};
        const fromDate = moment(from_date);
        const toDate = moment(to_date);
    
        // Determine the duration between from_date and to_date
        const duration = toDate.diff(fromDate, 'days');
    
        // Define the format for grouping based on the duration
        let format;
        if (duration <= 7) {
            format = { unit: "day", format: "DD MMM YY" }; // Group by day, display date with month and year
        } else if (duration <= 31) {
            format = { unit: "day", format: "DD MMM" }; // Group by day, display date with month
        } else if (duration <= 93) {
            format = { unit: "month", format: "MMM YY" }; // Group by month, display month and year
        } else if (duration <= 365) {
            format = { unit: "month", format: "MMM YY" }; // Group by month, display month and year
        } else {
            format = { unit: "year", format: "YYYY" }; // Group by year, display year
        }
    
        data.forEach(entry => {
            const period = moment(entry.start_date).format(format.format);
            const groupKey = moment(entry.start_date).startOf(format.unit).format(format.format);
    
            if (aggregatedData[groupKey]) {
                aggregatedData[groupKey].incoming += entry.incoming ? parseFloat(entry.incoming) : 0;
                aggregatedData[groupKey].outgoing += entry.outgoing ? parseFloat(entry.outgoing) : 0;
                aggregatedData[groupKey].closing_balance = entry.closing_balance; // Update to the latest closing balance within the period
            } else {
                aggregatedData[groupKey] = {
                    incoming: entry.incoming ? parseFloat(entry.incoming) : 0,
                    outgoing: entry.outgoing ? parseFloat(entry.outgoing) : 0,
                    opening_balance: entry.opening_balance,
                    closing_balance: entry.closing_balance,
                    start_date: entry.start_date,
                    end_date: entry.end_date,
                    period: period
                };
            }
        });
        return Object.values(aggregatedData);
    };
      
    return (
        <div>
            <Box>
                <CustomDataPickerPopover
                    anchorEl={anchorEl}
                    customDate={dates}
                    setAnchorEl={setAnchorEl}
                    setCustomDate={setDates}
                />
                <Box sx={{ pb: 2, borderBottom: '2px solid #F5F5F5' }}>
                    <Grid container style={{
                        alignItems: 'center',
                        justifyItems: 'center',
                        justifyContent: 'space-evenly',
                        alignContent: 'space-evenly',
                    }}>
                        <Grid item xs={4} sm={6}>
                            <CustomTypography
                                text={'Cash Flow'}
                                sx={{
                                    color: '#000000',
                                    display: 'inline',
                                    fontSize: '16px',
                                    fontWeight: '700',
                                    lineHeight: '18px',
                                    fontStyle: 'normal',
                                    fontFamily: "Noto Sans",
                                }}
                            />
                        </Grid>
                        <Grid item xs={8} sm={6} sx={{ textAlign: '-webkit-right' }} >
                            <DateRange
                                label=''
                                id={id + '_dropdown'}
                                sx={{ width: 200 }}
                                optionLabel={'name'}
                                inputRef={DropdownRef}
                                disableClearable={true}
                                value={selectedDateRange}
                                results={updatedCustomDateRange}
                                dataTestId={dataTestId + '_dropdown'}
                                setValue={(event, newDateRange) => {
                                    if (newDateRange.dates) {
                                        setAnchorEl(null);
                                        setDates(newDateRange.dates);
                                        setSelectedDateRange(newDateRange);
                                    } else {
                                        setAnchorEl(DropdownRef.current);
                                        setSelectedDateRange(newDateRange);
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>

                <Box sx={{ pt: 1, pb: 1, }}>
                    <Grid container>
                        <Grid item xs={12} sm={9} sx={{ p: 1, }}>
                            <CustomHighChart
                                data={data}
                                color={"#2464EB"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} sx={{ background: '#FFFCF0', textAlign: 'left' }}>
                            <Box sx={{ p: 4 }}>
                                <CashFlowDetails head={result ? `Cash as on ${moment(result.total.start_date).format(getDateFormat())}` : 'Cash as on'} value={result ? result.total.opening_balance : 0} color={'#7A7A7A'} />
                                <CashFlowDetails head={'Incoming'} value={result ? result.total.incoming : 0} color={'#049735'} />
                                <CashFlowDetails head={'Outgoing'} value={result ? result.total.outgoing : 0} color={'#ED0F1C'} />
                                <CashFlowDetails head={result ? `Cash as on ${moment(result.total.end_date).format(getDateFormat())}` : 'Cash as on'} value={result ? result.total.closing_balance : 0} color={'#2464EB'} />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

            </Box>
        </div>
    )
}


const CashFlowDetails = (props) => {
    const { head, value, color } = props;
    return (
        <Box sx={{ mb: 4 }}>
            <CustomTypography
                text={head}
                sx={{
                    mb: 1,
                    color: { color },
                    fontSize: '12px',
                    fontWeight: '500',
                    fontStyle: 'normal',
                    lineHeight: '16.34px',
                    fontFamily: "Noto Sans",
                }} />
            <CustomTypography
                text={<CurrencyFormatter amount={value} />}
                sx={{
                    color: '#000000',
                    fontSize: '14px',
                    fontWeight: '700',
                    fontStyle: 'normal',
                    lineHeight: '19.07px',
                    fontFamily: "Noto Sans",
                }}
            />
        </Box>
    )
}

export default CashflowComponents;