import React, { useContext, useEffect } from "react";
import { AppLogo } from "../../../../common/AppLogo";
import * as Actions from "../../../../../state/Actions";
import { sidebarMenu } from "../../../../../config/routes";
import { getQueryParams } from '../../../../../utils/Utils';
import { routesName } from "../../../../../config/routesName";
import { Link, useLocation, useParams } from 'react-router-dom';
import { resetFileImports } from "../../../../../state/Actions";
import {eventsNames, AnalyticsEvent } from "../../../../../firebase/firebaseAnalytics";
import CustomTypography from "../../../../../components/custom/typography/CustomTypography";

import {
    setFilterMessage,
} from '../../../../../utils/Utils';

import {
    getUserDetails,
} from "../../../../../config/cookiesInfo";


import {
    Box,
    Grid,
    Divider,
    Collapse,
    IconButton,
} from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


export const _Sidebar = (props) => {
    const { navigate, setOpen, open, setForMobile, CustomTooltip } = props;

    const params = useParams();
    const location = useLocation();
    let user_details = getUserDetails();
    const dispatch = Actions.getDispatch(useContext);
    const [activeItem, setActiveItem] = React.useState(routesName.dashboard.activeRoute);

    var currentPath = location.pathname;
    var pathSegments = currentPath.split('/');
    var lastSegment = pathSegments[pathSegments.length - 1];
    if (!isNaN(lastSegment)) {
        pathSegments.pop();
    }
    var newPath = pathSegments.join('/');

    useEffect(() => {
        let pathname = Object.values(routesName).find((item) => item.path === newPath);
        let currentRoute = Object.keys(routesName).find((routeKey) => {
            return pathname && routesName[routeKey].path === pathname.path
        })

        if (currentRoute) {
            setActiveItem(routesName[currentRoute].activeRoute)
        }
        setFilterMessage(dispatch, Actions, null);
    }, [location.pathname])

    const onRouteClick = (route) => {
        if (route.to === routesName.fileUpload.path) {
            resetFileImports(dispatch)
        }
        if (route.to === routesName.chartsAccountList.path) {
            dispatch(Actions.stateChange('chartofAccountTabValue', 0))
        }
        navigate(route.to);
        setForMobile(false);
        setActiveItem(route.title);
        AnalyticsEvent(eventsNames.categories.SIDEBAR,{action:eventsNames.actions.CLICK, item:route.eventName})
    }

    const [collapse, setCollapse] = React.useState(false)

    return (
        <Box
            className='sidebar'
            sx={{ width: '100%', backgroundColor: '#FFFFFF', boxShadow: '1px 0px 4px rgba(0, 0, 0, 0.04)', zIndex: 1 }}>

            {/* Represent the logo of application */}

            <Box style={{ height: 64, paddingLeft: 0, background: '#FFFFFF', borderBottom: '1px solid #EBEBEB', cursor: 'pointer' }} onClick={() => navigate(routesName.dashboard.path)}>
                <AppLogo open={open} />
            </Box>

            <Box
                sx={{ height: props.height, backgroundColor: '#FFFFFF', overflowY: 'scroll', }}
            >

                {
                    sidebarMenu.map((route, index) => {
                        return (
                            <React.Fragment key={index}>
                                {route.items.length === 0 ?
                                    <React.Fragment>
                                        <Box sx={{ display: 'flex', paddingLeft: 0.5, paddingRight: 2, paddingTop: 2, paddingBottom: 2 }}>
                                            <Box
                                                sx={{ mt: -0.4, backgroundColor: '#2464EB', width: 5, height: 20, marginLeft: 0.2, borderRadius: 6, visibility: activeItem === route.title ? "visible" : "hidden" }}
                                            >
                                            </Box>
                                            <CustomTypography
                                                id={'side_bar_' + index}
                                                dataTestId={'side_bar_' + index}
                                                text={<CustomTooltip title={route.title} placement="right">{route.icon}</CustomTooltip>}
                                                onClick={() => { onRouteClick(route); setCollapse(false) }}
                                                sx={{
                                                    mt: -1,
                                                    paddingLeft: 1,
                                                    paddingTop: 0.3,
                                                    fontSize: '12px',
                                                    display: 'inline',
                                                    cursor: 'pointer',
                                                    lineHeight: '12px',
                                                    letterSpacing: '0.5px',
                                                    fontWeight: activeItem === route.title ? 700 : 500,
                                                    color: activeItem === route.title ? '#2464EB' : '#A3A3A3',
                                                    '&:hover': !open && {
                                                        color: '#2464EB',
                                                        fontSize: '12px',
                                                    },
                                                }}
                                            />
                                            {open &&
                                                <CustomTypography
                                                    id={'side_bar_' + index}
                                                    dataTestId={'side_bar_' + index}
                                                    to={route.to}
                                                    component={Link}
                                                    text={route.title}
                                                    onClick={() => { onRouteClick(route); setCollapse(false) }}
                                                    sx={{
                                                        paddingLeft: 1,
                                                        paddingTop: 0.3,
                                                        fontSize: '14px',
                                                        cursor: 'pointer',
                                                        lineHeight: '12px',
                                                        textDecoration: 'none',
                                                        fontWeight: activeItem === route.title ? 700 : 500,
                                                        color: activeItem === route.title ? '#2464EB' : '#141414',
                                                        '&:hover': {
                                                            opacity: 1,
                                                            color: '#2464EB',
                                                            fontSize: '14px',
                                                        },
                                                    }}
                                                />
                                            }
                                        </Box>
                                        <Divider
                                            sx={{
                                                height: '1px',
                                                marginLeft: 2,
                                                marginRight: 2,
                                                border: "none",
                                                bgcolor: '#F5F5F5',
                                            }}
                                        />
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <CollapseContentData
                                            open={open}
                                            index={index}
                                            route={route}
                                            setOpen={setOpen}
                                            collapse={collapse}
                                            activeItem={activeItem}
                                            onRouteClick={onRouteClick}
                                            collapseIn={collapse === index}
                                            CustomTooltip={CustomTooltip}
                                            setCollapse={(id) => setCollapse(id)}
                                        />
                                        <Divider
                                            sx={{
                                                height: '1px',
                                                marginLeft: 2,
                                                marginRight: 2,
                                                border: "none",
                                                bgcolor: '#F5F5F5',
                                            }}
                                        />
                                    </React.Fragment>
                                }

                            </React.Fragment>
                        )
                    })
                }
            </Box>



        </Box>
    )
}

const CollapseContentData = (props) => {
    const { collapseIn, collapse, open, setOpen, index, route, onRouteClick, activeItem, CustomTooltip, setCollapse } = props;

    const [collapseIndex, setCollapseIndex] = React.useState();

    const onCollapseHandler = (value) => {
        setOpen(true);
        setCollapse(collapse === value ? false : value);
    }
    const active_collapse = route.items.find((item) => item.title === activeItem);
    React.useEffect(() => {
        if (!open) {
            setCollapse(false)
        }
        if (open && active_collapse) {
            setCollapseIndex(collapseIndex);
            setCollapse(collapse === false ? collapseIndex : collapse);
        }
    }, [open])

    React.useEffect(() => {
        if (active_collapse) {
            setOpen(true);
            setCollapse(index);
            setCollapseIndex(index);
        } else {
            let tem_active = route.items.find((item) => item.title === activeItem)
            setCollapseIndex(false);
            if (open && tem_active) {
                setCollapse(false)
            }
        }
    }, [active_collapse])

    return (
        <React.Fragment key={index} >

            <Box
                sx={{ display: 'flex', paddingLeft: 0.5, paddingRight: 2, paddingTop: 2, paddingBottom: 0.5 }}
            >
                <Box
                    sx={{ backgroundColor: '#2464EB', width: 5, height: 20, marginLeft: 0.2, borderRadius: 6, visibility: "hidden", }}

                >
                </Box>
                <CustomTypography
                    text={<CustomTooltip title={route.title} placement="right">{route.icon}</CustomTooltip>}
                    onClick={() => { onCollapseHandler(index) }}
                    id={'side_bar_open_' + index}
                    dataTestId={'side_bar_open_' + index}
                    sx={{
                        mt: -1,
                        paddingLeft: 1,
                        paddingTop: 0.3,
                        fontWeight: 500,
                        fontSize: '12px',
                        display: 'inline',
                        cursor: 'pointer',
                        lineHeight: '12px',
                        letterSpacing: '0.5px',
                        color: collapseIndex === index ? '#2464EB' : '#A3A3A3',
                        '&:hover': !open && {
                            color: '#2464EB',
                            fontSize: '12px',
                        },
                    }}
                />
                {open &&
                    <React.Fragment >
                        <Grid container sx={{ m: 0, p: 0, cursor: 'pointer', display: 'flex', flexWrap: 'wrap' }} onClick={() => { onCollapseHandler(index) }} >
                            <Grid item xs={6}>
                                <CustomTypography
                                    id={'side_bar_open_title_' + index}
                                    dataTestId={'side_bar_open_title_' + index}
                                    text={route.title}
                                    onClick={() => { onCollapseHandler(index) }}
                                    sx={{
                                        paddingLeft: 1,
                                        paddingTop: 0.3,
                                        fontWeight: 500,
                                        fontSize: '14px',
                                        cursor: 'pointer',
                                        lineHeight: '12px',
                                        color: collapseIndex === index ? '#2464EB' : '#141414',
                                        '&:hover': {
                                            color: '#2464EB',
                                            fontSize: '14px',
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} sx={{ textAlign: 'end', }}>
                                <CustomTypography
                                    id={'side_bar_open_icon_' + index}
                                    dataTestId={'side_bar_open_icon_' + index}
                                    text={
                                        collapseIn ?
                                            <ArrowDropUpIcon fontSize='small' sx={{ cursor: 'pointer' }} onClick={() => onCollapseHandler(index)} />
                                            :
                                            <ArrowDropDownIcon fontSize='small' sx={{ cursor: 'pointer' }} onClick={() => onCollapseHandler(index)} />
                                    }
                                    onClick={() => { onCollapseHandler(index) }}
                                    sx={{
                                        textAlign: 'end',
                                        fontWeight: 800,
                                        display: 'inline',
                                        fontSize: '12px',
                                        cursor: 'pointer',
                                        lineHeight: '12px',
                                        letterSpacing: '0.5px',
                                        justifyContent: 'flex-end',
                                        color: collapseIndex === index ? '#2464EB' : '#A3A3A3',
                                    }}
                                />
                            </Grid>
                        </Grid>


                    </React.Fragment>

                }
            </Box>
            <Collapse in={collapseIn} sx={{ pb: collapseIn ? 3 : 0.2 }}>
                {
                    route.items.map((childRoute, index) => {
                        return (
                            <React.Fragment key={index}>
                                <Box

                                    sx={{ ml: 3.5, display: 'flex', paddingLeft: 0.5, paddingRight: 2, paddingTop: 1, paddingBottom: 0.5 }}
                                >
                                    <Box
                                        sx={{ backgroundColor: '#2464EB', width: 5, height: 20, marginLeft: 0.2, borderRadius: 6, visibility: activeItem === childRoute.title ? "visible" : "hidden" }}

                                    >

                                    </Box>
                                    <CustomTypography
                                        id={'side_bar_' + index}
                                        dataTestId={'side_bar_' + index}
                                        component={Link}
                                        to={childRoute.to}
                                        text={childRoute.title}
                                        onClick={() => onRouteClick(childRoute)}
                                        sx={{
                                            paddingLeft: 1.5,
                                            paddingTop: 0.3,
                                            fontSize: '14px',
                                            cursor: 'pointer',
                                            lineHeight: '19px',
                                            fontStyle: 'normal',
                                            textDecoration: 'none',
                                            fontWeight: activeItem === childRoute.title ? 700 : 500,
                                            color: activeItem === childRoute.title ? '#2464EB' : '#141414',
                                            '&:hover': {
                                                opacity: 1,
                                                color: '#2464EB',
                                                fontSize: '14px',
                                            },
                                        }}
                                    >

                                    </CustomTypography>
                                </Box>
                            </React.Fragment>

                        )
                    })
                }
            </Collapse>
        </React.Fragment>
    )
}