import React from 'react';
import CustomTypography from '../../../custom/typography/CustomTypography';
import { ReactComponent as AnalysisIcon } from '../../../../assets/svg/Analysis.svg';

import {
    Box,
    Grid,
} from '@mui/material';

const Analysis = () => {
    return (
        <Box sx={{pt:{xs:0,sm:8},pb:3}}>
            <Content />
        </Box>
    )
}

export default Analysis

const Content = () => (
    <Grid container>
        <Text />
        <Graphic />
    </Grid>
);

const Text = () => (
    <Grid
        item
        sm={6}
        xs={12}
        sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection:'column',
            justifyContent:'center',
        }}
        order={{ xs: 2, sm: 2 }}
    >
        <CustomTypography
            text="Gain Insight into Your Business with Automated Financial Reports"
            sx={{
                color: '#141414',
                fontWeight: 700,
                lineHeight: '56px',
                letterSpacing: '0px',
                fontSize: { xs: '36px', sm: '40px' },
            }}
        />
        <CustomTypography
            text="You can effortlessly generate accurate and insightful financial reports, enabling you to make informed decisions and drive the success of your business."
            sx={{
                mt: 2,
                mb: 2,
                fontSize: '20px',
                fontWeight: 400,
                color: '#666666',
                lineHeight: '32px',
                fontStyle: 'normal',
                letterSpacing: '0.2px',
            }}
        />
    </Grid>
);

const Graphic = () => (
    <Grid
        item
        sm={6}
        xs={12}
        order={{ xs: 1, sm: 1 }}
        sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            mb: { xs: 5, sm: 0 },
            mt: { xs: 0, sm: 0 },
            justifyContent: 'center',
        }}
    >
        <Box
            component="div"
            sx={{
                width: '100%',
                height: 'auto',
                maxWidth: '422px',
                maxHeight: '400px',
            }}
        >
            <AnalysisIcon style={{ width: '100%', height: 'auto' }} />
        </Box>
    </Grid>
);