import React from 'react';
import CustomTypography from '../custom/typography/CustomTypography';
import { getBackGroundColorFromStatus,getColorFromStatus } from '../../utils/Utils';

const Status = (props) => {
    const {status,minWidth,sx}=props

    return (
        <CustomTypography
            text={status.replace('_', ' ').capitalize()}
            sx={{
                p:1,
                border: '1px',
                fontSize:'12px',
                borderRadius: '5px',
                whiteSpace:'nowrap',
                textAlign: 'center',
                display: 'inline-block',
                color:getColorFromStatus(status),
                minWidth:minWidth?minWidth: '100px',
                background:getBackGroundColorFromStatus(status),
                // background:getBackGroundColorFromStatus(status)?getBackGroundColorFromStatus(status): '#e8f3ff',
                ...sx
            }}
        />
    )
}

export default Status