import * as React from 'react';
import { apiAction } from '../../../api/api';
import { useNavigate } from 'react-router-dom';
import CustomInput from "../input/CustomInput";
import CustomButton from '../button/CustomButton';
import * as Actions from '../../../state/Actions';
import ClearIcon from '@mui/icons-material/Clear';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomTypography from '../typography/CustomTypography';

import {
    Box,
    Autocomplete,
    Divider,
    CircularProgress,
} from '@mui/material';

const CustomAsyncDropdown = React.memo((props) => {
    const navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);

    const {
        id,
        sx,
        url,
        body,
        item,
        value,
        results,
        onChange,
        setValue,
        addButton,
        autoFocus,
        dataTestId,
        autoSelect,
        InputProps,
        validation,
        className,
        placeholder,
        renderInput,
        inputDisabled,
        fetchTermLimit,
        size = "small",
        forcePopupIcon,
        disableClearable,
        optionLabel = "name",
        groupBy = 'firstletter',
        isShowEndAdornment = false,
    } = props;

    const [nextUrl, setNextUrl] = React.useState(url);
    const [hasMore, setHasMore] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [options, setOptions] = React.useState(Array.isArray(results) ? results : []);
    const [apiBody, setApiBody] = React.useState(body);

    React.useEffect(() => {
        setOptions(results);
    }, [results]);

    const onChangeInputHandler = React.useCallback((newValue) => {
        if (setValue) {
            setValue(newValue);
        }
        if (newValue && newValue.length % fetchTermLimit === 0) {
            body[optionLabel] = newValue;
            setApiBody({ ...body });
        }
    }, [setValue, fetchTermLimit, body, optionLabel]);

    const searchTerm = apiBody && apiBody[optionLabel];

    React.useEffect(() => {
        if (apiBody && searchTerm) {
            fetchOptions(apiBody, url);
        }
    }, [searchTerm, apiBody, url]);

    React.useEffect(() => {
        if (body) {
            setApiBody({ ...body });
        }
    }, [body]);

    const fetchOptions = React.useCallback(async (apiBody, url) => {
        if (url) {
            setLoading(true);
            let res = await apiAction({ url: url, method: 'post', data: apiBody, navigate: navigate, dispatch: dispatch });
            setLoading(false);
            if (res?.success) {
                const uniqueOptions = [
                    ...options,
                    ...res?.result.result.filter((item) =>
                        !options.some((option) => option[optionLabel] === item[optionLabel])
                    )
                ];
                setOptions(uniqueOptions);
                setNextUrl(res?.result.next);
                setHasMore(!!res?.result.next);
            }
        }
    }, [navigate, dispatch, options, optionLabel]);

    const handleFocus = React.useCallback(() => {
        if (!options.length) {
            fetchOptions(apiBody, url);
        }
    }, [options.length, fetchOptions, apiBody, url]);

    const PaperComponentCustom = React.useCallback((options) => {
        const { key, group, containerProps, children } = options;

        return (
            <Box {...containerProps} key={key} sx={{
                mb: -1,
                margin: '0px',
                color: '#141414',
                fontWeight: 400,
                fontSize: '12px',
                background: '#FFFF',
                lineHeight: '16px',
                fontStyle: 'normal',
                fontFamily: 'Noto Sans',
            }}>
                {addButton && (
                    <>
                        <CustomButton
                            fullWidth
                            variant='text'
                            id={id + '_add_new_btn'}
                            btnLabel={<b>+ Add new</b>}
                            onClick={addButton.onClick}
                            dataTestId={dataTestId + '_add_new_btn'}
                            onMouseDown={(event) => { event.preventDefault(); }}
                            sx={{ mt: -1, textTransform: 'none', '&:hover': { backgroundColor: '#e8f3ff' } }}
                        />
                        <Divider />
                    </>
                )}
                <CustomTypography
                    text={group}
                    sx={{
                        pl: 1,
                        pt: key ? 1 : 0,
                        color: '#141414',
                        fontWeight: 700,
                        fontSize: '12px',
                        lineHeight: '16px',
                        fontStyle: 'normal',
                        fontFamily: 'Noto Sans',
                    }}
                />
                {children}
            </Box>
        );
    }, [addButton, id, dataTestId]);

    const NoOptionsComponentCustom = React.useCallback(() => (
        <>
            <CustomTypography
                text={<div>{!loading ? `No results found` : <CircularProgress />}</div>}
                sx={{
                    mb: 2,
                    color: '#141414',
                    fontWeight: 500,
                    fontSize: '12px',
                    lineHeight: '16px',
                    fontStyle: 'normal',
                    fontFamily: 'Noto Sans',
                }}
            />
            {addButton && (
                <div style={{ marginLeft: '-18px', marginRight: '-18px' }}>
                    <Divider />
                    <CustomButton
                        fullWidth
                        variant='text'
                        id={id + '_add_new_btn'}
                        btnLabel={<b>+ Add new</b>}
                        onClick={addButton.onClick}
                        dataTestId={dataTestId + '_add_new_btn'}
                        onMouseDown={(event) => { event.preventDefault(); }}
                        sx={{ mb: -1.7, textTransform: 'none', '&:hover': { backgroundColor: '#e8f3ff' } }}
                    />
                </div>
            )}
        </>
    ), [loading, addButton, id, dataTestId]);

    const getOptionLabel = React.useCallback((option) => (
        typeof option === "string" ? option :
            option[optionLabel] || option.display_name || option.account_name ||
            option.currency_code || option.name || value || option
    ), [optionLabel, value]);

    const groupedResults = React.useCallback((array = []) => (
        array.reduce((acc, item) => {
            const existingGroup = acc.find((group) => group[0]?.[groupBy] === item[groupBy]);
            if (existingGroup) {
                existingGroup.push(item);
            } else {
                acc.push([item]);
            }
            return acc;
        }, []).flatMap((group) => group)
    ), [groupBy]);

    return (
        <Autocomplete
            sx={sx}
            id={id}
            size={size}
            autoHighlight
            value={value}
            disablePortal
            options={options}
            onOpen={handleFocus}
            className={className}
            autoSelect={autoSelect}
            dataTestId={dataTestId}
            disabled={inputDisabled}
            placeholder={placeholder}
            popupIcon={<ExpandMoreIcon />}
            forcePopupIcon={forcePopupIcon}
            getOptionLabel={getOptionLabel}
            renderGroup={PaperComponentCustom}
            disableClearable={disableClearable}
            groupBy={(option) => option[groupBy]}
            clearIcon={<ClearIcon fontSize='small' />}
            noOptionsText={<NoOptionsComponentCustom />}
            onFocusCapture={() => onChangeInputHandler('')}
            getOptionDisabled={(option) => option.disabled || null}
            isOptionEqualToValue={(option, value) => getOptionLabel(option) === getOptionLabel(value)}
            onChange={(event, newValue) => { onChange(event, newValue); if (newValue) { onChangeInputHandler(''); } }}
            ListboxProps={{
                onScroll: (event) => {
                    const listboxNode = event.currentTarget;
                    if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight && hasMore) {
                        fetchOptions(apiBody, nextUrl);
                    } else if (listboxNode.scrollTop === 0 && hasMore) {
                        fetchOptions(apiBody, nextUrl);
                    }
                },
                style: { maxHeight: 200, overflowY: 'auto' }
            }}
            renderInput={renderInput || ((params) => (
                <CustomInput
                    sx={sx}
                    isSearchableComponent
                    isShowEndAdornment={isShowEndAdornment}
                    validation={validation}
                    InputProps={InputProps}
                    fullWidth
                    {...params}
                    {...item}
                    autoFocus={autoFocus}
                    placeholder={placeholder}
                    value={value || ''}
                    onChange={(e) => onChangeInputHandler(e.target.value)}
                />
            ))}
        />
    );
});

export default CustomAsyncDropdown;
