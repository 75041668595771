import React from 'react';
import styled from '@emotion/styled/macro';
import { images } from '../../utils/Constant';
// import { ReactComponent as LogoSVG } from '../../assets/svg/Option 1.svg';
import { ReactComponent as LogoSVG } from '../../assets/svg/logo.svg';

const Img = styled('img')({
    height: '52px',
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
    marginBottom:'4px',
    alignItems: 'center',
});
const Text = styled('p')({
    margin: 0,
    padding: 0,
    color: '#000912',
    fontWeight: 600,
    fontSize: '10px',
    marginTop: '-8px',
    textAlign: 'center',
    letterSpacing: '1px',
});
export const AppLogo = ({ open = true }) => {
    return (
        open ?
        <div style={{marginLeft:'15px'}}>
            <LogoSVG />
            </div>
            :
            <div>
                <Img sx={{marginBottom:'5px'}} alt={images.appLogo.alt} src={images.appLogo.src} />
                <Text sx={{}}>Finycs</Text>
            </div>


    )
}