import React from 'react';
import Input from '../../../common/Input';
import * as Actions from "../../../../state/Actions";
import { MaterialUISwitch } from '../../../custom/switch/customSwitch';
import {
    checkItemDiscount,
} from '../sales/invoices/InvoiceManager';
import {
    isNumeric,
    stateChangeManager,
    limitDecimalPlaces,
} from '../../../../utils/Utils';

export const DiscountLabelComponent = ({ items, data, setData }) => {
    const { currency_symbol = 'R' } = data;

    const [itemData, setItemData] = React.useState([]);
    const [discount, setDiscount] = React.useState(checkItemDiscount(data[items]));

    React.useEffect(() => {
        if (itemData.length === data[items]?.length) {
            setData(prevData => ({
                ...prevData,
                [items]: prevData[items]?.map(item => ({
                    ...item,
                    item_level_discount: item.is_item_discount_in_percentage === discount ? item.item_level_discount : 0,
                    is_item_discount_in_percentage: discount,
                }))
            }));
        }
    }, [discount, items, setData, itemData?.length]);

    React.useEffect(() => {
        setItemData(data[items]);
        setDiscount(checkItemDiscount(data[items]));
    }, [data[items]?.length, checkItemDiscount(data[items])])

    const handleDiscountChange = (event) => {
        const isChecked = event.target.checked;
        setDiscount(isChecked);
    };

    return (
        <div style={{ whiteSpace: 'nowrap' }}>
            Disc <MaterialUISwitch r={currency_symbol} checked={discount} onChange={handleDiscountChange} />
        </div>
    );
};


export const DiscountInputComponent = ({ index, data, setData, discountData, finalPayment, ...otherProps }) => {
    const dispatch = Actions.getDispatch(React.useContext);
    const [editable, setEditable] = React.useState(false);

    const handleFocus = () => {
        setEditable(true);
    };

    const handleInputChange = (event) => {
        const inputValue = parseFloat(event.target.value);
        const isPercentageDiscount = data[index]?.is_item_discount_in_percentage;
        const amountToSubtract = (data[index]?.amount ? data[index]?.amount : 0) + (data[index]?.tax_amount ? data[index]?.tax_amount : 0);

        if (isNumeric(inputValue) && inputValue >= 0) {
            if (isPercentageDiscount && inputValue > 100) {
                stateChangeManager(dispatch, Actions, true, "error", "Discount cannot be more than 100%");
            } else if (!isPercentageDiscount && inputValue > data[index]?.rate) {
                stateChangeManager(dispatch, Actions, true, "error", "Discount cannot be more than the rate");
            } else {
                data[index].item_level_discount = Math.abs(limitDecimalPlaces(inputValue));
                setData([...data]);
            }
        } else {
            data[index].item_level_discount = 0;
            setData([...data]);
        }
    };

    const handleBlur = () => {
        setEditable(false);
    };

    const handleKeyPress = (event) => {
        const restrictedKeys = ["-", "+", "e", "E"];
        if (restrictedKeys.includes(event.key)) {
            event.preventDefault();
        }
    };

    return (
        <Input
            editable={editable}
            onFocus={handleFocus}
            isSearchableComponent={true}
            isShowCommaSeparatedValue={true}
            id={`input_item_level_discount_${index}`}
            currency_code={discountData.currency_code}
            dataTestId={`input_item_level_discount_${index}`}
            item={{
                type: "number",
                required: true,
                placeholder: "",
                fullWidth: true,
                onBlur: handleBlur,
                onChange: handleInputChange,
                value: data[index].item_level_discount || "",
                disabled: !(data[index]?.quantity || data[index]?.rate),
            }}
            InputProps={{
                inputProps: { min: 0 },
            }}
            onKeyPress={handleKeyPress}
            {...otherProps} // Spread all other props
        />
    );
};
