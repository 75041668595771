import React from 'react';
import validator from 'validator';
import { Resend } from './VerifyOtp';
import Input from '../../../common/Input';
import {useNavigate } from 'react-router-dom';
import CustomButton from '../../../custom/button/CustomButton';
import CustomTypography from '../../../custom/typography/CustomTypography';

import { apiAction } from '../../../../api/api';
import * as Actions from '../../../../state/Actions';
import { routesName } from '../../../../config/routesName';

import {
    send_otp,
    verify_otp,
} from '../../../../api/urls';

import {
    setLoader,
    isFormValid,
    stateChangeManager,
} from '../../../../utils/Utils';

import {
    Box,
    Link,
    Grid,
    Divider,
    IconButton,
    InputAdornment,
} from '@mui/material';
import { Edit } from '@mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const ForgotPassword = () => {
    const navigate = useNavigate();
    
    const dispatch = Actions.getDispatch(React.useContext);

    const [nextStep, setNextStep] = React.useState(false);
    const [data, setData] = React.useState({ email: null, });
    const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);
    const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);


    const togglePasswordVisibility = () => {
        setIsPasswordVisible((prev) => !prev);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsFormSubmitted(true);

        let validation_data = [
            { key: 'email', message: 'Email field cannot be empty' },
            {
                key: 'email',
                message: 'Please enter a valid Email',
                validation: data.email && !(validator.isEmail(data.email)),
            },
        ];

        const { isValid, message } = isFormValid(data, validation_data);
        if (isValid) {
            getOTP();
            setIsFormSubmitted(false);
        }
        else {
            stateChangeManager(dispatch, Actions, true, 'error', message);
        }
    }

    const getOTP = async () => {
        const res = await apiAction({
            method: 'post',
            url: send_otp(),
            data: { ...data, otp_for: 'reset_password' },
        });
        if (res?.success) {
            setNextStep(true);
            setData((prev) => ({ ...prev,otp:'', session_id: res?.session_id }));
            stateChangeManager(dispatch, Actions, true, 'success', res?.status);
        } else {
            stateChangeManager(dispatch, Actions, true, 'error', res?.status);
        }
    };

    const verifyOTP = async (event) => {
        event.preventDefault();
        setIsFormSubmitted(true);

        let validation_data = [
            { key: 'otp', message: 'OTP field cannot be empty' },
        ];

        const { isValid, message } = isFormValid(data, validation_data);
        if (isValid) {
            const res = await apiAction({
                method: 'post',
                url: verify_otp(),
                data: { ...data, otp_through: 'email' },
            });
            if (res?.success) {
                onSuccess(res);
                stateChangeManager(dispatch, Actions, true, 'success', res?.status);
            } else {
                stateChangeManager(dispatch, Actions, true, 'error', res?.status);
            }
        }
        else {
            stateChangeManager(dispatch, Actions, true, 'error', message);
        }
    }

    const onSuccess = (res) => {
        setLoader(dispatch, Actions, false);
        navigate("/auth" + routesName.restPassword.path, {
            state: { data: { ...data } },
        });
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <Box className="m_login_left_box" sx={{ backgroundColor: '#F7F7F7', minHeight: '100%', width: '28%', display: 'block' }} >
                <Box sx={{ pt: 8, minHeight: "100vh", minWidth: '100vh', alignItems: 'center', justifyContent: 'center' }}>
                    <Box sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                    </Box>
                </Box>
            </Box>

            <Box sx={{ height: '100%', width: '100%' }}>
                <Box className='m_login_container' sx={{ pt: 8, alignItems: "center", justifyContent: "center", }}>
                    <Box sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: "center", flexDirection: 'column', }}>
                        <Box component="form" onSubmit={nextStep ? verifyOTP : handleSubmit} noValidate sx={{ maxWidth: { xs: '100%', sm: 500 } }}>
                            <CustomTypography
                                text={'Forgot your password'}
                                sx={{
                                    color: '#000000',
                                    fontSize: '30px',
                                    textAlign: 'start',
                                    fontWeight: '700',
                                    fontStyle: 'normal',
                                    lineHeight: '40.86px',
                                    fontFamily: "Noto Sans",
                                }}
                            />
                            <CustomTypography
                                text={<span>Enter your account's verified email address, and we will send you an OTP to reset your password.</span>}
                                sx={{
                                    mb: 4,
                                    mt: 3,
                                    color: '#000000',
                                    fontSize: '14px',
                                    textAlign: 'start',
                                    fontWeight: '500',
                                    fontStyle: 'normal',
                                    lineHeight: '28px',
                                    fontFamily: "Noto Sans",
                                }}
                            />

                            <Box sx={{ mt: 2, }}>
                                <Input
                                    autoFocus={true}
                                    id='input_email_address'
                                    dataTestId='input_email_address'
                                    item={{
                                        type: 'text',
                                        required: true,
                                        fullWidth: true,
                                        disabled: nextStep,
                                        title: 'Email Address',
                                        value: data.email ? data.email : '',
                                        placeholder: 'Enter Your Email Address',
                                        validation: isFormSubmitted && !data.email,
                                        onChange: (e) => setData({ ...data, email: e.target.value })
                                    }}

                                    InputProps={{
                                        endAdornment: nextStep && (
                                            <InputAdornment position="end">
                                                <Divider sx={{ height: 20, m: 0.5 }} orientation="vertical" />
                                                <IconButton
                                                    edge="end"
                                                    aria-label="Edit mode"
                                                    onClick={() => { setNextStep(false); setData({ email: '' }) }}
                                                >
                                                    {<Edit fontSize='small' sx={{ color: '#2464EB' }} />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Box>

                            {nextStep && (
                                <Box sx={{ mt: 2, }}>
                                    <Input
                                        autoFocus
                                        id="otp_input"
                                        dataTestId="otp_input"
                                        isKeyPressEnter={false}
                                        item={{
                                            title: 'OTP',
                                            required: true,
                                            fullWidth: true,
                                            value: data.otp,
                                            placeholder: 'Enter Your OTP',
                                            validation: isFormSubmitted && (!data.otp),
                                            type: isPasswordVisible ? 'text' : 'password',
                                            onChange: (event) => setData((prev) => ({ ...prev, otp: event.target.value })),
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Divider sx={{ height: 20, m: 0.5 }} orientation="vertical" />
                                                    <IconButton
                                                        edge="end"
                                                        aria-label="toggle OTP visibility"
                                                        onClick={togglePasswordVisibility}
                                                    >
                                                        {isPasswordVisible ? <VisibilityIcon fontSize='small' /> : <VisibilityOffIcon fontSize='small' />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <Resend handleResend={()=>getOTP()}/>
                                </Box>
                            )}

                            <CustomButton
                                fullWidth
                                type="submit"
                                sx={{ mt: 4}}
                                variant="contained"
                                onClick={nextStep ? verifyOTP : handleSubmit}
                                btnLabel={nextStep ? "Verify your OTP" : 'Send password reset OTP'}
                                id={nextStep ? "verify_forgot_password_otp" : "send_forgot_password_otp"}
                                dataTestId={nextStep ? "verify_forgot_password_otp" : "send_forgot_password_otp"}
                            />

                            <Grid container spacing={2} sx={{ pt: 3, fontSize: { xs: '12px', sm: '16px' } }}>
                                <Grid item xs>
                                    <Link id='sign_up_link' dataTestId='sign_up_link' href={"/auth" + routesName.signUp.path}>Don't have an account? Sign up</Link>
                                </Grid>
                                <Grid item xs>
                                    <Link id='sign_in_link' dataTestId='sign_in_link' href={"/auth" + routesName.signIn.path}>Already have an account? Sign in</Link>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default ForgotPassword