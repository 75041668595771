import React from 'react';
import { apiAction } from '../../../../api/api';
import * as Actions from '../../../../state/Actions';
import { routesName } from '../../../../config/routesName';
import { useNavigate, useLocation } from 'react-router-dom';
import CustomButton from '../../../custom/button/CustomButton';
import CustomTypography from '../../../custom/typography/CustomTypography';

import {
    send_otp,
    verify_otp,
} from '../../../../api/urls';

import {
    isNumber,
    setLoader,
    stateChangeManager,
} from '../../../../utils/Utils';

import {
    Box,
    Link,
    Grid,
} from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

const styles = {
    title: {
        color: '#000000',
        fontSize: '16px',
        fontWeight: '700',
        lineHeight: '18px',
        fontStyle: 'normal',
        fontFamily: "Noto Sans",
    },
    confirmMessage: {
        mb: 1.5,
        fontSize: '18px',
        color: '#000000',
        fontWeight: '700',
        lineHeight: '18px',
        fontStyle: 'normal',
        textTransform: "none",
        fontFamily: "Noto Sans",
    },
    confirmDescription: {
        mb: 1,
        color: '#000000',
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '18px',
        fontStyle: 'normal',
        fontFamily: "Noto Sans",
    }
};

const VerifyOtp = ({ }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;
    const [isResend, setIsResend] = React.useState(false);
    const dispatch = Actions.getDispatch(React.useContext);
    const [enteredOtp, setEnteredOpt] = React.useState({
        email_otp: null,
        mobile_otp: null,
    })

    const [data, setData] = React.useState();
    const [isEmailVerified, setIsEmailVerified] = React.useState(false);


    const getOTP = async (additionData) => {

        const res = await apiAction({
            method: 'post',
            url: send_otp(),
            data: { ...additionData, session_id: data?.session_id, is_privacy_policy_and_terms_accepted: true },
        });
        if (res?.success) {
            setIsResend(false);
            setData((previous) => ({ ...previous, session_id: res?.session_id }));
            stateChangeManager(dispatch, Actions, true, 'success', res?.status);
        } else {
            stateChangeManager(dispatch, Actions, true, 'error', res?.status);
        }
    };

    const verifyOTP = async (otp, type) => {
        if (otp) {
            setLoader(dispatch, Actions, true);
            const res = await apiAction({
                method: "POST",
                url: verify_otp(),
                data: {
                    ...data,
                    otp: otp,
                    otp_through: type,
                    is_privacy_policy_and_terms_accepted: true
                },
            });
            if (res?.success) {
                if (type === 'email') {
                    setIsEmailVerified(true);
                    verifyOTP(enteredOtp.mobile_otp, 'mobile_number')
                } else {
                    setLoader(dispatch, Actions, false);
                    navigate("/auth" + routesName.otpBasedSignup.path, {
                        state: { data: { ...data, is_otp_based_auth: true } }
                    });
                }
                stateChangeManager(dispatch, Actions, true, 'success', res?.status);
            } else {
                setLoader(dispatch, Actions, false);
                stateChangeManager(dispatch, Actions, true, 'error', `${res?.status} for ${type === 'email' ? 'Email' : 'Mobile Number '}`);
            }
        } else {
            setLoader(dispatch, Actions, false);
            stateChangeManager(dispatch, Actions, true, 'error', `Please enter the otp for ${type === 'email' ? 'Email' : 'Mobile Number '}`);
        }
    };

    React.useEffect(() => {

        if (state?.data) {
            setData(state?.data);
        }
    }, [state])


    const handleSubmit = () => {
        verifyOTP(enteredOtp.email_otp, 'email')
    };

    let isOtpComplete = enteredOtp.email_otp && enteredOtp.mobile_otp;
    return (
        <Box sx={{ display: 'flex' }}>
            <Box className="m_login_left_box" sx={{ backgroundColor: '#F7F7F7', minHeight: '100%', width: '28%', display: 'block' }} >
                <Box sx={{ pt: 8, minHeight: "100vh", minWidth: '100vh', alignItems: 'center', justifyContent: 'center' }}>
                    <Box sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ height: '100%', width: '100%' }}>
                <Box className='m_login_container' sx={{ pt: 8, alignItems: "center", justifyContent: "center", }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "center", flexDirection: 'column', }}>

                        <Box noValidate component="form">
                            {/* Typography for Signup Title */}
                            <CustomTypography
                                text='Verify OTP'
                                sx={{
                                    mb: 4,
                                    color: '#000000',
                                    fontSize: '30px',
                                    fontWeight: '700',
                                    textAlign: 'start',
                                    lineHeight: '40.86px',
                                    fontFamily: "Noto Sans",
                                }}
                            />
                            <OtpInput handleResend={() => { getOTP({otp_for:'verification', email: data?.email }); setEnteredOpt((previous)=>({...previous,email_otp:null,})) ; setIsResend('email_otp'); }} isOTPVerified={isEmailVerified} autoFocus={true} clearEnterOtp={isResend==='email_otp'} getEnterOtp={(value) => { setEnteredOpt((previous) => ({ ...previous, email_otp: value })) }} title={<span style={{}}>Enter the OTP send to <span style={{ color: '#646f79' }}> {data?.email}</span></span>} />
                            <OtpInput handleResend={() => { getOTP({otp_for:'verification', mobile_number: data?.mobile_number }); setEnteredOpt((previous)=>({...previous,mobile_otp:null})) ; setIsResend('mobile_otp'); }} isOTPVerified={false} autoFocus={false} clearEnterOtp={isResend==='mobile_otp'} getEnterOtp={(value) => { setEnteredOpt((previous) => ({ ...previous, mobile_otp: value })) }} title={<span style={{}}>Enter the OTP send to <span style={{ color: '#646f79' }}> {data?.mobile_number}</span></span>} />

                            <CustomButton
                                fullWidth
                                btnLabel="Verify"
                                variant="contained"
                                disabled={!isOtpComplete}
                                sx={{ mt: 3, minWidth: '140px', maxWidth: '420px' }}
                                onClick={isEmailVerified?()=>{verifyOTP(enteredOtp.mobile_otp, 'mobile_number')}:handleSubmit}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>

    );
};

export default VerifyOtp;

export const Resend = ({ handleResend }) => {
    const [counter, setCounter] = React.useState(60);

    React.useEffect(() => {
        if (counter > 0) {
            setTimeout(() => setCounter(counter - 1), 1000);
        }
    }, [counter]);

    return (
        <Box sx={{ mt: 2, paddingLeft: '10px', display: 'flex', alignItems: 'center' }}>
            <CustomTypography
                text="Didn't receive an OTP?"
                sx={{
                    opacity: 0.8,
                    color: '#000000',
                    fontSize: '14px',
                    textAlign: 'center',
                    fontWeight: '500',
                    fontStyle: 'normal',
                    lineHeight: '40.86px',
                    fontFamily: "Noto Sans",
                }}
            />
            <CustomTypography
                text="Resend."
                id="resend_btn"
                dataTestId="resend_btn"
                onClick={() => {if(!counter){ handleResend();setCounter(60)}}}
                sx={{
                    ml: 0.5,
                    fontSize: '14px',
                    cursor: 'pointer',
                    fontWeight: '500',
                    lineHeight: '16px',
                    fontFamily: 'Noto Sans',
                    color: counter ? '#646f79' : '#2464EB',
                    ":hover": { textDecoration: counter ? 'none' : 'underline' },
                }}
            />
            {counter > 0 && (
                <CustomTypography
                    text={<span className="badge">{counter}</span>}
                    sx={{
                        ml: 2,
                        fontSize: '10px',
                        cursor: 'pointer',
                        fontWeight: '500',
                        lineHeight: '16px',
                        fontFamily: 'Noto Sans',
                    }}
                />
            )}
        </Box>
    );
};

export const OtpInput = ({ title, getEnterOtp, clearEnterOtp, autoFocus, isOTPVerified, handleResend }) => {
    const inputsRef = React.useRef([]);
    const [otp, setOtp] = React.useState(Array(6).fill(''));

    const handleInput = (e, index) => {
        const val = e.target.value;
        const newOtp = [...otp];

        if (!isNaN(val) && val !== "") {
            newOtp[index] = val;
            setOtp(newOtp);

            if (index < inputsRef.current.length - 1) {
                inputsRef.current[index + 1].focus();
            }
        } else {
            e.target.value = "";
            newOtp[index] = "";
            setOtp(newOtp);
        }
    };

    const handleKeyUp = (e, index) => {
        if (["backspace", "delete"].includes(e.key.toLowerCase())) {
            e.target.value = "";
            const newOtp = [...otp];
            newOtp[index] = "";
            setOtp(newOtp);

            if (index > 0) {
                inputsRef.current[index - 1].focus();
            }
        }
    };
    React.useEffect(() => {
        const enteredOtp = otp.join('');
        if (enteredOtp.length === 6) {
            getEnterOtp(enteredOtp)
        }
    }, [otp]);

    React.useEffect(() => {
        setOtp(Array(6).fill(''));
        if (autoFocus) {
            inputsRef.current[0].focus();
        }
    }, [clearEnterOtp]);

    return (
        <Box sx={{ mt: 3, }}>
            <CustomTypography text={title} sx={{ ...styles.confirmMessage, fontSize: '14px', mb: 1, paddingLeft: '10px', }} />
            <Box id="inputs" className="inputs" sx={{ mb: 2, p: 1, pl: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: '1px solid #F7F7F7', borderRadius: 1, }}>
                {otp.map((_, index) => (
                    <input
                        key={index}
                        type="text"
                        maxLength="1"
                        className="input"
                        inputMode="numeric"
                        value={otp[index]}
                        disabled={isOTPVerified}
                        onInput={e => handleInput(e, index)}
                        onKeyUp={e => handleKeyUp(e, index)}
                        ref={el => (inputsRef.current[index] = el)}
                        style={{ minWidth: '40px', background: '#e0e0e03d', padding: '5px' }}
                        onPaste={(e) => {
                            let pastedValue = isNumber(e.clipboardData.getData('Text')) && e.clipboardData.getData('Text').toString().split('').map(Number)
                            if (otp.length === pastedValue.length) {
                                setOtp(pastedValue);
                                inputsRef.current[pastedValue.length - 1].focus();
                            }
                        }}
                    />
                ))}

                <TaskAltIcon sx={{ ml: '10px', color: 'green', fontSize: '28px', visibility: isOTPVerified ? 'visible' : 'hidden' }} />
            </Box>
            {!isOTPVerified && (
                <Resend handleResend={handleResend} />
            )}

        </Box>
    )
}