import React from 'react';
import CustomTypography from '../custom/typography/CustomTypography';

const CustomLabel = (props) => {
    const { sx, title, titleVisibility = "visible" } = props;
    return (
        <CustomTypography
            text={title}

            sx={{
                mb: 1,
                color: '#000000',
                fontSize: '14px',
                fontWeight: '700',
                lineHeight: '18px',
                fontStyle: 'normal',
                textTransform: 'none',
                fontFamily: "Noto Sans",
                visibility: titleVisibility,
                ...sx,

            }}
        />
    )
}

export default CustomLabel