import React from 'react'
import { Container } from '@mui/material'

const Loader = () => {

    return (
        <Container>
            {/* <Roller /> */}
            <CustomSpinner />
        </Container>

    )
}
// const Spinner = () => {
//     return (
//         <div>

//             <div className="lds-spinner">
//                 <div></div>
//                 <div></div>
//                 <div></div>
//                 <div></div>
//                 <div></div>
//                 <div></div>
//                 <div></div>
//                 <div></div>
//                 <div></div>
//                 <div></div>
//                 <div></div>
//                 <div></div>
//             </div>
//         </div>
//     )
// }

const CustomSpinner = () => {
    return (
        <div className="loadingio-spinner-spinner-z515pskmu3b"><div className="ldio-2j6q63zyi35">
            <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
        </div></div>
    )
}

// const Roller = () => {
//     return (
//         <div className="lds-roller">
//             <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
//         </div>
//     )
// }

// const Hourglass = () => {
//     return (
//         <div className="lds-hourglass"></div>
//     )
// }

// const Ripple = () => {
//     return (
//         <div className="lds-ripple"><div></div><div></div>
//         </div>
//     )
// }


export default Loader