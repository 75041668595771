import React from 'react';
import moment from 'moment';
import Status from '../../../common/Status';
import { useNavigate } from 'react-router-dom';
import { apiAction } from "../../../../api/api";
import ReportsTitle from '../common/ReportsTitle';
import * as Actions from '../../../../state/Actions';
import { routesName } from '../../../../config/routesName';
import { sourceTypeList } from '../../../../utils/Constant';
import { CustomLink } from '../../../custom/link/CustomLink';
import ReportsSettingButtons from '../ReportsSettingButtons';
import { getBusinessInfo } from '../../../../config/cookiesInfo';
import CurrencyFormatter from '../../../common/CurrencyFormatter';
import { NoDataComponent } from '../../../common/NoDataComponent';
import CommonCustomDateRange from '../../../common/CommonCustomDateRange';
import CustomPagination from '../../../custom/pagination/CustomPagination';
import CustomTypography from '../../../custom/typography/CustomTypography';
import { CustomContainer } from '../../../custom/container/CustomContainer';

import {
    get_list_of_journal_entries,
    get_source_of_journal_entry,
    export_list_of_journal_entries,
} from '../../../../api/urls';

import {
    setLoader,
    date_range,
    getDateFormat,
    convertSlugToString,
} from '../../../../utils/Utils';

import {
    Box,
    Grid,
} from '@mui/material';

import {
    CustomTable,
    CustomTableRow,
    CustomTableBody,
    CustomTableHead,
    CustomTableHeadCell,
    CustomTableBodyCell,
    CustomTableContainer,
} from '../../../custom/table/CustomTable';

const JournalReports = () => {
    const stateAction = Actions.getState(React.useContext);
    const dispatch = Actions.getDispatch(React.useContext);

    const { selectedDateRange } = stateAction;
    let filter_date_range = date_range().find((item) => item.name === selectedDateRange)
    const default_date_range = filter_date_range ? filter_date_range : date_range()[2]

    const [dateRange, setDateRange] = React.useState(default_date_range);
    const [fromDate, setFromDate] = React.useState(moment());
    const [toDate, setToDate] = React.useState(moment());
    const [results, setResults] = React.useState();
    const [total, setTotal] = React.useState();
    const [date, setDate] = React.useState();

    const [page, setPage] = React.useState(1);
    const [pagination, setPagination] = React.useState({
        next: undefined,
        count: undefined,
        previous: undefined,
        number_of_pages: undefined,
    })

    React.useEffect(() => {
        if (dateRange) {
            if (dateRange.name !== "Custom") {
                getApiResults(dateRange.dates.from_date, dateRange.dates.to_date, dateRange.dates.date_range)
            } else {
                getApiResults(fromDate.format('YYYY-MM-DD'), toDate.format('YYYY-MM-DD'), `${fromDate.format(getDateFormat())} - ${toDate.format(getDateFormat())}`)
            }
        }
        // eslint-disable-next-line
    }, [dateRange, toDate, fromDate, page]);

    const getApiResults = async (from_date, to_date, range_date) => {
        setLoader(dispatch, Actions, true)
        setDate(range_date)
        let res = await apiAction({
            method: 'post',
            url: get_list_of_journal_entries(page),
            data: {
                is_posted: true,
                filter_end_date: to_date,
                filter_start_date: from_date,
                business_id: getBusinessInfo().id,
            },
        })

        if (res?.success) {
            setPagination({
                ...pagination,
                next: res?.result.next,
                count: res?.result.count,
                previous: res?.result.previous,
                number_of_pages: res?.result.number_of_pages,
            })
            setResults(res?.result.result);
            setLoader(dispatch, Actions, false);
            setTotal(res?.result.result.total_amount);
        } else {
            setLoader(dispatch, Actions, false);
        }
    }

    let state_data = { dateRange: dateRange, toDate: toDate.format('YYYY-MM-DD'), fromDate: fromDate.format('YYYY-MM-DD') }
    return (
        results &&
        <div>
            <ReportsSettingButtons

                scheduleData={{
                    report_name: 'journal_report',
                    report_title: 'Journal Report',

                }}
                exportData={{ url: export_list_of_journal_entries(), data: dateRange.dates ? { ...dateRange.dates, is_posted: true, } : { to_date: toDate.format('YYYY-MM-DD'), from_date: fromDate.format('YYYY-MM-DD'), is_posted: true, } }}
            />
            <CustomContainer maxWidth={"400"} sx={{}}>
                <div style={{ paddingLeft: '16px', paddingRight: '16px' }}>
                    <Grid container spacing={0} sx={{ pb: 2 }} style={{
                        alignItems: 'center',
                        justifyItems: 'center',
                        alignContent: 'space-evenly',
                        justifyContent: 'space-evenly',
                    }}>
                        <Grid item xs={12} sm={4}>
                            <ReportsTitle
                                date={date}
                                title={'Journal Report'}
                            />
                        </Grid>
                        <Grid container spacing={1} item xs={12} sm={8} sx={{ justifyContent: 'end', }}>
                            <CommonCustomDateRange
                                setPage={setPage}
                                date_range={date_range()}

                                toDate={toDate}
                                setToDate={setToDate}

                                fromDate={fromDate}
                                setFromDate={setFromDate}

                                dateRange={dateRange}
                                setDateRange={setDateRange}
                            />
                        </Grid>
                    </Grid>
                </div>
                <CustomTableContainer>
                    <CustomTable>
                        <Header />
                        {
                            results.length ?
                                <Body data={results} total={total} is_last_page={pagination.next === null} state_data={state_data} />
                                : null
                        }
                    </CustomTable>
                    <Box sx={{ p: !results.length ? 2 : 0 }} >
                        <NoDataComponent left='0%' top='0%' position={'relative'} data={results} />
                    </Box>
                </CustomTableContainer>
            </CustomContainer>
            {
                results.length ?
                    <CustomPagination
                        page={page}
                        count={pagination.number_of_pages}
                        onChange={(event, newValue) => setPage(newValue)}
                    />
                    : null
            }
        </div>
    )
}

export default JournalReports;

const Header = () => {
    return (
        <CustomTableHead>
            <CustomTableRow >
                {/* <CustomTableHeadCell style={{ width: 10 }}><span>Sr. No.</span></CustomTableHeadCell> */}
                <CustomTableHeadCell style={{ width: 100 }} align='center' ><span>Status </span></CustomTableHeadCell>
                <CustomTableHeadCell style={{}} ><span>Date</span></CustomTableHeadCell>
                <CustomTableHeadCell style={{}} ><span>Description</span></CustomTableHeadCell>
                <CustomTableHeadCell style={{}} ><span>Account</span></CustomTableHeadCell>
                <CustomTableHeadCell style={{}} align='right'><span>Debit</span></CustomTableHeadCell>
                <CustomTableHeadCell style={{}} align='right'><span>Credit</span></CustomTableHeadCell>
            </CustomTableRow>
        </CustomTableHead>
    )
}

const Body = (props) => {
    const { data, state_data } = props;
    let navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);

    const onClickDescriptionHandler = async (journal_entry_id, source_type) => {
        let res = await apiAction({
            method: 'get',
            navigate: navigate,
            dispatch: dispatch,
            url: get_source_of_journal_entry(journal_entry_id, source_type),
        })
        if (res?.success) {
            const { source_id, source_type } = res?.result;

            sourceTypeList.map((item) => {
                if (item.source_type === source_type) {
                    navigate(item.source_path + source_id)
                }
            })
        } else {
            if (source_type === 'opening_balance') {
                sourceTypeList.map((item) => {
                    if (item.source_type === source_type) {
                        navigate(item.source_path, { state: { ...item.state } })
                    }
                })
            }
        }
    }
    return (
        <CustomTableBody>
            {data.map((result, index) => {
                return (
                    <CustomTableRow key={index}>
                        {/* <CustomTableBodyCell sx={{}} ><span>{(index + 1)}</span></CustomTableBodyCell> */}
                        <CustomTableBodyCell align='center'><Status minWidth={'100px'} status={'Posted'} /></CustomTableBodyCell>
                        <CustomTableBodyCell sx={{}}  ><span>{moment(result.transaction_date).format(getDateFormat())}</span></CustomTableBodyCell>
                        <CustomTableBodyCell sx={{ fontWeight: 500, }} id={'description_link_' + index} dataTestId={'description_link_' + index}>
                            {result.source === 'manual' || result.source === 'statement' ?
                                <span>{convertSlugToString(result.description, '_')} </span>
                                :
                                <CustomTypography
                                    text={<span>{convertSlugToString(result.description, '_')} </span>}
                                    sx={{
                                        color: '#2464EB',
                                        fontSize: '12px',
                                        cursor: 'pointer',
                                        fontWeight: '500',
                                        lineHeight: '0px',
                                        display: 'inline',
                                        fontStyle: 'normal',
                                        textAlign: 'center',
                                        fontFamily: "Noto Sans",
                                        ":hover": { textDecoration: 'underline' }
                                    }}
                                    onClick={() => { onClickDescriptionHandler(result.id, result.source) }}
                                />
                            }
                        </CustomTableBodyCell>
                        <CustomTableBodyCell sx={{}}  ><span>
                            {
                                result.transactions.map((item, index) => {
                                    return (
                                        item.transaction_type === 'DEBIT' ?
                                            <p key={index} style={{ margin: '0px' }}>
                                                <CustomLink 
                                                state={state_data}
                                                id={'business_account_link_' + index} 
                                                dataTestId={'business_account_link_' + index}
                                                to={routesName.journalEntryTransaction.path.replace(':id', item.business_account_id)}
                                                ><span>{item.business_account}</span></CustomLink>
                                                <span > Dr.</span>
                                            </p>
                                            :
                                            <p key={index} style={{ margin: '0px' }}>
                                                <span >To </span>
                                                <CustomLink 
                                                state={state_data}
                                                id={'business_account_link_' + index} 
                                                dataTestId={'business_account_link_' + index}
                                                to={routesName.journalEntryTransaction.path.replace(':id', item.business_account_id)}
                                                >{item.business_account}</CustomLink>
                                            </p>
                                    )
                                })
                            }
                        </span></CustomTableBodyCell>
                        <CustomTableBodyCell sx={{}} align='right'>
                            {
                                result.transactions.map((item, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            {
                                                item.transaction_type === 'DEBIT' ?
                                                    <p key={index} style={{ margin: '0px' }}>{<CurrencyFormatter amount={item.amount} currency={null} />}</p>
                                                    :
                                                    <React.Fragment>
                                                        <span style={{ textAlign: 'center' }}>-</span><br />
                                                    </React.Fragment>
                                            }
                                        </React.Fragment>
                                    )
                                })
                            }
                        </CustomTableBodyCell>
                        <CustomTableBodyCell sx={{}} align='right'>
                            {
                                result.transactions.map((item, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            {

                                                item.transaction_type === 'CREDIT' ?
                                                    <p key={index} style={{ margin: '0px' }}>{<CurrencyFormatter amount={item.amount} currency={null} />}</p>
                                                    :
                                                    <React.Fragment>
                                                        <span style={{ textAlign: 'center' }}>-</span><br />
                                                    </React.Fragment>

                                            }
                                        </React.Fragment>

                                    )
                                })
                            }
                        </CustomTableBodyCell>
                    </CustomTableRow>
                )
            })}
            {/* 
            {
                is_last_page ?
                    <CustomTableRow sx={{
                        pb: 1,
                        pt: 2,
                        background: '#FFF7DC',
                        borderTop: '1px solid #FFF0C0',
                        borderBottom: '1px solid #FFF0C0'
                    }}>
                        <CustomTableBodyCell><span>Total</span></CustomTableBodyCell>
                        <CustomTableBodyCell colSpan={4} />
                        <CustomTableBodyCell align='right'><span><CurrencyFormatter amount={total.total_total_amount} currency={null} /></span></CustomTableBodyCell>
                        <CustomTableBodyCell align='right'><span><CurrencyFormatter amount={total.total_balance_amount} currency={null} /></span></CustomTableBodyCell>
                    </CustomTableRow>
                    : null
            } */}
        </CustomTableBody>
    )
}