import React from 'react';
import Create from '../common/Create';
import CustomTitle from '../../../../common/CustomTitle';
import { CustomTitleContainer } from '../../../../custom/container/CustomContainer';
import { eventsNames, AnalyticsEvent } from '../../../../../firebase/firebaseAnalytics';

const CreateJournalEntry = () => {
    return (
        <div>
            <CustomTitleContainer>
                <CustomTitle title={'Create New Journal Entry'} />
            </CustomTitleContainer>
            <Create
                AnalyticsEvent={(params) => { AnalyticsEvent(eventsNames.categories.JOURNAL_ENTRY, params) }}
            />
        </div>

    )
}

export default CreateJournalEntry