import React from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { apiAction } from "../../../../api/api";
import ReportsTitle from '../common/ReportsTitle';
import * as Actions from '../../../../state/Actions';
import ReportsSettingButtons from '../ReportsSettingButtons';
import { sales_by_customer_report } from '../../../../api/urls';
import { getBusinessInfo } from '../../../../config/cookiesInfo';
import CurrencyFormatter from '../../../common/CurrencyFormatter';
import { NoDataComponent } from '../../../common/NoDataComponent';
import CommonCustomDateRange from '../../../common/CommonCustomDateRange';
import CustomPagination from '../../../custom/pagination/CustomPagination';
import { CustomContainer } from '../../../custom/container/CustomContainer';

import {
    CustomerViewLink,
    ActionInvoiceListLink,
    ActionCreditNoteListLink,
} from '../../invoicing/common/CommonLinks';


import {
    setLoader,
    date_range,
    getDateFormat,
} from '../../../../utils/Utils';

import {
    Box,
    Grid,
} from '@mui/material';

import {
    CustomTable,
    CustomTableRow,
    CustomTableBody,
    CustomTableHead,
    CustomTableHeadCell,
    CustomTableBodyCell,
    CustomTableContainer,
} from '../../../custom/table/CustomTable';
import { routesName } from '../../../../config/routesName';

const SalesByCustomer = () => {
    const stateAction = Actions.getState(React.useContext);
    const dispatch = Actions.getDispatch(React.useContext);

    const { selectedDateRange } = stateAction;
    let filter_date_range = date_range().find((item) => item.name === selectedDateRange)
    const default_date_range = filter_date_range ? filter_date_range : date_range()[2]

    const [dateRange, setDateRange] = React.useState(default_date_range)
    const [fromDate, setFromDate] = React.useState(moment())
    const [toDate, setToDate] = React.useState(moment())
    const [results, setResults] = React.useState()
    const [date, setDate] = React.useState()

    const [page, setPage] = React.useState(1);
    const [pagination, setPagination] = React.useState({
        next: undefined,
        count: undefined,
        previous: undefined,
        number_of_pages: undefined,
    });

    React.useEffect(() => {
        if (dateRange) {
            if (dateRange.name !== "Custom") {
                getApiResults(dateRange.dates.from_date, dateRange.dates.to_date, dateRange.dates.date_range)
            } else {
                getApiResults(fromDate.format('YYYY-MM-DD'), toDate.format('YYYY-MM-DD'), `${fromDate.format(getDateFormat())} - ${toDate.format(getDateFormat())}`)
            }
        }
        // eslint-disable-next-line
    }, [dateRange, toDate, fromDate, page]);

    const getApiResults = async (from_date, to_date, range_date) => {
        setLoader(dispatch, Actions, true)
        setDate(range_date)
        let res = await apiAction({
            method: 'post',
            url: sales_by_customer_report(page),
            data: {
                business_id: getBusinessInfo().id,
                from_date: from_date,
                to_date: to_date
            },
        })

        if (res?.success) {
            let result = res?.result.result.paginated_result;
            setPagination({
                ...pagination,
                next: result.next,
                count: result.count,
                previous: result.previous,
                number_of_pages: result.number_of_pages,
            });
            setLoader(dispatch, Actions, false);
            setResults({ ...res?.result.result, sales_by_customer_list: result.result });
        } else {
            setLoader(dispatch, Actions, false);
        }
    }

    return (
        results &&
        <div>
            <ReportsSettingButtons
                scheduleData={{
                    report_name: 'sales_by_customer',
                    report_title: 'Sales By Customer',
                }}

                exportData={{ url: sales_by_customer_report(), data: dateRange.dates ? dateRange.dates : { to_date: toDate.format('YYYY-MM-DD'), from_date: fromDate.format('YYYY-MM-DD') } }}
            />
            <CustomContainer maxWidth={"400"} sx={{}}>
                <div style={{ paddingLeft: '16px', paddingRight: '16px' }}>
                    <Grid container spacing={0} sx={{ pb: 2 }} style={{
                        alignItems: 'center',
                        justifyItems: 'center',
                        alignContent: 'space-evenly',
                        justifyContent: 'space-evenly',
                    }}>
                        <Grid item xs={12} sm={4}>
                            <ReportsTitle
                                date={date}
                                title={'Sales By Customer'}
                            />
                        </Grid>
                        <Grid container spacing={1} item xs={12} sm={8} sx={{ justifyContent: 'end', }}>
                            <CommonCustomDateRange
                                setPage={setPage}
                                date_range={date_range()}

                                toDate={toDate}
                                setToDate={setToDate}

                                fromDate={fromDate}
                                setFromDate={setFromDate}

                                dateRange={dateRange}
                                setDateRange={setDateRange}
                            />
                        </Grid>
                    </Grid>
                </div>
                <CustomTableContainer>
                    <CustomTable sx={{}}>
                        <Header />
                        {
                            results.sales_by_customer_list.length ?
                                <Body
                                    data={results.sales_by_customer_list}
                                    total={results.sales_by_customer_total}
                                    is_last_page={pagination.next === null}
                                    filter_data={dateRange.dates ? dateRange.dates : { to_date: toDate.format('YYYY-MM-DD'), from_date: fromDate.format('YYYY-MM-DD') }}
                                />
                                : null
                        }
                    </CustomTable>
                    <Box sx={{ p: !results.sales_by_customer_list.length ? 2 : 0 }} >
                        <NoDataComponent left='0%' top='0%' position={'relative'} data={results.sales_by_customer_list} />
                    </Box>
                </CustomTableContainer>
            </CustomContainer>
            {
                results.sales_by_customer_list.length ?
                    <CustomPagination
                        page={page}
                        count={pagination.number_of_pages}
                        onChange={(event, newValue) => setPage(newValue)}
                    />
                    : null
            }
        </div>
    )
}

export default SalesByCustomer;

const Header = () => {
    return (
        <CustomTableHead>
            <CustomTableRow >
                <CustomTableHeadCell><span>Name</span></CustomTableHeadCell>
                <CustomTableHeadCell align='right'><span>Invoice Count</span></CustomTableHeadCell>
                <CustomTableHeadCell align='right'><span>Credit Note Count</span></CustomTableHeadCell>
                <CustomTableHeadCell align='right'><span>Sales</span></CustomTableHeadCell>
                <CustomTableHeadCell align='right'><span>Sales with Tax</span></CustomTableHeadCell>
            </CustomTableRow>
        </CustomTableHead>
    )
}

const Body = (props) => {
    const navigate = useNavigate();
    const { data, total, is_last_page, filter_data } = props;

    return (
        <CustomTableBody>
            {data.map((item, index) => {
                return (
                    <CustomTableRow key={index}>
                        {/* <CustomTableBodyCell><span>{item.customer}</span></CustomTableBodyCell> */}
                        <CustomTableBodyCell><CustomerViewLink id={item.customer_id} title={item.customer_display_name} /></CustomTableBodyCell>
                        {/* <CustomTableBodyCell align='right'><span>{item.invoice_count}</span></CustomTableBodyCell> */}
                        <CustomTableBodyCell align='right'>
                            {item.invoice_count ?
                                <ActionInvoiceListLink
                                    title={item.invoice_count}
                                    navigationState={{
                                        ...filter_data,
                                        action: 'sales_by_customer',
                                        customer_id: item.customer_id,
                                        customer: { display_name: item.customer_display_name },
                                    }}
                                />
                                : <span>{item.invoice_count}</span>
                            }
                        </CustomTableBodyCell>
                        <CustomTableBodyCell align='right'>
                            {item.credit_note_count ?
                                <ActionCreditNoteListLink
                                    title={item.credit_note_count}
                                    navigationState={{
                                        ...filter_data,
                                        action: 'sales_by_customer',
                                        customer_id: item.customer_id,
                                        customer: { display_name: item.customer_display_name },
                                    }}
                                />
                                : <span>{item.credit_note_count}</span>
                            }
                        </CustomTableBodyCell>

                        <CustomTableBodyCell align='right'><span><CurrencyFormatter amount={item.total_sales} currency={null} /></span></CustomTableBodyCell>
                        <CustomTableBodyCell align='right'><span><CurrencyFormatter amount={item.total_sales_with_tax} currency={null} /></span></CustomTableBodyCell>
                    </CustomTableRow>
                )
            })}
            {
                is_last_page ?
                    <CustomTableRow sx={{
                        pb: 1,
                        pt: 2,
                        background: '#FFF7DC',
                        borderTop: '1px solid #FFF0C0',
                        borderBottom: '1px solid #FFF0C0'
                    }}>
                        <CustomTableBodyCell><span>Total</span></CustomTableBodyCell>
                        <CustomTableBodyCell align='right'><span>{total.total_invoice_count}</span></CustomTableBodyCell>
                        <CustomTableBodyCell align='right'><span>{total.total_credit_note_count}</span></CustomTableBodyCell>
                        <CustomTableBodyCell align='right'><span><CurrencyFormatter amount={total.total_sales} currency={null} /></span></CustomTableBodyCell>
                        <CustomTableBodyCell align='right'><span><CurrencyFormatter amount={total.total_sales_with_tax} currency={null} /></span></CustomTableBodyCell>
                    </CustomTableRow>
                    : null
            }
        </CustomTableBody>
    )
}
