import moment from "moment/moment";
import React, { useContext } from "react";
import "react-multi-email/dist/style.css";
import { TaxList } from "../../common/GST";
import Input from "../../../../common/Input";
import * as Common from "../../common/Common";
import DateRange from "../../../../common/DateRange";
import DatePicker from "../../../../common/DatePicker";
import * as Actions from "../../../../../state/Actions";
import CustomTitle from "../../../../common/CustomTitle";
import { InputFile } from "../../../../common/InputFile";
import CommonAlertBox from "../../common/CommonAlertBox";
import { useNavigate, useLocation } from "react-router-dom";
import { routesName } from "../../../../../config/routesName";
import CommonDropdown from "../../../../common/CommonDropdown";
import CommonCalculation from "../../common/CommonCalculation";
import CustomDialog from "../../../../custom/dialog/CustomDialog";
import CustomButton from "../../../../custom/button/CustomButton";
import { getBusinessInfo } from "../../../../../config/cookiesInfo";
import CurrencyFormatter from "../../../../common/CurrencyFormatter";
import { TermsAndConditions } from "../../common/TermsAndConditions";
import { LabelWithAsteriskMark } from "../../../../common/CommonLabel";
import CommonAsyncDropdown from "../../../../common/CommonAsyncDropdown";
import { deliveryChallanTypeChoice } from "../../../../../utils/Constant";
import CommonCurrencySelection from "../../common/CommonCurrencySelection";
import CustomTypography from "../../../../custom/typography/CustomTypography";
import {eventsNames, AnalyticsEvent } from "../../../../../firebase/firebaseAnalytics";

import {
  apiAction,
  apiFormData
} from "../../../../../api/api";

import {
  getNotesPreferences,
  getDiscountPreferences,
  getAmountArePreferences,
  getTermsAndConditionPreferences,
} from "../../../setting/settings/Preferences/common/getPreferences";

import {
  CustomContainer,
  CustomTitleContainer,
  CustomButtonContainer,
} from "../../../../custom/container/CustomContainer";

import {
  basePriceTotal,
  checkItemDiscount,
  totalDiscountOnBasePrice,
  isFinalAmountRangeCrossed,
  convertExclusiveToInclusive,
  convertInclusiveToExclusive,
  applyTaxOnMultipleGSTComponents,
} from "../invoices/InvoiceManager";

import {
  setLoader,
  getAddress,
  filterTax,
  isFormValid,
  isAddressSame,
  findDefaultTax,
  getQueryParams,
  isApplicableForTax,
  stateChangeManager,
  isBusinessRegistered,
  breadCrumbsStateChangeManager,
} from "../../../../../utils/Utils";


import {
  get_item,
  get_party,
  list_party,
  list_items,
  get_list_of_tax,
  get_list_of_state,
  update_delivery_challan,
  create_delivery_challan,
  retrieve_delivery_challan,
  get_create_delivery_challan_meta_data,
} from "../../../../../api/urls";

import {
  Box,
  Grid,
  Checkbox,
  IconButton,
  FormControlLabel,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  CustomTable,
  CustomTableRow,
  CustomTableBody,
  CustomTableHead,
  CustomTableHeadCell,
  CustomTableBodyCell,
  CustomTableContainer,
} from "../../../../custom/table/CustomTable";

const Dropdown = DateRange;

// eslint-disable-next-line
const isNumeric = (num) =>
  (typeof num === "number" || (typeof num === "string" && num.trim() !== "")) &&
  !isNaN(num);

const CreateDeliveryChallan = (props) => {
  const { onClose, customer } = props;

  const navigate = useNavigate();
  let location = useLocation();
  const params = getQueryParams(location.search);
  const [isClear, setClear] = React.useState(false);
  const dispatch = Actions.getDispatch(React.useContext);
  const [isFormSubmitted, setFormSubmitted] = React.useState(false);
  const [state, setState] = React.useState({
    id: "",
    title: "",
    open: false,
    condition: "",
    maxWidth: "lg",
    compulsory: "",
    fullWidth: true,
  });

  const [zero, setZero] = React.useState(false);

  const [newItem, setNewItem] = React.useState(null);
  const [itemIndex, setItemIndex] = React.useState(null);

  const addButtonHandler = (title, condition, maxWidth, compulsory, index) => {
    setState({
      open: true,
      title: title,
      fullWidth: true,
      maxWidth: maxWidth,
      condition: condition,
      compulsory: compulsory,
    });
    setItemIndex(index);
    if (index!==null || index!==undefined) {
      setDeliveryChallan({ ...deliveryChallan });
    }
  };

  const [deliveryChallan, setDeliveryChallan] = React.useState({
    business_id: getBusinessInfo().id,
    customer_id: null,
    delivery_challan_date: moment().format("YYYY-MM-DD"),
    reference_number: null,
    delivery_challan_number: null,
    terms_and_conditions_id: getTermsAndConditionPreferences('delivery_challan'),
    place_of_supply_id: null,
    billing_address: null,
    shipping_address: null,
    challan_type: null,
    note: getNotesPreferences('delivery_challan'),
    delivery_challan_items: [
      {
        item: null,
        tax: null,
        rate: null,
        quantity: 1,
        tax_id: null,
        amount: null,
        item_id: null,
        unit_id: null,
        item_order: null,
        description: null,
        hsn_sac_code: null,
        item_level_discount: 0,
        is_item_discount_in_percentage: true,
      },
    ],
    subtotal: 0,
    tax: null,
    total_amount: 0,
    discount: 0,

    place_of_supply: null,
    payment_method: null,

    entity_level_discount: null,
    terms_and_condition: null,

    // Address
    same_as_billing_address: false,

    is_entity_discount: true,
    tax_applied: getAmountArePreferences(),
    is_amount_tax_inclusive: getAmountArePreferences() === 'inclusive',

    currency_symbol: null,
    currency_code: null,
    exchange_rate: 1,

    newCustomer: null,
    itemIndex: null,
    newItem: null,
    is_draft: false,

    attachment: null,
  });

  React.useEffect(() => {
    if (params.id) {
      breadCrumbsStateChangeManager(dispatch, "Edit");
      const getSalesOrder = async () => {
        setLoader(dispatch, Actions, true);
        let res = await apiAction({
          method: "post",
          url: retrieve_delivery_challan(params.id),
          data: { business_id: getBusinessInfo().id },
        });

        if (res?.success) {
          const item = res?.result;

          item.delivery_challan_items.map((item) => {
            if (item.tax) {
              item["tax_id"] = item.tax.id;
              item["item"] = {
                name: item.item,
                id: item.item_id,
                gst_rate: item.tax.rate ? item.tax.rate : null,
              };
            } else {
              item["tax_id"] = null;
              item["item"] = {
                name: item.item,
                id: item.item_id,
                gst_rate: null,
              };
            }
          });
          const partyRes = await apiAction({
            method: "post",
            url: get_party(item.customer_id),
            data: { business_id: getBusinessInfo().id },
          });
          setLoader(dispatch, Actions, false);

          setDeliveryChallan({
            ...deliveryChallan,
            ...item,
            customer: partyRes?.result,

            place_of_supply_id: item.place_of_supply.id,
            place_of_supply: item.place_of_supply.id
              ? { ...item.place_of_supply, name: item.place_of_supply.state }
              : null,

            same_as_billing_address:
              item.billing_address === item.shipping_address,

            terms_and_conditions_id: item.terms_and_conditions
              ? item.terms_and_conditions
              : null,
            terms_and_condition: item.terms_and_conditions
              ? {
                id: item.terms_and_conditions,
                name: item.terms_and_conditions_name,
              }
              : null,

            discount: item.discount,
            is_amount_tax_inclusive:
              res?.result.tax_applied === "inclusive" ? true : false,
          });
        }
      };
      setTimeout(() => {
        getSalesOrder();
      }, 1500);
      // eslint-disable-next-line
    }
  }, []);

  // api call to set the default delivery_challan_number
  React.useEffect(() => {
    const apiResults = async () => {
      let res = await apiAction({
        method: "post",
        url: get_create_delivery_challan_meta_data(),
        data: { business_id: getBusinessInfo().id },
      });
      if (res?.success) {
        deliveryChallan.delivery_challan_number =
          res?.result.delivery_challan_number;
        setDeliveryChallan({
          ...deliveryChallan,
          delivery_challan_number: res?.result.delivery_challan_number,
        });
      } else {
      }
    };
    if (!params.id) {
      apiResults();
    }
    // eslint-disable-next-line
  }, []);

  const onClear = () => {
    setClear(true);
    setDeliveryChallan({
      business_id: getBusinessInfo().id,
      customer_id: null,
      delivery_challan_date: moment().format("YYYY-MM-DD"),

      tax: null,
      subtotal: 0,
      discount: 0,
      total_amount: 0,
      expiry_date: null,
      estimate_date: null,
      place_of_supply: null,
      payment_method: null,
      reference_number: null,

      place_of_supply_id: null,
      entity_level_discount: null,
      terms_and_conditions_id: getTermsAndConditionPreferences('delivery_challan'),

      delivery_challan_number: deliveryChallan.delivery_challan_number,
      terms_and_condition: null,
      delivery_challan_items: [
        {
          item: null,
          tax: null,
          rate: null,
          quantity: 1,
          tax_id: null,
          amount: null,
          item_id: null,
          unit_id: null,
          item_order: null,
          description: null,
          hsn_sac_code: null,
          item_level_discount: 0,
          is_item_discount_in_percentage: true,
        },
      ],

      // Address
      note: getNotesPreferences('delivery_challan'),
      billing_address: null,
      shipping_address: null,
      same_as_billing_address: false,

      is_entity_discount: true,
      tax_applied: getAmountArePreferences(),
      is_amount_tax_inclusive: getAmountArePreferences() === 'inclusive',

      currency_code: null,
      newCustomer: null,
      itemIndex: null,
      newItem: null,
    });
  };
  //////////////////////////////////////////////////////////////////////////////////////////
  const gst_registration_type =
    deliveryChallan.customer && deliveryChallan.customer.gst_registration_type;

  let is_Location = gst_registration_type === "overseas";
  let is_gst_with_zero =
    gst_registration_type === "overseas" || gst_registration_type === "sez";
  //////////////////////////////////////////////////////////////////////////////////////////
  const onSave = async () => {
    console.log("deliveryChallan after save====>", deliveryChallan);

    setFormSubmitted(true);
    const { tax_applied, delivery_challan_items } = deliveryChallan;

    const is_rate = delivery_challan_items.find((item) => item.rate === null);
    const is_quantity = delivery_challan_items.find((item) => !item.quantity);
    const is_sales_order_item_not_selected = delivery_challan_items.find(
      (item) => !item.item_id
    );
    const is_tax = delivery_challan_items.find((item) => tax_applied !== "no_tax" && !item.tax_id);
    // const is_sales_order_item_hsn_sac_empty = delivery_challan_items.find((item) => !item.hsn_sac_code)

    let validation_data = [
      { key: "customer_id", message: "Please Select Customer" },
      { key: "challan_type", message: "Please Select Challan Type" },
      {
        key: "delivery_challan_number",
        message: "Please Enter Delivery Challan Number",
      },
      isBusinessRegistered() && !is_Location && {
        key: "place_of_supply_id",
        message: "Please Select Place of Supply",
      },
      {
        key: "delivery_challan_date",
        message: "Please Enter Valid Delivery Challan Date",
      },
      {
        key: "",
        validation: is_sales_order_item_not_selected,
        message: "Please Select Item",
      },
      {
        key: "",
        validation: is_quantity,
        message: "Please Enter the Quantity",
      },
      { key: "", validation: is_rate, message: "Please Enter the Rate" },
      { key: "", validation: is_tax, message: 'Please Select the Tax' },
    ];

    const { isValid, message } = isFormValid(deliveryChallan, validation_data);
    if (isValid) {

      if (isApplicableForTax(deliveryChallan.tax_applied,isBusinessRegistered())) {
        setZero('isApplicableForTax');
      } else if (deliveryChallan.total_amount === 0) {
        setZero('withZero');
      } else {
        onSaveAction();
      }
    } else {
      setLoader(dispatch, Actions, false);
      stateChangeManager(dispatch, Actions, true, "error", message);
    }
  };

  const onSaveAction = async () => {
    setLoader(dispatch, Actions, true);
    if (params.id) {
      AnalyticsEvent(eventsNames.categories.DELIVERY_CHALLANS,{action:eventsNames.actions.EDIT});
    } else {
      AnalyticsEvent(eventsNames.categories.DELIVERY_CHALLANS,{action:eventsNames.actions.CREATE});
    }

    let res = await apiFormData({
      method: "post",
      data: deliveryChallan,
      url: params.id
        ? update_delivery_challan(params.id)
        : create_delivery_challan(),
    });
    if (res?.success) {
      redirect(res?.result.id);
      setLoader(dispatch, Actions, false);
      stateChangeManager(dispatch, Actions, true, "success", res?.status);
    } else {
      setLoader(dispatch, Actions, false);
      stateChangeManager(dispatch, Actions, true, "error", res?.status);
    }
  };

  const redirect = (id) => {
    if (onClose) {
      onClose(id);
    } else {
      navigate(routesName.invoicingDeliveryChallanView.path + "?id=" + id);
    }
  };

  const onAddCustomer = (customer) => {
    setDeliveryChallan({ ...deliveryChallan, newCustomer: customer });
  };

  const onAddItem = (value) => {
    // Define the new item data
    const newItemData = {
      item: value,
      quantity: 1,
      item_order: null,
      item_id: value.id,
      unit_id: value?.unit?.id,
      amount: value.selling_price,
      description: value.description,
      hsn_sac_code: value.hsn_sac_code,
      rate: parseFloat(value.selling_price),
      item_level_discount: value.item_level_discount || 0,
      is_item_discount_in_percentage: checkItemDiscount(deliveryChallan?.delivery_challan_items),

      gst_rate: value?.tax?.rate,
      tax: deliveryChallan.tax_applied === "no_tax" ? null: value?.tax,
      tax_id: deliveryChallan.tax_applied === "no_tax" ? null : value?.tax?.id
    };

    // Update the deliveryChallan
    deliveryChallan.delivery_challan_items[itemIndex] = newItemData;

    // Set the updated deliveryChallan and new item
    setDeliveryChallan(prevChallan => ({
      ...prevChallan,
      newItem: value,
      delivery_challan_items: [...prevChallan.delivery_challan_items],
    }));

    // Update the new item state
    setNewItem(value);
  };


  return (
    <div>
      <CustomDialog
        state={state}
        setState={setState}
        itemIndex={deliveryChallan.itemIndex}
        onAddItem={(item) => onAddItem(item)}
        onAddCustomer={(customer) => onAddCustomer(customer)}
      />
      <CommonAlertBox
        open={zero}
        setOpen={setZero}
        onSaveAction={() => {
          if (zero==='withZero') {
            onSaveAction();
          }else if (deliveryChallan.total_amount === 0) {
            setTimeout(()=>{
              setZero('withZero');
            },100)
          } else{
            onSaveAction();
          }
        }}
        title={`Delivery Challan No. ${deliveryChallan.delivery_challan_number? deliveryChallan.delivery_challan_number: ""}`}
        message={
          zero==='withZero'?
          `You are about to create the Delivery Challan with zero amount. Are you sure you want to proceed?`
          :
          `Please consult the relevant provisions of the laws to determine the application of taxes for GST unregistered entities.`
        }
      />
      <CustomTitleContainer>
        <Grid
          container
          spacing={0}
          style={{
            alignItems: "center",
            justifyItems: "center",
            alignContent: "space-evenly",
            justifyContent: "space-evenly",
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: "-webkit-flex", justifyContent: "start" }}
          >
            <CustomTitle
              title={`Delivery Challan No. ${deliveryChallan.delivery_challan_number
                ? deliveryChallan.delivery_challan_number
                : ""
                }`}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: "-webkit-flex", justifyContent: "end" }}
          >
            <CustomTypography
              text={
                <span>
                  Balance Due:{" "}
                  <span style={{ fontWeight: "700", color: "#000000" }}>
                    <CurrencyFormatter
                      amount={deliveryChallan.total_amount}
                      currency={deliveryChallan.currency_code}
                    />
                  </span>
                </span>
              }
              sx={{
                fontSize: "16px",
                color: "#7A7A7A",
                fontWeight: "600",
                textAlign: "right",
                lineHeight: "22px",
                fontStyle: "normal",
                fontFamily: "Noto Sans",
              }}
            />
          </Grid>
        </Grid>
      </CustomTitleContainer>

      <CustomContainer maxWidth={"400"} sx={{}}>
        <form noValidate>
          <div style={{ borderBottom: "4px solid #F5F5F5" }}>
            <CustomDetails
              isClear={isClear}
              open={state.open}
              is_Location={is_Location}
              deliveryChallan={deliveryChallan}
              isFormSubmitted={isFormSubmitted}
              addButtonHandler={addButtonHandler}
              is_gst_with_zero={is_gst_with_zero}
              customer={customer ? customer : null}
              setDeliveryChallan={setDeliveryChallan}
            />
          </div>
          <ProductAndServicesDetails
            isClear={isClear}
            open={state.open}
            newItem={newItem}
            itemIndex={itemIndex}
            is_Location={is_Location}
            deliveryChallan={deliveryChallan}
            isFormSubmitted={isFormSubmitted}
            addButtonHandler={addButtonHandler}
            is_gst_with_zero={is_gst_with_zero}
            setDeliveryChallan={setDeliveryChallan}
          />
        </form>
      </CustomContainer>

      <CustomButtonContainer>
        <Buttons
          onSave={onSave}
          onClear={onClear}
          isClear={isClear}
          is_Location={is_Location}
          onCancel={() => navigate(-1)}
          deliveryChallan={deliveryChallan}
          setFormSubmitted={setFormSubmitted}
          is_gst_with_zero={is_gst_with_zero}
        />
      </CustomButtonContainer>
    </div>
  );
};

export default CreateDeliveryChallan;

const CustomDetails = (props) => {
  const {
    is_gst_with_zero,
    is_Location,
    open,
    deliveryChallan,
    setDeliveryChallan,
    addButtonHandler,
    isFormSubmitted,
  } = props;

  let location = useLocation();
  const params = getQueryParams(location.search);

  const onChange = (key_name, value) => {
    setDeliveryChallan({ ...deliveryChallan, [key_name]: value });
  };

  React.useEffect(() => {
    if (deliveryChallan.same_as_billing_address) {
      setDeliveryChallan({
        ...deliveryChallan,
        shipping_address: deliveryChallan.billing_address,
      });
    } else {
      setDeliveryChallan({
        ...deliveryChallan,
        shipping_address: deliveryChallan.shipping_address,
      });
    }
    // eslint-disable-next-line
  }, [
    deliveryChallan.same_as_billing_address,
    deliveryChallan.billing_address,
  ]);

  React.useEffect(() => {
    if (deliveryChallan.newCustomer) {
      customerMappings(deliveryChallan.newCustomer);
    }
    // eslint-disable-next-line
  }, [deliveryChallan.newCustomer]);

  const customerMappings = (customer) => {
    if (customer) {
      setDeliveryChallan({
        ...deliveryChallan,
        customer: customer,
        customer_id: customer.id,

        billing_address: customer.billing_address ? getAddress(customer.billing_address) : "",
        shipping_address: customer.shipping_address ? getAddress(customer.shipping_address) : "",
        same_as_billing_address: isAddressSame(customer.billing_address ? getAddress(customer.billing_address) : null, customer.shipping_address ? getAddress(customer.shipping_address) : null),

        currency_id: customer.currency ? customer.currency.id : null,
        currency_symbol: customer.currency ? customer.currency.symbol : null,
        currency_code: customer.currency ? customer.currency.currency_code : null,

        place_of_supply: null,
        place_of_supply_id: null,
      });
    } else {
      setDeliveryChallan({
        ...deliveryChallan,
        customer: null,
        customer_id: null,
        currency_code: null,
        billing_address: null,
        shipping_address: null,
        same_as_billing_address: false,
      });
    }
  };

  return (
    <Box
      style={{
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingBottom: "32px",
      }}
    >
      <Grid container spacing={3}>
        {/*//////////////////////////////// Customer ////////////////////////////////*/}
        <Grid item xs={12} sm={4.5}>
          <CommonAsyncDropdown
            id={"customer_dropdown"}
            dataTestId={"customer_dropdown"}
            autoFocus={true}
            autoSelect={false}
            disableClearable={false}
            optionLabel="display_name"
            placeholder="Search Customer"
            noOptionsText={"No result found"}
            validation={isFormSubmitted && !deliveryChallan.customer_id}
            item={{
              open: open,
              method: "post",
              url: list_party(1),
              value: deliveryChallan.customer,
              disabled: Object.keys(params).length !== 0,
              label: <LabelWithAsteriskMark label={"Customer"} />,
              body: {
                is_inactive: false,
                business_id: getBusinessInfo().id,
                role: "customer",
              },
              // disabled: (Object.keys(params).length !== 0 && deliveryChallan.status === 'sent'),
              onChange: async (event, newValue) => {
                if (newValue) {
                  if (newValue.id !== deliveryChallan.customer_id) {
                    const partyRes = await apiAction({
                      method: "post",
                      url: get_party(newValue.id),
                      data: { business_id: getBusinessInfo().id },
                    });
                    if (partyRes?.success) {
                      customerMappings(partyRes?.result);
                    }
                  }
                } else {
                  customerMappings(newValue);
                }
              },
            }}
            addButton={{
              title: "+ Add new customer",
              onClick: () =>
                addButtonHandler("New Customer", "new_customer", "lg"),
            }}
          />
        </Grid>

        {/*//////////////////////////////// Challan Type ////////////////////////////////*/}
        <Grid item xs={12} sm={4.5}>
          <Dropdown
            id={"challan_type_dropdown"}
            dataTestId={"challan_type_dropdown"}
            disableClearable={false}
            placeholder="Select Challan Type"
            results={deliveryChallanTypeChoice}
            label={<LabelWithAsteriskMark label={"Challan Type"} />}
            validation={isFormSubmitted && !deliveryChallan.challan_type}
            setValue={(event, value) =>
              onChange("challan_type", value ? value.value : null)
            }
            value={
              deliveryChallan.challan_type
                ? deliveryChallanTypeChoice.find(
                  (option) => option.value === deliveryChallan.challan_type
                )
                : null
            }
          />
        </Grid>

        {/*//////////////////////////////// Delivery Challan Number ////////////////////////////////*/}
        <Grid item xs={12} sm={3}>
          <Input
            id={"input_order_number"}
            dataTestId={"input_order_number"}
            item={{
              type: "text",
              required: true,
              fullWidth: true,
              texttransform: "none",
              inputProps: { maxLength: 16 },
              placeholder: "Enter Delivery Challan Number",
              title: (
                <LabelWithAsteriskMark label={"Delivery Challan Number"} />
              ),
              onChange: (e) =>
                onChange("delivery_challan_number", e.target.value),
              validation:
                isFormSubmitted && !deliveryChallan.delivery_challan_number,
              value: deliveryChallan.delivery_challan_number
                ? deliveryChallan.delivery_challan_number
                : "",
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} sx={{ pt: 4 }}>
        {/*//////////////////////////////// Place of supply ////////////////////////////////*/}
        <Grid item xs={12} sm={4.5}>
          <CommonDropdown
            stateComponent={true}
            id={"place_of_supply_dropdown"}
            dataTestId={"place_of_supply_dropdown"}
            disableClearable={false}
            placeholder="Select a Location"
            validation={
              isBusinessRegistered() &&
               !is_Location &&
              isFormSubmitted &&
              !deliveryChallan.place_of_supply_id
            }
            item={{
              method: "post",
              textTransform: "none",
              disabled: is_Location,
              url: get_list_of_state(),
              value: deliveryChallan.place_of_supply,
              body: { business_id: getBusinessInfo().id },
              label: <LabelWithAsteriskMark label={"Place of Supply"} isAsteriskMark={isBusinessRegistered()} />,
              onChange: (event, value) => {
                if (value) {
                  setDeliveryChallan({
                    ...deliveryChallan,
                    place_of_supply: value,
                    place_of_supply_id: value.id,
                  });
                } else {
                  setDeliveryChallan({
                    ...deliveryChallan,
                    place_of_supply: null,
                    place_of_supply_id: null,
                  });
                }
              },
            }}
          />
        </Grid>

        {/*//////////////////////////////// Delivery Challan Date ////////////////////////////////*/}
        <Grid item xs={12} sm={4.5}>
          <DatePicker
            id={"delivery_challan_date_select"}
            dataTestId={"delivery_challan_date_select"}
            placeholder=""
            title={<LabelWithAsteriskMark label={"Delivery Challan Date"} />}
            validation={
              isFormSubmitted && !deliveryChallan.delivery_challan_date
            }
            date={
              deliveryChallan.delivery_challan_date
                ? moment(deliveryChallan.delivery_challan_date)
                : null
            }
            setDate={(date) => {
              onChange(
                "delivery_challan_date",
                date ? date.format("YYYY-MM-DD") : null
              );
            }}
          />
        </Grid>

        {/*//////////////////////////////// Reference  Number ///////////////////////////////////////*/}
        <Grid item xs={12} sm={3}>
          <Input
            id={"input_reference_number"}
            dataTestId={"input_reference_number"}
            item={{
              type: "text",
              required: true,
              fullWidth: true,
              texttransform: "none",
              title: "Reference  Number",
              placeholder: "Enter Reference Number",
              value: deliveryChallan.reference_number
                ? deliveryChallan.reference_number
                : "",
              onChange: (e) => onChange("reference_number", e.target.value),
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} sx={{ pt: 4 }}>
        {/*//////////////////////////////// Billing address ////////////////////////////////*/}
        <Grid item xs={12} sm={4.5}>
          <Input
            id={"input_billing_address"}
            dataTestId={"input_billing_address"}
            item={{
              rows: 4,
              type: "text",
              required: true,
              multiline: true,
              fullWidth: true,
              title: "Billing Address",
              placeholder: "Enter Billing Address",
              onChange: (e) => onChange("billing_address", e.target.value),
              // validation: isFormSubmitted && !deliveryChallan.billing_address,
              value: deliveryChallan.billing_address
                ? deliveryChallan.billing_address
                : "",
            }}
          />
        </Grid>

        {/*//////////////////////////////// Shipping address ////////////////////////////////*/}
        <Grid item xs={12} sm={4.5}>
          <Grid container item xs={12} sm={12}>
            <Grid item xs={5} sm={6}>
              <CustomTypography
                text={"Shipping Address"}
                sx={{
                  fontSize: "14px",
                  color: "#000000",
                  fontWeight: "700",
                  lineHeight: "18px",
                  fontStyle: "normal",
                  textTransform: "none",
                  fontFamily: "Noto Sans",
                  textTransform: "capitalize",
                }}
              />
            </Grid>
            <Grid item xs={7} sm={6} sx={{ mt: -3, textAlign: "right" }}>
              <FormControlLabel
                id={"same_as_billing_address_checkbox"}
                dataTestId={"same_as_billing_address_checkbox"}
                sx={{ mb: -5, textAlign: "right" }}
                control={
                  <Checkbox
                    size="small"
                    checked={deliveryChallan.same_as_billing_address}
                    onClick={() =>
                      onChange(
                        "same_as_billing_address",
                        !deliveryChallan.same_as_billing_address
                      )
                    }
                    sx={{ color: "#9b9898" }}
                  />
                }
                label={
                  <span
                    style={{
                      color: "#000000",
                      fontFamily: "Noto Sans",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "16px",
                      fontSize: "12px",
                    }}
                  >
                    Same as Billing Address
                  </span>
                }
              />
            </Grid>
          </Grid>
          <Input
            id={"input_shipping_address"}
            dataTestId={"input_shipping_address"}
            item={{
              rows: 4,
              title: "",
              type: "text",
              required: true,
              multiline: true,
              fullWidth: true,
              placeholder: "Enter Shipping Address",
              disabled: deliveryChallan.same_as_billing_address,
              onChange: (e) => onChange("shipping_address", e.target.value),
              // validation: isFormSubmitted && !deliveryChallan.shipping_address,
              value: deliveryChallan.shipping_address
                ? deliveryChallan.shipping_address
                : "",
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const ProductAndServicesDetails = (props) => {
  const {
    is_gst_with_zero,
    open,
    deliveryChallan,
    setDeliveryChallan,
    addButtonHandler,
    isClear,
    isFormSubmitted,
  } = props;
  const [data, setData] = React.useState(
    deliveryChallan.delivery_challan_items
  );

  const onAddLine = () => {
    deliveryChallan.delivery_challan_items.push({
      item: null,
      tax: null,
      rate: null,
      quantity: 1,
      tax_id: null,
      amount: null,
      item_id: null,
      unit_id: null,
      item_order: null,
      hsn_sac_code: null,
      item_level_discount: 0,
      is_item_discount_in_percentage: checkItemDiscount(deliveryChallan?.delivery_challan_items),
    });

    setData([...deliveryChallan.delivery_challan_items]);
  };

  const onDeleteLine = (index) => {
    if (deliveryChallan.delivery_challan_items.length > 1) {
      deliveryChallan.delivery_challan_items.splice(index, 1);
      setData([...deliveryChallan.delivery_challan_items]);
    } else {
      setData([
        {
          item: null,
          tax: null,
          rate: null,
          quantity: 1,
          tax_id: null,
          amount: null,
          item_id: null,
          unit_id: null,
          item_order: null,
          description: null,
          hsn_sac_code: null,
          item_level_discount: 0,
          is_item_discount_in_percentage: checkItemDiscount(deliveryChallan?.delivery_challan_items),
        },
      ]);
    }
  };

  const onClearLines = () => {
    setData([
      {
        item: null,
        tax: null,
        rate: null,
        quantity: 1,
        tax_id: null,
        amount: null,
        item_id: null,
        unit_id: null,
        item_order: null,
        description: null,
        hsn_sac_code: null,
        item_level_discount: 0,
        is_item_discount_in_percentage: checkItemDiscount(deliveryChallan?.delivery_challan_items),
      },
    ]);
  };

  const accounts = applyTaxOnMultipleGSTComponents(
    deliveryChallan,
    "delivery_challan_items",
    ""
  );

  const totalAmount = basePriceTotal(
    deliveryChallan,
    deliveryChallan.delivery_challan_items
  ).toFloat();

  const totalTax = Object.values(accounts)
    .reduce((acc, tax) => acc + tax, 0)
    .toFloat();

  const totalDiscount = totalDiscountOnBasePrice(
    deliveryChallan,
    deliveryChallan.delivery_challan_items
  ).toFloat();

  const subTotal = deliveryChallan.is_amount_tax_inclusive
    ? deliveryChallan.delivery_challan_items
      .filter((item) => isNumeric(item.amount))
      .reduce((a, b) => a + parseFloat(b.amount), 0)
      .toFloat()
    : totalAmount.toFloat();

  const finalPayment =
    totalAmount.toFloat() - totalDiscount.toFloat() + totalTax.toFloat();

  deliveryChallan.tax = {
    ...accounts,
    total: parseFloat(parseFloat(totalTax).toFixed(2)),
  };
  deliveryChallan.total_amount = (
    totalAmount +
    totalTax -
    totalDiscount
  ).toFloat();
  deliveryChallan.discount = totalDiscount.toFloat();
  deliveryChallan.subtotal = totalAmount.toFloat();

  React.useEffect(() => {
    setDeliveryChallan({
      ...deliveryChallan,
      delivery_challan_items: data,
      total_amount: (totalAmount + totalTax - totalDiscount).toFloat(),
      discount: totalDiscount.toFloat(),
      subtotal: totalAmount.toFloat(),
    });
    // eslint-disable-next-line
  }, [data]);

  if (!("IGST" in deliveryChallan.tax)) {
    deliveryChallan.tax["IGST"] = 0;
  }
  if (!("SGST" in deliveryChallan.tax)) {
    deliveryChallan.tax["SGST"] = 0;
  }
  if (!("CGST" in deliveryChallan.tax)) {
    deliveryChallan.tax["CGST"] = 0;
  }

  const filterList = [
    { name: "Exclusive", value: "exclusive" },
    { name: "Inclusive", value: "inclusive" },
    { name: "No Tax", value: "no_tax" },
  ];

  const onAmountsAreInChange = (selected_type) => {
    let previous = deliveryChallan.tax_applied;
    if (previous !== selected_type.value) {
      if (selected_type.value === "inclusive") {
        deliveryChallan.is_amount_tax_inclusive = true;
        convertExclusiveToInclusive(deliveryChallan.delivery_challan_items);
        deliveryChallan.total_amount = totalAmount - totalDiscount;
      } else if (selected_type.value === "no_tax") {
        if (previous === filterList[1].value) {
          convertInclusiveToExclusive(deliveryChallan.delivery_challan_items);
        }
        // eslint-disable-next-line
        deliveryChallan.delivery_challan_items.map((sales_item) => {
          sales_item.tax = null;
          sales_item.tax_id = null;
        });
        deliveryChallan.is_amount_tax_inclusive = false;
        deliveryChallan.total_amount = totalAmount - totalDiscount;
      } else {
        deliveryChallan.is_amount_tax_inclusive = false;
        convertInclusiveToExclusive(deliveryChallan.delivery_challan_items);
      }
      setDeliveryChallan({
        ...deliveryChallan,
        tax_applied: selected_type.value,
      });
      setData([...deliveryChallan.delivery_challan_items]);
    }
  };

  const [isDiscountFiled, setIsDiscountFiled] = React.useState(getDiscountPreferences());
  React.useEffect(() => {
    if (deliveryChallan.discount) {
      setIsDiscountFiled(getDiscountPreferences() || (deliveryChallan.discount ? true : false))
    }
  }, [deliveryChallan.discount]);
  return (
    <div>
      <Box
        style={{ paddingRight: "20px", }}
        sx={{ pb: 2, pt: 2, display: { sx: 'block', sm: 'flex' }, justifyContent: "flex-end" }}
      >
        <CommonCurrencySelection
          isCurrency={true}
          isAmountAre={true}
          data={deliveryChallan}
          dropdown_key='customer_id'
          setData={setDeliveryChallan}
          isFormSubmitted={isFormSubmitted}
          onAmountsAreInChange={onAmountsAreInChange}
        />
      </Box>

      <Box sx={{ mb: 2 }}>
        <CustomTableContainer sx={{ overflowX: 'hidden' }} className="custom_table_css">
          <CustomTable>
            <ProductAndServicesDetailsHeaders isDiscountFiled={isDiscountFiled} data={deliveryChallan} setData={setDeliveryChallan} />
            <ProductAndServicesDetailsBody
              isDiscountFiled={isDiscountFiled}

              open={open}
              setData={setData}
              onDeleteLine={onDeleteLine}
              onClearLines={onClearLines}
              finalPayment={finalPayment}
              isFormSubmitted={isFormSubmitted}
              deliveryChallan={deliveryChallan}
              addButtonHandler={addButtonHandler}
              is_gst_with_zero={is_gst_with_zero}
              setDeliveryChallan={setDeliveryChallan}
              data={deliveryChallan.delivery_challan_items}
            />
          </CustomTable>
        </CustomTableContainer>
      </Box>

      <Grid
        container
        style={{
          paddingBottom: "24px",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
      >
        <Grid item xs={12} sm={6}>
          <Grid item xs={12}>
            <CustomTypography
              id={"add_action"}
              dataTestId={"add_action"}
              text={"+ Add Line"}
              onClick={onAddLine}
              sx={{
                fontSize: "12px",
                color: "#2464EB",
                cursor: "pointer",
                display: "inline",
                fontWeight: "700",
                lineHeight: "16px",
                fontStyle: "normal",
                textTransform: "none",
                fontFamily: "Noto Sans",
                ":hover": { textDecoration: "underline" },
              }}
            />
            <CustomTypography
              text={"|"}
              sx={{
                mr: 1,
                ml: 1,
                fontSize: "12px",
                color: "#B8B8B8",
                fontWeight: "700",
                lineHeight: "16px",
                fontStyle: "normal",
                display: "inline",
                textTransform: "none",
                fontFamily: "Noto Sans",
              }}
            />
            <CustomTypography
              id={"clear_action"}
              dataTestId={"clear_action"}
              text={"Clear All Lines"}
              onClick={onClearLines}
              sx={{
                fontSize: "12px",
                color: "#2464EB",
                cursor: "pointer",
                display: "inline",
                fontWeight: "700",
                lineHeight: "16px",
                fontStyle: "normal",
                textTransform: "none",
                fontFamily: "Noto Sans",
                ":hover": { textDecoration: "underline" },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={9} sx={{ mt: 3 }}>
            <Input
              id={"input_note"}
              dataTestId={"input_note"}
              item={{
                rows: 4,
                type: "text",
                title: "Notes",
                required: true,
                multiline: true,
                fullWidth: true,
                placeholder: "Notes",
                texttransform: "none",
                value: deliveryChallan.note ? deliveryChallan.note : "",
                onChange: (event) => {
                  setDeliveryChallan({
                    ...deliveryChallan,
                    note: event.target.value,
                  });
                },
              }}
            />
          </Grid>
          {/*//////////////////////////////// attachment ////////////////////////////////*/}

          <Grid item xs={12} sm={9} sx={{ mt: 3 }}>
            <InputFile
              id={"attachment_action"}
              dataTestId={"attachment_action"}
              onFileUpload={(file) => {
                setDeliveryChallan({ ...deliveryChallan, attachment: file });
              }}
              attachment={deliveryChallan.attachment}
              onFileRemove={() => {
                setDeliveryChallan({ ...deliveryChallan, attachment: null });
              }}
            />
          </Grid>

          {/*//////////////////////////////// Terms and Conditions ////////////////////////////////*/}
          <Grid item xs={12} sm={9} sx={{ mt: 4 }}>
            <TermsAndConditions
              title="Terms and Conditions"
              value={deliveryChallan.terms_and_conditions_id}
              setValue={(value) =>
                setDeliveryChallan({
                  ...deliveryChallan,
                  terms_and_conditions_id: value ? value.id : null,
                })
              }
            />
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6}>
          <CommonCalculation
            isCurrency={true}
            data={deliveryChallan}
            dropdown_key='customer_id'
            setData={setDeliveryChallan}
            isFormSubmitted={isFormSubmitted}


            accounts={accounts}
            subTotal={subTotal}
            totalTax={totalTax}
            totalAmount={totalAmount}
            finalPayment={finalPayment}
            totalDiscount={totalDiscount}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const ProductAndServicesDetailsHeaders = (props) => {
  const { isDiscountFiled, data, setData } = props;
  return (
    <CustomTableHead>
      <CustomTableRow><CustomTableHeadCell style={{ width: 5 }} align="left">  <span>#</span></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 310 }} align="left">  <span>Goods/Service</span></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 125 }} align="left">  <span>HSN/SAC</span></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 200 }} align="left">  <span>Description</span></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 185 }} align="left"><Common.QuantityLabelComponent data={data} setData={setData} items='delivery_challan_items' /></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 175 }} align="left"><Common.RateLabelComponent data={data} setData={setData} items='delivery_challan_items' /></CustomTableHeadCell>
        {isDiscountFiled &&
          <CustomTableHeadCell style={{ width: 100 }} align="left">  <Common.DiscountLabelComponent data={data} setData={setData} items='delivery_challan_items' /></CustomTableHeadCell>
        }
        <CustomTableHeadCell style={{ width: 200 }} align="left">  <span>Tax</span></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 145 }} align="left">  <span>Tax Amount</span></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 145 }} align="left">  <span>Amount</span></CustomTableHeadCell>

        <CustomTableHeadCell style={{ width: 5 }} align="right" />
      </CustomTableRow>
    </CustomTableHead>
  );
};

const ProductAndServicesDetailsBody = (props) => {
  const {
    isDiscountFiled,
    is_gst_with_zero,
    open,
    data,
    onDeleteLine,
    setData,
    addButtonHandler,
    deliveryChallan,
    newItem,
    itemIndex,
    setDeliveryChallan,
    isFormSubmitted,
    finalPayment,
  } = props;

  let location = useLocation();
  const params = getQueryParams(location.search);
  const dispatch = Actions.getDispatch(useContext);
  const [taxResult, setTaxResult] = React.useState([]);

  let taxApplied = deliveryChallan.tax_applied;
  const place_of_supply_id = deliveryChallan.place_of_supply_id;
  const taxList = filterTax(taxResult, place_of_supply_id,is_gst_with_zero);

  React.useEffect(() => {
    const apiResults = async () => {
      let res = await apiAction({
        method: "post",
        url: get_list_of_tax(),
        data: {
          business_id: getBusinessInfo().id,
        },
      });
      if (res?.success) {
        setTaxResult(res?.result);
      } else {
      }
    };
    apiResults();
    // eslint-disable-next-line
  }, []);

  const apiItemResults = async (id, index) => {
    let res = await apiAction({
      method: "post",
      url: get_item(id),
      data: { business_id: getBusinessInfo().id },
    });
    if (res?.success) {
      onItemSelect(res?.result, index);
    } else {
    }
  };

  const onItemSelect = (value, index) => {

    const getDefaultTax = () => {
      return findDefaultTax(taxList, is_gst_with_zero, value)
    };

    const getItemData = () => ({
      item: value,
      item_order: null,
      unit_id: value.unit?.id,
      quantity: value.quantity || 1,
      description: value.description,
      hsn_sac_code: value.hsn_sac_code,
      item_id: value.item_id || value.id,
      amount: value.amount || value.selling_price,
      rate: parseFloat(value.rate || value.selling_price),
      item_level_discount: value.item_level_discount || 0,
      is_item_discount_in_percentage: checkItemDiscount(deliveryChallan?.delivery_challan_items),

      gst_rate: getDefaultTax()?.rate,
      tax: taxApplied === "no_tax" ? null : getDefaultTax(),
      tax_id: taxApplied === "no_tax" ? null : getDefaultTax()?.id,
    });

    const updateData = () => {
      data[index] = value ? getItemData() : {
        item: null,
        tax: null,
        rate: null,
        quantity: 1,
        tax_id: null,
        amount: null,
        item_id: null,
        unit_id: null,
        item_order: null,
        description: null,
        hsn_sac_code: null,
        item_level_discount: 0,
        is_item_discount_in_percentage: checkItemDiscount(deliveryChallan?.delivery_challan_items),
      };
    };

    const isAmountRangeExceeded = () => isFinalAmountRangeCrossed({
      quantity: data[index]?.quantity,
      rate: data[index]?.rate,
      item_level_discount: data[index]?.item_level_discount,
      item: data[index],
      invoice: deliveryChallan,
      finalPayment: data.length <= 1
        ? 0
        : finalPayment - (data[index]?.amount || 0) - (data[index]?.tax_amount || 0),
    });

    if (isAmountRangeExceeded()) {
      stateChangeManager(
        dispatch,
        Actions,
        true,
        "error",
        "Amount range max limit reached"
      );
    } else {
      updateData();
      setData([...data]);
      setDeliveryChallan({  ...deliveryChallan,  delivery_challan_items: [...data],});
    }
  };


  React.useEffect(() => {
    if (newItem) {
      setTimeout(() => {
        onItemSelect(newItem, itemIndex);
      }, 500);
    }
    // eslint-disable-next-line
  }, [newItem]);

  React.useEffect(() => {

    const getDefaultTax = (value) => {
      return findDefaultTax(taxList, is_gst_with_zero, value);
    };

      const updatedData = data.map((item) => {
        const defaultTax = getDefaultTax(item);
        const isDefaultSelectTax = item.tax_id || is_gst_with_zero;
  
        // Calculate values based on conditions
        const gstRate = isDefaultSelectTax ? defaultTax?.rate ?? null : null;
        const tax = isDefaultSelectTax ? (taxApplied === "no_tax" ? null : defaultTax) : null;
        const taxId = isDefaultSelectTax ? (taxApplied === "no_tax" ? null : defaultTax?.id ?? null) : null;
  
        return {
          ...item,
          tax: tax,
          tax_id: taxId,
          gst_rate: gstRate,
        }
      });

      setData(updatedData);
      setDeliveryChallan((prev) => ({
        ...prev,
        delivery_challan_items: updatedData
      }));
    
    // eslint-disable-next-line

  }, [is_gst_with_zero,place_of_supply_id,taxApplied]);

  return (
    <CustomTableBody>
      {deliveryChallan.delivery_challan_items.map((item, index) => {
        item['item_order'] = index + 1;

        return (
          <CustomTableRow key={index}>
            <CustomTableBodyCell sx={{}} align="left">
              <span>{index + 1}</span>
            </CustomTableBodyCell>

            <CustomTableBodyCell sx={{}} align="left">
              <CommonAsyncDropdown
                id={"item_dropdown_" + index}
                dataTestId={"item_dropdown_" + index}
                optionLabel="name"
                autoSelect={false}
                disableClearable={false}
                noOptionsText={"No result found"}
                placeholder="Select Goods/Service"
                validation={isFormSubmitted && !data[index].item_id}
                item={{
                  open: open,
                  method: "post",
                  url: list_items(1),
                  value: data[index].item,
                  onChange: (event, value) => {
                    if (value) {
                      data[index].item = value.name;
                      setData([...data]);
                      apiItemResults(value.id, index);
                    } else {
                      onItemSelect(value, index);
                    }
                  },
                  body: {
                    role: "customer",
                    business_id: getBusinessInfo().id,
                    for_sales: true,
                  },
                }}
                addButton={{
                  title: "+ Add an item",
                  onClick: () =>
                    addButtonHandler(
                      "New Item",
                      "new_pas",
                      "lg",
                      "sales",
                      index
                    ),
                }}
              />
            </CustomTableBodyCell>

            <CustomTableBodyCell sx={{}} align="left">
              <Input
                id={"input_hsn_sac_code_" + index}
                dataTestId={"input_hsn_sac_code_" + index}
                item={{
                  type: "text",
                  disabled: true,
                  required: true,
                  fullWidth: true,
                  placeholder: "",
                  value: data[index]?.hsn_sac_code
                    ? data[index]?.hsn_sac_code
                    : "",
                }}
              />
            </CustomTableBodyCell>

            <CustomTableBodyCell sx={{}} align="left">
              <Input
                id={"input_description_" + index}
                dataTestId={"input_description_" + index}
                isSearchableComponent={true}
                onFocus={() => {
                  data[index].focus = true;
                  setData([...data]);
                }}
                onBlur={() => {
                  data[index].focus = false;
                  setData([...data]);
                }}
                item={{
                  type: "text",
                  required: true,
                  multiline: true,
                  fullWidth: true,
                  rows: data[index]?.focus ? 4 : 1,
                  value: data[index]?.description ? data[index]?.description : "",
                  placeholder: "",
                  onChange: (event) => {
                    data[index].description = event.target.value;
                    setData([...data]);
                  },
                }}
              />
            </CustomTableBodyCell>

            <CustomTableBodyCell sx={{}} align="left">
              <Common.QuantityInputComponent
                index={index}
                data={data}
                setData={setData}
                finalPayment={finalPayment}
                quantityData={deliveryChallan}
                isFormSubmitted={isFormSubmitted}
              />
            </CustomTableBodyCell>

            <CustomTableBodyCell sx={{}} align="left">
              
              <Common.RateInputComponent
                index={index}
                data={data}
                setData={setData}
                rateData={deliveryChallan}
                finalPayment={finalPayment}
                isFormSubmitted={isFormSubmitted}
              />
            </CustomTableBodyCell>

            {isDiscountFiled &&
              <CustomTableBodyCell sx={{}} align="left">
                <Common.DiscountInputComponent
                  index={index}
                  data={data}
                  setData={setData}
                  discountData={deliveryChallan}
                  finalPayment={finalPayment}
                />
        

              </CustomTableBodyCell>
            }

            <CustomTableBodyCell sx={{}} align="left">
              <TaxList
                id={"tax_dropdown_" + index}
                dataTestId={"tax_dropdown_" + index}
                result={taxList}
                value={data[index]?.tax}
                disableClearable={false}
                inputDisabled={
                  is_gst_with_zero
                    ? is_gst_with_zero
                    : deliveryChallan.tax_applied === "no_tax"
                }
                validation={isFormSubmitted && deliveryChallan.tax_applied !== 'no_tax' && !data[index]?.tax_id}
                setValue={(tax) => {
                  if (
                    isFinalAmountRangeCrossed({
                      quantity: data[index]?.quantity,
                      rate: data[index]?.rate,
                      item_level_discount: data[index].item_level_discount,
                      item: { ...data[index], tax: tax },
                      invoice: deliveryChallan,
                      finalPayment:
                        data.length <= 1
                          ? 0
                          : finalPayment -
                          (data[index]?.amount ? data[index]?.amount : 0) -
                          (data[index]?.tax_amount
                            ? data[index]?.tax_amount
                            : 0),
                    })
                  ) {
                    stateChangeManager(
                      dispatch,
                      Actions,
                      true,
                      "error",
                      "Amount range max limit reached"
                    );
                  } else {
                    if (tax) {
                      data[index].tax = tax;
                      data[index].tax_id = tax.id;
                      data[index].gst_rate = tax.rate;
                    } else {
                      data[index].tax = null;
                      data[index].tax_id = null;
                      data[index].tax_amount = null;
                    }
                    setData([...data]);
                  }
                }}
              />
            </CustomTableBodyCell>

            <CustomTableBodyCell sx={{}} align="left">
              <Input
                id={"input_tax_amount_" + index}
                dataTestId={"input_tax_amount_" + index}
                editable={false}
                is_currency_symbol={true}
                isShowCommaSeparatedValue={true}
                currency_code={deliveryChallan.currency_code}
                item={{
                  type: "text",
                  disabled: true,
                  fullWidth: true,
                  required: true,
                  placeholder: "",
                  value:
                    data[index]?.tax_amount &&
                      deliveryChallan.tax_applied !== "no_tax"
                      ? data[index]?.tax_amount
                      : "",
                }}
              />
            </CustomTableBodyCell>

            <CustomTableBodyCell sx={{}} align="left">
              <Input
                id={"input_amount_" + index}
                dataTestId={"input_amount_" + index}
                editable={false}
                is_currency_symbol={true}
                isShowCommaSeparatedValue={true}
                currency_code={deliveryChallan.currency_code}
                item={{
                  disabled: true,
                  type: "number",
                  fullWidth: true,
                  required: true,
                  placeholder: "",
                  value: data[index]?.amount ? data[index]?.amount : "",
                }}
              />
            </CustomTableBodyCell>

            <CustomTableBodyCell sx={{}} align="right">
              <IconButton
                onClick={() => onDeleteLine(index)}
                id={"delete_icon_" + index}
                dataTestId={"delete_icon_" + index}
              >
                <DeleteIcon />
              </IconButton>
            </CustomTableBodyCell>

          </CustomTableRow>
        );
      })}
    </CustomTableBody>
  );
};

const Buttons = (props) => {
  const { is_gst_with_zero, onSave, onCancel, onClear } = props;
  let location = useLocation();
  const params = getQueryParams(location.search);

  const clearActionDisabled =
    Object.keys(params).length === 0 || params.clone_id;

  return (
    <Box component="form" noValidate>
      <Grid container>
        <Grid item xs={12} sm={4} sx={{ display: "-webkit-flex", justifyContent: "start" }}>
          {clearActionDisabled && (
            <CustomButton
              id={"clear_btn"}
              dataTestId={"clear_btn"}
              variant="outlined"
              sx={{
                textTransform: "none",
                "&:hover": { backgroundColor: "#e8f3ff" },
              }}
              btnLabel="Clear"
              onClick={() => {onClear();}}
            />
          )}
          <CustomButton
            id={"cancel_btn"}
            dataTestId={"cancel_btn"}
            variant="contained"
            sx={{ ml: 1, textTransform: "none" }}
            btnLabel="Cancel"
            onClick={() => {onCancel();}}
          />
        </Grid>
        <Grid item xs={12} sm={8} sx={{ display: "-webkit-flex", justifyContent: "end" }}>
          <CustomButton
            id={"save_btn"}
            dataTestId={"save_btn"}
            variant="contained"
            sx={{ textTransform: "none" }}
            btnLabel={params.id ? "Update" : "Save"}
            onClick={() => {onSave({ is_draft: true });}}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
