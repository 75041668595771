import React from "react";
import { _Sidebar } from './common/CustomComponent';
import { HtmlTooltip } from "../../tooltip/CustomTooltip";


import {
    Box,
    styled,
    IconButton,
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';


const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    left: 0,
    right: 0,
    bottom: 0,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    padding: '16px',
    paddingTop: '0px',
    position: 'absolute',
    paddingBottom: '0px',
    maxHeight: '50px !important',
    borderTop: '2px solid #F2F2F2',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);


const Sidebar = (props) => {
    const [open, setOpen] = React.useState(true);

    const handleDrawer = () => {
        setOpen(!open);
    };
    return (
        <React.Fragment>

            <Drawer className="w_sidebar" variant="permanent" open={open} sx={{ maxHeight: '100vh', }}>
                <Box sx={{ height: 'auto', backgroundColor: '#FFFFFF', }}>
                    <_Sidebar {...props} open={open} setOpen={setOpen} setForMobile={() => { }} height={'calc(100vh - 140px)'} CustomTooltip={!open ? HtmlTooltip : 'span'} />
                </Box>
                <DrawerHeader onClick={handleDrawer} id={'drawer_header_action'} dataTestId={'drawer_header_action'}>
                    <HtmlTooltip title={open ? 'Close Navigation' : 'Open Navigation'} placement="right">
                        <IconButton onClick={handleDrawer} id={'side_bar_close_icon'} dataTestId={'side_bar_close_icon'} sx={{ bgcolor: '#f5f5f5',":hover":{bgcolor:'#e0e0e0'} }}>
                            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        </IconButton>
                    </HtmlTooltip>
                </DrawerHeader>
            </Drawer>

        </React.Fragment>


    )
}

export default Sidebar;





