import React from 'react'

import {
    Tab,
    Tabs,
    styled,
    Box,
} from '@mui/material'
import { AnalyticsEvent } from '../../../firebase/firebaseAnalytics'
import { getUserDetails } from '../../../config/cookiesInfo'

const CustomTabs = (props) => {
    const { id, dataTestId, tabs, value, onChange,sx,variant="scrollable" } = props

    const onTabChange = (event, selectedTab) => {
        onChange(event, selectedTab)
        let tab = selectedTab.name ? selectedTab.name : selectedTab
        // AnalyticsEvent("selected_tab",{tab:tab, email: getUserDetails().email})
    }

    return (

        <AntTabs sx={{...sx}} id={id} dataTestId={dataTestId} value={value} onChange={onTabChange} variant={variant}
        // tabItemContainerStyle={{position: "fixed", bottom:"0"}}
        >
            {
                tabs.map((item, index) => {
                    return (
                        item &&
                        <AntTab id={id + index} dataTestId={dataTestId + index} key={index} label={item.name ? item.name : item} />
                    )
                })
            }
        </AntTabs>


    )
}

export default CustomTabs;

const AntTabs = styled(Tabs)({
    fontSize: '14px',
    paddingLeft: '24px',
    paddingRight: '24px',
    overflowX: 'scroll',
    borderBottom: '1px solid #e8e8e8',
    '& .MuiTabs-indicator': {
        height: '5px',
        borderRadius: '6px',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#2464EB',
    },


});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    whiteSpace:'nowrap',
    textTransform: 'none',
    display:'inline',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
        minWidth: 0,
    },
    fontWeight: 400,
    marginRight: theme.spacing(1),
    color: '#141414',
    opacity: 0.8,
    fontSize: '14px',
    fontFamily: [
        'Noto Sans',
    ].join(','),
    '&:hover': {
        color: '#2464EB',
        opacity: 1,
        fontSize: '14px',
    },
    '&.Mui-selected': {
        color: '#141414',
        fontWeight: 700,
        opacity: 1,
        fontSize: '14px',
    },
    '&.Mui-focusVisible': {
        backgroundColor: '#d1eaff',
    },

}));




const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
))({
    '& .MuiTabs-indicator': {
        height: '5px',
        borderRadius: '6px',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
        maxWidth: 90,
        width: '100%',
        backgroundColor: '#2464EB',
        borderRadius: '6px',
    },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        textTransform: 'none',
        minWidth: 0,
        [theme.breakpoints.up('sm')]: {
            minWidth: 0,
        },
        fontWeight: 400,
        marginRight: theme.spacing(1),
        color: '#141414',
        opacity: 0.8,
        lineHeight: '19px',
        fontSize: '14px',
        fontFamily: [
            'Noto Sans',
        ].join(','),
        '&:hover': {
            color: '#2464EB',
            opacity: 1,
        },
        '&.Mui-selected': {
            color: '#141414',
            fontWeight: 'bold',

        },
        '&.Mui-focusVisible': {
            backgroundColor: '#d1eaff',
        },
    }),
);
