import React from 'react';
import { Box, Grid } from '@mui/material';
import Highcharts from "highcharts/highstock";
import { useNavigate } from 'react-router-dom';
import { apiAction } from '../../../../api/api';
import PieChart from "highcharts-react-official";
import DateRange from '../../../common/DateRange';
import * as Actions from '../../../../state/Actions';
import { routesName } from '../../../../config/routesName';
import { stateChangeManager } from '../../../../utils/Utils';
import { getBusinessInfo } from '../../../../config/cookiesInfo';
import CustomTypography from '../../../custom/typography/CustomTypography';
import { CustomDataPickerPopover } from '../../../custom/Datepicker/CustomDataPickerPopover';
import { amountFormatter, numberWithSuffix, multiple_date_range, getDateRanges } from '../../../../utils/Utils';
import { eventsNames, AnalyticsEvent } from '../../../../firebase/firebaseAnalytics';

const TopCustomers = React.memo((props) => {
    const { id, dataTestId, url, custom_date_range } = props;

    const navigate = useNavigate();
    const DropdownRef = React.useRef(null);
    const dispatch = Actions.getDispatch(React.useContext);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const initialDateRange = React.useMemo(() => multiple_date_range()[4], []);
    const [dateState, setDateState] = React.useState({
        dates: getDateRanges(initialDateRange?.dates),
        selectedDateRange: initialDateRange,
    });
    const [result, setResult] = React.useState([]);

    const getApiResults = React.useCallback(async () => {
        if (!url) return;
        const res = await apiAction({
            url: url,
            method: 'post',
            navigate: navigate,
            dispatch: dispatch,
            data: { business_id: getBusinessInfo().id, top_count: 10, ...dateState.dates },
        });
        if (res?.success) {
            setResult(res.result);
        } else {
            stateChangeManager(dispatch, Actions, true, "error", res?.status);
        }
    }, [url, dateState.dates, navigate, dispatch]);

    React.useEffect(() => {
        getApiResults();
    }, [getApiResults]);

    const categories = React.useMemo(() => result.map(item => ({
        id: item.customer_id,
        name: item.customer_display_name,
        current_sales_amount: item.sales_amount,
        previous_sales_amount: item.previous_sales_amount,
    })), [result]);

    const options = React.useMemo(() => ({
        chart: { type: 'bar' },
        title: { text: '' },
        xAxis: {
            categories: categories,
            title: { text: null },
            labels: {
                formatter: function () { return this.value.name; }
            }
        },
        yAxis: {
            min: 0,
            title: { text: null },
            labels: {
                formatter: function () { return numberWithSuffix(this.value); }
            },
        },
        tooltip: {
            useHTML: true,
            borderWidth: 0,
            borderRadius: 10,
            borderColor: '#ddd',
            backgroundColor: '#FFFFFF',
            formatter: function () {
                const tooltipHeader = `<strong style="font-size: 14px; font-family: Noto Sans;">${this.x.name}</strong><br/>`;
                const currentSales = `<p style="margin: 5px 0 10px 0; color: ${this.series.color};"><span style="font-weight: bold;">Current Sales:</span> ${amountFormatter(this.x.current_sales_amount)}</p>`;
                const previousSales = this.x.previous_sales_amount !== undefined
                    ? `<p style="margin: 5px 0; color: ${this.series.color};"><span style="font-weight: bold;">Previous Sales:</span> ${amountFormatter(this.x.previous_sales_amount)}</p>`
                    : '';
                return `<div>${tooltipHeader}${currentSales}${previousSales}</div>`;
            }
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    enabled: true,
                    formatter: function () { return `<span style="font-family: 'Noto Sans';">${amountFormatter(this.y)}</span>`; }
                },
            },
            series: {
                pointWidth: 20,
                cursor: 'pointer',
                point: {
                    events: {
                        click: function () {
                            AnalyticsEvent(eventsNames.categories.DASHBOARD, { action: eventsNames.actions.CLICK, graph_name: eventsNames.actions.dashboard.graph_name.TOP_CUSTOMER })
                            navigate(`${routesName.invoicingSalesCustomerView.path}?id=${this.category.id}`, { replace: false });
                        }
                    }
                }
            }
        },
        legend: {
            y: 80,
            x: -40,
            shadow: true,
            borderWidth: 0,
            align: 'right',
            floating: true,
            layout: 'vertical',
            verticalAlign: 'top',
            backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
        },
        credits: { enabled: false },
        series: [{
            color: "#2464EB",
            showInLegend: false,
            data: result.map(item => item.sales_amount)
        }]
    }), [categories, result, navigate]);

    return (
        <div>
            <CustomDataPickerPopover
                anchorEl={anchorEl}
                customDate={dateState.dates}
                setAnchorEl={setAnchorEl}
                setCustomDate={(newDates) => setDateState(prev => ({ ...prev, dates: newDates }))}
            />
            <Box>
                <Box sx={{ pb: 2, borderBottom: '2px solid #F5F5F5' }}>
                    <Grid container alignItems="center" justifyContent="space-evenly">
                        <Grid item xs={4} sm={6}>
                            <CustomTypography
                                text="Top Customers"
                                sx={{
                                    color: '#000000',
                                    display: 'inline',
                                    fontSize: '16px',
                                    textAlign: 'start',
                                    fontWeight: '700',
                                    lineHeight: '18px',
                                    fontStyle: 'normal',
                                    fontFamily: "Noto Sans",
                                }}
                            />
                        </Grid>
                        <Grid item xs={8} sm={6} sx={{ textAlign: '-webkit-right' }}>
                            <DateRange
                                label=""
                                sx={{ width: 200 }}
                                id={`${id}_dropdown`}
                                inputRef={DropdownRef}
                                disableClearable
                                value={dateState.selectedDateRange}
                                results={multiple_date_range()}
                                dataTestId={`${dataTestId}_dropdown`}
                                setValue={(event, newDateRange) => {
                                    if (newDateRange?.dates?.length) {
                                        setAnchorEl(null);
                                        setDateState({ dates: getDateRanges(newDateRange.dates), selectedDateRange: newDateRange });
                                    } else {
                                        setAnchorEl(DropdownRef.current);
                                        setDateState(prev => ({ ...prev, selectedDateRange: newDateRange }));
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
                {result.length ? (
                    <Box sx={{ p: 1 }}>
                        <Grid container>
                            <Grid item xs={12} sx={{ p: 1, borderRight: '1px solid #EBEBEB' }}>
                                <PieChart highcharts={Highcharts} options={options} />
                            </Grid>
                        </Grid>
                    </Box>
                ) : (
                    <Box sx={{ height: "430px", display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: "center" }}>
                        <CustomTypography
                            text={<span>No customer recorded for <span style={{ textTransform: 'lowercase' }}>selected date range</span></span>}
                            sx={{
                                opacity: 0.6,
                                color: '#000000',
                                display: 'inline',
                                fontSize: '14px',
                                textAlign: 'start',
                                fontWeight: '700',
                                lineHeight: '18px',
                                fontStyle: 'normal',
                                fontFamily: "Noto Sans",
                            }}
                        />
                    </Box>
                )}
            </Box>
        </div>
    );
});

export default TopCustomers;
