import React from 'react';
import moment from "moment";
import Logo from '../../../common/Logo';
import { useNavigate } from 'react-router-dom';
import { apiAction } from "../../../../api/api";
import * as Actions from '../../../../state/Actions';
import CustomDialog from "../../dialog/CustomDialog";
import { routesName } from "../../../../config/routesName";
import CustomTypography from "../../typography/CustomTypography";
import { eventsNames,AnalyticsEvent } from '../../../../firebase/firebaseAnalytics';

import {
    updateUserPreferencesData,
    getBusinessPreferenceData,
} from '../../../pages/setting/settings/Preferences/common/getPreferences';
import {
    retrieve_business,
    get_list_of_business,
} from "../../../../api/urls";

import {
    getUserDetails,
    getBusinessInfo,
    setBusinessInfo,
} from "../../../../config/cookiesInfo";

import {
    getDateFormat,
    get_party_contact_name,
} from "../../../../utils/Utils";

import {
    Box,
    Menu,
    Avatar,
    Button,
    ButtonGroup,
} from '@mui/material';
import BusinessSharpIcon from "@mui/icons-material/BusinessSharp";

const NavBarUserProfile = (props) => {
    let navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);

    const { logout, anchorEl, handleClose } = props;
    let user_details = getUserDetails();
    let user_details_name = get_party_contact_name(getUserDetails());

    const stringAvatar = (name) => {
        return {
            children: name ? `${name.split(' ')[0][0]}${name.split(' ')[1][0]}` : '',
        };
    }
    const [state, setState] = React.useState({
        title: '',
        open: false,
        condition: '',
        maxWidth: 'lg',
        fullWidth: true,
    })
    const onClickAddBusiness = () => {
        handleClose()
        setState({
            open: true,
            maxWidth: 'sm',
            fullWidth: true,
            condition: 'create_business_account',
            title: 'Add New Business Account',
        })
    }

    const [result, setResult] = React.useState([]);
    const getBusiness = async () => {
        let res = await apiAction({
            method: "get",
            navigate: navigate,
            dispatch: dispatch,
            url: get_list_of_business(),
        });
        if (res?.success) {
            const targetId = getBusinessInfo()?.id;
            let data = res?.result
            const itemToMove = data.find(item => item.id === targetId);
            const filteredData = data.filter(item => item.id !== targetId);

            const newData = [itemToMove, ...filteredData];
            setResult(newData);

        } else {
        }
    };

    React.useEffect(() => {
        if (!result.length && Boolean(anchorEl)) {
            getBusiness();
        }
        if (!user_details) {
            if (logout) {
                logout()
            }
        }
        // eslint-disable-next-line
    }, [Boolean(anchorEl)]);

    React.useEffect(() => {
        if (!Boolean(anchorEl)) {
            getBusiness();
        }
    }, [])

    const onClickedBusiness = async (id) => {
        let res = await apiAction({
            method: 'get',
            navigate: navigate,
            dispatch: dispatch,
            url: retrieve_business(id),
        })
        if (res?.success) {
            props.handleClose();
            setBusinessInfo(res?.result);
            props.navigate(routesName.dashboard.path);
            getBusinessPreferenceData(id, navigate, dispatch);
            updateUserPreferencesData(id, navigate, dispatch);
        } else {
        }
    }
    return (
        <React.Fragment>

            <CustomDialog
                state={state}
                setState={setState}
            />
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                sx={{ mt: '35px' }}
                PaperProps={{
                    style: {
                        width: 300,
                        height: '100%'
                    },
                }}
                onClose={handleClose}
                open={Boolean(anchorEl)}
            >
                <div style={{}}>
                    <Box sx={{
                        pb: 1,
                        display: 'flex',
                        textAlign: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}>

                        <Avatar />
                        <CustomTypography
                            text={<span>{user_details_name ? user_details_name : user_details?.name}</span>}
                            sx={{
                                mb: 1,
                                mt: 1,
                                color: '#2464EB',
                                fontSize: '14px',
                                fontWeight: '700',
                                lineHeight: '18px',
                                fontStyle: 'normal',
                                fontFamily: "Noto Sans",
                            }}
                        />
                        <CustomTypography
                            text={user_details?.email}
                            sx={{
                                mb: 0.5,
                                color: '#7A7A7A',
                                fontSize: '12px',
                                fontWeight: '500',
                                lineHeight: '18px',
                                fontStyle: 'normal',
                                fontFamily: "Noto Sans",
                            }}
                        />

                        <ButtonGroup variant="text" sx={{ mt: 1, height: 15 }}>
                            <Button id={'my_account_btn'} dataTestId={'my_account_btn'} onClick={() => { props.myAccount(); props.handleClose();AnalyticsEvent(eventsNames.categories.NAV_BAR,{action:eventsNames.actions.setting.USER_PROFILE}) }} sx={{ textTransform: 'none' }}>My Account </Button>
                            <Button id={'log_out_btn'} dataTestId={'log_out_btn'} onClick={() => { props.logout();props.handleClose();AnalyticsEvent(eventsNames.categories.USER_AUTHENTICATION,{action:eventsNames.actions.LOGOUT})}} sx={{ color: '#D93E06', textTransform: 'none' }}> Sign Out</Button>
                        </ButtonGroup>
                    </Box>
                    <Box>
                        <Box sx={{ mt: 1, p: 1, borderTop: '2px solid #dadde9', }}>
                            <CustomTypography
                                text={`My Organizations`}
                                sx={{
                                    color: "#7A7A7A",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    lineHeight: "18px",
                                    fontStyle: "normal",
                                    fontFamily: "Noto Sans",
                                }}
                            />
                        </Box>
                        <Box sx={{ mt: 1, borderTop: '1px solid #dadde9', overflowY: 'scroll' }}>

                            {
                                result.map((item, index) => {
                                    return (
                                        <Box
                                            key={index}
                                            onClick={() => { onClickedBusiness(item?.id) }}
                                            className={getBusinessInfo()?.id === item?.id ? "border-left-success" : ""}
                                            sx={{
                                                ml: 0.2,
                                                cursor: "pointer",
                                                borderBottom: "1px solid #dadde9",
                                                ":hover": { backgroundColor: "#f5f5f5" },
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    p: 1,
                                                    display: "flex",
                                                }}
                                            >
                                                <Box sx={{ maxWidth: 80, mr: 1, alignSelf: "center" }}>
                                                    {item?.logo_url ? (
                                                        <Logo height={40} width={40} image={item?.logo_url} />
                                                    ) : (
                                                        <BusinessSharpIcon fontSize="large" />
                                                    )}
                                                </Box>
                                                <Box sx={{ alignSelf: "center" }}>
                                                    <CustomTypography
                                                        text={item?.legal_name}
                                                        sx={{
                                                            color: "#000000",
                                                            fontSize: "12px",
                                                            fontWeight: "700",
                                                            lineHeight: "18px",
                                                            fontStyle: "normal",
                                                            fontFamily: "Noto Sans",
                                                        }}
                                                    />
                                                    <CustomTypography
                                                        text={"— " + item?.industry_name}
                                                        sx={{
                                                            color: "#7A7A7A",
                                                            fontSize: "10px",
                                                            fontWeight: "500",
                                                            lineHeight: "18px",
                                                            fontStyle: "normal",
                                                            fontFamily: "Noto Sans",
                                                        }}
                                                    />
                                                    <CustomTypography
                                                        text={
                                                            "Organization created on : " +
                                                            moment(item?.created_at).format("DD-MMM-YYYY")
                                                        }
                                                        sx={{
                                                            color: "#7A7A7A",
                                                            fontSize: "10px",
                                                            fontWeight: "500",
                                                            lineHeight: "18px",
                                                            fontStyle: "normal",
                                                            fontFamily: "Noto Sans",
                                                        }}
                                                    />
                                                </Box>
                                            </Box>
                                        </Box>
                                    )
                                })
                            }
                        </Box>
                    </Box>

                </div>
                {/* <p id={'add_new_origination_btn'} dataTestId={'add_new_origination_btn'} onClick={() => onClickAddBusiness()} style={{ marginBottom: '0px', background: '#2c5ffe', cursor: 'pointer', color: '#ffff', padding: 2, paddingBottom: 5 }}> + Add New Organization</p> */}

            </Menu>
        </React.Fragment>


    )
}

export default NavBarUserProfile