import React from 'react';
import moment from 'moment';
import { fixed_table_key } from './helper';
import { useNavigate } from 'react-router-dom';
import { apiAction } from "../../../../api/api";
import * as Actions from '../../../../state/Actions';
import { get_gstr1_report } from '../../../../api/urls';
import { getDateFormat } from '../../../../utils/Utils';
import { getBusinessInfo } from '../../../../config/cookiesInfo';
import CurrencyFormatter from '../../../common/CurrencyFormatter';
import CustomTypography from '../../../custom/typography/CustomTypography';

import {
    CustomContainer,
    CustomTitleContainer
} from '../../../custom/container/CustomContainer';

import {
    CustomTable,
    CustomTableRow,
    CustomTableBody,
    CustomTableHead,
    CustomTableHeadCell,
    CustomTableBodyCell,
    CustomTableContainer,
} from '../../../custom/table/CustomTable';

import {
    Box
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const Details = (props) => {
    let navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);

    const { onClickBack, stateData } = props;

    const [results, setResults] = React.useState([])

    React.useEffect(() => {
        if (stateData) {
            getApiResults(stateData.date.dates ? stateData.date.dates : { from_date: stateData.date.from_date, to_date: stateData.date.to_date, }, stateData.table_key,)
        }
        // eslint-disable-next-line
    }, [stateData]);

    const getApiResults = async (dates, table_key) => {
        // setLoader(dispatch, Actions, true)
        let res = await apiAction({
            method: 'post',
            navigate:navigate,
            dispatch:dispatch,
            url: get_gstr1_report(),
            data: {
                ...dates,
                table_key: table_key,
                business_id: getBusinessInfo().id,
            },
        })

        if (res?.success) {
            setResults(res?.result)
            // setLoader(dispatch, Actions, false);
        } else {
            // setLoader(dispatch, Actions, false);
        }
    }

    return (
        stateData &&
        <div>
            <CustomTitleContainer>
                <CustomTypography
                    text={<ArrowBackIosIcon fontSize='small' sx={{ mb: -.6 }} />}
                    sx={{
                        mb: 3,
                        color: '#2464EB',
                        fontSize: '14px',
                        display: 'inline',
                        cursor: 'pointer',
                        fontWeight: '500',
                        lineHeight: '16px',
                        fontStyle: 'normal',
                        textDecoration: 'none',
                        fontFamily: 'Noto Sans',
                        ":hover": { textDecoration: 'underline' }
                    }}
                    onClick={() => onClickBack()}
                />
                <CustomTypography
                    text={'Back'}
                    id={'back_btn'}
                    dataTestId={'back_btn'}
                    sx={{
                        mb: 3,
                        color: '#2464EB',
                        fontSize: '14px',
                        display: 'inline',
                        cursor: 'pointer',
                        fontWeight: '500',
                        lineHeight: '16px',
                        fontStyle: 'normal',
                        textDecoration: 'none',
                        fontFamily: 'Noto Sans',
                        ":hover": { textDecoration: 'underline' }
                    }}
                    onClick={() => onClickBack()}

                />
            </CustomTitleContainer>

            <CustomContainer maxWidth={"400"} sx={{}}>
                    <CustomTableContainer sx={{}}>
                        <CustomTable stickyHeader>
                            <Header data={fixed_table_key[stateData.table_key]} />
                            <Body data={fixed_table_key[stateData.table_key]} results={results} />
                        </CustomTable>
                    </CustomTableContainer>
            </CustomContainer>
        </div>
    )
}

export default Details;


const Header = (props) => {
    const { data } = props;

    return (
        <CustomTableHead>
            <CustomTableRow>
                {
                    data && data.map((item, index) => {
                        return (
                            <React.Fragment key={index}>
                                <CustomTableHeadCell align={item.align}>
                                    {item.label}
                                </CustomTableHeadCell>
                            </React.Fragment>
                        )
                    })
                }

            </CustomTableRow>
        </CustomTableHead>
    )
}

const Body = (props) => {
    const { data, results } = props;
    return (
        <CustomTableBody>
            {results.map((item, index) => {
                return (
                    item && data &&
                    <CustomTableRow key={index} style={index % 2 ? { background: "#f5f5f5", } : { background: "", }}>
                        {
                            data.map((value, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <CustomTableBodyCell align={value.align}>
                                            {
                                                item[value.field] ?
                                                    value.date ? moment(item[value.field]).format(getDateFormat()) :
                                                        value.numberWithCommas ? <CurrencyFormatter amount={item[value.field]} currency={null} /> :
                                                            item[value.field]
                                                    :
                                                    ''
                                            }
                                        </CustomTableBodyCell>

                                    </React.Fragment>
                                )
                            })
                        }
                    </CustomTableRow>
                )
            })}
        </CustomTableBody>
    )
}