import React from 'react';
import moment from 'moment';
import validator from 'validator';
// import Logo from '../../../common/Logo';
import Input from '../../../common/Input';
import { HOST } from "../../../../config/config";
import DateRange from '../../../common/DateRange';
import DatePicker from '../../../common/DatePicker';
import * as Actions from '../../../../state/Actions';
import CustomTitle from '../../../common/CustomTitle';
import { apiAction, apiFormData } from '../../../../api/api';
import CustomButton from '../../../custom/button/CustomButton';
import { getBusinessInfo } from '../../../../config/cookiesInfo';
import { LabelWithAsteriskMark } from '../../../common/CommonLabel';
import CustomTypography from '../../../custom/typography/CustomTypography';
import { eventsNames,AnalyticsEvent } from '../../../../firebase/firebaseAnalytics';

import {
  CustomContainer,
  CustomTitleContainer,
  CustomButtonContainer,
} from '../../../custom/container/CustomContainer';

import {
  setBusinessInfo,
} from '../../../../config/cookiesInfo';

import {
  isNumber,
  setLoader,
  isValidPan,
  isFormValid,
  getJsDateFormat,
  stateChangeManager,
  validateCityOrPostalCode,
} from '../../../../utils/Utils';

import {
  get_countries,
  get_industries,
  get_list_of_state,
  get_list_of_time_zone,
  get_list_of_date_format,
  get_list_of_fiscal_year,
  update_business_details,
  validate_migration_date,
} from '../../../../api/urls';

import {
  Box,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';

const Dropdown = DateRange;

const UpdateBusinessProfile = (props) => {
  const { result, onCancel } = props
  const dispatch = Actions.getDispatch(React.useContext);
  const [isFormSubmited, setFormSubmited] = React.useState(false);

  const initial_item_data = {
    logo: result ? result.logo : null,
    signature: result ? result.signature : null,
    legal_name: result ? result.legal_name : null,
    industry_id: result && result.industry ? result.industry.id : null,


    city: result && result.address ? result.address.city : null,
    state: result && result.address ? result.address.state : null,
    zip_code: result && result.address ? result.address.zip_code : null,
    address_line1: result && result.address ? result.address.address_line1 : null,
    address_line2: result && result.address ? result.address.address_line2 : null,

    state_id: result ? result.state_id : null,
    country_id: result && result.country ? result.country.id : null,

    currency_id: result && result.currency ? result.currency.id : null,

    financial_year_last_day: result ? result.financial_year_last_day : null,

    number_of_employees: result ? result.number_of_employees : null,

    pan_number: result ? result.pan_number : null,
    phone_number: result ? result.phone_number : null,
    gstin_number: result ? result.gstin_number : null,
    company_registration_number: result ? result.company_registration_number : null,
    is_business_registered: result ? result.is_business_registered : false,

    time_zone_id: result && result.time_zone ? result.time_zone.id : null,
    date_format_id: result && result.date_format ? result.date_format.id : null,
    fiscal_year_id: result && result.fiscal_year ? result.fiscal_year.id : null,

    business_contact: result ? result.business_contact : null,
    migration_date: result ? result.migration_date : null,
  };

  const [data, setData] = React.useState({ ...initial_item_data });

  const onSave = async () => {
    setFormSubmited(true);

    const { phone_number, zip_code } = data;
    let validation_data = [
      { key: 'legal_name', message: 'Please Enter Organization Name' },
      { key: 'industry_id', message: 'Please Select Industry' },
      { key: 'country_id', message: 'Please Select Location' },
      { key: 'state_id', message: 'Please Select State' },
      { key: 'city', message: 'Please enter City/Town' },
      { key: 'zip_code', message: 'Please enter Pin Code' },
      // { key: '', validation: !isNaN(zip_code) && zip_code && !(zip_code && validator.isLength(String(zip_code), { min: 6, max: 6 })), message: 'Please Enter Valid Pin Code.' },
      { key: 'fiscal_year_id', message: 'Please Select Fiscal Year' },
      // { key: 'time_zone_id', message: 'Please Select Time Zone' },
      { key: 'date_format_id', message: 'Please Select Date Format' },
      { key: 'currency_id', message: 'Please Select Currency' },
      { key: 'financial_year_last_day', message: 'Please Enter Financial Year Last Date' },
      { key: 'migration_date', message: 'Please Enter Migration Date' },
      { key: 'phone_number', message: 'Please Enter Phone Number' },
      { key: '', validation: !isNaN(phone_number) && phone_number && !(phone_number && validator.isLength(String(phone_number), { min: 10, max: 10 })), message: 'Please Enter Valid Mobile Number' },
      { key: '', validation: (data.pan_number && !isValidPan(data.pan_number)), message: 'Please Enter Valid Pan Number' },
      data.is_business_registered && { key: 'gstin_number', message: 'Please Enter GSTIN' },
      { key: '', validation: data.is_business_registered && (!data.gstin_number || (data.gstin_number && !validator.isLength(String(data.gstin_number), { min: 15, max: 15 }))), message: 'Please Enter Valid GSTIN' },
    ]
    const { isValid, message } = isFormValid(data, validation_data)
    if (isValid) {
      AnalyticsEvent(eventsNames.categories.BUSINESS,{action:eventsNames.actions.EDIT})
      setLoader(dispatch, Actions, true);
      let res = await apiFormData({
        method: 'post',
        file_key: 'logo',
        file_name: 'signature',
        data: { ...data, business_id: result.id, },
        url: update_business_details(),
      })
      if (res?.success) {
        onCancel();
        if (getBusinessInfo().id === res?.result.id) {
          setBusinessInfo(res?.result);
        }
        setLoader(dispatch, Actions, false);
        stateChangeManager(dispatch, Actions, true, 'success', res?.status);

      } else {
        setLoader(dispatch, Actions, false)
        stateChangeManager(dispatch, Actions, true, 'error', res?.status)
      }
    } else {
      setLoader(dispatch, Actions, false)
      stateChangeManager(dispatch, Actions, true, 'error', message);
    }
  }

  const [migrationDate, setMigrationDate] = React.useState();
  React.useEffect(() => {
    const getIndustries = async () => {
      let res = await apiAction({
        method: 'post',
        url: validate_migration_date(),
        data: { business_id: getBusinessInfo().id, },
      })
      if (res?.success) {
        setMigrationDate(res?.result)
        setData({ ...data, migration_date: getBusinessInfo() ? getBusinessInfo().migration_date : res?.result.previous_migration_date ? res?.result.previous_migration_date : result ? result.migration_date : null });
      } else {

      }
    }
    getIndustries()
    // eslint-disable-next-line
  }, [])
  return (
    <div>
      <CustomTitleContainer sx={{ pt: 1.7, pb: 1.7 }}>
        <CustomTitle title={'Edit Organization'} />
      </CustomTitleContainer>

      <CustomContainer maxWidth={"400"} sx={{ height: 'calc(100vh - 202px)', }}>
        <div style={{ paddingLeft: '20px', paddingRight: '20px', paddingBottom: '16px', }}>
          <UploadLogo
            data={data}
            setData={setData}
          />
          <BusinessInfo
            data={data}
            result={result}
            setData={setData}
            migrationDate={migrationDate}
            isFormSubmited={isFormSubmited}
          />
          <UploadSignature
            data={data}
            setData={setData}
          />
        </div>

      </CustomContainer>

      <CustomButtonContainer>
        <Buttons onSave={() => { onSave() }} onCancel={() => onCancel()} />
      </CustomButtonContainer>

    </div>
  )
}

export default UpdateBusinessProfile;

const BusinessInfo = (props) => {
  const { isFormSubmited, result, data, setData, migrationDate } = props;

  const onChange = (key_name, value) => {
    setData({ ...data, [key_name]: value })
  }
  const [type, setType] = React.useState(data.is_business_registered ? 'yes' : 'no');

  const [countries, setCountries] = React.useState([])
  React.useEffect(() => {
    const getCountries = async () => {
      let res = await apiAction({
        method: 'get',
        url: get_countries(),
      })
      if (res?.success) {
        setCountries(res?.result);
      } else {

      }
    }
    getCountries()
    // eslint-disable-next-line
  }, []);

  const [industries, setIndustries] = React.useState([]);
  React.useEffect(() => {
    const getIndustries = async () => {
      let res = await apiAction({
        method: 'get',
        url: get_industries(),
      })
      if (res?.success) {
        setIndustries(res?.result)
      } else {

      }
    }
    getIndustries()
    // eslint-disable-next-line
  }, []);

  const [states, setStates] = React.useState([]);
  React.useEffect(() => {
    const getStates = async (country_id) => {
      let res = await apiAction({
        method: 'post',
        url: get_list_of_state(),
        data: {
          business_id: getBusinessInfo().id,
          country_id: country_id
        }
      })
      if (res?.success) {
        setStates(res?.result.sort((a, b) => -b.name.localeCompare(a.name)))
      } else {

      }
    }
    if (data.country_id) {
      getStates(data.country_id)
    }
    // eslint-disable-next-line
  }, [data.country_id]);

  const [fiscalYear, setFiscalYear] = React.useState([]);
  React.useEffect(() => {
    const getFiscalYear = async (id) => {
      let res = await apiAction({
        method: 'get',
        url: get_list_of_fiscal_year(id),
      })
      if (res?.success) {
        setFiscalYear(res?.result);
        let selected_country = countries.find((item) => item.id === id)
        // console.log('===>data',data);
        if (!data.financial_year_last_day) {
          let fiscal_year = res?.result.find((item) => item.id === data.fiscal_year_id)
          if (fiscal_year) {
            financialYearLastDay(fiscal_year.month_range)
          }
        }
      } else {

      }
    }
    if (data.country_id) {
      getFiscalYear(data.country_id)
    }
    // eslint-disable-next-line
  }, [data.country_id]);

  const [dateFormat, setDateFormat] = React.useState([]);
  React.useEffect(() => {
    const getDateFormat = async (id) => {
      let res = await apiAction({
        method: 'get',
        url: get_list_of_date_format(id),
      })
      if (res?.success) {
        if (!data.date_format_id) {

          let selected_country = countries.find((item) => item.id === id);
          // console.log('===>data',data);

          if (selected_country && selected_country.name === "India") {
            let date_formate = res?.result.find((item) => item.date_format === 'dd-MM-yyyy')
            data.date_format_id = date_formate ? date_formate.id : null;
          }
          setData({ ...data })
        }
        setDateFormat(res?.result.map((item) => { return { ...item, date_format: `${item.date_format}  -  [ ${moment().format(getJsDateFormat(item.date_format))} ]` } }))
      } else {

      }
    }
    if (data.country_id && countries.length) {
      getDateFormat(data.country_id)
    }
    // eslint-disable-next-line
  }, [data.country_id, countries]);

  const [timeZone, setTimeZone] = React.useState([]);
  React.useEffect(() => {
    const getTimeZone = async (id) => {
      let res = await apiAction({
        method: 'get',
        url: get_list_of_time_zone(id),
      })
      if (res?.success) {
        setTimeZone(res?.result)
      } else {

      }
    }
    if (data.country_id) {
      getTimeZone(data.country_id)
    }
    // eslint-disable-next-line
  }, [data.country_id]);

  const financialYearLastDay = (month_range) => {
    if (month_range) {
      data.financial_year_last_day = moment(month_range.split("- ")[1], 'Do MMMM').add(1, 'year').format('YYYY-MM-DD')
    } else {
      data.financial_year_last_day = null
    }
    setData({ ...data })

  }
  return (
    <Box sx={{ pt: 1 }}>
      <Grid container item xs={8} spacing={3}>

        {/* //Organization Name// */}
        <Grid item xs={12}>
          <Input
            autoFocus={true}
            id={'legal_name_dropdown'}
            dataTestId={'legal_name_dropdown'}
            item={{
              type: 'text',
              fullWidth: true,
              placeholder: 'Enter Organization Name',
              value: data.legal_name ? data.legal_name : '',
              validation: isFormSubmited && !data.legal_name,
              title: <LabelWithAsteriskMark label={'Organization Name'} />,
              onChange: (e) => { onChange('legal_name', e.target.value !== '' ? e.target.value : null) }
            }}
          />
        </Grid>

        {/* //Select Industry// */}
        <Grid item xs={6}>
          <Dropdown
            id={'industry_dropdown'}
            dataTestId={'industry_dropdown'}
            results={industries}
            placeholder='Select the Industry'
            validation={isFormSubmited && !data.industry_id}
            label={<LabelWithAsteriskMark label={'Select Industry'} />}
            value={data.industry_id ? industries.find((item) => item.id === data.industry_id) : null}
            setValue={(event, selected) => { onChange('industry_id', selected ? selected.id : null) }}
          />
        </Grid>

        {/* //Select Country// */}
        <Grid item xs={6} >
          <Dropdown
            id={'country_dropdown'}
            dataTestId={'country_dropdown'}
            results={countries}
            placeholder='Select the Country'
            validation={isFormSubmited && !data.country_id}
            label={<LabelWithAsteriskMark label={'Select Country'} />}
            value={data.country_id ? countries.find((item) => item.id === data.country_id) : null}
            setValue={(event, selected) => {
              if (selected) {
                data.country_id = selected.id;
                // data.fiscal_year_id = selected.fiscal_year;
                data.date_format_id = selected.date_format;
                data.time_zone_id = selected.timezone_list[0];

                // clear address info when country change
                data.city = null;
                data.state_id = null;
                data.zip_code = null;
                data.address_line1 = null;
                data.address_line2 = null;
              } else {
                data.country_id = null;
                data.time_zone_id = null;
                // data.fiscal_year_id = null;
                data.date_format_id = null;
                // data.financial_year_last_day = null;

                // clear address info when country remove
                data.city = null;
                data.state_id = null;
                data.zip_code = null;
                data.address_line1 = null;
                data.address_line2 = null;
              }
              setData({ ...data, })
            }}
          />
        </Grid>

        {/* //Address Line 1// */}
        <Grid item xs={12} sx={{}}>
          <Input
            id={'input_address_line1'}
            dataTestId={'input_address_line1'}
            item={{
              type: 'text',
              required: true,
              fullWidth: true,
              title: 'Address Line 1',
              placeholder: 'Enter Address Line 1',
              value: data.address_line1 ? data.address_line1 : '',
              onChange: (e) => { onChange('address_line1', e.target.value !== '' ? e.target.value : null) }
            }}
          />
        </Grid>

        {/* //Address Line 2// */}
        <Grid item xs={12} sx={{}}>
          <Input
            id={'input_address_line2'}
            dataTestId={'input_address_line2'}
            item={{
              type: 'text',
              required: true,
              fullWidth: true,
              title: 'Address Line 2',
              placeholder: 'Enter Address Line 2',
              value: data.address_line2 ? data.address_line2 : '',
              onChange: (e) => { onChange('address_line2', e.target.value !== '' ? e.target.value : null) }
            }}
          />
        </Grid>

        {/* //Select State// */}
        <Grid item xs={4} sx={{}}>
          <Dropdown
            id={'state_dropdown'}
            dataTestId={'state_dropdown'}
            results={states}
            placeholder='Select the State'
            validation={isFormSubmited && !data.state_id}
            label={<LabelWithAsteriskMark label={'Select State'} />}
            // value={data.state ? states.find((item) => item.name === data.state) : null}
            value={data.state_id ? states.find((item) => item.id === data.state_id) : null}
            setValue={(event, selected) => {
              if (selected) {
                data.state = selected.name;
                data.state_id = selected.id;
              } else {
                data.state = null;
                data.state_id = null;
              }
              setData((previous) => ({ ...previous, ...data, }))
            }}
          />
        </Grid>

        {/* //City/Town// */}
        <Grid item xs={4} sx={{}}>
          <Input
            id={'input_city'}
            dataTestId={'input_city'}
            item={{
              type: 'text',
              fullWidth: true,
              placeholder: 'Enter City/Town',
              value: data.city ? data.city : '',
              validation: isFormSubmited && !data.city,

              title: <LabelWithAsteriskMark label={'City/Town'} />,

              onChange: (e) => {
                const re = /^[a-zA-Z\u0080-\u024F]+(?:. |-| |')*([1-9a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/;
                if (e.target.value === "" || re.test(e.target.value)) {
                  onChange('city', e.target.value !== '' ? e.target.value : null)
                }

              }
            }}
          />
        </Grid>

        {/* //Pin Code// */}
        <Grid item xs={4} sx={{}}>
          <Input
            id={'input_zip_code'}
            dataTestId={'input_zip_code'}
            item={{
              type: 'text',
              fullWidth: true,
              placeholder: 'Enter Pin Code',
              value: data.zip_code ? data.zip_code : '',
              validation: isFormSubmited && !data.zip_code,
              title: <LabelWithAsteriskMark label={'Pin Code'} />,
              // onChange: (e) => { onChange('zip_code', isNumber(e.target.value) ? Math.max(0, parseInt(e.target.value)).toString().slice(0, 6) : null) }
              onChange: (e) => {
                if (e.target.value === "" || validateCityOrPostalCode(e.target.value)) {
                  onChange('zip_code', e.target.value !== '' ? e.target.value : null)
                }
              }

            }}

          // onKeyPress={(event) => {
          //   if (event?.key === '-' || event?.key === '+' || event?.key === 'e' || event?.key === 'E') {
          //     event.preventDefault();
          //   }
          // }}
          />
        </Grid>

        {/* //Fiscal Year// */}
        <Grid item xs={4}>
          <Dropdown
            results={fiscalYear}
            item={{ disabled: true, }}
            id={'fiscal_year_dropdown'}
            optionLabel={'month_range'}
            dataTestId={'fiscal_year_dropdown'}
            placeholder='Select the Fiscal Year'
            validation={isFormSubmited && !data.fiscal_year_id}
            label={<LabelWithAsteriskMark label={'Fiscal Year'} />}
            value={data.fiscal_year_id ? fiscalYear.find((item) => item.id === data.fiscal_year_id) : null}
            setValue={(event, selected) => {
              if (selected) {
                financialYearLastDay(selected.month_range)
              } else {
                financialYearLastDay(null)
              }
              onChange('fiscal_year_id', selected ? selected.id : null)
            }}
          />
        </Grid>

        {/* //Time zone// */}
        <Grid item xs={4}>
          <Dropdown
            label='Time Zone'
            results={timeZone}
            id={'time_zone_dropdown'}
            optionLabel={'time_zone'}
            dataTestId={'time_zone_dropdown'}
            placeholder='Select the Time Zone'
            validation={isFormSubmited && !data.time_zone_id}
            value={data.time_zone_id ? timeZone.find((item) => item.id === data.time_zone_id) : null}
            setValue={(event, selected) => {
              if (selected) {
                data.time_zone_id = selected.id;
              } else {
                data.time_zone_id = null;
              }
              setData((previous) => ({ ...previous, ...data, }))
            }}
          />
        </Grid>

        {/* //Date Format// */}
        <Grid item xs={4}>
          <Dropdown
            results={dateFormat}
            id={'date_format_dropdown'}
            optionLabel={'date_format'}
            dataTestId={'date_format_dropdown'}
            placeholder='Select the Date Format'
            validation={isFormSubmited && !data.date_format_id}
            label={<LabelWithAsteriskMark label={'Date Format'} />}
            value={data.date_format_id ? dateFormat.find((item) => item.id === data.date_format_id) : null}
            setValue={(event, selected) => {
              if (selected) {
                data.date_format_id = selected.id;
              } else {
                data.date_format_id = null;
              }
              setData((previous) => ({ ...previous, ...data, }))
            }}
          />
        </Grid>

        {/* //Currency// */}
        <Grid item xs={4}>
          <Input
            id={'input_currency'}
            dataTestId={'input_currency'}
            item={{
              type: 'text',
              disabled: true,
              fullWidth: true,
              title: <LabelWithAsteriskMark label={'Currency'} />,
              value: result ? result.currency.currency_code : '',
            }}
          />
        </Grid>

        {/* //Financial Year Last Date// */}
        <Grid item xs={4}>
          <DatePicker
            id={'financial_year_last_day_date_select'}
            dataTestId={'financial_year_last_day_date_select'}
            disabledCloseIcon={true}
            disabledDatePicker={true}
            validation={isFormSubmited && !data.financial_year_last_day}
            title={<LabelWithAsteriskMark label={'Financial Year Last Date'} />}
            date={data.financial_year_last_day ? moment(data.financial_year_last_day) : null}
            setDate={(date) => { onChange('financial_year_last_day', date ? date.format('YYYY-MM-DD') : null) }}
          />
        </Grid>

        {/* //Migration Date// */}
        <Grid item xs={4}>
          {
            migrationDate && migrationDate.maximum_migration_date ?
              <DatePicker
                no_minDate={true}
                disabledCloseIcon={true}
                id={'migration_date_select'}
                date={moment(data.migration_date)}
                dataTestId={'migration_date_select'}
                disabledDatePicker={!migrationDate.is_editable}
                validation={isFormSubmited && !data.migration_date}
                maxDate={moment(migrationDate.maximum_migration_date)}
                title={<LabelWithAsteriskMark label={'Migration Date'} />}
                sx={{ background: !migrationDate.is_editable && '#F1F3F4' }}
                setDate={(date) => { onChange('migration_date', date ? date.format('YYYY-MM-DD') : null) }}
              />
              :
              <DatePicker
                no_minDate={true} // User can select the any date if maximum_migration_date is null
                title={'Migration Date'}
                disabledCloseIcon={true}
                id={'migration_date_select'}
                dataTestId={'migration_date_select'}
                date={moment(data.migration_date)}
                validation={isFormSubmited && !data.migration_date}
                setDate={(date) => { onChange('migration_date', date ? date.format('YYYY-MM-DD') : null) }}
              />
          }
        </Grid>

        {/* //Phone Number// */}
        <Grid item xs={6}>
          <Input
            id={'input_phone_number'}
            dataTestId={'input_phone_number'}
            item={{
              type: 'number',
              fullWidth: true,
              inputProps: { maxLength: 10 },
              placeholder: 'Enter Phone Number',
              value: data.phone_number ? data.phone_number : '',
              title: <LabelWithAsteriskMark label={'Phone Number'} />,
              onChange: (e) => { onChange("phone_number", isNumber(e.target.value) ? Math.max(0, parseInt(e.target.value)).toString().slice(0, 10) : null) }
            }}
            onKeyPress={(event) => {
              if (event?.key === '-' || event?.key === '+' || event?.key === '.' || event?.key === 'e' || event?.key === 'E') {
                event.preventDefault();
              }
            }}
          />
        </Grid>

        {/* //PAN Number// */}
        <Grid item xs={6}>
          <Input
            id={'input_pan_number'}
            dataTestId={'input_pan_number'}
            item={{
              type: 'text',
              fullWidth: true,
              title: 'PAN Number',
              inputProps: { maxLength: 10 },
              placeholder: 'Enter PAN Number',
              value: data.pan_number ? data.pan_number.toUpperCase() : '',
              validation: isFormSubmited && (data.pan_number && !isValidPan(data.pan_number)),
              onChange: (e) => { onChange('pan_number', e.target.value !== '' ? e.target.value.replace(/[`~$&+,:;=?_[\]@#|{}'<>.^*()%!-/]/, "").toUpperCase() : null) },
            }}
          />
        </Grid>

        {/* //Is GST Registered ?// */}
        <Grid item xs={6}>
          <Box sx={{ mt: 2.5 }}>
            <Grid container spacing={0}
              style={{
                alignItems: 'center',
                justifyItems: 'center',
                alignContent: 'space-evenly',
              }}
            >
              <Grid item sx={{ pr: 2, textAlign: 'left' }}>
                <CustomTypography
                  text={'Is GST Registered ?'}
                  sx={{
                    color: '#000000',
                    display: 'inline',
                    fontSize: '14px',
                    textAlign: 'start',
                    fontWeight: '700',
                    lineHeight: '18px',
                    fontStyle: 'normal',
                    fontFamily: "Noto Sans",
                  }}
                />
              </Grid>
              <Grid item sx={{ pl: 2, textAlign: 'left' }}>
                <RadioGroup row sx={{ marginBottom: 0 }}>
                  <FormControlLabel value={'yes'}
                    id={'yes_radio_btn'}
                    dataTestId={'yes_radio_btn'}
                    label={<span style={{
                      color: '#000000',
                      fontSize: '14px',
                      lineHeight: '18px',
                      display: 'inline',
                      fontStyle: 'normal',
                      fontFamily: "Noto Sans",
                      fontWeight: type === 'yes' ? '600' : '400',
                    }}>YES</span>} control={<Radio sx={{ color: '#8F8F8F' }} checked={type === 'yes'}
                      onChange={(e) => {
                        setType(e.target.value);

                        data.gstin_number = null;
                        data.is_business_registered = e.target.value === 'yes'
                        setData({ ...data })
                          ;
                      }}
                    />}
                  />
                  <FormControlLabel value={'no'}
                    id={'on_radio_btn'}
                    dataTestId={'on_radio_btn'}
                    label={<span style={{
                      color: '#000000',
                      fontSize: '14px',
                      display: 'inline',
                      lineHeight: '18px',
                      fontStyle: 'normal',
                      fontFamily: "Noto Sans",
                      fontWeight: type === 'no' ? '600' : '400',
                    }}>NO</span>} control={<Radio sx={{ color: '#8F8F8F' }} checked={type === 'no'}
                      onChange={(e) => {
                        setType(e.target.value);

                        data.gstin_number = null;
                        data.is_business_registered = e.target.value === 'yes'
                        setData({ ...data })
                      }}
                    />} />
                </RadioGroup>
              </Grid>

            </Grid>
          </Box>
        </Grid>

        {/* //GSTIN// */}
        {data.is_business_registered ?
          <Grid item xs={6}>
            <Input
              id={'input_gstin'}
              dataTestId={'input_gstin'}
              item={{
                type: 'text',
                fullWidth: true,
                inputProps: { maxLength: 15 },
                placeholder: 'For e.g. 29APPCK7465F1Z1',
                title: <LabelWithAsteriskMark label={'GSTIN'} />,
                value: data.gstin_number ? data.gstin_number.toUpperCase() : '',
                validation: isFormSubmited && (!data.gstin_number || (data.gstin_number && !validator.isLength(String(data.gstin_number), { min: 15, max: 15 }))),
                onChange: (e) => onChange("gstin_number", e.target.value !== '' ? e.target.value.replace(/[`~$&+,:;=?_[\]@#|{}'<>.^*()%!-/]/, "").toUpperCase() : null),
              }}
            />
          </Grid>
          : null
        }


      </Grid>
    </Box>
  )
}


const UploadLogo = (props) => {
  const { data, setData } = props;

  const [selectedImage, setSelectedImage] = React.useState();

  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setData({ ...data, logo: e.target.files[0] })
      setSelectedImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  const removeSelectedImage = () => {
    setSelectedImage();
    setData({ ...data, logo: null });
  };

  React.useEffect(() => {
    if (!selectedImage && data.logo) {
      setSelectedImage(HOST + data.logo)
    }
    // eslint-disable-next-line
  }, [data])
  return (
    <React.Fragment>
      <CustomTypography
        text={'Logo'}
        sx={{
          mb: 1,
          color: '#000000',
          fontSize: '14px',
          textAlign: 'start',
          fontWeight: '700',
          lineHeight: '18px',
          fontStyle: 'normal',
          fontFamily: "Noto Sans",
        }}
      />
      <Box sx={{
        mb: 2,
        pb: 2,
        display: 'flex',
        flexDirection: 'row',
        borderBottom: '2px solid #F5F5F5',
      }}>
        <Box sx={{ p: 2, alignItem: 'center', height: 80, width: 'calc(100vw - 88vw)', border: '1px dashed #7A7A7A' }}>
          {
            selectedImage ?
              <Box
                alt="Logo"
                component="img"
                sx={{ height: 80 }}
                src={selectedImage}
              />
              :
              <input
                id={'upload_profile_image'}
                dataTestId={'upload_profile_image'}
                accept="image/*"
                type="file"
                onChange={imageChange}
              />
          }
        </Box>

        <Box sx={{ ml: 2 }}>

          <CustomTypography
            text={`This logo will appear on transactions and email notifications.`}
            sx={{
              mb: 0.5,
              color: '#7A7A7A',
              fontSize: '12px',
              fontWeight: '500',
              lineHeight: '18px',
              fontStyle: 'normal',
              fontFamily: "Noto Sans",
            }}
          />
          <CustomTypography
            text={`Preferred Image Size: 150px x 150px @ 72 DPI Maximum size of 1MB.`}
            sx={{
              mb: 0.5,
              color: '#7A7A7A',
              fontSize: '12px',
              fontWeight: '500',
              lineHeight: '18px',
              fontStyle: 'normal',
              fontFamily: "Noto Sans",
            }}
          />
          {selectedImage &&
            <CustomTypography
              id={'remove_profile_image'}
              dataTestId={'remove_profile_image'}
              text={'Remove This Image'}
              onClick={removeSelectedImage}
              sx={{
                mb: 1,
                color: '#2464EB',
                fontSize: '14px',
                fontWeight: '700',
                cursor: 'pointer',
                lineHeight: '18px',
                fontStyle: 'normal',
                fontFamily: "Noto Sans",
              }}
            />
          }
        </Box>
      </Box>
    </React.Fragment>
  )
}

const UploadSignature = (props) => {
  const { data, setData } = props;

  const [selectedImage, setSelectedImage] = React.useState();

  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setData({ ...data, signature: e.target.files[0] })
      setSelectedImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  const removeSelectedImage = () => {
    setSelectedImage();
    setData({ ...data, signature: null });
  };

  React.useEffect(() => {
    if (!selectedImage && data.signature) {
      setSelectedImage(HOST + data.signature)
    }
    // eslint-disable-next-line
  }, [data])
  return (
    <React.Fragment>
      <Box sx={{
        pt: 2,
        mt: 2,
        mb: 2,
        pb: 2,
        borderTop: '2px solid #F5F5F5',
      }}>
        <CustomTypography
          text={'Signature'}
          sx={{
            mb: 1,
            color: '#000000',
            fontSize: '14px',
            textAlign: 'start',
            fontWeight: '700',
            lineHeight: '18px',
            fontStyle: 'normal',
            fontFamily: "Noto Sans",
          }}
        />
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
        }}>
          <Box sx={{ p: 2, alignItem: 'center', height: 80, width: 300, border: '1px dashed #7A7A7A' }}>
            {
              selectedImage ?
                <Box
                  alt="signature"
                  component="img"
                  sx={{ height: 80 }}
                  src={selectedImage}
                />
                :
                <input
                  id={'upload_image'}
                  dataTestId={'upload_image'}
                  accept="image/*"
                  type="file"
                  onChange={imageChange}
                />
            }
          </Box>

          <Box sx={{ ml: 2 }}>

            <CustomTypography
              text={`This signature will appear on transactions.`}
              sx={{
                mb: 0.5,
                color: '#7A7A7A',
                fontSize: '12px',
                fontWeight: '500',
                lineHeight: '18px',
                fontStyle: 'normal',
                fontFamily: "Noto Sans",
              }}
            />
            <CustomTypography
              text={`Preferred Image Size: 300px x 70px @ 72 DPI Maximum size of 1MB.`}
              sx={{
                mb: 0.5,
                color: '#7A7A7A',
                fontSize: '12px',
                fontWeight: '500',
                lineHeight: '18px',
                fontStyle: 'normal',
                fontFamily: "Noto Sans",
              }}
            />
            {selectedImage &&
              <CustomTypography
                id={'remove_image_btn'}
                dataTestId={'remove_image_btn'}
                text={'Remove This Image'}
                onClick={removeSelectedImage}
                sx={{
                  mb: 1,
                  color: '#2464EB',
                  fontSize: '14px',
                  fontWeight: '700',
                  cursor: 'pointer',
                  lineHeight: '18px',
                  fontStyle: 'normal',
                  fontFamily: "Noto Sans",
                }}
              />
            }
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  )
}

const Buttons = (props) => {
  const { onSave, onCancel } = props
  return (
    <Box component="form" noValidate  >
      <Grid container sx={{ p: 1 }}>
        <Grid item xs={12} sx={{ textAlign: 'right' }}>
          <CustomButton id={'cancel_btn'} dataTestId={'cancel_btn'} variant='outlined' sx={{ textTransform: 'none', mr: 1, '&:hover': { backgroundColor: '#e8f3ff' } }} btnLabel='Cancel' onClick={onCancel} />
          <CustomButton id={'save_btn'} dataTestId={'save_btn'} variant='contained' sx={{ textTransform: 'none', }} btnLabel={'Save'} onClick={onSave} />
        </Grid>
      </Grid>
    </Box>
  )
}