import React from 'react';
import moment from 'moment/moment';
import Input from '../../../../common/Input';
import Status from '../../../../common/Status';
import HTMLViewer from '../../../../common/HTMLViewer';
import * as Actions from '../../../../../state/Actions';
import CustomTitle from '../../../../common/CustomTitle';
import CustomTabs from '../../../../custom/tabs/CustomTabs';
import { useLocation, useNavigate } from 'react-router-dom';
import { routesName } from '../../../../../config/routesName';
import { CommonWebSocket } from '../../../../../api/webSocket';
import CustomDialog from '../../../../custom/dialog/CustomDialog';
import CustomButton from '../../../../custom/button/CustomButton';
import { getBusinessInfo } from '../../../../../config/cookiesInfo';
import { NoDataComponent } from '../../../../common/NoDataComponent';
import CurrencyFormatter from '../../../../common/CurrencyFormatter';
import AuditTrailHistory from '../../../../common/AuditTrailHistory';
import ViewPageActionButtons from '../../../../common/ViewPageActionButtons';
import CustomPagination from '../../../../custom/pagination/CustomPagination';
import CustomTypography from '../../../../custom/typography/CustomTypography';
import { eventsNames,AnalyticsEvent } from '../../../../../firebase/firebaseAnalytics';
import CommonJournalEntryTransactionView from '../../common/CommonJournalEntryTransactionView';

import {
    CustomContainer,
    CustomTitleContainer,
    CustomButtonContainer,
} from '../../../../custom/container/CustomContainer';

import {
    setLoader,
    isFormValid,
    getDateFormat,
    getPaymentType,
    getQueryParams,
    limitDecimalPlaces,
    stateChangeManager,
    breadCrumbsStateChangeManager,
} from '../../../../../utils/Utils';

import {
    apiAction,
    apiBlobResponse,
    apiHandleDownload,
} from '../../../../../api/api';

import {
    get_party,
    delete_bill,
    update_bill,
    export_bill,
    retrieve_bill,
    get_list_of_payment,
    list_of_credited_bills,
    get_credit_list_of_vendor,
    apply_credit_as_bill_payment,
    delete_credits_applied_to_bill,
} from '../../../../../api/urls';

import {
    CustomTable,
    CustomTableRow,
    CustomTableBody,
    CustomTableHead,
    CustomTableHeadCell,
    CustomTableBodyCell,
    CustomTableContainer,
} from '../../../../custom/table/CustomTable';

import {
    Box,
    Grid,
    Collapse,
    IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { DebitNoteViewLink, PaymentMadeViewLink } from '../../common/CommonLinks';




const BillViewpage = () => {
    let navigate = useNavigate();
    let location = useLocation();
    const [tab, setTab] = React.useState(0);
    const params = getQueryParams(location.search);
    const [applyNow, setApplyNow] = React.useState(false);
    const dispatch = Actions.getDispatch(React.useContext);

    const [billResults, setBillResults] = React.useState();
    const [vendorResults, setVendorResults] = React.useState();

    const [state, setState] = React.useState({
        id: '',
        title: '',
        open: false,
        condition: '',
        maxWidth: 'lg',
        fullWidth: true,
    });

    const webSocketResponse = CommonWebSocket();
    React.useEffect(() => {
        if (webSocketResponse) {
            let webSocketData = JSON.parse(webSocketResponse.data)
            if (webSocketData.document_type === "bill") {

                if (webSocketData.event === "delete_document" && billResults.id === webSocketData.object_id) {
                    if (webSocketData.event_success) {
                        stateChangeManager(dispatch, Actions, true, "success", `Bill ${webSocketData.object_number} deleted successfully`);
                        navigate(routesName.invoicingPurchaseBill.path)
                    } else {
                        stateChangeManager(dispatch, Actions, true, "error", `Failed to delete Bill ${webSocketData.object_number}`);
                    }
                }

                if (webSocketData.event === "update_document" && billResults.id === webSocketData.object_id) {
                    if (webSocketData.event_success) {
                        stateChangeManager(dispatch, Actions, true, "success", `Bill ${webSocketData.object_number} updated successfully`);
                        getBill();
                    } else {
                        stateChangeManager(dispatch, Actions, true, "error", `Failed to update Bill ${webSocketData.object_number}`);
                    }
                }
            }
        }
    }, [webSocketResponse])

    const viewJournalEntryHandler = (id) => {
        setState({
            id: id,
            open: true,
            maxWidth: 'sm',
            fullWidth: true,
            condition: 'view',
            title: 'View Journal Entry',
        })
    }
    const stateChangeHandler = (title, condition, maxWidth, url, deleteMessage) => {
        setState({
            url: url,
            open: true,
            title: title,
            fullWidth: true,
            maxWidth: maxWidth,
            condition: condition,
            deleteMessage: deleteMessage,
            navigate: () => navigate(routesName.invoicingPurchaseBill.path),
        })
    }

    const getBill = async () => {
        setLoader(dispatch, Actions, true)
        let res = await apiAction({
            method: 'post',
            url: retrieve_bill(params.id),
            data: { business_id: getBusinessInfo().id },
        })
        if (res?.success) {
            setBillResults(res?.result);
            breadCrumbsStateChangeManager(dispatch, res?.result.bill_number);
            let vendorRes = await apiAction({
                method: 'post',
                url: get_party(res?.result.vendor_id),
                data: { role: 'vendor', business_id: getBusinessInfo().id },
            })
            if (vendorRes.success) {
                setLoader(dispatch, Actions, false)
                setVendorResults(vendorRes.result)
            } else {
                setLoader(dispatch, Actions, false)
            }
        } else {
            setLoader(dispatch, Actions, false)
        }
    }

    React.useEffect(() => {
        if (!state.open && !applyNow && params.id) {
            getBill()
        }
    }, [applyNow, state.open]);

    const recordPayment = async (data) => {
        if (data) {
            setLoader(dispatch, Actions, false);
            navigate(routesName.invoicingPaymentsMade.path, { state: data });

        }
    }
    const approve = async (data) => {
        if (data) {
            const approveBillRes = await apiAction({ url: update_bill(data.id), method: 'POST', data: { is_draft: false, vendor_id: data.vendor_id, business_id: getBusinessInfo().id } })
            if (approveBillRes.success) {
                getBill();
                stateChangeManager(dispatch, Actions, true, 'success', approveBillRes.status);
            } else {
                getBill();
                stateChangeManager(dispatch, Actions, true, 'error', approveBillRes.status);
            }
        }
    }

    const print = async (data) => {
        setLoader(dispatch, Actions, true);
        if (data) {
            apiBlobResponse({
                url: export_bill(), data: { ...data, payment_term_id: data.payment_term, export_type: "pdf" }, onSuccess: () => {
                    setLoader(dispatch, Actions, false);
                }
            })
        }
    }
    const download = async (data) => {
        setLoader(dispatch, Actions, true);
        if (data) {
            apiHandleDownload({
                url: export_bill(), data: { ...data, payment_term_id: data.payment_term, export_type: "pdf" }, filename: data.bill_number, onSuccess: () => {
                    setLoader(dispatch, Actions, false)
                }
            })
        }
    }

    const onSelectAction = (action, item) => {
        setLoader(dispatch, Actions, true)
        if (action === "create") {
            setLoader(dispatch, Actions, false)
            navigate(routesName.invoicingPurchaseBillAdd.path)

        }
        if (action === "clone") {
            navigate(routesName.invoicingPurchaseBillAdd.path + "?clone_id=" + item.id)
            AnalyticsEvent(eventsNames.categories.BILLS,{action:eventsNames.actions.CLONE})
        }
        if (action === "edit") {
            navigate(routesName.invoicingPurchaseBillAdd.path + "?id=" + item.id)
        }
        if (action === "approve") {
            approve(item)
            AnalyticsEvent(eventsNames.categories.BILLS,{action:eventsNames.actions.APPROVE})
        }
        if (action === "print") {
            print(item)
            AnalyticsEvent(eventsNames.categories.BILLS,{action:eventsNames.actions.PRINT})
        }
        if (action === "download") {
            download(item)
            AnalyticsEvent(eventsNames.categories.BILLS,{action:eventsNames.actions.DOWNLOAD})
        }
        if (action === "record_payment") {
            recordPayment(item)
            AnalyticsEvent(eventsNames.categories.BILLS,{action:eventsNames.actions.RECORD_PAYMENT})
        }
    }

    const onClickActionText = (status, item) => {
        if (status === 'draft') {
            onSelectAction("approve", item)
        } else if (status === 'unpaid' || status === 'overdue' || status === 'partially_paid') {
            if (item.total_amount === 0) {
                stateChangeManager(dispatch, Actions, true, 'error', `Record payment is not possible for bill with zero amount`);
            } else {
                onSelectAction("record_payment", item)
            }
        }
        else if (status === 'paid') {
            onSelectAction("create", item)

        }
    }

    const toActionText = (status) => {
        let text
        if (status === 'draft') {
            text = 'Approve'
        } else if (status === 'unpaid' || status === 'overdue' || status === 'partially_paid') {
            text = 'Record payment'
        }
        else if (status === 'paid') {
            text = 'Create New Bill'
        }
        return text
    }

    const tabs = [
        { name: 'View', condition: 'preview' },
        { name: 'Payment History', condition: 'payment_history' },
        { name: 'Credits Applied', condition: 'applied_credited' },
    ]

    return (
        applyNow ?

            <div>
                {
                    billResults && vendorResults &&
                    <div>
                        <ApplyCredit
                            dispatch={dispatch}
                            bill={billResults}
                            vendor={vendorResults}
                            setApplyNow={setApplyNow}
                        />
                    </div>
                }
            </div>
            :
            <div>
                {
                    billResults && vendorResults &&
                    <div>
                        <CustomDialog
                            state={state}
                            setState={setState}
                        />
                        <CustomTitleContainer>
                            <Grid container spacing={0} style={{
                                alignItems: 'center',
                                justifyItems: 'center',
                                alignContent: 'space-evenly',
                                justifyContent: 'space-evenly',
                            }}>
                                <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'start', alignItems: 'center' }}>
                                    <CustomTitle title={billResults.bill_number} />
                                    <Status status={billResults.status} sx={{ ml: 1, }} />
                                </Grid>
                                <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'end', }}>
                                    <ViewPageActionButtons
                                        edit={() => onSelectAction("edit", billResults)}
                                        print={() => onSelectAction("print", billResults)}
                                        isEditable={billResults.payment_status === 'unpaid'}
                                        download={() => onSelectAction("download", billResults)}

                                        actionsList={
                                            [
                                                { name: 'Clone', onClick: () => onSelectAction('clone', billResults) },
                                                { name: 'Delete', showDivider: true, onClick: () => { stateChangeHandler('Delete Bill', 'delete', 'sm', delete_bill(billResults.id), `The bill will be deleted and can not be retrieved later.\n Are you sure about deleting it?`); AnalyticsEvent(eventsNames.categories.BILLS,{action:eventsNames.actions.DELETE}) } }
                                            ]
                                        }
                                    />
                                    <AuditTrailHistory
                                    data={{ object_id: billResults.id, object_type: 'bill' }}
                                    AnalyticsEvent={()=>{ AnalyticsEvent(eventsNames.categories.BILLS,{action:eventsNames.actions.HISTORY})}}
                                    />
                                </Grid>
                            </Grid>
                        </CustomTitleContainer>


                        <CustomContainer maxWidth={"400"} sx={{ height: 'calc(100vh - 134px)', }}>
                            {/* <Box sx={{}}>

                                <CustomTypography
                                    text={<span>Credits Available: <CurrencyFormatter amount={vendorResults.unused_credit} currency={vendorResults.currency ? vendorResults.currency.currency_code : null} /></span>}
                                    sx={{
                                        ml: 2,
                                        color: '#000000',
                                        fontSize: '14px',
                                        display: 'inline',
                                        fontWeight: '700',
                                        lineHeight: '18px',
                                        fontStyle: 'normal',
                                        fontFamily: "Noto Sans",
                                    }}
                                />
                                {
                                    vendorResults.unused_credit > 0 && billResults.due_amount > 0 && billResults.status !== 'draft' &&
                                    <CustomTypography
                                        text={'Apply Now'}
                                        sx={{
                                            mr: 5,
                                            ml: 2,
                                            mt: 2,
                                            color: '#2464EB',
                                            fontSize: '14px',
                                            display: 'inline',
                                            cursor: 'pointer',
                                            fontWeight: '700',
                                            lineHeight: '18px',
                                            fontStyle: 'normal',
                                            fontFamily: "Noto Sans",
                                            ":hover": { textDecoration: 'underline' }
                                        }}
                                        onClick={() => setApplyNow(!applyNow)}
                                    />
                                }
                            </Box> */}
                            <Box sx={{
                                p: 2,
                                border: '1px solid #FFF0C0', backgroundColor: "#FFF7DC"
                            }}>
                                <Grid container sx={{ mb: 1 }}>
                                    <Grid item xs={12} sm={6} sx={{ pl: 0, pb: 1, borderRight: '1px solid #F1D881' }}>
                                        <CustomTypography
                                            text={<span>Credits Available:</span>}
                                            sx={{
                                                mb: 2,
                                                color: '#7A7A7A',
                                                fontSize: '14px',
                                                fontWeight: '600',
                                                lineHeight: '19px',
                                                fontStyle: 'normal',
                                                textTransform: 'none',
                                                fontFamily: "Noto Sans",
                                            }}
                                        />
                                        <Box sx={{ display: 'flex' }}>
                                            <CustomTypography
                                                text={<CurrencyFormatter amount={vendorResults.unused_credit} currency={vendorResults.currency ? vendorResults.currency.currency_code : null} />}
                                                sx={{
                                                    mr: 1,
                                                    fontSize: '24px',
                                                    color: '#000000',
                                                    fontWeight: '700',
                                                    lineHeight: '0px',
                                                    textAlign: 'center',
                                                    fontStyle: 'normal',
                                                    textTransform: 'none',
                                                    fontFamily: "Noto Sans",
                                                }}
                                            />
                                            {
                                                vendorResults.unused_credit > 0 && billResults.due_amount > 0 && billResults.status !== 'draft' &&
                                                <CustomTypography
                                                    text={'Apply Now'}
                                                    id={'action_apply_now_btn'}
                                                    dataTestId={'action_apply_now_btn'}
                                                    sx={{
                                                        color: '#2464EB',
                                                        fontSize: '14px',
                                                        lineHeight: '0px',
                                                        display: 'inline',
                                                        cursor: 'pointer',
                                                        fontWeight: '700',
                                                        fontStyle: 'normal',
                                                        textAlign: 'center',
                                                        fontFamily: "Noto Sans",
                                                        ":hover": { textDecoration: 'underline' }
                                                    }}
                                                    onClick={() => setApplyNow(!applyNow)}
                                                />
                                                // <button style={{
                                                //     border:'none',
                                                //     padding: '5px',
                                                //     paddingLeft: '10px',
                                                //     paddingRight: '10px',
                                                //     color: '#ffffff',
                                                //     fontSize: '12px',
                                                //     fontWeight: '700',
                                                //     cursor: 'pointer',
                                                //     marginTop: '-10px',
                                                //     borderRadius: '3px',
                                                //     fontStyle: 'normal',
                                                //     fontFamily: "Noto Sans",
                                                //     backgroundColor: '#202A44',
                                                // }} onClick={() => setApplyNow(!applyNow)}>Apply Now</button>
                                            }
                                        </Box>
                                    </Grid>
                                    {
                                        <Grid item xs={12} sm={6} sx={{ pl: 1.5, borderLeft: '1px solid #F1D881' }}>
                                            <CustomTypography
                                                text={<span>Action:</span>}
                                                sx={{
                                                    mb: 2,
                                                    color: '#7A7A7A',
                                                    fontSize: '14px',
                                                    fontWeight: '600',
                                                    lineHeight: '19px',
                                                    fontStyle: 'normal',
                                                    textTransform: 'none',
                                                    fontFamily: "Noto Sans",
                                                }}
                                            />
                                            <Box sx={{ display: 'flex' }}>
                                                <CustomTypography
                                                    id={'action_status_btn'}
                                                    dataTestId={'action_status_btn'}
                                                    text={toActionText(billResults.status)}
                                                    sx={{
                                                        color: '#2464EB',
                                                        fontSize: '14px',
                                                        cursor: 'pointer',
                                                        lineHeight: '0px',
                                                        display: 'inline',
                                                        fontWeight: '700',
                                                        fontStyle: 'normal',
                                                        textAlign: 'center',
                                                        fontFamily: "Noto Sans",
                                                        ":hover": { textDecoration: 'underline' }
                                                    }}
                                                    onClick={() => onClickActionText(billResults.status, billResults)}
                                                />
                                            </Box>
                                        </Grid>
                                    }
                                </Grid>
                            </Box>
                            <Box sx={{}}>
                                <CustomTabs
                                    tabs={tabs}
                                    value={tab}
                                    onChange={(event, tab) => { setTab(tab) }}
                                />

                                {tabs[tab].condition === 'preview' && <Preview basicInformation={billResults} dispatch={dispatch} />}
                                {tabs[tab].condition === 'payment_history' && <PaymentHistoryScreen basicInformation={billResults} dispatch={dispatch} />}
                                {tabs[tab].condition === 'applied_credited' && <CreditedAppliedScreen basicInformation={billResults} dispatch={dispatch} state={state} setState={setState} />}

                            </Box>
                        </CustomContainer>
                    </div>
                }
            </div>
    )
}

export default BillViewpage;

const Preview = (props) => {

    const { basicInformation, dispatch } = props;
    const [previewData, setPreviewData] = React.useState();

    React.useEffect(() => {
        const getCustomerInvoice = async (data) => {
            setLoader(dispatch, Actions, true)
            let res = await apiAction({
                method: 'post',
                url: export_bill(data.id),
                data: { ...data, payment_term_id: data.payment_term, export_type: 'html' },
            })
            if (res?.success) {
                setPreviewData(res?.result);
                setLoader(dispatch, Actions, false);
            } else {
                setLoader(dispatch, Actions, false);
            }
        }
        if (basicInformation) {
            getCustomerInvoice(basicInformation)
        }
    }, [basicInformation]);

    React.useEffect(()=>{
        AnalyticsEvent(eventsNames.categories.BILLS,{action:eventsNames.actions.bill.action.VIEW})
    },[])
    return (
        previewData &&
        <React.Fragment>
            {
                basicInformation.has_rcm &&
                <Box sx={{ pl: 6, pr: 6, pt: 2, }}>
                    <ReverseChargeSummaryScreen
                        data={[
                            { name: 'Input CGST', amount: basicInformation.tax.input_CGST },
                            { name: 'Input SGST', amount: basicInformation.tax.input_SGST },
                            { name: 'Input IGST', amount: basicInformation.tax.input_IGST },
                        ]}
                        total={basicInformation.tax.total}
                        currency_code={basicInformation.currency_code}
                    />
                </Box>

            }
            <Box sx={{ pt: 2, }}>
                <HTMLViewer html_string={previewData.html_string} />
            </Box>
            <CommonJournalEntryTransactionView id={basicInformation.journal_entry} />
        </React.Fragment>
    )
}

const ReverseChargeSummaryScreen = (props) => {
    const { data, currency_code, total } = props;
    const [open, setOpen] = React.useState(false);
    const handleChange = () => {
        setOpen((prev) => !prev);
    };
    return (
        <React.Fragment>
            <CustomTableContainer sx={{ mt: 2 }}>
                <Box sx={{ display: 'flex', }}>
                    <CustomTypography
                        text={<span onClick={() => handleChange()}>Reverse Charge Summary</span>}
                        sx={{
                            color: '#000000',
                            fontSize: '14px',
                            cursor: 'pointer',
                            fontWeight: '700',
                            lineHeight: '18px',
                            fontStyle: 'normal',
                            fontFamily: "Noto Sans",
                        }}
                    />
                    {
                        open ?
                            <ArrowDropUpIcon sx={{ cursor: 'pointer' }} onClick={() => handleChange()} />
                            :
                            <ArrowDropDownIcon sx={{ cursor: 'pointer' }} onClick={() => handleChange()} />
                    }
                </Box>

                <Collapse in={open}>
                    <CustomTable>
                        <CustomTableHead>
                            <CustomTableRow>
                                <CustomTableHeadCell ><span >Tax Account</span></CustomTableHeadCell>
                                <CustomTableHeadCell align='right' ><span >Tax Amount</span></CustomTableHeadCell>
                            </CustomTableRow>
                        </CustomTableHead>
                        <CustomTableBody>
                            {
                                data.map((item, index) => {
                                    return (
                                        item.amount ?
                                            <CustomTableRow key={index}>
                                                <CustomTableBodyCell sx={{}}  >{item.name}</CustomTableBodyCell>
                                                <CustomTableBodyCell sx={{}} align='right'><span>{<CurrencyFormatter amount={item.amount} currency={currency_code} />}</span></CustomTableBodyCell>
                                            </CustomTableRow>
                                            : null
                                    )
                                })
                            }
                            <CustomTableRow >
                                <CustomTableBodyCell sx={{}}  >Total</CustomTableBodyCell>
                                <CustomTableBodyCell sx={{}} align='right'><span>{<CurrencyFormatter amount={total} currency={currency_code} />}</span></CustomTableBodyCell>

                            </CustomTableRow>
                        </CustomTableBody>
                    </CustomTable>
                </Collapse>
            </CustomTableContainer>
        </React.Fragment>
    )
}

const PaymentHistoryScreen = (props) => {
    let navigate = useNavigate();

    const { basicInformation, dispatch } = props

    const [page, setPage] = React.useState(1);
    const [paymentData, setPaymentData] = React.useState([]);
    const [pagination, setPagination] = React.useState({
        next: undefined,
        count: '0',
        previous: undefined,
        number_of_pages: undefined,
    });

    React.useEffect(() => {
        const getPayment = async (bill_number) => {
            setLoader(dispatch, Actions, true)
            let res = await apiAction({
                method: 'post',
                url: get_list_of_payment(page),
                data: { payment_type: "pay", business_id: getBusinessInfo().id, reference_number: bill_number },
            })
            if (res?.success) {
                setPagination({
                    ...pagination,
                    next: res?.result.next,
                    count: res?.result.count,
                    previous: res?.result.previous,
                    number_of_pages: res?.result.number_of_pages,
                })
                setPaymentData(res?.result.result)
                setLoader(dispatch, Actions, false)
            } else {
                setLoader(dispatch, Actions, false)
            }
        }
        if (basicInformation) {
            getPayment(basicInformation.bill_number)
        }
    }, [basicInformation, page]);

    React.useEffect(()=>{
        AnalyticsEvent(eventsNames.categories.BILLS,{action:eventsNames.actions.bill.action.PAYMENT_HISTORY})
    },[])

    return (
        <React.Fragment>
            <CustomTableContainer sx={{ pt: 2 }}>
                <CustomTable>
                    <CustomTableHead>
                        <CustomTableRow>
                            <CustomTableHeadCell ><span >Date</span></CustomTableHeadCell>
                            <CustomTableHeadCell ><span >Payment#</span></CustomTableHeadCell>
                            {/* <CustomTableHeadCell ><span >Invoice</span></CustomTableHeadCell> */}
                            <CustomTableHeadCell ><span >Mode</span></CustomTableHeadCell>
                            <CustomTableHeadCell align='right' ><span >Total Amount</span></CustomTableHeadCell>
                            <CustomTableHeadCell align='right' ><span >Unused Amount</span></CustomTableHeadCell>
                            {/* <CustomTableHeadCell align='right' /> */}

                        </CustomTableRow>
                    </CustomTableHead>
                    <CustomTableBody>
                        {
                            paymentData.map((item, index) => {
                                return (
                                    <CustomTableRow key={index} >
                                        <CustomTableBodyCell sx={{}} ><span>{moment(item.payment_date).format(getDateFormat())}</span></CustomTableBodyCell>
                                        <CustomTableBodyCell sx={{}}  ><PaymentMadeViewLink id={item.id} title={item.id} /></CustomTableBodyCell>
                                        {/* <CustomTableBodyCell sx={{}}  >{item.payment_for_reference_number.map((value, index) => { return (<span key={index}>{index ? ', ' : ''}{value}</span>) })}</CustomTableBodyCell> */}
                                        <CustomTableBodyCell sx={{}}  ><span>{item.payment_method ? getPaymentType(item.payment_method)?.name : '-'}</span></CustomTableBodyCell>
                                        <CustomTableBodyCell sx={{}} align='right'><span>{<CurrencyFormatter amount={item.total_amount} currency={item.currency_code} />}</span></CustomTableBodyCell>
                                        <CustomTableBodyCell sx={{}} align='right'><span>{<CurrencyFormatter amount={item.balance_amount} currency={item.currency_code} />}</span></CustomTableBodyCell>
                                        {/* <CustomTableHeadCell align='right' >
                                            <IconButton onClick={() => {
                                                {
                                                    item.is_advance ?
                                                        navigate(routesName.invoicingPaymentsMadeView.path + "?payment_id=" + item.id, { replace: false })
                                                        :
                                                        navigate(routesName.invoicingPaymentsMade.path + "?payment_id=" + item.id, { replace: false })
                                                }
                                            }}>
                                                <ModeEditIcon fontSize="small" sx={{ color: 'grey' }} />
                                            </IconButton>
                                        </CustomTableHeadCell> */}
                                    </CustomTableRow>
                                )
                            })
                        }
                    </CustomTableBody>
                </CustomTable>
                <Box sx={{ pt: !paymentData.length ? 10 : 0 }} >
                    <NoDataComponent left='0%' top='0%' position={'relative'} data={paymentData} />
                </Box>
            </CustomTableContainer>
            {paymentData.length ?
                <CustomPagination
                    page={page}
                    count={pagination.number_of_pages}
                    onChange={(event, newValue) => setPage(newValue)}
                />
                : null
            }
        </React.Fragment>
    )
}

const CreditedAppliedScreen = (props) => {
    const { basicInformation, dispatch, state, setState } = props;

    const [results, setResults] = React.useState([]);

    const stateChangeHandler = (title, condition, maxWidth, id, item, url, deleteMessage) => {
        setState({
            id: id,
            url: url,
            open: true,
            item: item,
            title: title,
            fullWidth: true,
            maxWidth: maxWidth,
            condition: condition,
            deleteMessage: deleteMessage
        })
    };
    React.useEffect(() => {
        const getCustomerInvoice = async () => {
            setLoader(dispatch, Actions, true)
            let res = await apiAction({
                method: 'post',
                url: list_of_credited_bills(),
                data: { business_id: getBusinessInfo().id, bill_id: basicInformation.id, },
            })
            if (res?.success) {
                setResults(res?.result);
                setLoader(dispatch, Actions, false);
            } else {
                setLoader(dispatch, Actions, false);
            }
        }
        if (!state.open && basicInformation) {
            getCustomerInvoice()
        }
    }, [state.open]);

    React.useEffect(()=>{
        AnalyticsEvent(eventsNames.categories.BILLS,{action:eventsNames.actions.bill.action.CREDITS_APPLIED})
    },[])
    return (
        <React.Fragment>
            <CustomDialog
                state={state}
                setState={setState}
            />
            <CustomTableContainer sx={{ pt: 2 }}>
                <CustomTable>
                    <CustomTableHead>
                        <CustomTableRow>
                            <CustomTableHeadCell ><span >Date</span></CustomTableHeadCell>
                            <CustomTableHeadCell ><span >Debit Note Number</span></CustomTableHeadCell>
                            <CustomTableHeadCell align='right' ><span >Credit Amount Applied</span></CustomTableHeadCell>
                            <CustomTableHeadCell align='right' />
                        </CustomTableRow>
                    </CustomTableHead>
                    <CustomTableBody>
                        {
                            results.map((item, index) => {
                                return (
                                    <CustomTableRow key={index}>
                                        <CustomTableBodyCell sx={{}}  ><span>{moment(item.bill_date).format(getDateFormat())}</span></CustomTableBodyCell>
                                        <CustomTableBodyCell sx={{}}  ><DebitNoteViewLink id={item.debit_note_id} title={item.debit_note_number}/></CustomTableBodyCell>
                                        <CustomTableBodyCell sx={{}} align='right'><span>{<CurrencyFormatter amount={item.credit_amount_applied} currency={item.currency_code} />}</span></CustomTableBodyCell>
                                        <CustomTableHeadCell align='right' >
                                            <IconButton size='small' id={'delete_credit_applied_btn' + index} dataTestId={'delete_credit_applied_btn' + index} onClick={() => { stateChangeHandler('Delete Credits Applied', 'delete', 'sm', item.id, item, delete_credits_applied_to_bill(item.id), `Are you sure you want to delete the credits applied to Bill ?`) }}>
                                                <DeleteIcon fontSize="small" sx={{ color: 'grey' }} />
                                            </IconButton>
                                        </CustomTableHeadCell>
                                    </CustomTableRow>
                                )
                            })
                        }
                    </CustomTableBody>
                </CustomTable>
                <Box sx={{ pt: !results.length ? 10 : 0 }} >
                    <NoDataComponent left='0%' top='0%' position={'relative'} data={results} />
                </Box>
            </CustomTableContainer>
        </React.Fragment>
    )
}


const ApplyCredit = (props) => {
    const { vendor, bill, setApplyNow, dispatch } = props;

    const [applyPayment, setApplyPayment] = React.useState({
        debit_note_list: [],
        bill_id: bill.id,
        total_credit_applied: 0,
        business_id: getBusinessInfo().id,
    })

    React.useEffect(() => {
        const getCreditNotes = async () => {
            let initial_debit_note_list = []
            let res = await apiAction({
                method: 'post',
                url: get_credit_list_of_vendor(),
                data: { business_id: getBusinessInfo().id, vendor_id: vendor.id },
            })
            if (res?.success) {
                res?.result.map((item) => {
                    initial_debit_note_list.push({
                        debit_note_id: item.id,
                        applied_as_payment_amount: 0,
                        credit_type: item.credit_type,
                        total_amount: item.total_amount,
                        currency_code: item.currency_code,
                        debit_note_date: item.debit_note_date,
                        credit_remaining: item.credit_remaining,
                        debit_note_number: item.debit_note_number,
                    })
                })
                setApplyPayment({ ...applyPayment, debit_note_list: initial_debit_note_list })
            } else {
            }
        }
        if (vendor) {
            getCreditNotes()
        }
    }, []);

    const onSave = async () => {
        console.log('On save applyPayment', applyPayment)
        let validation_data = [
            { key: "", validation: applyPayment.total_credit_applied === 0, message: 'Please enter a valid amount for Amount to Credit.' },
        ]
        const { isValid, message } = isFormValid(applyPayment, validation_data);
        if (isValid) {

            let res = await apiAction({
                method: 'post',
                data: { ...applyPayment },
                url: apply_credit_as_bill_payment(),

            })
            if (res?.success) {
                setApplyNow(false)
                stateChangeManager(dispatch, Actions, true, 'success', res?.status);
            } else {
                stateChangeManager(dispatch, Actions, true, 'error', res?.status);
            }
        } else {
            stateChangeManager(dispatch, Actions, true, 'error', message);
        }
    }

    let remaining_due_amount = bill.due_amount - applyPayment.total_credit_applied;
    return (
        <div>
            <CustomTitleContainer>
                <Grid container spacing={0} style={{
                    alignItems: 'center',
                    justifyItems: 'center',
                    alignContent: 'space-evenly',
                    justifyContent: 'space-evenly',
                }}>
                    <Grid item xs={6}>
                        <CustomTitle title={`Apply credits for ${bill.bill_number}`} />
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: 'end' }}>
                        <CustomTypography
                            text={<span>Bill Balance: <CurrencyFormatter amount={bill.due_amount} currency={bill.currency_code} /></span>}
                            sx={{
                                color: '#000000',
                                fontSize: '14px',
                                fontWeight: '700',
                                textAlign: 'right',
                                lineHeight: '18px',
                                fontStyle: 'normal',
                                fontFamily: "Noto Sans",
                            }}
                        />
                    </Grid>

                </Grid>
            </CustomTitleContainer>

            <CustomContainer maxWidth={"400"}>
                <CustomTableContainer>
                    <CustomTable>
                        <CustomTableHead>
                            <CustomTableRow >
                                <CustomTableHeadCell align='left'>Debit Note/Excess Payment</CustomTableHeadCell>
                                <CustomTableHeadCell align='left'>Debit Note/Excess Payment Date</CustomTableHeadCell>
                                <CustomTableHeadCell align='right'>Credit Amount</CustomTableHeadCell>
                                <CustomTableHeadCell align='right'>Credits Available</CustomTableHeadCell>
                                <CustomTableHeadCell align='right'>Amount to Credit</CustomTableHeadCell>
                            </CustomTableRow>
                        </CustomTableHead>
                        <CustomTableBody>
                            {
                                applyPayment.debit_note_list.map((item, index) => {
                                    return (
                                        <CustomTableRow key={index}>
                                            <CustomTableBodyCell sx={{}} align='left' size='small'> <p>{item.debit_note_number}</p></CustomTableBodyCell>
                                            <CustomTableBodyCell sx={{}} align='left' size='small'><p>{item.debit_note_date ? moment(item.debit_note_date).format(getDateFormat()) : '-'}</p></CustomTableBodyCell>
                                            <CustomTableBodyCell sx={{}} align='right' size='small'><p><CurrencyFormatter amount={item.total_amount} currency={item.currency_code} /></p></CustomTableBodyCell>
                                            <CustomTableBodyCell sx={{}} align='right' size='small'><p><CurrencyFormatter amount={item.credit_remaining} currency={item.currency_code} /></p></CustomTableBodyCell>
                                            <CustomTableBodyCell sx={{}} align='right' size='small'>
                                                <Input
                                                    id={'input_applied_as_payment_amount' + index}
                                                    dataTestId={'input_applied_as_payment_amount' + index}
                                                    isSearchableComponent={true}
                                                    item={{
                                                        title: '',
                                                        type: 'number',
                                                        placeholder: '',
                                                        required: true,
                                                        fullWidth: true,
                                                        value: item.applied_as_payment_amount ? item.applied_as_payment_amount : '',
                                                        onChange: (e) => {
                                                            if (e.target.value) {
                                                                applyPayment.debit_note_list[index].applied_as_payment_amount = Number(limitDecimalPlaces(Number(e.target.value)))
                                                            } else {
                                                                applyPayment.debit_note_list[index].applied_as_payment_amount = 0
                                                            }
                                                            setApplyPayment({
                                                                ...applyPayment,
                                                            })
                                                        },
                                                        onBlur: (e) => {
                                                            if (limitDecimalPlaces(e.target.value) <= remaining_due_amount) {
                                                                if (limitDecimalPlaces(e.target.value) > applyPayment.debit_note_list[index].credit_remaining) {
                                                                    applyPayment.debit_note_list[index].applied_as_payment_amount = limitDecimalPlaces(applyPayment.debit_note_list[index].credit_remaining);
                                                                } else {
                                                                    applyPayment.debit_note_list[index].applied_as_payment_amount = Number(limitDecimalPlaces(Number(e.target.value)));
                                                                }
                                                            } else {
                                                                if (limitDecimalPlaces(e.target.value) < applyPayment.debit_note_list[index].credit_remaining) {
                                                                    applyPayment.debit_note_list[index].applied_as_payment_amount = limitDecimalPlaces(remaining_due_amount);
                                                                } else {
                                                                    applyPayment.debit_note_list[index].applied_as_payment_amount = 0;
                                                                }
                                                            }
                                                            setApplyPayment({
                                                                ...applyPayment,
                                                                total_credit_applied: applyPayment.debit_note_list.reduce((acc, item) => acc + item.applied_as_payment_amount, 0),

                                                            })
                                                        }
                                                    }}
                                                    onKeyPress={(event) => {
                                                        if (event?.key === '-' || event?.key === '+' || event?.key === 'e' || event?.key === 'E') {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                />
                                            </CustomTableBodyCell>
                                        </CustomTableRow>
                                    )
                                }
                                )}

                        </CustomTableBody>
                    </CustomTable>

                    <Grid container item xs={12} sx={{ p: 2, pt: 1 }}>
                        <Grid item xs={12} sm={8} sx={{}}>
                            <p style={{ fontSize: '14px' }}>Note : If there is any tax applied to the advance payment, the tax will be reversed.</p>
                        </Grid>
                        <Grid item sm={4} xs={12} container spacing={0}>
                            <Grid item xs={6} sx={{ textAlign: 'right', mt: 1 }}>Amount to Credit :</Grid>
                            <Grid item xs={6} sx={{ pr: 1, mt: 1, textAlign: 'right' }}>{<CurrencyFormatter amount={applyPayment.total_credit_applied} currency={bill.currency_code} />}</Grid>
                            <Grid item xs={12} container sx={{ mt: 3, pb: 1, borderRadius: 1, background: '#EEEEEE', border: '1px dashed' }}>
                                <Grid item xs={6} sx={{ pr: 1, textAlign: 'right' }}><p>Bill Balance Due :</p></Grid>
                                <Grid item xs={6} sx={{ pr: 1, textAlign: 'right' }}>
                                    <p>{<CurrencyFormatter amount={remaining_due_amount} currency={bill.currency_code} />}</p>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CustomTableContainer>
            </CustomContainer>

            <CustomButtonContainer>
                <Buttons onSave={onSave} onCancel={() => setApplyNow(false)} />
            </CustomButtonContainer>
        </div>
    )
}

const Buttons = (props) => {
    const { onSave, onCancel } = props
    return (
        <Box component="form" noValidate  >
            <Grid container>
                <Grid item xs={12} sx={{ display: '-webkit-flex', justifyContent: 'end', }}>
                    <CustomButton id={'cancel_btn'} dataTestId={'cancel_btn'} variant='outlined' sx={{ textTransform: 'none', '&:hover': { backgroundColor: '#e8f3ff' } }} btnLabel='Cancel' onClick={onCancel} />
                    <CustomButton id={'save_btn'} dataTestId={'save_btn'} variant='contained' sx={{ ml: 1, textTransform: 'none', }} btnLabel={'Save'} onClick={onSave} />
                </Grid>
            </Grid>
        </Box>
    )
}