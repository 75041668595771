import React, { useState } from "react";
import Input from "../../../common/Input";
import CustomTitle from "../../../common/CustomTitle";
import { Box, Button, Grid, Modal } from "@mui/material";

const SaveMapping = (props) => {
    const { open, onClose, onSubmit, mapping, isNewMapping } = props
    const [name, setName] = useState((mapping && mapping.name && !isNewMapping) ? mapping.name : "")
    const [description, setDescription] = useState((mapping && mapping.description && !isNewMapping) ? mapping.description : "")

    React.useEffect(() => {
        if (mapping && mapping.name && !isNewMapping) {
            setName(mapping.name)
            setDescription(mapping.description)
        } else {
            setName('')
            setDescription('')
        }
    }, [open])
    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <Box sx={style} type="ma">
                <Grid container xs={12} item>
                    <CustomTitle title={'Save Mapping'} />
                    <Grid item xs={12} sx={{ mt: 1, mb: 3, borderTop: '1px solid #EBEBEB', }}></Grid>
                    <Grid item xs={12}>
                        <Input
                            id={"input_save_mapping_name"}
                            dataTestId={"input_save_mapping_name"}
                            item={{
                                type: 'text',
                                required: true,
                                fullWidth: true,
                                title: 'Mapping Name',
                                value: name ? name : '',
                                placeholder: 'Enter Mapping Name',
                                onChange: (event) => setName(event.target.value)
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 2 }}>
                        <Input
                            id={"input_save_mapping_description"}
                            dataTestId={"input_save_mapping_description"}
                            item={{
                                rows: 3.5,
                                type: 'text',
                                multiline: true,
                                required: true,
                                fullWidth: true,
                                title: 'Description',
                                placeholder: 'Enter Description',
                                value: description ? description : '',
                                onChange: (event) => setDescription(event.target.value)
                            }}
                        />
                    </Grid>
                    <Grid sm={12} item sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                        <Button id={"save_mapping_cancel_btn"} dataTestId={"save_mapping_cancel_btn"} variant="contained" sx={{ marginTop: 1, marginRight: 1, textTransform: 'none' }} onClick={() => onClose()}>
                            Cancel
                        </Button>
                        <Button id={"save_mapping_save_btn"} dataTestId={"save_mapping_save_btn"} variant="contained" sx={{ marginTop: 1, textTransform: 'none' }} onClick={() => {
                            if (name) {
                                onSubmit(name, description)
                            }
                        }}>
                            Submit
                        </Button>
                    </Grid>

                </Grid>
            </Box>
        </Modal>
    )
}
const style = {
    width: "30%",
    top: '35%',
    left: '50%',
    boxShadow: 24,
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '1px solid #EBEBEB',
    pt: 2,
    px: 4,
    pb: 3,
};


export default SaveMapping
