import React from 'react';
import moment from 'moment';
import { apiAction } from "../../../../api/api";
import ReportsTitle from '../common/ReportsTitle';
import * as Actions from '../../../../state/Actions';
import ReportsSettingButtons from '../ReportsSettingButtons';
import { getBusinessInfo } from '../../../../config/cookiesInfo';
import CurrencyFormatter from '../../../common/CurrencyFormatter';
import { NoDataComponent } from '../../../common/NoDataComponent';
import { PaymentMadeViewLink } from '../../invoicing/common/CommonLinks';
import CommonCustomDateRange from '../../../common/CommonCustomDateRange';
import CustomPagination from '../../../custom/pagination/CustomPagination';
import { CustomContainer } from '../../../custom/container/CustomContainer';
import { payments_received_payments_made_report } from '../../../../api/urls';

import {
  setLoader,
  date_range,
  getDateFormat,
  getPaymentType,
} from '../../../../utils/Utils';

import {
  Box,
  Grid,
} from '@mui/material';

import {
  CustomTable,
  CustomTableRow,
  CustomTableBody,
  CustomTableHead,
  CustomTableHeadCell,
  CustomTableBodyCell,
  CustomTableContainer,
} from '../../../custom/table/CustomTable';

const PaymentsMadeReports = () => {
  const stateAction = Actions.getState(React.useContext);
  const dispatch = Actions.getDispatch(React.useContext);

  const { selectedDateRange } = stateAction;
  let filter_date_range = date_range().find((item) => item.name === selectedDateRange)
  const default_date_range = filter_date_range ? filter_date_range : date_range()[2]

  const [dateRange, setDateRange] = React.useState(default_date_range);
  const [fromDate, setFromDate] = React.useState(moment());
  const [toDate, setToDate] = React.useState(moment());
  const [results, setResults] = React.useState();
  const [total, setTotal] = React.useState();
  const [date, setDate] = React.useState();

  const [page, setPage] = React.useState(1);
  const [pagination, setPagination] = React.useState({
    next: undefined,
    count: undefined,
    previous: undefined,
    number_of_pages: undefined,
  })

  React.useEffect(() => {
    if (dateRange) {
      if (dateRange.name !== "Custom") {
        getApiResults(dateRange.dates.from_date, dateRange.dates.to_date, dateRange.dates.date_range)
      } else {
        getApiResults(fromDate.format('YYYY-MM-DD'), toDate.format('YYYY-MM-DD'), `${fromDate.format(getDateFormat())} - ${toDate.format(getDateFormat())}`)
      }
    }
    // eslint-disable-next-line
  }, [dateRange, toDate, fromDate, page]);

  const getApiResults = async (from_date, to_date, range_date) => {
    setLoader(dispatch, Actions, true)
    setDate(range_date)
    let res = await apiAction({
      method: 'post',
      url: payments_received_payments_made_report(page),
      data: {
        role: 'vendor',
        to_date: to_date,
        payment_type: "pay",
        from_date: from_date,
        business_id: getBusinessInfo().id,
      },
    })

    if (res?.success) {
      let result = res?.result.result.paginated_result;

      setPagination({
        ...pagination,
        next: result.next,
        count: result.count,
        previous: result.previous,
        number_of_pages: result.number_of_pages,
      });
      setResults(result.result);
      setLoader(dispatch, Actions, false);
      setTotal(res?.result.result.total_amount);
    } else {
      setLoader(dispatch, Actions, false);
    }
  }
  return (
    results &&
    <div>
      <ReportsSettingButtons

        scheduleData={{
          report_title: 'Payments Made',
          report_name: 'payments_received_payments_made',
          additional_data: { role: 'vendor', payment_type: "pay", }

        }}

        exportData={{ url: payments_received_payments_made_report(), data: dateRange.dates ? { ...dateRange.dates, role: 'vendor', payment_type: "pay", } : { to_date: toDate.format('YYYY-MM-DD'), from_date: fromDate.format('YYYY-MM-DD'), role: 'vendor', payment_type: "pay", } }}
      />
      <CustomContainer maxWidth={"400"} sx={{}}>
        <div style={{ paddingLeft: '16px', paddingRight: '16px' }}>
          <Grid container spacing={0} sx={{ pb: 2 }} style={{
            alignItems: 'center',
            justifyItems: 'center',
            alignContent: 'space-evenly',
            justifyContent: 'space-evenly',
          }}>
            <Grid item xs={12} sm={4}>
              <ReportsTitle
                date={date}
                title={'Payments Made'}
              />
            </Grid>
            <Grid container spacing={1} item xs={12} sm={8} sx={{ justifyContent: 'end', }}>
              <CommonCustomDateRange
                setPage={setPage}
                date_range={date_range()}

                toDate={toDate}
                setToDate={setToDate}

                fromDate={fromDate}
                setFromDate={setFromDate}

                dateRange={dateRange}
                setDateRange={setDateRange}
              />
            </Grid>
          </Grid>
        </div>
        <CustomTableContainer>
          <CustomTable>
            <Header />
            {
              results.length ?
                <Body data={results} total={total} is_last_page={pagination.next === null} />
                : null
            }
          </CustomTable>
          <Box sx={{ p: !results.length ? 2 : 0 }} >
            <NoDataComponent left='0%' top='0%' position={'relative'} data={results} />
          </Box>
        </CustomTableContainer>
      </CustomContainer>
      {
        results.length ?
          <CustomPagination
            page={page}
            count={pagination.number_of_pages}
            onChange={(event, newValue) => setPage(newValue)}
          />
          : null
      }
    </div>
  )
}

export default PaymentsMadeReports;

const Header = () => {
  return (
    <CustomTableHead>
      <CustomTableRow >
        <CustomTableHeadCell><span>Payment Number</span></CustomTableHeadCell>
        <CustomTableHeadCell><span>Date</span></CustomTableHeadCell>
        <CustomTableHeadCell><span>Vendor Name</span></CustomTableHeadCell>
        <CustomTableHeadCell><span>Payment Mode</span></CustomTableHeadCell>
        <CustomTableHeadCell><span>Bill#</span></CustomTableHeadCell>
        <CustomTableHeadCell><span>Paid Through</span></CustomTableHeadCell>
        <CustomTableHeadCell align='right'><span>Amount</span></CustomTableHeadCell>
        <CustomTableHeadCell align='right'><span>Unused Amount</span></CustomTableHeadCell>
      </CustomTableRow>
    </CustomTableHead>
  )
}

const Body = (props) => {
  const { data, total, is_last_page } = props;

  return (
    <CustomTableBody>
      {data.map((item, index) => {
        return (
          <CustomTableRow key={index}>
            {/* <CustomTableBodyCell sx={{}}  ><span>{item.payment_number}</span></CustomTableBodyCell> */}
            <CustomTableBodyCell sx={{}}  ><PaymentMadeViewLink id={item.id} title={item.id} /></CustomTableBodyCell>
            <CustomTableBodyCell sx={{}}  ><span>{moment(item.payment_date).format(getDateFormat())}</span></CustomTableBodyCell>
            <CustomTableBodyCell sx={{}}  ><span>{item.party_display_name}</span></CustomTableBodyCell>
            <CustomTableBodyCell sx={{}}  >
              <p style={{ margin: '0px', marginBottom: '5px' }}>{item.payment_method ? getPaymentType(item.payment_method)?.name : '-'}</p>
              {
                item.is_advance &&
                <span style={{ fontSize: '10px', opacity: 0.6, }}>Advance Payment</span>
              }
            </CustomTableBodyCell>
            <CustomTableBodyCell sx={{}}  ><span>{item.payment_for_reference_number.map((value, index) => { return (<span key={index}>{index ? ', ' : ''}{value}</span>) })}</span></CustomTableBodyCell>
            <CustomTableBodyCell sx={{}}  ><span>{item.account_name}</span></CustomTableBodyCell>
            <CustomTableBodyCell sx={{}} align='right'><span>{<CurrencyFormatter amount={item.total_amount_} currency={null} />}</span></CustomTableBodyCell>
            <CustomTableBodyCell sx={{}} align='right'><span>{<CurrencyFormatter amount={item.balance_amount_} currency={null} />}</span></CustomTableBodyCell>
          </CustomTableRow>
        )
      })}
      {
        is_last_page ?
          <CustomTableRow sx={{
            pb: 1,
            pt: 2,
            background: '#FFF7DC',
            borderTop: '1px solid #FFF0C0',
            borderBottom: '1px solid #FFF0C0'
          }}>
            <CustomTableBodyCell><span>Total</span></CustomTableBodyCell>
            <CustomTableBodyCell colSpan={5} />
            <CustomTableBodyCell align='right'><span><CurrencyFormatter amount={total.total_total_amount} currency={null} /></span></CustomTableBodyCell>
            <CustomTableBodyCell align='right'><span><CurrencyFormatter amount={total.total_balance_amount} currency={null} /></span></CustomTableBodyCell>
          </CustomTableRow>
          : null
      }

    </CustomTableBody>
  )
}