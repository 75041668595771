import React from 'react';
import moment from 'moment';
import Status from '../../../../common/Status';
import TimeAgo from '../../../../common/TimeAgo';
import DateRange from '../../../../common/DateRange';
import * as Actions from '../../../../../state/Actions';
import CustomTitle from '../../../../common/CustomTitle';
import CommonSearch from '../../../../common/CommonSearch';
import CustomTabs from '../../../../custom/tabs/CustomTabs';
import { useNavigate, useLocation } from 'react-router-dom';
import { routesName } from '../../../../../config/routesName';
import { CommonWebSocket } from '../../../../../api/webSocket';
import ListItemActions from '../../../../common/ListItemActions';
import CustomDialog from '../../../../custom/dialog/CustomDialog';
import CustomButton from '../../../../custom/button/CustomButton';
import { getBusinessInfo } from '../../../../../config/cookiesInfo';
import { NoDataComponent } from '../../../../common/NoDataComponent';
import CurrencyFormatter from '../../../../common/CurrencyFormatter';
import { BadgeWithTitle } from '../../../../custom/badge/CustomBadge';
import { HeadingWithSortable } from '../../../../common/SortableHeading';
import CommonAsyncDropdown from '../../../../common/CommonAsyncDropdown';
import CustomTypography from '../../../../custom/typography/CustomTypography';
import CustomPagination from '../../../../custom/pagination/CustomPagination';
import { eventsNames,AnalyticsEvent } from '../../../../../firebase/firebaseAnalytics';
import { apiAction, apiBlobResponse, apiHandleDownload } from '../../../../../api/api';
import CustomDateRangePicker from '../../../../custom/Datepicker/CustomDateRangePicker';

import {
    BillViewLink,
    ActionTextLinkBtn,
} from '../../common/CommonLinks';

import {
    setLoader,
    getDateFormat,
    setFilterMessage,
    isFiltersApplied,
    stateChangeManager,
    get_last_12_month_date,
} from '../../../../../utils/Utils';

import {
    CustomContainer,
    CustomTitleContainer,
} from '../../../../custom/container/CustomContainer';

import {
    list_vendor,
    update_bill,
    delete_bill,
    export_bill,
    retrieve_bill,
    get_list_of_bill,
    get_list_bill_statestics
} from '../../../../../api/urls';

import {
    Box,
    Grid,
} from '@mui/material';
import LoopIcon from '@mui/icons-material/Loop';


import {
    CustomTable,
    CustomTableRow,
    CustomTableBody,
    CustomTableHead,
    CustomTableHeadCell,
    CustomTableBodyCell,
    CustomTableContainer,
} from '../../../../custom/table/CustomTable';
import CurrencyFormatterWithExchangeRate from '../../common/CurrencyFormatterWithExchangeRate';


const Dropdown = DateRange
const BillsList = () => {
    let location = useLocation();
    let navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);

    const [count, setCount] = React.useState();
    const tabs = [
        {AnalyticsEvent:()=>{AnalyticsEvent(eventsNames.categories.BILLS,{action:eventsNames.actions.CLICK,filter_type:eventsNames.actions.bill.filter_type.ALL})}, name: <span>All bills</span>, condition: {} },
        {AnalyticsEvent:()=>{AnalyticsEvent(eventsNames.categories.BILLS,{action:eventsNames.actions.CLICK,filter_type:eventsNames.actions.bill.filter_type.UNPAID})}, name: <BadgeWithTitle title={'Unpaid'} count={count && count.unpaid} />, condition: { payment_status: 'unpaid' } },
        {AnalyticsEvent:()=>{AnalyticsEvent(eventsNames.categories.BILLS,{action:eventsNames.actions.CLICK,filter_type:eventsNames.actions.bill.filter_type.DRAFT})}, name: <BadgeWithTitle title={'Draft'} count={count && count.draft} />, condition: { is_draft: true } },
    ]

    const [tab, setTab] = React.useState(0)
    const [page, setPage] = React.useState(1);
    const [time, setTime] = React.useState(moment());
    const [results, setResults] = React.useState([])
    const [overView, setOverView] = React.useState()
    const [status, setStatus] = React.useState(null)
    const [reLoad, setReLoad] = React.useState(false)

    const [pagination, setPagination] = React.useState({
        next: undefined,
        count: '0',
        previous: undefined,
        number_of_pages: undefined,
    })

    const [state, setState] = React.useState({
        id: '',
        title: '',
        open: false,
        condition: '',
        maxWidth: 'lg',
        fullWidth: true,

    })

    const stateChangeHandler = (title, condition, maxWidth, url, deleteMessage) => {
        setState({
            url: url,
            open: true,
            title: title,
            fullWidth: true,
            maxWidth: maxWidth,
            condition: condition,
            deleteMessage: deleteMessage,
            onDeleteAction: () => { getApiResults(body, page); }
        })
    }

    const [filters, setFilters] = React.useState({
        sort_by: 'bill_date',
        sort_order: 'D'
    })

    React.useEffect(() => {
        if (location.state) {
            if (location.state.action === 'total_unpaid') {
                setTab(1);
            }
        }
    }, [location.state]);

    const webSocketResponse = CommonWebSocket();
    React.useEffect(() => {
        if (webSocketResponse) {
            let webSocketData = JSON.parse(webSocketResponse.data)
            if (webSocketData.document_type === "bill") {
                let index = results.findIndex((item) => item.id === webSocketData.object_id);

                if (webSocketData.event === "delete_document" && index !== null && index >= 0) {
                    if (webSocketData.event_success) {
                        stateChangeManager(dispatch, Actions, true, "success", `Bill ${webSocketData.object_number} deleted successfully`);
                        getOverviewResults();
                        getApiResults(body, page);
                    } else {
                        stateChangeManager(dispatch, Actions, true, "error", `Failed to delete Bill ${webSocketData.object_number}`);
                    }
                }

                if (webSocketData.event === "update_document" && index !== null && index >= 0) {
                    if (webSocketData.event_success) {
                        stateChangeManager(dispatch, Actions, true, "success", `Bill ${webSocketData.object_number} updated successfully`);
                        getOverviewResults();
                        getApiResults(body, page);
                    } else {
                        stateChangeManager(dispatch, Actions, true, "error", `Failed to update Bill ${webSocketData.object_number}`);
                    }
                }
            }
        }
    }, [webSocketResponse])

    let body = { role: "vendor", business_id: getBusinessInfo().id, ...filters }
    React.useEffect(() => {
        getApiResults(body, page);
        // eslint-disable-next-line
    }, [filters, page, reLoad])
    React.useEffect(() => {
        getOverviewResults();
    }, [])

    const getOverviewResults = async () => {
        let overView_res = await apiAction({
            method: 'post',
            url: get_list_bill_statestics(),
            data: { business_id: getBusinessInfo().id },
        })
        setTime(moment())
        setOverView(overView_res.result)
    }

    const getApiResults = async (body, page) => {
        setLoader(dispatch, Actions, true);
        setFilterMessage(dispatch, Actions, null);
        let res = await apiAction({
            data: body,
            method: 'post',
            dispatch: dispatch,
            navigate: navigate,
            url: get_list_of_bill(page),
        })
        if (res?.success) {
            setPagination({
                ...pagination,
                next: res?.result.next,
                count: res?.result.count,
                previous: res?.result.previous,
                number_of_pages: res?.result.number_of_pages,
            });
            setResults(res?.result.result);
            setLoader(dispatch, Actions, false);
            setCount({
                all: res?.result.count,
                paid: res?.result.paid,
                draft: res?.result.draft,
                unpaid: res?.result.unpaid,
                posted: res?.result.posted,
                partially_paid: res?.result.partially_paid,
            })
            if (isFiltersApplied(filters)) { setFilterMessage(dispatch, Actions, `No bills found for your current filters. Verify your filters and try again.`) };
        } else {
            setLoader(dispatch, Actions, false);
        }
    }

    const handleChange = (event, tab) => {
        setPage(1);
        setTab(tab);
        if (tab === 0) {
            delete filters.is_draft
            delete filters.is_paid
        }
        if (tab === 1) {
            filters['is_paid'] = false
            filters['is_draft'] = false
            if (status) {
                if (status.disabled === false) {
                    setStatus(null);
                    delete filters.status;
                }

            }
        }
        if (tab === 2) {
            delete filters.status
            delete filters.is_paid
            filters['is_draft'] = true

            if (status) {
                setStatus(null);
            }
        }
        setFilters({ ...filters })
    }

    React.useEffect(()=>{
        if (tabs[tab].AnalyticsEvent) {
            tabs[tab].AnalyticsEvent()
        }
        },[tab])

    return (
        <div>
            <CustomDialog
                state={state}
                setState={setState}
            />

            <CustomTitleContainer>
                <Grid container spacing={0} style={{
                    alignItems: 'center',
                    justifyItems: 'center',
                    alignContent: 'space-evenly',
                    justifyContent: 'space-evenly',
                }}>
                    <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'start', }}>
                        <CustomTitle title={'Bills'} />
                    </Grid>
                    <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'end', }}>
                        <CustomButton
                            variant="contained"
                            btnLabel='Create a Bill'
                            id={'create_a_bill_btn'}
                            sx={{ textTransform: 'none', }}
                            dataTestId={'create_a_bill_btn'}
                            onClick={() => {
                                navigate(routesName.invoicingPurchaseBillAdd.path)
                                AnalyticsEvent(eventsNames.categories.BILLS,{action:eventsNames.actions.NEW})
                            }}
                        />
                    </Grid>
                </Grid>
            </CustomTitleContainer>

            <CustomContainer maxWidth={"400"} sx={{maxHeight:{xs:'calc(100vh - 240px)',sm:'100%'}}}>
                <div style={{ paddingLeft: '16px', paddingRight: '16px', paddingBottom: '24px', }}>
                    <Overview time={time} overView={overView} getOverviewResults={getOverviewResults} />
                    <div style={{ display: '-webkit-flex', }}>
                        <Filters tab={tab} stateChangeHandler={stateChangeHandler} filters={filters} setFilters={setFilters} status={status} setStatus={setStatus} setPage={setPage} />
                    </div>
                </div>
                <CustomTabs
                    id={'tabs_'}
                    dataTestId={'tabs_'}
                    tabs={tabs}
                    value={tab}
                    onChange={handleChange}
                />
                <CustomTableContainer>
                    <CustomTable sx={{}}>
                        <BillsListHeaders filters={filters} setFilters={setFilters} />
                        <BillsListBody data={results} stateChangeHandler={stateChangeHandler} reload={() => setReLoad(!reLoad)} />
                    </CustomTable>
                    <Box sx={{ pt: !results.length ? 10 : 0 }} >
                        <NoDataComponent left='0%' top='0%' position={'relative'} data={results} />
                    </Box>
                </CustomTableContainer>
            </CustomContainer>

            <CustomPagination
                page={page}
                count={pagination.number_of_pages}
                onChange={(event, newValue) => setPage(newValue)}
            />
        </div>
    )
}


const BillsListHeaders = (props) => {
    const { filters, setFilters } = props

    return (
        <CustomTableHead>
            <CustomTableRow >
                <CustomTableHeadCell style={{ width: 100 }} align='center' ><span style={{ cursor: '' }}>Status </span></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 100 }} ><HeadingWithSortable heading={'Date'} sortableKey={'bill_date'} filters={filters} setFilters={setFilters} /></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 120 }} ><HeadingWithSortable heading={'Number'} sortableKey={'bill_number'} filters={filters} setFilters={setFilters} /></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 210 }} ><HeadingWithSortable heading={'Vendor'} sortableKey={'vendor_display_name'} filters={filters} setFilters={setFilters} /></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 150 }} ><HeadingWithSortable heading={'Due Date'} sortableKey={'due_date'} filters={filters} setFilters={setFilters} /></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 150 }} align='right'><HeadingWithSortable heading={'Total'} sortableKey={'total_amount'} filters={filters} setFilters={setFilters} /></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 150 }} align='right' ><HeadingWithSortable heading={'Amount Due'} sortableKey={'due_amount'} filters={filters} setFilters={setFilters} /></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 200 }} align='right' ><span>Actions</span></CustomTableHeadCell>
            </CustomTableRow>
        </CustomTableHead>
    )
}

const BillsListBody = (props) => {
    const { data, stateChangeHandler, reload } = props
    let navigate = useNavigate()
    const dispatch = Actions.getDispatch(React.useContext);

    const onSelectAction = (action, item) => {
        setLoader(dispatch, Actions, true)
        if (action === "edit") {
            navigate(routesName.invoicingPurchaseBillAdd.path + "?id=" + item.id)
        }
        if (action === "view") {
            navigate(routesName.invoicingPurchaseBillView.path + "?id=" + item.id)
            AnalyticsEvent(eventsNames.categories.BILLS,{action:eventsNames.actions.VIEW})
        }
        if (action === "approve") {
            approveBill(item)
            AnalyticsEvent(eventsNames.categories.BILLS,{action:eventsNames.actions.APPROVE})
        }
        if (action === "print") {
            printBill(item)
            AnalyticsEvent(eventsNames.categories.BILLS,{action:eventsNames.actions.PRINT})
        }
        if (action === "download") {
            downloadBill(item)
            AnalyticsEvent(eventsNames.categories.BILLS,{action:eventsNames.actions.DOWNLOAD})
        }
        if (action === "record_payment") {
            setLoader(dispatch, Actions, false)
            recordPayment(item)
            AnalyticsEvent(eventsNames.categories.BILLS,{action:eventsNames.actions.RECORD_PAYMENT})
        }
    }

    const recordPayment = async (bill) => {
        let data = await apiAction({
            method: 'post',
            url: retrieve_bill(bill.id),
            data: { business_id: getBusinessInfo().id },
        })
        if (data) {
            navigate(routesName.invoicingPaymentsMade.path, { state: data.result })
        }
    }
    const approveBill = async (bill) => {
        let data = await apiAction({
            method: 'post',
            url: retrieve_bill(bill.id),
            data: { business_id: getBusinessInfo().id },
        })
        if (data) {
            const approveBillRes = await apiAction({ url: update_bill(bill.id), method: 'POST', data: { is_draft: false, vendor_id: data.result.vendor_id, business_id: getBusinessInfo().id } })
            if (approveBillRes.success) {
                reload(true)
                stateChangeManager(dispatch, Actions, true, 'success', approveBillRes.status);
            }
        }
    }

    const printBill = async (bill) => {
        let data = await apiAction({
            method: 'post',
            url: retrieve_bill(bill.id),
            data: { business_id: getBusinessInfo().id },
        })
        if (data) {
            apiBlobResponse({
                url: export_bill(), data: { ...data.result, payment_term_id: data.result.payment_term }, onSuccess: () => {
                    setLoader(dispatch, Actions, false)
                }
            })
        }
    }

    const downloadBill = async (bill) => {
        let data = await apiAction({
            method: 'post',
            url: retrieve_bill(bill.id),
            data: { business_id: getBusinessInfo().id },
        })
        if (data) {
            apiHandleDownload({
                url: export_bill(), data: { ...data.result, payment_term_id: data.result.payment_term }, filename: data.result.bill_number, onSuccess: () => {
                    setLoader(dispatch, Actions, false)
                }
            })
        }
    }

    const toActionText = (status) => {
        let text = ''
        if (status === 'draft') {
            text = 'Approve'
        } else if (status === 'paid') {
            text = 'View'
        } else if (status === 'unpaid' || status === 'overdue' || status === 'partially_paid') {
            text = 'Record payment'
        }
        return text
    }

    const onClickActionText = (status, item) => {
        if (status === 'draft') {
            onSelectAction("approve", item)
        } else if (status === 'paid') {
            onSelectAction("view", item)
        } else if (status === 'unpaid' || status === 'overdue' || 'partially_paid') {
            if (item.total_amount === 0) {
                stateChangeManager(dispatch, Actions, true, 'error', `Record payment is not possible for bill with zero amount`);
            } else {
                onSelectAction("record_payment", item)
            }
        }
    }
    return (
        <CustomTableBody>
            {
                data.map((item, index) => {
                    return (
                        <CustomTableRow key={index}>
                            <CustomTableBodyCell sx={{}} align='center' ><Status status={item.status} /></CustomTableBodyCell>
                            <CustomTableBodyCell sx={{}}  ><span>{moment(item.bill_date).format(getDateFormat())}</span></CustomTableBodyCell>
                            {/* <CustomTableBodyCell sx={{}}  ><span>{item.bill_number}</span></CustomTableBodyCell> */}
                            <CustomTableBodyCell sx={{}}  ><BillViewLink id={item.id} title={item.bill_number} /></CustomTableBodyCell>
                            <CustomTableBodyCell sx={{}}  ><span>{item.vendor_display_name}</span></CustomTableBodyCell>
                            <CustomTableBodyCell sx={{}} ><span>{moment(item.due_date).format(getDateFormat())}</span></CustomTableBodyCell>
                            <CustomTableBodyCell sx={{}} align='right'>
                                <span>{<CurrencyFormatter amount={item.total_amount} currency={item.currency_code} />}</span><br />
                                <CurrencyFormatterWithExchangeRate currency_code={item.currency_code} amount={item.total_amount} exchange_rate={item.exchange_rate} />
                            </CustomTableBodyCell>
                            <CustomTableBodyCell sx={{}} align='right'>
                                <span>{<CurrencyFormatter amount={item.due_amount} currency={item.currency_code} />}</span><br />
                                <CurrencyFormatterWithExchangeRate currency_code={item.currency_code} amount={item.due_amount} exchange_rate={item.exchange_rate} />
                            </CustomTableBodyCell>
                            <CustomTableBodyCell sx={{}} align='right'>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                    <ActionTextLinkBtn
                                        index={index}
                                        toActionText={toActionText(item.status)}
                                        onClickActionText={() => onClickActionText(item.status, item)} />
                                    <ListItemActions
                                        index={index}
                                        actions={
                                            [
                                                item.status !== 'paid' && { name: 'View', onClick: () => { onSelectAction("view", item) } },
                                                item.payment_status === 'unpaid' && { name: 'Edit', onClick: () => { onSelectAction("edit", item) } },
                                                { name: 'Print', onClick: () => { onSelectAction("print", item) } },
                                                { name: 'Export as PDF', onClick: () => { onSelectAction("download", item) } },
                                                { name: 'Delete', showDivider: true, onClick: () => { stateChangeHandler('Delete Bill', 'delete', 'sm', delete_bill(item.id), `The bill will be deleted and can not be retrieved later.\n Are you sure about deleting it?`); AnalyticsEvent(AnalyticsEvent(eventsNames.categories.BILLS,{action:eventsNames.actions.DELETE})) } }
                                            ]
                                        }
                                    />
                                </Box>
                            </CustomTableBodyCell>
                        </CustomTableRow>
                    )
                })
            }
        </CustomTableBody>
    )
}

export default BillsList;

const Overview = (props) => {
    const { time, overView, getOverviewResults } = props
    return (
        <Grid container sx={{ pl: 2, pt: 2, pb: 2, border: '1px solid #FFF0C0', backgroundColor: "#FFF7DC" }}>

            <Grid item xs={12} sm={6} sx={{ pl: 2, pt: 2, pb: 2, borderRight: '1px solid #F1D881' }}>
                <CustomTypography
                    text={'Overdue'}
                    sx={{
                        mb: 1,
                        color: '#7A7A7A',
                        fontSize: '14px',
                        fontWeight: '600',
                        lineHeight: '19px',
                        fontStyle: 'normal',
                        textTransform: 'none',
                        fontFamily: "Noto Sans",
                    }}
                />
                <CustomTypography
                    text={overView ? <span>{<CurrencyFormatter amount={overView.overdue_amount} />} <span style={{ color: '#999999', fontSize: '12px', lineHeight: '16px', fontWeight: 400, fontFamily: "Noto Sans" }}> { } </span></span> : ''}
                    sx={{
                        mb: 1,
                        color: '#000000',
                        fontSize: '24px',
                        fontWeight: '700',
                        lineHeight: '28px',
                        fontStyle: 'normal',
                        textTransform: 'none',
                        fontFamily: "Noto Sans",
                    }}
                />
                <CustomTypography
                    id={'refresh_action_btn'}
                    dataTestId={'refresh_action_btn'}
                    text={<span>Last update {<TimeAgo created_at={time} />}.<LoopIcon sx={{ mb: -1, color: '#2464EB', cursor: 'pointer' }} onClick={() => { getOverviewResults(); getOverviewResults(); }} /></span>}
                    sx={{
                        mb: 1,
                        color: '#999999',
                        fontSize: '12px',
                        fontWeight: '400',
                        lineHeight: '19px',
                        fontStyle: 'normal',
                        textTransform: 'none',
                        fontFamily: "Noto Sans",
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ pl: 2, pt: 2, pb: 2, borderLeft: '1px solid #F1D881' }}>
                <CustomTypography
                    text={'Due within next 30 days'}
                    sx={{
                        mb: 1,
                        color: '#7A7A7A',
                        fontSize: '14px',
                        fontWeight: '600',
                        lineHeight: '19px',
                        fontStyle: 'normal',
                        textTransform: 'none',
                        fontFamily: "Noto Sans",
                    }}
                />
                <CustomTypography
                    text={overView ? <span>{<CurrencyFormatter amount={overView.due_within_next_30_days} />}<span style={{ color: '#999999', fontSize: '12px', lineHeight: '16px', fontWeight: 400, fontFamily: "Noto Sans" }}> { } </span></span> : ''}
                    sx={{
                        mb: 1,
                        color: '#000000',
                        fontSize: '24px',
                        fontWeight: '700',
                        lineHeight: '28px',
                        fontStyle: 'normal',
                        textTransform: 'none',
                        fontFamily: "Noto Sans",
                    }}
                />
            </Grid>

            {/* <Grid item xs={4} sx={{ pl: 2, pt: 2, pb: 2, borderLeft: '1px solid #F1D881' }}>
                <CustomTypography
                    text={'Average time to get paid'}
                    sx={{
                        mb: 1,
                        color: '#7A7A7A',
                        fontSize: '14px',
                        fontWeight: '600',
                        lineHeight: '19px',
                        fontStyle: 'normal',
                        textTransform: 'none',
                        fontFamily: "Noto Sans",
                    }}
                />
                <CustomTypography
                    text={<span>29 <span style={{ color: '#999999', fontSize: '12px', lineHeight: '16px', fontWeight: 400, fontStyle: 'normal', }}> DAYS</span></span>}
                    sx={{
                        mb: 1,
                        color: '#000000',
                        fontSize: '24px',
                        fontWeight: '700',
                        lineHeight: '28px',
                        fontStyle: 'normal',
                        textTransform: 'none',
                        fontFamily: "Noto Sans",
                    }}
                />
            </Grid> */}
        </Grid>
    )
}

const Filters = (props) => {
    let location = useLocation();
    const { tab, setPage, stateChangeHandler, setFilters, filters, status, setStatus } = props

    let [dates, setDate] = React.useState();
    const [vendor, setVendor] = React.useState(null);

    //This code block is use when we pass the state through navigate
    const setLocationData = (vendor, vendor_id, from_date, to_date) => {

        let end_date = to_date ? to_date : get_last_12_month_date().to_date;
        let start_date = from_date ? from_date : get_last_12_month_date().from_date;

        setVendor(vendor);
        filters.vendor_id = vendor_id;
        filters.bill_end_date = end_date;
        filters.bill_start_date = start_date;
        setDate([moment(start_date), moment(end_date)]);
    }

    React.useEffect(() => {
        if (location.state) {
            if (location.state.action === 'total_unpaid') {
                filters.is_paid = false;
                filters.is_draft = false;
                setLocationData(location.state.vendor, location.state.vendor_id);
            }
            if (location.state.action === 'total_purchase') {
                filters.is_draft = false;
                setLocationData(location.state.vendor, location.state.vendor_id);
            }
            if (location.state.action === 'purchase_by_vendor') {
                filters.is_draft = false;
                setLocationData(location.state.vendor, location.state.vendor_id, location.state.from_date, location.state.to_date);
            }
        }
        setFilters({ ...filters });
    }, [location.state]);


    const statusList = [
        { name: 'Paid', disabled: filters.is_paid === false, condition: { status: 'paid', is_draft: false } },
        { name: 'Overdue ', condition: { status: 'overdue', is_draft: false } },
        { name: 'Partially Paid', condition: { status: 'partially_paid', is_draft: false } },
    ]

    const onDateRangeChange = (dates) => {
        setPage(1);
        if (dates) {
            setFilters({ ...filters, bill_start_date: dates[0].format("YYYY-MM-DD"), bill_end_date: dates[1].format("YYYY-MM-DD") })
        } else {
            delete filters.bill_end_date
            delete filters.bill_start_date
            setFilters({ ...filters })
        }

    }

    return (
        <Grid item xs={12} container sx={{ mt: 2, mb: 2 }} spacing={1}>

            <Grid item xs={6} sm={2.5}sx={{pb:{xs:2,sm:0}}}>
                <Dropdown
                    id={'status_dropdown'}
                    dataTestId={'status_dropdown'}
                    label="Status"
                    item={{
                        disabled: filters.is_draft,
                        sx: filters.is_draft ? { background: '#F1F3F4' } : {}
                    }}
                    value={status}
                    placeholder='Select Status'
                    results={statusList}
                    setValue={(event, value) => {
                        setPage(1);
                        setStatus(value);
                        if (value) {
                            delete filters.status
                            setFilters({ ...filters, ...value.condition })
                        } else {
                            delete filters.status
                            if (tab === 0) {
                                delete filters.is_draft
                                delete filters.is_paid
                            }
                            if (tab === 1) {
                                filters['is_paid'] = false
                                filters['is_draft'] = false
                            }
                            setFilters({ ...filters })
                        }
                    }}
                />
            </Grid>

            <Grid item xs={6} sm={2.5}sx={{pb:{xs:2,sm:0}}}>
                <CommonAsyncDropdown
                    id={'vendor_dropdown'}
                    dataTestId={'vendor_dropdown'}
                    autoSelect={false}
                    disableClearable={false}
                    optionLabel="display_name"
                    placeholder='Select Vendor'
                    noOptionsText={"No result found"}

                    item={{
                        value: vendor,
                        method: 'post',
                        label: 'Vendors',
                        url: list_vendor(1),
                        body: { is_inactive: false, business_id: getBusinessInfo().id, role: 'vendor' },
                        onChange: (event, value) => {
                            setPage(1);
                            if (value) {
                                setFilters({ ...filters, vendor_id: value.id })
                            } else {
                                delete filters.vendor_id
                                setFilters({ ...filters })
                            }
                            setVendor(value)

                        },
                    }}
                    addButton={{
                        title: '+ Add New Vendor',
                        onClick: () => stateChangeHandler('New Vendor', 'new_vendor', 'lg')
                    }}
                />
            </Grid>

            <Grid item xs={12} sm={4.5} container>
                <CustomDateRangePicker dates={dates} onDateRangeChange={onDateRangeChange} />
            </Grid>

            <Grid item xs={12} sm={2.5}>
                <CommonSearch
                    id={'input_search_number'}
                    dataTestId={'input_search_number'}
                    title={'Search'}
                    fullWidth={true}
                    titleVisibility={'hidden'}
                    placeholder={'Enter Bill #'}
                    onSearchValue={filters.bill_number ? filters.bill_number : ''}
                    onSearch={(search_text) => {
                        setPage(1);
                        setFilters({ ...filters, bill_number: search_text })
                    }} />
            </Grid>
        </Grid>
    )
}

