import React from 'react';
import * as Actions from '../../../../state/Actions';
import { getToken } from '../../../../config/cookiesInfo';
import CustomButton from '../../../custom/button/CustomButton';
import {
    setLoader,
    isFormValid,
    stateChangeManager
} from '../../../../utils/Utils';

const PrintAndPreview = (props) => {
    const { apiUrl, data, setFormSubmitted, validation_data, } = props

    const dispatch = Actions.getDispatch(React.useContext);

    const buttonHandler = () => {
        setFormSubmitted();
        const { isValid, message } = isFormValid(data, validation_data)

        if (isValid) {
            delete data["due_amount"]
            
            setLoader(dispatch, Actions, true)
            let file_key = "attachment"
            var formData = new FormData();
            if (data[file_key]) {
                formData.append(file_key, data[file_key])
            }
            data[file_key] = null;
            data['export_type'] = 'pdf';
            formData.append("export_type", 'pdf');
            formData.append("data", JSON.stringify(data));
            formData.append("business_id", data['business_id']);

            fetch(apiUrl, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Token ${getToken()}`
                }
            })
                .then((response) => response.blob())
                .then((res) => {
                    setLoader(dispatch, Actions, false)
                    const file = res
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);

                })
                .catch((err) => {
                    console.log(err.message);
                });
        } else {
            stateChangeManager(dispatch, Actions, true, 'error', message);
        }
    }

    return (
        <CustomButton
            id={'print_preview_btn'}
            dataTestId={'print_preview_btn'}
            variant='outlined'
            onClick={buttonHandler}
            btnLabel='Print or Preview'
            sx={{ textTransform: 'none', mr: 1, '&:hover': { backgroundColor: '#e8f3ff' } }}
        />
    )
}

export default PrintAndPreview