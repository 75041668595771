import React, { useEffect } from 'react';
import moment from 'moment/moment';
import Input from '../../../common/Input';
import Status from '../../../common/Status';
import { apiAction } from '../../../../api/api';
import { setLoader } from '../../../../utils/Utils';
import * as Actions from '../../../../state/Actions';
import CustomTitle from '../../../common/CustomTitle';
import CustomTabs from '../../../custom/tabs/CustomTabs';
import { routesName } from '../../../../config/routesName';
import { useLocation, useNavigate } from 'react-router-dom';
import { CustomLink } from '../../../custom/link/CustomLink';
import CustomButton from '../../../custom/button/CustomButton';
import { getBusinessInfo } from '../../../../config/cookiesInfo';
import CurrencyFormatter from '../../../common/CurrencyFormatter';
import AuditTrailHistory from '../../../common/AuditTrailHistory';
import { BadgeWithTitle } from '../../../custom/badge/CustomBadge';
import { LabelWithAsteriskMark } from '../../../common/CommonLabel';
import CustomPagination from '../../../custom/pagination/CustomPagination';
import CustomTypography from '../../../custom/typography/CustomTypography';
import NoTransactionComponent from '../../../common/NoTransactionComponent';
import { eventsNames, AnalyticsEvent } from '../../../../firebase/firebaseAnalytics';
import CurrencyFormatterWithExchangeRate from '../common/CurrencyFormatterWithExchangeRate';

import {
    InvoiceViewLink,
    EstimateViewLink,
    SalesOrderViewLink,
    CreditNoteViewLink,
    RecurringInvoiceViewLink,
} from '../common/CommonLinks';

import {
    BootstrapDialog,
    BootstrapDialogTitle,
} from '../../bulkStatementUpload/mappings/Transactions';

import {
    isNumber,
    isEmptyDict,
    getDateFormat,
    getQueryParams,
    limitDecimalPlaces,
    stateChangeManager,
    to_check_repeat_every,
    formatterWithoutSymbol,
    breadCrumbsStateChangeManager,
} from '../../../../utils/Utils';

import {
    CustomContainer,
    CustomTitleContainer,
} from '../../../custom/container/CustomContainer';

import {
    get_item,
    adjust_item_stock,
    get_stock_history,
    get_item_stock_info,
    get_list_of_invoice,
    get_list_of_estimate,
    get_list_of_sales_order,
    get_list_of_credit_note,
    get_item_transaction_count,
    get_list_of_recurring_invoice,
} from '../../../../api/urls';

import {
    CustomTable,
    CustomTableRow,
    CustomTableBody,
    CustomTableHead,
    CustomTableHeadCell,
    CustomTableBodyCell,
    CustomTableContainer,
} from '../../../custom/table/CustomTable';

import {
    Box,
    Grid,
    Radio,
    Badge,
    Avatar,
    Accordion,
    RadioGroup,
    DialogContent,
    FormControlLabel,
    AccordionSummary,
    AccordionDetails,
} from '@mui/material';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ViewPageActionButtons from '../../../common/ViewPageActionButtons';
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';

const ItemViewpage = () => {
    let navigate = useNavigate();
    let location = useLocation();
    const params = getQueryParams(location.search);
    const dispatch = Actions.getDispatch(React.useContext);

    const [tab, setTab] = React.useState(0);
    const [itemData, setItemData] = React.useState();
    const [stockInfo, setStockInfo] = React.useState();
    const [refreshStockHistory, setRefreshStockHistory] = React.useState(false);

    React.useEffect(() => {
        const getItem = async () => {
            setLoader(dispatch, Actions, true)
            let res = await apiAction({
                method: 'post',
                url: get_item(params.item_id),
                data: { business_id: getBusinessInfo().id },
            })
            if (res?.success) {
                setItemData(res?.result);
                setLoader(dispatch, Actions, false);
                breadCrumbsStateChangeManager(dispatch, res?.result.name);
            } else {
                setLoader(dispatch, Actions, false)
            }
        }
        if (params.item_id) {
            getItem();
            getStockInfo();
        }
        // eslint-disable-next-line
    }, []);

    const getStockInfo = async () => {
        let res = await apiAction({
            method: 'get',
            url: get_item_stock_info(params.item_id),
        })
        if (res?.success) {
            let stock_info = res?.result;
            if (!isEmptyDict(res?.result)) {
                setStockInfo([
                    { title: 'Current Stock', value: formatterWithoutSymbol(null, Number(stock_info.item_current_stock)), isData: true, text: 'As On', date: stock_info.current_stock_as_on_date },
                    { title: 'Stock In', value: <span>{formatterWithoutSymbol(null, Number(stock_info.stock_in))}<ArrowDropUpRoundedIcon fontSize='large' sx={{ ml: -1, mr: -1.2, mb: -1.8, color: '#41CB68' }} /></span>, borderLeft: '2px solid #F1D881', borderRight: '2px solid #F1D881', },
                    { title: 'Stock Out', value: <span>{formatterWithoutSymbol(null, Number(stock_info.stock_out))}<ArrowDropDownRoundedIcon fontSize='large' sx={{ ml: -1, mr: -1.2, mb: -1.6, color: '#FF4A33' }} /></span>, borderRight: '2px solid #F1D881', },
                    { title: 'Opening Stock', value: formatterWithoutSymbol(null, Number(stock_info.item_opening_stock)), isData: true, text: 'As On', date: stock_info.opening_stock_as_on_date },
                ])
            }
        } else {
        }
    }


    const tabs = [
        { name: 'Overview', condition: 'overview'},
        { name: 'Transaction List', condition: 'transaction_list'},
        itemData && itemData.current_stock !== null && { name: 'Stock History', condition: 'stock_history'},
    ]
    const [open, setOpen] = React.useState(false)
    return (
        itemData &&
        <div>
            <ManageStock open={open} setOpen={setOpen} setProductService={setItemData} productService={itemData} getStockInfo={() => { getStockInfo(); setRefreshStockHistory(!refreshStockHistory) }} />
            <CustomTitleContainer>
                <Grid container spacing={0} style={{
                    alignItems: 'center',
                    justifyItems: 'center',
                    alignContent: 'space-evenly',
                    justifyContent: 'space-evenly',
                }}>
                    <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'start', }}>
                        <CustomTitle title={'Goods & Services'} />
                    </Grid>
                    <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'end', }}>
                        <ViewPageActionButtons
                            isSent={false}
                            isPrint={false}
                            isEditable={true}
                            isDownload={false}
                            edit={() => {
                                navigate(routesName.invoicingItemsAdd.path + "?item_id=" + params.item_id, { replace: false })
                                // AnalyticsEvent(eventsNames.categories.ITEMS, { action: eventsNames.actions.EDIT });
                            }}
                        />
                        {
                            itemData.current_stock !== null &&
                            <CustomButton
                                id={'manage_btn'}
                                variant="contained"
                                dataTestId={'manage_btn'}
                                onClick={() => { setOpen(true) }}
                                sx={{ ml: 2, textTransform: 'none', }}
                                btnLabel={<span><SyncAltIcon fontSize='small' sx={{ mb: -.6, fontWeight: '700', transform: "rotate(90deg)" }} /> Manage Inventory</span>}
                            />
                        }
                        <AuditTrailHistory
                            data={{ object_id: params.item_id, object_type: 'items' }}
                            AnalyticsEvent={() => {AnalyticsEvent(eventsNames.categories.ITEMS, { action: eventsNames.actions.HISTORY });}} 
/>
                    </Grid>
                </Grid>
            </CustomTitleContainer>

            <CustomContainer maxWidth={"400"} sx={{ height: 'calc(100vh - 134px)' }}>
                <Box sx={{ mb: 2 }}>
                    <BasicInfo itemData={itemData} />
                    {
                        itemData.current_stock !== null &&
                        <StockOverview
                            stockInfo={stockInfo}
                            item_id={itemData.id}
                            unit_code={itemData.unit.code}
                        />
                    }
                </Box>
                <CustomTabs
                    id={'tabs_'}
                    dataTestId={'tabs_'}
                    tabs={tabs}
                    value={tab}
                    onChange={(event, tab) => { 
                        setTab(tab);
                     }}
                />
                {tabs[tab].condition === 'overview' && <Overview itemData={itemData} dispatch={dispatch} />}
                {tabs[tab].condition === 'transaction_list' && <TransactionList itemData={itemData} dispatch={dispatch} />}
                {tabs[tab].condition === 'stock_history' && <StockHistory item_id={itemData.id} dispatch={dispatch} unit_code={itemData.unit.code} refreshStockHistory={refreshStockHistory} />}
            </CustomContainer>
        </div>
    )
}

export default ItemViewpage;

const Overview = (props) => {
    const { itemData } = props;

    const basicInfo = [
        { title: 'Item Type', value: itemData.item_type.capitalize() },
        { title: 'HSN/SAC', value: itemData.hsn_sac_code },
        { title: 'Unit', value: itemData.unit.name + ` (${itemData.unit.code})` },
        { title: 'GST Rate', value: itemData.gst_rate ? itemData.gst_rate + ' %' : '' },
        { title: 'Low Stock Value', value: itemData.low_stock_value },
        { title: 'Low Stock Reminder Active', value: itemData.is_low_stock_reminder_active ? 'Yes' : 'No' },
    ]

    React.useEffect(() => {
        AnalyticsEvent(eventsNames.categories.ITEMS,{action:eventsNames.actions.item.action.ITEM_OVERVIEW})
    }, [])
    return (
        <Box>
            <Grid container sx={{ p: 2 }}>
                <Grid item xs={6}><Box sx={{ p: 2 }}><Grid container>
                    {
                        basicInfo.map((item, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <TitleData item={item} />
                                </React.Fragment>
                            )
                        })
                    }
                </Grid></Box></Grid>
                <Grid item xs={6} sx={{ borderLeft: '2px solid #F5F5F5', }}><Box sx={{ p: 2 }}><Grid container>
                    {
                        itemData.for_sales ?
                            <React.Fragment>

                                <Grid item xs={12}>
                                    <CustomTypography
                                        text={'Sales Information'}
                                        sx={{
                                            mb: 2,
                                            color: '#000000',
                                            fontSize: '14px',
                                            fontWeight: '700',
                                            lineHeight: '18px',
                                            fontStyle: 'normal',
                                            fontFamily: "Noto Sans",
                                            // textTransform: 'uppercase',
                                        }}
                                    />
                                </Grid>
                                <TitleData item={{ title: 'Selling Price', value: <CurrencyFormatter amount={itemData.selling_price} /> }} sx={{ pl: 1 }} />
                                <TitleData item={{ title: 'Sales Account', value: itemData.sales_account ? itemData.sales_account.account_name : '-' }} sx={{ pl: 1 }} />
                            </React.Fragment>
                            : null
                    }
                    {
                        itemData.for_purchase ?
                            <React.Fragment>
                                <Grid item xs={12}>
                                    <CustomTypography
                                        text={'Purchase Information'}
                                        sx={{
                                            mt: 2,
                                            mb: 2,
                                            color: '#000000',
                                            fontSize: '14px',
                                            fontWeight: '700',
                                            lineHeight: '18px',
                                            fontStyle: 'normal',
                                            fontFamily: "Noto Sans",
                                            // textTransform: 'uppercase',
                                        }}
                                    />
                                </Grid>

                                <TitleData item={{ title: 'Cost Price', value: <CurrencyFormatter amount={itemData.cost_price} /> }} sx={{ pl: 1 }} />
                                <TitleData item={{ title: 'Purchase Account', value: itemData.purchase_account ? itemData.purchase_account.account_name : '-' }} sx={{ pl: 1 }} />
                            </React.Fragment>
                            : null
                    }
                </Grid></Box></Grid>
            </Grid>

        </Box>
    )
}


const TitleData = (props) => {
    const { item, sx } = props
    return (
        <>
            <Grid item xs={6} sx={{ ...sx }}>
                <CustomTypography
                    text={item.title ? item.title : '-'}
                    sx={{
                        mb: 2,
                        color: '#000000',
                        fontSize: '14px',
                        fontWeight: '700',
                        lineHeight: '18px',
                        fontStyle: 'normal',
                        fontFamily: "Noto Sans",
                    }}
                />
            </Grid>
            <Grid item xs={6} sx={{ ...sx }}>
                <CustomTypography
                    text={item.value !== null ? item.value : '-'}
                    sx={{
                        mb: 2,
                        color: '#7A7A7A',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '18px',
                        fontStyle: 'normal',
                        fontFamily: "Noto Sans",
                        textTransform: item.textTransform,
                    }}
                />
            </Grid>
        </>
    )
}

const TransactionList = (props) => {
    const { itemData } = props;
    const [open, setOpen] = React.useState([]);
    const dispatch = Actions.getDispatch(React.useContext);

    const [count, setCount] = React.useState()
    const addOrRemove = (array, item) => {
        const exists = array.includes(item)

        if (exists) {
            return array.filter((c) => { return c !== item })
        } else {
            const result = array
            result.push(item)
            return result
        }
    }

    const setCountData = (key, value) => {
        setCount((previous) => ({
            ...previous,
            [key]: value
        }));
    }

    React.useEffect(() => {
        const getCountData = async (item_id) => {
            // setLoader(dispatch, Actions, true)
            let res = await apiAction({
                method: 'get',
                url: get_item_transaction_count(item_id)
            })
            if (res?.success) {
                console.log('====>res', res)
                setCount((previous) => ({ ...previous, ...res?.result }))
            } else {

            }
        }
        if (itemData?.id) {
            getCountData(itemData.id)
        }
        // eslint-disable-next-line
    }, []);
    const transaction_list = [
        { component_name: 'Estimate', name: <BadgeWithTitle title={'Estimate'} count={count?.estimate_count} />, component: <EstimateTransactionList open={open.includes('Estimate')} setCountData={(value) => { setCountData('estimate_count', value) }} item_id={itemData.id} dispatch={dispatch} /> },
        { component_name: 'Sales Order', name: <BadgeWithTitle title={'Sales Order'} count={count?.sales_order_count} />, component: <SalesOrderTransactionList open={open.includes('Sales Order')} setCountData={(value) => { setCountData('sales_order_count', value) }} item_id={itemData.id} dispatch={dispatch} /> },
        { component_name: 'Invoice', name: <BadgeWithTitle title={'Invoice'} count={count?.invoice_count} />, component: <InvoiceTransactionList open={open.includes('Invoice')} setCountData={(value) => { setCountData('invoice_count', value) }} item_id={itemData.id} dispatch={dispatch} /> },
        { component_name: 'Recurring Invoice', name: <BadgeWithTitle title={'Recurring Invoice'} count={count?.recurring_invoice_count} />, component: <RecurringInvoiceTransactionList open={open.includes('Recurring Invoice')} setCountData={(value) => { setCountData('recurring_invoice_count', value) }} item_id={itemData.id} dispatch={dispatch} /> },
        { component_name: 'Credit Note', name: <BadgeWithTitle title={'Credit Note'} count={count?.credit_note_count} />, component: <CreditNoteTransactionList open={open.includes('Credit Note')} setCountData={(value) => { setCountData('credit_note_count', value) }} item_id={itemData.id} dispatch={dispatch} /> },
    ]

    React.useEffect(() => {
        AnalyticsEvent(eventsNames.categories.ITEMS,{action:eventsNames.actions.item.action.ITEM_TRANSACTIONS})
    }, [])

    return (
        transaction_list.map((item, index) => {
            return (
                <Box style={{ p: 2, border: '5px solid #F5F5F5' }} key={index}>
                    <Accordion
                        square
                        elevation={0}
                        disableGutters
                        onChange={() => { setOpen([...addOrRemove(open, item.component_name)]) }}
                        sx={{ '&:before': { display: 'none' } }}
                    >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <CustomTypography
                                text={item.name}
                                sx={{
                                    color: '#000000',
                                    fontSize: '14px',
                                    fontWeight: '700',
                                    lineHeight: '18px',
                                    fontStyle: 'normal',
                                    fontFamily: "Noto Sans",
                                }}
                            />
                        </AccordionSummary>
                        <AccordionDetails>
                            {item.component}
                        </AccordionDetails>
                    </Accordion>
                </Box>
            )
        })
    )
}


const EstimateTransactionList = (props) => {
    const { item_id, dispatch, setCountData, open } = props

    const [page, setPage] = React.useState(1);
    const [estimateData, setEstimateData] = React.useState([]);
    const [pagination, setPagination] = React.useState({
        next: undefined,
        count: '0',
        previous: undefined,
        number_of_pages: undefined,
    });

    React.useEffect(() => {
        const getCustomerEstimate = async (item_id) => {
            // setLoader(dispatch, Actions, true)
            let res = await apiAction({
                method: 'post',
                url: get_list_of_estimate(page),
                data: { business_id: getBusinessInfo().id, item_id: item_id },
            })
            if (res?.success) {
                setPagination({
                    ...pagination,
                    next: res?.result.next,
                    count: res?.result.count,
                    previous: res?.result.previous,
                    number_of_pages: res?.result.number_of_pages,
                })
                setCountData(res?.result.count);
                setEstimateData(res?.result.result)
                setLoader(dispatch, Actions, false)
            } else {
                setLoader(dispatch, Actions, false)
            }
        }
        if (item_id && open) {
            getCustomerEstimate(item_id)
        }
        // eslint-disable-next-line
    }, [item_id, page, open]);

    return (
        estimateData.length ?
            <React.Fragment>
                <CustomTableContainer >
                    <CustomTable>
                        <CustomTableHead>
                            <CustomTableRow>
                                <CustomTableHeadCell style={{ width: 100 }} align='center' ><span >Status</span></CustomTableHeadCell>
                                <CustomTableHeadCell ><span >Date</span></CustomTableHeadCell>
                                <CustomTableHeadCell ><span >Estimate Number</span></CustomTableHeadCell>
                                <CustomTableHeadCell ><span >Customer</span></CustomTableHeadCell>
                                <CustomTableHeadCell align='right' ><span >Total</span></CustomTableHeadCell>
                            </CustomTableRow>
                        </CustomTableHead>
                        <CustomTableBody>
                            {
                                estimateData.map((item, index) => {
                                    return (
                                        <CustomTableRow key={index}>
                                            <CustomTableBodyCell sx={{}} align='center'><Status status={item.status} /></CustomTableBodyCell>
                                            <CustomTableBodyCell sx={{}}  ><span>{moment(item.estimate_date).format(getDateFormat())}</span></CustomTableBodyCell>
                                            {/* <CustomTableBodyCell sx={{}}  ><span>{item.estimate_number}</span></CustomTableBodyCell> */}
                                            <CustomTableBodyCell sx={{}}  ><EstimateViewLink id={item.id} title={item.estimate_number} /></CustomTableBodyCell>

                                            <CustomTableBodyCell sx={{}}  ><span>{item.customer_display_name}</span></CustomTableBodyCell>
                                            <CustomTableBodyCell sx={{}} align='right'>
                                                <span>{<CurrencyFormatter amount={item.total_amount} currency={item.currency_code} />}</span><br />
                                                <CurrencyFormatterWithExchangeRate currency_code={item.currency_code} amount={item.total_amount} exchange_rate={item.exchange_rate} />
                                            </CustomTableBodyCell>
                                        </CustomTableRow>
                                    )
                                })
                            }
                        </CustomTableBody>
                    </CustomTable>
                </CustomTableContainer>
                <CustomPagination
                    page={page}
                    count={pagination.number_of_pages}
                    onChange={(event, newValue) => setPage(newValue)}
                />
            </React.Fragment>
            : <NoTransactionComponent />
    )
}

const SalesOrderTransactionList = (props) => {
    const { item_id, dispatch, setCountData, open } = props

    const [page, setPage] = React.useState(1);
    const [salesOrderData, setSalesOrderData] = React.useState([]);
    const [pagination, setPagination] = React.useState({
        next: undefined,
        count: '0',
        previous: undefined,
        number_of_pages: undefined,
    });

    React.useEffect(() => {

        const getCustomerSalesOrder = async (item_id) => {
            // setLoader(dispatch, Actions, true)
            let res = await apiAction({
                method: 'post',
                url: get_list_of_sales_order(page),
                data: { role: 'customer', business_id: getBusinessInfo().id, item_id: item_id },
            })
            if (res?.success) {
                setPagination({
                    ...pagination,
                    next: res?.result.next,
                    count: res?.result.count,
                    previous: res?.result.previous,
                    number_of_pages: res?.result.number_of_pages,
                })
                setCountData(res?.result.count);
                setSalesOrderData(res?.result.result)
                setLoader(dispatch, Actions, false)
            } else {
                setLoader(dispatch, Actions, false)
            }
        }
        if (item_id && open) {
            getCustomerSalesOrder(item_id)
        }
        // eslint-disable-next-line
    }, [item_id, page, open]);

    return (
        salesOrderData.length ?
            <React.Fragment>
                <CustomTableContainer >
                    <CustomTable>
                        <CustomTableHead>
                            <CustomTableRow>
                                <CustomTableHeadCell style={{ width: 100 }} align='center'><span>Status</span></CustomTableHeadCell>
                                <CustomTableHeadCell ><span >Date</span></CustomTableHeadCell>
                                <CustomTableHeadCell ><span >Number</span></CustomTableHeadCell>
                                <CustomTableHeadCell ><span >Customer</span></CustomTableHeadCell>
                                <CustomTableHeadCell align='right' ><span >Total</span></CustomTableHeadCell>
                            </CustomTableRow>
                        </CustomTableHead>
                        <CustomTableBody>
                            {
                                salesOrderData.map((item, index) => {
                                    return (
                                        <CustomTableRow key={index}>
                                            <CustomTableBodyCell sx={{}} align='center'><Status status={item.status} /></CustomTableBodyCell>
                                            <CustomTableBodyCell sx={{}}  ><span>{moment(item.sales_order_date).format(getDateFormat())}</span></CustomTableBodyCell>
                                            {/* <CustomTableBodyCell sx={{}}  ><span>{item.sales_order_number}</span></CustomTableBodyCell> */}
                                            <CustomTableBodyCell sx={{}}  ><SalesOrderViewLink id={item.id} title={item.sales_order_number} /></CustomTableBodyCell>
                                            <CustomTableBodyCell sx={{}}  ><span>{item.customer_display_name}</span></CustomTableBodyCell>
                                            <CustomTableBodyCell sx={{}} align='right'>
                                                <span>{<CurrencyFormatter amount={item.total_amount} currency={item.currency_code} />}</span><br />
                                                <CurrencyFormatterWithExchangeRate currency_code={item.currency_code} amount={item.total_amount} exchange_rate={item.exchange_rate} />
                                            </CustomTableBodyCell>
                                        </CustomTableRow>
                                    )
                                })
                            }
                        </CustomTableBody>
                    </CustomTable>
                </CustomTableContainer>
                <CustomPagination
                    page={page}
                    count={pagination.number_of_pages}
                    onChange={(event, newValue) => setPage(newValue)}
                />
            </React.Fragment>
            : <NoTransactionComponent />
    )
}

const InvoiceTransactionList = (props) => {
    const { item_id, dispatch, setCountData, open } = props

    const [page, setPage] = React.useState(1);
    const [invoiceData, setInvoiceData] = React.useState([]);
    const [pagination, setPagination] = React.useState({
        next: undefined,
        count: '0',
        previous: undefined,
        number_of_pages: undefined,
    });

    React.useEffect(() => {
        const getCustomerInvoice = async (item_id) => {
            // setLoader(dispatch, Actions, true)
            let res = await apiAction({
                method: 'post',
                url: get_list_of_invoice(page),
                data: { role: 'customer', business_id: getBusinessInfo().id, item_id: item_id },
            })
            if (res?.success) {
                setPagination({
                    ...pagination,
                    next: res?.result.next,
                    count: res?.result.count,
                    previous: res?.result.previous,
                    number_of_pages: res?.result.number_of_pages,
                })
                setCountData(res?.result.count)
                setInvoiceData(res?.result.result)
                setLoader(dispatch, Actions, false)
            } else {
                setLoader(dispatch, Actions, false)
            }
        }
        if (item_id && open) {
            getCustomerInvoice(item_id)
        }
        // eslint-disable-next-line
    }, [item_id, page, open]);

    return (
        invoiceData.length ?
            <React.Fragment>
                <CustomTableContainer >
                    <CustomTable>
                        <CustomTableHead>
                            <CustomTableRow>
                                <CustomTableHeadCell style={{ width: 100 }} align='center'><span >Status</span></CustomTableHeadCell>
                                <CustomTableHeadCell ><span >Date</span></CustomTableHeadCell>
                                <CustomTableHeadCell ><span >Number</span></CustomTableHeadCell>
                                <CustomTableHeadCell ><span >Customer</span></CustomTableHeadCell>
                                <CustomTableHeadCell align='right' ><span >Total</span></CustomTableHeadCell>
                                <CustomTableHeadCell align='right' ><span >Amount Due</span></CustomTableHeadCell>
                            </CustomTableRow>
                        </CustomTableHead>
                        <CustomTableBody>
                            {
                                invoiceData.map((item, index) => {
                                    return (
                                        <CustomTableRow key={index}>
                                            <CustomTableBodyCell sx={{}} align='center'><Status status={item.status} /></CustomTableBodyCell>
                                            <CustomTableBodyCell sx={{}}  ><span>{moment(item.invoice_date).format(getDateFormat())}</span></CustomTableBodyCell>
                                            {/* <CustomTableBodyCell sx={{}}  ><span>{item.invoice_number}</span></CustomTableBodyCell> */}
                                            <CustomTableBodyCell sx={{}}  ><InvoiceViewLink id={item.id} title={item.invoice_number} /></CustomTableBodyCell>
                                            <CustomTableBodyCell sx={{}}  ><span>{item.customer_display_name}</span></CustomTableBodyCell>
                                            <CustomTableBodyCell sx={{}} align='right'>
                                                <span>{<CurrencyFormatter amount={item.total_amount} currency={item.currency_code} />}</span><br />
                                                <CurrencyFormatterWithExchangeRate currency_code={item.currency_code} amount={item.total_amount} exchange_rate={item.exchange_rate} />
                                            </CustomTableBodyCell>
                                            <CustomTableBodyCell sx={{}} align='right'>
                                                <span>{<CurrencyFormatter amount={item.due_amount} currency={item.currency_code} />}</span><br />
                                                <CurrencyFormatterWithExchangeRate currency_code={item.currency_code} amount={item.due_amount} exchange_rate={item.exchange_rate} />
                                            </CustomTableBodyCell>
                                        </CustomTableRow>
                                    )
                                })
                            }
                        </CustomTableBody>
                    </CustomTable>
                </CustomTableContainer>
                <CustomPagination
                    page={page}
                    count={pagination.number_of_pages}
                    onChange={(event, newValue) => setPage(newValue)}
                />
            </React.Fragment>
            : <NoTransactionComponent />
    )
}

const RecurringInvoiceTransactionList = (props) => {
    const { item_id, dispatch, setCountData, open } = props

    const [page, setPage] = React.useState(1);
    const [recurringInvoiceData, setRecurringInvoiceData] = React.useState([]);
    const [pagination, setPagination] = React.useState({
        next: undefined,
        count: '0',
        previous: undefined,
        number_of_pages: undefined,
    });

    React.useEffect(() => {
        const getCustomerRecurringInvoice = async (item_id) => {
            // setLoader(dispatch, Actions, true)
            let res = await apiAction({
                method: 'post',
                url: get_list_of_recurring_invoice(page),
                data: { business_id: getBusinessInfo().id, item_id: item_id },
            })
            if (res?.success) {
                setPagination({
                    ...pagination,
                    next: res?.result.next,
                    count: res?.result.count,
                    previous: res?.result.previous,
                    number_of_pages: res?.result.number_of_pages,
                })
                setCountData(res?.result.count)
                setRecurringInvoiceData(res?.result.result)
                setLoader(dispatch, Actions, false)
            } else {
                setLoader(dispatch, Actions, false)
            }
        }
        if (item_id && open) {
            getCustomerRecurringInvoice(item_id)
        }
        // eslint-disable-next-line
    }, [item_id, page, open]);

    return (
        recurringInvoiceData.length ?
            <React.Fragment>
                <CustomTableContainer >
                    <CustomTable>
                        <CustomTableHead>
                            <CustomTableRow>
                                <CustomTableHeadCell style={{ width: 100 }} align='center'><span>Status</span></CustomTableHeadCell>
                                <CustomTableHeadCell><span>Customer Name</span></CustomTableHeadCell>
                                <CustomTableHeadCell><span>Profile Name</span></CustomTableHeadCell>
                                <CustomTableHeadCell><span>Frequency</span></CustomTableHeadCell>
                                <CustomTableHeadCell><span>Last Invoice Date</span></CustomTableHeadCell>
                                <CustomTableHeadCell><span>Next Invoice Date</span></CustomTableHeadCell>
                                <CustomTableHeadCell align='right'><span>Amount</span></CustomTableHeadCell>
                            </CustomTableRow>
                        </CustomTableHead>
                        <CustomTableBody>
                            {
                                recurringInvoiceData.map((item, index) => {
                                    return (
                                        <CustomTableRow key={index}>
                                            <CustomTableBodyCell align='center'><Status status={item.status} /></CustomTableBodyCell>
                                            <CustomTableBodyCell><span>{item.customer_display_name}</span></CustomTableBodyCell>
                                            {/* <CustomTableBodyCell><span>{item.profile_name}</span></CustomTableBodyCell> */}
                                            <CustomTableBodyCell><RecurringInvoiceViewLink id={item.periodic_task_id} title={item.profile_name} /></CustomTableBodyCell>
                                            <CustomTableBodyCell><span>{to_check_repeat_every(item.repeat_frequency, item.repeat_every) ? to_check_repeat_every(item.repeat_frequency, item.repeat_every).name : `Every ${item.repeat_every} ${item.repeat_frequency.capitalize()}s`}</span></CustomTableBodyCell>
                                            <CustomTableBodyCell><span>{item.last_invoice_date ? moment(item.last_invoice_date).format(getDateFormat()) : '-'}</span></CustomTableBodyCell>
                                            <CustomTableBodyCell><span>{item.status === 'Expired' ? '-' : item.next_invoice_date ? moment(item.next_invoice_date).format(getDateFormat()) : '-'}</span></CustomTableBodyCell>
                                            <CustomTableBodyCell align='right'>
                                                <span><CurrencyFormatter amount={item.amount} currency={item.currency_code} /></span><br />
                                                <CurrencyFormatterWithExchangeRate currency_code={item.currency_code} amount={item.amount} exchange_rate={item.exchange_rate} />
                                            </CustomTableBodyCell>
                                        </CustomTableRow>
                                    )
                                })
                            }
                        </CustomTableBody>
                    </CustomTable>
                </CustomTableContainer>
                <CustomPagination
                    page={page}
                    count={pagination.number_of_pages}
                    onChange={(event, newValue) => setPage(newValue)}
                />
            </React.Fragment>
            : <NoTransactionComponent />
    )
}

const CreditNoteTransactionList = (props) => {
    const { item_id, dispatch, setCountData, open } = props

    const [page, setPage] = React.useState(1);
    const [estimateData, setEstimateData] = React.useState([]);
    const [pagination, setPagination] = React.useState({
        next: undefined,
        count: '0',
        previous: undefined,
        number_of_pages: undefined,
    });

    React.useEffect(() => {
        const getCustomerEstimate = async (item_id) => {
            // setLoader(dispatch, Actions, true)
            let res = await apiAction({
                method: 'post',
                url: get_list_of_credit_note(page),
                data: { role: "customer", business_id: getBusinessInfo().id, item_id: item_id },
            })
            if (res?.success) {
                setPagination({
                    ...pagination,
                    next: res?.result.next,
                    count: res?.result.count,
                    previous: res?.result.previous,
                    number_of_pages: res?.result.number_of_pages,
                })
                setCountData(res?.result.count)
                setEstimateData(res?.result.result)
                setLoader(dispatch, Actions, false)
            } else {
                setLoader(dispatch, Actions, false)
            }
        }
        if (item_id && open) {
            getCustomerEstimate(item_id)
        }
        // eslint-disable-next-line
    }, [item_id, page, open]);

    return (
        estimateData.length ?
            <React.Fragment>
                <CustomTableContainer >
                    <CustomTable>
                        <CustomTableHead>
                            <CustomTableRow>
                                <CustomTableHeadCell style={{ width: 100 }} align='center'><span >Status</span></CustomTableHeadCell>
                                <CustomTableHeadCell ><span >Date</span></CustomTableHeadCell>
                                <CustomTableHeadCell ><span >Number</span></CustomTableHeadCell>
                                <CustomTableHeadCell ><span >Invoice#</span></CustomTableHeadCell>
                                <CustomTableHeadCell ><span >Customer</span></CustomTableHeadCell>
                                <CustomTableHeadCell align='right' ><span >Amount</span></CustomTableHeadCell>
                                <CustomTableHeadCell align='right' ><span >Balance</span></CustomTableHeadCell>
                            </CustomTableRow>
                        </CustomTableHead>
                        <CustomTableBody>
                            {
                                estimateData.map((item, index) => {
                                    return (
                                        <CustomTableRow key={index}>
                                            <CustomTableBodyCell sx={{}} align='center'><Status status={item.status} /></CustomTableBodyCell>
                                            <CustomTableBodyCell sx={{}}  ><span>{moment(item.credit_note_date).format(getDateFormat())}</span></CustomTableBodyCell>
                                            {/* <CustomTableBodyCell sx={{}}  ><span>{item.credit_note_number}</span></CustomTableBodyCell> */}
                                            <CustomTableBodyCell sx={{}}  ><CreditNoteViewLink id={item.id} title={item.credit_note_number} /></CustomTableBodyCell>
                                            <CustomTableBodyCell sx={{}}  ><span>{item.against_invoice_number}</span></CustomTableBodyCell>
                                            <CustomTableBodyCell sx={{}}  ><span>{item.customer_display_name}</span></CustomTableBodyCell>
                                            <CustomTableBodyCell sx={{}} align='right'>
                                                <span>{<CurrencyFormatter amount={item.total_amount} currency={item.currency_code} />}</span><br />
                                                <CurrencyFormatterWithExchangeRate currency_code={item.currency_code} amount={item.total_amount} exchange_rate={item.exchange_rate} />
                                            </CustomTableBodyCell>
                                            <CustomTableBodyCell sx={{}} align='right'>
                                                <span>{<CurrencyFormatter amount={item.credit_remaining} currency={item.currency_code} />}</span><br />
                                                <CurrencyFormatterWithExchangeRate currency_code={item.currency_code} amount={item.credit_remaining} exchange_rate={item.exchange_rate} />
                                            </CustomTableBodyCell>
                                        </CustomTableRow>
                                    )
                                })
                            }
                        </CustomTableBody>
                    </CustomTable>
                </CustomTableContainer>
                <CustomPagination
                    page={page}
                    count={pagination.number_of_pages}
                    onChange={(event, newValue) => setPage(newValue)}
                />
            </React.Fragment>
            : <NoTransactionComponent />

    )
}


const StockHistory = (props) => {
    const { item_id, dispatch, unit_code, refreshStockHistory } = props

    const [result, setResult] = React.useState([]);

    React.useEffect(() => {
        const getCustomerEstimate = async (item_id) => {
            // setLoader(dispatch, Actions, true)
            let res = await apiAction({
                method: 'get',
                url: get_stock_history(item_id, getBusinessInfo().id),
            })
            if (res?.success) {
                setResult(res?.result)
                setLoader(dispatch, Actions, false)
            } else {
                setLoader(dispatch, Actions, false)
            }
        }
        if (item_id) {
            getCustomerEstimate(item_id)
        }
        // eslint-disable-next-line
    }, [item_id, refreshStockHistory]);

    React.useEffect(()=>{
        AnalyticsEvent(eventsNames.categories.ITEMS,{action:eventsNames.actions.item.action.STOCK_HISTORY})
    },[])
    return (
        <React.Fragment>
            <CustomTableContainer sx={{}}>
                <CustomTable>
                    <CustomTableHead>
                        <CustomTableRow>
                            <CustomTableHeadCell sx={{ width: 120 }}><span >Date</span></CustomTableHeadCell>
                            <CustomTableHeadCell sx={{}}><span >Reason</span></CustomTableHeadCell>
                            <CustomTableHeadCell sx={{ width: 180 }} align='right' ><span >Quantity<span style={{ fontSize: '10px', paddingLeft: '5px' }}>( {unit_code} )</span></span></CustomTableHeadCell>
                        </CustomTableRow>
                    </CustomTableHead>
                    <CustomTableBody>
                        {
                            result.map((item, index) => {
                                return (
                                    <CustomTableRow key={index}>
                                        <CustomTableBodyCell sx={{}}  ><span>{moment(item.date).format(getDateFormat())}</span></CustomTableBodyCell>
                                        <CustomTableBodyCell sx={{}}  ><span>{item.reason}</span></CustomTableBodyCell>
                                        <CustomTableBodyCell sx={{}} align='right'><span>{formatterWithoutSymbol(null, item.quantity)}</span></CustomTableBodyCell>
                                    </CustomTableRow>
                                )
                            })
                        }
                        {
                            result.length ?
                                <CustomTableRow sx={{ background: '#FFF7DC', }}>
                                    <CustomTableBodyCell sx={{ borderTop: '1px solid #FFF0C0', borderBottom: '1px solid #FFF0C0' }}>
                                        <strong>Total Quantity</strong>
                                    </CustomTableBodyCell>
                                    <CustomTableBodyCell align='right' colSpan={4} sx={{ borderTop: '1px solid #FFF0C0', borderBottom: '1px solid #FFF0C0' }}>
                                        <span>{formatterWithoutSymbol(null, result.reduce((acc, item) => acc + item.quantity, 0))}</span>
                                    </CustomTableBodyCell>
                                </CustomTableRow>
                                : null
                        }

                    </CustomTableBody>
                </CustomTable>
            </CustomTableContainer>
        </React.Fragment>
    )
}


const ManageStock = (props) => {
    const { open, setOpen, productService, setProductService, getStockInfo } = props
    let location = useLocation();
    const params = getQueryParams(location.search)

    const stock_initial = {
        reason: null,
        is_stock_in: true,
        stock_adjustment_value: null,
        business_id: getBusinessInfo().id,
    }
    const [editable, setEditable] = React.useState(false);
    const dispatch = Actions.getDispatch(React.useContext);
    const [data, setData] = React.useState({ ...stock_initial });
    const [isFormSubmitted, setFormSubmitted] = React.useState(false);

    const onSave = async () => {
        setFormSubmitted(true)
        if (data.stock_adjustment_value === null) {
            stateChangeManager(dispatch, Actions, true, 'error', `Please Enter the ${data.is_stock_in ? 'Add to Stock' : 'Remove from Stock'}`)
        } else {
            AnalyticsEvent(eventsNames.categories.ITEMS,{action:eventsNames.actions.item.action.MANAGE_STOCK})
            let res = await apiAction({
                data: { ...data, reason: data.reason ? data.reason : 'Adjustment' },
                method: 'post',
                url: adjust_item_stock(params.item_id),
            })
            if (res?.success) {
                if (getStockInfo) {
                    getStockInfo()
                }
                setProductService({ ...productService, current_stock: res?.result });
                stateChangeManager(dispatch, Actions, true, 'success', res?.status);
                handleCancel()
            } else {
                stateChangeManager(dispatch, Actions, true, 'error', res?.status)
            }
        }
    }

    const handleCancel = () => {
        setOpen(false);
    };

    React.useEffect(() => {
        setFormSubmitted(false);
        setData({ ...stock_initial });
    }, [open])
    return (
        <div>
            <BootstrapDialog
                open={open}
                maxWidth='sm'
                fullWidth={true}
                id={'manage_dialog'}
                dataTestId={'manage_dialog'}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={() => setOpen(false)}>
                    <CustomTypography
                        text={'Manage Stock'}
                        sx={{
                            color: '#000000',
                            fontSize: '16px',
                            fontWeight: '700',
                            lineHeight: '18px',
                            fontStyle: 'normal',
                            fontFamily: "Noto Sans",
                        }}
                    />
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Box sx={{ p: 2, pt: 0, }}>
                        <CustomTypography
                            text={<span>Item Name: <span style={{ fontWeight: '500' }}>{productService.name}</span></span>}
                            sx={{
                                mb: 3,
                                color: '#000000',
                                fontSize: '14px',
                                fontWeight: '700',
                                lineHeight: '18px',
                                fontStyle: 'normal',
                                fontFamily: "Noto Sans",
                            }}
                        />
                        <CustomTypography
                            text={<span>Current Stock: <span style={{ fontWeight: '500' }}>{formatterWithoutSymbol(null, productService.current_stock)}</span></span>}
                            sx={{
                                mb: 2,
                                color: '#000000',
                                fontSize: '14px',
                                fontWeight: '700',
                                lineHeight: '18px',
                                fontStyle: 'normal',
                                fontFamily: "Noto Sans",
                            }}
                        />
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <RadioGroup row>
                                    <FormControlLabel
                                        id={'stock_in_radio'}
                                        dataTestId={'stock_in_radio'}
                                        label={<span style={{
                                            color: '#000000',
                                            fontSize: '14px',
                                            lineHeight: '18px',
                                            fontStyle: 'normal',
                                            fontFamily: "Noto Sans",
                                            textTransform: 'capitalize',
                                            fontWeight: data.is_stock_in ? '600' : '400',
                                        }}>Stock In</span>} control={<Radio sx={{ color: '#8F8F8F' }} checked={data.is_stock_in} onChange={(e) => { setData({ ...data, is_stock_in: e.target.checked }) }} />} />
                                    <FormControlLabel value="service"
                                        id={'stock_out_radio'}
                                        dataTestId={'stock_out_radio'}
                                        label={<span style={{
                                            color: '#000000',
                                            fontSize: '14px',
                                            lineHeight: '18px',
                                            fontStyle: 'normal',
                                            fontFamily: "Noto Sans",
                                            textTransform: 'capitalize',
                                            fontWeight: !data.is_stock_in ? '600' : '400',
                                        }}>Stock Out</span>} control={<Radio sx={{ color: '#8F8F8F' }} checked={!data.is_stock_in} onChange={(e) => { setData({ ...data, is_stock_in: !e.target.checked }) }} />} />
                                </RadioGroup>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <Input
                                    id={'input_stock_adjustment_value'}
                                    dataTestId={'input_stock_adjustment_value'}
                                    editable={editable}
                                    currency_code={null}
                                    isShowCommaSeparatedValue={true}
                                    onFocus={() => { setEditable(true) }}
                                    item={{
                                        type: 'number',
                                        required: true,
                                        fullWidth: true,
                                        placeholder: 'Eg. 100',
                                        validation: isFormSubmitted && (data.stock_adjustment_value === null),
                                        value: data.stock_adjustment_value !== null ? data.stock_adjustment_value : '',
                                        title: <LabelWithAsteriskMark label={data.is_stock_in ? 'Add to Stock' : 'Remove from Stock'} />,
                                        onChange: (e) => {
                                            if (isNumber(e.target.value)) {
                                                data.stock_adjustment_value = limitDecimalPlaces(e.target.value)
                                            } else {
                                                data.stock_adjustment_value = null;
                                            }
                                            setData({ ...data })
                                        },
                                        onBlur: () => { setEditable(false) }
                                    }}
                                    onKeyPress={(event) => {
                                        if (event?.key === '-' || event?.key === '+') {
                                            event.preventDefault();
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <CustomTypography
                                    text={<span>Final Stock: <span style={{ fontWeight: '500' }}>{data.is_stock_in ? formatterWithoutSymbol(null, (productService.current_stock + data.stock_adjustment_value)) : formatterWithoutSymbol(null, (productService.current_stock - data.stock_adjustment_value))}</span></span>}
                                    sx={{
                                        color: '#000000',
                                        fontSize: '14px',
                                        fontWeight: '700',
                                        lineHeight: '18px',
                                        fontStyle: 'normal',
                                        fontFamily: "Noto Sans",
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <Input
                                    id={'input_reason'}
                                    dataTestId={'input_reason'}
                                    item={{
                                        rows: 4,
                                        type: 'text',
                                        title: 'Reason',
                                        required: true,
                                        multiline: true,
                                        fullWidth: true,
                                        placeholder: 'Eg. Adjustment',
                                        value: data.reason ? data.reason : '',
                                        onChange: (e) => { setData({ ...data, reason: e.target.value }) },
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <div style={{ textAlign: 'end', marginTop: '24px' }}>
                            <CustomButton
                                id={'manage_cancel_btn'}
                                dataTestId={'manage_cancel_btn'}
                                btnLabel='Cancel'
                                variant='outlined'
                                sx={{ textTransform: 'none', '&:hover': { backgroundColor: '#e8f3ff' } }}
                                onClick={() => { handleCancel();}}
                            />
                            <CustomButton
                                id={'manage_save_btn'}
                                dataTestId={'manage_save_btn'}
                                btnLabel='Update'
                                variant='contained'
                                sx={{ ml: 2, textTransform: 'none', }}
                                onClick={() => { onSave()  }}
                            />
                        </div>
                    </Box>
                </DialogContent>
            </BootstrapDialog>
        </div>
    )
}

const StockOverview = (props) => {
    const { itemData, item_id, unit_code, stockInfo } = props


    return (
        stockInfo &&
        <Box sx={{ p: 2, border: '1px solid #FFF0C0', backgroundColor: "#FFF7DC" }}>
            <Grid container>
                {
                    stockInfo.map((item, index) => {
                        return (
                            <Grid item xs={3} key={index} sx={{
                                p: 1.5,
                                borderLeft: item.borderLeft,
                                borderRight: item.borderRight,
                            }}
                            >
                                <Box>
                                    <CustomTypography
                                        text={item.title}
                                        sx={{
                                            mb: 1,
                                            color: '#7A7A7A',
                                            fontSize: '14px',
                                            fontWeight: '600',
                                            lineHeight: '19px',
                                            fontStyle: 'normal',
                                            textTransform: 'none',
                                            fontFamily: "Noto Sans",
                                        }} />
                                    <CustomTypography
                                        text={<span>{item.value}<span style={{ color: '#A3A3A3', fontSize: '10px', paddingLeft: '5px' }}>( {unit_code} )</span></span>}
                                        sx={{
                                            mb: 1,
                                            color: '#000000',
                                            fontSize: '24px',
                                            fontWeight: '700',
                                            lineHeight: '28px',
                                            fontStyle: 'normal',
                                            textTransform: 'none',
                                            fontFamily: "Noto Sans",
                                        }}
                                    />
                                    {
                                        item.isData &&
                                        <CustomTypography
                                            text={<span>{item.text}: {moment(item.date).format(getDateFormat())}</span>}
                                            sx={{
                                                mt: 1,
                                                color: '#000000',
                                                fontSize: '12px',
                                                fontWeight: '700',
                                                lineHeight: '19px',
                                                fontStyle: 'normal',
                                                textTransform: 'none',
                                                fontFamily: "Noto Sans",
                                            }}
                                        />
                                    }
                                </Box>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </Box>
    )
}

const BasicInfo = (props) => {
    const { itemData } = props;
    return (
        <Box sx={{ borderBottom: '2px solid #F5F5F5' }}>
            <Box sx={{
                p: 2,
                pt: 0,
                display: 'flex',
                alignItems: 'center',
            }}>
                <Avatar >{itemData.name.charAt(0).toUpperCase()}</Avatar>
                <CustomTypography
                    text={<span>{itemData.name} {itemData.status === '-' ? '' : <Status sx={{ ml: 1 }} status={itemData.status} />}</span>}
                    sx={{
                        ml: 2,
                        mb: -.5,
                        color: '#000000',
                        fontSize: '14px',
                        fontWeight: '700',
                        lineHeight: '18px',
                        fontStyle: 'normal',
                        fontFamily: "Noto Sans",
                    }}
                />
            </Box>
        </Box>
    )
}