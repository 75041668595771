import React from 'react';
import Setup from './Setup';
import { useNavigate } from 'react-router-dom';
import { apiAction } from '../../../../api/api';
import * as Actions from '../../../../state/Actions';
import CustomTitle from '../../../common/CustomTitle';
import ViewAdjustedAccount from './ViewAdjustedAccount';
import { routesName } from '../../../../config/routesName';
import CustomButton from '../../../custom/button/CustomButton';
import CustomDialog from '../../../custom/dialog/CustomDialog';
import CurrencyFormatter from '../../../common/CurrencyFormatter';
import { setLoader, stateChangeManager } from '../../../../utils/Utils';
import CustomTypography from '../../../custom/typography/CustomTypography';
import CreateOpeningBalanceAdjustment from './CreateOpeningBalanceAdjustment';
import { getBusinessInfo, setBusinessInfo } from '../../../../config/cookiesInfo';
import { BootstrapDialog } from '../../bulkStatementUpload/mappings/Transactions';
import { BootstrapDialogTitle } from '../../bulkStatementUpload/mappings/Transactions';

import {
  CustomContainer,
  CustomTitleContainer,
  CustomButtonContainer,
} from '../../../custom/container/CustomContainer';

import {
  groupByAutoForView,
  groupByAutoForSetup,
  groupByAutoForCreate
} from './helper';
import {
  delete_opening_balance,
  validate_migration_date,
  manual_opening_balance_adjustment,
  chart_of_account_for_opening_balance
} from '../../../../api/urls';

import {
  Box,
  Grid,
  Typography,
  DialogActions,
  DialogContent,
} from '@mui/material';
import OpeningBalanceMapping from './OpeningBalanceFileMapping';
import ImportOpeningBalanceFile from './ImportOpeningBalanceFile';
import OpeningBalanceMappingPreview from './OpeningBalanceMappingPreview';




const OpeningBalanceAdjustment = (props) => {
  const {initial_state}=props;
  React.useEffect(()=>{setCondition('view')},[initial_state])
  
  const navigate = useNavigate();


  const initial_opening_balance_adjustment_data = {
    transactions: [],
    transaction_amount: 0,
    business_id: getBusinessInfo().id,
    migration_date: getBusinessInfo().migration_date,
  }

  const [results, setResults] = React.useState([]);
  const [accounts, setAccounts] = React.useState([]);
  const dispatch = Actions.getDispatch(React.useContext);
  const [condition, setCondition] = React.useState('view');
  const [migrationDate, setMigrationDate] = React.useState();
  const [filterResults, setFilterResults] = React.useState([]);
  const [migrationData, setMigrationData] = React.useState({ ...initial_opening_balance_adjustment_data });
  const [validation, setValidation] = React.useState({
    total_amount: 0,
    adjusted_amount: 0,
  })

  const [state, setState] = React.useState({
    id: '',
    title: '',
    open: false,
    condition: '',
    maxWidth: 'lg',
    fullWidth: true,
  })
  const stateChangeHandler = (title, condition, maxWidth, url, deleteMessage) => {
    setState({
      url: url,
      open: true,
      title: title,
      fullWidth: true,
      maxWidth: maxWidth,
      condition: condition,
      deleteMessage: deleteMessage,
    })
  }
  const getApiResults = async () => {
    setLoader(dispatch, Actions, true)
    let migrationDataRes = await apiAction({
      method: 'post',
      navigate:navigate,
      dispatch:dispatch,
      url: validate_migration_date(),
      data: { business_id: getBusinessInfo().id, },
      onError: (error) => {
        setLoader(dispatch, Actions, false);
      }
    })
    if (migrationDataRes.success) {
      setMigrationDate(migrationDataRes.result);
      setMigrationData({ ...migrationData, migration_date: getBusinessInfo() ? getBusinessInfo().migration_date : migrationDataRes.result.previous_migration_date });
      let res = await apiAction({
        method: 'post',
        navigate:navigate,
        dispatch:dispatch,
        url: chart_of_account_for_opening_balance(),
        data: {
          business_id: getBusinessInfo().id,
          //  migration_date: migrationData.migration_date 
        },
        onError: (error) => {
          setLoader(dispatch, Actions, false);
        }
      })

      if (res?.success) {
        setAccounts(res?.result)
        setLoader(dispatch, Actions, false);
        setResults(groupByAutoForView([...res?.result], 'account_type'));
        setFilterResults(groupByAutoForSetup([...res?.result], 'account_type'));
        if (groupByAutoForView([...res?.result], 'account_type').length === 0) { setCondition('setup') };
      } else {

      }
    }
  }

  React.useEffect(() => {
    if (!state.open&&condition==='view') {
      getApiResults()
    }
    // eslint-disable-next-line
  }, [state.open,condition])

  const [open, setOpen] = React.useState(false);

  const onConfirmed = async () => {
    setLoader(dispatch, Actions, true)
    let res = await apiAction({
      method: 'post',
      navigate:navigate,
      dispatch:dispatch,
      data: { ...migrationData },
      url: manual_opening_balance_adjustment(),
      onError: (error) => {
        setLoader(dispatch, Actions, false);
      }
    })

    if (res?.success) {
      setCondition('view');
      setLoader(dispatch, Actions, false);
      // navigate(routesName.openingBalanceAdjustment.path);
      stateChangeManager(dispatch, Actions, true, 'success', res?.status);
      if (migrationData.migration_date!==getBusinessInfo().migration_date) {
        setBusinessInfo({...getBusinessInfo(),migration_date:migrationData.migration_date})  
    }
    } else {
      setLoader(dispatch, Actions, false);
      stateChangeManager(dispatch, Actions, true, 'error', res?.status);
    }
  }

  return (
    condition==='file_upload'?
    <ImportOpeningBalanceFile nextButton={()=>{setCondition('mapping_file')}}/>:
    condition==='mapping_file'?
    <OpeningBalanceMapping nextButton={()=>{setCondition('mapping_preview')}}/>:
    condition==='mapping_preview'?
    <OpeningBalanceMappingPreview onErrorButton={()=>{setCondition('file_upload')}} onCancelButton={()=>{setCondition('mapping_file')}} nextButton={()=>{setCondition('view')}}/>
    :
    <div>
      <CustomizedDialogs
        open={open}
        setOpen={setOpen}
        adjusted_amount={validation.adjusted_amount}
        statementUpload={() => setCondition('create')}
      />
      <CustomDialog
        state={state}
        setState={setState}
      />
      <CustomTitleContainer>
        <Grid container spacing={0} style={{
          alignItems: 'center',
          justifyItems: 'center',
          alignContent: 'space-evenly',
          justifyContent: 'space-evenly',
        }}>
          <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'start', }}>
            <CustomTitle title={'Opening Balances'} />
          </Grid>
          <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'end', }}>
            {
              condition === 'setup' ?
                <CustomButton
                  id={'import_btn'}
                  dataTestId={'import_btn'}
                  variant="contained"
                  sx={{ textTransform: 'none', }}
                  btnLabel='Import Opening Balances'
                  onClick={() => {
                    Actions.resetFileImports(dispatch)
                    setCondition('file_upload')
                    // navigate(routesName.openingBalanceImport.path)
                    dispatch(Actions.stateChange("migration_date", migrationData.migration_date))
                  }}
                />
                :
                null
            }

            {
              condition === 'view' ?
                <>
                  <CustomButton
                    id={'edit_btn'}
                    dataTestId={'edit_btn'}
                    btnLabel={'Edit'}
                    variant="contained"
                    sx={{ ml: 1, textTransform: 'none', }}
                    onClick={() => { setCondition('setup') }}
                  />
                  <CustomButton
                    id={'delete_btn'}
                    dataTestId={'delete_btn'}
                    variant="contained"
                    btnLabel={'Delete'}
                    sx={{ ml: 1, textTransform: 'none', }}
                    onClick={() => { stateChangeHandler('Delete Opening Balances', 'delete', 'sm', delete_opening_balance(getBusinessInfo().id), `Do you want to delete the entered opening balances?`) }}
                  />
                </>
                : null
            }
          </Grid>

        </Grid>
      </CustomTitleContainer>

      {
        condition === 'view' ?
          <CustomContainer maxWidth={"400"} sx={{height: 'calc(100vh - 134px)'}}>
            <ViewAdjustedAccount results={[...results]} migrationDate={migrationDate} />
          </CustomContainer>
          : condition === 'setup' ?
            <CustomContainer maxWidth={"400"} sx={{height: 'calc(100vh - 202px)',}}>
              <Setup migrationDate={migrationDate} validation={validation} setValidation={setValidation} migrationData={migrationData} setMigrationData={setMigrationData} filterResults={[...filterResults]} setFilterResults={setFilterResults} accounts={accounts} setAccounts={setAccounts} />
            </CustomContainer>

            : condition === 'create' ?
              <CustomContainer maxWidth={"400"} sx={{}}>
                <CreateOpeningBalanceAdjustment results={groupByAutoForCreate([...accounts], 'account_type')} migrationData={migrationData} setMigrationData={setMigrationData} />
              </CustomContainer>

              : null
      }

      {condition === 'setup' || condition === 'create' ?
        <CustomButtonContainer>
          <Buttons
            updateMigrationDate={() => {
              if (validation.total_amount) {
                if (validation.adjusted_amount) {
                  setOpen(true);
                } else {
                  setCondition('create');
                }
              } else {
                stateChangeManager(dispatch, Actions, true, 'error', `Please enter the opening balances for the accounts to continue.`);
              }
            }
            } condition={condition} setCondition={setCondition} onCancel={() => setCondition('view')} onConfirmed={() => onConfirmed()} hideCancelBtn={results.length?true:false}/>
        </CustomButtonContainer>

        : null
      }
    </div>
  )
}

export default OpeningBalanceAdjustment;

const Buttons = (props) => {
  const {hideCancelBtn, updateMigrationDate, condition, setCondition, onCancel, onConfirmed } = props;
  return (
    <Box component="form" noValidate  >
      <Grid container>
        <Grid item xs={4} sx={{ display: '-webkit-flex', justifyContent: 'start', }}>
          {hideCancelBtn&&
          <CustomButton id={'cancel_btn'} dataTestId={'cancel_btn'} variant='outlined' sx={{ textTransform: 'none', '&:hover': { backgroundColor: '#e8f3ff' } }} btnLabel='Cancel' onClick={() => { onCancel() }} />
          }
        </Grid>
        <Grid item xs={8} sx={{ display: '-webkit-flex', justifyContent: 'end', }}>
          {condition === 'setup' ?
            <CustomButton id={'continue_btn'} dataTestId={'continue_btn'} variant='contained' sx={{ textTransform: 'none', }} btnLabel={'Continue'} onClick={() => { updateMigrationDate(); }} />
            :
            <>
              <CustomButton id={'setup_btn'} dataTestId={'setup_btn'} variant='contained' sx={{ textTransform: 'none', }} btnLabel={'Back'} onClick={() => { setCondition('setup') }} />
              <CustomButton id={'confirm_btn'} dataTestId={'confirm_btn'} variant='contained' sx={{ ml: 1, textTransform: 'none', }} btnLabel={'Confirm'} onClick={() => { onConfirmed() }} />
            </>
          }
        </Grid>
      </Grid>
    </Box>
  )
}

const CustomizedDialogs = (props) => {
  const { open, setOpen, statementUpload, adjusted_amount } = props

  const handleClicked = () => {
    statementUpload();
    setOpen(false);
  };
  return (
    <div>
      <BootstrapDialog
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={() => setOpen(false)}>
          <CustomTypography
            text={'Note'}
            sx={{
              color: '#000000',
              fontSize: '16px',
              fontWeight: '700',
              lineHeight: '18px',
              fontStyle: 'normal',
              fontFamily: "Noto Sans",
            }}
          />
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom sx={{
            opacity: 0.8,
            wordSpacing: 2,
            lineHeight: 1.5,
            color: '#000000',
            fontSize: '14px',
            fontWeight: '700',
            fontStyle: 'normal',
            fontFamily: "Noto Sans",
          }}
          >
            The total debits and credits differ by {<CurrencyFormatter amount={adjusted_amount} />}. You can go back and adjust the balances to remove the difference, or you can continue and the difference will be transferred to the Opening Balance Adjustment account.
          </Typography>

          <div style={{ textAlign: 'center', marginTop: '24px' }}>
            <CustomButton
            id={'go_back_btn'} 
            dataTestId={'go_back_btn'}
              variant='outlined'
              btnLabel='Go Back'
              onClick={() => { setOpen(false); }}
              sx={{ textTransform: 'none', mr: 1, pl: 2, pr: 2, '&:hover': { backgroundColor: '#e8f3ff' } }}
            />
            <CustomButton
             id={'go_continue_btn'} 
             dataTestId={'go_continue_btn'}
              variant='contained'
              btnLabel='Continue'
              onClick={() => handleClicked()}
              sx={{ textTransform: 'none', mr: 2, pl: 2, pr: 2, }}
            />
          </div>

          <DialogActions>
          </DialogActions>
        </DialogContent>
      </BootstrapDialog>
    </div>
  )
}
