import { styled } from '@mui/material';
import { routesName } from '../config/routesName';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const journalEntryKeys = {
  debit: 'debit',
  credit: 'credit',
  account: 'account',
  debitDisable: 'debitDisable',
  creditDisable: 'creditDisable'
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const accountTypeChoice = [
  { name: 'ASSET', value: 'ASSET' },
  { name: 'LIABILITY', value: 'LIABILITY' },
  { name: 'EQUITY', value: 'EQUITY' },
  { name: 'INCOME', value: 'INCOME' },
  { name: 'EXPENSE', value: 'EXPENSE' },
]
export const genderTypeChoice = [
  { name: 'Male', value: 'male' },
  { name: 'Female', value: 'female' },
  { name: 'Other', value: 'other' },
]
export const deliveryChallanTypeChoice = [
  { name: 'Supply of Liquid Gas', value: 'supply_of_liquid_gas' },
  { name: 'Job Work', value: 'job_work' },
  { name: 'Supply on Approval', value: 'supply_on_approval' },
  { name: 'Others', value: 'others' },

]
export const creditNoteReasonChoice = [
  { name: 'Sales Return', value: 'sales_return' },
  { name: 'Post Sale Discount', value: 'post_sale_discount' },
  { name: 'Deficiency in Service', value: 'deficiency_in_service' },
  { name: 'Correction in Invoice', value: 'correction_in_invoice' },
  { name: 'Change in POS', value: 'change_in_pos' },
  { name: 'Finalization of Provisional Assessment', value: 'finalization_of_provisional_assessment' },
  { name: 'Other', value: 'other' },
]
export const debitNoteReasonChoice = [
  { name: 'Purchase Return', value: 'purchase_return' },
  { name: 'Post Purchase Discount', value: 'post_purchase_discount' },
  { name: 'Deficiency in Service', value: 'deficiency_in_service' },
  { name: 'Correction in Bill', value: 'correction_in_bill' },
  { name: 'Change in POS', value: 'change_in_pos' },
  { name: 'Finalization of Provisional Assessment', value: 'finalization_of_provisional_assessment' },
  { name: 'Other', value: 'other' },
]
export const input_css_style = { color: '#000000', fontWeight: '700', lineHeight: '16px', fontSize: '12px', fontStyle: 'normal', fontFamily: 'Noto Sans', }

export const screenDetails = [
  { name: 'Estimate', value: 'estimate' },
  { name: 'Sales Order', value: 'sales_order' },
  { name: 'Invoice', value: 'invoice' },
  { name: 'Payment', value: 'payment' },
  { name: 'Credit Note', value: 'credit_note' },
  { name: 'Purchase Orders', value: 'purchase_order' },
  { name: 'Debit Note', value: 'debit_note' },
]
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const GSTRegistrationTypeChoice = [
  { helperText: `Business that is registered under GST`, name: `GST registered- Regular`, value: 'gst_registered_regular' },
  { helperText: `Business that is registered under the Composition Scheme in GST`, name: `GST registered- Composition`, value: 'gst_registered_composition' },
  { helperText: `Business that has not been registered under GST`, name: `GST unregistered`, value: 'gst_unregistered' },
  { helperText: `A customer who is a regular consumer`, name: `Consumer`, value: 'consumer' },
  { helperText: `Persons with whom you do import or export of supplies outside India`, name: `Overseas`, value: 'overseas' },
  { helperText: `Business (Unit) that is located in a Special Economic Zone (SEZ) of India or a SEZ Developer`, name: `SEZ`, value: 'sez' },
  { helperText: `Supply of goods to an Export Oriented Unit  or against Advanced Authorization/Export Promotion Capital Goods.`, name: `Deemed exports- EOU's, STP's, EHTP's etc`, value: 'deemed_exports' },
]

export const GSTTaxRateChoice = [
  { name: `0 %`, value: 0 },
  // { name: `0.1 %`, value: 0.1 },
  { name: `0.25 %`, value: 0.25 },
  // { name: `1 %`, value: 1 },
  // { name: `1.5 %`, value: 1.5 },
  { name: `3 %`, value: 3 },
  { name: `5 %`, value: 5 },
  { name: `6 %`, value: 6 },
  // { name: `7.5 %`, value: 7.5 },
  { name: `12 %`, value: 12 },
  { name: `18 %`, value: 18 },
  { name: `28 %`, value: 28 },
]

export const listPaymentMethod = [
  {
    name: 'Cash',
    value: 'cash',
  },
  {
    name: 'Cheque',
    value: 'cheque',
  },
  {
    name: 'Card',
    value: 'card',
  },
  {
    name: 'UPI',
    value: 'upi',
  },
  {
    name: 'Net Banking',
    value: 'net_banking',
  },
]

export const listPaymentMethodToDisplay = [
  {
    name: 'Cash',
    value: 'cash',
  },
  {
    name: 'Cheque',
    value: 'cheque',
  },
  {
    name: 'Card',
    value: 'card',
  },
  {
    name: 'UPI',
    value: 'upi',
  },
  {
    name: 'Net Banking',
    value: 'net_banking',
  },
  {
    name: 'Debit Note',
    value: 'debit_note',
  },
  {
    name: 'Credit Note',
    value: 'credit_note',
  },
]

/////////////////////////////////////////////////////////////////images////////////////////////////////////////////////////////////

export const images = {

  appLogo: { src: require('../assets/image/appLogo.png'), alt: 'appLogo.png' },
  landing_feature_reports: { src: require('../assets/image/landing_feature_reports.png'), alt: 'landing_feature_reports.png' },
  landing_feature_export: { src: require('../assets/image/landing_feature_export.png'), alt: 'landing_feature_export.png' },
  landing_feature_gst: { src: require('../assets/image/landing_feature_gst.png'), alt: 'landing_feature_gst.png' },
  landing_feature: { src: require('../assets/image/landing_feature.png'), alt: 'landing_feature.png' },
  landing_feature_supports: { src: require('../assets/image/landing_feature_supports.png'), alt: 'landing_feature_supports.png' },
  landing_feature_account: { src: require('../assets/image/landing_feature_account.png'), alt: 'landing_feature_account.png' },
  landing_feature_share: { src: require('../assets/image/landing_feature_share.png'), alt: 'landing_feature_share.png' },
  landing_feature_upload: { src: require('../assets/image/landing_feature_upload.png'), alt: 'landing_feature_upload.png' },
  landing_feature_role: { src: require('../assets/image/landing_feature_role.png'), alt: 'landing_feature_role.png' },
  landing_feature_currency: { src: require('../assets/image/landing_feature_currency.png'), alt: 'landing_feature_currency.png' },
  landing_feature_business: { src: require('../assets/image/landing_feature_business.png'), alt: 'landing_feature_business.png' },
  landing_feature_contact: { src: require('../assets/image/landing_feature_contact.png'), alt: 'landing_feature_contact.png' },
  landing_feature_invoice: { src: require('../assets/image/landing_feature_invoice.png'), alt: 'landing_feature_invoice.png' },
  landing_feature_expense: { src: require('../assets/image/landing_feature_expense.png'), alt: 'landing_feature_expense.png' },
  landing_feature_inventory: { src: require('../assets/image/landing_feature_inventory.png'), alt: 'landing_feature_inventory.png' },


  landing_feature_dashboard: { src: require('../assets/image/landing_feature_dashboard.png'), alt: 'landing_feature_dashboard.png' },


  landing_accuracy: { src: require('../assets/image/landing_accuracy.png'), alt: 'landing_accuracy.png' },
  landing_time_saving: { src: require('../assets/image/landing_time_saving.png'), alt: 'landing_time_saving.png' },
  landing_accessible: { src: require('../assets/image/landing_accessible.png'), alt: 'landing_accessible.png' },
  landing_user_friendly_interface: { src: require('../assets/image/landing_user_friendly_interface.png'), alt: 'landing_user_friendly_interface.png' },
  landing_affordable_pricing: { src: require('../assets/image/landing_affordable_pricing.png'), alt: 'landing_affordable_pricing.png' },


  bar_code_image: { src: require('../assets/image/barcode.png'), alt: 'barcode.png' },
  profile_logo: { src: require('../assets/image/profile_logo.png'), alt: 'profile_logo.png' },
  landing_switch: { src: require('../assets/image/landing_switch.png'), alt: 'landing_switch.png' },
  landing_banner: { src: require('../assets/image/landing_banner_1.png'), alt: 'landing_banner_1.png' },
  landing_analysis: { src: require('../assets/image/landing_analysis.png'), alt: 'landing_analysis.png' },
  landing_platform: { src: require('../assets/image/landing_platform.png'), alt: 'landing_platform.png' },
  landing_contact_us: { src: require('../assets/image/landing_contact_us.png'), alt: 'landing_contact_us.png' },
  // landing_platform_img_1: { src: require('../assets/image/landing_platform_img_1.png'), alt: 'landing_platform_img_1.png' },
  // landing_platform_img_2: { src: require('../assets/image/landing_platform_img_2.png'), alt: 'landing_platform_img_2.png' },

  landing_analysisDetails_all: { src: require('../assets/image/landing_analysisDetails_all.png'), alt: 'landing_analysisDetails_all.png' },
  landing_analysisDetails_big: { src: require('../assets/image/landing_analysisDetails_big.png'), alt: 'landing_analysisDetails_big.png' },
  landing_analysisDetails_cloud: { src: require('../assets/image/landing_analysisDetails_cloud.png'), alt: 'landing_analysisDetails_cloud.png' },
  landing_analysisDetails_money: { src: require('../assets/image/landing_analysisDetails_money.png'), alt: 'landing_analysisDetails_money.png' },
  landing_analysisDetails_group: { src: require('../assets/image/landing_analysisDetails_group.png'), alt: 'landing_analysisDetails_group.png' },

  signup: { src: require('../assets/image/signUp.png'), alt: 'signUp.png' },
  tallyLogo: { src: require('../assets/image/tally-logo.png'), alt: 'tally-logo.png' },
  profile: { src: require('../assets/image/profile.png'), alt: 'profile.png' },
  login: { src: require('../assets/image/loginpage.png'), alt: 'loginpage.png' },
  openEmail: { src: require('../assets/image/open_email.png'), alt: 'open_email.png' },
  stateMent_upload: { src: require('../assets/image/undraw_update_re_swkp 1@3x.png'), message: { text1: 'No data found', text2: 'Data will be visible here once you upload the file.' } },
  list_source_transaction: { src: require('../assets/image/undraw_credit_card_payments_re_qboh 1@3x.png'), message: { text1: 'No transactions yet', text2: 'After your first transaction you will be able to view it here.' } },

  list_view: { src: require('../assets/image/list_view.png'), alt: 'list_view.png' },
  bar_graph: { src: require('../assets/image/bar_graph.png'), alt: 'bar_graph.png' },
  pie_chart: { src: require('../assets/image/pie_chart.png'), alt: 'pie_chart.png' },
  line_chart: { src: require('../assets/image/line_chart.png'), alt: 'line_chart.png' },
  scatter_chart: { src: require('../assets/image/scatter_chart.png'), alt: 'scatter_chart.png' },
  deviation_chart: { src: require('../assets/image/deviation_chart.png'), alt: 'deviation_chart.png' },
}
export const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '98%',
  maxHeight: '98%',
  alignItems: 'center',

});
export const opening_balance = "opening_balance"

export const sourced_transaction = "sourced_transactions"

export const opening_balance_adjustments = "Opening Balance Adjustments"

export const party = "party"

export const items = "item"

export const vendor = "vendor"

export const customer = "customer"

export const expense_number = 'EXP'
export const recurring_expense_number = 'RECUR-EXP'
export const recurring_invoice_number = 'RECUR-INV'

export const payment_made_number = 'PY-PAY'
export const payment_received_number = 'PY-RECEIVE'

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const locale_dict_currency = {
  "INR": "en_IN",
  "RUB": "ru_RU",
  "GBP": "en_GB",
  "USD": "en_US",
  "AZN": "az_AZ",
  "AUD": "en_AU",
  "AWG": "nl_AW",
  "ARS": "es_AR",
  "AFN": "ps_AF",
  "ALL": "sq_AL",
  "BRL": "pt_BR",
  "BGN": "bg_BG",
  "BWP": "en_BW",
  "BAM": "bs_BA",
  "BOB": "es_BO",
  "BMD": "en_BM",
  "BZD": "en_BZ",
  "BYR": "be_BY",
  "BBD": "en_BB",
  "BSD": "en_BS",
  "CUP": "es_CU",
  "HRK": "hr_HR",
  "CRC": "es_CR",
  "COP": "es_CO",
  "CNY": "zh_CN",
  "CLP": "es_CL",
  "KYD": "en_KY",
  "CAD": "en_CA",
  "KHR": "km_KH",
  "BND": "ms_Latn_BN",
  "GHS": "ak_GH",
  "FJD": "en_FJ",
  "FKP": "en_FK",
  "EUR": "de_DE",
  "SVC": "es_SV",
  "EGP": "ar_EG",
  "XCD": "en_AG",
  "DOP": "es_DO",
  "DKK": "da_DK",
  "CZK": "cs_CZ",
  "LAK": "lo_LA",
  "KGS": "ky_KG",
  "KRW": "ko_KR",
  "KPW": "ko_KP",
  "KZT": "kk_KZ",
  "JEP": "en_JE",
  "JPY": "ja_JP",
  "JMD": "en_JM",
  "ILS": "he_IL",
  "IMN": "gv_IM",
  "IRR": "fa_IR",
  "IDR": "id_ID",
  "ISK": "is_IS",
  "HUF": "hu_HU",
  "HKD": "zh_HK",
  "HNL": "es_HN",
  "GYD": "en_GY",
  "GBP": "en_GG",
  "GTQ": "es_GT",
  "GIP": "en_GI",
  "PYG": "es_PY",
  "PAB": "es_PA",
  "PKR": "ur_PK",
  "OMR": "ar_OM",
  "NOK": "nb_NO",
  "NGN": "en_NG",
  "NIO": "es_NI",
  "NZD": "en_NZ",
  "ANG": "nl_SX",
  "NPR": "ne_NP",
  "NAD": "naq_NA",
  "MZN": "mgh_MZ",
  "MAD": "ar_MA",
  "MNT": "mn_MN",
  "MXN": "es_MX",
  "MUR": "mfe_MU",
  "MYR": "ms_MY",
  "MKD": "mk_MK",
  "LRD": "vai_Vaii_LR",
  "LBP": "ar_LB",
  "TWD": "zh_TW",
  "SYP": "ar_SY",
  "SRD": "nl_SR",
  "CHF": "de_CH",
  "SEK": "sv_SE",
  "LKR": "si_LK",
  "ZAR": "en_ZA",
  "SOS": "so_SO",
  "SBD": "en_SB",
  "SGD": "zh_SG",
  "SCR": "en_SC",
  "RSD": "sr_RS",
  "SAR": "ar_SA",
  "SHP": "en_SH",
  "RON": "ro_RO",
  "QAR": "ar_QA",
  "PLN": "pl_PL",
  "PHP": "fil_PH",
  "PEN": "es_PE",
  "ZWD": "nd_ZW",
  "YER": "ar_YE",
  "VND": "vi_VN",
  "VEF": "es_VE",
  "UZS": "uz_UZ",
  "UYU": "es_UY",
  "AED": "ar_AE",
  "UAH": "ru_UA",
  "TVD": "en_TV",
  "TRY": "tr_TR",
  "TTD": "en_TT",
  "THB": "th_TH"
}

////////////////////////////////////////////////////////Currency data List////////////////////////////////////////////////////////////////////////

export const currencyData = [
  {
    "name": "UAE-Dirham",
    "currency_code": "AED",
    "symbol": " د.إ"
  },
  {
    "name": "Afghanistan Afghani",
    "currency_code": "AFN",
    "symbol": "؋"
  },
  {
    "name": "Albania Lek",
    "currency_code": "ALL",
    "symbol": "Lek"
  },
  {
    "name": "Netherlands Antilles Guilder",
    "currency_code": "ANG",
    "symbol": "ƒ"
  },
  {
    "name": "Argentine Peso",
    "currency_code": "ARS",
    "symbol": "$"
  },
  {
    "name": "Australian Dollar",
    "currency_code": "AUD",
    "symbol": "$"
  },
  {
    "name": "Aruba Guilder",
    "currency_code": "AWG",
    "symbol": "ƒ"
  },
  {
    "name": "Azerbaijan Manat",
    "currency_code": "AZN",
    "symbol": "₼"
  },
  {
    "name": "Bosnia and Herzegovina Convertible Mark",
    "currency_code": "BAM",
    "symbol": "KM"
  },
  {
    "name": "Barbados Dollar",
    "currency_code": "BBD",
    "symbol": "$"
  },
  {
    "name": "Bulgarian Lev",
    "currency_code": "BGN",
    "symbol": "лв."
  },
  {
    "name": "Bermudian Dollar",
    "currency_code": "BMD",
    "symbol": "$"
  },
  {
    "name": "Brunei Darussalam Dollar",
    "currency_code": "BND",
    "symbol": "$"
  },
  {
    "name": "Bolivia Bolíviano",
    "currency_code": "BOB",
    "symbol": "$b"
  },
  {
    "name": "Brazilian Real",
    "currency_code": "BRL",
    "symbol": "R$"
  },
  {
    "name": "Bahamas Dollar",
    "currency_code": "BSD",
    "symbol": "$"
  },
  {
    "name": "Botswana Pula",
    "currency_code": "BWP",
    "symbol": "P"
  },
  {
    "name": "Belarus Ruble",
    "currency_code": "BYN",
    "symbol": "Br"
  },
  {
    "name": "Belize Dollar",
    "currency_code": "BZD",
    "symbol": "BZ$"
  },
  {
    "name": "Canadian Dollar",
    "currency_code": "CAD",
    "symbol": "$"
  },
  {
    "name": "Switzerland Franc",
    "currency_code": "CHF",
    "symbol": "CHF"
  },
  {
    "name": "Chilean Peso",
    "currency_code": "CLP",
    "symbol": "$"
  },
  {
    "name": "China Yuan Renminbi",
    "currency_code": "CNY",
    "symbol": "¥"
  },
  {
    "name": "Colombian Peso",
    "currency_code": "COP",
    "symbol": "$"
  },
  {
    "name": "Costa Rica Colon",
    "currency_code": "CRC",
    "symbol": "₡"
  },
  {
    "name": "Cuba Peso",
    "currency_code": "CUP",
    "symbol": "₱"
  },
  {
    "name": "Czech Koruna",
    "currency_code": "CZK",
    "symbol": "Kč"
  },
  {
    "name": "Danish Krona",
    "currency_code": "DKK",
    "symbol": "kr"
  },
  {
    "name": "Dominican Republic Peso",
    "currency_code": "DOP",
    "symbol": "RD$"
  },
  {
    "name": "Egyptian Pound",
    "currency_code": "EGP",
    "symbol": "£"
  },
  {
    "name": "Euro Member Countries",
    "currency_code": "EUR",
    "symbol": "€"
  },
  {
    "name": "Fiji Dollar",
    "currency_code": "FJD",
    "symbol": "$"
  },
  {
    "name": "Falkland Islands (Malvinas) Pound",
    "currency_code": "FKP",
    "symbol": "£"
  },
  {
    "name": "Pound sterling",
    "currency_code": "GBP",
    "symbol": "£"
  },
  {
    "name": "Guernsey Pound",
    "currency_code": "GGP",
    "symbol": "£"
  },
  {
    "name": "Ghana Cedi",
    "currency_code": "GHS",
    "symbol": "¢"
  },
  {
    "name": "Gibraltar Pound",
    "currency_code": "GIP",
    "symbol": "£"
  },
  {
    "name": "Guatemala Quetzal",
    "currency_code": "GTQ",
    "symbol": "Q"
  },
  {
    "name": "Guyana Dollar",
    "currency_code": "GYD",
    "symbol": "$"
  },
  {
    "name": "Hong Kong Dollar",
    "currency_code": "HKD",
    "symbol": "圓"
  },
  {
    "name": "Honduras Lempira",
    "currency_code": "HNL",
    "symbol": "L"
  },
  {
    "name": "Croatian kuna",
    "currency_code": "HRK",
    "symbol": "kn"
  },
  {
    "name": "Hungarian Forint",
    "currency_code": "HUF",
    "symbol": "Ft"
  },
  {
    "name": "Indonesian Rupiah",
    "currency_code": "IDR",
    "symbol": "Rp"
  },
  {
    "name": "Israeli Shekel",
    "currency_code": "ILS",
    "symbol": "₪"
  },
  {
    "name": "Isle of Man Pound",
    "currency_code": "IMP",
    "symbol": "£"
  },
  {
    "name": "Indian rupee",
    "currency_code": "INR",
    "symbol": "₹"
  },
  {
    "name": "Iran Rial",
    "currency_code": "IRR",
    "symbol": "﷼"
  },
  {
    "name": "Iceland Krona",
    "currency_code": "ISK",
    "symbol": "kr"
  },
  {
    "name": "Jersey Pound",
    "currency_code": "JEP",
    "symbol": "£"
  },
  {
    "name": "Jamaican Dollar",
    "currency_code": "JMD",
    "symbol": "$"
  },
  {
    "name": "Japanese Yen",
    "currency_code": "JPY",
    "symbol": "¥"
  },
  {
    "name": "Kyrgyzstan Som",
    "currency_code": "KGS",
    "symbol": "лв"
  },
  {
    "name": "Cambodia Riel",
    "currency_code": "KHR",
    "symbol": "៛"
  },
  {
    "name": "Korea (North) Won",
    "currency_code": "KPW",
    "symbol": "₩"
  },
  {
    "name": "South Korean won",
    "currency_code": "KRW",
    "symbol": "₩"
  },
  {
    "name": "Cayman Islands Dollar",
    "currency_code": "KYD",
    "symbol": "$"
  },
  {
    "name": "Tenge",
    "currency_code": "KZT",
    "symbol": "₸"
  },
  {
    "name": "Laos Kip",
    "currency_code": "LAK",
    "symbol": "₭"
  },
  {
    "name": "Lebanon Pound",
    "currency_code": "LBP",
    "symbol": "£"
  },
  {
    "name": "Sri Lanka Rupee",
    "currency_code": "LKR",
    "symbol": "₨"
  },
  {
    "name": "Liberia Dollar",
    "currency_code": "LRD",
    "symbol": "$"
  },
  {
    "name": "Macedonia Denar",
    "currency_code": "MKD",
    "symbol": "ден"
  },
  {
    "name": "Mongolia Tughrik",
    "currency_code": "MNT",
    "symbol": "₮"
  },
  {
    "name": "Mauritius Rupee",
    "currency_code": "MUR",
    "symbol": "₨"
  },
  {
    "name": "Mexico Peso",
    "currency_code": "MXN",
    "symbol": "$"
  },
  {
    "name": "Malaysia Ringgit",
    "currency_code": "MYR",
    "symbol": "RM"
  },
  {
    "name": "Mozambique Metical",
    "currency_code": "MZN",
    "symbol": "MT"
  },
  {
    "name": "Namibia Dollar",
    "currency_code": "NAD",
    "symbol": "$"
  },
  {
    "name": "Nigerian Naira",
    "currency_code": "NGN",
    "symbol": "₦"
  },
  {
    "name": "Nicaragua Cordoba",
    "currency_code": "NIO",
    "symbol": "C$"
  },
  {
    "name": "Norwegian Krona",
    "currency_code": "NOK",
    "symbol": "kr"
  },
  {
    "name": "Nepal Rupee",
    "currency_code": "NPR",
    "symbol": "₨"
  },
  {
    "name": "New-Zealand Dollar",
    "currency_code": "NZD",
    "symbol": "$"
  },
  {
    "name": "Oman Rial",
    "currency_code": "OMR",
    "symbol": "﷼"
  },
  {
    "name": "Panama Balboa",
    "currency_code": "PAB",
    "symbol": "B/."
  },
  {
    "name": "Peru Sol",
    "currency_code": "PEN",
    "symbol": "S/."
  },
  {
    "name": "Philippines Peso",
    "currency_code": "PHP",
    "symbol": "₱"
  },
  {
    "name": "Pakistan Rupee",
    "currency_code": "PKR",
    "symbol": "₨"
  },
  {
    "name": "Polish Zloty",
    "currency_code": "PLN",
    "symbol": "Pł"
  },
  {
    "name": "Paraguay Guarani",
    "currency_code": "PYG",
    "symbol": "Gs"
  },
  {
    "name": "Qatar Riyal",
    "currency_code": "QAR",
    "symbol": "﷼"
  },
  {
    "name": "Romania Leu",
    "currency_code": "RON",
    "symbol": "lei"
  },
  {
    "name": "Serbia Dinar",
    "currency_code": "RSD",
    "symbol": "Дин."
  },
  {
    "name": "Russia Ruble",
    "currency_code": "RUB",
    "symbol": "₽"
  },
  {
    "name": "Saudi Riyal",
    "currency_code": "SAR",
    "symbol": "﷼"
  },
  {
    "name": "Solomon Islands Dollar",
    "currency_code": "SBD",
    "symbol": "$"
  },
  {
    "name": "Seychelles Rupee",
    "currency_code": "SCR",
    "symbol": "₨"
  },
  {
    "name": "Sweden Krona",
    "currency_code": "SEK",
    "symbol": "kr"
  },
  {
    "name": "Singapore Dollar",
    "currency_code": "SGD",
    "symbol": "$"
  },
  {
    "name": "Saint Helena Pound",
    "currency_code": "SHP",
    "symbol": "£"
  },
  {
    "name": "Somalia Shilling",
    "currency_code": "SOS",
    "symbol": "S"
  },
  {
    "name": "Suriname Dollar",
    "currency_code": "SRD",
    "symbol": "$"
  },
  {
    "name": "El Salvador Colon",
    "currency_code": "SVC",
    "symbol": "$"
  },
  {
    "name": "Syria Pound",
    "currency_code": "SYP",
    "symbol": "£"
  },
  {
    "name": "Thai Baht",
    "currency_code": "THB",
    "symbol": "฿"
  },
  {
    "name": "Turkish Lira",
    "currency_code": "TRY",
    "symbol": "TL"
  },
  {
    "name": "Trinidad and Tobago Dollar",
    "currency_code": "TTD",
    "symbol": "TT$"
  },
  {
    "name": "Tuvalu Dollar",
    "currency_code": "TVD",
    "symbol": "$"
  },
  {
    "name": "Taiwan Dollar",
    "currency_code": "TWD",
    "symbol": "$"
  },
  {
    "name": "Ukrainian Hryvnia",
    "currency_code": "UAH",
    "symbol": "₴"
  },
  {
    "name": "United States Dollar",
    "currency_code": "USD",
    "symbol": "$"
  },
  {
    "name": "Uruguay Peso",
    "currency_code": "UYU",
    "symbol": "$U"
  },
  {
    "name": "Uzbekistan Som",
    "currency_code": "UZS",
    "symbol": "лв"
  },
  {
    "name": "Venezuela Bolívar",
    "currency_code": "VEF",
    "symbol": "Bs"
  },
  {
    "name": "Viet Nam Dong",
    "currency_code": "VND",
    "symbol": "₫"
  },
  {
    "name": "East Caribbean Dollar",
    "currency_code": "XCD",
    "symbol": "$"
  },
  {
    "name": "Yemen Rial",
    "currency_code": "YER",
    "symbol": "﷼"
  },
  {
    "name": "South Africa Rand",
    "currency_code": "ZAR",
    "symbol": "R"
  },
  {
    "name": "Zimbabwe Dollar",
    "currency_code": "ZWD",
    "symbol": "Z$"
  }
]


////////////////////////////////////////////////////////Reports URL////////////////////////////////////////////////////////////////////////
export const reports_list = [
  {
    title: 'Financial Statements',
    item: [
      {
        eventName: 'PROFIT_AND_LOSS',
        name: routesName.profitLoss.name,
        path: routesName.profitLoss.path,
      },
      {
        eventName: 'BALANCE_SHEET',
        name: routesName.balanceSheet.name,
        path: routesName.balanceSheet.path,
      },
      {
        eventName: 'CASH_FLOW',
        name: routesName.cashFlow.name,
        path: routesName.cashFlow.path,
      },
      {
        eventName: 'HORIZONTAL_PROFIT_AND_LOSS',
        name: routesName.horizontalProfitLoss.name,
        path: routesName.horizontalProfitLoss.path,
      },
      {
        eventName: 'HORIZONTAL_BALANCE_SHEET',
        name: routesName.horizontalBalanceSheet.name,
        path: routesName.horizontalBalanceSheet.path,
      },
      {
        eventName: 'MOMENT_OF_EQUITY',
        name: routesName.movementEquityReports.name,
        path: routesName.movementEquityReports.path,
      },
      {
        eventName: 'BUSINESS_PERFORMANCE_RATIOS',
        name: routesName.businessPerformanceRatiosReports.name,
        path: routesName.businessPerformanceRatiosReports.path,
      },

    ]
  },
  {
    title: 'Taxes',
    item: [
      {
        eventName: 'TAX_SUMMARY',
        name: routesName.taxSummary.name,
        path: routesName.taxSummary.path,
      },
      {
        eventName: 'TDS_SUMMARY',
        name: routesName.tdsSummaryReports.name,
        path: routesName.tdsSummaryReports.path,
      },
      {
        eventName: 'TCS_SUMMARY',
        name: routesName.tcsSummaryReports.name,
        path: routesName.tcsSummaryReports.path,
      },
    ]
  },
  {
    title: 'Sales',
    item: [
      {
        eventName: 'SALES_BY_CUSTOMER',
        name: routesName.salesByCustomer.name,
        path: routesName.salesByCustomer.path,
      },
      {
        eventName: 'SALES_BY_ITEM',
        name: routesName.salesByItem.name,
        path: routesName.salesByItem.path,
      },
      {
        eventName: 'SALES_BY_SALES_PERSON',
        name: routesName.salesBySalesPerson.name,
        path: routesName.salesBySalesPerson.path,
      },
      {
        eventName: 'INCOME_BY_CUSTOMER',
        name: routesName.incomeByCustomer.name,
        path: routesName.incomeByCustomer.path,
      },
      {
        eventName: 'CUSTOMER_BALANCES',
        name: routesName.customerBalancesReports.name,
        path: routesName.customerBalancesReports.path,
      },
      {
        eventName: 'AR_AGING_SUMMARY',
        name: routesName.arAgingSummaryReports.name,
        path: routesName.arAgingSummaryReports.path,
      },
      {
        eventName: 'AR_AGING_DETAILS',
        name: routesName.arDetailedReports.name,
        path: routesName.arDetailedReports.path,
      },
      {
        eventName: 'ESTIMATE_DETAILS',
        name: routesName.estimateDetailsReports.name,
        path: routesName.estimateDetailsReports.path,
      },
      {
        eventName: 'SALES_ORDERS_DETAILS',
        name: routesName.salesOrderDetailsReports.name,
        path: routesName.salesOrderDetailsReports.path,
      },
      {
        eventName: 'INVOICE_DETAILS',
        name: routesName.invoiceDetailsReports.name,
        path: routesName.invoiceDetailsReports.path,
      },
      {
        eventName: 'RECURRING_INVOICE_DETAILS',
        name: routesName.recurringInvoiceDetailsReports.name,
        path: routesName.recurringInvoiceDetailsReports.path,
      },
      {
        eventName: 'DELIVERY_CHALLAN_DETAILS',
        name: routesName.deliveryChallanDetailsReports.name,
        path: routesName.deliveryChallanDetailsReports.path,
      },

    ]
  },
  {
    title: 'Purchases',
    item: [
      {
        eventName: 'PURCHASE_BY_VENDOR',
        name: routesName.purchaseByVendor.name,
        path: routesName.purchaseByVendor.path,
      },
      {
        eventName: 'PURCHASE_BY_ITEM',
        name: routesName.purchaseByItem.name,
        path: routesName.purchaseByItem.path,
      },
      {
        eventName: 'EXPENSE_DETAILS',
        name: routesName.expenseDetailsReports.name,
        path: routesName.expenseDetailsReports.path,
      },
      {
        eventName: 'VENDOR_BALANCES',
        name: routesName.vendorBalancesReports.name,
        path: routesName.vendorBalancesReports.path,
      },
      {
        eventName: 'AP_AGING_SUMMARY',
        name: routesName.apAgingSummaryReports.name,
        path: routesName.apAgingSummaryReports.path,
      },
      {
        eventName: 'AP_AGING_DETAILS',
        name: routesName.apDetailedReports.name,
        path: routesName.apDetailedReports.path,
      },
      {
        eventName: 'PURCHASE_ORDERS_DETAILS',
        name: routesName.purchaseOrderDetailsReports.name,
        path: routesName.purchaseOrderDetailsReports.path,
      },
      {
        eventName: 'BILL_DETAILS',
        name: routesName.billDetailsReports.name,
        path: routesName.billDetailsReports.path,
      },
      {
        eventName: 'RECURRING_EXPENSE_DETAILS',
        name: routesName.recurringExpenseDetailsReports.name,
        path: routesName.recurringExpenseDetailsReports.path,
      },
    ]
  },
  {
    title: 'Payments',
    item: [
      {
        eventName: 'PAYMENTS_RECEIVED',
        name: routesName.paymentsReceivedReports.name,
        path: routesName.paymentsReceivedReports.path,
      },
      {
        eventName: 'PAYMENTS_MADE',
        name: routesName.paymentsMadeReports.name,
        path: routesName.paymentsMadeReports.path,
      },
      {
        eventName: 'CREDIT_NOTE_DETAILS',
        name: routesName.creditNoteDetailsReports.name,
        path: routesName.creditNoteDetailsReports.path,
      },
      {
        eventName: 'DEBIT_NOTE_DETAILS',
        name: routesName.debitNoteDetailsReports.name,
        path: routesName.debitNoteDetailsReports.path,
      },
      {
        eventName: 'CUSTOMERS_REFUND',
        name: routesName.customersRefundReports.name,
        path: routesName.customersRefundReports.path,
      },
      {
        eventName: 'VENDORS_REFUND',
        name: routesName.vendorsRefundReports.name,
        path: routesName.vendorsRefundReports.path,
      },
      {
        eventName: 'TIME_TO_GET_PAID',
        name: routesName.timeToGetPaidReports.name,
        path: routesName.timeToGetPaidReports.path,
      },
    ]
  },
  {
    title: 'Accountant',
    item: [
      {
        eventName: 'ACCOUNT_TRANSACTIONS',
        name: routesName.accountTransactionReports.name,
        path: routesName.accountTransactionReports.path,
      },
      {
        eventName: 'DAY_BOOK',
        name: routesName.dayBookReports.name,
        path: routesName.dayBookReports.path,
      },
      {
        eventName: 'GENERAL_LEDGERS',
        name: routesName.generalLedgerList.name,
        path: routesName.generalLedgerList.path,
      },
      {
        eventName: 'JOURNAL_REPORTS',
        name: routesName.journalReports.name,
        path: routesName.journalReports.path,
      },
      {
        eventName: 'TRIAL_BALANCE',
        name: routesName.trialBalance.name,
        path: routesName.trialBalance.path,
      },
    ]
  },
  {
    title: 'Activity',
    item: [
      {
        eventName: 'ACTIVITY_LOGS',
        name: routesName.activityAuditTrailReports.name,
        path: routesName.activityAuditTrailReports.path,
      },
    ]
  },
  {
    title: 'Inventory',
    item: [
      {
        eventName: 'INVENTORY',
        name: routesName.itemStockReports.name,
        path: routesName.itemStockReports.path,
      },
    ]
  },
]

////////////////////////////////////////////////////////shortCuts Keys List////////////////////////////////////////////////////////////////////////

export const shortCuts = [
  // For Create 
  { type: 'create', label: 'Create Estimate', path: routesName.invoicingSalesEstimateAdd.path, eventName: 'create_estimate' },
  { type: 'create', label: 'Create Sales Order', path: routesName.invoicingSalesOrderAdd.path, eventName: 'create_sales_order' },
  { type: 'create', label: 'Create Delivery Challan', path: routesName.invoicingDeliveryChallanAdd.path, eventName: 'create_delivery_challan' },
  { type: 'create', label: 'Create Invoice', path: routesName.invoicingSalesInvoiceAdd.path, eventName: 'create_invoice' },
  { type: 'create', label: 'Create Recurring Invoice', path: routesName.invoicingSalesRecurringInvoiceAdd.path, eventName: 'create_recurring_invoice' },
  { type: 'create', label: 'Create Credit Note', path: routesName.invoicingCreditNotesAdd.path, eventName: 'create_credit_note' },
  { type: 'create', label: 'Record Expense', path: routesName.invoicingExpenseAdd.path, eventName: 'record_expense' },
  { type: 'create', label: 'Create Recurring Expense', path: routesName.invoicingRecurringExpenseAdd.path, eventName: 'create_recurring_expense' },
  { type: 'create', label: 'Create Purchase Order', path: routesName.invoicingPurchaseOrderAdd.path, eventName: 'create_purchase_order' },
  { type: 'create', label: 'Create Bill', path: routesName.invoicingPurchaseBillAdd.path, eventName: 'create_bill' },
  { type: 'create', label: 'Create Debit Note', path: routesName.invoicingDebitNotesAdd.path, eventName: 'create_debit_note' },
  { type: 'create', label: 'Create Journal Entry', path: routesName.journalEntryCreate.path, eventName: 'create_journal_entry' },

  // For Add
  { type: 'add', label: 'Add Goods or Service', path: routesName.invoicingItemsAdd.path, eventName: 'add_goods_or_service' },
  { type: 'add', label: 'Add Customer', path: routesName.invoicingSalesCustomerAdd.path, eventName: 'add_customer' },
  { type: 'add', label: 'Add Payment Received', path: routesName.invoicingPaymentsReceived.path, eventName: 'add_payment_received' },
  { type: 'add', label: 'Add Vendor', path: routesName.invoicingPurchaseVendorAdd.path, eventName: 'add_vendor' },
  { type: 'add', label: 'Add Payment Made', path: routesName.invoicingPaymentsMade.path, eventName: 'add_payment_made' },
  { type: 'add', label: 'Add Parent Accounts', path: routesName.parentAccountAdd.path, eventName: 'add_parent_accounts' },

  // For List
  { type: '', label: 'Items', path: routesName.invoicingItems.path, eventName: 'items' },
  { type: '', label: 'Customers', path: routesName.invoicingSalesCustomer.path, eventName: 'customers' },
  { type: '', label: 'Estimates', path: routesName.invoicingSalesEstimate.path, eventName: 'estimates' },
  { type: '', label: 'Sales Orders', path: routesName.invoicingSalesOrder.path, eventName: 'sales_orders' },
  { type: '', label: 'Delivery Challans', path: routesName.invoicingDeliveryChallan.path, eventName: 'delivery_challans' },
  { type: '', label: 'Invoices', path: routesName.invoicingSalesInvoice.path, eventName: 'invoices' },
  { type: '', label: 'Recurring Invoices', path: routesName.invoicingSalesRecurringInvoice.path, eventName: 'recurring_invoices' },
  { type: '', label: 'Payments Received', path: routesName.invoicingPaymentsReceivedList.path, eventName: 'payments_received' },
  { type: '', label: 'Credit Notes', path: routesName.invoicingCreditNotesList.path, eventName: 'credit_notes' },
  { type: '', label: 'Vendors', path: routesName.invoicingPurchaseVendor.path, eventName: 'vendors' },
  { type: '', label: 'Expenses', path: routesName.invoicingExpense.path, eventName: 'expenses' },
  { type: '', label: 'Recurring Expenses', path: routesName.invoicingRecurringExpense.path, eventName: 'recurring_expenses' },
  { type: '', label: 'Purchase Orders', path: routesName.invoicingPurchaseOrder.path, eventName: 'purchase_orders' },
  { type: '', label: 'Bills', path: routesName.invoicingPurchaseBill.path, eventName: 'bills' },
  { type: '', label: 'Payments Made', path: routesName.invoicingPaymentsMadeList.path, eventName: 'payments_made' },
  { type: '', label: 'Debit Notes', path: routesName.invoicingDebitNotesList.path, eventName: 'debit_notes' },
  { type: '', label: 'Journal Entries', path: routesName.journalEntryList.path, eventName: 'journal_entries' },

  // Accounts
  { type: '', label: 'Chart of Accounts', path: routesName.chartsAccountList.path, eventName: 'chart_of_accounts' },
  { type: '', label: 'Manage Chart of Accounts', path: routesName.chartsAccountAdd.path, eventName: 'manage_chart_of_accounts' },
  { type: '', label: 'Parent Accounts', path: routesName.parentAccount.path, eventName: 'parent_accounts' },

  { type: '', label: 'Statement Upload', path: routesName.fileUpload.path, eventName: 'statement_upload' },
  { type: '', label: 'Source Transactions', path: routesName.sourceTransactions.path, eventName: 'source_transactions' },
  { type: '', label: 'GST Filing', path: routesName.gstFilingComponent.path, eventName: 'gst_filing' },
  { type: '', label: 'Reports', path: routesName.reportsList.path, eventName: 'reports' },
  { type: '', label: 'Downloads', path: routesName.downloadList.path, eventName: 'downloads' },

  { type: '', label: 'Setting', path: routesName.settings.path + 'action=personal_information', eventName: 'setting' },
  { type: '', label: 'Reminders', path: routesName.settings.path + 'action=reminders', state: { selected_tab: 3, selected_value: 'reminders' }, eventName: 'reminders' },
  { type: '', label: 'Opening Balances', path: routesName.settings.path + 'action=opening_balances', state: { selected_tab: 2, selected_value: 'opening_balances' }, eventName: 'opening_balances' },
];



export const sourceTypeList = [
  { source_type: 'expense', source_path: routesName.invoicingExpenseView.path + '?id=' },
  { source_type: 'bill', source_path: routesName.invoicingPurchaseBillView.path + '?id=' },
  { source_type: 'invoice', source_path: routesName.invoicingSalesInvoiceView.path + '?id=' },

  { source_type: 'customer_opening_balance', source_path: routesName.invoicingSalesCustomerView.path + '?id=' },
  { source_type: 'vendor_opening_balance', source_path: routesName.invoicingPurchaseVendorView.path + '?id=' },

  { source_type: 'opening_balance', source_path: routesName.settings.path + '?action=opening_balances', state: { selected_tab: 2, selected_value: 'opening_balances' } },

  { source_type: 'credit_note', source_path: routesName.invoicingCreditNotesView.path + '?id=' },
  { source_type: 'refund_payment_credit_note', source_path: routesName.invoicingCreditNotesView.path + '?tab=1&id=' },

  { source_type: 'debit_note', source_path: routesName.invoicingDebitNotesView.path + '?id=' },
  { source_type: 'refund_payment_debit_note', source_path: routesName.invoicingDebitNotesView.path + '?tab=1&id=' },

  { source_type: 'pay', source_path: routesName.invoicingPaymentsMadeView.path + '?payment_id=' },
  { source_type: 'refund_payment_pay', source_path: routesName.invoicingPaymentsMadeView.path + '?tab=1&payment_id=' },

  { source_type: 'receive', source_path: routesName.invoicingPaymentsReceivedView.path + '?payment_id=' },
  { source_type: 'refund_payment_receive', source_path: routesName.invoicingPaymentsReceivedView.path + '?tab=1&payment_id=' },


]

export const time_format_ref = {
  "hh:mm:ss": ' %H:%M:%S',
  "hh:mm:ss AM/PM": ' %I:%M:%S %p',
  "hh:mm": ' %H:%M',
  "hh:mm AM/PM": ' %I:%M %p',
  "Thh:mm:ss": 'T%H:%M:%S',
  "Thh:mm:ssZ": 'T%H:%M:%SZ',
  "Thh:mm:ss.000": 'T%H:%M:%S.%f',
  "hh:mm:ss.000": ' %H:%M:%S.%f'
}

export const date_format_ref = {
  "yyyy-mm-dd": '%Y-%m-%d',
  "dd-mm-yyyy": '%d-%m-%Y',
  "dd/mm/yyyy": '%d/%m/%Y',
  "yyyy/mm/dd": '%Y/%m/%d',
  "dd.mm.yyyy": '%d.%m.%Y',
  "yyyy.mm.dd": '%Y.%m.%d',
  "dd month, yyyy": '%d %B, %Y',
  "dd mon, yyyy": '%d %b, %Y',
  "mon dd, yyyy": '%b %d, %Y',
  "dd-mon-yyyy": '%d-%b-%Y',
  "yyyy, month dd": '%Y, %B %d',
  "yyyy, mon dd": '%Y, %b %d',
  "dd-mon-yy": '%d-%b-%y',
  "yyyy-mon-dd": '%Y-%b-%d',
  "dd/mm/yy": '%d/%m/%y',
  "mm/dd/yyyy": '%m/%d/%Y',
  "mm/dd/yy": '%m/%d/%y',
  "dd-mm-yy": '%d-%m-%y',
  "mm-dd-yyyy": '%m-%d-%Y',
  "mon-dd-yyyy": '%b-%d-%Y',
  "dd.mm.yy": '%d.%m.%y',
  "mm.dd.yyyy": '%m.%d.%Y',
  "mm.dd.yy": '%m.%d.%y',
  "yy.mm.dd": '%y.%m.%d',
  "dd mon yy": '%d %b %y',
  "ddmonyy": '%d%b%y',
  "monddyy": '%b%d%y',
  "yyyymmdd": '%Y%m%d',
  "yymondd": '%y%b%d',
}

//////////////////////////////////////////////////////////////////////Filter Status//////////////////////////////////////////////////////////
export const expenseStatus = [
  { name: 'Unbilled', value: 'unbilled' },
  { name: 'Invoiced', value: 'invoiced' },
  { name: 'Reimbursed', value: 'reimbursed' },
  { name: 'Non Billable', value: 'non_billable' },
]


export const estimateStatus = [
  { name: 'Sent', value: 'sent' },
  { name: 'Unsent', value: 'unsent' },
  { name: 'Closed', value: 'closed' },
]


export const debitNoteStatus = [
  { name: 'Sent', value: 'sent' },
  { name: 'Draft', value: 'draft' },
  { name: 'Unsent', value: 'unsent' },
  { name: 'Closed', value: 'closed' },
  { name: 'Partially Utilized', value: 'partially_utilized' },
]


export const creditNoteStatus = [
  { name: 'Sent', value: 'sent' },
  { name: 'Draft', value: 'draft' },
  { name: 'Unsent', value: 'unsent' },
  { name: 'Closed', value: 'closed' },
  { name: 'Partially Utilized', value: 'partially_utilized' },
]

export const salesOrderStatus = [
  { name: 'Sent', value: 'sent' },
  { name: 'Unsent', value: 'unsent' },
  { name: 'Closed', value: 'closed' },
]

export const purchaseOrderStatus = [
  { name: 'Sent', value: 'sent' },
  { name: 'Unsent', value: 'unsent' },
  { name: 'Closed', value: 'closed' },
]


export const deliveryChallanStatus = [
  { name: 'Open', value: 'open' },
  { name: 'Returned', value: 'returned' },
  { name: 'Delivered', value: 'delivered' },
]


export const recurringInvoiceStatus = [
  { name: 'Active', value: 'Active' },
  { name: 'Expired', value: 'Expired' },
  { name: 'Inactive', value: 'Inactive' },
]


export const recurringExpenseStatus = [
  { name: 'Active', value: 'Active' },
  { name: 'Expired', value: 'Expired' },
  { name: 'Inactive', value: 'Inactive' },
]

