import React from 'react';
import moment from 'moment';
import Details from './Details';
import { apiAction } from "../../../../api/api";
import * as Actions from '../../../../state/Actions';
import CustomTitle from '../../../common/CustomTitle';
import { get_gstr1_report } from '../../../../api/urls';
import { routesName } from '../../../../config/routesName';
import { useNavigate, useLocation } from 'react-router-dom';
import { MetaData } from '../../../custom/tooltip/CustomTooltip';
import { getBusinessInfo } from '../../../../config/cookiesInfo';
import CurrencyFormatter from '../../../common/CurrencyFormatter';
import CommonCustomDateRange from '../../../common/CommonCustomDateRange';

import {
    setLoader,
    date_range,
    getDateFormat,
} from '../../../../utils/Utils';

import {
    CustomContainer,
    CustomTitleContainer
} from '../../../custom/container/CustomContainer';

import {
    CustomTable,
    CustomTableRow,
    CustomTableBody,
    CustomTableHead,
    CustomTableHeadCell,
    CustomTableBodyCell,
    CustomTableContainer,
} from '../../../custom/table/CustomTable';

import { Grid } from '@mui/material';


const OverviewSummary = () => {
    let location = useLocation();
    let navigate = useNavigate();
    let locationState = location.state;

    const stateAction = Actions.getState(React.useContext);
    const dispatch = Actions.getDispatch(React.useContext);

    const { selectedDateRange } = stateAction;
    let filter_date_range = date_range().find((item) => item.name === selectedDateRange)
    const default_date_range = filter_date_range ? filter_date_range : date_range()[2]

    const [dateRange, setDateRange] = React.useState(default_date_range)
    const [fromDate, setFromDate] = React.useState(moment())
    const [toDate, setToDate] = React.useState(moment())
    const [results, setResults] = React.useState([])

    React.useEffect(() => {
        if (!locationState && dateRange) {
            if (dateRange.name !== "Custom") {
                getApiResults(dateRange.dates.from_date, dateRange.dates.to_date, dateRange.dates.date_range)
            } else {
                getApiResults(fromDate.format('YYYY-MM-DD'), toDate.format('YYYY-MM-DD'), `${fromDate.format(getDateFormat())} - ${toDate.format(getDateFormat())}`)
            }
        }
        // eslint-disable-next-line
    }, [dateRange, toDate, fromDate]);

    const getApiResults = async (from_date, to_date, range_date) => {
        setLoader(dispatch, Actions, true)
        let res = await apiAction({
            method: 'post',
            navigate: navigate,
            dispatch: dispatch,
            url: get_gstr1_report(),
            data: {
                to_date: to_date,
                from_date: from_date,
                business_id: getBusinessInfo().id,
            },
        })

        if (res?.success) {
            setResults(res?.result)
            setLoader(dispatch, Actions, false);
        } else {
            setLoader(dispatch, Actions, false);
        }
    }

    return (
        locationState ?
            <Details
                stateData={locationState}
                onClickBack={() => navigate(routesName.gstr1FilingComponent.path)
                } />
            :
            <div>
                <CustomTitleContainer>
                    <Grid container spacing={0} style={{
                        alignItems: 'center',
                        justifyItems: 'center',
                        alignContent: 'space-evenly',
                        justifyContent: 'space-evenly',
                    }}>
                        <Grid item xs={12} sm={4}>
                            <CustomTitle title={'Overview Summary'} />
                        </Grid>
                        <Grid container spacing={1} item xs={12} sm={8} sx={{ justifyContent: 'end', }}>
                            <CommonCustomDateRange
                                setPage={() => { }}
                                date_range={date_range()}

                                toDate={toDate}
                                setToDate={setToDate}

                                fromDate={fromDate}
                                setFromDate={setFromDate}

                                dateRange={dateRange}
                                setDateRange={setDateRange}
                            />
                        </Grid>
                    </Grid>
                </CustomTitleContainer>

                <CustomContainer maxWidth={"400"}>

                    <CustomTableContainer sx={{ pb: 2 }}>
                        <CustomTable>
                            <Header />
                            <Body
                                data={results}
                                navigate={(item) => {
                                    navigate(
                                        routesName.gstr1FilingComponent.path,
                                        {
                                            state: {
                                                ...item,
                                                date: dateRange.dates ? dateRange :
                                                    { name: 'Custom', dates: 0, to_date: toDate.format('YYYY-MM-DD'), from_date: fromDate.format('YYYY-MM-DD') }
                                            }
                                        }
                                    )
                                }}
                            />
                        </CustomTable>
                    </CustomTableContainer>
                </CustomContainer>
            </div>

    )
}

export default OverviewSummary;

const Header = () => {
    return (
        <CustomTableHead>
            <CustomTableRow>
                <CustomTableHeadCell>Description</CustomTableHeadCell>
                <CustomTableHeadCell align='right'><span>Total Transaction</span></CustomTableHeadCell>
                <CustomTableHeadCell align='right'><span>Taxable Amount</span></CustomTableHeadCell>
                <CustomTableHeadCell align='right'><span>IGST</span></CustomTableHeadCell>
                <CustomTableHeadCell align='right'><span>CGST</span></CustomTableHeadCell>
                <CustomTableHeadCell align='right'><span>SGST</span></CustomTableHeadCell>
                <CustomTableHeadCell align='right'><span>CESS</span></CustomTableHeadCell>
            </CustomTableRow>
        </CustomTableHead>
    )
}

const Body = (props) => {
    const { data, navigate } = props;
    return (
        <CustomTableBody>
            {data.map((item, index) => {
                return (
                    <CustomTableRow id={'navigate_' + index} dataTestId={'navigate_' + index} key={index} style={index % 2 ? { background: "#f5f5f5", cursor: 'pointer', } : { background: "", cursor: 'pointer', }} onClick={() => { navigate(item) }}>
                        <CustomTableBodyCell sx={{ display: 'flex',alignItems:'center' }}><span>{item.description} </span><MetaData  placement={'top'} meta_data={item.meta_data} /></CustomTableBodyCell>
                        <CustomTableBodyCell align='right'><span>{item.total_transactions}</span></CustomTableBodyCell>
                        <CustomTableBodyCell align='right'><span><CurrencyFormatter amount={item.taxable_amount} currency={null} /></span></CustomTableBodyCell>
                        <CustomTableBodyCell align='right'><span><CurrencyFormatter amount={item.igst} currency={null} /></span></CustomTableBodyCell>
                        <CustomTableBodyCell align='right'><span><CurrencyFormatter amount={item.cgst} currency={null} /></span></CustomTableBodyCell>
                        <CustomTableBodyCell align='right'><span><CurrencyFormatter amount={item.sgst} currency={null} /></span></CustomTableBodyCell>
                        <CustomTableBodyCell align='right'><span><CurrencyFormatter amount={item.cess} currency={null} /></span></CustomTableBodyCell>
                    </CustomTableRow>
                )
            })}
        </CustomTableBody>
    )
}

