import React from 'react';
import Input from '../common/Input';
import { apiAction } from '../../api/api';
import { HOST } from '../../config/config';
import * as Actions from '../../state/Actions';
import { useNavigate } from 'react-router-dom';
import { input_css_style } from '../../utils/Constant';
import CustomButton from '../custom/button/CustomButton';
import { getBusinessInfo } from '../../config/cookiesInfo';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import CustomTypography from '../custom/typography/CustomTypography';

import {
    isFormValid,
    stateChangeManager,
} from '../../utils/Utils';

import {
    send_downloaded_file,
    share_downloaded_file,
    get_data_for_manual_reminder,
    send_manual_invoice_reminder,
} from '../../api/urls';

import {
    Box,
    Grid,
    Slide,
    Drawer,
    Button,
    Divider,
    IconButton,
} from '@mui/material';

import { Close } from '@mui/icons-material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AttachFileIcon from '@mui/icons-material/AttachFile';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const CommonShareFile = (props) => {
    let navigate = useNavigate();

    const { id, open, setOpen, title, isReminder = false,reminderAnalyticsEvent, to_email, cc_email } = props;
    const dispatch = Actions.getDispatch(React.useContext);

    const [data, setData] = React.useState({
        to_cc: [],
        to_email: [],
        file_id: null,
        subject: null,
        message: null,
        business_id: getBusinessInfo().id,
    })
    React.useEffect(() => {
        const onShare = async (id) => {
            let res = await apiAction({
                method: 'post',
                navigate: navigate,
                dispatch: dispatch,
                url: isReminder ? get_data_for_manual_reminder() : share_downloaded_file(),
                data: { business_id: getBusinessInfo().id, file_id: id, invoice_id: id },
            })

            if (res?.success) {

                setData({
                    ...data,
                    ...res?.result,
                    file_id: id,
                    invoice_id: id,
                    to_cc: cc_email ? cc_email : [],
                    to_email: to_email ? to_email : [res?.result.to_address],
                    fileUrl: res?.result.file && HOST + `/media/${res?.result.file}`,
                    fileName: res?.result.file && res?.result.file.split("/")[res?.result.file.split("/").length - 1],
                })
                // setLoader(dispatch, Actions, false);
            } else {
                // setLoader(dispatch, Actions, false);
            }
        }
        if (id && open) {
            onShare(id)
        }
    }, [id, open])

    const onSave = async () => {
        // let email_validate=data.to_email.map((item)=>!validator.isEmail(item))
        // console.log('email_validate',email_validate)
        let validation_data = [
            { key: "", validation: data.to_email.length === 0, message: 'Please Enter the To Email.' },
            // { key: '', validation: (!data.to_email.length===0 || !validator.isEmail(data.to_email)), message: 'Please enter valid email.' },
            { key: "subject", message: 'Please Enter the Subject.' },
            { key: "message", message: 'Please Enter the Message.' },
        ]
        const { isValid, message } = isFormValid(data, validation_data);

        if (isValid) {

            let res = await apiAction({
                method: 'post',
                navigate: navigate,
                dispatch: dispatch,
                url: isReminder ? send_manual_invoice_reminder() : send_downloaded_file(),
                data: { ...data, body: data.message },

            })
            if (res?.success) {
                toggleDrawer()
                stateChangeManager(dispatch, Actions, true, 'success', res?.status);
            } else {
                stateChangeManager(dispatch, Actions, true, 'error', res?.status);
            }
        } else {
            stateChangeManager(dispatch, Actions, true, 'error', message);
        }
    }

    const toggleDrawer = () => {
        setOpen(!open);
    }

    React.useEffect(() => {
        if (!open) {
            setData({
                to_cc: [],
                to_email: [],
                file_id: null,
                subject: null,
                message: null,
                business_id: getBusinessInfo().id,
            })
        }
    }, [open])

    return (
        <React.Fragment>
            {
                isReminder &&
                <Button
                    id={'common_reminder_btn'}
                    dataTestId={'common_reminder_btn'}
                    size='small'
                    variant='contained'
                    onClick={() => {
                        toggleDrawer()
                        if (reminderAnalyticsEvent) {
                            reminderAnalyticsEvent()
                        }
                    }}
                    sx={{
                        ml: 1,
                        height: '38px',
                        fontSize: '12px',
                        fontWeight: '700',
                        fontStyle: 'normal',
                        textTransform: 'none',
                        fontFamily: 'Noto Sans',
                    }}
                >
                    Reminders
                </Button>
            }
            <Drawer
                open={open}
                elevation={5}
                anchor={'right'}
                hideBackdrop={true}
                onClose={() => toggleDrawer()}
                TransitionComponent={Transition}
                variant={open?"persistent":'temporary'}

                id={'common_reminder_drawer'}
                dataTestId={'common_reminder_drawer'}

                PaperProps={{
                    sx: {
                        zIndex:999999,
                        backgroundColor: '#FFFFFF',
                        width: {
                            xs: '100vw',
                            sm: '35vw'
                        }
                    }
                }}
            >
                <Box sx={{
                    zIndex: 1,
                    minHeight: '100vh',
                    backgroundColor: '#FFFFFF',
                    boxShadow: '1px 0px 4px rgba(0, 0, 0, 0.04)',
                }}>
                    <Box sx={{ height: '24px', p: 2, pt: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <CustomTypography
                            text={title}
                            sx={{
                                color: '#000000',
                                fontSize: '14px',
                                fontWeight: '700',
                                lineHeight: '18px',
                                fontStyle: 'normal',
                                fontFamily: "Noto Sans",
                            }}
                        />
                        <IconButton size='small' id={'close_reminder_btn'} dataTestId={'close_reminder_btn'} onClick={() => toggleDrawer()} sx={{ ":hover": { bgcolor: '#e0e0e0' } }}>
                            <Close fontSize='small' />
                        </IconButton>
                    </Box>
                    <Divider />
                    <Box sx={{ p: 2, height: 'calc(100vh - 170px)', }}>
                        <Information
                            data={data}
                            setData={setData}
                            isReminder={isReminder}
                        />
                    </Box>
                    <Divider />
                    <Box sx={{ pb: 2, pr: 2, position: 'absolute', bottom: 0, left: 0, right: 0, }}>
                        <Buttons
                            btnLabel={title}
                            onSave={() => { onSave() }}
                        />
                    </Box>
                </Box>

            </Drawer>
        </React.Fragment>
    )
}

export default CommonShareFile;

const Buttons = (props) => {
    const { onSave, btnLabel } = props
    return (
        <Box component="form" noValidate  >
            <Grid container>
                <Grid item xs={12} sx={{ display: '-webkit-flex', justifyContent: 'end', }}>
                    <CustomButton id={'common_share_btn'} dataTestId={'common_share_btn'} variant='contained' sx={{ ml: 1, textTransform: 'none' }} btnLabel={btnLabel} onClick={onSave} />
                </Grid>
            </Grid>
        </Box>
    )
}


const Information = (props) => {
    const { data, setData, isReminder } = props;

    const myRef = React.useRef();
    const [clickedOutsideCC, setClickedOutsideCC] = React.useState(true);
    const [clickedOutsideTO, setClickedOutsideTO] = React.useState(true);
    const handleClickInside_CC = () => { setClickedOutsideCC(false); setClickedOutsideTO(true); };
    const handleClickInside_TO = () => { setClickedOutsideTO(false); setClickedOutsideCC(true); };

    const handleClickOutside_CC = e => {
        if (!myRef.current.contains(e.target)) {
            setClickedOutsideCC(true);
        }
    };
    const handleClickOutside_TO = e => {
        if (!myRef.current.contains(e.target)) {
            setClickedOutsideTO(true);
        }
    };
    React.useEffect(() => {
        document?.addEventListener('mousedown', handleClickOutside_CC);
        return () => document.removeEventListener('mousedown', handleClickOutside_CC);
    });
    React.useEffect(() => {
        document?.addEventListener('mousedown', handleClickOutside_TO);
        return () => document.removeEventListener('mousedown', handleClickOutside_TO);
    });

    const handleView = () => {
    };
    const extension = data.fileName && data.fileName.split(".")[data.fileName.split(".").length - 1];
    return (
        <div>
            <Box sx={{
                height: '76vh',
                overflow: 'scroll',
            }}>
                <Grid container >
                    <Grid container item xs={12} spacing={3}>
                        {/* <Grid item xs={12}>
                        <Input
                            item={{
                                type: 'text',
                                placeholder: '',
                                fullWidth: true,
                                title: 'To Email',
                                value: data.to_email ? data.to_email : '',
                                onChange: (event) => {
                                    setData({ ...data, to_email: event.target.value })
                                }
                            }}
                        />
                    </Grid> */}

                        <Grid item xs={12}>
                            <CustomTypography
                                text={'To Email'}
                                sx={{
                                    mb: 1,
                                    fontSize: '14px',
                                    color: '#000000',
                                    fontWeight: '700',
                                    lineHeight: '18px',
                                    fontStyle: 'normal',
                                    textTransform: "none",
                                    fontFamily: "Noto Sans",
                                    textTransform: 'capitalize',
                                }}
                            />
                            <div onClick={handleClickInside_TO} ref={myRef}>
                                <ReactMultiEmail
                                    id={'input_to_email'}
                                    dataTestId={'input_to_email'}
                                    emails={data.to_email ? data.to_email : []}
                                    onChange={(valueEmails) => {
                                        data.to_email = valueEmails
                                        setData({ ...data })
                                    }}
                                    style={{
                                        marginTop: '0px',
                                        maxHeight: data.to_email.length === 0 ? 35 : '',
                                        textAlign: 'center', borderRadius: "4px", overflowY: 'scroll', borderColor: (clickedOutsideTO) ? '#c7c7c7' : "#2464EB", borderWidth: clickedOutsideTO ? 1.5 : 1.8,
                                    }}

                                    getLabel={(
                                        email,
                                        index,
                                        removeEmail
                                    ) => {

                                        return (
                                            <div data-tag key={index} style={{ ...input_css_style }}>{email}<span data-tag-handle onClick={() => removeEmail(index)}>×</span>
                                            </div>
                                        );
                                    }}
                                    validateEmail={email => {
                                        return email?isEmail(email):false; // return boolean
                                    }}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <CustomTypography
                                text={'Cc'}
                                sx={{
                                    mb: 1,
                                    fontSize: '14px',
                                    color: '#000000',
                                    fontWeight: '700',
                                    lineHeight: '18px',
                                    fontStyle: 'normal',
                                    textTransform: "none",
                                    fontFamily: "Noto Sans",
                                    textTransform: 'capitalize',
                                }}
                            />
                            <div onClick={handleClickInside_CC} ref={myRef}>
                                <ReactMultiEmail
                                    id={'input_to_cc'}
                                    dataTestId={'input_to_cc'}
                                    emails={data.to_cc ? data.to_cc : []}
                                    onChange={(valueEmails) => {
                                        data.to_cc = valueEmails
                                        setData({ ...data })
                                    }}
                                    style={{
                                        marginTop: '0px',
                                        maxHeight: data.to_cc.length === 0 ? 35 : '',
                                        textAlign: 'center', borderRadius: "4px", overflowY: 'scroll', borderColor: (clickedOutsideCC) ? '#c7c7c7' : "#2464EB", borderWidth: clickedOutsideCC ? 1.5 : 1.8,
                                    }}

                                    getLabel={(
                                        email,
                                        index,
                                        removeEmail
                                    ) => {

                                        return (
                                            <div data-tag key={index} style={{ ...input_css_style }}>{email}<span data-tag-handle onClick={() => removeEmail(index)}>×</span>
                                            </div>
                                        );
                                    }}
                                    validateEmail={email => {
                                        return email?isEmail(email):false; // return boolean
                                    }}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <Input
                                id={'input_subject'}
                                dataTestId={'input_subject'}
                                item={{
                                    type: 'text',
                                    fullWidth: true,
                                    title: 'Subject',
                                    placeholder: 'Enter the Subject',
                                    value: data.subject ? data.subject : '',
                                    onChange: (event) => {
                                        setData({ ...data, subject: event.target.value })
                                    },
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Input
                                id={'input_message'}
                                dataTestId={'input_message'}
                                item={{
                                    rows: 14,
                                    type: 'text',
                                    multiline: true,
                                    fullWidth: true,
                                    title: 'Message',
                                    placeholder: 'Enter the Message',
                                    value: data.message ? data.message : '',
                                    onChange: (event) => {
                                        setData({ ...data, message: event.target.value })
                                    },
                                }}
                            />
                        </Grid>
                        {
                            !isReminder &&
                            <Grid item xs={12}>
                                <CustomTypography
                                    text={<span><AttachFileIcon sx={{ mb: -1, color: '#000000', }} />{'Attachment'}</span>}
                                    sx={{
                                        mb: 2,
                                        color: '#000000',
                                        fontSize: '14px',
                                        fontWeight: '700',
                                        lineHeight: '18px',
                                        fontStyle: 'normal',
                                        fontFamily: "Noto Sans",
                                    }}
                                />
                                <Box sx={{ p: 2, display: 'flex', backgroundColor: '#F2F2F2' }}>
                                    {
                                        extension === 'pdf' &&
                                        <PictureAsPdfIcon fontSize='small' sx={{ mr: .5, color: '#ee4035' }} />
                                    }
                                    <CustomTypography
                                        id={'file_view_action'}
                                        dataTestId={'file_view_action'}
                                        text={data.fileName}
                                        onClick={() => { handleView() }}
                                        sx={{
                                            fontSize: '12px',
                                            cursor: 'pointer',
                                            color: '#2464EB',
                                            fontWeight: '500',
                                            lineHeight: '16px',
                                            fontStyle: 'normal',
                                            fontFamily: 'Noto Sans',
                                            ":hover": { textDecoration: 'underline' }
                                        }}
                                    />
                                </Box>

                            </Grid>
                        }

                    </Grid>
                </Grid>
            </Box>
        </div>

    )
}