import React from 'react';
import moment from 'moment';
import Highcharts from 'highcharts';
import { apiAction } from '../../../../../../api/api';
import HighchartsReact from 'highcharts-react-official';
import { useIsElementVisible } from '../../common/Helper';
import * as Actions from '../../../../../../state/Actions';
import { GranularityComponent } from '../../common/CommonComponents';
import CustomTypography from '../../../../../custom/typography/CustomTypography';
import { BootstrapDialog, BootstrapDialogTitle } from '../../../../bulkStatementUpload/mappings/Transactions';

import {
    setLoader,
    amountFormatter,
    getUniqueObjectsByKey,
    getSelectedGranularityData,
    formattedDataBasedGranularity,
} from '../../../../../../utils/Utils';

import {
    get_data_point,
    evaluate_data_point_range,
} from '../../../../../../api/urls';

import {
    DialogContent,
} from '@mui/material';

const ScatterChart = (props) => {
    const { data, to_date, from_date, refreshDataPoint, openFull, setOpenFull } = props;
    const dispatch = Actions.getDispatch(React.useContext);


    const elementRef = React.useRef(null);
    const [results, setResults] = React.useState([]);
    const isElementVisible = useIsElementVisible(elementRef.current);
    const [granularity, setGranularity] = React.useState(getSelectedGranularityData('', from_date, to_date)?.selected);

    const fetchDataPoint = async (data_point_id) => {
        try {
            const value = await apiAction({
                url: get_data_point(data_point_id),
            });
            if (!value?.success) {
                console.log('Error', value);
                return;
            }
            const result = value.result;

            const item = await evaluateDataPoint(data_point_id);
            if (item?.success) {
                setLoader(dispatch, Actions, false);
                result['chartData'] = formattedDataBasedGranularity(item.result, granularity);

            }
            setResults((previous) => getUniqueObjectsByKey([...previous, result]));
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const evaluateDataPoint = async (data_point_id, isRefresh = false) => {
        try {
            const value = await apiAction({
                url: isRefresh ? `${evaluate_data_point_range(data_point_id, from_date, to_date, granularity)}&is_refresh=${true}` : evaluate_data_point_range(data_point_id, from_date, to_date, granularity),
            });

            return value?.success ? value : null;
        } catch (error) {
            console.error('Error:', error);
            return null;
        }
    };

    const fetchAllDataPoints = (data_point_ids = []) => {
        data_point_ids?.forEach((id) => {
            if (id) {
                fetchDataPoint(id);
            }
        });
    };

    const fetchAllEvaluateDataPoints = async (data_point_ids = [], isRefresh) => {
        try {
            const promises = data_point_ids.map(async (id) => {
                const resultItem = results.find((item) => item.id === id);
                if (resultItem) {
                    const item = await evaluateDataPoint(id, isRefresh);
                    setLoader(dispatch, Actions, false);
                    resultItem['chartData'] = formattedDataBasedGranularity(item.result, granularity);
                    return resultItem;
                }
            });
            const updatedResults = await Promise.all(promises);
            setResults([...updatedResults.filter(Boolean)])
            return updatedResults.filter(Boolean); // Filter out undefined values
        } catch (error) {
            console.error('Error:', error);
            return [];
        }
    };


    React.useEffect(() => {
        if (data && isElementVisible && !results.length) fetchAllDataPoints(data.data_point_ids);
    }, [isElementVisible]);

    React.useEffect(() => {
        const fetchData = () => {
            if (data && isElementVisible && results.length) {
                setResults([])
                fetchAllDataPoints(data?.data_point_ids);
            }
        };
        fetchData();
    }, [data]);

    React.useEffect(() => {
        if (data && isElementVisible && results.length) fetchAllEvaluateDataPoints(data.data_point_ids, true);
    }, [refreshDataPoint]);

    React.useEffect(() => {
        if (data && isElementVisible && results.length) fetchAllEvaluateDataPoints(data.data_point_ids, false);
        setGranularity(getSelectedGranularityData('', from_date, to_date)?.selected);
    }, [from_date, to_date]);

    React.useEffect(() => {
        if (data && isElementVisible && results.length) fetchAllEvaluateDataPoints(data.data_point_ids, false);
    }, [granularity]);

    return (
        <div ref={elementRef} style={{ position: 'relative', height: '100%', overflow: 'hidden' }}>
            <FullViewModeScatterChart
                open={openFull}
                title={data?.name}
                setOpen={setOpenFull}
                data={results.filter((item) => item.chartData)}

                to_date={to_date}
                from_date={from_date}
                granularity={granularity}
                setGranularity={setGranularity}
            />
            {results.length > 0 && (
                <>
                    <CustomScatterChart
                        to_date={to_date}
                        from_date={from_date}
                        granularity={granularity}
                        setGranularity={setGranularity}
                        data={results.filter((item) => item.chartData)}
                    />
                    <div style={{ position: 'absolute', top: 9, right: 9 }}>
                        <GranularityComponent
                            to_date={to_date}
                            from_date={from_date}
                            granularity={granularity}
                            setGranularity={setGranularity}
                        />
                    </div>
                </>
            )}
        </div>
    )
}

export default ScatterChart;


const CustomScatterChart = ({ data, color }) => {
    const [options, setOptions] = React.useState({});

    React.useEffect(() => {
        const seriesData = data?.map(item => ({
            name: item?.name,
            color: color ? color : '',
            data: item?.chartData?.map(entry => ({
                entry: entry,
                y: parseFloat(entry.result),
            })),
        }));

        const categories = data?.[0]?.chartData?.map(entry => entry.period);

        const chartOptions = {
            chart: {
                type: 'scatter',
                zoomType: 'xy'
            },
            title: {
                text: ''
            },
            credits: {
                enabled: false
            },
            yAxis: {
                title: {
                    text: null
                },
            },
            xAxis: {
                min: 0,
                maxPadding: 0,
                tickInterval: 1,
                endOnTick: true,
                startOnTick: true,
                showLastLabel: true,
                categories: categories,
            },
            legend: {
                align: 'left',
                layout: 'horizontal',
                verticalAlign: 'bottom'
            },
            plotOptions: {
                scatter: {
                    jitter: {
                        x: 0.005
                    }
                },
                series: {
                    cursor: 'pointer',
                    stickyTracking: false,
                    connectNulls: false,
                    point: {
                        events: {
                            click: function () { }
                        }
                    }
                },
            },
            tooltip: {
                snap: 0,
                formatter: function () {
                    return `<p style="font-family: 'Noto Sans'; color:${this.color};">${moment(this.point.options.entry.to_date).format('DD MMM YYYY')}</p><br style="margin-bottom:'10px'"/><span style="font-family: 'Noto Sans';"><span style=""><b>${this?.series?.name} :</b> </span><b>${amountFormatter(this.y)}</b></span>`;
                }
            },
            series: seriesData,
        };

        setOptions(chartOptions);
    }, [data]);

    return (
        <HighchartsReact
            options={options}
            highcharts={Highcharts}
            containerProps={{ style: { height: '100%', width: '100%', zIndex: 1 } }}
        />
    );
};


export const FullViewModeScatterChart = ({ color, open, setOpen, data, title, to_date, from_date, granularity, setGranularity }) => {
    return (
        <BootstrapDialog fullWidth open={open} maxWidth={'lg'}>
            <BootstrapDialogTitle id="customized-dialog-title" onClose={() => setOpen(false)}>
                <CustomTypography
                    text={title}
                    sx={{
                        color: '#000000',
                        fontSize: '16px',
                        fontWeight: '700',
                        lineHeight: '18px',
                        fontStyle: 'normal',
                        fontFamily: "Noto Sans",
                    }}
                />
            </BootstrapDialogTitle>
            <DialogContent dividers>
                <div style={{ position: 'relative' }}>
                    <CustomScatterChart
                        data={data}
                        color={color}
                        to_date={to_date}
                        from_date={from_date}
                        granularity={granularity}
                        setGranularity={setGranularity}
                    />
                    <div style={{ position: 'absolute', top: 9, right: 9 }}>
                        <GranularityComponent
                            to_date={to_date}
                            from_date={from_date}
                            granularity={granularity}
                            setGranularity={setGranularity}
                        />
                    </div>
                </div>
            </DialogContent>
        </BootstrapDialog>
    )
}