import React from 'react';
import CustomTitle from '../../../../../common/CustomTitle';
import CustomButton from '../../../../../custom/button/CustomButton';

import {
    CustomRadio,
    CustomHeading,
    CustomCheckbox,
    CustomHelperText,
} from '../common/CustomElement';

import {
    CustomContainer,
    CustomTitleContainer,
} from '../../../../../custom/container/CustomContainer';

import {
    Box,
    Grid,
} from '@mui/material'

const ItemPreferences = (props) => {
    const { initial_data, onSaveHandler } = props;

    const [data, setData] = React.useState({ ...initial_data });

    const onHandleChange = (key_name, value) => {
        setData((previous) => ({ ...previous, ...data, item: { ...data.item, [key_name]: value } }))
    }

    return (
        data &&
        <div>
            <CustomTitleContainer>
                <Grid container spacing={0} style={{
                    alignItems: "center",
                    justifyItems: "center",
                    alignContent: "space-evenly",
                    justifyContent: "space-evenly",
                }}>
                    <Grid item xs={6}>
                        <CustomTitle title={'Items Preferences'} />
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: "end", alignSelf: "center" }}>
                        <CustomButton
                            sx={{}}
                            btnLabel="Save"
                            variant="contained"
                            id={"save_items_preferences"}
                            dataTestId={"save_items_preferences"}
                            onClick={() => { onSaveHandler(data) }}
                        />
                    </Grid>
                </Grid>
            </CustomTitleContainer>
            <CustomContainer maxWidth={"400"} sx={{ height: 'calc(100vh - 134px)', }}>
                <Box sx={{ p: 2, pt: 1 }}>
                    <CodePreferences data={data} setData={setData} onHandleChange={onHandleChange} />
                    <Inventory data={data} setData={setData} onHandleChange={onHandleChange} />
                </Box>
            </CustomContainer>

        </div>
    )
}

export default ItemPreferences;

const CodePreferences = (props) => {
    const { data, setData, onHandleChange } = props;

    return (
        <Box sx={{ pb: 2, mb: 2, borderBottom: '1px solid #e0e0e0' }}>
            <CustomHeading label={`HSN Code or SAC`} />
            {/* <CustomCheckbox checked={data && data.item.hsn_or_sac_code} onClickedHandler={(value) => onHandleChange('hsn_or_sac_code', !value)} label={`Enable the HSN Code or SAC field`} /><br /> */}
            {/* {data && data.item.hsn_or_sac_code && */}
            {data &&
                <Box sx={{ mt: 2 }}>
                    <CustomHeading label={`Mandatory HSN Code or SAC Preference`}/>
                    <CustomHelperText label={`The Central Board of Indirect Taxes and Customs (CBIC) has mandated HSN Code or SAC for items effective from 1 April 2021.`} />

                    <Box sx={{ p: 2, backgroundColor: data.item[`4_digit_hsn_or_sac_code`] ? '#f9f9fb' : '' }}>
                        <CustomRadio id={`4_digit_hsn_or_sac_code`} dataTestId={`4_digit_hsn_or_sac_code`} checked={data && data.item[`4_digit_hsn_or_sac_code`]} onClickedHandler={(value) => setData((previous) => ({ ...previous, ...data, item: { ...data.item, [`4_digit_hsn_or_sac_code`]: true, [`6_or_8_digit_hsn_or_sac_code`]: false } }))} label={`4-digit HSN Code or SAC`} /><br />
                        <CustomHelperText label={`Select this option if your business’s annual turnover was less than ₹5 crores in the previous year. The 4-digit HSN Code or SAC is mandatory for B2B, SEZ, Export, or Deemed Export tax invoices and optional for B2C tax invoices.`} />
                    </Box>

                    <Box sx={{ p: 2, backgroundColor: data.item[`6_or_8_digit_hsn_or_sac_code`] ? '#f9f9fb' : '' }}>
                        <CustomRadio id={`6_or_8_digit_hsn_or_sac_code`} dataTestId={`6_or_8_digit_hsn_or_sac_code`} checked={data && data.item[`6_or_8_digit_hsn_or_sac_code`]} onClickedHandler={(value) => setData((previous) => ({ ...previous, ...data, item: { ...data.item, [`4_digit_hsn_or_sac_code`]: false, [`6_or_8_digit_hsn_or_sac_code`]: true } }))} label={`6 or 8-digit HSN Code or SAC`} /><br />
                        <CustomHelperText label={`Select this option if your business’s annual turnover was more than ₹5 crores in the previous year. The 6-digit HSN Code or SAC is mandatory for all tax invoices.`} />
                    </Box>

                </Box>
            }
        </Box>
    )
}

const Inventory = (props) => {
    const { data, setData, onHandleChange } = props;
    return (
        <Box sx={{ pb: 2, mb: 2, borderBottom: '1px solid #e0e0e0' }}>
            <CustomHeading label={`Inventory`} />
            <CustomCheckbox  id={`enable_inventory`} dataTestId={`enable_inventory`} checked={data && data.item.enable_inventory} onClickedHandler={(value) => onHandleChange('enable_inventory', !value)} label={`Enable Inventory Tracking`} /><br />
            {/* {data && data.item.enable_inventory &&
            <Box sx={{ mt: 3,borderTop: '1px solid #e0e0e0'}}>
            <CustomCheckbox checked={data && data.item.out_of_stock_warning} onClickedHandler={(value) => onHandleChange('out_of_stock_warning', !value)} label={<span style={{marginTop:'10px'}}>Show an Out of Stock warning when an item's stock drops below zero</span>} /><br />
            <CustomCheckbox checked={data && data.item.notify_items_low} onClickedHandler={(value) => onHandleChange('notify_items_low', !value)} label={`Notify me if an item's quantity reaches the reorder point`} /><br />
            </Box>
            } */}
        </Box>
    )
}