import React from 'react';
import CustomTypography from '../../../custom/typography/CustomTypography';
import { ReactComponent as SyncIcon } from '../../../../assets/svg/sync.svg';
import { ReactComponent as AccuracyIcon } from '../../../../assets/svg/Accuracy.svg';
import { ReactComponent as TimeSavingIcon } from '../../../../assets/svg/Timesaving.svg';
import { ReactComponent as AccessibleIcon } from '../../../../assets/svg/Accessible.svg';
import { ReactComponent as AffordablePricingIcon } from '../../../../assets/svg/Affordablepricing.svg';
import { ReactComponent as UserFriendlyInterfaceIcon } from '../../../../assets/svg/UserFriendlyInterface.svg';


import {
    Box,
    Grid,
} from '@mui/material';


const WhyChoose = () => {
    return (

        <Box sx={{ pb:{xs:2,sm:6}, }}>
            <Grid container sx={{}}>
                <Grid item xs={12} sx={{ pb: 6, pt: {xs:2,sm:4}, textAlign: "center" }} >
                    <CustomTypography
                        text={'Why Choose Finycs?'}
                        sx={{
                            color: '#141414',
                            fontWeight: 700,
                            lineHeight: '56px',
                            letterSpacing: '0px',
                            fontSize: { xs: '36px', sm: '40px' },
                        }}
                    />
                </Grid>
                <Grid item xs={12} container spacing={3}>
                    {cardData?.map((item,index) => (
                        <Grid item xs={12} sm={4} key={index}>
                            <CustomCard
                                icon={item.icon}
                                title={item.title}
                                message={item.message}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Box>
    )
}

export default WhyChoose


const CustomCard = (props) => {
    const { icon, title, message } = props
    let Icon = icon
    return (
        <Box
            sx={{
                padding: '20px',
                // maxWidth: '411px',
                // maxHeight: '234px',
                borderRadius: '8px',
                border: '1px solid #E0E0E0'
            }}>
            <Box sx={{
                // maxWidth: '363px',
                // maxHeight: '186px',
            }}>
                <Box sx={{
                    width: '48px',
                    height: '48px',
                    display: 'flex',
                    borderRadius: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#EBF1FF'
                }}>
                    <Icon />
                </Box>

                <Box>
                    <CustomTypography
                        text={title}
                        sx={{
                            mt: 2,
                            fontWeight: 700,
                            color: '#141414',
                            fontSize: '20px',
                            lineHeight: 'auto',
                            letterSpacing: '0px',
                        }}
                    />
                    <CustomTypography
                        text={message}
                        sx={{
                            mt: 2,
                            fontSize: '14px',
                            fontWeight: 400,
                            color: '#666666',
                            lineHeight: '20px',
                            fontStyle: 'normal',
                            letterSpacing: '0.2px',
                        }}
                    />
                </Box>
            </Box>
        </Box>
    )
}

const cardData = [
    {
        icon: AccuracyIcon,
        title: `Accuracy`,
        message: `Finycs is designed to help ensure accuracy in financial reporting. It provides a range of tools and features that helps to minimise errors and improve accuracy in financial data.`,
    },
    {
        icon: AffordablePricingIcon,
        title: `Affordable Pricing`,
        message: `Finycs provides affordable pricing plans that cater to small businesses accounting needs. The pricing plans are affordable and designed to fit different business sizes and budgets.`,
    },
    {
        icon: TimeSavingIcon,
        title: `Time Saving`,
        message: `Finycs has features that save time and make managing finances easier. This reduces manual work, letting users focus on other important business tasks.`,
    },
    {
        icon: AccessibleIcon,
        title: `Accessible`,
        message: `Finycs is available on Web and Android, making it accessible and convenient. Businesses can access their financial data anytime, anywhere from their mobile devices, laptops, or desktop computers.`,
        // message: `Finycs is available on Web, Android, and iOS, making it accessible and convenient. Businesses can access their financial data anytime, anywhere from their mobile devices, laptops, or desktop computers.`,
    },
    {
        icon: UserFriendlyInterfaceIcon,
        title: `User Friendly Interface`,
        message: `Finycs is designed to provide a user-friendly experience for its users. It has a modern and intuitive interface that is easy to navigate, even for users with limited computer and accounting knowledge.`,
    },
    {
        icon: SyncIcon,
        title: `Tally Integration`,
        message: `Seamless Tally Integration with Finycs for Real-Time Tally Data Accessibility. Quickly access and analyze your Tally data from any device, whether it's a mobile app or a web app, in just minutes.`,
    }
]