import React from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CustomTypography from '../../../../../../custom/typography/CustomTypography';

const BankDetailsList = ({ list, defaultAccount, onClick }) => (
  <div>
    {list?.map((item, index) => (
      <BankItem
        key={index}
        bank_name={item.bank_name}
        account_number={item.account_number}
        onClick={() => onClick(item, index)}
        isSelected={defaultAccount?.account_number === item.account_number}
      />
    ))}
  </div>
);

export default React.memo(BankDetailsList);

const BankItem = React.memo(({ bank_name, account_number, isSelected, onClick }) => (
  <Box
    sx={{
      cursor: "pointer",
      borderBottom: "2px solid #F5F5F5",
      ":hover": { backgroundColor: "#f5f5f5" },
    }}
    onClick={onClick}
  >
    <Grid container sx={{ p: 2, pt: 0.5, pb: 0 }}>
      <Grid item xs={8}>
        <Box sx={{ p: 1, display: "flex" }}>
          <Box sx={{ alignSelf: "center" }}>
            <CustomTypography
              text={
                <span>
                  {bank_name}
                  <span style={{ fontSize: '10px', opacity: 0.8, marginLeft: '5px' }}>
                    {isSelected ? '(Default)' : ''}
                  </span>
                </span>
              }
              sx={{
                mb: 1,
                color: "#000000",
                fontSize: "14px",
                fontWeight: "700",
                lineHeight: "18px",
                fontStyle: "normal",
                fontFamily: "Noto Sans",
              }}
            />
            <CustomTypography
              text={account_number}
              sx={{
                mb: 0.5,
                color: "#7A7A7A",
                fontSize: "12px",
                fontWeight: "500",
                lineHeight: "18px",
                fontStyle: "normal",
                fontFamily: "Noto Sans",
              }}
            />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={4} sx={{ textAlign: "end", alignSelf: "center" }}>
        <IconButton aria-label="ArrowForwardIosIcon" size="large">
          <ArrowForwardIosIcon fontSize="inherit" />
        </IconButton>
      </Grid>
    </Grid>
  </Box>
));
