import {
    styled,
} from '@mui/material'

import {
    Table,
    TableRow,
    TableBody,
    TableCell,
    TableHead,
    TableContainer,
} from '@mui/material';

export const CustomTable = styled((props)=><Table stickyHeader {...props}/>)(({ theme }) =>({
    // paddingBottom: '32px !important',
}));

export const CustomTableRow = styled(TableRow)({
});

export const CustomTableBody = styled(TableBody)({
});

export const CustomTableBodyCell = styled(TableCell)({
    color: '#000000',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '16px',
    fontStyle: 'normal',
    fontFamily: 'Noto Sans',
    borderBottom: '1px solid #F5F5F5'
});

export const CustomTableCell = styled(TableCell)({
    border: 'none',
    color: '#7A7A7A',
    fontSize: '12px',
    fontWeight: '600',
    lineHeight: '16px',
    fontStyle: 'normal',
    fontFamily: 'Noto Sans',
});

export const CustomTableHead = styled(TableHead)({
    backgroundColor: '#FAFAFA',
    border: '1px solid #F2F2F2',
});

export const CustomTableHeadCell = styled(TableCell)({
    color: '#939393',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    fontStyle: 'normal',
    fontFamily: 'Noto Sans',
});

export const CustomTableContainer = styled(TableContainer)({
    height:'100%',
    minWidth:'100%',
    maxWidth: 'calc(100vw - 270px)',
});
