import moment from "moment/moment";
import Emails from "../../common/Emails";
import React, { useContext } from "react";
import "react-multi-email/dist/style.css";
import { TaxList } from "../../common/GST";
import Input from "../../../../common/Input";
import * as Common from "../../common/Common";
import { useLocation } from "react-router-dom";
import { ListTerms } from "../../common/Terms";
import { useNavigate } from "react-router-dom";
import DateRange from "../../../../common/DateRange";
import DatePicker from "../../../../common/DatePicker";
import * as Actions from "../../../../../state/Actions";
import CustomTitle from "../../../../common/CustomTitle";
import CommonAlertBox from "../../common/CommonAlertBox";
import { InputFile } from "../../../../common/InputFile";
import PrintAndPreview from "../../common/PrintAndPreview";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import AsyncDropdown from "../../../../common/AsyncDropdown";
import { routesName } from "../../../../../config/routesName";
import CommonCalculation from "../../common/CommonCalculation";
import CommonDropdown from "../../../../common/CommonDropdown";
import { input_css_style } from "../../../../../utils/Constant";
import { apiAction, apiFormData } from "../../../../../api/api";
import CustomDialog from "../../../../custom/dialog/CustomDialog";
import CustomButton from "../../../../custom/button/CustomButton";
import { getBusinessInfo } from "../../../../../config/cookiesInfo";
import CurrencyFormatter from "../../../../common/CurrencyFormatter";
import { LabelWithAsteriskMark } from "../../../../common/CommonLabel";
import CommonPreviewShare from "../../../../common/CommonPreviewShare";
import CommonAsyncDropdown from "../../../../common/CommonAsyncDropdown";
import CommonCurrencySelection from "../../common/CommonCurrencySelection";
import CustomGroupButton from "../../../../custom/button/CustomGroupButton";
import CustomTypography from "../../../../custom/typography/CustomTypography";
import { eventsNames,AnalyticsEvent } from "../../../../../firebase/firebaseAnalytics";

import {
  getNotesPreferences,
  getDiscountPreferences,
  getAmountArePreferences,
} from "../../../setting/settings/Preferences/common/getPreferences";

import {
  CustomContainer,
  CustomTitleContainer,
  CustomButtonContainer,
} from "../../../../custom/container/CustomContainer";

import {
  setLoader,
  filterTax,
  getAddress,
  isFormValid,
  filterArray,
  getQueryParams,
  findDefaultTax,
  isVendorRegister,
  stateChangeManager,
  isApplicableForTax,
  setIsVendorRegister,
  breadCrumbsStateChangeManager,
} from "../../../../../utils/Utils";

import {
  basePriceTotal,
  checkItemDiscount,
  totalDiscountOnBasePrice,
  isFinalAmountRangeCrossed,
  convertExclusiveToInclusive,
  convertInclusiveToExclusive,
  applyTaxOnMultipleGSTComponents,
} from "../../sales/invoices/InvoiceManager";

import {
  get_item,
  list_items,
  list_party,
  get_vendor,
  get_list_of_tax,
  get_list_of_state,
  update_purchase_order,
  create_purchase_order,
  preview_purchase_order,
  retrieve_purchase_order,
  send_purchase_order_mail,
  get_chart_of_account_nested_nodes,
  get_create_purchase_order_meta_data,
} from "../../../../../api/urls";

import { Box, Grid, IconButton, FormControlLabel, fabClasses } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  CustomTable,
  CustomTableRow,
  CustomTableBody,
  CustomTableHead,
  CustomTableHeadCell,
  CustomTableBodyCell,
  CustomTableContainer,
} from "../../../../custom/table/CustomTable";

const Dropdown = DateRange;

// eslint-disable-next-line
const isNumeric = (num) =>
  (typeof num === "number" || (typeof num === "string" && num.trim() !== "")) &&
  !isNaN(num);

const CreatePurchaseOrder = (props) => {
  const { onClose, vendor } = props;

  let location = useLocation();
  const navigate = useNavigate();
  const params = getQueryParams(location.search);
  const dispatch = Actions.getDispatch(React.useContext);
  const [isFormSubmitted, setFormSubmitted] = React.useState(false);
  const [state, setState] = React.useState({
    id: "",
    title: "",
    open: false,
    condition: "",
    maxWidth: "lg",
    compulsory: "",
    fullWidth: true,
  });

  const [zero, setZero] = React.useState(false);
  const [saveAndSend, setSaveAndSend] = React.useState(false);

  const [itemIndex, setItemIndex] = React.useState(null);

  const addButtonHandler = (title, condition, maxWidth, compulsory, index) => {
    setState({
      open: true,
      title: title,
      fullWidth: true,
      maxWidth: maxWidth,
      condition: condition,
      compulsory: compulsory,
    });
    setItemIndex(index);
    if (index !== null || index !== undefined) {
      setPurchaseOrder({ ...purchaseOrder });
    }
  };

  const [purchaseOrder, setPurchaseOrder] = React.useState({
    vendor_id: null,
    vendor_emails: {
      cc: [],
      to: [],
      bcc: [],
    },
    tax: null,
    total_amount: 0,
    discount: 0,
    reference_number: null,
    payment_term: null,
    place_of_supply: null,
    payment_method: null,
    sales_person_id: null,
    payment_status: null,
    payment_term_id: null,
    place_of_supply_id: null,
    purchase_order_number: null,
    entity_level_discount: null,
    terms_and_conditions_id: null,
    business_id: getBusinessInfo().id,
    expected_delivery_date: null,
    purchase_order_date: moment().format("YYYY-MM-DD"),
    terms_and_condition: null,
    purchase_order_items: [
      {
        item: null,
        tax: null,
        rate: null,
        quantity: 1,
        tax_id: null,
        amount: null,
        item_id: null,
        unit_id: null,
        item_order: null,
        expense_category: null,
        item_level_discount: 0,
        expense_category_id: null,
        is_item_discount_in_percentage: true,
      },
    ],

    is_draft: false,

    // Address
    note: getNotesPreferences('purchase_order'),
    mailing_address: null,
    shipping_address: null,
    shipment_preference: null,
    same_as_billing_address: false,
    is_entity_discount: true,
    tax_applied: getAmountArePreferences(),
    is_amount_tax_inclusive: getAmountArePreferences() === 'inclusive',
    currency_symbol: null,
    currency_code: null,
    exchange_rate: 1,

    newVendor: null,
    itemIndex: null,
    newItem: null,

    source_of_supply_id: null,
    selected_source_of_supply: null,
  });

  React.useEffect(() => {
    const getPurchaseOrder = async () => {
      setLoader(dispatch, Actions, true);
      if (params.id) { breadCrumbsStateChangeManager(dispatch, 'Edit') }
      let res = await apiAction({
        method: "post",
        data: { business_id: getBusinessInfo().id },
        url: retrieve_purchase_order(params.id ? params.id : params.clone_id),
      });
      if (res?.success) {
        const partyRes = await apiAction({
          method: "post",
          url: get_vendor(res?.result.vendor_id),
          data: { business_id: getBusinessInfo().id },
        });
        setLoader(dispatch, Actions, false);

        let item = res?.result;
        item.purchase_order_items.map((item) => {
          if (item.tax) {
            item["tax_id"] = item.tax.id;
            item["item"] = {
              name: item.item,
              id: item.item_id,
              gst_rate: item.tax.rate ? item.tax.rate : null,
            };
            item["expense_category_id"] = item.expense_category;
            item["expense_category"] = {
              name: item.expense_category_name,
              id: item.expense_category,
              account_name: item.expense_category_name,
            };
          } else {
            item["tax_id"] = null;
            item["item"] = {
              name: item.item,
              id: item.item_id,
              gst_rate: null,
            };
            item["expense_category_id"] = item.expense_category;
            item["expense_category"] = {
              name: item.expense_category_name,
              id: item.expense_category,
              account_name: item.expense_category_name,
            };
          }
        });

        setPurchaseOrder({
          ...purchaseOrder,
          ...item,
          vendor: partyRes?.result,

          expected_delivery_date: params.clone_id
            ? null
            : item.expected_delivery_date,
          purchase_order_date: params.clone_id
            ? moment().format("YYYY-MM-DD")
            : item.purchase_order_date,
          purchase_order_number: params.clone_id
            ? purchaseOrder.purchase_order_number
            : item.purchase_order_number,

          payment_term_id: item.payment_term,
          payment_term: { id: item.payment_term, name: item.payment_term_name },

          is_amount_tax_inclusive:
            item.tax_applied === "inclusive" ? true : false,

          source_of_supply_id: res?.result.source_of_supply.id,
          selected_source_of_supply: res?.result.source_of_supply.id
            ? {
              ...res?.result.source_of_supply,
              name: res?.result.source_of_supply.state,
            }
            : null,
        });
      }
    };

    if (params.id || params.clone_id) {
      setTimeout(() => {
        getPurchaseOrder();
      }, 1000);
    }
    // eslint-disable-next-line
  }, []);

  // api call to set the default purchase_order_number
  React.useEffect(() => {
    const apiResults = async () => {
      let res = await apiAction({
        method: "post",
        url: get_create_purchase_order_meta_data(),
        data: { business_id: getBusinessInfo().id },
      });
      console.log("res", res);
      if (res?.success) {
        purchaseOrder.purchase_order_number = res?.result.purchase_order_number;
        setPurchaseOrder({
          ...purchaseOrder,
          purchase_order_number: res?.result.purchase_order_number,
        });
      } else {
      }
    };
    if (!params.id) {
      setTimeout(() => {
        apiResults();
      }, 100);
    }

    // eslint-disable-next-line
  }, []);

  const onClear = () => {
    setFormSubmitted(false);

    setPurchaseOrder({
      vendor_id: null,
      vendor_emails: {
        cc: [],
        to: [],
        bcc: [],
      },
      tax: null,
      total_amount: 0,
      discount: 0,
      reference_number: null,
      payment_term: null,
      place_of_supply: null,
      payment_method: null,
      sales_person_id: null,
      payment_status: null,
      payment_term_id: null,
      place_of_supply_id: null,
      entity_level_discount: null,
      terms_and_conditions_id: null,
      business_id: getBusinessInfo().id,
      expected_delivery_date: null,
      purchase_order_date: moment().format("YYYY-MM-DD"),
      purchase_order_number: purchaseOrder.purchase_order_number,
      terms_and_condition: null,
      purchase_order_items: [
        {
          item: null,
          tax: null,
          rate: null,
          quantity: 1,
          tax_id: null,
          amount: null,
          item_id: null,
          unit_id: null,
          item_order: null,
          expense_category: null,
          item_level_discount: 0,
          expense_category_id: null,
          is_item_discount_in_percentage: checkItemDiscount(purchaseOrder?.purchase_order_items),
        },
      ],

      is_draft: false,

      // Address
      note: getNotesPreferences('purchase_order'),
      mailing_address: null,
      shipping_address: null,
      shipment_preference: null,
      same_as_billing_address: false,
      is_entity_discount: true,
      tax_applied: getAmountArePreferences(),
      is_amount_tax_inclusive: getAmountArePreferences() === 'inclusive',
      currency_symbol: null,
      currency_code: null,
      newVendor: null,
      itemIndex: null,
      newItem: null,

      source_of_supply_id: null,
      selected_source_of_supply: null,
    });
  };

  //////////////////////////////////////////////////////////////////////////////////////////
  const gst_registration_type = purchaseOrder.vendor && purchaseOrder.vendor.gst_registration_type;
  setIsVendorRegister(gst_registration_type);

  let is_Location = gst_registration_type === "overseas";
  let is_gst_with_zero = gst_registration_type === "overseas" || gst_registration_type === "sez";
  //////////////////////////////////////////////////////////////////////////////////////////
  const onSave = async ({ is_save_send = false }) => {
    console.log("purchase Order On save====>", purchaseOrder);

    setFormSubmitted(true);
    const { tax_applied, purchase_order_items, vendor_emails } = purchaseOrder;
    const { to } = vendor_emails;
    const is_rate = purchase_order_items.find((item) => item.rate === null);
    const is_quantity = purchase_order_items.find((item) => !item.quantity);
    const is_tax = purchase_order_items.find(
      (item) => tax_applied !== "no_tax" && !item.tax_id
    );
    const is_purchase_order_items_not_selected = purchase_order_items.find(
      (item) => !item.item_id
    );
    const is_expense_category_not_selected = purchase_order_items.find(
      (item) => !item.expense_category_id
    );

    let validation_data = [
      { key: "vendor_id", message: "Please Select Vendor" },
      // {
      //   key: "",
      //   validation: to.length === 0,
      //   message: "Please enter valid email.",
      // },
      {
        key: "purchase_order_number",
        message: "Please Enter Purchase Order Number",
      },
      isVendorRegister && !is_Location && {
        key: "source_of_supply_id",
        message: "Please Select Source of Supply",
      },
      { key: "payment_term_id", message: "Please Select Terms" },
      {
        key: "purchase_order_date",
        message: "Please Enter Valid Purchase Order Date",
      },
      // { key: "mailing_address", message: 'Please Enter Mailing Address' },
      {
        key: "",
        validation: is_purchase_order_items_not_selected,
        message: "Please Select Item",
      },
      {
        key: "",
        validation: is_expense_category_not_selected,
        message: "Please Select Account",
      },
      {
        key: "",
        validation: is_quantity,
        message: "Please Enter the Quantity",
      },
      { key: "", validation: is_rate, message: "Please Enter the Rate" },
      { key: "", validation: is_tax, message: "Please Select the Tax" },
    ];
    const { isValid, message } = isFormValid(purchaseOrder, validation_data);

    if (isValid) {
      purchaseOrder.vendor_emails.to=filterArray(purchaseOrder.vendor_emails.to)

      if (isApplicableForTax(purchaseOrder.tax_applied,isVendorRegister)) {
        setZero('isApplicableForTax');
        setSaveAndSend(is_save_send);
      } else if (purchaseOrder.total_amount === 0) {
        setZero('withZero');
        setSaveAndSend(is_save_send);
      } else {
        onSaveAction(is_save_send);
      }
    } else {
      stateChangeManager(dispatch, Actions, true, "error", message);
    }
  };

  const onSaveAction = async (is_save_send) => {
    if (params.id) {
      AnalyticsEvent(eventsNames.categories.PURCHASE_ORDERS,{action:eventsNames.actions.EDIT});
    } else {
      AnalyticsEvent(eventsNames.categories.PURCHASE_ORDERS,{action:eventsNames.actions.CREATE});
    }
    setLoader(dispatch, Actions, true);
    let res = await apiFormData({
      method: "post",
      data: purchaseOrder,
      url: params.id
        ? update_purchase_order(params.id)
        : create_purchase_order(),
    });
    if (res?.success) {
      setLoader(dispatch, Actions, false);
      stateChangeManager(dispatch, Actions, true, "success", res?.status);
      if (is_save_send) {
        sendPurchaseOrder(res?.result.id);
      } else {
        redirect(res?.result.id);
        setLoader(dispatch, Actions, false);
      }
    } else {
      setLoader(dispatch, Actions, false);
      stateChangeManager(dispatch, Actions, true, "error", res?.status);
    }
  };

  const redirect = (id) => {
    if (onClose) {
      onClose(id);
    } else {
      navigate(routesName.invoicingPurchaseOrderView.path + "?id=" + id);
    }
  };

  const sendPurchaseOrder = async (purchaseOrder_id) => {
    setSendMail(true);
    setSendMailId(purchaseOrder_id);
  };

  const onAddVendor = (vendor) => {
    setPurchaseOrder({ ...purchaseOrder, newVendor: vendor });
  };

  const onAddItem = (value) => {
    // Construct the new item data
    const newItemData = {
      item: value,
      quantity: 1,
      item_order: null,
      item_id: value.id,
      unit_id: value?.unit?.id,
      amount: value.cost_price,
      description: value.description,
      hsn_sac_code: value.hsn_sac_code,
      rate: parseFloat(value.cost_price),
      item_level_discount: value.item_level_discount || 0,
      is_item_discount_in_percentage: checkItemDiscount(purchaseOrder?.purchase_order_items),

      gst_rate: value?.tax?.rate,
      tax: purchaseOrder.tax_applied === "no_tax" ? null: value?.tax,
      tax_id: purchaseOrder.tax_applied === "no_tax" ? null : value?.tax?.id,
    };

    // Update the sales order items
    purchaseOrder.purchase_order_items[itemIndex] = newItemData;

    // Update the sales order state
    setPurchaseOrder(prevOrder => ({
      ...prevOrder,
      newItem: value,
      purchase_order_items: [...prevOrder.purchase_order_items],
    }));

  };

  // Common api call for the Accounts
  const [NewAccountsResult, setNewAccountsResult] = React.useState();

  const onAddAccount = (value) => {
    purchaseOrder.purchase_order_items[itemIndex] = {
      ...purchaseOrder.purchase_order_items[itemIndex],
      expense_category: value,
      expense_category_id: value.id,
    };
    setNewAccountsResult(value);
    setPurchaseOrder({
      ...purchaseOrder,
      purchase_order_items: [...purchaseOrder.purchase_order_items],
    });
  };


  const [sendMail, setSendMail] = React.useState(false);
  const [sendMailId, setSendMailId] = React.useState(false);
  return (
    <div>
      <CustomDialog
        state={state}
        setState={setState}
        itemIndex={purchaseOrder.itemIndex}
        onAddItem={(item) => onAddItem(item)}
        onAddVendor={(vendor) => onAddVendor(vendor)}
        onAddAccount={(account) => onAddAccount(account)}
      />
      <CommonPreviewShare
        open={sendMail}
        id={sendMailId}
        type={"purchase_order"}
        file_key={"purchase_order_number"}
        export_Url={preview_purchase_order}
        retrieve_Url={retrieve_purchase_order}
        setOpen={(value) => {
          setSendMail(value);
          redirect(sendMailId);
        }}
      />
      <CommonAlertBox
        open={zero}
        setOpen={setZero}
        onSaveAction={() => {
          if (zero==='withZero') {
            onSaveAction(saveAndSend);
          }else if (purchaseOrder.total_amount === 0) {
            setTimeout(()=>{
              setZero('withZero');
            },100)
          } else{
            onSaveAction(saveAndSend);
          }
        }}
        title={`Purchase Order No. ${purchaseOrder.purchase_order_number? purchaseOrder.purchase_order_number: ""}`}
        message={
          zero==='withZero'?
          `You are about to create the purchase order with zero amount. Are you sure you want to proceed?`
          :
          `Please consult the relevant provisions of the laws to determine the application of taxes for GST unregistered entities.`
        }
      />
      <CustomTitleContainer>
        <Grid
          container
          spacing={0}
          style={{
            alignItems: "center",
            justifyItems: "center",
            alignContent: "space-evenly",
            justifyContent: "space-evenly",
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: "-webkit-flex", justifyContent: "start" }}
          >
            <CustomTitle
              title={`Purchase Order No. ${purchaseOrder.purchase_order_number
                ? purchaseOrder.purchase_order_number
                : ""
                }`}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: "-webkit-flex", justifyContent: "end" }}
          >
            <CustomTypography
              text={
                <span>
                  Balance Due:{" "}
                  <span style={{ fontWeight: "700", color: "#000000" }}>
                    <CurrencyFormatter
                      amount={purchaseOrder.total_amount}
                      currency={purchaseOrder.currency_code}
                    />
                  </span>
                </span>
              }
              sx={{
                fontSize: "16px",
                color: "#7A7A7A",
                fontWeight: "600",
                textAlign: "right",
                lineHeight: "22px",
                fontStyle: "normal",
                fontFamily: "Noto Sans",
              }}
            />
          </Grid>
        </Grid>
      </CustomTitleContainer>

      <CustomContainer maxWidth={"400"} sx={{}}>
        <form noValidate>
          <div style={{ borderBottom: "4px solid #F5F5F5" }}>
            <VendorDetails
              open={state.open}
              is_Location={is_Location}
              purchaseOrder={purchaseOrder}
              isFormSubmitted={isFormSubmitted}
              is_gst_with_zero={is_gst_with_zero}
              setPurchaseOrder={setPurchaseOrder}
              addButtonHandler={addButtonHandler}
              vendor={props.vendor ? props.vendor : null}
            />
          </div>

          <ProductAndServicesDetails
            is_Location={is_Location}
            purchaseOrder={purchaseOrder}
            isFormSubmitted={isFormSubmitted}
            setPurchaseOrder={setPurchaseOrder}
            is_gst_with_zero={is_gst_with_zero}
            addButtonHandler={addButtonHandler}
            NewAccountsResult={NewAccountsResult}
            data={purchaseOrder.purchase_order_items}
          />
        </form>
      </CustomContainer>

      <CustomButtonContainer>
        <Buttons
          onSave={onSave}
          onClear={onClear}
          is_Location={is_Location}
          onCancel={() => navigate(-1)}
          purchaseOrder={purchaseOrder}
          is_gst_with_zero={is_gst_with_zero}
          setFormSubmitted={setFormSubmitted}
        />
      </CustomButtonContainer>
    </div>
  );
};

const VendorDetails = (props) => {
  const {
    is_gst_with_zero,
    is_Location,
    open,
    purchaseOrder,
    setPurchaseOrder,
    addButtonHandler,
    isFormSubmitted,
    isClear,
    isFromCustomer,
    vendor,
  } = props;
  const { vendor_emails } = purchaseOrder;

  let location = useLocation();
  const params = getQueryParams(location.search);

  const onChange = (key_name, value) => {
    setPurchaseOrder({ ...purchaseOrder, [key_name]: value });
  };

  const onFetchTerms = (terms) => {
    let term = terms?.length > 0 ? terms[0] : null;
    if (term && Object.keys(params).length === 0) {
      setTimeout(() => {
        setPurchaseOrder((previous) => ({
          ...previous,
          payment_term_id: term.id,
          expected_delivery_date: moment().add(term.number_of_days, "days").format("YYYY-MM-DD"),
        }));
      }, 1000);
    }
  };

  const vendorMappings = (vendor) => {
    setIsVendorRegister(vendor?.gst_registration_type);
    let taxType= isVendorRegister ?'exclusive':'no_tax'
    if (vendor) {
      let party_contact_data = vendor.party_contact_data.find(
        (data) => data.contact_type === "primary"
      );
    
      setPurchaseOrder({
        ...purchaseOrder,
        vendor: vendor, vendor_emails: { ...purchaseOrder.vendor_emails, to: party_contact_data.email?filterArray([party_contact_data.email]):[], },
        vendor_id: vendor.id,
        mailing_address: vendor.billing_address ? getAddress(vendor.billing_address) : null,
        payment_term: { id: vendor.payment_term.id, name: vendor.payment_term.name, number_of_days: vendor.payment_term.number_of_days, },
        payment_term_id: vendor.payment_term ? vendor.payment_term.id : null,
        payment_method: vendor.preferred_payment_method ? vendor.preferred_payment_method : null,

        currency_id: vendor.currency ? vendor.currency.id : null,
        currency_symbol: vendor.currency ? vendor.currency.symbol : null,
        currency_code: vendor.currency ? vendor.currency.currency_code : null,

        source_of_supply_id: null,
        selected_source_of_supply: null,

        tax_applied:taxType ,
        is_amount_tax_inclusive: taxType === 'inclusive',
      });
    } else {
      setPurchaseOrder({
        ...purchaseOrder,
        vendor: null,
        vendor_id: null,
        payment_term: null,
        currency_code: null,
        currency_symbol: null,
        payment_method: null,
        mailing_address: null,
        payment_term_id: null,
        vendor_emails: { cc: [], to: [], bcc: [] },

        tax_applied:taxType ,
        is_amount_tax_inclusive: taxType === 'inclusive',
      });
    }
  };

  React.useEffect(() => {
    if (purchaseOrder.newVendor) {
      vendorMappings(purchaseOrder.newVendor);
    }
    // eslint-disable-next-line
  }, [purchaseOrder.newVendor]);

  const setEmails = (emails) => {
    setPurchaseOrder({
      ...purchaseOrder,
      vendor_emails: { ...vendor_emails, cc: emails.cc, bcc: emails.bcc },
    });
  };
  const [clickedOutside, setClickedOutside] = React.useState(true);
  const myRef = React.useRef();

  const handleClickOutside = (e) => {
    if (!myRef.current.contains(e.target)) {
      setClickedOutside(true);
    }
  };

  const handleClickInside = () => setClickedOutside(false);

  React.useEffect(() => {
    document?.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });


  return (
    <Box
      style={{
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingBottom: "32px",
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4.5}>
          <CommonAsyncDropdown
            id={"vendor_dropdown"}
            dataTestId={"vendor_dropdown"}
            autoFocus={true}
            autoSelect={false}
            disableClearable={false}
            optionLabel="display_name"
            placeholder="Select Vendor"
            noOptionsText={"No result found"}
            validation={isFormSubmitted && !purchaseOrder.vendor_id}
            item={{
              open: open,
              method: "post",
              url: list_party(1),
              value: purchaseOrder.vendor,
              label: <LabelWithAsteriskMark label={"Vendor"} />,
              body: {
                is_inactive: false,
                business_id: getBusinessInfo().id,
                role: "vendor",
              },
              disabled: !params.clone_id && Object.keys(params).length !== 0,
              onChange: async (event, newValue) => {
                if (newValue) {
                  if (newValue.id !== purchaseOrder.vendor_id) {
                    const vendorRes = await apiAction({
                      method: "post",
                      url: get_vendor(newValue.id),
                      data: { business_id: getBusinessInfo().id },
                    });
                    if (vendorRes?.success) {
                      vendorMappings(vendorRes.result);
                    }
                  }
                } else {
                  vendorMappings(newValue);
                }
              },
            }}
            addButton={{
              title: "+ Add new vendor",
              onClick: () => addButtonHandler("New Vendor", "new_vendor", "lg"),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4.5}>
          <Grid item xs={12} container>
            <Grid item xs={6}>
              <CustomTypography
                text={"Vendor email"}
                // text={<LabelWithAsteriskMark label={"Vendor email"} />}
                sx={{
                  mb: 1,
                  fontSize: "14px",
                  color: "#000000",
                  fontWeight: "700",
                  lineHeight: "18px",
                  fontStyle: "normal",
                  textTransform: "none",
                  fontFamily: "Noto Sans",
                  textTransform: "capitalize",
                }}
              />
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "right" }}>
              <Emails
                setEmail={setEmails}
                value={purchaseOrder}
                setValue={setPurchaseOrder}
                customer_emails={vendor_emails}
              />
            </Grid>
          </Grid>
          <div onClick={handleClickInside} ref={myRef}>
            <ReactMultiEmail
              id={"input_to_email"}
              dataTestId={"input_to_email"}
              emails={filterArray(purchaseOrder.vendor_emails.to)}
              onChange={(emails) => {
                setPurchaseOrder({
                  ...purchaseOrder,
                  vendor_emails: { ...vendor_emails, to: emails },
                });
              }}
              placeholder={
                <p
                  style={{
                    margin: "0px",
                    marginTop: "-8px",
                    position: "sticky",
                  }}
                >
                  <span
                    style={{
                      margin: 0,
                      height: "100%",
                      overflow: "hidden",
                      color: "#2F2F2E",
                      fontWeight: "400",
                      lineHeight: "0px",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontFamily: "Noto Sans",
                      opacity: 0.5,
                    }}
                  >
                    Enter Email Address
                  </span>
                </p>
              }
              style={{
                marginTop: "0px",
                textAlign: "center",
                borderRadius: "4px",
                overflowY: "scroll",
                maxHeight: 20,
                borderColor: clickedOutside
                  ?
                  //  isFormSubmitted &&
                  //   purchaseOrder.vendor_emails.to.length === 0
                  //   ? "#d32f2f":
                  '#c7c7c7'
                  : "#2464EB",
                borderWidth: clickedOutside ? 1.5 : 2,
              }}
              getLabel={(email, index, removeEmail) => {
                return (
                  <div data-tag key={index} style={{ ...input_css_style }}>
                    {email}
                    <span data-tag-handle onClick={() => removeEmail(index)}>
                      ×
                    </span>
                  </div>
                );
              }}
              validateEmail={(email) => {
                if (email) {
                  return isEmail(email)
                } else {
                  return false
                }
                // return email?isEmail(email):false; // return boolean
              }}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Input
            id={"input_order_number"}
            dataTestId={"input_order_number"}
            item={{
              type: "text",
              required: true,
              fullWidth: true,
              texttransform: "none",
              placeholder: "Enter Purchase Order Number",
              title: <LabelWithAsteriskMark label={"Purchase Order Number"} />,
              onChange: (e) =>
                onChange("purchase_order_number", e.target.value),
              validation:
                isFormSubmitted && !purchaseOrder.purchase_order_number,
              value: purchaseOrder.purchase_order_number
                ? purchaseOrder.purchase_order_number
                : "",
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} sx={{ pt: 4 }}>
        {/*//////////////////////////////// Source of supply ////////////////////////////////*/}

          <Grid item xs={12} sm={2.25}>
            <CommonDropdown
              stateComponent={true}
              disableClearable={false}
              placeholder="Select a Location"
              id={"source_of_supply_dropdown"}
              dataTestId={"source_of_supply_dropdown"}
              validation={
                isVendorRegister &&
                !is_Location &&
                isFormSubmitted &&
                !purchaseOrder.source_of_supply_id
              }
              item={{
                method: "post",
                textTransform: "none",
                url: get_list_of_state(),
                disabled: is_Location,
                body: { business_id: getBusinessInfo().id },
                value: purchaseOrder.selected_source_of_supply,
                label: <LabelWithAsteriskMark label={"Source of Supply"} isAsteriskMark={isVendorRegister} />,
                onChange: (event, value) => {
                  if (value) {
                    setPurchaseOrder({
                      ...purchaseOrder,
                      source_of_supply_id: value.id,
                      selected_source_of_supply: value,
                    });
                  } else {
                    setPurchaseOrder({
                      ...purchaseOrder,
                      source_of_supply_id: null,
                      selected_source_of_supply: null,
                    });
                  }
                },
              }}
            />
          </Grid>
          
        <Grid item xs={12} sm={2.25}>
          <ListTerms
            id={"payment_term_dropdown"}
            dataTestId={"payment_term_dropdown"}
            isClear={isClear}
            setTerms={(key_name, value, item) => {
              if (value) {
                setPurchaseOrder({
                  ...purchaseOrder,
                  [key_name]: value,
                  payment_term: item,
                  // expected_shipment_date: moment(bill.bill_date).add(item.number_of_days, 'days').format('YYYY-MM-DD')
                });
              } else {
                setPurchaseOrder({
                  ...purchaseOrder,
                  [key_name]: value,
                  payment_term: item,
                  // due_date: bill.bill_date
                });
              }
            }}
            onFetchTerms={onFetchTerms}
            validation={isFormSubmitted}
            value={purchaseOrder.payment_term}
            defaultTerm={purchaseOrder.payment_term}
            title={<LabelWithAsteriskMark label={"Payment Terms"} />}
          />
        </Grid>
        <Grid item xs={12} sm={2.25}>
          <DatePicker
            id={"order_date_select"}
            dataTestId={"order_date_select"}
            placeholder=""
            title={<LabelWithAsteriskMark label={"Purchase Order Date"} />}
            validation={isFormSubmitted && !purchaseOrder.purchase_order_date}
            date={
              purchaseOrder.purchase_order_date
                ? moment(purchaseOrder.purchase_order_date)
                : null
            }
            maxDate={
              purchaseOrder.expected_delivery_date
                ? moment(purchaseOrder.expected_delivery_date)
                : null
            }
            setDate={(date) => {
              date
                ? onChange("purchase_order_date", date.format("YYYY-MM-DD"))
                : onChange("purchase_order_date", null);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={2.25}>
          <DatePicker
            id={"delivery_date_select"}
            dataTestId={"delivery_date_select"}
            placeholder=""
            title={"Expected Delivery Date"}
            minDate={
              purchaseOrder.purchase_order_date
                ? moment(purchaseOrder.purchase_order_date)
                : null
            }
            date={
              purchaseOrder.expected_delivery_date
                ? moment(purchaseOrder.expected_delivery_date)
                : null
            }
            setDate={(date) => {
              date
                ? onChange("expected_delivery_date", date.format("YYYY-MM-DD"))
                : onChange("expected_delivery_date", null);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Input
            id={"input_reference_number"}
            dataTestId={"input_reference_number"}
            item={{
              type: "text",
              required: true,
              fullWidth: true,
              texttransform: "none",
              title: "Reference Number",
              placeholder: "Enter Reference Number..",
              value: purchaseOrder.reference_number
                ? purchaseOrder.reference_number
                : "",
              onChange: (e) => onChange("reference_number", e.target.value),
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} sx={{ pt: 4 }}>
        <Grid item xs={12} sm={4.5}>
          <Input
            id={"input_mailing_address"}
            dataTestId={"input_mailing_address"}
            item={{
              rows: 4,
              type: "text",
              required: true,
              multiline: true,
              fullWidth: true,
              texttransform: "none",
              title: "Mailing Address",
              placeholder: "Enter Mailing Address",
              onChange: (e) => onChange("mailing_address", e.target.value),
              // validation: isFormSubmitted && !purchaseOrder.mailing_address,
              value: purchaseOrder.mailing_address
                ? purchaseOrder.mailing_address
                : "",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4.5}>
          <Input
            id={"input_shipment_preference"}
            dataTestId={"input_shipment_preference"}
            item={{
              rows: 4,
              type: "text",
              required: true,
              fullWidth: true,
              multiline: true,
              texttransform: "none",
              title: "Shipment Preference",
              placeholder: "Enter Shipment Preference",
              value: purchaseOrder.shipment_preference
                ? purchaseOrder.shipment_preference
                : "",
              onChange: (e) => onChange("shipment_preference", e.target.value),
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const ProductAndServicesDetails = (props) => {
  const {
    NewAccountsResult,
    is_gst_with_zero,
    isFormSubmitted,
    open,
    purchaseOrder,
    setPurchaseOrder,
    addButtonHandler,
    isClear,
    newItem,
    itemIndex,
  } = props;
  const [data, setData] = React.useState(purchaseOrder.purchase_order_items);

  const onAddLine = () => {
    purchaseOrder.purchase_order_items.push({
      item: null,
      tax: null,
      rate: null,
      quantity: 1,
      tax_id: null,
      amount: null,
      item_id: null,
      unit_id: null,
      item_order: null,
      expense_category: null,
      item_level_discount: 0,
      expense_category_id: null,
      is_item_discount_in_percentage: checkItemDiscount(purchaseOrder?.purchase_order_items),
    });

    setData([...purchaseOrder.purchase_order_items]);
  };

  const onDeleteLine = (index) => {
    if (purchaseOrder.purchase_order_items.length > 1) {
      purchaseOrder.purchase_order_items.splice(index, 1);
      setData([...purchaseOrder.purchase_order_items]);
    } else {
      setData([
        {
          item: null,
          tax: null,
          rate: null,
          quantity: 1,
          tax_id: null,
          amount: null,
          item_id: null,
          unit_id: null,
          item_order: null,
          expense_category: null,
          item_level_discount: 0,
          expense_category_id: null,
          is_item_discount_in_percentage: checkItemDiscount(purchaseOrder?.purchase_order_items),
        },
      ]);
    }
  };

  const onClearLines = () => {
    setData([
      {
        item: null,
        tax: null,
        rate: null,
        quantity: 1,
        tax_id: null,
        amount: null,
        item_id: null,
        unit_id: null,
        item_order: null,
        expense_category: null,
        item_level_discount: 0,
        expense_category_id: null,
        is_item_discount_in_percentage: checkItemDiscount(purchaseOrder?.purchase_order_items),
      },
    ]);
  };

  const accounts = applyTaxOnMultipleGSTComponents(
    purchaseOrder,
    "purchase_order_items",
    "input_"
  );

  const totalAmount = basePriceTotal(
    purchaseOrder,
    purchaseOrder.purchase_order_items
  ).toFloat();

  const totalTax = Object.values(accounts)
    .reduce((acc, tax) => acc + tax, 0)
    .toFloat();

  const totalDiscount = totalDiscountOnBasePrice(
    purchaseOrder,
    purchaseOrder.purchase_order_items
  ).toFloat();

  const subTotal = purchaseOrder.is_amount_tax_inclusive
    ? purchaseOrder.purchase_order_items
      .filter((item) => isNumeric(item.amount))
      .reduce((a, b) => a + parseFloat(b.amount), 0)
      .toFloat()
    : totalAmount.toFloat();

  const finalPayment =
    totalAmount.toFloat() - totalDiscount.toFloat() + totalTax.toFloat();

  purchaseOrder.tax = {
    ...accounts,
    total: parseFloat(parseFloat(totalTax).toFixed(2)),
  };
  purchaseOrder.total_amount = (
    totalAmount +
    totalTax -
    totalDiscount
  ).toFloat();
  purchaseOrder.discount = totalDiscount.toFloat();
  purchaseOrder.subtotal = totalAmount.toFloat();

  React.useEffect(() => {
    setPurchaseOrder({
      ...purchaseOrder,
      purchase_order_items: data,
      total_amount: totalAmount + totalTax - totalDiscount,
      discount: totalDiscount,
      subtotal: totalAmount,
    });
    // eslint-disable-next-line
  }, [data]);

  if (!("input_IGST" in purchaseOrder.tax)) {
    purchaseOrder.tax["input_IGST"] = 0;
  }
  if (!("input_SGST" in purchaseOrder.tax)) {
    purchaseOrder.tax["input_SGST"] = 0;
  }
  if (!("input_CGST" in purchaseOrder.tax)) {
    purchaseOrder.tax["input_CGST"] = 0;
  }

  const filterList = [
    { name: "Exclusive", value: "exclusive" },
    { name: "Inclusive", value: "inclusive" },
    { name: "No Tax", value: "no_tax" },
  ];

  const onAmountsAreInChange = (selected_type) => {
    let previous = purchaseOrder.tax_applied;
    if (previous !== selected_type.value) {
      if (selected_type.value === "inclusive") {
        purchaseOrder.is_amount_tax_inclusive = true;
        convertExclusiveToInclusive(purchaseOrder.purchase_order_items);
        purchaseOrder.total_amount = totalAmount - totalDiscount;
      } else if (selected_type.value === "no_tax") {
        if (previous === filterList[1].value) {
          convertInclusiveToExclusive(purchaseOrder.purchase_order_items);
        }
        // eslint-disable-next-line
        purchaseOrder.purchase_order_items.map((bill_item) => {
          bill_item.tax = null;
          bill_item.tax_id = null;
        });
        purchaseOrder.is_amount_tax_inclusive = false;
        purchaseOrder.total_amount = totalAmount - totalDiscount;
      } else {
        purchaseOrder.is_amount_tax_inclusive = false;
        convertInclusiveToExclusive(purchaseOrder.purchase_order_items);
      }
      setPurchaseOrder({ ...purchaseOrder, tax_applied: selected_type.value });
      setData([...purchaseOrder.purchase_order_items]);
    }
  };

  const [isDiscountFiled, setIsDiscountFiled] = React.useState(getDiscountPreferences());
  React.useEffect(() => {
    if (purchaseOrder.discount) {
      setIsDiscountFiled(getDiscountPreferences() || (purchaseOrder.discount ? true : false))
    }
  }, [purchaseOrder.discount]);

  return (
    <div>
      <Box
        style={{ paddingRight: "20px", }}
        sx={{ pb: 2, pt: 2, display: { sx: 'block', sm: 'flex' }, justifyContent: "flex-end" }}
      >
        <CommonCurrencySelection
          isCurrency={true}
          isAmountAre={true}
          data={purchaseOrder}
          dropdown_key='vendor_id'
          setData={setPurchaseOrder}
          isFormSubmitted={isFormSubmitted}
          onAmountsAreInChange={onAmountsAreInChange}
        />
      </Box>


      <Box sx={{ mb: 2 }}>
        <CustomTableContainer sx={{ overflowX: 'hidden' }} className="custom_table_css">
          <CustomTable>
            <ProductAndServicesDetailsHeaders isDiscountFiled={isDiscountFiled} data={purchaseOrder} setData={setPurchaseOrder} />
            <ProductAndServicesDetailsBody
              isDiscountFiled={isDiscountFiled}
              open={open}
              newItem={newItem}
              setData={setData}
              itemIndex={itemIndex}
              onDeleteLine={onDeleteLine}
              finalPayment={finalPayment}
              onClearLines={onClearLines}
              purchaseOrder={purchaseOrder}
              isFormSubmitted={isFormSubmitted}
              setPurchaseOrder={setPurchaseOrder}
              addButtonHandler={addButtonHandler}
              is_gst_with_zero={is_gst_with_zero}
              NewAccountsResult={NewAccountsResult}
              data={purchaseOrder.purchase_order_items}
            />
          </CustomTable>
        </CustomTableContainer>
      </Box>

      <Grid
        container
        style={{
          paddingBottom: "24px",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
      >
        <Grid item Container xs={12} sm={6}>
          <Grid item xs={12}>
            <CustomTypography
              id={"add_action"}
              dataTestId={"add_action"}
              text={"+ Add Line"}
              onClick={onAddLine}
              sx={{
                fontSize: "12px",
                color: "#2464EB",
                cursor: "pointer",
                display: "inline",
                fontWeight: "700",
                lineHeight: "16px",
                fontStyle: "normal",
                textTransform: "none",
                fontFamily: "Noto Sans",
                ":hover": { textDecoration: "underline" },
              }}
            />
            <CustomTypography
              text={"|"}
              sx={{
                mr: 1,
                ml: 1,
                fontSize: "12px",
                color: "#B8B8B8",
                fontWeight: "700",
                lineHeight: "16px",
                fontStyle: "normal",
                display: "inline",
                textTransform: "none",
                fontFamily: "Noto Sans",
              }}
            />
            <CustomTypography
              id={"clear_action"}
              dataTestId={"clear_action"}
              text={"Clear All Lines"}
              onClick={onClearLines}
              sx={{
                fontSize: "12px",
                color: "#2464EB",
                cursor: "pointer",
                display: "inline",
                fontWeight: "700",
                lineHeight: "16px",
                fontStyle: "normal",
                textTransform: "none",
                fontFamily: "Noto Sans",
                ":hover": { textDecoration: "underline" },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={9} sx={{ mt: 3 }}>
            <Input
              id={"input_note"}
              dataTestId={"input_note"}
              item={{
                rows: 4,
                type: "text",
                fullWidth: true,
                required: true,
                multiline: true,
                title: "Notes",
                texttransform: "none",
                placeholder: "Notes",
                value: purchaseOrder.note ? purchaseOrder.note : "",
                onChange: (event) => {
                  setPurchaseOrder({
                    ...purchaseOrder,
                    note: event.target.value,
                  });
                },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={9} sx={{ mt: 3 }}>
            <InputFile
              id={"attachment_action"}
              dataTestId={"attachment_action"}
              onFileUpload={(file) =>
                setPurchaseOrder({ ...purchaseOrder, attachment: file })
              }
              attachment={purchaseOrder.attachment}
              onFileRemove={() => {
                setPurchaseOrder({ ...purchaseOrder, attachment: null });
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <CommonCalculation
            data={purchaseOrder}
            setData={setPurchaseOrder}
            isFormSubmitted={isFormSubmitted}

            isCurrency={true}
            dropdown_key='vendor_id'

            accounts={accounts}
            subTotal={subTotal}
            totalTax={totalTax}
            totalAmount={totalAmount}
            finalPayment={finalPayment}
            totalDiscount={totalDiscount}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const ProductAndServicesDetailsHeaders = (props) => {
  const { isDiscountFiled, data, setData } = props;
  return (
    <CustomTableHead>
      <CustomTableRow><CustomTableHeadCell style={{ width: 5 }} align="left">  <span>#</span></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 310 }} align="left">  <span>Goods/Service</span></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 275 }} align="left">  <span>Account</span></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 185 }} align="left"><Common.QuantityLabelComponent data={data} setData={setData} items='purchase_order_items' /></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 175 }} align="left"><Common.RateLabelComponent data={data} setData={setData} items='purchase_order_items' /></CustomTableHeadCell>
        {isDiscountFiled &&
          <CustomTableHeadCell style={{ width: 100 }} align="left">  <Common.DiscountLabelComponent data={data} setData={setData} items='purchase_order_items' /></CustomTableHeadCell>
        }
       
          <CustomTableHeadCell style={{ width: 200 }} align="left">  <span>Tax</span></CustomTableHeadCell>
      
        <CustomTableHeadCell style={{ width: 145 }} align="left">  <span>Tax Amount</span></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 145 }} align="left">  <span>Amount</span></CustomTableHeadCell>

        <CustomTableHeadCell style={{ width: 5 }} align="right" />

      </CustomTableRow>
    </CustomTableHead>
  );
};

const ProductAndServicesDetailsBody = (props) => {
  const {
    isDiscountFiled,
    is_gst_with_zero,
    open,
    newItem,
    itemIndex,
    data,
    onDeleteLine,
    isFormSubmitted,
    setData,
    addButtonHandler,
    purchaseOrder,
    NewAccountsResult,
    setPurchaseOrder,
    finalPayment,
  } = props;

  let location = useLocation();
  const dispatch = Actions.getDispatch(useContext);
  const [taxResult, setTaxResult] = React.useState([]);
  let taxApplied = purchaseOrder.tax_applied;
  const source_of_supply_id = purchaseOrder.source_of_supply_id;
  const taxList = filterTax(taxResult, source_of_supply_id, is_gst_with_zero);

  React.useEffect(() => {
    const apiResults = async () => {
      let res = await apiAction({
        method: "post",
        url: get_list_of_tax(),
        data: {
          business_id: getBusinessInfo().id,
        },
      });
      if (res?.success) {
        setTaxResult(res?.result);
      } else {
      }
    };
    apiResults();
    // eslint-disable-next-line
  }, []);

  const apiItemResults = async (id, index) => {
    let res = await apiAction({
      method: "post",
      url: get_item(id),
      data: { business_id: getBusinessInfo().id },
    });
    if (res?.success) {
      onItemSelect(res?.result, index);
    } else {
    }
  };

  const onItemSelect = (value, index) => {
    const getDefaultTax = () => {
      return findDefaultTax(taxList, false, value)
    };
    const getItemData = () => ({
      item: value,
      item_order: null,
      unit_id: value.unit?.id,
      quantity: value.quantity || 1,
      description: value.description,
      hsn_sac_code: value.hsn_sac_code,
      item_id: value.item_id || value.id,
      amount: value.amount || value.cost_price,
      rate: parseFloat(value.rate || value.cost_price),
      item_level_discount: value.item_level_discount || 0,
      is_item_discount_in_percentage: checkItemDiscount(purchaseOrder?.purchase_order_items),
      
      gst_rate: getDefaultTax()?.rate,
      tax: taxApplied === "no_tax" ? null : getDefaultTax(),
      tax_id: taxApplied === "no_tax" ? null : getDefaultTax()?.id,

      expense_category: value.purchase_account || value.expense_category,
      expense_category_id: value.purchase_account?.id || value.expense_category_id,
    });

    const updateData = () => {
      data[index] = value ? getItemData() : {
        tax: null,
        item: null,
        rate: null,
        quantity: 1,
        amount: null,
        tax_id: null,
        item_id: null,
        unit_id: null,
        item_order: null,
        expense_category: null,
        item_level_discount: 0,
        expense_category_id: null,
        is_item_discount_in_percentage: checkItemDiscount(purchaseOrder?.purchase_order_items),
      };
    };

    const isAmountRangeExceeded = () => isFinalAmountRangeCrossed({
      quantity: data[index]?.quantity,
      rate: data[index]?.rate,
      item_level_discount: data[index].item_level_discount,
      item: data[index],
      invoice: purchaseOrder,
      finalPayment: data.length <= 1
        ? 0
        : finalPayment - (data[index]?.amount || 0) - (data[index]?.tax_amount || 0),
    });
    if (isAmountRangeExceeded()) {
      stateChangeManager(
        dispatch,
        Actions,
        true,
        "error",
        "Amount range max limit reached"
      );
    } else {
      updateData();
      setData([...data]);
      setPurchaseOrder({ ...purchaseOrder, purchase_order_items: [...data] });
    }
  };


  React.useEffect(() => {

    const getDefaultTax = (value) => {
      return findDefaultTax(taxList, false, value);
    };

    const updatedData = data.map((item) => {
      const defaultTax = getDefaultTax(item);
      const isDefaultSelectTax = item.tax_id;

      // Calculate values based on conditions
      const gstRate = isDefaultSelectTax ? defaultTax?.rate ?? null : null;
      const tax = isDefaultSelectTax ? (taxApplied === "no_tax" ? null : defaultTax) : null;
      const taxId = isDefaultSelectTax ? (taxApplied === "no_tax" ? null : defaultTax?.id ?? null) : null;

      return {
        ...item,
        tax: tax,
        tax_id: taxId,
        gst_rate: gstRate,
      }
    });

    setData(updatedData);
    setPurchaseOrder((prev) => ({
      ...prev,
      purchase_order_items: updatedData
    }));

    // eslint-disable-next-line
  }, [is_gst_with_zero,source_of_supply_id,taxApplied]);


  return (
    <CustomTableBody>
      {purchaseOrder.purchase_order_items.map((item, index) => {
        item['item_order'] = index + 1;
        return (
          <CustomTableRow key={index}>

            <CustomTableBodyCell sx={{}} align="left">
              <span>{index + 1}</span>
            </CustomTableBodyCell>

            <CustomTableBodyCell sx={{}} align="left">
              <CommonAsyncDropdown
                className='item_dropdown'
                id={"item_dropdown_" + index}
                dataTestId={"item_dropdown_" + index}
                optionLabel="name"
                newItem={newItem}
                autoSelect={false}
                itemIndex={itemIndex}
                disableClearable={false}
                noOptionsText={"No result found"}
                placeholder="Select Goods/Service"
                validation={isFormSubmitted && !data[index].item_id}
                item={{
                  open: open,
                  method: "post",
                  url: list_items(1),
                  value: data[index].item,
                  onChange: (event, value) => {
                    if (value) {
                      data[index].item = value.name;
                      setData([...data]);
                      apiItemResults(value.id, index);
                    } else {
                      onItemSelect(value, index);
                    }
                  },
                  body: {
                    role: "vendor",
                    business_id: getBusinessInfo().id,
                    for_purchase: true,
                  },
                }}
                addButton={{
                  title: "+ Add an item",
                  onClick: () =>
                    addButtonHandler(
                      "Add item",
                      "new_pas",
                      "lg",
                      "product",
                      index
                    ),
                }}
              />
            </CustomTableBodyCell>

            <CustomTableBodyCell sx={{}} align="left">
              <div>
                <CustomTypography
                  text={''}
                  sx={{
                    mb: 1,
                    fontSize: '14px',
                    color: '#000000',
                    fontWeight: '700',
                    lineHeight: '18px',
                    fontStyle: 'normal',
                    textTransform: "none",
                    fontFamily: "Noto Sans",
                  }}
                />
                <AsyncDropdown
                  sx={{}}
                  fullWidth={true}
                  autoFocus={false}
                  disabledCloseIcon={false}
                  isGroupHeaderSticky={true}
                  className='expense_dropdown'
                  newResults={NewAccountsResult}
                  id={'expense_category_dropdown_' + index}
                  dataTestId={'expense_category_dropdown_' + index}
                  validation={isFormSubmitted && !data[index]?.expense_category_id}

                  selectedValue={data[index]?.expense_category}
                  setSelectedValue={(value) => {
                    if (value) {
                      data[index].expense_category = value;
                      data[index].expense_category_id = value.id;
                    } else {
                      data[index].expense_category = null;
                      data[index].expense_category_id = null;
                    }
                    setData([...data]);
                  }}

                  valueLabelKey='id'
                  uniqueOptionKey='id'
                  searchKey='account_name'
                  optionLabelKey='account_name'
                  placeholder='Select the Account'
                  playLoad={{ account_type: ['EXPENSE'] }}
                  optionGroupLabelKey='account_type'
                  URL={get_chart_of_account_nested_nodes(1)}

                  addButton={{
                    title: '+ Add accounts',
                    onClick: () => addButtonHandler('Add accounts', 'create_sub_account', 'sm', "EXPENSE", index),
                  }}
                />
              </div>
            </CustomTableBodyCell>

            <CustomTableBodyCell sx={{}} align="left">
              <Common.QuantityInputComponent
                index={index}
                data={data}
                setData={setData}
                finalPayment={finalPayment}
                quantityData={purchaseOrder}
                isFormSubmitted={isFormSubmitted}
              />
            </CustomTableBodyCell>

            <CustomTableBodyCell sx={{}} align="left">

              <Common.RateInputComponent
                index={index}
                data={data}
                setData={setData}
                rateData={purchaseOrder}
                finalPayment={finalPayment}
                isFormSubmitted={isFormSubmitted}
              />
            </CustomTableBodyCell>

            {isDiscountFiled &&
              <CustomTableBodyCell sx={{}} align="left">
                <Common.DiscountInputComponent
                  index={index}
                  data={data}
                  setData={setData}
                  discountData={purchaseOrder}
                  finalPayment={finalPayment}
                />

              </CustomTableBodyCell>
            }

              <CustomTableBodyCell sx={{}} align="left">
                <TaxList
                  id={"tax_dropdown_" + index}
                  dataTestId={"tax_dropdown_" + index}
                  result={taxList}
                  value={data[index]?.tax}
                  disableClearable={false}
                  inputDisabled={ purchaseOrder.tax_applied === "no_tax"}
                  validation={
                    isFormSubmitted &&
                    purchaseOrder.tax_applied !== "no_tax" &&
                    !data[index]?.tax_id
                  }
                  setValue={(tax) => {
                    if (
                      isFinalAmountRangeCrossed({
                        quantity: data[index]?.quantity,
                        rate: data[index]?.rate,
                        item_level_discount: data[index].item_level_discount,
                        item: { ...data[index], tax: tax },
                        invoice: purchaseOrder,
                        finalPayment:
                          data.length <= 1
                            ? 0
                            : finalPayment -
                            (data[index]?.amount ? data[index]?.amount : 0) -
                            (data[index]?.tax_amount
                              ? data[index]?.tax_amount
                              : 0),
                      })
                    ) {
                      stateChangeManager(
                        dispatch,
                        Actions,
                        true,
                        "error",
                        "Amount range max limit reached"
                      );
                    } else {
                      if (tax) {
                        data[index].tax = tax;
                        data[index].tax_id = tax.id;
                        data[index].gst_rate = tax.rate;
                      } else {
                        data[index].tax = null;
                        data[index].tax_id = null;
                        data[index].tax_amount = null;
                      }
                      setData([...data]);
                    }
                  }}
                />
              </CustomTableBodyCell>

            <CustomTableBodyCell sx={{}} align="left">
              <Input
                id={"input_tax_amount_" + index}
                dataTestId={"input_tax_amount_" + index}
                editable={false}
                is_currency_symbol={true}
                isShowCommaSeparatedValue={true}
                currency_code={purchaseOrder.currency_code}
                item={{
                  type: "number",
                  disabled: true,
                  fullWidth: true,
                  required: true,
                  placeholder: "",
                  value:
                    data[index]?.tax_amount &&
                      purchaseOrder.tax_applied !== "no_tax"
                      ? data[index]?.tax_amount
                      : "",
                }}
              />
            </CustomTableBodyCell>

            <CustomTableBodyCell sx={{}} align="left">
              <Input
                id={"input_amount_" + index}
                dataTestId={"input_amount_" + index}
                editable={false}
                is_currency_symbol={true}
                isShowCommaSeparatedValue={true}
                currency_code={purchaseOrder.currency_code}
                item={{
                  disabled: true,
                  type: "number",
                  fullWidth: true,
                  required: true,
                  placeholder: "",
                  value: data[index]?.amount ? data[index]?.amount : "",
                }}
              />
            </CustomTableBodyCell>

            <CustomTableBodyCell sx={{}} align="right">
              <IconButton
                onClick={() => onDeleteLine(index)}
                id={"delete_icon_" + index}
                dataTestId={"delete_icon_" + index}
              >
                <DeleteIcon />
              </IconButton>
            </CustomTableBodyCell>

          </CustomTableRow>
        );
      })}
    </CustomTableBody>
  );
};

const Buttons = (props) => {
  const {
    is_Location,
    onSave,
    onCancel,
    onClear,
    purchaseOrder,
    setFormSubmitted,
  } = props;
  let location = useLocation();
  const params = getQueryParams(location.search);

  const { tax_applied, purchase_order_items, vendor_emails } = purchaseOrder;
  const { to } = vendor_emails;
  const is_rate = purchase_order_items.find((item) => item.rate === null);
  const is_quantity = purchase_order_items.find((item) => !item.quantity);
  const is_tax = purchase_order_items.find(
    (item) => tax_applied !== "no_tax" && !item.tax_id
  );
  const is_purchase_order_items_not_selected = purchase_order_items.find(
    (item) => !item.item_id
  );
  const is_expense_category_not_selected = purchase_order_items.find(
    (item) => !item.expense_category_id
  );

  let validation_data = [
    { key: "vendor_id", message: "Please Select Vendor" },
    // {
    //   key: "",
    //   validation: to.length === 0,
    //   message: "Please enter valid email.",
    // },
    {
      key: "purchase_order_number",
      message: "Please Enter Purchase Order Number",
    },
    isVendorRegister && !is_Location && {
      key: "source_of_supply_id",
      message: "Please Select Source of Supply",
    },
    { key: "payment_term_id", message: "Please Select Terms" },
    {
      key: "purchase_order_date",
      message: "Please Enter Valid Purchase Order Date",
    },
    // { key: "mailing_address", message: 'Please Enter Mailing Address' },
    {
      key: "",
      validation: is_purchase_order_items_not_selected,
      message: "Please Select Item",
    },
    {
      key: "",
      validation: is_expense_category_not_selected,
      message: "Please Select Account",
    },
    { key: "", validation: is_quantity, message: "Please Enter the Quantity" },
    { key: "", validation: is_rate, message: "Please Enter the Rate" },
    isVendorRegister && { key: "", validation: is_tax, message: "Please Select the Tax" },
  ];


  const printPreviewEvent = () => {
    AnalyticsEvent(eventsNames.categories.PURCHASE_ORDERS,{action:eventsNames.actions.PREVIEW});
  };

  const clearActionDisabled =
    Object.keys(params).length === 0 || params.clone_id;
  return (
    <Box component="form" noValidate>
      <Grid container>
        <Grid item xs={12} sm={4} sx={{ display: "-webkit-flex", justifyContent: "start" }}>
          {clearActionDisabled && (
            <CustomButton
              id={"clear_btn"}
              dataTestId={"clear_btn"}
              variant="outlined"
              sx={{
                textTransform: "none",
                "&:hover": { backgroundColor: "#e8f3ff" },
              }}
              btnLabel="Clear"
              onClick={() => {
                onClear();
              }}
            />
          )}
          <CustomButton
            id={"cancel_btn"}
            dataTestId={"cancel_btn"}
            variant="contained"
            sx={{ ml: 1, textTransform: "none" }}
            btnLabel="Cancel"
            onClick={() => {
              onCancel();
            }}
          />
        </Grid>
        <Grid item xs={12} sm={8} sx={{ display: "-webkit-flex", justifyContent: "end" }}>
          <PrintAndPreview
            apiUrl={preview_purchase_order()}
            data={purchaseOrder}
            validation_data={validation_data}
            setFormSubmitted={() => {
              setFormSubmitted(true);
              printPreviewEvent();
            }}
          />
          <CustomGroupButton
            options={[
              {
                id: "save_send_btn",
                dataTestId: "save_send_btn",
                label: params.id ? "Update and Send" : "Save and Send",
                condition: () => {
                  onSave({ is_save_send: true });
                },
              },
              {
                id: "save_send_later_btn",
                dataTestId: "save_send_later_btn",
                label: params.id
                  ? "Update and Send Later"
                  : "Save and Send Later",
                condition: () => {
                  onSave({ is_save_send: false });
                },
              },
            ]}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default CreatePurchaseOrder;
