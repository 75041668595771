import React from "react";
import Input from "../../../../../common/Input";
import { apiAction } from "../../../../../../api/api";
import * as Actions from '../../../../../../state/Actions';
import CustomDialog from '../../../../../custom/dialog/CustomDialog';
import { getBusinessInfo } from "../../../../../../config/cookiesInfo";
import { update_terms_and_conditions } from "../../../../../../api/urls";
import CustomDropdown from '../../../../../custom/dropdown/CustomDropdown';
import CustomTextArea from "../../../../../custom/textArea/CustomTextArea";
import CustomTypography from '../../../../../custom/typography/CustomTypography';

import {
  isFormValid,
  stateChangeManager,
} from "../../../../../../utils/Utils";

import {
  Box,
  Radio,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

export const spanTag = (value, size = '14px') => <span style={{
  fontSize: size,
  color: '#141414',
  fontWeight: '500',
  lineHeight: '20px',
  fontStyle: 'normal',
  textTransform: 'none',
  fontFamily: "Noto Sans",
}}>{value}</span>

export const CustomCheckbox = (props) => {
  const { id, dataTestId, label = '', checked = false, disabled = false, onClickedHandler } = props;
  return (
    <FormControlLabel
      id={id}
      disabled={disabled}
      dataTestId={dataTestId}
      control={
        <Checkbox disabled={disabled} size="small" checked={checked} onClick={() => onClickedHandler(checked)} />
      }
      label={spanTag(label)}
    />
  )
}

export const CustomRadio = (props) => {
  const { id, dataTestId, label = '', checked = false, onClickedHandler } = props;

  return (
    <FormControlLabel
      id={id}
      dataTestId={dataTestId}
      control={
        <Radio size="small" checked={checked} onClick={() => onClickedHandler(checked)} />
      }
      label={spanTag(label)}
    />
  )
}

export const CustomHeading = (props) => {
  const { label = '' } = props;
  return (
    <CustomTypography
      text={label}
      sx={{
        mb: 2,
        color: '#000000',
        fontSize: '16px',
        fontWeight: '700',
        lineHeight: '18px',
        fontStyle: 'normal',
        fontFamily: "Noto Sans",
      }}
    />
  )
}

export const CustomHelperText = (props) => {
  const { sx, label = '' } = props;
  return (
    <CustomTypography
      text={label}
      sx={{
        ...sx,
        color: "#7A7A7A",
        fontSize: "12px",
        fontWeight: "500",
        lineHeight: "18px",
        fontStyle: "normal",
        fontFamily: "Noto Sans",
      }}
    />
  )
}

export const CommonNotes = (props) => {
  const { value, setValue, componentRef } = props;
  return (
    <Input
      id={componentRef + 'notes_input'}
      dataTestId={componentRef + 'notes_input'}
      item={{
        rows: 8,
        title: "",
        type: "text",
        required: true,
        multiline: true,
        fullWidth: true,
        placeholder: "",
        value: value ? value : '',
        onChange: (e) => { setValue(e.target.value) }
      }}
    />
  )
}

export const CommonTermsAndConditions = (props) => {
  const { results = [], setResults, value, setValue, openRef, componentRef, setOpenRef } = props;

  const dispatch = Actions.getDispatch(React.useContext);

  const [edit, setEdit] = React.useState(false);
  const [selectedData, setSelectedData] = React.useState();

  const [state, setState] = React.useState({
    id: '',
    title: '',
    open: false,
    condition: '',
    maxWidth: 'lg',
    fullWidth: true,
  })

  const addHandler = () => {
    setState({
      open: true,
      maxWidth: 'sm',
      fullWidth: true,
      title: 'Add Terms and Conditions',
      condition: 'create_terms_and_conditions',
    })
  }

  const onTermAndConditionAdd = (item) => {
    setValue(item.id);
    results.push(item);
    setSelectedData(item);
    setResults([...results]);
  }

  let selectedTerms = results.find((item) => item.id === value);
  React.useEffect(() => {
    setEdit(false);
    if (selectedTerms) {
      setSelectedData(selectedTerms);
    } else {
      setSelectedData(null);
    }
    // eslint-disable-next-line
  }, [value, selectedTerms])


  const handleSubmit = async () => {
    let validation_data = [
      { key: "name", message: `Name field can not be empty` },
      { key: "content", message: 'Content field can not be empty' },
    ]
    const { isValid, message } = isFormValid(selectedData, validation_data);
    if (isValid) {
      let res = await apiAction({
        method: 'post',
        url: update_terms_and_conditions(selectedData.id),
        data: {
          ...selectedData,
          business_id: getBusinessInfo().id,
        }
      })
      if (res?.success) {
        setEdit(!edit);
        let index = results.findIndex((item) => item.id === value);
        if (index >= 0) {
          results[index] = res?.result;
          setResults([...results]);
        }
        stateChangeManager(dispatch, Actions, true, 'success', res?.status);

      } else {
        stateChangeManager(dispatch, Actions, true, 'error', res?.status)
      }
    } else {
      stateChangeManager(dispatch, Actions, true, 'error', message)
    }
  };

  return (
    <React.Fragment>
      <CustomDialog
        state={state}
        setState={setState}
        onAddItem={onTermAndConditionAdd}

      />
      <Box sx={{ display: 'flex', alignItems: 'center' }} >
        <Box maxWidth={250} sx={{ flexGrow: 1 }}>
          <CustomDropdown
            fullWidth={true}
            results={results}
            disableClearable={false}
            placeholder='Select Terms and Condition'
            onFocus={() => { setOpenRef(componentRef) }}
            value={selectedData ? selectedData : null}
            id={componentRef + 'terms_and_conditions_dropdown'}
            dataTestId={componentRef + 'terms_and_conditions_dropdown'}
            onChange={(event, newInputValue) => { setValue(newInputValue ? newInputValue.id : null) }}
          // addButton={{
          //   title: '+ Add Terms and Condition',
          //   onClick: () => addHandler(),
          // }}
          />
        </Box>
        {
          selectedData ?
            <CustomTypography
              text={edit ? 'Close' : 'View'}
              onClick={() => { setEdit(!edit); setOpenRef(componentRef) }}
              // onClick={() => { edit ? handleSubmit() : setEdit(true); setOpenRef(componentRef) }}
              sx={{
                ml: 2,
                fontSize: '12px',
                display: 'inline',
                color: '#2464EB',
                cursor: 'pointer',
                display: 'inline',
                fontWeight: '700',
                lineHeight: '16px',
                fontStyle: 'normal',
                textTransform: "none",
                fontFamily: "Noto Sans",
                ":hover": { textDecoration: 'underline' },
              }}
            />
            : null
        }
      </Box>

      {
        edit ?
          <Box sx={{ flexGrow: 0, maxwidth: '100%', textAlign: 'end', pt: 1 }}>
            <Input
              id={componentRef + 'terms_and_conditions_input'}
              dataTestId={componentRef + 'terms_and_conditions_input'}
              item={{
                rows: 8,
                title: "",
                type: "text",
                required: true,
                disabled: true,
                multiline: true,
                fullWidth: true,
                placeholder: "",
                value: selectedData && selectedData.content ? selectedData.content : '',
                onChange: (e) => { setSelectedData((previous) => ({ ...previous, content: e.target.value })) }
              }}
            />
          </Box>
          : null
      }
    </React.Fragment>
  )
}