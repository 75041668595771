import React from 'react';
import moment from 'moment';
import Highcharts from 'highcharts';
import { DialogContent } from '@mui/material';
import { apiAction } from '../../../../../../api/api';
import HighchartsReact from 'highcharts-react-official';
import { useIsElementVisible } from '../../common/Helper';
import * as Actions from '../../../../../../state/Actions';
import { GranularityComponent } from '../../common/CommonComponents';
import CustomTypography from '../../../../../custom/typography/CustomTypography';
import { BootstrapDialog, BootstrapDialogTitle } from '../../../../bulkStatementUpload/mappings/Transactions';

import {
  setLoader,
  amountFormatter,
  getUniqueObjectsByKey,
  getSelectedGranularityData,
  formattedDataBasedGranularity,
} from '../../../../../../utils/Utils';

import {
  get_data_point,
  evaluate_data_point_range,
} from '../../../../../../api/urls';

const LineChart = ({ data, to_date, from_date, refreshDataPoint, openFull, setOpenFull }) => {
  const dispatch = Actions.getDispatch(React.useContext);

  const elementRef = React.useRef(null);
  const [results, setResults] = React.useState([]);
  const isElementVisible = useIsElementVisible(elementRef.current);
  const [granularity, setGranularity] = React.useState(getSelectedGranularityData('', from_date, to_date)?.selected);

  const fetchDataPoint = async (data_point_id) => {
    try {
      const value = await apiAction({ url: get_data_point(data_point_id) });
      if (!value?.success) {
        console.log('Error', value);
        return;
      }
      const result = value.result;
      const item = await evaluateDataPoint(data_point_id);
      if (item?.success) {
        setLoader(dispatch, Actions, false);
        result.lineChartData = formattedDataBasedGranularity(item.result, granularity);
      }
      setResults((prev) => getUniqueObjectsByKey([...prev, result]));
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const evaluateDataPoint = async (data_point_id, isRefresh = false) => {
    try {
      const url = isRefresh ? `${evaluate_data_point_range(data_point_id, from_date, to_date, granularity)}&is_refresh=true` : evaluate_data_point_range(data_point_id, from_date, to_date, granularity);
      const value = await apiAction({ url });
      return value?.success ? value : null;
    } catch (error) {
      console.error('Error:', error);
      return null;
    }
  };

  const fetchAllDataPoints = (data_point_ids = []) => {
    data_point_ids.forEach((id) => {
      if (id) fetchDataPoint(id);
    });
  };

  const fetchAllEvaluateDataPoints = async (data_point_ids = [], isRefresh) => {
    try {
      const promises = data_point_ids.map(async (id) => {
        const resultItem = results.find((item) => item.id === id);
        if (resultItem) {
          const item = await evaluateDataPoint(id, isRefresh);
          setLoader(dispatch, Actions, false);
          resultItem.lineChartData = formattedDataBasedGranularity(item.result, granularity);
          return resultItem;
        }
      });
      const updatedResults = await Promise.all(promises);
      setResults(updatedResults.filter(Boolean));
      return updatedResults.filter(Boolean);// Filter out undefined values
    } catch (error) {
      console.error('Error:', error);
      return [];
    }
  };

  React.useEffect(() => {
    if (data && isElementVisible && !results.length) fetchAllDataPoints(data.data_point_ids);
  }, [isElementVisible]);

  React.useEffect(() => {
    if (data && isElementVisible && results.length) {
      setResults([]);
      fetchAllDataPoints(data.data_point_ids);
    }
  }, [data]);

  React.useEffect(() => {
    if (data && isElementVisible && results.length) fetchAllEvaluateDataPoints(data.data_point_ids, true);
  }, [refreshDataPoint]);

  React.useEffect(() => {
    if (data && isElementVisible && results.length) {
      fetchAllEvaluateDataPoints(data.data_point_ids, false);
      setGranularity(getSelectedGranularityData('', from_date, to_date)?.selected);
    }
  }, [from_date, to_date]);

  React.useEffect(() => {
    if (data && isElementVisible && results.length) fetchAllEvaluateDataPoints(data.data_point_ids, false);
  }, [granularity]);

  return (
    <div ref={elementRef} style={{ position: 'relative', height: '100%', overflow: 'hidden' }}>
      <FullViewModeLineChart
        open={openFull}
        title={data?.name}
        setOpen={setOpenFull}
        data={results.filter((item) => item.lineChartData)}

        to_date={to_date}
        from_date={from_date}
        granularity={granularity}
        setGranularity={setGranularity}
      />
      {results.length > 0 && (
        <>
          <CustomLineChart
            to_date={to_date}
            from_date={from_date}
            granularity={granularity}
            setGranularity={setGranularity}
            data={results.filter((item) => item.lineChartData)}
          />
          <div style={{ position: 'absolute', top: 9, right: 9 }}>
            <GranularityComponent
              to_date={to_date}
              from_date={from_date}
              granularity={granularity}
              setGranularity={setGranularity}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default LineChart;

const CustomLineChart = ({ data, color }) => {
  const [options, setOptions] = React.useState({});

  React.useEffect(() => {
    const seriesData = data?.map(item => ({
      name: item?.name,
      color: color || '',
      data: item?.lineChartData?.map(entry => ({
        entry:entry,
        y: parseFloat(entry.result),
      })),
    }));

    let categories = data?.[0]?.lineChartData?.map(entry => entry.period);

    const chartOptions = {
      chart: {
        type: 'line',
      },
      title: {
        text: ''
      },
      credits: {
        enabled: false
      },
      yAxis: {
        title: {
          text: null
        },
      },
      xAxis: {
        min: 0,
        maxPadding: 0,
        tickInterval: 1,
        endOnTick: false,
        startOnTick: false,
        categories: categories,
      },
      legend: {
        margin: 10,
        align: 'left',
        layout: 'horizontal',
        verticalAlign: 'bottom'
      },
      plotOptions: {
        series: {
          cursor: 'pointer',
          stickyTracking: false,
          connectNulls: false,
          point: {
            events: {
              click: function () { }
            }
          }
        },
      },
      tooltip: {
        snap: 0,
        formatter: function () {
          return `<p style="font-family: 'Noto Sans'; color:${this.color};">${moment(this.point.options.entry.to_date).format('DD MMM YYYY')}</p><br style="margin-bottom:'10px'"/><span style="font-family: 'Noto Sans';"><span><b>${this.series.name} :</b> </span><b>${amountFormatter(this.y)}</b></span>`;
        }
      },
      series: seriesData,
    };

    setOptions(chartOptions);
  }, [data, color]);

  return (
    <HighchartsReact
      options={options}
      highcharts={Highcharts}
      containerProps={{ style: { height: '100%', width: '100%', zIndex: 1 } }}
    />
  );
};


export const FullViewModeLineChart = ({ color, open, setOpen, data, title, to_date, from_date, granularity, setGranularity }) => (
  <BootstrapDialog fullWidth open={open} maxWidth='lg'>
    <BootstrapDialogTitle id="customized-dialog-title" onClose={() => setOpen(false)}>
      <CustomTypography
        text={title}
        sx={{
          color: '#000000',
          fontSize: '16px',
          fontWeight: '700',
          lineHeight: '18px',
          fontStyle: 'normal',
          fontFamily: "Noto Sans",
        }}
      />
    </BootstrapDialogTitle>
    <DialogContent dividers>
      <div style={{ position: 'relative' }}>
        <CustomLineChart
          data={data}
          color={color}
          to_date={to_date}
          from_date={from_date}
          granularity={granularity}
          setGranularity={setGranularity}
        />
        <div style={{ position: 'absolute', top: 9, right: 9 }}>
          <GranularityComponent
            to_date={to_date}
            from_date={from_date}
            granularity={granularity}
            setGranularity={setGranularity}
          />
        </div>
      </div>
    </DialogContent>
  </BootstrapDialog>
);
