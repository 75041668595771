import React from 'react';
import CustomTypography from '../custom/typography/CustomTypography';

const NoTransactionComponent = (props) => {
    const {message1='No data to display',message2='Once you add the data you will be able to view it here.'}=props
    return (
        <CustomTypography
            text={<span>{message1}<br /><span style={{
                fontSize: '12px',
                color: '#2F2F2E',
                fontWeight: 400,
                fontStyle: 'normal',
                lineHeight: '20px',
            }}>{message2}</span></span>}
            sx={{
                mt: '1vh',
                mb: '5vh',
                color: '#000000',
                fontSize: '14px',
                fontWeight: '700',
                textAlign: 'center',
                lineHeight: '18px',
                fontStyle: 'normal',
                fontFamily: "Noto Sans",
            }}
        />
    )
}

export default NoTransactionComponent