import * as React from 'react';
import validator from 'validator';
import Input from '../../common/Input';
import { apiAction } from '../../../api/api';
import * as Actions from '../../../state/Actions';
import { routesName } from '../../../config/routesName';
import { useNavigate, useLocation } from 'react-router-dom';
import CustomTypography from '../../custom/typography/CustomTypography';
import { eventsNames, AnalyticsEvent } from '../../../firebase/firebaseAnalytics';

import {
    update_password,
    send_forgot_password_mail_view
} from '../../../api/urls';

import {
    parseJwt,
    setLoader,
    isFormValid,
    verifyPassword,
    getQueryParams,
    stateChangeManager,
} from '../../../utils/Utils';

import {
    Box,
    Link,
    Grid,
    Button,
    Divider,
    Typography,
    IconButton,
    InputAdornment,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const ResetPassword = () => {
    const navigate = useNavigate();

    const location = useLocation();
    const params = getQueryParams(location.search);
    const dispatch = Actions.getDispatch(React.useContext);
    const [parseJwtData, setParseJwtData] = React.useState(false);

    React.useEffect(() => {
        if (params.token) {
            setParseJwtData(parseJwt(params.token))
        }
    }, []);

    const sign_up_initialData = {
        email: null,
        token: null,
        new_password: null,
        confirm_password: null,
    }

    const [isFormSubmitted, setFormSubmitted] = React.useState(false);
    const [data, setData] = React.useState({ ...sign_up_initialData });

    React.useEffect(() => {
        if (parseJwtData) {
            setData({
                ...data,
                token: params.token,
                email: parseJwtData.email,
            })
        }
    }, [parseJwtData]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        setFormSubmitted(true)

        let validation_data = [
            { key: "new_password", message: 'New password field can not be empty' },
            { key: "confirm_password", message: 'Confirm password field can not be empty' },
            { key: '', validation: (!data.email || !validator.isEmail(data.email)), message: 'Please enter valid email.' },
            { key: "", validation: data.new_password !== data.confirm_password, message: 'New password and confirm password does not match' },
            { key: "", validation: !verifyPassword(data.new_password), message: 'Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 number, 1 special character, and be at least 8 characters long.' },

        ]
        const { isValid, message } = isFormValid(data, validation_data)
        if (isValid) {
            AnalyticsEvent(eventsNames.categories.USER_AUTHENTICATION,{action:eventsNames.actions.RESET_PASSWORD,state:'email_sent'});
            setLoader(dispatch, Actions, true)
            let res = await apiAction({
                data: { ...data },
                method: 'post',
                url: update_password(),
            })
            if (res?.success) {
                setLoader(dispatch, Actions, false);
                stateChangeManager(dispatch, Actions, true, 'success', res?.status);
                navigate("/auth" + routesName.signIn.path);
            } else {
                setLoader(dispatch, Actions, false);
                stateChangeManager(dispatch, Actions, true, 'error', res?.status);
            }
        } else {
            setLoader(dispatch, Actions, false);
            stateChangeManager(dispatch, Actions, true, 'error', message);
        }
    };

    const sendMail = async (event) => {
        event.preventDefault();

        setFormSubmitted(true)
        let validation_data = [
            { key: "email", message: 'Email field can not be empty' },
            { key: '', validation: (!data.email || !validator.isEmail(data.email)), message: 'Please enter valid email.' },

        ]
        const { isValid, message } = isFormValid(data, validation_data)
        if (isValid) {
            AnalyticsEvent(eventsNames.categories.USER_AUTHENTICATION,{action:eventsNames.actions.RESET_PASSWORD,state:'email_sent'});
            setLoader(dispatch, Actions, true)
            let res = await apiAction({
                data: { email: data.email },
                method: 'post',
                url: send_forgot_password_mail_view(),
            })
            if (res?.success) {
                setLoader(dispatch, Actions, false);
                stateChangeManager(dispatch, Actions, true, 'success', res?.status);
                navigate("/auth" + routesName.openEmailAccount.path, { state: { data: data, path: 'reset_password' } });
            } else {
                setLoader(dispatch, Actions, false);
                stateChangeManager(dispatch, Actions, true, 'error', res?.status);
            }
        } else {
            setLoader(dispatch, Actions, false);
            stateChangeManager(dispatch, Actions, true, 'error', message);
        }
    };

    const [isShow, setIsShow] = React.useState({
        is_password: false,
        is_confirmed_password: false
    })
    const onPasswordVisibility = (data) => {
        if (data === 'is_password') {
            isShow.is_password = !isShow.is_password;
        } else {
            isShow.is_confirmed_password = !isShow.is_confirmed_password;
        }
        setIsShow({ ...isShow })
    }

    return (
        <Box sx={{ display: "flex" }}>
            <Box className='m_login_left_box' sx={{ backgroundColor: '#F7F7F7', minHeight: '100%', width: '28%' }}>
                <Box
                    sx={{
                        pt: 8,
                        minHeight: "100vh",
                        minWidth: '100vh',
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Box
                        sx={{
                            p: 2,
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            justifyContent: "center",
                        }}
                    >
                        {/* <Img sx={{ pt: 1.5, ml: 30 }} alt={images.signup.alt} src={images.signup.src} /> */}
                    </Box>
                </Box>

            </Box>
            <Box sx={{ height: '100%', width: '100%' }}>
                <Box
                    sx={{
                        pt: 8,
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Box
                        sx={{
                            p: 2,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: "center",
                            flexDirection: 'column',
                        }}>
                        <Box component="form" onSubmit={params.token ? handleSubmit : sendMail} noValidate>
                            {
                                !params.token &&
                                <React.Fragment>
                                    <CustomTypography
                                        text={'Reset your password'}
                                        sx={{
                                            color: '#000000',
                                            fontSize: '30px',
                                            textAlign: 'start',
                                            fontWeight: '700',
                                            fontStyle: 'normal',
                                            lineHeight: '40.86px',
                                            fontFamily: "Noto Sans",
                                        }}
                                    />
                                    <CustomTypography
                                        text={<span>Enter your user account's verified email address and we will send you a password reset link.</span>}
                                        sx={{
                                            mb: 4,
                                            color: '#000000',
                                            fontSize: '14px',
                                            textAlign: 'start',
                                            fontWeight: '500',
                                            fontStyle: 'normal',
                                            lineHeight: '40.86px',
                                            fontFamily: "Noto Sans",
                                        }}
                                    />

                                    <Box sx={{ mt: 2, }}>
                                        <Input
                                            autoFocus={true}
                                            id='input_email_address'
                                            dataTestId='input_email_address'
                                            item={{
                                                type: 'text',
                                                required: true,
                                                fullWidth: true,
                                                title: 'Email Address',
                                                placeholder: 'Email Your Email Address',
                                                disabled: params.token ? true : false,
                                                value: data.email ? data.email : '',
                                                validation: isFormSubmitted && !data.email,
                                                onChange: (e) => setData({ ...data, email: e.target.value })
                                            }}
                                        />
                                    </Box>
                                </React.Fragment>
                            }
                            {
                                params.token &&
                                <Box>
                                    <CustomTypography
                                        text={<span>Change password for <br />{data.email}</span>}
                                        sx={{
                                            color: '#000000',
                                            fontSize: '20px',
                                            textAlign: 'start',
                                            fontWeight: '700',
                                            fontStyle: 'normal',
                                            lineHeight: '40.86px',
                                            fontFamily: "Noto Sans",
                                        }}
                                    />
                                    <Box sx={{ mt: 3, }}>
                                        <Input
                                            id='input_new_password'
                                            dataTestId='input_new_password'
                                            item={{
                                                required: true,
                                                fullWidth: true,
                                                title: 'New Password',
                                                placeholder: 'Enter New Password',
                                                type: isShow.is_password ? 'text' : 'password',
                                                value: data.new_password ? data.new_password : '',
                                                validation: isFormSubmitted && !verifyPassword(data.new_password),
                                                onChange: (e) => setData({ ...data, new_password: e.target.value })
                                            }}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">
                                                    <Divider sx={{ height: 20, m: 0.5 }} orientation="vertical" />
                                                    <IconButton edge="end" size='small' sx={{ ml: .5, color: '#2464EB', cursor: 'pointer' }}
                                                        onClick={() => { onPasswordVisibility('is_password') }} id='show_password_icon' dataTestId='show_password_icon'>
                                                        {
                                                            isShow.is_password ?
                                                                <VisibilityIcon fontSize='small' />
                                                                :
                                                                <VisibilityOffIcon fontSize='small' />
                                                        }
                                                    </IconButton>
                                                </InputAdornment>

                                            }}
                                        />
                                    </Box>
                                    <Box sx={{ mt: 2, }}>
                                        <Input
                                            id='input_confirm_password'
                                            dataTestId='input_confirm_password'
                                            item={{
                                                required: true,
                                                fullWidth: true,
                                                title: 'Confirm Password',
                                                placeholder: 'Enter Confirm Password',
                                                type: isShow.is_confirmed_password ? 'text' : 'password',
                                                value: data.confirm_password ? data.confirm_password : '',
                                                validation: isFormSubmitted && !verifyPassword(data.confirm_password),
                                                onChange: (e) => setData({ ...data, confirm_password: e.target.value }),
                                            }}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">
                                                    <Divider sx={{ height: 20, m: 0.5 }} orientation="vertical" />
                                                    <IconButton edge="end" size='small' sx={{ ml: .5, color: '#2464EB', cursor: 'pointer' }}
                                                        onClick={() => { onPasswordVisibility('is_confirmed_password') }} id='show_confirmed_password_icon' dataTestId='show_confirmed_password_icon'>
                                                        {
                                                            isShow.is_confirmed_password ?
                                                                <VisibilityIcon fontSize='small' />
                                                                :
                                                                <VisibilityOffIcon fontSize='small' />
                                                        }
                                                    </IconButton>
                                                </InputAdornment>

                                            }}
                                        />
                                    </Box>
                                </Box>
                            }


                            <Button
                                id='clicked_btn'
                                dataTestId='clicked_btn'
                                fullWidth
                                type="submit"
                                variant="contained"
                                sx={{ mt: 4, mb: 2, textTransform: 'none' }}>{params.token ? `Change Password` : `Send password reset email`}</Button>

                            <Grid container spacing={4} sx={{ pt: 1, visibility: 'hidden' }}>
                                <Grid item xs={12} sm={6}>
                                    <Link id='sign_up_link' dataTestId='sign_up_link' href={"/auth" + routesName.signUp.path} variant="body2">
                                        {"Don't have an account? Sign up"}
                                    </Link>
                                </Grid>
                                <Grid item xs={12} sm={6} sx={{ textAlign: 'end' }} >
                                    <Link id='sign_in_link' dataTestId='sign_in_link' href={"/auth" + routesName.signIn.path} variant="body2">
                                        {'Already have an account? Sign in'}
                                    </Link>
                                </Grid>
                            </Grid>

                            {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default ResetPassword;

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link id='copy_right_link' dataTestId='copy_right_link' color="inherit" href="https://numbers.artdexandcognoscis.com/">
                www.numbers.artdexandcognoscis.com
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}