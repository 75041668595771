import React from 'react';
import Status from '../../../../common/Status';
import HTMLViewer from '../../../../common/HTMLViewer';
import * as Actions from '../../../../../state/Actions';
import CustomTitle from '../../../../common/CustomTitle';
import { useNavigate, useLocation } from 'react-router-dom';
import CustomTabs from '../../../../custom/tabs/CustomTabs';
import { routesName } from '../../../../../config/routesName';
import CustomDialog from '../../../../custom/dialog/CustomDialog';
import { getBusinessInfo } from '../../../../../config/cookiesInfo';
import AuditTrailHistory from '../../../../common/AuditTrailHistory';
import CommonPreviewShare from '../../../../common/CommonPreviewShare';
import ViewPageActionButtons from '../../../../common/ViewPageActionButtons';
import CustomTypography from '../../../../custom/typography/CustomTypography';
import { eventsNames, AnalyticsEvent } from '../../../../../firebase/firebaseAnalytics';

import {
    getQueryParams,
    breadCrumbsStateChangeManager,
} from '../../../../../utils/Utils';

import {
    CustomContainer,
    CustomTitleContainer,
} from '../../../../custom/container/CustomContainer';

import {
    delete_sales_order,
    preview_sales_order,
    retrieve_sales_order,
    mark_sales_order_sent,
} from '../../../../../api/urls';

import {
    setLoader,
    stateChangeManager,
} from '../../../../../utils/Utils';

import {
    apiAction,
    apiBlobResponse,
    apiHandleDownload,
} from '../../../../../api/api';


import {
    Box,
    Grid,
} from '@mui/material';
import { CommonWebSocket } from '../../../../../api/webSocket';

const SalesOrderViewpage = () => {
    let location = useLocation();
    const navigate = useNavigate();
    const params = getQueryParams(location.search);
    const dispatch = Actions.getDispatch(React.useContext);

    const [tab, setTab] = React.useState(0);

    const [previewData, setPreviewData] = React.useState();
    const [basicInformation, setBasicInformation] = React.useState();

    const [state, setState] = React.useState({
        id: '',
        title: '',
        open: false,
        condition: '',
        maxWidth: 'lg',
        fullWidth: true,
    })

    const webSocketResponse = CommonWebSocket();
    React.useEffect(() => {
        if (webSocketResponse) {
            let webSocketData = JSON.parse(webSocketResponse.data)
            if (webSocketData.document_type === "sales_order") {

                if (webSocketData.event === "send_document" && basicInformation.id === webSocketData.object_id) {
                    if (webSocketData.event_success) {
                        setBasicInformation({ ...basicInformation, status: webSocketData.object_status })
                        stateChangeManager(dispatch, Actions, true, "success", `Email delivered successfully for ${webSocketData.object_number}`);
                    } else {
                        stateChangeManager(dispatch, Actions, true, "error", `Email delivery failed for ${basicInformation.sales_order_number}`);
                    }
                }

                if (webSocketData.event === "delete_document" && basicInformation.id === webSocketData.object_id) {
                    if (webSocketData.event_success) {
                        stateChangeManager(dispatch, Actions, true, "success", `Sales Order ${webSocketData.object_number} deleted successfully`);
                        navigate(routesName.invoicingSalesOrder.path);
                    } else {
                        stateChangeManager(dispatch, Actions, true, "error", `Failed to delete Sales Order ${webSocketData.object_number}`);
                    }
                }

                if (webSocketData.event === "update_document" && basicInformation.id === webSocketData.object_id) {
                    if (webSocketData.event_success) {
                        stateChangeManager(dispatch, Actions, true, "success", `Sales Order ${webSocketData.object_number} updated successfully`);
                        retrieveApiResults();
                    } else {
                        stateChangeManager(dispatch, Actions, true, "error", `Failed to update Sales Order ${webSocketData.object_number}`);
                    }
                }

            }
        }
    }, [webSocketResponse])

    const stateChangeHandler = (title, condition, maxWidth, url, deleteMessage) => {
        setState({
            url: url,
            open: true,
            title: title,
            fullWidth: true,
            maxWidth: maxWidth,
            condition: condition,
            deleteMessage: deleteMessage,
            navigate: () => navigate(routesName.invoicingSalesOrder.path),
        })
    }

    const retrieveApiResults = async () => {
        setLoader(dispatch, Actions, true);
        let res = await apiAction({
            method: 'post',
            url: retrieve_sales_order(params.id),
            data: { business_id: getBusinessInfo().id },
        })
        if (res?.success) {
            setBasicInformation(res?.result);
            breadCrumbsStateChangeManager(dispatch, res?.result.sales_order_number);
            let previewRes = await apiAction({
                method: 'post',
                url: preview_sales_order(params.id),
                data: { ...res?.result, place_of_supply_id: res?.result.place_of_supply ? res?.result.place_of_supply.id : null, terms_and_conditions_id: res?.result.terms_and_conditions, export_type: 'html' },
            })
            if (previewRes?.success) {
                setPreviewData({ ...previewRes.result, terms_and_conditions_id: res?.result.terms_and_conditions });
                setLoader(dispatch, Actions, false);
            } else {
                setLoader(dispatch, Actions, false);
            }
        }
    }

    React.useEffect(() => {
        if (params.id) {
            retrieveApiResults()
        }
        // eslint-disable-next-line
    }, []);


    const download = (data) => {
        setLoader(dispatch, Actions, true);
        apiHandleDownload({
            url: preview_sales_order(), data: { ...data }, filename: data.sales_order_number, onSuccess: () => {
                setLoader(dispatch, Actions, false);
            }
        })
    }

    const print = (data) => {
        setLoader(dispatch, Actions, true);
        apiBlobResponse({
            url: preview_sales_order(), data: { ...data }, onSuccess: () => {
                setLoader(dispatch, Actions, false);
            }
        })
    }

    const send = async (data) => {
        setSendMail(true);
        // const sendSalesOrderRes = await apiAction({ url: send_sales_order_mail(), method: 'POST', data: { business_id: getBusinessInfo().id, sales_order_id: data.id } })
        // if (sendSalesOrderRes.success) {
        //     retrieveApiResults();
        //     stateChangeManager(dispatch, Actions, true, 'success', sendSalesOrderRes.status);
        // }
    }

    const markSent = async (id) => {
        const sentRes = await apiAction({ url: mark_sales_order_sent(id), method: 'POST', data: { business_id: getBusinessInfo().id } })
        if (sentRes.success) {
            stateChangeManager(dispatch, Actions, true, 'success', sentRes.status);
        } else {
            stateChangeManager(dispatch, Actions, true, 'error', sentRes.status);
        }
    }

    const onSelectAction = (action, item) => {
        setLoader(dispatch, Actions, true);
        if (action === "create") {
            setLoader(dispatch, Actions, false);
            navigate(routesName.invoicingSalesOrderAdd.path)
            AnalyticsEvent(eventsNames.categories.SALES_ORDERS, { action: eventsNames.actions.NEW })
        }
        if (action === "clone") {
            navigate(routesName.invoicingSalesOrderAdd.path + "?clone_id=" + item.id)
            AnalyticsEvent(eventsNames.categories.SALES_ORDERS, { action: eventsNames.actions.CLONE })
        }
        if (action === "edit") {
            navigate(routesName.invoicingSalesOrderAdd.path + "?id=" + item.id)
        }
        if (action === "send") {
            send(item)
            AnalyticsEvent(eventsNames.categories.SALES_ORDERS, { action: eventsNames.actions.SEND })
        }
        if (action === "mark_sent") {
            markSent(item.id)
            AnalyticsEvent(eventsNames.categories.SALES_ORDERS, { action: eventsNames.actions.MARK_SENT })
        }
        if (action === "print") {
            print(item)
            AnalyticsEvent(eventsNames.categories.SALES_ORDERS, { action: eventsNames.actions.PRINT })
        }
        if (action === "download") {
            download(item)
            AnalyticsEvent(eventsNames.categories.SALES_ORDERS, { action: eventsNames.actions.DOWNLOAD })
        }
        if (action === "convert") {
            navigate(routesName.invoicingSalesInvoiceAdd.path + "?sales_id=" + item.id)
            AnalyticsEvent(eventsNames.categories.SALES_ORDERS, { action: eventsNames.actions.salesOrder.action.CONVERT_INVOICE })
        }
    }

    const onClickActionText = (status, item) => {
        if (status === 'unsent') {
            onSelectAction("send", item);
        } else if (status === 'sent') {
            onSelectAction("convert", item);
        }
        else if (status === 'closed') {
            onSelectAction("create", item);
        }
    }

    const toActionText = (status) => {
        let text
        if (status === 'unsent') {
            text = 'Send'
        } else if (status === 'sent') {
            text = 'Convert to invoice'
        }
        else if (status === 'closed') {
            text = 'Create New sales Order'
        }
        return text
    }



    const tabs = [
        { name: 'View', condition: 'preview' },
    ]

    const [sendMail, setSendMail] = React.useState(false);
    return (
        previewData && basicInformation &&
        <div>
            <CustomDialog
                state={state}
                setState={setState}
            />
            <CommonPreviewShare
                open={sendMail}
                type={'sales_order'}
                id={basicInformation.id}
                file_key={'sales_order_number'}
                export_Url={preview_sales_order}
                retrieve_Url={retrieve_sales_order}
                setOpen={(value) => { setSendMail(value); retrieveApiResults() }}
            />
            <CustomTitleContainer>
                <Grid container spacing={0} style={{
                    alignItems: 'center',
                    justifyItems: 'center',
                    alignContent: 'space-evenly',
                    justifyContent: 'space-evenly',
                }}>
                    <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'start', alignItems: 'center' }}>
                        <CustomTitle title={basicInformation.sales_order_number} />
                        <Status status={basicInformation.status} sx={{ ml: 1, }} />

                    </Grid>
                    <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'end', }}>
                        <ViewPageActionButtons
                            isEditable={basicInformation.status !== 'closed'}
                            edit={() => onSelectAction("edit", basicInformation)}
                            print={() => onSelectAction("print", basicInformation)}
                            download={() => onSelectAction("download", basicInformation)}

                            actionsList={
                                [
                                    basicInformation.status !== 'unsent' && { name: 'Resend', onClick: () => { onSelectAction("send", basicInformation); } },
                                    basicInformation.status === 'unsent' && { name: 'Mark as sent', onClick: () => { onSelectAction("mark_sent", basicInformation); } },
                                    { name: 'Clone', onClick: () => onSelectAction('clone', basicInformation) },
                                    { name: 'Delete', showDivider: true, onClick: () => { stateChangeHandler('Delete Sales Order', 'delete', 'sm', delete_sales_order(basicInformation.id), `The sales order will be deleted and can not be retrieved later.\n Are you sure about deleting it?`); AnalyticsEvent(eventsNames.categories.SALES_ORDERS, { action: eventsNames.actions.DELETE }) } }

                                ]
                            }
                        />
                        <AuditTrailHistory

                            data={{ object_id: basicInformation.id, object_type: 'sales_order' }}
                            AnalyticsEvent={() => { AnalyticsEvent(eventsNames.categories.SALES_ORDERS, { action: eventsNames.actions.HISTORY }) }}
                        />
                    </Grid>
                </Grid>
            </CustomTitleContainer>

            <CustomContainer maxWidth={"400"} sx={{ height: 'calc(100vh - 134px)' }}>
                <Box sx={{
                    p: 2,
                    border: '1px solid #FFF0C0', backgroundColor: "#FFF7DC"
                }}>
                    <Grid container sx={{ mb: 1 }}>
                        <Grid item xs={12} sm={6} sx={{}}>
                            <CustomTypography
                                text={<span>Action:</span>}
                                sx={{
                                    mb: 2,
                                    color: '#7A7A7A',
                                    fontSize: '14px',
                                    fontWeight: '600',
                                    lineHeight: '19px',
                                    fontStyle: 'normal',
                                    textTransform: 'none',
                                    fontFamily: "Noto Sans",
                                }}
                            />
                            <Box sx={{ display: 'flex' }}>
                                <CustomTypography
                                    id={'action_status_btn'}
                                    dataTestId={'action_status_btn'}
                                    text={toActionText(basicInformation.status)}
                                    sx={{
                                        color: '#2464EB',
                                        fontSize: '14px',
                                        cursor: 'pointer',
                                        lineHeight: '0px',
                                        display: 'inline',
                                        fontWeight: '700',
                                        fontStyle: 'normal',
                                        textAlign: 'center',
                                        fontFamily: "Noto Sans",
                                        ":hover": { textDecoration: 'underline' }
                                    }}
                                    onClick={() => onClickActionText(basicInformation.status, basicInformation)}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

                <Box sx={{}}>
                    <CustomTabs
                        tabs={tabs}
                        value={tab}
                        onChange={(event, tab) => { setTab(tab) }}
                    />
                    {tabs[tab].condition === 'preview' && <PreviewScreen previewData={previewData} basicInformation={basicInformation} />}
                </Box>

            </CustomContainer>
        </div>
    )
}

export default SalesOrderViewpage;

const PreviewScreen = (props) => {
    return (
        <Box sx={{ pt: 2 }}>
            <HTMLViewer html_string={props.previewData.html_string} />
        </Box>
    )
}