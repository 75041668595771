import React from "react";
import { routesName } from "../../../../config/routesName";
import Transactions from "../../bulkStatementUpload/mappings/Transactions";

import {
    import_opening_balance,
    validate_opening_balance_file_data,
} from "../../../../api/urls";


const OpeningBalanceMappingPreview = (props) => {
    const {nextButton,onCancelButton,onErrorButton}=props;
console.log('====>OpeningBalanceMappingPreview')
    return (
        <div>
            <Transactions title={"Opening Balances"} is_migration_date={true} isPerformValidation={true} validationUrl={validate_opening_balance_file_data()} url={import_opening_balance()} onFileErrorPath={routesName.openingBalanceImport.path} onErrorButton={onErrorButton} nextButton={nextButton} onCancelButton={onCancelButton} nextPagePath={routesName.openingBalanceAdjustment.path} onCancelPath={routesName.openingBalanceMapping.path} />
        </div>
    )
}

export default OpeningBalanceMappingPreview