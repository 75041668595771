import React from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import InputBase from '@mui/material/InputBase';
import { CustomLink } from '../../link/CustomLink';
import SearchIcon from '@mui/icons-material/Search';
import { shortCuts } from '../../../../utils/Constant';
import { HighlightedText } from '../../../../utils/Utils';
import NoTransactionComponent from '../../../common/NoTransactionComponent';
import { eventsNames, AnalyticsEvent } from '../../../../firebase/firebaseAnalytics';

import {
    Box,
    Divider,
    Popover,
} from '@mui/material';


const Search = styled('div')(({ theme }) => ({
    marginLeft: 0,
    width: '100%',
    position: 'relative',
    backgroundColor: '#f5f5f5',
    marginRight: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    height: '100%',
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    position: 'absolute',
    pointerEvents: 'none',
    justifyContent: 'center',
    padding: theme.spacing(0, 2),
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
        width: '100%',
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
    },
}));

const SearchBar = () => {
    const navigate = useNavigate();
    const [results, setResults] = React.useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [searchTerm, setSearchTerm] = React.useState('');

    const analyticsEventActions = (selected) => {
        AnalyticsEvent(eventsNames.categories.GLOBAL_SEARCH, { action: eventsNames.actions.CLICK, user_term: searchTerm, user_selection: selected })

    }

    const handleClick = () => {
        setSearchTerm('');
        setSelectedShortcut(0);
        setAnchorEl(true);
    };

    const handleClose = (select) => {
        setSearchTerm('');
        setAnchorEl(null);
        setSelectedShortcut(0);
        analyticsEventActions(select);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;



    React.useEffect(() => {
        const shortcuts = shortCuts.sort((a, b) => a.label.length - b.label.length).filter((val) => {
            if (searchTerm === '') {
                return false;
            } else if (
                val.type.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
                val.label.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
            ) {
                return true;
            }
        });
        setResults([...shortcuts]);
    }, [searchTerm])

    const shortcutListRef = React.useRef(null);
    const [selectedShortcut, setSelectedShortcut] = React.useState(0);

    const handleKeyDown = (event) => {
        if (shortcutListRef.current && selectedShortcut !== null) {
            const selectedItem = shortcutListRef.current.children[selectedShortcut];

            if (selectedItem) {
                selectedItem.scrollIntoView({
                    block: 'center',
                    inline: 'center',
                    behavior: 'auto',
                });
            }
        }

        switch (event.key) {
            case 'ArrowUp':
                event.preventDefault();
                setSelectedShortcut((prev) =>
                    prev === null ? results.length - 1 : Math.max(prev - 1, 0)
                );
                break;
            case 'ArrowDown':
                event.preventDefault();
                setSelectedShortcut((prev) =>
                    prev === null ? 0 : Math.min(prev + 1, results.length - 1)
                );
                break;
            case 'Enter':
                event.preventDefault();
                if (results.length && selectedShortcut !== null) {
                    if (results[selectedShortcut]) {
                        handleClose(results[selectedShortcut]?.eventName);
                        navigate(results[selectedShortcut].path, { state: results[selectedShortcut].state });
                    }
                }
                break;
            default:
                break;
        }

    };

    const handleSearchShortcut = (event) => {
        if (event.altKey && event?.code === 'KeyG') {
            event.preventDefault();
            handleClick()
        }
    };

    // const handleSearchShortcut = (event) => {
    //     console.log('====>event',event)
    //     if ((event?.metaKey || event?.altKey) && event?.code?.toLowerCase() === 'KeyG') {
    //       // Prevent default behavior if any
    //       event.preventDefault();
    //       handleClick();
    //     }
    //   };

    React.useEffect(() => {
        document?.addEventListener('keydown', handleSearchShortcut);
        return () => {
            document.removeEventListener('keydown', handleSearchShortcut);
        };
    }, []);
    return (
        <React.Fragment>
            <SearchBox onClick={(event) => { handleClick(event) }} />
            <Popover
                id={id}
                open={open}
                anchorReference="anchorPosition"
                anchorPosition={{ top: 60, left: window.innerWidth / 2, }}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                PaperProps={{
                    sx: {
                        width: {
                            sm: 600,
                            xs: '100%',
                        },
                        maxHeight: '60vh',
                    }
                }}
                onKeyDown={(event) => handleKeyDown(event)}
            >
                <Box sx={{}} >
                    <Box sx={{ position: 'sticky', top: 0, backgroundColor: '#fff' }}>
                        <Box sx={{ p: 2, }}>
                            <Search>
                                <SearchIconWrapper >
                                    <SearchIcon sx={{ color: '#7b7b7b', }} />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    id={'input_search_word'}
                                    dataTestId={'input_search_word'}
                                    autoFocus
                                    fullWidth
                                    value={searchTerm}
                                    placeholder="Search…"
                                    inputProps={{ 'aria-label': 'search' }}
                                    onChange={(event) => { setSearchTerm(event.target.value) }}
                                />
                            </Search>
                        </Box>
                        <Divider />
                    </Box>

                    <Box sx={{ overflowY: 'auto', maxHeight: 'calc(60vh - 60px)' }} ref={shortcutListRef}>
                        {
                            results.length ?
                                results.map((item, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <CustomLink
                                                id={'search_link_btn' + index}
                                                dataTestId={'search_link_btn' + index}
                                                to={item.path}
                                                state={item.state}
                                                onClick={() => { handleClose(item.eventName); }}
                                                sx={{
                                                    p: 2,
                                                    display: 'block',
                                                    fontSize: '14px',
                                                    cursor: 'pointer',
                                                    lineHeight: '12px',
                                                    textDecoration: 'none',
                                                    fontWeight: selectedShortcut === index ? 700 : 500,
                                                    color: selectedShortcut === index ? '#2464EB' : '#141414',
                                                    backgroundColor: selectedShortcut === index ? '#f5f5f5' : 'transparent',
                                                    ":hover": { textDecoration: 'none', backgroundColor: "#f5f5f5", color: '#2464EB', fontWeight: 700 }
                                                }}
                                            >{<HighlightedText text={item.label} search={searchTerm} />}</CustomLink>
                                        </React.Fragment>
                                    )
                                })
                                :
                                searchTerm !== '' &&
                                <Box sx={{ mt: '10vh', mb: '15vh' }}>
                                    <NoTransactionComponent
                                        message1={`No results found`}
                                        message2={`We can’t find anything with search term at the moment, try searching something else.`}

                                    />
                                </Box>

                        }
                    </Box>
                </Box>
            </Popover>
        </React.Fragment>

    )
}

const SearchBox = ({ onClick }) => (
    <Box
        onClick={onClick}
        sx={{
            pl: 0.5,
            pr: 1,
            pt: 0.5,
            pb: 0.5,
            display: 'flex',
            color: '#757575',
            cursor: 'pointer',
            borderRadius: '4px',
            alignItems: 'center',
            background: '#fafafa',
            border: '1px solid #f3f3f3',
            width: { xs: 'auto', sm: 120 },
            justifyContent: 'space-between',
            '&:hover': {
                border: '1px solid #e0e0e0',
            },
        }}
    >
        <div style={{ display: 'flex', alignItems: 'center', }}>
            <SearchIcon fontSize='medium' sx={{ pr: 0.5, color: '#757575', opacity: 0.8 }} />
            <span style={{ fontSize: '10px' }}>Search...</span>
        </div>
        <span
            style={{
                fontSize: '8px',
                fontWeight: 'bold',
                whiteSpace: 'nowrap',
                letterSpacing: '1px',
            }}
        >
            Alt + G
        </span>
    </Box>
);
export default SearchBar;
