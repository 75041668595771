import React from 'react';
// import Loader from './Loader';
import * as Actions from '../../../state/Actions'
import LinearProgress from '@mui/material/LinearProgress';

import {
  Backdrop,
  CircularProgress
} from '@mui/material';

export default function CustomLoader() {
  const state = Actions.getState(React.useContext);
  const [progress, setProgress] = React.useState(0);
  const [buffer, setBuffer] = React.useState(10);

  const progressRef = React.useRef(() => { });
  React.useEffect(() => {
    progressRef.current = () => {
      if (progress > 100) {
        setProgress(0);
        setBuffer(10);
      } else {
        const diff = Math.random() * 20;
        const diff2 = Math.random() * 20;
        setProgress(progress + diff);
        setBuffer(progress + diff + diff2);
      }
    };
  },[progress]);

  React.useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current();
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  React.useEffect(() => {
    if (!state.loader) {
      setProgress(0); // Reset the progress when the loader is turned off
      setBuffer(10); // Reset the buffer when the loader is turned off

      // console.log('====>buffer',buffer);
      // console.log('=====>progress',progress);
    }
  }, [state.loader]);

  return (
    state.loader &&
    <div
      style={{
        top: 2,
        left: 6,
        right: 0,
        position: 'fixed',
        background: 'rgba(255, 255, 255, 0.8)',
        zIndex: 9999, // Adjust the z-index to place the loader above other content
      }}
    >
      <LinearProgress
        variant="buffer"
        value={progress}
        valueBuffer={buffer}
        sx={{ height: 5, borderRadius: 5, }}
      />
      
      {/* <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={state.loader}
        >
        <CircularProgress color="inherit" />
      </Backdrop> */}
    </div>
  );
}