import React from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { apiAction } from '../../../../api/api';
import Loader from '../../../custom/loader/Loader';
import DatePicker from '../../../common/DatePicker';
import * as Actions from '../../../../state/Actions';
import CustomTitle from '../../../common/CustomTitle';
import { input_css_style } from '../../../../utils/Constant';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import CustomButton from '../../../custom/button/CustomButton';
import { LabelWithAsteriskMark } from '../../../common/CommonLabel';
import CustomTypography from '../../../custom/typography/CustomTypography';
import { getBusinessInfo, getUserDetails } from '../../../../config/cookiesInfo';
import CustomDefaultDropdown from '../../../custom/dropdown/CustomDefaultDropdown';
import DropdownWithMultipleValue from '../../../custom/dropdown/DropdownWithMultipleValue';
import { CustomHelperText } from '../../setting/settings/Preferences/common/CustomElement';

import {
    list_users_in_business,
    schedule_report_periodic_task,
    get_schedule_report_periodic_task,
} from '../../../../api/urls';


import {
    CustomHeading,
} from '../../setting/settings/Preferences/common/CustomElement';


import {
    setLoader,
    isFormValid,
    getDateFormat,
    stateChangeManager,
} from '../../../../utils/Utils';

import {
    Box,
    Grid,
    Slide,
    Drawer,
    Button,
    IconButton,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import ScheduleIcon from '@mui/icons-material/Schedule';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const ReportSchedules = (props) => {
    const { report_name, report_title, buttonStyle, additional_data } = props;

    let navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);

    const initial_data = {
        start_date: moment(),
        repeat_every: 'weekly',
        report_name: report_name,
        business_id: getBusinessInfo().id,

        to_cc: [],
        to_address: [],
        ...additional_data,
    }

    const [open, setOpen] = React.useState(false);
    const [isFormSubmitted, setFormSubmitted] = React.useState(false);

    const [userLists, setUserLists] = React.useState([]);
    const [selectEmails, setSelectEmails] = React.useState([]);
    const [data, setData] = React.useState({ ...initial_data });

    const handleClose = () => {
        setOpen(false);
        setUserLists([]);
        setSelectEmails([]);
        setFormSubmitted(false);
        setData({ ...initial_data });
    };

    const onSaveHandler = async () => {
        setFormSubmitted(true);
    
        let validation_data = [
            { key: "repeat_every", message: "Please Select Frequency" },
            { key: "start_date", message: "Please Select Start Date & Time" },
            { key: "start_date", message: "Please Select Start Date & Time" },

        ]
        if (selectEmails.length) {
            let emails = selectEmails.map((item) => item.email)
            let res = await apiAction({
                method: 'post',
                navigate: navigate,
                dispatch: dispatch,
                url: schedule_report_periodic_task(),
                data: {
                    ...data,
                    to_address: [...emails],
                    start_date: data.start_date.format('YYYY-MM-DD HH:mm:ss'),
                },
            })
            if (res?.success) {
                handleClose();
                stateChangeManager(dispatch, Actions, true, 'success', res?.status);
            } else {
                stateChangeManager(dispatch, Actions, true, 'error', res?.status)
            }
        } else {
            stateChangeManager(dispatch, Actions, true, 'error', `Please select the email recipients`)
        }

    }

    React.useEffect(() => {
        if (open) {
            getUserLists();
        }
        // eslint-disable-next-line
    }, [open]);

    const getUserLists = async () => {
        setLoader(dispatch, Actions, false);
        let res = await apiAction({
            method: "get",
            url: list_users_in_business(),
        });
        if (res?.success) {
            getScheduleReports(res?.result);
            setLoader(dispatch, Actions, false);
        } else {
            setLoader(dispatch, Actions, false);
        }
    };

    // console.log('====>getUserDetails()', getUserDetails())
    const getScheduleReports = async (users) => {
        setLoader(dispatch, Actions, false);
        let res = await apiAction({
            method: "get",
            url: get_schedule_report_periodic_task(report_name),
        });
        if (res?.success) {
            let result = res?.result
            data.is_active = result.is_active
            data.to_cc = result.report_data.to_cc
            data.repeat_every = result.repeat_frequency
            data.to_address = result.report_data.to_address
            data.start_date = moment(result.starts_on).utc()

            setData({ ...data })
            setSelectEmails([...result.report_data.to_address.map((item) => users.find((user) => user.email === item))])

            setLoader(dispatch, Actions, false);
        } else {
            if (res?.status === "ScheduleReport matching query does not exist.") {
                if (getUserDetails()) {
                    if (getUserDetails().hasOwnProperty('pk')) {
                        setSelectEmails([users.find((item) => item.id === getUserDetails().pk)])
                    } else {
                        setSelectEmails([users.find((item) => item.id === getUserDetails().id)])
                    }
                }
            }
            setLoader(dispatch, Actions, false);
        }
        setUserLists(users);
    };

    return (
        <React.Fragment>
            <Button
                size='small'
                variant="outlined"
                aria-label="EditIcon"
                id={'schedule_report_btn'}
                sx={{ ...buttonStyle, mr: 1 }}
                onClick={() => { setOpen(true) }}
                dataTestId={'schedule_report_btn'}
                startIcon={<ScheduleIcon fontSize="inherit" />}
            >
                Schedule Report
            </Button>

            <Drawer
                open={open}
                elevation={5}
                anchor={'right'}
                hideBackdrop={true}
                TransitionComponent={Transition}
                id={'common_report_schedules_Dialog'}
                variant={open?"persistent":'temporary'}
                dataTestId={'common_report_schedules_Dialog'}

                PaperProps={{
                    sx: {
                        zIndex:999999,
                        backgroundColor: '#FFFFFF',
                        width: {
                            xs: '100vw',
                            sm: '35vw'
                        }
                    }
                }}
            >

                <Box sx={{
                    zIndex: 1,
                    minHeight: '100vh',
                    backgroundColor: '#FFFFFF',
                    boxShadow: '1px 0px 4px rgba(0, 0, 0, 0.04)',
                }}>
                    <Box sx={{ pt: 0.42, pb: 0.42, pr: 2, pl: 2, backgroundColor: '#F2F2F2', borderBottom: '1px solid #e0e0e0', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <CustomTitle title={
                            <span style={{}}>Schedule Report<br />
                                <span style={{ fontSize: '13px', fontWeight: 600, opacity: 0.8 }}>Report Name : {report_title}</span>
                            </span>}
                        />
                        <IconButton size='small' id={'close_reports_schedules_btn'} dataTestId={'close_reports_schedules_btn'} onClick={() => handleClose()} sx={{":hover": { bgcolor: '#e0e0e0' }}}>
                            <Close fontSize='small' />
                        </IconButton>
                    </Box>
                    {userLists.length ?
                        <Box sx={{ maxHeight: 'calc(100vh - 134px)', }}>
                            <Box sx={{
                                p: 2,
                                overflow: 'scroll',
                                height: 'calc(100vh - 134px)',
                            }}>
                                <Information
                                    data={data}
                                    setData={setData}
                                    userLists={userLists}
                                    selectEmails={selectEmails}
                                    isFormSubmitted={isFormSubmitted}
                                    setSelectEmails={setSelectEmails}
                                />
                                {data.hasOwnProperty('is_active') &&
                                    <Box sx={{ mt: 5, pb: 5, borderTop: '2px solid #e0e0e0', borderBottom: '2px solid #e0e0e0' }}>
                                        <CustomTypography
                                            text={<p>{'Status'}</p>}
                                            sx={{
                                                mb: 1,
                                                fontSize: '16px',
                                                color: '#000000',
                                                fontWeight: '700',
                                                lineHeight: '18px',
                                                fontStyle: 'normal',
                                                textTransform: "none",
                                                fontFamily: "Noto Sans",
                                            }}
                                        />
                                        <CustomTypography

                                            text={<span style={{ fontWeight: 400, color: data.is_active ? 'green' : 'red' }}>{data.is_active ? 'Active' : 'Inactive'} <span onClick={() => setData({ ...data, is_active: !data.is_active })} style={{ color: '#2464EB', cursor: 'pointer', }}>({!data.is_active ? 'Mark as Active' : 'Mark as Inactive'})</span></span>}
                                            sx={{
                                                mt: 2,
                                                mb: 1,
                                                fontSize: '14px',
                                                color: '#000000',
                                                fontWeight: '700',
                                                lineHeight: '18px',
                                                fontStyle: 'normal',
                                                textTransform: "none",
                                                fontFamily: "Noto Sans",
                                            }}
                                        />
                                    </Box>
                                }
                            </Box>
                        </Box>
                        :
                        <CustomTypography
                            text={<Loader />}
                            sx={{
                                mt: '35vh',
                                color: '#000000',
                                fontSize: '14px',
                                fontWeight: '700',
                                textAlign: 'center',
                                lineHeight: '18px',
                                fontStyle: 'normal',
                                fontFamily: "Noto Sans",
                            }}
                        />
                    }
                    <Box sx={{ p: 1, pr: 2, pl: 2, backgroundColor: '#F2F2F2', position: 'absolute', borderTop: '2px solid #e0e0e0', bottom: 0, left: 0, right: 0, }}>
                        <Buttons onSave={() => { onSaveHandler() }} onCancel={() => { handleClose() }} />
                    </Box>
                </Box>

            </Drawer>
        </React.Fragment>
    )
}


export default ReportSchedules;
const Buttons = (props) => {
    const { onSave, onCancel } = props
    return (
        <Box component="form" noValidate sx={{}}>
            <Grid container>
                <Grid item xs={12} sx={{ display: '-webkit-flex', justifyContent: 'end', }}>
                    <CustomButton id={'common_report_schedules_cancel_btn'} dataTestId={'common_report_account_mapping_cancel_btn'} variant='outlined' sx={{ textTransform: 'none', '&:hover': { backgroundColor: '#e8f3ff' } }} btnLabel='Cancel' onClick={onCancel} />
                    <CustomButton id={'common_report_account_mapping_save_btn'} dataTestId={'common_report_account_mapping_save_btn'} variant='contained' sx={{ ml: 1, textTransform: 'none', }} btnLabel={'Save & Run'} onClick={onSave} />
                </Grid>
            </Grid>
        </Box>
    )
}


const Information = (props) => {
    const { data, isFormSubmitted, setData, userLists = [], selectEmails, setSelectEmails } = props

    const FrequencyList = [
        { name: `Weekly`, value: `weekly` },
        { name: `Monthly`, value: `monthly` },
        { name: `Quarterly`, value: `quarterly` },
        { name: `Yearly`, value: `yearly` },
    ]

    const onHandleChange = (key_name, value) => {
        setData((previous) => ({ ...previous, ...data, [key_name]: value }))
    }

    let selected_frequency = FrequencyList.find((item) => item.value === data.repeat_every);

    const myRef = React.useRef();
    const [clickedOutsideCC, setClickedOutsideCC] = React.useState(true);
    const handleClickInside_CC = () => { setClickedOutsideCC(false) };

    const handleClickOutside_CC = e => {
        if (!myRef.current.contains(e.target)) {
            setClickedOutsideCC(true);
        }
    };
    React.useEffect(() => {
        document?.addEventListener('mousedown', handleClickOutside_CC);
        return () => document.removeEventListener('mousedown', handleClickOutside_CC);
    });

    return (
        <Grid container spacing={3}>

            <Grid item xs={12}>
                <CustomTypography
                    text={<LabelWithAsteriskMark label={'Frequency'} />}
                    sx={{
                        mb: 1,
                        fontSize: '14px',
                        color: '#000000',
                        fontWeight: '700',
                        lineHeight: '18px',
                        fontStyle: 'normal',
                        textTransform: "none",
                        fontFamily: "Noto Sans",
                    }}
                />
                <CustomDefaultDropdown
                    placeholder=''
                    labelKey='name'
                    fullWidth={true}
                    validation={false}
                    MenuItemStyle={{}}
                    valueLabelKey={'name'}
                    results={FrequencyList}
                    disabledCloseIcon={true}
                    id={'report_schedules_frequency_dropdown'}
                    dataTestId={'report_schedules_frequency_dropdown'}
                    value={selected_frequency ? selected_frequency : null}
                    setValue={(selectedData) => {
                        if (selectedData) {
                            onHandleChange('repeat_every', selectedData.value)
                        } else {

                        }
                    }}
                />
                <CustomHelperText label={<p style={{ margin: '0px', marginTop: '5px', marginLeft: '5px' }}>{`Report will be generated and sent on a ${data.repeat_every} basis.`}</p>} />

            </Grid>
            <Grid item xs={12}>
                <DatePicker
                    isTime={true}
                    date={data.start_date}
                    disabledCloseIcon={true}
                    id={'start_date_timing_select'}
                    dataTestId={'start_date_timing_select'}
                    dateTimeFormat={getDateFormat()+' hh:mm a'}
                    minDate={data.start_date > moment() ? moment() : data.start_date}
                    setDate={(value) => { onHandleChange('start_date', value) }}
                    title={<LabelWithAsteriskMark label={'Start Date & Time'} />}
                />
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
                <CustomTypography
                    text={'Recipient Details'}
                    sx={{
                        fontSize: '18px',
                        color: '#000000',
                        fontWeight: '700',
                        lineHeight: '18px',
                        fontStyle: 'normal',
                        textTransform: "none",
                        fontFamily: "Noto Sans",
                        textTransform: 'capitalize',
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <CustomTypography
                    text={<LabelWithAsteriskMark label={'Email Recipients'} />}
                    sx={{
                        mb: 1,
                        fontSize: '14px',
                        color: '#000000',
                        fontWeight: '700',
                        lineHeight: '18px',
                        fontStyle: 'normal',
                        textTransform: "none",
                        fontFamily: "Noto Sans",
                    }}
                />
                <DropdownWithMultipleValue
                optionLabel='email'
                results={userLists}
                value={selectEmails}
                matchValueKey='email'
                groupByLabel='fullname'
                id={'email_recipients_dropdown'}
                dataTestId={'email_recipients_dropdown'}
                setValue={(value) => { setSelectEmails(value) }}
                validation={isFormSubmitted && selectEmails.length === 0}
                />
            </Grid>

            <Grid item xs={12}>
                <CustomTypography
                    text={'Additional Recipients'}
                    sx={{
                        mb: 1,
                        fontSize: '14px',
                        color: '#000000',
                        fontWeight: '700',
                        lineHeight: '18px',
                        fontStyle: 'normal',
                        textTransform: "none",
                        fontFamily: "Noto Sans",
                    }}
                />
                <div onClick={handleClickInside_CC} ref={myRef}>
                    <ReactMultiEmail
                        id={'input_report_schedules_to_cc'}
                        dataTestId={'input_report_schedules_to_cc'}
                        emails={data.to_cc ? data.to_cc : []}
                        onChange={(valueEmails) => {
                            data.to_cc = valueEmails
                            setData({ ...data })
                        }}
                        style={{
                            marginTop: '0px',
                            maxHeight: data.to_cc.length === 0 ? 35 : '',
                            textAlign: 'center', borderRadius: "4px", overflowY: 'scroll', borderColor: (clickedOutsideCC) ? '#c7c7c7' : "#2464EB", borderWidth: clickedOutsideCC ? 1.5 : 1.8,
                        }}

                        getLabel={(
                            email,
                            index,
                            removeEmail
                        ) => {

                            return (
                                <div data-tag key={index} style={{...input_css_style}}>{email}<span data-tag-handle onClick={() => removeEmail(index)}>×</span>
                                </div>
                            );
                        }}
                        validateEmail={email => {
                            return email?isEmail(email):false; // return boolean
                        }}
                    />
                </div>
            </Grid>
        </Grid>
    )
}