import React from 'react'
import { styled } from '@mui/system';
import { TextareaAutosize } from '@mui/base';

const CustomTextArea = (
    {
        id,
        value,
        style,
        minRows,
        onChange,
        dataTestId,
        placeholder,
        ...props
    }
) => {

    return (
        <StyledTextarea
            id={id}
            value={value}
            minRows={minRows}
            onChange={onChange}
            dataTestId={dataTestId}
            placeholder={placeholder}
            style={{
                ...style,
                fontWeight: 400,
                background: props.disabled ? '#F1F3F4' : '',
            }}
            {...props}
        />
    )
}

export default CustomTextArea;


const StyledTextarea = styled(TextareaAutosize)(
    `
    padding: 12px;
    color: #000000;
    font-size: 12px;
    line-height: 16px;
    border-radius:4px;
    background: #ffffff;
    font-family: Noto Sans;
    border: 1px solid #c4c4c4;

    &:focus {
      border-color: #2464eb;
      box-shadow: 0 0 0 1px #2464eb
    }

    // firefox
    &:focus-visible {
      outline: 0;
  `,
);

