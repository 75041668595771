// eslint-disable-next-line
Object.defineProperty(String.prototype, 'capitalize', {
  value: function () {
    return this.toLowerCase().split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
  },
  enumerable: false
});

// eslint-disable-next-line
Object.defineProperty(String.prototype, 'toFloat', {
  value: function (upto=2) {
    if(this){
      return parseFloat(parseFloat(this).toFixed(upto))
    }else{
      return 0
    }
    
  },
  enumerable: false
});

// eslint-disable-next-line
Object.defineProperty(Number.prototype, 'toFloat', {
  value: function (upto=2) {
    if(this){
      return parseFloat(parseFloat(this).toFixed(upto))
    }else{
      return 0
    }
  },
  enumerable: false
});

// eslint-disable-next-line
Object.defineProperty(Number.prototype, 'truncDigits', {
  value: function (digits) {
    const fact = 10 ** digits;
    return Math.floor(this * fact) / fact;
  },
  enumerable: false
});






