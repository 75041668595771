import React from "react";
import moment from "moment";
import { Box, Grid } from "@mui/material";
import DatePicker from "../../common/DatePicker";

export default function CustomDateRangePicker(props) {

    const { onDateRangeChange, disabledCloseIcon = false, dates } = props
    const [startDate, setStartDate] = React.useState(null)
    const [endDate, setEndDate] = React.useState(null)
    const [endDateOpen, setEndDateOpen] = React.useState(false)

    const onStartDateChange = (date) => {

        if (date) {
            setStartDate(date)
            setEndDateOpen(true)
        } else {
            setStartDate(null)
            setEndDate(null)
            setEndDateOpen(false)
            onDateRangeChange(null)
        }

    }

    const onEndDateChange = (date) => {
        if (date) {
            setEndDate(date)
            onDateRangeChange([startDate, date])
            setEndDateOpen(false)
        } else {
            setEndDate(null)
            setEndDateOpen(true)
        }

    }

    React.useEffect(() => {
        if (dates) {
            setEndDate(dates[1]);
            setStartDate(dates[0]);
        }else{
            setEndDate(null);
            setStartDate(null);  
        }
    }, [dates])

    React.useEffect(()=>{
        if (!endDateOpen&&startDate&&endDate) {
            onDateRangeChange([startDate, endDate])   
        }

    },[endDateOpen])

    return (
        <Grid container item xs={12} sx={{}} spacing={1}>
            <Grid item xs={12} sm={5.5}>
                <DatePicker
                    id={'start_date_select'}
                    dataTestId={'start_date_select'}
                    title='Start Date'
                    placeholder="From"
                    onOpen={() => setEndDateOpen(false)}
                    disabledCloseIcon={disabledCloseIcon}
                    date={startDate ? moment(startDate) : null}
                    setDate={(date) => onStartDateChange(date)}
                />
            </Grid>
            <Grid item xs={12} sm={5.5}>

                {startDate ?
                    <DatePicker
                        id={'end_date_select'}
                        dataTestId={'end_date_select'}

                        placeholder="To"
                        title='End Date'
                        open={endDateOpen}
                        disabledCloseIcon={disabledCloseIcon}
                        date={endDate ? moment(endDate) : null}
                        setDate={(date) => onEndDateChange(date)}
                        minDate={startDate ? moment(startDate) : null}
                        onClose={() => { endDate && setEndDateOpen(false) }}
                        onOpen={() => { startDate ? setEndDateOpen(true) : setEndDateOpen(false) }}
                    />

                    :

                    <DatePicker
                        id={'end_date_select'}
                        dataTestId={'end_date_select'}

                        placeholder="To"
                        title='End Date'
                        open={endDateOpen}
                        disabledCloseIcon={disabledCloseIcon}
                        date={endDate ? moment(endDate) : null}
                        setDate={(date) => onEndDateChange(date)}
                        onOpen={() => { startDate ? setEndDateOpen(true) : setEndDateOpen(false) }}

                    />

                }


            </Grid>
        </Grid>
    );
}