import React from 'react'
import { DataPointComponent } from '../../dataPoint/DataPointComponents';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import {
    Box,
    Divider,
} from '@mui/material';

const List = (props) => {
    const {mb=2.5, data, setData, to_date, from_date, refreshDataPoint, handleDataPointDrop } = props;
    
    return (
        <React.Fragment>
            <DragDropContext onDragEnd={handleDataPointDrop}>
                <Droppable droppableId="dataPoints">
                    {(provided, snapshot) => (
                        <Box
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            sx={{
                                mb: mb,
                                mt: snapshot.isDraggingOver ? 0.1 : 0,
                                transition: 'background-color 0.2s ease',
                                backgroundColor: snapshot.isDraggingOver ? '#ffebe6' : 'transparent',
                            }}
                        >
                            { data?.data_point_ids?.map((item, index) => (
                                    item&&
                                    <Draggable
                                        key={item}
                                        index={index}
                                        draggableId={item.toString()}
                                    >
                                        {(provided, snapshot) => (
                                            <Box
                                                sx={{
                                                    pl: 1.5,
                                                    cursor: 'grab',
                                                    border: snapshot.isDragging ? '2px solid #F5F5F5' : '',
                                                    backgroundColor: snapshot.isDragging ? '#c8e6c9' : '#ffffff'
                                                }}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                            >
                                                <Box

                                                    {...provided.dragHandleProps}
                                                    sx={{
                                                        pt: 1, pb: 1
                                                    }}
                                                >
                                                    <DataPointComponent
                                                        to_date={to_date}
                                                        sectionData={data}
                                                        from_date={from_date}
                                                        data_point_id={item}
                                                        refreshDataPoint={refreshDataPoint}
                                                        setSectionData={(item) => { setData(item); }}

                                                    />
                                                </Box>
                                                <Box sx={{ pr: 1.5, }}>
                                                    <Divider
                                                        orientation='horizontal'
                                                        sx={{
                                                            border: 'none',
                                                            height: '1px',
                                                            bgcolor: "#F5F5F5"
                                                        }}
                                                    />
                                                </Box>
                                            </Box>
                                        )}
                                    </Draggable>
                                ))}
                            {provided.placeholder}
                        </Box>
                    )}
                </Droppable>
            </DragDropContext>
        </React.Fragment>
    )
}

export default List
