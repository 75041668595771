import React from 'react';
import CustomTitle from '../../../../../common/CustomTitle';
import CustomButton from '../../../../../custom/button/CustomButton';

import {
    CustomRadio,
    CustomHeading,
    CustomCheckbox,
    CustomHelperText,
} from '../common/CustomElement';

import {
    CustomContainer,
    CustomTitleContainer,
} from '../../../../../custom/container/CustomContainer';

import {
    Box,
    Grid,
} from '@mui/material'

const CustomerPreferences = (props) => {
    const { initial_data, onSaveHandler } = props;

    const [data, setData] = React.useState({ ...initial_data });

    const onHandleChange = (key_name, value) => {
        setData((previous) => ({ ...previous, ...data, customer: { ...data.customer, [key_name]: value } }))
    }
    return (
        data &&
        <div>
            <CustomTitleContainer>
                <Grid container spacing={0} style={{
                    alignItems: "center",
                    justifyItems: "center",
                    alignContent: "space-evenly",
                    justifyContent: "space-evenly",
                }}>
                    <Grid item xs={6}>
                        <CustomTitle title={'Customers Preferences'} />
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: "end", alignSelf: "center" }}>
                        <CustomButton
                            sx={{}}
                            btnLabel="Save"
                            variant="contained"
                            id={"save_customer_preferences"}
                            dataTestId={"save_customer_preferences"}
                            onClick={() => { onSaveHandler(data) }}
                        />
                    </Grid>
                </Grid>
            </CustomTitleContainer>
            <CustomContainer maxWidth={"400"} sx={{ height: 'calc(100vh - 134px)', }}>
                <Box sx={{ p: 2, pt: 1 }}>
                    <Type data={data} setData={setData} onHandleChange={onHandleChange} />
                    {/* <CreditLimit data={data} setData={setData} onHandleChange={onHandleChange} /> */}
                </Box>
            </CustomContainer>

        </div>
    )
}

export default CustomerPreferences;

const Type = (props) => {
    const { data, setData, onHandleChange } = props;

    return (
        <Box sx={{ pb: 2, mb: 2, borderBottom: '1px solid #e0e0e0' }}>
            <CustomHeading label={`Default Customer Type`} />
            <CustomHelperText label={`Select the default customer type based on the kind of customers you usually sell your products or services. The default customer type will be pre‑selected in the customer creation form.`} /><br />

            <CustomRadio checked={data && data.customer.default_party_type==="business"} onClickedHandler={(value) => onHandleChange('default_party_type', 'business')} label={`Business`} /><br />
            <CustomRadio checked={data && data.customer.default_party_type==="individual"} onClickedHandler={(value) => onHandleChange('default_party_type', 'individual')} label={`Individual`} /><br />
        </Box>
    )
}

const CreditLimit = (props) => {
    const { data, setData, onHandleChange } = props;

    return (
        <Box sx={{ pb: 2, mb: 2, borderBottom: '1px solid #e0e0e0' }}>
            <CustomHeading label={`Customer Credit Limit`} />
            <CustomHelperText label={`Credit Limit enables you to set limit on the outstanding receivable amount of the customers.`} /><br />

            <CustomCheckbox checked={false} onClickedHandler={(value) => onHandleChange('enable_inventory', !value)} label={`Enable Credit Limit`} /><br />
        </Box>
    )
}