import React from 'react';
import moment from 'moment';
import ReportsSettingButtons from '../../ReportsSettingButtons';
import { apiAction } from "../../../../../api/api";
import ReportsTitle from '../../common/ReportsTitle';
import * as Actions from '../../../../../state/Actions';
import { export_movement_of_equity } from '../../../../../api/urls';
import { getBusinessInfo } from '../../../../../config/cookiesInfo';
import CurrencyFormatter from '../../../../common/CurrencyFormatter';
import CommonCustomDateRange from '../../../../common/CommonCustomDateRange';
import { CustomContainer } from '../../../../custom/container/CustomContainer';
import {
  setLoader,
  date_range,
  getDateFormat,
} from '../../../../../utils/Utils';

import {
  Grid,
} from '@mui/material';

import {
  CustomTable,
  CustomTableRow,
  CustomTableBody,
  CustomTableHead,
  CustomTableHeadCell,
  CustomTableBodyCell,
  CustomTableContainer,
} from '../../../../custom/table/CustomTable';

const MovementEquityReports = () => {
  const stateAction = Actions.getState(React.useContext);
  const dispatch = Actions.getDispatch(React.useContext);

  const { selectedDateRange } = stateAction;
  let filter_date_range = date_range().find((item) => item.name === selectedDateRange)
  const default_date_range = filter_date_range ? filter_date_range : date_range()[2]

  const [dateRange, setDateRange] = React.useState(default_date_range);
  const [fromDate, setFromDate] = React.useState(moment());
  const [toDate, setToDate] = React.useState(moment());
  const [results, setResults] = React.useState();
  const [date, setDate] = React.useState();

  React.useEffect(() => {
    if (dateRange) {
      if (dateRange.name !== "Custom") {
        getApiResults(dateRange.dates.from_date, dateRange.dates.to_date, dateRange.dates.date_range)
      } else {
        getApiResults(fromDate.format('YYYY-MM-DD'), toDate.format('YYYY-MM-DD'), `${fromDate.format(getDateFormat())} - ${toDate.format(getDateFormat())}`)
      }
    }
    // eslint-disable-next-line
  }, [dateRange, toDate, fromDate,]);

  const getApiResults = async (from_date, to_date, range_date) => {
    setLoader(dispatch, Actions, true)
    setDate(range_date)
    let res = await apiAction({
      method: 'post',
      url: export_movement_of_equity(),
      data: {
        to_date: to_date,
        from_date: from_date,
        business_id: getBusinessInfo().id,
      },
    })

    if (res?.success) {
      let result = res?.result;
      setResults(result.result);
      setLoader(dispatch, Actions, false);
    } else {
      setLoader(dispatch, Actions, false);
    }
  }

  return (
    results &&
    <div>
      <ReportsSettingButtons
        scheduleData={{
          report_name: 'movement_of_equity',
          report_title: 'Movement of Equity',

        }}
        exportData={{ url: export_movement_of_equity(), data: dateRange.dates ? { ...dateRange.dates, } : { to_date: toDate.format('YYYY-MM-DD'), from_date: fromDate.format('YYYY-MM-DD'), } }}
      />
      <CustomContainer maxWidth={"400"} sx={{ height: 'calc(100vh - 134px)' }}>
        <div style={{ paddingLeft: '16px', paddingRight: '16px' }}>
          <Grid container spacing={0} sx={{ pb: 2 }} style={{
            alignItems: 'center',
            justifyItems: 'center',
            alignContent: 'space-evenly',
            justifyContent: 'space-evenly',
          }}>
            <Grid item xs={12} sm={4}>
              <ReportsTitle
                date={date}
                title={'Movement of Equity'}
              />
            </Grid>
            <Grid container spacing={1} item xs={12} sm={8} sx={{ justifyContent: 'end', }}>
              <CommonCustomDateRange
                setPage={() => { }}
                date_range={date_range()}

                toDate={toDate}
                setToDate={setToDate}

                fromDate={fromDate}
                setFromDate={setFromDate}

                dateRange={dateRange}
                setDateRange={setDateRange}
              />
            </Grid>
          </Grid>
        </div>
        <CustomTableContainer>
          <CustomTable>
            <Header />
            <Body data={results} />
            <caption style={{ fontFamily: 'Noto Sans', fontWeight: '500', fontSize: '10px', fontStyle: 'normal', lineHeight: '14px' }}>**Amount is displayed in your base currency <span style={{ backgroundColor: '#6ff795', paddingLeft: '5px', paddingRight: '5px', paddingBottom: '2px', paddingTop: '1px' }}>{getBusinessInfo().currency.currency_code}</span></caption>
          </CustomTable>
        </CustomTableContainer>
      </CustomContainer>
    </div>
  )
}

export default MovementEquityReports;

const Header = () => {
  return (
    <CustomTableHead>
      <CustomTableRow >
        <CustomTableHeadCell><span>Account</span></CustomTableHeadCell>
        <CustomTableHeadCell align='right'><span>Total</span></CustomTableHeadCell>
      </CustomTableRow>
    </CustomTableHead>
  )
}

const Body = (props) => {
  const { data, } = props;

  return (
    <CustomTableBody>
      <BodyData
        title='Opening Balance'
        value={data.opening_balance}
        style={{ fontWeight: '500' }}
      />
      <CustomTableRow>
        <CustomTableBodyCell sx={{}} colSpan={12}>Changes in Equity</CustomTableBodyCell>
      </CustomTableRow>
      {
        data.current_year_earning ?
          <BodyData
            title={'Current Year Earnings'}
            value={data.current_year_earning}
            style={{ paddingLeft: '28px', fontWeight: '500' }}
          />
          : null
      }
      {
        data.changes_in_equity_list.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <BodyData
                title={item.account_name}
                value={item.account_balance}
                style={{ paddingLeft: '28px', fontWeight: '500' }}
              />
            </React.Fragment>
          )
        })
      }
      <BodyData
        title='Net Changes in Equity'
        value={data.net_changes_in_equity}
        style={{ fontWeight: '700' }}
      />
      <BodyData
        title='Closing Balance'
        value={data.closing_balance}
        style={{ fontWeight: '700' }}
      />
    </CustomTableBody>
  )
}

const BodyData = (props) => {
  const { title, value, style } = props;
  return (
    <CustomTableRow>
      <CustomTableBodyCell sx={{ ...style }}  >{title}</CustomTableBodyCell>
      <CustomTableBodyCell sx={{ ...style }} align='right'><span>{<CurrencyFormatter amount={value} currency={null} />}</span></CustomTableBodyCell>
    </CustomTableRow>
  )
}