import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomTypography from '../../../custom/typography/CustomTypography';

import {
    Box,
    List,
    Grid,
    Divider,
    ListItem,
    Container,
    ListItemText,
} from '@mui/material';
import MailIcon from '@mui/icons-material/Mail';
import PhoneIcon from '@mui/icons-material/Phone';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { routesName } from '../../../../config/routesName';

const Footer = () => {
    return (
        <Container maxWidth={false} disableGutters sx={{ pl: { xs: 0, sm: 14 }, pr: { xs: 0, sm: 14 } }}>
            <Box sx={{ padding: '35px 0' }}>
                <Grid container sx={{pl:2}}>
                    <Grid item xs={6} sm={3}>
                        <FeatureList title="Features" items={features1} />
                    </Grid>

                    <Grid item xs={6} sm={3}>
                        <FeatureList title="Features" items={features2} visibility="hidden" />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <ContactList title="Contact us" />
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};

export default Footer;

const FeatureList = ({ title, items, visibility = 'visible' }) => {
    return (
        <React.Fragment>
            <CustomTypography
                text={title}
                sx={{
                    pb: 2,
                    visibility,
                    fontWeight: 700,
                    color: '#141414',
                    fontSize: '20px',
                    lineHeight: 'auto',
                    letterSpacing: '0px',
                }}
            />
            <List sx={{ml:'-5px'}}>
                {items.map((item, index) => (
                    <FeatureListItem key={index} item={item} />
                ))}
            </List>
        </React.Fragment>
    );
};

const FeatureListItem = ({ item }) => {
    const location = useLocation();
    const navigate = useNavigate();

    const onClickHandler = async () => {
        if (location.pathname === '/landing' + routesName.demoPage.path) {
            await navigate('/landing');
        }
        item.onClick();
    };

    return (
        <ListItem
            sx={{
                fontWeight: 400,
                fontSize: '16px',
                color: '#666666',
                cursor: 'pointer',
                paddingLeft: '0px',
                fontStyle: 'normal',
                whiteSpace: 'nowrap',
                lineHeight: '33.04px',
                fontFamily: "Noto Sans",
                '&:hover': {
                    color: '#2464EB',
                    textDecoration: 'underline',
                },
            }}
            onClick={onClickHandler}
        >
            <KeyboardArrowRightIcon sx={{ }} />
            <ListItemText sx={{}} primary={item.name} />
        </ListItem>
    );
};

const ContactList = ({ title }) => {
    return (
        <React.Fragment>
            <CustomTypography
                text={title}
                sx={{
                    pb: 2,
                    fontWeight: 700,
                    color: '#141414',
                    fontSize: '20px',
                    lineHeight: 'auto',
                    letterSpacing: '0px',
                }}
            />
            <List>
                <ListItem sx={{ paddingLeft: '0px', color: '#666666', fontWeight: 400,fontSize:'16px',lineHeight:'24px',letterSpacing:'0.02px', fontFamily: "Noto Sans" }}>
                    <ListItemText
                        primary={
                            <Box sx={{ display: 'flex', maxWidth: '630px', justifyContent: 'space-between' }}>
                                <span>903, Vashi Infotech Park,<br />Sector 30A, Vashi, Navi Mumbai.<br />Maharashtra 400703.</span>
                                <span>304, Sun Square Business Hub,<br />#33, RAB Complex, 100 ft Ring Road,<br />15th Cross, 18th Main, JP Nagar 2nd Phase,<br />Bangalore, Karnataka 560078</span>
                            </Box>
                        }
                    />
                </ListItem>
                <Divider />
                <ContactItem
                    icon={<MailIcon sx={{ fontSize: 22 }} />}
                    href="mailto:contact@artdexandcognoscis.com"
                    text="contact@artdexandcognoscis.com"
                />
                <ContactItem
                    icon={<PhoneIcon sx={{ color: '#666666', fontSize: 24 }} />}
                    text="+91 22 49742883"
                />
            </List>
        </React.Fragment>
    );
};

const ContactItem = ({ icon, href, text }) => (
    <ListItem
        href={href}
        component={href ? 'a' : 'div'}
        sx={{ fontSize:'16px',lineHeight:'24px',letterSpacing:'0.02px',fontFamily: "Noto Sans", paddingLeft:'0px', color:'#666666', ':hover': {   color: href ? '#2464EB':'#666666', textDecoration: href ? 'underline' : 'none', cursor: href ? 'pointer' : 'default' } }}
    >
        {icon}
        <ListItemText sx={{ pl: 1 }} primary={text} />
    </ListItem>
);

const handleFeatureClickTab = (tab) => {
    const anchor = document.querySelector('#feature_section');
    if (anchor) {
        document.querySelector(tab)?.click();
        anchor.scrollIntoView({ block: 'start' });
    }
};

const features1 = [
    { name: 'Dashboard', onClick: () => handleFeatureClickTab('#tabs_0') },
    { name: 'Invoicing', onClick: () => handleFeatureClickTab('#tabs_1') },
    { name: 'Expenses', onClick: () => handleFeatureClickTab('#tabs_2') },
    { name: 'Accounting', onClick: () => handleFeatureClickTab('#tabs_3') },
];

const features2 = [
    { name: 'Reports', onClick: () => handleFeatureClickTab('#tabs_4') },
    { name: 'GST', onClick: () => handleFeatureClickTab('#tabs_5') },
    { name: 'Inventory', onClick: () => handleFeatureClickTab('#tabs_6') },
    { name: 'Tally Integration', onClick: () => handleFeatureClickTab('#tabs_7') },
];
