import React from 'react';
import Input from '../../../../common/Input';
import { apiAction } from '../../../../../api/api';
import { getUniqueObjects } from '../common/Helper';
import Loader from '../../../../custom/loader/Loader';
import AsyncDropdown from '../../../../common/AsyncDropdown';
import { list_of_data_point } from '../../../../../api/urls';
import CustomButton from '../../../../custom/button/CustomButton';
import { getBusinessInfo } from '../../../../../config/cookiesInfo';
import { LabelWithAsteriskMark } from '../../../../common/CommonLabel';
import CustomTypography from '../../../../custom/typography/CustomTypography';
import { CustomContainer } from '../../../../custom/container/CustomContainer';
import { CustomButtonContainer } from '../../../../custom/container/CustomContainer';
import AllowDuplicateMultipleValueDropdown from '../../../../custom/dropdown/AllowDuplicateMultipleValueDropdown';


import {
    Box,
    Grid,
} from '@mui/material';

const EditSection = (props) => {
    const { data, isExisting = false, isFormSubmitted, onClose, onManageSection, boxHeight = 'calc(100vh - 250px)' } = props

    const [value, setValue] = React.useState([]);
    const [isLoader, setIsLoader] = React.useState(true);
    const [dataPoints, setDataPoints] = React.useState([]);

    const [loaderMore, setLoadMore] = React.useState(0);
    const [dataPointGroup, setDataPointGroup] = React.useState([]);

    const initial_data = {
        business_id: getBusinessInfo().id,

        name: null,
        tab_id: null,
        section_id: null,
        data_point_ids: []
    }
    const [formData, setFormData] = React.useState({ ...initial_data });

    const onChangeHandler = (item) => {
        setValue(item)
    }

    const fetchDefaultDataPoint = async (data_point_ids = [], previousResult = []) => {
        if (data_point_ids) {
            const res = await apiAction({
                url: `${list_of_data_point(getBusinessInfo().id, '').replace('&name=', '')}&pk_list=[${data_point_ids}]`
            });
            if (res?.success) {
                setIsLoader(false);
                // Arrange Result based on the order in data_point_ids
                const arrangedResult = data_point_ids.map(id => res?.result.result.find(item => item.id === id)).filter(item => item !== undefined);

                setValue([...getUniqueObjects([...previousResult, ...arrangedResult])]);
                setDataPoints([...getUniqueObjects([...dataPoints, ...arrangedResult])]);
            } else {
                setIsLoader(false);
            }
        } else {
            setIsLoader(false);
        }
    };

    React.useEffect(() => {
        if (data) {
            const groupedResults = Array.from({ length: Math.ceil(data.data_point_ids?.length / 10) }, (v, i) => data.data_point_ids.slice(i * 10, i * 10 + 10));
            setDataPointGroup(groupedResults);
            setFormData({ ...formData, name: data.name, section_id: data.id, tab_id: data.tab_id, data_point_ids: data.data_point_ids })
            if (groupedResults.length) {
                if (!isExisting && data) {
                    fetchDefaultDataPoint(groupedResults[loaderMore], [])
                }
            } else {
                setIsLoader(false)
            }
        }
        setTimeout(() => {
            if (isExisting) {
                setIsLoader(false)
            };
        }, 1000);
    }, [data])


    const onSave = () => {
        if (isExisting) {
            onManageSection({ ...formData, data_point_ids: data.data_point_ids ? [...new Set([...data.data_point_ids, ...value.map(item => item.id)])] : [...value.map(item => item.id)] })
        } else {
            console.log('==>value', value)
            onManageSection({ ...formData, data_point_ids: value?.length ? [...value?.map(item => item?.id)] : [] })
        }
    }

    const getHasDependencies = (type) => {
        if (type === 'Account' || type === 'Function') {
            return true;
        }
        return false;
    };
    return (
        <Box sx={{ mt: 0, overflow: 'hidden' }}>
            <CustomContainer maxWidth={"400"} sx={{ height: boxHeight, }}>
                {isLoader ?
                    <Box sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <CustomTypography
                            text={<Loader />}
                            sx={{
                                color: '#000000',
                                fontSize: '14px',
                                fontWeight: '700',
                                textAlign: 'center',
                                lineHeight: '18px',
                                fontStyle: 'normal',
                                fontFamily: "Noto Sans",
                            }}
                        />
                    </Box>
                    :
                    <Box sx={{ p: 2, pt: 0 }}>
                        <Grid container spacing={3}>
                            {!isExisting &&
                                <Grid item xs={12}>
                                    <Input
                                        autoFocus={true}
                                        id={'input_section_name'}
                                        dataTestId={'input_section_name'}
                                        item={{
                                            type: 'text',
                                            fullWidth: true,
                                            placeholder: 'Enter Name',
                                            value: formData.name ? formData.name : '',
                                            validation: isFormSubmitted && !formData.name,
                                            title: <LabelWithAsteriskMark label={'Name'} />,
                                            onChange: (e) => { setFormData((previous) => ({ ...previous, ...formData, name: e.target.value !== '' ? e.target.value : null })) },
                                        }}
                                    />
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <CustomTypography
                                    text={'Data Points'}
                                    sx={{
                                        mb: 1,
                                        color: '#000000',
                                        fontSize: '14px',
                                        fontWeight: '700',
                                        lineHeight: '18px',
                                        fontStyle: 'normal',
                                        textTransform: 'none',
                                        fontFamily: "Noto Sans",
                                    }}
                                />
                                {data?.section_type === 'pie_chart' ?
                                    <AsyncDropdown
                                        sx={{}}
                                        maxHeight={250}
                                        fullWidth={true}
                                        autoFocus={false}
                                        validation={false}
                                        disabledCloseIcon={false}
                                        isGroupHeaderSticky={false}
                                        id={'mapping_account_type_'}
                                        dataTestId={'mapping_account_type_'}

                                        selectedValue={value?.[0]}
                                        setSelectedValue={(selectedData) => {
                                            if (selectedData) {
                                                setValue([selectedData])
                                            } else {
                                                setValue()
                                            }
                                        }}

                                        searchKey='name'
                                        valueLabelKey='id'
                                        optionLabelKey='name'
                                        uniqueOptionKey='id'
                                        placeholder='Select the Account'
                                        optionGroupLabelKey='data_point_type'
                                        getOptionDisabled={(option) => getHasDependencies(option?.data_point_type)}

                                        METHOD='get'
                                        nextUrlAdditionData={`&business_id=${getBusinessInfo().id}&sort_by=data_point_type`}
                                        URL={list_of_data_point(getBusinessInfo().id, '').replace('&name=', '') + '&sort_by=data_point_type'}

                                    />
                                    :
                                    <AllowDuplicateMultipleValueDropdown
                                        groupByLabel='data_point_type'
                                        matchValueKey='id'
                                        optionLabel='name'
                                        value={[...value]}
                                        defaultValue={[...value]}
                                        results={dataPoints}
                                        minInputBoxHeight={''}

                                        allowDuplicate={false}
                                        isOptionEqualToValue={true}

                                        setValue={onChangeHandler}
                                        id={'mapping_account_type_'}
                                        listboxStyle={{ maxHeight: 200 }}
                                        dataTestId={'mapping_account_type_'}
                                        url={list_of_data_point(getBusinessInfo().id, '').replace('&name=', '') + '&sort_by=data_point_type'}
                                    />
                                }

                                {
                                    !isExisting && dataPointGroup[loaderMore + 1] &&
                                    <Box sx={{ mt: 1, display: 'flex', justifyContent: 'flex-end' }}>
                                        <CustomTypography
                                            text={`Total data point is ${data.data_point_ids.length} and loaded data point is ${value.length} remaining data point ${data.data_point_ids.length - value.length}`}
                                            sx={{
                                                color: '#000000',
                                                fontSize: '14px',
                                                fontWeight: '700',
                                                lineHeight: '18px',
                                                fontStyle: 'normal',
                                                textTransform: 'none',
                                                fontFamily: "Noto Sans",
                                            }}
                                        />
                                        <CustomTypography
                                            id={'load_more_data_point'}
                                            dataTestId={'load_more_data_point'}
                                            text={'Load More'}
                                            sx={{
                                                ml: 2,
                                                color: '#2464EB',
                                                fontSize: '14px',
                                                cursor: 'pointer',
                                                fontWeight: '700',
                                                lineHeight: '18px',
                                                fontStyle: 'normal',
                                                textTransform: 'none',
                                                fontFamily: "Noto Sans",
                                                ":hover": { textDecoration: 'underline' }
                                            }}
                                            onClick={() => { setLoadMore(loaderMore + 1); fetchDefaultDataPoint(dataPointGroup[loaderMore + 1], value) }}
                                        />
                                    </Box>

                                }
                            </Grid>
                        </Grid>


                    </Box>
                }
            </CustomContainer>
            {!isLoader &&
                <CustomButtonContainer sx={{ mr: 0, ml: 0 }}>
                    <Buttons
                        onSave={() => { onSave() }}
                        onCancel={() => { onClose() }}
                    />
                </CustomButtonContainer>
            }
        </Box>
    )
}

export default EditSection;

const Buttons = (props) => {
    const { onSave, onCancel } = props
    return (
        <Box component="form" noValidate>
            <Grid container>
                <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'start' }}>
                    <CustomButton id={'cancel_btn'} dataTestId={'cancel_btn'} variant='outlined' sx={{ textTransform: 'none', '&:hover': { backgroundColor: '#e8f3ff' } }} btnLabel='Cancel' onClick={onCancel} />
                </Grid>
                <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'end', }}>
                    <CustomButton id={'save_btn'} dataTestId={'save_btn'} variant='contained' sx={{ ml: 1, textTransform: 'none' }} btnLabel={'Save'} onClick={onSave} />
                </Grid>
            </Grid>
        </Box>
    )
}
