import React from 'react';
import moment from 'moment';
import * as Common from '../../common/Common';
import { useNavigate } from 'react-router-dom';
import Status from '../../../../common/Status';
import * as Actions from "../../../../../state/Actions";
import CustomTitle from '../../../../common/CustomTitle';
import CommonSearch from '../../../../common/CommonSearch';
import { routesName } from '../../../../../config/routesName';
import { CommonWebSocket } from '../../../../../api/webSocket';
import DeliveryChallanAlertBox from './DeliveryChallanAlertBox';
import ListItemActions from '../../../../common/ListItemActions';
import CustomDialog from '../../../../custom/dialog/CustomDialog';
import CustomButton from '../../../../custom/button/CustomButton';
import { getBusinessInfo } from '../../../../../config/cookiesInfo';
import { NoDataComponent } from '../../../../common/NoDataComponent';
import CurrencyFormatter from '../../../../common/CurrencyFormatter';
import { HeadingWithSortable } from '../../../../common/SortableHeading';
import CommonAsyncDropdown from '../../../../common/CommonAsyncDropdown';
import CustomPagination from '../../../../custom/pagination/CustomPagination';
import {eventsNames, AnalyticsEvent } from '../../../../../firebase/firebaseAnalytics';
import CustomDateRangePicker from '../../../../custom/Datepicker/CustomDateRangePicker';

import {
    ActionTextLinkBtn,
    DeliveryChallanViewLink,
} from '../../common/CommonLinks';

import {
    CustomContainer,
    CustomTitleContainer,
} from '../../../../custom/container/CustomContainer';

import {
    apiAction,
    apiBlobResponse,
    apiHandleDownload,
} from '../../../../../api/api';

import {
    setLoader,
    getDateFormat,
    setFilterMessage,
    isFiltersApplied,
    stateChangeManager,
} from '../../../../../utils/Utils';

import {
    list_party,
    export_delivery_challan,
    delete_delivery_challan,
    update_delivery_challan,
    retrieve_delivery_challan,
    get_list_of_delivery_challan,
} from '../../../../../api/urls';

import {
    CustomTable,
    CustomTableRow,
    CustomTableBody,
    CustomTableHead,
    CustomTableHeadCell,
    CustomTableBodyCell,
    CustomTableContainer,
} from '../../../../custom/table/CustomTable';

import {
    Box,
    Grid,
} from '@mui/material';
import CurrencyFormatterWithExchangeRate from '../../common/CurrencyFormatterWithExchangeRate';

const ListDeliveryChallan = () => {
    let navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);

    const [page, setPage] = React.useState(1);
    const [results, setResults] = React.useState([])
    const [reLoad, setReLoad] = React.useState(false)
    const [pagination, setPagination] = React.useState({
        next: undefined,
        count: undefined,
        previous: undefined,
        number_of_pages: undefined,
    })


    const [state, setState] = React.useState({
        id: '',
        title: '',
        open: false,
        condition: '',
        maxWidth: 'lg',
        fullWidth: true,
    })

    const stateChangeHandler = (title, condition, maxWidth, url, deleteMessage) => {
        setState({
            url: url,
            open: true,
            title: title,
            fullWidth: true,
            maxWidth: maxWidth,
            condition: condition,
            deleteMessage: deleteMessage,
            onDeleteAction: () => { getApiResults(body, page) }

        })
    }

    const [filters, setFilters] = React.useState({
        sort_by: 'delivery_challan_date',
        sort_order: 'D'
    })


    const webSocketResponse = CommonWebSocket();
    React.useEffect(() => {
        if (webSocketResponse) {
            let webSocketData = JSON.parse(webSocketResponse.data)
            if (webSocketData.document_type === "delivery_challan") {
                let index = results.findIndex((item) => item.id === webSocketData.object_id);
                
                if (webSocketData.event === "delete_document" && index !== null && index >= 0) {
                    if (webSocketData.event_success) {
                        stateChangeManager(dispatch, Actions, true, "success", `Delivery Challan ${webSocketData.object_number} deleted successfully`);
                        getApiResults(body, page);
                    } else {
                        stateChangeManager(dispatch, Actions, true, "error", `Failed to delete Delivery Challan ${webSocketData.object_number}`);
                    }
                }

                if (webSocketData.event === "update_document" && index !== null && index >= 0) {
                    if (webSocketData.event_success) {
                        stateChangeManager(dispatch, Actions, true, "success", `Delivery Challan ${webSocketData.object_number} updated successfully`);
                        getApiResults(body, page);
                    } else {
                        stateChangeManager(dispatch, Actions, true, "error", `Failed to update Delivery Challan ${webSocketData.object_number}`);
                    }
                }
            }
        }
    }, [webSocketResponse])

    let body = { role: "customer", business_id: getBusinessInfo().id, ...filters }
    React.useEffect(() => {
        getApiResults(body, page);
        // eslint-disable-next-line
    }, [filters, page, reLoad])

    const getApiResults = async (body, page) => {
        setLoader(dispatch, Actions, true);
        setFilterMessage(dispatch, Actions, null);
        let res = await apiAction({
            data: body,
            method: 'post',
            dispatch: dispatch,
            navigate: navigate,
            url: get_list_of_delivery_challan(page),
        })

        if (res?.success) {
            setPagination({
                ...pagination,
                next: res?.result.next,
                count: res?.result.count,
                previous: res?.result.previous,
                number_of_pages: res?.result.number_of_pages,
            })
            setResults(res?.result.result);
            setLoader(dispatch, Actions, false);
            if (isFiltersApplied(filters)) { setFilterMessage(dispatch, Actions, `No delivery challans found for your current filters. Verify your filters and try again.`) };
        } else {
            setLoader(dispatch, Actions, false);

        }
    }

    
    React.useEffect(() => {
        const statusMapping = {
            open: eventsNames.actions.deliveryChallan.filter_type.OPEN,
            closed: eventsNames.actions.deliveryChallan.filter_type.CLOSED,
            returned: eventsNames.actions.deliveryChallan.filter_type.RETURNED,
            delivered: eventsNames.actions.deliveryChallan.filter_type.DELIVERED,
        };
    
        const filter_type = filters.status 
            ? statusMapping[filters.status] 
            : eventsNames.actions.deliveryChallan.filter_type.ALL;
    
        AnalyticsEvent(eventsNames.categories.DELIVERY_CHALLANS, {action: eventsNames.actions.CLICK,filter_type:filter_type});
    }, [filters.status]);
    return (
        <div>
            <CustomDialog
                state={state}
                setState={setState}
            />

            <CustomTitleContainer>
                <Grid container spacing={0} style={{
                    alignItems: 'center',
                    justifyItems: 'center',
                    alignContent: 'space-evenly',
                    justifyContent: 'space-evenly',
                }}>
                    <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'start', }}>
                        <CustomTitle title={'Delivery Challans'} />
                    </Grid>
                    <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'end', }}>
                        <CustomButton
                            id={'create_delivery_challan_btn'}
                            dataTestId={'create_delivery_challan_btn'}

                            variant="contained"
                            sx={{ textTransform: 'none', }}
                            btnLabel='Create Delivery Challan'
                            onClick={() => {
                                navigate(routesName.invoicingDeliveryChallanAdd.path)
                                AnalyticsEvent(eventsNames.categories.DELIVERY_CHALLANS,{action:eventsNames.actions.NEW})
                            }}
                        />
                    </Grid>
                </Grid>
            </CustomTitleContainer>

            <CustomContainer maxWidth={"400"} sx={{}}>
                <div style={{ paddingLeft: '16px', paddingRight: '16px', paddingBottom: '24px', display: '-webkit-flex', }}>
                    <Filters stateChangeHandler={stateChangeHandler} filters={filters} setFilters={setFilters} setPage={setPage} />
                </div>

                <CustomTableContainer>
                    <CustomTable sx={{ mt: 2, }}>
                        <ListSalesOrderHeaders filters={filters} setFilters={setFilters} />
                        <ListSalesOrderBody data={results} stateChangeHandler={stateChangeHandler} reload={() => setReLoad(!reLoad)} />
                    </CustomTable>
                    <Box sx={{ pt: !results.length ? 10 : 0 }} >
                        <NoDataComponent left='0%' top='0%' position={'relative'} data={results} />
                    </Box>
                </CustomTableContainer>
            </CustomContainer>

            <CustomPagination
                page={page}
                count={pagination.number_of_pages}
                onChange={(event, newValue) => setPage(newValue)}
            />
        </div>
    )
}

export default ListDeliveryChallan;


const ListSalesOrderHeaders = (props) => {
    const { filters, setFilters } = props

    return (
        <CustomTableHead>
            <CustomTableRow >
                <CustomTableHeadCell style={{ width: 100 }} align='center'><span style={{ cursor: '' }}>Status </span></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 100 }}><HeadingWithSortable heading={'Date'} sortableKey={'delivery_challan_date'} filters={filters} setFilters={setFilters} /></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 120 }}><HeadingWithSortable heading={'Number'} sortableKey={'delivery_challan_number'} filters={filters} setFilters={setFilters} /></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 210 }}><HeadingWithSortable heading={'Customer'} sortableKey={'customer_display_name'} filters={filters} setFilters={setFilters} /></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 150 }} align='right' ><HeadingWithSortable heading={'Total'} sortableKey={'total_amount'} filters={filters} setFilters={setFilters} /></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 200 }} align='right' ><span>Actions</span></CustomTableHeadCell>
            </CustomTableRow>
        </CustomTableHead>
    )
}

const ListSalesOrderBody = (props) => {
    const { data, stateChangeHandler, reload } = props

    let navigate = useNavigate();
    const [id, setId] = React.useState();
    const [open, setOpen] = React.useState(false);
    const dispatch = Actions.getDispatch(React.useContext);
    const onSelectAction = (action, item,logEvent) => {
        setLoader(dispatch, Actions, true)
        if (action === "edit") {
            navigate(routesName.invoicingDeliveryChallanAdd.path + "?id=" + item.id)
        }
        if (action === "view") {
            navigate(routesName.invoicingDeliveryChallanView.path + "?id=" + item.id)
            AnalyticsEvent(eventsNames.categories.DELIVERY_CHALLANS,{action:eventsNames.actions.VIEW})
        }
        if (action === "approve") {
            approveDeliveryChallan(item.id, { is_draft: false, })
            AnalyticsEvent(eventsNames.categories.DELIVERY_CHALLANS,{action:eventsNames.actions.APPROVE})
        }
        if (action === "mark_as_returned") {
            markAsReturned(item.id, { is_returned: true, })
            AnalyticsEvent(eventsNames.categories.DELIVERY_CHALLANS,{action:eventsNames.actions.MARK_AS_RETURNED})
        }
        if (action === "mark_as_delivered") {
            markAsDelivered(item.id, { is_delivered: true, })
            AnalyticsEvent(eventsNames.categories.DELIVERY_CHALLANS,{action:logEvent?logEvent:eventsNames.actions.MARK_AS_DELIVERED})
        }
        if (action === "mark_as_open") {
            markAsDelivered(item.id, { is_delivered: false, })
            AnalyticsEvent(eventsNames.categories.DELIVERY_CHALLANS,{action:eventsNames.actions.REVERT_TO_OPEN})
        }
        if (action === "print") {
            setOpen(true);
            setId(item.id);
            setLoader(dispatch, Actions, false);
            AnalyticsEvent(eventsNames.categories.DELIVERY_CHALLANS,{action:eventsNames.actions.PRINT})
        }
        if (action === "download") {
            downloadDeliveryChallan(item.id)
            AnalyticsEvent(eventsNames.categories.DELIVERY_CHALLANS,{action:eventsNames.actions.DOWNLOAD})
        }
        if (action === "convert") {
            setLoader(dispatch, Actions, true)
            navigate(routesName.invoicingSalesInvoiceAdd.path + "?challan_id=" + item.id)
            AnalyticsEvent(eventsNames.categories.DELIVERY_CHALLANS,{action:eventsNames.actions.deliveryChallan.action.CONVERT_INVOICE})
        }
    }


    const approveDeliveryChallan = async (id, is_draft) => {
        let data = await apiAction({
            method: 'post',
            url: retrieve_delivery_challan(id),
            data: { business_id: getBusinessInfo().id },
        })
        if (data) {
            const approveDeliveryChallanRes = await apiAction({ url: update_delivery_challan(id), method: 'POST', data: { ...is_draft, customer_id: data.result.customer_id, place_of_supply_id: data.result.place_of_supply.id, business_id: getBusinessInfo().id } })
            if (approveDeliveryChallanRes.success) {
                reload(true)
                stateChangeManager(dispatch, Actions, true, 'success', approveDeliveryChallanRes.status);
            } else {
                reload(true)
                stateChangeManager(dispatch, Actions, true, 'error', approveDeliveryChallanRes.status);
            }
        }
    }
    const markAsDelivered = async (id, is_delivered) => {
        let data = await apiAction({
            method: 'post',
            url: retrieve_delivery_challan(id),
            data: { business_id: getBusinessInfo().id },
        })
        if (data) {
            const res = await apiAction({ url: update_delivery_challan(id), method: 'POST', data: { ...is_delivered, customer_id: data.result.customer_id, place_of_supply_id: data.result.place_of_supply.id, business_id: getBusinessInfo().id } })
            if (res?.success) {
                reload(true)
                stateChangeManager(dispatch, Actions, true, 'success', res?.status);
            } else {
                reload(true)
                stateChangeManager(dispatch, Actions, true, 'error', res?.status);
            }
        }
    }
    const markAsReturned = async (id, is_returned) => {
        let data = await apiAction({
            method: 'post',
            url: retrieve_delivery_challan(id),
            data: { business_id: getBusinessInfo().id },
        })
        if (data) {
            const res = await apiAction({ url: update_delivery_challan(id), method: 'POST', data: { ...is_returned, customer_id: data.result.customer_id, place_of_supply_id: data.result.place_of_supply.id, business_id: getBusinessInfo().id } })
            if (res?.success) {
                reload(true)
                stateChangeManager(dispatch, Actions, true, 'success', res?.status);
            } else {
                reload(true)
                stateChangeManager(dispatch, Actions, true, 'error', res?.status);
            }
        }
    }
    const printDeliveryChallan = async (id, copy_type) => {

        let data = await apiAction({
            method: 'post',
            url: retrieve_delivery_challan(id),
            data: { business_id: getBusinessInfo().id },
        })
        if (data) {
            apiBlobResponse({
                url: export_delivery_challan(), data: { ...data.result, ...copy_type, terms_and_conditions_id: data.result.terms_and_conditions, export_type: "pdf", }, onSuccess: () => {
                    setLoader(dispatch, Actions, false)
                }
            })
        }
    }

    const downloadDeliveryChallan = async (id) => {
        let data = await apiAction({
            method: 'post',
            url: retrieve_delivery_challan(id),
            data: { business_id: getBusinessInfo().id },
        })
        if (data) {
            apiHandleDownload({
                url: export_delivery_challan(), data: { ...data.result, terms_and_conditions_id: data.result.terms_and_conditions, export_type: "pdf", copy_type: 'original' }, filename: data.result.delivery_challan_number, onSuccess: () => {
                    setLoader(dispatch, Actions, false)
                }
            })
        }
    }

    const toActionText = (status) => {
        let text = ''
        if (status === 'draft') {
            text = 'Approve'
        } else if (status === 'open') {
            text = 'Mark as Delivered'
        } else if (status === 'delivered') {
            text = 'Convert to Invoice'
        } else if (status === 'partially_invoiced') {
            text = 'Record Partially Returned'
        } else if (status === 'closed' || status === 'returned') {
            text = 'View'
        }
        return text
    }

    const onClickActionText = (status, item) => {
        if (status === 'draft') {
            onSelectAction("approve", item)
        } else if (status === 'open') {
            onSelectAction("mark_as_delivered", item)
        } else if (status === 'partially_invoiced') {
            onSelectAction("mark_as_returned", item,eventsNames.actions.deliveryChallan.action.RECORD_PARTIALLY_RETURNED)
        } else if (status === 'delivered') {
            onSelectAction("convert", item)
        } else if (status === 'closed' || status === 'returned') {
            onSelectAction("view", item)
        }
    }


    return (
        <>
            <DeliveryChallanAlertBox
                open={open}
                setOpen={() => { setOpen(false) }}
                onSaveAction={(type) => { printDeliveryChallan(id, { copy_type: type }) }}
            />
            <CustomTableBody>
                {
                    data.map((item, index) => {
                        return (
                            <CustomTableRow key={index}>
                                <CustomTableBodyCell sx={{}} align='center'><Status status={item.status} /></CustomTableBodyCell>
                                <CustomTableBodyCell sx={{}}  ><span>{moment(item.delivery_challan_date).format(getDateFormat())}</span></CustomTableBodyCell>
                                {/* <CustomTableBodyCell sx={{}}  ><span>{item.delivery_challan_number}</span></CustomTableBodyCell> */}
                                <CustomTableBodyCell sx={{}}  ><DeliveryChallanViewLink id={item.id} title={item.delivery_challan_number} /></CustomTableBodyCell>
                                <CustomTableBodyCell sx={{}}  ><span>{item.customer_display_name}</span></CustomTableBodyCell>
                                <CustomTableBodyCell sx={{}} align='right'>
                                    <span>{<CurrencyFormatter amount={item.total_amount} currency={item.currency_code} />}</span><br />
                                    <CurrencyFormatterWithExchangeRate currency_code={item.currency_code} amount={item.total_amount} exchange_rate={item.exchange_rate} />
                                </CustomTableBodyCell>
                                <CustomTableBodyCell sx={{}} align='right'>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                        <ActionTextLinkBtn
                                            index={index}
                                            toActionText={toActionText(item.status)}
                                            onClickActionText={() => onClickActionText(item.status, item)} />
                                        <ListItemActions
                                            index={index}
                                            actions={
                                                [
                                                    item.status !== 'returned' & item.status !== 'closed' && { name: 'View', onClick: () => { onSelectAction("view", item) } },
                                                    item.status === 'open' && { name: 'Edit', onClick: () => { onSelectAction("edit", item) } },
                                                    item.status === 'open' && { name: 'Mark as Returned', onClick: () => { onSelectAction("mark_as_returned", item) } },
                                                    item.status === 'delivered' && { name: 'Revert to Open', onClick: () => { onSelectAction("mark_as_open", item) } },
                                                    { name: 'Print', onClick: () => { onSelectAction("print", item) } },
                                                    { name: 'Export as PDF', onClick: () => { onSelectAction("download", item) } },
                                                    { name: 'Delete', showDivider: true, onClick: () => { stateChangeHandler('Delete Delivery Challan', 'delete', 'sm', delete_delivery_challan(item.id), `The delivery challan will be deleted and can not be retrieved later.\n Are you sure about deleting it?`) } }
                                                ]
                                            }
                                        />
                                    </Box>

                                </CustomTableBodyCell>
                            </CustomTableRow>
                        )
                    })
                }
            </CustomTableBody>
        </>

    )
}

const Filters = (props) => {
    const { setPage, stateChangeHandler, setFilters, filters } = props

    const [customer, setCustomer] = React.useState(null)

    const onDateRangeChange = (dates) => {
        setPage(1);
        if (dates) {
            filters.delivery_challan_end_date = dates[1].format("YYYY-MM-DD");
            filters.delivery_challan_start_date = dates[0].format("YYYY-MM-DD");
        } else {
            delete filters.delivery_challan_start_date
            delete filters.delivery_challan_end_date
        }
        setFilters({ ...filters })

    }

    return (
        <Grid item xs={12} container spacing={1}>
            <Grid item xs={12} sm={2.5} >
            <Common.FilterStatus
                filters={filters}
                setPage={setPage}
                setFilters={setFilters}
                dataKey={'deliveryChallanStatus'}
            />
            </Grid>

            <Grid item xs={12} sm={2.5}>
                <CommonAsyncDropdown
                    id={'customer_dropdown'}
                    dataTestId={'customer_dropdown'}
                    autoSelect={false}
                    disableClearable={false}
                    optionLabel="display_name"
                    placeholder='Select Customer'
                    noOptionsText={"No result found"}

                    item={{
                        method: 'post',
                        value: customer,
                        label: 'Customers',
                        url: list_party(1),
                        body: { is_inactive: false, business_id: getBusinessInfo().id, role: 'customer' },
                        onChange: (event, value) => {
                            setPage(1);
                            if (value) {
                                setFilters({ ...filters, customer_id: value.id })
                            } else {
                                delete filters.customer_id
                                setFilters({ ...filters })
                            }

                            setCustomer(value)
                        },
                    }}
                    addButton={{
                        title: '+ Add new customer',
                        onClick: () => stateChangeHandler('New Customer', 'new_customer', 'lg')
                    }}
                />
            </Grid>

            <Grid item xs={12} sm={4.5} container>
                <CustomDateRangePicker onDateRangeChange={onDateRangeChange} />
            </Grid>

            <Grid item xs={12} sm={2.5}>
                <CommonSearch
                    id={'input_search_number'}
                    dataTestId={'input_search_number'}
                    title={'Search'}
                    fullWidth={true}
                    titleVisibility={'hidden'}
                    placeholder={'Enter Delivery Challan Number'}
                    onSearchValue={filters.delivery_challan_number ? filters.delivery_challan_number : ''}
                    onSearch={(search_text) => {
                        setPage(1);
                        setFilters({ ...filters, delivery_challan_number: search_text })
                    }}
                />
            </Grid>

        </Grid>
    )
}
