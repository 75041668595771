import React from 'react';
import { Box, Grid } from '@mui/material';
import Highcharts from "highcharts/highstock";
import { useNavigate } from 'react-router-dom';
import { apiAction } from '../../../../api/api';
import PieChart from "highcharts-react-official";
import DateRange from '../../../common/DateRange';
import * as Actions from '../../../../state/Actions';
import { routesName } from '../../../../config/routesName';
import { stateChangeManager } from '../../../../utils/Utils';
import { getBusinessInfo } from '../../../../config/cookiesInfo';
import CustomTypography from '../../../custom/typography/CustomTypography';
import { eventsNames, AnalyticsEvent } from '../../../../firebase/firebaseAnalytics';
import { CustomDataPickerPopover } from '../../../custom/Datepicker/CustomDataPickerPopover';
import { amountFormatter, numberWithSuffix, multiple_date_range, getDateRanges } from '../../../../utils/Utils';

const TopSales = React.memo((props) => {
    const navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);
    const { id, dataTestId, url } = props;

    const DropdownRef = React.useRef(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [dates, setDates] = React.useState(getDateRanges(multiple_date_range()[4]?.dates));
    const [selectedDateRange, setSelectedDateRange] = React.useState(multiple_date_range()[4]);

    const [result, setResult] = React.useState([]);

    React.useEffect(() => {
        if (url) {
            getApiResults(url);
        }
    }, [url, dates]);

    const getApiResults = React.useCallback(async (url) => {
        let res = await apiAction({
            url: url,
            method: 'post',
            navigate: navigate,
            dispatch: dispatch,
            data: { business_id: getBusinessInfo().id, top_count: 10, ...dates },
        });
        if (res?.success) {
            setResult(res?.result);
        } else {
            stateChangeManager(dispatch, Actions, true, "error", res?.status);
        }
    }, [dates, dispatch, navigate]);

    const categories = React.useMemo(() => result.map((item) => {
        let temp = {};
        temp['id'] = item.item_id;
        temp['name'] = item.item_name;
        temp['current_sales_amount'] = item.total_amount;
        if ('previous_total_amount' in item) {
            temp['previous_sales_amount'] = item.previous_total_amount;
        }
        return temp;
    }), [result]);

    const options = React.useMemo(() => ({
        chart: {
            type: 'bar'
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: categories,
            title: {
                text: null
            },
            labels: {
                formatter: function () {
                    return this.value.name;
                }
            }
        },
        yAxis: {
            min: 0,
            title: {
                text: null,
            },
            labels: {
                formatter: function () {
                    return numberWithSuffix(this.value);
                }
            },
        },
        tooltip: {
            useHTML: true,
            borderWidth: 0,
            borderRadius: 10,
            borderColor: '#ddd',
            backgroundColor: '#FFFFFF',
            formatter: function () {
                const tooltipHeader = `
                    <strong style="font-size: 14px; font-family: Noto Sans;">${this.x.name}</strong><br/>`;
                const currentSales = `
                    <p style="margin: 5px 0 10px 0; color: ${this.series.color};">
                        <span style="font-weight: bold;">${'Current Sales'}:</span> ${amountFormatter(this.x.current_sales_amount)}
                    </p>`;
                const previousSales = this.x.previous_sales_amount !== undefined ? `
                    <p style="margin: 5px 0; color: ${this.series.color};">
                        <span style="font-weight: bold;">${'Previous Sales'}:</span> ${amountFormatter(this.x.previous_sales_amount)}
                    </p>` : '';
                const tooltipBody = `
                    <div style="font-family: Noto Sans; padding-top: 5px;"> 
                        ${currentSales}
                        ${previousSales}
                    </div>`;
                return `<div>${tooltipHeader + tooltipBody}</div>`;
            }
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        return `<span style="font-family: 'Noto Sans';">${amountFormatter(this.y)}</span>`;
                    }
                },
            },
            series: {
                pointWidth: 20,
                cursor: 'pointer',
                point: {
                    events: {
                        click: function () {
                            AnalyticsEvent(eventsNames.categories.DASHBOARD, { action: eventsNames.actions.CLICK, graph_name: eventsNames.actions.dashboard.graph_name.TOP_SALES })
                            navigate(routesName.invoicingItemsView.path + "?item_id=" + this.category.id, { replace: false });
                        }
                    }
                }
            }
        },
        legend: {
            y: 80,
            x: -40,
            shadow: true,
            borderWidth: 0,
            align: 'right',
            floating: true,
            layout: 'vertical',
            verticalAlign: 'top',
            backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
        },
        credits: {
            enabled: false
        },
        series: [{
            color: "#2464EB",
            showInLegend: false,
            data: result.map((item) => item.total_amount)
        }]
    }), [categories, result, navigate]);

    return (
        <div>
            <CustomDataPickerPopover
                anchorEl={anchorEl}
                customDate={dates}
                setAnchorEl={setAnchorEl}
                setCustomDate={setDates}
            />
            <Box>
                <Box sx={{ pb: 2, borderBottom: '2px solid #F5F5F5' }}>
                    <Grid container style={{
                        alignItems: 'center',
                        justifyItems: 'center',
                        justifyContent: 'space-evenly',
                        alignContent: 'space-evenly',
                    }}>
                        <Grid item xs={4} sm={6}>
                            <CustomTypography
                                text={'Top Sales'}
                                sx={{
                                    color: '#000000',
                                    display: 'inline',
                                    fontSize: '16px',
                                    textAlign: 'start',
                                    fontWeight: '700',
                                    lineHeight: '18px',
                                    fontStyle: 'normal',
                                    fontFamily: "Noto Sans",
                                }}
                            />
                        </Grid>
                        <Grid item xs={8} sm={6} sx={{ textAlign: '-webkit-right' }}>
                            <DateRange
                                label=''
                                sx={{ width: 200 }}
                                id={id + '_dropdown'}
                                inputRef={DropdownRef}
                                disableClearable={true}
                                value={selectedDateRange}
                                results={multiple_date_range()}
                                dataTestId={dataTestId + '_dropdown'}
                                setValue={(event, newDateRange) => {
                                    if (newDateRange?.dates?.length) {
                                        setAnchorEl(null);
                                        setSelectedDateRange(newDateRange);
                                        setDates(getDateRanges(newDateRange.dates));
                                    } else {
                                        setAnchorEl(DropdownRef.current);
                                        setSelectedDateRange(newDateRange);
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
                {
                    result.length ?
                        <Box sx={{ p: 1 }}>
                            <Grid container>
                                <Grid item xs={12} sx={{ p: 1, borderRight: '1px solid #EBEBEB' }}>
                                    <PieChart highcharts={Highcharts} options={options} />
                                </Grid>
                            </Grid>
                        </Box>
                        : <Box sx={{
                            height: "430px",
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            justifyContent: "center",
                        }}>
                            <CustomTypography
                                text={<span>No sales recorded for <span style={{ textTransform: 'lowercase' }}>{'selected date range'}</span></span>}
                                sx={{
                                    opacity: 0.6,
                                    color: '#000000',
                                    display: 'inline',
                                    fontSize: '14px',
                                    textAlign: 'start',
                                    fontWeight: '700',
                                    lineHeight: '18px',
                                    fontStyle: 'normal',
                                    fontFamily: "Noto Sans",
                                }}
                            />
                        </Box>
                }
            </Box>
        </div>
    );
});

export default TopSales;
