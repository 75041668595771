import { apiAction } from "../../../../../api/api";
import { get_balance_sheet_statement } from "../../../../../api/urls";
import { getBusinessInfo } from "../../../../../config/cookiesInfo";

export const ApiActions = async (from_date, to_date, date_range,setActions,Actions,setLoader) => {
    setLoader(setActions, Actions, true)
    
    let res = await apiAction({
        method: 'post',
        url: get_balance_sheet_statement(),
        data: {
            to_date: to_date,
            from_date: from_date,
            business_id: getBusinessInfo().id,
        },
    })
    let response
    if (res?.success) {
    setLoader(setActions, Actions, false)
        // console.log('res',res)
        response = { data: res?.result, date_range: date_range }
    } else {
        response = []
    }
    // console.log('response',response)
    return response
}
