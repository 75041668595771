import React from 'react';
import ReceiptView from '../../payment/ReceiptView';
import { routesName } from '../../../../../config/routesName';
import { eventsNames,AnalyticsEvent } from '../../../../../firebase/firebaseAnalytics';


const PaymentReceivedViewpage = () => {
  return (
    <ReceiptView
    title={'Payment Receipts'}
    editURL={routesName.invoicingPaymentsReceived.path}
    AnalyticsEvent={(params)=>{AnalyticsEvent(eventsNames.categories.PAYMENTS_RECEIVED,params)}}
    />
  )
}

export default PaymentReceivedViewpage;