import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomTypography from '../../../custom/typography/CustomTypography';
import { ReactComponent as RoundPlusIcon } from '../../../../assets/svg/ic_round-plus.svg';



import {
    Box,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@mui/material';

const FAQ = () => {
    return (
        <Box sx={{ pt: 3, pb: 3}}>
            <Grid container sx={{}}>
                <Grid item xs={12} style={{pb: 6, pt: 4, textAlign: "center" }}>
                        <CustomTypography
                            text={'Frequently Asked Questions about Finycs'}
                            sx={{
                                color: '#141414',
                                fontWeight: 700,
                                lineHeight: '56px',
                                letterSpacing: '0px',
                                fontSize: { xs: '36px', sm: '40px' },
                            }}
                        />
                </Grid>

                <Grid item xs={12} sx={{ pt: 4 }}>
                    <SimpleAccordion />
                </Grid>
            </Grid>
        </Box>
    )
}
export default FAQ;

const SimpleAccordion = () => {
    const [expanded, setExpanded] = React.useState("");

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    return (
        <div>
            {
                listData.map((item, index) => {
                    return (
                        <React.Fragment key={index}>
                            <Accordion
                            square
                            elevation={0} 
                            disableGutters 
                                expanded={expanded === `panel${index + 1}`}
                                onChange={handleChange(`panel${index + 1}`)}
                                sx={{
                                    margin: '20px',
                                    boxShadow: 'none',
                                    borderRadius: '8px',
                                    border: '1px solid #E0E0E0',
                                    '&:before': { display: 'none', },
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={<RoundPlusIcon />}
                                    sx={{
                                        boxShadow: 'none',
                                        marginBottom: expanded === `panel${index + 1}` ? '-1px' : '0',
                                    }}
                                >
                                    <CustomTypography
                                        text={item.title}
                                        sx={{
                                            color: '#141414',
                                            fontWeight: 600,
                                            fontSize: '20px',
                                            lineHeight: '28px',
                                            letterSpacing: '0px',
                                        }}
                                    />
                                </AccordionSummary>
                                <AccordionDetails sx={{
                                    paddingTop:'0',
                                    maxWidth:'769px',
                                    paddingBottom:'0',
                                    }}>
                                    <CustomTypography
                                        text={item.describe}
                                        sx={{
                                            fontSize: '14px',
                                            fontWeight: 400,
                                            color: '#666666',
                                            lineHeight: '20px',
                                            fontStyle: 'normal',
                                            letterSpacing: '0.2px',

                                        }}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        </React.Fragment>
                    )
                })
            }

        </div >
    )
}

const listData = [
    {
        title: `Can non-accountants use Finycs cloud accounting software?`,
        describe: <p>Yes, Finycs cloud accounting software is designed with a simple UI/UX technique, making it easy for non-accountants to use.</p>
    },
    {
        title: `Do I need to install any software to use the Finycs?`,
        describe: <p>No, Finycs cloud accounting software is 100% web-based, so you just need an Internet connection and browser. However, you can download and install our mobile invoicing app for Android.</p>
        // describe: <p>No, Finycs cloud accounting software is 100% web-based, so you just need an Internet connection and browser. However, you can download and install our mobile invoicing app for iOS and Android.</p>
    },
    {
        title: `Does Finycs offer a free trial?`,
        describe: <p>Yes, Finycs offers a 30-day free trial version without any credit card requirements.</p>
    },
    {
        title: `Is my data secure with the Finycs?`,
        describe: <p>Absolutely. Our servers are physically and electronically protected, and any connection between you and Finycs is protected by 256-bit SSL encryption.</p>
    },
    {
        title: `Does the Finycs support my country?`,
        describe: <p>Yes, Finycs accounting software is built for worldwide users.</p>
    },
    {
        title: `What industries can utilise the Finycs?`,
        describe: <p>Finycs accounting software can be used in various industries such as construction and service, consultancy service, art, writing, and photography, educational service, engineering, design and architecture, entertainment, financial service, food service, healthcare, manufacturing, retail, transportation and logistics, hospitality and travel, legal services, publishing and media, real estate, sales agency, technology, telecommunication, trading, textile, agriculture and farming, and automotive industry.</p>
    },
    {
        title: `Are there any free resources to learn about the Finycs?`,
        describe: <p>Yes, check our support website and YouTube channel to learn more about Finycs accounting software.</p>
    },
    {
        title: `Is Finycs a GST accounting software?`,
        describe: <p>Yes, Finycs is a GST accounting software that allows you to create GST invoices, GST items, and GST bills, and generate GST filing reports such as GSTR1, GSTR2, and GSTR 3B for your small business.</p>
    },

]