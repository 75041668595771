import moment from "moment/moment";
import Emails from "../../common/Emails";
import React, { useContext } from "react";
import "react-multi-email/dist/style.css";
import { TaxList } from "../../common/GST";
import Input from "../../../../common/Input";
import * as Common from "../../common/Common";
import DateRange from "../../../../common/DateRange";
import DatePicker from "../../../../common/DatePicker";
import * as Actions from "../../../../../state/Actions";
import CommonAlertBox from "../../common/CommonAlertBox";
import CustomTitle from "../../../../common/CustomTitle";
import { SalesPersonList } from "../../common/salesPerson";
import PrintAndPreview from "../../common/PrintAndPreview";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import { routesName } from "../../../../../config/routesName";
import CommonCalculation from "../../common/CommonCalculation";
import CommonDropdown from "../../../../common/CommonDropdown";
import { input_css_style } from "../../../../../utils/Constant";
import { apiAction, apiFormData } from "../../../../../api/api";
import CustomDialog from "../../../../custom/dialog/CustomDialog";
import CustomButton from "../../../../custom/button/CustomButton";
import { getBusinessInfo } from "../../../../../config/cookiesInfo";
import { TermsAndConditions } from "../../common/TermsAndConditions";
import CurrencyFormatter from "../../../../common/CurrencyFormatter";
import CommonPreviewShare from "../../../../common/CommonPreviewShare";
import { LabelWithAsteriskMark } from "../../../../common/CommonLabel";
import CommonAsyncDropdown from "../../../../common/CommonAsyncDropdown";
import CommonCurrencySelection from "../../common/CommonCurrencySelection";
import CustomGroupButton from "../../../../custom/button/CustomGroupButton";
import CustomTypography from "../../../../custom/typography/CustomTypography";
import { eventsNames, AnalyticsEvent } from "../../../../../firebase/firebaseAnalytics";


import {
  getNotesPreferences,
  getDiscountPreferences,
  getAmountArePreferences,
  getSalesPersonPreferences,
  getTermsAndConditionPreferences,
} from "../../../setting/settings/Preferences/common/getPreferences";

import {
  CustomContainer,
  CustomTitleContainer,
  CustomButtonContainer,
} from "../../../../custom/container/CustomContainer";

import {
  creditNoteReasonChoice,
  GSTRegistrationTypeChoice,
} from "../../../../../utils/Constant";

import {
  setLoader,
  filterTax,
  isFormValid,
  filterArray,
  isAddressSame,
  getQueryParams,
  findDefaultTax,
  stateChangeManager,
  isApplicableForTax,
  isBusinessRegistered,
  breadCrumbsStateChangeManager,
} from "../../../../../utils/Utils";

import {
  get_item,
  get_party,
  list_party,
  list_items,
  get_list_of_tax,
  retrieve_invoice,
  get_list_of_state,
  export_credit_note,
  update_credit_note,
  create_credit_note,
  get_list_of_invoice,
  retrieve_credit_note,
  get_create_credit_note_meta_data,
} from "../../../../../api/urls";

import {
  Box,
  Grid,
  Alert,
  Checkbox,
  AlertTitle,
  IconButton,
  FormControlLabel,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  CustomTable,
  CustomTableRow,
  CustomTableBody,
  CustomTableHead,
  CustomTableHeadCell,
  CustomTableBodyCell,
  CustomTableContainer,
} from "../../../../custom/table/CustomTable";

import {
  basePriceTotal,
  checkItemDiscount,
  totalDiscountOnBasePrice,
  isFinalAmountRangeCrossed,
  convertInclusiveToExclusive,
  convertExclusiveToInclusive,
  applyTaxOnMultipleGSTComponents,
} from "../invoices/InvoiceManager";
import CustomDefaultDropdown from "../../../../custom/dropdown/CustomDefaultDropdown";


const Dropdown = DateRange;

// eslint-disable-next-line
const isNumeric = (num) =>
  (typeof num === "number" || (typeof num === "string" && num.trim() !== "")) &&
  !isNaN(num);

const CreateCreditNote = (props) => {
  const { onClose } = props;

  const navigate = useNavigate();
  let location = useLocation();
  const params = getQueryParams(location.search);
  const dispatch = Actions.getDispatch(React.useContext);
  const [isFormSubmitted, setFormSubmitted] = React.useState(false);
  const [state, setState] = React.useState({
    id: "",
    title: "",
    open: false,
    condition: "",
    maxWidth: "lg",
    compulsory: "",
    fullWidth: true,
  });

  const [zero, setZero] = React.useState(false);
  const [saveAndSend, setSaveAndSend] = React.useState(false);

  const [itemIndex, setItemIndex] = React.useState(null);

  const addButtonHandler = (title, condition, maxWidth, compulsory, index) => {
    setState({
      open: true,
      title: title,
      fullWidth: true,
      maxWidth: maxWidth,
      condition: condition,
      compulsory: compulsory,
    });
    setItemIndex(index);
    if (index !== null || index !== undefined) {
      setNotesData({ ...notesData });
    }
  };

  const initial_credit_notes_data = {
    business_id: getBusinessInfo().id,

    customer_id: null,
    selected_customer: null,
    credit_note_number: null,

    customer_emails: {
      cc: [],
      to: [],
      bcc: [],
      toEmails: "",
      ccEmails: "",
      bccEmails: "",
    },
    selected_invoice: null,
    against_invoice_id: null,
    against_invoice_type: null,
    selected_against_invoice_type: null,
    credit_note_date: moment().format("YYYY-MM-DD"),

    reason: null,
    selected_reason: null,

    note: getNotesPreferences('credit_note'),
    terms_and_conditions_id: getTermsAndConditionPreferences('credit_note'),
    selected_terms_and_condition: null,

    sales_person_id: null,
    place_of_supply_id: null,
    selected_place_of_supply: null,

    billing_address: null,
    shipping_address: null,
    same_as_billing_address: false,

    is_entity_discount: true,
    tax_applied: getAmountArePreferences(),
    is_amount_tax_inclusive: getAmountArePreferences() === 'inclusive',

    credit_note_items: [
      {
        item: null,
        tax: null,
        rate: null,
        quantity: 1,
        tax_id: null,
        amount: null,
        item_id: null,
        unit_id: null,
        item_order: null,
        description: null,
        hsn_sac_code: null,
        item_level_discount: 0,
        is_item_discount_in_percentage: true,
      },
    ],

    tax: null,
    discount: 0,
    subtotal: 0,
    total_amount: 0,
    invoice_amount: 0,
    credit_note_upper_limit: 0,

    currency_code: null,

    is_draft: false,
  };

  const [notesData, setNotesData] = React.useState({
    ...initial_credit_notes_data,
  });

  React.useEffect(() => {
    const apiResults = async () => {
      let res = await apiAction({
        method: "post",
        url: get_create_credit_note_meta_data(),
        data: { business_id: getBusinessInfo().id },
      });
      if (res?.success) {
        notesData.credit_note_number = res?.result.credit_note_number;
        setNotesData({
          ...notesData,
          credit_note_number: res?.result.credit_note_number,
        });
      } else {
      }
    };
    if (Object.keys(params).length === 0) {
      apiResults();
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    const getPaidInvoice = async (id) => {
      setLoader(dispatch, Actions, true);
      let res = await apiAction({
        method: "post",
        url: retrieve_invoice(id),
        data: { business_id: getBusinessInfo().id },
      });
      const partyRes = await apiAction({
        method: "post",
        url: get_party(notesData.customer_id),
        data: { business_id: getBusinessInfo().id },
      });

      if (res?.success) {
        if (Object.keys(params).length === 0) {
          dataMapping({
            ...res?.result,
            gst_registration_type: partyRes?.result.gst_registration_type,
          });
        } else {
          setNotesData({
            ...notesData,
            invoice_amount: res?.result.total_amount,
            credit_note_upper_limit:
              res?.result.credit_note_upper_limit + notesData.total_amount,
          });
        }
        setLoader(dispatch, Actions, false);
      }
    };
    if (notesData.against_invoice_id) {
      getPaidInvoice(notesData.against_invoice_id);
    }
    // eslint-disable-next-line
  }, [notesData.against_invoice_id]);

  React.useEffect(() => {
    if (params.id) {
      const retrieveCreditNote = async () => {
        setLoader(dispatch, Actions, true);
        breadCrumbsStateChangeManager(dispatch, 'Edit');
        let res = await apiAction({
          method: "post",
          url: retrieve_credit_note(params.id),
          data: { business_id: getBusinessInfo().id },
        });
        if (res?.success) {
          updateDataMapping(res?.result);
          setLoader(dispatch, Actions, false);
        }
      };
      retrieveCreditNote();
    }
    // eslint-disable-next-line
  }, []);

  const dataMapping = (data) => {
    data.sales_items.map((item) => {
      if (!item.item_id) {
        item["disabled"] = true;
      }
      if (item.tax) {
        item["tax_id"] = item.tax.id;
        item["item"] = {
          id: item.item_id,
          gst_rate: item.tax.rate ? item.tax.rate : null,
          name: item.item ? item.item : item.expense_category_name,
        };
      } else {
        item["tax_id"] = null;
        item["item"] = { name: item.item ? item.item : item.expense_category_name, id: item.item_id, gst_rate: null };
      }
    });
    setNotesData({
      ...notesData,
      ...data,
      invoice_amount: data.total_amount,
      customer_emails: data.customer_emails,
      credit_note_upper_limit: data.credit_note_upper_limit,

      place_of_supply_id: data.place_of_supply.id,
      is_amount_tax_inclusive: data.tax_applied === "inclusive" ? true : false,
      selected_place_of_supply: data.place_of_supply.id
        ? { name: data.place_of_supply.state }
        : null,

      against_invoice_type: data.gst_registration_type,
      selected_against_invoice_type: GSTRegistrationTypeChoice.find(
        (item) => item.value === data.gst_registration_type
      ),

      sales_person_id: data.sales_person,
      sales_person: data.sales_person
        ? { name: data.sales_person_name, id: data.sales_person }
        : null,

      billing_address: data.billing_address,
      shipping_address: data.shipping_address,
      same_as_billing_address: isAddressSame(
        data.shipping_address,
        data.billing_address
      ),

      credit_note_items: data.sales_items,

      note: null,
      attachment: null,
      terms_and_condition: null,
      terms_and_conditions_id: null,
    });
  };
  const updateDataMapping = (data) => {
    data.credit_note_items.map((item) => {

      if (!item.item_id) {
        item["disabled"] = true;
      }
      if (item.tax) {
        item["tax_id"] = item.tax.id;
        item["item"] = {
          id: item.item_id,
          gst_rate: item.tax.rate ? item.tax.rate : null,
          name: item.item ? item.item : item.expense_category_name,
        };
      } else {
        item["tax_id"] = null;
        item["item"] = { name: item.item ? item.item : item.expense_category_name, id: item.item_id, gst_rate: null };
      }
    });
    setNotesData({
      ...notesData,
      ...data,
      // invoice_amount: data.total_amount,
      place_of_supply_id: data.place_of_supply.id,
      selected_place_of_supply: data.place_of_supply.id
        ? { name: data.place_of_supply.state }
        : null,
      is_amount_tax_inclusive: data.tax_applied === "inclusive" ? true : false,

      terms_and_conditions_id: data.terms_and_conditions,
      selected_customer: { name: data.customer_display_name },
      selected_invoice: { name: data.against_invoice_number },
      credit_note_date: moment(data.credit_note_date).format("YYYY-MM-DD"),
      credit_note_upper_limit:
        data.total_amount + notesData.credit_note_upper_limit,
      selected_reason: creditNoteReasonChoice.find(
        (item) => item.value === data.reason
      ),
      same_as_billing_address: isAddressSame(
        data.shipping_address,
        data.billing_address
      ),
      terms_and_condition: data.terms_and_conditions
        ? { name: data.terms_and_conditions_name }
        : null,
      selected_against_invoice_type: GSTRegistrationTypeChoice.find(
        (item) => item.value === data.against_invoice_type
      ),
    });
  };


  const saveEvents = (is_draft) => {
    if (params.id) {
      AnalyticsEvent(eventsNames.categories.CREDIT_NOTES, { action: eventsNames.actions.EDIT })
    } else if (is_draft) {
      AnalyticsEvent(eventsNames.categories.CREDIT_NOTES, { action: eventsNames.actions.CREATE, state: eventsNames.actions.creditNote.state.DRAFT })
    } else {
      AnalyticsEvent(eventsNames.categories.CREDIT_NOTES, { action: eventsNames.actions.CREATE, state: eventsNames.actions.creditNote.state.SAVE })
    }
  };
  //////////////////////////////////////////////////////////////////////////////////////////
  const gst_registration_type =
    notesData.against_invoice_type && notesData.against_invoice_type;

  let is_Location = gst_registration_type === "overseas";
  let is_gst_with_zero =
    gst_registration_type === "overseas" || gst_registration_type === "sez";
  //////////////////////////////////////////////////////////////////////////////////////////
  const onSave = async ({ is_draft = false, is_save_send = false }) => {
    console.log("notesData after save====>", notesData);

    setFormSubmitted(true);
    const { credit_note_items, customer_emails, tax_applied } = notesData;
    const { to } = customer_emails;

    const is_rate = credit_note_items.find((item) => item.rate === null);
    const is_quantity = credit_note_items.find((item) => !item.quantity);
    const is_tax = credit_note_items.find((item) => tax_applied !== "no_tax" && !item.tax_id);
    const is_credit_note_item_not_selected = credit_note_items.find((item) => !item.item_id && !item.expense_category);

    let validation_data = [
      {
        key: "",
        validation: notesData.credit_note_upper_limit < notesData.total_amount,
        message: `Something's not quite right!`,
      },
      {
        key: "",
        validation:
          notesData.applied_as_payment_amount + notesData.refunded_amount >
          notesData.total_amount,
        message: `Something's not quite right!`,
      },
      { key: "customer_id", message: "Please Select Customer" },
      { key: "against_invoice_id", message: "Please Select Invoice Number" },
      { key: "credit_note_number", message: "Please Enter Credit Note Number" },
      isBusinessRegistered() && !is_Location && {
        key: "place_of_supply_id",
        message: "Please Select place of supply",
      },
      // {
      //   key: "emails",
      //   validation: to.length === 0,
      //   message: "Please Enter Valid Customer Email",
      // },
      {
        key: "credit_note_date",
        message: "Please Enter Valid Credit Note Date",
      },
      { key: "reason", message: "Please Select Reason" },
      { key: "against_invoice_type", message: "Please Select Invoice Type" },
      // { key: "billing_address", message: 'Please Enter Billing Address' },
      // { key: "shipping_address", message: 'Please Enter Shipping Address' },
      {
        key: "",
        validation: is_credit_note_item_not_selected,
        message: "Please Select Item",
      },
      {
        key: "",
        validation: is_quantity,
        message: "Please Enter the Quantity",
      },
      { key: "", validation: is_rate, message: "Please Enter the Rate" },
      { key: "", validation: is_tax, message: "Please Select the Tax" },
    ];

    const { isValid, message } = isFormValid(notesData, validation_data);
    if (isValid) {
      notesData.customer_emails.to = filterArray(notesData.customer_emails.to)
      if (is_draft) {
        notesData.is_draft = true;
      } else {
        notesData.is_draft = false;
      }
      if (isApplicableForTax(notesData.tax_applied,isBusinessRegistered())) {
        setZero('isApplicableForTax');
        setSaveAndSend(is_save_send);
      } else if (notesData.total_amount === 0) {
        setZero('withZero');
        setSaveAndSend(is_save_send);
      } else {
        onSaveAction(is_save_send);
      }
    } else {
      stateChangeManager(dispatch, Actions, true, "error", message);
    }
  };

  const onSaveAction = async (is_save_send) => {
    setLoader(dispatch, Actions, true);
    saveEvents(notesData.is_draft)

    let res = await apiFormData({
      method: "post",
      data: notesData,
      url: params.id ? update_credit_note(params.id) : create_credit_note(),
    });
    if (res?.success) {
      setLoader(dispatch, Actions, false);
      stateChangeManager(dispatch, Actions, true, "success", res?.status);
      if (is_save_send) {
        sendCreditNotes(res?.result.id);
      } else {
        redirect(res?.result.id);
        setLoader(dispatch, Actions, false);
      }
    } else {
      setLoader(dispatch, Actions, false);
      stateChangeManager(dispatch, Actions, true, "error", res?.status);
    }
  };

  const sendCreditNotes = async (credit_note_id) => {
    setSendMail(true);
    setSendMailId(credit_note_id);
  };

  const onAddItem = (value) => {

    // Construct the new item data
    const newItemData = {
      item: value,
      quantity: 1,
      item_order: null,
      item_id: value.id,
      unit_id: value?.unit?.id,
      amount: value.selling_price,
      description: value.description,
      hsn_sac_code: value.hsn_sac_code,
      rate: parseFloat(value.selling_price),
      item_level_discount: value.item_level_discount || 0,
      is_item_discount_in_percentage: checkItemDiscount(notesData?.credit_note_items),

      gst_rate: value?.tax?.rate,
      tax: notesData.tax_applied === "no_tax" ? null : value?.tax,
      tax_id: notesData.tax_applied === "no_tax" ? null : value?.tax?.id,
    };

    // Update the sales order items
    notesData.credit_note_items[itemIndex] = newItemData;

    // Update the sales order state
    setNotesData(prevOrder => ({
      ...prevOrder,
      newItem: value,
      sales_items: [...prevOrder.credit_note_items],
    }));
  };

  const redirect = (id) => {
    if (onClose) {
      onClose(id);
    } else {
      navigate(routesName.invoicingCreditNotesView.path + "?id=" + id);
    }
  };

  const onClear = () => {
    setFormSubmitted(false);
    setNotesData({
      ...initial_credit_notes_data,
      credit_note_number: notesData.credit_note_number,
    });
  };

  const [sendMail, setSendMail] = React.useState(false);
  const [sendMailId, setSendMailId] = React.useState(false);
  return (
    <div>
      <CustomDialog
        state={state}
        setState={setState}
        itemIndex={notesData.itemIndex}
        onAddItem={(item) => onAddItem(item)}
      />
      <CommonPreviewShare
        open={sendMail}
        id={sendMailId}
        type={"credit_note"}
        file_key={"credit_note_number"}
        export_Url={export_credit_note}
        retrieve_Url={retrieve_credit_note}
        setOpen={(value) => {
          setSendMail(value);
          redirect(sendMailId);
        }}
      />
      <CommonAlertBox
        open={zero}
        setOpen={setZero}
        onSaveAction={() => {
          if (zero==='withZero') {
            onSaveAction(saveAndSend);
          }else if (notesData.total_amount === 0) {
            setTimeout(()=>{
              setZero('withZero');
            },100)
          } else{
            onSaveAction(saveAndSend);
          }
        }}
        title={`Credit Note No. ${notesData.credit_note_number ? notesData.credit_note_number : ""
          }`}
        message={
          zero==='withZero'?
          `You are about to create the credit note with zero amount. Are you sure you want to proceed?`
          :
          `Please consult the relevant provisions of the laws to determine the application of taxes for GST unregistered entities.`
        }
      />
      <CustomTitleContainer>
        <Grid
          container
          spacing={0}
          style={{
            alignItems: "center",
            justifyItems: "center",
            alignContent: "space-evenly",
            justifyContent: "space-evenly",
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: "-webkit-flex", justifyContent: "start" }}
          >
            <CustomTitle
              title={`Credit Note No. ${notesData.credit_note_number ? notesData.credit_note_number : ""
                }`}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: "-webkit-flex", justifyContent: "end" }}
          >
            <CustomTypography
              text={
                <span>
                  Remaining Credit :{" "}
                  <span style={{ fontWeight: "700", color: "#000000" }}>
                    <CurrencyFormatter
                      amount={notesData.total_amount}
                      currency={notesData.currency_code}
                    />
                  </span>
                </span>
              }
              sx={{
                fontSize: "16px",
                color: "#7A7A7A",
                fontWeight: "600",
                textAlign: "right",
                lineHeight: "22px",
                fontStyle: "normal",
                fontFamily: "Noto Sans",
              }}
            />
          </Grid>
        </Grid>
      </CustomTitleContainer>
      {notesData.against_invoice_id &&
        <>
          {notesData.credit_note_upper_limit < notesData.total_amount ? (
            notesData.credit_note_upper_limit === 0 ? (
              <Box sx={{ mt: 2 }}>
                <Alert severity="error">
                  <AlertTitle>
                    <strong>Something's not quite right</strong>
                  </AlertTitle>
                  It is not possible to create a new credit note for this invoice{" "}
                  {notesData.invoice_number} because the total amount of credit
                  notes already created against this invoice{" "}
                  {notesData.invoice_number} has reached the limit.
                </Alert>
              </Box>
            ) : notesData.credit_note_upper_limit === notesData.invoice_amount ? (
              <Box sx={{ mt: 2 }}>
                <Alert severity="error">
                  <AlertTitle>
                    <strong>Something's not quite right</strong>
                  </AlertTitle>
                  The remaining credit amount{" "}
                  {
                    <CurrencyFormatter
                      amount={notesData.total_amount}
                      currency={notesData.currency_code}
                    />
                  }{" "}
                  can not be greater than selected invoice amount{" "}
                  {
                    <CurrencyFormatter
                      amount={notesData.invoice_amount}
                      currency={notesData.currency_code}
                    />
                  }
                  . Please change the amount, unit price, or quantity you want to
                  credit.
                </Alert>
              </Box>
            ) : (
              <Box sx={{ mt: 2 }}>
                <Alert severity="error">
                  <AlertTitle>
                    <strong>Something's not quite right</strong>
                  </AlertTitle>
                  Please ensure that the total amount of credit notes against the
                  invoice {notesData.invoice_number} does not exceed the remaining
                  balance{" "}
                  {
                    <CurrencyFormatter
                      amount={notesData.credit_note_upper_limit}
                      currency={notesData.currency_code}
                    />
                  }
                  .
                </Alert>
              </Box>
            )
          ) : null}
          {notesData.applied_as_payment_amount + notesData.refunded_amount >
            notesData.total_amount ? (
            <Box sx={{ mt: 2 }}>
              <Alert severity="error">
                <AlertTitle>
                  <strong>Something's not quite right</strong>
                </AlertTitle>
                Please make sure that the credit note's amount is not lesser than{" "}
                {
                  <CurrencyFormatter
                    amount={
                      notesData.applied_as_payment_amount +
                      notesData.refunded_amount
                    }
                    currency={notesData.currency_code}
                  />
                }{" "}
                because as many credits have been applied to invoices or refunded.
              </Alert>
            </Box>
          ) : null}
        </>
      }
      <CustomContainer maxWidth={"400"} sx={{}}>
        <form noValidate>
          <div style={{ borderBottom: "4px solid #F5F5F5" }}>
            <CustomerDetails
              open={state.open}
              notesData={notesData}
              is_Location={is_Location}
              setNotesData={setNotesData}
              isFormSubmitted={isFormSubmitted}
              addButtonHandler={addButtonHandler}
              is_gst_with_zero={is_gst_with_zero}
              editDisabled={params.id ? true : false}
              initial_credit_notes_data={initial_credit_notes_data}
            />
          </div>

          <ProductAndServicesDetails
            open={state.open}
            itemIndex={itemIndex}
            notesData={notesData}
            is_Location={is_Location}
            setNotesData={setNotesData}
            isFormSubmitted={isFormSubmitted}
            addButtonHandler={addButtonHandler}
            is_gst_with_zero={is_gst_with_zero}
          />
        </form>
      </CustomContainer>

      <CustomButtonContainer>
        <Buttons
          onSave={onSave}
          onClear={onClear}
          notesData={notesData}
          is_Location={is_Location}
          onCancel={() => navigate(-1)}
          is_gst_with_zero={is_gst_with_zero}
          setFormSubmitted={setFormSubmitted}
        />
      </CustomButtonContainer>
    </div>
  );
};

export default CreateCreditNote;

const CustomerDetails = (props) => {
  const {
    is_gst_with_zero,
    is_Location,
    editDisabled,
    notesData,
    setNotesData,
    isFormSubmitted,
    isClear,
    open,
    initial_credit_notes_data,
  } = props;
  const { customer_emails } = notesData;

  const onChange = (key_name, value) => {
    setNotesData({ ...notesData, [key_name]: value });
  };

  React.useEffect(() => {
    if (notesData.same_as_billing_address) {
      setNotesData({
        ...notesData,
        shipping_address: notesData.billing_address,
      });
    } else {
      setNotesData({ ...notesData, shipping_address: null });
    }
    // eslint-disable-next-line
  }, [notesData.same_as_billing_address]);

  const setEmails = (emails) => {
    setNotesData({
      ...notesData,
      customer_emails: { ...customer_emails, cc: emails.cc, bcc: emails.bcc },
    });
  };

  const [clickedOutside, setClickedOutside] = React.useState(true);
  const myRef = React.useRef();

  const handleClickOutside = (e) => {
    if (!myRef.current.contains(e.target)) {
      setClickedOutside(true);
    }
  };

  const handleClickInside = () => setClickedOutside(false);

  React.useEffect(() => {
    document?.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  const [isSalesPersonField, setIsSalesPersonField] = React.useState(getSalesPersonPreferences());
  React.useEffect(() => {
    if (notesData.sales_person) {
      setIsSalesPersonField(getSalesPersonPreferences() || notesData.sales_person ? true : false)
    }
  }, [notesData.sales_person]);
  return (
    <Box
      style={{
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingBottom: "32px",
      }}
    >
      <Grid container spacing={3}>
        {/*//////////////////////////////// Customer ////////////////////////////////*/}
        <Grid item xs={12} sm={4.5}>
          <CommonAsyncDropdown
            id={"customer_dropdown"}
            dataTestId={"customer_dropdown"}
            autoFocus={true}
            autoSelect={false}
            disableClearable={false}
            optionLabel="display_name"
            placeholder="Search Customer"
            noOptionsText={"No result found"}
            validation={isFormSubmitted && !notesData.customer_id}
            item={{
              method: "post",
              url: list_party(1),
              disabled: editDisabled,
              value: notesData.selected_customer,
              sx: editDisabled ? { background: "#F1F3F4" } : {},
              label: <LabelWithAsteriskMark label={"Customers"} />,
              body: {
                is_inactive: false,
                business_id: getBusinessInfo().id,
                role: "customer",
              },
              onChange: (event, value) => {
                if (value) {
                  setNotesData({
                    ...notesData,
                    ...initial_credit_notes_data,
                    sales_person_id: null,
                    customer_id: value.id,
                    sales_person_name: null,
                    selected_customer: value,
                    credit_note_number: notesData.credit_note_number,
                  });
                } else {
                  setNotesData({
                    ...notesData,
                    ...initial_credit_notes_data,
                    customer_id: null,
                    sales_person_id: null,
                    sales_person_name: null,
                    selected_invoice: null,
                    selected_customer: null,
                    against_invoice_id: null,
                    credit_note_number: notesData.credit_note_number,
                  });
                }
              },
            }}
          />
        </Grid>
        {/*//////////////////////////////// Invoice Number ////////////////////////////////*/}
        <Grid item xs={12} sm={4.5}>
          <CommonAsyncDropdown
            id={"invoice_number_dropdown"}
            dataTestId={"invoice_number_dropdown"}
            autoSelect={false}
            disableClearable={false}
            optionLabel="invoice_number"
            noOptionsText={"No result found"}
            placeholder="Search Invoice Number"
            validation={isFormSubmitted && !notesData.against_invoice_id}
            item={{
              method: "post",
              value: notesData.selected_invoice,
              disabled: !notesData.customer_id || editDisabled,
              label: <LabelWithAsteriskMark label={"Invoice Number"} />,
              url: notesData.customer_id ? get_list_of_invoice(1) : null,
              sx: !notesData.customer_id || editDisabled ? { background: "#F1F3F4" } : {},
              body: notesData.customer_id ? { business_id: getBusinessInfo().id, role: "customer", status: "paid", customer_id: notesData.customer_id, } : null,
              onChange: (event, value) => {
                if (value) {
                  setNotesData({
                    ...notesData,
                    selected_invoice: value,
                    against_invoice_id: value.id,
                  });
                } else {
                  setNotesData({
                    ...notesData,
                    ...initial_credit_notes_data,
                    sales_person_id: null,
                    sales_person_name: null,
                    selected_invoice: null,
                    against_invoice_id: null,
                    customer_id: notesData.customer_id,
                    selected_customer: notesData.selected_customer,
                    credit_note_number: notesData.credit_note_number,
                  });
                }
              },
            }}
          />
        </Grid>

        {/*//////////////////////////////// Credit Note Number ////////////////////////////////*/}
        <Grid item xs={12} sm={3}>
          <Input
            id={"input_credit_note_number"}
            dataTestId={"input_credit_note_number"}
            item={{
              type: "text",
              required: true,
              fullWidth: true,
              texttransform: "none",
              inputProps: { maxLength: 16 },
              placeholder: "Enter Credit Note Number",
              title: <LabelWithAsteriskMark label={"Credit Note Number"} />,
              validation: isFormSubmitted && !notesData.credit_note_number,
              onChange: (e) => onChange("credit_note_number", e.target.value),
              value: notesData.credit_note_number
                ? notesData.credit_note_number
                : "",
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} sx={{ pt: 4 }}>
        {/*//////////////////////////////// Place of supply ////////////////////////////////*/}
        <Grid item xs={12} sm={4.5}>
          <CommonDropdown
            stateComponent={true}
            id={"place_of_supply_dropdown"}
            dataTestId={"place_of_supply_dropdown"}
            disableClearable={false}
            placeholder="Select a Location"
            validation={
              isBusinessRegistered() &&
              !is_Location && isFormSubmitted && !notesData.place_of_supply_id
            }
            item={{
              method: "post",
              textTransform: "none",
              disabled: is_Location,
              url: get_list_of_state(),
              value: notesData.selected_place_of_supply,
              body: { business_id: getBusinessInfo().id },
              label: <LabelWithAsteriskMark label={"Place of Supply"} isAsteriskMark={isBusinessRegistered()} />,
              onChange: (event, value) => {
                if (value) {
                  setNotesData({
                    ...notesData,
                    selected_place_of_supply: value,
                    place_of_supply_id: value.id,
                  });
                } else {
                  setNotesData({
                    ...notesData,
                    selected_place_of_supply: null,
                    place_of_supply_id: null,
                  });
                }
              },
            }}
          />
        </Grid>

        {/*//////////////////////////////// Customer Email ////////////////////////////////*/}
        <Grid item xs={12} sm={4.5}>
          <Grid item xs={12} container>
            <Grid item xs={6}>
              <CustomTypography
                text={'Customer Email'}
                // text={<LabelWithAsteriskMark label={"Customer Email"} />}
                sx={{
                  mb: 1,
                  fontSize: "14px",
                  color: "#000000",
                  fontWeight: "700",
                  lineHeight: "18px",
                  fontStyle: "normal",
                  textTransform: "none",
                  fontFamily: "Noto Sans",
                  textTransform: "capitalize",
                }}
              />
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "right" }}>
              <Emails
                value={notesData}
                setValue={setNotesData}
                customer_emails={customer_emails}
                setEmail={setEmails}
              />
            </Grid>
          </Grid>
          <div onClick={handleClickInside} ref={myRef}>
            <ReactMultiEmail
              id={"input_to_email"}
              dataTestId={"input_to_email"}
              emails={filterArray(notesData.customer_emails.to)}
              onChange={(emails) => {
                setNotesData({
                  ...notesData,
                  customer_emails: { ...customer_emails, to: emails },
                });
              }}
              // placeholder={<span style={{}}><span style={{ margin: 0, height: "100%", overflow: 'hidden', color: '#2F2F2E', fontWeight: '400', lineHeight: '0px', fontSize: '12px', fontStyle: 'normal', fontFamily: 'Noto Sans', opacity: 0.50, }}>Enter Email Address</span></span>}
              placeholder={
                <p
                  style={{
                    margin: "0px",
                    marginTop: "-8px",
                    position: "sticky",
                  }}
                >
                  <span
                    style={{
                      margin: 0,
                      height: "100%",
                      overflow: "hidden",
                      color: "#2F2F2E",
                      fontWeight: "400",
                      lineHeight: "0px",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontFamily: "Noto Sans",
                      opacity: 0.5,
                    }}
                  >
                    Enter Email Address
                  </span>
                </p>
              }
              style={{
                marginTop: "0px",
                textAlign: "center",
                borderRadius: "4px",
                overflowY: "scroll",
                maxHeight: 20,
                borderColor: clickedOutside
                  ?
                  // isFormSubmitted && notesData.customer_emails.to.length === 0
                  //   ? "#d32f2f": 
                  '#c7c7c7'
                  : "#2464EB",
                borderWidth: clickedOutside ? 1.5 : 2,
              }}
              getLabel={(email, index, removeEmail) => {
                return (
                  <div data-tag key={index} style={{ ...input_css_style }}>
                    {email}
                    <span data-tag-handle onClick={() => removeEmail(index)}>
                      ×
                    </span>
                  </div>
                );
              }}
              validateEmail={(email) => {
                if (email) {
                  return isEmail(email);
                } else {
                  return false;
                }
                // return email?isEmail(email):false; // return boolean
              }}
            />
          </div>
        </Grid>

        {/*//////////////////////////////// Credit Note Date ///////////////////////////////////////*/}
        <Grid item xs={12} sm={3}>
          <DatePicker
            id={"credit_note_date_select"}
            dataTestId={"credit_note_date_select"}
            placeholder=""
            title={<LabelWithAsteriskMark label={"Credit Note Date"} />}
            validation={isFormSubmitted && !notesData.credit_note_date}
            date={
              notesData.credit_note_date
                ? moment(notesData.credit_note_date)
                : null
            }
            setDate={(date) => {
              onChange(
                "credit_note_date",
                date ? date.format("YYYY-MM-DD") : null
              );
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} sx={{ pt: 4 }}>
        {/*//////////////////////////////// Reason ////////////////////////////////*/}
        <Grid item xs={12} sm={4.5}>
          <Dropdown
            id={"reason_dropdown"}
            dataTestId={"reason_dropdown"}
            disableClearable={false}
            placeholder="Select Reason"
            results={creditNoteReasonChoice}
            value={notesData.selected_reason}
            label={<LabelWithAsteriskMark label={"Reason"} />}
            validation={isFormSubmitted && !notesData.reason}
            setValue={(event, value) => {
              setNotesData({
                ...notesData,
                reason: value ? value.value : null,
                selected_reason: value ? value : null,
              });
            }}
          />
        </Grid>

        {/*//////////////////////////////// Invoice Type ////////////////////////////////*/}
        <Grid item xs={12} sm={4.5}>
          <CustomTypography
            text={<LabelWithAsteriskMark label={'Invoice Type'} />}
            sx={{
              mb: 1,
              color: '#000000',
              fontSize: '14px',
              fontWeight: '700',
              lineHeight: '18px',
              fontStyle: 'normal',
              textTransform: "none",
              fontFamily: "Noto Sans",
              textTransform: 'capitalize',
            }}
          />
          <CustomDefaultDropdown
            labelKey='name'
            fullWidth={true}
            valueLabelKey={'name'}
            id={"invoice_type_dropdown"}
            placeholder="Select Invoice Type"
            results={GSTRegistrationTypeChoice}
            dataTestId={"invoice_type_dropdown"}
            validation={isFormSubmitted && !notesData.against_invoice_type}
            value={notesData.against_invoice_type ? GSTRegistrationTypeChoice.find((item) => item.value === notesData.against_invoice_type) : null}
            setValue={(value) => {
              if (value) {
                notesData.against_invoice_type = value.value;
                notesData.selected_against_invoice_type = value;
                if (value.value === "overseas" || value.value === "sez") {
                  notesData.place_of_supply_id = null;
                  notesData.selected_place_of_supply = null;
                }
              } else {
                notesData.against_invoice_type = null;
                notesData.selected_against_invoice_type = null;
              }
              setNotesData({ ...notesData });
            }}
          />
        </Grid>

        {/*//////////////////////////////// Sales person ///////////////////////////////////////*/}
        {isSalesPersonField &&
          <Grid item xs={12} sm={3}>
            <SalesPersonList
              isClear={isClear}
              title="Sales Person"
              onAddItem={(item) => {
                onChange("sales_person_id", item ? item.id : null);
              }}
              onChange={(value) => {
                setNotesData({
                  ...notesData,
                  sales_person_id: value ? value.id : null,
                  sales_person_name: value ? value.name : null,
                });
              }}
              defaultValue={
                notesData.sales_person_name
                  ? {
                    name: notesData.sales_person_name,
                    sales_person_id: notesData.sales_person,
                    id: notesData.sales_person,
                  }
                  : null
              }
            />
          </Grid>
        }
      </Grid>

      <Grid container spacing={3} sx={{ pt: 4 }}>
        {/*//////////////////////////////// Billing address ////////////////////////////////*/}
        <Grid item xs={12} sm={4.5}>
          <Input
            id={"input_billing_address"}
            dataTestId={"input_billing_address"}
            item={{
              rows: 4,
              type: "text",
              required: true,
              fullWidth: true,
              multiline: true,
              title: "Billing Address",
              placeholder: "Enter Billing Address",
              // validation: isFormSubmitted && !notesData.billing_address,
              onChange: (e) => onChange("billing_address", e.target.value),
              value: notesData.billing_address ? notesData.billing_address : "",
            }}
          />
        </Grid>

        {/*//////////////////////////////// Shipping address ////////////////////////////////*/}
        <Grid item xs={12} sm={4.5}>
          <Grid container item xs={12}>
            <Grid item xs={5} sm={6}>
              <CustomTypography
                text={"Shipping Address"}
                sx={{
                  fontSize: "14px",
                  color: "#000000",
                  fontWeight: "700",
                  lineHeight: "18px",
                  fontStyle: "normal",
                  textTransform: "none",
                  fontFamily: "Noto Sans",
                  textTransform: "capitalize",
                }}
              />
            </Grid>
            <Grid item xs={7} sm={6} sx={{ mt: -3, textAlign: "right" }}>
              <FormControlLabel
                id={"same_as_billing_address_checkbox"}
                dataTestId={"same_as_billing_address_checkbox"}
                sx={{ mb: -5, textAlign: "right" }}
                control={
                  <Checkbox
                    size="small"
                    checked={notesData.same_as_billing_address}
                    onClick={() =>
                      onChange(
                        "same_as_billing_address",
                        !notesData.same_as_billing_address
                      )
                    }
                    sx={{ color: "#9b9898" }}
                  />
                }
                label={
                  <span
                    style={{
                      color: "#000000",
                      fontFamily: "Noto Sans",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "16px",
                      fontSize: "12px",
                    }}
                  >
                    Same as Billing Address
                  </span>
                }
              />
            </Grid>
          </Grid>
          <Input
            id={"input_shipping_address"}
            dataTestId={"input_shipping_address"}
            item={{
              rows: 4,
              title: "",
              type: "text",
              required: true,
              multiline: true,
              fullWidth: true,
              placeholder: "Enter Shipping Address",
              disabled: notesData.same_as_billing_address,
              // validation: isFormSubmitted && !notesData.shipping_address,
              onChange: (e) => onChange("shipping_address", e.target.value),
              value: notesData.shipping_address
                ? notesData.shipping_address
                : "",
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const ProductAndServicesDetails = (props) => {
  const {
    is_gst_with_zero,
    open,
    notesData,
    setNotesData,
    addButtonHandler,
    isClear,
    isFormSubmitted,
  } = props;
  const [data, setData] = React.useState(notesData.credit_note_items);

  const onAddLine = () => {
    notesData.credit_note_items.push({
      item: null,
      tax: null,
      rate: null,
      quantity: 1,
      tax_id: null,
      amount: null,
      item_id: null,
      unit_id: null,
      item_order: null,
      hsn_sac_code: null,
      item_level_discount: 0,
      is_item_discount_in_percentage: checkItemDiscount(notesData?.credit_note_items),
    });

    setData([...notesData.credit_note_items]);
  };

  const onDeleteLine = (index) => {
    if (notesData.credit_note_items.length > 1) {
      notesData.credit_note_items.splice(index, 1);
      setData([...notesData.credit_note_items]);
    } else {
      setData([
        {
          item: null,
          tax: null,
          rate: null,
          quantity: 1,
          tax_id: null,
          amount: null,
          item_id: null,
          unit_id: null,
          item_order: null,
          description: null,
          hsn_sac_code: null,
          item_level_discount: 0,
          is_item_discount_in_percentage: checkItemDiscount(notesData?.credit_note_items),
        },
      ]);
    }
  };

  const onClearLines = () => {
    setData([
      {
        item: null,
        tax: null,
        rate: null,
        quantity: 1,
        tax_id: null,
        amount: null,
        item_id: null,
        unit_id: null,
        item_order: null,
        description: null,
        hsn_sac_code: null,
        item_level_discount: 0,
        is_item_discount_in_percentage: checkItemDiscount(notesData?.credit_note_items),
      },
    ]);
  };

  const accounts = applyTaxOnMultipleGSTComponents(
    notesData,
    "credit_note_items",
    ""
  );

  const totalAmount = basePriceTotal(
    notesData,
    notesData.credit_note_items
  ).toFloat();

  const totalTax = Object.values(accounts)
    .reduce((acc, tax) => acc + tax, 0)
    .toFloat();

  const totalDiscount = totalDiscountOnBasePrice(
    notesData,
    notesData.credit_note_items
  ).toFloat();

  const subTotal = notesData.is_amount_tax_inclusive
    ? notesData.credit_note_items
      .filter((item) => isNumeric(item.amount))
      .reduce((a, b) => a + parseFloat(b.amount), 0)
      .toFloat()
    : totalAmount.toFloat();

  const finalPayment =
    totalAmount.toFloat() - totalDiscount.toFloat() + totalTax.toFloat();

  notesData.tax = {
    ...accounts,
    total: parseFloat(parseFloat(totalTax).toFixed(2)),
  };
  notesData.total_amount = (totalAmount + totalTax - totalDiscount).toFloat();
  notesData.discount = totalDiscount.toFloat();
  notesData.subtotal = totalAmount.toFloat();

  React.useEffect(() => {
    setNotesData({
      ...notesData,
      credit_note_items: data,
      total_amount: (totalAmount + totalTax - totalDiscount).toFloat(),
      discount: totalDiscount.toFloat(),
      subtotal: totalAmount.toFloat(),
    });
    // eslint-disable-next-line
  }, [data]);

  if (!("IGST" in notesData.tax)) {
    notesData.tax["IGST"] = 0;
  }
  if (!("SGST" in notesData.tax)) {
    notesData.tax["SGST"] = 0;
  }
  if (!("CGST" in notesData.tax)) {
    notesData.tax["CGST"] = 0;
  }

  const filterList = [
    { name: "Exclusive", value: "exclusive" },
    { name: "Inclusive", value: "inclusive" },
    { name: "No Tax", value: "no_tax" },
  ];

  const onAmountsAreInChange = (selected_type) => {
    let previous = notesData.tax_applied;
    if (previous !== selected_type.value) {
      if (selected_type.value === "inclusive") {
        notesData.is_amount_tax_inclusive = true;
        convertExclusiveToInclusive(notesData.credit_note_items);
        notesData.total_amount = totalAmount - totalDiscount;
      } else if (selected_type.value === "no_tax") {
        if (previous === filterList[1].value) {
          convertInclusiveToExclusive(notesData.credit_note_items);
        }
        // eslint-disable-next-line
        notesData.credit_note_items.map((sales_item) => {
          sales_item.tax = null;
          sales_item.tax_id = null;
        });
        notesData.is_amount_tax_inclusive = false;
        notesData.total_amount = totalAmount - totalDiscount;
      } else {
        notesData.is_amount_tax_inclusive = false;
        convertInclusiveToExclusive(notesData.credit_note_items);
      }
      setNotesData({ ...notesData, tax_applied: selected_type.value });
      setData([...notesData.credit_note_items]);
    }
  };

  const [isDiscountFiled, setIsDiscountFiled] = React.useState(getDiscountPreferences());
  React.useEffect(() => {
    if (notesData.discount) {
      setIsDiscountFiled(getDiscountPreferences() || (notesData.discount ? true : false))
    }
  }, [notesData.discount]);

  return (
    <div>
      <Box
        style={{ paddingRight: "20px", }}
        sx={{ pb: 2, pt: 2, display: { sx: 'block', sm: 'flex' }, justifyContent: "flex-end" }}
      >
        <CommonCurrencySelection
          isCurrency={false}
          isAmountAre={true}
          data={notesData}
          setData={setNotesData}
          dropdown_key='customer_id'
          isFormSubmitted={isFormSubmitted}
          onAmountsAreInChange={onAmountsAreInChange}
        />
      </Box>


      <Box sx={{ mb: 2 }}>
        <CustomTableContainer sx={{ overflowX: 'hidden' }} className="custom_table_css">
          <CustomTable>
            <ProductAndServicesDetailsHeaders isDiscountFiled={isDiscountFiled} data={notesData} setData={setNotesData} />
            <ProductAndServicesDetailsBody
              isDiscountFiled={isDiscountFiled}
              open={open}
              setData={setData}
              notesData={notesData}
              setNotesData={setNotesData}
              onDeleteLine={onDeleteLine}
              finalPayment={finalPayment}
              onClearLines={onClearLines}
              isFormSubmitted={isFormSubmitted}
              data={notesData.credit_note_items}
              addButtonHandler={addButtonHandler}
              is_gst_with_zero={is_gst_with_zero}
            />
          </CustomTable>
        </CustomTableContainer>
      </Box>

      <Grid
        container
        style={{
          paddingBottom: "24px",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
      >
        <Grid item xs={12} sm={6}>
          <Grid item xs={12}>
            <CustomTypography
              id={"add_action"}
              dataTestId={"add_action"}
              text={"+ Add Line"}
              onClick={onAddLine}
              sx={{
                fontSize: "12px",
                color: "#2464EB",
                cursor: "pointer",
                display: "inline",
                fontWeight: "700",
                lineHeight: "16px",
                fontStyle: "normal",
                textTransform: "none",
                fontFamily: "Noto Sans",
                ":hover": { textDecoration: "underline" },
              }}
            />
            <CustomTypography
              text={"|"}
              sx={{
                mr: 1,
                ml: 1,
                fontSize: "12px",
                color: "#B8B8B8",
                fontWeight: "700",
                lineHeight: "16px",
                fontStyle: "normal",
                display: "inline",
                textTransform: "none",
                fontFamily: "Noto Sans",
              }}
            />
            <CustomTypography
              id={"clear_action"}
              dataTestId={"clear_action"}
              text={"Clear All Lines"}
              onClick={onClearLines}
              sx={{
                fontSize: "12px",
                color: "#2464EB",
                cursor: "pointer",
                display: "inline",
                fontWeight: "700",
                lineHeight: "16px",
                fontStyle: "normal",
                textTransform: "none",
                fontFamily: "Noto Sans",
                ":hover": { textDecoration: "underline" },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={9} sx={{ mt: 3 }}>
            <Input
              id={"input_note"}
              dataTestId={"input_note"}
              item={{
                rows: 4,
                type: "text",
                title: "Notes",
                required: true,
                multiline: true,
                fullWidth: true,
                placeholder: "Notes",
                texttransform: "none",
                value: notesData.note ? notesData.note : "",
                onChange: (event) => {
                  setNotesData({ ...notesData, note: event.target.value });
                },
              }}
            />
          </Grid>

          {/*//////////////////////////////// Terms and Conditions ////////////////////////////////*/}
          <Grid item xs={12} sm={9} sx={{ mt: 4 }}>
            <TermsAndConditions
              title="Terms and Conditions"
              value={notesData.terms_and_conditions_id}
              setValue={(value) =>
                setNotesData({
                  ...notesData,
                  terms_and_conditions_id: value ? value.id : null,
                })
              }
            />
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6}>
          <CommonCalculation
            data={notesData}
            setData={setNotesData}
            isFormSubmitted={isFormSubmitted}

            accounts={accounts}
            subTotal={subTotal}
            totalTax={totalTax}
            totalAmount={totalAmount}
            finalPayment={finalPayment}
            totalDiscount={totalDiscount}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const ProductAndServicesDetailsHeaders = (props) => {
  const { isDiscountFiled, data, setData } = props;
  return (
    <CustomTableHead>
      <CustomTableRow><CustomTableHeadCell style={{ width: 5 }} align="left">  <span>#</span></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 310 }} align="left">  <span>Goods/Service</span></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 125 }} align="left">  <span>HSN/SAC</span></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 200 }} align="left">  <span>Description</span></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 185 }} align="left"><Common.QuantityLabelComponent data={data} setData={setData} items='credit_note_items' /></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 175 }} align="left"><Common.RateLabelComponent data={data} setData={setData} items='credit_note_items' /></CustomTableHeadCell>

        {isDiscountFiled &&
          <CustomTableHeadCell style={{ width: 100 }} align="left">  <Common.DiscountLabelComponent data={data} setData={setData} items='credit_note_items' /></CustomTableHeadCell>

        }
        <CustomTableHeadCell style={{ width: 200 }} align="left">  <span>Tax</span></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 145 }} align="left">  <span>Tax Amount</span></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 145 }} align="left">  <span>Amount</span></CustomTableHeadCell>

        <CustomTableHeadCell style={{ width: 5 }} align="right" />
      </CustomTableRow>
    </CustomTableHead>
  );
};

const ProductAndServicesDetailsBody = (props) => {
  const {
    isDiscountFiled,
    is_gst_with_zero,
    open,
    data,
    onDeleteLine,
    setData,
    addButtonHandler,
    notesData,
    setNotesData,
    isFormSubmitted,
    finalPayment,
  } = props;

  let location = useLocation();
  const params = getQueryParams(location.search);
  const dispatch = Actions.getDispatch(useContext);
  const [taxResult, setTaxResult] = React.useState([]);

  let taxApplied = notesData.tax_applied;
  const place_of_supply_id = notesData.place_of_supply_id;
  const taxList = filterTax(taxResult, place_of_supply_id, is_gst_with_zero);

  React.useEffect(() => {
    const apiResults = async () => {
      let res = await apiAction({
        method: "post",
        url: get_list_of_tax(),
        data: {
          business_id: getBusinessInfo().id
        },
      });
      if (res?.success) {
        setTaxResult(res?.result);
      } else {
      }
    };
    apiResults();
    // eslint-disable-next-line
  }, []);

  const apiItemResults = async (id, index) => {
    let res = await apiAction({
      method: "post",
      url: get_item(id),
      data: { business_id: getBusinessInfo().id },
    });
    if (res?.success) {
      onItemSelect(res?.result, index);
    } else {
    }
  };

  const onItemSelect = (value, index) => {

    const getDefaultTax = () => {
      return findDefaultTax(taxList, is_gst_with_zero, value)
    };


    const getItemData = () => ({
      item: value,
      item_order: null,
      unit_id: value.unit?.id,
      quantity: value.quantity || 1,
      description: value.description,
      hsn_sac_code: value.hsn_sac_code,
      item_id: value.item_id || value.id,
      amount: value.amount || value.selling_price,
      rate: parseFloat(value.rate || value.selling_price),
      item_level_discount: value.item_level_discount || 0,
      is_item_discount_in_percentage: checkItemDiscount(notesData?.credit_note_items),

      gst_rate: getDefaultTax()?.rate,
      tax: taxApplied === "no_tax" ? null : getDefaultTax(),
      tax_id: taxApplied === "no_tax" ? null : getDefaultTax()?.id,

    });

    const updateData = () => {
      data[index] = value ? getItemData() : {
        item: null,
        tax: null,
        rate: null,
        quantity: 1,
        tax_id: null,
        amount: null,
        item_id: null,
        unit_id: null,
        item_order: null,
        description: null,
        hsn_sac_code: null,
        item_level_discount: 0,
        is_item_discount_in_percentage: checkItemDiscount(notesData?.credit_note_items),
      };
    };
    const isAmountRangeExceeded = () => isFinalAmountRangeCrossed({
      quantity: data[index]?.quantity,
      rate: data[index]?.rate,
      item_level_discount: data[index].item_level_discount,
      item: data[index],
      invoice: notesData,
      finalPayment: data.length <= 1
        ? 0
        : finalPayment - (data[index]?.amount || 0) - (data[index]?.tax_amount || 0),
    });

    if (isAmountRangeExceeded()) {
      stateChangeManager(
        dispatch,
        Actions,
        true,
        "error",
        "Amount range max limit reached"
      );
    } else {
      updateData();
      setData([...data]);
      setNotesData({ ...notesData, credit_note_items: [...data] });
    }
  };

  React.useEffect(() => {

    const getDefaultTax = (value) => {
      return findDefaultTax(taxList, is_gst_with_zero, value);
    };

    const updatedData = data.map((item) => {
      const defaultTax = getDefaultTax(item);
      const isDefaultSelectTax = item.tax_id || is_gst_with_zero;

      // Calculate values based on conditions
      const gstRate = isDefaultSelectTax ? defaultTax?.rate ?? null : null;
      const tax = isDefaultSelectTax ? (taxApplied === "no_tax" ? null : defaultTax) : null;
      const taxId = isDefaultSelectTax ? (taxApplied === "no_tax" ? null : defaultTax?.id ?? null) : null;

      return {
        ...item,
        tax: tax,
        tax_id: taxId,
        gst_rate: gstRate,
      }
    });

    setData(updatedData);
    setNotesData((prev) => ({
      ...prev,
      credit_note_items: updatedData
    }));

    // eslint-disable-next-line
  }, [is_gst_with_zero, place_of_supply_id, taxApplied]);

  return (
    <CustomTableBody>
      {notesData.credit_note_items.map((item, index) => {
        item['item_order'] = index + 1;

        return (
          <CustomTableRow key={index}>
            <CustomTableBodyCell sx={{}} align="left">
              <span>{index + 1}</span>
            </CustomTableBodyCell>

            <CustomTableBodyCell sx={{}} align="left">
              <CommonAsyncDropdown
                id={"item_dropdown_" + index}
                dataTestId={"item_dropdown_" + index}
                optionLabel="name"
                autoSelect={false}
                disableClearable={false}
                noOptionsText={"No result found"}
                placeholder="Select Goods/Service"
                validation={item.disabled ? false : isFormSubmitted && !data[index].item_id}
                item={{
                  open: open,
                  method: "post",
                  url: list_items(1),
                  disabled: item.disabled,
                  value: data[index].item,
                  onChange: (event, value) => {
                    if (value) {
                      data[index].item = value.name;
                      setData([...data]);
                      apiItemResults(value.id, index);
                    } else {
                      onItemSelect(value, index);
                    }
                  },
                  body: {
                    role: "customer",
                    business_id: getBusinessInfo().id,
                    for_sales: true,
                  },
                }}
                addButton={{
                  title: "+ Add an item",
                  onClick: () =>
                    addButtonHandler(
                      "New Item",
                      "new_pas",
                      "lg",
                      "sales",
                      index
                    ),
                }}
              />
            </CustomTableBodyCell>

            <CustomTableBodyCell sx={{}} align="left">
              <Input
                id={"input_hsn_sac_code_" + index}
                dataTestId={"input_hsn_sac_code_" + index}
                item={{
                  type: "text",
                  disabled: true,
                  required: true,
                  fullWidth: true,
                  placeholder: "",
                  value: data[index]?.hsn_sac_code
                    ? data[index]?.hsn_sac_code
                    : "",
                }}
              />
            </CustomTableBodyCell>

            <CustomTableBodyCell sx={{}} align="left">
              <Input
                id={"input_description_" + index}
                dataTestId={"input_description_" + index}
                isSearchableComponent={true}
                onFocus={() => {
                  data[index].focus = true;
                  setData([...data]);
                }}
                onBlur={() => {
                  data[index].focus = false;
                  setData([...data]);
                }}
                item={{
                  type: "text",
                  required: true,
                  multiline: true,
                  fullWidth: true,
                  rows: data[index]?.focus ? 4 : 1,
                  value: data[index]?.description ? data[index]?.description : "",
                  placeholder: "",
                  onChange: (event) => {
                    data[index].description = event.target.value;
                    setData([...data]);
                  },
                }}
              />
            </CustomTableBodyCell>

            <CustomTableBodyCell sx={{}} align="left">
              <Common.QuantityInputComponent
                index={index}
                data={data}
                setData={setData}
                quantityData={notesData}
                finalPayment={finalPayment}
                isFormSubmitted={isFormSubmitted}
              />
            </CustomTableBodyCell>

            <CustomTableBodyCell sx={{}} align="left">

              <Common.RateInputComponent
                index={index}
                data={data}
                setData={setData}
                rateData={notesData}
                finalPayment={finalPayment}
                isFormSubmitted={isFormSubmitted}
              />
            </CustomTableBodyCell>

            {isDiscountFiled &&
              <CustomTableBodyCell sx={{}} align="left">
                <Common.DiscountInputComponent
                  index={index}
                  data={data}
                  setData={setData}
                  discountData={notesData}
                  finalPayment={finalPayment}
                />

              </CustomTableBodyCell>
            }

            <CustomTableBodyCell sx={{}} align="left">
              <TaxList
                id={"tax_dropdown_" + index}
                dataTestId={"tax_dropdown_" + index}
                result={taxList}
                value={data[index]?.tax}
                disableClearable={false}
                inputDisabled={
                  is_gst_with_zero
                    ? is_gst_with_zero
                    : notesData.tax_applied === "no_tax"
                }
                validation={
                  !is_gst_with_zero &&
                  isFormSubmitted &&
                  notesData.tax_applied !== "no_tax" &&
                  !data[index]?.tax_id
                }
                setValue={(tax) => {
                  if (
                    isFinalAmountRangeCrossed({
                      quantity: data[index]?.quantity,
                      rate: data[index]?.rate,
                      item_level_discount: data[index].item_level_discount,
                      item: { ...data[index], tax: tax },
                      invoice: notesData,
                      finalPayment:
                        data.length <= 1
                          ? 0
                          : finalPayment -
                          (data[index]?.amount ? data[index]?.amount : 0) -
                          (data[index]?.tax_amount
                            ? data[index]?.tax_amount
                            : 0),
                    })
                  ) {
                    stateChangeManager(
                      dispatch,
                      Actions,
                      true,
                      "error",
                      "Amount range max limit reached"
                    );
                  } else {
                    if (tax) {
                      data[index].tax = tax;
                      data[index].tax_id = tax.id;
                      data[index].gst_rate = tax.rate;
                    } else {
                      data[index].tax = null;
                      data[index].tax_id = null;
                      data[index].tax_amount = null;
                    }
                    setData([...data]);
                  }
                }}
              />
            </CustomTableBodyCell>

            <CustomTableBodyCell sx={{}} align="left">
              <Input
                id={"input_tax_amount_" + index}
                dataTestId={"input_tax_amount_" + index}
                editable={false}
                is_currency_symbol={true}
                isShowCommaSeparatedValue={true}
                currency_code={notesData.currency_code}
                item={{
                  type: "text",
                  disabled: true,
                  fullWidth: true,
                  required: true,
                  placeholder: "",
                  value:
                    data[index]?.tax_amount && notesData.tax_applied !== "no_tax"
                      ? data[index]?.tax_amount
                      : "",
                }}
              />
            </CustomTableBodyCell>

            <CustomTableBodyCell sx={{}} align="left">
              <Input
                id={"input_amount_" + index}
                dataTestId={"input_amount_" + index}
                editable={false}
                is_currency_symbol={true}
                isShowCommaSeparatedValue={true}
                currency_code={notesData.currency_code}
                item={{
                  disabled: true,
                  type: "number",
                  fullWidth: true,
                  required: true,
                  placeholder: "",
                  value: data[index]?.amount ? data[index]?.amount : "",
                }}
              />
            </CustomTableBodyCell>

            <CustomTableBodyCell sx={{}} align="right">
              <IconButton
                disabled={item.disabled}
                onClick={() => onDeleteLine(index)}
                id={"delete_icon_" + index}
                dataTestId={"delete_icon_" + index}
              >
                <DeleteIcon />
              </IconButton>
            </CustomTableBodyCell>

          </CustomTableRow>
        );
      })}
    </CustomTableBody>
  );
};

const Buttons = (props) => {
  const {
    is_Location,
    onSave,
    onCancel,
    onClear,
    notesData,
    setFormSubmitted,
  } = props;
  let location = useLocation();
  const params = getQueryParams(location.search);
  const { credit_note_items, customer_emails, tax_applied } = notesData;
  const { to } = customer_emails;

  const is_rate = credit_note_items.find((item) => item.rate === null);
  const is_quantity = credit_note_items.find((item) => !item.quantity);
  const is_tax = credit_note_items.find((item) => tax_applied !== "no_tax" && !item.tax_id);
  const is_credit_note_item_not_selected = credit_note_items.find((item) => !item.item_id && !item.expense_category);
  let validation_data = [
    {
      key: "",
      validation: notesData.credit_note_upper_limit < notesData.total_amount,
      message: `Something's not quite right!`,
    },
    {
      key: "",
      validation:
        notesData.applied_as_payment_amount + notesData.refunded_amount >
        notesData.total_amount,
      message: `Something's not quite right!`,
    },
    { key: "customer_id", message: "Please Select Customer" },
    { key: "against_invoice_id", message: "Please Select Invoice Number" },
    { key: "credit_note_number", message: "Please Enter Credit Note Number" },
    isBusinessRegistered() && !is_Location && {
      key: "place_of_supply_id",
      message: "Please Select place of supply",
    },
    // {
    //   key: "emails",
    //   validation: to.length === 0,
    //   message: "Please Enter Valid Customer Email",
    // },
    { key: "credit_note_date", message: "Please Enter Valid Credit Note Date" },
    { key: "reason", message: "Please Select Reason" },
    { key: "against_invoice_type", message: "Please Select Invoice Type" },
    // { key: "billing_address", message: 'Please Enter Billing Address' },
    // { key: "shipping_address", message: 'Please Enter Shipping Address' },
    {
      key: "",
      validation: is_credit_note_item_not_selected,
      message: "Please Select Item",
    },
    { key: "", validation: is_quantity, message: "Please Enter the Quantity" },
    { key: "", validation: is_rate, message: "Please Enter the Rate" },
    { key: "", validation: is_tax, message: "Please Select the Tax" },
  ];

  const printPreviewEvent = () => {
    AnalyticsEvent(eventsNames.categories.CREDIT_NOTES, { action: eventsNames.actions.PREVIEW })
  };
  const clearActionDisabled =
    Object.keys(params).length === 0 || params.clone_id;
  return (
    <Box component="form" noValidate>
      <Grid container>
        <Grid
          item
          xs={4}
          sx={{ display: "-webkit-flex", justifyContent: "start" }}
        >
          {clearActionDisabled && (
            <CustomButton
              id={"clear_btn"}
              dataTestId={"clear_btn"}
              variant="outlined"
              sx={{
                textTransform: "none",
                "&:hover": { backgroundColor: "#e8f3ff" },
              }}
              btnLabel="Clear"
              onClick={() => {
                onClear();
              }}
            />
          )}
          <CustomButton
            id={"cancel_btn"}
            dataTestId={"cancel_btn"}
            variant="contained"
            sx={{ ml: 1, textTransform: "none" }}
            btnLabel="Cancel"
            onClick={() => {
              onCancel();
            }}
          />
        </Grid>
        <Grid
          item
          xs={8}
          sx={{ display: "-webkit-flex", justifyContent: "end" }}
        >
          <PrintAndPreview
            apiUrl={export_credit_note()}
            data={{ ...notesData, export_type: "pdf" }}
            validation_data={validation_data}
            setFormSubmitted={() => {
              setFormSubmitted(true);
              printPreviewEvent();
            }}
          />
          <CustomButton
            id={"save_as_draft_btn"}
            dataTestId={"save_as_draft_btn"}
            variant="outlined"
            sx={{
              mr: 1,
              textTransform: "none",
              "&:hover": { backgroundColor: "#e8f3ff" },
            }}
            btnLabel={params.id ? "Update as Draft" : "Save as Draft"}
            onClick={() => {
              onSave({ is_draft: true, is_save_send: false });
            }}
          />
          <CustomGroupButton
            options={[
              {
                id: "save_send_btn",
                dataTestId: "save_send_btn",
                label: params.id ? "Update and Send" : "Save and Send",
                condition: () => {
                  onSave({ is_draft: false, is_save_send: true });
                },
              },
              {
                id: "save_send_later_btn",
                dataTestId: "save_send_later_btn",
                label: params.id
                  ? "Update and Send Later"
                  : "Save and Send Later",
                condition: () => {
                  onSave({ is_draft: false, is_save_send: false });
                },
              },
            ]}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
