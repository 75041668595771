import React from 'react';
import moment from 'moment';
import * as Actions from '../../../state/Actions';
import CustomButton from '../button/CustomButton';
import CustomDateRangePicker from './CustomDateRangePicker';
import { validateDates ,stateChangeManager} from '../../../utils/Utils';

import {
    Box,
    Popover,
} from '@mui/material';

export const CustomDataPickerPopover = (props) => {
    const { customDate, setCustomDate, anchorEl, setAnchorEl } = props;
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const dispatch = Actions.getDispatch(React.useContext);

    let [dates, setDate] = React.useState();
    const onDateRangeChange = (dates) => {
        validateDatesHandle(dates[0].format("YYYY-MM-DD"), dates[1].format("YYYY-MM-DD"))
    }
    const handleClose = () => {
        setAnchorEl(null);
    };

    const validateDatesHandle=(fromDate, toDate)=>{
        if (validateDates(fromDate, toDate)) {
            setCustomDate({ ...customDate, from_date: moment(fromDate).format("YYYY-MM-DD"), to_date: moment(toDate).format("YYYY-MM-DD") })
            handleClose();
        } else {
            stateChangeManager(dispatch, Actions, true, 'error', `Please ensure that the end date cannot be less than or equal to start date`)
        }
    }
    React.useEffect(() => {
        if (!dates&&customDate&&open) {
            setDate([moment(customDate?.from_date),moment(customDate?.to_date)])
        }

    }, [open])
    return (
        <Popover
            id={id}
            open={open}
            sx={{ mt: '8px' }}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            PaperProps={{
                style: {
                    padding: 10,
                    minWidth: 200,

                },
            }}
        >
            <CustomDateRangePicker dates={dates} onDateRangeChange={setDate} />
            <Box sx={{ mt: 4, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box>
                    <CustomButton
                        btnLabel='Cancel'
                        variant="outlined"
                        id={'close_custom_date'}
                        onClick={() => handleClose()}
                        dataTestId={'close_custom_date'}

                    />
                </Box>
                <Box>
                    <CustomButton
                        btnLabel='Clear'
                        variant="outlined"
                        id={'clear_selected_date'}
                        onClick={() => { setDate() }}
                        dataTestId={'clear_selected_date'}
                    />

                    <CustomButton
                        sx={{ ml: 1, }}
                        disabled={!dates}
                        variant="contained"
                        btnLabel='Apply Filter'
                        id={'apply_selected_date'}
                        dataTestId={'apply_selected_date'}
                        onClick={() => { onDateRangeChange(dates) }}
                    />
                </Box>
            </Box>

        </Popover>
    )
}