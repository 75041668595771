import React from 'react';
import { TextField } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { isNumber, formatterWithoutSymbol, amountFormatter } from '../../../utils/Utils';

const CustomInput = ({
    id,
    sx,
    rows,
    value,
    onBlur,
    onFocus,
    disabled,
    onChange,
    editable,
    inputRef,
    multiline,
    dataTestId,
    InputProps,
    helperText,
    onKeyPress,
    validation,
    inputProps,
    placeholder,
    currency_code,
    type = 'text',
    size = "small",
    isFormSubmitted,
    customValidation,
    required = false,
    readOnly = false,
    autoFocus = false,
    fullWidth = false,
    is_currency_symbol,
    input_search = false,
    isKeyPressEnter = true,
    maxInputBoxHeight = '',
    minInputBoxHeight = '',
    placeholderTextTransform,
    isShowCommaSeparatedValue,
    isShowEndAdornment = false,
    isSearchableComponent = false,
    endAdornment = <ArrowDropDownIcon />,
    ...props
}) => {
    const [tempValue, setTempValue] = React.useState();
    React.useEffect(() => {
        if (tempValue && isNumber(value) && Number(tempValue) === Number(value)) {
            setTempValue()
        }
    }, [value, tempValue])

    const isError = customValidation !== undefined ? customValidation : validation;

    const handleKeyPressEnter = React.useCallback((event) => {
        const key = event.keyCode || event.which;
        if (key === 13 && !isShowCommaSeparatedValue) {
            if (onBlur) onBlur(event);
            if (onChange) onChange(event);
            if (onKeyPress) onKeyPress(event);
        } else if (onKeyPress) {
            onKeyPress(event);
        }
    }, [isShowCommaSeparatedValue, onBlur, onChange, onKeyPress]);

    const handleFocus = React.useCallback((event) => {
        if (onFocus) onFocus(event);
    }, [onFocus]);

    const handleBlur = React.useCallback((event) => {
        event.target.value = event.target.value.trimStart();
        if (onBlur) onBlur(event);
        if (!isSearchableComponent && onChange) onChange(event);
    }, [onBlur, onChange, isSearchableComponent]);

    const handleChange = React.useCallback((event) => {
        if (onChange) onChange(event);
        if (isNumber(event.target.value) && type==='number')setTempValue(event.target.value);
    }, [onChange]);

    const endAdornmentMemo = React.useMemo(() => (isShowEndAdornment ? { endAdornment } : {}), [isShowEndAdornment, endAdornment]);
    
    const formattedValue = React.useMemo(() => (
        tempValue ?
            tempValue
            :
            isShowCommaSeparatedValue && !editable
                ? value
                    ? is_currency_symbol
                        ? amountFormatter(value, currency_code)
                        : formatterWithoutSymbol(currency_code, value)
                    : value !== null ? value : ""
                : value
    ), [isShowCommaSeparatedValue, editable, value, is_currency_symbol, currency_code,tempValue]);

    return (
        <TextField
            {...props}
            rows={rows}
            size={size}
            error={isError}
            autoComplete="off"
            inputRef={inputRef}
            disabled={disabled}
            required={required}
            autoFocus={autoFocus}
            fullWidth={fullWidth}
            multiline={multiline}
            value={formattedValue}
            data-testid={dataTestId}
            placeholder={placeholder}
            onWheel={() => document.activeElement.blur()}
            type={isShowCommaSeparatedValue && !editable ? "text" : type}
            id={id || (placeholder ? placeholder.toLowerCase().replace(/ /g, "_") : "-")}
            inputProps={{
                ...inputProps,
                readOnly: readOnly,
                style: {
                    color: '#000000',
                    fontSize: '12px',
                    fontWeight: '700',
                    lineHeight: '16px',
                    fontStyle: 'normal',
                    fontFamily: 'Noto Sans',
                    minHeight: minInputBoxHeight,
                },
            }}
            helperText={isError ? (
                <span style={{
                    textAlign: 'start',
                    lineHeight: '16px',
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontFamily: 'Noto Sans',
                    marginBottom: '0px'
                }}>{helperText || ''}</span>
            ) : null}
            InputProps={{
                ...InputProps,
                ...endAdornmentMemo,
                style: {
                    color: '#000000',
                    fontWeight: '700',
                    lineHeight: '16px',
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontFamily: 'Noto Sans',
                },
            }}
            sx={{
                ...sx,
                "& input::placeholder": {
                    color: '#2F2F2E',
                    fontWeight: '400',
                    lineHeight: '16px',
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontFamily: 'Noto Sans',
                    opacity: 0.50,
                    textTransform: placeholderTextTransform || 'none',
                },
                "& textarea::placeholder": {
                    color: '#2F2F2E',
                    fontWeight: '400',
                    lineHeight: '16px',
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontFamily: 'Noto Sans',
                    opacity: 0.50,
                    textTransform: placeholderTextTransform || 'none',
                },
            }}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onChange={handleChange}
            onKeyPress={isKeyPressEnter ? handleKeyPressEnter : onKeyPress}
        />
    );
};

export default React.memo(CustomInput);
