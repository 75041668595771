import { Link } from 'react-router-dom';

import {
    styled,
} from '@mui/material'

export const CustomLink = styled(Link)({
    fontSize: '12px',
    cursor: 'pointer',
    color: '#2464EB',
    fontWeight: '500',
    lineHeight: '16px',
    fontStyle: 'normal',
    textDecoration: 'none',
    fontFamily: 'Noto Sans',
    ":hover": { textDecoration: 'underline' }
});