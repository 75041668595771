import React from 'react';
import moment from 'moment/moment';
import Status from '../../../../common/Status';
import { apiAction } from '../../../../../api/api';
import DateRange from '../../../../common/DateRange';
import HTMLViewer from '../../../../common/HTMLViewer';
import * as Actions from '../../../../../state/Actions';
import CustomTitle from '../../../../common/CustomTitle';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomTabs from '../../../../custom/tabs/CustomTabs';
import { routesName } from '../../../../../config/routesName';
import CustomDialog from '../../../../custom/dialog/CustomDialog';
import CustomButton from '../../../../custom/button/CustomButton';
import { getBusinessInfo } from '../../../../../config/cookiesInfo';
import CurrencyFormatter from '../../../../common/CurrencyFormatter';
import AuditTrailHistory from '../../../../common/AuditTrailHistory';
import { BadgeWithTitle } from '../../../../custom/badge/CustomBadge';
import CustomGroupButton from '../../../../custom/button/CustomGroupButton';
import CommonCustomDateRange from '../../../../common/CommonCustomDateRange';
import ViewPageActionButtons from '../../../../common/ViewPageActionButtons';
import CustomPagination from '../../../../custom/pagination/CustomPagination';
import CustomTypography from '../../../../custom/typography/CustomTypography';
import NoTransactionComponent from '../../../../common/NoTransactionComponent';
import { eventsNames,AnalyticsEvent } from '../../../../../firebase/firebaseAnalytics';
import { ReactComponent as CalendarIcon } from '../../../../../assets/svg/calendar.svg';
import CurrencyFormatterWithExchangeRate from '../../common/CurrencyFormatterWithExchangeRate';
import { BootstrapDialog, BootstrapDialogTitle } from '../../../bulkStatementUpload/mappings/Transactions';

import {
  BillViewLink,
  ExpenseViewLink,
  DebitNoteViewLink,
  PaymentMadeViewLink,
  PurchaseOrderViewLink,
  RecurringExpenseViewLink,
} from '../../common/CommonLinks';

import {
  CustomContainer,
  CustomTitleContainer,
} from '../../../../custom/container/CustomContainer';

import {
  get_party,
  list_expense,
  delete_vendor,
  get_list_of_bill,
  mark_party_inactive,
  get_list_of_payment,
  send_party_statement,
  get_list_of_debit_note,
  export_party_statement,
  get_credit_list_of_vendor,
  get_list_of_purchase_order,
  get_party_transaction_count,
  get_vendor_purchase_overview,
  get_list_of_recurring_expense,
} from '../../../../../api/urls';

import {
  setLoader,
  getAddress,
  date_range,
  isFormValid,
  getDateFormat,
  getQueryParams,
  stateChangeManager,
  convertSlugToString,
  returnTransactionType,
  to_check_repeat_every,
  get_party_contact_name,
  breadCrumbsStateChangeManager,
  to_check_gst_registration_type,
} from '../../../../../utils/Utils';

import {
  CustomTable,
  CustomTableRow,
  CustomTableBody,
  CustomTableHead,
  CustomTableHeadCell,
  CustomTableBodyCell,
  CustomTableContainer,
} from '../../../../custom/table/CustomTable';

import {
  Box,
  Chip,
  Grid,
  Avatar,
  Accordion,
  DialogContent,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import AlternateEmailRoundedIcon from '@mui/icons-material/AlternateEmailRounded';


const Dropdown = DateRange
const VendorViewpage = () => {
  let navigate = useNavigate();
  let location = useLocation();
  const [tab, setTab] = React.useState(0);
  const [vendor, setVendor] = React.useState();
  const [open, setOpen] = React.useState(false);
  const params = getQueryParams(location.search);
  const [results, setResults] = React.useState([]);
  const [purchase, setPurchase] = React.useState();
  const dispatch = Actions.getDispatch(React.useContext);

  const [state, setState] = React.useState({
    id: '',
    title: '',
    open: false,
    condition: '',
    maxWidth: 'lg',
    fullWidth: true,
  })

  const stateChangeHandler = (title, condition, maxWidth, id, item, url) => {
    setState({
      id: id,
      url: url,
      open: true,
      item: item,
      title: title,
      fullWidth: true,
      maxWidth: maxWidth,
      condition: condition,
      navigate: () => navigate(routesName.invoicingPurchaseVendor.path),
    })
  }

  const getVendor = async () => {
    setLoader(dispatch, Actions, true)
    let res = await apiAction({
      method: 'post',
      url: get_party(params.id),
      data: { role: 'vendor', business_id: getBusinessInfo().id },
    })
    if (res?.success) {
      setVendor(res?.result);
      getApiResults(res?.result);
      setLoader(dispatch, Actions, false);
      breadCrumbsStateChangeManager(dispatch, res?.result.display_name);
    } else {
      setLoader(dispatch, Actions, false)
    }
  }
  React.useEffect(() => {

    if (params.id) {
      getVendor()
    }
  }, []);

  React.useEffect(() => {
    const getVendorPurchase = async () => {
      let res = await apiAction({
        method: 'post',
        url: get_vendor_purchase_overview(params.id),
        data: { role: 'vendor', business_id: getBusinessInfo().id },
      })
      if (res?.success) {
        setPurchase(res?.result)
      } else {

      }
    }
    if (params.id) {
      getVendorPurchase()
    }
  }, []);

  const getApiResults = async (vendor) => {
    let res = await apiAction({
      method: 'post',
      url: get_credit_list_of_vendor(),
      data: { business_id: getBusinessInfo().id, vendor_id: vendor.id, },
    })
    if (res?.success) {
      setResults(res?.result)
    } else {
    }
  }

  const markPartyActiveInactive = async (id, isActive) => {
    let res = await apiAction({
      method: 'post',
      url: mark_party_inactive(id),
      data: { business_id: getBusinessInfo().id, is_inactive: isActive },
    })
    if (res?.success) {
      getVendor();
      stateChangeManager(dispatch, Actions, true, 'success', res?.status);
    } else {
      stateChangeManager(dispatch, Actions, true, 'error', res?.status);
    }
  }

  return (
    <div>
      <CustomDialog
        state={state}
        setState={setState}
      />
      {
        vendor && purchase &&
        <div>
          <CustomizedDialogs
            open={open}
            setOpen={setOpen}
            results={results}
            vendor={vendor}
            title={`Credit details for ${vendor.display_name}`}
          />
          <CustomTitleContainer>
            <Grid container spacing={0} style={{
              alignItems: 'center',
              justifyItems: 'center',
              alignContent: 'space-evenly',
              justifyContent: 'space-evenly',
            }}>
              <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'start', }}>
                <CustomTitle title={'Vendor'} />
              </Grid>
              <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'end', }}>


                <ViewPageActionButtons
                  isSent={false}
                  isPrint={false}
                  isDownload={false}
                  isEditable={!vendor.is_inactive}
                  edit={() => {
                    navigate(routesName.invoicingPurchaseVendorAdd.path + "?id=" + vendor.id, { replace: false })
                  }}
                  actionsList={
                    [
                      { name: vendor.is_inactive ? 'Mark as Active' : 'Mark as Inactive', onClick: () => { markPartyActiveInactive(vendor.id, !vendor.is_inactive); AnalyticsEvent(eventsNames.categories.VENDORS,{action:eventsNames.actions.CLICK,switch:vendor.is_inactive ? eventsNames.actions.vendor.switch.MARK_ACTIVE:eventsNames.actions.vendor.switch.MARK_INACTIVE}); } },
                      { name: 'Delete', showDivider: true, onClick: () => { stateChangeHandler('Delete Vendor', 'delete', 'sm', vendor.id, vendor, delete_vendor(vendor.id)); AnalyticsEvent(eventsNames.categories.VENDORS,{action:eventsNames.actions.DELETE}) } }
                    ]
                  }
                />
                {
                  !vendor.is_inactive &&
                  <CustomButton
                    id={'create_bill_btn'}
                    dataTestId={'create_bill_btn'}
                    variant="contained"
                    btnLabel='Create Bill'
                    sx={{ ml: 2, textTransform: 'none', }}
                    onClick={() => {
                      navigate(routesName.invoicingPurchaseBillAdd.path + "?vendor_id=" + params.id, { replace: false })
                      AnalyticsEvent(eventsNames.categories.VENDORS,{action:eventsNames.actions.vendor.action.CREATE_BILL})
                    }}
                  />
                }

                <AuditTrailHistory
                  box_sx={{ height: '24px' }}
                  data={{ object_id: vendor.id, object_type: 'vendor' }}
                  AnalyticsEvent={()=>{AnalyticsEvent(eventsNames.categories.VENDORS,{action:eventsNames.actions.HISTORY})}}
                />
              </Grid>
            </Grid>
          </CustomTitleContainer>

          <CustomContainer maxWidth={"400"} sx={{ height: 'calc(100vh - 134px)' }}>
            <Box sx={{ mb: 2 }}>
              <BasicInfo vendor={vendor} markPartyActiveInactive={markPartyActiveInactive} />
              <PurchaseOverview
                data={purchase && `${moment(purchase.from_date).format(getDateFormat('MMM-YYYY'))} - ${moment(purchase.to_date).format(getDateFormat('MMM-YYYY'))} | Last 12 months.`}
                purchase_overview_data={[
                  { title: 'Total Purchase', value: <CurrencyFormatter amount={purchase.total_purchase} currency={vendor.currency.currency_code} />, onClick: false ? () => navigate(routesName.invoicingPurchaseBill.path, { state: { vendor_id: vendor.id, vendor: vendor, action: 'total_purchase' } }) : null, },
                  { title: 'Total Payments made', value: <CurrencyFormatter amount={purchase.total_paid} currency={vendor.currency.currency_code} />, onClick: false ? () => navigate(routesName.invoicingPaymentsMadeList.path, { state: { party_id: vendor.id, party: vendor, action: 'total_paid' } }) : null, borderLeft: '2px solid #F1D881', borderRight: '2px solid #F1D881', },
                  { title: 'Total Unpaid', value: <CurrencyFormatter amount={purchase.total_unpaid} currency={vendor.currency.currency_code} />, onClick: false ? () => navigate(routesName.invoicingPurchaseBill.path, { state: { vendor_id: vendor.id, vendor: vendor, action: 'total_unpaid' } }) : null, borderRight: '2px solid #F1D881', },
                  { title: 'Unused credit', value: <CurrencyFormatter amount={vendor.unused_credit} currency={vendor.currency.currency_code} />, onClick: vendor.unused_credit ? () => setOpen(true) : null, }

                ]}
              />
            </Box>
            <CustomTabs
              id={'tabs_'}
              dataTestId={'tabs_'}
              tabs={tabs}
              value={tab}
              onChange={(event, tab) => {setTab(tab)}}
            />
            {tabs[tab].condition === 'vendor_information' && <VendorInformation vendor={vendor} />}
            {tabs[tab].condition === 'transaction_list' && <TransactionList vendor_id={Number(params.id)} vendor_name={vendor.display_name} />}
            {tabs[tab].condition === 'statement' && <Statement vendor_id={Number(params.id)} vendor_name={vendor.display_name} />}

          </CustomContainer>
        </div>
      }
    </div>

  )
}

export default VendorViewpage;

const tabs = [
  { name: 'Vendor Information', condition: 'vendor_information' },
  { name: 'Transaction List', condition: 'transaction_list' },
  { name: 'Statements', condition: 'statement' },
]

const BasicInfo = (props) => {
  const { vendor } = props


  const billing_address = vendor.billing_address ? getAddress(vendor.billing_address) : null;
  const shipping_address = vendor.shipping_address ? getAddress(vendor.shipping_address) : null;

  const party_contact_data = vendor.party_contact_data.find((item) => item.contact_type === "primary");

  return (
    <Box sx={{ borderBottom: '2px solid #F5F5F5' }}>
      <Box sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'row',
      }}>
        <Avatar />
        <Box sx={{ alignSelf: 'center', ml: 2.5 }}>

          <CustomTypography
            text={<span>{vendor.display_name}{vendor.is_inactive && <Chip sx={{ ml: 2, }} label={'Inactive'} />}</span>}
            sx={{
              mb: 0.5,
              color: '#000000',
              fontSize: '14px',
              fontWeight: '700',
              lineHeight: '18px',
              fontStyle: 'normal',
              fontFamily: "Noto Sans",
            }}
          />
          <CustomTypography
            text={party_contact_data && <span><AlternateEmailRoundedIcon fontSize='small' sx={{ mb: -.8 }} /> {party_contact_data.email}</span>}
            sx={{
              mb: 0.5,
              color: '#7A7A7A',
              fontSize: '12px',
              display: 'inline',
              fontWeight: '500',
              lineHeight: '18px',
              fontStyle: 'normal',
              fontFamily: "Noto Sans",
            }}
          />
          <CustomTypography
            text={party_contact_data && party_contact_data.mobile_number ? <span> | <LocalPhoneRoundedIcon fontSize='small' sx={{ mb: -.8 }} />{party_contact_data.mobile_number}</span> : ''}
            sx={{
              mb: 0.5,
              color: '#7A7A7A',
              fontSize: '12px',
              display: 'inline',
              fontWeight: '500',
              lineHeight: '18px',
              fontStyle: 'normal',
              fontFamily: "Noto Sans",
            }}
          />
          <CustomTypography
            text={vendor.company_name ? <span><BusinessRoundedIcon fontSize='small' sx={{ mb: -.5 }} />{vendor.company_name}</span> : ''}
            sx={{
              mt: 0.5,
              mb: 0.5,
              color: '#7A7A7A',
              fontSize: '12px',
              fontWeight: '500',
              lineHeight: '18px',
              fontStyle: 'normal',
              fontFamily: "Noto Sans",
            }}
          />
        </Box>
      </Box>
    </Box>
  )
};

const PurchaseOverview = (props) => {
  const { data, purchase_overview_data } = props

  return (
    <Box sx={{ p: 2, border: '1px solid #FFF0C0', backgroundColor: "#FFF7DC" }}>
      <Grid container>
        {
          purchase_overview_data.map((item, index) => {
            return (
              <Grid item xs={3} key={index} sx={{
                p: 1.5,
                borderLeft: item.borderLeft,
                borderRight: item.borderRight,
              }}
              >
                <Box>
                  <CustomTypography
                    text={item.title}
                    sx={{
                      mb: 1,
                      color: '#7A7A7A',
                      fontSize: '14px',
                      fontWeight: '600',
                      lineHeight: '19px',
                      fontStyle: 'normal',
                      textTransform: 'none',
                      fontFamily: "Noto Sans",
                    }} />
                  <CustomTypography
                    id={'on_clicked_btn' + index}
                    dataTestId={'on_clicked_btn' + index}

                    text={item.value}
                    sx={{
                      mb: 1,
                      fontSize: '24px',
                      display: 'inline',
                      fontWeight: '700',
                      lineHeight: '28px',
                      fontStyle: 'normal',
                      textTransform: 'none',
                      fontFamily: "Noto Sans",
                      cursor: item.onClick ? 'pointer' : '',
                      color: item.onClick ? '#2464EB' : '#000000',
                      ":hover": { textDecoration: item.onClick ? 'underline' : '' }
                    }}
                    onClick={() => item.onClick ? item.onClick() : null}
                  />
                </Box>
              </Grid>
            )
          })
        }
      </Grid>
      <CustomTypography
        text={data ? <span><CalendarIcon />{data}</span> : ''}
        sx={{
          mt: 1,
          color: '#A3A3A3',
          fontSize: '12px',
          fontWeight: '500',
          lineHeight: '16px',
          fontStyle: 'normal',
          textTransform: 'none',
          fontFamily: "Noto Sans",
        }}
      />
    </Box>
  )
}

const VendorInformation = (props) => {
  const { vendor } = props;

  const billing_address = vendor.billing_address ? getAddress(vendor.billing_address) : null;
  const shipping_address = vendor.shipping_address ? getAddress(vendor.shipping_address) : null;

  const party_contact_data = vendor.party_contact_data.find((item) => item.contact_type === "primary");

  const customer_basic_info = [
    { title: 'Name', value: get_party_contact_name(party_contact_data) },
    { title: 'Phone', value: vendor.phone_number },
    { title: 'PAN Number', value: vendor.PAN },
    { title: 'Vendor Type', value: vendor.party_type.capitalize() },
    { title: 'Fax', value: vendor.fax_number },
    { title: 'Website', value: vendor.website },
    { title: 'GST Registration Type', value: to_check_gst_registration_type(vendor.gst_registration_type) },
    { title: 'GSTIN', value: vendor.gstin, textTransform: 'uppercase' },
  ]

  const customer_details = [
    { title: 'Opening Balance', value: <span><CurrencyFormatter amount={vendor.opening_balance} currency={vendor.currency.currency_code} />{vendor.opening_balance ? returnTransactionType(vendor.opening_balance_transaction_type) : ''}</span> },
    { title: 'Billing Address', value: billing_address },
    { title: 'Shipping Address', value: shipping_address },
    { title: 'TDS Applicable', value: vendor.is_tds_applicable ? 'Yes' : 'No' },
    { title: 'Terms', value: vendor.payment_term ? vendor.payment_term.name : '-' },
    { title: 'Payment Method', value: vendor.preferred_payment_method, textTransform: 'capitalize' },
    { title: 'Other', value: vendor.other_details },
    { title: 'Notes', value: vendor.note },
  ]

  React.useEffect(() => {
    AnalyticsEvent(eventsNames.categories.VENDORS,{action:eventsNames.actions.vendor.action.INFORMATION});
  }, [])
  return (
    <Grid container sx={{ p: 2 }}>
      <Grid item xs={6}>
        <Box sx={{ p: 2 }}>
          <Grid container>
            {
              customer_basic_info.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <TitleData item={item} />
                  </React.Fragment>
                )
              })
            }
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={6} sx={{ borderLeft: '2px solid #F5F5F5', }}>
        <Box sx={{ p: 2 }}>
          <Grid container>
            {
              customer_details.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <TitleData item={item} />
                  </React.Fragment>
                )
              })
            }
          </Grid>
        </Box>
      </Grid>
    </Grid>
  )
}

const TitleData = (props) => {
  const { item } = props
  return (
    <>
      <Grid item xs={6}>
        <CustomTypography
          text={item.title ? item.title : '-'}
          sx={{
            mb: 2,
            color: '#000000',
            fontSize: '14px',
            fontWeight: '700',
            lineHeight: '18px',
            fontStyle: 'normal',
            fontFamily: "Noto Sans",
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <CustomTypography
          text={item.value ? item.value : '-'}
          sx={{
            mb: 2,
            color: '#7A7A7A',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '18px',
            fontStyle: 'normal',
            fontFamily: "Noto Sans",
            textTransform: item.textTransform,
          }}
        />
      </Grid>
    </>
  )
}

const TransactionList = (props) => {
  const { vendor_id, vendor_name } = props
  const dispatch = Actions.getDispatch(React.useContext);
  const [open, setOpen] = React.useState([]);
  const [count, setCount] = React.useState();
  const addOrRemove = (array, item) => {
    const exists = array.includes(item)

    if (exists) {
      return array.filter((c) => { return c !== item })
    } else {
      const result = array
      result.push(item)
      return result
    }
  }
  const setCountData = (key, value) => {
    setCount((previous) => ({
      ...previous,
      [key]: value
    }));
  }

  React.useEffect(() => {
    const getCountData = async (party_id) => {
      // setLoader(dispatch, Actions, true)
      let res = await apiAction({
        method: 'get',
        url: get_party_transaction_count(party_id)
      })
      if (res?.success) {
        setCount((previous) => ({ ...previous, ...res?.result }))
      } else {

      }
    }
    if (vendor_id) {
      getCountData(vendor_id)
    }
    // eslint-disable-next-line
  }, []);
  const transaction_list = [
    { component_name: 'Expense', name: <BadgeWithTitle title={'Expense'} count={count?.expense_count} />, component: <ExpenseTransactionList open={open.includes('Expense')} setCountData={(value) => { setCountData('expense_count', value) }} vendor_id={vendor_id} dispatch={dispatch} /> },
    { component_name: 'Recurring Expense', name: <BadgeWithTitle title={'Recurring Expense'} count={count?.recurring_expense_count} />, component: <RecurringBillTransactionList open={open.includes('Recurring Expense')} setCountData={(value) => { setCountData('recurring_expense_count', value) }} vendor_id={vendor_id} vendor_name={vendor_name} dispatch={dispatch} /> },
    { component_name: 'Purchase Order', name: <BadgeWithTitle title={'Purchase Order'} count={count?.purchase_order_count} />, component: <PurchaseOrderTransactionList open={open.includes('Purchase Order')} setCountData={(value) => { setCountData('purchase_order_count', value) }} vendor_id={vendor_id} dispatch={dispatch} /> },
    { component_name: 'Bill', name: <BadgeWithTitle title={'Bill'} count={count?.bill_count} />, component: <BillTransactionList open={open.includes('Estimate')} setCountData={(value) => { setCountData('bill_count', value) }} vendor_id={vendor_id} dispatch={dispatch} /> },
    { component_name: 'Vendor Payment', name: <BadgeWithTitle title={'Vendor Payment'} count={count?.payment_count} />, component: <VendorPaymentTransactionList open={open.includes('Vendor Payment')} setCountData={(value) => { setCountData('payment_count', value) }} vendor_id={vendor_id} vendor_name={vendor_name} dispatch={dispatch} /> },
    { component_name: 'Debit Note', name: <BadgeWithTitle title={'Debit Note'} count={count?.debit_note_count} />, component: <DebitNoteTransactionList open={open.includes('Debit Note')} setCountData={(value) => { setCountData('debit_note_count', value) }} vendor_id={vendor_id} vendor_name={vendor_name} dispatch={dispatch} /> },
  ]

  React.useEffect(() => {
    AnalyticsEvent(eventsNames.categories.VENDORS,{action:eventsNames.actions.vendor.action.TRANSACTION});
  }, [])

  return (
    transaction_list.map((item, index) => {
      return (
        <Box style={{ p: 2, border: '5px solid #F5F5F5' }} key={index}>
          <Accordion
            square
            elevation={0}
            disableGutters
            onChange={() => { setOpen([...addOrRemove(open, item.component_name)]) }}
            sx={{ '&:before': { display: 'none' } }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <CustomTypography
                text={item.name}
                sx={{
                  color: '#000000',
                  fontSize: '14px',
                  fontWeight: '700',
                  lineHeight: '18px',
                  fontStyle: 'normal',
                  fontFamily: "Noto Sans",
                }}
              />
            </AccordionSummary>
            <AccordionDetails>
              {item.component}
            </AccordionDetails>
          </Accordion>
        </Box>
      )
    })

  )
}


const BillTransactionList = (props) => {
  const { vendor_id, dispatch, setCountData, open } = props

  const [page, setPage] = React.useState(1);
  const [billData, setBillData] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    next: undefined,
    count: '0',
    previous: undefined,
    number_of_pages: undefined,
  });

  React.useEffect(() => {
    const getVendorInvoice = async (vendor_id) => {
      // setLoader(dispatch, Actions, true)
      let res = await apiAction({
        method: 'post',
        url: get_list_of_bill(page),
        data: { role: 'vendor', business_id: getBusinessInfo().id, vendor_id: vendor_id },
      })
      if (res?.success) {
        setPagination({
          ...pagination,
          next: res?.result.next,
          count: res?.result.count,
          previous: res?.result.previous,
          number_of_pages: res?.result.number_of_pages,
        })
        setCountData(res?.result.count)
        setBillData(res?.result.result)
        setLoader(dispatch, Actions, false)
      } else {
        setLoader(dispatch, Actions, false)
      }
    }
    if (vendor_id && open) {
      getVendorInvoice(vendor_id)
    }
  }, [vendor_id, page, open]);

  return (
    billData.length ?
      <React.Fragment>
        <CustomTableContainer >
          <CustomTable>
            <CustomTableHead>
              <CustomTableRow>
                <CustomTableHeadCell style={{ width: 100 }} align='center'><span >Status</span></CustomTableHeadCell>
                <CustomTableHeadCell ><span >Date</span></CustomTableHeadCell>
                <CustomTableHeadCell ><span >Number</span></CustomTableHeadCell>
                {/* <CustomTableHeadCell ><span >Vendor</span></CustomTableHeadCell> */}
                <CustomTableHeadCell align='right' ><span >Total</span></CustomTableHeadCell>
                <CustomTableHeadCell align='right' ><span >Amount Due</span></CustomTableHeadCell>
              </CustomTableRow>
            </CustomTableHead>
            <CustomTableBody>
              {
                billData.map((item, index) => {
                  return (
                    <CustomTableRow key={index}>
                      <CustomTableBodyCell sx={{}} align='center'><Status status={item.status} /></CustomTableBodyCell>
                      <CustomTableBodyCell sx={{}}  ><span>{moment(item.bill_date).format(getDateFormat())}</span></CustomTableBodyCell>
                      <CustomTableBodyCell sx={{}}  ><BillViewLink id={item.id} title={item.bill_number} /></CustomTableBodyCell>
                      {/* <CustomTableBodyCell sx={{}}  ><span>{item.vendor_display_name}</span></CustomTableBodyCell> */}
                      <CustomTableBodyCell sx={{}} align='right'>
                        <span>{<CurrencyFormatter amount={item.total_amount} currency={item.currency_code} />}</span><br />
                        <CurrencyFormatterWithExchangeRate currency_code={item.currency_code} amount={item.total_amount} exchange_rate={item.exchange_rate} />
                      </CustomTableBodyCell>
                      <CustomTableBodyCell sx={{}} align='right'>
                        <span>{<CurrencyFormatter amount={item.due_amount} currency={item.currency_code} />}</span><br />
                        <CurrencyFormatterWithExchangeRate currency_code={item.currency_code} amount={item.due_amount} exchange_rate={item.exchange_rate} />
                      </CustomTableBodyCell>
                    </CustomTableRow>
                  )
                })
              }
            </CustomTableBody>
          </CustomTable>
        </CustomTableContainer>
        <CustomPagination
          page={page}
          count={pagination.number_of_pages}
          onChange={(event, newValue) => setPage(newValue)}
        />
      </React.Fragment>
      : <NoTransactionComponent />
  )
}

const VendorPaymentTransactionList = (props) => {
  const { vendor_id, open, dispatch, setCountData } = props

  const [page, setPage] = React.useState(1);
  const [paymentData, setPaymentData] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    next: undefined,
    count: '0',
    previous: undefined,
    number_of_pages: undefined,
  });

  React.useEffect(() => {
    const getVendorPayment = async (vendor_id) => {
      // setLoader(dispatch, Actions, true)
      let res = await apiAction({
        method: 'post',
        url: get_list_of_payment(page),
        data: {
          payment_type: "pay", business_id: getBusinessInfo().id,
          party_id: vendor_id,
          // party_display_name: vendor_name,
        },
      })
      if (res?.success) {
        setPagination({
          ...pagination,
          next: res?.result.next,
          count: res?.result.count,
          previous: res?.result.previous,
          number_of_pages: res?.result.number_of_pages,
        })
        setCountData(res?.result.count)
        setPaymentData(res?.result.result)
        setLoader(dispatch, Actions, false)
      } else {
        setLoader(dispatch, Actions, false)
      }
    }
    if (vendor_id && open) {
      getVendorPayment(vendor_id)
    }
  }, [vendor_id, page, open]);

  return (
    paymentData.length ?
      <React.Fragment>
        <CustomTableContainer >
          <CustomTable>
            <CustomTableHead>
              <CustomTableRow>
                <CustomTableHeadCell ><span >Date</span></CustomTableHeadCell>
                <CustomTableHeadCell ><span >Payment#</span></CustomTableHeadCell>
                <CustomTableHeadCell ><span >Bill</span></CustomTableHeadCell>
                <CustomTableHeadCell ><span >Mode</span></CustomTableHeadCell>
                <CustomTableHeadCell align='right' ><span >Total Amount</span></CustomTableHeadCell>
                <CustomTableHeadCell align='right' ><span >Unused Amount</span></CustomTableHeadCell>
              </CustomTableRow>
            </CustomTableHead>
            <CustomTableBody>
              {
                paymentData.map((item, index) => {
                  return (
                    <CustomTableRow key={index}>
                      <CustomTableBodyCell sx={{}} ><span>{moment(item.payment_date).format(getDateFormat())}</span></CustomTableBodyCell>
                      <CustomTableBodyCell sx={{}}  ><PaymentMadeViewLink id={item.id} title={item.id} /></CustomTableBodyCell>
                      <CustomTableBodyCell sx={{}}  >{item.payment_for_reference_number.map((value, index) => { return (<span key={index}>{index ? ', ' : ''}{value}</span>) })}</CustomTableBodyCell>
                      <CustomTableBodyCell sx={{}}  >
                        <p style={{ margin: '0px', marginBottom: '5px' }}>{convertSlugToString(item.payment_method, '_').toUpperCase()}</p>
                        {
                          item.is_advance &&
                          <span style={{ fontSize: '10px', opacity: 0.6, }}>Advance Payment</span>
                        }
                      </CustomTableBodyCell>
                      <CustomTableBodyCell sx={{}} align='right'>
                        <span>{<CurrencyFormatter amount={item.total_amount} currency={item.currency_code} />}</span><br />
                        <CurrencyFormatterWithExchangeRate currency_code={item.currency_code} amount={item.total_amount} exchange_rate={item.exchange_rate} />
                      </CustomTableBodyCell>
                      <CustomTableBodyCell sx={{}} align='right'>
                        <span>{<CurrencyFormatter amount={item.balance_amount} currency={item.currency_code} />}</span><br />
                        <CurrencyFormatterWithExchangeRate currency_code={item.currency_code} amount={item.balance_amount} exchange_rate={item.exchange_rate} />
                      </CustomTableBodyCell>
                    </CustomTableRow>
                  )
                })
              }
            </CustomTableBody>
          </CustomTable>
        </CustomTableContainer>
        <CustomPagination
          page={page}
          count={pagination.number_of_pages}
          onChange={(event, newValue) => setPage(newValue)}
        />
      </React.Fragment>
      : <NoTransactionComponent />
  )
}

const PurchaseOrderTransactionList = (props) => {
  const { vendor_id, dispatch, setCountData, open } = props

  const [page, setPage] = React.useState(1);
  const [purchaseOrderData, setPurchaseOrderDat] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    next: undefined,
    count: '0',
    previous: undefined,
    number_of_pages: undefined,
  });

  React.useEffect(() => {
    const getVendorPurchaseOrder = async (vendor_id) => {
      // setLoader(dispatch, Actions, true)
      let res = await apiAction({
        method: 'post',
        url: get_list_of_purchase_order(page),
        data: { role: 'vendor', business_id: getBusinessInfo().id, vendor_id: vendor_id },
      })
      if (res?.success) {
        setPagination({
          ...pagination,
          next: res?.result.next,
          count: res?.result.count,
          previous: res?.result.previous,
          number_of_pages: res?.result.number_of_pages,
        })
        setCountData(res?.result.count)
        setPurchaseOrderDat(res?.result.result)
        setLoader(dispatch, Actions, false)
      } else {
        setLoader(dispatch, Actions, false)
      }
    }
    if (vendor_id && open) {
      getVendorPurchaseOrder(vendor_id)
    }
  }, [vendor_id, page, open]);

  return (
    purchaseOrderData.length ?
      <React.Fragment>
        <CustomTableContainer >
          <CustomTable>
            <CustomTableHead>
              <CustomTableRow>
                <CustomTableHeadCell style={{ width: 100 }} align='center'><span >Status</span></CustomTableHeadCell>
                <CustomTableHeadCell ><span >Date</span></CustomTableHeadCell>
                <CustomTableHeadCell ><span >Number</span></CustomTableHeadCell>
                {/* <CustomTableHeadCell ><span >Vendor</span></CustomTableHeadCell> */}
                <CustomTableHeadCell align='right' ><span >Total</span></CustomTableHeadCell>
              </CustomTableRow>
            </CustomTableHead>
            <CustomTableBody>
              {
                purchaseOrderData.map((item, index) => {
                  return (
                    <CustomTableRow key={index}>
                      <CustomTableBodyCell sx={{}} align='center'><Status status={item.status} /></CustomTableBodyCell>
                      <CustomTableBodyCell sx={{}}  ><span>{moment(item.purchase_order_date).format(getDateFormat())}</span></CustomTableBodyCell>
                      <CustomTableBodyCell sx={{}}  ><PurchaseOrderViewLink id={item.id} title={item.purchase_order_number} /></CustomTableBodyCell>
                      {/* <CustomTableBodyCell sx={{}}  ><span>{item.vendor_display_name}</span></CustomTableBodyCell> */}
                      <CustomTableBodyCell sx={{}} align='right'>
                        <span>{<CurrencyFormatter amount={item.total_amount} currency={item.currency_code} />}</span><br />
                        <CurrencyFormatterWithExchangeRate currency_code={item.currency_code} amount={item.total_amount} exchange_rate={item.exchange_rate} />
                      </CustomTableBodyCell>
                    </CustomTableRow>
                  )
                })
              }
            </CustomTableBody>
          </CustomTable>
        </CustomTableContainer>
        <CustomPagination
          page={page}
          count={pagination.number_of_pages}
          onChange={(event, newValue) => setPage(newValue)}
        />
      </React.Fragment>
      : <NoTransactionComponent />
  )
}

const ExpenseTransactionList = (props) => {
  const { vendor_id, dispatch, setCountData, open } = props

  const [page, setPage] = React.useState(1);
  const [results, setResults] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    next: undefined,
    count: '0',
    previous: undefined,
    number_of_pages: undefined,
  });

  React.useEffect(() => {
    const getApiResults = async (vendor_id) => {
      // setLoader(dispatch, Actions, true)
      let res = await apiAction({
        method: 'post',
        url: list_expense(page),
        data: { business_id: getBusinessInfo().id, vendor_id: vendor_id },
      })
      if (res?.success) {
        setPagination({
          ...pagination,
          next: res?.result.next,
          count: res?.result.count,
          previous: res?.result.previous,
          number_of_pages: res?.result.number_of_pages,
        })
        setCountData(res?.result.count)
        setResults(res?.result.result)
        setLoader(dispatch, Actions, false)
      } else {
        setLoader(dispatch, Actions, false)
      }
    }
    if (vendor_id && open) {
      getApiResults(vendor_id)
    }
  }, [vendor_id, page, open]);

  return (
    results.length ?
      <React.Fragment>
        <CustomTableContainer >
          <CustomTable>
            <CustomTableHead>
              <CustomTableRow>
                <CustomTableHeadCell style={{ width: 100 }} align='center'><span >Status</span></CustomTableHeadCell>
                <CustomTableHeadCell ><span >Date</span></CustomTableHeadCell>
                <CustomTableHeadCell ><span >Number</span></CustomTableHeadCell>
                <CustomTableHeadCell ><span >Expense Account</span></CustomTableHeadCell>
                <CustomTableHeadCell ><span >Paid Through</span></CustomTableHeadCell>
                <CustomTableHeadCell ><span >Customer Name</span></CustomTableHeadCell>
                <CustomTableHeadCell align='right' ><span >Amount</span></CustomTableHeadCell>
              </CustomTableRow>
            </CustomTableHead>
            <CustomTableBody>
              {
                results.map((item, index) => {
                  return (
                    <CustomTableRow key={index}>
                      <CustomTableBodyCell sx={{}} align='center'><Status status={item.status} /></CustomTableBodyCell>
                      <CustomTableBodyCell sx={{}}  ><span>{moment(item.expense_date).format(getDateFormat())}</span></CustomTableBodyCell>
                      <CustomTableBodyCell sx={{}}  ><ExpenseViewLink id={item.id} title={item.id} /></CustomTableBodyCell>
                      <CustomTableBodyCell sx={{}}  ><span>{item.expense_account_name}</span></CustomTableBodyCell>
                      <CustomTableBodyCell sx={{}}  ><span>{item.paid_through_account_name}</span></CustomTableBodyCell>
                      <CustomTableBodyCell sx={{}}  ><span>{item.customer_name}</span></CustomTableBodyCell>
                      <CustomTableBodyCell sx={{}} align='right'>
                        <span>{<CurrencyFormatter amount={item.total_amount} currency={item.currency_code} />}</span><br />
                        <CurrencyFormatterWithExchangeRate currency_code={item.currency_code} amount={item.total_amount} exchange_rate={item.exchange_rate} />
                      </CustomTableBodyCell>
                    </CustomTableRow>
                  )
                })
              }
            </CustomTableBody>
          </CustomTable>
        </CustomTableContainer>
        <CustomPagination
          page={page}
          count={pagination.number_of_pages}
          onChange={(event, newValue) => setPage(newValue)}
        />
      </React.Fragment>
      : <NoTransactionComponent />
  )
}

const DebitNoteTransactionList = (props) => {
  const { vendor_id, open, dispatch, setCountData } = props

  const [page, setPage] = React.useState(1);
  const [estimateData, setEstimateData] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    next: undefined,
    count: '0',
    previous: undefined,
    number_of_pages: undefined,
  });

  React.useEffect(() => {
    const getCustomerEstimate = async (vendor_id) => {
      // setLoader(dispatch, Actions, true)
      let res = await apiAction({
        method: 'post',
        url: get_list_of_debit_note(page),
        data: { role: "customer", business_id: getBusinessInfo().id, vendor_id: vendor_id },
      })
      if (res?.success) {
        setPagination({
          ...pagination,
          next: res?.result.next,
          count: res?.result.count,
          previous: res?.result.previous,
          number_of_pages: res?.result.number_of_pages,
        })
        setCountData(res?.result.count)
        setEstimateData(res?.result.result)
        setLoader(dispatch, Actions, false)
      } else {
        setLoader(dispatch, Actions, false)
      }
    }
    if (vendor_id && open) {
      getCustomerEstimate(vendor_id)
    }
    // eslint-disable-next-line
  }, [vendor_id, page, open]);

  return (
    estimateData.length ?
      <React.Fragment>
        <CustomTableContainer >
          <CustomTable>
            <CustomTableHead>
              <CustomTableRow>
                <CustomTableHeadCell style={{ width: 100 }} align='center' ><span >Status</span></CustomTableHeadCell>
                <CustomTableHeadCell ><span >Date</span></CustomTableHeadCell>
                <CustomTableHeadCell ><span >Number</span></CustomTableHeadCell>
                <CustomTableHeadCell ><span >Bill#</span></CustomTableHeadCell>
                {/* <CustomTableHeadCell ><span >Customer</span></CustomTableHeadCell> */}
                <CustomTableHeadCell align='right' ><span >Amount</span></CustomTableHeadCell>
                <CustomTableHeadCell align='right' ><span >Balance</span></CustomTableHeadCell>
              </CustomTableRow>
            </CustomTableHead>
            <CustomTableBody>
              {
                estimateData.map((item, index) => {
                  return (
                    <CustomTableRow key={index}>
                      <CustomTableBodyCell sx={{}} align='center'><Status status={item.status} /></CustomTableBodyCell>
                      <CustomTableBodyCell sx={{}}  ><span>{moment(item.debit_note_date).format(getDateFormat())}</span></CustomTableBodyCell>
                      <CustomTableBodyCell sx={{}}  ><DebitNoteViewLink id={item.id} title={item.debit_note_number} /></CustomTableBodyCell>
                      <CustomTableBodyCell sx={{}}  ><span>{item.against_bill_number}</span></CustomTableBodyCell>
                      {/* <CustomTableBodyCell sx={{}}  ><span>{item.vendor_display_name}</span></CustomTableBodyCell> */}
                      <CustomTableBodyCell sx={{}} align='right'>
                        <span>{<CurrencyFormatter amount={item.total_amount} currency={item.currency_code} />}</span><br />
                        <CurrencyFormatterWithExchangeRate currency_code={item.currency_code} amount={item.total_amount} exchange_rate={item.exchange_rate} />
                      </CustomTableBodyCell>
                      <CustomTableBodyCell sx={{}} align='right'>
                        <span>{<CurrencyFormatter amount={item.credit_remaining} currency={item.currency_code} />}</span><br />
                        <CurrencyFormatterWithExchangeRate currency_code={item.currency_code} amount={item.credit_remaining} exchange_rate={item.exchange_rate} />
                      </CustomTableBodyCell>
                    </CustomTableRow>
                  )
                })
              }
            </CustomTableBody>
          </CustomTable>
        </CustomTableContainer>
        <CustomPagination
          page={page}
          count={pagination.number_of_pages}
          onChange={(event, newValue) => setPage(newValue)}
        />
      </React.Fragment>
      : <NoTransactionComponent />
  )
}

const RecurringBillTransactionList = (props) => {
  const { vendor_name, dispatch, setCountData, open } = props

  const [page, setPage] = React.useState(1);
  const [recurringBillData, setRecurringBillData] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    next: undefined,
    count: '0',
    previous: undefined,
    number_of_pages: undefined,
  });

  React.useEffect(() => {
    const getVendorRecurringExpense = async (vendor_name) => {
      // setLoader(dispatch, Actions, true)
      let res = await apiAction({
        method: 'post',
        url: get_list_of_recurring_expense(page),
        data: { business_id: getBusinessInfo().id, vendor_display_name: vendor_name },
      })
      if (res?.success) {
        setPagination({
          ...pagination,
          next: res?.result.next,
          count: res?.result.count,
          previous: res?.result.previous,
          number_of_pages: res?.result.number_of_pages,
        })
        setCountData(res?.result.count)
        setRecurringBillData(res?.result.result)
        setLoader(dispatch, Actions, false)
      } else {
        setLoader(dispatch, Actions, false)
      }
    }
    if (vendor_name && open) {
      getVendorRecurringExpense(vendor_name)
    }
  }, [vendor_name, page, open]);

  return (
    recurringBillData.length ?
      <React.Fragment>
        <CustomTableContainer >
          <CustomTable>
            <CustomTableHead>
              <CustomTableRow>
                <CustomTableHeadCell style={{ width: 100 }} align='center'><span>Status</span></CustomTableHeadCell>
                <CustomTableHeadCell><span>Profile Name</span></CustomTableHeadCell>
                <CustomTableHeadCell><span>Frequency</span></CustomTableHeadCell>
                <CustomTableHeadCell><span>Last Expense Date</span></CustomTableHeadCell>
                <CustomTableHeadCell><span>Next Expense Date</span></CustomTableHeadCell>
                <CustomTableHeadCell align='right'><span>Amount</span></CustomTableHeadCell>
              </CustomTableRow>
            </CustomTableHead>
            <CustomTableBody>
              {
                recurringBillData.map((item, index) => {
                  return (
                    <CustomTableRow key={index}>
                      <CustomTableBodyCell align='center'><Status status={item.status} /></CustomTableBodyCell>
                      {/* <CustomTableBodyCell><span>{item.profile_name}</span></CustomTableBodyCell> */}
                      <CustomTableBodyCell><RecurringExpenseViewLink id={item.periodic_task_id} title={item.profile_name} /></CustomTableBodyCell>
                      <CustomTableBodyCell><span>{to_check_repeat_every(item.repeat_frequency, item.repeat_every) ? to_check_repeat_every(item.repeat_frequency, item.repeat_every).name : `Every ${item.repeat_every} ${item.repeat_frequency.capitalize()}s`}</span></CustomTableBodyCell>
                      <CustomTableBodyCell><span>{item.last_expense_date ? moment(item.last_expense_date).format(getDateFormat()) : '-'}</span></CustomTableBodyCell>
                      <CustomTableBodyCell><span>{item.status === 'Expired' ? '-' : item.next_expense_date ? moment(item.next_expense_date).format(getDateFormat()) : '-'}</span></CustomTableBodyCell>
                      <CustomTableBodyCell align='right'>
                        <span><CurrencyFormatter amount={item.amount} currency={item.currency_code} /></span><br />
                        <CurrencyFormatterWithExchangeRate currency_code={item.currency_code} amount={item.amount} exchange_rate={item.exchange_rate} />
                      </CustomTableBodyCell>
                    </CustomTableRow>
                  )
                })
              }
            </CustomTableBody>
          </CustomTable>
        </CustomTableContainer>
        <CustomPagination
          page={page}
          count={pagination.number_of_pages}
          onChange={(event, newValue) => setPage(newValue)}
        />
      </React.Fragment>
      : <NoTransactionComponent />
  )
}


const Statement = (props) => {
  const { vendor_id } = props

  const stateAction = Actions.getState(React.useContext);
  const dispatch = Actions.getDispatch(React.useContext);

  const { selectedDateRange } = stateAction;
  let filter_date_range = date_range().find((item) => item.name === selectedDateRange)
  const default_date_range = filter_date_range ? filter_date_range : date_range()[2]

  const [dateRange, setDateRange] = React.useState(default_date_range);
  const [stateMent, setStateMent] = React.useState()
  const [body, setBody] = React.useState({
    filter_by: 'all',
    party_id: vendor_id,
    to_date: default_date_range.dates.to_date,
    from_date: default_date_range.dates.from_date,
  })

  React.useEffect(() => {
    if (dateRange) {
      setBody({
        ...body,
        to_date: dateRange.dates.to_date,
        from_date: dateRange.dates.from_date,
      })
    }

  }, [dateRange]);

  const getVendorStatement = async (export_type) => {
    let validation_data = [
      { key: "from_date", message: 'Please select the start date.' },
      { key: "to_date", message: 'Please select the end date.' },
    ]

    const { isValid, message } = isFormValid(body, validation_data);

    if (isValid) {
      setLoader(dispatch, Actions, true)
      let res = await apiAction({
        method: 'post',
        url: export_party_statement(),
        data: { business_id: getBusinessInfo().id, ...body, export_type: export_type },
      })
      if (res?.success) {
        setLoader(dispatch, Actions, false)
        if (export_type === 'html') { setStateMent(res?.result) }
        { export_type !== 'html' && stateChangeManager(dispatch, Actions, true, 'success', res?.status); }
      } else {
        setLoader(dispatch, Actions, false)
        stateChangeManager(dispatch, Actions, true, 'error', res?.status);
      }
    } else {
      stateChangeManager(dispatch, Actions, true, 'error', message);
    }
  }

  const sendVendorStatement = async () => {
    setLoader(dispatch, Actions, true)
    let res = await apiAction({
      method: 'post',
      url: send_party_statement(),
      data: { business_id: getBusinessInfo().id, ...body },
    })
    if (res?.success) {
      setLoader(dispatch, Actions, false)
      stateChangeManager(dispatch, Actions, true, 'success', res?.status);
    } else {
      stateChangeManager(dispatch, Actions, true, 'error', res?.status);
      setLoader(dispatch, Actions, false)
    }
  }
  const onDateRangeChange = (dates) => {
    if (dates) {
      setBody({ ...body, from_date: dates[0].format("YYYY-MM-DD"), to_date: dates[1].format("YYYY-MM-DD") })
    } else {
      setBody({ ...body, to_date: null, from_date: null })
    }

  }

  React.useEffect(() => {
    AnalyticsEvent(eventsNames.categories.VENDORS,{action:eventsNames.actions.vendor.action.STATEMENT});
  }, [])
  return (
    <Box sx={{ p: 2, }}>
      <Box sx={{ borderBottom: '2px solid #F5F5F5' }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={1.75}>
            <Dropdown
              id={'statement_type_dropdown'}
              dataTestId={'statement_type_dropdown'}
              label='Type'
              disableClearable={true}
              value={body.filter_by ? { name: body.filter_by.capitalize() } : null}
              results={[{ name: 'All', value: 'all' }, { name: 'Outstanding', value: 'outstanding' }]}
              setValue={(event, value) => {
                setBody({ ...body, filter_by: value.value })
              }}
            />
          </Grid>
          <Grid item container xs={12} sm={5.25} spacing={1} sx={{}}>
            <CommonCustomDateRange
              setPage={() => { }}
              date_range={date_range()}

              toDate={null}
              setToDate={() => { }}

              fromDate={null}
              setFromDate={() => { }}

              dateRange={dateRange}
              setDateRange={setDateRange}

              isDateRange={true}
              onDateRangeChange={onDateRangeChange}
            />
          </Grid>

          {/* <Grid item xs={12} sm={1} sx={{ mt: 3, textAlign: 'end' }}>
            {stateMent &&
              <ViewPageActionButtons
                isSent={false}
                isPrint={false}
                isDownload={false}
                isEditable={false}
                actionsList={
                  [
                    { name: 'Send Statement', onClick: () => { sendCustomerStatement() }, id: 'send_statement_btn', dataTestId: 'send_statement_btn' },
                    { name: 'Export Statement as PDF', onClick: () => { getCustomerStatement('pdf') }, id: 'export_pdf_btn', dataTestId: 'export_pdf_btn', }
                  ]
                }
              />
            }
          </Grid> */}

          <Grid item xs={12} sm={5} sx={{ mt: 3, justifyContent: 'end', textAlign: 'end' }}>
            <CustomButton
              sx={{ mr: 1 }}
              variant="outlined"
              btnLabel='Create Statement'
              id={'create_statement_btn'}
              dataTestId={'create_statement_btn'}
              onClick={() => { getVendorStatement('html') }}
            />
            {stateMent && body.from_date && body.to_date &&
              <>
                <CustomButton
                  sx={{ mr: 1 }}
                  variant="contained"
                  btnLabel='Send Statement'
                  id={'send_statement_btn'}
                  dataTestId={'send_statement_btn'}
                  onClick={() => { sendVendorStatement() }}
                />
                <CustomButton
                  variant="contained"
                  id={'export_pdf_btn'}
                  btnLabel='Export as PDF'
                  dataTestId={'export_pdf_btn'}
                  onClick={() => { getVendorStatement('pdf') }}
                />
                {/* <CustomGroupButton
                  options={[{ id: 'export_pdf_btn', dataTestId: 'export_pdf_btn', label: 'Export as PDF', condition: () => getVendorStatement('pdf') },
                  ]}
                /> */}
              </>
            }
          </Grid>
        </Grid>
      </Box>
      <>
        {
          stateMent && <HTMLViewer html_string={stateMent.html_string} />
        }
      </>
    </Box>
  )
}

const CustomizedDialogs = (props) => {
  const { open, setOpen, title, vendor, results } = props;

  return (
    <div>
      <BootstrapDialog
        open={open}
        fullWidth={true}
      >

        <BootstrapDialogTitle id="customized-dialog-title" onClose={() => setOpen(false)}>
          <CustomTypography
            text={title}
            sx={{
              color: '#000000',
              fontSize: '16px',
              fontWeight: '700',
              lineHeight: '18px',
              fontStyle: 'normal',
              fontFamily: "Noto Sans",
            }}
          />
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <UnusedCredit vendor={vendor} results={results} />
        </DialogContent>
      </BootstrapDialog>
    </div>
  )
}

const UnusedCredit = (props) => {
  const { vendor, results = [] } = props;

  return (
    <CustomTableContainer>
      <CustomTable>
        <CustomTableHead>
          <CustomTableRow >
            <CustomTableHeadCell align='left'>Credit Info</CustomTableHeadCell>
            <CustomTableHeadCell align='left'>Date Credited</CustomTableHeadCell>
            <CustomTableHeadCell align='right'>Amount</CustomTableHeadCell>
          </CustomTableRow>
        </CustomTableHead>
        <CustomTableBody>
          {
            results.map((item, index) => {
              return (
                <CustomTableRow key={index}>
                  <CustomTableBodyCell sx={{}} align='left' size='small'>
                    {/* <p>{item.debit_note_number}</p> */}
                    {item.credit_type === 'excess_payment' ?
                      <React.Fragment>
                        <PaymentMadeViewLink title={item.id} id={item.id} />
                        {/* <br/><span style={{ fontSize: '10px', opacity: 0.6, }}>{item.credit_note_number}</span> */}
                      </React.Fragment>
                      : <DebitNoteViewLink title={item.debit_note_number} id={item.id} />
                    }
                  </CustomTableBodyCell>
                  <CustomTableBodyCell sx={{}} align='left' size='small'><p>{item.debit_note_date ? moment(item.debit_note_date).format(getDateFormat()) : '-'}</p></CustomTableBodyCell>
                  <CustomTableBodyCell sx={{}} align='right' size='small'><p>{<CurrencyFormatter amount={item.credit_remaining} currency={vendor && vendor.currency.currency_code} />}</p></CustomTableBodyCell>
                </CustomTableRow>
              )
            }
            )}
          <CustomTableRow sx={{ background: '#FFF7DC', }}>
            <CustomTableBodyCell colSpan={3} sx={{ borderTop: '1px solid #FFF0C0', borderBottom: '1px solid #FFF0C0' }} align='right' size='small'><p>{<CurrencyFormatter amount={results.reduce((acc, item) => acc + item.credit_remaining, 0).toFixed(2)} currency={vendor && vendor.currency.currency_code} />}</p></CustomTableBodyCell>
          </CustomTableRow>
        </CustomTableBody>
      </CustomTable>
    </CustomTableContainer>
  )
}