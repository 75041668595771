import React from 'react';
import moment from 'moment/moment';
import HTMLViewer from '../../../common/HTMLViewer';
import * as Actions from '../../../../state/Actions';
import CustomTitle from '../../../common/CustomTitle';
import CustomTabs from '../../../custom/tabs/CustomTabs';
import { routesName } from '../../../../config/routesName';
import { CommonWebSocket } from '../../../../api/webSocket';
import { useLocation, useNavigate } from 'react-router-dom';
import ListItemActions from '../../../common/ListItemActions';
import CustomDialog from '../../../custom/dialog/CustomDialog';
import { getBusinessInfo } from '../../../../config/cookiesInfo';
import CurrencyFormatter from '../../../common/CurrencyFormatter';
import { NoDataComponent } from '../../../common/NoDataComponent';
import AuditTrailHistory from '../../../common/AuditTrailHistory';
import CommonPreviewShare from '../../../common/CommonPreviewShare';
import { eventsNames } from '../../../../firebase/firebaseAnalytics';
import ViewPageActionButtons from '../../../common/ViewPageActionButtons';
import CustomTypography from '../../../custom/typography/CustomTypography';
import CustomPagination from '../../../custom/pagination/CustomPagination';
import CommonJournalEntryTransactionView from '../common/CommonJournalEntryTransactionView';

import {
  payment_made_number,
  payment_received_number,
} from '../../../../utils/Constant';

import {
  CustomContainer,
  CustomTitleContainer,
} from '../../../custom/container/CustomContainer';

import {
  apiAction,
  apiBlobResponse,
  apiHandleDownload,
} from '../../../../api/api';

import {
  delete_payment,
  retrieve_payment,
  delete_refund_payment,
  export_payment_receipt,
  send_payment_receipt_mail,
  get_list_of_refund_payment,
} from '../../../../api/urls';

import {
  setLoader,
  getDateFormat,
  getQueryParams,
  stateChangeManager,
  breadCrumbsStateChangeManager,
} from '../../../../utils/Utils';

import {
  CustomTable,
  CustomTableRow,
  CustomTableBody,
  CustomTableHead,
  CustomTableHeadCell,
  CustomTableBodyCell,
  CustomTableContainer,
} from '../../../custom/table/CustomTable';

import {
  Box,
  Grid,
} from '@mui/material';


const ReceiptView = (props) => {
  const { title, editURL, AnalyticsEvent } = props;

  let navigate = useNavigate();
  let location = useLocation();

  const params = getQueryParams(location.search);
  const dispatch = Actions.getDispatch(React.useContext);

  const [tab, setTab] = React.useState(params.tab ? 1 : 0);
  const [previewData, setPreviewData] = React.useState();
  const [paymentData, setPaymentData] = React.useState();
  const [state, setState] = React.useState({
    id: '',
    title: '',
    open: false,
    condition: '',
    maxWidth: 'lg',
    fullWidth: true,
  });

  let payment_number = paymentData && paymentData.payment_type === "pay" ? payment_made_number : payment_received_number;

  const webSocketResponse = CommonWebSocket();
  React.useEffect(() => {
    if (webSocketResponse) {
      let webSocketData = JSON.parse(webSocketResponse.data)
      if (webSocketData.document_type === "payment") {

        if (webSocketData.event === "delete_document" && paymentData.id === webSocketData.object_id) {
          if (webSocketData.event_success) {
            stateChangeManager(dispatch, Actions, true, "success", `Payment ${payment_number}-${webSocketData.object_id} deleted successfully`);
            navigate(paymentData.payment_type === "pay" ? routesName.invoicingPaymentsMadeList.path : routesName.invoicingPaymentsReceivedList.path);
          } else {
            stateChangeManager(dispatch, Actions, true, "error", `Failed to delete Payment ${payment_number}-${webSocketData.object_id}`);
          }
        }

        if (webSocketData.event === "update_document" && paymentData.id === webSocketData.object_id) {
          if (webSocketData.event_success) {
            stateChangeManager(dispatch, Actions, true, "success", `Payment ${payment_number}-${webSocketData.object_id} updated successfully`);
            getPayment(params.payment_id);
          } else {
            stateChangeManager(dispatch, Actions, true, "error", `Failed to update Payment ${payment_number}-${webSocketData.object_id}`);
          }
        }
      }
    }
  }, [webSocketResponse])

  const viewJournalEntryHandler = (id) => {
    setState({
      id: id,
      open: true,
      maxWidth: 'sm',
      fullWidth: true,
      condition: 'view',
      title: 'View Journal Entry',
    })
  }

  const stateChangeHandler = (title, condition, maxWidth, id, item, url, deleteMessage) => {
    setState({
      id: id,
      url: url,
      open: true,
      item: item,
      title: title,
      fullWidth: true,
      maxWidth: maxWidth,
      condition: condition,
      deleteMessage: deleteMessage,
      navigate: () => navigate(paymentData.payment_type === "pay" ? routesName.invoicingPaymentsMadeList.path : routesName.invoicingPaymentsReceivedList.path),

    })
  };

  const getPayment = async (payment_id) => {
    setLoader(dispatch, Actions, true)
    let res = await apiAction({
      method: 'post',
      url: retrieve_payment(payment_id),
      data: { business_id: getBusinessInfo().id },
    })
    if (res?.success) {
      setPaymentData(res?.result);
      breadCrumbsStateChangeManager(dispatch, res?.result.payment_type === 'pay' ? `${payment_made_number}-${res?.result.id}` : `${payment_received_number}-${res?.result.id}`);
      let previewRes = await apiAction({
        method: 'post',
        url: export_payment_receipt(),
        data: { business_id: getBusinessInfo().id, payment_id: payment_id, export_type: 'html' },
      })
      setPreviewData(previewRes.result);
      setLoader(dispatch, Actions, false);
    } else {
      setLoader(dispatch, Actions, false);
    }
  }
  React.useEffect(() => {
    if (params.payment_id && !tab || params.tab) {
      getPayment(params.payment_id);
    }
  }, [params.payment_id, tab]);


  const sendPaymentReceiptMail = async (payment_id) => {
    AnalyticsEvent({ action: eventsNames.actions.SEND })
    setSendMail(true);
    // const res = await apiAction({ url: send_payment_receipt_mail(), method: 'POST', data: { business_id: getBusinessInfo().id, payment_id: payment_id } })
    // if (res?.success) {
    //   stateChangeManager(dispatch, Actions, true, 'success', res?.status);
    // }
  }

  const print = async (payment_id) => {
    AnalyticsEvent({ action: eventsNames.actions.PRINT })
    apiBlobResponse({
      url: export_payment_receipt(), data: { business_id: getBusinessInfo().id, payment_id: payment_id }, onSuccess: () => {
        setLoader(dispatch, Actions, false)
      }
    })
  }
  const download = async (payment_id) => {
    AnalyticsEvent({ action: eventsNames.actions.DOWNLOAD })
    apiHandleDownload({
      url: export_payment_receipt(), data: { business_id: getBusinessInfo().id, payment_id: payment_id }, filename: `${payment_number}-${payment_id}`, onSuccess: () => {
        setLoader(dispatch, Actions, false)
      }
    })
  }
  const edit = (payment_id) => {
    paymentData.is_advance ?
      navigate(editURL + "?advance_payment_id=" + payment_id, { replace: false })
      :
      navigate(editURL + "?payment_id=" + payment_id, { replace: false })
  }

  const tabs = [
    { name: 'View', condition: 'preview', AnalyticsEvent: () => AnalyticsEvent({ action: eventsNames.actions.PREVIEW }) },
    { name: 'Refund History', condition: 'refund_history', AnalyticsEvent: () => AnalyticsEvent({ action: eventsNames.actions.REFUND_HISTORY }) },
  ]

  const [sendMail, setSendMail] = React.useState(false);

  React.useEffect(() => {
    if (tabs[tab].AnalyticsEvent) {
      tabs[tab].AnalyticsEvent()
    }

  }, [tab])
  return (
    previewData && paymentData &&
    <div>
      <CustomDialog
        state={state}
        setState={setState}
      />
      <CommonPreviewShare
        open={sendMail}
        type={'payment'}
        id={paymentData.id}
        retrieve_Url={retrieve_payment}
        export_Url={export_payment_receipt}
        setOpen={(value) => { setSendMail(value); getPayment(params.payment_id); }}
        file_key={paymentData.payment_type === 'pay' ? `${payment_made_number}-${paymentData.id}` : `${payment_received_number}-${paymentData.id}`}
      />
      <CustomTitleContainer>
        <Grid container spacing={0} style={{
          alignItems: 'center',
          justifyItems: 'center',
          alignContent: 'space-evenly',
          justifyContent: 'space-evenly',
        }}>
          <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'start', }}>
            <CustomTitle title={title} />
          </Grid>
          <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'end', }}>
            <ViewPageActionButtons
              isSent={false}
              isEditable={true}
              edit={() => {
                edit(params.payment_id)
              }}
              print={() => {
                print(params.payment_id)
              }}
              download={() => {
                download(params.payment_id)
              }}

              actionsList={
                [
                  {
                    name: 'Send', onClick: () => {
                      sendPaymentReceiptMail(params.payment_id)
                    }

                  },
                  paymentData.balance_amount > 0 && {
                    name: 'Refund', onClick: () => {
                      paymentData.payment_type === "pay" ?
                        navigate(routesName.invoicingPaymentsMadeRefund.path + "?payment_id=" + paymentData.id, { state: routesName.invoicingPaymentsMadeList.path, replace: false })
                        :
                        navigate(routesName.invoicingPaymentsReceivedRefund.path + "?payment_id=" + paymentData.id, { state: routesName.invoicingPaymentsReceivedList.path, replace: false })
                    }
                  },

                  {
                    name: 'Delete', showDivider: true, onClick: () => {
                      stateChangeHandler('Delete Payment', 'delete', 'sm', paymentData.id, paymentData, delete_payment(paymentData.id), `Are you sure about deleting the selected payment?`);
                      AnalyticsEvent({ action: eventsNames.actions.DELETE })
                    }
                  }
                ]
              }
            />
            <AuditTrailHistory

              data={{ object_id: params.payment_id, object_type: 'payment' }}
              AnalyticsEvent={() => { AnalyticsEvent({ action: eventsNames.actions.HISTORY }) }}
            />
          </Grid>

        </Grid>
      </CustomTitleContainer>

      <CustomContainer maxWidth={"400"} sx={{ height: 'calc(100vh - 134px)' }}>
        <Box sx={{
          p: 2,
          border: '1px solid #FFF0C0', backgroundColor: "#FFF7DC"
        }}>


          <Grid container sx={{ mb: 1 }}>
            <Grid item xs={12} sm={6} sx={{}}>
              <CustomTypography
                text={<span>Unused Amount:</span>}
                sx={{
                  mb: 2,
                  color: '#7A7A7A',
                  fontSize: '14px',
                  fontWeight: '600',
                  lineHeight: '19px',
                  fontStyle: 'normal',
                  textTransform: 'none',
                  fontFamily: "Noto Sans",
                }}
              />
              <Box sx={{ display: 'flex' }}>
                <CustomTypography
                  text={<CurrencyFormatter amount={paymentData.balance_amount} currency={paymentData.currency_code} />}
                  sx={{
                    mr: 1,
                    fontSize: '24px',
                    color: '#000000',
                    fontWeight: '700',
                    lineHeight: '0px',
                    textAlign: 'center',
                    fontStyle: 'normal',
                    textTransform: 'none',
                    fontFamily: "Noto Sans",
                  }}
                />
                {
                  paymentData.balance_amount > 0 &&
                  <CustomTypography
                    id={'action_status_btn'}
                    dataTestId={'action_status_btn'}
                    text={'Refund'}
                    sx={{
                      color: '#2464EB',
                      fontSize: '14px',
                      lineHeight: '0px',
                      display: 'inline',
                      cursor: 'pointer',
                      fontWeight: '700',
                      fontStyle: 'normal',
                      textAlign: 'center',
                      fontFamily: "Noto Sans",
                      ":hover": { textDecoration: 'underline' }
                    }}
                    onClick={() => {
                      paymentData.payment_type === "pay" ?
                        navigate(routesName.invoicingPaymentsMadeRefund.path + "?payment_id=" + paymentData.id, { replace: false })
                        :
                        navigate(routesName.invoicingPaymentsReceivedRefund.path + "?payment_id=" + paymentData.id, { replace: false })
                    }}
                  />
                }
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{}}>
          <CustomTabs
            id={'tabs_'}
            dataTestId={'tabs_'}
            tabs={tabs}
            value={tab}
            onChange={(event, tab) => {
              setTab(tab)
            }}
          />
          {tabs[tab].condition === 'preview' && <PreviewScreen previewData={previewData} basicInformation={paymentData} />}
          {tabs[tab].condition === 'refund_history' && <RefundHistoryScreen paymentData={paymentData} dispatch={dispatch} />}
        </Box>

      </CustomContainer>
    </div>
  )
}

export default ReceiptView;

const PreviewScreen = (props) => {
  return (
    <React.Fragment>
      <Box sx={{ pt: 2 }}>
        <HTMLViewer html_string={props.previewData.html_string} />
      </Box>
      <CommonJournalEntryTransactionView id={props.basicInformation.journal_entry_id} />

    </React.Fragment>
  )
}

const RefundHistoryScreen = (props) => {
  const { paymentData, dispatch } = props;

  let navigate = useNavigate();
  const [page, setPage] = React.useState(1);
  const [results, setResults] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    next: undefined,
    count: '0',
    previous: undefined,
    number_of_pages: undefined,
  });

  const [state, setState] = React.useState({
    id: '',
    title: '',
    open: false,
    condition: '',
    maxWidth: 'lg',
    fullWidth: true,
  });

  const stateChangeHandler = (title, condition, maxWidth, id, item, url, deleteMessage) => {
    setState({
      id: id,
      url: url,
      open: true,
      item: item,
      title: title,
      fullWidth: true,
      maxWidth: maxWidth,
      condition: condition,
      deleteMessage: deleteMessage
    })
  };

  React.useEffect(() => {
    const getCustomerInvoice = async () => {
      setLoader(dispatch, Actions, true);
      let res = await apiAction({
        method: 'post',
        url: get_list_of_refund_payment(page),
        data: { business_id: getBusinessInfo().id, payment_id: paymentData.id, },
      })
      if (res?.success) {
        setPagination({
          ...pagination,
          next: res?.result.next,
          count: res?.result.count,
          previous: res?.result.previous,
          number_of_pages: res?.result.number_of_pages,
        });
        setResults(res?.result.result);
        setLoader(dispatch, Actions, false);
      } else {
        setLoader(dispatch, Actions, false);
      }
    }
    if (!state.open && paymentData) {
      getCustomerInvoice()
    }
  }, [page, state.open]);
  return (
    <React.Fragment>
      <CustomDialog
        state={state}
        setState={setState}
      />
      <CustomTableContainer sx={{ pt: 2 }}>
        <CustomTable>
          <CustomTableHead>
            <CustomTableRow>
              <CustomTableHeadCell ><span >Date</span></CustomTableHeadCell>
              <CustomTableHeadCell ><span >Payment Mode</span></CustomTableHeadCell>
              <CustomTableHeadCell align='right' ><span >Amount Refunded</span></CustomTableHeadCell>
              <CustomTableHeadCell sx={{}} align='right' />
            </CustomTableRow>

          </CustomTableHead>
          <CustomTableBody>
            {
              results.map((item, index) => {
                return (
                  <CustomTableRow key={index}>
                    <CustomTableBodyCell sx={{}}  ><span>{moment(item.refund_date).format(getDateFormat())}</span></CustomTableBodyCell>
                    <CustomTableBodyCell sx={{}}  ><span>{item.payment_method}</span></CustomTableBodyCell>
                    <CustomTableBodyCell sx={{}} align='right'><span>{<CurrencyFormatter amount={item.total_refund_amount} currency={item.currency_code} />}</span></CustomTableBodyCell>
                    <CustomTableBodyCell sx={{}} align='right'>
                      <ListItemActions
                        index={index}
                        actions={
                          [
                            // {
                            //   name: 'Edit', onClick: () => {
                            //     { }
                            //     // item.payment_type === "pay" ?
                            //     //   navigate(routesName.invoicingPaymentsMadeRefund.path + "?refund_id=" + item.id, { replace: false })
                            //     //   :
                            //     //   navigate(routesName.invoicingPaymentsReceivedRefund.path + "?refund_id=" + item.id, { replace: false })
                            //   }
                            // },
                            null,
                            { name: 'Delete', showDivider: true, onClick: () => { stateChangeHandler('Delete Refund History', 'delete', 'sm', item.id, item, delete_refund_payment(item.id), `Are you sure about deleting the selected refund history?`) } }
                          ]
                        }
                      />
                    </CustomTableBodyCell>
                  </CustomTableRow>
                )
              })
            }
          </CustomTableBody>

        </CustomTable>
        <Box sx={{ pt: !results.length ? 10 : 0 }} >
          <NoDataComponent left='0%' top='0%' position={'relative'} data={results} />
        </Box>
      </CustomTableContainer>
      {
        results.length ?
          <CustomPagination
            page={page}
            count={pagination.number_of_pages}
            onChange={(event, newValue) => setPage(newValue)}
          />
          : null
      }
    </React.Fragment>
  )
}
