
import React from "react";
import { isNumeric } from "../../../../../utils/Utils";

export const applyDiscount = (item) => {
}

export const applyTax = (amount,tax_rate,is_amount_tax_inclusive) => {
    
    if(is_amount_tax_inclusive){
        return parseFloat((parseFloat(amount ? amount : 0) - parseFloat((amount? amount : 0) / (1 + tax_rate * 0.01))))
    }else{
        return parseFloat(parseFloat((amount ? amount : 0) * (tax_rate *0.01)))
    }
    
}

export const calculateAmount = (quantity,rate) => {
    return parseFloat(quantity ? quantity : 1) * parseFloat(rate ? rate : 0)
}

export const calculateDiscount = (amount,discount_in_percentage) => {
    return parseFloat(parseFloat(amount) * parseFloat(discount_in_percentage)/100).toFloat()
}

export const actualPrice = (amount_including_gst,rate_of_gst) => {
    return parseFloat(parseFloat(amount_including_gst)/(1+rate_of_gst*0.01))
} 

export const basePriceTotal = (invoice,items) => {
    return items.filter((item) => isNumeric(item.amount)).reduce((a, b) => {
        let tax_total_rate = taxRatePerItem(b)
        let actual_amount = invoice.is_amount_tax_inclusive ? actualPrice(b.amount,tax_total_rate).toFloat() : b.amount.toFloat()
        return a + parseFloat(actual_amount)
    }, 0)
}

// export const totalTdsTcsAmount = (invoice) => {
//     console.log('====>tds_tcs_calculated_amount',invoice)
//     let tds_tcs_calculated_amount = 0;
//     if (invoice.tds_tcs_choice) {
//         if (invoice.selected_tds_tcs) {
//             let selected_value = invoice.selected_tds_tcs;
//             if (invoice.tds_tcs_choice === 'tds') {
//                 tds_tcs_calculated_amount = (invoice.subtotal-invoice.discount) * (selected_value.rate * 0.01);
//             } else {
//                 tds_tcs_calculated_amount = invoice.total_amount * (selected_value.rate * 0.01);
//             }
//         } else {
//             tds_tcs_calculated_amount = 0;
//         }
//     }
//     return tds_tcs_calculated_amount.toFloat();
// }

export const totalTdsTcsAmount = (selected_tds_tcs,tds_tcs_choice,subtotal,discount,total_amount) => {
    let tds_tcs_calculated_amount = 0;
    if (tds_tcs_choice) {
        if (selected_tds_tcs) {
            if (tds_tcs_choice === 'tds') {
                tds_tcs_calculated_amount = (subtotal-discount) * (selected_tds_tcs.rate * 0.01);
            } else {
                tds_tcs_calculated_amount = total_amount * (selected_tds_tcs.rate * 0.01);
            }
        } else {
            tds_tcs_calculated_amount = 0;
        }
    }
    return tds_tcs_calculated_amount.toFloat();
}

export const totalDiscountOnBasePrice = (invoice,items) => {
    return items.filter((item) => isNumeric(item.item_level_discount)).reduce((acc, i) => {
        let itemLevelDiscount = i.item_level_discount ? parseFloat(i.item_level_discount) : 0
        let amount = i.amount ? i.amount : 0
        
        if(i.is_item_discount_in_percentage){
            let tax_total_rate = taxRatePerItem(i)
            let actual_amount = invoice.is_amount_tax_inclusive ? actualPrice(amount,tax_total_rate).toFloat() : amount.toFloat()
            return (acc + calculateDiscount(actual_amount,itemLevelDiscount)).toFloat()
        }else{
            return (acc + itemLevelDiscount).toFloat()
        }
    }, 0)
}

export const taxRatePerItem = (invoice_item) => {
    return  invoice_item.tax ? invoice_item.tax.tax_details.reduce((acc, tax_info) => acc + tax_info.rate, 0) : 0
}


export const convertExclusiveToInclusive = (items) => {
    // eslint-disable-next-line
    items.map((sales_item) => {
        if (sales_item.tax) {
            let amount = parseFloat(sales_item.amount||0)
            // let tax_total_rate = sales_item.tax.tax_details.reduce((acc, tax_info) => acc + tax_info.rate, 0)
            // let total_tax_amount = applyTax(amount, tax_total_rate, false)
            // sales_item.rate = ((amount + total_tax_amount)/sales_item.quantity).toFloat()
            sales_item.rate = ((amount)/sales_item.quantity).toFloat()
            sales_item.amount = calculateAmount(sales_item.quantity, sales_item.rate)
        }
    })
}

export const convertInclusiveToExclusive = (items) => {
    // eslint-disable-next-line
    items.map((sales_item) => {
        if (sales_item.tax) {
            let amount = parseFloat(sales_item.amount||0)
            // let tax_total_rate = sales_item.tax.tax_details.reduce((acc, tax_info) => acc + tax_info.rate, 0)
            // let total_tax_amount = applyTax(amount, tax_total_rate, true)
            // sales_item.rate = ((amount- total_tax_amount)/sales_item.quantity).toFloat()
            sales_item.rate = ((amount)/sales_item.quantity).toFloat()
            sales_item.amount = calculateAmount(sales_item.quantity, sales_item.rate)
        }

    })
}

export const applyTaxOnMultipleGSTComponents = (invoice, items_key, tax_key) => {
    const accounts = {};
    let totalActualTaxAmount = 0;
    let totalDistributedTaxAmount = 0;

    // Helper to calculate amount after discount
    const calculateAmountAfterDiscount = (invoice_item, actual_amount) => {
        const discount = invoice_item.item_level_discount 
            ? invoice_item.is_item_discount_in_percentage 
                ? calculateDiscount(actual_amount, invoice_item.item_level_discount) 
                : invoice_item.item_level_discount 
            : 0;
        return actual_amount - discount.toFloat();
    };

    // Helper to calculate tax per account
    const updateAccount = (account_key, tax_amount, actual_tax_diff, isTruncated) => {
        if (accounts[account_key] !== undefined) {
            accounts[account_key] += isTruncated 
                ? (tax_amount + actual_tax_diff).toFloat() 
                : tax_amount.toFloat();
        } else {
            accounts[account_key] = isTruncated 
                ? (tax_amount + actual_tax_diff).toFloat() 
                : tax_amount.toFloat();
        }
    };

    // Process each item and accumulate tax data
    invoice[items_key].forEach((invoice_item) => {
        if (!invoice_item.tax) return;

        const tax_total_rate = taxRatePerItem(invoice_item);
        const actual_amount = invoice.is_amount_tax_inclusive 
            ? actualPrice(invoice_item.amount, tax_total_rate) 
            : (invoice_item.amount ? invoice_item.amount.toFloat() : 0);
        
        const amount_after_discount = calculateAmountAfterDiscount(invoice_item, actual_amount);
        const actual_tax_amount = applyTax(amount_after_discount, tax_total_rate, false).toFloat();
        const distributed_tax_amount = invoice_item.tax.tax_details.reduce((acc, tax_item) => 
            acc + applyTax(amount_after_discount, tax_item.rate, false).toFloat(), 
            0
        );

        totalActualTaxAmount += actual_tax_amount;
        totalDistributedTaxAmount += distributed_tax_amount;
        let isTruncated = false;

        invoice_item.tax.tax_details.forEach((tax_item) => {
            const account_key = `${tax_key}${tax_item.tax_account}`;
            const tax_amount = applyTax(amount_after_discount, tax_item.rate, false).toFloat();

            const actual_tax_diff = (actual_tax_amount - distributed_tax_amount).toFloat();
            isTruncated = invoice.is_amount_tax_inclusive && actual_tax_amount !== distributed_tax_amount && !isTruncated;

            updateAccount(account_key, tax_amount, actual_tax_diff, isTruncated);
        });

        invoice_item['tax_amount'] = invoice.tax_applied !== "no_tax"
            ? (invoice.is_amount_tax_inclusive ? actual_tax_amount : distributed_tax_amount)
            : '';
    });
    
    let newAccounts={...accounts}
    
    // Distribute tax among accounts
    const getTotalTax=(object)=>Object.values(object).reduce((acc, tax) => acc + tax, 0).toFloat();
    const totalTax = getTotalTax(newAccounts);
    const accountKeys = Object.keys(newAccounts);

    accountKeys.forEach((key, index) => {
        const taxPerAccount = (totalTax / accountKeys.length).toFloat();
        const isMoreThanTwoDecimals = (taxPerAccount * 100) % 1 !== 0;

        accounts[key] = index === 0 && totalActualTaxAmount !== totalDistributedTaxAmount && isMoreThanTwoDecimals
            ? (taxPerAccount - 0.01).toFloat()
            : taxPerAccount;
    });

    return getTotalTax(accounts)===totalTax?{ ...accounts }:{ ...newAccounts };
};





export const isFinalAmountRangeCrossed = ({quantity,rate,item_level_discount, item,invoice, finalPayment,}) => {
    // console.log("Final Payments ", quantity,rate,item_level_discount, item)

    let tax_total_rate = taxRatePerItem(item)
    const amount = calculateAmount(quantity,rate)
    // console.log("Final Payments amount", amount)
    let actual_amount = invoice.is_amount_tax_inclusive ? actualPrice(amount, tax_total_rate) : amount ? amount.toFloat() : 0
    // console.log("Final Payments actual_amount", actual_amount)
    let discount = item_level_discount ? calculateDiscount(actual_amount, item_level_discount) : 0
    // console.log("Final Payments discount", discount)
    let amount_after_discount = actual_amount - discount.toFloat()
    // console.log("Final Payments amount_after_discount", amount_after_discount)
    let actual_tax_amount = applyTax(amount_after_discount,tax_total_rate,false).toFloat()
    // console.log("Final Payments actual_tax_amount", actual_tax_amount)
    
    let final = (amount_after_discount.toFloat() + actual_tax_amount.toFloat()  + finalPayment.toFloat()).toFixed(2).toString()
    // console.log("Final Payments ", finalPayment.toFloat(), " ", amount_after_discount.toFloat()," ",final)
    return final.includes(".") ? final.length > 21 : final.length > 18

}


export function checkItemDiscount(array=[]) {
    // Check if data.items exists, then access the first item and check if is_item_discount_in_percentage property exists
    if (array.length && array[0] && array[0].hasOwnProperty('is_item_discount_in_percentage')) {
      return array[0].is_item_discount_in_percentage;
    } else {
      return true; // Return false if the property doesn't exist or if data.items is not defined
    }
  }


  export const useFinalCalculations = (invoice) => {
    return React.useMemo(() => {
      const accounts = applyTaxOnMultipleGSTComponents(invoice, "sales_items", "");
  
      const totalAmount = basePriceTotal(invoice, invoice.sales_items).toFloat(); //in inclusive tax time amount will be converted to base price and returns sum of all base prices
  
      const totalTax = Object.values(accounts)
        .reduce((acc, tax) => acc + tax, 0)
        .toFloat();
  
      const totalDiscount = totalDiscountOnBasePrice(
        invoice,
        invoice.sales_items
      ).toFloat();
  
      const subTotal = invoice.is_amount_tax_inclusive
        ? invoice.sales_items
          .filter((item) => isNumeric(item.amount))
          .reduce((a, b) => a + parseFloat(b.amount), 0)
          .toFloat()
        : totalAmount.toFloat();
  
      let tds_tcs_amount = totalTdsTcsAmount(
        invoice.selected_tds_tcs,
        invoice.tds_tcs_choice,
        invoice.is_amount_tax_inclusive ? totalAmount : subTotal,
        totalDiscount,
        totalAmount - totalDiscount + totalTax
      );
  
      invoice.tds_tcs_amount = tds_tcs_amount;
  
      let tempFinalPayment = totalAmount - totalDiscount + totalTax;
  
      const finalPayment =
        invoice.tds_tcs_choice === "tds"
          ? (tempFinalPayment - tds_tcs_amount).toFloat()
          : invoice.tds_tcs_choice === "tcs"
            ? (tempFinalPayment + tds_tcs_amount).toFloat()
            : tempFinalPayment.toFloat();
  
      invoice.tax = { ...accounts, total: totalTax.toFloat() };
  
      invoice.discount = totalDiscount.toFloat();
  
      invoice.subtotal = totalAmount.toFloat();
  
      invoice.total_amount = finalPayment;
  
      return {
        accounts,
        totalAmount,
        totalTax,
        totalDiscount,
        subTotal,
        tds_tcs_amount,
        finalPayment,
      };
    }, [invoice]);
  };