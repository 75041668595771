import React from 'react'
import CloseIcon from '@mui/icons-material/Close';
import * as Actions from '../../../state/Actions';

import {
    Alert,
    Button,
    Snackbar,
    IconButton
} from '@mui/material'

const CustomAlert = (props) => {
    const state = Actions.getState(React.useContext);
    const setState = Actions.getDispatch(React.useContext);

    const handleClick = () => {
        setState(Actions.stateChange('alert', true));
    }
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return setState(Actions.stateChange('alert', false));
        }
        setState(Actions.stateChange('alert', false));
    };

    const action = (
        <React.Fragment>
            <Button variant="outlined" onClick={handleClick}>
                Open success snackbar
            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <Snackbar
            action={action}
            open={state.alert}
            onClose={handleClose}
            autoHideDuration={4000}
            message="Note archived"
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>

            <Alert
                {...props}
                onClose={handleClose}
                severity={state.severity}
                sx={{ textTransform: 'none' }}>
                {state.message}
            </Alert>
        </Snackbar>
    )
}

export default CustomAlert
