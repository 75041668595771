import React from 'react';
import Input from '../../../common/Input';
import { useNavigate } from 'react-router-dom';
import { apiAction } from '../../../../api/api';
import Loader from '../../../custom/loader/Loader';
import * as Actions from '../../../../state/Actions';
import CustomTitle from '../../../common/CustomTitle';
import CommonSearch from '../../../common/CommonSearch';
import CustomDialog from '../../../custom/dialog/CustomDialog';
import CustomButton from '../../../custom/button/CustomButton';
import { getBusinessInfo } from '../../../../config/cookiesInfo';
import { LabelWithAsteriskMark } from '../../../common/CommonLabel';
import CustomTypography from '../../../custom/typography/CustomTypography';

import {
    CustomContainer,
    CustomTitleContainer,
} from '../../../custom/container/CustomContainer';

import {
    setLoader,
    isFormValid,
    getUniqueObjects,
    stateChangeManager,
} from '../../../../utils/Utils';

import {
    update_account,
    create_custom_account,
    get_list_of_parent_account_new,
    get_chart_of_account_nested_nodes,
} from '../../../../api/urls';

import {
    Box,
    Accordion,
    IconButton,
    AccordionDetails,
    AccordionSummary,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
    CustomTable,
    CustomTableRow,
    CustomTableBody,
    CustomTableHead,
    CustomTableHeadCell,
    CustomTableBodyCell,
    CustomTableContainer,
} from '../../../custom/table/CustomTable';
import { AsyncParentAccountDropdown } from './AsyncParentAccountDropdown';

const CreateMultipleAccounts = () => {

    const [newParentAccount, setNewParentAccount] = React.useState({
        index: null,
        value: null,
    });

    const [state, setState] = React.useState({
        id: '',
        title: '',
        open: false,
        condition: '',
        maxWidth: 'lg',
        compulsory: '',
        fullWidth: true,
    })


    const addButtonHandler = (title, condition, maxWidth, compulsory, index) => {
        setState({
            open: true,
            title: title,
            fullWidth: true,
            maxWidth: maxWidth,
            condition: condition,
            compulsory: compulsory,
        })
        if (index !== null) {
            setNewParentAccount({
                ...newParentAccount,
                index: index
            })
        }
    }

    return (
        <div>
            <CustomDialog
                sx={{ overflow: 'hidden' }}
                state={state}
                setState={setState}
                onAddAccount={(item) => {
                    setNewParentAccount({
                        ...newParentAccount,
                        value: item
                    })
                }}
            />
            <CustomTitleContainer>
                <CustomTitle title={'Manage Chart of Accounts'} />
            </CustomTitleContainer>

            <CustomContainer maxWidth={"400"} sx={{ height: 'calc(100vh - 134px)' }}>
                {
                    ['ASSET', 'LIABILITY', 'EQUITY', 'EXPENSE', 'INCOME'].map((item, index) => {
                        return (
                            <React.Fragment key={index}>
                                <CommonAccordion
                                    index={index}
                                    account_type={item}
                                    addButtonHandler={addButtonHandler}
                                    newParentAccount={newParentAccount}
                                    setNewParentAccount={setNewParentAccount}
                                />

                            </React.Fragment>
                        )
                    })
                }
            </CustomContainer>
        </div>
    )
}

export default CreateMultipleAccounts;

const AccountHeader = () => {

    return (
        <CustomTableHead>
            <CustomTableRow>
                <CustomTableHeadCell style={{ width: 300 }}><LabelWithAsteriskMark label={'Account Name'} /></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 300 }}><LabelWithAsteriskMark label={'Parent Account'} /></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 80 }} />
            </CustomTableRow>
        </CustomTableHead>
    )
}

const AccountBody = React.memo(({ autoFocus, id, dataTestId, account_type, accounts, newParentAccount, addButtonHandler, setNewParentAccount }) => {

    let navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);

    const [data, setData] = React.useState();
    const [isFormSubmitted, setFormSubmitted] = React.useState(false);

    const [isNewAccount, setIsNewAccount] = React.useState(false);
    const [newAccount, setNewAccount] = React.useState({
        account_name: null,
        parent_account: null
    });

    React.useEffect(() => {
        setData(accounts)
        // eslint-disable-next-line
    }, [accounts])

    React.useEffect(() => {
        const updateAccount = (account) => {
            account['parent_account'] = newParentAccount.value.id;
            account['parent_account_name'] = newParentAccount.value.name;
            return { ...account };
        };
    
        if (newParentAccount.value && account_type === newParentAccount.value.account_type) {
            if (newParentAccount.index !== null) {
                const updatedData = [...data];
                updatedData[newParentAccount.index] = updateAccount(updatedData[newParentAccount.index]);
                setData(updatedData);
            } else {
                setNewAccount(updateAccount(newAccount));
            }
            setNewParentAccount({ index: null, value: null });
        }else{
            if (account_type !== newParentAccount?.value?.account_type) {
                // stateChangeManager(dispatch, Actions, true, 'error', 'New add account created under the' +newParentAccount.value.account_type );
            }
        }
    }, [newParentAccount, account_type, data, newAccount]);

    const onSave = async (item) => {
        setFormSubmitted(true);
        let validation_data = [
            { key: "", validation: item.account_name === null, message: 'Please Enter Account Name' },
            { key: "", validation: item.parent_account === null, message: 'Please Select Parent Account' },
        ]
        const { isValid, message } = isFormValid({}, validation_data)
        if (isValid) {
            let res = await apiAction({
                method: 'post',
                navigate: navigate,
                dispatch: dispatch,
                url: item.id ? update_account(item.id) : create_custom_account(),
                data: {
                    name: item.account_name,
                    parent_id: item.parent_account,
                    business_id: getBusinessInfo().id
                },
            })
            if (res?.success) {
                setNewAccount({
                    account_name: null,
                    parent_account: null
                })
                if (item?.id) {
                    item.isEditable = false;
                } else {
                    accounts.unshift({ ...res?.result, is_custom: true });
                }
                setFormSubmitted(false);
                stateChangeManager(dispatch, Actions, true, 'success', res?.status);

            } else {
                stateChangeManager(dispatch, Actions, true, 'error', res?.status);
            }
        } else {
            stateChangeManager(dispatch, Actions, true, 'error', message);
        }
    }

    return (
        <React.Fragment>
            <CustomTableBody>
                <CustomTableRow sx={{ borderBottom: '1px solid #F5F5F5' }}>
                    <CustomTableBodyCell size='small'>
                        <Input
                            autoFocus={autoFocus}
                            id={'input_name_' + id}
                            dataTestId={'input_name_' + dataTestId}
                            item={{
                                title: '',
                                type: 'text',
                                required: true,
                                fullWidth: true,
                                disabled: !isNewAccount,
                                placeholder: 'Enter Account Name',
                                value: newAccount.account_name ? newAccount.account_name : '',
                                validation: isFormSubmitted && isNewAccount && !newAccount.account_name,
                                onChange: (event) => {
                                    if (event.target.value !== '') {
                                        newAccount.account_name = event.target.value;
                                    } else {
                                        newAccount.account_name = null;
                                    }
                                    setNewAccount({ ...newAccount })
                                }
                            }}
                        />
                    </CustomTableBodyCell>
                    <CustomTableBodyCell size='small'>
                        <AsyncParentAccountDropdown
                            sx={{}}
                            title=''
                            disabled={!isNewAccount}
                            id={'parent_account_' + id}
                            dataTestId={'parent_account_' + dataTestId}

                            URL={`${get_list_of_parent_account_new(1)}&account_type=${account_type}`}
                            validation={isFormSubmitted && isNewAccount && !newAccount.parent_account}
                            nextUrlAdditionData={`&business_id=${getBusinessInfo()?.id}&account_type=${account_type}`}
                            selectedValue={newAccount.parent_account ? { id: newAccount.parent_account, name: newAccount['parent_account_name'] } : null}

                            setSelectedValue={(value) => {
                                if (value) {
                                    newAccount.parent_account = value.id;
                                    newAccount['parent_account_name'] = value.name;
                                } else {
                                    newAccount.parent_account = null;
                                    newAccount['parent_account_name'] = null;
                                }
                                setNewAccount({ ...newAccount })
                            }}
                            addButton={{
                                title: '+ Add New ',
                                onClick: () => { addButtonHandler('Add New Parent Account', 'create_parent_account', 'sm', account_type, null) },
                            }}
                        />

                    </CustomTableBodyCell>
                    <CustomTableBodyCell size='small'>
                        <Box sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
                            {isNewAccount ?
                                <>
                                    <CustomButton
                                        btnLabel='Save'
                                        variant="contained"
                                        id={'save_' + id + '_btn'}
                                        sx={{ maxHeight: 34, mr: 1 }}
                                        dataTestId={'save_' + dataTestId + '_btn'}
                                        onClick={() => { onSave(newAccount); }}
                                    />
                                    <IconButton sx={{}} onClick={() => {
                                        setIsNewAccount(!isNewAccount);
                                        setNewAccount({
                                            account_name: null,
                                            parent_account: null
                                        })
                                    }}
                                        id={'cancel_' + id + '_btn'}
                                        dataTestId={'cancel_' + dataTestId + '_btn'}
                                    >
                                        <CloseIcon fontSize='small' sx={{ color: '#2464EB' }} />
                                    </IconButton>
                                </>
                                :
                                <CustomButton
                                    variant="contained"
                                    sx={{ maxHeight: 34 }}
                                    btnLabel='Add New Account'
                                    id={'add_new_' + id + '_btn'}
                                    dataTestId={'add_new_' + dataTestId + '_btn'}
                                    onClick={() => { setIsNewAccount(!isNewAccount); }}
                                />
                            }
                        </Box>
                    </CustomTableBodyCell>
                </CustomTableRow>
                {
                    data && data.map((item, index) => {
                        let isEditable = item?.isEditable;
                        let parent_account_id = item?.parent_account;
                        let parent_account_name = item?.parent_account_name;
                        return (
                            <CustomTableRow key={index} sx={{ borderBottom: '1px solid #F5F5F5' }}>
                                <CustomTableBodyCell size='small'>
                                    <Input
                                        id={'input_account_name_' + index}
                                        dataTestId={'input_account_name_' + index}
                                        item={{
                                            title: '',
                                            type: 'text',
                                            required: true,
                                            fullWidth: true,
                                            placeholder: 'Enter Account Name',
                                            disabled: !data[index]?.isEditable,
                                            validation: isFormSubmitted && !data[index]?.account_name,
                                            value: data[index]?.account_name ? data[index]?.account_name : '',
                                            onChange: (event) => {
                                                if (event.target.value !== '') {
                                                    data[index].account_name = event.target.value;
                                                } else {
                                                    data[index].account_name = null;
                                                }
                                                setData([...data])
                                            }
                                        }}
                                    />
                                </CustomTableBodyCell>
                                <CustomTableBodyCell size='small'>
                                    <AsyncParentAccountDropdown
                                        title=''
                                        disabled={!isEditable}
                                        id={'parent_account_' + index}
                                        dataTestId={'parent_account_' + index}

                                        validation={isFormSubmitted && !parent_account_id}
                                        URL={`${get_list_of_parent_account_new(1)}&account_type=${account_type}`}
                                        nextUrlAdditionData={`&business_id=${getBusinessInfo()?.id}&account_type=${account_type}`}
                                        selectedValue={parent_account_id ? { id: parent_account_id, name: parent_account_name } : null}

                                        setSelectedValue={(value) => {
                                            if (value) {
                                                data[index].parent_account = value.id;
                                                data[index]['parent_account_name'] = value.name;
                                            } else {
                                                data[index].parent_account = null;
                                                data[index]['parent_account_name'] = null;
                                            }
                                            setData([...data])
                                        }}
                                        addButton={{
                                            title: '+ Add New ',
                                            onClick: () => { addButtonHandler('Add New Parent Account', 'create_parent_account', 'sm', account_type, index) },
                                        }}
                                    />
                                </CustomTableBodyCell>
                                <CustomTableBodyCell size='small'>
                                    {
                                        data[index]?.is_custom &&
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            {
                                                data[index]?.isEditable ?
                                                    <>
                                                        <CustomButton
                                                            btnLabel='Update'
                                                            variant="contained"
                                                            sx={{ maxHeight: 34, mr: 1 }}
                                                            id={'parent_account_save_btn' + index}
                                                            onClick={() => { onSave(data[index]) }}
                                                            dataTestId={'parent_account_save_btn' + index}
                                                        />

                                                        <IconButton sx={{}} onClick={() => {
                                                            if (data[index]?.parent_account) {
                                                                data[index].isEditable = false;
                                                                setData([...data,])
                                                            } else {

                                                            }
                                                        }}
                                                            id={'parent_account_action_btn' + index}
                                                            dataTestId={'parent_account_action_btn' + index}
                                                        >
                                                            <CloseIcon fontSize='small' sx={{ color: '#2464EB' }} />
                                                        </IconButton>
                                                    </>
                                                    :
                                                    <IconButton sx={{}} onClick={() => {
                                                        data[index].isEditable = true;
                                                        setData([...data,])
                                                    }}>
                                                        <ModeEditIcon fontSize='small' sx={{ color: '#2464EB' }} />
                                                    </IconButton>
                                            }
                                        </Box>}
                                </CustomTableBodyCell>
                            </CustomTableRow>
                        )
                    })
                }
            </CustomTableBody>
        </React.Fragment>

    )
});



const CommonAccordion = (props) => {
    const { index, account_type, newParentAccount, addButtonHandler, setNewParentAccount } = props;
    let navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);
    const [searchTerm, setSearchTerm] = React.useState('');
    const dispatch = Actions.getDispatch(React.useContext);

    const [page, setPage] = React.useState(1);
    const [accounts, setAccounts] = React.useState([])
    const [pagination, setPagination] = React.useState({
        next: undefined,
        count: undefined,
        previous: undefined,
        number_of_pages: undefined,
    });

    const getAccountsResults = async () => {
        setLoader(dispatch, Actions, true);
        const res = await apiAction({
            method: 'post',
            navigate: navigate,
            dispatch: dispatch,
            url: get_chart_of_account_nested_nodes(page),
            data: { business_id: getBusinessInfo()?.id, account_type: [account_type], account_name: searchTerm },
        });

        if (res?.success) {
            setPagination({
                ...pagination,
                next: res?.result.next,
                count: res?.result.count,
                previous: res?.result.previous,
                number_of_pages: res?.result.number_of_pages,
            });
            setIsLoading(false);
            setLoader(dispatch, Actions, false);
            setAccounts([...getUniqueObjects([...accounts, ...res?.result.result])]);
        } else {
            setLoader(dispatch, Actions, false);

        }
    };

    React.useEffect(() => {
        if (open && account_type) {
            getAccountsResults()
        }
    }, [open, page, account_type, searchTerm]);

    const onChangeHandler = () => {
        setPage(1);
        setOpen(!open);
        setAccounts([]);
        setIsLoading(true);

    }
    return (
        <Box style={{ border: '5px solid #F5F5F5' }}>
            <Accordion
                square
                elevation={0}
                disableGutters
                sx={{
                    '&:before': { display: 'none' }
                }}
                onChange={() => { onChangeHandler() }}
            >
                <AccordionSummary sx={{}} expandIcon={<ExpandMoreIcon />}>
                    <CustomTypography
                        text={account_type}
                        id={'multiple_account_' + index}
                        dataTestId={'multiple_account_' + index}
                        sx={{
                            color: '#000000',
                            fontSize: '14px',
                            fontWeight: '700',
                            lineHeight: '18px',
                            fontStyle: 'normal',
                            fontFamily: "Noto Sans",
                        }}
                    />

                </AccordionSummary>

                <AccordionDetails>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ flexGrow: 1 }}>

                        </div>
                        {open &&
                            <div style={{ flexGrow: .2, marginRight: '16px' }}>
                                <CommonSearch autoFocus={false} sx={{ height: '40px' }} id={'input_search_under_' + account_type} dataTestId={'input_search_under_' + account_type} isTitleVisibility={false} placeholder={'Search account'} fullWidth={true} onSearchValue={searchTerm} onSearch={(search_text) => {
                                    if (search_text) {
                                        setPage(1);
                                        setAccounts([]);
                                        setSearchTerm(search_text);
                                    } else {
                                        setPage(1);
                                        setSearchTerm('')
                                    }
                                }} />
                            </div>
                        }
                    </div>
                    {isLoading ?
                        <CustomTypography
                            text={<Loader />}
                            sx={{
                                mt: '5vh',
                                color: '#000000',
                                fontSize: '14px',
                                fontWeight: '700',
                                textAlign: 'center',
                                lineHeight: '18px',
                                fontStyle: 'normal',
                                fontFamily: "Noto Sans",
                            }}
                        />
                        :
                        <CustomTableContainer >

                            <CustomTable>
                                <AccountHeader />
                                <AccountBody
                                    autoFocus={true}
                                    refresh={() => { }}
                                    accounts={accounts}
                                    account_type={account_type}
                                    id={'account_body_' + index}
                                    dataTestId={'account_body_' + index}

                                    newParentAccount={newParentAccount}
                                    setNewParentAccount={setNewParentAccount}

                                    addButtonHandler={addButtonHandler}
                                />
                            </CustomTable>
                            {
                                pagination.next &&
                                <CustomTypography
                                    text={'More'}
                                    sx={{
                                        p: 2,
                                        pt: 1,
                                        color: '#2464EB',
                                        fontSize: '16px',
                                        cursor: 'pointer',
                                        fontWeight: '700',
                                        textAlign: 'start',
                                        fontStyle: 'normal',
                                        lineHeight: 'normal',
                                        fontFamily: 'Noto Sans',

                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                    }}
                                    onClick={() => { setPage(page + 1) }}
                                />
                            }
                        </CustomTableContainer>
                    }
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}