import React, { useState, useEffect, useMemo, useCallback } from 'react';
import CustomTypography from '../custom/typography/CustomTypography';
import CustomAsyncDropdown from '../custom/dropdown/CustomAsyncDropdown';

const CommonAsyncDropdown = ({
    id,
    dataTestId,
    options,
    autoFocus,
    groupBy = 'firstletter',
    filter_by_id,
    filterValue,
    item,
    sx,
    placeholder,
    disableClearable,
    autoSelect,
    noOptionsText,
    addButton,
    onSearchHandler,
    validation,
    defaultValue,
    isShowEndAdornment = false,
    optionLabel,
    fetchTermLimit = 2,
    className
}) => {
    const [results, setResults] = React.useState([]);

    React.useEffect(() => {
        if (options) {
            setResults(options);
        }
    }, [options]);

    const filteredResults = React.useMemo(() => {
        return item.filter ? results.filter(value => value.account_type === item.filter) : results;
    }, [results, item.filter]);

    const handleSetValue = React.useCallback((value) => {
        if (onSearchHandler) {
            onSearchHandler(value);
        }
    }, [onSearchHandler]);

    const combinedSx = React.useMemo(() => {
        return item.sx ? { ...item.sx, background: item.disabled ? '#F1F3F4' : '' } : { ...sx, background: item.disabled ? '#F1F3F4' : '' };
    }, [item.sx, item.disabled, sx]);

    return (
        <div>
            <CustomTypography
                text={item.label}
                sx={{
                    mb: 1,
                    fontSize: '14px',
                    color: '#000000',
                    fontWeight: '700',
                    lineHeight: '18px',
                    fontStyle: 'normal',
                    textTransform: "none",
                    fontFamily: "Noto Sans",
                }}
            />
            <CustomAsyncDropdown
                id={id}
                url={item.url}
                body={item.body}
                groupBy={groupBy}
                autoFocus={autoFocus}
                className={className}
                addButton={addButton}
                dataTestId={dataTestId}
                autoSelect={autoSelect}
                validation={validation}
                onChange={item.onChange}
                placeholder={placeholder}
                optionLabel={optionLabel}
                inputDisabled={item.disabled}
                noOptionsText={noOptionsText}
                fetchTermLimit={fetchTermLimit}
                disableClearable={disableClearable}
                isShowEndAdornment={isShowEndAdornment}
                value={item.value || defaultValue || null}
                setValue={handleSetValue}
                results={filteredResults}
                sx={combinedSx}
            />
        </div>
    );
};

export default React.memo(CommonAsyncDropdown);
