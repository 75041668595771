import React from 'react';
import Input from '../../../common/Input';
import CustomTitle from '../../../common/CustomTitle';
import CommonSearch from '../../../common/CommonSearch';
import { reports_list } from '../../../../utils/Constant';
import { useNavigate, useLocation } from 'react-router-dom';
import { CustomLink } from '../../../custom/link/CustomLink';
import { HtmlTooltip } from '../../../custom/tooltip/CustomTooltip';
import CustomTypography from '../../../custom/typography/CustomTypography';

import {
    HighlightedText,
} from '../../../../utils/Utils';

import {
    Box,
    Slide,
    Drawer,
    Button,
    Divider,
    IconButton,
    InputAdornment,
} from '@mui/material';

import { Close } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const ReportSearch = (props) => {
    const { buttonStyle } = props;

    const navigate = useNavigate();
    const location = useLocation();

    const inputRef = React.useRef();
    const [open, setOpen] = React.useState(false);
    const [searchTerm, setSearchTerm] = React.useState('');
    // React.useEffect(() => { if (open) { inputRef.current.focus(); } }, [open]);

    function searchReportsByName(searchTerm) {
        const results = [];

        for (const category of reports_list) {
            const matchingItems = category.item.filter((item) =>
                item.name.toLowerCase().includes(searchTerm.toLowerCase())
            );

            if (matchingItems.length > 0) {
                const categoryWithMatches = { ...category, item: matchingItems };
                results.push(categoryWithMatches);
            }
        }

        return results;
    }

    const isSelected = (value) => { return location.pathname === value }
    const reportsList = searchTerm ? searchReportsByName(searchTerm) : reports_list

    return (
        <React.Fragment>
            <HtmlTooltip title={'Reports search'} placement="bottom">
                <Button
                    size='small'
                    variant="outlined"
                    aria-label="MenuIcon"
                    id={'search_reports_btn'}
                    sx={{ ...buttonStyle, ml: 1 }}
                    onClick={() => { setOpen(true) }}
                    dataTestId={'search_reports_btn'}>
                    <SearchIcon />
                </Button>
            </HtmlTooltip>
            <Drawer
                open={open}
                elevation={5}
                anchor={'right'}
                hideBackdrop={true}
                TransitionComponent={Transition}
                id={'common_report_search_Dialog'}
                variant={open?"persistent":'temporary'}
                dataTestId={'common_report_search_Dialog'}

                PaperProps={{
                    sx: {
                        zIndex:999999,
                        backgroundColor: '#FFFFFF',
                        width: {
                            sm: '25vw',
                            xs: '100vw',
                        }
                    }
                }}


            >


                <Box sx={{
                    zIndex: 1,
                    minHeight: '100vh',
                    backgroundColor: '#FFFFFF',
                    boxShadow: '1px 0px 4px rgba(0, 0, 0, 0.04)',
                }}>
                    <Box sx={{ height: '24px', p: 2, pt: 3, backgroundColor: '#F2F2F2', borderBottom: '1px solid #e0e0e0', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <CustomTitle title={'Reports'} />
                        <IconButton size='small' id={'close_reports_search_btn'} dataTestId={'close_reports_search_btn'} onClick={() => setOpen(false)} sx={{ ":hover": { bgcolor: '#e0e0e0' } }}>
                            <Close fontSize='small' />
                        </IconButton>
                    </Box>
                    <Box sx={{ pr: 1, pl: 1, mb: 1 }}>
                        <CommonSearch
                            fullWidth={true}
                            autoFocus={true}
                            inputRef={inputRef}
                            isTitleVisibility={false}
                            id={'input_reports_search'}
                            placeholder={'Search reports'}
                            dataTestId={'input_reports_search'}
                            onSearchValue={searchTerm ? searchTerm : ''}
                            onSearch={(search_text) => { setSearchTerm(search_text) }} />
                        {/* <Input
                            autoFocus={true}
                            inputRef={inputRef}
                            isKeyPressEnter={false}
                            isSearchableComponent={true}

                            id={'input_reports_search'}
                            dataTestId={'input_reports_search'}
                            item={{
                                type: 'text',
                                title: '',
                                fullWidth: true,
                                titleVisibility: 'hidden',
                                placeholder: `Search reports`,
                                value: searchTerm ? searchTerm : '',
                                onChange: (e) => {
                                    setSearchTerm(e.target.value);
                                },
                                onblur: (e) => {
                                    setSearchTerm(e.target.value);

                                }
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <Divider sx={{ height: 20, m: 0.5 }} orientation="vertical" />
                                    <IconButton edge="end" size='small' disabled={searchTerm === "" ? true : false} sx={{ ml: .5, color: '#2464EB', cursor: 'pointer' }}
                                        onClick={() => {
                                            if (searchTerm !== "") {
                                                setSearchTerm('');
                                            } else {
                                                setSearchTerm(searchTerm);
                                            }
                                        }}>
                                        {
                                            searchTerm !== "" ?
                                                <ClearIcon /> : <SearchIcon />
                                        }
                                    </IconButton>
                                </InputAdornment>

                            }}
                        /> */}
                    </Box>
                    <Divider />
                    <Box sx={{ height: 'calc(100vh - 120px)', overflow: 'auto' }} >
                        {
                            reportsList.length ?
                                reportsList.map((value, index) => {
                                    return (
                                        <Box key={index} sx={{}}>
                                            <CustomTypography
                                                text={value.title}
                                                sx={{
                                                    pb: 1,
                                                    pt: 1,
                                                    pl: 1,
                                                    color: '#141414',
                                                    fontWeight: 700,
                                                    fontSize: '12px',
                                                    lineHeight: '16px',
                                                    fontStyle: 'normal',
                                                    fontFamily: 'Noto Sans',
                                                }}
                                            />
                                            {
                                                value.item.map((item, key) => {
                                                    return (
                                                        <React.Fragment key={key} >
                                                            <CustomLink
                                                                to={item.path}

                                                                id={`report_search_link_${index}_${key}`}
                                                                dataTestId={`report_search_link_${index}_${key}`}

                                                                sx={{
                                                                    p: 1,
                                                                    pl: 2,
                                                                    fontWeight: 400,
                                                                    display: 'block',
                                                                    fontSize: '12px',
                                                                    cursor: 'pointer',
                                                                    lineHeight: '16px',
                                                                    fontStyle: 'normal',
                                                                    fontFamily: 'Noto Sans',
                                                                    backgroundColor: isSelected(item.path) ? "#e0e0e0" : '',
                                                                    ":hover": { backgroundColor: isSelected(item.path) ? '' : "#f5f5f5", },
                                                                }}
                                                            >{item.name}</CustomLink>
                                                            {/* <CustomTypography
                                                                text={item.name}
                                                                id={`report_search_link_${index}_${key}`}
                                                                dataTestId={`report_search_link_${index}_${key}`}
                                                                onClick={() => {
                                                                    setOpen(false);
                                                                    setSearchTerm();
                                                                    if (!isSelected(item.path)) { navigate(item.path); }
                                                                }}
                                                                sx={{
                                                                    p: 1,
                                                                    pl: 2,
                                                                    fontWeight: 400,
                                                                    display: 'block',
                                                                    color: '#141414',
                                                                    fontSize: '12px',
                                                                    cursor: 'pointer',
                                                                    lineHeight: '16px',
                                                                    fontStyle: 'normal',
                                                                    fontFamily: 'Noto Sans',
                                                                    backgroundColor: selectedItem === item.path ? "#e0e0e0" : '',
                                                                    ":hover": { backgroundColor: selectedItem === item.path ? '' : "#f5f5f5", },
                                                                }}
                                                            /> */}

                                                        </React.Fragment>
                                                    )
                                                })
                                            }

                                        </Box>
                                    )
                                })
                                : <CustomTypography
                                    text={`We can’t find anything with current search term at the moment, try searching something else.`}
                                    sx={{
                                        pl: 1,
                                        pr: 1,
                                        mt: '35vh',
                                        color: '#000000',
                                        fontSize: '14px',
                                        fontWeight: '700',
                                        textAlign: 'center',
                                        lineHeight: '18px',
                                        fontStyle: 'normal',
                                        fontFamily: "Noto Sans",
                                    }}
                                />
                        }
                    </Box>
                </Box>

            </Drawer>
        </React.Fragment>
    )
}

export default ReportSearch
