import React from 'react'

import {
    Tab,
    Tabs,
    styled,
} from '@mui/material'

const CustomVerticalTabs = (props) => {
    const { id, dataTestId, tabs, value, onChange } = props
    return (
        <AntTabs id={id} dataTestId={dataTestId} value={value} onChange={onChange} orientation="vertical" variant="scrollable">
            {
                tabs.map((item, index) => {
                    return (
                        <AntTab id={id + index} dataTestId={dataTestId + index} key={index} label={item.name ? item.name : item} />
                    )
                })
            }
        </AntTabs>

    )
}

export default CustomVerticalTabs;

const AntTabs = styled(Tabs)({
    fontSize: '14px',
    '& .MuiTabs-indicator': {
        width: '5px',
        display: 'flex',
        borderRadius: '6px',
        backgroundColor: '#2464EB',
    },


});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textAlign: 'left',
    textTransform: 'none',
    alignItems: 'flex-start',
    borderBottom: '2px solid #F5F5F5',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
        minWidth: 0,
    },
    fontWeight: 400,
    marginRight: theme.spacing(1),
    color: '#141414',
    opacity: 0.8,
    fontSize: '14px',
    fontFamily: [
        'Noto Sans',
    ].join(','),
    '&:hover': {
        color: '#2464EB',
        opacity: 1,
        fontSize: '14px',
    },
    '&.Mui-selected': {
        color: '#141414',
        fontWeight: 700,
        opacity: 1,
        fontSize: '14px',
    },
    '&.Mui-focusVisible': {
        backgroundColor: '#d1eaff',
    },

}));

