import React from 'react';
import PaymentList from '../../payment/PaymentList';
import { routesName } from '../../../../../config/routesName';
import { eventsNames,AnalyticsEvent } from '../../../../../firebase/firebaseAnalytics';

const ListPaymentsMade = () => {
    return (
        <div>
            <PaymentList
                columns={columns}
                title='All Payments'
                btnLabel='Add payment'
                body={{ payment_type: "pay" }}
                btnClick={routesName.invoicingPaymentsMade.path}
                viewURL={routesName.invoicingPaymentsMadeView.path}
                AnalyticsEvent={(params)=>{AnalyticsEvent(eventsNames.categories.PAYMENTS_MADE,params)}}
            />
        </div>
    )
}

const columns = [
    {
        label: "Date",
        sortable: true,
        field: "payment_date",
        width: { width: 120 },
    },
    {
        label: "Payment#",
        sortable: true,
        width: { width: 150 },
        field: "id",
    },
    {
        sortable: true,
        width: { width: 200 },
        label: "Vendor Name",
        field: "party_display_name",
    },
    {
        label: "Bill",
        sortable: false,
        width: { width: 150 },
        field: "payment_for_reference_number",
    },
    {
        label: "Mode",
        sortable: false,
        width: { width: 100 },
        field: "payment_method",
    },
    {
        align: 'right',
        sortable: true,
        label: "Total Amount",
        width: { width: 150 },
        field: "total_amount",
    },
    // {
    //     align: 'right',
    //     sortable: true,
    //     width: { width: 150 },
    //     label: "Total Payment Amount",
    //     field: "total_payment_amount",
    // },
    {
        align: 'right',
        sortable: true,
        width: { width: 150 },
        label: "Unused Amount",
        field: "balance_amount",
    },
    {
        field: "",
        align: 'right',
        label: "Actions",
        sortable: false,
        width: { width: 50 },
    },
]
export default ListPaymentsMade;