import React from 'react'
import Create from '../common/Create';
import { useNavigate } from 'react-router-dom';
import { apiAction } from '../../../../../api/api'
import * as Actions from '../../../../../state/Actions';
import { getBusinessInfo } from '../../../../../config/cookiesInfo';
import { eventsNames, AnalyticsEvent } from '../../../../../firebase/firebaseAnalytics';

import {
    update_journal_entry,
    retrieve_journal_entry,
} from '../../../../../api/urls'

const UpdateJournalEntry = (props) => {
    const { id, onClose, currencyDisabled = false } = props
    let navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);

    React.useEffect(() => {
        if (id) {
            getApiResults(id)
        }
    }, [id])

    const [results, setResults] = React.useState([])
    const [currency, setCurrency] = React.useState()
    const [transactions, setTransactions] = React.useState([])
    const getApiResults = async (id) => {
        let res = await apiAction({
            method: 'post',
            navigate: navigate,
            dispatch: dispatch,
            url: retrieve_journal_entry(id),
            data: { business_id: getBusinessInfo().id }
        })
        if (res?.success) {
            setResults(res?.result)
            if (res?.result.transactions.length) {
                setTransactions(res?.result.transactions)
                setCurrency({ id: res?.result.transactions[0].currency_id, currency_code: res?.result.transactions[0].currency, symbol: res?.result.transactions[0].currency_symbol })
            } else {
                setTransactions([
                    {
                        account: true,
                        transaction_type: "DEBIT",
                        amount: res?.result.transaction_amount,
                        currency_id: getBusinessInfo().currency.id,
                        currency: getBusinessInfo().currency.currency_code
                    },
                    {
                        account: true,
                        transaction_type: "CREDIT",
                        amount: res?.result.transaction_amount,
                        currency_id: getBusinessInfo().currency.id,
                        currency: getBusinessInfo().currency.currency_code
                    }
                ])
            }
        } else {

        }
    }

    return (
        <div>
            <Create
                onClose={onClose}
                transactions={transactions}
                url={update_journal_entry(id)}
                currencyDisabled={currencyDisabled}
                current_journal_entry_id={results.id}
                contra_entry_for_id={results.contra_entry_for_id}

                updateData={{
                    note: results.note,
                    source: results.source,
                    is_contra: results.is_contra,
                    attachment: results.attachment,
                    date: results.transaction_date,
                    description: results.description,
                    amount: results.transaction_amount,
                    contra_entry_for_id: results.contra_entry_for_id,
                    currency: currency ? currency : getBusinessInfo().currency,
                }}
                AnalyticsEvent={(params) => { AnalyticsEvent(eventsNames.categories.JOURNAL_ENTRY, params) }}

            />

        </div>
    )
}

export default UpdateJournalEntry