import moment from "moment/moment";
import Emails from "../../common/Emails";
import React, { useContext } from "react";
import "react-multi-email/dist/style.css";
import { TaxList } from "../../common/GST";
import Input from "../../../../common/Input";
import * as Common from "../../common/Common";
import { ListTerms } from "../../common/Terms";
import DatePicker from "../../../../common/DatePicker";
import * as Actions from "../../../../../state/Actions";
import CustomTitle from "../../../../common/CustomTitle";
import { InputFile } from "../../../../common/InputFile";
import CommonAlertBox from "../../common/CommonAlertBox";
import PrintAndPreview from "../../common/PrintAndPreview";
import { SalesPersonList } from "../../common/salesPerson";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import { routesName } from "../../../../../config/routesName";
import CommonDropdown from "../../../../common/CommonDropdown";
import CommonCalculation from "../../common/CommonCalculation";
import { input_css_style } from "../../../../../utils/Constant";
import CustomDialog from "../../../../custom/dialog/CustomDialog";
import CustomButton from "../../../../custom/button/CustomButton";
import { getBusinessInfo } from "../../../../../config/cookiesInfo";
import CurrencyFormatter from "../../../../common/CurrencyFormatter";
import { TermsAndConditions } from "../../common/TermsAndConditions";
import { LabelWithAsteriskMark } from "../../../../common/CommonLabel";
import CommonPreviewShare from "../../../../common/CommonPreviewShare";
import CommonAsyncDropdown from "../../../../common/CommonAsyncDropdown";
import CommonCurrencySelection from "../../common/CommonCurrencySelection";
import { eventsNames,AnalyticsEvent } from "../../../../../firebase/firebaseAnalytics";
import CustomGroupButton from "../../../../custom/button/CustomGroupButton";
import CustomTypography from "../../../../custom/typography/CustomTypography";


import {
  getNotesPreferences,
  getDiscountPreferences,
  getAmountArePreferences,
  getSalesPersonPreferences,
  getTermsAndConditionPreferences,
} from "../../../setting/settings/Preferences/common/getPreferences";

import {
  CustomContainer,
  CustomTitleContainer,
  CustomButtonContainer,
} from "../../../../custom/container/CustomContainer";

import {
  basePriceTotal,
  checkItemDiscount,
  totalDiscountOnBasePrice,
  isFinalAmountRangeCrossed,
  convertExclusiveToInclusive,
  convertInclusiveToExclusive,
  applyTaxOnMultipleGSTComponents,
} from "../invoices/InvoiceManager";

import {
  setLoader,
  filterTax,
  getAddress,
  filterArray,
  isFormValid,
  isAddressSame,
  findDefaultTax,
  getQueryParams,
  isApplicableForTax,
  stateChangeManager,
  isBusinessRegistered,
  breadCrumbsStateChangeManager,
} from "../../../../../utils/Utils";

import { apiAction, apiFormData } from "../../../../../api/api";

import {
  get_item,
  get_party,
  list_party,
  list_items,
  create_estimate,
  get_list_of_tax,
  update_estimate,
  preview_estimate,
  get_list_of_state,
  retrieve_estimate,
  update_sales_order,
  create_sales_order,
  preview_sales_order,
  retrieve_sales_order,
  get_create_sales_order_meta_data,
} from "../../../../../api/urls";

import {
  Box,
  Grid,
  Checkbox,
  IconButton,
  FormControlLabel,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  CustomTable,
  CustomTableRow,
  CustomTableBody,
  CustomTableHead,
  CustomTableBodyCell,
  CustomTableHeadCell,
  CustomTableContainer,
} from "../../../../custom/table/CustomTable";


// eslint-disable-next-line
const isNumeric = (num) =>
  (typeof num === "number" || (typeof num === "string" && num.trim() !== "")) &&
  !isNaN(num);

const CreateSalesOrder = (props) => {
  const {
    onClose,
    customer,
    isEstimate,
    estimateData,
    setEstimateData,
    estimate_validation_data,
  } = props;

  const navigate = useNavigate();
  let location = useLocation();
  const params = getQueryParams(location.search);
  const [isClear, setClear] = React.useState(false);
  const dispatch = Actions.getDispatch(React.useContext);
  const [isFormSubmitted, setFormSubmitted] = React.useState(false);
  const [state, setState] = React.useState({
    id: "",
    title: "",
    open: false,
    condition: "",
    maxWidth: "lg",
    compulsory: "",
    fullWidth: true,
  });

  const [zero, setZero] = React.useState(false);
  const [saveAndSend, setSaveAndSend] = React.useState(false);

  const [newItem, setNewItem] = React.useState(null);
  const [itemIndex, setItemIndex] = React.useState(null);

  const addButtonHandler = (title, condition, maxWidth, compulsory, index) => {
    setState({
      open: true,
      title: title,
      fullWidth: true,
      maxWidth: maxWidth,
      condition: condition,
      compulsory: compulsory,
    });
    setItemIndex(index);
    if (index!==null || index!==undefined) {
      setSalesOrder({ ...salesOrder });
    }
  };

  const [salesOrder, setSalesOrder] = React.useState({
    customer_emails: {
      cc: [],
      to: [],
      bcc: [],
    },
    tax: null,
    subtotal: 0,
    total_amount: 0,
    discount: 0,
    customer_id: null,
    reference_number: null,
    payment_term: null,
    place_of_supply: null,
    payment_method: null,
    sales_person_id: null,
    payment_status: null,
    payment_term_id: null,
    place_of_supply_id: null,
    sales_order_number: null,
    entity_level_discount: null,
    business_id: getBusinessInfo().id,
    expected_shipment_date: null,
    sales_order_date: moment().format("YYYY-MM-DD"),
    terms_and_condition: null,
    terms_and_conditions_id: getTermsAndConditionPreferences(isEstimate ? 'estimate' : 'sales_order'),
    sales_order_items: [
      {
        item: null,
        tax: null,
        rate: null,
        quantity: 1,
        tax_id: null,
        amount: null,
        item_id: null,
        unit_id: null,
        item_order: null,
        description: null,
        hsn_sac_code: null,
        item_level_discount: 0,
        is_item_discount_in_percentage: true,
      },
    ],
    salesOrder_title: "salesOrder Test",
    salesOrder_sub_heading: "salesOrder Test",

    // Address
    delivery_method: null,
    billing_address: null,
    shipping_address: null,
    same_as_billing_address: false,
    note: getNotesPreferences(isEstimate ? 'estimate' : 'sales_order'),

    newItem: null,
    itemIndex: null,
    newCustomer: null,
    currency_code: null,
    currency_symbol: null,
    is_entity_discount: true,
    tax_applied: getAmountArePreferences(),
    is_amount_tax_inclusive: getAmountArePreferences() === 'inclusive',
  });

  React.useEffect(() => {
    if (params.id) {
      const getSalesOrder = async () => {
        setLoader(dispatch, Actions, true);
        breadCrumbsStateChangeManager(dispatch, 'Edit');
        let res = await apiAction({
          method: "post",
          url: isEstimate
            ? retrieve_estimate(params.id)
            : retrieve_sales_order(params.id),
          data: { business_id: getBusinessInfo().id },
        });

        if (res?.success) {
          const item = res?.result;
          if (isEstimate) {
            item["sales_order_items"] = item.estimate_items;
            item.estimate_items.map((item) => {
              if (item.tax) {
                item["tax_id"] = item.tax.id;
                item["item"] = {
                  name: item.item,
                  id: item.item_id,
                  gst_rate: item.tax.rate ? item.tax.rate : null,
                };
              } else {
                item["tax_id"] = null;
                item["item"] = {
                  name: item.item,
                  id: item.item_id,
                  gst_rate: null,
                };
              }
            });
          } else {
            item.sales_order_items.map((item) => {
              if (item.tax) {
                item["tax_id"] = item.tax.id;
                item["item"] = {
                  name: item.item,
                  id: item.item_id,
                  gst_rate: item.tax.rate ? item.tax.rate : null,
                };
              } else {
                item["tax_id"] = null;
                item["item"] = {
                  name: item.item,
                  id: item.item_id,
                  gst_rate: null,
                };
              }
            });
          }

          setLoader(dispatch, Actions, false);
          const partyRes = await apiAction({
            method: "post",
            url: get_party(item.customer_id),
            data: { business_id: getBusinessInfo().id },
          });

          setSalesOrder({
            ...salesOrder,
            ...item,
            customer: partyRes?.result,

            place_of_supply_id: item.place_of_supply.id,
            place_of_supply: item.place_of_supply.id
              ? { ...item.place_of_supply, name: item.place_of_supply.state }
              : null,

            sales_person_id: item.sales_person,
            sales_person: item.sales_person
              ? { name: item.sales_person_name, id: item.sales_person }
              : null,

            same_as_billing_address: isAddressSame(item.billing_address, item.shipping_address),

            payment_term_id: item.payment_term,
            payment_term: {
              name: item.payment_term_name,
              payment_term_id: item.payment_term,
              id: item.payment_term,
            },

            terms_and_conditions_id: item.terms_and_conditions
              ? item.terms_and_conditions
              : null,
            terms_and_condition: item.terms_and_conditions
              ? {
                id: item.terms_and_conditions,
                name: item.terms_and_conditions_name,
              }
              : null,

            discount: item.discount,
            is_amount_tax_inclusive:
              res?.result.tax_applied === "inclusive" ? true : false,
          });
        }
      };
      setTimeout(() => {
        getSalesOrder();
      }, 1500);
      // eslint-disable-next-line
    }
  }, []);

  React.useEffect(() => {
    if (params.estimate_id) {
      const convertEstimate_SalesOrder = async () => {
        setLoader(dispatch, Actions, true);
        let res = await apiAction({
          method: "post",
          url: retrieve_estimate(params.estimate_id),
          data: { business_id: getBusinessInfo().id },
        });

        if (res?.success) {
          const item = res?.result;
          item["sales_order_items"] = item.estimate_items;
          item.estimate_items.map((item) => {
            if (item.tax) {
              item["tax_id"] = item.tax.id;
              item["item"] = {
                name: item.item,
                id: item.item_id,
                gst_rate: item.tax.rate ? item.tax.rate : null,
              };
            } else {
              item["tax_id"] = null;
              item["item"] = {
                name: item.item,
                id: item.item_id,
                gst_rate: null,
              };
            }
          });
          setLoader(dispatch, Actions, false);
          const partyRes = await apiAction({
            method: "post",
            url: get_party(res?.result.customer_id),
            data: { business_id: getBusinessInfo().id },
          });
          if (partyRes?.success) {
            setSalesOrder({
              ...salesOrder,
              ...item,
              estimate_id: item.id,
              customer: partyRes?.result,

              reference_number: item.estimate_number,
              payment_term: partyRes?.result.payment_term,
              payment_term_id: partyRes?.result.payment_term
                ? partyRes?.result.payment_term.id
                : null,

              place_of_supply_id: item.place_of_supply.id,
              place_of_supply: item.place_of_supply.id
                ? { ...item.place_of_supply, name: item.place_of_supply.state }
                : null,

              sales_person_id: item.sales_person,
              sales_person: item.sales_person
                ? { name: item.sales_person_name, id: item.sales_person }
                : null,

              same_as_billing_address: isAddressSame(
                item.billing_address,
                item.shipping_address
              ),

              note: null,
              attachment: null,
              terms_and_condition: null,
              terms_and_conditions_id: null,

              discount: item.discount,
              is_amount_tax_inclusive:
                res?.result.tax_applied === "inclusive" ? true : false,
            });
          }
        }
      };
      setTimeout(() => {
        convertEstimate_SalesOrder();
      }, 1500);
      // eslint-disable-next-line
    }
  }, []);

  // clone
  React.useEffect(() => {
    if (params.clone_id) {
      const convertClone = async () => {
        setLoader(dispatch, Actions, true);
        let res = await apiAction({
          method: "post",
          url: isEstimate
            ? retrieve_estimate(params.clone_id)
            : retrieve_sales_order(params.clone_id),
          data: { business_id: getBusinessInfo().id },
        });

        if (res?.success) {
          const item = res?.result;
          if (isEstimate) {
            item["sales_order_items"] = item.estimate_items;
            item.estimate_items.map((item) => {
              if (item.tax) {
                item["tax_id"] = item.tax.id;
                item["item"] = {
                  name: item.item,
                  id: item.item_id,
                  gst_rate: item.tax.rate ? item.tax.rate : null,
                };
              } else {
                item["tax_id"] = null;
                item["item"] = {
                  name: item.item,
                  id: item.item_id,
                  gst_rate: null,
                };
              }
            });
          } else {
            item.sales_order_items.map((item) => {
              if (item.tax) {
                item["tax_id"] = item.tax.id;
                item["item"] = {
                  name: item.item,
                  id: item.item_id,
                  gst_rate: item.tax.rate ? item.tax.rate : null,
                };
              } else {
                item["tax_id"] = null;
                item["item"] = {
                  name: item.item,
                  id: item.item_id,
                  gst_rate: null,
                };
              }
            });
          }

          setLoader(dispatch, Actions, false);
          const partyRes = await apiAction({
            method: "post",
            url: get_party(res?.result.customer_id),
            data: { business_id: getBusinessInfo().id },
          });
          if (partyRes?.success) {
            setSalesOrder({
              ...salesOrder,
              ...item,
              customer: partyRes?.result,

              reference_number: item.estimate_number,
              payment_term: partyRes?.result.payment_term,

              estimate_date: moment().format("YYYY-MM-DD"),
              sales_order_date: moment().format("YYYY-MM-DD"),
              estimate_number: estimateData && estimateData.estimate_number,
              sales_order_number: salesOrder && salesOrder.sales_order_number,

              payment_term_id: partyRes?.result.payment_term
                ? partyRes?.result.payment_term.id
                : null,

              place_of_supply_id: item.place_of_supply.id,
              place_of_supply: item.place_of_supply.id
                ? { ...item.place_of_supply, name: item.place_of_supply.state }
                : null,

              sales_person_id: item.sales_person,
              sales_person: item.sales_person
                ? { name: item.sales_person_name, id: item.sales_person }
                : null,

              same_as_billing_address:
                item.billing_address === item.shipping_address,

              terms_and_conditions_id: item.terms_and_conditions
                ? item.terms_and_conditions
                : null,
              terms_and_condition: item.terms_and_conditions
                ? {
                  id: item.terms_and_conditions,
                  name: item.terms_and_conditions_name,
                }
                : null,

              discount: item.discount,
              is_amount_tax_inclusive:
                res?.result.tax_applied === "inclusive" ? true : false,
            });
          }
        }
      };
      setTimeout(() => {
        convertClone();
      }, 1500);
      // eslint-disable-next-line
    }
  }, []);
  // api call to set the default sales_order_number
  React.useEffect(() => {
    const apiResults = async () => {
      let res = await apiAction({
        method: "post",
        url: get_create_sales_order_meta_data(),
        data: { business_id: getBusinessInfo().id },
      });
      if (res?.success) {
        salesOrder.sales_order_number = res?.result.sales_order_number;
        setSalesOrder({
          ...salesOrder,
          sales_order_number: res?.result.sales_order_number,
        });
      } else {
      }
    };
    if (!params.id) {
      apiResults();
    }
    // eslint-disable-next-line
  }, []);

  const onClear = () => {
    setClear(true);
    setSalesOrder({
      customer_emails: {
        cc: [],
        to: [],
        bcc: [],
      },
      tax: null,
      subtotal: 0,
      discount: 0,
      total_amount: 0,
      expiry_date: null,
      customer_id: null,
      estimate_date: null,
      payment_term: null,
      place_of_supply: null,
      payment_method: null,
      reference_number: null,
      sales_person_id: null,
      payment_status: null,
      payment_term_id: null,
      place_of_supply_id: null,
      terms_and_condition: null,
      entity_level_discount: null,
      business_id: getBusinessInfo().id,
      expected_shipment_date: null,
      sales_order_date: moment().format("YYYY-MM-DD"),
      sales_order_number: salesOrder.sales_order_number,
      terms_and_conditions_id: getTermsAndConditionPreferences(isEstimate ? 'estimate' : 'sales_order'),
      sales_order_items: [
        {
          item: null,
          tax: null,
          rate: null,
          quantity: 1,
          tax_id: null,
          amount: null,
          item_id: null,
          unit_id: null,
          item_order: null,
          description: null,
          hsn_sac_code: null,
          item_level_discount: 0,
          is_item_discount_in_percentage: checkItemDiscount(salesOrder?.sales_order_items),
        },
      ],
      salesOrder_title: "salesOrder Test",
      salesOrder_sub_heading: "salesOrder Test",

      // Address
      delivery_method: null,
      billing_address: null,
      shipping_address: null,
      same_as_billing_address: false,
      note: getNotesPreferences(isEstimate ? 'estimate' : 'sales_order'),

      newItem: null,
      itemIndex: null,
      newCustomer: null,
      currency_code: null,
      currency_symbol: null,
      is_entity_discount: true,
      tax_applied: getAmountArePreferences(),
      is_amount_tax_inclusive: getAmountArePreferences() === 'inclusive',
    });
  };
  //////////////////////////////////////////////////////////////////////////////////////////
  const gst_registration_type = isEstimate
    ? estimateData.customer && estimateData.customer.gst_registration_type
    : salesOrder.customer && salesOrder.customer.gst_registration_type;

  let is_Location = gst_registration_type === "overseas";
  let is_gst_with_zero =
    gst_registration_type === "overseas" || gst_registration_type === "sez";
  //////////////////////////////////////////////////////////////////////////////////////////
  const onSave = async ({ is_save_send = false }) => {
    console.log("salesOrder after save====>", salesOrder);

    setFormSubmitted(true);
    const { tax_applied, sales_order_items, customer_emails } = salesOrder;
    const { to } = customer_emails;

    const is_rate = sales_order_items.find((item) => item.rate === null);
    const is_quantity = sales_order_items.find((item) => !item.quantity);
    const is_sales_order_item_not_selected = sales_order_items.find(
      (item) => !item.item_id
    );
    const is_tax = sales_order_items.find(
      (item) => tax_applied !== "no_tax" && !item.tax_id
    );
    // const is_sales_order_item_hsn_sac_empty = sales_order_items.find((item) => !item.hsn_sac_code)

    let validation_data = [
      { key: "customer_id", message: "Please Select Customer" },
      // {
      //   key: "emails",
      //   validation: to.length === 0,
      //   message: "Please enter valid email.",
      // },
      { key: "sales_order_number", message: "Please Enter Sales Order Number" },
      isBusinessRegistered() && !is_Location && {
        key: "place_of_supply_id",
        message: "Please Select Place of Supply",
      },
      { key: "payment_term_id", message: "Please Select Terms" },
      {
        key: "sales_order_date",
        message: "Please Enter Valid Sales Order Date",
      },
      {
        key: "",
        validation: is_sales_order_item_not_selected,
        message: "Please Select Item",
      },
      {
        key: "",
        validation: is_quantity,
        message: "Please Enter the Quantity",
      },
      { key: "", validation: is_rate, message: "Please Enter the Rate" },
      { key: "", validation: is_tax, message: "Please Select the Tax" },
      // { key: "", validation: is_sales_order_item_hsn_sac_empty, message: 'HASN/SAC code in all items required.' },
    ];

    const { isValid, message } = isEstimate
      ? isFormValid(estimateData, estimate_validation_data)
      : isFormValid(salesOrder, validation_data);
    if (isValid) {
      salesOrder.customer_emails.to=filterArray(salesOrder.customer_emails.to)

      if (isApplicableForTax(isEstimate ? estimateData.tax_applied : salesOrder.tax_applied,isBusinessRegistered())) {
        setZero('isApplicableForTax');
        setSaveAndSend(is_save_send);
      } else if (isEstimate ? estimateData.total_amount === 0 : salesOrder.total_amount === 0) {
        setZero('withZero');
        setSaveAndSend(is_save_send);
      } else {
        onSaveAction(is_save_send);
      }
    } else {
      setLoader(dispatch, Actions, false);
      stateChangeManager(dispatch, Actions, true, "error", message);
    }
  };

  const onSaveAction = async (is_save_send) => {
    setLoader(dispatch, Actions, true);
    const actionType = params.id ? eventsNames.actions.EDIT : eventsNames.actions.CREATE;
    if (isEstimate) {
      AnalyticsEvent(eventsNames.categories.ESTIMATES,{action:actionType});
    } else {
      AnalyticsEvent(eventsNames.categories.SALES_ORDERS,{action:actionType});
    }
    let res = await apiFormData({
      method: "post",
      data: isEstimate ? estimateData : salesOrder,
      url: isEstimate
        ? params.id
          ? update_estimate(params.id)
          : create_estimate()
        : params.id
          ? update_sales_order(params.id)
          : create_sales_order(),
    });
    if (res?.success) {
      setLoader(dispatch, Actions, false);
      stateChangeManager(dispatch, Actions, true, "success", res?.status);
      if (is_save_send) {
        if (isEstimate) {
          sendEstimate(res?.result.id);
        } else {
          sendSalesOrder(res?.result.id);
        }
      } else {
        redirect(res?.result.id);
        setLoader(dispatch, Actions, false);
      }
    } else {
      setLoader(dispatch, Actions, false);
      stateChangeManager(dispatch, Actions, true, "error", res?.status);
    }
  };

  const redirect = (id) => {
    if (onClose) {
      onClose(id);
    } else {
      navigate(routesName.invoicingSalesOrderView.path + "?id=" + id);
    }
  };

  const sendSalesOrder = async (salesOrder_id) => {
    setSendMail(true);
    setSendMailId(salesOrder_id);
    // let body = { business_id: getBusinessInfo().id, sales_order_id: salesOrder_id }
    // const sendSalesOrderRes = await apiAction({ url: send_sales_order_mail(), method: 'POST', data: body })
    // if (sendSalesOrderRes.success) {
    //     stateChangeManager(dispatch, Actions, true, 'success', sendSalesOrderRes.status);
    //     redirect()
    // }
  };

  const sendEstimate = async (estimate_id) => {
    setSendMail(true);
    setSendMailId(estimate_id);
    // let body = { business_id: getBusinessInfo().id, estimate_id: estimate_id }
    // const sendEstimateRes = await apiAction({ url: send_estimate_mail(), method: 'POST', data: body })
    // if (sendEstimateRes.success) {
    //     stateChangeManager(dispatch, Actions, true, 'success', sendEstimateRes.status);
    //     redirect()
    // }
  };

  const onAddCustomer = (customer) => {
    setSalesOrder({ ...salesOrder, newCustomer: customer });
  };

  const onAddItem = (value) => {
    // Construct the new item data
    const newItemData = {
      item: value,
      quantity: 1,
      item_order: null,
      item_id: value.id,
      unit_id: value?.unit?.id,
      amount: value.selling_price,
      description: value.description,
      hsn_sac_code: value.hsn_sac_code,
      rate: parseFloat(value.selling_price),
      item_level_discount: value.item_level_discount || 0,
      is_item_discount_in_percentage: checkItemDiscount(salesOrder?.sales_order_items),

      gst_rate: value?.tax?.rate,
      tax: salesOrder.tax_applied === "no_tax" ? null: value?.tax,
      tax_id: salesOrder.tax_applied === "no_tax" ? null : value?.tax?.id,
    };
  
    // Update the sales order items
    salesOrder.sales_order_items[itemIndex] = newItemData;
  
    // Update the sales order state
    setSalesOrder(prevOrder => ({
      ...prevOrder,
      newItem: value,
      sales_order_items: [...prevOrder.sales_order_items],
    }));
  
    // Update the new item state
    setNewItem(value);
  };
  

  React.useEffect(() => {
    if (isEstimate) {
      setEstimateData({
        ...estimateData,
        ...salesOrder,
        estimate_items: salesOrder.sales_order_items,
      });
    }
  }, [salesOrder]);

  const [sendMail, setSendMail] = React.useState(false);
  const [sendMailId, setSendMailId] = React.useState(false);
  return (
    <div>
      <CustomDialog
        state={state}
        setState={setState}
        itemIndex={salesOrder.itemIndex}
        onAddItem={(item) => onAddItem(item)}
        onAddCustomer={(customer) => onAddCustomer(customer)}
      />
      <CommonPreviewShare
        open={sendMail}
        id={sendMailId}
        type={isEstimate ? "estimate" : "sales_order"}
        setOpen={(value) => {
          setSendMail(value);
          redirect(sendMailId);
        }}
        file_key={isEstimate ? "estimate_number" : "sales_order_number"}
        export_Url={isEstimate ? preview_estimate : preview_sales_order}
        retrieve_Url={isEstimate ? retrieve_estimate : retrieve_sales_order}
      />
      <CommonAlertBox
        open={zero}
        setOpen={setZero}
        onSaveAction={() => {
          if (zero==='withZero') {
            onSaveAction(saveAndSend);
          }else if (isEstimate ? estimateData.total_amount === 0 : salesOrder.total_amount === 0) {
            setTimeout(()=>{
              setZero('withZero');
            },100)
          } else{
            onSaveAction(saveAndSend);
          }
        }}
        title={ isEstimate   ? `Estimate No. ${estimateData.estimate_number ? estimateData.estimate_number : ""   }`   : `Sales Order No. ${salesOrder.sales_order_number     ? salesOrder.sales_order_number     : ""   }`
        }
        message={
          zero==='withZero'?
          isEstimate
            ? `You are about to create the estimate with zero amount. Are you sure you want to proceed?`
            : `You are about to create the sales order with zero amount. Are you sure you want to proceed?`
            :
          `Please consult the relevant provisions of the laws to determine the application of taxes for GST unregistered entities.`

        }
      />
      <CustomTitleContainer>
        <Grid
          container
          spacing={0}
          style={{
            alignItems: "center",
            justifyItems: "center",
            alignContent: "space-evenly",
            justifyContent: "space-evenly",
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: "-webkit-flex", justifyContent: "start" }}
          >
            {isEstimate ? (
              <CustomTitle
                title={`Estimate No. ${estimateData.estimate_number
                  ? estimateData.estimate_number
                  : ""
                  }`}
              />
            ) : (
              <CustomTitle
                title={`Sales Order No. ${salesOrder.sales_order_number
                  ? salesOrder.sales_order_number
                  : ""
                  }`}
              />
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: "-webkit-flex", justifyContent: "end" }}
          >
            <CustomTypography
              text={
                <span>
                  Balance Due:{" "}
                  <span style={{ fontWeight: "700", color: "#000000" }}>
                    <CurrencyFormatter
                      amount={salesOrder.total_amount}
                      currency={salesOrder.currency_code}
                    />
                  </span>
                </span>
              }
              sx={{
                fontSize: "16px",
                color: "#7A7A7A",
                fontWeight: "600",
                textAlign: "right",
                lineHeight: "22px",
                fontStyle: "normal",
                fontFamily: "Noto Sans",
              }}
            />
          </Grid>
        </Grid>
      </CustomTitleContainer>

      <CustomContainer maxWidth={"400"} sx={{}}>
        <form noValidate>
          <div style={{ borderBottom: "4px solid #F5F5F5" }}>
            <CustomDetails
              isClear={isClear}
              open={state.open}
              isEstimate={isEstimate}
              salesOrder={salesOrder}
              is_Location={is_Location}
              estimateData={estimateData}
              setSalesOrder={setSalesOrder}
              isFormSubmitted={isFormSubmitted}
              setEstimateData={setEstimateData}
              is_gst_with_zero={is_gst_with_zero}
              addButtonHandler={addButtonHandler}
              customer={customer ? customer : null}
            />
          </div>
          <ProductAndServicesDetails
            isClear={isClear}
            open={state.open}
            newItem={newItem}
            itemIndex={itemIndex}
            salesOrder={salesOrder}
            is_Location={is_Location}
            setSalesOrder={setSalesOrder}
            isFormSubmitted={isFormSubmitted}
            addButtonHandler={addButtonHandler}
            is_gst_with_zero={is_gst_with_zero}
          />
        </form>
      </CustomContainer>

      <CustomButtonContainer>
        <Buttons
          onClear={onClear}
          onSave={onSave}
          isClear={isClear}
          isEstimate={isEstimate}
          salesOrder={salesOrder}
          is_Location={is_Location}
          estimateData={estimateData}
          onCancel={() => navigate(-1)}
          setFormSubmitted={setFormSubmitted}
          is_gst_with_zero={is_gst_with_zero}
          estimate_validation_data={estimate_validation_data}
        />
      </CustomButtonContainer>
    </div>
  );
};

const CustomDetails = (props) => {
  const {
    is_gst_with_zero,
    is_Location,
    isEstimate,
    estimateData,
    setEstimateData,
    open,
    salesOrder,
    setSalesOrder,
    addButtonHandler,
    isFormSubmitted,
    isClear,
    isFromCustomer,
    customer,
  } = props;
  const { customer_emails } = salesOrder;

  let location = useLocation();
  const params = getQueryParams(location.search);

  const onChange = (key_name, value) => {
    setSalesOrder({ ...salesOrder, [key_name]: value });
  };

  React.useEffect(() => {
    if (isClear && Object.keys(params).length === 0) {
      // setCustomer(null)
      setSalesOrder({ ...salesOrder, customer: null });
    }
    // eslint-disable-next-line
  }, [isClear]);

  React.useEffect(() => {
    if (salesOrder.same_as_billing_address) {
      setSalesOrder({
        ...salesOrder,
        shipping_address: salesOrder.billing_address,
      });
    } else {
      setSalesOrder({
        ...salesOrder,
        shipping_address: salesOrder.shipping_address,
      });
    }
    // eslint-disable-next-line
  }, [salesOrder.same_as_billing_address, salesOrder.billing_address]);

  const onFetchTerms = (term) => {
    if (term && Object.keys(params).length === 0 && (!isFromCustomer || !customer.payment_term)) {
      setTimeout(() => {
        setSalesOrder((previous) => ({
          ...previous,
          payment_term: term,
          payment_term_id: term.id,
          // due_date: moment().add(term.number_of_days, 'days').format('YYYY-MM-DD')
        }));
      }, 1000);
    }
  };

  React.useEffect(() => {
    if (salesOrder.newCustomer) {
      // setCustomer(invoice.newCustomer)
      customerMappings(salesOrder.newCustomer);
    }
    // eslint-disable-next-line
  }, [salesOrder.newCustomer]);

  const setEmails = (emails) => {
    setSalesOrder({
      ...salesOrder,
      customer_emails: { ...customer_emails, cc: emails.cc, bcc: emails.bcc },
    });
  };

  const customerMappings = (customer) => {
    if (customer) {
      let party_contact_data = customer.party_contact_data.find(
        (data) => data.contact_type === "primary"
      );

      setSalesOrder({
        ...salesOrder,
        customer: customer,
        customer_emails: { ...salesOrder.customer_emails, to:party_contact_data.email? filterArray([party_contact_data.email]):[]},
        customer_id: customer.id,
        billing_address: customer.billing_address ? getAddress(customer.billing_address) : "", shipping_address: customer.shipping_address ? getAddress(customer.shipping_address) : "",
        payment_term: customer.payment_term ? customer.payment_term : salesOrder.payment_term,
        payment_term_id: customer.payment_term ? customer.payment_term.id : salesOrder.payment_term ? salesOrder.payment_term.id : null,
        due_date: customer.payment_term ? moment().add(customer.payment_term.number_of_days, "days").format("YYYY-MM-DD") : salesOrder.due_date,
        payment_method: customer.preferred_payment_method ? customer.preferred_payment_method : "cash",
        same_as_billing_address: isAddressSame(customer.billing_address ? getAddress(customer.billing_address) : null, customer.shipping_address ? getAddress(customer.billing_address) : null),

        place_of_supply: null,
        place_of_supply_id: null,

        currency_id: customer.currency ? customer.currency.id : null,
        currency_symbol: customer.currency ? customer.currency.symbol : null,
        currency_code: customer.currency ? customer.currency.currency_code : null,
      });
    } else {
      setSalesOrder({
        ...salesOrder,
        customer: null,
        customer_id: null,
        payment_term: null,
        currency_code: null,
        billing_address: null,
        currency_symbol: null,
        delivery_method: null,
        payment_term_id: null,
        payment_method: "cash",
        shipping_address: null,
        due_date: salesOrder.due_date,
        customer_emails: { cc: [], to: [], bcc: [] },
      });
    }
  };

  const [clickedOutside, setClickedOutside] = React.useState(true);
  const myRef = React.useRef();

  const handleClickOutside = (e) => {
    if (!myRef.current.contains(e.target)) {
      setClickedOutside(true);
    }
  };

  const handleClickInside = () => setClickedOutside(false);

  React.useEffect(() => {
    document?.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  const [isSalesPersonField, setIsSalesPersonField] = React.useState(getSalesPersonPreferences());
  React.useEffect(() => {
    if (salesOrder.sales_person) {
      setIsSalesPersonField(getSalesPersonPreferences() || salesOrder.sales_person ? true : false)
    }
  }, [salesOrder.sales_person]);

  return (
    <Box
      style={{
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingBottom: "32px",
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4.5}>
          <CommonAsyncDropdown
            id={"customer_dropdown"}
            dataTestId={"customer_dropdown"}
            autoSelect={false}
            autoFocus={true}
            disableClearable={false}
            optionLabel="display_name"
            placeholder="Search Customer"
            noOptionsText={"No result found"}
            validation={isFormSubmitted && !salesOrder.customer_id}
            item={{
              open: open,
              method: "post",
              url: list_party(1),
              value: salesOrder.customer,
              label: <LabelWithAsteriskMark label={"Customer"} />,
              body: {
                is_inactive: false,
                business_id: getBusinessInfo().id,
                role: "customer",
              },
              disabled: !params.clone_id && Object.keys(params).length !== 0,
              onChange: async (event, newValue) => {
                if (newValue) {
                  if (newValue.id !== salesOrder.customer_id) {
                    const partyRes = await apiAction({
                      method: "post",
                      url: get_party(newValue.id),
                      data: { business_id: getBusinessInfo().id },
                    });
                    if (partyRes?.success) {
                      customerMappings(partyRes?.result);
                    }
                  }
                } else {
                  customerMappings(newValue);
                }
              },
            }}
            addButton={{
              title: "+ Add new customer",
              onClick: () =>
                addButtonHandler("New Customer", "new_customer", "lg"),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4.5}>
          <Grid item xs={12} container>
            <Grid item xs={6}>
              <CustomTypography
                text={'Customer Email'}
                // text={<LabelWithAsteriskMark label={"Customer Email"} />}
                sx={{
                  mb: 1,
                  fontSize: "14px",
                  color: "#000000",
                  fontWeight: "700",
                  lineHeight: "18px",
                  fontStyle: "normal",
                  textTransform: "none",
                  fontFamily: "Noto Sans",
                  textTransform: "capitalize",
                }}
              />
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "right" }}>
              <Emails
                value={salesOrder}
                setValue={setSalesOrder}
                customer_emails={customer_emails}
                setEmail={setEmails}
              />
            </Grid>
          </Grid>
          <div onClick={handleClickInside} ref={myRef}>
            <ReactMultiEmail
              id={"input_to_email"}
              dataTestId={"input_to_email"}
              emails={filterArray(salesOrder.customer_emails.to)}
              placeholder={
                <p
                  style={{
                    margin: "0px",
                    marginTop: "-8px",
                    position: "sticky",
                  }}
                >
                  <span
                    style={{
                      margin: 0,
                      height: "100%",
                      overflow: "hidden",
                      color: "#2F2F2E",
                      fontWeight: "400",
                      lineHeight: "0px",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontFamily: "Noto Sans",
                      opacity: 0.5,
                    }}
                  >
                    Enter Email Address
                  </span>
                </p>
              }
              onChange={(emails) => {
                setSalesOrder({
                  ...salesOrder,
                  customer_emails: { ...customer_emails, to: emails },
                });
              }}
              style={{
                marginTop: "0px",
                textAlign: "center",
                borderRadius: "4px",
                overflowY: "scroll",
                maxHeight: 20,
                borderColor: clickedOutside
                  ?
                  // isFormSubmitted &&
                  // salesOrder.customer_emails.to.length === 0
                  // ? "#d32f2f": 
                  '#c7c7c7'
                  : "#2464EB",
                borderWidth: clickedOutside ? 1.5 : 2,
              }}
              getLabel={(email, index, removeEmail) => {
                return (
                  <div data-tag key={index} style={{ ...input_css_style }}>
                    {email}
                    <span data-tag-handle onClick={() => removeEmail(index)}>
                      ×
                    </span>
                  </div>
                );
              }}
              validateEmail={(email) => {
                return email?isEmail(email):false; // return boolean
              }}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Input
            id={"input_order_number"}
            dataTestId={"input_order_number"}
            item={{
              type: "text",
              required: true,
              fullWidth: true,
              texttransform: "none",
              inputProps: { maxLength: 16 },
              placeholder: isEstimate
                ? "Estimate Number"
                : "Enter Sales Order Number",
              title: (
                <LabelWithAsteriskMark
                  label={isEstimate ? "Estimate Number" : "Sales Order Number"}
                />
              ),
              onChange: (e) =>
                isEstimate
                  ? onChange("estimate_number", e.target.value)
                  : onChange("sales_order_number", e.target.value),
              validation: isEstimate
                ? isFormSubmitted && !estimateData.estimate_number
                : isFormSubmitted && !salesOrder.sales_order_number,
              value: isEstimate
                ? estimateData.estimate_number
                  ? estimateData.estimate_number
                  : ""
                : salesOrder.sales_order_number
                  ? salesOrder.sales_order_number
                  : "",
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} sx={{ pt: 4 }}>
        <Grid container item xs={12} sm={9} spacing={3}>
          {/*//////////////////////////////// Place of supply ////////////////////////////////*/}
          <Grid item xs={12} sm={isEstimate ? 6 : 3}>
            <CommonDropdown
              stateComponent={true}
              disableClearable={false}
              placeholder="Select a Location"
              id={"place_of_supply_dropdown"}
              dataTestId={"place_of_supply_dropdown"}
              validation={
                isBusinessRegistered() &&
                !is_Location &&
                isFormSubmitted &&
                !salesOrder.place_of_supply_id
              }
              item={{
                method: "post",
                textTransform: "none",
                disabled: is_Location,
                url: get_list_of_state(),
                value: salesOrder.place_of_supply,
                body: { business_id: getBusinessInfo().id },
                label: <LabelWithAsteriskMark label={"Place of Supply"} isAsteriskMark={isBusinessRegistered()} />,
                onChange: (event, value) => {
                  if (value) {
                    setSalesOrder({
                      ...salesOrder,
                      place_of_supply: value,
                      place_of_supply_id: value.id,
                    });
                  } else {
                    setSalesOrder({
                      ...salesOrder,
                      place_of_supply: null,
                      place_of_supply_id: null,
                    });
                  }
                },
              }}
            />
          </Grid>

          {/*////////////////////////////////Payment  Terms ////////////////////////////////*/}
          {isEstimate ? null : (
            <Grid item xs={12} sm={3}>
              <ListTerms
                id={"payment_term_dropdown"}
                dataTestId={"payment_term_dropdown"}
                isClear={isClear}
                setTerms={(key_name, value, item) => {
                  if (value) {
                    setSalesOrder({
                      ...salesOrder,
                      [key_name]: value,
                      payment_term: item,
                      //  expected_shipment_date: moment(salesOrder.sales_order_date).add(item.number_of_days, 'days').format('YYYY-MM-DD')
                    });
                  } else {
                    setSalesOrder({
                      ...salesOrder,
                      [key_name]: value,
                      payment_term: item,
                      // expected_shipment_date: salesOrder.sales_order_date
                    });
                  }
                }}
                onFetchTerms={onFetchTerms}
                validation={isFormSubmitted}
                value={salesOrder.payment_term}
                defaultTerm={salesOrder.payment_term}
                title={<LabelWithAsteriskMark label={"Payment Terms"} />}
              />
            </Grid>
          )}

          {/*//////////////////////////////// Sales Order Date ////////////////////////////////*/}
          <Grid item xs={12} sm={3}>
            <DatePicker
              id={"date_select_1"}
              dataTestId={"date_select_1"}
              placeholder=""
              title={
                <LabelWithAsteriskMark
                  label={isEstimate ? "Estimate Date" : "Sales Order Date"}
                />
              }
              validation={
                isEstimate
                  ? isFormSubmitted && !estimateData.estimate_date
                  : isFormSubmitted && !salesOrder.sales_order_date
              }
              date={
                isEstimate
                  ? estimateData.estimate_date
                    ? moment(estimateData.estimate_date)
                    : null
                  : salesOrder.sales_order_date
                    ? moment(salesOrder.sales_order_date)
                    : null
              }
              setDate={(date) => {
                isEstimate
                  ? onChange(
                    "estimate_date",
                    date ? date.format("YYYY-MM-DD") : null
                  )
                  : onChange(
                    "sales_order_date",
                    date ? date.format("YYYY-MM-DD") : null
                  );
              }}
              maxDate={
                isEstimate
                  ? estimateData.expiry_date
                    ? moment(estimateData.expiry_date)
                    : null
                  : salesOrder.expected_shipment_date
                    ? moment(salesOrder.expected_shipment_date)
                    : null
              }
            />
          </Grid>

          {/*//////////////////////////////// Expected Shipment Date ////////////////////////////////*/}
          <Grid item xs={12} sm={3}>
            <DatePicker
              id={"date_select_2"}
              dataTestId={"date_select_2"}
              placeholder=""
              title={isEstimate ? "Expiry Date" : "Expected Shipment Date"}
              minDate={
                isEstimate
                  ? estimateData.estimate_date
                    ? moment(estimateData.estimate_date)
                    : null
                  : salesOrder.sales_order_date
                    ? moment(salesOrder.sales_order_date)
                    : null
              }
              date={
                isEstimate
                  ? estimateData.expiry_date
                    ? moment(estimateData.expiry_date)
                    : null
                  : salesOrder.expected_shipment_date
                    ? moment(salesOrder.expected_shipment_date)
                    : null
              }
              setDate={(date) => {
                isEstimate
                  ? onChange(
                    "expiry_date",
                    date ? date.format("YYYY-MM-DD") : null
                  )
                  : onChange(
                    "expected_shipment_date",
                    date ? date.format("YYYY-MM-DD") : null
                  );
              }}
            />
          </Grid>
        </Grid>
        {/*//////////////////////////////// Reference  Number ///////////////////////////////////////*/}
        <Grid item xs={12} sm={3}>
          <Input
            id={"input_reference_number"}
            dataTestId={"input_reference_number"}
            item={{
              type: "text",
              required: true,
              fullWidth: true,
              texttransform: "none",
              title: "Reference  Number",
              placeholder: "Enter Reference Number",
              value: salesOrder.reference_number
                ? salesOrder.reference_number
                : "",
              onChange: (e) => onChange("reference_number", e.target.value),
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} sx={{ pt: 4 }}>
        <Grid container item xs={12} sm={9} spacing={3}>
          {/*//////////////////////////////// Billing address ////////////////////////////////*/}
          <Grid item xs={12} sm={6}>
            <Input
              id={"input_billing_address"}
              dataTestId={"input_billing_address"}
              item={{
                rows: 4,
                type: "text",
                required: true,
                multiline: true,
                fullWidth: true,
                title: "Billing Address",
                placeholder: "Enter Billing Address",
                // validation: isFormSubmitted && !salesOrder.billing_address,
                onChange: (e) => onChange("billing_address", e.target.value),
                value: salesOrder.billing_address
                  ? salesOrder.billing_address
                  : "",
              }}
            />
          </Grid>

          {/*//////////////////////////////// Shipping address ////////////////////////////////*/}
          <Grid item xs={12} sm={6}>
            <Grid container item xs={12} sm={12}>
              <Grid item xs={5} sm={6}>
                <CustomTypography
                  text={"Shipping Address"}
                  sx={{
                    fontSize: "14px",
                    color: "#000000",
                    fontWeight: "700",
                    lineHeight: "18px",
                    fontStyle: "normal",
                    textTransform: "none",
                    fontFamily: "Noto Sans",
                    textTransform: "capitalize",
                  }}
                />
              </Grid>
              <Grid item xs={7} sm={6} sx={{ mt: -3, textAlign: "right" }}>
                <FormControlLabel
                  id={"same_as_billing_address_checkbox"}
                  dataTestId={"same_as_billing_address_checkbox"}
                  sx={{ mb: -5, textAlign: "right" }}
                  control={
                    <Checkbox
                      size="small"
                      checked={salesOrder.same_as_billing_address}
                      onClick={() =>
                        onChange(
                          "same_as_billing_address",
                          !salesOrder.same_as_billing_address
                        )
                      }
                      sx={{ color: "#9b9898" }}
                    />
                  }
                  label={
                    <span
                      style={{
                        color: "#000000",
                        fontFamily: "Noto Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "16px",
                        fontSize: "12px",
                      }}
                    >
                      Same as Billing Address
                    </span>
                  }
                />
              </Grid>
            </Grid>
            <Input
              id={"input_shipping_address"}
              dataTestId={"input_shipping_address"}
              item={{
                rows: 4,
                title: "",
                type: "text",
                required: true,
                multiline: true,
                fullWidth: true,
                placeholder: "Enter Shipping Address",
                disabled: salesOrder.same_as_billing_address,
                // validation: isFormSubmitted && !salesOrder.shipping_address,
                onChange: (e) => onChange("shipping_address", e.target.value),
                value: salesOrder.shipping_address
                  ? salesOrder.shipping_address
                  : "",
              }}
            />
          </Grid>
          {/* </Grid> */}
        </Grid>
        {/*//////////////////////////////// Sales person ///////////////////////////////////////*/}
        {isSalesPersonField &&
          <Grid item xs={12} sm={3}>
            <SalesPersonList
              isClear={isClear}
              title="Sales Person"
              onAddItem={(item) => {
                onChange("sales_person_id", item ? item.id : null);
              }}
              onChange={(value) => {
                setSalesOrder({
                  ...salesOrder,
                  sales_person_id: value ? value.id : null,
                  sales_person_name: value ? value.name : null,
                });
              }}
              defaultValue={
                salesOrder.sales_person_name
                  ? {
                    name: salesOrder.sales_person_name,
                    sales_person_id: salesOrder.sales_person,
                    id: salesOrder.sales_person,
                  }
                  : null
              }
            />
          </Grid>
        }
      </Grid>

      {isEstimate ? null : (
        <Grid container spacing={3} sx={{ pt: 4 }}>
          <Grid container item xs={12} sm={9} spacing={3}>
            <Grid item xs={12} sm={6}>
              <Input
                id={"input_delivery_method"}
                dataTestId={"input_delivery_method"}
                item={{
                  type: "text",
                  texttransform: "none",
                  title: "Delivery Method",
                  placeholder: "Enter Delivery Method",
                  fullWidth: true,
                  required: true,
                  value: salesOrder.delivery_method
                    ? salesOrder.delivery_method
                    : "",
                  onChange: (e) => onChange("delivery_method", e.target.value),
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

const ProductAndServicesDetails = (props) => {
  const {
    is_gst_with_zero,
    open,
    salesOrder,
    setSalesOrder,
    addButtonHandler,
    isClear,
    isFormSubmitted,
  } = props;
  const [data, setData] = React.useState(salesOrder.sales_order_items);

  const onAddLine = () => {
    salesOrder.sales_order_items.push({
      item: null,
      tax: null,
      rate: null,
      quantity: 1,
      tax_id: null,
      amount: null,
      item_id: null,
      unit_id: null,
      item_order: null,
      hsn_sac_code: null,
      item_level_discount: 0,
      is_item_discount_in_percentage: checkItemDiscount(salesOrder?.sales_order_items),
    });

    setData([...salesOrder.sales_order_items]);
  };

  const onDeleteLine = (index) => {
    if (salesOrder.sales_order_items.length > 1) {
      salesOrder.sales_order_items.splice(index, 1);
      setData([...salesOrder.sales_order_items]);
      setSalesOrder({
        ...salesOrder,
        sales_order_items: salesOrder.sales_order_items,
      });
    } else {
      setData([
        {
          item: null,
          tax: null,
          rate: null,
          quantity: 1,
          tax_id: null,
          amount: null,
          item_id: null,
          unit_id: null,
          item_order: null,
          description: null,
          hsn_sac_code: null,
          item_level_discount: 0,
          is_item_discount_in_percentage: checkItemDiscount(salesOrder?.sales_order_items),
        },
      ]);

      setSalesOrder({
        ...salesOrder,
        sales_order_items: [
          {
            item: null,
            tax: null,
            rate: null,
            quantity: 1,
            tax_id: null,
            amount: null,
            item_id: null,
            unit_id: null,
            item_order: null,
            description: null,
            hsn_sac_code: null,
            item_level_discount: 0,
            is_item_discount_in_percentage: checkItemDiscount(salesOrder?.sales_order_items),
          },
        ],
      });
    }
  };

  const onClearLines = () => {
    setData([
      {
        item: null,
        tax: null,
        rate: null,
        quantity: 1,
        tax_id: null,
        amount: null,
        item_id: null,
        unit_id: null,
        item_order: null,
        description: null,
        hsn_sac_code: null,
        item_level_discount: 0,
        is_item_discount_in_percentage: checkItemDiscount(salesOrder?.sales_order_items),
      },
    ]);

    setSalesOrder({
      ...salesOrder,
      sales_order_items: [
        {
          item: null,
          tax: null,
          rate: null,
          quantity: 1,
          tax_id: null,
          amount: null,
          item_id: null,
          unit_id: null,
          item_order: null,
          description: null,
          hsn_sac_code: null,
          item_level_discount: 0,
          is_item_discount_in_percentage: checkItemDiscount(salesOrder?.sales_order_items),
        },
      ],
    });
  };

  const accounts = applyTaxOnMultipleGSTComponents(
    salesOrder,
    "sales_order_items",
    ""
  );

  const totalAmount = basePriceTotal(
    salesOrder,
    salesOrder.sales_order_items
  ).toFloat();

  const totalTax = Object.values(accounts)
    .reduce((acc, tax) => acc + tax, 0)
    .toFloat();

  const totalDiscount = totalDiscountOnBasePrice(
    salesOrder,
    salesOrder.sales_order_items
  ).toFloat();

  const subTotal = salesOrder.is_amount_tax_inclusive
    ? salesOrder.sales_order_items
      .filter((item) => isNumeric(item.amount))
      .reduce((a, b) => a + parseFloat(b.amount), 0)
      .toFloat()
    : totalAmount.toFloat();

  const finalPayment =
    totalAmount.toFloat() - totalDiscount.toFloat() + totalTax.toFloat();

  salesOrder.tax = {
    ...accounts,
    total: parseFloat(parseFloat(totalTax).toFixed(2)),
  };
  salesOrder.total_amount = (totalAmount + totalTax - totalDiscount).toFloat();
  salesOrder.discount = totalDiscount.toFloat();
  salesOrder.subtotal = totalAmount.toFloat();

  React.useEffect(() => {
    setSalesOrder({
      ...salesOrder,
      sales_order_items: data,
      total_amount: (totalAmount + totalTax - totalDiscount).toFloat(),
      discount: totalDiscount.toFloat(),
      subtotal: totalAmount.toFloat(),
    });
    // eslint-disable-next-line
  }, [data]);

  if (!("IGST" in salesOrder.tax)) {
    salesOrder.tax["IGST"] = 0;
  }
  if (!("SGST" in salesOrder.tax)) {
    salesOrder.tax["SGST"] = 0;
  }
  if (!("CGST" in salesOrder.tax)) {
    salesOrder.tax["CGST"] = 0;
  }

  const filterList = [
    { name: "Exclusive", value: "exclusive" },
    { name: "Inclusive", value: "inclusive" },
    { name: "No Tax", value: "no_tax" },
  ];

  const onAmountsAreInChange = (selected_type) => {
    let previous = salesOrder.tax_applied;
    if (previous !== selected_type.value) {
      if (selected_type.value === "inclusive") {
        salesOrder.is_amount_tax_inclusive = true;
        convertExclusiveToInclusive(salesOrder.sales_order_items);
        salesOrder.total_amount = totalAmount - totalDiscount;
      } else if (selected_type.value === "no_tax") {
        if (previous === filterList[1].value) {
          convertInclusiveToExclusive(salesOrder.sales_order_items);
        }
        // eslint-disable-next-line
        salesOrder.sales_order_items.map((sales_item) => {
          sales_item.tax = null;
          sales_item.tax_id = null;
        });
        salesOrder.is_amount_tax_inclusive = false;
        salesOrder.total_amount = totalAmount - totalDiscount;
      } else {
        salesOrder.is_amount_tax_inclusive = false;
        convertInclusiveToExclusive(salesOrder.sales_order_items);
      }
      setSalesOrder({ ...salesOrder, tax_applied: selected_type.value });
      setData([...salesOrder.sales_order_items]);
    }
  };

  const [isDiscountFiled, setIsDiscountFiled] = React.useState(getDiscountPreferences());
  React.useEffect(() => {
    if (salesOrder.discount) {
      setIsDiscountFiled(getDiscountPreferences() || (salesOrder.discount ? true : false))
    }
  }, [salesOrder.discount]);

  return (
    <div>
      <Box
        style={{ paddingRight: "20px", }}
        sx={{ pb: 2, pt: 2, display: { sx: 'block', sm: 'flex' }, justifyContent: "flex-end" }}
      >
        <CommonCurrencySelection
          data={salesOrder}
          isCurrency={true}
          isAmountAre={true}
          setData={setSalesOrder}
          dropdown_key='customer_id'
          isFormSubmitted={isFormSubmitted}
          onAmountsAreInChange={onAmountsAreInChange}
        />
      </Box>

      <Box sx={{ mb: 2 }}>
        <CustomTableContainer sx={{ overflowX: 'hidden' }} className="custom_table_css">
          <CustomTable>
            <ProductAndServicesDetailsHeaders isDiscountFiled={isDiscountFiled} data={salesOrder} setData={setSalesOrder} />
            <ProductAndServicesDetailsBody
              isDiscountFiled={isDiscountFiled}
              open={open}
              setData={setData}
              salesOrder={salesOrder}
              onDeleteLine={onDeleteLine}
              onClearLines={onClearLines}
              finalPayment={finalPayment}
              setSalesOrder={setSalesOrder}
              isFormSubmitted={isFormSubmitted}
              data={salesOrder.sales_order_items}
              addButtonHandler={addButtonHandler}
              is_gst_with_zero={is_gst_with_zero}
            />
          </CustomTable>
        </CustomTableContainer>
      </Box>

      <Grid
        container
        style={{
          paddingBottom: "24px",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
      >
        <Grid item xs={12} sm={6}>
          <Grid item xs={12}>
            <CustomTypography
              id={"add_action"}
              dataTestId={"add_action"}
              text={"+ Add Line"}
              onClick={onAddLine}
              sx={{
                fontSize: "12px",
                color: "#2464EB",
                cursor: "pointer",
                display: "inline",
                fontWeight: "700",
                lineHeight: "16px",
                fontStyle: "normal",
                textTransform: "none",
                fontFamily: "Noto Sans",
                ":hover": { textDecoration: "underline" },
              }}
            />
            <CustomTypography
              text={"|"}
              sx={{
                mr: 1,
                ml: 1,
                fontSize: "12px",
                color: "#B8B8B8",
                fontWeight: "700",
                lineHeight: "16px",
                fontStyle: "normal",
                display: "inline",
                textTransform: "none",
                fontFamily: "Noto Sans",
              }}
            />
            <CustomTypography
              id={"clear_action"}
              dataTestId={"clear_action"}
              text={"Clear All Lines"}
              onClick={onClearLines}
              sx={{
                fontSize: "12px",
                color: "#2464EB",
                cursor: "pointer",
                display: "inline",
                fontWeight: "700",
                lineHeight: "16px",
                fontStyle: "normal",
                textTransform: "none",
                fontFamily: "Noto Sans",
                ":hover": { textDecoration: "underline" },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={9} sx={{ mt: 3 }}>
            <Input
              id={"input_note"}
              dataTestId={"input_note"}
              item={{
                rows: 4,
                type: "text",
                title: "Notes",
                required: true,
                multiline: true,
                fullWidth: true,
                placeholder: "Notes",
                texttransform: "none",
                value: salesOrder.note ? salesOrder.note : "",
                onChange: (event) => {
                  setSalesOrder({ ...salesOrder, note: event.target.value });
                },
              }}
            />
          </Grid>

          {/*//////////////////////////////// Attachments ////////////////////////////////*/}
          <Grid item xs={12} sm={9} sx={{ mt: 3 }}>
            <InputFile
              id={"attachment_action"}
              dataTestId={"attachment_action"}
              onFileUpload={(file) =>
                setSalesOrder({ ...salesOrder, attachment: file })
              }
              attachment={salesOrder.attachment}
              onFileRemove={() => {
                setSalesOrder({ ...salesOrder, attachment: null });
              }}
            />
          </Grid>

          {/*//////////////////////////////// Terms and Conditions ////////////////////////////////*/}
          <Grid item xs={12} sm={9} sx={{ mt: 4 }}>
            <TermsAndConditions
              title="Terms and Conditions"
              value={salesOrder.terms_and_conditions_id}
              setValue={(value) =>
                setSalesOrder({
                  ...salesOrder,
                  terms_and_conditions_id: value ? value.id : null,
                })
              }
            />
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6}>
          <CommonCalculation
            data={salesOrder}
            setData={setSalesOrder}
            isFormSubmitted={isFormSubmitted}

            isCurrency={true}
            dropdown_key='customer_id'

            accounts={accounts}
            subTotal={subTotal}
            totalTax={totalTax}
            totalAmount={totalAmount}
            finalPayment={finalPayment}
            totalDiscount={totalDiscount}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const ProductAndServicesDetailsHeaders = (props) => {
  const { isDiscountFiled, data, setData } = props;
  return (
    <CustomTableHead>
      <CustomTableRow>
        <CustomTableHeadCell style={{ width: 5 }} align="left"><span>#</span></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 310 }} align="left"><span>Goods/Service</span></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 125 }} align="left"><span>HSN/SAC</span></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 200 }} align="left"><span>Description</span></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 185 }} align="left"><Common.QuantityLabelComponent data={data} setData={setData} items='sales_order_items' /></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 175 }} align="left"><Common.RateLabelComponent data={data} setData={setData} items='sales_order_items' /></CustomTableHeadCell>
        {isDiscountFiled &&
          <CustomTableHeadCell style={{ width: 100 }} align="left">  <Common.DiscountLabelComponent data={data} setData={setData} items='sales_order_items' /></CustomTableHeadCell>
        }
        <CustomTableHeadCell style={{ width: 200 }} align="left"><span>Tax</span></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 145 }} align="left"><span>Tax Amount</span></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 145 }} align="left"><span>Amount</span></CustomTableHeadCell>
        <CustomTableHeadCell style={{ width: 5 }} align="right" />
      </CustomTableRow>
    </CustomTableHead>
  );
};

const ProductAndServicesDetailsBody = (props) => {
  const {
    isDiscountFiled,
    is_gst_with_zero,
    open,
    data,
    onDeleteLine,
    setData,
    addButtonHandler,
    salesOrder,
    newItem,
    itemIndex,
    setSalesOrder,
    isFormSubmitted,
    finalPayment,
  } = props;

  let location = useLocation();
  const dispatch = Actions.getDispatch(useContext);
  const [taxResult, setTaxResult] = React.useState([]);
  let taxApplied = salesOrder.tax_applied;
  const place_of_supply_id = salesOrder.place_of_supply_id;
  const taxList = filterTax(taxResult, place_of_supply_id,is_gst_with_zero);

  React.useEffect(() => {
    const apiResults = async () => {
      let res = await apiAction({
        method: "post",
        url: get_list_of_tax(),
        data: {
          business_id: getBusinessInfo().id,
        },
      });
      if (res?.success) {
        setTaxResult(res?.result);
      } else {
      }
    };
    apiResults();
    // eslint-disable-next-line
  }, []);

  const apiItemResults = async (id, index) => {
    let res = await apiAction({
      method: "post",
      url: get_item(id),
      data: { business_id: getBusinessInfo().id },
    });
    if (res?.success) {
      onItemSelect(res?.result, index);
    } else {
    }
  };

  const onItemSelect = (value, index) => {

    const getDefaultTax = () => {
      return findDefaultTax(taxList, is_gst_with_zero, value)
    };

  
    const getItemData = () => ({
      item: value,
      item_order: null,
      unit_id: value.unit?.id,
      quantity: value.quantity || 1,
      description: value.description,
      hsn_sac_code: value.hsn_sac_code,
      item_id: value.item_id || value.id,
      amount: value.amount || value.selling_price,
      rate: parseFloat(value.rate || value.selling_price),
      item_level_discount: value.item_level_discount || 0,
      is_item_discount_in_percentage: checkItemDiscount(salesOrder?.sales_items),

      gst_rate: getDefaultTax()?.rate,
      tax: taxApplied === "no_tax" ? null : getDefaultTax(),
      tax_id: taxApplied === "no_tax" ? null : getDefaultTax()?.id,
    });
  
    const updateData = () => {
      data[index] = value ? getItemData() : {
        tax: null,
        item: null,
        rate: null,
        quantity: 1,
        tax_id: null,
        amount: null,
        item_id: null,
        unit_id: null,
        item_order: null,
        description: null,
        hsn_sac_code: null,
        item_level_discount: 0,
        is_item_discount_in_percentage: checkItemDiscount(salesOrder?.sales_order_items),
      };
    };
  
    const isAmountRangeExceeded = () => isFinalAmountRangeCrossed({
      quantity: data[index]?.quantity,
      rate: data[index]?.rate,
      item_level_discount: data[index].item_level_discount,
      item: data[index],
      invoice: salesOrder,
      finalPayment: data.length <= 1
        ? 0
        : finalPayment - (data[index]?.amount || 0) - (data[index]?.tax_amount || 0),
    });
  
    if (isAmountRangeExceeded()) {
      stateChangeManager(
        dispatch,
        Actions,
        true,
        "error",
        "Amount range max limit reached"
      );
    } else {
      updateData();
      setData([...data]);
      setSalesOrder({ ...salesOrder, sales_order_items: [...data] });
    }
  };
  

  React.useEffect(() => {
    if (newItem) {
      setTimeout(() => {
        onItemSelect(newItem, itemIndex);
      }, 500);
    }
    // eslint-disable-next-line
  }, [newItem]);

  React.useEffect(() => {

    const getDefaultTax = (value) => {
      return findDefaultTax(taxList, is_gst_with_zero, value);
    };

      const updatedData = data.map((item) => {
        const defaultTax = getDefaultTax(item);
        const isDefaultSelectTax = item.tax_id || is_gst_with_zero;
  
        // Calculate values based on conditions
        const gstRate = isDefaultSelectTax ? defaultTax?.rate ?? null : null;
        const tax = isDefaultSelectTax ? (taxApplied === "no_tax" ? null : defaultTax) : null;
        const taxId = isDefaultSelectTax ? (taxApplied === "no_tax" ? null : defaultTax?.id ?? null) : null;
  
        return {
          ...item,
          tax: tax,
          tax_id: taxId,
          gst_rate: gstRate,
        }
      });

      setData(updatedData);
      setSalesOrder((prev) => ({
        ...prev,
        sales_order_items: updatedData
      }));
    
    // eslint-disable-next-line
  }, [is_gst_with_zero,place_of_supply_id,taxApplied]);

  return (
    <CustomTableBody>
      {salesOrder.sales_order_items.map((item, index) => {
        item['item_order'] = index + 1;

        return (
          <CustomTableRow key={index}>
            <CustomTableBodyCell sx={{}} align="left">
              <span>{index + 1}</span>
            </CustomTableBodyCell>

            <CustomTableBodyCell sx={{}} align="left">
              <CommonAsyncDropdown
                id={"item_dropdown_" + index}
                dataTestId={"item_dropdown_" + index}
                optionLabel="name"
                autoSelect={false}
                disableClearable={false}
                noOptionsText={"No result found"}
                placeholder="Select Goods/Service"
                validation={isFormSubmitted && !data[index].item_id}
                item={{
                  open: open,
                  method: "post",
                  url: list_items(1),
                  value: data[index].item,
                  onChange: (event, value) => {
                    if (value) {
                      data[index].item = value.name;
                      setData([...data]);
                      apiItemResults(value.id, index);
                    } else {
                      onItemSelect(value, index);
                    }
                  },
                  body: {
                    role: "customer",
                    business_id: getBusinessInfo().id,
                    for_sales: true,
                  },
                }}
                addButton={{
                  title: "+ Add an item",
                  onClick: () =>
                    addButtonHandler(
                      "New Item",
                      "new_pas",
                      "lg",
                      "sales",
                      index
                    ),
                }}
              />
            </CustomTableBodyCell>

            <CustomTableBodyCell sx={{}} align="left">
              <Input
                id={"input_hsn_sac_code_" + index}
                dataTestId={"input_hsn_sac_code_" + index}
                item={{
                  type: "text",
                  disabled: true,
                  required: true,
                  fullWidth: true,
                  placeholder: "",
                  value: data[index]?.hsn_sac_code
                    ? data[index]?.hsn_sac_code
                    : "",
                }}
              />
            </CustomTableBodyCell>

            <CustomTableBodyCell sx={{}} align="left">
              <Input
                id={"input_description_" + index}
                dataTestId={"input_description_" + index}
                isSearchableComponent={true}
                onFocus={() => {
                  data[index].focus = true;
                  setData([...data]);
                }}
                onBlur={() => {
                  data[index].focus = false;
                  setData([...data]);
                }}
                item={{
                  type: "text",
                  required: true,
                  multiline: true,
                  fullWidth: true,
                  rows: data[index]?.focus ? 4 : 1,
                  value: data[index]?.description ? data[index]?.description : "",
                  placeholder: "",
                  onChange: (event) => {
                    data[index].description = event.target.value;
                    setData([...data]);
                  },
                }}
              />
            </CustomTableBodyCell>

            <CustomTableBodyCell sx={{}} align="left">
              <Common.QuantityInputComponent
                index={index}
                data={data}
                setData={setData}
                quantityData={salesOrder}
                finalPayment={finalPayment}
                isFormSubmitted={isFormSubmitted}
              />
            </CustomTableBodyCell>

            <CustomTableBodyCell sx={{}} align="left">


              <Common.RateInputComponent
                index={index}
                data={data}
                setData={setData}
                rateData={salesOrder}
                finalPayment={finalPayment}
                isFormSubmitted={isFormSubmitted}
              />
            </CustomTableBodyCell>

            {isDiscountFiled &&
              <CustomTableBodyCell sx={{}} align="left">
                <Common.DiscountInputComponent
                  index={index}
                  data={data}
                  setData={setData}
                  discountData={salesOrder}
                  finalPayment={finalPayment}
                />

              </CustomTableBodyCell>
            }
            <CustomTableBodyCell sx={{}} align="left">
              <TaxList
                id={"tax_dropdown_" + index}
                dataTestId={"tax_dropdown_" + index}
                result={taxList}
                value={data[index]?.tax}
                disableClearable={false}
                inputDisabled={
                  is_gst_with_zero
                    ? is_gst_with_zero
                    : salesOrder.tax_applied === "no_tax"
                }
                validation={
                  !is_gst_with_zero &&
                  isFormSubmitted &&
                  salesOrder.tax_applied !== "no_tax" &&
                  !data[index]?.tax_id
                }
                setValue={(tax) => {
                  if (
                    isFinalAmountRangeCrossed({
                      quantity: data[index]?.quantity,
                      rate: data[index]?.rate,
                      item_level_discount: data[index].item_level_discount,
                      item: { ...data[index], tax: tax },
                      invoice: salesOrder,
                      finalPayment: data.length <= 1 ? 0 : finalPayment - (data[index]?.amount ? data[index]?.amount : 0) - (data[index]?.tax_amount ? data[index]?.tax_amount : 0),
                    })
                  ) {
                    stateChangeManager(dispatch, Actions, true, "error", "Amount range max limit reached");
                  } else {
                    if (tax) {
                      data[index].tax = tax;
                      data[index].tax_id = tax.id;
                      data[index].gst_rate = tax.rate;
                    } else {
                      data[index].tax = null;
                      data[index].tax_id = null;
                      data[index].tax_amount = null;
                    }
                    setData([...data]);
                  }
                }}
              />
            </CustomTableBodyCell>

            <CustomTableBodyCell sx={{}} align="left">
              <Input
                id={"input_tax_amount_" + index}
                dataTestId={"input_tax_amount_" + index}
                editable={false}
                is_currency_symbol={true}
                isShowCommaSeparatedValue={true}
                currency_code={salesOrder.currency_code}
                item={{
                  type: "text",
                  disabled: true,
                  fullWidth: true,
                  required: true,
                  placeholder: "",
                  value: data[index]?.tax_amount && salesOrder.tax_applied !== "no_tax" ? data[index]?.tax_amount : "",
                }}
              />
            </CustomTableBodyCell>

            <CustomTableBodyCell sx={{}} align="left">
              <Input
                id={"input_amount_" + index}
                dataTestId={"input_amount_" + index}
                editable={false}
                is_currency_symbol={true}
                isShowCommaSeparatedValue={true}
                currency_code={salesOrder.currency_code}
                item={{
                  disabled: true,
                  type: "number",
                  fullWidth: true,
                  required: true,
                  placeholder: "",
                  value: data[index]?.amount ? data[index]?.amount : "",
                }}
              />
            </CustomTableBodyCell>

            <CustomTableBodyCell sx={{}} align="right">
              <IconButton
                onClick={() => onDeleteLine(index)}
                id={"delete_icon_" + index}
                dataTestId={"delete_icon_" + index}
              >
                <DeleteIcon />
              </IconButton>
            </CustomTableBodyCell>

          </CustomTableRow>
        );
      })}
    </CustomTableBody>
  );
};

const Buttons = (props) => {
  const {
    is_Location,
    isEstimate,
    estimateData,
    estimate_validation_data,
    onSave,
    onCancel,
    onClear,
    salesOrder,
    setFormSubmitted,
  } = props;
  let location = useLocation();
  const params = getQueryParams(location.search);

  const { tax_applied, sales_order_items, customer_emails } = salesOrder;
  const { to } = customer_emails;

  const is_rate = sales_order_items.find((item) => item.rate === null);
  const is_quantity = sales_order_items.find((item) => !item.quantity);
  const is_sales_order_item_not_selected = sales_order_items.find(
    (item) => !item.item_id
  );
  const is_tax = sales_order_items.find(
    (item) => tax_applied !== "no_tax" && !item.tax_id
  );
  // const is_sales_order_item_hsn_sac_empty = sales_order_items.find((item) => !item.hsn_sac_code)

  let validation_data = [
    { key: "customer_id", message: "Please Select Customer" },
    // {
    //   key: "emails",
    //   validation: to.length === 0,
    //   message: "Please enter valid email.",
    // },
    { key: "sales_order_number", message: "Please Enter Sales Order Number" },
    isBusinessRegistered()&&!is_Location && {
      key: "place_of_supply_id",
      message: "Please Select Place of Supply",
    },
    { key: "payment_term_id", message: "Please Select Terms" },
    { key: "sales_order_date", message: "Please Enter Valid Sales Order Date" },
    {
      key: "",
      validation: is_sales_order_item_not_selected,
      message: "Please Select Item",
    },
    { key: "", validation: is_quantity, message: "Please Enter the Quantity" },
    { key: "", validation: is_rate, message: "Please Enter the Rate" },
    { key: "", validation: is_tax, message: "Please Select the Tax" },
  ];

  const clearActionDisabled =
    Object.keys(params).length === 0 || params.clone_id;

  return (
    <Box component="form" noValidate>
      <Grid container>
        <Grid item xs={12} sm={4} sx={{ display: "-webkit-flex", justifyContent: "start" }}>
          {clearActionDisabled && (
            <CustomButton
              id={"clear_btn"}
              dataTestId={"clear_btn"}
              variant="outlined"
              sx={{
                textTransform: "none",
                "&:hover": { backgroundColor: "#e8f3ff" },
              }}
              btnLabel="Clear"
              onClick={() => {
                onClear();
              }}
            />
          )}

          <CustomButton
            id={"cancel_btn"}
            dataTestId={"cancel_btn"}
            variant="contained"
            sx={{ ml: 1, textTransform: "none" }}
            btnLabel="Cancel"
            onClick={() => {
              onCancel();
            }}
          />
        </Grid>
        <Grid item xs={12} sm={8} sx={{ display: "-webkit-flex", justifyContent: "end" }}>
          <PrintAndPreview
            apiUrl={isEstimate ? preview_estimate() : preview_sales_order()}
            validation_data={
              isEstimate ? estimate_validation_data : validation_data
            }
            data={isEstimate ? estimateData : salesOrder}
            setFormSubmitted={() => {
              setFormSubmitted(true);
              if (isEstimate) {
                AnalyticsEvent(eventsNames.categories.ESTIMATES,{action:eventsNames.actions.PREVIEW});
              } else {
                AnalyticsEvent(eventsNames.categories.SALES_ORDERS,{action:eventsNames.actions.PREVIEW});
              }
            }}
          />
          <CustomGroupButton
            options={[
              {
                id: "save_send_btn",
                dataTestId: "save_send_btn",
                label: params.id ? "Update and Send" : "Save and Send",
                condition: () => {
                  onSave({ is_draft: false, is_save_send: true });
                },
              },
              {
                id: "save_send_later_btn",
                dataTestId: "save_send_later_btn",
                label: params.id
                  ? "Update and Send Later"
                  : "Save and Send Later",
                condition: () => {onSave({ is_draft: false, is_save_send: false });},
              },
            ]}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default CreateSalesOrder;
