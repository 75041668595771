import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogTitle, IconButton, DialogContent, DialogActions } from '@mui/material';

import DeleteJournalEntry from '../../pages/accounting/journal/deleteJournalEntry/DeleteJournalEntry';
import CreateJournalEntry from '../../pages/accounting/journal/createJournalEntry/CreateJournalEntry';
import UpdateJournalEntry from '../../pages/accounting/journal/updateJournalEntry/UpdateJournalEntry';

import CreateSubAccount from '../../pages/accounting/accounts/CreateSubAccount';
import CreateParentAccount from '../../pages/accounting/accounts/CreateParentAccount';
import CreateCompany from '../../pages/businessManagement/createBusiness/CreateCompany';
import ViewJournalEntry from '../../pages/accounting/journal/common/ViewJournalEntry';
import SourceTransactionJournalEntry from '../../pages/bulkStatementUpload/sourceTransaction/SourceTransactionJournalEntry';

import { AddTerms } from '../../pages/invoicing/common/Terms';
import CreateNewInvoice from '../../pages/invoicing/sales/invoices/CreateNewInvoice';
import CreateItems from '../../pages/invoicing/items/CreateItems';
import CreateNewCustomer from '../../pages/invoicing/sales/customers/CreateNewCustomer';
import { AddTax } from '../../pages/invoicing/common/GST';
import { CreateSalesPerson } from '../../pages/invoicing/common/salesPerson';
import { AddTermsAndConditions } from '../../pages/invoicing/common/TermsAndConditions';
import CreateNewVendor from '../../pages/invoicing/purchase/vendors/CreateNewVendor';
import CreateNewBill from '../../pages/invoicing/purchase/bills/CreateNewBill';
import CustomTypography from '../typography/CustomTypography';
import PreferenceAndCategorized from '../../pages/bulkStatementUpload/sourceTransaction/PreferenceAndCategorized';
import CommonPreviewShare from '../../common/CommonPreviewShare';
import UpdateTermsAndConditions from '../../pages/invoicing/common/UpdateTermsAndConditions';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: children ? 2 : 3, fontWeight: '600', textTransform: 'none', }} {...other} style={{
            alignItems: 'center',
            justifyItems: 'center',
            justifyContent: 'space-evenly',
            alignContent: 'space-evenly',
        }}>
            <CustomTypography
                text={children}
                sx={{
                    color: '#141414',
                    display: 'inline',
                    fontSize: '20px',
                    fontWeight: '700',
                    lineHeight: '27px',
                    fontStyle: 'normal',
                    textTransform: 'none',
                    fontFamily: "Noto Sans",
                }}
            />

            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        right: 8,
                        mt: children ? 0 : -3,
                        position: 'absolute',
                        color: (theme) => theme.palette.grey[900],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const CustomDialog = (props) => {
    const {sx, onTermAndConditionUpdate, state, setState, onAddTerms, onAddItem, onAddCustomer, onAddVendor, onAddAccount, itemIndex, onAddPreference } = props

    return (
        <div>
            <BootstrapDialog
                open={state.open}
                maxWidth={state.maxWidth}
                fullWidth={state.fullWidth}
                onClose={() => setState({ open: false, condition: state.condition })}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={() => setState({ open: false, condition: state.condition })}>
                    {state.title}
                </BootstrapDialogTitle>
                <DialogContent dividers sx={{...sx}}>
                    {state.condition === 'view' ?
                        <ViewJournalEntry id={state.id} onClose={() => setState({ open: false })} /> :
                        state.condition === 'create' ?
                            <CreateJournalEntry onClose={() => setState({ open: false })} /> :
                            state.condition === 'update' ?
                                <UpdateJournalEntry currencyDisabled={state.currencyDisabled} id={state.id} onClose={() => setState({ open: false })} /> :
                                state.condition === 'preference_categorized' ?
                                    <PreferenceAndCategorized data={state} onClose={() => setState({ open: false, condition: "preference_categorized" })} onAddPreference={(body) => { onAddPreference(body) }} fetchPreferences={() => state.fetchPreferences ? state.fetchPreferences() : null} /> :
                                    state.condition === 'delete' ?
                                        <DeleteJournalEntry navigate={() => state.navigate ? state.navigate() : null} onDeleteAction={() => state.onDeleteAction ? state.onDeleteAction() : null} id={state.id} url={state.url} deleteMessage={state.deleteMessage} onClose={() => { setState({ open: false }) }} /> :
                                        state.condition === 'create_sub_account' ?
                                            <CreateSubAccount filterValue={state.filterValue} account_type={state.account_type} onClose={() => setState({ open: false })} filter={state.compulsory} onAddAccount={onAddAccount} /> :
                                            state.condition === 'create_parent_account' ?
                                                <CreateParentAccount onClose={() => setState({ open: false })} filter={state.compulsory} onAddAccount={onAddAccount} /> :
                                                state.condition === 'create_business_account' ?
                                                    <CreateCompany marginTop={2} onClose={() => setState({ open: false })} /> :
                                                    state.condition === 'source_transaction_journalEntry' ?
                                                        <SourceTransactionJournalEntry id={state.id} onClose={() => setState({ open: false })} onAddTransactionAction={() => state.onAddTransactionAction ? state.onAddTransactionAction() : null} /> :
                                                        state.condition === 'add_terms' ?
                                                            <AddTerms id={state.id} onClose={() => setState({ open: false })} onAddTerms={onAddTerms} /> :
                                                            state.condition === 'add_sales_person' ?
                                                                <CreateSalesPerson id={state.id} onClose={() => setState({ open: false })} onAddItem={onAddItem} /> :
                                                                state.condition === 'add_tax' ?
                                                                    <AddTax id={state.id} onClose={() => setState({ open: false })} /> :
                                                                    state.condition === 'new_customer' ?
                                                                        <CreateNewCustomer id={state.id} onClose={() => setState({ open: false })} onAddCustomer={onAddCustomer} /> :
                                                                        state.condition === 'update_customer' ?
                                                                            <CreateNewCustomer id={state.id} updateCustomerData={state.item} onClose={() => setState({ open: false })} /> :
                                                                            state.condition === 'new_vendor' ?
                                                                                <CreateNewVendor id={state.id} onClose={() => setState({ open: false })} onAddVendor={onAddVendor} /> :
                                                                                state.condition === 'update_vendor' ?
                                                                                    <CreateNewVendor id={state.id} updateVendorData={state.item} onClose={() => setState({ open: false })} /> :
                                                                                    state.condition === 'new_invoice' ?
                                                                                        <CreateNewInvoice id={state.id} onClose={() => setState({ open: false })} customer={state.item} /> :
                                                                                        state.condition === 'new_bill' ?
                                                                                            <CreateNewBill id={state.id} onClose={() => setState({ open: false })} vendor={state.item} /> :
                                                                                            state.condition === 'new_pas' ?
                                                                                                <CreateItems compulsory={state.compulsory} id={state.id} updateItemData={state.item} onClose={() => setState({ open: false })} onAddItem={onAddItem} itemIndex={itemIndex} /> :
                                                                                                state.condition === 'create_terms_and_conditions' ?
                                                                                                    <AddTermsAndConditions id={state.id} onClose={() => setState({ open: false })} onAddItem={onAddItem} /> :
                                                                                                    state.condition === 'update_terms_and_conditions' ?
                                                                                                        <UpdateTermsAndConditions results={state.results} id={state.id} onClose={() => setState({ open: false })} onAddItem={onTermAndConditionUpdate} /> :
                                                                                                        state.condition === 'preview_send' ?
                                                                                                            <CommonPreviewShare id={state.id} type={state.type} file_key={state.file_key} export_Url={state.export_Url} retrieve_Url={state.retrieve_Url} onClose={() => setState({ open: false })} /> :
                                                                                                            ''
                    }
                    <DialogActions>
                    </DialogActions>
                </DialogContent>
            </BootstrapDialog>
        </div>
    );
}

export default CustomDialog