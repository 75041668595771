import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { routesName } from "../../../../config/routesName";
import { opening_balance } from "../../../../utils/Constant";
import Mappings from "../../bulkStatementUpload/mappings/Mappings";

const OpeningBalanceMapping = (props) => {
    const {nextButton}=props;
    return(
        <DndProvider backend={HTML5Backend}>
        <Mappings onFileErrorPath={routesName.openingBalanceImport.path} nextButton={nextButton} nextPagePath={routesName.openingBalancePreview.path} mapping_for={ opening_balance } isDateFormat={false}/>
        </DndProvider>
        
    )
}

export default OpeningBalanceMapping